import getPath from '../PropertyAdapters/ellipse/path';
import getRelativeAnchors from '../PropertyAdapters/ellipse/relativeAnchors';
import getAnchors from '../Utils/anchors';
import defaultShapeToFabricJSON from './default';

const fromShapeToFabricJSON = (shape, canvasState) => ({
    ...defaultShapeToFabricJSON(shape, canvasState),
    rx: shape.get('width') / 2,
    ry: shape.get('height') / 2,
    path: getPath(shape),
    anchors: getAnchors(shape, getRelativeAnchors(shape))
});

export default fromShapeToFabricJSON;
