const TreeNode = require('../../fabric-adapter/DecksignFabricShapeType/Groups/TreeNode');

class GroupHeuristic {
    static canGroupShapes(tree, ids = []) {
        let siblings = null;

        if (ids.length < 2) return false;

        // eslint-disable-next-line no-restricted-syntax
        for (const id of ids) {
            if (TreeNode.findNode(tree, id)) {
                if (!siblings) {
                    siblings = TreeNode.getSiblings(tree, id);
                } else if (!siblings.map(sibling => sibling.shape.id).includes(id)) {
                    return false;
                }
            }
        }

        return true;
    }

    static canUngroupShapes(selectedShapes) {
        if (!selectedShapes.size) return false;

        // eslint-disable-next-line no-restricted-syntax
        for (const selectedShape of selectedShapes) {
            if (selectedShape.get('type').toLowerCase() !== 'group') {
                return false;
            }
        }

        return true;
    }
}

module.exports = GroupHeuristic;
