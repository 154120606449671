const forEach = require('lodash/forEach');

module.exports = Base => class extends Base {
    updateBorders() {
        forEach(this.cells, cell => {
            this.borders = this.borders.map(border => {
                if (cell.borders.includes(border.id)) {
                    switch (border.side) {
                        case 'top':
                            border.position = {
                                x1: cell.left, x2: cell.right, y1: cell.top, y2: cell.top
                            };
                            break;
                        case 'bottom':
                            border.position = {
                                x1: cell.left, x2: cell.right, y1: cell.bottom, y2: cell.bottom
                            };
                            break;
                        case 'right':
                            border.position = {
                                x1: cell.right, x2: cell.right, y1: cell.top, y2: cell.bottom
                            };
                            break;
                        case 'left':
                            border.position = {
                                x1: cell.left, x2: cell.left, y1: cell.top, y2: cell.bottom
                            };
                            break;
                        default:
                    }
                }
                return border;
            });
        });
    }
};
