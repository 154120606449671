import * as types from '#Constants/ActionTypes';

const initialState = {
    plans: [],
    address: {},
    card: {},
    error: false,
    isPlansLoading: false,
    isBillingInfoLoading: false,
    isSubscriptionLoading: false,
    billingInfoFirstFetch: true
};

const billing = (state = initialState, action) => {
    switch (action.type) {
        case types.PROFILE_BILLING_FETCH_BILLING_INFO_PENDING:
            return { ...state, isBillingInfoLoading: true };
        case types.PROFILE_BILLING_FETCH_PLANS_PENDING:
            return { ...state, isPlansLoading: true };
        case types.PROFILE_BILLING_SUBSCRIBE_PENDING:
        case types.PROFILE_BILLING_UPDATE_SUBSCRIPTION_PENDING:
        case types.PROFILE_BILLING_UNSUBSCRIBE_PENDING:
            return { ...state, isSubscriptionLoading: true };
        case types.PROFILE_BILLING_FETCH_PLANS_DONE:
            return {
                ...state,
                plans: [...action.plans],
                error: false,
                isPlansLoading: false
            };
        case types.PROFILE_BILLING_FETCH_BILLING_INFO_DONE:
            return {
                ...state,
                address: { ...action.address },
                card: { ...action.card },
                cardholder: action.cardholder,
                error: false,
                isBillingInfoLoading: false,
                billingInfoFirstFetch: false
            };
        case types.PROFILE_BILLING_SUBSCRIBE_DONE:
        case types.PROFILE_BILLING_UPDATE_SUBSCRIPTION_DONE:
        case types.PROFILE_BILLING_UNSUBSCRIBE_DONE:
            return {
                ...state,
                error: false,
                isSubscriptionLoading: false
            };
        case types.PROFILE_BILLING_FETCH_BILLING_INFO_REJECTED:
            return {
                ...state,
                error: true,
                isBillingInfoLoading: false,
                billingInfoFirstFetch: false
            };
        case types.PROFILE_BILLING_FETCH_PLANS_REJECTED:
            return { ...state, error: true, isPlansLoading: false };
        case types.PROFILE_BILLING_SUBSCRIBE_REJECTED:
        case types.PROFILE_BILLING_UPDATE_SUBSCRIPTION_REJECTED:
        case types.PROFILE_BILLING_UNSUBSCRIBE_REJECTED:
            return { ...state, error: true, isSubscriptionLoading: false };
        default:
            return state;
    }
};

export default billing;
