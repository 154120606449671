import { replace } from 'connected-react-router';
import get from 'lodash/get';

import decksignCanvas from 'decksign-canvas';
import api from '#Utilities/api';
import { debounceAction } from '#Utilities/actions';
import { removeDeckPage, applyCurrentLayoutToAllPagesWithSameLayoutName, createNewVersionOfLayout } from '#Actions/deck';
import { changeInsertMenuVisibility } from '#Actions/build/ui';
import client from '#Utilities/graphql';
import * as types from '#Constants/ActionTypes';
import createCanvasState from '#Utilities/decksign/canvasState';
import blockActionCreatorByLayoutModal from './blockActionCreatorByLayoutModal';

const { CanvasState } = decksignCanvas.api;

const SAVE_INTERVAL_SECONDS = 10;
const zoomSteps = [
    0.25, 0.5, 0.75,
    1, 1.25, 1.5,
    2, 2.5, 3, 4
];

const getPageUpdates = (canvasState, page, deck) => {
    const persistedState = CanvasState.getPersistedState(canvasState, page);
    const updates = {
        shapes: persistedState.shapes,
        background: persistedState.background,
        layout: persistedState.layout
    };
    if (persistedState.layout &&
        ((page.originalLayout &&
            persistedState.layout.name !== page.originalLayout.name) ||
            !page.originalLayout
        )
    ) {
        return client.fetchLayouts(deck.id)
            .then(layouts => ({
                ...updates,
                originalLayout: layouts.find(({ name }) => persistedState.layout.name === name)
            }));
    }
    return Promise.resolve(updates);
};

const updatePage = (canvasState, page, deck) => dispatch => getPageUpdates(canvasState, page, deck)
    .then(updates => {
        const {
            shapes,
            background,
            layout,
            originalLayout = null
        } = updates;
        return client.updatePage(deck.id, page.id, shapes, background, layout, originalLayout);
    })
    .then(modifiedDeck => {
        const newPage = modifiedDeck.pages
            .find(modifiedPage => modifiedPage.pageNumber === page.pageNumber);

        return dispatch({
            type: types.UPDATE_PAGE_ID_IN_CURRENT_DECK,
            previousId: page.id,
            newId: newPage.id,
            newPage,
            version: canvasState.get('version')
        });
    }).catch(err => {
        dispatch({ type: types.SAVING_FAILED, error: err });
        throw err;
    });

const doSaveCanvasState = canvasState => (dispatch, getState) => {
    const {
        build: {
            page,
            persistedCanvasState: {
                version: persistedVersion,
                isSaving
            },
            isCanvasStateUpdating,
            ui: {
                shapesNavigation: {
                    currentMode
                }
            }
        },
        deck
    } = getState();
    const isCanvasStateNew = CanvasState
        .shouldCurrentVersionBePersisted(canvasState, persistedVersion);
    const inLayoutMode = currentMode === 'Layout';

    if (!isSaving && !isCanvasStateUpdating && isCanvasStateNew && page && !inLayoutMode) {
        dispatch({
            type: 'START_CANVAS_STATE_SAVE',
            version: canvasState.get('version')
        });
        dispatch(updatePage(canvasState, page, deck));
    }
    return Promise.resolve();
};

const debouncedSave = debounceAction(doSaveCanvasState, SAVE_INTERVAL_SECONDS * 1e3);

const saveCanvasState = (canvasState, forceSave = false) => {
    if (!forceSave) {
        return debouncedSave(canvasState);
    }
    debouncedSave.cancel();
    return doSaveCanvasState(canvasState);
};

const savePage = (forceSave = false) => (dispatch, getState) => {
    const { build: { canvasState } } = getState();
    return saveCanvasState(canvasState, forceSave)(dispatch, getState);
};

const savePageBeforeRemove = () => (dispatch, getState) => {
    const {
        build: {
            page,
            canvasState,
            persistedCanvasState: {
                version: persistedVersion,
                isSaving
            },
            isCanvasStateUpdating
        },
        deck
    } = getState();
    const isCanvasStateNew = CanvasState
        .shouldCurrentVersionBePersisted(canvasState, persistedVersion);

    if (!isSaving && !isCanvasStateUpdating && isCanvasStateNew && page) {
        dispatch({
            type: 'START_CANVAS_STATE_SAVE',
            version: canvasState.get('version')
        });

        return updatePage(canvasState, page, deck)(dispatch, getState);
    }

    return Promise.resolve();
};

const removePage = pageId => removeDeckPage(pageId);

const loadPage = ({ pageId }) => (dispatch, getState) => {
    const state = getState();
    if (!process.env.REACT_APP_PERF_TEST_ENABLED) {
        if (state.deck && pageId && pageId.length > 0) {
            client.fetchPage(pageId)
                .then(response => {
                    const updatedState = getState();
                    if (!response) {
                        return null;
                    }
                    if (response && response.id !== updatedState.build.activePage.id) {
                        console.warn('avoiding useless render of outdated resource');
                        return null;
                    }
                    /* This is done so we can show the loader on changeACtive page when the page is cached.
                        This is because the browser gives priority to the rendering of the canvas and it
                        blocks the loader from displaying.
                    */
                    setTimeout(() => {
                        dispatch({
                            type: types.LOAD_PAGE,
                            page: response,
                            deck: state.deck,
                            canvasState: createCanvasState(state.deck, response)
                        });
                    }, 0);
                    return null;
                })
                .catch(err => {
                    console.error(err);
                });
        }
    }
};

const createChangeActivePageAction = (id, payload) => (dispatch, getState) => {
    const {
        deck: { pages },
        build: { activePage: { id: currentActivePageId } }
    } = getState();

    if (id === currentActivePageId) {
        dispatch(loadPage({ pageId: id }));
        return;
    }

    if (!id) {
        return;
    }

    dispatch({
        type: types.CHANGE_ACTIVE_PAGE,
        id,
        pageIndex: pages.findIndex(page => page._id === id),
        currentPageIndex: pages.findIndex(page => page._id === currentActivePageId),
        ...payload
    });

    debouncedSave.cancel();
    const currentPageNumber = pages.findIndex(page => page._id === id) + 1;
    if (currentPageNumber) {
        dispatch(replace({
            pathname: `${window.location.pathname}`,
            search: `?pageNumber=${currentPageNumber}`
        }));
    } else {
        dispatch(replace({
            pathname: `${window.location.pathname}`,
            search: '?pageNumber=1'
        }));
    }
    dispatch(loadPage({ pageId: id }));
};

const changeActivePage = (id, payload) => blockActionCreatorByLayoutModal(createChangeActivePageAction, id, payload);

const forceSavePage = (dispatch, getState) => {
    const state = getState();
    if (state.build.page && state.build.page.shapes) {
        return dispatch(savePage(true));
    }
    return Promise.resolve();
};

const saveAndChangeActivePage = (id, payload) => dispatch => {
    dispatch(forceSavePage).then(() => {
        dispatch(changeActivePage(id, payload));
    });
};

const addPage = (deck, page, newActivePageIndex) => dispatch => {
    dispatch({ type: types.ADD_PAGE });
    client.addPage(deck.id, page, newActivePageIndex)
        .then(updatedDeck => {
            dispatch({
                type: types.LOAD_DECK,
                deck: updatedDeck
            });
            dispatch(saveAndChangeActivePage(updatedDeck.pages[newActivePageIndex].id, { reason: types.ADD_PAGE }));
            dispatch(changeInsertMenuVisibility(false));
        })
        .catch(err => console.error(err));
};

const duplicatePage = (pageId, pageIndex) => (dispatch, getState) => {
    const deck = getState().deck;
    dispatch({ type: types.DUPLICATE_PAGE });
    client.duplicatePage(deck.id, pageId, pageIndex + 1)
        .then(updatedDeck => {
            dispatch({
                type: types.LOAD_DECK,
                deck: updatedDeck
            });
            dispatch(saveAndChangeActivePage(updatedDeck.pages[pageIndex + 1].id, { reason: types.DUPLICATE_PAGE }));
        })
        .catch(err => { throw err; });
};

const createBlankPage = (currentDeck, newPageIndex) => (dispatch, getState) => {
    const deck = currentDeck || getState().deck;
    const pageIndex = newPageIndex || deck.pages.length;
    dispatch(addPage(deck, {}, pageIndex));
};

const addPageToEmptyDeck = currentDeck => (dispatch, getState) => {
    const deck = currentDeck || getState().deck;
    if (get(deck, 'id') === undefined) {
        return Promise.reject(new Error('No current deck'));
    }
    dispatch({
        type: types.SET_LOADING,
        loading: true
    });
    const page = {};
    const pageNumber = 0;
    return client.addPage(deck.id, page, pageNumber)
        .then(updatedDeck => {
            dispatch({
                type: types.LOAD_DECK,
                deck: updatedDeck
            });
            dispatch({
                type: types.SET_LOADING,
                loading: false
            });
        })
        .catch(err => console.error(err));
};

const createPageFromLayout = (currentDeck, item, newPageIndex = currentDeck.pages.length) => (dispatch, getState) => {
    const layouts = getState().build.layouts.list;
    const layout = layouts.find(({ name }) => name === item.name);
    const shapes = layout.shapes
        .filter(shape => shape.placeholderSequence !== undefined)
        .map(placeholder => ({
            ...placeholder,
            id: undefined,
            _id: undefined,
            inLayout: false,
            placeholderSourceId: placeholder.id
        }));
    const page = {
        originalLayout: {
            ...layout,
            _id: undefined,
            id: undefined
        },
        shapes
    };
    dispatch(addPage(currentDeck, page, newPageIndex));
};

const createNewPageFromPage = (page, deck, pageIndex) => dispatch => (
    api.put({
        path: `decks/${deck.id}/pages`,
        body: { page, pageAt: pageIndex },
        query: { for: 'override' }
    }).then(modifiedDeck => {
        const newPage = modifiedDeck.pages
            .find(p => p.pageNumber === page.pageNumber);
        dispatch({
            previousId: deck.pages[pageIndex].id,
            newId: newPage.id,
            newPage,
            type: types.UPDATE_PAGE_IN_CURRENT_DECK,
            deck: modifiedDeck
        });
    })
);

const addPreviouslyExistingPage = (pageId, pageIndex, activePageIndex) => (dispatch, getState) => {
    const state = getState();
    const {
        deck
    } = state;
    dispatch({ type: types.UPDATE_DECK });
    return client.addPreviouslyExistingPage(deck.id, pageId, pageIndex)
        .then(updatedDeck => {
            dispatch({
                type: types.LOAD_DECK,
                deck: updatedDeck
            });

            return dispatch(saveAndChangeActivePage(updatedDeck.pages[activePageIndex].id, { reason: 'REMOVE_PAGE' }));
        })
        .catch(err => console.error(err));
};

const updatePageInCurrentDeck = (page, deck) => dispatch => {
    const modifiedDeck = {
        ...deck,
        pages: deck.pages.map(aPage => (
            aPage.id === page.id ? page : aPage
        ))
    };

    const newPage = modifiedDeck.pages
        .find(p => p.pageNumber === page.pageNumber);

    dispatch({
        previousId: page.id,
        newId: newPage.id,
        newPage,
        type: types.UPDATE_PAGE_IN_CURRENT_DECK,
        deck: modifiedDeck
    });
};

const getIndexOfNextPage = (state, upOrDown) => {
    const currentDeck = state;
    const activePage = state.build.activePage.id;
    const indexOfActivePage = currentDeck.deck.pages.findIndex(page => activePage === page.id);

    let indexOfNextPage;

    if (upOrDown === 'up') {
        indexOfNextPage = Math.max(0, indexOfActivePage - 1);
    } else if (upOrDown === 'down') {
        indexOfNextPage = Math.min(currentDeck.deck.pages.length - 1, indexOfActivePage + 1);
    }

    return currentDeck.deck.pages[indexOfNextPage].id;
};

const setPageVisibility = (pageId, visibility) => (dispatch, getState) => {
    const { deck } = getState();
    dispatch({
        type: types.SET_LOADING,
        loading: true
    });
    client.updatePageVisibility(deck.id, pageId, visibility)
        .then(updatedDeck => {
            dispatch({
                type: types.LOAD_DECK,
                deck: updatedDeck
            });
            dispatch({
                type: types.SET_LOADING,
                loading: false
            });
        });
};

const copyPage = (dispatch, getState) => {
    const page = getState().build.page;
    dispatch({
        type: types.SET_CLIPBOARD,
        items: [page],
        itemType: 'Page'
    });
};

const pastePage = (dispatch, getState) => {
    const page = getState().build.clipboard.items[0];
    const pageIndex = getState().build.page.pageNumber - 1;
    dispatch(duplicatePage(page.id, pageIndex));
};

const cutPage = (dispatch, getState) => {
    const page = getState().build.page;
    dispatch({
        type: types.SET_CLIPBOARD,
        items: [page],
        itemType: 'Page'
    });
    dispatch(removePage(page.id));
};

const handleScroll = () => {
    const selectedPane = document.getElementsByClassName('ui card  PageCard isSelected')[0];
    selectedPane.scrollIntoView({ block: 'center' });
};

const pageUp = (dispatch, getState) => {
    const state = getState();
    const indexOfNextPage = getIndexOfNextPage(state, 'up');

    if (state.build.activePage.id !== indexOfNextPage) {
        dispatch(saveAndChangeActivePage(indexOfNextPage));
        handleScroll();
    }
};

const pageDown = (dispatch, getState) => {
    const state = getState();
    const indexOfNextPage = getIndexOfNextPage(state, 'down');

    if (state.build.activePage.id !== indexOfNextPage) {
        dispatch(saveAndChangeActivePage(indexOfNextPage));
        handleScroll();
    }
};

const zoomIn = () => (dispatch, getState) => {
    let nextZoom;
    const currentZoom = getState().build.zoom.value;
    const currentStep = zoomSteps.indexOf(currentZoom);
    if (currentStep === -1) {
        const higherZoomValues = zoomSteps.filter(zoomval => zoomval > currentZoom);
        nextZoom = higherZoomValues.length ? Math.min.apply(null, higherZoomValues) : currentZoom;
    } else {
        nextZoom = currentStep !== zoomSteps.length - 1 ? zoomSteps[currentStep + 1] : currentZoom;
    }

    dispatch({
        type: types.ZOOM_AT,
        zoom: Number(nextZoom)
    });
};

const zoomAt = value => dispatch => {
    dispatch({
        type: types.ZOOM_AT,
        zoom: Number(value)
    });
};

const zoomOut = () => (dispatch, getState) => {
    let nextZoom;
    const currentZoom = getState().build.zoom.value;
    const currentStep = zoomSteps.indexOf(currentZoom);
    if (currentStep === -1) {
        const lowerZoomValues = zoomSteps.filter(zoomval => zoomval < currentZoom);
        nextZoom = lowerZoomValues.length ? Math.max.apply(null, lowerZoomValues) : currentZoom;
    } else {
        nextZoom = currentStep !== 0 ? zoomSteps[currentStep - 1] : currentZoom;
    }

    dispatch({
        type: types.ZOOM_AT,
        zoom: Number(nextZoom)
    });
};

const adjustZoomToScreen = () => ({
    type: types.ADJUST_ZOOM_TO_SCREEN
});

const setThumbnail = (thumbnail, id) => ({
    type: types.SET_THUMBNAIL,
    thumbnail,
    id
});

const createDragPageAction = sourceId => ({
    type: 'DRAG_PAGE',
    sourceId
});

const createApplyToAllAction = applyToAll => (dispatch, getState) => {
    const {
        build: {
            ui: {
                shapesNavigation: {
                    delayedActionCreator
                }
            }
        }
    } = getState();

    dispatch({
        type: types.START_LAYOUT_CHANGE
    });

    const promise = applyToAll ?
        dispatch(applyCurrentLayoutToAllPagesWithSameLayoutName()) :
        dispatch(createNewVersionOfLayout());

    promise.then(() => {
        dispatch({
            type: types.CLOSE_LAYOUT_CONFIRMATION_MODAL
        });

        dispatch(delayedActionCreator());
    });
};

const createCloseLayoutConfirmationModalAction = () => ({
    type: types.CLOSE_LAYOUT_CONFIRMATION_MODAL
});

const createCancelLayoutConfirmationModalAction = () => (dispatch, getState) => {
    const {
        build: {
            ui: {
                shapesNavigation: {
                    delayedActionCreator,
                    versionBeforeEdit
                }
            }
        }
    } = getState();

    dispatch({
        type: types.START_LAYOUT_CHANGE
    });

    dispatch({
        type: types.REVERT_CANVAS_STATE,
        version: versionBeforeEdit
    });

    return dispatch(forceSavePage).then(() => {
        dispatch({
            type: types.CLOSE_LAYOUT_CONFIRMATION_MODAL
        });

        dispatch(delayedActionCreator());
    });
};

export {
    addPageToEmptyDeck,
    adjustZoomToScreen,
    changeActivePage,
    copyPage,
    createApplyToAllAction,
    createBlankPage,
    createCancelLayoutConfirmationModalAction,
    createCloseLayoutConfirmationModalAction,
    createDragPageAction,
    createNewPageFromPage,
    createPageFromLayout,
    createChangeActivePageAction,
    cutPage,
    addPreviouslyExistingPage,
    duplicatePage,
    forceSavePage,
    getIndexOfNextPage,
    loadPage,
    pageDown,
    pageUp,
    pastePage,
    removePage,
    saveAndChangeActivePage,
    saveCanvasState,
    savePage,
    savePageBeforeRemove,
    setPageVisibility,
    setThumbnail,
    updatePageInCurrentDeck,
    zoomAt,
    zoomIn,
    zoomOut
};
