const { strokeFillConstructor } = require('../../../Shape/Stroke/StrokeFill');
require('../../../Shape/Stroke/StrokeFill/StrokeFillLoader.js');

module.exports = Base => class extends Base {
    applyBorderUpdateToAll(borders = [], update) {
        borders.forEach(border => {
            this.borderSegments[border].applyUpdate(update);
        });
    }

    clearBorders(borders = []) {
        const update = { fill: new strokeFillConstructor.Color(), strokeWidth: 0 };
        this.applyBorderUpdateToAll(borders, update);
    }

    getInnerHorizontalBorders(cells = []) {
        return this.getInnerBorders(cells)
            .filter(border => border.side === 'horizontal');
    }

    getInnerVerticalBorders(cells = []) {
        return this.getInnerBorders(cells)
            .filter(border => border.side === 'vertical');
    }

    getInnerBorders(cells = []) {
        const filteredBorders = new Set();
        const ids = cells.map(cell => cell.id);
        cells.forEach(cell => cell.borders.forEach(borderId => {
            if (
                this.borderSegments[borderId].isInsideCellSelection(ids)
            ) {
                filteredBorders.add(this.borderSegments[borderId]);
            }
        }));
        return [...filteredBorders];
    }

    getOuterBorders(cells = []) {
        const filteredBorders = new Set();
        const ids = cells.map(cell => cell.id);
        cells.forEach(cell => cell.borders.forEach(borderId => {
            if (
                this.borderSegments[borderId].isOnEdgeOfCellSelection(ids)
            ) {
                filteredBorders.add(this.borderSegments[borderId]);
            }
        }));
        return [...filteredBorders];
    }
};
