import getPath from '../PropertyAdapters/trapezoid/path';
import getBaseLength from '../PropertyAdapters/trapezoid/baseLength';
import getPoints from '../PropertyAdapters/trapezoid/points';
import defaultShapeToFabricJSON from './default';

const fromShapeToFabricJSON = (shape, canvasState) => ({
    ...defaultShapeToFabricJSON(shape, canvasState),
    baseLength: getBaseLength(shape),
    path: getPath(shape),
    points: getPoints(shape)
});

export default fromShapeToFabricJSON;
