import {
    Record,
    List,
    Map,
    fromJS
} from 'immutable';
import {
    isEqual,
    isNil
} from 'lodash';
import {
    DEFAULT_RUNSTYLE,
    DEFAULT_PARAGRAPHSTYLE,
    DEFAULT_TEXT_BODY_STYLE
} from './Config/defaultStyles';
import TEXTBODY_PROPERTIES from './Config/textBodyRecordProperties';
import {
    setTextBodyStyleProperties,
    updateMargins,
    getMargins,
    getTextDirection,
    getVerticalAlign,
    updateVerticalAlign,
    updateTextDirection,
    getText,
    getTextLength,
    isEmpty,
    hasText,
    getAutoFitShape,
    getAutoFitText
} from './TextBodyProperties/TextBodyProperties';
import {
    getParagraphs,
    getParagraph,
    getParagraphsCount,
    getParagraphIndexAtChar,
    getParagraphsWithIndex,
    setParagraphs,
    addParagraph
} from './Paragraphs/Paragraphs';
import {
    getParagraphStyle,
    getDefaultParagraphStyle,
    setDefaultParagraphStyle,
    setParagraphStyleProperties,
    getParagraphStylesCount,
    getParagraphsWithParagraphStyle,
    getParagraphsWithStyle,
    getParagraphWithStyle,
    getItemsForTextSelection,
    getFirstRunInParagraph,
    getParagraphStyles,
    getParagraphStyleAt,
    cleanUpParagraphStyles
} from './Paragraphs/ParagraphStyles';
import {
    addRunStyle,
    getRunStylesCount,
    getRunStyleIndex,
    setRunStyleIndexToRun,
    getTextWithStyle,
    getDefaultRunStyle,
    getRunStyle,
    fillHolesInRunsArray,
    getDefaultRunStyleFromFirstRun,
    getRunStylesAt,
    getRunStyles,
    getFonts,
    mergeContiguousRunsWithSameStyle,
    removeUnusedRunStyles,
    setDefaultRunStyle,
    mergeRunStyle,
    setStyle,
    cleanUpRunStyles,
    clearStyle
} from './Runs/RunStyles';
import {
    getRunsAt,
    getRunDynamicType,
    getRunIndex,
    mergeRunsWithNewLineChar,
    splitRunsAt
} from './Runs/Runs';
// eslint-disable-next-line import/no-cycle
import { applyListUpdate, convertParagraphsToListType } from './Paragraphs/lists';
import {
    applyDefaultParagraphDimensionsUpdateFromNewFontSize,
    updateParagraphDimensionsForFontSizeRatio,
    getParagraphDimensionsUpdate
} from './Paragraphs/ParagraphDimensionUpdate';
import {
    addParagraphWithStyle,
    addText,
    addTextWithStyle,
    getStyledParagraphsWithStyledRuns,
    removeText,
    setParagraphText,
    setParagraphTextWithStyle,
    setText,
    setTextWithStyle,
    getFullTextParagraphStyle,
    getFullTextRunStyle,
    getTextForStyles
} from './TextBodyProperties/TextWithStyles';
import sortByStartIndexes from './Utils/sortByStartIndexes';
import getStylesCount from './Utils/getStylesCount';
import { getRenderParagraphStyle, setComplementParagraphStyleAsParagraphStyle } from '../Style/paragraph';
import { getRenderRunStyle, setComplementRunStyleAsRunStyle, setRunStyleDefault } from '../Style/run';
import { addItem } from './TextBodyProperties/bullets';
import RunStyle from './Utils/RunStyle';
import formatFontFamily from '../../../utilities/formatFontFamily';
import omit from '../../utilities/omit';
import pick from '../../utilities/pick';
import omitBy from '../../utilities/omitBy';

const TextBody = Record({
    [TEXTBODY_PROPERTIES.ASSIGNED_STYLES]: Map({
        paragraph: Map(),
        run: Map(),
        textBody: Map({
            margins: fromJS(DEFAULT_TEXT_BODY_STYLE.get('margins'))
        })
    }),
    [TEXTBODY_PROPERTIES.PARAGRAPHS]: List([Map({ startIndex: 0 })]),
    [TEXTBODY_PROPERTIES.PARAGRAPH_STYLES]: List([DEFAULT_PARAGRAPHSTYLE.set('isDefault', true)]),
    [TEXTBODY_PROPERTIES.RUNS]: List(),
    [TEXTBODY_PROPERTIES.RUN_STYLES]: List([DEFAULT_RUNSTYLE.set('isDefault', true)]),
    [TEXTBODY_PROPERTIES.TEXT]: '',
    [TEXTBODY_PROPERTIES.AUTO_FIT_TEXT]: DEFAULT_TEXT_BODY_STYLE.get('autoFitText'),
    [TEXTBODY_PROPERTIES.AUTO_FIT_SHAPE]: DEFAULT_TEXT_BODY_STYLE.get('autoFitShape'),
    [TEXTBODY_PROPERTIES.TEXT_DIRECTION]: DEFAULT_TEXT_BODY_STYLE.get('textDirection'),
    [TEXTBODY_PROPERTIES.VERTICAL_ALIGN]: DEFAULT_TEXT_BODY_STYLE.get('verticalAlign'),
    [TEXTBODY_PROPERTIES.MARGINS]: Map({}),
    [TEXTBODY_PROPERTIES.PARENT_TYPE]: undefined,
    [TEXTBODY_PROPERTIES.PLACEHOLDER_BORDER_STROKE]: undefined,
    [TEXTBODY_PROPERTIES.IS_CELL_CONTENT]: undefined,
    [TEXTBODY_PROPERTIES.RUN_STYLE]: undefined,
    [TEXTBODY_PROPERTIES.PARAGRAPH_STYLE]: undefined,
    [TEXTBODY_PROPERTIES.BULLETS]: undefined,
    [TEXTBODY_PROPERTIES.STYLE]: ''
});

const reset = () => TextBody();

const addParagraphIfEmpty = (textBody, textBodyPlaceholder) => {
    let updatedTextBody = textBody;
    if (textBody && !getParagraphsCount(updatedTextBody)) {
        const defaultParagraphStyle = getDefaultParagraphStyle(textBodyPlaceholder);
        if ((defaultParagraphStyle.getIn(['bullet', 'type']) ?? 'none') !== 'none' &&
                getParagraphsCount(textBodyPlaceholder)) {
            const paragraphStyle = getParagraphsWithParagraphStyle(textBodyPlaceholder, 0, 0, true)
                .getIn([0, 'style']);
            updatedTextBody = addItem(updatedTextBody, '');
            updatedTextBody = setParagraphStyleProperties(updatedTextBody, paragraphStyle, 0, 0);
            const defaultRunStyle = getTextWithStyle(textBodyPlaceholder, 0, 0, true)
                .getIn([0, 'style']);
            updatedTextBody = setDefaultRunStyle(updatedTextBody, defaultRunStyle);
        } else {
            updatedTextBody = addParagraph(updatedTextBody, '');
        }
    }
    return updatedTextBody;
};

const removeParagraphs = (
    textBodyData,
    startIndex = 0,
    endIndex = getParagraphsCount(textBodyData) - 1
) => {
    const paragraphs = getParagraphs(textBodyData, startIndex, endIndex);

    if (paragraphs.size) {
        const firstParagraph = paragraphs.first();
        const lastParagraph = paragraphs.last();
        const removeEnd = getText(textBodyData)[
            lastParagraph.get('endIndex') + 1
        ] === '\n' ?
            lastParagraph.get('endIndex') + 1 :
            lastParagraph.get('endIndex');
        return removeText(textBodyData, firstParagraph.get('startIndex'), removeEnd);
    }
    return textBodyData;
};

const applyTextscriptUpdate = (runStyle, property, value) => {
    if (property === 'superscript') {
        return runStyle.set(property, value).set('subscript', false);
    }
    return runStyle.set(property, value).set('superscript', false);
};

const setDefaultParagraphAndRunStyles = (textBody, styleProperties) => {
    const runStyle = omitBy(styleProperties, (value, key) => key === 'paragraph');
    const paragraphStyle = styleProperties.get('paragraph');
    let updatedTextBody = setDefaultParagraphStyle(textBody, paragraphStyle);
    updatedTextBody = setDefaultRunStyle(updatedTextBody, runStyle);
    return updatedTextBody;
};

const getNewStyle = (newStyle, defaultStyle) => newStyle.filter((value, key) => {
    if (Map.isMap(defaultStyle.get(key))) {
        if (value.isSubset(defaultStyle.get(key))) {
            return false;
        }
        return true;
    }
    if (defaultStyle.get(key) !== value) {
        return true;
    }
    return false;
});

// TODO: refactor this to make it more understandable..
const setStyleProperties = (
    textBodyData,
    styleProperties,
    startIndex = 0,
    endIndex = getTextLength(textBodyData) - 1,
    dynamicType = 'none'
) => {
    let updatedTextBody = splitRunsAt(
        textBodyData,
        startIndex,
        endIndex
    );

    let runStylesInRange = getRunStylesAt(
        updatedTextBody,
        startIndex,
        endIndex
    );

    // TODO: clean this up... this seems to have to do with lists..
    if (styleProperties.getIn(['font', 'size']) && startIndex !== endIndex) {
        const paragraphs = !isNil(startIndex) ?
            getItemsForTextSelection(updatedTextBody, Map({ start: startIndex, end: endIndex })) :
            getParagraphs(updatedTextBody);
        paragraphs.forEach(paragraph => {
            const style = getParagraphStyle(updatedTextBody, paragraph.get('style'));
            if (style && style.get('bullet')) {
                updatedTextBody = setParagraphStyleProperties(
                    updatedTextBody,
                    Map({
                        bullet: Map({
                            style: Map({
                                size: styleProperties.getIn(['font', 'size'])
                            })
                        })
                    }),
                    paragraph.get('index'),
                    paragraph.get('index')
                );
            }
        });
    }

    let paragraphDimensionsUpdate = {};

    if (getTextLength(textBodyData) !== 0 && startIndex - 1 === endIndex && styleProperties.get('paragraph')) {
        const paragraphIndex = getParagraphIndexAtChar(
            updatedTextBody,
            startIndex
        );
        updatedTextBody = setParagraphStyleProperties(
            updatedTextBody,
            styleProperties.get('paragraph'),
            paragraphIndex,
            paragraphIndex
        );
    }

    // If we are at the end of text, add last runStyle to list
    if (endIndex === getTextLength(textBodyData) - 1) {
        runStylesInRange = getRunStylesAt(
            updatedTextBody,
            startIndex,
            endIndex + 1
        );
    }

    if (runStylesInRange.size === 0 || (
        [undefined, getTextLength(updatedTextBody) - 1].includes(endIndex) &&
        [undefined, 0].includes(startIndex)
    ) || getTextLength(updatedTextBody) === 0) {
        updatedTextBody = setDefaultParagraphAndRunStyles(
            updatedTextBody,
            omit(styleProperties, ['textSelection'])
        );
    }

    runStylesInRange.forEach(runStyle => {
        let newStyle = runStyle.get('style', Map({}));
        newStyle = newStyle.set('isDefault', false);
        styleProperties.keySeq().forEach(property => {
            if (property === 'font') {
                // TODO: handle cursor
                if (isCursorSelection(styleProperties.get('textSelection'))) {
                    newStyle = newStyle.update(
                        'cursorStyle',
                        (cursorStyle = Map()) => cursorStyle.set(
                            'font',
                            newStyle.get('font') ?
                                newStyle.get('font').merge(styleProperties.get('font')) :
                                styleProperties.get('font')
                        )
                    );
                } else {
                    newStyle = newStyle.set(
                        'font',
                        newStyle.get('font') ?
                            newStyle.get('font').merge(fromJS(styleProperties.get('font'))) :
                            styleProperties.get('font')
                    );

                    if (newStyle.get('cursorStyle')) {
                        newStyle = newStyle.update(
                            'cursorStyle',
                            cursorStyle => cursorStyle.set(
                                'font',
                                newStyle.get('font') ?
                                    newStyle.get('font').merge(styleProperties.get('font')) :
                                    styleProperties.get('font')
                            )
                        );
                    }
                }
            } else if (property === 'paragraph') {
                // FIX: copy pasted comment
                // patch because selections are broken
                // between text and paragraphs
                let offsetStart = 0;
                let offsetEnd = 0;
                if (endIndex !== getTextLength(updatedTextBody) - 1) {
                    const text = getText(updatedTextBody);
                    for (let i = 0; i < startIndex + offsetStart + 1; i++) {
                        if (text[i] === '\n') {
                            offsetStart += 1;
                        }
                    }
                    for (let i = 0; i < endIndex + offsetEnd + 1; i++) {
                        if (text[i] === '\n') {
                            offsetEnd += 1;
                        }
                    }
                }
                const firstIncludedParagraphIndex = getParagraphIndexAtChar(
                    updatedTextBody,
                    startIndex,
                    offsetStart
                );
                const lastIncludedParagraphIndex = getParagraphIndexAtChar(
                    updatedTextBody,
                    endIndex,
                    offsetStart
                );
                updatedTextBody = setParagraphStyleProperties(
                    updatedTextBody,
                    styleProperties.get('paragraph'),
                    firstIncludedParagraphIndex,
                    lastIncludedParagraphIndex
                );
            } else if (['superscript', 'subscript'].includes(property)) {
                const textScriptUpdate = applyTextscriptUpdate(newStyle, property, styleProperties.get(property));
                const cursorStyle = newStyle.get('cursorStyle', Map());
                if (isCursorSelection(styleProperties.get('textSelection'))) {
                    newStyle = newStyle.set('cursorStyle', cursorStyle.mergeDeep(textScriptUpdate));
                } else {
                    newStyle = newStyle.mergeDeep(textScriptUpdate);
                    newStyle = newStyle.set('cursorStyle', cursorStyle.mergeDeep(textScriptUpdate));
                }
            } else if (isCursorSelection(styleProperties.get('textSelection'))) {
                newStyle = newStyle.update(
                    'cursorStyle',
                    (cursorStyle = Map()) => cursorStyle
                        .set(property, fromJS(styleProperties.get(property)))
                );
            } else {
                newStyle = newStyle.set(property, styleProperties.get(property));

                if (newStyle.get('cursorStyle')) {
                    newStyle = newStyle.setIn(['cursorStyle', property], styleProperties.get(property));
                }
            }

            newStyle = getNewStyle(newStyle, getDefaultRunStyle(updatedTextBody));

            newStyle = newStyle.set('isDefault', false);

            let updatedStyleIndex = getRunStyleIndex(
                updatedTextBody,
                newStyle
            );

            if (updatedStyleIndex === -1) {
                updatedTextBody = addRunStyle(updatedTextBody, newStyle);
                updatedStyleIndex = getRunStylesCount(updatedTextBody) - 1;
            }

            let run = getRunsAt(
                updatedTextBody,
                runStyle.get('startIndex'),
                runStyle.get('endIndex')
            ).first();
            if (run) {
                run = run.delete('index');
                const runIndex = getRunIndex(updatedTextBody, run);
                if (dynamicType !== 'none') {
                    updatedTextBody = mergeRunStyle(
                        updatedTextBody,
                        runIndex,
                        {
                            dynamicType
                        }
                    );
                }
                if (styleProperties.hasIn(['font', 'size']) && styleProperties.keySeq().size === 1) {
                    paragraphDimensionsUpdate = getParagraphDimensionsUpdate(
                        updatedTextBody,
                        runIndex,
                        updatedTextBody,
                        paragraphDimensionsUpdate
                    );
                }
                updatedTextBody = setRunStyleIndexToRun(
                    updatedTextBody,
                    runIndex,
                    updatedStyleIndex
                );
            }
        });
    });

    updatedTextBody = Object.entries(paragraphDimensionsUpdate)
        .reduce(
            (data, [paragraphStyleIndex, ratio]) => updateParagraphDimensionsForFontSizeRatio(
                data,
                paragraphStyleIndex,
                ratio
            ),
            updatedTextBody
        );

    updatedTextBody = mergeRunsWithNewLineChar(updatedTextBody);

    updatedTextBody = removeUnusedRunStyles(
        mergeContiguousRunsWithSameStyle(updatedTextBody)
    );

    updatedTextBody = setTextBodyStyleProperties(updatedTextBody, styleProperties);

    return updatedTextBody;
};

const clearStyleProperties = (textBody, styleProperties, startIndex = 0, endIndex) => {
    const newStyleElements = styleProperties.reduce(
        (newElements, property) => newElements
            .set(property, null),
        Map()
    );
    return setStyleProperties(textBody, newStyleElements, startIndex, endIndex);
};

const getTextForStyleProperties = (textBodyData, styleProperties) => {
    const mappedStyleProperties = Map.isMap(styleProperties) ? styleProperties : fromJS(styleProperties);
    const properties = mappedStyleProperties.keySeq().toArray();
    const runStyleThatHaveStyleProperties = textBodyData
        .get(TEXTBODY_PROPERTIES.RUN_STYLES)
        .map((runStyle, index) => (runStyle.set('index', index)))
        .filter(runStyle => {
            if (runStyle.get('isDefault') === true) {
                return false;
            }
            const style = getRunStyle(textBodyData, runStyle.get('index'), true);
            const props = (properties.filter(key => {
                if (key === 'font') {
                    return mappedStyleProperties.get('font').every((value, propKey) => (
                        style.get('font') && style.getIn(['font', propKey]) === mappedStyleProperties.getIn(['font', propKey])
                    ));
                }
                return isEqual(mappedStyleProperties.get(key), style.get(key));
            }).length > 0);
            return props;
        })
        .map(runStyle => runStyle.get('index'));
    return textBodyData.get(TEXTBODY_PROPERTIES.RUNS)
        .filter(run => runStyleThatHaveStyleProperties.includes(run.get('style')))
        .map(run => run.set('text', getText(textBodyData, run.get('startIndex'), run.get('endIndex')))
            .set('style', getRunStyle(textBodyData, run.get('style'))));
};

const hasStyleProperties = (
    textBodyData,
    styleProperties,
    startIndex = 0,
    endIndex = getTextLength(textBodyData) - 1
) => {
    const runStyles = getRunStylesAt(textBodyData, startIndex, endIndex);
    const stylesWithDefaultsInRange = runStyles.length === 0 ? [
        getDefaultRunStyle(textBodyData)
    ] :
        fillHolesInRunsArray(textBodyData, runStyles)
            .map(run => (
                !run.get('style') ? run.set('style', getDefaultRunStyle(textBodyData)) :
                    run
            ))
            .filter(run => (
                getText(textBodyData, run.get('startIndex'), run.get('endIndex')) !== '\n' &&
                run.get('endIndex') >= startIndex &&
                run.get('startIndex') <= endIndex
            ))
            .map(runStyle => runStyle.get('style'));

    return stylesWithDefaultsInRange
        .reduce(
            (rangeCovered, style) => rangeCovered &&
            Object.keys(styleProperties)
                .reduce((propertyCovered, property) => {
                    if (property === 'font') {
                        return propertyCovered && Object.keys(styleProperties.font)
                            .reduce(
                                (fontCovered, fontProperty) => fontCovered &&
                                style.get('font') &&
                                (style.get('font').get(fontProperty) === styleProperties.font[fontProperty]),
                                propertyCovered
                            );
                    }
                    return propertyCovered && (
                        style.get(property) === styleProperties[property]
                    );
                }, rangeCovered),
            true
        );
};

const updateColorPresets = (textBodyData, { presets, scheme } = {}) => textBodyData
    .update(TEXTBODY_PROPERTIES.RUN_STYLES, runStyles => runStyles
        .map(runStyle => RunStyle.updateColorPresets(runStyle, { presets, scheme })));

const isCursorSelection = textSelection => !isNil(textSelection) && textSelection.get('start') === textSelection.get('end');

const isTextSelectionApplicable = (textBody, textSelection) => (
    textSelection.get('editing') === true &&
        textSelection.get('start') <= getTextLength(textBody) - 1 &&
        textSelection.get('end') <= getTextLength(textBody)
);

const hasBullet = textBody => {
    const defaultParagraphStyle = getDefaultParagraphStyle(textBody);
    return defaultParagraphStyle.getIn(['bullet', 'type'], 'none') !== 'none';
};

const getRunStyleFontSize = (textBody, runStyleToAssign) => runStyleToAssign.getIn(['font', 'size']) ||
    DEFAULT_RUNSTYLE.getIn(['font', 'family']);

const applyAssignedStyleOnBullet = (textBody, runStyleToAssign) => {
    const updatedTextBody = applyDefaultParagraphDimensionsUpdateFromNewFontSize(
        textBody,
        getRunStyleFontSize(textBody, runStyleToAssign)
    );
    return updatedTextBody;
};

const updateAssignedStyles = (
    shape,
    textBody,
    assignedStyles,
    removeCustomStyles,
    isEmphasisStyle
) => {
    let updatedTextBody = textBody;
    const styleToAssign = isEmphasisStyle ?
        textBody.get('assignedStyles').get('textBody').merge(assignedStyles.get('assignedStyle')) :
        assignedStyles.get('assignedStyle');
    const paragraphStyleToAssign = isEmphasisStyle ?
        textBody.get('assignedStyles').get('paragraph').merge(assignedStyles.get('assignedParagraphStyle')) :
        assignedStyles.get('assignedParagraphStyle');
    const runStyleToAssign = isEmphasisStyle ?
        formatFontFamily(
            textBody.get('assignedStyles').get('run').merge(assignedStyles.get('assignedRunStyle')),
            assignedStyles.get('assignedRunStyle')
        ) :
        assignedStyles.get('assignedRunStyle');

    if (paragraphStyleToAssign) {
        updatedTextBody = updatedTextBody.mergeDeepIn([TEXTBODY_PROPERTIES.ASSIGNED_STYLES, 'paragraph'], paragraphStyleToAssign);
        if (removeCustomStyles) {
            if (hasBullet(updatedTextBody)) {
                updatedTextBody = applyAssignedStyleOnBullet(
                    updatedTextBody,
                    assignedStyles.get('assignedRunStyle')
                );
            }
            updatedTextBody = setStyleProperties(
                updatedTextBody,
                Map({
                    paragraph: getRenderParagraphStyle(updatedTextBody, shape)
                })
            );
        }
    }
    if (runStyleToAssign) {
        updatedTextBody = updatedTextBody.setIn([TEXTBODY_PROPERTIES.ASSIGNED_STYLES, 'run'], runStyleToAssign);
        if (removeCustomStyles) {
            updatedTextBody = setStyleProperties(
                updatedTextBody,
                hasBullet(updatedTextBody) ?
                    omit(getRenderRunStyle(updatedTextBody, shape), ['font', 'size']) :
                    getRenderRunStyle(updatedTextBody, shape)
            );
            updatedTextBody = setRunStyleDefault(updatedTextBody);
        }
    }

    if (styleToAssign) {
        updatedTextBody = updatedTextBody.mergeDeepIn([TEXTBODY_PROPERTIES.ASSIGNED_STYLES, 'textBody'], styleToAssign);
        if (removeCustomStyles) {
            Object.keys(DEFAULT_TEXT_BODY_STYLE.toJS()).forEach(key => {
                updatedTextBody = updatedTextBody.delete(key);
            });
        }
    }
    return updatedTextBody;
};

const applyUpdate = (
    shape,
    textBody,
    updates,
    removeCustomStyles = false,
    isEmphasisStyle = false
) => {
    const assignedStyles = pick(
        updates,
        [
            'assignedStyle',
            'assignedParagraphStyle',
            'assignedRunStyle'
        ]
    );

    const textUpdate = omit(
        updates,
        [
            'assignedStyle',
            'assignedParagraphStyle',
            'assignedRunStyle'
        ]
    );

    let updatedTextBody = textBody;

    updatedTextBody = updateAssignedStyles(
        shape,
        updatedTextBody,
        assignedStyles,
        removeCustomStyles,
        isEmphasisStyle
    );

    if (textUpdate.get('listUpdate')) {
        updatedTextBody = applyListUpdate(updatedTextBody, textUpdate);
    } else if (
        textUpdate.getIn(['textSelection', 'editing']) === true &&
        textUpdate.getIn(['textSelection', 'start']) === textUpdate.getIn(['textSelection', 'end'])
    ) {
        updatedTextBody = setStyleProperties(
            updatedTextBody,
            textUpdate,
            textUpdate.getIn(['textSelection', 'start']),
            textUpdate.getIn(['textSelection', 'end'])
        );
    } else if (
        textUpdate.getIn(['textSelection', 'editing']) &&
        isTextSelectionApplicable(updatedTextBody, textUpdate.get('textSelection'))
    ) {
        updatedTextBody = setStyleProperties(
            updatedTextBody,
            textUpdate,
            textUpdate.getIn(['textSelection', 'start']),
            textUpdate.getIn(['textSelection', 'end']) - 1
        );
    } else if (textUpdate.getIn(['textSelection', 'editing']) !== true) {
        if (textUpdate.hasIn(['font', 'size']) && textUpdate.keySeq().size === 1) {
            updatedTextBody = applyDefaultParagraphDimensionsUpdateFromNewFontSize(
                updatedTextBody,
                textUpdate.getIn(['font', 'size'])
            );
        }
        updatedTextBody = setStyleProperties(updatedTextBody, textUpdate);
    }

    updatedTextBody = cleanUpParagraphStyles(updatedTextBody);
    updatedTextBody = cleanUpRunStyles(updatedTextBody);
    return updatedTextBody;
};

const applyTextBodyStructure = (textBody, sourceTextBody) => {
    let updatedTextBody = textBody;

    updatedTextBody = updatedTextBody.set('style', sourceTextBody.get('style'));
    updatedTextBody = updatedTextBody.set('assignedStyles', sourceTextBody.get('assignedStyles'));

    updatedTextBody = setDefaultParagraphStyle(
        updatedTextBody,
        getDefaultParagraphStyle(sourceTextBody)
    );

    updatedTextBody = updatedTextBody
        .setIn(
            ['assignedStyles', 'paragraph'],
            sourceTextBody
                .getIn(['assignedStyles', 'paragraph'])
        );

    updatedTextBody = setComplementParagraphStyleAsParagraphStyle(updatedTextBody);

    updatedTextBody = setDefaultRunStyle(
        updatedTextBody,
        getDefaultRunStyle(sourceTextBody)
    );

    updatedTextBody = updatedTextBody
        .setIn(
            ['assignedStyles', 'run'],
            sourceTextBody
                .getIn(['assignedStyles', 'run'])
        );

    updatedTextBody = setComplementRunStyleAsRunStyle(updatedTextBody);

    return updatedTextBody;
};

const Textbody = {
    TextBody,
    addParagraphIfEmpty,
    getItemsForTextSelection,
    convertParagraphsToListType,
    sortByStartIndexes,
    clearStyleProperties,
    hasText,
    updateMargins,
    getMargins,
    getTextDirection,
    getVerticalAlign,
    updateVerticalAlign,
    updateTextDirection,
    applyUpdate,
    addParagraph,
    addParagraphWithStyle,
    addText,
    addTextWithStyle,
    getStyledParagraphsWithStyledRuns,
    removeText,
    setParagraphText,
    setParagraphTextWithStyle,
    setText,
    setTextWithStyle,
    clearStyle,
    getDefaultParagraphStyle,
    getDefaultRunStyle,
    getFonts,
    getParagraph,
    getParagraphStyle,
    getFirstRunInParagraph,
    getParagraphStyles,
    getParagraphStylesCount,
    getParagraphs,
    getParagraphStyleAt,
    getParagraphsCount,
    getParagraphsWithIndex,
    getParagraphWithStyle,
    getParagraphsWithStyle,
    getRunDynamicType,
    getRunStyle,
    getRunStyles,
    getRunStylesCount,
    getRunsAt,
    getStylesCount,
    getText,
    getTextForStyleProperties,
    getTextForStyles,
    getTextLength,
    getTextWithStyle,
    hasStyleProperties,
    removeParagraphs,
    reset,
    setDefaultParagraphStyle,
    setDefaultRunStyle,
    setParagraphStyleProperties,
    setParagraphs,
    setStyle,
    setStyleProperties,
    getFullTextParagraphStyle,
    getFullTextRunStyle,
    updateColorPresets,
    getRunStylesAt,
    isEmpty,
    getDefaultRunStyleFromFirstRun,
    applyDefaultParagraphDimensionsUpdateFromNewFontSize,
    isTextSelectionApplicable,
    getParagraphsWithParagraphStyle,
    updateAssignedStyles,
    getAutoFitShape,
    getAutoFitText,
    applyTextBodyStructure
};

export default Textbody;
