import {
    updateShapes
} from '../../Helpers/helpers';
import { convertPropertiesToShapeProperties } from '../../utilities/convertPropertiesToShapeProperties';

const updateProperties = (canvasState, command, ids = null) => {
    const updates = convertPropertiesToShapeProperties(command.get('properties'));
    return updateShapes(canvasState, updates, ids);
};

export default updateProperties;
