exports.DEFAULT_SIZE = {
    width: 150,
    height: 150
};

exports.DEFAULT_POLYGON_PROPS = {
    sides: 6
};

exports.DEFAULT_TRAPEZOID_PROPS = {
    baseLengthRatio: 2 / 3
};

exports.DEFAULT_TRIANGLE_PROPS = {
    isRight: false
};

exports.DEFAULT_CHEVRON_PROPS = {
    tipWidth: 100,
    isFull: false
};

exports.DEFAULT_ARROW_PROPS = {
    headWidth: 50,
    tailHeight: 65,
    isDouble: false
};

exports.DEFAULT_WHITE_FILL = {
    type: 'color',
    color: {
        type: 'custom',
        value: 'rgba(255, 255, 255, 1)'
    }
};

exports.DEFAULT_TRANSPARENT_FILL = {
    type: 'color',
    color: {
        type: 'custom',
        value: 'rgba(255, 255, 255, 0)'
    }
};

exports.DEFAULT_CALLOUT_PROPS = {
    tailWidth: 60,
    tailLength: 100,
    tailAngle: 45,
    height: 100
};

exports.DEFAULT_LINE_PROPS = {
    coords: [
        { x: 0, y: 0 },
        { x: 100, y: 0 }
    ],
    connectionStrategy: 'straight',
    cap: 'flat',
    tail: {
        width: 'medium',
        type: 'none',
        length: 'medium'
    },
    head: {
        width: 'medium',
        type: 'none',
        length: 'medium'
    }
};
