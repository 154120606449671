const get = require('lodash/get');
const getFirstParagraphStyle = require('./getFirstParagraphStyle');

module.exports = {
    id: {
        itemUpdatePropertyName: 'styleId'
    },
    lineSpacing: {
        itemUpdatePropertyName: 'lineHeight',
        toStyle(canvasItem) {
            const paragraphStyle = getFirstParagraphStyle(canvasItem);
            return get(paragraphStyle, 'lineHeight');
        }
    },
    horizontalJustification: {
        itemUpdatePropertyName: 'textAlign',
        toStyle(canvasItem) {
            const paragraphStyle = getFirstParagraphStyle(canvasItem);
            return get(paragraphStyle, 'align');
        }
    },
    verticalJustification: {
        itemUpdatePropertyName: 'verticalAlign',
        toStyle(canvasItem) {
            return get(canvasItem, 'textBody.verticalAlign');
        }
    },
    topMargin: {
        itemUpdatePropertyName: 'margins.top',
        toStyle(canvasItem) {
            return get(canvasItem, 'textBody.margins.top');
        }
    },
    bottomMargin: {
        itemUpdatePropertyName: 'margins.bottom',
        toStyle(canvasItem) {
            return get(canvasItem, 'textBody.margins.bottom');
        }
    },
    leftMargin: {
        itemUpdatePropertyName: 'margins.left',
        toStyle(canvasItem) {
            return get(canvasItem, 'textBody.margins.left');
        }
    },
    rightMargin: {
        itemUpdatePropertyName: 'margins.right',
        toStyle(canvasItem) {
            return get(canvasItem, 'textBody.margins.right');
        }
    },
    spaceBefore: {
        itemUpdatePropertyName: 'spacing-top',
        toStyle(canvasItem) {
            const paragraphStyle = getFirstParagraphStyle(canvasItem);
            return get(paragraphStyle, 'padding.top');
        }
    },
    spaceAfter: {
        itemUpdatePropertyName: 'spacing-bottom',
        toStyle(canvasItem) {
            const paragraphStyle = getFirstParagraphStyle(canvasItem);
            return get(paragraphStyle, 'padding.bottom');
        }
    },
    leftIndent: {
        itemUpdatePropertyName: 'spacing-left',
        toStyle(canvasItem) {
            const paragraphStyle = getFirstParagraphStyle(canvasItem);
            return get(paragraphStyle, 'padding.left');
        }
    },
    rightIndent: {
        itemUpdatePropertyName: 'spacing-right',
        toStyle(canvasItem) {
            const paragraphStyle = getFirstParagraphStyle(canvasItem);
            return get(paragraphStyle, 'padding.right');
        }
    },
    direction: {
        itemUpdatePropertyName: 'textDirection',
        toStyle(canvasItem) {
            return get(canvasItem, 'textBody.textDirection');
        }
    }
};
