const shouldPrioritizeTextBodyPlaceholderProps = shape => !shape.getIn(['textBody', 'text']) &&
    shape.getIn(['textBodyPlaceholder', 'text']);

const getPrioritizedTextBody = shape => (shouldPrioritizeTextBodyPlaceholderProps(shape) ?
    shape.get('textBodyPlaceholder') :
    shape.get('textBody'));

export {
    shouldPrioritizeTextBodyPlaceholderProps,
    getPrioritizedTextBody
};
