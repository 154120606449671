const { fabric } = require('fabric');
const isNil = require('lodash/isNil');
const get = require('lodash/get');
const isEmpty = require('lodash/isEmpty');
const {
    FABRIC_FONT_DEFAULT_SIZE
} = require('../../constants/text');

const { subscript, superscript } = new fabric.Textbox('');

const setScript = ({
    size = 1,
    baseline = 0
} = {}, {
    fontSize,
    deltaY
}) => {
    const updatedStyles = {
        fontSize: fontSize * size,
        deltaY: deltaY + (fontSize * baseline)
    };
    return updatedStyles;
};

const setScripts = (fontSize = FABRIC_FONT_DEFAULT_SIZE, ...scripts) => {
    const updatedStyles = scripts.reduce((style, script) => setScript(script, style), {
        fontSize,
        deltaY: 0
    });
    return updatedStyles;
};

const unsetScript = ({
    size = 1
} = {}, fontSize) => fontSize / size;

const unsetScripts = (fontSize = FABRIC_FONT_DEFAULT_SIZE, ...scripts) => scripts
    .reduce((currentFontSize, script) => unsetScript(script, currentFontSize), fontSize);

const {
    adaptColorValueAdapterToFabricColor,
    adaptFabricColorToColorValueDescriptor
} = require('../ColorValueDescriptorAdapter');

exports.adaptRunStyleToFabricCharacterStyle = (runStyle = {}, colorPalette) => {
    const fabricCharacterStyle = {};
    if (!isNil(runStyle.color) && !isNil(runStyle.color.type)) {
        fabricCharacterStyle.fill = adaptColorValueAdapterToFabricColor(
            runStyle.color,
            colorPalette
        );

        fabricCharacterStyle.fillPreset = get(runStyle, 'color.preset', '');
    }
    if (!isNil(runStyle.hyperlink)) {
        fabricCharacterStyle.hyperlink = runStyle.hyperlink;
    }
    if (!isNil(runStyle.font)) {
        if (!isNil(runStyle.font.case)) {
            fabricCharacterStyle.fontCase = runStyle.font.case;
        }
        if (!isNil(runStyle.font.family)) {
            fabricCharacterStyle.fontFamily = runStyle.font.family.trim();
        }
        if (!isNil(runStyle.font.size)) {
            fabricCharacterStyle.fontSize = runStyle.font.size;
        }
        if (!isNil(runStyle.font.style)) {
            fabricCharacterStyle.fontStyle = runStyle.font.style;
        }
        if (!isNil(runStyle.font.weight)) {
            fabricCharacterStyle.fontWeight = runStyle.font.weight;
        }
    }
    if (!isNil(runStyle.overline)) {
        fabricCharacterStyle.overline = runStyle.overline;
    }
    if (!isNil(runStyle.underline)) {
        fabricCharacterStyle.underline = runStyle.underline;
    }
    if (!isNil(runStyle.linethrough)) {
        fabricCharacterStyle.linethrough = runStyle.linethrough;
    }
    if (!isNil(runStyle.opacity)) {
        fabricCharacterStyle.opacity = runStyle.opacity;
    }
    if (!isNil(runStyle.characterSpacing)) {
        fabricCharacterStyle.charSpacing = runStyle.characterSpacing;
    }
    if (!isNil(runStyle.textTransform)) {
        fabricCharacterStyle.textTransform = runStyle.textTransform;
    }
    const scripts = [];
    if (!isNil(runStyle.subscript)) {
        fabricCharacterStyle.subscript = runStyle.subscript;
        if (runStyle.subscript === true) {
            scripts.push(subscript);
        }
    }
    if (!isNil(runStyle.superscript)) {
        fabricCharacterStyle.superscript = runStyle.superscript;
        if (runStyle.superscript === true) {
            scripts.push(superscript);
        }
    }

    if (!isNil(runStyle.textSelection)) {
        fabricCharacterStyle.textSelection = runStyle.textSelection;
    }

    if (!isEmpty(runStyle.cursorStyle)) {
        fabricCharacterStyle.cursorStyle = exports.adaptRunStyleToFabricCharacterStyle(runStyle.cursorStyle);
    }

    const scriptsStyles = scripts.length > 0 ?
        setScripts(fabricCharacterStyle.fontSize, ...scripts) :
        {};
    return {
        ...fabricCharacterStyle,
        ...scriptsStyles
    };
};

exports.adaptFabricFontStyleToRunFontStyle = (fabricCharacterStyle, scripts) => {
    const font = {};
    if (fabricCharacterStyle.fontCase) {
        font.case = fabricCharacterStyle.fontCase;
    }
    if (!isNil(fabricCharacterStyle.fontFamily)) {
        font.family = fabricCharacterStyle.fontFamily;
    }
    font.size = unsetScripts(fabricCharacterStyle.fontSize, ...scripts);
    if (fabricCharacterStyle.fontStyle) {
        font.style = fabricCharacterStyle.fontStyle;
    }
    if (fabricCharacterStyle.fontWeight) {
        font.weight = fabricCharacterStyle.fontWeight;
    }
    return font;
};

exports.adaptFabricCharacterStyleToRunStyle = (fabricCharacterStyle = {}, colorPalette) => {
    const runStyle = {};
    if (fabricCharacterStyle.fill) {
        runStyle.color = adaptFabricColorToColorValueDescriptor(
            fabricCharacterStyle.fill,
            colorPalette
        );
        runStyle.color.preset = fabricCharacterStyle.fillPreset || '';
    }
    if (!isNil(fabricCharacterStyle.hyperlink)) {
        runStyle.hyperlink = fabricCharacterStyle.hyperlink;
    }
    const scripts = [];
    if (fabricCharacterStyle.subscript) {
        scripts.push(subscript);
        runStyle.subscript = true;
    }
    if (fabricCharacterStyle.superscript) {
        scripts.push(superscript);
        runStyle.superscript = true;
    }
    if (
        fabricCharacterStyle.fontCase ||
        fabricCharacterStyle.fontFamily ||
        !isNil(fabricCharacterStyle.fontSize) ||
        fabricCharacterStyle.fontStyle ||
        fabricCharacterStyle.fontWeight ||
        scripts.length > 0
    ) {
        runStyle.font = exports.adaptFabricFontStyleToRunFontStyle(fabricCharacterStyle, scripts);
    }
    if (!isNil(fabricCharacterStyle.overline)) {
        runStyle.overline = fabricCharacterStyle.overline;
    }
    if (!isNil(fabricCharacterStyle.underline)) {
        runStyle.underline = fabricCharacterStyle.underline;
    }
    if (!isNil(fabricCharacterStyle.linethrough)) {
        runStyle.linethrough = fabricCharacterStyle.linethrough;
    }
    if (!isNil(fabricCharacterStyle.opacity)) {
        runStyle.opacity = fabricCharacterStyle.opacity;
    }
    if (!isNil(fabricCharacterStyle.charSpacing)) {
        runStyle.characterSpacing = fabricCharacterStyle.charSpacing;
    }
    if (!isNil(fabricCharacterStyle.textTransform)) {
        runStyle.textTransform = fabricCharacterStyle.textTransform;
    }
    if (!isNil(fabricCharacterStyle.textSelection)) {
        runStyle.textSelection = fabricCharacterStyle.textSelection;
    }
    if (!isNil(fabricCharacterStyle.cursorStyle)) {
        runStyle.cursorStyle = exports.adaptFabricCharacterStyleToRunStyle(fabricCharacterStyle.cursorStyle);
    }

    return runStyle;
};
