const {
    SELECTION_COMMANDS
} = require('./types');

const History = {
    getHistoryDiff({ previous, next }) {
        return next.get('history').slice(previous.get('history').size, next.get('history').size);
    },

    getCommandTypesInHistory(history) {
        return history.map(command => command.get('type'));
    },

    isOnlySelectionUpdate({ previous, next }) {
        const diff = History.getHistoryDiff({ previous, next });
        const types = History.getCommandTypesInHistory(diff);
        if (types.size > 0) {
            return types.every(commandType => SELECTION_COMMANDS.includes(commandType));
        }
        return false;
    }
};

module.exports = History;
