import throttle from 'lodash/throttle';
import debounce from 'lodash/debounce';

export const throttleAction = (action, wait, options) => {
    const throttled = throttle(
        (dispatch, args) => dispatch(action(...args)),
        wait,
        options
    );

    const wrappedAction = (...args) => dispatch => throttled(dispatch, args);

    wrappedAction.cancel = throttled.cancel;
    wrappedAction.flush = throttled.flush;

    return wrappedAction;
};

export const debounceAction = (action, wait, options) => {
    const debounced = debounce(
        (dispatch, args) => dispatch(action(...args)),
        wait,
        options
    );

    const wrappedAction = (...args) => dispatch => debounced(dispatch, args);

    wrappedAction.cancel = debounced.cancel;
    wrappedAction.flush = debounced.flush;

    return wrappedAction;
};
