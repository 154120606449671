import CanvasStateSelectors from '../../../Canvas/CanvasStateSelectors';
import {
    updateFullShapes
} from '../../Helpers/helpers';
import getPropertiesForDestructuring from '../../utilities/getPropertiesForDestructuring';

const isPageAlign = shapes => shapes.size === 1;

const getShapesCoordinates = shapes => shapes.map(shape => ({
    left: shape.get('x') - (shape.get('width') / 2),
    right: shape.get('x') + (shape.get('width') / 2),
    top: shape.get('y') - (shape.get('height') / 2),
    bottom: shape.get('y') + (shape.get('height') / 2)
}));

const getExtremums = (shapesCoordinate, pageSize) => {
    let extremums = shapesCoordinate.reduce((previous, currentItem) => ({
        left: currentItem.left < previous.left ? currentItem.left : previous.left,
        right: currentItem.right > previous.right ? currentItem.right : previous.right,
        top: currentItem.top < previous.top ? currentItem.top : previous.top,
        bottom: currentItem.bottom > previous.bottom ? currentItem.bottom : previous.bottom
    }), {
        left: Infinity,
        right: -Infinity,
        top: Infinity,
        bottom: -Infinity
    });

    if (isPageAlign(shapesCoordinate)) {
        extremums = {
            left: 0,
            right: pageSize.get('width'),
            top: 0,
            bottom: pageSize.get('height')
        };
    }
    return extremums;
};

const align = (canvasState, command) => {
    const { position } = getPropertiesForDestructuring(command, ['position']);
    const shapes = CanvasStateSelectors.getSelectedCanvasItems(canvasState);
    const shapesCoordinate = getShapesCoordinates(shapes);

    const extremums = getExtremums(shapesCoordinate, canvasState.get('size'));

    const updatedShapes = shapes.map((shape, index) => {
        if (position === 'left') {
            if (shapesCoordinate.get(index).left > extremums.left) {
                return shape.set('x', extremums.left + (shape.get('width') / 2));
            }
            return shape;
        } else if (position === 'right') {
            if (shapesCoordinate.get(index).right < extremums.right) {
                return shape.set('x', extremums.right - (shape.get('width') / 2));
            }
            return shape;
        } else if (position === 'top') {
            if (shapesCoordinate.get(index).top > extremums.top) {
                return shape.set('y', extremums.top + (shape.get('height') / 2));
            }
            return shape;
        } else if (position === 'bottom') {
            if (shapesCoordinate.get(index).bottom < extremums.bottom) {
                return shape.set('y', extremums.bottom - (shape.get('height') / 2));
            }
            return shape;
        } else if (position === 'center') {
            if (isPageAlign(shapes)) {
                return shape.set('x', extremums.right / 2);
            }
            return shape.set('x', extremums.left + ((extremums.right - extremums.left) / 2));
        } else if (position === 'verticalCenter') {
            if (isPageAlign(shapes)) {
                return shape.set('y', extremums.bottom / 2);
            }
            return shape.set('y', extremums.top + ((extremums.bottom - extremums.top) / 2));
        }
        throw new Error(`${position} align is not supported.`);
    });
    return updateFullShapes(canvasState, updatedShapes);
};

export default align;
