import { fabric } from 'fabric';
import { isEmpty, isNil } from 'lodash';
import { FABRIC_FONT_DEFAULT_SIZE } from '../../../../fabric-adapter/constants/text';
import adaptColorValueAdapterToFabricColor from './colorValueDescriptorAdapter';

const { subscript, superscript } = new fabric.Textbox('');

const adaptRunStyleToFabricCharacterStyle = (runStyle = Map(), colorPalette) => {
    const fabricCharacterStyle = {};
    if (!isNil(runStyle.get('color')) && !isNil(runStyle.getIn(['color', 'type']))) {
        fabricCharacterStyle.fill = adaptColorValueAdapterToFabricColor(
            runStyle.get('color'),
            colorPalette
        );

        fabricCharacterStyle.fillPreset = runStyle.getIn(['color', 'preset'], '');
    }
    if (!isNil(runStyle.get('hyperlink'))) {
        fabricCharacterStyle.hyperlink = runStyle.get('hyperlink');
    }
    if (!isNil(runStyle.get('font'))) {
        if (!isNil(runStyle.getIn(['font', 'case']))) {
            fabricCharacterStyle.fontCase = runStyle.getIn(['font', 'case']);
        }
        if (!isNil(runStyle.getIn(['font', 'family']))) {
            fabricCharacterStyle.fontFamily = runStyle.getIn(['font', 'family']).trim();
        }
        if (!isNil(runStyle.getIn(['font', 'size']))) {
            fabricCharacterStyle.fontSize = runStyle.getIn(['font', 'size']);
        }
        if (!isNil(runStyle.getIn(['font', 'style']))) {
            fabricCharacterStyle.fontStyle = runStyle.getIn(['font', 'style']);
        }
        if (!isNil(runStyle.getIn(['font', 'weight']))) {
            fabricCharacterStyle.fontWeight = runStyle.getIn(['font', 'weight']);
        }
    }
    if (!isNil(runStyle.get('overline'))) {
        fabricCharacterStyle.overline = runStyle.get('overline');
    }
    if (!isNil(runStyle.get('underline'))) {
        fabricCharacterStyle.underline = runStyle.get('underline');
    }
    if (!isNil(runStyle.get('linethrough'))) {
        fabricCharacterStyle.linethrough = runStyle.get('linethrough');
    }
    if (!isNil(runStyle.get('opacity'))) {
        fabricCharacterStyle.opacity = runStyle.get('opacity');
    }
    if (!isNil(runStyle.get('characterSpacing'))) {
        fabricCharacterStyle.charSpacing = runStyle.get('characterSpacing');
    }
    if (!isNil(runStyle.get('textTransform'))) {
        fabricCharacterStyle.textTransform = runStyle.get('textTransform');
    }
    const scripts = [];
    if (!isNil(runStyle.get('subscript'))) {
        fabricCharacterStyle.subscript = runStyle.get('subscript');
        if (runStyle.get('subscript')) {
            scripts.push(subscript);
        }
    }
    if (!isNil(runStyle.get('superscript'))) {
        fabricCharacterStyle.superscript = runStyle.get('superscript');
        if (runStyle.get('superscript')) {
            scripts.push(superscript);
        }
    }

    if (!isNil(runStyle.get('textSelection'))) {
        fabricCharacterStyle.textSelection = runStyle.get('textSelection').toJS();
    }

    if (!isEmpty(runStyle.get('cursorStyle'))) {
        fabricCharacterStyle.cursorStyle = adaptRunStyleToFabricCharacterStyle(runStyle.get('cursorStyle'));
    }

    const scriptsStyles = scripts.length > 0 ?
        setScripts(fabricCharacterStyle.fontSize, ...scripts) :
        {};
    return {
        ...fabricCharacterStyle,
        ...scriptsStyles
    };
};

const setScript = ({
    size = 1,
    baseline = 0
} = {}, {
    fontSize,
    deltaY
}) => {
    const updatedStyles = {
        fontSize: fontSize * size,
        deltaY: deltaY + (fontSize * baseline)
    };
    return updatedStyles;
};

const setScripts = (fontSize = FABRIC_FONT_DEFAULT_SIZE, ...scripts) => {
    const updatedStyles = scripts.reduce((style, script) => setScript(script, style), {
        fontSize,
        deltaY: 0
    });
    return updatedStyles;
};

export {
    // eslint-disable-next-line import/prefer-default-export
    adaptRunStyleToFabricCharacterStyle
};
