module.exports = {
    level1ItemPreset: {
        isReference: true,
        referenceUpdateParent: 'level1'
    },
    level2ItemPreset: {
        isReference: true,
        referenceUpdateParent: 'level2'
    },
    level3ItemPreset: {
        isReference: true,
        referenceUpdateParent: 'level3'
    },
    level4ItemPreset: {
        isReference: true,
        referenceUpdateParent: 'level4'
    },
    level5ItemPreset: {
        isReference: true,
        referenceUpdateParent: 'level5'
    }
};
