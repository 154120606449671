const { fabric } = require('fabric');
const { omit } = require('lodash');

const Bullet = fabric.util.createClass(fabric.Textbox, {
    type: 'bullet',

    initialize(bulletText = '•', options) {
        const text = `${bulletText}`;
        this.callSuper('initialize', text, {
            ...omit(options, [
                'linethrough',
                'underline'
            ]),
            type: 'bullet',
            textAlign: 'left',
            strokeWidth: 0,
            objectCaching: false
        });
        if (this.textLines.length > 1) {
            this.set('text', this.getText());
        }
    },

    overrideOpacity(isEditingInParagraph, hasText) {
        if (hasText) {
            this.set({
                opacity: 1
            });
        } else if (isEditingInParagraph && !hasText) {
            this.set({
                opacity: 0.5
            });
        } else {
            this.set({
                opacity: 0
            });
        }
        this.set({
            dirty: true
        });
    },

    toObject() {
        return {
            ...this.callSuper('toObject'),
            text: this.getText()
        };
    },

    getText() {
        return this.get('text').trim();
    },

    getStyle() {
        return {
            fill: this.get('fill'),
            font: this.get('fontFamily'),
            size: this.get('fontSize'),
            opacity: this.get('opacity')
        };
    }
});

module.exports = Bullet;
