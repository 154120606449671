import { fromJS } from 'immutable';
import {
    updateShape
} from '../../Helpers/helpers';
import getPropertiesForDestructuring from '../../utilities/getPropertiesForDestructuring';
import CanvasStateSelectors from '../../../Canvas/CanvasStateSelectors';

const flipShapes = (canvasState, command) => {
    const {
        flipX,
        flipY
    } = getPropertiesForDestructuring(
        command,
        [
            'flipX',
            'flipY'
        ]
    );

    const shapes = CanvasStateSelectors.getSelectedCanvasItems(canvasState);

    return shapes.reduce((currentCanvasState, shape) => {
        const update = fromJS({
            shapeProps: {
                flipX: flipX ? !shape.get('flipX') : shape.get('flipX'),
                flipY: flipY ? !shape.get('flipY') : shape.get('flipY')
            }
        });

        return updateShape(currentCanvasState, shape.get('id'), update);
    }, canvasState);
};

export default flipShapes;
