import { fillEmptySerialized } from '../../../../../CanvasState/Helpers/Fill';
import { dashEmptySerialized } from '../../../../../CanvasState/Helpers/Stroke';
import { getRenderStroke } from '../../../../../CanvasState/Helpers/Style/stroke';

const removeStrokeFillIfEmpty = shape => {
    if (shape.getIn(['stroke', 'fill']) && shape.getIn(['stroke', 'fill']).equals(fillEmptySerialized)) {
        return shape.removeIn(['stroke', 'fill']);
    }

    return shape;
};

const removeDashIfEmpty = shape => {
    if (shape.getIn(['stroke', 'dash']) && shape.getIn(['stroke', 'dash']).equals(dashEmptySerialized)) {
        return shape.removeIn(['stroke', 'dash']);
    }

    return shape;
};

const getStroke = (shape, colorPalette) => getRenderStroke(
    removeStrokeFillIfEmpty(
        removeDashIfEmpty(
            shape
        )
    ),
    colorPalette
);

export default getStroke;
