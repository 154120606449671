import { camelCase } from 'lodash';
import defaultFabricAttributes from '../../Config/defaultFabricAttributes';
import defaultPropertyAdapters from '../PropertyAdapters/default';

const fromShapeToFabricJSON = (shape, canvasState) => ({
    ...defaultFabricAttributes,
    shapeType: defaultPropertyAdapters.shapeType(shape),
    type: camelCase(defaultPropertyAdapters.type(shape)),
    id: defaultPropertyAdapters.id(shape),
    name: defaultPropertyAdapters.name(shape),
    width: defaultPropertyAdapters.width(shape),
    height: defaultPropertyAdapters.height(shape),
    top: defaultPropertyAdapters.y(shape),
    left: defaultPropertyAdapters.x(shape),
    style: defaultPropertyAdapters.style(shape) || '',
    angle: defaultPropertyAdapters.rotation(shape),
    anchors: defaultPropertyAdapters.anchors(shape),
    charSpacing: defaultPropertyAdapters.charSpacing(shape),
    inLayout: defaultPropertyAdapters.inLayout(shape),
    scaleX: defaultPropertyAdapters.scaleX(shape),
    scaleY: defaultPropertyAdapters.scaleY(shape),
    lockPath: defaultPropertyAdapters.lockPath(shape),
    flipX: defaultPropertyAdapters.flipX(shape),
    flipY: defaultPropertyAdapters.flipY(shape),
    isBackground: defaultPropertyAdapters.isBackground(shape),
    isHidden: defaultPropertyAdapters.isHidden(shape),
    isImported: defaultPropertyAdapters.isImported(shape),
    isLocked: defaultPropertyAdapters.isLocked(shape),
    skewX: defaultPropertyAdapters.skewX(shape),
    skewY: defaultPropertyAdapters.skewY(shape),
    hoverCursor: defaultPropertyAdapters.hoverCursor(shape),
    displayPlaceholder: defaultPropertyAdapters.displayPlaceholder(shape),
    dynamicProperties: defaultPropertyAdapters.dynamicProperties(shape),
    lockUniScaling: defaultPropertyAdapters.lockAspectRatio(shape),
    fill: defaultPropertyAdapters.fill(shape),
    opacity: defaultPropertyAdapters.opacity(shape),
    stroke: defaultPropertyAdapters.stroke(shape, canvasState.get('colorPalette')),
    selectable: defaultPropertyAdapters.selectable(shape),
    visible: defaultPropertyAdapters.visible(shape),
    selected: defaultPropertyAdapters.selected(shape),
    paragraphs: defaultPropertyAdapters.paragraphs(shape, canvasState.get('colorPalette')),
    placeholderParagraphs: defaultPropertyAdapters.placeholderParagraphs(shape, canvasState.get('colorPalette')),
    autoFitText: defaultPropertyAdapters.autoFitText(shape),
    autoFitShape: defaultPropertyAdapters.autoFitShape(shape),
    textDirection: defaultPropertyAdapters.textDirection(shape),
    verticalAlign: defaultPropertyAdapters.verticalAlign(shape),
    textBodyMargins: defaultPropertyAdapters.textBodyMargins(shape),
    isTextEmpty: defaultPropertyAdapters.isTextEmpty(shape),
    placeholderSequence: defaultPropertyAdapters.placeholderSequence(shape),
    placeholderSourceId: defaultPropertyAdapters.placeholderSourceId(shape),
    placeholderType: defaultPropertyAdapters.placeholderType(shape)
});

export default fromShapeToFabricJSON;
