import { combineReducers } from 'redux';
import * as types from '#Constants/ActionTypes';
import billing from './billing';
import invoices from './invoices';
import invites from './invites';

const fetching = (state = false, action) => {
    switch (action.type) {
        case types.FETCHING_USER:
            return true;
        case types.FETCHED_USER:
            return false;
        default:
            return state;
    }
};

const firstFetch = (state = true, action) => {
    switch (action.type) {
        case types.FETCHED_USER:
        case types.SET_USER:
            return false;
        case types.LOGGED_OUT:
            return true;
        default:
            return state;
    }
};

const profile = (state = {}, action) => {
    switch (action.type) {
        case types.SET_USER:
            return action.user;
        case types.SET_ACTIVE_SECTION:
            return {
                ...state,
                activeSection: action.section
            };
        case types.UPDATE_PASSWORD_ERROR:
            return {
                ...state,
                passwordError: action.error
            };
        case types.UPDATE_PASSWORD_SUCCESS:
            return {
                ...state,
                passwordError: ''
            };
        case types.UPDATE_NAME:
            return {
                ...state,
                nameUpdating: true
            };
        case types.UPDATE_OCCUPATION:
            return {
                ...state,
                occupationUpdating: true
            };
        case types.UPDATE_NAME_DONE:
            return {
                ...state,
                nameUpdating: false,
                name: action.name
            };

        case types.UPDATE_OCCUPATION_DONE:
            return {
                ...state,
                occupationUpdating: false,
                occupation: action.occupation
            };
        case types.UPDATE_EMAIL:
            return {
                ...state,
                emailUpdating: true
            };
        case types.UPDATE_EMAIL_DONE:
            return {
                ...state,
                emailUpdating: false,
                email: action.email,
                emailError: action.error
            };
        case types.LOGGED_OUT:
            return {};
        case types.WELCOME_OPTION_CHANGED:
            return {
                ...state,
                showWelcomeScreen: action.updatedOption

            };
        case types.CONFIRMATION_SUCCESS:
            return {

                ...state,
                verified: true
            };
        case types.CONFIRMATION_ERROR:
            return {

                ...state,
                verified: false
            };
        default:
            return state;
    }
};

export default combineReducers({
    fetching,
    firstFetch,
    profile,
    billing,
    invoices,
    invites
});
