const { Record, List } = require('immutable');
const isEqual = require('lodash/isEqual');
const get = require('lodash/get');
const has = require('lodash/has');
const omit = require('lodash/omit');
const isNil = require('lodash/isNil');
const cloneDeep = require('lodash/cloneDeep');
const omitBy = require('lodash/omitBy');
const update = require('lodash/update');

const RunStyle = require('./RunStyle');
const complementAgainstSources = require('../../utilities/complementAgainstSources');
const defaultsDeepWithExceptions = require('../../utilities/defaultsDeepWithExceptions');

const PARAGRAPHS = 'paragraphs';
const PARAGRAPH_STYLES = 'paragraphStyles';
const RUNS = 'runs';
const RUN_STYLES = 'runStyles';
const TEXT = 'text';
const AUTO_FIT_TEXT = 'autoFitText';
const AUTO_FIT_SHAPE = 'autoFitShape';
const intersectObjects = require('../../utilities/intersectObjects');

const TextBodyData = Record({
    [PARAGRAPHS]: List([{ startIndex: 0 }]),
    [PARAGRAPH_STYLES]: List(),
    [RUNS]: List(),
    [RUN_STYLES]: List(),
    [TEXT]: '',
    [AUTO_FIT_TEXT]: false,
    [AUTO_FIT_SHAPE]: false
});

const styleDeepExceptions = ['color', 'bullet.style.color'];

const defaultsDeep = (...styles) => defaultsDeepWithExceptions(...styles, styleDeepExceptions);

const intersectStyles = (...styles) => intersectObjects(...styles, styleDeepExceptions);

const cleanUpStyles = styles => {
    const defaultIndex = styles.findIndex(style => style.isDefault === true);
    const defaultStyle = defaultIndex === -1 ? {} : styles[defaultIndex];
    const newDefault = defaultsDeep(
        {
            isDefault: true
        },
        intersectStyles(...[
            ...styles
                .filter((style, index) => index !== defaultIndex)
                .map(style => defaultsDeep(cloneDeep(style), defaultStyle)),
            defaultStyle
        ]),
        defaultStyle
    );
    const cleanedStyles = styles
        .map((style, index) => (defaultIndex === index ?
            newDefault :
            complementAgainstSources(style, newDefault, styleDeepExceptions)));
    return cleanedStyles;
};

const omitNil = data => omitBy(data, isNil);

const getTextLength = textBodyData => textBodyData.get(TEXT).length;

const reset = () => TextBodyData();

const getParagraphsCount = textBodyData => textBodyData.get(PARAGRAPHS).size;

const getParagraphStylesCount = textBodyData => textBodyData
    .get(PARAGRAPH_STYLES).size;

const getFonts = textBodyData => {
    const fontSet = new Set([
        ...textBodyData.get(RUN_STYLES)
            .filter(style => has(style, 'font.family'))
            .map(style => style.font.family)
    ]);
    const defaultRunStyle = getDefaultRunStyle(textBodyData);
    if (has(defaultRunStyle, 'font.family')) {
        return fontSet.add(
            defaultRunStyle.font.family
        );
    }
    return fontSet;
};

const getText = (
    textBodyData,
    startIndex = 0,
    endIndex = getTextLength(textBodyData)
) => textBodyData
    .get(TEXT)
    .substring(startIndex, endIndex + 1);

const addRuns = (textBodyData, ...runs) => runs.reduce(
    (updatedTextBody, run) => updatedTextBody
        .update(RUNS, updatedRuns => updatedRuns.push(run)),
    textBodyData
);

const addRunStyle = (textBodyData, runStyle) => textBodyData
    .update(RUN_STYLES, runStyles => runStyles.push(runStyle));

const getRunStylesCount = textBodyData => textBodyData.get(RUN_STYLES).size;

const sortByStartIndexes = toSort => List(toSort
    .sortBy(item => item.startIndex));

const sortParagraphs = textBodyData => {
    const sortedParagraphs = sortByStartIndexes(textBodyData.get(PARAGRAPHS));

    if (sortedParagraphs.size === 0) {
        return List();
    }

    const lastParagraph = sortedParagraphs.last();
    const lastParagraphEndIndex = getTextLength(textBodyData) - 1;
    const updatedLast = {
        ...lastParagraph,
        endIndex: lastParagraphEndIndex,
        text: getText(
            textBodyData,
            lastParagraph.startIndex,
            lastParagraphEndIndex
        )
    };

    return sortedParagraphs
        .slice(0, -1)
        .map((paragraph, index) => {
            // as indexes are inclusives and we're computing our endIndex based
            // on the next paragraphs start we need to offset it by 2
            const PARAGRAPHS_OFFSET = 2;
            const endIndex = Math.max(
                sortedParagraphs.get(index + 1).startIndex - PARAGRAPHS_OFFSET,
                -1
            );
            return {
                ...paragraph,
                endIndex,
                text: getText(textBodyData, paragraph.startIndex, endIndex)
            };
        })
        .push(updatedLast);
};

const addParagraphStyle = (textBodyData, paragraphStyle) => textBodyData.update(
    PARAGRAPH_STYLES,
    paragraphStyles => paragraphStyles.push(paragraphStyle)
);

const getParagraphs = (textBodyData, start = 0, endIndex) => sortParagraphs(
    textBodyData
).slice(
    start,
    endIndex === undefined ? undefined : endIndex + 1
);

const getParagraphsWithIndex = (textBodyData, startIndex = 0, endIndex) => getParagraphs(
    textBodyData,
    startIndex,
    endIndex
)
    .map((paragraph, index) => ({
        ...paragraph,
        index: index + startIndex
    }));

const getParagraphIndexAtChar = (textBodyData, charIndex = 0) => {
    const numOfEmptyParagraphs = getParagraphs(textBodyData).reduce((total, paragraph) => {
        if (paragraph.startIndex <= charIndex) {
            if (paragraph.endIndex === -1) {
                return total + 1;
            }
        }
        return total;
    }, 0);
    const index = getParagraphs(textBodyData)
        .findIndex(paragraph => (
            (paragraph.endIndex + numOfEmptyParagraphs + 1) >= charIndex &&
            (
                (paragraph.startIndex + numOfEmptyParagraphs) <= charIndex ||
                paragraph.startIndex - 1 === paragraph.endIndex
            )
        ));

    if (index === -1) {
        throw new RangeError('Text selection should be in text range.');
    }
    return index;
};

const getRunIndex = (textBodyData, run) => {
    const isEqualToRun = r => isEqual(run, r);
    return textBodyData
        .get(RUNS)
        .findIndex(isEqualToRun);
};

const getRunStyleIndex = (textBodyData, runStyle) => textBodyData
    .get(RUN_STYLES)
    .findIndex(cursor => isEqual(omit(runStyle, ['isDefault']), omit(cursor, ['isDefault'])));

const getRunsAt = (
    textBodyData,
    startIndex = 0,
    endIndex = getTextLength(textBodyData)
) => textBodyData
    .get(RUNS)
    .map((run, index) => ({
        ...run,
        index
    }))
    .filter(run => run.endIndex >= startIndex && run.startIndex <= endIndex);

const getRunStyle = (
    textBodyData,
    index,
    withDefault = false
) => {
    const style = textBodyData.getIn([RUN_STYLES, index]);
    return withDefault ?
        defaultsDeep(
            cloneDeep(style),
            getDefaultRunStyle(textBodyData)
        ) :
        cloneDeep(style);
};

const getRun = (textBodyData, index) => textBodyData.getIn([RUNS, index]);

const getRunStylesAt = (textBodyData, startIndex, endIndex, withDefault) => getRunsAt(
    textBodyData,
    startIndex,
    endIndex
)
    .map(run => {
        if (run.style > textBodyData.get(RUN_STYLES).size) {
            throw new RangeError(
                `cannot get style ${
                    run.style
                } run style: ${
                    textBodyData.get(RUN_STYLES).toJS()
                }`
            );
        }
        const style = getRunStyle(textBodyData, run.style, withDefault);
        return {
            ...run,
            style
        };
    });

const getRunStyles = (textBodyData, withDefault) => (
    getRunStylesAt(textBodyData, 0, getTextLength(textBodyData), withDefault)
        .map(run => run.style)
);

const getRunIndexAtPosition = (textBodyData, position) => textBodyData
    .get(RUNS)
    .findIndex(run => run.endIndex >= position && run.startIndex <= position);

const fillHolesInRunsArray = (textBodyData, runs, withDefault) => {
    if (runs.size === 0) {
        return runs;
    }

    const sortedRuns = sortByStartIndexes(runs);

    let filledRuns = sortedRuns
        .slice(1)
        .reduce((pastState, current) => {
            let acc = pastState;
            const previous = acc.last();
            if (current.startIndex - 1 > previous.endIndex) {
                const startIndex = previous.endIndex + 1;
                const endIndex = current.startIndex - 1;
                acc = acc.push({
                    startIndex,
                    endIndex,
                    text: getText(textBodyData, startIndex, endIndex),
                    style: null
                });
            }
            return acc.push(current);
        }, sortedRuns.slice(0, 1));

    const firstRun = filledRuns.first();
    if (firstRun.startIndex > 0) {
        const startIndex = 0;
        const endIndex = firstRun.startIndex - 1;
        filledRuns = filledRuns.unshift({
            startIndex: 0,
            endIndex: firstRun.startIndex - 1,
            text: getText(textBodyData, startIndex, endIndex),
            style: withDefault ? getDefaultRunStyle(textBodyData) : null
        });
    }

    const lastRun = filledRuns.last();
    if (lastRun.endIndex < getTextLength(textBodyData)) {
        const startIndex = lastRun.endIndex + 1;
        const endIndex = getTextLength(textBodyData) - 1;
        filledRuns = filledRuns.push({
            startIndex,
            endIndex,
            text: getText(textBodyData, startIndex, endIndex),
            style: withDefault ? getDefaultRunStyle(textBodyData) : null
        });
    }

    return filledRuns;
};

const getRunDynamicType = run => get(run, 'dynamicType', 'none');

const getParagraphStartinginRun = (textBodyData, runIndex) => {
    const run = getRun(textBodyData, runIndex);
    return getParagraphsWithIndex(
        textBodyData,
        getParagraphIndexAtChar(textBodyData, run.startIndex),
        getParagraphIndexAtChar(textBodyData, run.endIndex)
    )
        .filter(paragraph => paragraph.startIndex >= run.startIndex);
};

const isParagraphStyleListType = (textBodyData, paragraphStyleIndex) => {
    const paragraphStyle = getParagraphStyle(textBodyData, paragraphStyleIndex, true);
    return get(paragraphStyle, 'bullet.type', 'none') !== 'none';
};

const getParagraphDimensionsUpdate = (
    textBodyData,
    runIndex,
    runStyleIndex,
    paragraphDimensionsUpdate
) => {
    const paragraphsStartingInRun = getParagraphStartinginRun(textBodyData, runIndex);

    const newFontSize = get(
        getRunStyle(textBodyData, runStyleIndex, true),
        'font.size'
    );

    if (!newFontSize) {
        return paragraphDimensionsUpdate;
    }

    return paragraphsStartingInRun
        .filter(paragraphStartingInRun => isParagraphStyleListType(
            textBodyData,
            paragraphStartingInRun.style
        ))
        .reduce(
            (currentUpdate, paragraphStartingInRun) => {
                const previousFontSize = get(
                    getFirstRunInParagraph(
                        textBodyData,
                        paragraphStartingInRun.index
                    ),
                    'style.font.size'
                );

                const paragraphStyleIndex = paragraphStartingInRun.style;

                if ([previousFontSize, newFontSize, paragraphStyleIndex].some(isNil)) {
                    return paragraphDimensionsUpdate;
                }

                return {
                    ...currentUpdate,
                    [paragraphStyleIndex]: newFontSize / previousFontSize
                };
            },
            paragraphDimensionsUpdate
        );
};

const setRunStyleIndexToRun = (textBodyData, runIndex, runStyleIndex) => mergeRunStyle(
    textBodyData,
    runIndex,
    {
        style: runStyleIndex
    }
);

const mergeRunStyle = (textBodyData, index, runUpdate) => textBodyData.updateIn(
    [RUNS, index],
    originalRun => ({
        ...originalRun,
        ...runUpdate
    })
);

const areRunStylesOverlapping = (run1, run2) => run1.endIndex + 1 === run2.startIndex &&
    run1.style === run2.style &&
    getRunDynamicType(run1) === 'none' &&
    getRunDynamicType(run2) === 'none';

const mergeContiguousRunsWithSameStyle = textBodyData => {
    const sortedRuns = sortByStartIndexes(textBodyData.get(RUNS));
    const mergedRuns = sortedRuns.slice(1).reduce((acc, currentRun) => {
        const previousRun = acc.last();
        if (areRunStylesOverlapping(previousRun, currentRun)) {
            return acc.set(-1, {
                ...previousRun,
                endIndex: currentRun.endIndex
            });
        }
        return acc.push(currentRun);
    }, sortedRuns.slice(0, 1));
    return textBodyData.set(RUNS, mergedRuns);
};

const removeRuns = (textBodyData, ...runIndexes) => textBodyData
    .update(
        RUNS,
        runs => runs.filter((_, index) => !runIndexes.includes(index))
    );

const removeStyle = (textBodyData, styleIndex, stylesKey, removeFromKey) => textBodyData
    .update(
        removeFromKey,
        removeFromElements => removeFromElements
            .map(element => {
                if (!isNil(element.style) && element.style >= styleIndex) {
                    return omitNil({
                        ...element,
                        style: element.style - 1
                    });
                }
                return element;
            })
    )
    .update(
        stylesKey,
        styles => styles
            .filter((_, index) => index !== styleIndex)
    );

const isStyleInUse = (style, usedIn) => {
    const used = usedIn.some(element => element.style === style.index);
    const empty = Object.keys(omitNil(style)).length === 0;

    return (used && !empty) || get(style, 'style.isDefault');
};

const isStyleUnused = (style, usedIn) => !isStyleInUse(style, usedIn);

const unusedStyleIndexes = (styles, usedIn) => styles
    .map((style, index) => ({
        style,
        index
    }))
    .filter(style => isStyleUnused(style, usedIn));

const removeUnusedStyles = (textBodyData, stylesKey, usedInKey) => unusedStyleIndexes(
    textBodyData.get(stylesKey),
    textBodyData.get(usedInKey)
)
    .sort()
    .reverse()
    .reduce((updatedTextBodyData, { index }) => removeStyle(
        updatedTextBodyData,
        index,
        stylesKey,
        usedInKey
    ), textBodyData);

const removeUnusedParagraphStyles = textBodyData => removeUnusedStyles(textBodyData, PARAGRAPH_STYLES, PARAGRAPHS);

const removeUnusedRunStyles = textBodyData => removeUnusedStyles(textBodyData, RUN_STYLES, RUNS);

const sortRuns = textBodyData => textBodyData
    .update(RUNS, runs => sortByStartIndexes(runs));

const splitRunAt = (textBodyData, runIndex, textIndex) => {
    const runAtIndex = getRun(textBodyData, runIndex);

    const firstRun = {
        ...runAtIndex,
        endIndex: textIndex - 1
    };

    const lastRun = {
        ...runAtIndex,
        startIndex: textIndex
    };

    return addRuns(
        removeRuns(textBodyData, runIndex),
        firstRun,
        lastRun
    );
};

const shouldMergeLoneNewLineCharWithRun = (
    previousRun,
    previousRunText
) => previousRun.startIndex === previousRun.endIndex &&
    previousRunText === '\n';

const mergeRunsWithNewLineChar = textBodyData => {
    const sortedRuns = sortByStartIndexes(textBodyData.get(RUNS));
    const mergedRuns = sortedRuns.slice(1).reduce((acc, currentRun) => {
        const previousRun = acc.last();
        if (shouldMergeLoneNewLineCharWithRun(previousRun, getText(textBodyData)[previousRun.startIndex])) {
            return acc.set(-1, {
                ...currentRun,
                startIndex: previousRun.endIndex
            });
        }
        return acc.push(currentRun);
    }, sortedRuns.slice(0, 1));
    return textBodyData.set(RUNS, mergedRuns);
};

const splitRunsAt = (textBodyData, startIndex, endIndex) => {
    let updatedTextBody = sortRuns(textBodyData);
    let startRunIndex = getRunIndexAtPosition(updatedTextBody, startIndex);
    const endRunIndex = getRunIndexAtPosition(updatedTextBody, endIndex);
    const sortedRuns = updatedTextBody.get(RUNS);

    if (startRunIndex === -1 && endRunIndex === -1) {
        const runsCompletelyInsideRange = sortedRuns
            .filter(run => run.startIndex >= startIndex &&
                run.endIndex <= endIndex);

        if (!runsCompletelyInsideRange.size) {
            return addRuns(updatedTextBody, {
                startIndex,
                endIndex
            });
        }
        return addRuns(
            textBodyData,
            {
                startIndex,
                endIndex: runsCompletelyInsideRange.first().startIndex - 1
            },
            {
                startIndex: runsCompletelyInsideRange.last().endIndex + 1,
                endIndex
            }
        );
    }
    if (endRunIndex !== -1) {
        const endRun = getRun(updatedTextBody, endRunIndex);
        const firstRunInRange = sortedRuns.find(run => run.startIndex > startIndex);
        if (startRunIndex === -1 && !isNil(firstRunInRange)) {
            updatedTextBody = addRuns(updatedTextBody, {
                startIndex,
                endIndex: firstRunInRange.startIndex - 1
            });
        }
        if (endRun.endIndex > endIndex) {
            updatedTextBody = splitRunAt(
                updatedTextBody,
                endRunIndex,
                endIndex + 1
            );
        }
    }
    updatedTextBody = sortRuns(updatedTextBody);
    startRunIndex = getRunIndexAtPosition(updatedTextBody, startIndex);
    const startRun = getRun(updatedTextBody, startRunIndex);

    if (endRunIndex === -1) {
        const lastRunOfRange = updatedTextBody.get(RUNS)
            .findLast(run => run?.endIndex < endIndex);

        if (lastRunOfRange) {
            updatedTextBody = addRuns(updatedTextBody, {
                startIndex: lastRunOfRange.endIndex + 1,
                endIndex
            });
        }
    }

    if (startRun && startRun.startIndex < startIndex) {
        updatedTextBody = splitRunAt(
            updatedTextBody,
            startRunIndex,
            startIndex
        );
    }

    return updatedTextBody;
};

const getParagraphStyleAt = (textBodyData, index) => textBodyData
    .getIn([PARAGRAPH_STYLES, index]);

const getParagraphStyles = (textBodyData, withDefault) => List(
    textBodyData
        .get(PARAGRAPHS)
        .reduce(
            (acc, { style }) => (isNil(style) && acc) || acc.add(style),
            new Set()
        )
)
    .map(i => getParagraphStyle(textBodyData, i, withDefault));

const getParagraphStyle = (textBodyData, index, withDefault = false) => {
    const style = getParagraphStyleAt(textBodyData, index);
    return withDefault ?
        defaultsDeep(
            {},
            style,
            getDefaultParagraphStyle(textBodyData)
        ) :
        style;
};

const getFirstRunInParagraph = (textBodyData, paragraphIndex, withDefault) => getParagraphWithStyle(
    textBodyData,
    paragraphIndex,
    withDefault
)
    .textRuns[0];

const getDefaultRunStyleIndex = textBodyData => {
    const index = textBodyData
        .get(RUN_STYLES)
        .findIndex(style => style.isDefault);
    if (index === -1) {
        return textBodyData.get(RUN_STYLES).size;
    }
    return index;
};

const getDefaultRunStyle = textBodyData => ({
    ...cloneDeep(textBodyData.getIn([
        RUN_STYLES,
        getDefaultRunStyleIndex(textBodyData)
    ]))
});

const updateParagraphDimensionsForFontSizeRatio = (
    textBodyData,
    paragraphStyleIndex,
    fontSizeRatio
) => textBodyData.updateIn(
    [PARAGRAPH_STYLES, paragraphStyleIndex],
    paragraphStyle => {
        ['bullet.style.size', 'indent', 'padding.left']
            .filter(dimensionKey => has(paragraphStyle, dimensionKey))
            .forEach(dimensionKey => update(
                paragraphStyle,
                dimensionKey,
                dimension => Number.parseFloat((dimension * fontSizeRatio).toPrecision(2))
            ));
        return paragraphStyle;
    }
);

const setDefaultRunStyle = (textBodyData, newDefault) => textBodyData
    .update(
        RUN_STYLES,
        (runStyles = {}) => {
            let mutableRunStyles = runStyles.toJS();
            const defaultIndex = mutableRunStyles
                .findIndex(style => style.isDefault === true);
            const defaultStyle = defaultIndex === -1 ? {} : mutableRunStyles[defaultIndex];

            const updatedDefault = defaultsDeep(
                {
                    isDefault: true
                },
                newDefault,
                cloneDeep(defaultStyle)
            );
            mutableRunStyles = mutableRunStyles.map((style, index) => {
                if (index === defaultIndex) {
                    return updatedDefault;
                }
                return complementAgainstSources(
                    cloneDeep(style),
                    updatedDefault,
                    styleDeepExceptions
                );
            });
            if (defaultIndex === -1) {
                mutableRunStyles.push(updatedDefault);
            }
            return List(mutableRunStyles);
        }
    );

const getDefaultParagraphStyleIndex = textBodyData => {
    const index = textBodyData
        .get(PARAGRAPH_STYLES)
        .findIndex(style => style.isDefault);
    if (index === -1) {
        return textBodyData.get(PARAGRAPH_STYLES).size;
    }
    return index;
};

const getDefaultParagraphStyle = textBodyData => ({
    ...cloneDeep(textBodyData.getIn([
        PARAGRAPH_STYLES,
        getDefaultParagraphStyleIndex(textBodyData)
    ]))
});

const setDefaultParagraphStyle = (textBodyData, newDefault) => textBodyData
    .update(
        PARAGRAPH_STYLES,
        (paragraphStyles = {}) => {
            let mutableParagraphStyles = paragraphStyles.toJS();
            const defaultIndex = mutableParagraphStyles
                .findIndex(style => style.isDefault === true);
            const defaultStyle = defaultIndex === -1 ? {} : mutableParagraphStyles[defaultIndex];
            const updatedDefault = defaultsDeep(
                {
                    isDefault: true
                },
                newDefault,
                cloneDeep(defaultStyle)
            );
            mutableParagraphStyles = mutableParagraphStyles.map((style, index) => {
                if (index === defaultIndex) {
                    return updatedDefault;
                }
                return complementAgainstSources(
                    cloneDeep(style),
                    updatedDefault,
                    styleDeepExceptions
                );
            });
            if (defaultIndex === -1) {
                mutableParagraphStyles.push(updatedDefault);
            }
            return List(mutableParagraphStyles);
        }
    );

const updateParagraphs = (
    textBodyData,
    startIndex,
    originalTextLength,
    newTextLength
) => {
    const offset = newTextLength - originalTextLength;
    const text = getText(textBodyData);
    return textBodyData.update(
        PARAGRAPHS,
        originalParagraphs => originalParagraphs
            .map(paragraph => ({
                ...paragraph,
                startIndex: paragraph.startIndex > startIndex ?
                    paragraph.startIndex + offset :
                    paragraph.startIndex
            }))
            .filter((paragraph, index, offsettedParagraph) => (
                paragraph.startIndex <= newTextLength
            ) && (
                paragraph.startIndex === 0 ||
                    text[paragraph.startIndex - 1] === '\n'
            ) && (
                offsettedParagraph
                    .findIndex(
                        p => p.startIndex === paragraph.startIndex
                    ) === index
            ))
    );
};

const updateRuns = (
    textBodyData,
    startIndex,
    endIndex,
    originalTextLength,
    newTextLength
) => {
    const offset = newTextLength - originalTextLength;
    return textBodyData.update(RUNS, runs => runs
        .filter(run => run.startIndex <= startIndex || run.endIndex >= endIndex)
        .map(run => {
            if (run.endIndex >= startIndex && run.startIndex <= endIndex) {
                return {
                    ...run,
                    endIndex: run.endIndex + offset
                };
            } if (run.startIndex > endIndex) {
                return {
                    ...run,
                    startIndex: run.startIndex + offset,
                    endIndex: run.endIndex + offset
                };
            }
            return run;
        })
        .filter(run => run.startIndex <= run.endIndex));
};

const getEndIndex = (textBodyData, text, startIndex) => Math.min(
    getTextLength(textBodyData) - 1,
    startIndex + (text.length - 1)
);

const getParagraphStyleIndex = (textBodyData, query) => textBodyData
    .get(PARAGRAPH_STYLES)
    .findIndex(paragraphStyle => isEqual(omit(query, ['isDefault']), omit(paragraphStyle, ['isDefault'])));

const setParagraphStyle = (textBodyData, paragraphIndex, style) => textBodyData.updateIn(
    [PARAGRAPHS, paragraphIndex],
    paragraph => omitNil({
        ...paragraph,
        style
    })
);

const serializeParagraphStyles = textBodyData => textBodyData.get(PARAGRAPH_STYLES).toJS();

const serializeRunStyles = textBodyData => textBodyData.get(RUN_STYLES).toJS();

const updateStyleRanges = (
    textBodyData,
    startIndex,
    endIndex,
    originalLength
) => {
    // Update paragraphs
    let updatedTextBody = updateParagraphs(
        textBodyData,
        startIndex,
        originalLength,
        getTextLength(textBodyData)
    );

    // Update runs
    updatedTextBody = updateRuns(
        updatedTextBody,
        startIndex,
        endIndex,
        originalLength,
        getTextLength(updatedTextBody)
    );

    return removeUnusedParagraphStyles(
        removeUnusedRunStyles(updatedTextBody)
    );
};

const addText = (textBodyData, text, index = getTextLength(textBodyData)) => {
    const originalTextLength = getTextLength(textBodyData);
    const updatedTextBody = textBodyData.update(
        TEXT,
        t => t.substr(0, index) + text + t.substr(index)
    );
    return updateStyleRanges(
        updatedTextBody,
        index,
        index,
        originalTextLength
    );
};

const isEmpty = textBodyData => textBodyData.get('text').length === 0;

const getDefaultRunStyleFromFirstRun = textBodyData => (isEmpty(textBodyData) ?
    getDefaultRunStyle(textBodyData) :
    getRunStylesAt(textBodyData, 0, 0, true).get(0, {}).style);

const setDefaultStyleFromFullText = textBodyData => (!isEmpty(textBodyData) ?
    setDefaultParagraphStyle(
        setDefaultRunStyle(
            textBodyData,
            getDefaultRunStyleFromFirstRun(textBodyData)
        ),
        getStyledParagraphsWithStyledRuns(textBodyData, true).get(0).style
    ) :
    textBodyData);

const setText = (
    textBodyData,
    text,
    startIndex = 0,
    endIndex = getEndIndex(textBodyData, text, startIndex)
) => {
    let updatedTextBodyData = textBodyData;
    const originalText = getText(updatedTextBodyData);
    if (startIndex < 0 || endIndex > originalText.length) {
        throw new RangeError('Invalid range for setText');
    }
    if (startIndex === 0 && endIndex === -1) {
        if (text.length === 0) {
            updatedTextBodyData = setDefaultStyleFromFullText(updatedTextBodyData);
        }
        return updatedTextBodyData
            .set(TEXT, text)
            .set(PARAGRAPHS, List([{ startIndex: 0 }]))
            .set(RUNS, List());
    }

    return updateStyleRanges(
        updatedTextBodyData.update(
            TEXT,
            t => t.substr(0, startIndex) + text + t.substr(endIndex + 1)
        ),
        startIndex,
        endIndex,
        originalText.length
    );
};

const getStylesCount = textBodyData => getRunStylesCount(textBodyData) + getParagraphStylesCount(textBodyData);

const setStyle = (
    textBodyData,
    style,
    startIndex = 0,
    endIndex = getTextLength(textBodyData) - 1
) => {
    if (endIndex < startIndex) return textBodyData;
    if (startIndex < 0) {
        throw new Error('Cannot set style on unexistant text');
    }
    let updatedTextBodyData = splitRunsAt(
        textBodyData,
        startIndex,
        Math.min(endIndex, getTextLength(textBodyData) - 1)
    );

    if (style !== null) {
        let runStyleIndex = getRunStyleIndex(updatedTextBodyData, style);
        if (runStyleIndex === -1) {
            updatedTextBodyData = addRunStyle(updatedTextBodyData, style);
            runStyleIndex = getRunStylesCount(updatedTextBodyData) - 1;
        }
        updatedTextBodyData = updatedTextBodyData.update(
            RUNS,
            runs => runs.map(run => {
                if (run.endIndex >= startIndex && run.startIndex <= endIndex) {
                    return {
                        ...run,
                        style: runStyleIndex
                    };
                }
                return run;
            })
        );
        updatedTextBodyData = mergeRunsWithNewLineChar(updatedTextBodyData);
        updatedTextBodyData = mergeContiguousRunsWithSameStyle(
            updatedTextBodyData
        );
    } else {
        const runsInRange = getRunsAt(updatedTextBodyData, startIndex, endIndex);
        updatedTextBodyData = removeRuns(
            updatedTextBodyData,
            ...runsInRange.map(run => run.index)
        );
    }
    return removeUnusedRunStyles(updatedTextBodyData);
};

const getTextWithStyle = (
    textBodyData,
    startIndex = 0,
    endIndex = getTextLength(textBodyData) - 1,
    withDefault
) => {
    let runs = sortByStartIndexes(textBodyData.get(RUNS))
        .map(run => ({
            startIndex: Math.max(run.startIndex, startIndex),
            endIndex: Math.min(run.endIndex, endIndex),
            text: getText(textBodyData, run.startIndex, run.endIndex),
            style: getRunStyle(textBodyData, run.style, withDefault)
        }));
    if (runs.size) {
        runs = fillHolesInRunsArray(textBodyData, runs, withDefault);
    } else {
        runs = List([{
            startIndex,
            endIndex,
            text: getText(textBodyData, startIndex, endIndex),
            style: null
        }]);
    }
    return runs
        .filter(run => run.endIndex >= startIndex && run.startIndex <= endIndex)
        .toJS();
};

const setTextWithStyle = (
    textBodyData,
    text,
    style,
    startIndex = 0,
    endIndex = getEndIndex(textBodyData, text, startIndex)
) => setStyle(
    setText(textBodyData, text, startIndex, endIndex),
    style,
    startIndex,
    startIndex + (text.length - 1)
);

const getParagraphsWithStyle = (
    textBodyData,
    startIndex = 0,
    endIndex = getParagraphsCount(textBodyData) - 1,
    withDefault
) => getParagraphs(textBodyData, startIndex, endIndex)
    .map(paragraph => ({
        ...paragraph,
        textRuns: getTextWithStyle(
            textBodyData,
            paragraph.startIndex,
            paragraph.endIndex,
            withDefault
        )
    }));

const getParagraphsWithParagraphStyle = (
    textBodyData,
    startIndex = 0,
    endIndex = getParagraphsCount(textBodyData) - 1,
    withDefault
) => getParagraphs(textBodyData, startIndex, endIndex)
    .map(({
        style: styleIndex,
        ...paragraph
    }) => {
        let style = {};
        if (!isNil(styleIndex)) {
            style = getParagraphStyle(textBodyData, styleIndex, withDefault);
        } else if (withDefault) {
            style = getDefaultParagraphStyle(textBodyData);
        }
        return {
            ...paragraph,
            style
        };
    });

const getParagraphWithStyle = (textBodyData, index, withDefault) => getParagraphsWithStyle(
    textBodyData,
    index,
    index,
    withDefault
)
    .first();

const getStyledParagraphsWithStyledRuns = (textBodyData, withDefault = false) => getParagraphsWithStyle(textBodyData)
    .map(paragraph => ({
        ...paragraph,
        style: getParagraphStyle(
            textBodyData,
            paragraph.style,
            withDefault
        )
    }));

const getParagraph = (textBodyData, index) => getParagraphs(textBodyData, index, index).first();

const setParagraphText = (textBodyData, index, text = '') => {
    const paragraph = getParagraph(textBodyData, index);
    return setText(
        textBodyData,
        text,
        paragraph.startIndex,
        paragraph.endIndex
    );
};

const setParagraphTextWithStyle = (textBodyData, index, text, style) => {
    let paragraph = getParagraph(textBodyData, index);
    if (!paragraph) throw new Error('Cannot set unexistant paragraph');

    const newTextBodyData = setParagraphText(textBodyData, index, text);
    paragraph = getParagraph(newTextBodyData, index);
    return setStyle(
        newTextBodyData,
        style,
        paragraph.startIndex,
        paragraph.endIndex
    );
};

const setParagraphs = (textBodyData, paragraphs) => textBodyData
    .set(PARAGRAPHS, List(paragraphs));

const setParagraphStyleProperties = (
    textBodyData,
    properties,
    startIndex = 0,
    endIndex = getParagraphsCount(textBodyData) - 1
) => {
    const updatedTextBody = getParagraphsWithParagraphStyle(textBodyData, startIndex, endIndex)
        .reduce((reducedTextBody, paragraph, index) => {
            let textBody = reducedTextBody;
            const object = defaultsDeep(
                {
                    ...properties,
                    isDefault: false
                },
                paragraph.style || {}
            );
            const defaultParagraphStyle = getDefaultParagraphStyle(textBody);
            const newParagraphStyle = complementAgainstSources(
                object,
                defaultParagraphStyle,
                styleDeepExceptions
            );

            let newParagraphStyleIndex = getParagraphStyleIndex(
                textBody,
                newParagraphStyle
            );

            if (newParagraphStyleIndex === -1) {
                newParagraphStyleIndex = getParagraphStylesCount(textBody);
                textBody = addParagraphStyle(textBody, newParagraphStyle);
            }
            return setParagraphStyle(
                textBody,
                startIndex + index,
                newParagraphStyleIndex
            );
        }, textBodyData);

    return removeUnusedParagraphStyles(updatedTextBody);
};

const addParagraph = (textBodyData, text = '') => {
    let originalText = getText(textBodyData);
    if (originalText.length > 0) {
        originalText += '\n';
    }

    return textBodyData
        .set(TEXT, `${originalText}${text}`)
        .update(PARAGRAPHS, paragraphs => paragraphs.push({
            startIndex: originalText.length
        }));
};

const addParagraphWithStyle = (textBodyData, text = '', style) => {
    const updatedTextBody = addParagraph(textBodyData, text);
    const newParagraphIndex = getParagraphsCount(updatedTextBody) - 1;
    const paragraph = getParagraph(updatedTextBody, newParagraphIndex);
    return setStyle(
        updatedTextBody,
        style,
        paragraph.startIndex,
        paragraph.endIndex
    );
};

const removeText = (textBodyData, startIndex, endIndex) => setText(textBodyData, '', startIndex, endIndex);

const removeParagraphs = (
    textBodyData,
    startIndex = 0,
    endIndex = getParagraphsCount(textBodyData) - 1
) => {
    const paragraphs = getParagraphs(textBodyData, startIndex, endIndex);

    if (paragraphs.size) {
        const firstParagraph = paragraphs.first();
        const lastParagraph = paragraphs.last();
        const removeEnd = getText(textBodyData)[
            lastParagraph.endIndex + 1
        ] === '\n' ?
            lastParagraph.endIndex + 1 :
            lastParagraph.endIndex;
        return removeText(textBodyData, firstParagraph.startIndex, removeEnd);
    }
    return textBodyData;
};

const applyTextscriptUpdate = (runStyle, property, value) => ({
    ...runStyle,
    superscript: (property === 'superscript' && value === true),
    subscript: (property === 'subscript' && value === true)
});

const setStyleProperties = (
    textBodyData,
    styleProperties,
    startIndex = 0,
    endIndex = getTextLength(textBodyData) - 1,
    dynamicType = 'none'
) => {
    let updatedTextBody = splitRunsAt(
        textBodyData,
        startIndex,
        endIndex
    );

    let runStylesInRange = getRunStylesAt(
        updatedTextBody,
        startIndex,
        endIndex
    );

    let paragraphDimensionsUpdate = {};

    if (getTextLength(textBodyData) !== 0 && startIndex - 1 === endIndex && styleProperties.paragraph) {
        const paragraphIndex = getParagraphIndexAtChar(
            updatedTextBody,
            startIndex
        );
        updatedTextBody = setParagraphStyleProperties(
            updatedTextBody,
            styleProperties.paragraph,
            paragraphIndex,
            paragraphIndex
        );
    }

    // If we are at the end of text, add last runStyle to list
    if (endIndex === getTextLength(textBodyData) - 1) {
        runStylesInRange = getRunStylesAt(
            updatedTextBody,
            startIndex,
            endIndex + 1
        );
    }

    runStylesInRange.forEach(runStyle => {
        let newStyle = runStyle.style ?
            { ...runStyle.style } :
            {};
        newStyle.isDefault = false;
        Object.keys(styleProperties).forEach(property => {
            if (property === 'font') {
                if (isCursorSelection(styleProperties.textSelection)) {
                    newStyle = {
                        ...newStyle,
                        cursorStyle: {
                            ...newStyle.cursorStyle,
                            font: {
                                ...newStyle.font,
                                ...styleProperties.font
                            }
                        }
                    };
                } else {
                    newStyle = {
                        ...newStyle,
                        font: {
                            ...newStyle.font,
                            ...styleProperties.font
                        }
                    };

                    if (newStyle.cursorStyle) {
                        newStyle.cursorStyle = {
                            ...newStyle.cursorStyle,
                            font: {
                                ...newStyle.font,
                                ...styleProperties.font
                            }
                        };
                    }
                }
            } else if (property === 'paragraph') {
                // FIX: copy pasted comment
                // patch because selections are broken
                // between text and paragraphs
                let offsetStart = 0;
                let offsetEnd = 0;
                if (endIndex !== getTextLength(updatedTextBody) - 1) {
                    const text = getText(updatedTextBody);
                    for (let i = 0; i < startIndex + offsetStart + 1; i++) {
                        if (text[i] === '\n') {
                            offsetStart += 1;
                        }
                    }
                    for (let i = 0; i < endIndex + offsetEnd + 1; i++) {
                        if (text[i] === '\n') {
                            offsetEnd += 1;
                        }
                    }
                }
                const firstIncludedParagraph = getParagraphIndexAtChar(
                    updatedTextBody,
                    startIndex,
                    offsetStart
                );
                const lastIncludedParagraph = getParagraphIndexAtChar(
                    updatedTextBody,
                    endIndex,
                    offsetStart
                );
                updatedTextBody = setParagraphStyleProperties(
                    updatedTextBody,
                    styleProperties.paragraph,
                    firstIncludedParagraph,
                    lastIncludedParagraph
                );
            } else if (['superscript', 'subscript'].includes(property)) {
                if (isCursorSelection(styleProperties.textSelection)) {
                    newStyle = {
                        ...newStyle,
                        cursorStyle: {
                            ...newStyle.cursorStyle,
                            ...applyTextscriptUpdate(newStyle, property, styleProperties[property])
                        }
                    };
                } else {
                    newStyle = applyTextscriptUpdate(newStyle, property, styleProperties[property]);

                    if (newStyle.cursorStyle) {
                        newStyle.cursorStyle = {
                            ...newStyle.cursorStyle,
                            ...applyTextscriptUpdate(newStyle, property, styleProperties[property])
                        };
                    }
                }
            } else {
                // eslint-disable-next-line no-lonely-if
                if (isCursorSelection(styleProperties.textSelection)) {
                    newStyle = {
                        ...newStyle,
                        cursorStyle: {
                            ...newStyle.cursorStyle,
                            [property]: styleProperties[property]
                        }
                    };
                } else {
                    newStyle = {
                        ...newStyle,
                        [property]: styleProperties[property]
                    };

                    if (newStyle.cursorStyle) {
                        newStyle.cursorStyle = {
                            ...newStyle.cursorStyle,
                            [property]: styleProperties[property]
                        };
                    }
                }
            }

            newStyle = complementAgainstSources(
                newStyle,
                getDefaultRunStyle(updatedTextBody),
                styleDeepExceptions
            );

            let updatedStyleIndex = getRunStyleIndex(
                updatedTextBody,
                newStyle
            );
            if (updatedStyleIndex === -1) {
                updatedTextBody = addRunStyle(updatedTextBody, newStyle);
                updatedStyleIndex = getRunStylesCount(updatedTextBody) - 1;
            }

            const run = getRunsAt(
                updatedTextBody,
                runStyle.startIndex,
                runStyle.endIndex
            ).first();

            if (run) {
                delete run.index;
                const runIndex = getRunIndex(updatedTextBody, run);
                if (dynamicType !== 'none') {
                    updatedTextBody = mergeRunStyle(
                        updatedTextBody,
                        runIndex,
                        {
                            dynamicType
                        }
                    );
                }
                if (has(styleProperties, 'font.size') && Object.keys(styleProperties).length === 1) {
                    paragraphDimensionsUpdate = getParagraphDimensionsUpdate(
                        updatedTextBody,
                        runIndex,
                        updatedTextBody,
                        paragraphDimensionsUpdate
                    );
                }
                updatedTextBody = setRunStyleIndexToRun(
                    updatedTextBody,
                    runIndex,
                    updatedStyleIndex
                );
            }
        });
    });
    updatedTextBody = Object.entries(paragraphDimensionsUpdate)
        .reduce(
            (data, [paragraphStyleIndex, ratio]) => updateParagraphDimensionsForFontSizeRatio(
                data,
                paragraphStyleIndex,
                ratio
            ),
            updatedTextBody
        );
    updatedTextBody = mergeRunsWithNewLineChar(updatedTextBody);
    updatedTextBody = removeUnusedRunStyles(
        mergeContiguousRunsWithSameStyle(updatedTextBody)
    );
    return updatedTextBody;
};

const clearStyle = (
    textBodyData,
    startIndex = 0,
    endIndex = getTextLength(textBodyData) - 1
) => setStyle(textBodyData, null, startIndex, endIndex);

const getTextForStyles = (textBodyData, styles) => {
    const styleIndexes = styles
        .map(
            style => getRunStyleIndex(textBodyData, style)
        )
        .filter(index => index > -1);
    return textBodyData.get(RUNS)
        .filter(run => styleIndexes.includes(run.style))
        .map(run => ({
            ...run,
            text: getText(textBodyData, run.startIndex, run.endIndex),
            style: getRunStyle(textBodyData, run.style)
        }));
};

const getTextForStyleProperties = (textBodyData, styleProperties) => {
    const properties = Object.keys(styleProperties);
    const runStyleThatHaveStyleProperties = textBodyData
        .get(RUN_STYLES)
        .map((runStyle, index) => ({ ...runStyle, index }))
        .filter(runStyle => {
            if (runStyle.isDefault === true) {
                return false;
            }
            const style = getRunStyle(textBodyData, runStyle.index, true);
            return (properties.filter(property => {
                if (property === 'font') {
                    return Object.keys(styleProperties.font).every(prop => (
                        style.font && style.font[prop] === styleProperties.font[prop]
                    ));
                }
                return isEqual(styleProperties[property], style[property]);
            }).length > 0);
        })
        .map(runStyle => runStyle.index);

    return textBodyData.get(RUNS)
        .filter(run => runStyleThatHaveStyleProperties.includes(run.style))
        .map(run => ({
            ...run,
            text: getText(textBodyData, run.startIndex, run.endIndex),
            style: getRunStyle(textBodyData, run.style)
        }));
};

const hasStyleProperties = (
    textBodyData,
    styleProperties,
    startIndex = 0,
    endIndex = getTextLength(textBodyData) - 1
) => {
    const runStyles = getRunStylesAt(textBodyData, startIndex, endIndex);
    const stylesWithDefaultsInRange = runStyles.length === 0 ? [
        getDefaultRunStyle(textBodyData)
    ] :
        fillHolesInRunsArray(textBodyData, runStyles)
            .map(run => (
                !run.style ? {
                    ...run,
                    style: getDefaultRunStyle(textBodyData)
                } :
                    run
            ))
            .filter(run => (
                getText(textBodyData, run.startIndex, run.endIndex) !== '\n' &&
                run.endIndex >= startIndex &&
                run.startIndex <= endIndex
            ))
            .map(runStyle => runStyle.style);

    return stylesWithDefaultsInRange
        .reduce(
            (rangeCovered, style) => rangeCovered &&
            Object.keys(styleProperties)
                .reduce((propertyCovered, property) => {
                    if (property === 'font') {
                        return propertyCovered && Object.keys(styleProperties.font)
                            .reduce(
                                (fontCovered, fontProperty) => fontCovered &&
                                style.font &&
                                (style.font[fontProperty] === styleProperties.font[fontProperty]),
                                propertyCovered
                            );
                    }
                    return propertyCovered && (
                        style[property] === styleProperties[property]
                    );
                }, rangeCovered),
            true
        );
};

const toJSON = textBodyData => ({
    [PARAGRAPHS]: getParagraphs(textBodyData)
        .map(p => {
            delete p.text;
            return p;
        })
        .toJS(),
    [RUNS]: getRunsAt(textBodyData)
        .map(r => {
            delete r.index;
            return r;
        })
        .toJS(),
    [PARAGRAPH_STYLES]: serializeParagraphStyles(textBodyData),
    [RUN_STYLES]: serializeRunStyles(textBodyData),
    [TEXT]: getText(textBodyData),
    [AUTO_FIT_TEXT]: textBodyData.get(AUTO_FIT_TEXT),
    [AUTO_FIT_SHAPE]: textBodyData.get(AUTO_FIT_SHAPE)
});

const fromJSON = ({
    paragraphs = [],
    runs = [],
    runStyles = [],
    paragraphStyles = [],
    text = '',
    autoFitText = false,
    autoFitShape = false
}) => TextBodyData()
    .set(PARAGRAPHS, List(paragraphs))
    .set(RUNS, List(runs))
    .set(
        RUN_STYLES,
        List(cleanUpStyles(runStyles))
    )
    .set(
        PARAGRAPH_STYLES,
        List(cleanUpStyles(paragraphStyles))
    )
    .set(AUTO_FIT_TEXT, autoFitText)
    .set(AUTO_FIT_SHAPE, autoFitShape)
    .set(TEXT, text);

const addTextWithStyle = (textBodyData, text, style, index) => {
    let addIndex = index;
    if (index === undefined) {
        addIndex = getTextLength(textBodyData);
    }
    const updatedTextBody = addText(textBodyData, text, addIndex);
    return setStyle(
        updatedTextBody,
        style,
        addIndex,
        addIndex + (text.length - 1)
    );
};

const getFullTextParagraphStyle = textBodyData => {
    const paragraphStyles = getParagraphStyles(textBodyData, true).toJS();
    const intersectedParagraphStyle = intersectStyles(...paragraphStyles);
    return defaultsDeep(
        {
            ...intersectedParagraphStyle,
            isDefault: true
        },
        getDefaultParagraphStyle(textBodyData)
    );
};

const getFullTextRunStyle = textBodyData => {
    const runStyles = getRunStyles(textBodyData, true).toJS();
    const intersectedRunStyle = intersectStyles(...runStyles);
    return defaultsDeep(
        {
            ...intersectedRunStyle,
            isDefault: true
        },
        getDefaultRunStyle(textBodyData)
    );
};

const updateColorPresets = (textBodyData, { presets, scheme } = {}) => textBodyData
    .update(RUN_STYLES, runStyles => runStyles
        .map(runStyle => RunStyle.updateColorPresets(runStyle, { presets, scheme })));

const applyDefaultParagraphDimensionsUpdateFromNewFontSize = (textBodyData, newFontSize) => {
    const previousFontSize = getDefaultRunFontSize(textBodyData, newFontSize);
    const defaultParagraphStyleIndex = getDefaultParagraphStyleIndex(textBodyData);
    if ([newFontSize, previousFontSize, defaultParagraphStyleIndex].some(isNil)) {
        return textBodyData;
    }
    const paragraphStyleIndexes = getParagraphStyleFromParagraphWithDefaultFontSize(textBodyData);
    return paragraphStyleIndexes.reduce(
        (current, index) => updateParagraphDimensionsForFontSizeRatio(
            current,
            index,
            newFontSize / previousFontSize
        ),
        textBodyData
    );
};

const getParagraphStyleFromParagraphWithDefaultFontSize = textBodyData => {
    const defaultFontSize = getDefaultRunFontSize(textBodyData);
    const paragraphsWithDefaultFontSize = getParagraphsWithStyleAndIndex(
        textBodyData,
        undefined,
        undefined,
        true
    )
        .filter(paragraph => get(paragraph, 'textRuns.0.style.font.size') === defaultFontSize);
    return [...new Set([
        getDefaultParagraphStyleIndex(textBodyData),
        ...paragraphsWithDefaultFontSize.map(paragraph => get(paragraph, 'style')).filter(Boolean)
    ])];
};

const getParagraphsWithStyleAndIndex = (
    textBodyData,
    startIndex,
    endIndex,
    withDefault
) => getParagraphsWithStyle(textBodyData, startIndex, endIndex, withDefault)
    .map((paragraph, index) => ({
        ...paragraph,
        index
    }));

const getDefaultRunFontSize = (textBodyData, defaultValue) => get(
    getDefaultRunStyle(textBodyData),
    'font.size',
    defaultValue
);

const isCursorSelection = textSelection => !isNil(textSelection) && textSelection.start === textSelection.end;

module.exports = {
    TextBodyData,
    addParagraph,
    addParagraphWithStyle,
    addText,
    addTextWithStyle,
    clearStyle,
    fromJSON,
    getDefaultParagraphStyle,
    getDefaultRunStyle,
    getFonts,
    getParagraph,
    getParagraphStyle,
    getFirstRunInParagraph,
    getParagraphStyles,
    getParagraphStylesCount,
    getParagraphs,
    getParagraphStyleAt,
    getParagraphsCount,
    getParagraphsWithIndex,
    getParagraphWithStyle,
    getParagraphsWithStyle,
    getRunDynamicType,
    getRunStyle,
    getRunStyles,
    getRunStylesCount,
    getRunsAt,
    getStyledParagraphsWithStyledRuns,
    getStylesCount,
    getText,
    getTextForStyleProperties,
    getTextForStyles,
    getTextLength,
    getTextWithStyle,
    hasStyleProperties,
    removeParagraphs,
    removeText,
    reset,
    setDefaultParagraphStyle,
    setDefaultRunStyle,
    setParagraphStyleProperties,
    setParagraphText,
    setParagraphTextWithStyle,
    setParagraphs,
    setStyle,
    setStyleProperties,
    setText,
    setTextWithStyle,
    getFullTextParagraphStyle,
    getFullTextRunStyle,
    toJSON,
    updateColorPresets,
    getParagraphsWithParagraphStyle,
    getRunStylesAt,
    isEmpty,
    getDefaultRunStyleFromFirstRun,
    applyDefaultParagraphDimensionsUpdateFromNewFontSize
};
