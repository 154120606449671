class Point {
    constructor(x, y) {
        this._x = x;
        this._y = y;
    }

    rotateInDegrees(degrees = 0) {
        const radians = degrees * (Math.PI / 180);
        this.rotateInRadians(radians);
    }

    rotateInRadians(radians) {
        Object.assign(this, {
            x: (this.x * Math.cos(radians)) - (this.y * Math.sin(radians)),
            y: (this.x * Math.sin(radians)) + (this.y * Math.cos(radians))
        });
    }

    get x() {
        return this._x;
    }

    set x(x) {
        this._x = x;
    }

    get y() {
        return this._y;
    }

    set y(y) {
        this._y = y;
    }
}

module.exports = Point;
