import { List, Set } from 'immutable';
import {
    getCellGridColumn,
    getCellGridRow,
    getLeftBorderSegmentsForCell,
    getRightBorderSegmentsForCell,
    getTopBorderSegmentsForCell,
    getBottomBorderSegmentsForCell,
    isBorderOnTableEdge,
    getColumnEndX,
    getColumnStartX,
    getRowEndY,
    getRowStartY
} from './tableGridLogic';

const getCellsBorders = (table, cellIds) => cellIds.reduce((borders, cellId) => borders.push(
    ...getCellBorders(table, cellId)
), List());

const getCellBorders = (table, cellId) => List([
    ...getLeftBorderSegmentsForCell(cellId, table),
    ...getRightBorderSegmentsForCell(cellId, table),
    ...getTopBorderSegmentsForCell(cellId, table),
    ...getBottomBorderSegmentsForCell(cellId, table)
]);

const getTopmostLeftmostCell = (table, cellIds) => cellIds
    .map(cellId => table.get('cells').find(cell => cell.get('id') === cellId))
    .sort((a, b) => a.get('row') - b.get('row') || a.get('column') - b.get('column'))
    .get(0);

const getCellIdsInColumn = (table, columnIndex) => List(Set(getCellGridColumn(columnIndex, table)));

const getCellsInColumn = (table, columnIndex) => getCellIdsInColumn(table, columnIndex)
    .map(cellId => getCell(table, cellId));

const getCellIdsInRow = (table, rowIndex) => List(Set(getCellGridRow(rowIndex, table)));

const getCellsInRow = (table, rowIndex) => getCellIdsInRow(table, rowIndex)
    .map(cellId => getCell(table, cellId));

const getFirstColumn = table => getCellGridColumn(0, table).map(cellId => getCell(table, cellId));

const getFirstRow = table => getCellGridRow(0, table).map(cellId => getCell(table, cellId));

const getLastColumn = table => getCellGridColumn(table.get('columnWidths').size - 1, table).map(cellId => getCell(table, cellId));

const getLastRow = table => getCellGridRow(table.get('rowHeights').size - 1, table).map(cellId => getCell(table, cellId));

const getCell = (table, cellId) => table
    .get('cells')
    .find(cell => cell.get('id') === cellId);

const getCellIndex = (table, cellId) => table
    .get('cells')
    .findIndex(cell => cell.get('id') === cellId);

const getBottomEdgeOfCellList = (table, cellIds) => cellIds
    .reduce((bottomEdge, cellId) => {
        const cell = getCell(table, cellId);
        if (isPartOfBottomEdgeInList(table, cell, cellIds)) {
            return List([
                ...bottomEdge,
                cellId
            ]);
        }
        return bottomEdge;
    }, List());

const getTopEdgeOfCellList = (table, cellIds) => cellIds
    .reduce((topEdge, cellId) => {
        const cell = getCell(table, cellId);
        if (isPartOfTopEdgeInList(table, cell, cellIds)) {
            return List([
                ...topEdge,
                cellId
            ]);
        }
        return topEdge;
    }, List());

const getLeftEdgeOfCellList = (table, cellIds) => cellIds
    .reduce((leftEdge, cellId) => {
        const cell = getCell(table, cellId);
        if (isPartOfLeftEdgeInList(table, cell, cellIds)) {
            return List([
                ...leftEdge,
                cellId
            ]);
        }
        return leftEdge;
    }, List());

const getRightEdgeOfCellList = (table, cellIds) => cellIds
    .reduce((rightEdge, cellId) => {
        const cell = getCell(table, cellId);
        if (isPartOfRightEdgeInList(table, cell, cellIds)) {
            return List([
                ...rightEdge,
                cellId
            ]);
        }
        return rightEdge;
    }, List());

const getLastSpannedRow = cell => cell.get('row') + cell.get('rowSpan') - 1;

const getLastSpannedColumn = cell => cell.get('column') + cell.get('columnSpan') - 1;

const getBorder = (table, borderId) => table
    .get('borders')
    .find(border => border.get('id') === borderId);

const getBorders = (cellId, table) => ([
    ...getLeftBorderSegments(cellId, table),
    ...getRightBorderSegments(cellId, table),
    ...getTopBorderSegments(cellId, table),
    ...getBottomBorderSegments(cellId, table)
]);

const getLeftBorderSegments = (cellId, table) => getLeftBorderSegmentsForCell(cellId, table);
const getRightBorderSegments = (cellId, table) => getRightBorderSegmentsForCell(cellId, table);
const getTopBorderSegments = (cellId, table) => getTopBorderSegmentsForCell(cellId, table);
const getBottomBorderSegments = (cellId, table) => getBottomBorderSegmentsForCell(cellId, table);

const isPartOfBottomEdgeInList = (table, cell, cellIds) => getLastSpannedRow(cell) === table.get('rowCount') - 1 ||
    !cellIds.includes(table.getIn(['cellGrid', getLastSpannedRow(cell) + 1, cell.get('column')]));

const isPartOfLeftEdgeInList = (table, cell, cellIds) => cell.get('column') === 0 ||
    !cellIds.includes(table.getIn(['cellGrid', cell.get('row'), cell.get('column') - 1]));

const isPartOfRightEdgeInList = (table, cell, cellIds) => getLastSpannedColumn(cell) === table.get('columnCount') - 1 ||
    !cellIds.includes(table.getIn(['cellGrid', cell.get('row'), getLastSpannedColumn(cell) + 1]));

const isPartOfTopEdgeInList = (table, cell, cellIds) => cell.get('row') === 0 ||
    !cellIds.includes(table.getIn(['cellGrid', cell.get('row') - 1, cell.get('column')]));

const getInnerBorders = (table, cellIds) => {
    let filteredBorders = Set();
    cellIds.forEach(cellId => {
        getBorders(cellId, table).forEach(borderId => {
            if (
                isInsideCellSelection(table, borderId, cellIds)
            ) {
                filteredBorders = filteredBorders.add(borderId);
            }
        });
    });
    return List(filteredBorders);
};

const getOuterBorders = (table, cellIds) => {
    let filteredBorders = Set();
    cellIds.forEach(cellId => {
        getBorders(cellId, table).forEach(borderId => {
            if (
                isOnEdgeOfCellSelection(table, borderId, cellIds)
            ) {
                filteredBorders = filteredBorders.add(borderId);
            }
        });
    });
    return List(filteredBorders);
};

const getInnerHorizontalBorders = (table, cellIds) => getInnerBorders(table, cellIds)
    .filter(borderId => getBorder(table, borderId).get('side') === 'horizontal');

const getInnerVerticalBorders = (table, cellIds) => getInnerBorders(table, cellIds)
    .filter(borderId => getBorder(table, borderId).get('side') === 'vertical');

const isInsideCellSelection = (table, borderId, cellIds) => {
    const border = getBorder(table, borderId);
    if (border.get('side') === 'horizontal') {
        return !isBorderOnTableEdge(borderId, table) &&
            cellIds.includes(table.getIn(['cellGrid', border.get('row') - 1, border.get('column')])) &&
            cellIds.includes(table.getIn(['cellGrid', border.get('row'), border.get('column')]));
    }
    return !isBorderOnTableEdge(borderId, table) &&
        cellIds.includes(table.getIn(['cellGrid', border.get('row'), border.get('column') - 1])) &&
        cellIds.includes(table.getIn(['cellGrid', border.get('row'), border.get('column')]));
};

const isOnEdgeOfCellSelection = (table, borderId, cellIds) => {
    const border = getBorder(table, borderId);
    if (border.get('side') === 'horizontal') {
        const cellToLeftIsInSelection = border.get('row') > 0 &&
            cellIds.includes(table.getIn(['cellGrid', border.get('row') - 1, border.get('column')]));
        const cellToRightIsInSelection = border.get('row') < table.get('rowHeights').size &&
            cellIds.includes(table.getIn(['cellGrid', border.get('row'), border.get('column')]));
        return !isInsideCellSelection(table, borderId, cellIds) &&
            (cellToLeftIsInSelection ||
            cellToRightIsInSelection);
    }
    const cellOnTopIsInSelection = border.get('column') > 0 &&
        cellIds.includes(table.getIn(['cellGrid', border.get('row'), border.get('column') - 1]));
    const cellOnBottomIsInSelection = border.get('column') < table.get('columnWidths').size &&
        cellIds.includes(table.getIn(['cellGrid', border.get('row'), border.get('column')]));
    return !isInsideCellSelection(table, borderId, cellIds) &&
        (cellOnTopIsInSelection ||
        cellOnBottomIsInSelection);
};

const hasHeaderSpannerColumn = table => getCellsInColumn(table, 0)
    .some(cell => isHeaderSpannerColumn(cell));

const hasHeaderSpannerRow = table => getCellsInRow(table, 0)
    .some(cell => isHeaderSpannerRow(cell));

const isHeaderSpannerColumn = cell => cell?.getIn('content', 'textBody', 'text')?.length > 0 &&
    cell?.get('style')?.startsWith('TB-HCS');

const isHeaderSpannerRow = cell => cell?.getIn('content', 'textBody', 'text')?.length > 0 &&
    cell?.get('style')?.startsWith('TB-HRS');

const addBorder = (table, border) => table
    .set('borders', table.get('borders').push(border));

const getWidthForCellAt = (table, column, columnSpan) => getColumnEndX(column + (columnSpan - 1), table) -
    getColumnStartX(column, table);

const getHeightForCellAt = (table, row, rowSpan) => getRowEndY(row + (rowSpan - 1), table) - getRowStartY(row, table);

export {
    getCell,
    getCellIndex,
    getCellsBorders,
    getTopmostLeftmostCell,
    getCellsInColumn,
    getCellsInRow,
    getCellIdsInColumn,
    getCellIdsInRow,
    getFirstColumn,
    getFirstRow,
    getLastColumn,
    getLastRow,
    getLastSpannedRow,
    getLastSpannedColumn,
    getLeftBorderSegments,
    getRightBorderSegments,
    getTopBorderSegments,
    getBottomBorderSegments,
    getBottomEdgeOfCellList,
    getTopEdgeOfCellList,
    getLeftEdgeOfCellList,
    getRightEdgeOfCellList,
    getBorders,
    getBorder,
    getOuterBorders,
    getInnerBorders,
    getInnerHorizontalBorders,
    getInnerVerticalBorders,
    hasHeaderSpannerColumn,
    hasHeaderSpannerRow,
    isHeaderSpannerColumn,
    isHeaderSpannerRow,
    addBorder,
    getWidthForCellAt,
    getHeightForCellAt
};
