const { fabric } = require('fabric');

const { omit, isEmpty } = require('lodash');
const { PPTX_FIRST_LINE_RATIO, PPTX_BOTTOM_HEIGHT_RATIO, PPTX_ONE_LINE_HEIGHT_MULTIPLIER } = require('../../constants/text');

const {
    getFontMetricPositionRelativeToFontSize,
    hasFontMetrics
} = require('../../utilities/FontMetricsGetter');

const { clone } = fabric.util.object;

module.exports = {
    initDimensions() {
        this.callSuper('initDimensions');
        this.enlargeSpaces();
        this.saveState({ propertySet: '_dimensionAffectingProps' });
    },

    enlargeSpaces() {
        for (let i = 0, len = (this._textLines ?? []).length; i < len; i++) {
            const {
                textAlign = ''
            } = this.getParagraphForLine(i);
            const maxWidth = this.getLineMaxWidth(i);
            if (textAlign.startsWith('justify') && !(i === len - 1 || this.isEndOfWrapping(i))) {
                let accumulatedSpace = 0;
                const line = this._textLines[i];
                const currentLineWidth = this.getLineWidth(i);
                if (currentLineWidth < maxWidth && this.textLines[i].match(this._reSpacesAndTabs)) {
                    const spaces = this.textLines[i].match(this._reSpacesAndTabs);
                    const numberOfSpaces = spaces.length;
                    const diffSpace = (maxWidth - currentLineWidth) / numberOfSpaces;
                    for (let j = 0, jlen = line.length; j <= jlen; j++) {
                        const charBound = this.__charBounds[i][j];
                        if (this._reSpaceAndTab.test(line[j])) {
                            charBound.width += diffSpace;
                            charBound.kernedWidth += diffSpace;
                            charBound.left += accumulatedSpace;
                            accumulatedSpace += diffSpace;
                        } else {
                            charBound.left += accumulatedSpace;
                        }
                    }
                }
            }
        }
    },

    calcTextHeight() {
        let addedBottomHeight = 0;

        const height = this._textLines.reduce(
            (calculatedHeight, line, index) => {
                const topOffset = index !== 0 ? this._getLineTopOffset(index) : 0;
                const bottomOffset = index !== this._textLines.length - 1 ? this._getLineBottomOffset(index) : 0;

                const fontSize = this.getMaxFontSizeForLine(index);
                const lineHeight = this.getParagraphForLine(index).lineHeight / 1.2;

                const leading = this.calcLeading(index);
                let appliedHeight = leading;

                if (lineHeight !== 1) {
                    if (!index) { // First Line
                        appliedHeight *= PPTX_FIRST_LINE_RATIO;
                    } else { // Multiple Font Sizes
                        appliedHeight += this.calcMultipleLinesDiff(index, appliedHeight);
                    }

                    addedBottomHeight = fontSize * PPTX_BOTTOM_HEIGHT_RATIO;
                }

                return calculatedHeight + appliedHeight + topOffset + bottomOffset;
            },
            0
        );

        return height + addedBottomHeight;
    },

    calcLeading(index) {
        const fontSize = this.getMaxFontSizeForLine(index);
        const lineHeight = this.getParagraphForLine(index).lineHeight / 1.2;

        return fontSize * lineHeight * PPTX_ONE_LINE_HEIGHT_MULTIPLIER;
    },

    calcMultipleLinesDiff(index, currentHeight) {
        return (this.calcLeading(index - 1) - currentHeight) * 0.25;
    },

    correctCharacterTopToActualBaseline({
        approximatedCharacterTop,
        charIndex = 0,
        lineIndex = 0
    }) {
        const approximatedFabricBaseline = this.getApproximatedFabricBaseline(lineIndex);

        const actualTextBaseline = this.getActualTextBaseline({
            charIndex,
            lineIndex
        });

        return (
            approximatedCharacterTop +
            approximatedFabricBaseline
        ) - actualTextBaseline;
    },

    getCharMetricValue({
        charIndex,
        lineIndex,
        metricId
    }) {
        const fontFamily = this.getValueOfPropertyAt(
            lineIndex,
            charIndex,
            'fontFamily'
        );

        const fontSize = this.getValueOfPropertyAt(
            lineIndex,
            charIndex,
            'fontSize'
        );

        return Math.abs(
            getFontMetricPositionRelativeToFontSize({
                fontFamily,
                fontSize,
                metricId
            })
        );
    },

    getMaxFontSizeForLine(lineIndex) {
        const line = this._textLines[lineIndex];
        let maxHeight = this.getHeightOfChar(lineIndex, 0);
        for (let i = 1, len = line.length; i < len; i++) {
            maxHeight = Math.max(this.getHeightOfChar(lineIndex, i), maxHeight);
        }
        return maxHeight;
    },

    getLineBaselineOffset(lineIndex) {
        return ((this._fontSizeMult - 0.25) * this.getMaxFontSizeForLine(lineIndex));
    },

    getHeightOfChar(lineIndex, charIndex) {
        const fontFamily = this.getValueOfPropertyAt(
            lineIndex,
            charIndex,
            'fontFamily'
        );

        if (hasFontMetrics(fontFamily)) {
            const fontSize = this.getValueOfPropertyAt(
                lineIndex,
                charIndex,
                'fontSize'
            );

            const ascender = getFontMetricPositionRelativeToFontSize({
                fontFamily,
                fontSize,
                metricId: 'ascender'
            });

            const descender = getFontMetricPositionRelativeToFontSize({
                fontFamily,
                fontSize,
                metricId: 'descender'
            });

            const charHeight = ascender - descender;

            return charHeight / this._fontSizeMult;
        }
        return this.callSuper('getHeightOfChar', lineIndex, charIndex);
    },

    _getStyleDeclaration(lineIndex, charIndex) {
        let unwrappedLineIndex = lineIndex;
        let unwrappedCharIndex = charIndex;
        if (this._styleMap && !this.unWrapping) {
            const map = this._styleMap[lineIndex];
            if (!map) {
                return null;
            }
            unwrappedLineIndex = map.line;
            unwrappedCharIndex = map.offset + charIndex;
        }
        const {
            firstUnwrappedLineIndex,
            styles
        } = this.getParagraphForUnwrappedTextLine(unwrappedLineIndex);
        const paragraphUnwrappedLineIndex = unwrappedLineIndex - firstUnwrappedLineIndex;

        return ((
            styles &&
            styles[paragraphUnwrappedLineIndex] &&
            styles[paragraphUnwrappedLineIndex][unwrappedCharIndex]
        ) ? styles[paragraphUnwrappedLineIndex][unwrappedCharIndex] : {});
    },

    getValueOfPropertyAt(lineIndex, charIndex, property, applyCursorStyle = false) {
        const charStyle = this._getStyleDeclaration(lineIndex, charIndex);
        const previousCharStyle = this._getStyleDeclaration(lineIndex, charIndex - 1);
        const paragraph = this.getParagraphForLine(lineIndex);
        if (applyCursorStyle && charStyle?.cursorStyle) {
            if ((!previousCharStyle || isEmpty(previousCharStyle)) && charStyle.cursorStyle[property]) {
                return charStyle.cursorStyle[property];
            } else if (previousCharStyle[property]) {
                return charStyle.cursorStyle[property] || previousCharStyle[property];
            }
        }
        if (charStyle && charStyle[property] !== undefined) {
            return charStyle[property];
        }
        return paragraph[property] || this[property];
    },

    _getTopOffset() {
        return this.callSuper('_getTopOffset');
    },

    insertCharStyleObject(lineIndex, charIndex, quantity, copiedStyle) {
        if (!this.styles) {
            this.styles = {};
        }
        const currentLineStyles = this.styles[lineIndex];
        const currentLineStylesCloned = currentLineStyles ? clone(currentLineStyles) : {};

        const textLength = this.textLines[lineIndex] ? this.textLines[lineIndex].length : 0;

        const cursorStyle = this.styles?.[lineIndex]?.[charIndex]?.cursorStyle;
        if (cursorStyle) {
            delete this.styles[lineIndex][charIndex].cursorStyle;
        }
        let actualQuantity = quantity || 1;

        // shift all char styles by quantity forward
        // 0,1,2,3 -> (charIndex=2) -> 0,1,3,4 -> (insert 2) -> 0,1,2,3,4

        if (charIndex !== textLength) {
            Object.entries(currentLineStylesCloned).forEach(entry => {
                const [key, value] = entry;
                const numericIndex = parseInt(key, 10); // Key is actually an index but it's a String
                if (numericIndex >= charIndex) {
                    currentLineStyles[numericIndex + actualQuantity] = value;
                    // only delete the style if there was nothing moved there
                    if (!currentLineStylesCloned[numericIndex - actualQuantity]) {
                        delete currentLineStyles[numericIndex];
                    }
                }
            });
        }

        this._forceClearCache = true;
        if (copiedStyle) {
            while (actualQuantity--) {
                if (Object.keys(copiedStyle[actualQuantity]).length) {
                    if (!this.styles[lineIndex]) {
                        this.styles[lineIndex] = {};
                    }
                    this.styles[lineIndex][charIndex + actualQuantity] = clone(copiedStyle[actualQuantity]);
                }
            }
            return;
        }
        if (!currentLineStyles) {
            return;
        }

        let newStyle = currentLineStyles[charIndex ? charIndex - 1 : 1];

        if (cursorStyle) {
            newStyle = {
                ...newStyle,
                ...omit(cursorStyle, ['textSelection'])
            };
        }

        while (newStyle && actualQuantity--) {
            this.styles[lineIndex][charIndex + actualQuantity] = clone(newStyle);
        }
    },

    insertNewStyleBlock(insertedText, start, copiedStyle) {
        const cursorLoc = this.get2DCursorLocation(start, true);
        const addedLines = [0];
        let linesLength = 0;
        let style = copiedStyle;
        let index = 0;
        // get an array of how many char per lines are being added.
        for (index = 0; index < insertedText.length; index++) {
            if (insertedText[index] === '\n') {
                linesLength++;
                addedLines[linesLength] = 0;
            } else {
                addedLines[linesLength]++;
            }
        }
        // for the first line copy the style from the current char position.
        if (addedLines[0] > 0) {
            this.insertCharStyleObject(cursorLoc.lineIndex, cursorLoc.charIndex, addedLines[0], style);
            style = style && style.slice(addedLines[0] + 1);
        }
        // cursorLoc.lineIndex, cursorLoc.charIndex + addedLines[0], linesLength
        if (linesLength) {
            this.insertNewlineStyleObject(cursorLoc.lineIndex, cursorLoc.charIndex, linesLength, addedLines[0]);
        }
        for (index = 1; index < linesLength; index++) {
            if (addedLines[index] > 0) {
                this.insertCharStyleObject(cursorLoc.lineIndex + index, 0, addedLines[index], style);
            } else if (style) {
                this.styles[cursorLoc.lineIndex + index][0] = [...style[0]];
            }
            style = style && style.slice(addedLines[index] + 1);
        }
        // we use i outside the loop to get it like linesLength
        if (addedLines[index] > 0) {
            this.insertCharStyleObject(cursorLoc.lineIndex + index, 0, addedLines[index], style);
        }
    },

    insertNewlineStyleObject(lineIndex, charIndex, qty, addedLine, copiedStyle) {
        let currentCharStyle;
        const newLineStyles = {};
        let somethingAdded = false;
        const isEndOfLine = this._unwrappedTextLines[lineIndex].length === charIndex;
        const charIndexAfterAddedLine = charIndex + addedLine;
        let actualQuantity = qty || 1;
        this.shiftLineStyles(lineIndex, actualQuantity);
        if (this.styles[lineIndex]) {
            currentCharStyle = this.styles[lineIndex][charIndexAfterAddedLine === 0 ?
                charIndexAfterAddedLine : charIndexAfterAddedLine - 1];
        }
        const currentStyles = this.styles[lineIndex] ? this.styles[lineIndex] : {};
        // we clone styles of all chars
        // after cursor onto the current line
        Object.entries(currentStyles).forEach((val, index) => {
            const numIndex = parseInt(index, 10);
            if (numIndex >= charIndexAfterAddedLine) {
                somethingAdded = true;
                newLineStyles[numIndex - charIndexAfterAddedLine] = this.styles[lineIndex][index];
                // remove lines from the previous line since they're on a new line now
                if (!(isEndOfLine && charIndexAfterAddedLine === 0)) {
                    delete this.styles[lineIndex][index];
                }
            }
        });
        let styleCarriedOver = false;
        if (somethingAdded) {
            // if is end of line, the extra style we copied
            // is probably not something we want
            this.styles[lineIndex + actualQuantity] = newLineStyles;
            styleCarriedOver = true;
        }
        if (styleCarriedOver) {
            // skip the last line of since we already prepared it.
            actualQuantity--;
        }
        // for the all the lines or all the other lines
        // we clone current char style onto the next (otherwise empty) line
        while (actualQuantity > 0) {
            if (copiedStyle && copiedStyle[actualQuantity - 1]) {
                this.styles[lineIndex + actualQuantity] = { 0: clone(copiedStyle[actualQuantity - 1]) };
            } else if (currentCharStyle) {
                this.styles[lineIndex + actualQuantity] = { 0: clone(currentCharStyle) };
            } else {
                delete this.styles[lineIndex + actualQuantity];
            }
            actualQuantity--;
        }
        this._forceClearCache = true;
    },

    removeStyleFromTo(start, end) {
        const cursorStart = this.get2DCursorLocation(start, true);
        const cursorEnd = this.get2DCursorLocation(end, true);
        const lineStart = cursorStart.lineIndex;
        const charStart = cursorStart.charIndex;
        const lineEnd = cursorEnd.lineIndex;
        const charEnd = cursorEnd.charIndex;
        let i; let
            styleObj;
        if (lineStart !== lineEnd) {
            // step1 remove the trailing of lineStart
            if (this.styles[lineStart]) {
                for (i = charStart; i < this._unwrappedTextLines[lineStart].length; i++) {
                    delete this.styles[lineStart][i];
                }
            }
            // step2 move the trailing of lineEnd to lineStart if needed
            if (this.styles[lineEnd]) {
                for (i = charEnd; i < this._unwrappedTextLines[lineEnd].length; i++) {
                    styleObj = this.styles[lineEnd][i];
                    if (styleObj) {
                        this.styles[lineStart][charStart + i - charEnd] = styleObj;
                    }
                }
            }
            // step3 detects lines will be completely removed.
            for (i = lineStart + 1; i <= lineEnd; i++) {
                delete this.styles[i];
            }
            // step4 shift remaining lines.
            this.shiftLineStyles(lineEnd, lineStart - lineEnd);
        } else if (this.styles[lineStart]) {
            // remove and shift left on the same line
            styleObj = this.styles[lineStart];
            const diff = charEnd - charStart;
            let removedStyle;
            let numericChar;
            for (i = charStart; i < charEnd; i++) {
                if (styleObj[i + 1]) {
                    if (styleObj[i + 1].cursorStyle) {
                        delete styleObj[i + 1].cursorStyle;
                    }
                }
                removedStyle = styleObj[i];
                delete styleObj[i];
            }

            Object.keys(this.styles[lineStart]).forEach(key => {
                numericChar = parseInt(key, 10); // key is actually an index but it's a String
                if (numericChar >= charEnd) {
                    styleObj[numericChar - diff] = styleObj[key];
                    delete styleObj[key];
                }
            });

            styleObj[charStart] = {
                ...styleObj[charStart],
                cursorStyle: removedStyle
            };
        }
    }
};
