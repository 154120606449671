const {
    computeSelectionWidth
} = require('./properties/width');

const {
    computeSelectionHeight
} = require('./properties/height');

const {
    computeSelectionXpos
} = require('./properties/x');

const {
    computeSelectionYpos
} = require('./properties/y');

const {
    computeSelectionFill
} = require('./properties/fill');

const {
    computeSelectionStroke
} = require('./properties/stroke');

const {
    computeSelectionOpacity
} = require('./properties/opacity');

const {
    computeStyleFromCanvasItems
} = require('./properties/style');

const {
    computeRotationFromCanvasStateItems
} = require('./properties/rotation');
const {
    computeFontFamilyFromTextBodies
} = require('./properties/fontFamily');
const {
    computeFontSizeFromTextBodies
} = require('./properties/fontSize');
const {
    computeFontColorFromTextBodies
} = require('./properties/fontColor');

const {
    computeTextTransformFromTextBodies
} = require('./properties/textTransform');

const {
    computeFontStyleFromTextBodies
} = require('./properties/fontStyle');
const {
    computeFontWeightFromTextBodies
} = require('./properties/fontWeight');
const {
    computeLinethroughFromTextBodies
} = require('./properties/linethrough');
const {
    computeOverlineFromTextBodies
} = require('./properties/overline');
const {
    computeUnderlineFromTextBodies
} = require('./properties/underline');

const {
    computeSubscriptFromTextBodies
} = require('./properties/subscript');

const {
    computeSuperscriptFromTextBodies
} = require('./properties/superscript');

const {
    computeLineSpacingFromTextBodies
} = require('./properties/lineSpacing');

const {
    computeIndentFromTextBodies
} = require('./properties/indent');

const {
    computeConnectionStrategyFromCanvasStateItems
} = require('./properties/connectionStrategy');

const {
    computeAlignFromTextBodies
} = require('./properties/align');

const {
    computeVerticalAlignFromTextBodies
} = require('./properties/verticalAlign');

const {
    computeTextDirectionFromTextBodies
} = require('./properties/textDirection');

const {
    computeBulletColorFromTextBodies,
    computeBulletColorPresetFromTextBodies,
    computeBulletFamilyFromTextBodies,
    computeBulletsFromTextBodies,
    computeBulletSizeFromTextBodies,
    computeBulletTextFromTextBodies,
    computeBulletLeftPaddingFromTextBodies,
    computeBulletFontWeightFromTextBodies,
    computeBulletFontStyleFromTextBodies,
    computeBulletLevelFromTextBodies
} = require('./properties/bullets');

const {
    computeMarginsFromTextBodies
} = require('./properties/margins');

const {
    computeSpacingsFromTextBodies
} = require('./properties/spacings');

const {
    computeScaleXFromCanvasStateItems
} = require('./properties/scaleX');

const {
    computeScaleYFromCanvasStateItems
} = require('./properties/scaleY');

const {
    computeSkewXFromCanvasStateItems,
    computeSkewYFromCanvasStateItems
} = require('./properties/skew');

const {
    computeLockAspectRatioFromCanvasStateItems
} = require('./properties/lockAspectRatio');

const {
    computeIsBackgroundFromCanvasStateIems
} = require('./properties/isBackground');

const {
    computeAutoFitTextFromTextBodies
} = require('./properties/autoFitText');

const {
    computeAutoFitShapeFromTextBodies
} = require('./properties/autoFitShape');

const {
    computeHasBandedColumnsFromTables
} = require('./properties/hasBandedColumns');

const {
    computeHasBandedRowsFromTables
} = require('./properties/hasBandedRows');

const {
    computeHasHeaderColumnFromTables
} = require('./properties/hasHeaderColumn');

const {
    computeHasHeaderRowFromTables
} = require('./properties/hasHeaderRow');

const {
    computeHasTotalColumnFromTables
} = require('./properties/hasTotalColumn');

const {
    computeHasTotalRowFromTables
} = require('./properties/hasTotalRow');

const {
    computeMainAxisFromTables
} = require('./properties/mainAxis');

const computeStrokeFillFromBorders = require('./properties/borderStrokeFill');

const computeStrokeWidthFromBorders = require('./properties/borderStrokeWidth');

const computeStrokeDashTypeFromBorders = require('./properties/borderStrokeDashType');

const {
    computePlaceholderBorderStrokeFromTextBodies
} = require('./properties/placeholderBorderStroke');

const PROPERTIES = {
    AUTO_FIT_SHAPE: 'autoFitShape',
    AUTO_FIT_TEXT: 'autoFitText',
    BORDER_STROKE_DASH_TYPE: 'borderStrokeDashType',
    BORDER_STROKE_FILL: 'borderStrokeFill',
    BORDER_STROKE_WIDTH: 'borderStrokeWidth',
    BULLET_COLOR: 'bulletColor',
    BULLET_COLOR_PRESET: 'bulletColorPreset',
    BULLET_FAMILY: 'bulletFamily',
    BULLETS: 'bullets',
    BULLET_SIZE: 'bulletSize',
    BULLET_TEXT: 'bulletText',
    BULLET_FONT_WEIGHT: 'bulletFontWeight',
    BULLET_FONT_STYLE: 'bulletFontStyle',
    BULLET_LEVEL: 'level',
    CONNECTION_STRATEGY: 'connectionStrategy',
    FILL: 'fill',
    FONT_FAMILY: 'fontFamily',
    FONT_SIZE: 'fontSize',
    FONT_STYLE: 'fontStyle',
    FONT_WEIGHT: 'fontWeight',
    FONT_COLOR: 'color',
    HAS_BANDED_COLUMNS: 'hasBandedColumns',
    HAS_BANDED_ROWS: 'hasBandedRows',
    HAS_HEADER_COLUMN: 'hasHeaderColumn',
    HAS_HEADER_ROW: 'hasHeaderRow',
    HAS_TOTAL_COLUMN: 'hasTotalColumn',
    HAS_TOTAL_ROW: 'hasTotalRow',
    HEIGHT: 'height',
    INDENT: 'indent',
    IS_BACKGROUND: 'isBackground',
    PLACEHOLDER_BORDER_STROKE: 'placeholderBorderStroke',
    LINETHROUGH: 'linethrough',
    LINE_SPACING: 'lineSpacing',
    LOCK_ASPECT_RATIO: 'lockAspectRatio',
    MAIN_AXIS: 'mainAxis',
    MARGINS: 'margins',
    OPACITY: 'opacity',
    OVERLINE: 'overline',
    ROTATION: 'rotation',
    SCALE_X: 'scaleX',
    SCALE_Y: 'scaleY',
    SHAPES_WIDTH: 'shapesWidth',
    SHAPES_HEIGHT: 'shapesHeight',
    SKEW_X: 'skewX',
    SKEW_Y: 'skewY',
    SPACINGS: 'spacings',
    STROKE: 'stroke',
    STYLE: 'style',
    SUBSCRIPT: 'subscript',
    SUPERSCRIPT: 'superscript',
    TEXT_ALIGN: 'align',
    TEXT_DIRECTION: 'textDirection',
    TEXT_TRANSFORM: 'textTransform',
    UNDERLINE: 'underline',
    VERTICAL_ALIGN: 'verticalAlign',
    WIDTH: 'width',
    X: 'x',
    Y: 'y'
};

const CANVAS_STATE_ITEMS_COMPUTE_METHOD_FOR_PROPERTY = {
    [PROPERTIES.FILL]: computeSelectionFill,
    [PROPERTIES.STROKE]: computeSelectionStroke,
    [PROPERTIES.OPACITY]: computeSelectionOpacity,
    [PROPERTIES.CONNECTION_STRATEGY]: computeConnectionStrategyFromCanvasStateItems,
    [PROPERTIES.IS_BACKGROUND]: computeIsBackgroundFromCanvasStateIems,
    [PROPERTIES.STYLE]: computeStyleFromCanvasItems
};

const CANVAS_STATE_ROTATION_COMPUTE_METHOD_FOR_PROPERTY = {
    [PROPERTIES.ROTATION]: computeRotationFromCanvasStateItems
};

const CANVAS_STATE_LOCK_ASPECT_RATIO_COMPUTE_METHOD_FOR_PROPERTY = {
    [PROPERTIES.LOCK_ASPECT_RATIO]: computeLockAspectRatioFromCanvasStateItems
};

const TABLE_COMPUTE_METHOD_FOR_PROPERTY = {
    [PROPERTIES.STYLE]: computeStyleFromCanvasItems
};

const TABLE_COMPUTE_METHOD_FOR_STRUCTURE_PROPERTIES = {
    [PROPERTIES.HAS_BANDED_COLUMNS]: computeHasBandedColumnsFromTables,
    [PROPERTIES.HAS_BANDED_ROWS]: computeHasBandedRowsFromTables,
    [PROPERTIES.HAS_HEADER_COLUMN]: computeHasHeaderColumnFromTables,
    [PROPERTIES.HAS_HEADER_ROW]: computeHasHeaderRowFromTables,
    [PROPERTIES.HAS_TOTAL_COLUMN]: computeHasTotalColumnFromTables,
    [PROPERTIES.HAS_TOTAL_ROW]: computeHasTotalRowFromTables,
    [PROPERTIES.MAIN_AXIS]: computeMainAxisFromTables
};

const CANVAS_STATE_TRANSFORM_COMPUTE_METHOD_FOR_PROPERTY = {
    [PROPERTIES.SKEW_X]: computeSkewXFromCanvasStateItems,
    [PROPERTIES.SKEW_Y]: computeSkewYFromCanvasStateItems
};

const CANVAS_STATE_SCALE_COMPUTE_METHOD_FOR_PROPERTY = {
    [PROPERTIES.SCALE_X]: computeScaleXFromCanvasStateItems,
    [PROPERTIES.SCALE_Y]: computeScaleYFromCanvasStateItems
};

const CANVAS_STATE_BOUNDING_BOX_COMPUTE_METHOD_FOR_PROPERTY = {
    [PROPERTIES.WIDTH]: computeSelectionWidth,
    [PROPERTIES.HEIGHT]: computeSelectionHeight,
    [PROPERTIES.X]: computeSelectionXpos,
    [PROPERTIES.Y]: computeSelectionYpos
};

const CANVAS_STATE_TEXTBODIES_COMPUTE_METHOD_FOR_PROPERTY = {
    [PROPERTIES.AUTO_FIT_TEXT]: computeAutoFitTextFromTextBodies,
    [PROPERTIES.AUTO_FIT_SHAPE]: computeAutoFitShapeFromTextBodies,
    [PROPERTIES.BULLET_COLOR]: computeBulletColorFromTextBodies,
    [PROPERTIES.BULLET_COLOR_PRESET]: computeBulletColorPresetFromTextBodies,
    [PROPERTIES.BULLET_FAMILY]: computeBulletFamilyFromTextBodies,
    [PROPERTIES.BULLET_FONT_WEIGHT]: computeBulletFontWeightFromTextBodies,
    [PROPERTIES.BULLET_FONT_STYLE]: computeBulletFontStyleFromTextBodies,
    [PROPERTIES.BULLETS]: computeBulletsFromTextBodies,
    [PROPERTIES.BULLET_SIZE]: computeBulletSizeFromTextBodies,
    [PROPERTIES.BULLET_TEXT]: computeBulletTextFromTextBodies,
    [PROPERTIES.BULLET_LEVEL]: computeBulletLevelFromTextBodies,
    [PROPERTIES.BULLET_LEFT_PADDING]: computeBulletLeftPaddingFromTextBodies,
    [PROPERTIES.FONT_COLOR]: computeFontColorFromTextBodies,
    [PROPERTIES.FONT_FAMILY]: computeFontFamilyFromTextBodies,
    [PROPERTIES.FONT_SIZE]: computeFontSizeFromTextBodies,
    [PROPERTIES.FONT_STYLE]: computeFontStyleFromTextBodies,
    [PROPERTIES.FONT_WEIGHT]: computeFontWeightFromTextBodies,
    [PROPERTIES.INDENT]: computeIndentFromTextBodies,
    [PROPERTIES.PLACEHOLDER_BORDER_STROKE]: computePlaceholderBorderStrokeFromTextBodies,
    [PROPERTIES.LINE_SPACING]: computeLineSpacingFromTextBodies,
    [PROPERTIES.LINETHROUGH]: computeLinethroughFromTextBodies,
    [PROPERTIES.MARGINS]: computeMarginsFromTextBodies,
    [PROPERTIES.OVERLINE]: computeOverlineFromTextBodies,
    [PROPERTIES.SPACINGS]: computeSpacingsFromTextBodies,
    [PROPERTIES.SUBSCRIPT]: computeSubscriptFromTextBodies,
    [PROPERTIES.SUPERSCRIPT]: computeSuperscriptFromTextBodies,
    [PROPERTIES.TEXT_ALIGN]: computeAlignFromTextBodies,
    [PROPERTIES.TEXT_DIRECTION]: computeTextDirectionFromTextBodies,
    [PROPERTIES.TEXT_TRANSFORM]: computeTextTransformFromTextBodies,
    [PROPERTIES.UNDERLINE]: computeUnderlineFromTextBodies,
    [PROPERTIES.VERTICAL_ALIGN]: computeVerticalAlignFromTextBodies
};

const BORDERS_COMPUTE_METHOD_FOR_PROPERTY = {
    [PROPERTIES.BORDER_STROKE_DASH_TYPE]: computeStrokeDashTypeFromBorders,
    [PROPERTIES.BORDER_STROKE_FILL]: computeStrokeFillFromBorders,
    [PROPERTIES.BORDER_STROKE_WIDTH]: computeStrokeWidthFromBorders
};

module.exports = {
    BORDERS_COMPUTE_METHOD_FOR_PROPERTY,
    CANVAS_STATE_ITEMS_COMPUTE_METHOD_FOR_PROPERTY,
    CANVAS_STATE_ROTATION_COMPUTE_METHOD_FOR_PROPERTY,
    CANVAS_STATE_LOCK_ASPECT_RATIO_COMPUTE_METHOD_FOR_PROPERTY,
    CANVAS_STATE_TRANSFORM_COMPUTE_METHOD_FOR_PROPERTY,
    CANVAS_STATE_BOUNDING_BOX_COMPUTE_METHOD_FOR_PROPERTY,
    CANVAS_STATE_TEXTBODIES_COMPUTE_METHOD_FOR_PROPERTY,
    CANVAS_STATE_SCALE_COMPUTE_METHOD_FOR_PROPERTY,
    TABLE_COMPUTE_METHOD_FOR_PROPERTY,
    TABLE_COMPUTE_METHOD_FOR_STRUCTURE_PROPERTIES
};
