const {
    FABRIC_DEFAULT_CHAR_SPACING,
    FABRIC_SPACING_FACTOR,
    FABRIC_FONT_SIZE_AUTO_FIT_STEP_MULTIPLIER,
    FABRIC_DEFAULT_TEXT_FILL
} = require('../constants/text');

const { getRgbaObject, stringifyRgbaObject } = require('../../utilities/color');

const intersectObjects = require('../../utilities/intersectObjects');

module.exports = {
    getSharedStyleInCharacterStyleMap(characterStyleMap = {}) {
        const flattenCharacterStyles = Object.values(characterStyleMap)
            .reduce(
                (acc, lineStyles = {}) => [
                    ...acc,
                    ...Object.values(lineStyles)
                ],
                []
            );

        return intersectObjects(...flattenCharacterStyles);
    },

    getCharacterSpacing(paragraphOptions) {
        if (paragraphOptions.charSpacing) {
            const pixelSpacing = paragraphOptions.charSpacing / (3 / 4);
            return (pixelSpacing / paragraphOptions.fontSize) * FABRIC_SPACING_FACTOR;
        }
        return FABRIC_DEFAULT_CHAR_SPACING;
    },

    reduceFontSizeInParagraphs(paragraphs) {
        return paragraphs.map(paragraph => ({
            ...paragraph,
            styles: paragraph.styles.map(line => Object.entries(line).reduce(
                (currentLine, [charIndex, style]) => {
                    const updatedStyle = {
                        ...style
                    };
                    if (updatedStyle.fontSize) {
                        updatedStyle.fontSize -= (
                            updatedStyle.fontSize * FABRIC_FONT_SIZE_AUTO_FIT_STEP_MULTIPLIER
                        );
                    }
                    return {
                        ...currentLine,
                        [charIndex]: updatedStyle
                    };
                },
                {}
            ))
        }));
    },

    updateBulletFontSizeInParagraphs(paragraphs) {
        return paragraphs.map(paragraph => {
            const {
                bullet,
                bulletStyle,
                level
            } = paragraph;

            let updatedParagraph = paragraph;

            if (bullet) {
                bullet.fontSize -= (bullet.fontSize * FABRIC_FONT_SIZE_AUTO_FIT_STEP_MULTIPLIER);
                bulletStyle.fontSize -= (bulletStyle.fontSize * FABRIC_FONT_SIZE_AUTO_FIT_STEP_MULTIPLIER);
                updatedParagraph = {
                    ...paragraph,
                    paddingLeft: ((level * 2) - 1) * bullet.fontSize,
                    bullet,
                    bulletStyle
                };
            }

            return updatedParagraph;
        });
    },

    getBulletFillFromContentText(fill, contentText) {
        const {
            a: alphaComponent,
            ...colorComponents
        } = getRgbaObject(fill || FABRIC_DEFAULT_TEXT_FILL);

        return stringifyRgbaObject({
            ...colorComponents,
            a: contentText.length > 0 ? alphaComponent : 0
        });
    }
};
