import { List } from 'immutable';
import { cloneShape } from '../../Helpers/helpers';

const copyShapes = (canvasState, command) => canvasState
    .set(
        'clipboard',
        canvasState
            .get('selection')
            .reduce(
                (clipboard, id) => clipboard.push(
                    cloneShape(canvasState, id)
                        .set('isCut', command.get('isCut'))
                        .set('originalPage', canvasState.getIn(['dynamicValues', 'pageNumber']))
                ),
                new List()
            )
    );

export default copyShapes;
