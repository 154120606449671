const Tip = require('./Tip');
const { PathBuilder } = require('../../PathBuilder');
const geometry = require('../../geometry');

class ArrowTip extends Tip {
    constructor(
        lineWidth,
        widthModifier,
        lengthModifier,
        arrowWidth = 0.3,
        arrowLength = 1.4,
        degrees = 60
    ) {
        super(lineWidth, widthModifier, lengthModifier);
        this.arrowWidth = arrowWidth;
        this.arrowLength = arrowLength;
        this.reflectionLine = { // ax + b line for center point and reflection
            a: Math.tan((degrees * Math.PI) / 180),
            b: 0
        };
        this.degrees = degrees;
    }

    get path() {
        // Top left
        const topLeftPoint = { // Cartesian coords
            x: 0,
            y: this.arrowLength
        };

        // Top right
        const topRightPoint = { // Cartesian coords
            x: this.arrowWidth,
            y: this.arrowLength
        };

        const reflectedtopLeftPoint = this.calculateReflectedPoint(topLeftPoint);
        const reflectedtopRightPoint = this.calculateReflectedPoint(topRightPoint);
        const intersection = this.intersectionPoint();

        return new PathBuilder()
            .moveTo({
                x: topLeftPoint.x,
                y: -topLeftPoint.y
            })
            .arcTo({
                rx: this.arrowWidth / 2,
                ry: this.arrowWidth / 2,
                xAxisRotation: 0,
                largeArcFlag: 0,
                sweepFlag: 1,
                x: topRightPoint.x,
                y: -topRightPoint.y
            })
            .lineTo({
                x: intersection.x,
                y: -intersection.y
            })
            .lineTo({
                x: reflectedtopRightPoint.x,
                y: -reflectedtopRightPoint.y
            })
            .arcTo({
                rx: this.arrowWidth / 2,
                ry: this.arrowWidth / 2,
                xAxisRotation: 0,
                largeArcFlag: 0,
                sweepFlag: 1,
                x: reflectedtopLeftPoint.x,
                y: -reflectedtopLeftPoint.y
            })
            .lineTo({ x: 0, y: 0 })
            .close()
            .rotate(this.degrees, 0, 0)
            .scale(this.pathLength, this.pathWidth);
    }

    get length() {
        const lengthRatio = geometry.distanceBetweenPoints(
            { x: 0, y: 0 },
            this.intersectionPoint()
        );
        return this.pathLength * lengthRatio;
    }

    // This calculates the center point of the arrow
    intersectionPoint() {
        return {
            x: this.arrowWidth,
            y: (this.reflectionLine.a * this.arrowWidth) + this.reflectionLine.b
        };
    }

    calculateReflectedPoint(point) {
        const d = (point.x + ((point.y - this.reflectionLine.b) * this.reflectionLine.a)) /
                (1 + (this.reflectionLine.a ** 2));
        return {
            x: (2 * d) - point.x,
            y: ((2 * d * this.reflectionLine.a) - point.y) + (2 * this.reflectionLine.b)
        };
    }
}

module.exports = ArrowTip;
