import CanvasStateSelectors from '../../../Canvas/CanvasStateSelectors';
import getPropertiesForDestructuring from '../../utilities/getPropertiesForDestructuring';

const updateShapes = (canvasState, command) => {
    const {
        shapes
    } = getPropertiesForDestructuring(command, ['shapes']);

    return shapes.reduce((updatedCanvasState, shape) => updatedCanvasState.setIn(
        CanvasStateSelectors.getShapePathById(canvasState, shape.get('id')),
        shape
    ), canvasState);
};

export default updateShapes;
