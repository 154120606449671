import { List, Map } from 'immutable';
import * as kindDescriptors from '../Preset/kinds';
import omit from '../../utilities/omit';

const applyStyle = (shape = Map(), styleDefinition = Map(), type = '') => {
    if (styleDefinition.keySeq().size) {
        const referencedProperties = getKindReferenceProperties(styleDefinition.get('kind'));
        const referenceStyle = getReferencedStylesFromProperties(
            referencedProperties,
            styleDefinition,
            type
        );
        const propertiesToOmit = getPropertiesToOmitForTargetType(type);
        return omit(
            applyKindStyle(referenceStyle, styleDefinition)
                .merge(shape),
            propertiesToOmit
        );
    }
    return undefined;
};

const getKindReferenceProperties = kind => getKindPropertyDescriptors(kind)
    .entrySeq()
    .filter(descriptor => descriptor[1].get('isReference') === true)
    .map(([property]) => property)
    .toList();

const getKindPropertyDescriptors = kind => getKindSpecificPropertyDescriptors('SharedProps')
    .merge(getKindSpecificPropertyDescriptors(kind));

const getKindSpecificPropertyDescriptors = kind => {
    if (!Object.keys(kindDescriptors).includes(kind)) {
        throw new Error(`${kind} is an invalid kind`);
    }

    return kindDescriptors[kind] || Map();
};

const getReferencedStylesFromProperties = (
    referencedProperties = List(),
    styleDefinition,
    type
) => referencedProperties
    .filter(referencedProperty => styleDefinition.get(referencedProperty))
    .reduce(
        (currentShapeStyle, referencedProperty) => (
            getReferencedStylesFromPropertyWithParent(
                currentShapeStyle,
                referencedProperty,
                styleDefinition,
                type
            ) ||
                applyStyle(
                    currentShapeStyle,
                    styleDefinition.get(referencedProperty),
                    type
                )
        ),
        Map()
    );

const getReferencedStylesFromPropertyWithParent = (
    currentShapeStyle = Map(),
    referencedProperty,
    styleDefinition,
    type
) => {
    const propertyDescriptor = getKindPropertyDescriptor(
        styleDefinition.get('kind'),
        referencedProperty
    );
    if (propertyDescriptor.get('referenceUpdateParent')) {
        return currentShapeStyle
            .setIn(
                propertyDescriptor
                    .get('referenceUpdateParent')
                    .split('.'),
                applyStyle(
                    Map(),
                    styleDefinition.get(referencedProperty),
                    type
                )
            );
    }
    return undefined;
};

const getKindPropertyDescriptor = (kind, property) => getKindPropertyDescriptors(kind)
    .get(property);

const getPropertiesToOmitForTargetType = type => {
    switch (type.toLowerCase()) {
        case 'ellipse':
            return ['rx', 'ry'];
        default:
            return [];
    }
};

const applyKindStyle = (shape, styleDefinition = Map()) => getKindPropertyDescriptors(styleDefinition.get('kind'))
    .entrySeq()
    .reduce(
        (
            shapeStyle,
            [styleDefinitionProperty, conversionDescriptor]
        ) => applyStyleProperty(
            styleDefinition.get(styleDefinitionProperty),
            conversionDescriptor,
            shapeStyle,
            styleDefinition
        ),
        shape
    );

const applyStyleProperty = (
    value,
    conversionDescriptor = Map(),
    shapeStyle = Map(),
    styleDefinition = Map()
) => {
    if (conversionDescriptor.get('toItemUpdate')) {
        return conversionDescriptor.get('toItemUpdate')(
            value,
            shapeStyle,
            styleDefinition
        );
    }
    if (conversionDescriptor.get('itemUpdatePropertyName')) {
        return shapeStyle
            .setIn(
                conversionDescriptor.get('itemUpdatePropertyName').split('.'),
                value
            );
    }
    return shapeStyle;
};

export {
    // eslint-disable-next-line import/prefer-default-export
    applyStyle
};
