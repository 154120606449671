import { getShapePathById } from '../../../../../../Canvas/CanvasStateSelectors';
import { setStrokeOpacityFromFill } from '../../../../../../CanvasState/Helpers/Style/stroke';

const getStroke = (table, border, canvasState) => {
    const oldShape = canvasState.getIn([
        ...getShapePathById(canvasState, table.id),
        'borders',
        table.borders
            .findIndex(currentBorder => currentBorder.id === border.id)
    ]);

    const stroke = oldShape.get('stroke') || oldShape.getIn(['assignedStyles', 'stroke']);
    return setStrokeOpacityFromFill(
        stroke,
        canvasState.get('colorPalette')
    );
};

export default getStroke;
