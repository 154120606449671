const Tip = require('./Tip');
const { PathBuilder } = require('../../PathBuilder');

class DiamondTip extends Tip {
    constructor(lineWidth, widthModifier, lengthModifier) {
        super(lineWidth, widthModifier, lengthModifier);
        this.recenterPath = true;
    }

    get path() {
        return new PathBuilder()
            .moveTo({ x: 0, y: 0 })
            .lineTo({ x: 0.5, y: -0.5 })
            .lineTo({ x: 1, y: 0 })
            .lineTo({ x: 0.5, y: 0.5 })
            .close()
            .scale(this.pathLength, this.pathWidth)
            .translate({ x: -this.pathLength / 2, y: 0 });
    }
}

module.exports = DiamondTip;
