import * as types from '#Constants/ActionTypes';

const initialState = {
    activeColorPaletteView: 'custom',
    activeColorScheme: '',
    activeLogoSelectionView: 'imported',
    activePageThumbnail: 'graphic',
    colorInputs: {
        CP1: undefined,
        CS1: undefined,
        CS2: undefined
    },
    logoSource: '',
    previouslyUsedLogoSources: [],
    isColorPaletteSaving: false,
    isFetchingPreviouslyUsedLogoSources: false,
    isFetchingThemes: false,
    selectedColorPalette: '',
    selectedTheme: '',
    themes: []
};

export default function styleSelection(state = initialState, action) {
    switch (action.type) {
        case types.FETCH_THEMES:
            return {
                ...state,
                isFetchingThemes: true
            };
        case types.SET_THEMES:
            return {
                ...state,
                themes: action.themes,
                isFetchingThemes: false
            };
        case types.SELECT_THEME:
            return {
                ...state,
                selectedTheme: action.themeId
            };
        case types.CHANGE_ACTIVE_COLOR_PALETTE_VIEW:
            return {
                ...state,
                activeColorPaletteView: action.viewId
            };
        case types.CHANGE_ACTIVE_COLOR_SCHEME:
            return {
                ...state,
                activeColorScheme: action.scheme
            };
        case types.CHANGE_PRIMARY_COLOR_INPUT:
            return {
                ...state,
                selectedColorPalette: '',
                colorInputs: {
                    ...state.colorInputs,
                    CP1: action.color
                }
            };
        case types.CHANGE_SECONDARY_COLOR_INPUT:
            return {
                ...state,
                selectedColorPalette: '',
                colorInputs: {
                    ...state.colorInputs,
                    CS1: action.color
                }
            };
        case types.CHANGE_ACCENT_COLOR_INPUT:
            return {
                ...state,
                selectedColorPalette: '',
                colorInputs: {
                    ...state.colorInputs,
                    CS2: action.color
                }
            };
        case types.SAVING_COLOR_PALETTE:
            return {
                ...state,
                isColorPaletteSaving: true
            };
        case types.SELECT_COLOR_PALETTE:
            return {
                ...state,
                isColorPaletteSaving: false,
                selectedColorPalette: action.colorPaletteId
            };
        case types.RESET_COLOR_INPUTS:
            return {
                ...state,
                colorInputs: {
                    CP1: undefined,
                    CS1: undefined,
                    CS2: undefined
                },
                selectedColorPalette: ''
            };
        case types.RESET_SECONDARY_COLOR_INPUT:
            return {
                ...state,
                colorInputs: {
                    ...state.colorInputs,
                    CS1: undefined,
                    CS2: undefined
                },
                selectedColorPalette: ''
            };
        case types.RESET_ACCENT_COLOR_INPUT:
            return {
                ...state,
                colorInputs: {
                    ...state.colorInputs,
                    CS2: undefined
                },
                selectedColorPalette: ''
            };
        case types.CHANGE_LOGO_SOURCE:
            return {
                ...state,
                logoSource: action.logoSource
            };
        case types.FETCH_PREVIOUSLY_USED_LOGO_SOURCES:
            return {
                ...state,
                isFetchingPreviouslyUsedLogoSources: true
            };
        case types.SET_PREVIOUSLY_USED_LOGO_SOURCES:
            return {
                ...state,
                previouslyUsedLogoSources: action.sources,
                isFetchingPreviouslyUsedLogoSources: false
            };
        case types.CHANGE_ACTIVE_LOGO_SELECTION_VIEW:
            return {
                ...state,
                activeLogoSelectionView: action.viewId
            };
        case types.CHANGE_ACTIVE_PAGE_THUMBNAIL:
            return {
                ...state,
                activePageThumbnail: action.pageThumbnailId
            };
        case types.INIT_COLOR_INPUTS:
            return {
                ...state,
                colorInputs: {
                    ...state.colorInputs,
                    ...action.colorInputs
                }
            };
        case types.INIT_SELECTED_COLOR_PALETTE:
            return {
                ...state,
                selectedColorPalette: action.selectedColorPalette
            };
        case types.INIT_SELECTED_THEME:
            return {
                ...state,
                selectedTheme: action.selectedTheme
            };
        default:
            return state;
    }
}
