import { omitBy, isNil } from 'lodash';

const omitNil = data => omitBy(data, isNil);

const removeStyle = (textBodyData, styleIndex, stylesKey, removeFromKey) => textBodyData
    .update(
        removeFromKey,
        removeFromElements => removeFromElements
            .map(element => {
                if (!isNil(element.get('style')) && element.get('style') >= styleIndex) {
                    return element.set('style', element.get('style') - 1);
                }
                return element;
            })
    )
    .update(
        stylesKey,
        styles => styles
            .filter((_, index) => index !== styleIndex)
    );

const isStyleInUse = (style, usedIn) => {
    const used = usedIn.some(element => element.get('style') === style.index);
    const empty = Object.keys(omitNil(style)).length === 0;
    return (used && !empty) || style.style?.get('isDefault') === true;
};

const isStyleUnused = (style, usedIn) => !isStyleInUse(style, usedIn);

const unusedStyleIndexes = (styles, usedIn) => styles
    .map((style, index) => ({
        style,
        index
    }))
    .filter(style => isStyleUnused(style, usedIn));

const removeUnusedStyles = (textBodyData, stylesKey, usedInKey) => unusedStyleIndexes(
    textBodyData.get(stylesKey),
    textBodyData.get(usedInKey)
)
    .sort()
    .reverse()
    .reduce((updatedTextBodyData, { index }) => removeStyle(
        updatedTextBodyData,
        index,
        stylesKey,
        usedInKey
    ), textBodyData);

export default removeUnusedStyles;
