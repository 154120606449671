const DEFAULT_CROP = {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
};

const getStretchedFillProperties = (fill, shape) => {
    const {
        top, right, bottom, left
    } = fill.crop || DEFAULT_CROP;

    return {
        offsetX: left * shape.width,
        offsetY: top * shape.height,
        imageScaleX: (1 - right - left),
        imageScaleY: (1 - bottom - top)
    };
};

const getUnstretchedFillProperties = (fill, shape, img) => {
    const {
        top, right, bottom, left
    } = fill.crop || DEFAULT_CROP;

    const containerWidth = shape.width /
        (1 - (right + left));
    const containerHeight = shape.height /
        (1 - (top + bottom));

    const widthRatio = containerWidth / img.width;
    const heightRatio = containerHeight / img.height;

    const leftCrop = (left * widthRatio) || 0;
    const topCrop = (top * heightRatio) || 0;

    return {
        offsetX: -leftCrop * img.width,
        offsetY: -topCrop * img.height,
        imageScaleX: (1 * widthRatio),
        imageScaleY: (1 * heightRatio)
    };
};

const getLimitingDimension = (img, shape, meetOrSlice) => {
    const imageAspectRatio = img.width / img.height;
    const shapeAspectRatio = shape.getScaledWidth() / shape.getScaledHeight();
    if (imageAspectRatio > shapeAspectRatio) {
        if (meetOrSlice === 'meet') {
            return 'width';
        }
        return 'height';
    }
    if (meetOrSlice === 'meet') {
        return 'height';
    }
    return 'width';
};

const getOffsetOfAlign = (shapeDimension, imgDimension, align) => {
    switch (align) {
        case 'min':
            return 0;
        case 'mid':
            return (shapeDimension / 2) - (imgDimension / 2);
        case 'max':
        default:
            return shapeDimension - imgDimension;
    }
};

const forcePreserveAspectRatioOnFillProps = (fill, fillProps, img, shape) => {
    if (img) {
        const {
            align = {
                x: 'mid',
                y: 'mid'
            },
            meetOrSlice
        } = fill.preserveAspectRatio;
        const limitingDimension = getLimitingDimension(img, shape, meetOrSlice);

        const uniScale = shape[limitingDimension] / img[limitingDimension];

        const scaledFillProps = {
            ...fillProps,
            imageScaleX: uniScale,
            imageScaleY: uniScale
        };
        if (limitingDimension === 'width') {
            scaledFillProps.offsetY += getOffsetOfAlign(
                shape.height,
                img.getScaledHeight(),
                align.y
            );
        } else {
            scaledFillProps.offsetX += getOffsetOfAlign(
                shape.width,
                img.getScaledWidth(),
                align.x
            );
        }
        return scaledFillProps;
    }
    return fillProps;
};

module.exports = (fill, shape, img) => {
    let fillProps = {};

    if (fill.stretch) {
        fillProps = getStretchedFillProperties(fill, shape);
    } else {
        fillProps = getUnstretchedFillProperties(fill, shape, img);
    }

    if (fill.preserveAspectRatio && fill.preserveAspectRatio.meetOrSlice) {
        fillProps = forcePreserveAspectRatioOnFillProps(fill, fillProps, img, shape);
    } else {
        fillProps = {
            ...fillProps,
            imageScaleX: fillProps.imageScaleX,
            imageScaleY: fillProps.imageScaleY
        };
    }

    return fillProps;
};
