import getAssignedStyles from './assignedStyles';
import getFill from './fill';
import getStroke from './stroke';
import { getTextBody, getTextBodyPlaceholder } from './textBody';

const defaultPropertyAdapters = {
    id: fabricShape => fabricShape.id,
    name: fabricShape => fabricShape.name,
    type: fabricShape => fabricShape.type,
    flipX: fabricShape => fabricShape.flipX,
    flipY: fabricShape => fabricShape.flipY,
    left: fabricShape => fabricShape.left,
    top: fabricShape => fabricShape.top,
    width: fabricShape => fabricShape.width,
    height: fabricShape => fabricShape.height,
    style: fabricShape => fabricShape.style,
    angle: fabricShape => fabricShape.angle,
    inLayout: fabricShape => fabricShape.inLayout,
    scaleX: fabricShape => fabricShape.scaleX,
    scaleY: fabricShape => fabricShape.scaleY,
    lockPath: fabricShape => fabricShape.lockPath,
    isBackground: fabricShape => fabricShape.isBackground || false,
    isGroupHidden: fabricShape => fabricShape.isGroupHidden || false,
    isGroupLocked: fabricShape => fabricShape.isGroupLocked || false,
    isHidden: fabricShape => fabricShape.isHidden,
    isImported: fabricShape => fabricShape.isImported,
    isLocked: fabricShape => fabricShape.isLocked,
    skewX: fabricShape => fabricShape.skewX,
    skewY: fabricShape => fabricShape.skewY,
    dynamicProperties: fabricShape => fabricShape.dynamicProperties,
    lockUniScaling: fabricShape => fabricShape.lockUniScaling,
    fill: getFill,
    opacity: fabricShape => fabricShape.opacity,
    stroke: getStroke,
    visible: fabricShape => fabricShape.visible,
    selectable: fabricShape => fabricShape.selectable,
    paragraphs: getTextBody,
    placeholderParagraphs: getTextBodyPlaceholder,
    assignedStyles: getAssignedStyles,
    placeholderSequence: fabricShape => fabricShape.placeholderSequence,
    placeholderSourceId: fabricShape => fabricShape.placeholderSourceId,
    placeholderType: fabricShape => fabricShape.placeholderType
};

export default defaultPropertyAdapters;
