import { fromJS } from 'immutable';
import defaultFromFabricToCanvasState from './default';

const fromFabricToCanvasState = (fabricShape, canvasState) => fromJS({
    ...defaultFromFabricToCanvasState(fabricShape, canvasState),
    type: 'Image',
    source: fabricShape.source,
    clipTo: fabricShape.clipTo,
    path: fabricShape.path
});

export default fromFabricToCanvasState;
