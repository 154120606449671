import get from 'lodash/get';
import uniqBy from 'lodash/uniqBy';

// eslint-disable-next-line no-unused-vars
export const getPaletteColorSchemeFromDeck = deck => get(deck, 'theme.colorScheme') ||
    get(deck, 'originalTheme.colorScheme') ||
    get(deck, 'applicationTheme.colorScheme') ||
    'monochromatic';

export const getColorsPresets = deck => uniqBy([
    ...get(deck, 'theme.styleDefinitions', []),
    ...get(deck, 'originalTheme.styleDefinitions', []),
    ...get(deck, 'applicationTheme.styleDefinitions', [])
], 'id')
    .filter(def => def.kind === 'ColorsPreset');

export const getColorPalette = deck => uniqBy([
    ...get(deck, 'theme.colorPalette', []),
    ...get(deck, 'originalTheme.colorPalette', []),
    ...get(deck, 'applicationTheme.colorPalette', [])
], 'id');

export const getStyleDefinitionById = (deck, styleId) => {
    const flatStyleDefinitions = [
        ...get(deck, 'theme.styleDefinitions', []),
        ...get(deck, 'originalTheme.styleDefinitions', []),
        ...get(deck, 'applicationTheme.styleDefinitions', [])
    ];
    return uniqBy(flatStyleDefinitions, 'id').find(styleDef => styleDef.id === styleId);
};
