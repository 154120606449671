const get = require('lodash/get');
const omit = require('lodash/omit');

const { convertPropertiesToShapeProperties } = require('../../../utilities/convertPropertiesToShapeProperties');

module.exports = Base => class PartMixins extends Base {
    static orderCellsByTopLeft(cells) {
        return cells.sort(
            (current, next) => (current.row - next.row) || (current.column - next.column)
        );
    }

    static get PART_NAMES() {
        return {
            BANDED_COLUMN_EVEN: 'bandedColumnEven',
            BANDED_COLUMN_ODD: 'bandedColumnOdd',
            BANDED_ROW_EVEN: 'bandedRowEven',
            BANDED_ROW_ODD: 'bandedRowOdd',
            CONTENT_COLUMN: 'contentColumn',
            CONTENT_ROW: 'contentRow',
            FIRST_COLUMN: 'firstColumn',
            FIRST_ROW: 'firstRow',
            HEADER_COLUMN: 'headerColumn',
            HEADER_ROW: 'headerRow',
            HEADER_SPANNER_COLUMN: 'headerSpannerColumn',
            HEADER_SPANNER_ROW: 'headerSpannerRow',
            LAST_COLUMN: 'lastColumn',
            LAST_ROW: 'lastRow',
            SUBTOTAL_COLUMN: 'subtotalColumn',
            SUBTOTAL_ROW: 'subtotalRow',
            TOTAL_COLUMN: 'totalColumn',
            TOTAL_ROW: 'totalRow'
        };
    }

    static get IMPLEMENTED_PART_UPDATES() {
        return [
            this.PART_NAMES.CONTENT_COLUMN,
            this.PART_NAMES.CONTENT_ROW,
            this.PART_NAMES.BANDED_COLUMN_EVEN,
            this.PART_NAMES.BANDED_COLUMN_ODD,
            this.PART_NAMES.BANDED_ROW_EVEN,
            this.PART_NAMES.BANDED_ROW_ODD,
            this.PART_NAMES.LAST_COLUMN,
            this.PART_NAMES.LAST_ROW,
            this.PART_NAMES.FIRST_COLUMN,
            this.PART_NAMES.FIRST_ROW,
            this.PART_NAMES.TOTAL_COLUMN,
            this.PART_NAMES.TOTAL_ROW,
            this.PART_NAMES.HEADER_COLUMN,
            this.PART_NAMES.HEADER_ROW,
            this.PART_NAMES.HEADER_SPANNER_COLUMN,
            this.PART_NAMES.HEADER_SPANNER_ROW
        ];
    }

    applyPartUpdates(partUpdates, removeCustomStyles = false) {
        this.PART_UPDATES_SEQUENCE
            .forEach(partName => {
                if (partUpdates[partName]) {
                    const partUpdate = {
                        ...partUpdates[partName],
                        tableStyleMismatches: partUpdates.tableStyleMismatches
                    };
                    this.applyPartUpdate(partName, partUpdate, removeCustomStyles);
                }
            });
    }

    applyPartUpdate(partName, cellUpdate, removeCustomStyles) {
        const cellStyleMismatches = get(
            cellUpdate,
            'tableStyleMismatches.cells',
            []
        );

        const borderStyleMismatches = get(
            cellUpdate,
            'tableStyleMismatches.borders',
            []
        );

        const {
            shapeProps: {
                borderUpdates = {},
                ...cellShapeUpdate
            },
            textProps: textUpdate
        } = convertPropertiesToShapeProperties(cellUpdate);

        const cellIds = this.getPartCellIds(partName)
            .filter(cellId => !cellStyleMismatches.includes(cellId));

        if (this.BANDED_PARTS.includes(partName)) {
            const mismatchedCellIds = this.getPartCellIds(partName)
                .filter(cellId => cellStyleMismatches.includes(cellId));

            this.applyCellUpdate(
                {
                    ids: mismatchedCellIds,
                    ...omit(cellShapeUpdate, 'style')
                },
                undefined,
                undefined,
                {
                    ...borderUpdates,
                    styleMismatches: borderStyleMismatches
                },
                removeCustomStyles
            );
        }

        this.applyCellUpdate(
            {
                ids: cellIds,
                ...cellShapeUpdate
            },
            textUpdate,
            undefined,
            {
                ...borderUpdates,
                styleMismatches: borderStyleMismatches
            },
            removeCustomStyles
        );
    }

    getPartCells(partName) {
        const {
            BANDED_COLUMN_EVEN,
            BANDED_COLUMN_ODD,
            BANDED_ROW_EVEN,
            BANDED_ROW_ODD,
            CONTENT_COLUMN,
            CONTENT_ROW,
            FIRST_COLUMN,
            FIRST_ROW,
            HEADER_COLUMN,
            HEADER_ROW,
            HEADER_SPANNER_COLUMN,
            HEADER_SPANNER_ROW,
            LAST_COLUMN,
            LAST_ROW,
            TOTAL_COLUMN,
            TOTAL_ROW
        } = this.constructor.PART_NAMES;

        switch (partName) {
            case BANDED_COLUMN_EVEN:
                return this.getEvenColumnCells();
            case BANDED_COLUMN_ODD:
                return this.getOddColumnCells();
            case BANDED_ROW_EVEN:
                return this.getEvenRowCells();
            case BANDED_ROW_ODD:
                return this.getOddRowCells();
            case CONTENT_COLUMN:
                return this.getContentCells();
            case CONTENT_ROW:
                return this.getContentCells();
            case FIRST_COLUMN:
                if (this.mainAxis === 'horizontal') {
                    return this.getFirstColumn();
                }
                return [];
            case FIRST_ROW:
                if (this.mainAxis === 'vertical') {
                    return this.getFirstRow();
                }
                return [];
            case HEADER_COLUMN:
                return this.getHeaderColumn();
            case HEADER_ROW:
                return this.getHeaderRow();
            case HEADER_SPANNER_ROW:
                return this.getHeaderSpannerRow();
            case HEADER_SPANNER_COLUMN:
                return this.getHeaderSpannerColumn();
            case LAST_COLUMN:
                if (this.mainAxis === 'horizontal') {
                    return this.getLastColumn();
                }
                return [];
            case LAST_ROW:
                if (this.mainAxis === 'vertical') {
                    return this.getLastRow();
                }
                return [];
            case TOTAL_COLUMN: {
                if (this.hasTotalColumn) {
                    return this.getLastColumn();
                }
                return [];
            }
            case TOTAL_ROW: {
                if (this.hasTotalRow) {
                    return this.getLastRow();
                }
                return [];
            }
            default:
                return [];
        }
    }

    getPartCellIds(partName) {
        return this.getPartCells(partName).map(({ id }) => id);
    }

    getOddBandedColumnIndexes() {
        if (!this.hasBandedColumns) {
            return [];
        }
        const offsetWithFirstColumn = this.hasHeaderColumn + this.hasHeaderSpannerColumn;
        const offsetWithLastColumn = this.hasTotalColumn;
        return Array.from(
            Array(
                Math.max(this.columnCount - (offsetWithFirstColumn + offsetWithLastColumn), 0)
            )
                .keys()
        )
            .map(index => (index % 2 === 0 ?
                index + offsetWithFirstColumn :
                false
            ))
            .filter(index => index !== false);
    }

    getEvenBandedColumnIndexes() {
        if (!this.hasBandedColumns) {
            return [];
        }
        const offsetWithFirstColumn = this.hasHeaderColumn + this.hasHeaderSpannerColumn;
        const offsetWithLastColumn = this.hasTotalColumn;
        return Array.from(
            Array(
                Math.max(this.columnCount - (offsetWithFirstColumn + offsetWithLastColumn), 0)
            )
                .keys()
        )
            .map(index => (index % 2 === 1 ?
                index + offsetWithFirstColumn :
                false
            ))
            .filter(index => index !== false);
    }

    getOddBandedRowIndexes() {
        if (!this.hasBandedRows) {
            return [];
        }
        const offsetWithFirstRow = this.hasHeaderRow + this.hasHeaderSpannerRow;
        const offsetWithLastRow = this.hasTotalRow;
        return Array.from(
            Array(Math.max(this.rowCount - (offsetWithFirstRow + offsetWithLastRow), 0)).keys()
        )
            .map(index => (index % 2 === 0 ?
                index + offsetWithFirstRow :
                false
            ))
            .filter(index => index !== false);
    }

    getEvenBandedRowIndexes() {
        if (!this.hasBandedRows) {
            return [];
        }
        const offsetWithFirstRow = this.hasHeaderRow + this.hasHeaderSpannerRow;
        const offsetWithLastRow = this.hasTotalRow;
        return Array.from(
            Array(Math.max(this.rowCount - (offsetWithFirstRow + offsetWithLastRow), 0)).keys()
        )
            .map(index => (index % 2 === 1 ?
                index + offsetWithFirstRow :
                false
            ))
            .filter(index => index !== false);
    }

    getContentCells() {
        const firstColumn = 0 + this.hasHeaderColumn;
        const firstRow = 0 + this.hasHeaderRow;
        const lastColumn = this.columnCount - (1 + this.hasTotalColumn);
        const lastRow = this.rowCount - (1 + this.hasTotalRow);
        return Object.values(this.cells)
            .filter(cell => {
                const afterFirstColumn = cell.column >= firstColumn;
                const afterFirstRow = cell.row >= firstRow;
                const beforeLastColumn = cell.column + (cell.columnSpan - 1) <= lastColumn;
                const beforeLastRow = cell.row + (cell.rowSpan - 1) <= lastRow;
                return (
                    afterFirstColumn &&
                    afterFirstRow &&
                    beforeLastColumn &&
                    beforeLastRow
                );
            });
    }

    getOddColumnCells() {
        const columnIndexes = this.getOddBandedColumnIndexes();
        return this.getContentCells()
            .filter(cell => columnIndexes.includes(cell.column));
    }

    getEvenColumnCells() {
        const columnIndexes = this.getEvenBandedColumnIndexes();
        return this.getContentCells()
            .filter(cell => columnIndexes.includes(cell.column));
    }

    getOddRowCells() {
        const rowIndexes = this.getOddBandedRowIndexes();
        return this.getContentCells()
            .filter(cell => rowIndexes.includes(cell.row));
    }

    getEvenRowCells() {
        const rowIndexes = this.getEvenBandedRowIndexes();
        return this.getContentCells()
            .filter(cell => rowIndexes.includes(cell.row));
    }

    getHeaderColumn() {
        if (this.hasHeaderColumn) {
            return this.getCellsInColumn(Number(this.hasHeaderSpannerColumn));
        }
        return [];
    }

    getHeaderRow() {
        if (this.hasHeaderRow) {
            return this.getCellsInRow(Number(this.hasHeaderSpannerRow));
        }
        return [];
    }

    getHeaderSpannerColumn() {
        if (this.hasHeaderSpannerRow) {
            return this.getFirstColumn()
                .filter(cell => cell.isHeaderSpannerColumn);
        }
        return [];
    }

    getHeaderSpannerRow() {
        if (this.hasHeaderSpannerRow) {
            return this.getFirstRow()
                .filter(cell => cell.isHeaderSpannerRow);
        }
        return [];
    }

    get PART_UPDATES_SEQUENCE() {
        if (this.mainAxis === 'vertical') {
            return [
                this.constructor.PART_NAMES.CONTENT_ROW,
                this.constructor.PART_NAMES.BANDED_COLUMN_ODD,
                this.constructor.PART_NAMES.BANDED_ROW_EVEN,
                this.constructor.PART_NAMES.BANDED_ROW_ODD,
                this.constructor.PART_NAMES.TOTAL_COLUMN,
                this.constructor.PART_NAMES.HEADER_COLUMN,
                this.constructor.PART_NAMES.HEADER_SPANNER_COLUMN,
                this.constructor.PART_NAMES.TOTAL_ROW,
                this.constructor.PART_NAMES.HEADER_ROW,
                this.constructor.PART_NAMES.HEADER_SPANNER_ROW
            ]
                .filter(partName => (
                    this.constructor.IMPLEMENTED_PART_UPDATES.includes(partName)
                ));
        }
        return [
            this.constructor.PART_NAMES.CONTENT_COLUMN,
            this.constructor.PART_NAMES.BANDED_ROW_ODD,
            this.constructor.PART_NAMES.BANDED_COLUMN_EVEN,
            this.constructor.PART_NAMES.BANDED_COLUMN_ODD,
            this.constructor.PART_NAMES.TOTAL_ROW,
            this.constructor.PART_NAMES.HEADER_ROW,
            this.constructor.PART_NAMES.HEADER_SPANNER_ROW,
            this.constructor.PART_NAMES.TOTAL_COLUMN,
            this.constructor.PART_NAMES.HEADER_COLUMN,
            this.constructor.PART_NAMES.HEADER_SPANNER_COLUMN
        ]
            .filter(partName => (
                this.constructor.IMPLEMENTED_PART_UPDATES.includes(partName)
            ));
    }

    get BANDED_PARTS() {
        return [
            this.constructor.PART_NAMES.BANDED_ROW_EVEN,
            this.constructor.PART_NAMES.BANDED_ROW_ODD,
            this.constructor.PART_NAMES.BANDED_COLUMN_EVEN,
            this.constructor.PART_NAMES.BANDED_COLUMN_ODD
        ];
    }
};
