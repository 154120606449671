module.exports = {
    dragStartingPoint: {
        x: undefined,
        y: undefined,
        locked: false
    },

    originalMousePosition: {
        x: undefined,
        y: undefined
    },

    originalPosition: {
        x: undefined,
        y: undefined
    },

    originalPositionG: {
        x: 0,
        y: 0
    },

    thresholdForAxisSwap: 15,
    minMouseDistanceForAxisChange: 16,

    axisCanBeSwap: true,

    handleAxisLocking(e) {
        const event = e.e ? e.e : e;
        if (event.shiftKey) {
            this.setInitialPositions(event);
            if (this.axisCanBeSwap) {
                this.swapAxisLocked(event);
            }
            if (!this.axisCanBeSwap && this.swapTresholdReached()) {
                this.axisCanBeSwap = true;
            }
        }
    },

    setInitialPositions(event) {
        if (!this.dragStartingPoint.x && !this.dragStartingPoint.y) {
            this.dragStartingPoint = {
                x: this.getActiveObjectLeft(),
                y: this.getActiveObjectTop(),
                locked: false
            };
        }
        if (!this.originalMousePosition.x && !this.originalMousePosition.y) {
            this.originalMousePosition = {
                x: event.clientX,
                y: event.clientY
            };
        }
        if (!this.originalPosition.x && !this.originalPosition.y) {
            this.setInitialPositionsToCurrentPosition();
        }
    },

    setInitialPositionsToCurrentPosition() {
        this.originalPosition = {
            x: this.getActiveObjectLeft(),
            y: this.getActiveObjectTop()
        };
    },

    swapTresholdReached() {
        return Math.abs(this.getActiveObjectLeft() - this.originalPosition.x) >
        this.thresholdForAxisSwap ||
        Math.abs(this.getActiveObjectTop() - this.originalPosition.y) > this.thresholdForAxisSwap;
    },

    swapAxisLocked(event) {
        if (this.mousePositionDelta(event).x > this.mousePositionDelta(event).y) {
            if (Math.abs(event.clientX - this.originalMousePosition.x) >
             this.minMouseDistanceForAxisChange) {
                this.lockMovement(false, true);
                this.dragStartingPoint.locked = true;
            }
        } else if (Math.abs(event.clientY - this.originalMousePosition.y) >
        this.minMouseDistanceForAxisChange) {
            this.lockMovement(true, false);
            this.dragStartingPoint.locked = true;
        }
        this.axisCanBeSwap = false;
    },

    mousePositionDelta(event) {
        return {
            x: Math.abs(event.clientX - this.originalMousePosition.x),
            y: Math.abs(event.clientY - this.originalMousePosition.y)
        };
    },

    resetAxisLocking(horizontal, vertical) {
        this.lockMovement(horizontal, vertical);
        this.dragStartingPoint = {
            x: undefined,
            y: undefined,
            locked: false
        };
        this.originalMousePosition = {
            x: undefined,
            y: undefined
        };
        this.originalPosition = {
            x: undefined,
            y: undefined
        };
        this.axisCanBeSwap = true;
    },

    lockMovement(horizontal = this.isType('table'), vertical = this.isType('table')) {
        this.lockMovementX = horizontal;
        this.lockMovementY = vertical;
        if (horizontal && this.originalPosition.x) {
            if (this.shapeType === 'Group' && this.originalParentPosition) {
                if (this.top === this.originalParentPosition.top &&
                    this.left === this.originalParentPosition.left) {
                    this.setActiveObjectLeft(this.originalPosition.x);
                }
            } else if (this.originalParentPosition) {
                this.setActiveObjectLeft(this.originalPosition.x + this.originalParentPosition.left);
            } else this.setActiveObjectLeft(this.originalPosition.x);
        }
        if (vertical && this.originalPosition.y) {
            if (this.shapeType === 'Group' && this.originalParentPosition) {
                if (this.top === this.originalParentPosition.top &&
                    this.left === this.originalParentPosition.left) {
                    this.setActiveObjectLeft(this.originalPosition.y);
                }
            } else if (this.originalParentPosition) {
                this.setActiveObjectTop(this.originalPosition.y + this.originalParentPosition.top);
            } else this.setActiveObjectTop(this.originalPosition.y);
        }
        this.setCoords();
        if (this.canvas) {
            this.canvas.requestRenderAll();
        }
    }
};
