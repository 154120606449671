export const KEYBOARD_NAVIGATION_FOR_CELLS = true;

export const features = {
    plan: {
        style: {
            display: true
        }
    },
    build: {
        navigation: {
            objects: {
                isImported: {
                    display: false
                }
            }
        }
    },
    colorTest: {
        display: false
    }
};
