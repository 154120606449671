module.exports = fabric => {
    /* eslint-disable */

    /*
    * Part of the missing code for handling nested groups in addWithUpdate in Fabric 4.4.0
    * See PR https://github.com/fabricjs/fabric.js/pull/6774/
    */
    fabric.util.removeTransformFromObject = function (object, transform) {
        var inverted = fabric.util.invertTransform(transform),
        finalTransform = fabric.util.multiplyTransformMatrices(inverted, object.calcOwnMatrix());
        fabric.util.applyTransformToObject(object, finalTransform);
    };

    /*
    * Part of the missing code for handling nested groups in addWithUpdate in Fabric 4.4.0
    * See PR https://github.com/fabricjs/fabric.js/pull/6774/
    */
    fabric.util.addTransformToObject = function (object, transform) {
        fabric.util.applyTransformToObject(
            object,
            fabric.util.multiplyTransformMatrices(transform, object.calcOwnMatrix())
        );
    }

    /*
    * Part of the missing code for handling nested groups in addWithUpdate in Fabric 4.4.0
    * See PR https://github.com/fabricjs/fabric.js/pull/6774/
    */
    fabric.util.applyTransformToObject = function (object, transform) {
        var options = fabric.util.qrDecompose(transform),
            center = new fabric.Point(options.translateX, options.translateY);
        object.flipX = false;
        object.flipY = false;
        object.set('scaleX', options.scaleX);
        object.set('scaleY', options.scaleY);
        object.skewX = options.skewX;
        object.skewY = options.skewY;
        object.angle = options.angle;
        object.setPositionByOrigin(center, 'center', 'center');
    }
};
