import {
    setGenericPageInfoFromLayout,
    removeLinkedLayoutPlaceholders,
    removeLayoutShapes,
    getShapesFromLayout,
    getLayoutPlaceholders,
    getPagePlaceholders,
    copyPlaceholderToPage
} from '../../Helpers/helpers';
import getPropertiesForDestructuring from '../../utilities/getPropertiesForDestructuring';

const layoutReset = (canvasState, command) => {
    const {
        layout
    } = getPropertiesForDestructuring(command, ['layout']);

    let updatedCanvasState = setGenericPageInfoFromLayout(canvasState, layout);

    updatedCanvasState = updatedCanvasState.set('shapes', removeLinkedLayoutPlaceholders(updatedCanvasState.get('shapes')));

    const layoutShapes = getShapesFromLayout(updatedCanvasState, layout);

    updatedCanvasState = removeLayoutShapes(updatedCanvasState);

    updatedCanvasState = updatedCanvasState.merge(layoutShapes);

    getLayoutPlaceholders(updatedCanvasState).forEach(layoutPlaceholder => {
        if (!getPagePlaceholders(updatedCanvasState)
            .find(placeholder => placeholder.get('placeholderSequence') === layoutPlaceholder.get('placeholderSequence'))
        ) {
            updatedCanvasState = copyPlaceholderToPage(updatedCanvasState, layoutPlaceholder);
        }
    });

    return updatedCanvasState;
};

export default layoutReset;
