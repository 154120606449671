import { uniqBy } from 'lodash';
import { fetchStoryboardsList } from '#Actions/plan/storyboards/storyboards';
import client from '#Utilities/graphql';
import * as types from '#Constants/ActionTypes';

const getObjectivesTypes = objectives => {
    const distinctArr = [];
    const unique = {};

    objectives.forEach(objective => {
        if (!unique[objective.type]) {
            distinctArr.push(objective.type);
            unique[objective.type] = true;
        }
    });

    return distinctArr;
};

const getObjectivesCategoriesByTypes = (objectives, objectivesTypes) => uniqBy(objectives, 'category')
    .filter((objective, index, originalArray) => originalArray
        .find(originalObjective => objectivesTypes
            .includes(objective.type) && objective.category === originalObjective.category))
    .map(objective => objective.category);

const setFilteredObjectivesList = (filteredObjectives, objectiveType) => ({
    type: types.SET_FILTERED_OBJECTIVES_LIST,
    filteredObjectives,
    objectiveType
});

const setSelectedObjective = selectedObjective => dispatch => dispatch(fetchStoryboardsList(selectedObjective.id))
    .then(() => {
        dispatch({
            type: types.SET_SELECTED_OBJECTIVE,
            selectedObjective,
            selectedType: selectedObjective.type,
            selectedCategory: selectedObjective.category
        });
    });

const setInitialObjective = initialObjective => ({
    type: types.SET_INITIAL_OBJECTIVE,
    initialObjective
});

const getFilterObjectivesIntoCategories = (objectives, objectiveType) => {
    const objByTypes = objectives.filter(({ type }) => type === objectiveType);

    const categories = uniqBy(objByTypes, 'category').map(objective => objective.category);

    const filteredObjectives = categories.reduce(
        (categoriesToObjectives, category) => (
            {
                ...categoriesToObjectives,
                [category]: objByTypes.filter(objective => objective.category === category)
            }),
        {}
    );

    return { objectiveType, filteredObjectives };
};

const fetchObjectivesList = () => dispatch => {
    dispatch({ type: types.FETCH_OBJECTIVES_LIST });

    return client.fetchObjectives()
        .then(objectives => {
            const objectivesTypes = getObjectivesTypes(objectives);
            const categories = getObjectivesCategoriesByTypes(objectives, ['Occupation']);
            let filteredObjectives = {};
            const listsOfObjectivesByTypes = objectivesTypes
                .map(type => getFilterObjectivesIntoCategories(objectives, type));
            listsOfObjectivesByTypes.forEach(list => {
                filteredObjectives = Object.assign(filteredObjectives,
                    { [list.objectiveType]: list.filteredObjectives });
            });
            dispatch({
                type: types.SET_OBJECTIVES_LIST,
                objectives,
                filteredObjectives,
                categories
            });
        });
};

export {
    fetchObjectivesList,
    setFilteredObjectivesList,
    setSelectedObjective,
    setInitialObjective
};
