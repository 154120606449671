import * as types from '#Constants/ActionTypes';

const initialState = {
    blackPageEnd: false,
    autoplayDelay: 30,
    autoplay: false,
    spaceBarAutoplayControlSettings: false,
    arrowKeysControlSetting: true,
    spaceBarBackspaceControlSetting: false,
    pageUpPageDownControlSetting: true,
    mouseButtonsControlSetting: true,
    endPresentationPage: 'Last page'
};

export const settings = (state = initialState, action) => {
    switch (action.type) {
        case types.SET_VIEWER_SETTINGS:
            return {
                ...state,
                ...action.settings
            };
        default:
            return state;
    }
};
