const Bounds = require('./Bounds');

class TriangleBounds extends Bounds {
    get dimensions() {
        return {
            width: this.shapeWidth / 2,
            height: this.shapeHeight / 2
        };
    }

    get position() {
        return {
            x: this._attributes.topVertex.x / 2,
            y: (this._attributes.flipY ? -(this.shapeHeight - this.dimensions.height) :
                (this.shapeHeight - this.dimensions.height)) / 2
        };
    }
}

module.exports = TriangleBounds;
