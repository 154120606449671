import { getTrapezoidPoints } from '../../../../../utilities/ShapeGenerators';
import getBaseLength from './baseLength';

const getPoints = shape => getTrapezoidPoints(
    shape.get('width'),
    shape.get('height'),
    getBaseLength(shape)
);

export default getPoints;
