const { fabric } = require('fabric');
const { getGradientObject } = require('../utilities/gradientGenerator');
const pixelRoundErrorInsensitiveEqual = require('../../utilities/pixelRoundErrorInsensitiveEqual.js');
const tableGridLogic = require('../../utilities/table/tableGridLogic');

const Border = fabric.util.createClass(fabric.Line, {
    type: 'border',

    initialize(json) {
        this.callSuper('initialize', [json.x1, json.y1, json.x2, json.y2], { ...json, originX: 'center', originY: 'center' });
        this.originX = 'center';
        this.originY = 'center';
        this.row = json.row;
        this.column = json.column;
        this.side = json.side;
        this.table = json.table;
        this.styleId = json.styleId;
        this.applyFill();
        this.setCoords();
    },

    toObject(propertiesToInclude = []) {
        const serializedBorder = this.callSuper('toObject', [
            ...propertiesToInclude,
            'id',
            'name',
            'strokeAlign',
            'strokeCompoundType',
            'strokeFill',
            'strokeHead',
            'strokeTail',
            'side',
            'lineDash',
            'strokeDashName',
            'row',
            'column',
            'x1',
            'x2',
            'y1',
            'y2',
            'styleId'
        ]);
        delete serializedBorder.height;
        delete serializedBorder.version;
        delete serializedBorder.width;
        delete serializedBorder.fill;
        delete serializedBorder.stroke;
        return serializedBorder;
    },

    applyFill() {
        switch (this.strokeFill.type) {
            case 'color':
                this.set('stroke', this.strokeFill.color.value);
                break;
            case 'gradient':
                this.setGradient('stroke', getGradientObject({
                    gradient: this.strokeFill.gradient,
                    width: this.width,
                    height: this.height,
                    angle: this.angle
                }));
                break;
            default:
                break;
        }
    },

    isHorizontal() {
        return this.side === 'horizontal';
    },

    isVertical() {
        return this.side === 'vertical';
    },

    isOnSameAxisAs(line) {
        return (
            (this.isHorizontal() && line.isHorizontal()) ||
            (!this.isHorizontal() && !line.isHorizontal())
        );
    },

    isAtSamePositionAs(line) {
        if (this.isHorizontal()) {
            return pixelRoundErrorInsensitiveEqual(this.top, line.top);
        }
        return pixelRoundErrorInsensitiveEqual(this.left, line.left);
    },

    isAdjacentTo(line) {
        return (
            (
                this.getLineStart() <= line.getCenter() &&
                this.getLineEnd() >= line.getCenter()
            ) ||
            (
                line.getLineStart() <= this.getCenter() &&
                line.getLineEnd() >= this.getCenter()
            )
        );
    },

    isPartOfMergedCell(selectedCells) {
        const adjacentCells = this.getAdjacentCells();
        return !this.isOnTableEdge() &&
            adjacentCells.some(cell => cell.id !== this.cell &&
                selectedCells.includes(cell));
    },

    isInsideSelection(selectedCells) {
        const adjacentCells = this.getAdjacentCells();
        return !this.isOnTableEdge() &&
            adjacentCells.every(cell => selectedCells.includes(cell));
    },

    getTable() {
        return this.table;
    },

    getAdjacentCells() {
        const adjacentCells = [this.group.getCellById(this.cell)];
        if (!this.isOnTableEdge()) {
            const adjacentBorders = this.group.getAdjacentBorders(this);
            adjacentBorders.forEach(border => {
                adjacentCells.push(this.group.getCellById(border.cell));
            });
        }
        return adjacentCells;
    },

    /**
     * TODO: should not return cells separated by a merged cell
     * even if they are on the same axis.
     */
    getCellsOnAxis() {
        const cells = [];
        this.group.getObjects().filter(obj => obj.type === 'border')
            .forEach(border => {
                if (this.isOnSameAxisAs(border) && this.isAtSamePositionAs(border)) {
                    cells.push(...border.getAdjacentCells());
                }
            });
        return [...new Set(cells)];
    },

    handleScale() {
        // this.scaleX = 1;
        // this.scaleY = 1;

        // ['left', 'x2'].forEach(propertyToScale => {
        //     this[propertyToScale] = this.get(propertyToScale) * scaleX;
        // });
        // ['top', 'y2'].forEach(propertyToScale => {
        //     this[propertyToScale] = this.get(propertyToScale) * scaleY;
        // });
        // if (['bottom', 'top'].includes(this.side)) {
        //     this.width = this.get('width') * scaleX;
        // } else {
        //     this.height = this.get('height') * scaleY;
        // }
    },

    containsPoint(point) {
        return (
            point >= this.getLineStart() &&
            point <= this.getLineEnd()
        );
    },

    getAbsoluteTop() {
        return this.top + this.getTable().getAbsoluteTop();
    },

    getAbsoluteLeft() {
        return this.left + this.getTable().getAbsoluteLeft();
    },

    getLineStart() {
        return this.isHorizontal() ?
            this.getAbsoluteLeft() + Math.min(this.x1, this.x2) :
            this.getAbsoluteTop() + Math.min(this.y1, this.y2);
    },

    getLineEnd() {
        return this.isHorizontal() ?
            this.getAbsoluteLeft() + Math.max(this.x1, this.x2) :
            this.getAbsoluteTop() + Math.max(this.y1, this.y2);
    },

    getCenter() {
        return this.isHorizontal() ?
            this.getAbsoluteLeft() + ((this.x2 - this.x1) / 2) :
            this.getAbsoluteTop() + ((this.y2 - this.y1) / 2);
    },

    adjustCoordsToTable() {
        const maxOuterBorderWidth = Math.max(
            this.getTable().getLeftBorderMaxWidth(),
            this.getTable().getTopBorderMaxWidth()
        );

        if (this.side === 'horizontal') {
            this.x1 = this.getTable().getColumnStartX(this.column) + (maxOuterBorderWidth / 2);
            this.x2 = this.getTable().getColumnEndX(this.column) + (maxOuterBorderWidth / 2);
            this.y1 = this.getTable().getRowStartY(this.row) +
                (this.getTable().getTopBorderMaxWidth() / 2);
            this.y2 = this.y1;
            this.height = 0;
            this.width = this.x2 - this.x1;
        } else {
            this.x1 = this.getTable().getColumnStartX(this.column) +
                (this.getTable().getLeftBorderMaxWidth() / 2);
            this.x2 = this.x1;
            this.y1 = this.getTable().getRowStartY(this.row) + (maxOuterBorderWidth / 2);
            this.y2 = this.getTable().getRowEndY(this.row) + (maxOuterBorderWidth / 2);
            this.height = this.y2 - this.y1;
            this.width = 0;
        }
        this.left = this.x1 + (this.width / 2);
        this.top = this.y1 + (this.height / 2);
        this.dirty = true;
    },

    isOnTableEdge() {
        return tableGridLogic.isBorderOnTableEdge(this.id, this.getTable());
    },

    isInsideCell() {
        return tableGridLogic.isBorderInsideCell(this.id, this.getTable());
    },

    shouldRender() {
        return !this.isInsideCell();
    }

});

Border.fromObject = (object, callback) => {
    const border = new Border(object);
    if (callback instanceof Function) {
        callback(border);
    }
    return border;
};

module.exports = Border;
