import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import app from '#Reducers/app';
import build from '#Reducers/build/build';
import { deck, effectiveCanvasItemStyles } from '#Reducers/deck/deck';
import { decks } from '#Reducers/decks';
import plan from '#Reducers/plan/plan';
import manage from '#Reducers/manage/manage';
import share from '#Reducers/share/share';
import viewer from '#Reducers/viewer/viewer';
import error from '#Reducers/error/error';
import user from '#Reducers/user';
import auth from '#Reducers/auth';
import appHistory from '#Reducers/appHistory';
import history from '#Reducers/history';

export default browserHistory => appHistory(combineReducers({
    app,
    build,
    deck,
    decks,
    effectiveCanvasItemStyles,
    router: connectRouter(browserHistory),
    plan,
    manage,
    share,
    viewer,
    error,
    auth,
    user,
    history
}));
