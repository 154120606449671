class Bounds {
    static get MIN_BOUNDS_SIZE() {
        return 15;
    }

    constructor(shapeWidth, shapeHeight, attributes) {
        this.shapeWidth = shapeWidth;
        this.shapeHeight = shapeHeight;
        this._attributes = attributes;
    }

    get dimensions() {
        return {
            width: this.shapeWidth,
            height: this.shapeHeight
        };
    }

    get width() {
        return this.dimensions.width;
    }

    get height() {
        return this.dimensions.height;
    }

    // eslint-disable-next-line class-methods-use-this
    get position() {
        return { x: 0, y: 0 };
    }

    get x() {
        return this._attributes?.flipX ? -this.position.x : this.position.x;
    }

    get y() {
        return this.position.y;
    }

    get top() {
        return this.y - (this.height / 2);
    }

    get bottom() {
        return this.y + (this.height / 2);
    }

    get left() {
        return this.x - (this.width / 2);
    }

    get right() {
        return this.x + (this.width / 2);
    }
}

module.exports = Bounds;
