import CanvasStateSelectors from '../../../Canvas/CanvasStateSelectors';
import { clearText } from '../../Helpers/Table/cell';
import { getCellIds } from '../../Helpers/Table/table';
import { getCellIndex } from '../../Helpers/Table/tableHelper';

const clear = canvasState => {
    const table = CanvasStateSelectors.getSelectedCanvasItems(canvasState).get(0);
    const tablePath = CanvasStateSelectors.getShapePathById(canvasState, table.get('id'));
    const cellIds = getCellIds(canvasState);

    return canvasState.setIn(
        tablePath,
        cellIds.reduce((currentTable, cellId) => {
            const cellIndex = getCellIndex(currentTable, cellId);
            return currentTable.setIn(
                ['cells', cellIndex],
                clearText(currentTable.getIn(['cells', cellIndex]))
            );
        }, table)
    );
};

export default clear;
