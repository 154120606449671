import { fromJS } from 'immutable';

export default fromJS({
    shapePreset: {
        isReference: true,
        referenceUpdateParent: 'assignedShapeStyle'
    },
    typefacePreset: {
        isReference: true,
        referenceUpdateParent: 'assignedRunStyle'
    },
    paragraphPreset: {
        isReference: true,
        referenceUpdateParent: 'assignedParagraphStyle'
    },
    strokePreset: {
        isReference: true,
        referenceUpdateParent: 'assignedStrokeStyle'
    },
    listPreset: {
        isReference: true,
        referenceUpdateParent: 'listUpdate'
    }
});
