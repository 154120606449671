const Tip = require('./Tip/Tip');
const OvalTip = require('./Tip/OvalTip');
const DiamondTip = require('./Tip/DiamondTip');
const HalfDiamondTip = require('./Tip/HalfDiamondTip');
const TriangleTip = require('./Tip/TriangleTip');
const StealthTip = require('./Tip/StealthTip');
const BarTip = require('./Tip/BarTip');
const ArrowTip = require('./Tip/ArrowTip');

module.exports = {
    oval: OvalTip,
    diamond: DiamondTip,
    halfDiamond: HalfDiamondTip,
    triangle: TriangleTip,
    stealth: StealthTip,
    bar: BarTip,
    arrow: ArrowTip,
    none: Tip
};
