import {
    updateShapesFromDelta
} from '../../Helpers/helpers';
import { convertPropertiesToShapeProperties } from '../../utilities/convertPropertiesToShapeProperties';

const updateObjectPropertiesFromDelta = (canvasState, command, ids = null) => {
    const updates = convertPropertiesToShapeProperties(command.get('properties'));

    return updateShapesFromDelta(canvasState, updates, ids);
};

export default updateObjectPropertiesFromDelta;
