const isEqual = require('lodash/isEqual');
const forEach = require('lodash/forEach');
const Border = require('../Border');

module.exports = {
    /**
     * This mimics powerpoint border rendering where they
     * give z-index priority to borders on table edge,
     * then to vertical borders and then horizontal borders.
     */
    renderBorders() {
        this.cleanOldReducedBorders();
        const verticalEdges = this.getReducedVerticalEdges();
        const horizontalEdges = this.getReducedHorizontalEdges();
        const verticalInnerBorders = this.getReducedVerticalInnerBorders();
        const horizontalInnerBorders = this.getReducedHorizontalInnerBorders();

        const borders = [
            ...verticalInnerBorders,
            ...horizontalInnerBorders,
            ...verticalEdges,
            ...horizontalEdges
        ];
        this.add(...borders);
    },

    cleanOldReducedBorders() {
        const oldBorders = this.getObjects().filter(obj => obj.type === 'border');
        this.remove(...oldBorders);
    },

    isAdjacentBorders(currentBorder, previousBorder) {
        if (currentBorder.side === 'vertical' && Math.abs(currentBorder.row - previousBorder.row) !== 1) {
            return false;
        } if (currentBorder.side === 'horizontal' && Math.abs(currentBorder.column - previousBorder.column) !== 1) {
            return false;
        }
        return true;
    },

    getReducedHorizontalEdges() {
        const horizontalEdges = this.getHorizontalEdgeBorders();
        const bordersByStyle = [];
        horizontalEdges.forEach(edge => {
            bordersByStyle.push(this.reduceBordersByStyle(edge));
        });
        return this.reduceHorizontalBorders(bordersByStyle);
    },

    getReducedVerticalEdges() {
        const verticalEdges = this.getVerticalEdgeBorders();
        const bordersByStyle = [];
        verticalEdges.forEach(edge => {
            bordersByStyle.push(this.reduceBordersByStyle(edge));
        });
        return this.reduceVerticalBorders(bordersByStyle);
    },

    getReducedHorizontalInnerBorders() {
        const horizontalBorders = this.getInnerHorizontalBordersByRows();
        const bordersByStyle = [];
        horizontalBorders.forEach(row => {
            bordersByStyle.push(this.reduceBordersByStyle(row));
        });
        return this.reduceHorizontalBorders(bordersByStyle);
    },

    getReducedVerticalInnerBorders() {
        const verticalBorders = this.getInnerVerticalBordersByColumns();
        const bordersByStyle = [];
        verticalBorders.forEach(column => {
            bordersByStyle.push(this.reduceBordersByStyle(column));
        });
        return this.reduceVerticalBorders(bordersByStyle);
    },

    compareAdjacentBorders(currentBorder, previousBorder) {
        const currentBorderStyle = {
            ...currentBorder.strokeFill,
            ...currentBorder.strokeWidth,
            ...currentBorder.strokeDashName
        };
        const previousBorderStyle = {
            ...previousBorder.strokeFill,
            ...previousBorder.strokeWidth,
            ...previousBorder.strokeDashName
        };
        return isEqual(currentBorderStyle, previousBorderStyle) && previousBorder.shouldRender();
    },

    reduceBordersByStyle(borders) {
        const cleanBorders = borders.filter(val => val !== undefined);
        const reducedBorders = [[]];
        cleanBorders.forEach((border, index) => {
            if (border && this.borderSegments[cleanBorders[index]].shouldRender()) {
                if (index === 0) {
                    reducedBorders[reducedBorders.length - 1].push(border);
                } else {
                    const previousBorder = this.borderSegments[cleanBorders[index - 1]];
                    const sameStyle = this.compareAdjacentBorders(
                        this.borderSegments[cleanBorders[index]],
                        previousBorder
                    );
                    const adjacent = this.isAdjacentBorders(
                        this.borderSegments[cleanBorders[index]],
                        previousBorder
                    );
                    if (sameStyle && adjacent) {
                        reducedBorders[reducedBorders.length - 1].push(border);
                    } else {
                        reducedBorders.push([border]);
                    }
                }
            }
        });

        return reducedBorders;
    },

    reduceHorizontalBorders(borders) {
        const finalBorders = [];
        borders.forEach(row => {
            row.forEach(borderSegment => {
                const maxOuterBorderWidth = Math.max(
                    this.getLeftBorderMaxWidth(),
                    this.getTopBorderMaxWidth()
                );
                const firstSegment = this.borderSegments[borderSegment[0]];
                const lastSegment = this.borderSegments[borderSegment[borderSegment.length - 1]];

                if (firstSegment && lastSegment) {
                    const offsets = this.getBorderIntersectionOffsets(firstSegment, lastSegment);

                    const x1 = this.getColumnStartX(firstSegment.column) +
                        (maxOuterBorderWidth / 2) + offsets.beforeOffset;
                    const x2 = this.getColumnEndX(lastSegment.column) + (maxOuterBorderWidth / 2) + offsets.afterOffset;
                    const y1 = this.getRowStartY(firstSegment.row) + (this.getTopBorderMaxWidth() / 2);
                    const y2 = y1;
                    const height = 0;
                    const width = x2 - x1;
                    const left = x1 + (width / 2);
                    const top = y1 + (height / 2);
                    finalBorders.push(new Border({
                        ...firstSegment.toJSON(),
                        table: this,
                        top,
                        left,
                        x1,
                        x2,
                        y1,
                        y2,
                        height,
                        width
                    }));
                }
            });
        });
        return finalBorders;
    },

    reduceVerticalBorders(borders) {
        const finalBorders = [];
        borders.forEach(column => {
            column.forEach(borderSegment => {
                const maxOuterBorderWidth = Math.max(
                    this.getLeftBorderMaxWidth(),
                    this.getTopBorderMaxWidth()
                );
                const firstSegment = this.borderSegments[borderSegment[0]];
                const lastSegment = this.borderSegments[borderSegment[borderSegment.length - 1]];
                if (firstSegment && lastSegment) {
                    const offsets = this.getBorderIntersectionOffsets(firstSegment, lastSegment);

                    const x1 = this.getColumnStartX(firstSegment.column) +
                        (this.getLeftBorderMaxWidth() / 2);
                    const x2 = x1;
                    const y1 = this.getRowStartY(firstSegment.row) + (maxOuterBorderWidth / 2) + offsets.beforeOffset;
                    const y2 = this.getRowEndY(lastSegment.row) + (maxOuterBorderWidth / 2) + offsets.afterOffset;
                    const height = y2 - y1;
                    const width = 0;
                    const left = x1 + (width / 2);
                    const top = y1 + (height / 2);
                    finalBorders.push(new Border({
                        ...firstSegment.toJSON(),
                        table: this,
                        top,
                        left,
                        x1,
                        x2,
                        y1,
                        y2,
                        height,
                        width
                    }));
                }
            });
        });
        return finalBorders;
    },

    getBordersOnTableEdge() {
        return [
            ...this.verticalBorders[0],
            ...this.verticalBorders[this.columns],
            ...this.horizontalBorders[0],
            ...this.horizontalBorders[this.rows]
        ];
    },

    getMaxBorderSize(borderIds) {
        return Math.max(...borderIds
            .map(borderId => this.getBorderSize(borderId)));
    },

    getBorderSize(borderId) {
        return this?.borderSegments?.[borderId]?.strokeWidth || 0;
    },

    getPerpendicularBorderSizes(firstSegment, lastSegment) {
        let sizes = {
            borderBeforeSize: 0,
            borderAfterSize: 0
        };

        if (firstSegment.side === 'vertical') {
            const horizontalBordersBefore =
                this.horizontalBorders[firstSegment.row]
                    .slice(
                        firstSegment.column > 0 ? firstSegment.column - 1 : 0,
                        firstSegment.column + 1
                    );

            const horizontalBordersAfter =
                this.horizontalBorders[lastSegment.row + 1]
                    .slice(
                        lastSegment.column > 0 ? lastSegment.column - 1 : 0,
                        lastSegment.column + 1
                    );

            sizes = {
                borderBeforeSize: this.getMaxBorderSize(horizontalBordersBefore),
                borderAfterSize: this.getMaxBorderSize(horizontalBordersAfter)
            };
        } else if (firstSegment.side === 'horizontal') {
            const verticalBordersBefore =
                this.verticalBorders[firstSegment.column]
                    .slice(
                        firstSegment.row > 0 ? firstSegment.row - 1 : 0,
                        firstSegment.row + 1
                    );

            const verticalBordersAfter =
                this.verticalBorders[lastSegment.column + 1]
                    .slice(
                        lastSegment.row > 0 ? lastSegment.row - 1 : 0,
                        lastSegment.row + 1
                    );

            sizes = {
                borderBeforeSize: this.getMaxBorderSize(verticalBordersBefore),
                borderAfterSize: this.getMaxBorderSize(verticalBordersAfter)
            };
        }

        return sizes;
    },

    getAdjacentBorderSizes(firstSegment, lastSegment) {
        let sizes = {
            borderBeforeSize: 0,
            borderAfterSize: 0
        };

        if (firstSegment.side === 'vertical') {
            sizes = {
                borderBeforeSize: this.getBorderSize(this.verticalBorders[lastSegment.column][lastSegment.row - 1]),
                borderAfterSize: this.getBorderSize(this.verticalBorders[lastSegment.column][lastSegment.row + 1])
            };
        } else if (firstSegment.side === 'horizontal') {
            sizes = {
                borderBeforeSize: this.getBorderSize(this.horizontalBorders[firstSegment.row][firstSegment.column - 1]),
                borderAfterSize: this.getBorderSize(this.horizontalBorders[lastSegment.row][lastSegment.column + 1])
            };
        }

        return sizes;
    },

    getBorderIntersectionOffsets(
        firstSegment,
        lastSegment
    ) {
        const offsets = {
            beforeOffset: 0,
            afterOffset: 0
        };

        const perpendicularBorderSizes = this.getPerpendicularBorderSizes(firstSegment, lastSegment);
        const adjacentBorderSizes = this.getAdjacentBorderSizes(firstSegment, lastSegment);
        const borderSize = firstSegment.strokeWidth;

        if (borderSize < adjacentBorderSizes.borderBeforeSize) {
            offsets.beforeOffset += (perpendicularBorderSizes.borderBeforeSize / 2);
        } else if (borderSize > adjacentBorderSizes.borderBeforeSize) {
            offsets.beforeOffset -= (perpendicularBorderSizes.borderBeforeSize / 2);
        }

        if (firstSegment.strokeWidth < adjacentBorderSizes.borderAfterSize) {
            offsets.afterOffset -= (perpendicularBorderSizes.borderAfterSize / 2);
        } else if (borderSize > adjacentBorderSizes.borderAfterSize) {
            offsets.afterOffset += (perpendicularBorderSizes.borderAfterSize / 2);
        }

        // This is to counteract non-butt lineCaps extruding from half the strokeWidth
        if (firstSegment.strokeLineCap !== 'butt') {
            offsets.beforeOffset += (borderSize / 2);
            offsets.afterOffset -= (borderSize / 2);
        }

        return offsets;
    },

    getVerticalEdgeBorders() {
        const verticalEdges = [
            this.verticalBorders[0],
            this.verticalBorders[this.verticalBorders.length - 1]
        ];
        return verticalEdges;
    },

    getHorizontalEdgeBorders() {
        const horizontalEdges = [
            this.horizontalBorders[0],
            this.horizontalBorders[this.horizontalBorders.length - 1]
        ];
        return horizontalEdges;
    },

    getInnerHorizontalBorders() {
        const innerHorizontal = [];
        const borderSubset = this.horizontalBorders;
        forEach(
            borderSubset.slice(1, this.rows),
            borders => innerHorizontal.push(...borders)
        );
        return innerHorizontal;
    },

    getInnerHorizontalBordersByRows() {
        const innerHorizontal = [];
        const borderSubset = this.horizontalBorders;
        forEach(
            borderSubset.slice(1, this.rows),
            borders => innerHorizontal.push(borders)
        );
        return innerHorizontal;
    },

    getInnerVerticalBorders() {
        const innerVertical = [];
        const borderSubset = this.verticalBorders;
        forEach(
            borderSubset.slice(1, this.columns),
            borders => innerVertical.push(...borders)
        );
        return innerVertical;
    },

    getInnerVerticalBordersByColumns() {
        const innerVertical = [];
        const borderSubset = this.verticalBorders;
        forEach(
            borderSubset.slice(1, this.columns),
            borders => innerVertical.push(borders)
        );
        return innerVertical;
    }
};
