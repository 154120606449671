const isObject = require('lodash/isObject');
const isPlainObject = require('lodash/isPlainObject');

const omitNullDeep = object => (
    isPlainObject(object) ?
        Object.entries(object)
            .reduce((currentObject, [key, value]) => {
                if (value !== null) {
                    return ({
                        ...currentObject,
                        [key]: (isObject(value) ?
                            omitNullDeep(value) :
                            value)
                    });
                }
                return currentObject;
            }, {}) :
        {}
);

module.exports = omitNullDeep;
