import { Map } from 'immutable';
import complementAgainstSources from '../../utilities/complementAgainstSources';
import defaultsDeepWithExceptions from '../../utilities/defaultsDeepWithExceptions';
import { DEFAULT_RUNSTYLE } from '../Text/Config/defaultStyles';
import textBodyStyles from '../Text/Config/textBodyStyles';
import { getDefaultRunStyle, setDefaultRunStyle } from '../Text/Runs/RunStyles';

const atomicProperties = [
    'color'
];

const setComplementRunStyleAsRunStyle = textBody => {
    let updatedTextBody = textBody;
    updatedTextBody = updatedTextBody.set(
        'runStyle',
        defaultsDeepWithExceptions(
            getDefaultRunStyle(updatedTextBody),
            DEFAULT_RUNSTYLE,
            atomicProperties
        ).remove('isDefault')
    );

    updatedTextBody = updatedTextBody.set(
        'runStyle',
        complementAgainstSources(
            updatedTextBody.get('runStyle'),
            updatedTextBody.getIn(['assignedStyles', 'run']),
            atomicProperties
        )
    );

    updatedTextBody = setRunStyleDefault(updatedTextBody);
    return updatedTextBody;
};

const complementRunStyleAgainstDefault = (textBody, runStyle = Map()) => {
    const defaultRunStyle = runStyle.get('isDefault') === true ?
        Map() :
        textBody.get('runStyle');
    return complementAgainstSources(
        runStyle,
        DEFAULT_RUNSTYLE,
        textBody.getIn(['assignedStyles', 'run']),
        defaultRunStyle,
        atomicProperties
    );
};

const setRunStyleDefault = (textBody, shape) => setDefaultRunStyle(
    textBody,
    getDefaultRunStyle(shape?.getIn(['textBody'])) || getRenderRunStyle(textBody, shape)
);

const getRenderRunStyle = (textBody, shape = Map()) => defaultsDeepWithExceptions(
    textBody?.get('runStyle') || Map(),
    textBody?.getIn(['assignedStyles', 'run']) || Map(),
    loadDefaultRunStyleConfiguration(shape.get('type')),
    atomicProperties
);

const loadDefaultRunStyleConfiguration = type => (
    (!!type && Object.prototype.hasOwnProperty.call(textBodyStyles, type) ?
        textBodyStyles[type] :
        textBodyStyles.DEFAULT).get('DEFAULT_RUNSTYLE')
);

export {
    setComplementRunStyleAsRunStyle,
    complementRunStyleAgainstDefault,
    setRunStyleDefault,
    getRenderRunStyle
};
