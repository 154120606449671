import { fromJS } from 'immutable';
import { fillEmptySerialized } from '../../../../../CanvasState/Helpers/Fill';
import { dashEmptySerialized } from '../../../../../CanvasState/Helpers/Stroke';
import { getRenderStroke } from '../../../../../CanvasState/Helpers/Style/stroke';

const removeStrokeFillIfEmpty = shape => {
    let updatedShape = shape;

    if (updatedShape.getIn(['stroke', 'fill']) &&
        updatedShape.getIn(['stroke', 'fill']).equals(fillEmptySerialized)) {
        updatedShape = updatedShape.removeIn(['stroke', 'fill']);
    }

    if (updatedShape.getIn(['assignedStyles', 'stroke', 'fill']) &&
        updatedShape.getIn(['assignedStyles', 'stroke', 'fill']).equals(fillEmptySerialized)) {
        updatedShape = updatedShape.removeIn(['assignedStyles', 'stroke', 'fill']);
    }

    return updatedShape;
};

const removeDashIfEmpty = shape => {
    let updatedShape = shape;

    if (updatedShape.getIn(['stroke', 'dash']) &&
        updatedShape.getIn(['stroke', 'dash']).equals(dashEmptySerialized)) {
        updatedShape = updatedShape.removeIn(['stroke', 'dash']);
    }

    if (updatedShape.getIn(['assignedStyles', 'stroke', 'dash']) &&
        updatedShape.getIn(['assignedStyles', 'stroke', 'dash']).equals(dashEmptySerialized)) {
        updatedShape = updatedShape.removeIn(['assignedStyles', 'stroke', 'dash']);
    }

    return updatedShape;
};

const getStroke = (shape, colorPalette) => {
    let updatedShape = shape;

    if (!updatedShape.get('stroke')) {
        updatedShape = updatedShape.set(
            'stroke',
            fromJS({
                align: undefined,
                cap: undefined,
                dash: undefined,
                compoundType: undefined,
                join: undefined,
                styleId: undefined,
                width: undefined,
                head: undefined,
                tail: undefined
            })
        );
    }

    return getRenderStroke(
        removeStrokeFillIfEmpty(
            removeDashIfEmpty(
                updatedShape
            )
        ),
        colorPalette
    ).toJS();
};

export {
    removeDashIfEmpty,
    removeStrokeFillIfEmpty,
    getStroke
};
