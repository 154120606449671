import * as types from '#Constants/ActionTypes';

const currentChartSelection = (state = 'overview', action) => {
    switch (action.type) {
        case types.CHART_SELECT:
            return action.selection;
        default:
            return state;
    }
};

export { currentChartSelection };
