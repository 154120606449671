import { fromJS, List } from 'immutable';
import { isNil } from 'lodash';
import adaptFabricColorToColorValueDescriptor from './colorValueDescriptorAdapter';

const adaptFabricBulletStyleToBulletStyle = (
    {
        bulletType,
        bulletText,
        bulletStyle = {},
        itemCountStartsAt
    },
    colorPalette = List()
) => {
    const paragraphBulletStyle = {};
    if (bulletType) {
        paragraphBulletStyle.type = bulletType;
    }
    if (bulletText) {
        paragraphBulletStyle.text = bulletText;
    }
    if (itemCountStartsAt) {
        paragraphBulletStyle.itemCountStartsAt = itemCountStartsAt;
    }
    paragraphBulletStyle.style = {};
    if (bulletStyle.fontFamily) {
        paragraphBulletStyle.style.font = bulletStyle.fontFamily;
    }
    if (bulletStyle.fontSize) {
        paragraphBulletStyle.style.size = bulletStyle.fontSize;
    }
    if (bulletStyle.fill) {
        paragraphBulletStyle.style.color = adaptFabricColorToColorValueDescriptor(
            bulletStyle.fill,
            colorPalette
        );
    }
    return paragraphBulletStyle;
};

const adaptFabricPaddingStyleToPaddingStyle = ({
    paddingBottom,
    paddingLeft,
    paddingRight,
    paddingTop
}) => {
    const paddingStyle = {};
    if (paddingBottom) {
        paddingStyle.bottom = paddingBottom;
    }
    if (paddingLeft) {
        paddingStyle.left = paddingLeft;
    }
    if (paddingRight) {
        paddingStyle.right = paddingRight;
    }
    if (paddingTop) {
        paddingStyle.top = paddingTop;
    }
    return paddingStyle;
};

const adaptFabricTextStyleToParagraphStyle = (
    {
        textAlign,
        lineHeight,
        bulletType,
        bulletText,
        bulletStyle,
        itemCountStartsAt,
        level,
        paddingBottom,
        paddingLeft,
        paddingRight,
        paddingTop,
        indent
    } = {},
    colorPalette
) => {
    const paragraphStyle = {};
    if (textAlign) {
        paragraphStyle.align = textAlign === 'justify-left' ? 'justify' : textAlign;
    }
    if (lineHeight) {
        paragraphStyle.lineSpacing = lineHeight;
    }
    if (level) {
        paragraphStyle.level = level;
    }
    if (bulletType || bulletText) {
        paragraphStyle.bullet = adaptFabricBulletStyleToBulletStyle(
            {
                bulletType, bulletText, bulletStyle, itemCountStartsAt
            },
            colorPalette
        );
    }
    if (paddingBottom || paddingLeft || paddingRight || paddingTop) {
        paragraphStyle.padding = adaptFabricPaddingStyleToPaddingStyle({
            paddingBottom,
            paddingLeft,
            paddingRight,
            paddingTop
        });
    }
    if (!isNil(indent) && !Number.isNaN(indent)) {
        paragraphStyle.indent = indent;
    }
    return fromJS(paragraphStyle);
};

export default adaptFabricTextStyleToParagraphStyle;
