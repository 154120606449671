import { List } from 'immutable';
import { stringifyPathFromArray } from '../../../../../utilities/svgUtility';

const stringifyPath = path => {
    if (List.isList(path)) {
        return stringifyPathFromArray(path.toJS());
    }
    return path;
};

const getPath = shape => stringifyPath(shape.get('path') ? shape.get('path') : '');

export default getPath;
