import { List, Map } from 'immutable';
import { isValid, getRgba } from './color';

const filterValidColors = (palette = List()) => palette
    .filter((descriptor = Map()) => isValid(descriptor.get('color')));

const findColorById = (palette = List(), search = '') => {
    const foundColor = filterValidColors(palette)
        .find(color => color.get('id') === search) || Map();
    return foundColor.get('color');
};

const findIdByColor = (palette = List(), search) => {
    if (isValid(search)) {
        const color = filterValidColors(palette)
            .find((descriptor = Map()) => getRgba(descriptor.get('color')) === getRgba(search)) || Map();
        return color.get('id');
    }
    throw new Error(`Search pattern ${search} is not valid`);
};

export {
    filterValidColors,
    findColorById,
    findIdByColor
};
