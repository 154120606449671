import get from 'lodash/get';
import { mainNavConfigByType } from '#Config/navigation.config';

const sectionPaths = Object.entries(mainNavConfigByType)
    .map(([id, { path }]) => ({ id, path }));

const getActiveSection = () => {
    const location = window.location.pathname;
    const currentSection = sectionPaths
        .find(({ path: sectionPath }) => new RegExp(`/${sectionPath}/?`, 'i')
            .test(location));
    return get(currentSection, 'path', '');
};

const makeSectionFields = (defaultFields, sectionFields = {}) => ({
    default: defaultFields,
    ...Object.entries(sectionFields)
        .reduce((sectionFieldsWithDefault, [section, extraFields]) => ({
            ...sectionFieldsWithDefault,
            [section]: `${defaultFields}\n${extraFields}`
        }), {})
});

const getFieldsForSection = (
    sectionFields,
    section = getActiveSection()
) => get(sectionFields, section, sectionFields.default);

const makeFieldsGetter = sectionFields => () => getFieldsForSection(sectionFields);

const PAGE_FIELDS = makeSectionFields(`
    _id
    id
    title
    shapes
    width
    height
    hidden
    topic
    background
    pageNumber
    sequenceNumber
    slideLayout
    layout
    originalLayout
    createdAt
    updatedAt
`);

export const getPageFields = makeFieldsGetter(PAGE_FIELDS);

const DECK_FIELDS_DEFAULT = `
    id
    version
    title
    createdAt
    updatedAt
    pageCount
    isSharedDeck
    pages {
        _id
        id
        hidden
        updatedAt
        createdAt
    }
    user {
        id
    }
`;

const DECK_FIELDS_FOR_BUILD = `
    properties
    pages {
        pageNumber
        width
        height
        hidden
        topic
    }
    theme
    originalTheme
    applicationTheme
`;

const DECK_FIELDS_FOR_MANAGE = `
    properties
    pages {
        pageNumber
        width
        height
        topic
        updatedAt
        createdAt
        hidden
    }
`;

const DECK_FIELDS_FOR_PLAN = `
    objective {
        id
        _id
        label
        category
        type
        sequence
        definition
        counts
        active
    }
    storyboard {
        id
        active
        counts
        topics {
            sequence
        }
        definition
        name
        sequence
    }
    pages {
        topic,
        pageNumber
    }
    originalName,
    applicationTheme,
    originalTheme,
    theme
`;

const DECK_FIELDS = makeSectionFields(DECK_FIELDS_DEFAULT, {
    plan: DECK_FIELDS_FOR_PLAN,
    build: DECK_FIELDS_FOR_BUILD,
    manage: DECK_FIELDS_FOR_MANAGE
});

export const getDeckFields = makeFieldsGetter(DECK_FIELDS);

const OBJECTIVE_FIELDS = makeSectionFields(`
    id
    _id
    label
    category
    type
    sequence
    definition
    counts
    active
`);

export const getObjectiveFields = makeFieldsGetter(OBJECTIVE_FIELDS);

const STORYBOARDS_FIELDS = makeSectionFields(`
    id
    active
    counts
    definition
    name
    sequence
`);

export const getStoryboardFields = makeFieldsGetter(STORYBOARDS_FIELDS);

const TOPIC_FIELDS = makeSectionFields(`
    active
    defaultPages
    description
    id
    label
`);

export const getTopicFields = makeFieldsGetter(TOPIC_FIELDS);

const VIEWER_FIELDS = makeSectionFields(`
    share {
        id
        permissions
    }
    view {
        id
    }
    deck {
        id
        title
        user {
            firstName
            lastName
        }
        updatedAt
        pages {
            id
            pageNumber
            imageSrc
            thumbnailSrc
            topic
        }
    }
    isCreator
`);

export const getViewerFields = makeFieldsGetter(VIEWER_FIELDS);

const SHARE_FIELDS = makeSectionFields(`
    _id
    id
    deck
    audience
    description
    recipients
    message
    date
    permissions
    from
    to
`);

export const getShareFields = makeFieldsGetter(SHARE_FIELDS);
