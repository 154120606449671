const Shape = require('../../Shape/Shape');
const { shapeConstructors } = require('../../Shape/Shape');
const {
    adaptFabricParagraphsToTextBody
} = require('../Adapters/TextBody/TextBodyAdapter');

const getConstructorNameFromSerializedFabricObject = serializedFabricObject => serializedFabricObject.shapeType || [
    'Image', 'Circle', 'Ellipse', 'Line', 'Group', 'Path', 'Textbox', 'Rectangle'
]
    .find(cursor => Shape.shapeConstructors[cursor].isSerializedFabricObject(serializedFabricObject)) || 'Rectangle';

function fromFabricToShape(
    canvas,
    textShape,
    DOMParser,
    originalCanvasItemJSON = {},
    parentOffset = { left: 0, top: 0 }
) {
    if (textShape.type.toLowerCase() === 'table') {
        return shapeConstructors.Table.fromFabric(
            textShape,
            DOMParser,
            canvas,
            originalCanvasItemJSON
        );
    }

    textShape.shapeType = getConstructorNameFromSerializedFabricObject(textShape);

    const originalCanvasItem = shapeConstructors[textShape.shapeType]
        .fromJSON(originalCanvasItemJSON);

    const canvasItem = new shapeConstructors[textShape.shapeType](
        textShape.name,
        textShape.left - parentOffset.left || 0,
        textShape.top - parentOffset.top || 0,
        textShape
    );

    canvas.setCanvasOnItem(canvasItem);
    canvasItem.textBody = adaptFabricParagraphsToTextBody(
        textShape.paragraphs,
        canvas.colorPalette,
        originalCanvasItem
    );

    canvasItem.textBody.autoFitText = textShape.autoFitText;
    canvasItem.textBody.autoFitShape = textShape.autoFitShape;
    canvasItem.textBody.verticalAlign = textShape.verticalAlign;

    canvasItem.textBodyPlaceholder = adaptFabricParagraphsToTextBody(
        textShape.placeholderParagraphs,
        canvas.colorPalette,
        originalCanvasItem
    );

    canvasItem.textBodyPlaceholder.autoFitText = textShape.autoFitText;
    canvasItem.textBodyPlaceholder.autoFitShape = textShape.autoFitShape;

    canvasItem.setShapePropertiesFromSerializedFabricObject(textShape);

    canvasItem.copyStyles(originalCanvasItem);

    return canvasItem;
}

module.exports = {
    fromFabricToShape,
    getConstructorNameFromSerializedFabricObject
    // getConstructorNameFromSerializedFabricObjectName
};
