import { createSelector } from 'reselect';
import uniq from 'lodash/uniq';
import memoize from 'lodash/memoize';
import { List } from 'immutable';

import decksignCanvas from 'decksign-canvas';

import { rgbToHex } from '#Utilities/colors';
import { isNewShapeType } from '#Utilities/decksign/object';
import {
    getColorPalette,
    getPaletteColorScheme,
    getStyleDefinitions
} from '../deck';

const {
    CanvasState,
    ItemStyle
} = decksignCanvas.api;

const checkCanvasState = canvasState => canvasState && CanvasState.isCanvasState(canvasState);

export const getCanvasState = createSelector(({
    build: {
        canvasState
    }
}) => canvasState, canvasState => (
    checkCanvasState(canvasState) ? canvasState : undefined
));

export const getLogoSource = createSelector(
    getCanvasState,
    canvasState => ((canvasState && canvasState.has('dynamicValues') && canvasState.get('dynamicValues').has('logo')) ?
        canvasState.get('dynamicValues').get('logo').toJS().imageSource :
        undefined
    )
);

const getSelectedCanvasItemsIds = createSelector(
    getCanvasState,
    canvasState => (
        canvasState ?
            CanvasState.getSelectedCanvasItemsIds(canvasState) :
            []
    )
);

export { getSelectedCanvasItemsIds };

const getFontsStateFromCanvasState = createSelector(
    getCanvasState,
    canvasState => (
        canvasState ? CanvasState.getFontsList(canvasState) : []
    )
);

export const getSelectedCanvasItems = createSelector(
    getCanvasState,
    canvasState => (canvasState ? CanvasState.getSelectedCanvasItems(canvasState) : List())
);

export const getSelectedCanvasItemStyleId = createSelector(
    getSelectedCanvasItems,
    selectedCanvasItems => selectedCanvasItems.size === 1 && selectedCanvasItems.getIn([0, 'style'])
);

export const areOnlyTextshapesSelected = createSelector(
    getSelectedCanvasItems,
    items => items.every(item => isNewShapeType(item))
);

export const getSelectedShapesParentGroupsPath = createSelector(
    getCanvasState,
    canvasState => (
        (canvasState && canvasState.has('selectedShapesParentGroupsPath')) ?
            canvasState.get('selectedShapesParentGroupsPath').toJS() :
            []
    )
);

export const selectionIsAGroup = createSelector([
    getSelectedCanvasItems
], canvasItems => canvasItems.size > 0 && canvasItems.every(obj => obj.get('shapes') && obj.get('shapes').size > 0));

export const getSelectedCanvasItemsOverlay = createSelector(
    [getSelectedCanvasItems],
    items => {
        const firstItemWithLink = items.find(i => !!i.get('hyperlink'));
        if (!firstItemWithLink) {
            return {
                visibility: 'hidden'
            };
        }
        return {
            visibility: 'visible',
            hyperlink: firstItemWithLink.get('hyperlink')
        };
    }
);

export const canUndoCanvasState = ({
    history: {
        undoStack
    }
}) => !undoStack.isEmpty();

export const canRedoCanvasState = ({
    history: {
        redoStack
    }
}) => !redoStack.isEmpty();

export const getCanCutOrCopyCanvasItem = createSelector(
    getSelectedCanvasItemsIds, ids => (ids?.length > 0)
);

export const canPasteCanvasState = createSelector(
    getCanvasState,
    canvasState => (
        canvasState ?
            canvasState.get('clipboard').size > 0 :
            false
    )
);

export const checkIsEditingTableCell = createSelector(
    getCanvasState,
    canvasState => {
        if (!canvasState) {
            return false;
        }
        const selectedItemsTypes = CanvasState
            .getSelectedCanvasItemsTypes(canvasState);
        return selectedItemsTypes.size === 1 &&
            selectedItemsTypes.first().toLowerCase() === 'table' && CanvasState.getSelectedCellsFromContextualSelection(canvasState).size > 0;
    }
);

export const checkIsEditingTable = createSelector(
    getCanvasState,
    canvasState => {
        if (!canvasState) {
            return false;
        }
        const selectedItemsTypes = CanvasState
            .getSelectedCanvasItemsTypes(canvasState);
        return selectedItemsTypes.size === 1 &&
            selectedItemsTypes.first().toLowerCase() === 'table';
    }
);

export const getSelectedCanvasItemsTypes = createSelector(
    getCanvasState,
    canvasState => (canvasState ? CanvasState.getSelectedCanvasItemsTypes(canvasState).toJS() : [])
);

export const getSelectedCanvasItemsType = createSelector(
    getSelectedCanvasItemsTypes,
    types => {
        const uniqTypes = uniq(types);
        return uniqTypes.length === 1 ? uniqTypes[0] : '';
    }
);

export const checkShouldAllowCellMerge = createSelector(
    getCanvasState,
    canvasState => (
        canvasState ?
            CanvasState.shouldAllowCellMerge(canvasState) :
            false
    )
);

export const getPresetColors = createSelector(
    getCanvasState,
    canvasState => {
        if (!canvasState) {
            return undefined;
        }
        const colorPalette = canvasState.get('colorPalette');
        if (!colorPalette.size > 0) {
            return undefined;
        }
        return colorPalette.map(color => ({
            title: color.get('id'),
            color: rgbToHex(color.get('color'))
        })).toJS();
    }
);

export const getFontsList = createSelector(
    getFontsStateFromCanvasState,
    fonts => fonts
);

export const getLayeredShapes = createSelector(
    getCanvasState,
    canvasState => (canvasState ?
        CanvasState.getListableShapesByLayers(canvasState) :
        {
            layoutShapes: [],
            pageShapes: []
        })
);

export const getPageShapes = createSelector(
    getCanvasState,
    canvasState => (
        canvasState ?
            canvasState.get('shapes').toJS() :
            []
    )
);

export const getPageBackground = createSelector(
    getCanvasState,
    canvasState => (
        canvasState ?
            canvasState.get('pageBackground').toJS() :
            []
    )
);

export const getLayoutBackground = createSelector(
    getCanvasState,
    canvasState => (
        canvasState ?
            canvasState.get('layoutBackground').toJS() :
            []
    )
);

export const getLayoutShapes = createSelector(
    getCanvasState,
    canvasState => (
        canvasState ?
            canvasState
                .get('layoutShapes')
                .filter(s => !s.placeHolderType).toJS() :
            []
    )
);

export const getEditMode = createSelector(
    getCanvasState,
    canvasState => (
        canvasState ?
            canvasState.get('editMode') :
            undefined
    )
);

export const getLayeredShapesForNavigation = createSelector(
    getLayeredShapes,
    getEditMode,
    ({ layoutShapes, pageShapes }, editMode) => ({
        layoutShapes: {
            shapes: layoutShapes,
            isLocked: editMode !== 'Layout',
            isCollapsed: editMode !== 'Layout'
        },
        pageShapes: {
            shapes: pageShapes,
            isLocked: editMode !== 'Page',
            isCollapsed: editMode !== 'Page'
        }
    })
);

export const getSelectedCellIds = createSelector(
    getCanvasState,
    canvasState => (
        canvasState ?
            CanvasState.getSelectedCellIds(canvasState) :
            []
    )
);

export const getVersion = createSelector(
    getCanvasState,
    canvasState => (
        canvasState ?
            canvasState.get('version') :
            undefined
    )
);

export const getSelectedCanvasItemStyle = createSelector(
    getSelectedCanvasItems,
    getStyleDefinitions,
    getPaletteColorScheme,
    getColorPalette,
    (selectedCanvasItems, styleDefinitions, colorScheme, colorPalette) => {
        if (selectedCanvasItems.size === 1 && selectedCanvasItems.hasIn([0, 'style'])) {
            const styleId = selectedCanvasItems.getIn([0, 'style']);
            return ItemStyle.getEffectiveStyleById(styleDefinitions, styleId, { scheme: colorScheme, colorPalette });
        }
        return {};
    }
);

export const getCanvasItemUpdateForStyleId = createSelector(
    getStyleDefinitions,
    getSelectedCanvasItemsType,
    getPaletteColorScheme,
    getColorPalette,
    (
        styleDefinitions,
        type,
        scheme,
        colorPalette
    ) => memoize(styleId => {
        const style = ItemStyle.getEffectiveStyleById(styleDefinitions, styleId, { scheme, colorPalette });
        return ItemStyle.applyStyle({}, style, type);
    })
);

export const getTableStyleMismatches = createSelector(
    getCanvasState,
    getSelectedCanvasItemStyleId,
    getSelectedCanvasItemsIds,
    getCanvasItemUpdateForStyleId,
    (canvasState, styleId, ids, updateGetter) => {
        if (!canvasState || ids.length !== 1 || !styleId) {
            return { cells: [], borders: [] };
        }
        return CanvasState.getTableStyleMismatches(
            canvasState,
            ids[0],
            updateGetter(styleId)
        );
    }
);

export const canSendSelectionToFrontSelector = createSelector(
    getCanvasState,
    canvasState => (canvasState ?
        CanvasState.canSendSelectionToFront(canvasState) :
        false)
);

export const canSendSelectionToBackSelector = createSelector(
    getCanvasState,
    canvasState => (canvasState ?
        CanvasState.canSendSelectionToBack(canvasState) :
        false)
);

export const canGroupShapesSelector = createSelector(
    getCanvasState,
    canvasState => (canvasState ?
        CanvasState.canGroupShapes(canvasState) :
        false)
);

// FIXME: to fix with https://decksign.atlassian.net/browse/SC-5934
export const canUngroupShapesSelector = createSelector(
    getCanvasState,
    canvasState => (canvasState ?
        CanvasState.canUngroupShapes(canvasState) :
        false)
);
