import * as types from '#Constants/ActionTypes';

const initialState = {
    visible: false,
    id: '',
    name: '',
    error: false,
    isLoading: false
};

const deleteShare = (state = initialState, action) => {
    switch (action.type) {
        case types.OPEN_DELETE_SHARE_MODAL:
            return {
                ...state,
                visible: true,
                id: action.id,
                name: action.name,
                error: false
            };
        case types.CLOSE_DELETE_SHARE_MODAL:
            return {
                ...state,
                visible: false,
                id: initialState.id,
                name: initialState.name
            };
        case types.DELETE_SHARE_PENDING:
            return {
                ...state,
                isLoading: true
            };
        case types.DELETE_SHARE_DONE:
            return {
                ...initialState
            };
        case types.DELETE_SHARE_REJECTED:
            return {
                ...state,
                error: true,
                isLoading: false
            };
        default:
            return state;
    }
};

export { deleteShare };
