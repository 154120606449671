import { combineReducers } from 'redux';

import * as types from '#Constants/ActionTypes';

const deck = (state = [], action) => {
    switch (action.type) {
        case types.POST_DECK_COMMENT: {
            return [...state, action.message];
        }
        case types.DELETE_DECK_COMMENT: {
            return [...state, action.message];
        }
        case types.EDIT_DECK_COMMENT: {
            return [...state, action.message];
        }

        default:
            return state;
    }
};

const page = (state = {}, action) => {
    switch (action.type) {
        case types.POST_PAGE_COMMENT: {
            const pageComments = state[action.pageIndex] || [];
            const newComments = [...pageComments, action.comment];

            return { ...state, [action.pageIndex]: newComments };
        }
        case types.DELETE_PAGE_COMMENT: {
            const pageComments = state[action.pageIndex] || [];
            const newComments = [...pageComments.filter(comment => (comment.id !== action.commentId))];
            return { ...state, [action.pageIndex]: newComments };
        }
        case types.EDIT_PAGE_COMMENT: {
            const pageComments = state[action.pageIndex] || [];
            const indexOfAmendedItem = pageComments.findIndex(comment => comment.id === action.commentId);
            const amendedItem = action.updatedComment;
            const newComments = [
                ...pageComments.slice(0, indexOfAmendedItem),
                amendedItem,
                ...pageComments.slice(indexOfAmendedItem + 1)
            ];
            return { [action.pageIndex]: newComments };
        }

        default: return state;
    }
};

export default combineReducers({
    deck,
    page
});
