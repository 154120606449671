import { List } from 'immutable';
import { getRgba } from '../../../../CanvasState/utilities/color';
import { findColorById } from '../../../../CanvasState/utilities/ColorPalette';

const isColorValueDescriptorValid = descriptor => {
    const isValidCoded = descriptor.get('type') === 'coded' && !!descriptor.get('reference');
    const isValidCustom = descriptor.get('type') === 'custom' && !!descriptor.get('value');
    return isValidCoded || isValidCustom;
};

const adaptColorValueAdapterToFabricColor = (
    colorValueDescriptor = Map(),
    colorPalette = List()
) => {
    if (isColorValueDescriptorValid(colorValueDescriptor)) {
        if (colorValueDescriptor.get('type') === 'coded') {
            const color = findColorById(colorPalette, colorValueDescriptor.get('reference'));
            if (color) {
                return getRgba(color);
            }
            if (colorValueDescriptor.get('value')) {
                return getRgba(colorValueDescriptor.get('value'));
            }
            throw new Error('Couldn\'t find color code in pallette and no fallback value where given.');
        }
        return getRgba(colorValueDescriptor.get('value'));
    }
    throw new Error(`Invalid color value descriptor ${colorValueDescriptor.toJS()}`);
};

export default adaptColorValueAdapterToFabricColor;
