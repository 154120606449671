module.exports = [
    'fill',
    'opacity',
    'borderColor',
    'borderSize',
    'scaleX',
    'scaleY',
    'id',
    'inLayout',
    'isLocked',
    'isHidden',
    'isImported',
    'placeholderType',
    'placeholderSequence',
    'style',
    'hasBandedColumns',
    'hasBandedRows',
    'hasHeaderColumn',
    'hasHeaderRow',
    'hasTotalColumn',
    'hasTotalRow',
    'mainAxis',
    'definedRowHeights'
];
