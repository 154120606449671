import { getRenderShapeProperty } from '../../../../CanvasState/Helpers/Style/shape';
import defaultShapeToFabricJSON from './default';

const fromShapeToFabricJSON = (shape, canvasState) => ({
    ...defaultShapeToFabricJSON(shape, canvasState),
    rx: getRenderShapeProperty(shape, ['rx']),
    ry: getRenderShapeProperty(shape, ['ry'])
});

export default fromShapeToFabricJSON;
