const invites = (state = {
    fetching: false,
    errorMessage: '',
    data: []
}, action) => {
    switch (action.type) {
        case 'FETCH_INVITES':
            return {
                ...state,
                fetching: true
            };
        case 'FETCH_INVITES_SUCCESS':
            return {
                ...state,
                fetching: false,
                data: action.data.map(invite => ({
                    ...invite,
                    isResending: false
                }))
            };
        case 'CANCEL_INVITE':
            return {
                ...state,
                data: state.data.filter(invite => invite._id !== action.inviteId)
            };
        case 'INVITE_SENT':
            return {
                ...state,
                data: [...state.data, ...action.invites],
                errorMessage: action.failedEmailErrors.join('\r\n')
            };
        case 'RESEND_INVITE_START':
            return {
                ...state,
                data: state.data.map(invite => {
                    if (invite._id === action.inviteId) {
                        return {
                            ...invite,
                            isResending: true
                        };
                    }
                    return invite;
                })
            };
        case 'RESEND_INVITE_END':
            return {
                ...state,
                data: state.data.map(invite => {
                    if (invite._id === action.inviteId) {
                        return {
                            ...invite,
                            resendCount: action.resendCount,
                            isResending: false
                        };
                    }
                    return invite;
                })
            };
        case 'INVITE_ERROR':
            return {
                ...state,
                errorMessage: action.error &&
                action.error.graphQLErrors.length > 0 &&
                action.error.graphQLErrors[0].message
            };
        case 'CLEAR_INVITE_ERROR':
            return {
                ...state,
                errorMessage: ''
            };
        case 'INVITE_RESET_RESEND':
            return {
                ...state,
                data: state.data.map(invite => {
                    if (invite._id === action.inviteId) {
                        return {
                            ...invite,
                            isResending: false
                        };
                    }
                    return invite;
                })
            };
        default:
            return state;
    }
};
export default invites;
