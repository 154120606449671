const defaultFabricAttributesConfig = {
    backgroundColor: '',
    breakWords: false,
    fillRule: 'nonzero',
    flipX: false,
    flipY: false,
    linethrough: false,
    lockScalingY: false,
    originX: 'center',
    originY: 'center',
    overline: false,
    scaleX: 1,
    scaleY: 1,
    opacity: 1,
    selectable: true,
    selected: false,
    shadow: null,
    strokeDashArray: null,
    transformMatrix: null,
    type: 'textshape',
    underline: false,
    visible: true,
    zoomX: 1,
    zoomY: 1,
    isBackground: false,
    isImported: false,
    isLocked: false,
    isHidden: false
};

module.exports = defaultFabricAttributesConfig;
