import { fromJS } from 'immutable';
import defaultFromFabricToCanvasState from './default';

const fromFabricToCanvasState = (fabricShape, canvasState) => fromJS({
    ...defaultFromFabricToCanvasState(fabricShape, canvasState),
    fill: undefined,
    type: 'Line',
    coords: fabricShape.coords,
    connectionStrategy: fabricShape.connectionStrategy
});

export default fromFabricToCanvasState;
