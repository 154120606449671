import defaultPropertyAdapters from '../PropertyAdapters/default';

const fromFabricToCanvasState = (fabricShape, canvasState) => ({
    id: defaultPropertyAdapters.id(fabricShape),
    name: defaultPropertyAdapters.name(fabricShape),
    type: defaultPropertyAdapters.type(fabricShape),
    flipX: defaultPropertyAdapters.flipX(fabricShape),
    flipY: defaultPropertyAdapters.flipY(fabricShape),
    x: defaultPropertyAdapters.left(fabricShape),
    y: defaultPropertyAdapters.top(fabricShape),
    width: defaultPropertyAdapters.width(fabricShape),
    height: defaultPropertyAdapters.height(fabricShape),
    style: defaultPropertyAdapters.style(fabricShape),
    rotation: defaultPropertyAdapters.angle(fabricShape),
    inLayout: defaultPropertyAdapters.inLayout(fabricShape),
    scaleX: defaultPropertyAdapters.scaleX(fabricShape),
    scaleY: defaultPropertyAdapters.scaleY(fabricShape),
    lockPath: defaultPropertyAdapters.lockPath(fabricShape),
    isBackground: defaultPropertyAdapters.isBackground(fabricShape),
    isGroupHidden: defaultPropertyAdapters.isGroupHidden(fabricShape),
    isGroupLocked: defaultPropertyAdapters.isGroupLocked(fabricShape),
    isHidden: defaultPropertyAdapters.isHidden(fabricShape),
    isImported: defaultPropertyAdapters.isImported(fabricShape),
    isLocked: defaultPropertyAdapters.isLocked(fabricShape),
    skewX: defaultPropertyAdapters.skewX(fabricShape),
    skewY: defaultPropertyAdapters.skewY(fabricShape),
    dynamicProperties: defaultPropertyAdapters.dynamicProperties(fabricShape),
    lockAspectRatio: defaultPropertyAdapters.lockUniScaling(fabricShape),
    fill: defaultPropertyAdapters.fill(fabricShape, canvasState),
    opacity: defaultPropertyAdapters.opacity(fabricShape),
    stroke: defaultPropertyAdapters.stroke(fabricShape, canvasState),
    visible: defaultPropertyAdapters.visible(fabricShape),
    selectable: defaultPropertyAdapters.selectable(fabricShape),
    textBody: defaultPropertyAdapters.paragraphs(fabricShape, canvasState),
    textBodyPlaceholder: defaultPropertyAdapters.placeholderParagraphs(fabricShape, canvasState),
    assignedStyles: defaultPropertyAdapters.assignedStyles(fabricShape, canvasState),
    placeholderSequence: defaultPropertyAdapters.placeholderSequence(fabricShape),
    placeholderSourceId: defaultPropertyAdapters.placeholderSourceId(fabricShape),
    placeholderType: defaultPropertyAdapters.placeholderType(fabricShape)
});

export default fromFabricToCanvasState;
