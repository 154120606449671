import { combineReducers } from 'redux';
import * as types from '#Constants/ActionTypes';
import { resetOnLocationChange } from '#Utilities/reducers';
import { currentShare, isAudienceLevel } from './currentShare/currentShare';
import { currentComment } from './currentComment/currentComment';
import { deckSummary } from './shares/shares';
import { currentAudience } from './currentAudience/currentAudience';
import { currentChartSelection } from './currentChartSelection/currentChartSelection';
import { updateShare } from './updateShare/updateShare';
import { deleteShare } from './deleteShare/deleteShare';

const loading = (state = false, action) => {
    switch (action.type) {
        case types.FETCH_SHARE_INFORMATION_PENDING:
        case types.FETCH_SHARES_PENDING:
            return true;
        case types.FETCH_SHARE_INFORMATION:
        case types.FETCH_SHARE_INFORMATION_REJECTED:
        case types.FETCH_SHARES:
        case types.FETCH_SHARES_REJECTED:
            return false;
        default:
            return state;
    }
};

const displayPublicLinkModal = (state = false, action) => {
    switch (action.type) {
        case types.DISPLAY_PUBLIC_LINK_MODAL:
            return true;
        case types.HIDE_PUBLIC_LINK_MODAL:
            return false;
        default:
            return state;
    }
};

const displayNewShareModal = (state = false, action) => {
    switch (action.type) {
        case types.TOGGLE_NEW_SHARE_MODAL:
            return !state;
        default:
            return state;
    }
};

const combinedReducer = combineReducers({
    loading,
    displayPublicLinkModal,
    deckSummary,
    currentShare,
    currentComment,
    currentAudience,
    isAudienceLevel,
    displayNewShareModal,
    currentChartSelection,
    updateShare,
    deleteShare
});

export default resetOnLocationChange(combinedReducer);
