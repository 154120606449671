const { fabric } = require('fabric');
const svgpath = require('svgpath');
const Textshape = require('./TextShape');
const { getCalloutTailVertexes } = require('../../utilities/ShapeGenerators');
const { generateCalloutPath } = require('../../utilities/PathGenerator/PathGenerator');
const { flipDimension, flipAngle } = require('../utilities/FlipDimensions');
const { svgStringToArray, stringifyPath } = require('../../utilities/svgUtility');
const TextBoundsCalculator = require('../utilities/TextBoundsCalculator');
const geometry = require('../../utilities/geometry');

const Callout = fabric.util.createClass(Textshape, {
    type: 'callout',

    initialize(json, opts) {
        this.callSuper('initialize', json, opts);
        this.shapeType = 'Callout';
        this.getHandlerPosition = this.getHandlerPosition.bind(this);
        this.getHandlerLimits = this.getHandlerLimits.bind(this);
        this.updateModifierAttribute = this.updateModifierAttribute.bind(this);
    },

    updateShapeScale(scaleX, scaleY) {
        this.callSuper('updateShapeScale', scaleX, scaleY, true);
    },

    prepareShapeForRendering() {
        this.callSuper('prepareShapeForRendering');
        this.setObjectCaching(false);
        this.adjustShapePosition();
        this.on('scaling', () => {
            this.canvas.on('mouse:up', () => {
                this.getShapeObject().set({
                    pathOffset: {
                        x: ((this.getShapeObject().width * this.getShapeObject().scaleX) / 2),
                        y: ((this.getShapeObject().height * this.getShapeObject().scaleY) / 2)
                    }
                });
                this.adjustShapePosition();
                this.canvas.off('mouse:up');
            });
        });
    },

    generateModificationHandlers() {
        this.handlers = [
            new fabric.GenericModificationHandler(
                this,
                this.getHandlerPosition,
                this.getHandlerLimits,
                this.updateModifierAttribute,
                { refreshText: false }
            )
        ];
    },

    adjustShapePosition() {
        const callout = this.getShapeObject();
        const newPath = svgStringToArray(
            svgpath(stringifyPath(callout.path)).scale(callout.scaleX, callout.scaleY)
        );
        const newTail = this.getTailFromPath(newPath);
        const realWidth = this.getRealWidthWithScale(callout.scaleX);
        const realHeight = this.getRealHeightWithScale(callout.scaleY);

        this.currentOffset = this.currentOffset || {
            x: (
                Math.sign(newTail.point.x) *
                (realWidth - (callout.width * callout.scaleX))
            ) / 2,
            y: (
                Math.sign(newTail.point.y) *
                (realHeight - (callout.height * callout.scaleY))
            ) / 2
        };
        const newLength = geometry.distanceBetweenPoints(
            { x: this.width / 2, y: this.height / 2 },
            newTail.point
        );
        const newTailWidth = geometry.distanceBetweenPoints(
            newTail.left,
            newTail.right
        );
        callout.set({
            width: callout.width * callout.scaleX,
            height: callout.height * callout.scaleY,
            scaleX: 1,
            scaleY: 1,
            path: newPath,
            left: this.currentOffset.x * callout.scaleX,
            top: this.currentOffset.y * callout.scaleY,
            tailLength: newLength,
            tailWidth: newTailWidth
        });
    },

    getRealHeightWithScale(scaleY) {
        const callout = this.getShapeObject();
        const tailPoint = this.getTailVertexes().point;

        if (tailPoint.y < 0) {
            return Math.abs(tailPoint.y) + (callout.height * scaleY);
        }
        return Math.max(
            (callout.height * scaleY),
            tailPoint.y
        );
    },

    getRealWidthWithScale(scaleX) {
        const callout = this.getShapeObject();
        const tailPoint = this.getTailVertexes().point;

        if (tailPoint.x < 0) {
            return Math.abs(tailPoint.x) + (callout.width * scaleX);
        }
        return Math.max(
            (callout.width * scaleX),
            tailPoint.x
        );
    },

    getTailFromPath(path) {
        return {
            point: {
                x: path[3][1],
                y: path[3][2]
            },
            left: { x: path[4][1], y: path[4][2] },
            right: { x: path[5][1], y: path[5][2] }
        };
    },

    getHandlerPosition() {
        const relativePosition = this.getRelativeHandlerPosition();
        const callout = this.getShapeObject();
        const absolutePosition = this.getAbsolutePosition();
        return {
            x: absolutePosition.x + flipDimension(callout.flipX, relativePosition.x),
            y: absolutePosition.y + flipDimension(callout.flipY, relativePosition.y)
        };
    },

    getRelativeHandlerPosition() {
        const callout = this.getShapeObject();
        const halfWidth = callout.width / 2;
        const halfHeight = callout.height / 2;
        const tailVertexes = this.getTailVertexes();
        const activeScaleX = this.scaleX * callout.scaleX;
        const activeScaleY = this.scaleY * callout.scaleY;
        return {
            x: (tailVertexes.point.x - halfWidth) * activeScaleX,
            y: (tailVertexes.point.y - halfHeight) * activeScaleY
        };
    },

    getHandlerLimits() {
        return {
            min: Number.MIN_VALUE,
            max: Number.MAX_VALUE
        };
    },

    updateModifierAttribute({ x, y }) {
        const callout = this.getShapeObject();
        const newTailLength = this.getNewTailLength({ x, y });
        const newTailAngle = this.getNewTailAngle({ x, y });
        const path = svgpath(this.getNewPath(newTailLength, newTailAngle)).segments;
        callout.tailLength = newTailLength;
        callout.tailAngle = newTailAngle;
        callout.path = path;
        this.set('dirty', true);
    },

    getNewPath(newTailLength, newTailAngle) {
        const callout = this.getShapeObject();
        const tailVertexes = this.getTailVertexes(newTailLength, newTailAngle);
        return generateCalloutPath(callout.width, callout.height, tailVertexes);
    },

    getTailVertexes(
        newTailLength = this.getShapeObject().tailLength,
        newTailAngle = this.getShapeObject().tailAngle
    ) {
        const callout = this.getShapeObject();
        return getCalloutTailVertexes(
            callout.width,
            callout.height,
            newTailLength,
            callout.tailWidth,
            newTailAngle
        );
    },

    getNewTailLength({ x, y }) {
        const callout = this.getShapeObject();
        const activeScaleX = this.scaleX * callout.scaleX;
        const activeScaleY = this.scaleY * callout.scaleY;
        const absolutePosition = this.getAbsolutePosition();
        return geometry.distanceBetweenPoints(
            { x: 0, y: 0 },
            {
                x: (x - absolutePosition.x) / activeScaleX,
                y: (y - absolutePosition.y) / activeScaleY
            }
        );
    },

    getNewTailAngle({ x, y }) {
        const callout = this.getShapeObject();
        const activeScaleX = this.scaleX * callout.scaleX;
        const activeScaleY = this.scaleY * callout.scaleY;
        const absolutePosition = this.getAbsolutePosition();
        const newAngle = Math.atan2(
            (y - absolutePosition.y) / activeScaleY,
            (x - absolutePosition.x) / activeScaleX
        );
        const flippedAngle = flipAngle(callout.flipX, callout.flipY, newAngle);
        return (flippedAngle * 180) / Math.PI;
    },

    getTextBounds() {
        const shape = this.getShapeObject();
        return new TextBoundsCalculator.Ellipse(
            this.width,
            this.height,
            {
                flipX: shape.flipX
            }
        );
    }
});

Callout.fromObject = (object, callback) => {
    const callout = new Callout(object);
    callout.on('text:load', () => {
        callback(callout);
    })
        .on('load:error', err => {
            console.error(err);
            callback(null);
        });
};

module.exports = Callout;
