import { fromJS } from 'immutable';

const { defaultShapeStyle, defaultStrokeStyle } = require('./defaultShapeStyle');

const shapeStyleProvider = {
    Textbox: {
        ...defaultShapeStyle,
        width: 240,
        height: 90,
        fill: {
            type: 'color',
            color: {
                type: 'custom',
                value: 'rgba(255, 255, 255, 0)'
            }
        },
        stroke: {
            fill: {
                type: 'color',
                color: {
                    type: 'custom',
                    value: 'rgba(77, 77, 77, 1)'
                }
            },
            width: 0
        },
        placeholderText: 'Enter text...',
        text: '',
        opacity: 0
    },
    Rectangle: {
        ...defaultShapeStyle,
        width: 240,
        height: 90,
        rx: 0.0,
        ry: 0.0
    },
    Group: {
        ...defaultShapeStyle,
        stroke: undefined,
        fill: undefined
    },
    Line: {
        ...defaultShapeStyle,
        stroke: {
            ...defaultStrokeStyle,
            width: 1
        }
    },
    DEFAULT: { ...defaultShapeStyle }
};

const immutableShapeStyleProvider = fromJS(shapeStyleProvider);

export {
    shapeStyleProvider,
    immutableShapeStyleProvider
};
