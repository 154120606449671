module.exports = {
    shapePreset: {
        isReference: true
    },
    typefacePreset: {
        isReference: true
    },
    paragraphPreset: {
        isReference: true
    },
    strokePreset: {
        isReference: true
    },
    topBorderPreset: {
        isReference: true
    },
    bottomBorderPreset: {
        isReference: true
    },
    leftBorderPreset: {
        isReference: true
    },
    rightBorderPreset: {
        isReference: true
    },
    internalHorizontalBorderPreset: {
        isReference: true
    },
    internalVerticalBorderPreset: {
        isReference: true
    }
};
