class Join {
    constructor(type, miterLimit) {
        this.type = type;
        this.miterLimit = miterLimit;
    }

    toJSON() {
        return {
            type: this.type,
            miterLimit: this.miterLimit
        };
    }

    static fromJSON(jsonObject = {}) {
        return new this(jsonObject.type, jsonObject.miterLimit);
    }
}

module.exports = Join;
