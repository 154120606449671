const isArray = require('lodash/isArray');

const equalOrNullDeep = require('./equalsOrNullDeep');
const omitNullDeep = require('./omitNullDeep');

module.exports = (object, ...sources) => {
    let srcs = [...sources];
    let exceptions = srcs.pop();
    if (!isArray(exceptions)) {
        srcs = [...srcs, exceptions];
        exceptions = [];
    }
    return srcs.reduce(
        (intersection, nextSource) => omitNullDeep(equalOrNullDeep(
            intersection,
            nextSource,
            exceptions
        )),
        object
    );
};
