const { fabric } = require('fabric');
const Textshape = require('./TextShape');

const Chart = fabric.util.createClass(Textshape, {
    type: 'chart',

    initialize(json, opts = {}) {
        this.callSuper('initialize', json, opts);
        this.shapeType = 'Chart';
    },

    updateShapeScale(scaleX, scaleY) {
        const shapeObject = this.getShapeObject();
        shapeObject.set({
            width: this.width * scaleX,
            height: this.height * scaleY,
            scaleX: 1,
            scaleY: 1
        });
        this.width *= scaleX;
        this.height *= scaleY;
        this.scaleX = 1;
        this.scaleY = 1;
    }
});

Chart.fromObject = (object, callback) => {
    if (!object.fill || object.fill.type !== 'imageSource') {
        const fill = {
            type: 'imageSource',
            stretch: true,
            crop: {
                top: 0,
                rigth: 0,
                bottom: 0,
                left: 0
            }
        };

        object.fill = fill;
    }

    const chart = new Chart(object);
    chart.on('text:load', () => {
        callback(chart);
    })
        .on('load:error', err => {
            console.error(err);
            callback(null);
        });
};

module.exports = Chart;
