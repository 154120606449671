module.exports = Base => class extends Base {
    fromShapeToFabric() {
        const fabricObject = super.fromShapeToFabric();
        fabricObject.rx = this.getRenderedRx();
        fabricObject.ry = this.getRenderedRy();
        fabricObject.type = 'rectangle';
        return fabricObject;
    }

    static isSerializedFabricObject(serializedFabricObject) {
        return typeof serializedFabricObject.rx === 'number' &&
            typeof serializedFabricObject.ry === 'number';
    }

    static getShapeAttributesFromSerializedFabricObject(serializedFabricObject = {}) {
        return {
            ...super.getShapeAttributesFromSerializedFabricObject(serializedFabricObject),
            rx: serializedFabricObject.rx,
            ry: serializedFabricObject.ry
        };
    }

    getRenderedRx() {
        return this.getRenderShapeProperty('rx') * this.width;
    }

    getRenderedRy() {
        return this.getRenderShapeProperty('ry') * this.height;
    }
};
