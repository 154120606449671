import { getShapePathById } from '../../../../../../Canvas/CanvasStateSelectors';

const getAssignedStyles = (table, border, canvasState) => canvasState.getIn(
    [
        ...getShapePathById(canvasState, table.id),
        'borders',
        table.borders
            .findIndex(currentBorder => currentBorder.id === border.id),
        'assignedStyles']
);

export default getAssignedStyles;
