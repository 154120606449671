import { List, Map } from 'immutable';
import { convertPropertiesToShapeProperties } from '../../utilities/convertPropertiesToShapeProperties';

import getPropertiesForDestructuring from '../../utilities/getPropertiesForDestructuring';
import omit from '../../utilities/omit';
import {
    updateCells
} from './cell';
import {
    hasHeaderSpannerColumn,
    hasHeaderSpannerRow,
    isHeaderSpannerColumn,
    isHeaderSpannerRow,
    getFirstColumn,
    getFirstRow,
    getLastColumn,
    getLastRow,
    getCellsInColumn,
    getCellsInRow
} from './tableHelper';

const PART_NAMES = {
    BANDED_COLUMN_EVEN: 'bandedColumnEven',
    BANDED_COLUMN_ODD: 'bandedColumnOdd',
    BANDED_ROW_EVEN: 'bandedRowEven',
    BANDED_ROW_ODD: 'bandedRowOdd',
    CONTENT_COLUMN: 'contentColumn',
    CONTENT_ROW: 'contentRow',
    FIRST_COLUMN: 'firstColumn',
    FIRST_ROW: 'firstRow',
    HEADER_COLUMN: 'headerColumn',
    HEADER_ROW: 'headerRow',
    HEADER_SPANNER_COLUMN: 'headerSpannerColumn',
    HEADER_SPANNER_ROW: 'headerSpannerRow',
    LAST_COLUMN: 'lastColumn',
    LAST_ROW: 'lastRow',
    SUBTOTAL_COLUMN: 'subtotalColumn',
    SUBTOTAL_ROW: 'subtotalRow',
    TOTAL_COLUMN: 'totalColumn',
    TOTAL_ROW: 'totalRow'
};

const IMPLEMENTED_PART_UPDATES = [
    PART_NAMES.CONTENT_COLUMN,
    PART_NAMES.CONTENT_ROW,
    PART_NAMES.BANDED_COLUMN_EVEN,
    PART_NAMES.BANDED_COLUMN_ODD,
    PART_NAMES.BANDED_ROW_EVEN,
    PART_NAMES.BANDED_ROW_ODD,
    PART_NAMES.LAST_COLUMN,
    PART_NAMES.LAST_ROW,
    PART_NAMES.FIRST_COLUMN,
    PART_NAMES.FIRST_ROW,
    PART_NAMES.TOTAL_COLUMN,
    PART_NAMES.TOTAL_ROW,
    PART_NAMES.HEADER_COLUMN,
    PART_NAMES.HEADER_ROW,
    PART_NAMES.HEADER_SPANNER_COLUMN,
    PART_NAMES.HEADER_SPANNER_ROW
];

const BANDED_PARTS = [
    PART_NAMES.BANDED_ROW_EVEN,
    PART_NAMES.BANDED_ROW_ODD,
    PART_NAMES.BANDED_COLUMN_EVEN,
    PART_NAMES.BANDED_COLUMN_ODD
];

const getPartUpdateSequence = table => {
    if (table.get('mainAxis') === 'vertical') {
        return [
            PART_NAMES.CONTENT_ROW,
            PART_NAMES.BANDED_COLUMN_ODD,
            PART_NAMES.BANDED_ROW_EVEN,
            PART_NAMES.BANDED_ROW_ODD,
            PART_NAMES.TOTAL_COLUMN,
            PART_NAMES.HEADER_COLUMN,
            PART_NAMES.HEADER_SPANNER_COLUMN,
            PART_NAMES.TOTAL_ROW,
            PART_NAMES.HEADER_ROW,
            PART_NAMES.HEADER_SPANNER_ROW
        ]
            .filter(partName => (
                IMPLEMENTED_PART_UPDATES.includes(partName)
            ));
    }
    return [
        PART_NAMES.CONTENT_COLUMN,
        PART_NAMES.BANDED_ROW_ODD,
        PART_NAMES.BANDED_COLUMN_EVEN,
        PART_NAMES.BANDED_COLUMN_ODD,
        PART_NAMES.TOTAL_ROW,
        PART_NAMES.HEADER_ROW,
        PART_NAMES.HEADER_SPANNER_ROW,
        PART_NAMES.TOTAL_COLUMN,
        PART_NAMES.HEADER_COLUMN,
        PART_NAMES.HEADER_SPANNER_COLUMN
    ]
        .filter(partName => (
            IMPLEMENTED_PART_UPDATES.includes(partName)
        ));
};

const applyPartUpdates = (table, update) => {
    const {
        shapeProps,
        removeCustomStyles
    } = getPropertiesForDestructuring(
        update,
        [
            'shapeProps',
            'removeCustomStyles'
        ]
    );

    const {
        partUpdates,
        tableStyleMismatches = Map()
    } = getPropertiesForDestructuring(
        shapeProps,
        [
            'partUpdates',
            'tableStyleMismatches'
        ]
    );

    let updatedTable = table;

    getPartUpdateSequence(table)
        .forEach(partName => {
            if (partUpdates.get(partName)) {
                const partUpdate = partUpdates
                    .get(partName)
                    .merge(
                        Map({
                            tableStyleMismatches,
                            removeCustomStyles
                        })
                    );
                updatedTable = applyPartUpdate(updatedTable, partName, partUpdate);
            }
        });

    return updatedTable;
};

const applyPartUpdate = (table, partName, update) => {
    const cellStyleMismatches = update
        .getIn(
            [
                'tableStyleMismatches',
                'cells'
            ],
            List()
        );

    const borderStyleMismatches = update
        .getIn(
            [
                'tableStyleMismatches',
                'borders'
            ],
            List()
        );

    const {
        shapeProps,
        textProps,
        removeCustomStyles
    } = getPropertiesForDestructuring(
        convertPropertiesToShapeProperties(update),
        [
            'shapeProps',
            'textProps',
            'removeCustomStyles'
        ]
    );

    const cellIds = getPartCellIds(table, partName)
        .filter(cellId => !cellStyleMismatches.includes(cellId));

    let cellUpdate = Map();
    let updatedTable = table;

    if (BANDED_PARTS.includes(partName)) {
        const mismatchedCellIds = getPartCellIds(table, partName)
            .filter(cellId => cellStyleMismatches.includes(cellId));

        cellUpdate = Map({
            shapeProps: omit(shapeProps, ['style'])
                .merge(
                    Map({
                        borderUpdates: shapeProps
                            .get('borderUpdates')
                            .merge(
                                Map({
                                    styleMismatches: borderStyleMismatches
                                })
                            )
                    })
                ),
            textProps: undefined,
            strokeProps: undefined
        });

        updatedTable = updateCells(updatedTable, mismatchedCellIds.toJS(), cellUpdate);
    }

    cellUpdate = Map({
        shapeProps: shapeProps
            .merge(
                Map({
                    borderUpdates: shapeProps
                        .get('borderUpdates')
                        .merge(
                            Map({
                                styleMismatches: borderStyleMismatches
                            })
                        )
                })
            ),
        textProps,
        strokeProps: undefined,
        removeCustomStyles
    });

    updatedTable = updateCells(updatedTable, cellIds.toJS(), cellUpdate);

    return updatedTable;
};

const getPartCellIds = (table, partName) => getPartCells(table, partName).map(cell => cell.get('id'));

const getPartCells = (table, partName) => {
    switch (partName) {
        case PART_NAMES.BANDED_COLUMN_EVEN:
            return getEvenColumnCells(table);
        case PART_NAMES.BANDED_COLUMN_ODD:
            return getOddColumnCells(table);
        case PART_NAMES.BANDED_ROW_EVEN:
            return getEvenRowCells(table);
        case PART_NAMES.BANDED_ROW_ODD:
            return getOddRowCells(table);
        case PART_NAMES.CONTENT_COLUMN:
        case PART_NAMES.CONTENT_ROW:
            return getContentCells(table);
        case PART_NAMES.FIRST_COLUMN:
            if (table.get('mainAxis') === 'horizontal') {
                return getFirstColumn(table);
            }
            return List();
        case PART_NAMES.FIRST_ROW:
            if (table.get('mainAxis') === 'vertical') {
                return getFirstRow(table);
            }
            return List();
        case PART_NAMES.HEADER_COLUMN:
            return getHeaderColumn(table);
        case PART_NAMES.HEADER_ROW:
            return getHeaderRow(table);
        case PART_NAMES.HEADER_SPANNER_ROW:
            return getHeaderSpannerRow(table);
        case PART_NAMES.HEADER_SPANNER_COLUMN:
            return getHeaderSpannerColumn(table);
        case PART_NAMES.LAST_COLUMN:
            if (table.get('mainAxis') === 'horizontal') {
                return getLastColumn(table);
            }
            return List();
        case PART_NAMES.LAST_ROW:
            if (table.get('mainAxis') === 'vertical') {
                return getLastRow(table);
            }
            return List();
        case PART_NAMES.TOTAL_COLUMN: {
            if (table.get('hasTotalColumn')) {
                return getLastColumn(table);
            }
            return List();
        }
        case PART_NAMES.TOTAL_ROW: {
            if (table.get('hasTotalRow')) {
                return getLastRow(table);
            }
            return List();
        }
        default:
            return List();
    }
};

const getEvenBandedColumnIndexes = table => {
    if (!table.get('hasBandedColumns')) {
        return List();
    }
    const offsetWithFirstColumn = table.get('hasHeaderColumn') + hasHeaderSpannerColumn(table);
    const offsetWithLastColumn = table.get('hasTotalColumn');
    return List(
        Array(
            Math.max(table.get('columnWidths').size - (offsetWithFirstColumn + offsetWithLastColumn), 0)
        )
            .keys()
    )
        .map(index => (index % 2 === 1 ?
            index + offsetWithFirstColumn :
            false
        ))
        .filter(index => index !== false);
};

const getOddBandedColumnIndexes = table => {
    if (!table.get('hasBandedColumns')) {
        return List();
    }
    const offsetWithFirstColumn = table.get('hasHeaderColumn') + hasHeaderSpannerColumn(table);
    const offsetWithLastColumn = table.get('hasTotalColumn');
    return List(
        Array(
            Math.max(table.get('columnWidths').size - (offsetWithFirstColumn + offsetWithLastColumn), 0)
        )
            .keys()
    )
        .map(index => (index % 2 === 0 ?
            index + offsetWithFirstColumn :
            false
        ))
        .filter(index => index !== false);
};

const getEvenBandedRowIndexes = table => {
    if (!table.get('hasBandedRows')) {
        return List();
    }
    const offsetWithFirstRow = table.get('hasHeaderRow') + hasHeaderSpannerRow(table);
    const offsetWithLastRow = table.get('hasTotalRow');
    return List(
        Array(Math.max(table.get('rowHeights').size - (offsetWithFirstRow + offsetWithLastRow), 0)).keys()
    )
        .map(index => (index % 2 === 1 ?
            index + offsetWithFirstRow :
            false
        ))
        .filter(index => index !== false);
};

const getOddBandedRowIndexes = table => {
    if (!table.get('hasBandedRows')) {
        return List();
    }
    const offsetWithFirstRow = table.get('hasHeaderRow') + hasHeaderSpannerRow(table);
    const offsetWithLastRow = table.get('hasTotalRow');
    return List(
        Array(Math.max(table.get('rowHeights').size - (offsetWithFirstRow + offsetWithLastRow), 0)).keys()
    )
        .map(index => (index % 2 === 0 ?
            index + offsetWithFirstRow :
            false
        ))
        .filter(index => index !== false);
};

const getContentCells = table => {
    const firstColumn = 0 + table.get('hasHeaderColumn');
    const firstRow = 0 + table.get('hasHeaderRow');
    const lastColumn = table.get('columnWidths').size - (1 + table.get('hasTotalColumn'));
    const lastRow = table.get('rowHeights').size - (1 + table.get('hasTotalRow'));
    return table.get('cells')
        .filter(cell => {
            const afterFirstColumn = cell.get('column') >= firstColumn;
            const afterFirstRow = cell.get('row') >= firstRow;
            const beforeLastColumn = cell.get('column') + (cell.get('columnSpan') - 1) <= lastColumn;
            const beforeLastRow = cell.get('row') + (cell.get('rowSpan') - 1) <= lastRow;
            return (
                afterFirstColumn &&
                afterFirstRow &&
                beforeLastColumn &&
                beforeLastRow
            );
        });
};

const getEvenColumnCells = table => {
    const columnIndexes = getEvenBandedColumnIndexes(table);
    return getContentCells(table)
        .filter(cell => columnIndexes.includes(cell.get('column')));
};

const getOddColumnCells = table => {
    const columnIndexes = getOddBandedColumnIndexes(table);
    return getContentCells(table)
        .filter(cell => columnIndexes.includes(cell.get('column')));
};

const getEvenRowCells = table => {
    const rowIndexes = getEvenBandedRowIndexes(table);
    return getContentCells(table)
        .filter(cell => rowIndexes.includes(cell.get('row')));
};

const getOddRowCells = table => {
    const rowIndexes = getOddBandedRowIndexes(table);
    return getContentCells(table)
        .filter(cell => rowIndexes.includes(cell.get('row')));
};

const getHeaderColumn = table => (table.get('hasHeaderColumn') ?
    getCellsInColumn(table, Number(hasHeaderSpannerColumn(table))) : List());

const getHeaderRow = table => (table.get('hasHeaderRow') ?
    getCellsInRow(table, Number(hasHeaderSpannerRow(table))) : List());

const getHeaderSpannerColumn = table => (hasHeaderSpannerColumn(table) ?
    getFirstColumn(table).filter(cell => isHeaderSpannerColumn(cell)) : List());

const getHeaderSpannerRow = table => (hasHeaderSpannerRow(table) ?
    getFirstRow(table).filter(cell => isHeaderSpannerRow(cell)) : List());

export {
    getPartUpdateSequence,
    getPartCells,
    applyPartUpdates,
    PART_NAMES
};
