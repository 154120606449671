const Bounds = require('./Bounds/Bounds');
const RectangleBounds = require('./Bounds/RectangleBounds');
const EllipseBounds = require('./Bounds/EllipseBounds');
const TriangleBounds = require('./Bounds/TriangleBounds');
const TrapezoidBounds = require('./Bounds/TrapezoidBounds');
const ChevronBounds = require('./Bounds/ChevronBounds');
const ArrowBounds = require('./Bounds/ArrowBounds');
const DiamondBounds = require('./Bounds/DiamondBounds');

module.exports = {
    Default: Bounds,
    Rectangle: RectangleBounds,
    Ellipse: EllipseBounds,
    Triangle: TriangleBounds,
    Trapezoid: TrapezoidBounds,
    Chevron: ChevronBounds,
    Arrow: ArrowBounds,
    Diamond: DiamondBounds
};
