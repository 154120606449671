import { get } from 'lodash';
import {
    getParagraphsWithParagraphStyle
} from '../Paragraphs/ParagraphStyles';

const romanize = index => {
    if (!+index) return false;
    const digits = String(+index).split('');
    const key = ['', 'C', 'CC', 'CCC', 'CD', 'D', 'DC', 'DCC', 'DCCC', 'CM',
        '', 'X', 'XX', 'XXX', 'XL', 'L', 'LX', 'LXX', 'LXXX', 'XC',
        '', 'I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX'];
    let roman = '';
    let i = 3;
    while (i--) roman = (key[+digits.pop() + (i * 10)] || '') + roman;
    return `${Array(+digits.join('') + 1).join('M') + roman}.`;
};

const parseStrategy = options => {
    const [type, specifier] = options.type.split('/');
    const strategy = {
        ...options,
        type
    };
    if (type === 'ordered' && !strategy.orderStrategy) {
        strategy.orderStrategy = specifier;
    }
    if (type === 'unordered' && !options.text) {
        strategy.text = specifier;
    }
    return strategy;
};

const calcNumberToRenderForOrderedBullet = (index, textBody, itemCountStartsAt) => getParagraphsWithParagraphStyle(
    textBody,
    0,
    index
)
    .reduce((levels, paragraph) => {
        const level = paragraph.getIn(['style', 'level'], 1);
        const newLevels = levels.slice(0, level + 1);
        newLevels[level] = newLevels[level] === undefined ?
            itemCountStartsAt :
            newLevels[level] + 1;
        return newLevels;
    }, [])
    .pop();

const getOrderedBulletTextForIndex = (index, options, textBody) => {
    const itemCountStartsAt = get(options, 'itemCountStartsAt', 1);
    const number = textBody ?
        calcNumberToRenderForOrderedBullet(index, textBody, itemCountStartsAt) :
        index + itemCountStartsAt;

    switch (options.orderStrategy) {
        case 'numeric':
            return `${number}.`;
        case 'roman':
            return romanize(number);
        default:
            throw new Error(`Bullet order strategy ${options.orderStrategy} isn't supported`);
    }
};

const getOrderedBulletRenderedText = (numberToRender, parsedStrategy) => {
    switch (parsedStrategy.orderStrategy) {
        case 'numeric':
            return `${numberToRender}.`;
        case 'roman':
            return romanize(numberToRender);
        default:
            throw new Error(`Bullet order strategy ${parsedStrategy.orderStrategy} isn't supported`);
    }
};

const bulletStrategies = (index, options, textBody) => {
    const parsedStrategy = parseStrategy(options);
    switch (parsedStrategy.type) {
        case 'ordered':
            return getOrderedBulletTextForIndex(index, parsedStrategy, textBody);
        case 'unordered':
            if (!parsedStrategy.text) throw new Error('Unordered bullet should be set with text');
            return parsedStrategy.text;
        case 'none':
            return '';
        default:
            throw new Error(`Bullet type ${parsedStrategy.type} isn't supported.`);
    }
};

const getBulletRenderedText = (numberToRender, parsedStrategy) => {
    switch (parsedStrategy.type) {
        case 'ordered':
            return getOrderedBulletRenderedText(numberToRender, parsedStrategy);
        case 'unordered':
            if (!parsedStrategy.text) throw new Error('Unordered bullet should be set with text');
            return parsedStrategy.text;
        case 'none':
            return '';
        default:
            throw new Error(`Bullet type ${parsedStrategy.type} isn't supported.`);
    }
};

const shouldIncrement = previousParagraph => !previousParagraph ||
    previousParagraph.text.length;

const getNumberToRender = (paragraphs, index) => {
    const paragraphToNumber = paragraphs[index];
    let previousParagraph;

    return paragraphs
        .slice(0, index + 1)
        .reduce((currentNumber, paragraph) => {
            if (paragraph.bulletType !== paragraphToNumber.bulletType || paragraphToNumber.level > paragraph.level) {
                previousParagraph = undefined;
                return 0;
            }

            if (paragraph.level === paragraphToNumber.level && shouldIncrement(previousParagraph)) {
                previousParagraph = paragraph;
                return currentNumber + 1;
            }

            return currentNumber;
        }, 0);
};

const getBulletTextForFabricParagraph = (paragraphs, index) => {
    const numberToRender = getNumberToRender(paragraphs, index);
    const parsedStrategy = parseStrategy({
        type: getBulletTypeFromFabricParagraph(paragraphs[index])
    });
    return getBulletRenderedText(numberToRender, parsedStrategy);
};

const getBulletTypeFromFabricParagraph = ({
    bulletType,
    bulletText
}) => {
    if (bulletType === 'unordered') {
        return `${bulletType}/${bulletText}`;
    }
    return bulletType;
};

const bulletStrategiesValidator = (options, textBody) => {
    bulletStrategies(0, options, textBody);
};

const getOrderedListStyleType = orderStrategy => {
    switch (orderStrategy) {
        case 'numeric':
            return 'decimal';
        case 'roman':
            return 'upper-roman';
        default:
            return '';
    }
};

const getListStyleType = options => {
    const strategy = parseStrategy(options);
    switch (strategy.type) {
        case 'ordered':
            return getOrderedListStyleType(strategy.orderStrategy);
        case 'unordered':
            return 'none';
        default:
            return '';
    }
};

const getClassName = options => {
    const strategy = parseStrategy(options);
    if (strategy.type === 'unordered') {
        return `${strategy.type}-${strategy.text}`;
    } if (strategy.type === 'ordered') {
        return `ordered-${strategy.orderStrategy}`;
    }
    return '';
};

const getListItemStyleTag = options => {
    const strategy = parseStrategy(options);
    if (strategy.type === 'unordered') {
        return `<style>.${getClassName(options)} li:before {content:'${strategy.text}';}</style>`;
    }
    return '';
};

const getUlLiText = options => {
    const strategy = parseStrategy(options);
    if (strategy.type === 'unordered') {
        return `;--ul-li-text:'${strategy.text}'`;
    }

    return '';
};

export {
    bulletStrategies,
    bulletStrategiesValidator,
    getBulletTextForFabricParagraph,
    getBulletRenderedText,
    getBulletTypeFromFabricParagraph,
    getListStyleType,
    getListItemStyleTag,
    getClassName,
    getOrderedBulletRenderedText,
    getUlLiText,
    getNumberToRender
};
