const getGradientLength = ({ width, height, angleInRadian }) => {
    let mainSide;
    let otherSide;
    const angleToChangeSide = Math.atan2(height, width);
    let angle = angleInRadian;
    while (angle < 0) angle += 2 * Math.PI;
    angle %= Math.PI;
    if (angle < angleToChangeSide) {
        mainSide = height;
        otherSide = width;
    } else if (angle < Math.PI / 2) {
        angle = (Math.PI / 2) - angle;
        mainSide = width;
        otherSide = height;
    } else if (angle < Math.PI - angleToChangeSide) {
        angle -= (Math.PI / 2);
        mainSide = width;
        otherSide = height;
    } else {
        angle = Math.PI - angle;
        mainSide = height;
        otherSide = width;
    }
    const internal = (mainSide / 2) / Math.sin((Math.PI / 2) - angle);
    const partialSide = ((mainSide / 2) / Math.sin((Math.PI / 2) - angle)) * Math.sin(angle);
    const external = ((otherSide / 2) - partialSide) * Math.sin(angle);
    return internal + external;
};

const convertGradientAngleToSizeModificator = ({ width, height, angleInDegree }) => {
    const angleInRadian = (angleInDegree * Math.PI) / 180;
    const gradientLength = getGradientLength({ width, height, angleInRadian });
    return {
        x1: (width / 2) - (gradientLength * Math.sin(-angleInRadian)),
        x2: (width / 2) + (gradientLength * Math.sin(-angleInRadian)),
        y1: (height / 2) - (gradientLength * Math.cos(-angleInRadian)),
        y2: (height / 2) + (gradientLength * Math.cos(-angleInRadian))
    };
};

const getGradientObject = ({
    gradient, width, rotation, height
}) => {
    const sizeModificator = convertGradientAngleToSizeModificator({
        width,
        height,
        angleInDegree: (gradient.angle - (gradient.rotateWithTheShape ? 0 : rotation)) - 90
    });
    if (gradient.gradientType === 'radial') {
        const offsetX = gradient.offsetX || 0;
        const offsetY = gradient.offsetY || 0;
        return {
            type: 'radial',
            x1: width / 2,
            // Fabric radial gradients fails to understand the meaning of life and freak out when
            // both start and end positions are the same, so moving the y of the start to make it
            // behave properly. Currently working in fabric.js 2.2.3 (currently using 2.0.1 beta6)
            y1: (height / 2) + 0.001,
            x2: (width / 2) + ((width / 2) * offsetX),
            y2: (height / 2) + ((height / 2) * offsetY),
            r1: Math.hypot(width / 2, height / 2),
            r2: 0,
            colorStops: gradient.stops.reduce((compilation, stop) => {
                compilation[1 - stop.position] = stop.color.value;
                return compilation;
            }, {})
        };
    }
    return {
        type: 'linear',
        x1: sizeModificator.x1,
        y1: sizeModificator.y1,
        x2: sizeModificator.x2,
        y2: sizeModificator.y2,
        colorStops: gradient.stops.reduce((compilation, stop) => {
            compilation[stop.position] = stop.color.value;
            return compilation;
        }, {})
    };
};

module.exports = {
    getGradientLength,
    convertGradientAngleToSizeModificator,
    getGradientObject
};
