import ReactGA from 'react-ga';
import { omit } from 'lodash';

import { updateDeckFromPlanIfNeeded, removeDeckPage } from '#Actions/deck';
import { updateCanvasState } from '#Actions/build/objects';
import { addPreviouslyExistingPage, createChangeActivePageAction } from '#Actions/build/page';
import * as types from '#Constants/ActionTypes';
import client from '#Utilities/graphql';

import { history } from '../configureStore';

const undo = (dispatch, getState) => {
    const {
        deck: {
            pages
        },
        history: {
            undoStack
        }
    } = getState();

    const undoAction = undoStack.peek();

    if (!undoAction) {
        return;
    }

    const payload = omit(undoAction, ['historyActionType']);

    switch (undoAction.historyActionType) {
        case types.CANVAS_STATE:
            dispatch({ type: `UNDO_${undoAction.historyActionType}` });
            dispatch({
                ...updateCanvasState(undoAction.updatedState, true, true),
                historyActionType: undoAction.historyActionType
            });
            break;
        case types.REMOVE_PAGE:
            dispatch({ type: `UNDO_${undoAction.historyActionType}`, ...payload });
            dispatch(addPreviouslyExistingPage(undoAction.pageId, undoAction.pageIndex, undoAction.activePageIndex));
            break;
        case types.CHANGE_PAGE:
            dispatch({ type: `UNDO_${undoAction.historyActionType}`, ...payload });
            dispatch(createChangeActivePageAction(pages[undoAction.currentPageIndex].id, {
                historyActionType: undoAction.historyActionType
            }));
            break;
        default:
            throw new Error(`No undo handler found for ${undoAction.historyActionType}`);
    }
};

const redo = (dispatch, getState) => {
    const {
        deck: {
            pages
        },
        history: {
            redoStack
        }
    } = getState();

    const redoAction = redoStack.peek();

    if (!redoAction) {
        return;
    }

    const payload = omit(redoAction, ['historyActionType']);

    switch (redoAction.historyActionType) {
        case types.CANVAS_STATE:
            dispatch({ type: `REDO_${redoAction.historyActionType}` });
            dispatch({
                ...updateCanvasState(redoAction.updatedState, true, true),
                historyActionType: redoAction.historyActionType
            });
            break;
        case types.REMOVE_PAGE:
            dispatch({ type: `REDO_${redoAction.historyActionType}`, ...payload });
            dispatch(removeDeckPage(redoAction.pageId, { reason: 'REMOVE_PAGE' }));
            break;
        case types.CHANGE_PAGE:
            dispatch({ type: `REDO_${redoAction.historyActionType}`, ...payload });
            dispatch(createChangeActivePageAction(pages[redoAction.newPageIndex].id, {
                historyActionType: redoAction.historyActionType
            }));
            break;
        default:
            throw new Error(`No redo handler found for ${redoAction.historyActionType}`);
    }
};

const changeSubNavStatus = status => ({
    type: types.CHANGE_SUB_NAV_STATUS,
    status
});

const setAppLoading = loading => ({
    type: types.SET_LOADING,
    loading
});

const setAppConfig = () => dispatch => client.getConfig()
    .then(config => {
        dispatch({
            type: types.SET_APP_CONFIG,
            config
        });
        const { GATrackingID } = config;
        ReactGA.initialize(GATrackingID);
        ReactGA.pageview(window.location.pathname + window.location.search);
        history.listen(location => {
            const token = JSON.parse(localStorage.getItem('cognito-user-tokens'))?.accessToken || null;
            const isAuthenticated = token?.jwtToken || false;
            if (isAuthenticated) {
                client.setActiveSection(location.pathname.slice(1) + location.search)
                    .then(({ activeSection }) => dispatch({ type: types.SET_ACTIVE_SECTION, section: activeSection }));
                ReactGA.pageview(location.pathname + location.search);
            }
        });
    });

const checkAndSave = () => dispatch => Promise.all([
    dispatch(updateDeckFromPlanIfNeeded())
]);

export {
    undo,
    redo,
    setAppLoading,
    changeSubNavStatus,
    setAppConfig,
    checkAndSave
};
