import getPath from '../PropertyAdapters/callout/path';
import getTailAngle from '../PropertyAdapters/callout/tailAngle';
import getTailLength from '../PropertyAdapters/callout/tailLength';
import getTailVertexes from '../PropertyAdapters/callout/tailVertexes';
import getTailWidth from '../PropertyAdapters/callout/tailWidth';
import defaultShapeToFabricJSON from './default';

const fromShapeToFabricJSON = (shape, canvasState) => ({
    ...defaultShapeToFabricJSON(shape, canvasState),
    tailWidth: getTailWidth(shape),
    tailLength: getTailLength(shape),
    tailAngle: getTailAngle(shape),
    tailVertexes: getTailVertexes(shape),
    path: getPath(shape)
});

export default fromShapeToFabricJSON;
