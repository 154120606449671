const { fabric } = require('fabric');
const { defaultPositionMarkerAttributes } = require('./config/defaultMarkerAttributes.config');

const PositionMarker = fabric.util.createClass(fabric.Line, {
    type: 'positionMarker',
    excludeFromExport: true,

    initialize(border) {
        const table = border.getTable();
        let x1 = table.getAbsoluteLeft() - (table.width / 2);
        let x2 = table.getAbsoluteLeft() + (table.width / 2);
        let y1 = table.getAbsoluteTop() - (table.height / 2);
        let y2 = table.getAbsoluteTop() + (table.height / 2);
        if (border.isHorizontal()) {
            y1 = border.y1;
            y2 = border.y2;
        } else {
            x1 = border.x1;
            x2 = border.x2;
        }

        this.callSuper(
            'initialize',
            [x1, y1, x2, y2],
            {
                border,
                ...defaultPositionMarkerAttributes
            }
        );
    },

    isHorizontal() {
        return this.y1 === this.y2;
    },

    setLeft(left) {
        this.left = left;
        if (this.canvas) {
            this.setCoords();
            this.canvas.requestRenderAll();
        }
    },
    setTop(top) {
        this.top = top;
        if (this.canvas) {
            this.setCoords();
            this.canvas.requestRenderAll();
        }
    }
});

module.exports = PositionMarker;
