const Stroke = require('../../Shape/Stroke/Stroke');
const StrokeFill = require('../../Shape/Stroke/StrokeFill');

module.exports = Base => class extends Base {
    static fromFabric(fabricObject, borderJSON = {}) {
        const originalBorder = this.fromJSON(borderJSON);
        const border = new this(
            fabricObject.name,
            Stroke.fromFabric(fabricObject),
            fabricObject.table,
            fabricObject.side,
            fabricObject.row,
            fabricObject.column,
            {
                id: fabricObject.id
            },
            fabricObject.styleId
        );

        border.copyStyles(originalBorder);
        return border;
    }

    toFabric() {
        const stroke = Stroke.fromJSON(this.getRenderStroke());
        stroke.canvasItem = this;
        return {
            ...super.toFabric(),
            x1: this.x1,
            x2: this.x2,
            y1: this.y1,
            y2: this.y2,
            left: this.x1,
            top: this.y1,
            ...stroke.toFabric(),
            ...(stroke.fill ?
                { strokeFill: StrokeFill.updateValueOfColorDescriptors(stroke.fill, this) } :
                {}),
            side: this.side,
            row: this.row,
            column: this.column,
            styleId: this.styleId
        };
    }

    get x1() {
        return this.table.getColumnStartX(this.column) - (this.isVertical() ?
            this.getRenderStroke().width / 2 :
            0
        );
    }

    get x2() {
        if (this.isVertical()) {
            return this.table.getColumnStartX(this.column) - (this.getRenderStroke().width / 2);
        }
        return this.table.getColumnEndX(this.column);
    }

    get y1() {
        return this.table.getRowStartY(this.row) - (this.isHorizontal() ?
            this.getRenderStroke().width / 2 :
            0
        );
    }

    get y2() {
        if (this.isHorizontal()) {
            return this.table.getRowStartY(this.row) - (this.getRenderStroke().width / 2);
        }
        return this.table.getRowEndY(this.row);
    }
};
