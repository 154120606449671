const { Map } = require('immutable');

const DEFAULT_FILL = {
    fill: {
        type: 'color',
        color: {
            type: 'custom',
            value: 'rgba(255, 255, 255, 1)'
        }
    }
};
const CanvasStateSelectors = require('../../Canvas/CanvasStateSelectors');

const getAssignedFillOrEmpty = item => (item.getIn(['assignedStyles', 'shape', 'fill']) ?
    item.getIn(['assignedStyles', 'shape', 'fill']) :
    Map());

exports.computeSelectionFill = canvasStateItems => {
    const fills = canvasStateItems
        .map(item => {
            const fillToBeIgnored = [
                'table',
                'group',
                'border'
            ].includes(CanvasStateSelectors.getCanvasItemType(item));

            const isCellContent = item.get('isCellContent') === true;
            if (!isCellContent && !fillToBeIgnored) {
                return item.getIn(['fill']) ? getAssignedFillOrEmpty(item).merge(item.getIn(['fill'])) :
                    getAssignedFillOrEmpty(item);
            }
            return undefined;
        })
        .toSet()
        .filter(Boolean);
    return fills.size === 1 ?
        fills.first() :
        DEFAULT_FILL.fill;
};
