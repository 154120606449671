import { fromJS } from 'immutable';
import { isNil } from 'lodash';
import { addOpacityToRGBA } from '../../../utilities/stringUtility';

import defaultDoActionOnFillColor from '../../utilities/doActionOnFillColor';
import * as ColorValueDescriptor from '../Style/colorValueDescriptor';

const updateValueOfColorDescriptors = (fill, shape) => doActionOnFillColor(
    fill,
    ColorValueDescriptor.updateValueOfDescriptor,
    shape
);

const normalizeAlpha = fill => doActionOnFillColor(fill, ColorValueDescriptor.normalizeAlpha);

const updateColorPresets = (fill, { presets, scheme } = {}) => doActionOnFillColor(
    fill,
    ColorValueDescriptor.updateColorPresets.bind(ColorValueDescriptor),
    { presets, scheme }
);

const doActionOnFillColor = (fill, action, ...args) => defaultDoActionOnFillColor(fill, 'type', action, ...args);

const createSolidFill = colorValueDescriptor => fromJS({
    type: 'color',
    color: colorValueDescriptor
});

const getFirstColorValueDescriptor = fill => {
    let firstColorValueDescriptor = null;

    doActionOnFillColor(fill, colorValueDescriptor => {
        if (!firstColorValueDescriptor) {
            firstColorValueDescriptor = colorValueDescriptor;
        }
    });
    return firstColorValueDescriptor;
};

const extractRGBAFromFirstDecsriptor = (fill, colorPalette) => {
    const descriptor = getFirstColorValueDescriptor(fill);
    if (descriptor) {
        return ColorValueDescriptor.extractRBGAFromDescriptor(descriptor, colorPalette);
    }
    return undefined;
};

const getComputedFill = (parent, fill) => {
    let updatedFill = fill;
    updatedFill = forceStrokeFill(updatedFill);
    updatedFill = forceOpacity(updatedFill, !isNil(parent.get('opacity')) ? parent.get('opacity') : 1);
    return updatedFill;
};

const forceStrokeFill = fill => {
    switch (fill.get('type')) {
        case 'gradient':
            return fill;
        default:
            return fill.remove('gradient');
    }
};

const forceOpacity = (fill, opacity) => {
    if (fill?.get('color') && !isNil(opacity)) {
        return fill.setIn(['color', 'value'], addOpacityToRGBA(fill.getIn(['color', 'value']), opacity));
    }

    return fill;
};

const fillEmptySerialized = fromJS({ gradient: { stops: [] } });

export {
    getComputedFill,
    forceOpacity,
    updateColorPresets,
    createSolidFill,
    updateValueOfColorDescriptors,
    getFirstColorValueDescriptor,
    extractRGBAFromFirstDecsriptor,
    normalizeAlpha,
    fillEmptySerialized
};
