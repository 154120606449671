const { fabric } = require('fabric');
const { isEqual } = require('lodash');

const getValidSelection = require('../../utilities/shape/SelectionHeuristic');
const CanvasState = require('../../Canvas/CanvasState');
const TreeNode = require('../DecksignFabricShapeType/Groups/TreeNode');

module.exports = {
    implementation: 'new',

    __onMouseDown(e) {
        this._cacheTransformEventData(e);
        this._handleEvent(e, 'down:before');
        const target = this._target;

        if (this.isGroup(target) && target.selectable && !this.isAddingGroupToMultiSelection(e)) {
            this.onGroupMouseDown(e, target);
        } else if (this.groupTree && this._isSelectionKeyPressed(e) && target.selectable) {
            this.off('mouse:up', this.onMouseUpTextEditingEnter);
            const selectedIds = getValidSelection(
                this.groupTree,
                this.currentSelection,
                target.id,
                this._isSelectionKeyPressed(e) // Multiselection
            );

            if (this.getActiveObject().isType('activeSelection')) {
                this._discardActiveObject();
            }

            const selection = this.createSelection(selectedIds);

            this.setObjectCachingOnParents(selectedIds[0], false);

            this.currentSelection = selectedIds;

            this.updateGroupSelectionAndRerender(e, selection);

            this.on('mouse:up', this.onGroupMouseUp);

            this._handleEvent(e, 'down');
        } else {
            this.callSuper('__onMouseDown', e);

            if (target === null) {
                return;
            }
            if (!this.isGroupChild(target) && !target.isType('modificationHandler')) {
                delete this.groupTree;
                this.currentSelection = [];
            }

            if (target && target.selectable) {
                this.updateCanvasStateSelection(this.getActiveObjects().map(obj => obj.id));
                this.on('mouse:up', this.onMouseUp);
            }
        }
    },

    onGroupMouseDown(e, target) {
        const deepestSubTargetId = this.getDeepestSubTargetId(target);
        // When first clicked or when clicked on different group
        if (!this.groupTree || this.groupTree.getShapeId() !== target.id) {
            this.groupTree = TreeNode.generateTree(target);
            this.currentSelection = [this.groupTree.getShapeId()];
            this.updateGroupSelectionAndRerender(e, target);
        } else if (deepestSubTargetId === target.id) { // When clicking on root
            this.currentSelection = [this.groupTree.getShapeId()];
            this.updateGroupSelectionAndRerender(e, target);
        } else {
            if (this.getActiveObject() && this.getActiveObject().editingText) {
                this.getActiveObject()._mouseDownHandler({
                    e
                });
                return;
            }

            const selectedIds = getValidSelection(
                this.groupTree,
                this.currentSelection,
                deepestSubTargetId,
                this._isSelectionKeyPressed(e) // Multiselection
            );

            if (!isEqual(this.currentSelection, selectedIds)) {
                this.off('mouse:up', this.onGroupMouseUpTextEditingEnter);
            }

            const selection = this.createSelection(selectedIds);

            this.setObjectCachingOnParents(selectedIds[0], false);

            this.currentSelection = selectedIds;

            this.updateGroupSelectionAndRerender(e, selection);

            if (selection && selection.renderModificationHandlers instanceof Function) {
                selection.renderModificationHandlers();
            }

            this.on('mouse:up', this.onGroupMouseUp);

            this._handleEvent(e, 'down');
        }
    },

    updateGroupSelectionAndRerender(e, selection) {
        this.updateCanvasStateSelection(this.currentSelection);
        this.setActiveObject(selection);
        this._setupCurrentTransform(e, selection, true);
        this.requestRenderAll();
    },

    isGroupChild(target) {
        if (target) {
            if (target.isType('activeSelection')) {
                return target.getObjects().some(obj => TreeNode.findNode(this.groupTree, obj.id));
            }

            return TreeNode.findNode(this.groupTree, target.id);
        }
        return false;
    },

    isAddingGroupToMultiSelection(e) {
        return this.getActiveObject() && this._isSelectionKeyPressed(e);
    },

    // TODO: Move back to DecksignFabricCanvas when implementation is done
    updateCanvasStateSelection(selection) {
        this.canvasState = CanvasState.update(
            this.canvasState,
            {
                type: 'UPDATE_SELECTION',
                selection,
                groupTree: this.groupTree
            }
        );
        this.fireCanvasStateUpdate();
    },

    // TODO: Move back to DecksignFabricCanvas when implementation is done
    setActiveObject(target, e) {
        if (target && target.isType('cursorZone')) {
            return undefined;
        }

        // Need to check if already selected to not fire deselect event
        if (this.getActiveObject() && this.getActiveObject().id === this.getDeepestSubTargetId(target)) {
            return undefined;
        }

        // When are doing a drag select
        if (target && this._groupSelector) {
            this.updateCanvasStateSelection(target.isType('activeSelection') ?
                target._objects.map(obj => obj.id) :
                [target.id]);
        }

        this.remove(this.selectionHighlight);
        return this.callSuper('setActiveObject', target, e);
    },

    setObjectCachingOnParents(id, bool) {
        const parentNodes = TreeNode.getAllParents(this.groupTree, id);

        parentNodes.forEach(parentNode => {
            parentNode.shape.objectCaching = bool;
        });
    },

    // TODO: Move back to DecksignFabricCanvas when implementation is done
    removeActiveSelection() {
        if (this.getActiveObject() && this.getActiveObject().isType('activeSelection')) {
            this.discardActiveObject();
        }
    },

    onMouseUp(e) {
        if (this.canEditText(e.target) && !this.getActiveObject().editingText) {
            this.off('mouse:up', this.onMouseUpTextEditingEnter); // Remove existing event
            this.on('mouse:up', this.onMouseUpTextEditingEnter);
        }

        this.off('mouse:up', this.onMouseUp);
    },

    // TODO: Remove when implementation is done
    // eslint-disable-next-line no-unused-vars
    onMouseDown(e) {

    },

    // TODO: Remove when implementation is done
    // eslint-disable-next-line no-unused-vars
    mouseDownBefore(e) {

    },

    // TODO: Remove when implementation is done
    onMouseMove() {

    },

    onGroupMouseUp(e) {
        if (e.target && !e.target.isType('activeSelection')) {
            const parentNode = TreeNode.getParent(this.groupTree, e.target.id);

            this.setObjectCachingOnParents(e.target.id, true);

            if (parentNode) parentNode.shape.addWithUpdate(); // Recalculate group bounds

            // Add handler so when we reclick it enters text editing properly
            if (this.canEditText(e.target) && !this.getActiveObject().editingText) {
                this.off('mouse:up', this.onMouseUpTextEditingEnter); // Remove existing event
                this.on('mouse:up', this.onMouseUpTextEditingEnter);
            }
        } else if (e.target.isType('activeSelection') && e.target.group) {
            const parentNode = TreeNode.findNode(this.groupTree, e.target.group.id);
            parentNode.shape.addWithUpdate();
        }

        this.off('mouse:up', this.onGroupMouseUp);
    },

    onMouseUpTextEditingEnter(e) {
        this.handleTextEditingEnterEvent(e);
        this._setCursorFromEvent(e, e.target);
        this.off('mouse:up', this.onMouseUpTextEditingEnter);
    },

    isGroup(target) {
        return target && target.type.toLowerCase() === 'group';
    },

    getDeepestSubTargetId(target) {
        if (!this.targets.length) {
            return target?.id;
        }
        return this.targets?.[0]?.id;
    },

    createSelection(selections) {
        if (selections.length > 1) {
            const parentNode = TreeNode.getParent(this.groupTree, selections[0]);
            const activeSelection = new fabric.ActiveSelection(
                [],
                {
                    originX: 'center',
                    originY: 'center',
                    canvas: this
                }
            );
            selections.forEach(selection => {
                const selectedShape = this.getObjectFromId(selection);
                if (parentNode) {
                    if (this.isGroupChild(selectedShape)) {
                        parentNode.shape.removeWithUpdate(selectedShape);
                    } else {
                        this.remove(selectedShape);
                    }
                }
                activeSelection.addWithUpdate(selectedShape);
            });
            if (parentNode) {
                parentNode.shape.addWithUpdate(activeSelection);
            }
            this.setActiveObject(activeSelection);
            return activeSelection;
        }

        return this.getObjectFromId(selections[0]);
    },

    // TODO: Split to have group specific logic separated from Canvas
    handleSelection(canvasState) {
        const selections = canvasState.get('selection') && canvasState.get('selection').toJS();

        this.removeActiveSelection();

        if (!this.groupTree || !TreeNode.findNode(this.groupTree, selections[0])) {
            this.groupTree = TreeNode.generateTreeFromChildren(this.getObjectFromId(selections[0]));
        }

        if (this.groupTree) {
            this.regenerateGroupTree();
            this.currentSelection = selections;
        } else {
            this.currentSelection = [];
        }

        if (selections.length) {
            this.off('selection:created', this.makeSelection)
                .off('selection:updated', this.makeSelection);

            this.setObjectCachingOnParents(selections[0], false);

            const selection = this.createSelection(selections);

            this.setActiveObject(selection);

            if (selection.renderModificationHandlers instanceof Function) {
                selection.renderModificationHandlers();
            }

            this.on('selection:created', this.makeSelection)
                .on('selection:updated', this.makeSelection);
            this.renderContextualSelection();

            if (this.textSelection.editing === true &&
                (this.canEditText(this.getActiveObject()) || this.getActiveObject().isType('table'))) {
                this.getActiveObject().renderTextSelection(this.textSelection);
                this.removeSelectionOverlay();
            }
        } else {
            delete this.groupTree;
            this.currentSelection = [];
            this.discardActiveObject();
        }
    },

    regenerateGroupTree() {
        const groupId = this.groupTree.shape.id;
        const group = this.getObjects().find(obj => obj.id === groupId);
        if (group) {
            this.groupTree = TreeNode.generateTree(this.getObjects().find(obj => obj.id === groupId));
        }
    },

    getObjectFromId(id) {
        if (!id) return null;
        if (this.groupTree) {
            const foundNode = TreeNode.findNode(this.groupTree, id);

            if (foundNode) {
                return foundNode.shape;
            }
        }

        return this.getObjectFromIdInObjects(this.getObjects(), id);
    },

    getObjectFromIdInObjects(objects, id) {
        if (!objects) return null;

        let foundObject = objects.find(obj => obj.id === id);

        if (foundObject) return foundObject;

        // eslint-disable-next-line no-restricted-syntax
        for (const obj of objects) {
            foundObject = this.getObjectFromIdInObjects(obj._objects, id);
            if (foundObject) {
                return foundObject;
            }
        }

        return null;
    },

    handleSelectability() {
        this.getObjects()
            .filter(object => {
                switch (this.getEditMode()) {
                    case 'Page':
                        if (object.isBackground) {
                            object.hoverCursor = 'default';
                        }
                        return object.inLayout && !object.isPlaceholder();
                    case 'Layout':
                        return !object.isDisplayedInLayoutEditMode();
                    case 'Deck':
                        return true;
                    default:
                        return true;
                }
            })
            .forEach(object => {
                object.selectable = false;
                object.hoverCursor = (object.inLayout && this.getEditMode() === 'Page') || object.isBackground ? 'default' : 'auto';
            });
        this.getObjects()
            .filter(object => !object.inLayout).forEach(object => {
                this.updateHoverCursors(object);
            });
    },

    updateHoverCursors(object) {
        if (object.isType('group')) {
            object._objects.forEach(obj => {
                this.updateHoverCursors(obj);
            });
        }
        if (!object.selectable && object.type !== 'cursorZone') {
            object.hoverCursor = 'default';
        } else if (object.type !== 'cursorZone') {
            object.hoverCursor = 'move';
        }
    },

    getSelectionForHighlight() {
        const target = this._target;
        if (this.groupTree) {
            const selectedIds = getValidSelection(
                this.groupTree,
                this.currentSelection,
                this.getDeepestSubTargetId(target),
                false
            );

            return this.createSelection(selectedIds);
        }

        return target;
    },

    // TODO: Move back to DecksignFabricCanvas when implementation is done
    _transformObject(e) {
        // Remove event so we don't enter text edit at end of drag
        this.off('mouse:up', this.onMouseUpTextEditingEnter);
        if (this.getActiveObject()?.editingText === true) {
            return;
        }

        this.callSuper('_transformObject', e);
    },

    // TODO: Remove these methods if not used

    removeTempActiveSelection() {

    }
};
