import { combineReducers } from 'redux';

import * as types from '#Constants/ActionTypes';

const imageSource = (state = '', action) => {
    switch (action.type) {
        case types.BUILD_HEADER_CHANGE_IMAGE_SOURCE:
            return action.src;
        case types.RESET_IMAGE_IMPORT_FORM:
            return '';
        default:
            return state;
    }
};

const submitIsEnabled = (state = false, action) => {
    switch (action.type) {
        case types.BUILD_HEADER_CHANGE_IMAGE_SUBMIT_STATUS:
            return action.status;
        case types.RESET_IMAGE_IMPORT_FORM:
            return false;
        default:
            return state;
    }
};

const imagePreviewSize = (state = { width: 0, height: 0 }, action) => {
    switch (action.type) {
        case types.BUILD_HEADER_CHANGE_PREVIEW_IMAGE_SIZE:
            return action.size;
        case types.RESET_IMAGE_IMPORT_FORM:
            return {
                width: 0,
                height: 0
            };
        default:
            return state;
    }
};

export default combineReducers({
    imageSource,
    submitIsEnabled,
    imagePreviewSize
});
