const {
    fromJS,
    List,
    Set,
    Map
} = require('immutable');
const get = require('lodash/get');
const {
    DEFAULT_PARAGRAPHSTYLE,
    DEFAULT_RUNSTYLE
} = require('../Shape/AbstractShape/config/defaultStyles');
const { loadTextBodyDefaults } = require('../Shape/AbstractShape/mixins/styleProvider');

exports.getSelectionBoundingBox = selectedShapes => {
    const leftEdge = selectedShapes.reduce((leftmost, shape) => {
        const shapeLeftEdge = shape.x - (shape.scaledWidth / 2);
        if (shapeLeftEdge < leftmost) {
            return shapeLeftEdge;
        }
        return leftmost;
    }, Infinity);
    const rightEdge = selectedShapes.reduce((rightmost, shape) => {
        const shapeRightEdge = shape.x + (shape.scaledWidth / 2);
        if (shapeRightEdge > rightmost) {
            return shapeRightEdge;
        }
        return rightmost;
    }, -Infinity);
    const topEdge = selectedShapes.reduce((topmost, shape) => {
        const shapeTopEdge = shape.y - (shape.scaledHeight / 2);
        if (shapeTopEdge < topmost) {
            return shapeTopEdge;
        }
        return topmost;
    }, Infinity);
    const bottomEdge = selectedShapes.reduce((bottommost, shape) => {
        const shapeBottomEdge = shape.y + (shape.scaledHeight / 2);
        if (shapeBottomEdge > bottommost) {
            return shapeBottomEdge;
        }
        return bottommost;
    }, -Infinity);
    const boundingBox = {
        x: (leftEdge + rightEdge) / 2,
        y: (topEdge + bottomEdge) / 2,
        width: rightEdge - leftEdge,
        height: bottomEdge - topEdge
    };
    return boundingBox;
};

exports.getDefaultStyleFromTextBody = (textBody, textGroup, defaults) => {
    const styleList = textBody.get(`${textGroup}Styles`) || new List();
    return textBody.get(`DEFAULT_${textGroup.toUpperCase()}STYLE`) ||
        styleList.find(style => style.get('isDefault') === true) ||
        fromJS(defaults);
};

exports.getStylesFromTextBody = (textBody, textGroup, defaults, textSelection) => {
    let shouldGetDefault = false;
    const styles = textBody.get(`${textGroup}Styles`) || new List();
    let paragraphs = textBody.get(`${textGroup}s`) || new List();

    if (textSelection?.editing === true) {
        if (textGroup === 'run') {
            if (textSelection.start === textSelection.end) {
                paragraphs = paragraphs.filter(paragraph => (textSelection.start > paragraph.get('startIndex') &&
                    textSelection.end - 1 <= paragraph.get('endIndex')));
            } else {
                paragraphs = paragraphs.filter(paragraph => (textSelection.start >= paragraph.get('startIndex') &&
                    textSelection.end - 1 <= paragraph.get('endIndex')));
            }
        } else {
            paragraphs = paragraphs
                .filter(paragraph => textSelection.start <= paragraph.get('endIndex') + 1 &&
                    paragraph.get('startIndex') <= textSelection.end);
        }
    }

    if (paragraphs.size === 0) {
        shouldGetDefault = true;
    }

    if (paragraphs.size === 1 && paragraphs.first().get('endIndex') === -1) {
        return new List(
            [exports.getDefaultStyleFromTextBody(textBody, textGroup, defaults)]
        );
    }

    if (textSelection && textSelection?.start === textSelection?.end) {
        let previousStyle = new Map();

        if (paragraphs.size > 1) {
            paragraphs = new List([paragraphs.first()]);
            previousStyle = styles.get(paragraphs.first().get('style'));
        }

        const currentStyle = styles.find(style => style.getIn(['cursorStyle', 'textSelection', 'start']) === textSelection?.start &&
            style.getIn(['cursorStyle', 'textSelection', 'end']) === textSelection?.end);

        const cursorStyle = currentStyle?.get('cursorStyle');

        if (cursorStyle) {
            return new List().push(previousStyle.mergeDeep(cursorStyle));
        }
    }

    const usedStyles = paragraphs.reduce((usedAccStyles, paragraph) => {
        const styleIndex = paragraph.get('style');

        if (styleIndex !== null && !Number.isNaN(styleIndex) && styles.get(styleIndex)) {
            const defaultIndex = styles.findIndex(style => style.get('isDefault'));
            if (defaultIndex >= 0) {
                return usedAccStyles
                    .add(
                        styles
                            .get(defaultIndex)
                            .mergeDeep(styles.get(styleIndex))
                    );
            }
            return usedAccStyles.add(styles.get(styleIndex));
        }
        shouldGetDefault = true;
        return usedAccStyles;
    }, new Set());
    return new List(shouldGetDefault ?
        [exports.getDefaultStyleFromTextBody(textBody, textGroup, defaults)] :
        []).push(...usedStyles);
};

exports.getParagraphsStylesFromTextBody = (textBody, textSelection) => exports.getStylesFromTextBody(
    textBody,
    'paragraph',
    loadTextBodyDefaults(textBody.get('parentType')).DEFAULT_PARAGRAPHSTYLE,
    textSelection
);

exports.getRunsStylesFromTextBody = (textBody, textSelection) => exports.getStylesFromTextBody(
    textBody,
    'run',
    loadTextBodyDefaults(textBody.get('parentType')).DEFAULT_RUNSTYLE,
    textSelection
);

exports.getStylesFromTextBodies = (textBodies, textGroup, defaults) => {
    if (textBodies.size === 1 && (!textBodies.first().get(`${textGroup}s`) || textBodies.first().get(`${textGroup}s`).size === 0)) {
        const defaultStyle = exports
            .getDefaultStyleFromTextBody(textBodies.first(), textGroup, defaults);
        return new List([defaultStyle]);
    }
    if (textBodies instanceof List) {
        return textBodies
            .reduce((runs, textBody) => (
                runs.push(...exports.getStylesFromTextBody(textBody, textGroup, defaults))
            ), new List());
    } if (textBodies.constructor.name === 'Map') {
        const newList = new List();
        return newList.set(0, textBodies);
    }
    return new List();
};

exports.getParagraphsStylesFromTextBodies = textBodies => exports.getStylesFromTextBodies(textBodies, 'paragraph', DEFAULT_PARAGRAPHSTYLE);

exports.getRunsStylesFromTextBodies = (textBodies, isCell = false) => exports.getStylesFromTextBodies(textBodies, 'run', isCell ? {
    ...DEFAULT_RUNSTYLE,
    color: {
        type: 'custom',
        value: 'rgba(77, 77, 77, 1)'
    }
} :
    DEFAULT_RUNSTYLE);

exports.same = (parents = new List(), key = '', defaultValue = '', { skipUndefined = true, eachInstanceDefault } = {}) => {
    let values = parents.map(parent => parent.getIn(key.split('.'), eachInstanceDefault)).toSet();
    if (skipUndefined) {
        values = values.filter(value => value !== undefined);
    }
    return values.size === 1 ?
        values.first() :
        defaultValue;
};

exports.sameWithMultiPath = (parents = new List(), keys = [], defaultValue = '', { skipUndefined = true } = {}) => {
    let values = parents.reduce((set, parent) => {
        let value;
        for (let i = 0, len = keys.length; i < len && value === undefined; i++) {
            value = parent.getIn(keys[i].split('.'));
        }
        return set.add(value);
    }, new Set());
    if (skipUndefined) {
        values = values.filter(value => value !== undefined);
    }
    return values.size === 1 ?
        values.first() || defaultValue :
        defaultValue;
};

exports.sameParagraphStyleInTextBodies = (
    textBodies,
    key,
    defaultValue,
    { skipUndefined = true, textSelection } = {}
) => {
    let values = textBodies.map(textBody => {
        const paragraphStyles = exports.getParagraphsStylesFromTextBody(textBody, textSelection);
        const eachInstanceDefault = textBody
            .getIn(['assignedStyles', 'paragraph', ...key.split('.')]) ||
            get(
                loadTextBodyDefaults(textBody.get('parentType')).DEFAULT_PARAGRAPHSTYLE,
                key
            );

        return exports.same(
            paragraphStyles,
            key,
            undefined,
            { skipUndefined, eachInstanceDefault }
        );
    }).toSet();

    if (skipUndefined) {
        values = values.filter(value => value !== undefined);
    }

    return values.size === 1 ?
        values.first() || defaultValue :
        defaultValue;
};

exports.sameRunStyleInTextBodies = (
    textBodies,
    key,
    defaultValue,
    { skipUndefined = true, textSelection } = {}
) => {
    let values = textBodies.map(textBody => {
        const runStyles = exports.getRunsStylesFromTextBody(textBody, textSelection);
        const eachInstanceDefault = textBody
            .getIn(['assignedStyles', 'run', ...key.split('.')]) ||
                get(
                    loadTextBodyDefaults(textBody.get('parentType')).DEFAULT_RUNSTYLE,
                    key
                );
        return exports.same(
            runStyles,
            key,
            undefined,
            { skipUndefined, eachInstanceDefault }
        );
    }).toSet();
    if (skipUndefined) {
        values = values.filter(value => value !== undefined);
    }
    return values.size === 1 ?
        values.first() || defaultValue :
        defaultValue;
};
