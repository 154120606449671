const isNil = require('lodash/isNil');

const Style = require('./Style');

module.exports = class extends Style {
    static fromJSON(json) {
        return new this(json);
    }

    static get properties() {
        return [
            ...super.properties,
            'characterSpacing',
            'color',
            'font',
            'hyperlink',
            'linethrough',
            'opacity',
            'overline',
            'superscript',
            'subscript',
            'textTransform',
            'underline'
        ];
    }

    static get atomicProperties() {
        return [
            'color'
        ];
    }

    constructor({
        characterSpacing,
        color,
        font,
        hyperlink,
        linethrough,
        opacity,
        overline,
        superscript,
        subscript,
        textTransform,
        underline,
        ...baseStyle
    }) {
        super(baseStyle);
        this.characterSpacing = characterSpacing;
        this.color = color;
        this.font = font;
        this.hyperlink = hyperlink;
        this.linethrough = linethrough;
        this.opacity = opacity;
        this.overline = overline;
        this.superscript = superscript;
        this.subscript = subscript;
        this.textTransform = textTransform;
        this.underline = underline;
    }

    toJSON() {
        return {
            ...super.toJSON(),
            characterSpacing: this.characterSpacing,
            color: this.color,
            font: this.font,
            hyperlink: this.hyperlink,
            linethrough: this.linethrough,
            opacity: this.opacity,
            overline: this.overline,
            superscript: this.superscript,
            subscript: this.subscript,
            textTransform: this.textTransform,
            underline: this.underline
        };
    }

    get characterSpacing() {
        return this._characterSpacing;
    }

    set characterSpacing(characterSpacing) {
        if (!isNil(characterSpacing)) {
            this._characterSpacing = characterSpacing;
        }
    }

    get color() {
        return this._color;
    }

    set color(color) {
        if (!isNil(color)) {
            this._color = color;
        }
    }

    get font() {
        return this._font;
    }

    set font(font) {
        if (!isNil(font)) {
            this._font = font;
        }
    }

    get hyperlink() {
        return this._hyperlink;
    }

    set hyperlink(hyperlink) {
        if (!isNil(hyperlink)) {
            this._hyperlink = hyperlink;
        }
    }

    get linethrough() {
        return this._linethrough;
    }

    set linethrough(linethrough) {
        if (!isNil(linethrough)) {
            this._linethrough = linethrough;
        }
    }

    get opacity() {
        return this._opacity;
    }

    set opacity(opacity) {
        if (!isNil(opacity)) {
            this._opacity = opacity;
        }
    }

    get overline() {
        return this._overline;
    }

    set overline(overline) {
        if (!isNil(overline)) {
            this._overline = overline;
        }
    }

    get superscript() {
        return this._superscript;
    }

    set superscript(superscript) {
        if (!isNil(superscript)) {
            this._superscript = superscript;
        }
    }

    get subscript() {
        return this._subscript;
    }

    set subscript(subscript) {
        if (!isNil(subscript)) {
            this._subscript = subscript;
        }
    }

    get textTransform() {
        return this._textTransform;
    }

    set textTransform(textTransform) {
        if (!isNil(textTransform)) {
            this._textTransform = textTransform;
        }
    }

    get underline() {
        return this._underline;
    }

    set underline(underline) {
        if (!isNil(underline)) {
            this._underline = underline;
        }
    }
};
