import { combineReducers } from 'redux';

import { resetableReducer, resetOnLocationChange } from '#Utilities/reducers';
import * as types from '#Constants/ActionTypes';

import { selectedStoryboard, initialStoryboard, storyboards } from './storyboards';
import styleSelection from './styleSelection';
import {
    selectedObjective,
    initialObjective,
    objectives,
    filteredObjectives,
    objectivesCategories
} from './objectives/objectives';
import {
    userSelectedTopics,
    selectedPage,
    userUnselectedTopics,
    topics,
    browseTopics,
    isReordering
} from './topics/topics';

// TODO: remove once save is done
const shouldSave = (
    state = false,
    action
) => {
    switch (action.type) {
        case types.HIDE_SAVE_BUTTON:
            return false;
        case types.SHOW_SAVE_BUTTON:
            return true;
        default:
            return state;
    }
};

const confirmTopicDeletion = (state = {
    modalIsOpen: false,
    triggeringTopics: [],
    browseIsOpen: false
}, action) => {
    switch (action.type) {
        case types.CHANGE_PAGE_DELETION_MODAL:
            return {
                ...state,
                modalIsOpen: action.modalIsOpen,
                browseIsOpen: action.browseIsOpen,
                triggeringTopics: action.triggeringTopics
            };
        default:
            return state;
    }
};

const currentItemDescription = (state = {
    itemTitle: 'Storyboard/Topic',
    description: ''
}, action) => {
    switch (action.type) {
        case types.SET_CURRENT_ITEM_DESCRIPTION:
            return {
                ...state,
                itemTitle: action.itemTitle,
                description: action.description
            };
        default:
            return state;
    }
};

const combinedReducers = combineReducers({
    filteredObjectives,
    objectives,
    objectivesCategories,
    selectedObjective,
    initialObjective,
    storyboards,
    selectedStoryboard,
    initialStoryboard,
    topics,
    browseTopics,
    isReordering,
    selectedPage,
    userSelectedTopics,
    userUnselectedTopics,
    shouldSave,
    confirmTopicDeletion,
    currentItemDescription,
    styleSelection
});

export default resetOnLocationChange(resetableReducer(types.FETCH_DECK, types.CREATE_DECK)(combinedReducers), 'plan');
