const { DEFAULT_TOTALROW_STYLE } = require('./config/defaultCellStyles.config.js');
const Cell = require('./Cell.js');

class TotalRowCell extends Cell {
    constructor(name, x, y, width, height, attributes = {}) {
        super(name, x, y, width, height);
        this.isTotalRowCell = true;
        Object.assign(this, DEFAULT_TOTALROW_STYLE, attributes);
    }
}

module.exports = TotalRowCell;
