// import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

const portalRoot = document.getElementById('PortalRoot');

const Portal = ({ children }) => (
    ReactDOM.createPortal(
        children,
        portalRoot
    )
);

Portal.propTypes = {
    children: PropTypes.any.isRequired
};

export default Portal;
