import {
    isNil
} from 'lodash';
import { Map } from 'immutable';
import listUpdateSchema from '../Config/listUpdate.jsonschema';
import { bulletStrategies } from '../Utils/bulletStrategies';
import {
    getParagraph,
    getParagraphsCount
} from './Paragraphs';
import {
    getParagraphStyle,
    setDefaultParagraphStyle,
    getDefaultParagraphStyle,
    setParagraphStyleProperties,
    getItemsForTextSelection,
    getFirstRunInParagraph
} from './ParagraphStyles';
import {
    getDefaultRunStyle
} from '../Runs/RunStyles';
// eslint-disable-next-line import/no-cycle
import Textbody from '../TextBody';
import getPropertiesForDestructuring from '../../../utilities/getPropertiesForDestructuring';
import omit from '../../../utilities/omit';

const jsonschema = require('jsonschema');

const getBulletSizeRatioFromItemUpdate = update => {
    const textSize = update.getIn(['font', 'size']);
    const bulletSize = update.getIn(['bullet', 'style', 'size']);
    if (!isNil(textSize) && !isNil(bulletSize) && textSize > 0) {
        return bulletSize / textSize;
    }
    return 1;
};

const getIndentSizeRatioFromItemUpdate = update => {
    const textSize = update.getIn(['font', 'size']);
    const indentSize = update.getIn(['paragraph', 'indent'], textSize);
    const bulletType = update.getIn(['bullet', 'type'], 'none');
    if (bulletType === 'none') {
        return 0;
    }
    if (!isNil(textSize) && !isNil(indentSize) && textSize > 0) {
        return indentSize / textSize;
    }
    return 1;
};

const getLeftPaddingRatioFromItemUpdate = update => {
    const textSize = update.getIn(['font', 'size']);
    const leftPaddingSize = update.getIn(['paragraph', 'padding', 'left'], textSize);
    if (!isNil(textSize) && !isNil(leftPaddingSize) && textSize > 0) {
        return leftPaddingSize / textSize;
    }
    return 1;
};

const convertParagraphToListType = (textBody, index, newBulletType, bulletUpdate) => {
    let updatedTextBody = textBody;
    const paragraph = getParagraph(updatedTextBody, index);
    const paragraphStyle = getParagraphStyle(updatedTextBody, paragraph.get('style'), true);
    const oldBulletStyle = paragraphStyle.getIn(['bullet', 'type'], 'none');
    const oldStyleWasList = oldBulletStyle !== 'none';
    const oldItemLevel = paragraphStyle.get('level', 1);
    switch (newBulletType) {
        case 'none': {
            const update = Map({
                level: 1,
                bullet: Map({
                    type: 'none'
                }),
                indent: 0,
                padding: paragraphStyle
                    .get('padding')
                    .merge(
                        Map({
                            left: 0
                        })
                    )
            });
            updatedTextBody = setParagraphStyleProperties(updatedTextBody, update, index, index);
            break;
        }
        case 'ordered':
        case 'unordered': {
            const bulletType = `ordered/${bulletUpdate.getIn(['listUpdate', 'itemType'], 'numeric')}`;
            const levelUpdate = bulletUpdate.getIn(['listUpdate', `level${oldStyleWasList ? oldItemLevel : 1}`], Map());
            const update = Map({
                level: oldStyleWasList ? oldItemLevel : 1,
                bullet: Map({
                    type: newBulletType === 'ordered' ? bulletType : 'unordered'
                }),
                indent: levelUpdate.get('indent', 0),
                padding: Map({
                    left: levelUpdate.get('spacing-left', 0)
                })
            });
            updatedTextBody = setParagraphStyleProperties(updatedTextBody, update, index, index);
            break;
        }
        default:
            break;
    }
    return updatedTextBody;
};

const convertParagraphsToListType = (textBody, update = Map()) => {
    let updatedTextBody = textBody;
    const newBulletType = update.getIn(['listUpdate', 'type']);
    if (update.get('textSelection')) {
        const paragraphs = getItemsForTextSelection(updatedTextBody, update.get('textSelection'));
        paragraphs.forEach(paragraph => {
            updatedTextBody = convertParagraphToListType(updatedTextBody, paragraph.get('index'), newBulletType, update);
        });
    } else {
        const numOfParagraphs = getParagraphsCount(updatedTextBody);
        for (let i = 0, len = numOfParagraphs; i < len; i++) {
            updatedTextBody = convertParagraphToListType(updatedTextBody, i, newBulletType, update);
        }
    }
    return updatedTextBody;
};

const applyBulletStyleUpdateToDefault = (textBody, { onlyBulletUpdate, update } = {}) => {
    let updatedTextBody = textBody;
    const bulletSizeRatio = onlyBulletUpdate ?
        getBulletSizeRatioFromItemUpdate(update) :
        1;
    const indentSizeRatio = onlyBulletUpdate ?
        getIndentSizeRatioFromItemUpdate(update) :
        1;
    const leftPaddingRatio = getLeftPaddingRatioFromItemUpdate(update);
    const defaultFontSize = getDefaultRunStyle(updatedTextBody).getIn(['font', 'size']);
    const defaultBulletStyle = update
        .getIn(['bullet', 'style'], Map())
        .set('size', defaultFontSize * bulletSizeRatio);
    const newDefault = Map({
        bullet: Map({
            style: defaultBulletStyle,
            text: bulletStrategies(0, update.get('bullet').toJS(), textBody),
            type: update.getIn(['bullet', 'type'])
        }),
        indent: defaultFontSize * indentSizeRatio,
        padding: Map({
            left: defaultFontSize * leftPaddingRatio
        })
    });
    updatedTextBody = setDefaultParagraphStyle(updatedTextBody, newDefault);
    return updatedTextBody;
};

const getItemsForTextSelectionAndLevel = (textBody, textSelection, level) => getItemsForTextSelection(
    textBody,
    textSelection
)
    .filter(paragraph => {
        const paragraphStyle = getParagraphStyle(textBody, paragraph.get('style'), true) ||
                getDefaultParagraphStyle(textBody);
        return paragraphStyle.get('level') === level;
    });

const setBulletToItem = (textBody, item, bullet = Map({ type: 'none' })) => {
    const updatedTextBody = setParagraphStyleProperties(
        textBody,
        Map({
            bullet: Map({
                type: bullet.get('type'),
                text: bulletStrategies(item, bullet.toJS(), textBody),
                style: bullet.get('style')
            })
        }),
        item,
        item
    );
    return updatedTextBody;
};

const applyLeveledUpdate = (textBody, {
    update,
    textSelection,
    level,
    onlyBulletUpdate
}) => {
    let newUpdate = update;
    let updatedTextBody = textBody;

    let bulletSizeRatio;
    let indentSizeRatio;
    let leftPaddingRatio;
    if (onlyBulletUpdate) {
        bulletSizeRatio = getBulletSizeRatioFromItemUpdate(newUpdate);
        indentSizeRatio = getIndentSizeRatioFromItemUpdate(newUpdate);
        leftPaddingRatio = getLeftPaddingRatioFromItemUpdate(newUpdate);
    }
    const levelItems = getItemsForTextSelectionAndLevel(updatedTextBody, textSelection, level);

    levelItems.forEach(paragraph => {
        let newBulletSize = newUpdate.getIn(['bullet', 'style', 'size']);
        let newIndent = newUpdate.getIn(['paragraph', 'indent']);
        let newLeftPadding = newUpdate.getIn(['paragraph', 'padding', 'left']);
        if (onlyBulletUpdate) {
            const firstRunInParagraph = getFirstRunInParagraph(updatedTextBody, paragraph.get('index'), true) || Map();
            const currentFontSize = firstRunInParagraph.getIn(['style', 'font', 'size']) ||
                getDefaultRunStyle(updatedTextBody).getIn(['font', 'size']) ||
                updatedTextBody.getIn(['assignedStyles', 'run', 'font', 'size']);

            newBulletSize = currentFontSize * bulletSizeRatio;
            newIndent = currentFontSize * indentSizeRatio;
            newLeftPadding = currentFontSize * leftPaddingRatio;
        }

        const bulletUpdate = newUpdate
            .get('bullet')
            .set('size', newBulletSize);

        updatedTextBody = setBulletToItem(updatedTextBody, paragraph.get('index'), bulletUpdate);

        if (!onlyBulletUpdate) {
            newUpdate = newUpdate.setIn(['paragraph', 'padding', 'left'], newLeftPadding);
            newUpdate = newUpdate.setIn(['paragraph', 'indent'], newIndent);
            updatedTextBody = Textbody.setStyleProperties(
                updatedTextBody,
                omit(newUpdate, ['bullet']),
                paragraph.get('startIndex'),
                paragraph.get('endIndex')
            );
        } else {
            updatedTextBody = setParagraphStyleProperties(
                updatedTextBody,
                Map({
                    level,
                    bullet: bulletUpdate,
                    indent: newIndent,
                    padding: Map({
                        left: newLeftPadding
                    })
                }),
                paragraph.get('index'),
                paragraph.get('index')
            );
        }
    });
    return updatedTextBody;
};

const applyListUpdate = (textBody, update = Map()) => {
    let updatedTextBody = textBody;
    jsonschema.validate(update.get('listUpdate').toJS(), listUpdateSchema, { throwError: true });
    const {
        onlyBulletUpdate,
        allLevels,
        level1,
        level2,
        level3,
        level4,
        level5
    } = getPropertiesForDestructuring(
        update.get('listUpdate'),
        [
            'onlyBulletUpdate',
            'allLevels',
            'level1',
            'level2',
            'level3',
            'level4',
            'level5'
        ]
    );

    const textSelection = update.get('textSelection');

    // Properly sets up list related properties so applyLeveledUpdate works properly
    updatedTextBody = convertParagraphsToListType(updatedTextBody, update);

    if (allLevels) {
        [1, 2, 3, 4, 5].forEach(level => {
            updatedTextBody = applyLeveledUpdate(updatedTextBody, {
                update: allLevels,
                textSelection,
                level,
                onlyBulletUpdate
            });
        });
        if (!update.get('textSelection')) {
            updatedTextBody = applyBulletStyleUpdateToDefault(updatedTextBody, {
                onlyBulletUpdate,
                update: allLevels
            });
        }
    } else {
        [level1, level2, level3, level4, level5].forEach((leveledUpdate, index) => {
            updatedTextBody = applyLeveledUpdate(updatedTextBody, {
                update: leveledUpdate,
                textSelection,
                level: index + 1,
                onlyBulletUpdate
            });
        });
    }
    return updatedTextBody;
};

export {
    applyListUpdate,
    getBulletSizeRatioFromItemUpdate,
    getIndentSizeRatioFromItemUpdate,
    getLeftPaddingRatioFromItemUpdate,
    convertParagraphsToListType,
    applyLeveledUpdate,
    applyBulletStyleUpdateToDefault
};
