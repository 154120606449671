import { combineReducers } from 'redux';

import * as types from '#Constants/ActionTypes';

const subNavStatus = (state = false, action) => {
    switch (action.type) {
        case types.CHANGE_SUB_NAV_STATUS:
            return action.status;
        default:
            return state;
    }
};

const loading = (state = false, action) => {
    switch (action.type) {
        case types.SET_LOADING:
            return action.loading;
        case types.CREATE_NEW_SHARE_PENDING:
            return true;
        case types.CREATE_NEW_SHARE_DONE:
            return false;
        default:
            return state;
    }
};

const hasUnsavedProperties = (
    state = false,
    action
) => {
    switch (action.type) {
        case types.ADD_SELECTED_TOPICS:
        case types.SET_SELECTED_TOPICS:
        case types.SET_USER_SELECTED_TOPICS:
        case types.REMOVE_SELECTED_TOPICS:
        case types.FLAG_PROPERTIES_UNSAVED:
        case types.ADD_PAGE:
        case types.DUPLICATE_PAGE:
        case types.UPDATE_DECK:
            return true;
        case types.CHANGE_DECK_PROPERTIES:
        case types.FLAG_PROPERTIES_SAVED:
        case types.LOAD_DECK:
            return false;
        default:
            return state;
    }
};

const config = (state = {
    decksAssetsURLBase: 'default',
    decksImagesURLBase: 'default',
    decksThumbnailsURLBase: 'default',
    themesThumbnailsURLBase: 'default',
    origin: 'default',
    path: 'default',
    enableSignup: false,
    enableColorsPresetsSelection: false,
    saveCanvasAsPNG: true
}, action) => {
    switch (action.type) {
        case types.SET_APP_CONFIG:
            return {
                ...state,
                ...action.config
            };
        default:
            return state;
    }
};

const profileModalOpened = (
    state = false,
    action
) => {
    switch (action.type) {
        case types.SET_PROFILE_MODAL_VISIBILITY:
            return action.opened;
        default:
            return state;
    }
};

const profileModalDefaultIndex = (
    state = 0,
    action
) => {
    switch (action.type) {
        case types.SET_PROFILE_MODAL_VISIBILITY:
            return action.defaultIndex || 0;
        default:
            return state;
    }
};

export default combineReducers({
    hasUnsavedProperties,
    loading,
    subNavStatus,
    config,
    profileModalOpened,
    profileModalDefaultIndex
});
