const merge = require('lodash/merge');
const set = require('lodash/set');

const ColorValueDescriptor = require('../../ColorValueDescriptor');
const { calculateRunStyleEndIndex } = require('../../../utilities/html');

const parseHTMLRunsContainer = Symbol('parseHTMLRunsContainer');
const parseHTMLParagraph = Symbol('parseHTMLParagraph');
const parseHTMLListItem = Symbol('parseHTMLListItem');
const parseHTMLList = Symbol('parseHTMLList');
const parseHTMLParagraphStyle = Symbol('parseHTMLParagraphStyle');
const parseHTMLParagraphPadding = Symbol('parseHTMLParagraphPadding');
const parseHTMLParagraphStyleProperty = Symbol('parseHTMLParagraphStyleProperty');
const setBulletStylesFromList = Symbol('setBulletStylesFromList');
const extractRunStyles = Symbol('extractRunStyles');
const extractRunStylesFromSpan = Symbol('extractRunStylesFromSpan');
const parseRunStyleAt = Symbol('parseRunStyleAt');
const parseHTMLRunStyleProperty = Symbol('parseRunStyleAt');
const extractStyleEntriesFromStyleAttribute = Symbol('extractStyleEntriesFromStyleAttribute');
const parseTextDecorationValue = Symbol('parseTextDecorationValue');
const parseFontValue = Symbol('parseFontValue');
const getOrderStrategyFromStyleAttribute = Symbol('getOrderStrategyFromStyleAttribute');
const getUnorderedTextFromStyleNode = Symbol('getUnorderedTextFromStyleNode');
const applyStyleAndEnterNode = Symbol('applyStyleAndEnterNode');
const parseLineBreak = Symbol('parseLineBreak');

module.exports = Base => class extends Base {
    parseHTMLToTextBody(html) {
        try {
            this.paragraphCount = 0;
            const parser = new this.shape.DOMParser();
            const dom = parser.parseFromString(html.replace(/<br>/g, '<br/>'), 'text/xml');
            for (let i = 0, len = dom.childNodes.length; i < len; i++) {
                const node = dom.childNodes[i];
                this[parseHTMLRunsContainer](node);
            }
            this[extractRunStyles](dom.firstChild, 0);
        } catch (parsingError) {
            console.error(parsingError);
        }
    }

    [parseHTMLRunsContainer](node) {
        switch (node.tagName) {
            case 'div':
                for (let i = 0, len = node.childNodes.length; i < len; i++) {
                    const child = node.childNodes[i];
                    this[parseHTMLRunsContainer](child, i);
                }
                break;
            case 'ul':
            case 'ol':
                this[parseHTMLList](node);
                break;
            case 'p':
                this[parseHTMLParagraph](node, this.paragraphCount);
                this.paragraphCount += 1;
                break;
            default:
                break;
        }
    }

    [parseHTMLParagraph](paragraph, index) {
        this.shapeListTypeSetted = this.shapeListTypeSetted || false;

        const text = this.constructor[parseLineBreak](paragraph);
        if (index > 0) {
            this.addParagraph(text);
        } else {
            this.setParagraphText(0, text);
        }
        this.setBulletToItem(index, { type: 'none' });
        this[parseHTMLParagraphStyle](paragraph.getAttribute('style'), index);

        if (!this.shapeListTypeSetted) {
            this.setAllBulletsType({ type: 'none' });
            this.shapeListTypeSetted = true;
        }
    }

    static [parseLineBreak](node) {
        let text = '';
        for (let i = 0, len = node.childNodes.length; i < len; i++) {
            const child = node.childNodes[i];
            switch (child.nodeName) {
                case '#text':
                    text += child.textContent;
                    break;
                case 'br':
                    text += '\n';
                    break;
                default:
                    text += this[parseLineBreak](child);
                    break;
            }
        }
        return text;
    }

    static ensurePadding({
        bottom = 0, top = 0, left = 0, right = 0
    } = {}) {
        return {
            bottom,
            top,
            left,
            right
        };
    }

    getCustomBulletTextFromStyle(style = '', index) {
        const match = (style || '').match(/--ul-li-text:\s?'(.*?)'/);
        if (match && match.length === 2) {
            this.setBulletToItem(index, {
                type: 'unordered',
                text: match[1]
            });
        }
    }

    static getBulletColorFromStyle(style = '') {
        const match = (style || '').match(/--bullet-color:\s?([^;]*)/);
        if (match && match.length === 2) {
            return match[1];
        }

        return undefined;
    }

    static getBulletColorPresetFromStyle(style = '') {
        const match = (style || '').match(/--bullet-color-preset:\s?([^;]*)/);
        if (match && match.length === 2) {
            return match[1];
        }

        return undefined;
    }

    static getBulletFontFromStyle(style = '') {
        const match = (style || '').match(/--bullet-font-family:\s?([^;]*)/);
        if (match && match.length === 2) {
            return match[1];
        }

        return undefined;
    }

    static getBulletSizeFromStyle(style = '') {
        const match = (style || '').match(/--bullet-font-size:\s?([^;]*)/);
        if (match && match.length === 2) {
            return parseFloat(match[1]);
        }

        return undefined;
    }

    static getBulletIndentFromStyle(style = '') {
        const match = (style || '').match(/--bullet-indent:\s?([^;]*)/);
        if (match && match.length === 2) {
            return parseFloat(match[1]);
        }

        return undefined;
    }

    static getBulletLeftPaddingFromStyle(style = '') {
        const match = (style || '').match(/--bullet-left-padding:\s?([^;]*)/);
        if (match && match.length === 2) {
            return parseFloat(match[1]);
        }

        return undefined;
    }

    static getBulletStyleFromStyle(style = '') {
        const bulletColor = this.getBulletColorFromStyle(style);
        const bulletColorPreset = this.getBulletColorPresetFromStyle(style);
        const bulletFont = this.getBulletFontFromStyle(style);
        const bulletSize = this.getBulletSizeFromStyle(style);

        if (bulletColor !== undefined || bulletFont !== undefined || bulletSize !== undefined) {
            const bulletStyle = {};

            if (bulletColor !== undefined) {
                bulletStyle.color = bulletColor;
                bulletStyle.colorPreset = bulletColorPreset;
            }

            if (bulletSize !== undefined) {
                bulletStyle.size = bulletSize;
            }

            if (bulletFont !== undefined) {
                bulletStyle.font = bulletFont;
            }

            return bulletStyle;
        }

        return undefined;
    }

    static getItemCountStartsAtFromStyle(style = '') {
        const match = (style || '').match(/--item-count-starts-at:\s?([^;]*)/);
        if (match && match.length === 2) {
            return parseInt(match[1], 10);
        }

        return undefined;
    }

    getParagraphStyleFromCSS(style) {
        let paragraphStyle = {};
        this.constructor[extractStyleEntriesFromStyleAttribute](style)
            .forEach(({ property, value }) => {
                paragraphStyle = this[parseHTMLParagraphStyleProperty](property, value, paragraphStyle);
            });

        paragraphStyle.padding = this.constructor.ensurePadding(paragraphStyle.padding);
        return paragraphStyle;
    }

    static getBulletPositionsFromStyle(style) {
        const bulletIndent = this.getBulletIndentFromStyle(style);
        const bulletLeftPadding = this.getBulletLeftPaddingFromStyle(style);
        const paragraphStyle = {};

        if (bulletIndent !== undefined || bulletLeftPadding !== undefined) {
            if (bulletIndent !== undefined) {
                paragraphStyle.indent = bulletIndent;
            }

            if (bulletLeftPadding !== undefined) {
                paragraphStyle.padding = {
                    left: bulletLeftPadding
                };
            }
        }
        return paragraphStyle;
    }

    [parseHTMLParagraphStyle](style, index) {
        let paragraphStyle = this.getParagraphStyleFromCSS(style);

        this.getCustomBulletTextFromStyle(style, index);

        const bulletStyle = this.constructor.getBulletStyleFromStyle(style);

        paragraphStyle = merge(paragraphStyle, this.constructor.getBulletPositionsFromStyle(style));

        if (bulletStyle) {
            if (!paragraphStyle.bullet) {
                paragraphStyle.bullet = {};
            }
            if (bulletStyle.color) {
                bulletStyle.color = ColorValueDescriptor.getDescriptorFromValue(
                    bulletStyle.color,
                    this.shape
                );
                bulletStyle.color.preset = bulletStyle.colorPreset;
            }
            paragraphStyle.bullet.style = {
                ...(paragraphStyle.bullet.style || {}),
                ...bulletStyle
            };
        }

        const itemCountStartsAt = this.constructor.getItemCountStartsAtFromStyle(style);
        if (itemCountStartsAt !== undefined) {
            paragraphStyle = set(paragraphStyle, 'bullet.itemCountStartsAt', itemCountStartsAt);
        }

        if (Object.keys(paragraphStyle).length > 0) {
            this.setParagraphStylePropertiesAt(index, paragraphStyle);
        }
    }

    static [extractStyleEntriesFromStyleAttribute](styleString) {
        return (styleString || '').split(';')
            .map(styleLine => styleLine.match(/([.,()#\w\d%-]+)/gi))
            .filter(styleMatch => styleMatch && styleMatch.length > 0)
            .map(([property, ...value]) => ({
                property,
                value
            }));
    }

    [parseHTMLParagraphStyleProperty](property, value, input) {
        const output = { ...input };
        switch (property) {
            case 'text-align':
                [output.align] = value;
                break;
            case 'line-height':
                output.lineSpacing = parseFloat(value[0]);
                break;
            case 'padding':
                output.padding = this.constructor[parseHTMLParagraphPadding](value);
                break;
            case 'padding-bottom':
                output.padding = { ...output.padding || {}, bottom: parseFloat(value[0]) };
                break;
            case 'padding-left':
                output.padding = { ...output.padding || {}, left: parseFloat(value[0]) };
                break;
            case 'padding-right':
                output.padding = { ...output.padding || {}, right: parseFloat(value[0]) };
                break;
            case 'padding-top':
                output.padding = { ...output.padding || {}, top: parseFloat(value[0]) };
                break;
            case 'text-indent':
                output.indent = parseFloat(value[0]);
                break;
            default:
                break;
        }
        return output;
    }

    static [parseHTMLParagraphPadding](values) {
        const [top, right = top, bottom = top, left = right] = values.map(parseFloat);
        return {
            bottom,
            left,
            right,
            top
        };
    }

    [parseHTMLList](list, level = 1) {
        this[setBulletStylesFromList](list);
        for (let i = 0, len = list.childNodes.length; i < len; i++) {
            const listItem = list.childNodes[i];
            if (listItem.nodeName === 'li') {
                this[parseHTMLListItem](listItem, this.paragraphCount, level);
                this[parseHTMLParagraphStyle](listItem.getAttribute('style'), this.paragraphCount);
                this.paragraphCount += 1;
            }
            if (['ul', 'ol'].includes(listItem.nodeName)) {
                this[parseHTMLList](listItem, level + 1);
            }
        }
    }

    [parseHTMLListItem](listItem, index, level) {
        const text = this.constructor[parseLineBreak](listItem);
        if (index > 0) {
            this.addItem(text, level);
        } else {
            this.setItem(index, text, level);
        }
        this.setBulletToItem(index, this.currentBulletStyle);
    }

    [setBulletStylesFromList](list) {
        this.shapeListTypeSetted = this.shapeListTypeSetted || false;
        switch (list.tagName) {
            case 'ul':
                this.currentBulletStyle = {
                    type: 'unordered',
                    text: this.constructor[getUnorderedTextFromStyleNode](list.getElementsByTagName('style')[0])
                };
                break;
            case 'ol':
            default:
                this.currentBulletStyle = {
                    type: `ordered/${this[getOrderStrategyFromStyleAttribute](list.getAttribute('style'))}`
                };
                break;
        }

        if (!this.shapeListTypeSetted) {
            this.setAllBulletsType(this.currentBulletStyle);
            this.shapeListTypeSetted = true;
        }
    }

    static [getUnorderedTextFromStyleNode](styleElement) {
        if (styleElement) {
            const bullet = styleElement.textContent
                .match(/.*ul\sli:before\s?{\n?\t?content:\s?'?([^']*)'?;?\n?}/m)[1];
            return bullet;
        }
        return '•';
    }

    [getOrderStrategyFromStyleAttribute](attribute) {
        const properties = this.constructor[extractStyleEntriesFromStyleAttribute](attribute);
        const listStyleType = properties.find(({ property }) => property === 'list-style-type');
        switch ((listStyleType || { value: [] }).value[0]) {
            case 'upper-roman':
                return 'roman';
            case 'decimal':
            default:
                return 'numeric';
        }
    }

    [extractRunStyles](node, textOffset) {
        if (!node) {
            return null;
        }
        let cursor = textOffset;
        switch (node.nodeName) {
            case 'div':
            case 'ul':
            case 'ol':
                if (node.firstChild) {
                    cursor = this[extractRunStyles](node.firstChild, cursor);
                }
                break;
            case 'li':
            case 'p':
                if (node.firstChild) {
                    cursor = this[extractRunStyles](node.firstChild, cursor);
                }
                cursor += 1;
                break;
            case 'span':
                cursor = this[extractRunStylesFromSpan](node, cursor);
                break;
            case 'br':
                cursor += 1;
                break;
            case 'strong':
                cursor = this[applyStyleAndEnterNode](node, 'font-weight:bold', cursor);
                break;
            case 'em':
                cursor = this[applyStyleAndEnterNode](node, 'font-style:italic', cursor);
                break;
            case 'ins':
                cursor = this[applyStyleAndEnterNode](node, 'text-decoration:underline', cursor);
                break;
            case 'del':
                cursor = this[applyStyleAndEnterNode](node, 'text-decoration:line-through', cursor);
                break;
            case 'sup':
                cursor = this[applyStyleAndEnterNode](node, 'vertical-align:super', cursor);
                break;
            case 'sub':
                cursor = this[applyStyleAndEnterNode](node, 'vertical-align:sub', cursor);
                break;
            case '#text':
                cursor += node.textContent.length;
                break;
            default:
                break;
        }
        if (node.nextSibling) {
            cursor = this[extractRunStyles](node.nextSibling, cursor);
        }
        return cursor;
    }

    [extractRunStylesFromSpan](node, textOffset) {
        const style = node.getAttribute('style');

        return this[applyStyleAndEnterNode](node, style, textOffset);
    }

    [applyStyleAndEnterNode](node, style, textOffset) {
        if (style && node.textContent.length !== 0) {
            const endIndex = calculateRunStyleEndIndex(node, textOffset);
            this[parseRunStyleAt](style, textOffset, endIndex);
        }

        return this[extractRunStyles](node.firstChild, textOffset);
    }

    getRunStyleFromCSS(htmlStyles) {
        let style = {};
        const htmlProperties = this.constructor[extractStyleEntriesFromStyleAttribute](htmlStyles);

        htmlProperties.forEach(({ property, value }) => {
            style = this[parseHTMLRunStyleProperty](property, value, style, htmlProperties);
        });
        return style;
    }

    [parseRunStyleAt](htmlStyles, startIndex, endIndex) {
        const style = this.getRunStyleFromCSS(htmlStyles);

        if (Object.keys(style).length > 0) {
            this.setStyleProperties(
                style,
                startIndex,
                endIndex,
                this.constructor.extractDynamicTypeInStyleString(htmlStyles)
            );
        }
    }

    [parseHTMLRunStyleProperty](property, value, input, htmlProperties) {
        let output = { ...input };
        switch (property) {
            case 'text-decoration':
                value.forEach(textDecoration => {
                    output = this.constructor[parseTextDecorationValue](textDecoration, output);
                });
                break;
            case 'font-family':
                output = this.constructor[parseFontValue](property, value.join(' '), output);
                break;
            case 'font-weight':
            case 'font-style':
                output = this.constructor[parseFontValue](property, value[0], output);
                break;
            case 'font-size':
                output = this.constructor[parseFontValue](property, parseFloat(value[0]), output);
                break;
            case 'letter-spacing':
                output.characterSpacing = parseFloat(value[0]);
                break;
            case 'color': {
                output.color = ColorValueDescriptor.getDescriptorFromValue(
                    value.join(' '),
                    this.shape
                );
                const htmlProperty = htmlProperties.find(({ property: key }) => key === '--color-preset');
                if (htmlProperty) {
                    [output.color.preset] = htmlProperty.value;
                }
                break;
            }
            case 'text-transform':
                output.textTransform = value[0] === 'uppercase' ?
                    'allCaps' :
                    '';
                break;
            case 'font-variant':
                output.textTransform = value[0] === 'small-caps' ?
                    'smallCaps' :
                    '';
                break;
            case 'vertical-align':
                output.subscript = false;
                output.subscript = false;
                if (value[0] === 'super') {
                    output.superscript = true;
                }
                if (value[0] === 'sub') {
                    output.subscript = true;
                }
                break;
            default:
                break;
        }
        return output;
    }

    static [parseFontValue](property, value, input) {
        const output = { font: {}, ...input };
        output.font[property.replace('font-', '')] = value;
        return output;
    }

    static [parseTextDecorationValue](value, input) {
        const output = { ...input };
        switch (value) {
            case 'line-through':
                output.linethrough = true;
                break;
            case 'overline':
            case 'underline':
            default:
                output[value] = true;
                break;
        }
        return output;
    }
};
