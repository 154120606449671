const { DEFAULT_TABLE_SIZE_SETTINGS } = require('../../config/defaultTableAttributes.config');

module.exports = Base => class extends Base {
    splitCells(cellIds, nbRows, nbColumns) {
        [...new Set(cellIds)].forEach(cellId => {
            this.splitCell(cellId, nbRows, nbColumns);
        });
    }

    splitColumnAtPosition(columnIndex, coord) {
        const columnWidth = this.columnWidths[columnIndex];
        if (Math.round(coord) < 0 || columnWidth < Math.round(coord)) {
            throw new Error(`Can't split column at outside position ${coord}`);
        }
        if ([0, columnWidth].includes(Math.floor(coord))) {
            return;
        }
        if (
            Math.round(coord) < DEFAULT_TABLE_SIZE_SETTINGS.minCellWidth ||
            (columnWidth - DEFAULT_TABLE_SIZE_SETTINGS.minCellWidth) < Math.round(coord)
        ) {
            throw new Error(`Can't split column at position ${coord} this would create cells smaller than ${DEFAULT_TABLE_SIZE_SETTINGS.minCellWidth}`);
        }
        this.columnWidths.splice(columnIndex, 1, coord, columnWidth - coord);
        this.bumpCellsAfterColumnIndex(columnIndex);
        const splittedCells = this.getCellIdsInColumn(columnIndex);
        splittedCells.forEach(cell => {
            this.getCellById(cell).expandSpanAfterColumnSplit(columnIndex);
        });
    }

    splitRowAtPosition(rowIndex, coord) {
        const rowHeight = this.combineDefinedAndRenderRowHeights()[rowIndex];
        if (Math.round(coord) < 0 || Math.round(rowHeight) < Math.round(coord)) {
            throw new Error(`Can't split column at outside position ${coord}`);
        }
        if ([0, Math.floor(rowHeight)].includes(Math.floor(coord))) {
            return;
        }
        if (
            Math.round(coord) < DEFAULT_TABLE_SIZE_SETTINGS.minCellHeight ||
            (rowHeight - DEFAULT_TABLE_SIZE_SETTINGS.minCellHeight) < Math.round(coord)
        ) {
            throw new Error(`Can't split column at position ${coord} this would create cells smaller than ${DEFAULT_TABLE_SIZE_SETTINGS.minCellHeight}`);
        }
        this.definedRowHeights = [
            ...this.combineDefinedAndRenderRowHeights().slice(0, rowIndex),
            coord,
            rowHeight - coord,
            ...this.combineDefinedAndRenderRowHeights().slice(rowIndex + 1)
        ];
        this.rowHeights.splice(
            rowIndex,
            1,
            DEFAULT_TABLE_SIZE_SETTINGS.minCellHeight,
            DEFAULT_TABLE_SIZE_SETTINGS.minCellHeight
        );
        this.bumpCellsBelowRowIndex(rowIndex);
        const splittedCells = this.getCellIdsInRow(rowIndex);
        splittedCells.forEach(cellId => {
            this.getCellById(cellId).expandSpanAfterRowSplit(rowIndex);
        });
    }

    splitCellAtHorizontalPosition(cellId, position) {
        const cell = this.getCellById(cellId);
        const initialColumnSpan = cell.columnSpan;
        const columnIndex = cell.getColumnIndexAtRelativeX(position);
        const columnRelativePosition = cell.getColumnRelativeXFromCellRelativeX(position);
        this.splitColumnAtPosition(columnIndex, columnRelativePosition);
        if (initialColumnSpan !== cell.columnSpan) {
            cell.splitAtColumnIndex(columnIndex + 1);
        } else {
            cell.splitAtColumnIndex(columnIndex);
        }
    }

    getRandomBorder() {
        const randomBorder = this.getBorderById(((this.horizontalBorders[5] || [])[0]));
        return randomBorder;
    }

    splitCellAtVerticalPosition(cellId, position) {
        const cell = this.getCellById(cellId);
        const rowIndex = cell.getRowIndexAtRelativeY(position);
        const rowRelativePosition = cell.getRowRelativeYFromCellRelativeY(position);
        this.splitRowAtPosition(rowIndex, rowRelativePosition);
        cell.splitAtRowIndex(rowIndex + 1);
    }

    splitCell(cellId, nbRows, nbColumns) {
        const startCell = this.getCellById(cellId);
        let actualWidth = startCell.width;
        const widthStep = actualWidth / nbColumns;

        while (widthStep < Math.floor(actualWidth)) {
            actualWidth -= widthStep;
            this.splitCellAtHorizontalPosition(cellId, actualWidth);
        }

        const columnCellIds = this.getCellGridRow(startCell.row)
            .slice(startCell.column, startCell.column + nbColumns);

        const startHeight = startCell.height;
        const heightStep = startHeight / nbRows;

        columnCellIds
            .forEach(columnCellId => {
                let actualHeight = startHeight;
                while (heightStep < Math.floor(actualHeight)) {
                    actualHeight -= heightStep;
                    this.splitCellAtVerticalPosition(columnCellId, actualHeight);
                }
            });
    }

    bumpCellsBelowRowIndex(rowIndex) {
        const cellsToOffset = Object.values(this.cells)
            .filter(cell => cell.row > rowIndex);

        const borderIds = cellsToOffset.reduce((borders, cell) => [
            ...borders,
            ...new Set([
                ...cell.bottomBorderSegments,
                ...(cell.column === 0 ? cell.leftBorderSegments : []),
                ...cell.rightBorderSegments
            ])
        ], []);

        const borders = borderIds
            .map(borderId => this.getBorderById(borderId));

        borders
            .forEach(border => {
                border.row += 1;
            });

        cellsToOffset.forEach(cell => {
            cell.row += 1;
        });
    }

    bumpCellsAfterColumnIndex(columnIndex) {
        const cellsToOffset = Object.values(this.cells)
            .filter(cell => cell.column > columnIndex);

        const wasAlreadyBumped = cellsToOffset
            .some(cell => cell.column + cell.columnSpan === this.columnCount);

        if (!wasAlreadyBumped) {
            const borderIds = cellsToOffset.reduce((borders, cell) => [
                ...borders,
                ...new Set([
                    ...cell.bottomBorderSegments,
                    ...(cell.row === 0 ? cell.topBorderSegments : []),
                    ...cell.rightBorderSegments
                ])
            ], []);

            const borders = borderIds
                .map(borderId => this.getBorderById(borderId));

            borders
                .forEach(border => {
                    border.column += 1;
                });

            cellsToOffset.forEach(cell => {
                cell.column += 1;
            });
        }
    }
};
