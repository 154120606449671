import { List, Map } from 'immutable';

const sortByStartIndexes = toSort => {
    if (!List.isList(toSort) || toSort.find(item => !Map.isMap(item))) {
        throw new Error(`${toSort} should be an immutable List`);
    }
    const sortedItems = toSort.sortBy(item => item.get('startIndex'));
    return sortedItems;
};

export default sortByStartIndexes;
