import { fromJS, Map } from 'immutable';
import complementAgainstSources from '../../utilities/complementAgainstSources';

import defaultsDeepWithExceptions from '../../utilities/defaultsDeepWithExceptions';
import pick from '../../utilities/pick';
import { immutableShapeStyleProvider as shapeStyleProvider } from '../../utilities/ShapeStyleProvider';
import { fillEmptySerialized } from '../Fill';
import { forceOpacity, updateValueOfDescriptor } from './colorValueDescriptor';

const atomicProperties = [
    'fill'
];

const shapeStyleProperties = [
    'fill',
    'opacity',
    'rx',
    'ry'
];

const removeFillIfEmpty = shape => {
    if (shape.get('fill') && shape.get('fill').equals(fillEmptySerialized)) {
        return shape.remove('fill');
    }
    return shape;
};

const forceShapeFillFromRendered = shape => {
    if (shape.get('fill') === undefined) {
        return shape.set('fill', getRenderShapeProperty(shape, ['fill']));
    }
    return shape;
};

// FIXME: shouldn't have to removeEmptyFill
const getRenderShapeProperty = (shape, propertyPath) => {
    const shapeNoEmptyFill = removeFillIfEmpty(shape);
    return defaultsDeepWithExceptions(
        shapeNoEmptyFill,
        shapeNoEmptyFill.getIn(['assignedStyles', 'shape']) || Map(),
        getShapeStyleDefaults(shapeNoEmptyFill),
        atomicProperties
    ).getIn(propertyPath);
};

const getShapeStyleDefaults = shape => {
    const style = pick(
        loadDefaultShapeStyleConfiguration(shape.get('type')),
        [
            'styleId',
            'fill',
            'opacity',
            'rx',
            'ry'
        ]
    );

    return style;
};

const loadDefaultShapeStyleConfiguration = type => (shapeStyleProvider.get(type) ?
    shapeStyleProvider.get(type) :
    shapeStyleProvider.get('DEFAULT'));

const setComplementShapeStyleAsItemShapeStyle = shape => initShapeStyle(
    shape,
    complementAgainstSources(
        pick(shape, shapeStyleProperties),
        getShapeStyleDefaults(shape),
        shape.getIn(['assignedStyles', 'shape']) || Map(),
        atomicProperties
    )
);

const initShapeStyle = (shape, properties) => shape
    .remove('fill')
    .remove('opacity')
    .remove('rx')
    .remove('ry')
    .remove('styleId')
    .merge(properties);

const assignedShapeStyleToCanvasState = (shape, assignedShapeStyle, canvasState) => {
    let updatedAssignedShapeStyle = fromJS(assignedShapeStyle);
    if (assignedShapeStyle) {
        if (assignedShapeStyle.rx) {
            updatedAssignedShapeStyle = updatedAssignedShapeStyle.update(
                'rx',
                rx => rx / shape.get('width')
            );
        }

        if (assignedShapeStyle.ry) {
            updatedAssignedShapeStyle = updatedAssignedShapeStyle.update(
                'rx',
                ry => ry / shape.get('height')
            );
        }

        return updatedAssignedShapeStyle.update(
            'fill',
            fill => fill.update(
                'color',
                color => updateValueOfDescriptor(
                    forceOpacity(color, updatedAssignedShapeStyle.get('opacity')),
                    canvasState.get('colorPalette')
                )
            )
        );
    }

    return updatedAssignedShapeStyle;
};

export {
    forceShapeFillFromRendered,
    getRenderShapeProperty,
    setComplementShapeStyleAsItemShapeStyle,
    initShapeStyle,
    assignedShapeStyleToCanvasState
};
