const checkIfTextShape = obj => [
    'textshape',
    'customImage',
    'customLine',
    'regularPolygon',
    'triangle',
    'rectangle',
    'trapezoid',
    'chevron',
    'arrow',
    'callout',
    'customPath',
    'chart',
    'ellipse'
].includes(obj.type);

const getSubGroup = (group, groupId) => group._objects.find(s => s.id === groupId);

const extractShapesFromGroup = (group, ids) => group.filter(
    shape => ids.find(shapeId => shape.id === shapeId)
);

const getFabricShapeFromParentGroups = (
    itemsList,
    selectedShapesParentGroupsPath,
    ids
) => selectedShapesParentGroupsPath.flatMap(selectedShapeParentGroupsPath => {
    const lastParentGroup = selectedShapeParentGroupsPath.reduce(
        (currentGroup, nextGroupId) => getSubGroup(currentGroup, nextGroupId),
        itemsList
    );
    return extractShapesFromGroup(lastParentGroup._objects, ids);
});

const applyPageContentOffset = (obj, { left, top }) => {
    const offseted = { ...obj };
    offseted.left = (offseted.left || 0) + left;
    offseted.top = (offseted.top || 0) + top;
    if (offseted.type === 'line') {
        [1, 2].forEach(point => {
            offseted[`x${point}`] = (offseted[`x${point}`] || 0) + left;
            offseted[`y${point}`] = (offseted[`y${point}`] || 0) + top;
        });
    }
    if (checkIfTextShape(offseted)) {
        offseted.textFormat = applyPageContentOffset(offseted.textFormat, { left, top });
    }
    return offseted;
};

const getItemsByIdsWithParentGroupsIds = (itemsList, idsList, selectedShapesParentGroupsPath) => {
    if (selectedShapesParentGroupsPath && selectedShapesParentGroupsPath.length > 0) {
        return getFabricShapeFromParentGroups(
            { _objects: itemsList },
            selectedShapesParentGroupsPath,
            idsList
        );
    }
    return extractShapesFromGroup(itemsList, idsList);
};

const getItemByid = (itemsList, id) => itemsList.reduce((foundItem, currentItem) => {
    if (foundItem) {
        return foundItem;
    } if (currentItem.type === 'group') {
        if (currentItem.id === id) return currentItem;
        const child = getItemByid(currentItem.getObjects(), id);
        if (child) return child;
        return null;
    } if (checkIfTextShape(currentItem) &&
        currentItem.getShapeObject().id === id) {
        return currentItem;
    }
    return null;
}, null);

const getDistanceToAnchor = (anchors, position) => anchors.reduce((bestAnchor, currentAnchor) => {
    const distanceToAnchor = ((currentAnchor.x - position.x) * (currentAnchor.x - position.x)) +
            ((currentAnchor.y - position.y) * (currentAnchor.y - position.y));
    return distanceToAnchor > bestAnchor.distPowerTwo ?
        bestAnchor : { anchor: currentAnchor, distPowerTwo: distanceToAnchor };
}, { anchor: null, distPowerTwo: Infinity });

module.exports = {
    applyPageContentOffset,
    checkIfTextShape,
    getItemByid,
    getDistanceToAnchor,
    getItemsByIdsWithParentGroupsIds
};
