const { get } = require('lodash');
const complementAgainstSources = require('../../../utilities/complementAgainstSources');
const defaultsDeepWithExceptions = require('../../../utilities/defaultsDeepWithExceptions');
const RunStyle = require('../../Styles/RunStyle');
const TextBodyData = require('../TextBodyData');

module.exports = Base => class extends Base {
    constructor(shape, options) {
        super(shape, options);
        this.runStyle = get(options, 'runStyle');
        this.assignedRunStyle = get(options, 'assignedRunStyle');
    }

    getRenderRunStyle() {
        return defaultsDeepWithExceptions(
            this.runStyle.toJSON(),
            this.assignedRunStyle.toJSON(),
            this.defaultRunStyle.toJSON(),
            RunStyle.atomicProperties
        );
    }

    setComplementRunStyleAsRunStyle() {
        this.runStyle = RunStyle.fromJSON(defaultsDeepWithExceptions(
            TextBodyData.getDefaultRunStyle(this.textBodyData),
            this.defaultRunStyle.toJSON(),
            RunStyle.atomicProperties
        ));
        this.runStyle = RunStyle.fromJSON(complementAgainstSources(
            this.runStyle.toJSON(),
            this.assignedRunStyle.toJSON(),
            RunStyle.atomicProperties
        ));
        this.setRunStyleDefaultOnData();
    }

    complementRunStyleAgainstDefault(runStyle = {}) {
        const defaultRunStyle = runStyle.isDefault === true ?
            {} :
            this.runStyle.toJSON();
        return complementAgainstSources(
            runStyle,
            this.defaultRunStyle.toJSON(),
            this.assignedRunStyle.toJSON(),
            defaultRunStyle,
            RunStyle.atomicProperties
        );
    }

    setRunStyleDefaultOnData(shape) {
        this.textBodyData = TextBodyData.setDefaultRunStyle(
            this.textBodyData,
            shape?.textBody?.getDefaultRunStyle() ?
                shape?.textBody?.getDefaultRunStyle() :
                this.getRenderRunStyle()
        );
    }

    copyAssignedRunStyle(original) {
        this.assignedRunStyle = original.assignedRunStyle;
    }

    get assignedRunStyle() {
        return this._assignedRunStyle;
    }

    set assignedRunStyle(runStyle = RunStyle.fromJSON({})) {
        if (!(runStyle instanceof RunStyle)) {
            throw new Error('Can\'t set invalid textbody runStyle on textbody');
        } else {
            this._assignedRunStyle = runStyle;
        }
    }

    get defaultRunStyle() {
        const defaultRunStyle = RunStyle.fromJSON(this.defaults.DEFAULT_RUNSTYLE);
        return defaultRunStyle;
    }

    get runStyle() {
        return this._runStyle;
    }

    set runStyle(runStyle = RunStyle.fromJSON({})) {
        if (!(runStyle instanceof RunStyle) && runStyle !== undefined) {
            throw new Error('Can\'t set invalid textbody runStyle on textbody');
        } else {
            this._runStyle = runStyle;
        }
    }

    get characterSpacing() {
        return this.runStyle.characterSpacing;
    }

    set characterSpacing(characterSpacing) {
        this.runStyle.characterSpacing = characterSpacing;
    }

    get color() {
        return this.runStyle.color;
    }

    set color(bullet) {
        this.runStyle.color = bullet;
    }

    get font() {
        return this.runStyle.font;
    }

    set font(bullet) {
        this.runStyle.font = bullet;
    }

    get hyperlink() {
        return this.runStyle.hyperlink;
    }

    set hyperlink(indent) {
        this.runStyle.hyperlink = indent;
    }

    get linethrough() {
        return this.runStyle.linethrough;
    }

    set linethrough(textDirection) {
        this.runStyle.linethrough = textDirection;
    }

    get opacity() {
        return this.runStyle.opacity;
    }

    set opacity(opacity) {
        this.runStyle.opacity = opacity;
    }

    get overline() {
        return this.runStyle.overline;
    }

    set overline(overline) {
        this.runStyle.overline = overline;
    }

    get superscript() {
        return this.runStyle.superscript;
    }

    set superscript(superscript) {
        this.runStyle.superscript = superscript;
    }

    get subscript() {
        return this.runStyle.subscript;
    }

    set subscript(subscript) {
        this.runStyle.subscript = subscript;
    }

    get textTransform() {
        return this.runStyle.textTransform;
    }

    set textTransform(textTransform) {
        this.runStyle.textTransform = textTransform;
    }

    get underline() {
        return this.runStyle.underline;
    }

    set underline(underline) {
        this.runStyle.underline = underline;
    }
};
