const StrokeFill = require('./StrokeFill.js');
const StrokeFillConstructor = require('.');
const Pattern = require('./Pattern.js');

class PatternStrokeFill extends StrokeFill {
    constructor(pattern) {
        super('pattern');
        this.pattern = pattern;
    }

    toJSON() {
        return Object.assign(super.toJSON(), {
            pattern: this.pattern.toJSON()
        });
    }

    static fromJSON(patternStrokeFill, jsonObject) {
        return new this(Pattern.fromJSON(jsonObject.pattern));
    }
}

StrokeFillConstructor.addToConstructorList(PatternStrokeFill, 'pattern');

module.exports = PatternStrokeFill;
