const {
    fromJS,
    Map,
    List
} = require('immutable');
const CanvasStateSelectors = require('../CanvasStateSelectors');
const CommandTypes = require('./types');
const { commands } = require('../../CanvasState/Commands/commands');
const { default: validateCommand } = require('./validation/commandValidator');

module.exports = {
    propertiesConservingContextualSelection: [
        CommandTypes.ADD_TABLE_LINE,
        CommandTypes.SPLIT_SELECTED_CELLS,
        CommandTypes.MERGE_SELECTED_CELLS,
        CommandTypes.CLEAR_SELECTED_CELLS,
        CommandTypes.UPDATE_PROPERTIES_ON_SELECTED_SHAPES,
        CommandTypes.UPDATE_PROPERTIES_FROM_DELTA_ON_SELECTED_SHAPES,
        CommandTypes.UPDATE_SHAPES_PROPERTIES,
        CommandTypes.UPDATE_CELL_CONTENT_TEXTBODY,
        CommandTypes.UPDATE_SHAPES

    ],

    commandsWithoutEffectsOnCachedCanvasStateShapes: [
        CommandTypes.SET_CLIPBOARD,
        CommandTypes.COPY_SELECTED_SHAPES,
        CommandTypes.UPDATE_COLOR_PALETTE
    ],

    commandsWithoutShapeTypesRelevance: [
        CommandTypes.GROUP_SELECTED_SHAPES,
        CommandTypes.UNGROUP_SELECTED_SHAPES,
        CommandTypes.DUPLICATE_SHAPES
    ],

    executeCommand(canvasState, command) {
        validateCommand(command);

        if (!this.isCanvasState(canvasState)) {
            throw new Error(`Can't execute ${command.type} command on invalid canvas state`);
        }

        let updatedCanvasState = null;

        updatedCanvasState = this.generateUpdatedCanvasState(canvasState, fromJS(command));

        if (updatedCanvasState) {
            return canvasState
                .merge(updatedCanvasState)
                .set('version', canvasState.get('version') + 1)
                .set('history', canvasState.get('history').push(Map(command)));
        }

        return canvasState;
    },

    getCommandShapeIdsByTypes(canvasState, command) {
        const ids = command.get('ids') ||
            command.get('selection') ||
            canvasState?.get('selection') ||
            List();
        let shapeTypes = ids.map(id => Map({
            type: CanvasStateSelectors.getShapeById(canvasState, id)?.get('type').toLowerCase(),
            id
        }));
        if (command.get('shapes')) {
            shapeTypes = command.get('shapes').map(shape => Map({
                type: shape.get('type').toLowerCase(),
                id: undefined
            }));
        }
        let shapesByTypes = shapeTypes.groupBy(shapeType => shapeType.get('type'));
        if (this.commandsWithoutShapeTypesRelevance.includes(command.get('type'))) {
            shapesByTypes = Map();
        }
        return shapesByTypes;
    },

    generateUpdatedCanvasState(canvasState, command) {
        const shapeIdsByTypes = this.getCommandShapeIdsByTypes(canvasState, command);
        let updatedCanvasState = canvasState;
        let commandAlreadyHandled = false;
        try {
            shapeIdsByTypes
                .keySeq()
                .forEach(shapeType => {
                    const ids = shapeIdsByTypes
                        .get(shapeType)
                        .map(s => s.get('id'));
                    updatedCanvasState = commands[shapeType]?.[command.get('type')]?.(updatedCanvasState, command, ids) ||
                    commands.default[command.get('type')](updatedCanvasState, command, ids);
                    commandAlreadyHandled = true;
                });
            if (!commandAlreadyHandled) {
                const commandFunction = commands.default?.[command.get('type')];

                if (!commandFunction) {
                    throw new Error(`Unknown canvas update command : ${
                        command.get('type')
                    }`);
                }
                updatedCanvasState = commandFunction(updatedCanvasState, command);
            }
        } catch (err) {
            console.error(err);
            throw new Error(`Could not process command : ${
                command.get('type')
            }`);
        }
        return updatedCanvasState;
    }
};
