import CanvasStateSelectors from '../../../Canvas/CanvasStateSelectors';
import getPropertiesForDestructuring from '../../utilities/getPropertiesForDestructuring';

const convertSelectedItemsTextLevel = (canvasState, command) => {
    const selectedCanvasItemPaths = CanvasStateSelectors
        .getCanvasStateSelectedShapePaths(canvasState);

    const {
        oldLevel,
        newLevel
    } = getPropertiesForDestructuring(
        command,
        [
            'oldLevel',
            'newLevel'
        ]
    );

    return canvasState.withMutations(state => {
        selectedCanvasItemPaths.forEach(path => {
            convertCanvasItemTextItemsLevel(state, path, newLevel, oldLevel);
        });
    });
};

const convertCanvasItemTextItemsLevel = (state, path, newLevel, oldLevel) => {
    convertTextBodyItemsLevel(state, [...path, 'textBody'], newLevel, oldLevel);
    convertTextBodyItemsLevel(state, [...path, 'textBodyPlaceholder'], newLevel, oldLevel);
};

const convertTextBodyItemsLevel = (state, textBodyPath, newLevel, oldLevel) => {
    if (state.hasIn([...textBodyPath, 'paragraphStyles'])) {
        state.updateIn(
            [...textBodyPath, 'paragraphStyles'],
            styles => styles.map(style => style.update(
                'level',
                currentLevel => convertLevel({
                    currentLevel,
                    newLevel,
                    oldLevel
                })
            ))
        );
    }
};

const convertLevel = ({ currentLevel, newLevel, oldLevel }) => {
    if (currentLevel === oldLevel || (
        oldLevel === 0 && currentLevel === undefined
    )) {
        return newLevel;
    }
    return currentLevel;
};

export default convertSelectedItemsTextLevel;
