import { fromJS } from 'immutable';
import defaultFromFabricToCanvasState from './default';

const fromFabricToCanvasState = (fabricShape, canvasState) => fromJS({
    ...defaultFromFabricToCanvasState(fabricShape, canvasState),
    headWidth: fabricShape.headWidth,
    tailHeight: fabricShape.tailHeight,
    isDouble: fabricShape.isDouble
});

export default fromFabricToCanvasState;
