import { combineReducers } from 'redux';
import DecksignCanvas from 'decksign-canvas';
import { isNewShapeType } from '#Utilities/decksign/object';

import * as types from '#Constants/ActionTypes';

const CanvasState = DecksignCanvas.api.CanvasState;

const deck = (state = [], action) => {
    switch (action.type) {
        case types.GET_PAGE_FONTS:
            if (!action.fonts.length) {
                return state;
            }

            return [...new Set([...state, ...action.fonts])];
        case types.LOAD_PAGE: {
            const missingFonts = CanvasState
                .getFontsList(action.canvasState)
                .reduce((acc, item) => {
                    if (!isNewShapeType(item)) {
                        return acc;
                    }
                    item.textBody.getTextWithStyles()
                        .forEach(({ style }) => {
                            if (style && style.font && style.font.family) {
                                if (!state.includes(style)) {
                                    acc.add(style.font.family);
                                }
                            }
                        });
                    return acc;
                }, new Set());
            return [
                ...state,
                ...missingFonts
            ];
        }
        default:
            return state;
    }
};

const fetched = (state = [], action) => {
    switch (action.type) {
        case types.ADD_FETCHED_FONTS:
            return [
                ...state,
                ...action.fonts
            ];
        default:
            return state;
    }
};

const registry = (state = null, action) => {
    switch (action.type) {
        case types.GET_FONTS_REGISTRY:
            return action.fonts;
        default:
            return state;
    }
};

const fetching = (state = [], action) => {
    switch (action.type) {
        case types.FETCHING_FONTS:
            return [...new Set([...state, ...action.fonts])];
        case types.FETCHED_FONT_ERRORS:
            return state.filter(f => !action.fonts.includes(f));
        case types.ADD_FETCHED_FONTS:
            return state.filter(f => !action.fonts.includes(f));
        default:
            return state;
    }
};

const unresolved = (state = [], action) => {
    switch (action.type) {
        case types.FETCHED_FONT_ERRORS:
            return [...new Set([...state, ...action.fonts])];
        default:
            return state;
    }
};

const fonts = combineReducers({
    deck,
    fetched,
    registry,
    fetching,
    unresolved
});

export default fonts;
