const { DEFAULT_HEADERROW_STYLE } = require('./config/defaultCellStyles.config.js');
const Cell = require('./Cell.js');

class HeaderRowCell extends Cell {
    constructor(name, x, y, width, height, attributes = {}) {
        super(name, x, y, width, height);
        this.isHeaderRowCell = true;
        Object.assign(this, DEFAULT_HEADERROW_STYLE, attributes);
    }
}

module.exports = HeaderRowCell;
