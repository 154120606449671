import gql from 'graphql-tag';
import update from 'lodash/update';

import {
    getPageFields,
    getDeckFields,
    getObjectiveFields,
    getStoryboardFields,
    getTopicFields,
    getShareFields,
    getViewerFields
} from '#Utilities/graphql/fields';

import { makeClient } from '#Utilities/graphql/client';

export const fetchPage = pageId => makeClient()
    .then(client => client.query({
        query: gql`
    query($pageId: String){
        page(id: $pageId) {
            ${getPageFields()},
        }
    }`,
        variables: {
            pageId
        }
    }))
    .then(({ data }) => data.page);

export const fetchLayouts = deckId => makeClient()
    .then(client => client.query({
        query: gql`
    query($deckId: String){
        deck(id: $deckId) {
            id,
                layouts
        }
    }`,
        variables: {
            deckId
        }
    }))
    .then(({ data }) => data.deck.layouts);

export const fetchDeck = deckId => makeClient()
    .then(client => client.query({
        query: gql`
    query($deckId: String) {
        deck(id: $deckId) {
            ${getDeckFields()}
        }
    }`,
        variables: {
            deckId
        }
    }))
    .then(({ data }) => data.deck);

export const fetchCurrentDeck = () => makeClient()
    .then(client => client.query({
        query: gql`{
            currentDeck {
                ${getDeckFields()}
            }
        }`
    }))
    .then(({ data }) => data.currentDeck);

export const fetchObjectives = () => makeClient()
    .then(client => client.query({
        query: gql`{
            objectives {
                ${getObjectiveFields()}
            }
        }`
    }))
    .then(({ data }) => data.objectives);

export const fetchStoryboards = objectiveId => makeClient()
    .then(client => client.query({
        query: gql`
    query($objectiveId: String){
        storyboards(objectiveId: $objectiveId) {
            ${getStoryboardFields()}
        }
    }`,
        variables: {
            objectiveId
        }
    }))
    .then(({ data }) => data.storyboards);

export const fetchStoryboardTopics = storyboardId => makeClient()
    .then(client => client.query({
        query: gql`
    query($storyboardId: String){
        storyboardTopics(storyboardId: $storyboardId) {
            optional,
                sequence,
                topic {
                    ${getTopicFields()}
                }
        }
    }`,
        variables: {
            storyboardId
        }
    }))
    .then(({ data }) => data.storyboardTopics);

export const getThemes = () => makeClient()
    .then(client => client.query({
        query: gql`{
            theme {
                id
                themeId
                versionId
                name
                description
                thumbnails
                fonts
                colorPalette {
                    id
                    color
                }
            }
        }`
    }))
    .then(({ data }) => data.theme);

export const getLogos = () => makeClient()
    .then(client => client.query({
        query: gql`{
            logos {
                id,
                    height,
                    width,
                    size,
                    sources,
                    name,
                    _id
            }
        }`
    }))
    .then(({ data }) => data.logos);

export const fetchTopics = () => makeClient()
    .then(client => client.query({
        query: gql`{
            topics {
                id
                label
            }
        }`
    }))
    .then(({ data }) => data.topics);

export const fetchShare = shareId => makeClient()
    .then(client => client.query({
        query: gql`
    query($shareId: String){
        share(shareId: $shareId) {
            ${getShareFields()}
        }
    }`,
        fetchPolicy: 'no-cache',
        variables: {
            shareId
        }
    }))
    .then(({ data }) => data.share);

export const fetchShareSummary = shareId => makeClient()
    .then(client => client.query({
        query: gql`
    query($shareId: String){
        shareSummary(id: $shareId) {
            id,
                deck,
                pageCount,
                dates,
                description,
                pages,
                duration,
                pagesViewed,
                feedbacks,
                uniqueViewers,
                comments,
                isSubLevel,
                audience,
                viewsCount,
                uniqueViews,
                read,
                opened,
                user
        }
    }`,
        fetchPolicy: 'no-cache',
        variables: {
            shareId
        }
    }))
    .then(({ data }) => data.shareSummary);

export const fetchDeckSharesSummary = deckId => makeClient()
    .then(client => client.query({
        query: gql`
    query($deckId: String){
        deckShareSummary(id: $deckId) {
            pages,
                shares,
                duration,
                pagesViewed,
                feedbacks,
                uniqueViewers,
                comments,
                isSubLevel,
                audience,
                viewsCount,
                uniqueViews,
                read,
                opened
        }
    }`,
        fetchPolicy: 'no-cache',
        variables: {
            deckId
        }
    }))
    .then(({ data }) => data.deckShareSummary);

export const fetchShareViewerData = ({
    shareId,
    publicToken,
    viewerAccessToken,
    viewerId
}) => makeClient()
    .then(client => client.query({
        query: gql`
    query($shareId: String, $publicToken: String, $viewerAccessToken: String, $viewerId: String){
        view(
            shareId: $shareId,
            publicToken: $publicToken,
            viewerAccessToken: $viewerAccessToken,
            viewerId: $viewerId
        ) {
            ${getViewerFields()}
        }
    }`,
        fetchPolicy: 'no-cache',
        variables: {
            shareId,
            publicToken,
            viewerAccessToken,
            viewerId
        }
    }))
    .then(({ data }) => data.view);

export const fetchInvoices = () => makeClient()
    .then(client => client.query({
        query: gql`{
            invoices {
                id
                number
                due_date
                created
                invoice_pdf
                hosted_invoice_url
                amount_due
                currency
                paid,
                    tax_percentages
            }
        }`,
        fetchPolicy: 'no-cache'
    }))
    .then(({ data }) => data.invoices);

export const fetchInvites = () => makeClient()
    .then(client => client.query({
        query: gql`{
            invites {
                _id,
                    email,
                    status,
                    createdAt,
                    resendCount
            }
        }`,
        fetchPolicy: 'no-cache'
    }))
    .then(({ data }) => data.invites);

export const fetchMe = () => makeClient()
    .then(client => client.query({
        query: gql`{
            me {
                name,
                occupation,
                activeSection,
                subscription,
                createdAt,
                email,
                id,
                showWelcomeScreen
            },
            currentDeck {
                ${getDeckFields()}
            }
        }`,
        fetchPolicy: 'no-cache'
    }))
    .then(({ data }) => ({ user: data.me, currentDeck: data.currentDeck }));

export const search = searchBody => makeClient()
    .then(client => client.query({
        query: gql`
        query($searchBody: JSON){
        search(searchBody: $searchBody)
    }`,
        variables: {
            searchBody
        },
        fetchPolicy: 'no-cache'
    }))
    .then(({ data: { search: searchResult } } = {}) => update(searchResult, 'public', (decks = []) => decks.map(result => ({
        ...result,
        deck: {
            ...result.deck,
            isSharedDeck: true
        }
    }))))
    .catch(() => []);

export const getFontMetricsForFiles = files => makeClient()
    .then(client => client.query({
        query: gql`
        query($files: [String]) {
        getFontMetricsForFiles(files: $files)
    }`,
        variables: {
            files
        }
    }))
    .then(({ data }) => data.getFontMetricsForFiles);

export const getLastShareForDeck = deckId => makeClient()
    .then(client => client.query({
        query: gql`
        query($deckId: String){
        getLastShareForDeck(deckId: $deckId) {
            recipients,
                permissions,
                audience
        }
    }`,
        variables: {
            deckId
        }
    }))
    .then(({ data }) => data.getLastShareForDeck);

export const listProcessRecords = () => makeClient()
    .then(client => client.query({
        query: gql`{
            listProcessRecords {
                name,
                    status,
                    file
            }
        }`,
        fetchPolicy: 'no-cache'
    }))
    .then(({ data }) => data.listProcessRecords)
    .catch(() => ({}));

export const getRecordForFile = fileId => makeClient()
    .then(client => client.query({
        query: gql`
        query($fileId: String){
        getRecordForFile(file: $fileId) {
            status,
                deck,
                totalPages,
                completedPages
        }
    }`,
        variables: {
            fileId
        },
        fetchPolicy: 'no-cache'
    }))
    .then(({ data }) => data.getRecordForFile);

export const getBillingInfo = () => makeClient()
    .then(client => client.query({
        query: gql`
        {
            billingInfo {
                address {
                    line1,
                        line2,
                        country,
                        state,
                        city,
                        postal_code
                },
                    card {
                        brand,
                            last4,
                            exp_year,
                            exp_month
                    },
                    cardholder
            }
        }`,
        fetchPolicy: 'no-cache'
    }))
    .then(({ data }) => data.billingInfo);

export const getPlansInfo = () => makeClient()
    .then(client => client.query({
        query: gql`{ plans {
            id,
                nickname,
                amount,
                interval,
                currency
        }
        }`
    }))
    .then(({ data }) => data.plans);

export const getConfig = () => makeClient()
    .then(client => {
        const result = client.query({
            query: gql`{config}`
        });
        return result;
    })
    .then(({ data }) => data.config);
