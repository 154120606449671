import * as types from '#Constants/ActionTypes';

const initialState = {
    visible: false,
    share: {},
    error: false,
    isLoading: false
};

const updateShare = (state = initialState, action) => {
    switch (action.type) {
        case types.TOGGLE_UPDATE_SHARE_MODAL:
            return { ...state, visible: !state.visible };
        case types.CLOSE_UPDATE_SHARE_MODAL:
            return { ...state, visible: false, share: {} };
        case types.OPEN_UPDATE_SHARE_MODAL:
            return {
                ...state,
                visible: true,
                share: { ...action.share },
                error: false,
                isLoading: false
            };
        case types.UPDATE_SHARE_MODAL_FETCH_PENDING:
        case types.UPDATE_SHARE_MUTATION_PENDING:
            return { ...state, isLoading: true };
        case types.UPDATE_SHARE_MUTATION_REJECTED:
        case types.UPDATE_SHARE_MODAL_FETCH_REJECTED:
            return { ...state, error: true, isLoading: false };
        case types.UPDATE_SHARE_MUTATION_DONE:
            return {
                ...state,
                share: {},
                error: false,
                isLoading: false
            };
        default:
            return state;
    }
};

export { updateShare };
