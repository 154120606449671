const { DEFAULT_HEADERCOL_STYLE } = require('./config/defaultCellStyles.config.js');
const Cell = require('./Cell.js');

class HeaderColumnCell extends Cell {
    constructor(name, x, y, width, height, attributes = {}) {
        super(name, x, y, width, height);
        this.isHeaderColumnCell = true;
        Object.assign(this, DEFAULT_HEADERCOL_STYLE, attributes);
    }
}

module.exports = HeaderColumnCell;
