module.exports = {
    paragraphs: [
        {
            text: 'This is the first paragraph of my test for the new decksign text canvas.',
            styles: [
                {
                    0: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    1: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    2: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    3: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    4: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    5: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    6: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    7: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    8: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    9: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    10: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    11: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    12: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    13: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    14: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    15: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    16: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    17: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    18: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    19: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    20: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    21: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    22: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    23: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    24: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    25: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    26: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    27: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    28: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    29: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    30: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    31: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    32: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    33: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    34: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    35: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    36: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    37: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    38: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    39: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    40: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    41: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    42: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    43: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    44: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    45: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    46: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    47: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    48: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    49: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    50: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    51: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    52: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    53: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    54: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    55: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    56: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    57: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    58: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    59: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    60: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    61: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    62: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    63: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    64: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    65: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    66: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    67: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    68: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    69: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    70: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    71: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    }
                }
            ],
            fill: 'rgba(77,77,77,1)',
            fillPreset: 'C5',
            hyperlink: '',
            fontFamily: 'Libre Franklin Regular',
            fontSize: 26.6667,
            fontStyle: 'normal',
            fontWeight: 'normal',
            overline: false,
            underline: false,
            linethrough: false,
            opacity: 1,
            charSpacing: 0,
            textTransform: '',
            subscript: false,
            superscript: false,
            type: 'paragraph',
            paddingBottom: 10.6667,
            textAlign: 'left',
            lineHeight: 1.5,
            indent: 0,
            bulletText: ''
        },
        {
            text: "This is the second paragraph and I'm testing the invocation of text.",
            styles: [
                {
                    0: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    1: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    2: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    3: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    4: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    5: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    6: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    7: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    8: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    9: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    10: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    11: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    12: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    13: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    14: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    15: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    16: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    17: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    18: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    19: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    20: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    21: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    22: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    23: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    24: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    25: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    26: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    27: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    28: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    29: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    30: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    31: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    32: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    33: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    34: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    35: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    36: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    37: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    38: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    39: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    40: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    41: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    42: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    43: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    44: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    45: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    46: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    47: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    48: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    49: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    50: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    51: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    52: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    53: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    54: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    55: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    56: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    57: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    58: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    59: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    60: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    61: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    62: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    63: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    64: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    65: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    66: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    67: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    }
                }
            ],
            fill: 'rgba(255,255,255,1)',
            fillPreset: '',
            hyperlink: '',
            fontFamily: 'Lato',
            fontSize: 16,
            fontStyle: 'normal',
            fontWeight: 'normal',
            overline: false,
            underline: false,
            linethrough: false,
            opacity: 1,
            charSpacing: 0,
            textTransform: '',
            subscript: false,
            superscript: false,
            type: 'paragraph',
            paddingBottom: 10.6667,
            paddingLeft: 42.666666666666664,
            paddingRight: 85.33333333333333,
            paddingTop: 10.666666666666666,
            textAlign: 'left',
            lineHeight: 1.5,
            indent: 0,
            bulletText: ''
        },
        {
            text: '[...]',
            styles: [
                {
                    0: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    1: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    2: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    3: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    },
                    4: {
                        fill: 'rgba(77,77,77,1)',
                        fillPreset: 'C5',
                        hyperlink: '',
                        fontFamily: 'Libre Franklin Regular',
                        fontSize: 26.6667,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        overline: false,
                        underline: false,
                        linethrough: false,
                        opacity: 1,
                        charSpacing: 0,
                        textTransform: '',
                        subscript: false,
                        superscript: false
                    }
                }
            ],
            fill: 'rgba(255,255,255,1)',
            fillPreset: '',
            hyperlink: '',
            fontFamily: 'Lato',
            fontSize: 16,
            fontStyle: 'normal',
            fontWeight: 'normal',
            overline: false,
            underline: false,
            linethrough: false,
            opacity: 1,
            charSpacing: 0,
            textTransform: '',
            subscript: false,
            superscript: false,
            type: 'paragraph',
            paddingBottom: 10.6667,
            paddingTop: 21.333333333333332,
            textAlign: 'left',
            lineHeight: 1.5,
            indent: 0,
            bulletText: ''
        }
    ],
    textDirection: 'horizontal',
    width: 641.01
};
