import Ajv from 'ajv';
import { CommandCategories } from './commandCategories';
import AjvValidator from './validators/AjvValidator';

const ajv = new Ajv();

const baseCommandSchema = {
    type: 'object',
    properties: {
        type: { type: 'string' }
    },
    required: ['type']
};

const updateShapesCommandSchema = {
    type: 'object',
    properties: {
        type: { type: 'string' },
        ids: {
            type: 'array',
            items: {
                type: 'string'
            }
        }
    },
    required: ['type', 'ids']
};

const createOrReplaceShapeCommandSchema = {
    type: 'object',
    properties: {
        type: { type: 'string' },
        shapes: {
            type: 'array',
            items: {
                type: 'object'
            }
        }
    },
    required: ['type', 'shapes']
};

export default {
    [CommandCategories.UPDATE_CANVAS_COMMAND]: [
        new AjvValidator(ajv.compile(baseCommandSchema))
    ],
    [CommandCategories.UPDATE_SELECTED_COMMAND]: [
        new AjvValidator(ajv.compile(baseCommandSchema))
    ],
    [CommandCategories.UPDATE_SHAPES_COMMAND]: [
        new AjvValidator(ajv.compile(updateShapesCommandSchema))
    ],
    [CommandCategories.CREATE_OR_REPLACE_SHAPE_COMMAND]: [
        new AjvValidator(ajv.compile(createOrReplaceShapeCommandSchema))
    ]
};
