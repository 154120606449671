const get = require('lodash/get');
const complementAgainstSources = require('../../../utilities/complementAgainstSources');
const defaultsDeepWithExceptions = require('../../../utilities/defaultsDeepWithExceptions');
const TextBodyStyle = require('../../Styles/TextBodyStyle');

module.exports = Base => class extends Base {
    constructor(shape, options) {
        super(shape, options);
        this.style = get(options, 'style');
        this.assignedStyle = get(options, 'assignedStyle');
    }

    getRenderStyleProperty(propertyPath) {
        return get(defaultsDeepWithExceptions(
            this.style.toJSON(),
            this.assignedStyle.toJSON(),
            this.defaultStyle.toJSON(),
            []
        ), propertyPath);
    }

    setComplementTextBodyStyleAsTextBodyStyle() {
        this.style = TextBodyStyle.fromJSON(complementAgainstSources(
            this.style.toJSON(),
            this.defaultStyle.toJSON(),
            this.assignedStyle.toJSON(),
            []
        ));
    }

    copyAssignedTextBodyStyle(original) {
        this.assignedStyle = original.assignedStyle;
    }

    get assignedStyle() {
        return this._assignedStyle;
    }

    set assignedStyle(style = TextBodyStyle.fromJSON({})) {
        if (!(style instanceof TextBodyStyle)) {
            throw new Error('Can\'t set invalid textbody style on textbody');
        } else {
            this._assignedStyle = style;
        }
    }

    get defaultStyle() {
        return TextBodyStyle.fromJSON(this.defaults.DEFAULT_TEXT_BODY_STYLE);
    }

    get style() {
        return this._style;
    }

    set style(style = TextBodyStyle.fromJSON({})) {
        if (!(style instanceof TextBodyStyle) && style !== undefined) {
            throw new Error('Can\'t set invalid textbody style on textbody');
        } else {
            this._style = style;
        }
    }

    get autoFitText() {
        return this.style.autoFitText;
    }

    set autoFitText(autoFitText) {
        this.style.autoFitText = autoFitText;
    }

    get autoFitShape() {
        return this.style.autoFitShape;
    }

    set autoFitShape(autoFitShape) {
        this.style.autoFitShape = autoFitShape;
    }

    get margins() {
        return this.style.margins;
    }

    set margins(margins) {
        this.style.margins = margins;
    }

    get textDirection() {
        return this.style.textDirection;
    }

    set textDirection(textDirection) {
        this.style.textDirection = textDirection;
    }

    get verticalAlign() {
        return this.style.verticalAlign;
    }

    set verticalAlign(verticalAlign) {
        this.style.verticalAlign = verticalAlign;
    }
};
