const Bounds = require('./Bounds');

class EllipseBounds extends Bounds {
    get dimensions() {
        return {
            width: this.shapeWidth / Math.sqrt(2),
            height: this.shapeHeight / Math.sqrt(2)
        };
    }
}

module.exports = EllipseBounds;
