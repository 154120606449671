const isNil = require('lodash/isNil');
const has = require('lodash/has');
const get = require('lodash/get');
const Stroke = require('../Shape/Stroke/Stroke.js');
const CanvasItem = require('../Shape/CanvasItem');
const StrokeFill = require('../Shape/Stroke/StrokeFill');
require('../Shape/Stroke/StrokeFill/StrokeFillLoader.js');
const WithStrokeStyle = require('../Shape/AbstractShape/mixins/WithStrokeStyle');
const BorderFabricMixin = require('../fabric-adapter/mixins/borderFabricObject');
const tableGridLogic = require('../utilities/table/tableGridLogic');
const { defaultStrokeAttributes } = require('./config/defaultStrokeAttributes.config.js');

class Border extends WithStrokeStyle(BorderFabricMixin(CanvasItem)) {
    static fromJSON(jsonObject) {
        const json = jsonObject;
        if (has(json, 'assignedStyles.stroke')) {
            json.assignedStrokeStyle = Stroke.fromJSON(get(json, 'assignedStyles.stroke'));
        }
        const borderSegment = new this(
            json.name,
            Stroke.fromJSON({
                ...json.stroke
            }),
            '',
            json.side,
            json.row,
            json.column,
            {
                id: json.id || json._id,
                inLayout: json.inLayout,
                assignedStrokeStyle: json.assignedStrokeStyle
            }
        );
        return borderSegment;
    }

    static get UPDATE_TYPES() {
        return {
            ALL: 'all',
            BOTTOM: 'bottom',
            CLEAR: 'clear',
            INNER_ALL: 'inner-all',
            INNER_HORIZONTAL: 'inner-horizontal',
            INNER_VERTICAL: 'inner-vertical',
            LEFT: 'left',
            OUTER: 'outer',
            RIGHT: 'right',
            TOP: 'top'
        };
    }

    static get SIDE() {
        return {
            HORIZONTAL: 'horizontal',
            VERTICAL: 'vertical'
        };
    }

    static get UPDATES_SEQUENCE() {
        return [
            this.UPDATE_TYPES.ALL,
            this.UPDATE_TYPES.INNER_ALL,
            this.UPDATE_TYPES.INNER_HORIZONTAL,
            this.UPDATE_TYPES.INNER_VERTICAL,
            this.UPDATE_TYPES.OUTER,
            this.UPDATE_TYPES.LEFT,
            this.UPDATE_TYPES.RIGHT,
            this.UPDATE_TYPES.BOTTOM,
            this.UPDATE_TYPES.TOP
        ];
    }

    static get defaults() {
        return {
            stroke: defaultStrokeAttributes
        };
    }

    constructor(name, stroke, table, side, row, column, attributes = {}) {
        super(name, attributes.id, attributes.inLayout);
        this.stroke = stroke;
        this.table = table;
        this.side = side;
        this.row = row;
        this.column = column;
        Object.assign(this, attributes);
        if (attributes.assignedStrokeStyle) {
            this.assignedStrokeStyle = attributes.assignedStrokeStyle;
        }
    }

    toJSON() {
        const json = {
            ...super.toJSON(),
            side: this.side,
            row: this.row,
            column: this.column,
            stroke: this.stroke && this.stroke.toJSON(),
            table: this.table._id
        };

        if (this.assignedStrokeStyle !== undefined) {
            json.assignedStyles = {
                stroke: this.assignedStrokeStyle.toJSON()
            };
        }

        return json;
    }

    copy() {
        const copy = new Border(
            this.name,
            this.stroke && this.stroke.copy(),
            this.table,
            this.side,
            this.row,
            this.column,
            {
                inLayout: this.inLayout,
                assignedStrokeStyle: this.assignedStrokeStyle
            }
        );

        copy.copyStyles(copy);

        return copy;
    }

    applyUpdate({
        fill, width, dash, opacity, assignedStrokeStyle, removeCustomStyles
    }) {
        if (fill && StrokeFill.strokeFillConstructor[fill.type]) {
            if (this.stroke === undefined) {
                this.stroke = Stroke.fromJSON({});
            }
            this.stroke.fill = fill;
        }
        if (!isNil(opacity) && !Number.isNaN(opacity)) {
            this.forceStrokeFillFromRendered();
            this.stroke.opacity = opacity;
        }
        if (width !== undefined) {
            this.stroke.width = width;
        }
        if (dash !== undefined) {
            const {
                type,
                dashStops = []
            } = dash;
            this.stroke.dash = new Stroke.Dash(type, dashStops);
        }

        if (assignedStrokeStyle) {
            this.assignedStrokeStyle = Stroke.fromJSON(assignedStrokeStyle);
            if (removeCustomStyles) {
                this.stroke = Stroke.fromJSON({});
            }
        }
    }

    updateColorPresets({ presets, scheme }) {
        this.updateStrokeColorPresets({ presets, scheme });
    }

    isInsideCellSelection(cellSelectionIds = []) {
        if (this.isHorizontal()) {
            return !this.isOnTableEdge() &&
                cellSelectionIds.includes(this.table.cellGrid[this.row - 1][this.column]) &&
                cellSelectionIds.includes(this.table.cellGrid[this.row][this.column]);
        }
        return !this.isOnTableEdge() &&
            cellSelectionIds.includes(this.table.cellGrid[this.row][this.column - 1]) &&
            cellSelectionIds.includes(this.table.cellGrid[this.row][this.column]);
    }

    isOnEdgeOfCellSelection(cellSelectionIds) {
        if (this.isHorizontal()) {
            const cellToLeftIsInSelection = this.row > 0 &&
                cellSelectionIds.includes(this.table.cellGrid[this.row - 1][this.column]);
            const cellToRightIsInSelection = this.row < this.table.rowCount &&
                cellSelectionIds.includes(this.table.cellGrid[this.row][this.column]);
            return !this.isInsideCellSelection(cellSelectionIds) &&
                (cellToLeftIsInSelection ||
                cellToRightIsInSelection);
        }
        const cellOnTopIsInSelection = this.column > 0 &&
            cellSelectionIds.includes(this.table.cellGrid[this.row][this.column - 1]);
        const cellOnBottomIsInSelection = this.column < this.table.columnCount &&
            cellSelectionIds.includes(this.table.cellGrid[this.row][this.column]);
        return !this.isInsideCellSelection(cellSelectionIds) &&
            (cellOnTopIsInSelection ||
            cellOnBottomIsInSelection);
    }

    isHorizontal() {
        return this.side === Border.SIDE.HORIZONTAL;
    }

    isVertical() {
        return this.side === Border.SIDE.VERTICAL;
    }

    isOnTableEdge() {
        return tableGridLogic.isBorderOnTableEdge(this.id, this.table);
    }

    isInsideCell() {
        return tableGridLogic.isBorderInsideCell(this.id, this.table);
    }

    shouldRender() {
        return !this.isInsideCell();
    }

    copyStyles(original) {
        super.copyStyles(original);
    }

    get center() {
        return this.isHorizontal() ?
            (this.x1 + this.x2) / 2 :
            (this.y1 + this.y2) / 2;
    }

    get defaults() {
        return this.constructor.defaults;
    }
}

module.exports = Border;
