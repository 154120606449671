const { Map, List } = require('immutable');
const isEqual = require('lodash/isEqual');

export default (exceptions = []) => (value, source, key) => {
    if (exceptions.includes(key)) {
        if (isEqual(value, source)) {
            return value;
        }
        return null;
    }
    if (!Map.isMap(value) || !Map.isMap(source)) {
        if (Map.isMap(value) || Map.isMap(source)) {
            return null;
        }
        return value === source ? value : null;
    }
    if (List.isList(value) || List.isList(source)) {
        if (List.isList(value) && List.isList(source)) {
            return List([...value, ...source]);
        }
        return null;
    }
    if (value === undefined || source === undefined) {
        return null;
    }
    return undefined;
};
