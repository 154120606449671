import { omit } from 'lodash';
import defaultShapeToFabricJSON from './default';
// eslint-disable-next-line import/no-cycle
import generalFromShapeToFabricJSON from '../fromShapeToFabricJSON';

const fromShapeToFabricJSON = (shape, canvasState) => ({
    ...omit((defaultShapeToFabricJSON(shape, canvasState)), ['opacity']),
    anchors: {},
    stroke: undefined,
    subTargetCheck: true,
    shapeType: 'Group',
    objects: shape.get('shapes')
        .map(child => generalFromShapeToFabricJSON(child, canvasState))
        .toJS()
});

export default fromShapeToFabricJSON;
