import { Map } from 'immutable';

const PathsExceptions = [
    ['color', 'type'],
    ['color', 'reference']
];

const cleanupStyles = (fullDefaultStyle, styles) => {
    const cleanedStyles = styles.filter(style => !style.get('isDefault')).map(style => style.reduce((cleanedStyle, value, key) => {
        if (fullDefaultStyle.get(key) === value) {
            return cleanedStyle.delete(key);
        }
        if (Map.isMap(value) && key !== 'cursorStyle' && fullDefaultStyle.get(key)) {
            let cleanedStyleWithMap = cleanedStyle;
            fullDefaultStyle.get(key).forEach((val, property) => {
                if (value.get(property) === val) {
                    if (!PathsExceptions.some(p => p.join(',') === [key, property].join(','))) {
                        cleanedStyleWithMap = cleanedStyle.deleteIn([key, property]);
                    }
                }
            });
            return cleanedStyleWithMap;
        }
        return cleanedStyle;
    }, style));
    return cleanedStyles;
};

export default cleanupStyles;
