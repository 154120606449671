import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Route from '#Components/Common/Router/Route';
import Switch from '#Components/Common/Router/Switch';
import { otherNavConfigByType } from '#Config/navigation.config';
import { setAppConfig } from '#Actions/app';
import LoadingComponent from '#Components/Common/LoadableLoading/LoadableLoading';
import AppLoadingIndicator from '#Components/Common/AppLoadingIndicator/AppLoadingIndicator';
import { GlobalEventHandlerProvider } from './GlobalEventHandlerContext';
import withRaven from './withRaven/withRaven';

import './App.scss';
import PrivateRoute from '../Common/Router/PrivateRoute';

const Main = React.lazy(() => import('./Main/Main'));

const Auth = React.lazy(() => import('#Components/Auth'));

const ConnectedViewer = React.lazy(() => import('#Components/ConnectedViewer/ConnectedViewer'));

class App extends React.Component {
    componentDidMount() {
        this.props.onSetAppConfig();
    }

    render() {
        return (
            <GlobalEventHandlerProvider>
                <div className="App">
                    <Suspense fallback={<LoadingComponent />}>
                        <AppLoadingIndicator />
                        <Switch>
                            <Route path="/auth" component={Auth} />
                            <Route
                                path={`/${otherNavConfigByType.viewer.path}/:shareId/:viewerId`}
                                component={ConnectedViewer}
                            />

                            <PrivateRoute
                                path="/"
                                component={Main}
                            />
                        </Switch>
                    </Suspense>
                </div>
            </GlobalEventHandlerProvider>
        );
    }
}

App.propTypes = {
    onSetAppConfig: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    subscription: state.user.profile.subscription
});

const mapDispatchToProps = dispatch => ({
    onSetAppConfig: () => dispatch(setAppConfig())
});

const AppWithStore = connect(mapStateToProps, mapDispatchToProps)(App);
const AndRaven = withRaven(AppWithStore);

export default (AndRaven);
