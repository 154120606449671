import { setSelectedStoryboard } from '#Actions/plan/storyboards/storyboards';
import { setSelectedObjective } from '#Actions/plan/objectives/objectives';
import { forceRemoveUserSelectedTopics, forceToBlankUserSelectedTopics } from '#Actions/plan/topics/topics';
import * as types from '#Constants/ActionTypes';

const showSaveButton = () => ({ type: types.SHOW_SAVE_BUTTON });

const hideSaveButton = () => ({ type: types.HIDE_SAVE_BUTTON });

const removeInitialStoryboard = (deleteAllPages = false) => (dispatch, getState) => {
    const selectedTopics = getState().plan.userSelectedTopics;
    dispatch({ type: types.REMOVE_INITIAL_STATE });
    if (deleteAllPages) {
        return dispatch(forceRemoveUserSelectedTopics(selectedTopics));
    }
    return dispatch(forceToBlankUserSelectedTopics(selectedTopics));
};

const resetToInitialState = () => (dispatch, getState) => {
    const state = getState();
    if (state.plan.initialStoryboard.id !== state.plan.selectedStoryboard.id) {
        return dispatch(setSelectedStoryboard(state.plan.initialStoryboard))
            .then(() => dispatch(setSelectedObjective(state.plan.initialObjective)));
    }
    return Promise.resolve();
};

const changeDeletionModalState = (modalIsOpen, triggeringTopics, browseIsOpen = false) => ({
    type: types.CHANGE_PAGE_DELETION_MODAL,
    modalIsOpen,
    triggeringTopics,
    browseIsOpen
});

const setItemDescription = (itemTitle, description) => ({
    type: types.SET_CURRENT_ITEM_DESCRIPTION,
    itemTitle,
    description
});

export {
    showSaveButton,
    hideSaveButton,
    removeInitialStoryboard,
    resetToInitialState,
    changeDeletionModalState,
    setItemDescription
};
