import * as types from '#Constants/ActionTypes';

const accessToken = (state = '', action) => {
    switch (action.type) {
        case types.INIT_VIEW:
            return action.accessToken;
        default:
            return state;
    }
};

export { accessToken };
