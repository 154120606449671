import * as types from '#Constants/ActionTypes';

const feedbacks = (state = {}, action) => {
    switch (action.type) {
        case types.PUT_PAGE_FEEDBACKS: {
            const pageFeedbacks = state[action.pageIndex];
            const newFeedbacks = pageFeedbacks ?
                { ...pageFeedbacks, [action.feedbackCat]: action.feedbackValue } :
                { [action.feedbackCat]: action.feedbackValue };

            return { ...state, [action.pageIndex]: newFeedbacks };
        }
        default: return state;
    }
};

export { feedbacks };
