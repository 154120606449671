export const ADD_SELECTED_TOPICS = 'ADD_SELECTED_TOPICS';
export const ADD_USER_SELECTED_TOPICS = 'ADD_USER_SELECTED_TOPICS';
export const ADD_USER_UNSELECTED_TOPICS = 'ADD_USER_UNSELECTED_TOPICS';
export const CHANGE_PAGE_DELETION_MODAL = 'CHANGE_PAGE_DELETION_MODAL';
export const CHANGE_SELECTED_COLOR_IN_COLOR_PALETTE = 'CHANGE_SELECTED_COLOR_IN_COLOR_PALETTE';
export const CLOSE_COLOR_PALETTE_COLOR_PICKER = 'CLOSE_COLOR_PALETTE_COLOR_PICKER';
export const EXPAND_COLOR_PALETTE = 'EXPAND_COLOR_PALETTE';
export const FLAG_PROPERTIES_UNSAVED = 'FLAG_PROPERTIES_UNSAVED';
export const FLAG_PROPERTIES_SAVED = 'FLAG_PROPERTIES_SAVED';
export const FETCH_OBJECTIVES_LIST = 'FETCH_OBJECTIVES_LIST';
export const FETCH_STORYBOARDS_LIST = 'FETCH_STORYBOARDS_LIST';
export const FINISH_COLOR_PALETTE_SAVE = 'FINISH_COLOR_PALETTE_SAVE';
export const HIDE_SAVE_BUTTON = 'HIDE_SAVE_BUTTON';
export const OPEN_COLOR_PALETTE_COLOR_PICKER = 'OPEN_COLOR_PALETTE_COLOR_PICKER';
export const REORDER_ENDED = 'REORDER_ENDED';
export const REORDER_STARTED = 'REORDER_STARTED';
export const RESET_SELECTED_COLORS_IN_COLOR_PALETTE = 'RESET_SELECTED_COLORS_IN_COLOR_PALETTE';
export const REMOVE_ALL_SELECTED_TOPICS = 'REMOVE_ALL_SELECTED_TOPICS';
export const REMOVE_INITIAL_STATE = 'REMOVE_INITIAL_STATE';
export const REMOVE_OBJECTIVES_LIST = 'REMOVE_OBJECTIVES_LIST';
export const REMOVE_PAGE_ENDED = 'REMOVE_PAGE_ENDED';
export const REMOVE_PAGE_STARTED = 'REMOVE_PAGE_STARTED';
export const REMOVE_SELECTED_TOPICS = 'REMOVE_SELECTED_TOPICS';
export const REMOVE_STORYBOARDS_LIST = 'REMOVE_STORYBOARDS_LIST';
export const REMOVE_TOPICS_LIST = 'REMOVE_TOPICS_LIST';
export const START_COLOR_PALETTE_SAVE = 'START_COLOR_PALETTE_SAVE';
export const SET_ALL_OBJECTIVES_TYPES = 'SET_ALL_OBJECTIVES_TYPES';
export const SET_FILTERED_OBJECTIVES_LIST = 'SET_FILTERED_OBJECTIVES_LIST';
export const SET_FILTERED_STORYBOARDS_LIST = 'SET_FILTERED_STORYBOARDS_LIST';
export const SET_INITIAL_OBJECTIVE = 'SET_INITIAL_OBJECTIVE';
export const SET_INITIAL_STORYBOARD = 'SET_INITIAL_STORYBOARD';
export const SET_OBJECTIVES_CATEGORIES = 'SET_OBJECTIVES_CATEGORIES';
export const SET_OBJECTIVES_LIST = 'SET_OBJECTIVES_LIST';
export const SET_SELECTED_OBJECTIVE = 'SET_SELECTED_OBJECTIVE';
export const SET_SELECTED_STORYBOARD = 'SET_SELECTED_STORYBOARD';
export const SET_SELECTED_TOPICS = 'SET_SELECTED_TOPICS';
export const SET_STORYBOARDS_LIST = 'SET_STORYBOARDS_LIST';
export const SET_USER_SELECTED_TOPICS = 'SET_USER_SELECTED_TOPICS';
export const SET_USER_UNSELECTED_TOPICS = 'SET_USER_UNSELECTED_TOPICS';
export const SET_TOPICS_LIST = 'SET_TOPICS_LIST';
export const SET_BROWSE_TOPICS_LIST = 'SET_BROWSE_TOPICS_LIST';
export const SET_TOPIC_SELECTION_STATE = 'SET_TOPIC_SELECTION_STATE';
export const SET_OTHER_REPRESENTATIONS = 'SET_OTHER_REPRESENTATIONS';
export const SET_SELECTED_PAGE = 'SET_SELECTED_PAGE';
export const SET_CURRENT_ITEM_DESCRIPTION = 'SET_CURRENT_ITEM_DESCRIPTION';
export const SHOW_SAVE_BUTTON = 'SHOW_SAVE_BUTTON';
