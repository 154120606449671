const { fabric } = require('fabric');
const svgpath = require('svgpath');
const Textshape = require('./TextShape');
const { generatePathFromPoints } = require('../../utilities/PathGenerator/PathGenerator');
const { getTrapezoidPoints } = require('../../utilities/ShapeGenerators');
const { flipDimension } = require('../utilities/FlipDimensions');
const TextBoundsCalculator = require('../utilities/TextBoundsCalculator');
const { SHAPE_RADIUS } = require('./ModificationHandlers/config/defaultModificationHandlerAttributes.config');

const Trapezoid = fabric.util.createClass(Textshape, {
    type: 'trapezoid',

    initialize(json, opts) {
        this.callSuper('initialize', json, opts);
        this.shapeType = 'Trapezoid';
        this.getHandlerPosition = this.getHandlerPosition.bind(this);
        this.getHandlerLimits = this.getHandlerLimits.bind(this);
        this.updateModifierAttribute = this.updateModifierAttribute.bind(this);
    },

    updateShapeScale(scaleX, scaleY) {
        const shapeObject = this.getShapeObject();
        shapeObject.set({
            baseLength: shapeObject.baseLength * scaleX
        });
        this.callSuper('updateShapeScale', scaleX, scaleY, true);
    },

    generateModificationHandlers() {
        this.handlers = [
            new fabric.GenericModificationHandler(
                this,
                this.getHandlerPosition,
                this.getHandlerLimits,
                this.updateModifierAttribute,
                { lockVertical: true }
            )
        ];
    },

    getHandlerPosition() {
        const relativePosition = this.getRelativeHandlerPosition();
        const trapezoid = this.getShapeObject();
        const absolutePosition = this.getAbsolutePosition();
        return {
            x: absolutePosition.x + flipDimension(trapezoid.flipX, relativePosition.x),
            y: absolutePosition.y + flipDimension(trapezoid.flipY, relativePosition.y + SHAPE_RADIUS)
        };
    },

    getRelativeHandlerPosition() {
        const trapezoid = this.getShapeObject();
        const activeScaleX = this.scaleX * trapezoid.scaleX;
        const activeScaleY = this.scaleY * trapezoid.scaleY;
        const baseLength = trapezoid.baseLength * activeScaleX;
        return {
            x: -baseLength / 2,
            y: -(trapezoid.height * activeScaleY) / 2
        };
    },

    getHandlerLimits() {
        const trapezoid = this.getShapeObject();
        const halfScaledWidth = trapezoid.getScaledWidth() / 2;
        const halfStrokeWidth = (trapezoid.strokeWidth / 2) * trapezoid.scaleX;
        const absolutePosition = this.getAbsolutePosition();
        return {
            min: trapezoid.flipX ?
                absolutePosition.x :
                (absolutePosition.x - halfScaledWidth) + halfStrokeWidth,
            max: trapezoid.flipX ?
                (absolutePosition.x + halfScaledWidth) - halfStrokeWidth :
                absolutePosition.x
        };
    },

    updateModifierAttribute({ x }) {
        const trapezoid = this.getShapeObject();
        const newBaseLength = this.getNewBaseLength(x);
        const points = this.getNewVertexes(newBaseLength);
        const path = svgpath(generatePathFromPoints(points)).segments;
        trapezoid.baseLength = newBaseLength;
        trapezoid.points = points;
        trapezoid.path = path;
        this.set('dirty', true);
    },

    getNewBaseLength(x) {
        const trapezoid = this.getShapeObject();
        return flipDimension(this.trapezoid, (this.left - x) * 2) / trapezoid.scaleX;
    },

    getNewVertexes(newBaseLength) {
        const trapezoid = this.getShapeObject();
        return getTrapezoidPoints(
            trapezoid.width,
            trapezoid.height,
            newBaseLength
        );
    },

    getTextBounds() {
        const shape = this.getShapeObject();
        return new TextBoundsCalculator.Trapezoid(
            this.width,
            this.height,
            {
                baseLength: shape.baseLength,
                flipX: shape.flipX
            }
        );
    }
});

Trapezoid.fromObject = (object, callback) => {
    const trapezoid = new Trapezoid(object);
    trapezoid.on('text:load', () => {
        callback(trapezoid);
    })
        .on('load:error', err => {
            console.error(err);
            callback(null);
        });
};

module.exports = Trapezoid;
