import { fromJS } from 'immutable';
import defaultFromFabricToCanvasState from './default';

const fromFabricToCanvasState = (fabricShape, canvasState) => fromJS({
    ...defaultFromFabricToCanvasState(fabricShape, canvasState),
    type: 'Path',
    path: fabricShape.path
});

export default fromFabricToCanvasState;
