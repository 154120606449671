class GradientStop {
    constructor(position, color) {
        this.position = position;
        this.color = color;
    }

    toJSON() {
        return {
            position: this.position,
            color: this.color
        };
    }

    static fromJSON(jsonObject) {
        return new this(jsonObject.position, jsonObject.color);
    }
}

module.exports = GradientStop;
