import {
    Record,
    Map,
    List,
    fromJS
} from 'immutable';
import UUID from 'uuid/v4';
import genericShapeProperties from '../../genericShapeProperties';
import { addShape, getNewShapeName, updateSelection } from '../../Helpers/helpers';
import { generateEllipsePath } from '../../../utilities/PathGenerator/PathGenerator';
import Textbody from '../../Helpers/Text/TextBody';
import getPropertiesForDestructuring from '../../utilities/getPropertiesForDestructuring';
import { applyStyle } from '../../Helpers/Style/style';
import { convertPropertiesToShapeProperties } from '../../utilities/convertPropertiesToShapeProperties';

const EllipseRecord = Record({
    ...genericShapeProperties,
    type: 'Ellipse',
    rx: 0,
    ry: 0,
    path: ''
}, 'Ellipse');

const add = (canvasState, command) => {
    const {
        shapes,
        defaultCanvasItemStyles,
        editMode
    } = getPropertiesForDestructuring(
        command,
        [
            'shapes',
            'defaultCanvasItemStyles',
            'editMode'
        ]
    );

    const newIds = [];

    let updatedCanvasState = shapes.reduce((currentCanvasState, shape) => {
        const style = defaultCanvasItemStyles.get(shape.get('styleToLoad') || 'shape');
        const styleDefinition = applyStyle(Map(), style, 'Ellipse');
        const styleToApply = convertPropertiesToShapeProperties(styleDefinition);

        const textBody = Textbody.TextBody({
            paragraphStyles: List([Map({ isDefault: true })]),
            paragraphs: List([Map({ startIndex: 0, endIndex: -1, style: 1 })]),
            runStyles: List([Map({ isDefault: true })]),
            assignedStyles: Map({
                paragraph: styleToApply.getIn(['textProps', 'assignedParagraphStyle']),
                run: styleToApply.getIn(['textProps', 'assignedRunStyle']),
                textBody: styleToApply.getIn(['textProps', 'assignedStyle'])
            })
        });

        const textBodyPlaceholder = Textbody.TextBody({
            paragraphStyles: List([Map({ isDefault: true })]),
            paragraphs: List([Map({ startIndex: 0, endIndex: -1, style: 1 })]),
            runStyles: List([Map({ isDefault: true })]),
            assignedStyles: Map({
                paragraph: styleToApply.getIn(['textProps', 'assignedParagraphStyle']),
                run: styleToApply.getIn(['textProps', 'assignedRunStyle']),
                textBody: styleToApply.getIn(['textProps', 'assignedStyle'])
            })
        });

        const modifiers = shape.get('modifiers');

        const rx = modifiers.get('width') / 2;
        const ry = modifiers.get('height') / 2;
        const path = generateEllipsePath(rx, ry).buildString();

        const newShape = EllipseRecord(
            modifiers
                .merge(
                    fromJS({
                        name: getNewShapeName(currentCanvasState, 'Ellipse'),
                        id: UUID().toString(),
                        inLayout: editMode === 'layout',
                        x: currentCanvasState.get('size').get('width') / 2,
                        y: currentCanvasState.get('size').get('height') / 2,
                        assignedStyles: Map({
                            shape: styleToApply.getIn(['shapeProps', 'assignedShapeStyle']),
                            stroke: styleToApply.getIn(['strokeProps', 'assignedStrokeStyle'])
                        }),
                        style: style.get('id'),
                        rx,
                        ry,
                        path,
                        textBody,
                        textBodyPlaceholder
                    })
                )
        );
        newIds.push(newShape.get('id'));
        return addShape(currentCanvasState, newShape);
    }, canvasState);
    updatedCanvasState = updateSelection(updatedCanvasState, fromJS({ selection: newIds }));
    return updatedCanvasState;
};

export default add;
