import { List, Set } from 'immutable';
import {
    expandSpanAfterColumnSplit,
    expandSpanAfterRowSplit,
    getCellHeight,
    getCellWidth,
    getColumnIndexAtRelativeX,
    getColumnRelativeXFromCellRelativeX,
    getRowIndexAtRelativeY,
    getRowRelativeYFromCellRelativeY,
    splitAtColumnIndex,
    splitAtRowIndex
} from './cell';
import { DEFAULT_TABLE_SIZE_SETTINGS } from './Config/defaultTableAttributes.config';
import { combineDefinedAndRenderRowHeights, getCellGridRow } from './tableGridLogic';
import {
    getBottomBorderSegments,
    getCell,
    getCellIdsInColumn,
    getCellIdsInRow,
    getCellIndex,
    getLeftBorderSegments,
    getRightBorderSegments,
    getTopBorderSegments
} from './tableHelper';

const splitCell = (table, cellId, rows, columns) => {
    let updatedTable = table;
    const startCell = getCell(updatedTable, cellId);
    let actualWidth = getCellWidth(updatedTable, startCell);
    const widthStep = actualWidth / columns;

    while (widthStep < Math.floor(actualWidth)) {
        actualWidth -= widthStep;
        updatedTable = splitCellAtHorizontalPosition(updatedTable, cellId, actualWidth);
    }

    const columnCellIds = getCellGridRow(startCell.get('row'), updatedTable)
        .slice(startCell.get('column'), startCell.get('column') + columns);

    const startHeight = getCellHeight(updatedTable, startCell);
    const heightStep = startHeight / rows;

    updatedTable = columnCellIds.reduce((currentTable, currentCellId) => {
        let currentUpdatedTable = currentTable;
        let actualHeight = startHeight;
        while (heightStep < Math.floor(actualHeight)) {
            actualHeight -= heightStep;
            currentUpdatedTable = splitCellAtVerticalPosition(currentUpdatedTable, currentCellId, actualHeight);
        }
        return currentUpdatedTable;
    }, updatedTable);

    return updatedTable;
};

const splitCellAtHorizontalPosition = (table, cellId, position) => {
    let updatedTable = table;
    let cell = getCell(updatedTable, cellId);
    const initialColumnSpan = cell.get('columnSpan');
    const columnIndex = getColumnIndexAtRelativeX(updatedTable, cell, position);
    const columnRelativePosition = getColumnRelativeXFromCellRelativeX(updatedTable, cell, position);
    updatedTable = splitColumnAtPosition(updatedTable, columnIndex, columnRelativePosition);
    cell = getCell(updatedTable, cellId);
    if (initialColumnSpan !== cell.get('columnSpan')) {
        updatedTable = splitAtColumnIndex(updatedTable, cell, columnIndex + 1);
    } else {
        updatedTable = splitAtColumnIndex(updatedTable, cell, columnIndex);
    }

    return updatedTable;
};

const splitColumnAtPosition = (table, columnIndex, coord) => {
    let updatedTable = table;
    const columnWidth = updatedTable.getIn(['columnWidths', columnIndex]);
    if (Math.round(coord) < 0 || columnWidth < Math.round(coord)) {
        throw new Error(`Can't split column at outside position ${coord}`);
    }
    if ([0, columnWidth].includes(Math.floor(coord))) {
        return updatedTable;
    }
    if (
        Math.round(coord) < DEFAULT_TABLE_SIZE_SETTINGS.minCellWidth ||
        (columnWidth - DEFAULT_TABLE_SIZE_SETTINGS.minCellWidth) < Math.round(coord)
    ) {
        throw new Error(`Can't split column at position ${coord} this would create cells smaller than ${DEFAULT_TABLE_SIZE_SETTINGS.minCellWidth}`);
    }
    updatedTable = updatedTable.set('columnWidths', updatedTable.get('columnWidths').splice(columnIndex, 1, coord, columnWidth - coord));
    updatedTable = bumpCellsAfterColumnIndex(updatedTable, columnIndex);
    updatedTable = getCellIdsInColumn(updatedTable, columnIndex)
        .reduce((currentTable, cellId) => expandSpanAfterColumnSplit(
            currentTable,
            getCell(currentTable, cellId),
            columnIndex
        ), updatedTable);

    return updatedTable;
};

const bumpCellsAfterColumnIndex = (table, columnIndex) => {
    let updatedTable = table;
    const cellsToOffset = updatedTable.get('cells')
        .filter(cell => cell.get('column') > columnIndex);

    const wasAlreadyBumped = cellsToOffset
        .some(cell => cell.get('column') + cell.get('columnSpan') === table.get('columnWidths').size);

    if (!wasAlreadyBumped) {
        const borderIds = cellsToOffset.reduce((borders, cell) => [
            ...borders,
            ...Set([
                ...getBottomBorderSegments(cell.get('id'), updatedTable),
                ...(cell.get('row') === 0 ? getTopBorderSegments(cell.get('id'), updatedTable) : List()),
                ...getRightBorderSegments(cell.get('id'), updatedTable)
            ])
        ], List());

        updatedTable = borderIds.reduce((currentTable, borderId) => {
            const borderIndex = currentTable
                .get('borders')
                .findIndex(border => border.get('id') === borderId);
            return currentTable.setIn(
                ['borders', borderIndex, 'column'],
                currentTable.getIn(['borders', borderIndex, 'column']) + 1
            );
        }, updatedTable);

        updatedTable = cellsToOffset.reduce((currentTable, cell) => {
            const cellIndex = getCellIndex(currentTable, cell.get('id'));
            return currentTable.setIn(
                ['cells', cellIndex, 'column'],
                currentTable.getIn(['cells', cellIndex, 'column']) + 1
            );
        }, updatedTable);
    }

    return updatedTable;
};

const splitCellAtVerticalPosition = (table, cellId, position) => {
    let updatedTable = table;
    let cell = getCell(updatedTable, cellId);
    const rowIndex = getRowIndexAtRelativeY(updatedTable, cell, position);
    const rowRelativePosition = getRowRelativeYFromCellRelativeY(updatedTable, cell, position);
    updatedTable = splitRowAtPosition(updatedTable, rowIndex, rowRelativePosition);
    cell = getCell(updatedTable, cellId);
    updatedTable = splitAtRowIndex(updatedTable, cell, rowIndex + 1);
    return updatedTable;
};

const splitRowAtPosition = (table, rowIndex, coord) => {
    let updatedTable = table;
    const rowHeight = combineDefinedAndRenderRowHeights(updatedTable).get(rowIndex);
    if (Math.round(coord) < 0 || Math.round(rowHeight) < Math.round(coord)) {
        throw new Error(`Can't split column at outside position ${coord}`);
    }
    if ([0, Math.floor(rowHeight)].includes(Math.floor(coord))) {
        return updatedTable;
    }
    if (
        Math.round(coord) < DEFAULT_TABLE_SIZE_SETTINGS.minCellHeight ||
        (rowHeight - DEFAULT_TABLE_SIZE_SETTINGS.minCellHeight) < Math.round(coord)
    ) {
        throw new Error(`Can't split column at position ${coord} this would create cells smaller than ${DEFAULT_TABLE_SIZE_SETTINGS.minCellHeight}`);
    }
    updatedTable = updatedTable.set('definedRowHeights', List([
        ...combineDefinedAndRenderRowHeights(updatedTable).slice(0, rowIndex),
        coord,
        rowHeight - coord,
        ...combineDefinedAndRenderRowHeights(updatedTable).slice(rowIndex + 1)
    ]));
    updatedTable = updatedTable.set('rowHeights', updatedTable
        .get('rowHeights')
        .splice(
            rowIndex,
            1,
            DEFAULT_TABLE_SIZE_SETTINGS.minCellHeight,
            DEFAULT_TABLE_SIZE_SETTINGS.minCellHeight
        ));
    updatedTable = bumpCellsBelowRowIndex(updatedTable, rowIndex);
    updatedTable = getCellIdsInRow(updatedTable, rowIndex)
        .reduce((currentTable, cellId) => expandSpanAfterRowSplit(
            currentTable,
            getCell(updatedTable, cellId),
            rowIndex
        ), updatedTable);

    return updatedTable;
};

const bumpCellsBelowRowIndex = (table, rowIndex) => {
    let updatedTable = table;
    const cellsToOffset = table.get('cells')
        .filter(cell => cell.get('row') > rowIndex);

    const borderIds = cellsToOffset.reduce((borders, cell) => [
        ...borders,
        ...new Set([
            ...getBottomBorderSegments(cell.get('id'), updatedTable),
            ...(cell.get('column') === 0 ? getLeftBorderSegments(cell.get('id'), updatedTable) : List()),
            ...getRightBorderSegments(cell.get('id'), updatedTable)
        ])
    ], List());

    updatedTable = borderIds.reduce((currentTable, borderId) => {
        const borderIndex = currentTable
            .get('borders')
            .findIndex(border => border.get('id') === borderId);
        return currentTable.setIn(
            ['borders', borderIndex, 'row'],
            currentTable.getIn(['borders', borderIndex, 'row']) + 1
        );
    }, updatedTable);

    updatedTable = cellsToOffset.reduce((currentTable, cell) => {
        const cellIndex = getCellIndex(currentTable, cell.get('id'));
        return currentTable.setIn(
            ['cells', cellIndex, 'row'],
            currentTable.getIn(['cells', cellIndex, 'row']) + 1
        );
    }, updatedTable);

    return updatedTable;
};

export {
    // eslint-disable-next-line import/prefer-default-export
    splitCell
};
