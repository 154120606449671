import tinycolor from 'tinycolor2';

const getRgba = input => {
    if (isValid(input)) {
        const rgbaObject = getRgbaObject(input);

        return stringifyRgbaObject(rgbaObject);
    }
    throw new Error(`Invalid ${input} color can't convert to rgba`);
};

const getRgbaObject = input => {
    if (isValid(input)) {
        return tinycolor(input).toRgb();
    }
    throw new Error(`Invalid ${input} color can't convert to rgba object`);
};

const stringifyRgbaObject = ({
    r,
    g,
    b,
    a
}) => `rgba(${r},${g},${b},${a})`;

const isValid = input => tinycolor(input).isValid();

export {
    getRgba,
    getRgbaObject,
    stringifyRgbaObject,
    isValid
};
