import { List } from 'immutable';
import { DEFAULT_PARAGRAPHSTYLE } from '../Text/Config/defaultStyles';

export default shape => {
    const paragraphStyles = shape.getIn(['textBody', 'paragraphStyles'], List());
    const firstParagraph = shape.getIn('textBody', 'paragraphs', List())
        .find(paragraph => paragraph.get('startIndex') === 0);
    return (firstParagraph && paragraphStyles.get(firstParagraph.get('style'))) ?
        paragraphStyles.get(firstParagraph.get('style')) :
        DEFAULT_PARAGRAPHSTYLE;
};
