import { fromJS } from 'immutable';

import bulletConversion from './bulletConversion';

export default fromJS({
    typefacePreset: {
        isReference: true
    },
    paragraphPreset: {
        isReference: true
    },
    defaultBulletType: bulletConversion,
    bulletIndent: {},
    bulletText: {},
    bulletTypefacePreset: {
        isReference: true,
        referenceUpdateParent: 'bullet'
    },
    numericIndent: {},
    numericTypefacePreset: {
        isReference: true,
        referenceUpdateParent: 'numeric'
    },
    numericTextAfter: {},
    numericTextBefore: {},
    alphaIndent: {},
    alphaTypefacePreset: {
        isReference: true,
        referenceUpdateParent: 'alpha'
    },
    alphaTextAfter: {},
    alphaTextBefore: {},
    romanIndent: {},
    romanTypefacePreset: {
        isReference: true,
        referenceUpdateParent: 'roman'
    },
    romanTextAfter: {},
    romanTextBefore: {},
    shapeBulletIndent: {},
    shapeBullet: {},
    imageBulletIndent: {},
    imageBulletSource: {}
});
