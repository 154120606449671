const isEqual = require('lodash/isEqual');

const { getDefaultPlaceholderStroke } = require('../../utilities/dash');
const MultiItemTextbox = require('../MultiItemTextbox');

module.exports = {
    placeholderType: '',
    displayPlaceholder: false,

    initializePlaceholder() {
        this.selectPlaceholderHandler = this.selectPlaceholderHandler.bind(this);
        this.deselectPlaceholderHandler = this.deselectPlaceholderHandler.bind(this);
        this.on('selected', this.selectPlaceholderHandler);
        this.on('modified', this.selectPlaceholderHandler);
        this.on('deselected', this.deselectPlaceholderHandler);
        this.on('removed', this.deselectPlaceholderHandler);
        this.on('moving', this.movingPlaceholderHandler);
        this.on('moved', this.movedPlaceholderHandler);
    },

    selectPlaceholderHandler() {
        if (this.canvas) {
            this.canvas.fire('placeholder:select', { target: this });
        }
    },

    deselectPlaceholderHandler() {
        if (this.canvas) {
            this.canvas.fire('placeholder:deselect', { target: this });
        }
    },

    movingPlaceholderHandler() {
        if (this.canvas && !this.hasMoved) {
            this.canvas.fire('placeholder:moving', { target: this });
            this.hasMoved = true;
        }
    },

    movedPlaceholderHandler() {
        if (this.canvas) {
            this.canvas.fire('placeholder:moved', { target: this });
            this.hasMoved = false;
        }
    },

    initializePlaceholderStroke() {
        const shape = this.getShapeObject();
        if (this.shouldDisplayPlaceholderStroke()) {
            this.setOrigininalStrokeDefinition(shape);
            shape.set(getDefaultPlaceholderStroke());
        } else if (!this.isTextEmpty && shape.isDefaultPlaceholderStrokeUsed) {
            shape.set({
                ...this.originalStrokeDefinition,
                isDefaultPlaceholderStrokeUsed: false
            });
        }
    },

    shouldDisplayPlaceholderStroke() {
        let placeholderInLayout = false;

        if (this.canvas && this.canvas.canvasState) {
            placeholderInLayout = (
                this.canvas.getEditMode() === 'Layout' &&
                this.isPlaceholder()
            );
        }
        const placeholderWithoutText = !this.isShapeBorderVisible() &&
            !this.isImagePlaceholder() &&
            this.isPlaceholder() &&
            this.isTextEmpty;

        return this.displayPlaceholder &&
            (placeholderInLayout || placeholderWithoutText || this.isImagePlaceholderWithoutFill());
    },

    isPlaceholderStrokeDisplayed() {
        const defaultPlaceholderStroke = getDefaultPlaceholderStroke();
        const shape = this.getShapeObject();
        const isRenderingDefaultPlaceholderStroke = Object.entries(defaultPlaceholderStroke)
            .every(([key, value]) => isEqual(value, shape.get(key))) &&
            this.isPlaceholder() &&
            this.isTextEmpty;

        return this.shouldDisplayPlaceholderStroke() ||
            isRenderingDefaultPlaceholderStroke;
    },

    getPlaceholderTextItems(positions) {
        return new MultiItemTextbox({
            paragraphs: this.placeholderParagraphs,
            ...positions,
            textShapeStyle: this.style,
            isHidden: this.isHidden,
            visible: this.visible,
            flipX: this.flipX
        });
    },

    isDisplayedInLayoutEditMode() {
        return this.inLayout || this.isPlaceholder();
    },

    isPlaceholder() {
        return (this.placeholderType || '').length > 0;
    },

    originalStrokeDefinition: {},

    setOrigininalStrokeDefinition(shape) {
        this.originalStrokeDefinition = {
            strokeWidth: shape.strokeWidth,
            stroke: shape.stroke,
            strokeDashArray: shape.strokeDashArray
        };
    },

    render(ctx) {
        const shape = this.getShapeObject();
        if (this.shouldDisplayPlaceholderStroke()) {
            if (this.canvas.interactive && this.displayPlaceholder) {
                shape.set(getDefaultPlaceholderStroke());
            } else if (this.displayPlaceholder) {
                shape.set(this.originalStrokeDefinition);
            }
        } else if (this.displayPlaceholder === false && this.isTextEmpty) {
            shape.set(this.originalStrokeDefinition);
        }
        this.callSuper('render', ctx);
    }
};
