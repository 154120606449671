import { ERROR_LEVEL } from '#Constants/error';
import * as types from '#Constants/ActionTypes';

const initialState = {
    message: '',
    error: null,
    visible: false,
    level: null
};
export const currentError = (state = initialState, action) => {
    switch (action.type) {
        case types.ERROR_NETWORK:
        case types.ERROR_ALL:
            return {
                message: action.error.message || '',
                level: action.error.level || ERROR_LEVEL.FATAL,
                error: action.error,
                visible: true
            };
        case types.HIDE_ERROR:
            return {
                ...state,
                visible: false
            };
        default:
            return state;
    }
};
