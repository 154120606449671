import * as types from '#Constants/ActionTypes';

const changeToolsMenuView = section => ({
    type: types.CHANGE_TOOLS_MENU_VIEW,
    section
});

const changeInsertMenuVisibility = visibility => dispatch => {
    dispatch({
        type: types.CHANGE_INSERT_MENU_VISIBILITY,
        visibility
    });
};

const changePagesPreviewVisibility = visibility => ({
    type: types.CHANGE_PAGES_PREVIEW_VISIBILITY,
    visibility
});

const changeNavigationMenuView = index => ({
    type: types.CHANGE_NAVIGATION_MENU_VIEW,
    index
});

const changeToolbarMenuView = index => ({
    type: types.CHANGE_TOOLBAR_MENU_VIEW,
    index
});

export {
    changeToolsMenuView,
    changeInsertMenuVisibility,
    changePagesPreviewVisibility,
    changeNavigationMenuView,
    changeToolbarMenuView
};
