import { isNil } from 'lodash';
import Textbody from '../../Helpers/Text/TextBody';
import * as Fill from '../../Helpers/Fill';
import { getRGBAFromComponents } from '../../utilities/stringUtility';
import getPropertiesForDestructuring from '../../utilities/getPropertiesForDestructuring';

const updateGroupColorPreset = (colorPalette, group, { presets, scheme }) => {
    const updatedGroup = group.get('shapes').reduce((groupToUpdate, child, index) => {
        let updatedShape = child;
        if (child.get('type') === 'Group') {
            updatedShape = updateGroupColorPreset(colorPalette, child, { presets, scheme });
        } else if (child.get('type') === 'Table') {
            updatedShape = updateTableColorPreset(colorPalette, child, { presets, scheme });
        } else {
            updatedShape = updateSimpleShapeColorPreset(colorPalette, child, { presets, scheme });
        }
        return groupToUpdate.setIn(['shapes', index], updatedShape);
    }, group);
    return updatedGroup;
};

const updateTableColorPreset = (colorPalette, table, { presets, scheme }) => {
    let updatedTable = table;
    updatedTable = updatedTable.get('cells').reduce((tableToUpdate, cell, index) => {
        const updatedCell = updateCellColorPreset(colorPalette, cell, { presets, scheme });
        return tableToUpdate.setIn(['cells', index], updatedCell);
    }, updatedTable);

    updatedTable = updatedTable.get('borders').reduce((tableToUpdate, border, index) => {
        const updatedBorder = updateBorderColorPreset(colorPalette, border, { presets, scheme });
        return tableToUpdate.setIn(['borders', index], updatedBorder);
    }, updatedTable);

    return updatedTable;
};

const updateCellColorPreset = (colorPalette, cell, { presets, scheme }) => {
    let updatedCell = cell;
    updatedCell = updateShapeColorPresets(updatedCell, colorPalette, { presets, scheme });
    updatedCell = updatedCell.get('contents').reduce((cellToUpdate, content, index) => {
        const updatedContent = content
            .set('textBody', Textbody.updateColorPresets(content.get('textBody'), { presets, scheme }))
            .set('textBodyPlaceholder', Textbody.updateColorPresets(content.get('textBodyPlaceholder'), { presets, scheme }));
        return cellToUpdate.setIn(['contents', index], updatedContent);
    }, updatedCell);
    return updatedCell;
};

const updateBorderColorPreset = (colorPalette, border, { presets, scheme }) => updateStrokeColorPresets(
    border,
    colorPalette,
    { presets, scheme }
);

const updateShapeColorPresets = (shape, colorPalette, { presets, scheme }) => {
    let updatedShape = shape;
    const shapeFillPaths = [
        ['fill'],
        ['assignedStyles', 'shape', 'fill']
    ];
    shapeFillPaths.forEach(fillPath => {
        if (updatedShape.getIn(fillPath)) {
            updatedShape = updatedShape.setIn(
                fillPath,
                Fill.updateColorPresets(updatedShape.getIn(fillPath), { presets, scheme })
            );
            const rgba = Fill.extractRGBAFromFirstDecsriptor(updatedShape.getIn(fillPath), colorPalette);
            if (rgba && !isNil(rgba.red)) {
                const stringityRGBA = getRGBAFromComponents(rgba);
                updatedShape = updatedShape.setIn([...fillPath, 'color', 'value'], stringityRGBA);
            }
            if (rgba && !isNil(rgba.alpha)) {
                let opacity = 1;
                opacity = rgba.alpha;
                updatedShape = updatedShape.setIn([...fillPath, 'opacity'], opacity);
            }
        }
    });
    return updatedShape;
};

const updateStrokeColorPresets = (shape, colorPalette, { presets, scheme }) => {
    let updatedShape = shape;
    const strokeFillPaths = [
        ['stroke', 'fill'],
        ['assignedStyles', 'stroke', 'fill']
    ];
    strokeFillPaths.forEach(fillPath => {
        if (updatedShape.getIn(fillPath)) {
            updatedShape = updatedShape.setIn(
                fillPath,
                Fill.updateColorPresets(updatedShape.getIn(fillPath), { presets, scheme })
            );
            const rgba = Fill.extractRGBAFromFirstDecsriptor(updatedShape.getIn(fillPath), colorPalette);
            if (rgba && updatedShape.getIn([...fillPath.pop(), 'opacity'])) {
                rgba.alpha = updatedShape.getIn([...fillPath.pop(), 'opacity']);
            }
            if (rgba && !isNil(rgba.red)) {
                const stringityRGBA = getRGBAFromComponents(rgba);
                updatedShape = updatedShape.setIn([...fillPath, 'color', 'value'], stringityRGBA);
            }
            if (rgba && !isNil(rgba.alpha)) {
                let opacity = 1;
                opacity = rgba.alpha;
                updatedShape = updatedShape.setIn([...fillPath, 'opacity'], opacity);
            }
        }
    });
    return updatedShape;
};

const updateSimpleShapeColorPreset = (colorPalette, shape, { presets, scheme }) => {
    let updatedShape = shape;
    if (updatedShape.get('textBody')) {
        updatedShape = updatedShape
            .set('textBody', Textbody.updateColorPresets(updatedShape.get('textBody'), { presets, scheme }));
    }
    if (updatedShape.get('textBodyPlaceholder')) {
        updatedShape = updatedShape
            .set('textBodyPlaceholder', Textbody.updateColorPresets(updatedShape.get('textBodyPlaceholder'), { presets, scheme }));
    }
    updatedShape = updateShapeColorPresets(updatedShape, colorPalette, { presets, scheme });
    updatedShape = updateStrokeColorPresets(updatedShape, colorPalette, { presets, scheme });
    return updatedShape;
};

const updateColorPreset = (canvasState, command) => {
    const {
        presets,
        scheme
    } = getPropertiesForDestructuring(
        command,
        [
            'presets',
            'scheme'
        ]
    );

    if (!presets && !scheme) {
        return canvasState;
    }
    let updatedCanvasState = canvasState;
    const colorPalette = canvasState.get('colorPalette', []);

    const shapesKeys = ['shapes', 'layoutShapes'];
    shapesKeys.forEach(key => {
        updatedCanvasState = updatedCanvasState.get(key).reduce((currentCanvasState, shape, index) => {
            let updatedShape = shape;
            if (shape.get('type') === 'Group') {
                updatedShape = updateGroupColorPreset(colorPalette, shape, { presets, scheme });
            } else if (shape.get('type') === 'Table') {
                updatedShape = updateTableColorPreset(colorPalette, shape, { presets, scheme });
            } else {
                updatedShape = updateSimpleShapeColorPreset(colorPalette, shape, { presets, scheme });
            }
            return currentCanvasState.setIn([key, index], updatedShape);
        }, updatedCanvasState);
    });

    return updatedCanvasState;
};

export default updateColorPreset;
