import CanvasStateSelectors from '../../../Canvas/CanvasStateSelectors';
import {
    getShapePathWithLayer
} from '../../Helpers/helpers';
import getPropertiesForDestructuring from '../../utilities/getPropertiesForDestructuring';

const getHorizontalPaddingBetweenShapes = sortedShapes => sortedShapes
    .reduce(
        ({ total, lastReference }, shape, index) => ({
            total: index > 0 ? total + (shape.get('x') - (shape.get('width') / 2) - lastReference) : total,
            lastReference: shape.get('x') + (shape.get('width') / 2)
        }),
        { total: 0, lastReference: -Infinity }
    );

const getVerticalPaddingBetweenShapes = sortedShapes => sortedShapes
    .reduce(
        ({ total, lastReference }, shape, index) => ({
            total: index > 0 ? total + (shape.get('y') - (shape.get('height') / 2) - lastReference) : total,
            lastReference: shape.get('y') + (shape.get('height') / 2)
        }),
        { total: 0, lastReference: -Infinity }
    );

const getShapesNewXPositions = (sortedShapes, horizontalPadding) => {
    const leftmostShape = sortedShapes.first();
    const rightmostShape = sortedShapes.last();
    return sortedShapes.reduce(({ newPositions, lastReference }, currentShape, index) => {
        let newX = null;
        if (
            index > 0 &&
            currentShape.get('id') !== leftmostShape.get('id') &&
            currentShape.get('id') !== rightmostShape.get('id')
        ) {
            newX = (lastReference + horizontalPadding) +
            (currentShape.get('width') / 2);
            newPositions.push(newX);
        }
        return { newPositions, lastReference: (newX || currentShape.get('x')) + (currentShape.get('width') / 2) };
    }, { newPositions: [], lastReference: -Infinity });
};

const getShapesNewYPositions = (sortedShapes, verticalPadding) => {
    const topmostShape = sortedShapes.first();
    const bottommostShape = sortedShapes.last();
    return sortedShapes.reduce(({ newPositions, lastReference }, currentShape, index) => {
        let newY = null;
        if (
            index > 0 &&
            currentShape.get('id') !== topmostShape.get('id') &&
            currentShape.get('id') !== bottommostShape.get('id')
        ) {
            newY = (lastReference + verticalPadding) +
            (currentShape.get('height') / 2);
            newPositions.push(newY);
        }
        return { newPositions, lastReference: (newY || currentShape.get('y')) + (currentShape.get('height') / 2) };
    }, { newPositions: [], lastReference: -Infinity });
};

const distributeShapesHorizontally = canvasState => {
    const selectedShapes = CanvasStateSelectors.getSelectedCanvasItems(canvasState);

    const sortedShapes = selectedShapes.sort((shapeA, shapeB) => {
        if (shapeA.get('x') < shapeB.get('x')) {
            return -1;
        }
        if (shapeA.get('x') > shapeB.get('x')) {
            return 1;
        }
        return 0;
    });

    const numOfShapeToFillPadding = sortedShapes.size - 1;
    const { total: spaceBetween } = getHorizontalPaddingBetweenShapes(sortedShapes);
    const horizontalPadding = spaceBetween / numOfShapeToFillPadding;

    const { newPositions } = getShapesNewXPositions(sortedShapes, horizontalPadding);
    const updatedShapes = sortedShapes.map((shape, index) => {
        // the leftmost and rightmost shape don't move
        if (index === 0 || index === sortedShapes.size - 1) {
            return shape;
        }
        return shape.set('x', newPositions[index - 1]);
    });
    return updatedShapes;
};

const distributeShapesVertically = canvasState => {
    const selectedShapes = CanvasStateSelectors.getSelectedCanvasItems(canvasState);
    const sortedShapes = selectedShapes.sort((shapeA, shapeB) => {
        if (shapeA.get('y') < shapeB.get('y')) {
            return -1;
        }
        if (shapeA.get('y') > shapeB.get('y')) {
            return 1;
        }
        return 0;
    });
    const numOfShapeToFillPadding = sortedShapes.size - 1;
    const { total: spaceBetween } = getVerticalPaddingBetweenShapes(sortedShapes);
    const verticalPadding = spaceBetween / numOfShapeToFillPadding;
    const { newPositions } = getShapesNewYPositions(sortedShapes, verticalPadding);
    const updatedShapes = sortedShapes.map((shape, index) => {
        // the topmost and bottommost shape don't move
        if (index === 0 || index === sortedShapes.size - 1) {
            return shape;
        }
        return shape.set('y', newPositions[index - 1]);
    });
    return updatedShapes;
};

const distribute = (canvasState, command) => {
    const {
        direction
    } = getPropertiesForDestructuring(command, ['direction']);
    let updatedShapes;
    if (direction === 'vertical') {
        updatedShapes = distributeShapesVertically(canvasState);
    } else if (direction === 'horizontal') {
        updatedShapes = distributeShapesHorizontally(canvasState);
    } else {
        throw new Error(`${direction} is not handled`);
    }
    const shapePaths = updatedShapes.map(shape => getShapePathWithLayer(canvasState, shape.get('id')));
    let updatedCanvasState = canvasState;
    shapePaths.forEach((keyPath, index) => {
        updatedCanvasState = updatedCanvasState.setIn(keyPath, updatedShapes.get(index));
    });
    return updatedCanvasState;
};

export default distribute;
