class Command {
    constructor({ commandChar, relative, ...rest }) {
        if (!(typeof commandChar === 'string' && commandChar.length === 1)) {
            throw TypeError('The command char should be a string of length 1');
        }
        if (typeof relative !== 'boolean') {
            throw TypeError('Relative should be a Boolean');
        }
        Object.assign(this, {
            commandChar: relative ?
                commandChar.toLowerCase() :
                commandChar.toUpperCase(),
            ...rest
        });
    }

    static get regexp() {
        const maybeSpace = '\\s*';
        const flagRegexp = '[01]';
        const decimalRegexp = '-?\\d+(?:\\.\\d+)?';
        const positiveDecimalRegexp = '\\d+(?:\\.\\d+)?';
        return {
            flagCapture: `${maybeSpace}(${flagRegexp})`,
            decimalCapture: `${maybeSpace}(${decimalRegexp})`,
            positiveDecimalCapture: `${maybeSpace}(${positiveDecimalRegexp})`
        };
    }
}

module.exports = Command;
