import { Map } from 'immutable';
import { isArray } from 'lodash';

import equalsOrNullDeep from './equalsOrNullDeep';
import omitNullDeep from './omitNullDeep';

export default (object, ...sources) => {
    if (!Map.isMap(object)) {
        return Map();
    }

    let srcs = [...sources];
    let exceptions = srcs.pop();

    if (!isArray(exceptions)) {
        srcs = [...srcs, exceptions];
        exceptions = [];
    }
    return srcs.reduce(
        (intersection, nextSource) => omitNullDeep(
            equalsOrNullDeep(
                intersection,
                nextSource,
                exceptions
            )
        ),
        object
    );
};
