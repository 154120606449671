const getPropertiesForDestructuring = (map, keys) => keys
    .reduce((currentObject, key) => {
        const value = map.get(key);
        if (value !== undefined) {
            currentObject[key] = value;
        }
        return currentObject;
    }, {});

export default getPropertiesForDestructuring;
