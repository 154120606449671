import * as types from '#Constants/ActionTypes';

export const deck = (state = { pages: [] }, action) => {
    switch (action.type) {
        case types.LOAD_DECK:
        case types.CHANGE_DECK_PROPERTIES:
        case types.UPDATE_PAGE_IN_CURRENT_DECK:
        case types.REMOVE_PAGE_IN_CURRENT_DECK:
        case types.CREATE_DECK_SUCCESS:
        case types.FETCH_DECK_SUCCESS:
        case types.UPDATE_DECK_LAYOUTS:
            return action.deck;
        case types.UPDATE_PAGE_ID_IN_CURRENT_DECK:
            return {
                ...state,
                pages: state.pages.map(page => {
                    if (page.id !== action.previousId) {
                        return page;
                    }
                    return {
                        ...page,
                        ...action.newPage
                    };
                })
            };
        case types.INIT_VIEW:
            return action.deck;
        default:
            return state;
    }
};
