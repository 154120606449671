const Merge = require('./Merge');
const Split = require('./Split');
const Defaults = require('./Defaults');

module.exports = Base => class extends Merge(Split(Defaults(Base))) {
    getCellAtPosition(row, column) {
        return this.cells[this.cellGrid[row][column]];
    }

    getBottomEdgeOfCellList(cellIds = []) {
        return cellIds
            .reduce((bottomEdge, cellId) => {
                const cell = this.getCellById(cellId);
                if (cell.isPartOfBottomEdgeInList(cellIds)) {
                    return [
                        ...bottomEdge,
                        cell
                    ];
                }
                return bottomEdge;
            }, []);
    }

    getLeftEdgeOfCellList(cellIds = []) {
        return cellIds
            .reduce((leftEdge, cellId) => {
                const cell = this.getCellById(cellId);
                if (cell.isPartOfLeftEdgeInList(cellIds)) {
                    return [
                        ...leftEdge,
                        cell
                    ];
                }
                return leftEdge;
            }, []);
    }

    getRightEdgeOfCellList(cellIds = []) {
        return cellIds
            .reduce((rightEdge, cellId) => {
                const cell = this.getCellById(cellId);
                if (cell.isPartOfRightEdgeInList(cellIds)) {
                    return [
                        ...rightEdge,
                        cell
                    ];
                }
                return rightEdge;
            }, []);
    }

    getTopEdgeOfCellList(cellIds = []) {
        return cellIds
            .reduce((topEdge, cellId) => {
                const cell = this.getCellById(cellId);
                if (cell.isPartOfTopEdgeInList(cellIds)) {
                    return [
                        ...topEdge,
                        cell
                    ];
                }
                return topEdge;
            }, []);
    }
};
