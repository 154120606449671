const ColorValueDescriptor = require('../../Shape/ColorValueDescriptor');
const { findColorById, findIdByColor } = require('../../utilities/ColorPalette');
const { getRgba, isValid } = require('../../utilities/color');

exports.adaptColorValueAdapterToFabricColor = (
    colorValueDescriptor = {},
    colorPalette = []
) => {
    if (ColorValueDescriptor.isColorValueDescriptorValid(colorValueDescriptor)) {
        if (colorValueDescriptor.type === 'coded') {
            const color = findColorById(colorPalette, colorValueDescriptor.reference);
            if (color) {
                return getRgba(color);
            }
            if (colorValueDescriptor.value) {
                return getRgba(colorValueDescriptor.value);
            }
            throw new Error('Couldn\'t find color code in pallette and no fallback value where given.');
        }
        return getRgba(colorValueDescriptor.value);
    }
    throw new Error(`Invalid color value descriptor ${colorValueDescriptor}`);
};

exports.adaptFabricColorToColorValueDescriptor = (
    colorValue,
    colorPalette = []
) => {
    if (isValid(colorValue)) {
        const reference = findIdByColor(colorPalette, colorValue);
        if (reference !== undefined) {
            return {
                reference,
                type: 'coded',
                value: getRgba(colorValue)
            };
        }
        return {
            type: 'custom',
            value: getRgba(colorValue)
        };
    }
    throw new Error(`The color value ${colorValue} is not a valid color string`);
};
