import {
    getRun
} from '../Runs/Runs';
import {
    getParagraphsWithIndex,
    getParagraphIndexAtChar
} from './Paragraphs';

const getParagraphStartingInRun = (textBodyData, runIndex) => {
    const run = getRun(textBodyData, runIndex);
    return getParagraphsWithIndex(
        textBodyData,
        getParagraphIndexAtChar(textBodyData, run.get('startIndex')),
        getParagraphIndexAtChar(textBodyData, run.get('endIndex'))
    )
        .filter(paragraph => paragraph.get('startIndex') >= run.get('startIndex'));
};

export default getParagraphStartingInRun;
