import { getComputedFill } from '../../../../../CanvasState/Helpers/Fill';
import { getRenderStroke, getRenderStrokeWithDefaults } from '../../../../../CanvasState/Helpers/Style/stroke';
import {
    getColumnStartX,
    getColumnEndX,
    getRowStartY,
    getRowEndY
} from '../../../../../CanvasState/Helpers/Table/tableGridLogic';
import { dashArrayGenerator } from '../../../../../fabric-adapter/utilities/dash';
import { immutableDefaultStrokeAttributes as defaultStrokeAttributes } from '../../../../../Table/config/defaultStrokeAttributes.config';
import { removeDashIfEmpty, removeStrokeFillIfEmpty } from '../default/stroke';

const isVertical = border => border.get('side') === 'vertical';
const isHorizontal = border => border.get('side') === 'horizontal';

const getX1 = (border, table) => (getColumnStartX(border.get('column'), table) - (isVertical(border) ?
    getRenderStroke(border).get('width') / 2 :
    0
));

const getX2 = (border, table) => {
    if (isVertical(border)) {
        return getColumnStartX(border.get('column'), table) - (getRenderStroke(border).get('width') / 2);
    }
    return getColumnEndX(border.get('column'), table);
};

const getY1 = (border, table) => (getRowStartY(border.get('row'), table) - (isHorizontal(border) ?
    getRenderStroke(border).get('width') / 2 :
    0
));

const getY2 = (border, table) => {
    if (isHorizontal(border)) {
        return getRowStartY(border.get('row'), table) - (getRenderStroke(border).get('width') / 2);
    }
    return getRowEndY(border.get('row'), table);
};

const lineCapToFabric = value => {
    switch (value) {
        case 'round':
        case 'square':
            return value;
        case 'flat':
            return 'butt';
        default:
            return '';
    }
};

const isVisible = fill => fill.get('type') !== 'none';

const getStrokeProperties = (border, colorPalette) => {
    const stroke = getRenderStrokeWithDefaults(
        removeStrokeFillIfEmpty(
            removeDashIfEmpty(
                border
            )
        ),
        defaultStrokeAttributes,
        colorPalette
    );

    return {
        strokeAlign: stroke.get('align'),
        strokeLineCap: lineCapToFabric(stroke.get('cap')),
        strokeCompoundType: stroke.get('compoundType'),
        strokeDashArray: dashArrayGenerator(
            stroke.getIn(['dash', 'type']),
            stroke.get('width'),
            stroke.getIn(['dash', 'dashStops']) ?
                stroke.getIn(['dash', 'dashStops']) :
                undefined,
            stroke.get('cap')
        ),
        strokeDashName: stroke.getIn(['dash', 'type']),
        strokeFill: getComputedFill(border, stroke.get('fill')).toJS(),
        strokeHead: stroke.get('head').toJS(),
        strokeLineJoin: stroke.getIn(['join', 'type']),
        strokeMiterLimit: stroke.getIn(['join', 'miterLimit']),
        strokeTail: stroke.get('tail').toJS(),
        strokeWidth: isVisible(stroke.get('fill')) ? stroke.get('width') : 0
    };
};

const getBorders = (table, canvasState) => table
    .get('borders')
    .map(border => getBorder(border, table, canvasState)).toJS();

const getBorder = (border, table, canvasState) => ({
    id: border.get('id'),
    name: border.get('name'),
    side: border.get('side'),
    row: border.get('row'),
    column: border.get('column'),
    isHidden: false,
    isImported: false,
    isLocked: false,
    left: getX1(border, table),
    top: getY1(border, table),
    x1: getX1(border, table),
    x2: getX2(border, table),
    y1: getY1(border, table),
    y2: getY2(border, table),
    ...getStrokeProperties(border, canvasState.get('colorPalette')),
    style: border.get('style') || ''
});

export default getBorders;
