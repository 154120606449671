const doActionOnFillColor = require('../../../utilities/shape/doActionOnFillColor');
const ColorValueDescriptor = require('../../ColorValueDescriptor');

const strokeFillConstructor = {};

const addToConstructorList = (constructor, name) => {
    const capitalisedName = name.charAt(0).toUpperCase() + name.slice(1);
    strokeFillConstructor[name] = constructor;
    strokeFillConstructor[capitalisedName] = constructor;
};

module.exports = {
    get strokeFillConstructor() {
        return strokeFillConstructor;
    },

    addToConstructorList,

    fromJSON(jsonObject) {
        return new this(jsonObject.type);
    },

    updateValueOfColorDescriptors(fill, shape) {
        return this.doActionOnFillColor(
            fill,
            ColorValueDescriptor.updateValueOfDescriptor,
            shape
        );
    },

    normalizeAlpha(fill) {
        return this.doActionOnFillColor(fill, ColorValueDescriptor.normalizeAlpha);
    },

    doActionOnFillColor(fill, action, ...args) {
        return doActionOnFillColor(fill, 'type', action, ...args);
    }
};
