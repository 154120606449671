import Textbody from '../../../../../../CanvasState/Helpers/Text/TextBody';
import { fabricParagraphsToTextBody } from '../../default/textBody';
import { getContentPath } from './utils';

const getTextBody = (table, cell, content, canvasState) => {
    const oldShape = canvasState.getIn(getContentPath(table, cell, content, canvasState));
    return fabricParagraphsToTextBody(
        content.paragraphs,
        Textbody.addParagraphIfEmpty(
            oldShape.get('textBody'),
            oldShape.get('textBodyPlaceholder')
        ),
        canvasState.get('colorPalette')
    );
};

const getTextBodyPlaceholder = (table, cell, content, canvasState) => {
    const oldShape = canvasState.getIn(getContentPath(table, cell, content, canvasState));
    return fabricParagraphsToTextBody(content.placeholderParagraphs, oldShape.get('textBodyPlaceholder'), canvasState.get('colorPalette'));
};

export {
    getTextBody,
    getTextBodyPlaceholder
};
