import { fromJS } from 'immutable';

export default fromJS({
    id: {
        itemUpdatePropertyName: 'style'
    },
    name: {},
    basedOn: {},
    kind: {}
});
