import { fetchTopics } from '#Actions/plan/topics/topics';
import client from '#Utilities/graphql';
import * as types from '#Constants/ActionTypes';

const fetchStoryboardsList = objective => dispatch => {
    dispatch({ type: types.FETCH_STORYBOARDS_LIST });

    return client.fetchStoryboards(objective)
        .then(resStoryboards => {
            const storyboards = resStoryboards.sort((a, b) => (a.sequence - b.sequence));

            dispatch({
                type: types.SET_STORYBOARDS_LIST,
                storyboards
            });
        })
        .catch(err => console.warn(err));
};

const setFilteredStoryboardsList = filteredStoryboards => ({
    type: types.SET_FILTERED_STORYBOARDS_LIST,
    filteredStoryboards
});

const removeStoryboardsList = () => ({
    type: types.REMOVE_STORYBOARDS_LIST
});

// TODO: make another action resetSelectedStoryboard.
const setSelectedStoryboard = selectedStoryboard => dispatch => {
    const topicPreparationPromise = selectedStoryboard.id ?
        dispatch(fetchTopics(selectedStoryboard.id)) :
        Promise.resolve(dispatch({ type: types.REMOVE_TOPICS_LIST }));
    return topicPreparationPromise.then(() => {
        dispatch({
            type: types.SET_SELECTED_STORYBOARD,
            selectedStoryboard
        });
    });
};

const setInitialStoryboard = initialStoryboard => ({
    type: types.SET_INITIAL_STORYBOARD,
    initialStoryboard
});

export {
    fetchStoryboardsList,
    setFilteredStoryboardsList,
    removeStoryboardsList,
    setSelectedStoryboard,
    setInitialStoryboard
};
