const Bounds = require('./Bounds');

class ChevronBounds extends Bounds {
    get dimensions() {
        const boundsWidth = this._attributes.isFull ?
            this.shapeWidth - this._attributes.tipWidth :
            this.shapeWidth - (2 * this._attributes.tipWidth);
        return {
            width: Math.max(boundsWidth, Bounds.MIN_BOUNDS_SIZE),
            height: this.shapeHeight
        };
    }

    get x() {
        return this.position.x;
    }
}

module.exports = ChevronBounds;
