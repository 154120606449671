const get = require('lodash/get');
const complementAgainstSources = require('../../../utilities/complementAgainstSources');
const defaultsDeepWithExceptions = require('../../../utilities/defaultsDeepWithExceptions');
const ParagraphStyle = require('../../Styles/ParagraphStyle');
const TextBodyData = require('../TextBodyData');

module.exports = Base => class extends Base {
    constructor(shape, options) {
        super(shape, options);
        this.paragraphStyle = get(options, 'paragraphStyle');
        this.assignedParagraphStyle = get(options, 'assignedParagraphStyle');
    }

    getRenderParagraphStyle() {
        return defaultsDeepWithExceptions(
            this.paragraphStyle.toJSON(),
            this.assignedParagraphStyle.toJSON(),
            this.defaultParagraphStyle.toJSON(),
            ParagraphStyle.atomicProperties
        );
    }

    setComplementParagraphStyleAsParagraphStyle() {
        this.paragraphStyle = ParagraphStyle.fromJSON(complementAgainstSources(
            TextBodyData.getDefaultParagraphStyle(this.textBodyData),
            this.defaultParagraphStyle.toJSON(),
            this.assignedParagraphStyle.toJSON(),
            ParagraphStyle.atomicProperties
        ));
        this.setParagraphStyleDefaultOnData();
    }

    complementParagraphStyleAgainstDefault(paragraphStyle = {}) {
        return complementAgainstSources(
            paragraphStyle,
            this.defaultParagraphStyle.toJSON(),
            this.assignedParagraphStyle.toJSON(),
            paragraphStyle.isDefault ? {} : this.paragraphStyle.toJSON(),
            ParagraphStyle.atomicProperties
        );
    }

    setParagraphStyleDefaultOnData(shape) {
        const style = shape?.textBody?.getDefaultParagraphStyle() ?
            shape?.textBody?.getDefaultParagraphStyle() :
            this.getRenderParagraphStyle();
        this.textBodyData = TextBodyData.setDefaultParagraphStyle(
            this.textBodyData,
            style
        );
    }

    copyAssignedParagraphStyle(original) {
        this.assignedParagraphStyle = original.assignedParagraphStyle;
    }

    get assignedParagraphStyle() {
        return this._assignedParagraphStyle;
    }

    set assignedParagraphStyle(paragraphStyle = ParagraphStyle.fromJSON({})) {
        if (!(paragraphStyle instanceof ParagraphStyle)) {
            throw new Error('Can\'t set invalid textbody paragraphStyle on textbody');
        } else {
            this._assignedParagraphStyle = paragraphStyle;
        }
    }

    get defaultParagraphStyle() {
        return ParagraphStyle.fromJSON(this.defaults.DEFAULT_PARAGRAPHSTYLE);
    }

    get paragraphStyle() {
        return this._paragraphStyle;
    }

    set paragraphStyle(paragraphStyle = ParagraphStyle.fromJSON({})) {
        if (!(paragraphStyle instanceof ParagraphStyle) && paragraphStyle !== undefined) {
            throw new Error('Can\'t set invalid textbody paragraphStyle on textbody');
        } else {
            this._paragraphStyle = paragraphStyle;
        }
    }

    get align() {
        return this.paragraphStyle.align;
    }

    set align(align) {
        this.paragraphStyle.align = align;
    }

    get bullet() {
        return this.paragraphStyle.bullet;
    }

    set bullet(bullet) {
        this.paragraphStyle.bullet = bullet;
    }

    get indent() {
        return this.paragraphStyle.indent;
    }

    set indent(indent) {
        this.paragraphStyle.indent = indent;
    }

    get lineSpacing() {
        return this.paragraphStyle.lineSpacing;
    }

    set lineSpacing(textDirection) {
        this.paragraphStyle.lineSpacing = textDirection;
    }

    get padding() {
        return this.paragraphStyle.padding;
    }

    set padding(padding) {
        this.paragraphStyle.padding = padding;
    }
};
