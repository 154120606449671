const get = require('lodash/get');
const update = require('lodash/update');

module.exports = {
    getColorStringFromFill(canvasState, fill) {
        if (fill && fill.type) {
            switch (fill.type) {
                case 'color':
                    return this.getColorStringFromColorValueDescriptor(canvasState, fill.color);
                case 'gradient':
                    if (fill.gradient && fill.gradient.stops.length > 0) {
                        return this.getColorStringFromColorValueDescriptor(
                            canvasState,
                            fill.gradient.stops[0].color
                        );
                    }
                    break;
                case 'pattern':
                    if (
                        fill.pattern &&
                        fill.pattern.colors &&
                        fill.pattern.colors.foreground
                    ) {
                        return this.getColorStringFromColorValueDescriptor(
                            canvasState,
                            fill.pattern.colors.foreground
                        );
                    }
                    break;
                default:
                    return '';
            }
        }
        return '';
    },

    copyPresetOnFill(newFill = {}, oldFill = {}) {
        if (get(newFill, 'type', 'newFillType') === get(oldFill, 'type', 'oldType')) {
            switch (newFill.type) {
                case 'color':
                    return {
                        ...newFill,
                        color: this.copyPresetOnDescriptor(newFill.color, oldFill.color)
                    };
                case 'gradient':
                    return update(newFill, 'gradient.stops', stops => stops.map((stop, stopIndex) => ({
                        ...stop,
                        color: this.copyPresetOnDescriptor(
                            stop.color,
                            get(oldFill, `gradient.stops.${stopIndex}.color`)
                        )
                    })));
                case 'pattern':
                    return update(newFill, 'pattern.colors', colors => ({
                        foreground: this.copyPresetOnDescriptor(
                            colors.foreground,
                            get(oldFill, 'pattern.colors.foreground')
                        ),
                        background: this.copyPresetOnDescriptor(
                            colors.background,
                            get(oldFill, 'pattern.colors.background')
                        )
                    }));
                default:
                    return newFill;
            }
        }
        return newFill;
    },

    getSolidFillFromColorString(canvasState, colorString = '') {
        const colorValueDescriptor = this.getColorValueDescriptorForColorString(
            canvasState,
            colorString
        );
        if (colorValueDescriptor) {
            return {
                type: 'color',
                color: colorValueDescriptor
            };
        }
        return null;
    },

    getSolidFillFromColorValueDescriptor(canvasState, colorValueDescriptor) {
        return {
            type: 'color',
            color: colorValueDescriptor
        };
    }
};
