import * as types from '#Constants/ActionTypes';

const decks = (state = null, action) => {
    switch (action.type) {
        case types.GET_DECKS:
            return state;
        case types.SET_DECKS:
            return action.decks;
        case types.RESET_DECKS_LIST:
            return null;
        default:
            return state;
    }
};

export { decks };
