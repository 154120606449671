import { List, Map, fromJS } from 'immutable';
import { isEqual, isNil } from 'lodash';
import {
    getTextLength,
    getText,
    isEmpty
} from '../TextBodyProperties/TextBodyProperties';
import {
    getRunsAt,
    getRunDynamicType,
    splitRunsAt,
    mergeRunsWithNewLineChar,
    removeRuns
} from './Runs';
import { DEFAULT_RUNSTYLE } from '../Config/defaultStyles';
import TEXTBODY_PROPERTIES from '../Config/textBodyRecordProperties';
import sortByStartIndexes from '../Utils/sortByStartIndexes';
import removeUnusedStyles from '../Utils/removeUnusedStyles';
import cleanupStyles from '../Utils/cleanupStyles';

const hasDefaultRunStyle = textBodyData => textBodyData.get('runStyles').findIndex(style => style.get('isDefault') === true) !== -1;

const getDefaultRunStyleIndex = textBodyData => {
    const index = textBodyData
        .get(TEXTBODY_PROPERTIES.RUN_STYLES)
        .findIndex(style => style.get('isDefault'));
    if (index === -1) {
        return textBodyData.get(TEXTBODY_PROPERTIES.RUN_STYLES).size;
    }
    return index;
};

const getDefaultRunStyle = textBodyData => (
    textBodyData ? textBodyData.getIn([
        TEXTBODY_PROPERTIES.RUN_STYLES,
        getDefaultRunStyleIndex(textBodyData)
    ], Map()) : Map()
);

const getAssignedRunStyle = textBody => textBody.getIn([TEXTBODY_PROPERTIES.ASSIGNED_STYLES, 'run'], Map({}));

const getRunStyle = (
    textBodyData,
    index,
    withDefault = false
) => {
    const style = textBodyData.getIn([TEXTBODY_PROPERTIES.RUN_STYLES, index], Map({}));
    return withDefault ?
        getAssignedRunStyle(textBodyData).mergeDeep(getDefaultRunStyle(textBodyData)).mergeDeep(style) :
        style;
};

const fillHolesInRunsArray = (textBodyData, runs, withDefault) => {
    if (runs.size === 0) {
        return runs;
    }

    const sortedRuns = sortByStartIndexes(runs);

    let filledRuns = sortedRuns
        .slice(1)
        .reduce((pastState, current) => {
            let acc = pastState;
            const previous = acc.last();
            if (current.get('startIndex') - 1 > previous.get('endIndex')) {
                const startIndex = previous.get('endIndex') + 1;
                const endIndex = current.get('startIndex') - 1;
                acc = acc.push(Map({
                    startIndex,
                    endIndex,
                    text: getText(textBodyData, startIndex, endIndex),
                    style: null
                }));
            }
            return acc.push(current);
        }, sortedRuns.slice(0, 1));

    const firstRun = filledRuns.first();
    if (firstRun.get('startIndex') > 0) {
        const startIndex = 0;
        const endIndex = firstRun.get('startIndex') - 1;
        filledRuns = filledRuns.unshift(Map({
            startIndex: 0,
            endIndex: firstRun.get('startIndex') - 1,
            text: getText(textBodyData, startIndex, endIndex),
            style: withDefault ? getDefaultRunStyle(textBodyData) : null
        }));
    }

    const lastRun = filledRuns.last();
    if (lastRun.get('endIndex') < getTextLength(textBodyData)) {
        const startIndex = lastRun.get('endIndex') + 1;
        const endIndex = getTextLength(textBodyData) - 1;
        filledRuns = filledRuns.push(Map({
            startIndex,
            endIndex,
            text: getText(textBodyData, startIndex, endIndex),
            style: withDefault ? getDefaultRunStyle(textBodyData) : null
        }));
    }

    return filledRuns;
};

const getTextWithStyle = (
    textBodyData,
    startIndex = 0,
    endIndex = getTextLength(textBodyData) - 1,
    withDefault
) => {
    let runs = sortByStartIndexes(textBodyData.get(TEXTBODY_PROPERTIES.RUNS))
        .map(run => (Map({
            startIndex: Math.max(run.get('startIndex'), startIndex),
            endIndex: Math.min(run.get('endIndex'), endIndex),
            text: getText(textBodyData, run.get('startIndex'), run.get('endIndex')),
            style: getRunStyle(textBodyData, run.get('style'), withDefault)
        })));
    if (runs.size) {
        runs = fillHolesInRunsArray(textBodyData, runs, withDefault);
    } else {
        runs = List([Map({
            startIndex,
            endIndex,
            text: getText(textBodyData, startIndex, endIndex),
            style: null
        })]);
    }
    return runs
        .filter(run => run.get('endIndex') >= startIndex && run.get('startIndex') <= endIndex);
};

const getRunStylesAt = (textBodyData, startIndex, endIndex, withDefault) => getRunsAt(
    textBodyData,
    startIndex,
    endIndex
)
    .map(run => {
        if (run.get('style') > textBodyData.get(TEXTBODY_PROPERTIES.RUN_STYLES).size) {
            throw new RangeError(
                `cannot get style ${
                    run.get('style')
                } run style: ${
                    textBodyData.get(TEXTBODY_PROPERTIES.RUN_STYLES).toJS()
                }`
            );
        }
        const style = getRunStyle(textBodyData, run.get('style'), withDefault);
        return run.set('style', style);
    });

const getRunStyles = (textBodyData, withDefault) => (
    getRunStylesAt(textBodyData, 0, getTextLength(textBodyData), withDefault)
        .map(run => run.get('style'))
);

const getDefaultRunStyleFromFirstRun = textBodyData => {
    let updatedTextBody = textBodyData;
    if (isEmpty(textBodyData)) {
        updatedTextBody = getDefaultRunStyle(updatedTextBody);
    } else {
        updatedTextBody = getRunStylesAt(textBodyData, 0, 0, true).get(0, Map({})).get('style');
    }
    return updatedTextBody;
};

const getFonts = textBodyData => {
    const fontSet = new Set([
        ...textBodyData.get(TEXTBODY_PROPERTIES.RUN_STYLES)
            .filter(runStyle => runStyle.getIn(['font', 'family']) !== undefined)
            .map(runStyle => runStyle.get('font').get('family'))
    ]);
    const defaultRunStyle = getDefaultRunStyle(textBodyData);
    if (defaultRunStyle.getIn(['font', 'family'])) {
        return fontSet.add(
            defaultRunStyle.getIn(['font', 'family'])
        );
    }
    return fontSet;
};

const addRunStyle = (textBodyData, runStyle) => textBodyData
    .update(TEXTBODY_PROPERTIES.RUN_STYLES, runStyles => runStyles.push(
        Map.isMap(runStyle) ? runStyle : fromJS(runStyle)
    ));

const getRunStylesCount = textBodyData => textBodyData.get(TEXTBODY_PROPERTIES.RUN_STYLES).size;

const getRunStyleIndex = (textBodyData, runStyle) => textBodyData
    .get(TEXTBODY_PROPERTIES.RUN_STYLES)
    .findIndex(currentRunStyle => currentRunStyle.equals(runStyle));

const setRunStyleIndexToRun = (textBodyData, runIndex, runStyleIndex) => mergeRunStyle(
    textBodyData,
    runIndex,
    Map({
        style: runStyleIndex
    })
);

const mergeRunStyle = (textBodyData, index, runUpdate) => textBodyData.updateIn(
    [TEXTBODY_PROPERTIES.RUNS, index],
    originalRun => originalRun.merge(runUpdate)
);

const areRunStylesOverlapping = (run1, run2) => run1.get('endIndex') + 1 === run2.get('startIndex') &&
    run1.get('style') === run2.get('style') &&
    getRunDynamicType(run1) === 'none' &&
    getRunDynamicType(run2) === 'none';

const mergeContiguousRunsWithSameStyle = textBodyData => {
    const sortedRuns = sortByStartIndexes(textBodyData.get(TEXTBODY_PROPERTIES.RUNS));
    const mergedRuns = sortedRuns.slice(1).reduce((acc, currentRun) => {
        const previousRun = acc.last();
        if (areRunStylesOverlapping(previousRun, currentRun)) {
            return acc.set(-1, previousRun.set('endIndex', currentRun.get('endIndex')));
        }
        return acc.push(currentRun);
    }, sortedRuns.slice(0, 1));
    return textBodyData.set(TEXTBODY_PROPERTIES.RUNS, mergedRuns);
};

const removeUnusedRunStyles = textBodyData => removeUnusedStyles(
    textBodyData,
    TEXTBODY_PROPERTIES.RUN_STYLES,
    TEXTBODY_PROPERTIES.RUNS
);

const setDefaultRunStyle = (textBodyData, newDefault) => textBodyData
    .update(
        TEXTBODY_PROPERTIES.RUN_STYLES,
        (runStyles = Map()) => {
            let mutableRunStyles = runStyles;
            const defaultIndex = mutableRunStyles
                .findIndex(style => style.get('isDefault') === true);
            const defaultStyle = defaultIndex === -1 ? Map({ isDefault: true }) : mutableRunStyles.get(defaultIndex);
            let updatedDefault = defaultStyle.mergeDeep(newDefault);
            if (newDefault && newDefault.get('color')) {
                if (!newDefault.getIn(['color', 'preset'])) {
                    updatedDefault = updatedDefault.deleteIn(['color', 'preset']);
                }
            }
            mutableRunStyles = mutableRunStyles.map((style, index) => {
                if (index === defaultIndex) {
                    return updatedDefault;
                }
                const updatedStyle = style.filter((value, key) => {
                    if (isEqual(style.get(key), updatedDefault.get(key))) {
                        return false;
                    }
                    return true;
                });
                return updatedStyle;
            });
            if (defaultIndex === -1) {
                mutableRunStyles = mutableRunStyles.unshift(updatedDefault);
            }
            return mutableRunStyles;
        }
    );

const setStyle = (
    textBodyData,
    style,
    startIndex = 0,
    endIndex = getTextLength(textBodyData) - 1
) => {
    if (endIndex < startIndex) return textBodyData;
    if (startIndex < 0) {
        throw new Error('Cannot set style on unexistant text');
    }
    let updatedTextBodyData = splitRunsAt(
        textBodyData,
        startIndex,
        Math.min(endIndex, getTextLength(textBodyData) - 1)
    );

    if (!isNil(style)) {
        let runStyleIndex = getRunStyleIndex(updatedTextBodyData, style);
        if (runStyleIndex === -1) {
            updatedTextBodyData = addRunStyle(updatedTextBodyData, style);
            runStyleIndex = getRunStylesCount(updatedTextBodyData) - 1;
        }
        updatedTextBodyData = updatedTextBodyData.update(
            TEXTBODY_PROPERTIES.RUNS,
            runs => runs.map(run => {
                if (run.get('endIndex') >= startIndex && run.get('startIndex') <= endIndex) {
                    return run.set('style', runStyleIndex);
                }
                return run;
            })
        );
        updatedTextBodyData = mergeRunsWithNewLineChar(updatedTextBodyData);
        updatedTextBodyData = mergeContiguousRunsWithSameStyle(
            updatedTextBodyData
        );
    } else {
        const runsInRange = getRunsAt(updatedTextBodyData, startIndex, endIndex);
        updatedTextBodyData = removeRuns(
            updatedTextBodyData,
            ...runsInRange.map(run => run.get('index'))
        );
    }
    return removeUnusedRunStyles(updatedTextBodyData);
};

const clearStyle = (
    textBodyData,
    startIndex = 0,
    endIndex = getTextLength(textBodyData) - 1
) => setStyle(textBodyData, null, startIndex, endIndex);

const cleanUpRunStyles = textBody => {
    let updatedTextBody = textBody;
    const fullDefaultStyle = textBody
        .getIn([TEXTBODY_PROPERTIES.ASSIGNED_STYLES, 'run'])
        .mergeDeep(DEFAULT_RUNSTYLE)
        .mergeDeep(getDefaultRunStyle(textBody));
    const updatedRunStyles = List([
        getDefaultRunStyle(textBody),
        ...cleanupStyles(fullDefaultStyle, updatedTextBody.get(TEXTBODY_PROPERTIES.RUN_STYLES))
    ]);
    updatedTextBody = updatedTextBody.set(TEXTBODY_PROPERTIES.RUN_STYLES, updatedRunStyles);
    return updatedTextBody;
};

const getRenderRunStyle = textBody => {
    const assignedRunStyle = textBody.get(TEXTBODY_PROPERTIES.ASSIGNED_STYLES).get('run');
    const defaultApplicationRunStyle = DEFAULT_RUNSTYLE;
    const defaultStyle = getDefaultRunStyle(textBody);
    return assignedRunStyle.mergeDeep(defaultApplicationRunStyle).mergeDeep(defaultStyle).toJS();
};

export {
    addRunStyle,
    getRunStylesCount,
    getRunStyleIndex,
    setRunStyleIndexToRun,
    getTextWithStyle,
    hasDefaultRunStyle,
    getDefaultRunStyle,
    getRunStyle,
    fillHolesInRunsArray,
    getDefaultRunStyleFromFirstRun,
    getRunStylesAt,
    getRunStyles,
    getFonts,
    mergeContiguousRunsWithSameStyle,
    removeUnusedRunStyles,
    setDefaultRunStyle,
    mergeRunStyle,
    setStyle,
    cleanUpRunStyles,
    getRenderRunStyle,
    clearStyle
};
