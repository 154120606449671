const { sameWithMultiPath } = require('../utils');
const { loadTextBodyDefaults } = require('../../Shape/AbstractShape/mixins/styleProvider');

exports.computeVerticalAlignFromTextBodies = textBodies => sameWithMultiPath(
    textBodies,
    ['verticalAlign', 'assignedStyles.textBody.verticalAlign'],
    loadTextBodyDefaults(textBodies.getIn([0, 'parentType']))
        .DEFAULT_TEXT_BODY_STYLE.verticalAlign,
    { skipUndefined: false }
);
