import { removeShape, updateSelection } from '../../Helpers/helpers';
import CanvasStateSelectors from '../../../Canvas/CanvasStateSelectors';
import {
    isCellCoveringWholeAxis,
    removeRowsForCellIds,
    removeColumnsForCellIds
} from '../../Helpers/Table/table';

const removeLine = (canvasState, command) => {
    const axis = command.get('axis');
    if (isCellCoveringWholeAxis(canvasState, axis)) {
        const table = CanvasStateSelectors.getSelectedCanvasItems(canvasState).get(0);
        let updatedCanvasState = removeShape(canvasState, table.get('id'));
        updatedCanvasState = updateSelection(updatedCanvasState);
        updatedCanvasState = updatedCanvasState.set('contextualSelection', null);
        return updatedCanvasState;
    }

    return axis === 'horizontal' ? removeRowsForCellIds(canvasState) : removeColumnsForCellIds(canvasState);
};

export default removeLine;
