const Shape = require('../Shape/Shape');

const { applyPageContentOffset } = require('../fabric-adapter/utilities/DecksignFabricCanvasUtilities');

const {
    convertPropertyToNewShapeProperty,
    convertPropertiesToShapeProperties
} = require('./convertPropertiesToShapeProperties');

const isNewShapeType = object => (
    object &&
    Object.keys(Shape.shapeConstructors).indexOf(object.constructor.name) !== -1
);

const isTable = object => (object && object.constructor.name === 'Table');

const offsetBoundingBox = (boundingBox, x, y) => ({
    bottom: boundingBox.bottom + y,
    left: boundingBox.left + x,
    right: boundingBox.right + x,
    top: boundingBox.top + y
});

const getCanvasItemsAnchorByid = (items, id, anchorName) => items
    .reduce((foundItem, currentIten) => {
        if (foundItem) {
            return foundItem;
        } if ((currentIten.shapes || []).length) {
            const anchor = getCanvasItemsAnchorByid(currentIten.shapes, id, anchorName);
            if (anchor) {
                return {
                    x: anchor.x + currentIten.x,
                    y: anchor.y + currentIten.y,
                    expectedAngles: anchor.expectedAngles,
                    boundingBox: offsetBoundingBox(anchor.boundingBox, currentIten.x, currentIten.y)
                };
            }
            return null;
        } if (currentIten.id === id) {
            const anchor = currentIten.anchors[anchorName];
            return {
                x: anchor.x,
                y: anchor.y,
                expectedAngles: [...anchor.expectedAngles],
                boundingBox: currentIten.boundingBox
            };
        }
        return null;
    }, null);

const getCanvasItemsAnchorByConnection = (items, connection) => getCanvasItemsAnchorByid(
    items,
    connection.id,
    connection.name
);

const convertCanvasItemsToFabricObjects = (canvasItemsToConvert, fabric) => {
    const connectedidToConnectorid = {};
    return Promise.all(canvasItemsToConvert.map(item => new Promise(resolve => {
        if (isTable(item)) {
            resolve(item.toFabric({ fabric }));
        } else if (isNewShapeType(item)) {
            const requiredAnchors = {};
            if (item.attachedAnchors) {
                if (item.attachedAnchors.start) {
                    requiredAnchors[
                        item.attachedAnchors.start.id
                    ] = getCanvasItemsAnchorByConnection(
                        canvasItemsToConvert,
                        item.attachedAnchors.start
                    );
                    if (!connectedidToConnectorid[item.attachedAnchors.start.id]) {
                        connectedidToConnectorid[item.attachedAnchors.start.id] = [];
                    }
                    connectedidToConnectorid[item.attachedAnchors.start.id].push(item.id);
                }
                if (item.attachedAnchors.end) {
                    requiredAnchors[item.attachedAnchors.end.id] = getCanvasItemsAnchorByConnection(
                        canvasItemsToConvert,
                        item.attachedAnchors.end
                    );
                    if (!connectedidToConnectorid[item.attachedAnchors.end.id]) {
                        connectedidToConnectorid[item.attachedAnchors.end.id] = [];
                    }
                    connectedidToConnectorid[item.attachedAnchors.end.id].push(item.id);
                }
            }
            resolve(item.fromShapeToFabric({ requiredAnchors, fabric }));
        } else {
            resolve(item);
        }
    })));
};

const offsetAndApplyAssetUrlOnFabricObjects = (fabricItems, options) => fabricItems
    .map(original => {
        let item = { ...original };
        if (options.offset) {
            item = applyPageContentOffset(item, options.offset);
        }
        if (options.displayPlaceholder) {
            item.displayPlaceholder = true;
        }
        if (item.fill && item.fill.type === 'imageSource') {
            if (options.assetsUrl && !item.fill.imageSource.startsWith(options.assetsUrl)) {
                item.fill.imageSource = `${options.assetsUrl}/${item.fill.imageSource}`;
            }
        }
        if (item.shapeType === 'Group') {
            const optionsForObjectsInGroup = { ...options };
            optionsForObjectsInGroup.offset = null;
            item.objects = offsetAndApplyAssetUrlOnFabricObjects(
                item.objects,
                optionsForObjectsInGroup
            );
        }
        return item;
    });

const getCanvasObjectsByIdsFromArray = (selectedObjectsIds, canvasItems) => {
    let activeObjects = [];
    canvasItems.forEach(object => {
        if (selectedObjectsIds.indexOf(object.id) !== -1) {
            activeObjects.push(object);
        } else if (object.shapeType === 'Group') {
            activeObjects = activeObjects.concat(
                getCanvasObjectsByIdsFromArray(
                    selectedObjectsIds,
                    object._objects
                )
            );
        }
    });
    return activeObjects;
};

const replaceCanvasObjectInArray = (updatedCanvasObject, CanvasObjectArray) => CanvasObjectArray
    .map(shape => {
        if (updatedCanvasObject && shape.id === updatedCanvasObject.id) {
            return updatedCanvasObject;
        } if (updatedCanvasObject && shape.shapes && shape.shapes.length !== 0) {
            shape.shapes = replaceCanvasObjectInArray(
                updatedCanvasObject,
                CanvasObjectArray
            );
            return shape;
        }
        return shape;
    });

const inverseItemsOrder = items => items.map(item => {
    if (item.type === 'group') {
        item.objects = inverseItemsOrder(item.objects);
    }
    return item;
}).reverse();

const getRoot = (items, selectedItem, pathToRoot = [], currPath = []) => {
    const currentItem = items.find(item => item.id === selectedItem[0]);
    if (currentItem) {
        currPath.push(currentItem.id);
        pathToRoot.push(currPath);
        return pathToRoot;
    }
    items.forEach(item => {
        getRoot(item.shapes || [], selectedItem, pathToRoot, [...currPath, item.id]);
    });
    return pathToRoot;
};

const getCanvasItemRoot = (canvasItems, selection) => {
    let listToRoot = getRoot(canvasItems, selection);
    listToRoot = listToRoot.flat();
    const root = listToRoot[0];
    return root;
};

module.exports = {
    convertPropertyToNewShapeProperty,
    convertPropertiesToShapeProperties,
    isNewShapeType,
    convertCanvasItemsToFabricObjects,
    offsetAndApplyAssetUrlOnFabricObjects,
    getAvgScale(target) {
        return (target.scaleX + target.scaleY) / 2;
    },
    getCanvasObjectsByIdsFromArray,
    replaceCanvasObjectInArray,
    inverseItemsOrder,
    getCanvasItemRoot
};
