const DEFAULT_RUNSTYLE = {
    characterSpacing: 0,
    color: {
        type: 'custom',
        value: 'rgba(0, 0, 0, 1)'
    },
    font: {
        case: null,
        family: 'Lato',
        size: 16,
        style: 'normal',
        weight: 'normal'
    },
    hyperlink: '',
    linethrough: false,
    opacity: 1,
    overline: false,
    subscript: false,
    superscript: false,
    textTransform: '',
    underline: false
};

const DEFAULT_PARAGRAPHSTYLE = {
    align: 'center',
    indent: 0,
    lineSpacing: 1.2,
    padding: {
        bottom: 0,
        left: 0,
        right: 0,
        top: 0
    }
};

const DEFAULT_TEXT_BODY_STYLE = {
    autoFitText: false,
    autoFitShape: false,
    margins: {
        bottom: 0,
        left: 0,
        right: 0,
        top: 0
    },
    verticalAlign: 'middle',
    textDirection: 'horizontal'
};

module.exports = {
    DEFAULT_RUNSTYLE,
    DEFAULT_PARAGRAPHSTYLE,
    DEFAULT_TEXT_BODY_STYLE
};
