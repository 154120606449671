import { getShapePathById } from '../../../../../Canvas/CanvasStateSelectors';
import { setComplementStrokeAsItemStrokeStyle } from '../../../../../CanvasState/Helpers/Style/stroke';

const getStroke = (fabricShape, canvasState) => {
    const oldShape = canvasState.getIn(getShapePathById(canvasState, fabricShape.id));
    const { stroke } = fabricShape;

    if (!stroke) {
        return undefined;
    }

    return setComplementStrokeAsItemStrokeStyle(
        oldShape
    );
};

export default getStroke;
