const { sameParagraphStyleInTextBodies } = require('../utils');

exports.computeLineSpacingFromTextBodies = (textBodies, textSelection) => sameParagraphStyleInTextBodies(
    textBodies,
    'lineSpacing',
    '',
    {
        textSelection
    }
);
