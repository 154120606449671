import { Map } from 'immutable';

import defaultsDeepWithExceptions from '../../utilities/defaultsDeepWithExceptions';
import textBodyStyles from '../Text/Config/textBodyStyles';
import complementAgainstSources from '../../utilities/complementAgainstSources';

const { DEFAULT_PARAGRAPHSTYLE } = require('../Text/Config/defaultStyles');
const { getDefaultParagraphStyle, setDefaultParagraphStyle } = require('../Text/Paragraphs/ParagraphStyles');

const paragraphStyleProperties = [
    'styleId',
    'align',
    'bullet',
    'indent',
    'lineSpacing',
    'padding',
    'level'
];

const atomicProperties = [
    'bullet.style.color'
];

const setComplementParagraphStyleAsParagraphStyle = textBody => {
    let updatedTextBody = textBody;
    updatedTextBody = updatedTextBody.set(
        'paragraphStyle',
        complementAgainstSources(
            getDefaultParagraphStyle(updatedTextBody),
            DEFAULT_PARAGRAPHSTYLE,
            updatedTextBody.getIn(['assignedStyles', 'paragraph']) || Map(),
            atomicProperties
        )
    );
    updatedTextBody = setParagraphStyleDefaultOnData(updatedTextBody);
    return updatedTextBody;
};

const complementParagraphStyleAgainstDefault = (textBody, paragraphStyle = Map()) => complementAgainstSources(
    paragraphStyle,
    DEFAULT_PARAGRAPHSTYLE,
    textBody.getIn(['assignedStyles', 'paragraph']) || Map(),
    paragraphStyle.get('isDefault') ? Map() : textBody.get('paragraphStyle'),
    atomicProperties
);

const setParagraphStyleDefaultOnData = (textBody, shape) => {
    const style = getDefaultParagraphStyle(shape?.getIn(['textBody'])) ?
        getDefaultParagraphStyle(shape?.getIn(['textBody'])) :
        getRenderParagraphStyle(textBody, shape);
    return setDefaultParagraphStyle(
        textBody,
        style
    );
};

const getRenderParagraphStyle = (textBody, shape = Map()) => defaultsDeepWithExceptions(
    textBody?.get('paragraphStyle') || Map(),
    textBody?.getIn(['assignedStyles', 'paragraph']) || Map(),
    loadDefaultParagraphStyleConfiguration(shape.get('type')),
    atomicProperties
);

const loadDefaultParagraphStyleConfiguration = type => (
    (!!type && Object.prototype.hasOwnProperty.call(textBodyStyles, type) ?
        textBodyStyles[type] :
        textBodyStyles.DEFAULT).get('DEFAULT_PARAGRAPHSTYLE')
);

export {
    setComplementParagraphStyleAsParagraphStyle,
    complementParagraphStyleAgainstDefault,
    setParagraphStyleDefaultOnData,
    getRenderParagraphStyle,
    paragraphStyleProperties
};
