const { sameRunStyleInTextBodies } = require('../utils');

exports.computeOverlineFromTextBodies = (textBodies, textSelection) => sameRunStyleInTextBodies(
    textBodies,
    'overline',
    false,
    {
        textSelection
    }
);
