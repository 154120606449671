const shapeConstructors = {};

const addToConstructorList = (Constructor, name) => {
    switch (typeof name) {
        case 'string':
            shapeConstructors[name] = Constructor;
            break;
        case 'object': // Array
            name.forEach(subname => { shapeConstructors[subname] = Constructor; });
            break;
        default:
            break;
    }
    shapeConstructors[Constructor.name] = Constructor;
    shapeConstructors[`${Constructor.name[0]?.toLowerCase()}${Constructor.name.substr(1)}`] = Constructor;
    shapeConstructors[Constructor.name?.toLowerCase()] = Constructor;
};

module.exports = {
    get shapeConstructors() {
        return shapeConstructors;
    },
    getShapeConstructorByType(type) {
        return shapeConstructors[type];
    },
    addToConstructorList
};
