const { fabric } = require('fabric');
const applyFill = require('../utilities/applyFill');
const tableGridLogic = require('../../utilities/table/tableGridLogic');

const CellBackground = fabric.util.createClass(fabric.Rect, {
    type: 'cellBackground',

    initialize(json) {
        const props = {
            ...json,
            top: 0,
            left: 0,
            strokeWidth: 0
        };
        this.callSuper('initialize', props);
        this.originX = 'center';
        this.originY = 'center';
        applyFill({
            shape: this,
            fill: this.fill,
            targetProperty: 'fill'
        })
            .then(() => this.fire('cell:background:load', this));
    }
});

CellBackground.fromObject = (input, callback) => {
    const preloaded = new CellBackground(input);
    if (callback) {
        preloaded.on('cell:background:load', callback);
    }
    return preloaded;
};

const Cell = fabric.util.createClass(fabric.Group, {
    type: 'cell',

    initialize(json, opts, table) {
        const options = {
            ...opts,
            name: json.name,
            id: json.id,
            fill: json.fill,
            originX: 'center',
            originY: 'center',
            subTargetCheck: true,
            style: json.style || '',
            row: json.row,
            column: json.column,
            rowSpan: json.rowSpan,
            columnSpan: json.columnSpan,
            table,
            width: json.width,
            height: json.height,
            top: json.top,
            left: json.left,
            ownCaching: true
        };

        this.callSuper('initialize', [], options);
        new Promise(resolve => { fabric.CellBackground.fromObject(json, resolve); })
            .then(background => {
                this.add(background);
                this.originalFill = background.fill;
            })
            .then(() => Promise.all(json.contents.map(content => this.addContent({
                ...content,
                top: 0,
                left: 0,
                width: json.width,
                height: json.height
            }, table))))
            .then(() => {
                this.table = table;
                this.ownCaching = true;
                this.addWithUpdate();
                this.dirty = true;
                this.fire('cell:load', this);
            })
            .catch(err => this.fire('cell:load', this, err));
    },

    needsItsOwnCache() {
        return true;
    },

    toObject(propertiesToExtract) {
        const cell = this.callSuper('toObject', ['id', 'name', 'fill', 'style', 'row', 'column', 'table', 'columnSpan', 'rowSpan'].concat(propertiesToExtract));
        const background = this.getBackground();
        cell.width = background.width;
        cell.height = background.height;
        cell.contents = cell.objects.filter(content => content.type !== 'cellBackground');
        cell.ownCaching = true;
        delete cell.objects;
        return cell;
    },

    addContent(json, table = {}) {
        return new Promise(resolve => {
            fabric.Textshape.fromObject(json, content => {
                content.set({
                    tableName: table.name,
                    tableId: table.id,
                    originX: 'center',
                    originY: 'center'
                });
                this.add(content);
                resolve(content);
            });
        });
    },

    getBackground() {
        return this.getObjects().find(child => child.type === 'cellBackground' || child.type === 'cell');
    },

    getContents() {
        return this.getObjects().filter(child => child.type !== 'cellBackground' && child.type !== 'cell');
    },

    getContentForPoint(point) {
        if (this.getContents().length === 1) {
            return this.getContents()[0];
        }
        return this
            .getContents()
            .reverse()
            .find(object => {
                const boundingRect = object.getBoundingRect();
                const containsHorizontally = (
                    boundingRect.left < point.x &&
                    (boundingRect.left + boundingRect.width) > point.x);
                const containsVertically = (
                    boundingRect.top < point.y &&
                    (boundingRect.top + boundingRect.height) > point.y);
                return containsHorizontally && containsVertically;
            });
    },

    enterCellEditing(point, e) {
        this.editingText = true;
        let [content] = this.getContents();
        if (point) {
            const localPoint = this.toLocalPoint(point, 'center', 'center');

            content = this.getContentForPoint(localPoint);
        }

        content.enterTextEditing(true);
        if (e) {
            content.setSelectionByEvent(e);
        } else {
            content.setSelectionToTextEnd(true);
        }
    },

    exitEditing() {
        if (this.editingText) {
            this.getContents().forEach(content => {
                content.exitTextEditing();
            });
            this.editingText = false;
        }
    },

    getEditingContent() {
        const content = this.getContents().find(next => next.editingText === true);
        return content;
    },

    getTable() {
        return this.group;
    },

    containsPoint({ x, y }) {
        const containsHorizontally = (
            this.getRelativeLeft() < x &&
            this.getRelativeRight() > x);
        const containsVertically = (
            this.getRelativeTop() < y &&
            this.getRelativeBottom() > y);
        return containsHorizontally && containsVertically;
    },

    hasBorderId(id) {
        return Object.values(this.borders).some(borderId => borderId === id);
    },

    getAbsoluteLeft() {
        return this.getRelativeLeft() + this.getTable().getAbsoluteLeft();
    },

    getAbsoluteRight() {
        return this.getRelativeRight() + this.getTable().getAbsoluteLeft();
    },

    getAbsoluteTop() {
        return this.getRelativeTop() + this.getTable().getAbsoluteTop();
    },

    getAbsoluteBottom() {
        return this.getRelativeBottom() + this.getTable().getAbsoluteLeft();
    },

    getRelativeLeft() {
        return this.left - (this.width / 2);
    },

    getRelativeRight() {
        return this.left + (this.width / 2);
    },

    getRelativeTop() {
        return this.top - (this.height / 2);
    },

    getRelativeBottom() {
        return this.top + (this.height / 2);
    },

    checkIfOtherCellIsOnSide(otherCell, side) {
        switch (side) {
            case 'bottom':
                return otherCell.top > this.top;
            case 'left':
                return otherCell.left < this.left;
            case 'right':
                return otherCell.left > this.left;
            case 'top':
                return otherCell.top < this.top;
            default:
                throw new Error(`Can't check other cell position for side ${side}`);
        }
    },

    adjustCoordsToTable() {
        const leftSide = this.getTable().getColumnStartX(this.column);
        const rightSide = this.getTable().getColumnStartX(this.column + this.columnSpan);
        const topSide = this.getTable().getRowStartY(this.row);
        const bottomSide = this.getTable().getRowStartY(this.row + this.rowSpan);
        this.width = rightSide - leftSide;
        this.height = bottomSide - topSide;
        this.left = leftSide + (this.width / 2) + (this.getTable().getLeftBorderMaxWidth() / 2);
        this.top = topSide + (this.height / 2) + (this.getTable().getTopBorderMaxWidth() / 2);
        this.dirty = true;
        const background = this.getBackground();
        background.width = this.width;
        background.height = this.height;
        background.top = 0;
        background.left = 0;
        this.getContents().forEach(content => {
            content.dirty = true;
            content.width = this.width;
            content.height = this.height;
            content.top = 0;
            content.left = 0;
            content.updateTextCoordsForShapeParent();
        });
    },

    getLeftBorderSegmentIds() {
        return [...new Set(tableGridLogic.getLeftBorderSegmentsForCell(this.id, this.getTable()))];
    },

    getLeftBorderSegments() {
        return this.getLeftBorderSegmentIds()
            .map(borderSegmentId => this.getTable().getBorderById(borderSegmentId));
    },

    getLeftBorderSize() {
        return Math
            .max(...this.getLeftBorderSegments()
                .map(borderSegment => borderSegment.strokeWidth));
    },

    getRightBorderSegmentIds() {
        return [...new Set(tableGridLogic.getRightBorderSegmentsForCell(this.id, this.getTable()))];
    },

    getRightBorderSegments() {
        return this.getRightBorderSegmentIds()
            .map(borderSegmentId => this.getTable().getBorderById(borderSegmentId));
    },

    getRightBorderSize() {
        return Math
            .max(...this.getRightBorderSegments()
                .map(borderSegment => borderSegment.strokeWidth));
    },

    getTopBorderSegmentIds() {
        return [...new Set(tableGridLogic.getTopBorderSegmentsForCell(this.id, this.getTable()))];
    },

    getTopBorderSegments() {
        return this.getTopBorderSegmentIds()
            .map(borderSegmentId => this.getTable().getBorderById(borderSegmentId));
    },

    getTopBorderSize() {
        return Math
            .max(...this.getTopBorderSegments()
                .map(borderSegment => borderSegment.strokeWidth));
    },

    getBottomBorderSegmentIds() {
        return [
            ...new Set(tableGridLogic.getBottomBorderSegmentsForCell(this.id, this.getTable()))
        ];
    },

    getBottomBorderSegments() {
        return this.getBottomBorderSegmentIds()
            .map(borderSegmentId => this.getTable().getBorderById(borderSegmentId));
    },

    getBottomBorderSize() {
        return Math
            .max(...this.getBottomBorderSegments()
                .map(borderSegment => borderSegment.strokeWidth));
    },

    getLastRowIndex() {
        return this.row + (this.rowSpan - 1);
    },

    getSpannedHeight() {
        return tableGridLogic.combineDefinedAndRenderRowHeights(this.group)
            .slice(this.row, this.row + this.rowSpan)
            .reduce((sum, height) => sum + height, 0);
    },

    getTextHeight() {
        return this.getContents()
            .filter(content => content.getTextItemsHeight)
            .reduce(
                (height, content) => Math.max(height, content.editing === true ?
                    this.group.rowHeights[this.row] :
                    content.getTextItemsHeight()),
                0
            );
    }
});

Cell.fromObject = (object, callback) => {
    const cell = new Cell(object);
    if (callback) {
        cell.on('cell:load', callback);
    }
    return cell;
};

module.exports = { Cell, CellBackground };
