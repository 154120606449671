const isNil = require('lodash/isNil');

const {
    addOpacityToRGBA,
    extractRGBAComponent,
    rgbaDivisionOfAlphaBy255IfNeeded
} = require('../utilities/stringUtility');

const { findColorById } = require('../utilities/ColorPalette');

const updateValueOfDescriptorWithPalette = (descriptor = {}, colorPalette = []) => {
    if (descriptor.type === 'coded') {
        const { alpha } = extractRGBAComponent(descriptor.value);
        const colorOnPalette = findColorById(
            colorPalette,
            descriptor.reference
        );
        if (colorOnPalette) {
            const {
                red,
                green,
                blue,
                aplha: rgbaAlpha = 1
            } = extractRGBAComponent(colorOnPalette);

            return {
                ...descriptor,
                value: `rgba(${red}, ${green}, ${blue}, ${!isNil(alpha) ? alpha : rgbaAlpha})`
            };
        }
    }
    return {
        ...descriptor,
        value: descriptor.value
    };
};

module.exports = {
    updateValueOfDescriptorWithPalette,
    getValueFromDescriptor(descriptor, shape = {}) {
        if (descriptor.type === 'coded' && shape.canvas && shape.canvas.colorPalette) {
            return updateValueOfDescriptorWithPalette(
                descriptor,
                shape.canvas.colorPalette
            ).value;
        }
        return descriptor.value;
    },

    // eslint-disable-next-line no-unused-vars
    getDescriptorFromValue(value, shape = {}) {
        if (shape && shape.canvas && shape.canvas.colorPalette) {
            const { red, green, blue } = extractRGBAComponent(value);
            const colorReference = shape.canvas.colorPalette.find(colorToCompare => {
                const componentsToCompare = extractRGBAComponent(colorToCompare.color);
                return (
                    red === componentsToCompare.red &&
                    green === componentsToCompare.green &&
                    blue === componentsToCompare.blue
                );
            });
            if (colorReference) {
                return {
                    type: 'coded',
                    reference: colorReference.id,
                    value: colorReference.color
                };
            }
        }
        return {
            type: 'custom',
            value
        };
    },

    updateValueOfDescriptor(descriptor, shape = {}) {
        return updateValueOfDescriptorWithPalette(
            descriptor,
            (shape && shape.canvas && shape.canvas.colorPalette)
        );
    },

    normalizeAlpha(descriptor = {}) {
        return {
            ...descriptor,
            value: rgbaDivisionOfAlphaBy255IfNeeded(descriptor.value || 0)
        };
    },

    forceOpacity(descriptor, opacity) {
        return {
            ...descriptor,
            value: addOpacityToRGBA(descriptor.value, opacity)
        };
    },

    extractRBGAFromDescriptor(descriptor, shape = {}) {
        const value = this.getValueFromDescriptor(descriptor, shape);
        return extractRGBAComponent(value);
    },

    isColorValueDescriptorValid({
        type,
        reference,
        value
    } = {}) {
        const isValidCoded = type === 'coded' && !!reference;
        const isValidCustom = type === 'custom' && !!value;
        return isValidCoded || isValidCustom;
    },

    updateColorPresets(descriptor, { presets = [], scheme = 'monochromatic' } = {}) {
        if (this.isColorValueDescriptorValid(descriptor) && descriptor.preset) {
            const preset = presets.find(({ id }) => id === descriptor.preset);
            if (preset) {
                const reference = preset[`${scheme}SchemeColor`];
                if (reference) {
                    return {
                        ...descriptor,
                        reference
                    };
                }
            }
        }
        return descriptor;
    }
};
