const ColorValueDescriptor = require('../ColorValueDescriptor');

const updateColorPresets = (runStyle, { presets, scheme }) => {
    if (runStyle && runStyle.color) {
        return {
            ...runStyle,
            color: ColorValueDescriptor.updateColorPresets(runStyle.color, { presets, scheme })
        };
    }
    return runStyle;
};

module.exports = {
    updateColorPresets
};
