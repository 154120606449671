import getFirstParagraphStyle from './getFirstParagraphStyle';

export default {
    id: {
        itemUpdatePropertyName: 'styleId'
    },
    lineSpacing: {
        itemUpdatePropertyName: 'lineHeight',
        toStyle(shape) {
            const paragraphStyle = getFirstParagraphStyle(shape);
            return paragraphStyle.get('lineHeight');
        }
    },
    horizontalJustification: {
        itemUpdatePropertyName: 'textAlign',
        toStyle(shape) {
            const paragraphStyle = getFirstParagraphStyle(shape);
            return paragraphStyle.get('align');
        }
    },
    verticalJustification: {
        itemUpdatePropertyName: 'verticalAlign',
        toStyle(shape) {
            return shape.getIn(['textBody', 'verticalAlign']);
        }
    },
    topMargin: {
        itemUpdatePropertyName: 'margins.top',
        toStyle(shape) {
            return shape.getIn(['textBody', 'margins', 'top']);
        }
    },
    bottomMargin: {
        itemUpdatePropertyName: 'margins.bottom',
        toStyle(shape) {
            return shape.getIn(['textBody', 'margins', 'bottom']);
        }
    },
    leftMargin: {
        itemUpdatePropertyName: 'margins.left',
        toStyle(shape) {
            return shape.getIn(['textBody', 'margins', 'left']);
        }
    },
    rightMargin: {
        itemUpdatePropertyName: 'margins.right',
        toStyle(shape) {
            return shape.getIn(['textBody', 'margins', 'right']);
        }
    },
    spaceBefore: {
        itemUpdatePropertyName: 'spacing-top',
        toStyle(shape) {
            const paragraphStyle = getFirstParagraphStyle(shape);
            return paragraphStyle.getIn(['padding', 'top']);
        }
    },
    spaceAfter: {
        itemUpdatePropertyName: 'spacing-bottom',
        toStyle(shape) {
            const paragraphStyle = getFirstParagraphStyle(shape);
            return paragraphStyle.getIn(['padding', 'bottom']);
        }
    },
    leftIndent: {
        itemUpdatePropertyName: 'spacing-left',
        toStyle(shape) {
            const paragraphStyle = getFirstParagraphStyle(shape);
            return paragraphStyle.getIn(['padding', 'left']);
        }
    },
    rightIndent: {
        itemUpdatePropertyName: 'spacing-right',
        toStyle(shape) {
            const paragraphStyle = getFirstParagraphStyle(shape);
            return paragraphStyle.getIn(['padding', 'right']);
        }
    },
    direction: {
        itemUpdatePropertyName: 'textDirection',
        toStyle(shape) {
            return shape.getIn(['textBody', 'textDirection']);
        }
    }
};
