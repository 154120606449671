import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Dimmer, Loader } from 'semantic-ui-react';
import { isLoading } from '#Selectors/app';

const AppLoadingIndicator = ({ loading }) => loading && (
    <Dimmer active page>
        <Loader size="big" />
    </Dimmer>
);

AppLoadingIndicator.propTypes = {
    loading: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
    loading: isLoading(state)
});

export default connect(mapStateToProps)(AppLoadingIndicator);
