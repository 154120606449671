import { camelCase } from 'lodash';
import rectangleShapeToFabricJSON from './ShapeAdapters/rectangle';
import textboxShapeToFabricJSON from './ShapeAdapters/textbox';
import ellipseShapeToFabricJSON from './ShapeAdapters/ellipse';
import triangleShapeToFabricJSON from './ShapeAdapters/triangle';
import trapezoidShapeToFabricJSON from './ShapeAdapters/trapezoid';
import chevronShapeToFabricJSON from './ShapeAdapters/chevron';
import calloutShapeToFabricJSON from './ShapeAdapters/callout';
import imageShapeToFabricJSON from './ShapeAdapters/image';
import pathShapeToFabricJSON from './ShapeAdapters/path';
import arrowShapeToFabricJSON from './ShapeAdapters/arrow';
import regularPolygonShapeToFabricJSON from './ShapeAdapters/regularPolygon';
import lineShapeToFabricJSON from './ShapeAdapters/line';
import tableShapeToFabricJSON from './ShapeAdapters/table';
// eslint-disable-next-line import/no-cycle
import groupShapeToFabricJSON from './ShapeAdapters/group';

const adaptersByTypes = {
    rectangle: rectangleShapeToFabricJSON,
    ellipse: ellipseShapeToFabricJSON,
    triangle: triangleShapeToFabricJSON,
    trapezoid: trapezoidShapeToFabricJSON,
    chevron: chevronShapeToFabricJSON,
    callout: calloutShapeToFabricJSON,
    textbox: textboxShapeToFabricJSON,
    image: imageShapeToFabricJSON,
    path: pathShapeToFabricJSON,
    arrow: arrowShapeToFabricJSON,
    line: lineShapeToFabricJSON,
    regularPolygon: regularPolygonShapeToFabricJSON,
    table: tableShapeToFabricJSON,
    group: groupShapeToFabricJSON
};

const fromShapeToFabricJSON = (shape, canvasState) => adaptersByTypes[camelCase(shape.get('type'))](shape, canvasState);

export default fromShapeToFabricJSON;
