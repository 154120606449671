const invoices = (state = {
    fetching: false,
    error: false,
    errorMessage: '',
    data: []
}, action) => {
    switch (action.type) {
        case 'FETCH_INVOICES':
            return {
                ...state,
                fetching: true
            };
        case 'FETCH_INVOICES_SUCCESS':
            return {
                ...state,
                fetching: false,
                error: false,
                data: action.data
            };
        case 'FETCH_INVOICES_ERROR':
            return {
                ...state,
                fetching: false,
                error: true,
                errorMessage: action.errorMessage
            };
        default:
            return state;
    }
};
export default invoices;
