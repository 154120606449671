module.exports = {
    isImagePlaceholder() {
        return this.isPlaceholder() && this.placeholderType === 'PICTURE';
    },

    hasImageFill() {
        const fill = this.get('fill');
        return fill && fill.type === 'imageSource' && this.imgEl;
    },

    isImagePlaceholderWithoutFill() {
        return this.isImagePlaceholder() && !this.hasImageFill();
    }
};
