import { Map, fromJS, Record } from 'immutable';

export default (object, customizer = () => {}) => {
    if (!Map.isMap(object) && !(object instanceof Record)) {
        throw new Error('Can\'t get pairs of non map');
    }
    const pairs = [];
    const queue = [{ key: '', object }];
    while (queue.length > 0) {
        const {
            key: parentKey,
            object: target
        } = queue.shift();
        target
            .entrySeq()
            .forEach(([key, value]) => {
                const currentKey = `${parentKey}${key}`;
                const customValue = customizer(currentKey, value);
                if (customValue !== undefined) {
                    pairs.push([currentKey, customValue]);
                } else if (Map.isMap(value)) {
                    queue.push({
                        key: `${currentKey}.`,
                        object: value
                    });
                } else {
                    pairs.push([currentKey, value]);
                }
            });
    }

    return fromJS(pairs);
};
