const newShapeType = ['ellipse', 'rectangle', 'circle', 'textbox', 'image',
    'polygon', 'triangle', 'line', 'path', 'pathgroup', 'group', 'triangles', 'arrows',
    'list', 'funnel', 'customLine', 'arrow', 'callout', 'trapezoid', 'regularpolygon', 'chevron'];
// should be an npm module, it is inside the build section for developpement purpose
const isNewShapeType = object => {
    if (object && object.get) {
        return newShapeType.includes(object.get('type').toLowerCase());
    }
    return false;
};

const isTable = object => (object && object.constructor.name === 'Table');

const convertPropertyToNewShapeProperty = property => {
    const dictionary = {};
    dictionary.angle = 'rotation';
    dictionary.left = 'x';
    dictionary.lineHeight = 'lineSpacing';
    dictionary.shapeFill = 'fill';
    dictionary.stroke = 'borderColor';
    dictionary.strokeWidth = 'borderSize';
    dictionary.textAlign = 'align';
    dictionary.top = 'y';
    dictionary.opacity = 'opacity';
    return dictionary[property] || property;
};

const snapToAngle = (angle, zoom) => {
    const lockToAngles = [45, 90, 135, 180, 225, 270, 315, 360];
    const deltaSnap = Math.min(10, 5 / zoom);

    let currentAngle = angle;

    if (currentAngle < deltaSnap) {
        currentAngle += 360;
    }

    for (let i = 0; i < lockToAngles.length; i += 1) {
        if (
            lockToAngles[i] - deltaSnap < currentAngle &&
            lockToAngles[i] + deltaSnap > currentAngle
        ) {
            return lockToAngles[i];
        }
    }

    return currentAngle;
};

const getLeft = canvasItem => (isNewShapeType(canvasItem) ? canvasItem.x : canvasItem.left);
const getTop = canvasItem => (isNewShapeType(canvasItem) ? canvasItem.y : canvasItem.top);

export {
    convertPropertyToNewShapeProperty,
    isNewShapeType,
    isTable,
    snapToAngle,
    getTop,
    getLeft
};
