import { isNil } from 'lodash';
import getShapeType from './shapeType';
import getRelativeAnchors from './relativeAnchors';
import getAutoFitShape from './textBody/autoFitShape';
import getAutoFitText from './textBody/autoFitText';
import getCharSpacing from './charSpacing';
import getFill from './fill';
import getHoverCursor from './hoverCursor';
import isTextEmpty from './textBody/isTextEmpty';
import isSelectable from './selectable';
import { getStroke } from './stroke';
import getTextBodyMargins from './textBody/textBodyMargins';
import getTextDirection from './textBody/textDirection';
import getVerticalAlign from './textBody/verticalAlign';
import { getPlaceholderParagraphs, getParagraphs } from './textBody/paragraphs';
import getStartIndex from './textBody/startIndex';
import getEndIndex from './textBody/endIndex';
import getAnchors from '../../Utils/anchors';
import isVisible from './visible';

const defaultPropertyAdapters = {
    type: shape => shape.get('type'),
    shapeType: getShapeType,
    name: shape => shape.get('name'),
    id: shape => shape.get('id'),
    width: shape => shape.get('width'),
    height: shape => shape.get('height'),
    x: shape => shape.get('x'),
    y: shape => shape.get('y'),
    style: shape => shape.get('style') || '',
    rotation: shape => (!isNil(shape.get('rotation')) ? shape.get('rotation') : 0),
    anchors: shape => getAnchors(shape, getRelativeAnchors(shape)),
    charSpacing: getCharSpacing,
    inLayout: shape => shape.get('inLayout'),
    scaleX: shape => shape.get('scaleX') || 1,
    scaleY: shape => shape.get('scaleY') || 1,
    lockPath: shape => shape.get('lockPath') || false,
    flipX: shape => shape.get('flipX'),
    flipY: shape => shape.get('flipY'),
    isBackground: shape => shape.get('isBackground') || false,
    isGroupHidden: shape => shape.get('isGroupHidden') || false,
    isGroupLocked: shape => shape.get('isGroupLocked') || false,
    isHidden: shape => shape.get('isHidden') || false,
    isImported: shape => shape.get('isImported') || false,
    isLocked: shape => shape.get('isLocked'),
    skewX: shape => shape.get('skewX'),
    skewY: shape => shape.get('skewY'),
    hoverCursor: getHoverCursor,
    dynamicProperties: shape => shape.get('dynamicProperties')?.toJS() || [],
    lockAspectRatio: shape => shape.get('lockAspectRatio') || false,
    fill: getFill,
    opacity: shape => shape.get('opacity'),
    stroke: getStroke,
    selectable: isSelectable,
    visible: isVisible,
    displayPlaceholder: shape => shape.get('displayPlaceholder') || false,
    hyperlink: shape => shape.get('hyperlink'),
    selected: shape => shape.get('selected') || false,
    evented: shape => shape.get('evented'),
    sendToBack: shape => shape.get('sendToBack'),
    autoFitText: getAutoFitText,
    autoFitShape: getAutoFitShape,
    paragraphs: getParagraphs,
    placeholderParagraphs: getPlaceholderParagraphs,
    textBodyMargins: shape => getTextBodyMargins(shape)?.toJS(),
    verticalAlign: getVerticalAlign,
    textDirection: getTextDirection,
    startIndex: getStartIndex,
    endIndex: getEndIndex,
    isTextEmpty,
    placeholderSequence: shape => shape.get('placeholderSequence'),
    placeholderSourceId: shape => shape.get('placeholderSourceId'),
    placeholderType: shape => shape.get('placeholderType')
};

export default defaultPropertyAdapters;
