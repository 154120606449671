const {
    FABRIC_BASE_RATIO,
    FABRIC_FIRST_LINE_OFFSET_RATIO,
    PPTX_FIRST_LINE_RATIO
} = require('../../constants/text');

const {
    getLineContentHeightInEm
} = require('../../utilities/FontMetricsGetter');

module.exports = {
    getLineMaxWidth(lineIndex) {
        const {
            firstLineIndex,
            indent = 0,
            paddingLeft = 0,
            paddingRight = 0
        } = this.getParagraphForLine(lineIndex);
        let lineWidthRestriction = paddingLeft + paddingRight;
        if (firstLineIndex === lineIndex) {
            lineWidthRestriction += indent;
        }
        return this.width - lineWidthRestriction;
    },

    getLineContentHeight(lineIndex) {
        const {
            firstLineIndex
        } = this.getParagraphForLine(lineIndex);
        if (firstLineIndex === lineIndex) {
            return this.getHeightOfLine(lineIndex) / this._getLineHeight(lineIndex);
        }
        return this.getHeightOfLine(lineIndex);
    },

    calculateTopOffsetForLineHeight(lineIndex) {
        const {
            firstLineIndex,
            lineHeight
        } = this.getParagraphForLine(lineIndex);
        if (firstLineIndex !== lineIndex) {
            return 0;
        }
        const lineContentHeight = this.getLineContentHeight(lineIndex);
        if (lineHeight === 1) {
            return 0.05 * lineContentHeight;
        }
        if (lineHeight < 1) {
            return -(1 - lineHeight) * lineContentHeight * 0.7;
        }

        const difference = lineHeight - 1;
        return difference * FABRIC_FIRST_LINE_OFFSET_RATIO * lineContentHeight;
    },

    _getLineHeight(lineIndex) {
        const {
            fontFamily
        } = this.getParagraphForLine(lineIndex);
        return this._calculateLineHeight(lineIndex) /
            getLineContentHeightInEm({ fontFamily });
    },

    _calculateLineHeight(lineIndex) {
        const {
            lineHeight
        } = this.getParagraphForLine(lineIndex);
        const fabricLineHeight = lineHeight / 1.2;

        if (lineHeight >= 1) {
            return fabricLineHeight * FABRIC_BASE_RATIO;
        }

        return fabricLineHeight * FABRIC_BASE_RATIO;
    },

    _getLineLeftOffset(lineIndex) {
        const lineWidth = this.getLineWidth(lineIndex);
        const {
            paddingLeft = 0,
            paddingRight = 0,
            indent = 0,
            textAlign = 'left',
            bullet,
            firstLineIndex
        } = this.getParagraphForLine(lineIndex);

        const bulletWidth = bullet ? bullet.getLineWidth(0) : 0;

        if (textAlign === 'center') {
            const diff = bullet ? this.getLineWidth(0) - lineWidth : 0;
            return ((this.width - lineWidth - diff) / 2) + paddingLeft + Math.max(0, indent + bulletWidth);
        }
        if (textAlign === 'right') {
            return this.width - (lineWidth + paddingRight);
        }
        if (textAlign === 'justify-center' && this.isEndOfWrapping(lineIndex)) {
            return (this.width - (lineWidth + paddingLeft)) / 2;
        }
        if (textAlign === 'justify-right' && this.isEndOfWrapping(lineIndex)) {
            return this.width - (lineWidth + paddingRight);
        }
        return firstLineIndex === lineIndex ? paddingLeft + Math.max(0, indent + bulletWidth) : paddingLeft;
    },

    _getLineTopOffset(lineIndex) {
        const {
            paddingTop = 0,
            firstLineIndex
        } = this.getParagraphForLine(lineIndex);
        return firstLineIndex === lineIndex ? paddingTop : 0;
    },

    _getLineBottomOffset(lineIndex) {
        const {
            paddingBottom = 0
        } = this.getParagraphForLine(lineIndex);

        return this._isLineLastOfParagraph(lineIndex) && !this._isLineInLastParagraph(lineIndex) ? paddingBottom : 0;
    },

    getHeightOfLine(lineIndex) {
        if (this.__lineHeights[lineIndex]) {
            return this.__lineHeights[lineIndex];
        }

        const lineHeight = this.getParagraphForLine(lineIndex).lineHeight / 1.2;

        const leading = this.calcLeading(lineIndex);
        let heightOfLine = leading;

        if (lineHeight !== 1) {
            if (!lineIndex) { // First Line
                heightOfLine *= PPTX_FIRST_LINE_RATIO;
            } else { // Multiple Font Sizes
                heightOfLine += this.calcMultipleLinesDiff(lineIndex, heightOfLine);
            }
        } else if (!lineIndex) {
            heightOfLine -= heightOfLine * this._fontSizeFraction;
        } else {
            heightOfLine += this.calcMultipleLinesDiff(lineIndex, heightOfLine);
        }

        this.__lineHeights[lineIndex] = heightOfLine;

        return this.__lineHeights[lineIndex];
    },

    getApproximatedFabricBaseline(lineIndex = 0) {
        return Math.abs((
            this.getHeightOfLine(lineIndex) *
            this._fontSizeFraction
        ) / this._getLineHeight(lineIndex));
    },

    getActualTextBaseline({
        charIndex = 0,
        lineIndex = 0
    } = {}) {
        return Math.abs(this.getCharMetricValue({
            charIndex,
            lineIndex,
            metricId: 'descender'
        }));
    },

    getTopOffsetBeforeLine(lineIndex) {
        let topOffset = this._getTopOffset();
        for (let i = 0; i < lineIndex; i++) {
            topOffset += i === 0 ? 0 : this._getLineTopOffset(i);
            topOffset += this.getHeightOfLine(i);
            topOffset += this._getLineBottomOffset(i);
        }
        topOffset += lineIndex === 0 ? 0 : this._getLineTopOffset(lineIndex);
        return topOffset;
    }
};
