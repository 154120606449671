import * as types from '#Constants/ActionTypes';

const objectives = (state = null, action) => {
    switch (action.type) {
        case types.SET_OBJECTIVES_LIST:
            return action.objectives;
        case types.REMOVE_OBJECTIVES_LIST:
            return null;
        case types.FETCH_OBJECTIVES_LIST:
        default:
            return state;
    }
};

const filteredObjectives = (state = {}, action) => {
    switch (action.type) {
        case types.SET_FILTERED_OBJECTIVES_LIST:
            return action.filteredObjectives;
        case types.SET_OBJECTIVES_LIST:
            return action.filteredObjectives;
        default:
            return state;
    }
};

const selectedObjective = (state = {}, action) => {
    switch (action.type) {
        case types.SET_SELECTED_OBJECTIVE:
            return action.selectedObjective;
        case types.FETCH_DECK_SUCCESS:
            if (action.deck && action.deck.objective) {
                return action.deck.objective;
            }
            return state;
        default:
            return state;
    }
};

const initialObjective = (state = {}, action) => {
    switch (action.type) {
        case types.SET_INITIAL_OBJECTIVE:
            return action.initialObjective;
        case types.FETCH_DECK_SUCCESS:
            if (action.deck && action.deck.objective) {
                return action.deck.objective;
            }
            return state;
        default:
            return state;
    }
};

const objectivesTypes = (state = null, action) => {
    switch (action.type) {
        case types.SET_ALL_OBJECTIVES_TYPES:
            return action.allTypes;
        default:
            return state;
    }
};

const objectivesCategories = (state = [], action) => {
    switch (action.type) {
        case types.SET_OBJECTIVES_CATEGORIES:
            return action.categories;
        case types.SET_OBJECTIVES_LIST:
            return action.categories;
        default:
            return state;
    }
};

export {
    objectives,
    filteredObjectives,
    selectedObjective,
    initialObjective,
    objectivesTypes,
    objectivesCategories
};
