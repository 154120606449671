const UUID = require('uuid/v4');

module.exports = {
    duplicateShape() {
        this.clone(shadowObj => {
            const ids = [];
            shadowObj.selectable = true;
            const newId = UUID().toString();
            shadowObj.id = newId;
            if (this.type === 'activeSelection') {
                shadowObj._objects.forEach(obj => {
                    ids.push(obj.id);
                    obj.id = UUID().toString();
                });
            } else {
                ids.push(this.id);
            }
            this.canvas.add(shadowObj);
            this.duplicating = true;
            this.canvas.requestRenderAll();
            this.canvas.duplicateItem(ids);
        });
    },

    changeOriginalShapeName() {
        if (this.type === 'activeSelection') {
            this._objects = this._objects.map(obj => Object.assign(obj, { name: `${obj.name}_copy` }));
        }
        this.name = `${this.name}_copy`;
    }
};
