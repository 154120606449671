import decksignCanvas from 'decksign-canvas';
import { LOCATION_CHANGE } from 'connected-react-router';
import { updateCanvasStateAfterDeckUpdate } from '#Utilities/decksign/canvasStateUpdater';

import * as types from '#Constants/ActionTypes';

import textSelection from './textSelection/textSelection';

const CanvasState = decksignCanvas.api.CanvasState;

const isCanvasUpdating = (state = false, action) => {
    switch (action.type) {
        case types.CHANGE_ACTIVE_PAGE: {
            return action.id !== null;
        }
        case types.LOAD_PAGE:
        case types.SET_CANVAS_UPDATING:
            return true;
        case types.UPDATE_CANVAS_STATE:
            return action.needUpdate;
        case types.CANVAS_IS_RENDERED:
        case types.SPLIT_SELECTED_CELLS_ERROR:
            return false;
        default:
            return state;
    }
};

const isDuplicatingDeck = (state = false, action) => {
    switch (action.type) {
        case types.DUPLICATE_DECK:
            return true;
        case types.DECK_DUPLICATED:
            return false;
        case types.DUPLICATE_DECK_FAILED:
            return false;
        default:
            return state;
    }
};

const isFirstPageRender = (state = false, action) => {
    switch (action.type) {
        case types.CHANGE_ACTIVE_PAGE:
        case types.LOAD_PAGE:
            return true;
        case types.CANVAS_IS_RENDERED:
            return false;
        case LOCATION_CHANGE:
            if (action.payload.location.pathname.includes('build')) {
                return true;
            }
            return state;
        default:
            return state;
    }
};

const canvasState = (state = null, action) => {
    switch (action.type) {
        case types.SET_DECK:
        case types.CHANGE_ACTIVE_PAGE:
            return null;
        case LOCATION_CHANGE:
            if (action.payload.location.pathname.includes('build')) {
                return null;
            }
            return state;
        case types.LOAD_PAGE: {
            return action.canvasState;
        }
        case types.CHANGE_DECK_PROPERTIES: {
            return updateCanvasStateAfterDeckUpdate(state, action.deck);
        }
        case types.UPDATE_CANVAS_STATE:
            return action.updatedState;
        case types.SELECT_OBJECTS:
            return CanvasState.update(state, {
                type: types.UPDATE_SELECTION,
                selection: action.itemIds,
                selectedShapesParentGroupsPath: action.selectedShapesParentGroupsPath
            });
        case types.DELETE_SELECTED_SHAPES:
            return CanvasState.update(state, {
                type: types.DELETE_SELECTED_SHAPES
            });
        case types.EXIT_CONTEXTUAL_SELECTION:
            return CanvasState.update(state, {
                type: types.EXIT_CONTEXTUAL_SELECTION
            });
        case types.GROUP_SELECTED_SHAPES:
            return CanvasState.update(state, {
                type: types.GROUP_SELECTED_SHAPES
            });
        case types.UNGROUP_SELECTED_SHAPES:
            return CanvasState.update(state, {
                type: types.UNGROUP_SELECTED_SHAPES
            });
        case types.COPY_SELECTED_SHAPES:
            return CanvasState.update(state, {
                type: types.COPY_SELECTED_SHAPES
            });
        case types.SET_CLIPBOARD:
            if (action.itemType === 'Object') {
                return CanvasState.update(state, {
                    type: types.SET_CLIPBOARD,
                    isCut: action.isCut,
                    textSelection: action.textSelection
                });
            }
            return state;
        case types.LAYOUT_RESET:
            return CanvasState.update(state, {
                type: types.LAYOUT_RESET,
                layout: action.layout
            });
        case types.PASTE_CLIPBOARD_CONTENT:
            return CanvasState.update(state, {
                type: types.PASTE_CLIPBOARD_CONTENT,
                clipboard: action.clipboard,
                page: action.page
            });
        case types.CUT_SELECTED_SHAPES:
            return CanvasState.update(state, {
                type: types.CUT_SELECTED_SHAPES
            });
        case types.FLIP_SELECTED_OBJECTS:
            return CanvasState.update(state, {
                type: 'FLIP_SELECTED_SHAPES',
                flipX: action.flipX,
                flipY: action.flipY
            });
        // TODO: Validate this is still used
        case types.UPDATE_SHAPES:
            return CanvasState.update(state, {
                type: types.UPDATE_SHAPES,
                shapes: action.shapes
            });
        case types.UPDATE_PROPERTIES_ON_SELECTED_SHAPES:
            return CanvasState.update(state, {
                type: types.UPDATE_PROPERTIES_ON_SELECTED_SHAPES,
                properties: action.properties
            });
        case types.UPDATE_PROPERTIES_FROM_DELTA_ON_SELECTED_SHAPES:
            return CanvasState.update(state, {
                type: types.UPDATE_PROPERTIES_FROM_DELTA_ON_SELECTED_SHAPES,
                properties: action.properties
            });
        case types.CONVERT_TO_LIST_TYPE:
            return CanvasState.update(state, {
                type: types.CONVERT_TO_LIST_TYPE,
                update: action.update,
                listType: action.listType
            });
        case types.DELETE_ALL_SHAPES:
            return CanvasState.update(state, {
                type: types.DELETE_ALL_SHAPES
            });
        case types.CREATE_SHAPES: {
            const {
                shapes,
                editMode,
                defaultCanvasItemStyles
            } = action;

            return CanvasState.update(state, {
                type: types.CREATE_SHAPES,
                shapes,
                editMode,
                defaultCanvasItemStyles
            });
        }
        case types.CHANGE_SHAPE_INDEX:
            return CanvasState.update(state, {
                type: types.CHANGE_SHAPE_INDEX,
                changeType: action.changeType,
                isTriggeredByUI: action.isTriggeredByUI,
                sourcePath: action.sourcePath,
                targetPath: action.targetPath
            });
        case types.DISTRIBUTE_SELECTED_SHAPES:
            return CanvasState.update(state, {
                type: types.DISTRIBUTE_SELECTED_SHAPES,
                direction: action.direction
            });
        case types.ALIGN_SELECTED_SHAPES:
            return CanvasState.update(state, {
                type: types.ALIGN_SELECTED_SHAPES,
                position: action.position
            });
        case types.UPDATE_SELECTION_AND_GROUP:
            return CanvasState.update(state, {
                type: types.UPDATE_SELECTION_AND_GROUP,
                selection: action.selection,
                selectedShapesParentGroupsPath: action.selectedShapesParentGroupsPath
            });
        case types.UPDATE_SELECTION:
            return CanvasState.update(state, {
                type: types.UPDATE_SELECTION,
                selection: action.selection,
                selectedShapesParentGroupsPath: action.selectedShapesParentGroupsPath
            });
        case types.OFFSET_SELECTED_SHAPES:
            return CanvasState.update(state, {
                type: types.OFFSET_SELECTED_SHAPES,
                property: action.property,
                step: action.step
            });
        case types.SPLIT_SELECTED_CELLS:
            return CanvasState.update(state, {
                type: types.SPLIT_SELECTED_CELLS,
                rows: action.rows,
                columns: action.columns
            });
        case types.MERGE_SELECTED_CELLS:
            return CanvasState.update(state, {
                type: types.MERGE_SELECTED_CELLS
            });
        case types.ADD_TABLE_LINE:
            return CanvasState.update(state, {
                type: types.ADD_TABLE_LINE,
                direction: action.direction
            });
        case types.REMOVE_TABLE_LINE:
            return CanvasState.update(state, {
                type: types.REMOVE_TABLE_LINE,
                axis: action.axis
            });
        case types.CLEAR_SELECTED_CELLS:
            return CanvasState.update(state, {
                type: types.CLEAR_SELECTED_CELLS
            });
        case types.TOGGLE_SHAPE_LOCK:
            return CanvasState.update(state, {
                type: types.TOGGLE_SHAPE_LOCK,
                ids: action.ids
            });
        case types.TOGGLE_SHAPE_VISIBILITY:
            return CanvasState.update(state, {
                type: types.TOGGLE_SHAPE_VISIBILITY,
                ids: action.ids
            });
        case types.TOGGLE_EDIT_MODE:
            return CanvasState.update(state, {
                type: types.TOGGLE_EDIT_MODE,
                editMode: action.editMode
            });
        case types.CHANGE_LAYOUT_NAME:
            return CanvasState.update(state, {
                type: types.CHANGE_LAYOUT_NAME,
                name: action.name
            });
        case types.UPDATE_CANVAS_LOGO_SOURCE:
            return CanvasState.update(state, {
                type: 'UPDATE_DYNAMIC_VALUES',
                update: action.dynamicValues
            });
        case types.APPLY_LAYOUT_ON_PAGE:
            return CanvasState.update(state, {
                type: types.APPLY_LAYOUT_ON_PAGE,
                unaffectedShapes: action.unaffectedShapes || [],
                unaffectedShapesProperties: action.unaffectedShapesProperties || []
            });
        case types.CONVERT_SELECTED_SHAPES_TEXT_ITEMS_LEVEL:
            return CanvasState.update(state, {
                type: types.CONVERT_SELECTED_SHAPES_TEXT_ITEMS_LEVEL,
                oldLevel: action.oldLevel,
                newLevel: action.newLevel
            });
        default:
            return state;
    }
};

const persistedCanvasState = (state = {
    version: 1,
    isSaving: false,
    error: null
}, action) => {
    switch (action.type) {
        case types.CHANGE_ACTIVE_PAGE:
            return {
                ...state,
                version: 1
            };
        case types.LOAD_PAGE:
            return {
                version: 1,
                isSaving: false
            };
        case types.START_CANVAS_STATE_SAVE:
            return {
                version: action.version,
                isSaving: true
            };
        case types.UPDATE_PAGE_ID_IN_CURRENT_DECK:
            return {
                ...state,
                isSaving: false
            };
        case types.SAVING_FAILED:
            return {
                ...state,
                isSaving: false,
                error: action.error
            };
        default:
            return state;
    }
};

const page = (state = null, action) => {
    switch (action.type) {
        case types.CHANGE_ACTIVE_PAGE:
            return null;
        case types.LOAD_PAGE:
            return { ...action.page };
        case types.UPDATE_PAGE_IN_CURRENT_DECK:
            return {
                ...state,
                ...action.newPage
            };
        case types.UPDATE_PAGE_ID_IN_CURRENT_DECK: {
            if (state && action.previousId === state.id) {
                return { ...state, id: action.newId, _id: action.newId };
            }
            return state;
        }
        default:
            return state;
    }
};

const activePage = (state = { id: null }, action) => {
    switch (action.type) {
        case types.SET_DECK:
        case types.CREATE_DECK:
            return {
                id: null
            };
        case types.CHANGE_ACTIVE_PAGE: {
            return { ...state, id: action.id };
        }
        case types.UPDATE_PAGE_IN_CURRENT_DECK:
            return { id: action.newId };
        case types.UPDATE_PAGE_ID_IN_CURRENT_DECK: {
            if (action.previousId === state.id) {
                return { ...state, id: action.newId };
            }
            return state;
        }
        default:
            return state;
    }
};

const editedTextShape = (state = null, action) => {
    switch (action.type) {
        case types.EDITED_TEXTSHAPE:
            return action.textShape;
        default:
            return state;
    }
};

const resetCanvasCache = (state = false, action) => {
    switch (action.type) {
        case types.UPDATE_CANVAS_STATE:
            return action.resetCache || false;
        case types.CANVAS_IS_RENDERED:
            return false;
        default:
            return state;
    }
};

export {
    page,
    activePage,
    editedTextShape,
    isCanvasUpdating,
    isFirstPageRender,
    isDuplicatingDeck,
    persistedCanvasState,
    canvasState,
    resetCanvasCache,
    textSelection
};
