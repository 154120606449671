const { sameParagraphStyleInTextBodies } = require('../utils');

exports.computeIndentFromTextBodies = (textBodies, textSelection) => sameParagraphStyleInTextBodies(
    textBodies,
    'indent',
    0,
    {
        textSelection
    }
);
