const get = require('lodash/get');
const set = require('lodash/set');
const strokeTipSizeToCanvasItemConversion = require('./strokeTipSizeToCanvasItemConversion');
const Fill = require('../../Fill');

module.exports = {
    id: {
        itemUpdatePropertyName: 'styleId'
    },
    fill: {
        toItemUpdate: (value, canvasItemStyle) => ({
            ...canvasItemStyle,
            strokeColor: value
        }),
        toStyle: canvasItem => get(canvasItem, 'stroke.fill', {}),
        isFill: true
    },
    strokeType: {
        toItemUpdate: (value, canvasItemStyle) => ({
            ...canvasItemStyle,
            strokeDash: value || 'solid'
        }),
        toStyle: canvasItem => get(canvasItem, 'stroke.dash.type', 'solid')
    },
    strokeWeight: {
        toItemUpdate: (value, canvasItemStyle) => ({
            ...canvasItemStyle,
            strokeWidth: value || 0
        }),
        toStyle: canvasItem => get(canvasItem, 'stroke.width', 0)
    },
    opacity: {
        itemUpdatePropertyName: 'strokeOpacity',
        toItemUpdate: (value, update, definition) => {
            let opacity = value;
            if (opacity === undefined) {
                const rgba = get(definition, 'fill');
                if (rgba) {
                    const components = Fill.extractRGBAFromFirstDecsriptor(definition.fill);
                    if (components && components.alpha !== undefined) {
                        opacity = components.alpha;
                    }
                }
            }
            return set(
                update,
                'strokeOpacity',
                opacity
            );
        }
    },
    joinType: {
        toItemUpdate: (value, canvasItemStyle) => ({
            ...canvasItemStyle,
            strokeJoinType: value || ''
        }),
        toStyle: canvasItem => get(canvasItem, 'stroke.join.type', '')
    },
    capType: {
        toItemUpdate: (value, canvasItemStyle) => ({
            ...canvasItemStyle,
            strokeCapType: value || ''
        }),
        toStyle: canvasItem => get(canvasItem, 'stroke.cap', '')
    },
    startTipType: {
        toItemUpdate: (value, canvasItemStyle) => ({
            ...canvasItemStyle,
            headTipType: value || ''
        }),
        toStyle: canvasItem => get(canvasItem, 'stroke.head.type', '')
    },
    endTipType: {
        toItemUpdate: (value, canvasItemStyle) => ({
            ...canvasItemStyle,
            tailTipType: value || ''
        }),
        toStyle: canvasItem => get(canvasItem, 'stroke.tail.type', '')
    },
    startTipSize: {
        ...strokeTipSizeToCanvasItemConversion('head')
    },
    endTipSize: {
        ...strokeTipSizeToCanvasItemConversion('tail')
    },
    tipAlign: {
    }
};
