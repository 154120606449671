const get = require('lodash/get');

const {
    createFontLoader,
    updateFontLoader
} = require('./fontLoader');

const baseSubFamilyByFamily = require('./config/baseSubFamilyByFamily');

const capitalize = string => (string.charAt(0).toUpperCase() + string.slice(1));

const getBaseSubFamilyForFamily = (family, genericStyle = 'Regular') => (
    (baseSubFamilyByFamily[family] || {})[genericStyle] ||
    (baseSubFamilyByFamily[family] || {}).Regular ||
    baseSubFamilyByFamily.Default[genericStyle] ||
    baseSubFamilyByFamily.Default.Regular
);

const getStyledSubFamilyFromGenericStyle = (family, genericStyle, subFamilies) => {
    const explicitSubFamily = subFamilies.find(subFamily => subFamily === genericStyle);
    if (explicitSubFamily) {
        return explicitSubFamily;
    }

    const microsoftStyledSubFamily = subFamilies.find(subFamily => subFamily.endsWith(`${family} ${genericStyle}`));
    if (microsoftStyledSubFamily) {
        return microsoftStyledSubFamily;
    }

    const baseSubFamily = getBaseSubFamilyForFamily(family, genericStyle);

    const baseStyledSubFamily = subFamilies.find(subFamily => subFamily.endsWith(`${baseSubFamily} ${genericStyle}`));
    if (baseStyledSubFamily) {
        return baseStyledSubFamily;
    } if (genericStyle === 'Bold Italic') {
        return getStyledSubFamilyFromGenericStyle(family, 'Bold', subFamilies);
    }

    return baseSubFamily;
};

const getGenericStyledFontNameSuffix = (isBold = false, isItalic = false) => {
    if (isBold || isItalic) {
        if (isBold && isItalic) {
            return 'Bold Italic';
        } if (isBold) {
            return 'Bold';
        }
        return 'Italic';
    }
    return 'Regular';
};

const getFontOnServer = ({
    fontName,
    fontFamily,
    fontsRegistry,
    subFamily = getBaseSubFamilyForFamily(fontName),
    suffix = '',
    baseFontFileUrl
}) => {
    if (fontsRegistry && fontsRegistry[fontFamily]) {
        const file = fontsRegistry[fontFamily][subFamily];
        const url = `${baseFontFileUrl}/${encodeURIComponent(file)}`;
        return createFontLoader({
            name: fontName,
            status: 'ready',
            fontFaceName: `${fontFamily} ${suffix}`.trim(),
            fontFaceURL: `url(${url})`,
            file
        });
    }
    return createFontLoader({
        name: fontName,
        status: 'unresolved',
        fontFaceName: `${fontFamily} ${suffix}`.trim()
    });
};

const getImplicitFontSubFamilyFontLoaders = ({
    fontName,
    fontsRegistry,
    baseFontFileUrl
}) => {
    const subFamilyFontLoaders = Object.entries(fontsRegistry)
        .filter(([fontFamily]) => fontName.startsWith(fontFamily))
        .reduce((fontLoaders, [fontFamily, subFamilies]) => {
            const subFamily = capitalize(fontName.substr(fontFamily.length).trim());
            if (subFamilies[subFamily]) {
                fontLoaders.push(getFontOnServer({
                    fontName,
                    fontFamily,
                    fontsRegistry,
                    subFamily,
                    suffix: subFamily,
                    baseFontFileUrl
                }));
            } else {
                const styledSubFamily = getStyledSubFamilyFromGenericStyle(
                    fontFamily,
                    subFamily, Object.keys(subFamilies)
                );
                fontLoaders.push(getFontOnServer({
                    fontName,
                    fontFamily,
                    fontsRegistry,
                    subFamily: styledSubFamily,
                    suffix: subFamily,
                    baseFontFileUrl
                }));
            }
            return fontLoaders;
        }, []);

    if (subFamilyFontLoaders.length === 0) {
        return [createFontLoader({
            name: fontName,
            status: 'unresolved',
            fontFaceName: fontName
        })];
    }

    return subFamilyFontLoaders;
};

const getFetchFontLoaders = ({
    fontName,
    fontsRegistry,
    baseFontFileUrl
}) => {
    const fontLoaders = [];
    if (fontName.split(' ').length > 1 && !fontsRegistry[fontName]) {
        fontLoaders.push(...getImplicitFontSubFamilyFontLoaders({
            fontName,
            fontsRegistry,
            baseFontFileUrl
        }));
    } else {
        fontLoaders.push(getFontOnServer({
            fontName,
            fontFamily: fontName,
            fontsRegistry,
            baseFontFileUrl
        }));
        if (get(fontsRegistry, fontName, null)) {
            const subFamilies = Object.keys(fontsRegistry[fontName]);
            const boldSubFamily = getStyledSubFamilyFromGenericStyle(fontName, 'Bold', subFamilies);
            if (get(fontsRegistry, `${fontName}.${boldSubFamily}`, null)) {
                fontLoaders.push(getFontOnServer({
                    fontName,
                    fontFamily: fontName,
                    fontsRegistry,
                    subFamily: boldSubFamily,
                    suffix: 'Bold',
                    baseFontFileUrl
                }));
            }
            const italicSubFamily = getStyledSubFamilyFromGenericStyle(fontName, 'Italic', subFamilies);
            if (get(fontsRegistry, `${fontName}.${italicSubFamily}`, null)) {
                fontLoaders.push(getFontOnServer({
                    fontName,
                    fontFamily: fontName,
                    fontsRegistry,
                    subFamily: italicSubFamily,
                    suffix: 'Italic',
                    baseFontFileUrl
                }));
            }
            const boldItalicSubFamily = getStyledSubFamilyFromGenericStyle(fontName, 'Bold Italic', subFamilies);
            if (get(fontsRegistry, `${fontName}.${boldItalicSubFamily}`, null)) {
                fontLoaders.push(getFontOnServer({
                    fontName,
                    fontFamily: fontName,
                    fontsRegistry,
                    subFamily: boldItalicSubFamily,
                    suffix: 'Bold Italic',
                    baseFontFileUrl
                }));
            }
        }
    }
    return fontLoaders;
};

const fetchFonts = ({
    fontNames,
    fontsRegistry,
    baseFontFileUrl
}) => fontNames.reduce(
    (fontLoaders, fontName) => [
        ...fontLoaders,
        ...getFetchFontLoaders({
            fontName,
            fontsRegistry,
            baseFontFileUrl
        })
    ],
    []
);

const mergeFontLoadersAndMetrics = ({ loaders, metrics }) => loaders
    .reduce(
        (namedMetrics, { file, fontFaceName }) => {
            const fileMetrics = metrics[file];
            if (fileMetrics) {
                return {
                    ...namedMetrics,
                    [fontFaceName]: fileMetrics
                };
            }
            return namedMetrics;
        },
        {}
    );

const getFontFilesFromLoaders = loaders => loaders
    .map(({
        file
    }) => file);

const getMetricsFromLoaders = ({ loaders, fetchMetrics }) => (
    fetchMetrics(
        getFontFilesFromLoaders(loaders)
    )
        .then(metrics => mergeFontLoadersAndMetrics({ loaders, metrics }))
);

const fetchFontsWithFetchedMetrics = ({
    fontNames,
    fontsRegistry,
    baseFontFileUrl,
    fetchMetrics
}) => {
    const loaders = fetchFonts({
        fontNames,
        fontsRegistry,
        baseFontFileUrl
    });
    return getMetricsFromLoaders({ loaders, fetchMetrics })
        .then(metrics => ({ metrics, loaders }));
};

module.exports = {
    fetchFonts,
    getBaseSubFamilyForFamily,
    getStyledSubFamilyFromGenericStyle,
    updateFontLoader,
    getGenericStyledFontNameSuffix,
    fetchFontsWithFetchedMetrics
};
