import validateKeyExceptions from './validateKeyExceptions';
import defaultsDeepWithExceptions from './defaultsDeepWithExceptions';
import toPairsDeepWithExceptions from './toPairsDeepWithExceptions';
import fromDeepPairs from './fromDeepPairs';
import complementPairsAgainstSource from './complementPairsAgainstSource';

export default (object, ...sources) => {
    if (object) {
        const exceptions = sources.pop();
        validateKeyExceptions(exceptions);
        const source = defaultsDeepWithExceptions(...sources.filter(Boolean).reverse(), exceptions);
        return fromDeepPairs(
            complementPairsAgainstSource(
                toPairsDeepWithExceptions(object, exceptions),
                source
            )
        );
    }
    return object;
};
