export default tip => ({
    toItemUpdate: (value, shapeStyle) => shapeStyle
        .set(`${tip}TipLength`, value)
        .set(`${tip}TipWidth`, value),
    toStyle: shape => {
        const currentTip = shape.get(`stroke.${tip}`, Map());
        if (currentTip.get('length') === currentTip.get('width')) {
            return currentTip.get('length');
        }
        return '';
    }
});
