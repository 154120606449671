import get from 'lodash/get';
import differenceBy from 'lodash/differenceBy';
import { changeDeletionModalState } from '#Actions/plan/plan';
import { updateDeckPropertiesFromPlan } from '#Actions/deck';
import client from '#Utilities/graphql';
import * as types from '#Constants/ActionTypes';

const removeTopicsList = () => ({
    type: types.REMOVE_TOPICS_LIST
});

const setUserSelectedTopics = topics => dispatch => {
    dispatch({
        type: types.SET_USER_SELECTED_TOPICS,
        topics
    });
};

const addUserSelectedTopics = topics => (dispatch, getState) => {
    dispatch({
        type: types.ADD_SELECTED_TOPICS,
        topics,
        topicsOrder: getState().plan.topics.map(topic => topic.topic.id),
        topicsOrderRequired: getState().plan.topics.filter(topic => !topic.optional).map(topic => topic.topic.id)
    });
};

const selectAllTopics = topics => (dispatch, getState) => {
    const alreadySelectedTopics = getState().plan.userSelectedTopics;
    const topicsToAdd = differenceBy(topics, alreadySelectedTopics, 'id');
    dispatch(addUserSelectedTopics(topicsToAdd));
};

const removeUserSelectedTopics = (topics, browseIsOpen = false) => (dispatch, getState) => {
    const deck = getState().deck;

    const topicsAlreadyInDeck = deck.pages.filter(page => topics.some(topic => topic.id === page.topic.id));

    dispatch({
        type: types.REMOVE_SELECTED_TOPICS,
        topics
    });
    if (topicsAlreadyInDeck.length > 0) {
        dispatch(changeDeletionModalState(true, topics, browseIsOpen));
    }
};

const getChangesSinceLastSave = (deckPages, selectedTopics) => {
    let pages = deckPages;
    const removedSinceLastSave = pages.filter(page => !selectedTopics.some(topic => page.topic.id === topic.id));
    const addedSinceLastSave = selectedTopics.filter(topic => !pages.some(page => page.topic.id === topic.id));
    const topicDiff = [...removedSinceLastSave, ...addedSinceLastSave].map(topic => ({ topic: topic.id }));
    pages = pages.map(page => ({ id: page.id }));

    return [
        ...pages,
        ...topicDiff
    ];
};

const forceRemoveUserSelectedTopics = topics => (dispatch, getState) => {
    dispatch({ type: types.FLAG_PROPERTIES_UNSAVED });
    const state = getState();
    let allPages = state.deck.pages;
    let allUserSelectedTopics = state.plan.userSelectedTopics;
    topics.forEach(topicToRemove => {
        if (topicToRemove.id === 'BLANK_TOPIC') return;
        allPages = allPages.filter(page => page.topic.id !== topicToRemove.id);
        allUserSelectedTopics = allUserSelectedTopics.filter(topic => topic.id !== topicToRemove.id);
    });
    const pages = getChangesSinceLastSave(allPages, allUserSelectedTopics);
    const removedPagesOperation = state.deck.pages
        .filter(page => !allUserSelectedTopics
            .some(topic => page.topic.id === topic.id))
        .map(p => ({ operationName: 'REMOVE_PAGE', arguments: { pageId: p.id } }));
    const pagesOps = pages.map(page => {
        if (page.id && !page.topic) {
            return { operationName: 'NO_OP', arguments: { pageId: page.id } };
        }
        return { operationName: 'ADD_PAGE', arguments: { topic: page.topic } };
    });
    const pagesOperations = [
        ...removedPagesOperation,
        ...pagesOps
    ];
    const body = {
        objective: get(state, 'plan.initialObjective.id', null),
        storyboard: get(state, 'plan.initialStoryboard.id', null),
        pagesOperations
    };
    dispatch({
        type: types.REMOVE_SELECTED_TOPICS,
        topics
    });
    return dispatch(updateDeckPropertiesFromPlan(body))
        .then(() => {
            dispatch(changeDeletionModalState(false, {}));
        });
};

const forceToBlankUserSelectedTopics = topics => (dispatch, getState) => {
    dispatch({ type: types.FLAG_PROPERTIES_UNSAVED });
    const state = getState();
    const allPages = state.deck.pages;
    const allSelectedTopics = state.plan.userSelectedTopics;
    let pagesToChange = [];
    topics.forEach(topic => {
        pagesToChange = pagesToChange.concat(allPages.filter(page => page.topic.id === topic.id));
    });
    let pages = getChangesSinceLastSave(allPages, allSelectedTopics);

    pagesToChange.forEach(pageToChange => {
        pages = pages.map(page => {
            if (page.id === pageToChange.id) {
                return { id: page.id, topic: 'BLANK_TOPIC' };
            }
            return page;
        });
    });
    const pagesOperations = pages.map(page => {
        if (page.id && page.topic) {
            return { operationName: 'ADD_TOPIC', arguments: { pageId: page.id, topic: page.topic } };
        } if (page.id !== undefined) {
            return { operationName: 'NO_OP', arguments: { pageId: page.id } };
        }
        return { operationName: 'ADD_PAGE', arguments: { topic: page.topic } };
    });
    const body = {
        objective: get(state, 'plan.initialObjective.id', null),
        storyboard: get(state, 'plan.initialStoryboard.id', null),
        pagesOperations
    };
    dispatch({
        type: types.REMOVE_SELECTED_TOPICS,
        topics
    });
    return dispatch(updateDeckPropertiesFromPlan(body))
        .then(() => {
            dispatch(changeDeletionModalState(false, {}));
        });
};

const removeAllSelectedTopics = () => dispatch => {
    dispatch({ type: types.REMOVE_ALL_SELECTED_TOPICS });
};

const setUserSelectedTopicsFromDeckAndStoryboardTopics = (deckTopics = []) => ({
    type: types.SET_USER_SELECTED_TOPICS,
    topics: deckTopics
});

const setUserUnselectedTopicsFromDeckAndStoryboardTopics = (extractedStoryboardTopics = [], deckTopics = []) => ({
    type: types.SET_USER_UNSELECTED_TOPICS,
    topics: differenceBy(extractedStoryboardTopics, deckTopics, 'id')
});

const fetchTopics = storyboardId => dispatch => {
    if (!storyboardId) {
        return dispatch(removeTopicsList());
    }
    return client.fetchStoryboardTopics(storyboardId)
        .then(topics => {
            dispatch({
                type: types.SET_TOPICS_LIST,
                topics
            });
        })
        .catch(err => console.warn(err));
};

const fetchTopicsForBrowse = storyboardId => dispatch => {
    if (!storyboardId) {
        return null;
    }
    return client.fetchStoryboardTopics(storyboardId)
        .then(topics => {
            dispatch({
                type: types.SET_BROWSE_TOPICS_LIST,
                topics
            });
        })
        .catch(err => console.warn(err));
};

const fetchAndSetTopicsFromDeck = (storyboardId, deck) => dispatch => {
    if (!storyboardId) {
        return dispatch(removeTopicsList());
    }
    return client.fetchStoryboardTopics(storyboardId)
        .then(topics => {
            dispatch({
                type: types.SET_TOPICS_LIST,
                topics
            });
            if (deck.pages.length > 0) {
                const deckTopics = deck.pages.map(page => page.topic);
                const recommendedStoryboardTopics = topics.filter(topic => !topic.optional).map(topic => topic.topic);
                dispatch(setUserSelectedTopicsFromDeckAndStoryboardTopics(deckTopics));
                dispatch(setUserUnselectedTopicsFromDeckAndStoryboardTopics(recommendedStoryboardTopics, deckTopics));
            }
        })
        .catch(err => console.warn(err));
};

const selectPage = page => dispatch => {
    dispatch({
        type: types.SET_SELECTED_PAGE,
        page
    });
};

export {
    fetchTopics,
    fetchTopicsForBrowse,
    fetchAndSetTopicsFromDeck,
    setUserSelectedTopics,
    addUserSelectedTopics,
    removeUserSelectedTopics,
    removeTopicsList,
    selectPage,
    removeAllSelectedTopics,
    forceToBlankUserSelectedTopics,
    forceRemoveUserSelectedTopics,
    selectAllTopics
};
