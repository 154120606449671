const { fabric } = require('fabric');
const {
    defaultCursorZoneFormat,
    SELECTION_CURSOR_ZONE_SIZE,
    MOVE_CURSOR_ZONE_SIZE
} = require('./config/defaultCursorZoneAttributes.config');

const SelectionCursorZone = fabric.util.createClass(fabric.Rect, {
    type: 'cursorZone',

    initialize(side, table) {
        this.callSuper('initialize', { ...defaultCursorZoneFormat });
        this.table = table;
        this.side = side;
        switch (side) {
            case 'top':
                this.width = table.width;
                this.height = SELECTION_CURSOR_ZONE_SIZE;
                this.top = table.getAbsoluteTop() - (table.height / 2) - MOVE_CURSOR_ZONE_SIZE;
                this.left = table.getAbsoluteLeft();
                this.hoverCursor = 's-resize';
                break;
            case 'bottom':
                this.width = table.width;
                this.height = SELECTION_CURSOR_ZONE_SIZE;
                this.top = table.top + (table.height / 2) + MOVE_CURSOR_ZONE_SIZE;
                this.left = table.getAbsoluteLeft();
                this.hoverCursor = 'n-resize';
                break;
            case 'left':
                this.width = SELECTION_CURSOR_ZONE_SIZE;
                this.height = table.height;
                this.top = table.getAbsoluteTop();
                this.left = table.getAbsoluteLeft() - (table.width / 2) - MOVE_CURSOR_ZONE_SIZE;
                this.hoverCursor = 'e-resize';
                break;
            default:
                this.width = SELECTION_CURSOR_ZONE_SIZE;
                this.height = table.height;
                this.top = table.getAbsoluteTop();
                this.left = table.getAbsoluteLeft() + (table.width / 2) + MOVE_CURSOR_ZONE_SIZE;
                this.hoverCursor = 'w-resize';
        }
        this.originX = 'center';
        this.originY = 'center';
        this.selectCells = this.selectCells.bind(this);
        this.onMouseUp = this.onMouseUp.bind(this);
        this.updatePositionAndSize = this.updatePositionAndSize.bind(this);
        this.on('mousedown', e => {
            e.target = this;
            this.selectedCells = [];
            this.canvas.selection = false;
            document.addEventListener('mousemove', this.selectCells);
            document.addEventListener('mouseup', this.onMouseUp);
            if (
                !this.canvas.getActiveObject() ||
                this.canvas.getActiveObject().id !== this.table.id
            ) {
                this.canvas.setActiveObject(this.table);
                this.canvas.fire('selection:created', e);
            }
            if (!e.e.shiftKey && !e.e.ctrlKey && !e.e.metaKey) {
                this.table.clearSelection(false);
            }
            this.selectCells(e.e, !!e.e.shiftKey, (!!e.e.ctrlKey || !!e.e.metaKey));
        });
    },

    selectCells(e, appendToActive = true, toggle = false) {
        const localPoint = this.table.getLocalPointer(e);
        let groupOffset = {
            x: 0,
            y: 0
        };
        if (this.table.group) {
            groupOffset = this.table.group.getCenterPoint();
        }
        const relativeLocalPoint = {
            x: localPoint.x - (this.table.width / 2) - groupOffset.x,
            y: localPoint.y - (this.table.height / 2) - groupOffset.y
        };
        const cells = this.table.getCellsOnAxis(
            ['left', 'right'].includes(this.side),
            relativeLocalPoint
        );
        this.selectedCells = [];
        this.selectedCells.push(...cells);
        this.selectedCells.forEach((cell, index) => this.table.addCellToSelection(
            cell,
            (appendToActive || index !== 0) && !toggle,
            toggle
        ));
    },

    updatePositionAndSize() {
        switch (this.side) {
            case 'top':
                this.width = this.table.width;
                this.height = SELECTION_CURSOR_ZONE_SIZE;
                this.top = this.table.getAbsoluteTop() - (this.table.height / 2) - MOVE_CURSOR_ZONE_SIZE;
                this.left = this.table.getAbsoluteLeft();
                break;
            case 'bottom':
                this.width = this.table.width;
                this.height = SELECTION_CURSOR_ZONE_SIZE;
                this.top = this.table.top + (this.table.height / 2) + MOVE_CURSOR_ZONE_SIZE;
                this.left = this.table.getAbsoluteLeft();
                break;
            case 'left':
                this.width = SELECTION_CURSOR_ZONE_SIZE;
                this.height = this.table.height;
                this.top = this.table.getAbsoluteTop();
                this.left = this.table.getAbsoluteLeft() - (this.table.width / 2) - MOVE_CURSOR_ZONE_SIZE;
                break;
            default:
                this.width = SELECTION_CURSOR_ZONE_SIZE;
                this.height = this.table.height;
                this.top = this.table.getAbsoluteTop();
                this.left = this.table.getAbsoluteLeft() + (this.table.width / 2) + MOVE_CURSOR_ZONE_SIZE;
        }
        this.setCoords();
    },

    onMouseUp() {
        document.removeEventListener('mousemove', this.selectCells);
        document.removeEventListener('mouseup', this.onMouseUp);
        this.canvas.selection = true;
        this.canvas.fire('table:cells:selected', {
            table: this.table.id,
            cells: this.table.getSelectedCells().map(cell => cell.id)
        });
    }
});

module.exports = SelectionCursorZone;
