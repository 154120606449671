const { Map, List } = require('immutable');
const { getDefaultPlaceholderStroke } = require('../fabric-adapter/utilities/dash');
const {
    BORDERS_COMPUTE_METHOD_FOR_PROPERTY,
    CANVAS_STATE_ITEMS_COMPUTE_METHOD_FOR_PROPERTY,
    CANVAS_STATE_ROTATION_COMPUTE_METHOD_FOR_PROPERTY,
    CANVAS_STATE_TRANSFORM_COMPUTE_METHOD_FOR_PROPERTY,
    CANVAS_STATE_BOUNDING_BOX_COMPUTE_METHOD_FOR_PROPERTY,
    CANVAS_STATE_TEXTBODIES_COMPUTE_METHOD_FOR_PROPERTY,
    CANVAS_STATE_SCALE_COMPUTE_METHOD_FOR_PROPERTY,
    TABLE_COMPUTE_METHOD_FOR_PROPERTY,
    TABLE_COMPUTE_METHOD_FOR_STRUCTURE_PROPERTIES,
    CANVAS_STATE_LOCK_ASPECT_RATIO_COMPUTE_METHOD_FOR_PROPERTY
} = require('./constants');

const CanvasStateSelectors = require('../Canvas/CanvasStateSelectors');

const computeTableStructureProperties = (
    canvasItems,
    properties
) => {
    const isSelectionInTableMode = canvasItems.every(canvasItem => (
        ['table', 'cell', 'border']
    ).includes((canvasItem.get('type') || '').toLowerCase()));
    if (isSelectionInTableMode) {
        const tables = canvasItems
            .filter(canvasItem => (canvasItem.get('type') || '').toLowerCase() === 'table');
        return Object.entries(TABLE_COMPUTE_METHOD_FOR_STRUCTURE_PROPERTIES)
            .reduce(
                (
                    selectionProperties,
                    [key, method]
                ) => selectionProperties.set(key, method(tables)),
                properties
            );
    }
    return properties;
};

const computeBorderSelectionProperties = (borders = [], computedSelectionProperties) => Object
    .entries(BORDERS_COMPUTE_METHOD_FOR_PROPERTY)
    .reduce(
        (selectionProperties, [key, method]) => {
            if (!(method instanceof Function)) {
                throw new Error(`${key} method is not a function`);
            }
            return selectionProperties.set(key, method(borders));
        },
        computedSelectionProperties
    );

exports.computeSelectionProperties = (canvasStateItems = new List(), textSelection) => {
    if (canvasStateItems.size > 0) {
        const boundingBox = CanvasStateSelectors.getBoundingBoxOfItems(canvasStateItems);
        let computedSelectionProperties = Object
            .entries(CANVAS_STATE_BOUNDING_BOX_COMPUTE_METHOD_FOR_PROPERTY)
            .reduce(
                (selectionProperties, [key, method]) => {
                    if (!(method instanceof Function)) {
                        throw new Error(`${key} method is not a function`);
                    }
                    return selectionProperties.set(key, method(boundingBox));
                },
                new Map()
            );

        const flattenCanvasStateItems = CanvasStateSelectors.getFlattenShapes(canvasStateItems);

        const borders = flattenCanvasStateItems
            .filter(canvasItem => canvasItem.get('type') === 'border');

        computedSelectionProperties = computeBorderSelectionProperties(
            borders,
            computedSelectionProperties
        );

        const shapeTypesWithoutTextBody = [
            'group',
            'table',
            'border',
            'cell'
        ];

        const textBodies = flattenCanvasStateItems.filter(item => (
            !shapeTypesWithoutTextBody.includes((item.get('type') || '').toLowerCase()) &&
            item.get('textBody')
        ))
            .map(item => {
                const textBody = item.get('textBody');
                if (textBody.get('text') && textBody.get('text').length > 0) {
                    return textBody.withMutations(tb => {
                        tb.set('parentType', item.get('type'))
                            .set('placeholderBorderStroke', 0);
                    });
                }
                const textBodyPlaceholder = item.get('textBodyPlaceholder');
                if (textBodyPlaceholder) {
                    return textBodyPlaceholder.withMutations(tb => {
                        tb.set('parentType', item.get('type'))
                            .set('placeholderBorderStroke', getDefaultPlaceholderStroke().strokeWidth);
                    });
                }
                return Map();
            });

        computedSelectionProperties = Object
            .entries({
                ...CANVAS_STATE_SCALE_COMPUTE_METHOD_FOR_PROPERTY,
                ...CANVAS_STATE_ROTATION_COMPUTE_METHOD_FOR_PROPERTY,
                ...CANVAS_STATE_LOCK_ASPECT_RATIO_COMPUTE_METHOD_FOR_PROPERTY
            })
            .reduce(
                (selectionProperties, [key, method]) => selectionProperties.set(key, method(canvasStateItems)),
                computedSelectionProperties
            );

        const textSelectionToCompute = (textBodies.size === 1 && textSelection?.editing === true) ?
            textSelection :
            undefined;
        computedSelectionProperties = Object
            .entries(CANVAS_STATE_TEXTBODIES_COMPUTE_METHOD_FOR_PROPERTY)
            .reduce(
                (selectionProperties, [key, method]) => {
                    if (!(method instanceof Function)) {
                        throw new Error(`${key} method is not a function`);
                    }
                    return selectionProperties.set(
                        key,
                        method(textBodies, textSelectionToCompute)
                    );
                },
                computedSelectionProperties
            );

        computedSelectionProperties = Object
            .entries(CANVAS_STATE_TRANSFORM_COMPUTE_METHOD_FOR_PROPERTY)
            .reduce(
                (
                    selectionProperties,
                    [key, method]
                ) => selectionProperties.set(key, method(flattenCanvasStateItems)),
                computedSelectionProperties
            );

        computedSelectionProperties = Object.entries(CANVAS_STATE_ITEMS_COMPUTE_METHOD_FOR_PROPERTY)
            .reduce(
                (selectionProperties, [key, method]) => selectionProperties.set(key, method(flattenCanvasStateItems)),
                computedSelectionProperties
            );

        const everySelectedCanvasItemIsTable = canvasStateItems.every(item => (item.get('type') || '').toLowerCase() === 'table');
        if (everySelectedCanvasItemIsTable) {
            computedSelectionProperties = Object.entries(TABLE_COMPUTE_METHOD_FOR_PROPERTY)
                .reduce(
                    (
                        selectionProperties,
                        [key, method]
                    ) => selectionProperties.set(key, method(canvasStateItems)),
                    computedSelectionProperties
                );
        }

        computedSelectionProperties = computeTableStructureProperties(
            canvasStateItems,
            computedSelectionProperties
        );

        return computedSelectionProperties;
    }
    return new Map();
};

exports.computeTableProperties = table => Object.entries(TABLE_COMPUTE_METHOD_FOR_PROPERTY)
    .reduce(
        (
            selectionProperties,
            [key, method]
        ) => selectionProperties.set(key, method([table])),
        new Map({})
    );
