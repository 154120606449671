const isObject = require('lodash/isObject');
const isArray = require('lodash/isArray');
const isEqual = require('lodash/isEqual');

module.exports = (exceptions = []) => (value, source, key) => {
    if (exceptions.includes(key)) {
        if (isEqual(value, source)) {
            return value;
        }
        return null;
    }
    if (!isObject(value) || !isObject(source)) {
        if (isObject(value) || isObject(source)) {
            return null;
        }
        return value === source ? value : null;
    }
    if (isArray(value) || isArray(source)) {
        if (isArray(value) && isArray(source)) {
            return [...value, ...source];
        }
        return null;
    }
    if (value === undefined || source === undefined) {
        return null;
    }
    return undefined;
};
