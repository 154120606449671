const RESIZE_CURSOR_ZONE_SIZE = 6;

const SELECTION_CURSOR_ZONE_SIZE = 10;

const MOVE_CURSOR_ZONE_SIZE = 8;

const defaultCursorZoneFormat = {
    opacity: 0.0,
    fill: 'red',
    selectable: false,
    objectCaching: false,
    excludeFromExport: true
};

module.exports = {
    RESIZE_CURSOR_ZONE_SIZE,
    SELECTION_CURSOR_ZONE_SIZE,
    MOVE_CURSOR_ZONE_SIZE,
    defaultCursorZoneFormat
};
