import UUID from 'uuid/v4';
import moment from 'moment';

const prefix = 'DECKSIGN';

const getItem = key => window.localStorage.getItem(`${prefix}_${key}`) || null;

const setItem = (key, value) => window.localStorage.setItem(`${prefix}_${key}`, value);

const getObject = key => (getItem(key) !== 'undefined' ? JSON.parse(getItem(key) || 'null') : null);

const setObject = (key, value) => setItem(key, JSON.stringify(value));

const getCurrentDeck = () => getObject('currentDeck');

const setCurrentDeck = currentDeck => {
    setObject('currentDeck', currentDeck);
};

const setPublicToken = () => {
    setObject('publicToken', { uuid: UUID().toString(), expiration_date: moment().add(3, 'days') });
};

const getPublicToken = () => {
    const token = getObject('publicToken');
    if (token === null || token.expiration_date < new Date()) {
        setPublicToken();
        return getObject('publicToken').uuid;
    }
    return token.uuid;
};

const updatePageFromCurrentDeck = page => setCurrentDeck({
    ...getCurrentDeck(),
    pages: getCurrentDeck().pages.map(cursor => (
        cursor._id === page._id ?
            page :
            cursor
    ))
});

export {
    getItem,
    setItem,
    getObject,
    setObject,
    setPublicToken,
    getPublicToken,
    getCurrentDeck,
    setCurrentDeck,
    updatePageFromCurrentDeck
};
