import { Map } from 'immutable';
import complementAgainstSources from '../../utilities/complementAgainstSources';
import defaultsDeepWithExceptions from '../../utilities/defaultsDeepWithExceptions';
import { DEFAULT_TEXT_BODY_STYLE } from '../Text/Config/defaultStyles';
import textBodyStyles from '../Text/Config/textBodyStyles';

const textBodyStyleProperties = [
    'styleId',
    'autoFitText',
    'autoFitShape',
    'margins',
    'textDirection',
    'verticalAlign'
];

const getRenderStyleProperty = (shape, textBody, propertyPath) => defaultsDeepWithExceptions(
    getStyle(textBody),
    textBody?.getIn(['assignedStyles', 'textBody']) || Map(),
    loadDefaultTextBodyStyleConfiguration(shape.get('type')),
    []
).getIn(propertyPath);

const setComplementTextBodyStyleAsTextBodyStyle = textBody => textBody
    .set(
        'style',
        complementAgainstSources(
            textBody.get('style') || Map(),
            DEFAULT_TEXT_BODY_STYLE,
            textBody.getIn(['assignedStyles', 'textBody']) || Map(),
            []
        )
    );

const getStyle = textBody => (textBody ? Map({
    autoFitText: textBody.get('autoFitText'),
    autoFitShape: textBody.get('autoFitShape'),
    textDirection: textBody.get('textDirection'),
    verticalAlign: textBody.get('verticalAlign'),
    margins: textBody.get('margins')
}) : DEFAULT_TEXT_BODY_STYLE);

const loadDefaultTextBodyStyleConfiguration = type => (
    (!!type && Object.prototype.hasOwnProperty.call(textBodyStyles, type) ?
        textBodyStyles[type] :
        textBodyStyles.DEFAULT).get('DEFAULT_TEXT_BODY_STYLE')
);

export {
    getRenderStyleProperty,
    setComplementTextBodyStyleAsTextBodyStyle,
    textBodyStyleProperties
};
