const isNil = require('lodash/isNil');

const Style = require('./Style');

module.exports = class extends Style {
    static fromJSON(json) {
        return new this(json);
    }

    static get properties() {
        return [
            ...super.properties,
            'align',
            'bullet',
            'indent',
            'lineSpacing',
            'padding',
            'level'
        ];
    }

    static get atomicProperties() {
        return [
            'bullet.style.color'
        ];
    }

    constructor({
        align,
        bullet,
        indent,
        lineSpacing,
        padding,
        level,
        ...baseStyle
    }) {
        super(baseStyle);
        this.align = align;
        this.bullet = bullet;
        this.indent = indent;
        this.lineSpacing = lineSpacing;
        this.padding = padding;
        this.level = level;
    }

    toJSON() {
        return {
            ...super.toJSON(),
            align: this.align,
            bullet: this.bullet,
            indent: this.indent,
            lineSpacing: this.lineSpacing,
            padding: this.padding,
            level: this.level
        };
    }

    get align() {
        return this._align;
    }

    set align(align) {
        if (!isNil(align)) {
            this._align = align;
        }
    }

    get bullet() {
        return this._bullet;
    }

    set bullet(bullet) {
        if (!isNil(bullet)) {
            this._bullet = bullet;
        }
    }

    get indent() {
        return this._indent;
    }

    set indent(indent) {
        if (!isNil(indent)) {
            this._indent = indent;
        }
    }

    get lineSpacing() {
        return this._lineSpacing;
    }

    set lineSpacing(lineSpacing) {
        if (!isNil(lineSpacing)) {
            this._lineSpacing = lineSpacing;
        }
    }

    get padding() {
        return this._padding;
    }

    set padding(padding) {
        if (!isNil(padding)) {
            this._padding = padding;
        }
    }

    get level() {
        return this._level;
    }

    set level(level) {
        if (!isNil(level)) {
            this._level = level;
        }
    }
};
