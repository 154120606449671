import * as types from '#Constants/ActionTypes';

const storyboards = (state = [], action) => {
    switch (action.type) {
        case types.SET_STORYBOARDS_LIST:
            return action.storyboards;
        case types.REMOVE_STORYBOARDS_LIST:
            return [];
        case types.SET_FILTERED_STORYBOARDS_LIST:
            return action.filteredStoryboards;
        default:
            return state;
    }
};

const initialStoryboard = (state = {}, action) => {
    switch (action.type) {
        case types.SET_INITIAL_STORYBOARD:
            return action.initialStoryboard;
        case types.FETCH_DECK_SUCCESS:
            if (action.deck && action.deck.storyboard) {
                return action.deck.storyboard;
            }
            return state;
        case types.REMOVE_INITIAL_STATE:
            return {};
        default:
            return state;
    }
};

const selectedStoryboard = (state = {}, action) => {
    switch (action.type) {
        case types.SET_SELECTED_STORYBOARD:
            return action.selectedStoryboard;
        case types.FETCH_DECK_SUCCESS:
            if (action.deck && action.deck.storyboard) {
                return action.deck.storyboard;
            }
            return state;
        case types.REMOVE_INITIAL_STATE:
            return {};
        default:
            return state;
    }
};

export {
    storyboards,
    selectedStoryboard,
    initialStoryboard
};
