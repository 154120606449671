import { fromJS } from 'immutable';
import defaultFromFabricToCanvasState from './default';

const fromFabricToCanvasState = (fabricShape, canvasState) => fromJS({
    ...defaultFromFabricToCanvasState(fabricShape, canvasState),
    type: 'Rectangle',
    rx: fabricShape.rx,
    ry: fabricShape.ry
});

export default fromFabricToCanvasState;
