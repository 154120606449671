const isNil = require('lodash/isNil');
const get = require('lodash/get');
const pick = require('lodash/pick');

const TextBodyStyle = require('../Shape/Styles/TextBodyStyle');
const ParagraphStyle = require('../Shape/Styles/ParagraphStyle');

const shapeUtilities = {
    styleToRunStyle(fabricStyle) {
        let style = {};
        if (fabricStyle.fill) {
            style.color = fabricStyle.fill;
        }
        if (fabricStyle.fontFamily ||
            fabricStyle.fontSize ||
            fabricStyle.fontStyle ||
            fabricStyle.fontWeight
        ) {
            style.font = {};
            if (fabricStyle.fontFamily) {
                style.font.family = fabricStyle.fontFamily;
            }
            if (fabricStyle.fontSize) {
                style.font.size = fabricStyle.fontSize;
            }
            if (fabricStyle.fontStyle) {
                style.font.style = fabricStyle.fontStyle;
            }
            if (fabricStyle.fontWeight) {
                style.font.weight = fabricStyle.fontWeight;
            }
        }
        if (Object.keys(fabricStyle).includes('textTransform')) {
            style.textTransform = fabricStyle.textTransform;
        }
        if (fabricStyle.opacity) {
            style.opacity = fabricStyle.opacity;
        }
        if (fabricStyle.assignedParagraphStyle) {
            let assignedParagraphStyle = shapeUtilities
                .styleToRunStyle(fabricStyle.assignedParagraphStyle);
            assignedParagraphStyle = {
                ...assignedParagraphStyle,
                ...assignedParagraphStyle.paragraph
            };
            style.assignedStyle = pick(assignedParagraphStyle, TextBodyStyle.properties);
            style.assignedParagraphStyle = pick(assignedParagraphStyle, ParagraphStyle.properties);
        }
        if (fabricStyle.assignedRunStyle) {
            style.assignedRunStyle = shapeUtilities
                .styleToRunStyle(fabricStyle.assignedRunStyle);
        }
        if (fabricStyle.charSpacing) {
            style.characterSpacing = fabricStyle.charSpacing;
        }
        if (fabricStyle.overline !== null && fabricStyle.overline !== undefined) {
            style.overline = fabricStyle.overline;
        }
        if (fabricStyle.linethrough !== null && fabricStyle.linethrough !== undefined) {
            style.linethrough = fabricStyle.linethrough;
        }
        if (fabricStyle.underline !== null && fabricStyle.underline !== undefined) {
            style.underline = fabricStyle.underline;
        }
        if (fabricStyle.subscript !== null && fabricStyle.subscript !== undefined) {
            style.subscript = fabricStyle.subscript;
        }
        if (fabricStyle.superscript !== null && fabricStyle.superscript !== undefined) {
            style.superscript = fabricStyle.superscript;
        }
        if (fabricStyle.textSelection) {
            style.textSelection = fabricStyle.textSelection;
        }
        if (fabricStyle.bullets) {
            style = shapeUtilities.setParagraphUpdateProperty(
                style,
                'bullet',
                shapeUtilities.styleToBullet(fabricStyle)
            );
        }
        if (fabricStyle.bullet) {
            style.bullet = fabricStyle.bullet;
        }
        if (fabricStyle.indent) {
            style = shapeUtilities.setParagraphUpdateProperty(style, 'indent', fabricStyle.indent);
        }
        if (fabricStyle.lineHeight) {
            style = shapeUtilities.setParagraphUpdateProperty(style, 'lineSpacing', fabricStyle.lineHeight);
        }
        if (!isNil(fabricStyle.level)) {
            style = shapeUtilities.setParagraphUpdateProperty(style, 'level', fabricStyle.level);
        }
        if (!isNil(fabricStyle.levelStyle)) {
            style.levelStyle = fabricStyle.levelStyle;
        }

        if (fabricStyle.margins) {
            style.margins = fabricStyle.margins;
        }

        const padding = shapeUtilities.styleToPadding(fabricStyle);

        if (Object.keys(padding).length) {
            style = shapeUtilities.setParagraphUpdateProperty(style, 'padding', padding);
        }

        if (fabricStyle.align || fabricStyle.textAlign) {
            style = shapeUtilities.setParagraphUpdateProperty(
                style,
                'align',
                fabricStyle.align || fabricStyle.textAlign
            );

            style.align = fabricStyle.align || fabricStyle.textAlign;
        }

        if (fabricStyle.verticalAlign) {
            style = shapeUtilities.setParagraphUpdateProperty(style, 'verticalAlign', fabricStyle.verticalAlign);
            style.verticalAlign = fabricStyle.verticalAlign;
        }

        if (fabricStyle.textDirection) {
            style = shapeUtilities.setParagraphUpdateProperty(style, 'textDirection', fabricStyle.textDirection);
            style.textDirection = fabricStyle.textDirection;
        }

        if (fabricStyle.autoFitShape) {
            style.autoFitShape = fabricStyle.autoFitShape;
        }

        if (fabricStyle.autoFitText) {
            style.autoFitText = fabricStyle.autoFitText;
        }

        if (fabricStyle.listUpdate) {
            style.listUpdate = ['allLevels', 'level1', 'level2', 'level3', 'level4', 'level5'].reduce((listUpdate, key) => {
                if (fabricStyle.listUpdate[key]) {
                    listUpdate[key] = shapeUtilities.styleToRunStyle(fabricStyle.listUpdate[key]);
                }
                return listUpdate;
            }, {});
            style.listUpdate.onlyBulletUpdate = fabricStyle.listUpdate.onlyBulletUpdate || false;
            style.listUpdate.type = fabricStyle.listUpdate.type;
            style.listUpdate.itemType = fabricStyle.listUpdate.itemType;
            if (fabricStyle.assignedRunStyle) {
                style.assignedRunStyle = {
                    ...style.assignedRunStyle,
                    font: style.listUpdate.level1 ?
                        style.listUpdate.level1.font :
                        style.listUpdate.allLevels.font
                };
            }
        }
        if (fabricStyle.levelStyle) {
            style.levelStyle = shapeUtilities.styleToRunStyle(fabricStyle.levelStyle);
        }

        return style;
    },

    styleToBullet(fabricStyle) {
        return {
            ...fabricStyle.bullets
        };
    },

    styleToPadding(fabricStyle) {
        return ['bottom', 'left', 'right', 'top']
            .filter(side => !isNil(fabricStyle[`spacing-${side}`]))
            .reduce((currentPadding, side) => ({
                ...currentPadding,
                [side]: fabricStyle[`spacing-${side}`]
            }), {});
    },

    setParagraphUpdateProperty(currentUpdate, property, value) {
        currentUpdate.paragraph = {
            ...currentUpdate.paragraph,
            [property]: value
        };

        return currentUpdate;
    },

    styleToParagraphStyle(fabricStyle) {
        const style = {};
        if (fabricStyle.textAlign) {
            style.align = fabricStyle.textAlign;
        }
        if (fabricStyle.lineHeight) {
            style.lineSpacing = fabricStyle.lineHeight;
        }
        return style;
    },

    getCombinedLineCharacterStyles(textFormat) {
        const filteredEmptyLineStyles = textFormat.styles.filter(
            lineStyle => lineStyle.length !== 0
        );
        const combinedLineCharacterStyles = [].concat(
            ...filteredEmptyLineStyles.map(style => (
                Object.keys(style || {}).length > 0 ? style : null
            ))
        );
        return combinedLineCharacterStyles;
    },
    snapToAngle(angle, zoom) {
        const lockToAngles = [45, 90, 135, 180, 225, 270, 315, 360];
        const deltaSnap = Math.min(10, 5 / zoom);

        let currentAngle = angle;

        if (currentAngle < deltaSnap) {
            currentAngle += 360;
        }

        for (let i = 0; i < lockToAngles.length; i += 1) {
            if (
                lockToAngles[i] - deltaSnap < currentAngle &&
                lockToAngles[i] + deltaSnap > currentAngle
            ) {
                return lockToAngles[i];
            }
        }

        return currentAngle;
    },
    offsetTargetWithOriginalParentGroup(targetRelativePosition, originalParentPosition) {
        targetRelativePosition.left -= get(originalParentPosition, 'left', 0);
        targetRelativePosition.top -= get(originalParentPosition, 'top', 0);
        return targetRelativePosition;
    }
};

module.exports = shapeUtilities;
