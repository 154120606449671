const { List, Map } = require('immutable');

class TreeNode {
    _children = new List();

    constructor(shape, children = []) {
        this.shape = shape;
        this._children = new List(children);

        if (!shape) {
            throw new Error('Shape should be defined');
        }
    }

    get children() {
        return this._children;
    }

    set children(children) {
        this._children = new List(children);
    }

    getShapeId() {
        return this.shape.id;
    }

    getChildrenShapes() {
        return this._children.map(child => child.shape).toArray();
    }

    getChildrenIds() {
        return this._children.map(child => child.shape.id).toArray();
    }

    static generateTree(shape) {
        if (!shape) {
            return undefined;
        }

        if (shape?.constructor?.name === 'Group') {
            return new TreeNode(shape, shape.type.toLowerCase() === 'group' ?
                shape.shapes.map(obj => TreeNode.generateTree(obj)) :
                []);
        }

        if (Map.isMap(shape)) {
            return new TreeNode(shape.toJS(), shape.get('type').toLowerCase() === 'group' ?
                shape.get('shapes').map(obj => TreeNode.generateTree(obj)) :
                []);
        }

        return new TreeNode(shape, shape.type.toLowerCase() === 'group' ?
            shape._objects.reduce((objects, currentObject) => {
                if (currentObject.type === 'activeSelection') {
                    return [
                        ...objects,
                        ...currentObject._objects.map(obj => TreeNode.generateTree(obj))
                    ];
                }
                return [...objects, TreeNode.generateTree(currentObject)];
            }, []) : []);
    }

    static generateTreeFromChildren(shape) {
        if (!shape || !shape.group) return null;

        let root = shape;

        while (root.group) {
            root = root.group;
        }

        return this.generateTree(root);
    }

    static findNode(tree, id) {
        if (!tree) return null;
        if (tree.shape.id === id) return tree;

        // eslint-disable-next-line no-restricted-syntax
        for (const child of tree.children) {
            const foundNode = TreeNode.findNode(child, id, tree);
            if (foundNode) {
                return foundNode;
            }
        }

        return null;
    }

    static getParent(tree, id, parent = null) {
        if (!tree) return null;
        if (tree.shape.id === id) {
            return parent;
        }

        // eslint-disable-next-line no-restricted-syntax
        for (const child of tree.children) {
            const foundParent = TreeNode.getParent(child, id, tree);
            if (foundParent) {
                return foundParent;
            }
        }

        return null;
    }

    static getAllParents(tree, id, parents = []) {
        if (!tree) return [];
        if (tree.shape.id === id) {
            return parents;
        }

        // eslint-disable-next-line no-restricted-syntax
        for (const child of tree.children) {
            const foundParents = TreeNode.getAllParents(child, id, [...parents, tree]);
            if (foundParents.length) {
                return foundParents;
            }
        }

        return [];
    }

    static getSiblings(tree, id, parent = null) {
        if (!tree) return [];
        if (tree.shape.id === id && parent) {
            return [...parent.children.filter(child => child.shape.id !== id)];
        }

        // eslint-disable-next-line no-restricted-syntax
        for (const child of tree.children) {
            const foundSiblings = TreeNode.getSiblings(child, id, tree);
            if (foundSiblings.length) {
                return foundSiblings;
            }
        }

        return [];
    }
}

module.exports = TreeNode;
