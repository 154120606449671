import { createBrowserHistory } from 'history';
import { createStore, applyMiddleware, compose } from 'redux';
import Immutable from 'immutable';
import thunk from 'redux-thunk';
import reduxCatch from 'redux-catch';
import { routerMiddleware } from 'connected-react-router';
import { displayError } from '#Actions/error/error';
import * as types from '#Constants/ActionTypes';
import { addPrevPathNameToLocationChange } from '#Utilities/middlewares';
import createRootReducer from './reducers/index';

const reduxErrorHandler = (error, getState, lastAction, dispatch) => {
    switch (lastAction.type) {
        case types.SPLIT_SELECTED_CELLS:
            dispatch({
                type: types.SPLIT_SELECTED_CELLS_ERROR,
                error
            });
            break;
        default:
            dispatch(displayError(error));
            break;
    }
};

const actionSanitizer = action => {
    const message = `This part of the payload is removed by default for
    performance concerns. You can include it back by editing the actionSanitizer
    in the 'app/client/configureStore.js' file.
    If you do so please do not commit the patch, this should remain the default
    for everyone`;
    switch (action.type) {
        case types.UPDATE_CANVAS_STATE:
            return {
                ...action,
                updatedState: message
            };
        case 'GET_FONTS_REGISTRY':
            return {
                ...action,
                fonts: message
            };
        case types.LOAD_PAGE:
            return {
                ...action,
                canvasState: message,
                page: {
                    ...action.page,
                    shapes: message,
                    layout: {
                        ...action.page.layout,
                        shapes: message
                    }
                }
            };
        case types.SET_THUMBNAIL:
            return {
                ...action,
                thumbnail: message
            };
        default:
            return action;
    }
};

const stateSanitizer = state => {
    const message = `This part of the state is removed by default for
    performance concerns. You can include it back by editing the stateSanitizer
    in the 'app/client/configureStore.js' file.
    If you do so please do not commit the patch, this should remain the default
    for everyone`;
    return {
        ...state,
        build: {
            ...state.build,
            page: state.build.page && {
                ...state.build.page,
                shapes: message,
                layout: {
                    ...state.build.page.layout,
                    shapes: message
                }
            },
            fonts: {
                ...state.build.fonts,
                registry: message
            },
            canvasState: message
        },
        deck: state.deck && {
            ...state.deck,
            pages: state.deck.pages && state.deck.pages.map(page => ({
                ...page,
                thumbnail: page.thumbnail && message
            }))
        },
        history: state.history && {
            ...state.history,
            undoStack: state.history.undoStack && state.history.undoStack.map(action => ({
                ...action,
                updatedState: message
            })),
            redoStack: state.history.redoStack && state.history.redoStack.map(action => ({
                ...action,
                updatedState: message
            }))
        }
    };
};

/* eslint-disable no-underscore-dangle */
const composeEnhancers =
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
            trace: true,
            serialize: {
                immutable: Immutable
            },
            features: {
                pause: true,
                dispatch: true,
                jump: true
            },
            actionSanitizer,
            stateSanitizer
        }) :
        compose;
/* eslint-enable */

export const history = createBrowserHistory();

const configureStore = () => {
    const middlewares = [
        reduxCatch(reduxErrorHandler),
        thunk,
        routerMiddleware(history),
        addPrevPathNameToLocationChange
    ];

    const enhancer = composeEnhancers(applyMiddleware(...middlewares));

    const store = createStore(
        createRootReducer(history),
        enhancer
    );

    if (module.hot) {
        module.hot.accept('./reducers', () => {
            store.replaceReducer(createRootReducer(history));
        });
    }
    if (process.env.REACT_APP_PERF_TEST_ENABLED && !window.store) {
        window.store = store;
    }
    return store;
};

export default configureStore;
