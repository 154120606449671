import * as types from '#Constants/ActionTypes';

const initialState = {
    lastSplitTime: 0,
    isPaused: true,
    activePageId: ''
};

export const sessionTimer = (state = initialState, action) => {
    switch (action.type) {
        case types.START_SESSION_TIMER:
            return {
                ...state,
                lastSplitTime: action.lastSplitTime,
                isPaused: false
            };
        case types.PAUSE_SESSION_TIMER:
            return {
                ...state,
                isPaused: true
            };
        case types.SPLIT_SESSION_TIMER:
            return {
                ...state,
                lastSplitTime: action.lastSplitTime
            };
        case types.SET_SESSION_TIMER_TRACKED_PAGE:
            return {
                ...state,
                activePageId: action.activePageId
            };
        case types.POST_TIMER_END:
        case types.POST_TIMER_START:
            return state;
        default:
            return state;
    }
};
