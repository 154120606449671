import { pasteShapes } from '../../Helpers/helpers';
import getPropertiesForDestructuring from '../../utilities/getPropertiesForDestructuring';

const duplicate = (canvasState, command) => {
    const {
        shapes
    } = getPropertiesForDestructuring(command, ['shapes']);

    let updatedCanvasState = canvasState;
    updatedCanvasState = pasteShapes(updatedCanvasState, shapes, false);
    updatedCanvasState = updatedCanvasState
        .set('selection', shapes.map(shape => shape.get('id')));
    return updatedCanvasState;
};

export default duplicate;
