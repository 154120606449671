import {
    Record,
    Map,
    List,
    fromJS
} from 'immutable';
import UUID from 'uuid/v4';
import genericShapeProperties from '../../genericShapeProperties';
import { addShape, getNewShapeName, updateSelection } from '../../Helpers/helpers';
import Textbody from '../../Helpers/Text/TextBody';
import { getTriangleBottomVertexes } from '../../../utilities/ShapeGenerators';
import { generatePathFromPoints } from '../../../utilities/PathGenerator/PathGenerator';
import getPropertiesForDestructuring from '../../utilities/getPropertiesForDestructuring';
import { applyStyle } from '../../Helpers/Style/style';
import { convertPropertiesToShapeProperties } from '../../utilities/convertPropertiesToShapeProperties';

const TriangleRecord = Record({
    ...genericShapeProperties,
    type: 'Triangle',
    topVertex: Map({}),
    points: List([]),
    isRight: false,
    path: ''
}, 'Triangle');

const getDefaultTopVertex = modifiers => ({
    x: modifiers.get('isRight') ? parseFloat((-modifiers.get('width') / 2).toFixed(2)) : 0,
    y: parseFloat((-modifiers.get('height') / 2).toFixed(2))
});

const getNewVertexes = (width, height, topVertex) => {
    const bottomVertexes = getTriangleBottomVertexes(
        width,
        height
    );
    return [topVertex, ...bottomVertexes]
        .map(point => ({
            x: parseFloat((point.x).toFixed(2)),
            y: parseFloat((point.y).toFixed(2))
        }));
};

const add = (canvasState, command) => {
    const {
        shapes,
        defaultCanvasItemStyles,
        editMode
    } = getPropertiesForDestructuring(
        command,
        [
            'shapes',
            'defaultCanvasItemStyles',
            'editMode'
        ]
    );

    const newIds = [];

    let updatedCanvasState = shapes.reduce((currentCanvasState, shape) => {
        const style = defaultCanvasItemStyles.get(shape.get('styleToLoad') || 'shape');
        const styleDefinition = applyStyle(Map(), style, 'Triangle');
        const styleToApply = convertPropertiesToShapeProperties(styleDefinition);

        const textBody = Textbody.TextBody({
            paragraphStyles: List([Map({ isDefault: true })]),
            paragraphs: List([Map({ startIndex: 0, endIndex: -1, style: 1 })]),
            runStyles: List([Map({ isDefault: true })]),
            assignedStyles: Map({
                paragraph: styleToApply.getIn(['textProps', 'assignedParagraphStyle']),
                run: styleToApply.getIn(['textProps', 'assignedRunStyle']),
                textBody: styleToApply.getIn(['textProps', 'assignedStyle'])
            })
        });

        const textBodyPlaceholder = Textbody.TextBody({
            paragraphStyles: List([Map({ isDefault: true })]),
            paragraphs: List([Map({ startIndex: 0, endIndex: -1, style: 1 })]),
            runStyles: List([Map({ isDefault: true })]),
            assignedStyles: Map({
                paragraph: styleToApply.getIn(['textProps', 'assignedParagraphStyle']),
                run: styleToApply.getIn(['textProps', 'assignedRunStyle']),
                textBody: styleToApply.getIn(['textProps', 'assignedStyle'])
            })
        });

        const modifiers = shape.get('modifiers');
        const topVertex = getDefaultTopVertex(modifiers);
        const points = getNewVertexes(modifiers.get('width'), modifiers.get('height'), topVertex);
        const path = generatePathFromPoints(points);
        const newShape = TriangleRecord(
            modifiers
                .merge(
                    fromJS({
                        name: getNewShapeName(currentCanvasState, 'Triangle'),
                        id: UUID().toString(),
                        inLayout: editMode === 'layout',
                        x: currentCanvasState.get('size').get('width') / 2,
                        y: currentCanvasState.get('size').get('height') / 2,
                        assignedStyles: Map({
                            shape: styleToApply.getIn(['shapeProps', 'assignedShapeStyle']),
                            stroke: styleToApply.getIn(['strokeProps', 'assignedStrokeStyle'])
                        }),
                        style: style.get('id'),
                        textBody,
                        textBodyPlaceholder,
                        topVertex: fromJS(topVertex),
                        points: fromJS(points),
                        path: fromJS(path)
                    })
                )
        );
        newIds.push(newShape.get('id'));
        return addShape(currentCanvasState, newShape);
    }, canvasState);
    updatedCanvasState = updateSelection(updatedCanvasState, fromJS({ selection: newIds }));
    return updatedCanvasState;
};

export default add;
