const {
    Cell
} = require('../../Cell');

module.exports = Base => class extends Base {
    generateDefaultCells() {
        const cells = {};
        let cell;
        for (let i = 0; i < this.rowCount; i++) {
            for (let j = 0; j < this.columnCount; j++) {
                cell = new Cell(
                    `${this.name}_cell_${(i * this.columnCount) + j}`,
                    i,
                    j,
                    1,
                    1,
                    this
                );
                cells[cell.id] = cell;
            }
        }
        this.cells = cells;
    }
};
