import getPath from '../PropertyAdapters/path/path';
import defaultShapeToFabricJSON from './default';

const fromShapeToFabricJSON = (shape, canvasState) => ({
    ...defaultShapeToFabricJSON(shape, canvasState),
    type: 'CustomPath',
    shapeType: 'Path',
    path: getPath(shape)
});

export default fromShapeToFabricJSON;
