const _dynamicValues = Symbol('dynamicValues');

const dynamicDefaultValue = '?';

const cleanUpObject = o => Object.keys(o).reduce((a, b) => {
    if (typeof o[b] === 'object' || o[b] === null || o[b] === undefined) {
        return a;
    }
    a[b] = o[b];
    return a;
}, {});

module.exports = Base => class DynamicFields extends Base {
    static getDynamicDefaultValue() {
        return dynamicDefaultValue;
    }

    static insertDynamicTypeInStyleString(styleString, dynamicType) {
        if (dynamicType && dynamicType !== 'none') {
            if (styleString === '') {
                return ` style="--dynamic-type:${dynamicType}"`;
            }
            return `${styleString.substr(0, styleString.length - 1)}--dynamic-type:${dynamicType};"`;
        }
        return styleString;
    }

    static extractDynamicTypeInStyleString(styleString) {
        return (styleString || '')
            .split(';')
            .map(s => s.match(/--dynamic-type:(.*)/))
            .filter(s => !!s)
            .map(s => s[1])
            .pop() || 'none';
    }

    constructor(...args) {
        super(...args);
        this[_dynamicValues] = {};
    }

    offsetRunsDynamically(run, index, runs) {
        if (run.dynamicType && run.dynamicType !== 'none') {
            const dynamicValue = this.getDynamicValue(run.dynamicType);
            const runLength = ((run.endIndex - run.startIndex) + 1);
            const offset = dynamicValue.length - runLength;
            if (offset !== 0) {
                run.endIndex += offset;
                runs.slice(index + 1).forEach(r => {
                    r.startIndex += offset;
                    r.endIndex += offset;
                });
            }
        }
    }

    setDynamicValues(values) {
        const cleanedUpValues = cleanUpObject(values);
        this[_dynamicValues] = cleanedUpValues;
    }

    mergeDynamicValues(values) {
        const cleanedUpValues = cleanUpObject(values);
        this[_dynamicValues] = { ...this[_dynamicValues], ...cleanedUpValues };
    }

    setDynamicValue(type, value) {
        this[_dynamicValues][type] = value;
    }

    getDynamicValues() {
        return { ...this[_dynamicValues] };
    }

    getDynamicValue(type) {
        return (Object.prototype.hasOwnProperty.call(this[_dynamicValues], type)) ?
            this[_dynamicValues][type].toString() :
            DynamicFields.getDynamicDefaultValue();
    }
};
