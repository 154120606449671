import * as types from '#Constants/ActionTypes';

const section = {
    private: 'private',
    public: 'public'
};

const files = (state = {
    [section.private]: [],
    [section.public]: [],
    isFetching: null,
    shouldFetch: null
}, action) => {
    switch (action.type) {
        case types.SET_MANAGE_SHOULD_FETCH_TRUE: {
            return {
                ...state,
                shouldFetch: true
            };
        }
        case types.SET_FILES: {
            return {
                ...state,
                public: action.public,
                private: action.private,
                isFetching: false,
                shouldFetch: false
            };
        }
        case types.UPDATE_FILE_PROPERTIES: {
            const filesWithNewProperties = state[section.private].map(file => (
                file.id === action.fileId ?
                    { ...file, ...action.properties } : file
            ));

            return { ...state, [section.private]: filesWithNewProperties };
        }
        case types.REMOVE_FILE_FROM_LIST: {
            return {
                ...state,
                [section.private]: state[section.private].filter(file => file.id !== action.fileId)
            };
        }
        case types.RESET_FILES_LIST: {
            return { ...state, [action.fileType]: [] };
        }
        case types.FETCH_FILES:
            return { ...state, isFetching: true };
        case types.DELETE_DECK_SUCCESS:
            return { ...state, shouldFetch: true };
        case types.CHANGE_DECK_PROPERTIES:
            return {
                ...state,
                [section.private]: state[section.private].map(file => {
                    if (file.deck.id === action.deck.id) {
                        return {
                            ...file,
                            deck: {
                                ...file.deck,
                                ...action.deck,
                                pages: file.deck.pages
                            }
                        };
                    }
                    return file;
                })
            };
        default:
            return state;
    }
};

export { files };
