const { defaultShapeStyle } = require('../Shape/AbstractShape/config/defaultShapeStyle');
const { getRgba, isValid } = require('./color');

const rgbaDivisionOfAlphaBy255IfNeeded = rgba => {
    if (rgba) {
        const values = rgba.match(/^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?(?:,[\s+]?(\d+.?\d*)[\s+]?)?/i);
        if (values && values[4] !== null) {
            const alpha = parseFloat(values[4]);
            const convertedAlpha = alpha > 1 ? alpha / 255 : alpha;
            return `rgba(${values[1]}, ${values[2]}, ${values[3]}, ${convertedAlpha})`;
        } if (values[1] && values[2] && values[3] && values[4] === undefined) {
            return `rgba(${values[1]}, ${values[2]}, ${values[3]}, 1)`;
        }
        throw new Error('Color must be in RGBA format');
    }
    return null;
};

const extractRGBAComponent = (rgba = '') => {
    if (isValid(rgba) && (getRgba(rgba) || '').startsWith('rgb')) {
        const [red, green, blue, alpha] = getRgba(rgba).match(/[\d.]+/g);
        return {
            red: Number(red),
            green: Number(green),
            blue: Number(blue),
            alpha: Number(alpha)
        };
    }
    return {};
};

const addOpacityToRGBA = (input = '', newOpacity) => {
    if (isValid(input) && (getRgba(input) || '').startsWith('rgb')) {
        const { red, green, blue } = extractRGBAComponent(input);
        return `rgba(${red}, ${green}, ${blue}, ${newOpacity})`;
    }
    return defaultShapeStyle.fill.color.value;
};

const isTransparent = input => {
    if (isValid(input) && (getRgba(input) || '').startsWith('rgb')) {
        const { alpha } = extractRGBAComponent(input);
        return alpha === 0;
    }

    return false;
};

const isNoFill = input => {
    if (input?.type === 'none') {
        return true;
    }

    return false;
};

module.exports = {
    rgbaDivisionOfAlphaBy255IfNeeded,
    extractRGBAComponent,
    addOpacityToRGBA,
    isTransparent,
    isNoFill
};
