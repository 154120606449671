const { fromJS } = require('immutable');
const ItemStyle = require('../Shape/ItemStyle/ItemStyle');

module.exports = {
    setStyleDefinitions(canvasState, styleDefinitions = []) {
        if (!this.isCanvasState(canvasState)) {
            throw new Error('Can only set style definitions on canvas state');
        }
        return canvasState.set('styleDefinitions', fromJS(styleDefinitions));
    },

    getStyleDefinitions(canvasState) {
        if (!this.isCanvasState(canvasState)) {
            throw new Error('Can only get style definitions from canvas state');
        }

        return canvasState.get('styleDefinitions').toJS();
    },

    // eslint-disable-next-line
    getApplciationStyleDefinitionId(canvasState, styleDefinitionKind) {
        if (!this.isCanvasState(canvasState)) {
            throw new Error('Can search style definitions in canvas state only');
        }
        return '';
    },

    getApplicationStyleDefinition(canvasState, styleDefinitionKind) {
        if (!this.isCanvasState(canvasState)) {
            throw new Error('Can search style definitions in canvas state only');
        }

        return ItemStyle.getStyleDefinitionById(
            this.getStyleDefinitions(canvasState),
            this.getApplciationStyleDefinitionId(canvasState, styleDefinitionKind)
        );
    },

    // eslint-disable-next-line
    getThemeStyleDefinitionId(canvasState, styleDefinitionKind) {
        if (!this.isCanvasState(canvasState)) {
            throw new Error('Can search style definitions in canvas state only');
        }
        return '';
    },

    getThemeStyleDefinition(canvasState, styleDefinitionKind) {
        if (!this.isCanvasState(canvasState)) {
            throw new Error('Can search style definitions in canvas state only');
        }

        return ItemStyle.getStyleDefinitionById(
            this.getStyleDefinitions(canvasState),
            this.getThemeStyleDefinitionId(canvasState, styleDefinitionKind)
        );
    }
};
