const set = require('lodash/set');
const get = require('lodash/get');
const { styleToRunStyle } = require('./shape');

const emphasisStyleRegexp = /^(TB-CE|TB-HE)/;

const convertPropertyToNewShapeProperty = property => {
    const dictionary = {};
    dictionary.angle = 'rotation';
    dictionary.left = 'x';
    dictionary.lineHeight = 'lineSpacing';
    dictionary.shapeFill = 'fill';
    dictionary.stroke = 'borderColor';
    dictionary.strokeWidth = 'borderSize';
    dictionary.textAlign = 'align';
    dictionary.top = 'y';
    return dictionary[property] || property;
};

const convertPropertyToStrokePath = property => {
    const dictionary = {};
    dictionary.strokeColor = 'fill';
    dictionary.strokeWidth = 'width';
    dictionary.strokeDash = 'dash.type';
    dictionary.headTipType = 'head.type';
    dictionary.tailTipType = 'tail.type';
    dictionary.strokeJoinType = 'join.type';
    dictionary.headTipLength = 'head.length';
    dictionary.headTipWidth = 'head.width';
    dictionary.tailTipLength = 'tail.length';
    dictionary.tailTipWidth = 'tail.width';
    dictionary.strokeCapType = 'cap';
    dictionary.strokeOpacity = 'opacity';
    return dictionary[property] || property;
};

const convertPropertiesToShapeProperties = properties => {
    const convertedProperties = {};
    convertedProperties.shapeProps = {};
    convertedProperties.textProps = {};
    convertedProperties.strokeProps = {};
    Object.keys(properties).forEach(property => {
        switch (property) {
            case 'fill':
            case 'fontFamily':
            case 'fontSize':
            case 'fontStyle':
            case 'fontWeight':
            case 'linethrough':
            case 'overline':
            case 'textAlign':
            case 'align':
            case 'level':
            case 'margins':
            case 'verticalAlign':
            case 'textDirection':
            case 'underline':
            case 'spacing-bottom':
            case 'spacing-left':
            case 'spacing-right':
            case 'spacing-top':
            case 'lineHeight':
            case 'textTransform':
            case 'characterSpacing':
            case 'superscript':
            case 'assignedParagraphStyle':
            case 'assignedRunStyle':
            case 'textSelection':
            case 'subscript':
            case 'bullet':
            case 'indent':
            case 'levelStyle':
            case 'listUpdate':
                convertedProperties.textProps = {
                    ...convertedProperties.textProps,
                    ...styleToRunStyle(properties)
                };
                break;
            case 'html':
                convertedProperties.textProps = {
                    ...convertedProperties.textProps,
                    [property]: properties[property]
                };
                break;
            case 'left':
            case 'top':
            case 'angle': {
                const convertedProperty = convertPropertyToNewShapeProperty(property);
                convertedProperties.shapeProps[convertedProperty] = properties[property];
                break;
            }
            case 'strokeDash':
            case 'strokeWidth':
            case 'strokeColor':
            case 'strokeJoinType':
            case 'strokeCapType':
            case 'headTipType':
            case 'tailTipType':
            case 'headTipLength':
            case 'tailTipLength':
            case 'headTipWidth':
            case 'strokeOpacity':
            case 'tailTipWidth': {
                const convertedProperty = convertPropertyToStrokePath(property);
                set(convertedProperties.strokeProps, convertedProperty, properties[property]);
                break;
            }
            case 'assignedStrokeStyle': {
                Object.entries(properties.assignedStrokeStyle)
                    .forEach(([key, value]) => {
                        set(convertedProperties, `strokeProps.assignedStrokeStyle.${convertPropertyToStrokePath(key)}`, value);
                    });
                break;
            }
            case 'shapeFill': {
                const convertedProperty = convertPropertyToNewShapeProperty(property);
                convertedProperties.shapeProps[convertedProperty] = properties[property];
                if (properties[property].color) {
                    convertedProperties.shapeProps[convertedProperty].type = 'color';
                }
                break;
            }
            case 'bullets': {
                convertedProperties.textProps = styleToRunStyle(properties);
                break;
            }
            case 'style': {
                if (get(properties, 'cells.ids', []).length === 0 || !properties.cells.contextualSelection) {
                    convertedProperties.shapeProps.style = properties.style;
                }
                convertedProperties.isEmphasisStyle = emphasisStyleRegexp.test(properties.style);
                break;
            }
            case 'autoFitShape':
            case 'autoFitText': {
                convertedProperties.shapeProps[property] = properties[property];
                convertedProperties.textProps[property] = properties[property];
                break;
            }
            default: {
                convertedProperties.shapeProps[property] = properties[property];
                break;
            }
        }
    });
    return convertedProperties;
};

module.exports = {
    convertPropertiesToShapeProperties,
    convertPropertyToNewShapeProperty,
    convertStrokeUpdateToStroke(strokeUpdate) {
        return Object.entries(strokeUpdate)
            .reduce((current, [key, value]) => ({
                ...current,
                [convertPropertyToStrokePath(key)]: value
            }), {});
    }
};
