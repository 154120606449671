const cloneDeep = require('lodash/cloneDeep');
const get = require('lodash/get');

const fontWeightRegexp = /\b[\S]*(Thin|Light|Regular|Medium|Black|Bold)\b/;

module.exports = (styleToAssign, emphasisStyle) => {
    const formattedStyle = cloneDeep(styleToAssign);
    const fontWeight = get(emphasisStyle, 'font.weight', false);
    const fontStyle = get(emphasisStyle, 'font.style', false);

    if (!get(formattedStyle, 'font.family', false)) { return formattedStyle; }

    if (fontWeight && fontWeight !== 'normal') {
        formattedStyle.font.family = formattedStyle.font.family.replace(
            fontWeightRegexp,
            `${emphasisStyle.font.weight[0].toUpperCase()}${emphasisStyle.font.weight.slice(1)}`
        );
        if (!formattedStyle.font.family.includes('Bold')) {
            formattedStyle.font.family = `${formattedStyle.font.family.trim()} Bold`;
        }
    } else if (fontWeight === 'normal') {
        formattedStyle.font.family = formattedStyle.font.family.replace(fontWeightRegexp, '');
    }

    if (fontStyle && fontStyle !== 'normal') {
        formattedStyle.font.family = formattedStyle.font.family.replace('Regular', 'Italic');
        if (!formattedStyle.font.family.includes('Italic')) {
            formattedStyle.font.family = `${formattedStyle.font.family.trim()} Italic`;
        }
    } else if (fontStyle === 'normal') {
        formattedStyle.font.family = formattedStyle.font.family.replace('Italic', '');
    }
    return formattedStyle;
};
