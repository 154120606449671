import { fromJS } from 'immutable';
import defaultFromFabricToCanvasState from './default';

const fromFabricToCanvasState = (fabricShape, canvasState) => fromJS({
    ...defaultFromFabricToCanvasState(fabricShape, canvasState),
    isRight: fabricShape.isRight,
    topVertex: fabricShape.topVertex
});

export default fromFabricToCanvasState;
