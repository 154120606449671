import get from 'lodash/get';

import decksignCanvas from 'decksign-canvas';
import api from '#Utilities/api';
import client from '#Utilities/graphql';
import { createCanvasItemStyleSelectionAction } from '#Actions/build/canvasItemStyle';
import { getIsDeckEmpty } from '#Selectors/deck';
import {
    getTableStyleMismatches,
    getSelectedCanvasItemStyleId,
    getVersion
} from '#Selectors/build/canvasState';

import * as types from '#Constants/ActionTypes';

import blockActionCreatorByLayoutModal from './blockActionCreatorByLayoutModal';

const CanvasState = decksignCanvas.api.CanvasState;
const LOGO_BASE_SIZE = 300;

const triggerCanvasUpdate = update => (dispatch, getState) => {
    const state = getState();
    const { isCanvasUpdating } = state.build;
    if (!isCanvasUpdating && !getIsDeckEmpty(state)) {
        dispatch({ type: types.SET_CANVAS_UPDATING });
        return dispatch(update);
    }
    return null;
};

const syncWithFabricCanvasState = updatedState => ({
    type: types.UPDATE_CANVAS_STATE,
    updatedState,
    needUpdate: false
});

const updateCanvasState = (updatedState, needUpdate = true, resetCache = false) => ({
    type: types.UPDATE_CANVAS_STATE,
    updatedState,
    needUpdate,
    resetCache
});

const prepareParentGroupsPathArrays = parentGroupsPaths => Array.from(
    new Set(parentGroupsPaths
        .map(JSON.stringify)), JSON.parse
);
const selectAndGroupObjects = (_itemIds, selectedShapesParentGroupsPath = []) => {
    if (!Array.isArray(_itemIds) && typeof _itemIds !== 'string') throw new Error(`Can only select items using the names as a string or an array of string, tried to use ${typeof _itemIds}`);
    const itemIds = typeof _itemIds === 'string' ? [_itemIds] : _itemIds;

    return triggerCanvasUpdate({
        type: types.UPDATE_SELECTION_AND_GROUP,
        selection: itemIds,
        selectedShapesParentGroupsPath: prepareParentGroupsPathArrays(selectedShapesParentGroupsPath)
    });
};

const selectObjects = (_itemIds, selectedShapesParentGroupsPath = []) => {
    if (!Array.isArray(_itemIds) && typeof _itemIds !== 'string') throw new Error(`Can only select items using the names as a string or an array of string, tried to use ${typeof _itemIds}`);
    const itemIds = typeof _itemIds === 'string' ? [_itemIds] : _itemIds;

    return triggerCanvasUpdate({
        type: types.UPDATE_SELECTION,
        selection: itemIds,
        selectedShapesParentGroupsPath: prepareParentGroupsPathArrays(selectedShapesParentGroupsPath)
    });
};

const updateObjectsProperties = update => (dispatch, getState) => {
    const {
        build: {
            textSelection
        }
    } = getState();
    const properties = update;
    if (textSelection.editing === true) {
        properties.textSelection = textSelection;
    }
    dispatch(triggerCanvasUpdate({
        type: types.UPDATE_PROPERTIES_ON_SELECTED_SHAPES,
        properties
    }));
};

const updateObjectPropertiesFromDelta = update => (dispatch, getState) => {
    const {
        build: {
            textSelection
        }
    } = getState();
    const properties = update;
    if (textSelection.editing === true) {
        properties.textSelection = textSelection;
    }
    dispatch(triggerCanvasUpdate({
        type: types.UPDATE_PROPERTIES_FROM_DELTA_ON_SELECTED_SHAPES,
        properties
    }));
};

const toggleBoldForSelection = selectedObjectProperties => {
    const fontWeight = selectedObjectProperties.fontWeight === 'bold' ?
        'normal' :
        'bold';
    return updateObjectsProperties({ fontWeight });
};

const toggleItalicForSelection = selectedObjectProperties => {
    const fontStyle = selectedObjectProperties.fontStyle === 'italic' ?
        'normal' :
        'italic';
    return updateObjectsProperties({ fontStyle });
};

const toggleUnderlineForSelection = selectedObjectProperties => {
    const underline = !selectedObjectProperties.underline;
    return updateObjectsProperties({ underline });
};

const alignObjects = position => triggerCanvasUpdate({
    type: types.ALIGN_SELECTED_SHAPES,
    position
});

const flipObjects = (flipX, flipY) => triggerCanvasUpdate({
    type: types.FLIP_SELECTED_OBJECTS,
    flipX,
    flipY
});

const distributeObjects = direction => triggerCanvasUpdate({
    type: types.DISTRIBUTE_SELECTED_SHAPES,
    direction
});

const groupObjects = () => triggerCanvasUpdate({
    type: types.GROUP_SELECTED_SHAPES
});

const ungroupObjects = () => triggerCanvasUpdate({
    type: types.UNGROUP_SELECTED_SHAPES
});

const removeObjects = () => triggerCanvasUpdate({
    type: types.DELETE_SELECTED_SHAPES
});

const clearCanvasItems = () => triggerCanvasUpdate({
    type: types.DELETE_ALL_SHAPES
});

const addObject = newCanvasItem => (dispatch, getState) => {
    const { build: { canvasState }, effectiveCanvasItemStyles } = getState();
    const editMode = CanvasState.isCanvasState(canvasState) && canvasState.get('editMode').toLowerCase();
    if (newCanvasItem.displayName === 'Logo') {
        const logoSource = canvasState.get('dynamicValues') &&
            canvasState.get('dynamicValues').get('logo') &&
            canvasState.get('dynamicValues').get('logo').get('imageSource');
        if (logoSource) {
            client.getFileDimension(logoSource)
                .then(({ width, height }) => {
                    const isLandscape = width > height;
                    const modifiers = {
                        ...newCanvasItem.modifiers,
                        width: isLandscape ? LOGO_BASE_SIZE : width * (LOGO_BASE_SIZE / height),
                        height: isLandscape ? height * (LOGO_BASE_SIZE / width) : LOGO_BASE_SIZE
                    };

                    dispatch(triggerCanvasUpdate({
                        type: types.CREATE_SHAPES,
                        shapes: [{
                            ...newCanvasItem,
                            modifiers
                        }],
                        editMode,
                        defaultCanvasItemStyles: effectiveCanvasItemStyles
                    }));
                });
        }
    } else {
        dispatch(triggerCanvasUpdate({
            type: types.CREATE_SHAPES,
            shapes: [newCanvasItem],
            editMode,
            defaultCanvasItemStyles: effectiveCanvasItemStyles
        }));
    }
};

const addGraph = item => (dispatch, getState) => {
    const { app: { config: { decksAssetsURLBase } } } = getState();
    return api.post({
        path: 'images/from-url',
        body: { imageUrl: item.imageSrc }
    }).then(({ id }) => {
        dispatch(addObject({
            ...item,
            type: 'Image',
            fill: {
                type: 'imageSource',
                imageSource: `${api.urlBase}/${decksAssetsURLBase}/${id}`,
                stretch: true
            }
        }));
    });
};

const addTable = ({
    rows, columns, settings, replacePlaceholder = false
}) => (dispatch, getState) => {
    const { build: { canvasState: { present } }, effectiveCanvasItemStyles } = getState();
    const editMode = CanvasState.isCanvasState(present) && present.get('editMode').toLowerCase();
    const defaultStyleKey = (get(settings, 'mainAxis') === 'horizontal' ?
        'horizontalTable' :
        'verticalTable'
    );
    dispatch(triggerCanvasUpdate({
        type: types.CREATE_SHAPES,
        shapes: [{
            type: 'table',
            rows,
            columns,
            settings,
            replacePlaceholder,
            styleToLoad: defaultStyleKey
        }],
        editMode,
        defaultCanvasItemStyles: effectiveCanvasItemStyles
    }));
};
const changeObjectIndex = (changeType, isTriggeredByUI, sourcePath, targetPath) => triggerCanvasUpdate({
    type: types.CHANGE_SHAPE_INDEX,
    isTriggeredByUI,
    changeType,
    sourcePath,
    targetPath
});

const copyObject = (dispatch, getState) => {
    const state = getState();
    const copiedItems = CanvasState.getSelectedItems(state.build.canvasState);
    const text = document.getSelection().toString();
    if (text) {
        navigator.clipboard.writeText(text);
        dispatch({
            type: types.SET_CLIPBOARD,
            items: copiedItems,
            itemType: 'Object',
            isCut: false,
            textSelection: state.build.textSelection
        });
    }
};

const getClipboardItems = state => {
    const canvasStateClipboardItems = CanvasState.getCanvasStateClipboard(state.build.canvasState);
    const storedClipboard = state.build.clipboard;
    const storedClipboardItems = storedClipboard.items
        .map(item => {
            const newItem = item;
            newItem.isCut = storedClipboard?.isCut || false;
            return newItem;
        });
    return canvasStateClipboardItems.length > 0 ?
        canvasStateClipboardItems :
        storedClipboardItems;
};

const pasteObject = (dispatch, getState) => {
    const state = getState();
    const clipboardItems = getClipboardItems(state);
    const isTextEditing = state.build.textSelection.editing;
    const text = '';
    if (clipboardItems && !isTextEditing) {
        dispatch({
            type: types.PASTE_CLIPBOARD_CONTENT,
            clipboard: getClipboardItems(state),
            page: state.build.page.pageNumber
        });
    } else if (isTextEditing) {
        navigator.clipboard.readText().then(clipboardText => {
            const textarea = document.querySelector("[data-fabric-hiddentextarea='']");
            textarea.setRangeText(
                clipboardText,
                textarea.selectionStart,
                textarea.selectionEnd,
                'end'
            );
            textarea.dispatchEvent(new InputEvent('input', { inputType: 'insertFromPaste' }));
        });
    } else {
        const newCanvasItem = {
            type: 'textbox',
            modifiers: { text }
        };
        dispatch(triggerCanvasUpdate({
            type: types.CREATE_SHAPES,
            shapes: [newCanvasItem],
            editMode: 'page'
        }));
    }
};

const cutObject = (dispatch, getState) => {
    const state = getState();
    const copiedItems = CanvasState.getSelectedItems(state.build.canvasState);
    dispatch({
        type: types.SET_CLIPBOARD,
        items: copiedItems,
        itemType: 'Object',
        isCut: true
    });
    dispatch({
        type: types.CUT_SELECTED_SHAPES
    });
};

const selectAllCanvasItems = (dispatch, getState) => {
    const state = getState();
    const shapes = state.build.canvasState.get('shapes').filter(s => s.get('selectable'));
    dispatch(selectObjects(shapes.map(s => s.get('id')).toJS()));
};

const editedTextShape = textShape => triggerCanvasUpdate({
    type: types.EDITED_TEXTSHAPE,
    textShape
});

const updateTableCellContent = ({
    content
}) => dispatch => {
    dispatch(updateObjectsProperties({
        cellContent: content
    }));
};

const splitSelectedCells = (rows, columns) => (dispatch, getState) => {
    const state = getState();
    const styleId = getSelectedCanvasItemStyleId(state);
    const tableStyleMismatches = getTableStyleMismatches(state);

    dispatch(triggerCanvasUpdate({
        type: types.SPLIT_SELECTED_CELLS,
        rows,
        columns
    }));

    if (styleId) {
        dispatch(createCanvasItemStyleSelectionAction(styleId, false, tableStyleMismatches));
    }

    return true;
};

const mergeSelectedCells = () => (dispatch, getState) => {
    const state = getState();
    const styleId = getSelectedCanvasItemStyleId(state);
    const tableStyleMismatches = getTableStyleMismatches(state);

    dispatch(triggerCanvasUpdate({
        type: types.MERGE_SELECTED_CELLS
    }));

    if (styleId) {
        dispatch(createCanvasItemStyleSelectionAction(styleId, false, tableStyleMismatches));
    }
};

const addLine = direction => (dispatch, getState) => {
    dispatch(triggerCanvasUpdate({
        type: types.ADD_TABLE_LINE,
        direction
    }));

    const state = getState();
    const styleId = getSelectedCanvasItemStyleId(state);
    const tableStyleMismatches = getTableStyleMismatches(state);

    if (styleId) {
        dispatch(createCanvasItemStyleSelectionAction(styleId, false, tableStyleMismatches));
    }
};

const removeLine = axis => (dispatch, getState) => {
    const state = getState();
    const styleId = getSelectedCanvasItemStyleId(state);
    const tableStyleMismatches = getTableStyleMismatches(state);

    dispatch(triggerCanvasUpdate({
        type: types.REMOVE_TABLE_LINE,
        axis
    }));
    if (styleId) {
        dispatch(createCanvasItemStyleSelectionAction(styleId, false, tableStyleMismatches));
    }
};

const createUpdateTableStructureAction = structure => (dispatch, getState) => {
    const state = getState();
    const styleId = getSelectedCanvasItemStyleId(state);
    const tableStyleMismatches = getTableStyleMismatches(state);
    dispatch(updateObjectsProperties(structure));
    if (styleId) {
        dispatch(createCanvasItemStyleSelectionAction(styleId, false, tableStyleMismatches));
    }
};

const toggleObjectLock = ids => triggerCanvasUpdate({
    type: types.TOGGLE_SHAPE_LOCK,
    ids
});

const toggleObjectVisibility = ids => triggerCanvasUpdate({
    type: types.TOGGLE_SHAPE_VISIBILITY,
    ids
});

const createToggleEditModeAction = type => (dispatch, getState) => dispatch(triggerCanvasUpdate({
    type: types.TOGGLE_EDIT_MODE,
    editMode: type,
    version: getVersion(getState()) + 1
}));

const toggleEditMode = type => dispatch => dispatch(blockActionCreatorByLayoutModal(createToggleEditModeAction, type));

const clearTableCells = () => triggerCanvasUpdate({
    type: types.CLEAR_SELECTED_CELLS
});

const replaceTablePlaceholderForTable = object => (dispatch, getState) => {
    const { effectiveCanvasItemStyles } = getState();
    const defaultStyleKey = (get(object, 'settings.mainAxis') === 'horizontal' ?
        'horizontalTable' :
        'verticalTable'
    );
    const styleId = get(effectiveCanvasItemStyles, `${defaultStyleKey}.id`);
    dispatch(updateObjectsProperties(object));
    if (styleId) {
        dispatch(createCanvasItemStyleSelectionAction(styleId));
    }
};

const changeShapeName = (id, name) => dispatch => {
    dispatch({
        type: types.UPDATE_PROPERTIES_ON_SELECTED_SHAPES,
        properties: {
            name
        }
    });
};

export {
    addGraph,
    addObject,
    addTable,
    selectObjects,
    updateObjectsProperties,
    alignObjects,
    flipObjects,
    distributeObjects,
    groupObjects,
    ungroupObjects,
    removeObjects,
    clearCanvasItems,
    changeObjectIndex,
    copyObject,
    pasteObject,
    cutObject,
    selectAllCanvasItems,
    editedTextShape,
    updateTableCellContent,
    updateCanvasState,
    toggleBoldForSelection,
    toggleItalicForSelection,
    toggleUnderlineForSelection,
    splitSelectedCells,
    syncWithFabricCanvasState,
    mergeSelectedCells,
    addLine,
    removeLine,
    createUpdateTableStructureAction,
    toggleObjectLock,
    toggleObjectVisibility,
    toggleEditMode,
    clearTableCells,
    replaceTablePlaceholderForTable,
    changeShapeName,
    selectAndGroupObjects,
    updateObjectPropertiesFromDelta
};
