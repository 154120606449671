import { Map } from 'immutable';
import { isNil } from 'lodash';
import adaptColorValueAdapterToFabricColor from './colorValueDescriptorAdapter';

const prepareBulletStyle = paragraphStyle => {
    if (paragraphStyle.get('bullet', Map()) !== undefined) {
        return paragraphStyle
            .get('bullet', Map())
            .set('level', paragraphStyle.get('level', 1));
    }
    return paragraphStyle
        .set('bullet', Map())
        .setIn(['bullet', 'level'], paragraphStyle.get('level', 1));
};

const adaptParagraphStyleToFabricTextStyle = (
    paragraphStyle = Map(),
    colorPalette
) => {
    const fabricTextStyle = {
        ...adaptBulletStyleToFabricBulletStyle(prepareBulletStyle(paragraphStyle), colorPalette),
        ...adaptPaddingStyleToFabricPaddingStyle(paragraphStyle.get('padding'))
    };
    if (paragraphStyle.get('align')) {
        fabricTextStyle.textAlign = paragraphStyle.get('align') === 'justify' ? 'justify-left' : paragraphStyle.get('align');
    }
    if (paragraphStyle.get('lineSpacing')) {
        fabricTextStyle.lineHeight = paragraphStyle.get('lineSpacing');
    }
    if (paragraphStyle.get('level', 1)) {
        fabricTextStyle.level = paragraphStyle.get('level', 1);
    }
    if (!isNil(paragraphStyle.get('indent')) && !Number.isNaN(paragraphStyle.get('indent'))) {
        fabricTextStyle.indent = paragraphStyle.get('indent');
    }
    return fabricTextStyle;
};

const adaptBulletStyleToFabricBulletStyle = (
    bulletStyle = Map(),
    colorPalette
) => {
    const fabricBulletStyle = {};
    if (bulletStyle.get('type', 'none') !== 'none') {
        fabricBulletStyle.type = 'listItem';
        fabricBulletStyle.level = bulletStyle.get('level');
        fabricBulletStyle.bulletType = bulletStyle.get('type');
        if (bulletStyle.get('text')) {
            fabricBulletStyle.bulletText = bulletStyle.get('text');
        }
        if (bulletStyle.get('itemCountStartsAt')) {
            fabricBulletStyle.itemCountStartsAt = bulletStyle.get('itemCountStartsAt');
        }
        fabricBulletStyle.bulletStyle = {};
        if (bulletStyle.getIn(['style', 'font'])) {
            fabricBulletStyle.bulletStyle.fontFamily = bulletStyle.getIn(['style', 'font']);
        }

        if (bulletStyle.getIn(['style', 'fontWeight'])) {
            fabricBulletStyle.bulletStyle.fontWeight = bulletStyle.getIn(['style', 'fontWeight']);
        }
        if (bulletStyle.getIn(['style', 'size'])) {
            fabricBulletStyle.bulletStyle.fontSize = bulletStyle.getIn(['style', 'size']);
        }
        if (bulletStyle.getIn(['style', 'color'])) {
            fabricBulletStyle.bulletStyle.fill = adaptColorValueAdapterToFabricColor(
                bulletStyle.getIn(['style', 'color']),
                colorPalette
            );
        }
    } else {
        fabricBulletStyle.type = 'paragraph';
    }
    return fabricBulletStyle;
};

const adaptPaddingStyleToFabricPaddingStyle = (paddingStyle = Map()) => {
    const fabricPaddingStyle = {};
    if (paddingStyle.get('bottom')) {
        fabricPaddingStyle.paddingBottom = paddingStyle.get('bottom');
    }
    if (paddingStyle.get('left')) {
        fabricPaddingStyle.paddingLeft = paddingStyle.get('left');
    }
    if (paddingStyle.get('right')) {
        fabricPaddingStyle.paddingRight = paddingStyle.get('right');
    }
    if (paddingStyle.get('top')) {
        fabricPaddingStyle.paddingTop = paddingStyle.get('top');
    }
    return fabricPaddingStyle;
};

export {
    adaptParagraphStyleToFabricTextStyle,
    adaptBulletStyleToFabricBulletStyle,
    adaptPaddingStyleToFabricPaddingStyle
};
