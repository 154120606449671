const { fabric } = require('fabric');
const {
    defaultModificationHandlerAttributes,
    MOVE_RADIUS,
    MAX_SIDES,
    MIN_SIDES,
    ADJUSTMENT_RANGE
} = require('./config/defaultModificationHandlerAttributes.config');
const { rotatePoint } = require('../../../utilities/geometry');

const THETA_INCREMENTS = (2 * Math.PI) / ((MAX_SIDES + 1) - MIN_SIDES);

const RegularPolygonModificationHandler = fabric.util.createClass(fabric.Circle, {
    type: 'modificationHandler',

    initialize(target, attributes = {}) {
        const fullAttributes = {
            ...defaultModificationHandlerAttributes,
            ...attributes
        };

        this.callSuper('initialize', fullAttributes);
        this.target = target;
        this.refreshText = fullAttributes.refreshText;
        this.thetaIncrements = THETA_INCREMENTS;
        this.currentTheta = this.getInitialTheta();
        this.updatePosition();

        this.onMouseMove = this.onMouseMove.bind(this);
        this.onMouseUp = this.onMouseUp.bind(this);
        this.onMouseDown = this.onMouseDown.bind(this);
        this.on('mousedown', this.onMouseDown);
    },

    getInitialTheta() {
        return (this.target.getShapeObject().sides - MIN_SIDES) * this.thetaIncrements;
    },

    onMouseDown() {
        this.target.canvas.selection = false;
        document.addEventListener('mousemove', this.onMouseMove);
        document.addEventListener('mouseup', this.onMouseUp);
    },

    onMouseMove(e) {
        const centeredPosition = this.getCenteredPointerPosition(e);
        this.currentTheta = Math.atan2(centeredPosition.y, centeredPosition.x);
        this.updatePosition();
        this.updateSidesCount();
        this.target.canvas.renderAll();
    },

    onMouseUp() {
        this.target.canvas.selection = true;
        this.target.canvas.on('selection:cleared', this.target.canvas.handleSelectionClear);
        document.removeEventListener('mousemove', this.onMouseMove);
        document.removeEventListener('mouseup', this.onMouseUp);
        this.target.canvas._groupSelector = null;
        this.target.canvas.setActiveObject(this.target);
        this.target.canvas.handleObjectModified(this.target);
        if (this.refreshText) {
            this.target.refreshText();
        }
    },

    updatePosition(position) {
        const newPosition = position || this.getAdjustedPosition();
        const rotatedPosition = rotatePoint(
            newPosition,
            this.target.angle || 0,
            { x: this.target.left, y: this.target.top }
        );
        this.left = rotatedPosition.x;
        this.top = rotatedPosition.y;
        this.setCoords();
    },

    getAdjustedPosition() {
        const maxQuotient = Math.floor(
            (this.currentTheta + ADJUSTMENT_RANGE) / this.thetaIncrements
        );
        const minQuotient = Math.floor(
            (this.currentTheta - ADJUSTMENT_RANGE) / this.thetaIncrements
        );
        if (maxQuotient !== minQuotient) {
            this.currentTheta = maxQuotient * this.thetaIncrements;
        }
        return {
            x: this.target.getAbsolutePosition().x + (MOVE_RADIUS * Math.cos(this.currentTheta)),
            y: this.target.getAbsolutePosition().y + (MOVE_RADIUS * Math.sin(this.currentTheta))
        };
    },

    getCenteredPointerPosition(e) {
        const position = this.target.getLocalPointer(e);
        return {
            x: position.x - (this.target.width / 2),
            y: position.y - (this.target.height / 2)
        };
    },

    updateSidesCount() {
        if (this.currentTheta < 0) {
            this.currentTheta += 2 * Math.PI;
        }
        const multiplier = Math.floor(this.currentTheta / this.thetaIncrements);
        const newSidesCount = multiplier + MIN_SIDES;
        if (newSidesCount !== this.target.getShapeObject().sides) {
            this.target.updateModifierAttribute(newSidesCount);
        }
    }
});

RegularPolygonModificationHandler.fromJSON = (object, callback) => {
    const handler = new RegularPolygonModificationHandler(object);
    callback(handler);
    return handler;
};

module.exports = RegularPolygonModificationHandler;
