const SHAPE_RADIUS = 5;

const defaultModificationHandlerAttributes = {
    fill: '#FFF600',
    stroke: '#CCCCC',
    strokeWidth: 1,
    radius: SHAPE_RADIUS,
    selectable: false,
    originX: 'center',
    originY: 'center',
    hoverCursor: 'crosshair',
    lockVertical: false,
    lockHorizontal: false,
    applyRotation: true,
    excludeFromExport: true,
    refreshText: true
};

const MOVE_RADIUS = 40;

const MAX_SIDES = 12;

const MIN_SIDES = 3;

const ADJUSTMENT_RANGE = 0.3;

module.exports = {
    defaultModificationHandlerAttributes,
    MOVE_RADIUS,
    MAX_SIDES,
    MIN_SIDES,
    ADJUSTMENT_RANGE,
    SHAPE_RADIUS
};
