const extractRGBAComponents = (rgba = '') => {
    if (!rgba) {
        return {};
    }
    if (rgba.startsWith('rgb')) {
        const [red, green, blue, alpha] = rgba.match(/[.0-9]+/g)
            .map(component => parseFloat(component));
        return {
            red, green, blue, alpha
        };
    }
    throw new Error(`Can't extract rgba components of ${rgba}`);
};

const serializeComponentToHex = component => component.toString(16).padStart(2, '0');

export const rgbToHex = rgba => {
    const { red, green, blue } = extractRGBAComponents(rgba);
    return `#${serializeComponentToHex(red)}${serializeComponentToHex(green)}${serializeComponentToHex(blue)}`;
};

export const convertRGBAToOpacityField = fill => {
    if (fill && fill.color) {
        const { alpha } = extractRGBAComponents(fill.color.value);
        return alpha ? parseFloat(alpha, 10) : '';
    }
    return '';
};
