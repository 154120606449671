import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({ component: Component, ...rest }) => {
    const token = JSON.parse(localStorage.getItem('cognito-user-tokens'))?.accessToken || null;
    const isAuthenticated = token?.jwtToken || false;
    return (
        <Route
            {...rest}
            render={props => (
                isAuthenticated || process.env.REACT_APP_PERF_TEST_ENABLED ?
                    <Component {...props} /> :
                    <Redirect to={{ pathname: '/auth/login', state: { referer: props.location } }} />
            )}
        />
    );
};

export default PrivateRoute;
