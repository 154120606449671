import { getShapePathById } from '../../../../../../Canvas/CanvasStateSelectors';

const getAssignedStyles = (table, cell, canvasState) => canvasState.getIn(
    [
        ...getShapePathById(canvasState, table.id),
        'cells',
        table.borders
            .findIndex(currentCell => currentCell.id === cell.id),
        'assignedStyles'
    ]
);

export default getAssignedStyles;
