import { fromJS, Map } from 'immutable';
import TEXTBODY_PROPERTIES from '../Config/textBodyRecordProperties';

const updateMargins = (textBody, marginsUpdate) => {
    let currentMargins = getMargins(textBody);
    if (!Map.isMap(currentMargins)) {
        currentMargins = fromJS(currentMargins);
    }
    const updatedMargins = currentMargins.merge(marginsUpdate);
    return textBody.setIn([TEXTBODY_PROPERTIES.MARGINS], updatedMargins);
};

const getMargins = textBody => {
    const currentMargins = textBody.get(TEXTBODY_PROPERTIES.MARGINS, Map({}));
    const assignedMargins = textBody.getIn([TEXTBODY_PROPERTIES.ASSIGNED_STYLES, 'textBody', 'margins'], Map({}));
    return assignedMargins.merge(currentMargins);
};

const updateTextDirection = (textBody, textDirection) => textBody.setIn(
    [TEXTBODY_PROPERTIES.TEXT_DIRECTION],
    textDirection
);

const getTextDirection = textBody => textBody.get(TEXTBODY_PROPERTIES.TEXT_DIRECTION);

const updateVerticalAlign = (textBody, verticalAlign) => textBody.setIn(
    [TEXTBODY_PROPERTIES.VERTICAL_ALIGN],
    verticalAlign
);

const getVerticalAlign = textBody => textBody.get(TEXTBODY_PROPERTIES.VERTICAL_ALIGN);

const updateAutoFitShape = (textBody, autoFitShape) => textBody
    .setIn([TEXTBODY_PROPERTIES.AUTO_FIT_SHAPE], autoFitShape)
    .setIn([TEXTBODY_PROPERTIES.AUTO_FIT_TEXT], !autoFitShape);

const updateAutoFitText = (textBody, autoFitText) => textBody
    .setIn([TEXTBODY_PROPERTIES.AUTO_FIT_TEXT], autoFitText)
    .setIn([TEXTBODY_PROPERTIES.AUTO_FIT_SHAPE], !autoFitText);

const getAutoFitShape = textBody => textBody.get(TEXTBODY_PROPERTIES.AUTO_FIT_SHAPE);

const getAutoFitText = textBody => textBody.get(TEXTBODY_PROPERTIES.AUTO_FIT_TEXT);

const setTextBodyStyleProperties = (textBody, styleProperties) => {
    let updatedTextBody = textBody;
    if (styleProperties.get('margins')) {
        updatedTextBody = updateMargins(updatedTextBody, styleProperties.get('margins'));
    }
    if (styleProperties.get('textDirection')) {
        updatedTextBody = updateTextDirection(updatedTextBody, styleProperties.get('textDirection'));
    }
    if (styleProperties.get('verticalAlign')) {
        updatedTextBody = updateVerticalAlign(updatedTextBody, styleProperties.get('verticalAlign'));
    }
    if (styleProperties.get('autoFitShape')) {
        updatedTextBody = updateAutoFitShape(updatedTextBody, styleProperties.get('autoFitShape'));
    }
    if (styleProperties.get('autoFitText')) {
        updatedTextBody = updateAutoFitText(updatedTextBody, styleProperties.get('autoFitText'));
    }
    return updatedTextBody;
};

const getTextLength = textBodyData => textBodyData.get(TEXTBODY_PROPERTIES.TEXT, '').length;

const getText = (
    textBodyData,
    startIndex = 0,
    endIndex = getTextLength(textBodyData)
) => textBodyData
    .get(TEXTBODY_PROPERTIES.TEXT, '')
    .substring(startIndex, endIndex + 1);

const isEmpty = textBodyData => textBodyData.get('text').length === 0;

const hasText = textBody => !isEmpty(textBody);

const getEndIndex = (textBodyData, text, startIndex) => Math.min(
    getTextLength(textBodyData) - 1,
    startIndex + (text.length - 1)
);

export {
    setTextBodyStyleProperties,
    updateMargins,
    getMargins,
    updateTextDirection,
    getTextDirection,
    updateVerticalAlign,
    getVerticalAlign,
    updateAutoFitShape,
    updateAutoFitText,
    getAutoFitShape,
    getAutoFitText,
    getTextLength,
    getText,
    isEmpty,
    getEndIndex,
    hasText
};
