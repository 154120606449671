const getRelativeAnchors = shape => {
    const sinCosAt45 = Math.sin(Math.PI / 4);
    const scaledRx = shape.get('rx') * shape.get('scaleX');
    const scaledRy = shape.get('rx') * shape.get('scaleY');

    return {
        auto: { x: 0, y: 0 },
        'top-left': { x: -(scaledRx * sinCosAt45), y: -(scaledRy * sinCosAt45), expectedAngles: ['top', 'left'] },
        'top-center': { x: 0, y: 0 - scaledRy, expectedAngles: ['top'] },
        'top-right': { x: scaledRx * sinCosAt45, y: -(scaledRy * sinCosAt45), expectedAngles: ['top', 'right'] },
        'center-left': { x: 0 - scaledRx, y: 0, expectedAngles: ['left'] },
        'center-right': { x: 0 + scaledRx, y: 0, expectedAngles: ['right'] },
        'bottom-left': { x: -(scaledRx * sinCosAt45), y: scaledRy * sinCosAt45, expectedAngles: ['bottom', 'left'] },
        'bottom-center': { x: 0, y: 0 + scaledRy, expectedAngles: ['bottom'] },
        'bottom-right': { x: scaledRx * sinCosAt45, y: scaledRy * sinCosAt45, expectedAngles: ['bottom', 'right'] }
    };
};

export default getRelativeAnchors;
