const { extractRGBAComponent } = require('../../utilities/stringUtility');
const { updateValueOfDescriptorWithPalette } = require('../../Shape/ColorValueDescriptor');

module.exports = {
    getColorStringFromColorValueDescriptor(canvasState, colorValueDescriptor = {}) {
        if (this.isCanvasState(canvasState)) {
            return updateValueOfDescriptorWithPalette(colorValueDescriptor, canvasState.get('colorPalette').toJS()).value;
        }
        return '';
    },

    getColorValueDescriptorForColorString(canvasState, colorString = '') {
        if (this.isCanvasState(canvasState)) {
            const { red, green, blue } = extractRGBAComponent(colorString);
            const colorInPalette = canvasState.get('colorPalette').find(colorToCompare => {
                const componentsToCompare = extractRGBAComponent(colorToCompare.get('color'));
                return (
                    red === componentsToCompare.red &&
                    green === componentsToCompare.green &&
                    blue === componentsToCompare.blue
                );
            });
            if (colorInPalette) {
                return {
                    type: 'coded',
                    reference: colorInPalette.get('id'),
                    value: colorString
                };
            }
            return {
                type: 'custom',
                value: colorString
            };
        }
        return null;
    },

    copyPresetOnDescriptor(newDescriptor = {}, oldDescriptor = {}) {
        if (newDescriptor.reference === oldDescriptor.reference) {
            newDescriptor.preset = oldDescriptor.preset;
        }
        return newDescriptor;
    }
};
