const jsonschema = require('jsonschema');
const pickBy = require('lodash/pickBy');
const isNil = require('lodash/isNil');
const omit = require('lodash/omit');
const set = require('lodash/set');
const update = require('lodash/update');
const uniqBy = require('lodash/uniqBy');
const cloneDeep = require('lodash/cloneDeep');
const defaultsDeep = require('lodash/defaultsDeep');

const Fill = require('../Fill');
const StyleDefinitionJSONSchema = require('./config/StyleDefinition.jsonschema');
const kindDescriptors = require('./config/kinds');

const getKindSpecificPropertyDescriptors = kind => {
    if (!Object.keys(kindDescriptors).includes(kind)) {
        throw new Error(`${kind} is an invalid kind`);
    }

    return kindDescriptors[kind] || {};
};

const getKindPropertyDescriptors = kind => ({
    ...getKindSpecificPropertyDescriptors('SharedProps'),
    ...getKindSpecificPropertyDescriptors(kind)
});

const getKindPropertyDescriptor = (kind, property) => getKindPropertyDescriptors(kind)[property];

const getKindProperties = kind => Object.keys(getKindPropertyDescriptors(kind));

const filterStyleDefinitionsByKind = ({
    styleDefinitions = [],
    kind
}) => styleDefinitions.filter(styleDefinition => styleDefinition.kind === kind);

const getKindReferenceProperties = kind => Object
    .entries(getKindPropertyDescriptors(kind))
    .filter(descriptor => descriptor[1].isReference === true)
    .map(([property]) => property);

const pickKindPropertiesInStyleDefinition = (styleDefinition = {}) => {
    const kindProperties = getKindProperties(styleDefinition.kind);
    return pickBy(styleDefinition, (value, key) => (
        kindProperties.includes(key) &&
        !isNil(value) &&
        value !== ''
    ));
};

const validate = (styleDefinition = {}) => jsonschema.validate(
    styleDefinition,
    StyleDefinitionJSONSchema
)
    .valid;

const validateAll = (styleDefinitions = []) => styleDefinitions.every(validate);

const getBaseStyleDefinition = (
    styleDefinitions = [],
    { kind, basedOn } = {},
    { scheme, colorPalette } = {}
) => {
    if (basedOn) {
        // eslint-disable-next-line no-use-before-define
        const baseStyleDefinition = getStyleDefinitionById(
            styleDefinitions,
            basedOn,
            { scheme, colorPalette }
        );
        if (baseStyleDefinition) {
            if (kind === baseStyleDefinition.kind) {
                return baseStyleDefinition;
            }
            throw new Error('Style definition base should be the same style');
        }
        throw new Error(`Cannot find base style ${basedOn}`);
    }

    return {};
};

const shouldUpdateColorReference = ({
    kind,
    property
}) => getKindPropertyDescriptor(kind, property).isFill === true;

const getSchemeColor = ({
    colorsPreset,
    scheme = 'monochromatic'
}) => colorsPreset[`${scheme}SchemeColor`];

const updateColorReference = ({
    styleDefinitions,
    kind,
    property,
    descriptor,
    scheme,
    colorPalette = []
}) => {
    if (shouldUpdateColorReference({ kind, property })) {
        const colorsPresets = filterStyleDefinitionsByKind({
            styleDefinitions,
            kind: 'ColorsPreset'
        });
        return Fill.doActionOnFillColor(descriptor, (colorValueDescriptor = {}) => {
            const colorsPreset = colorsPresets
                .find(({ id }) => colorValueDescriptor.reference === id);
            if (colorsPreset) {
                const reference = getSchemeColor({
                    colorsPreset,
                    scheme
                });
                if (reference) {
                    const colorValueDescriptorWithReference = {
                        ...colorValueDescriptor,
                        reference,
                        preset: colorsPreset.id
                    };
                    const colorInPalette = colorPalette.find(({ id }) => reference === id);
                    if (colorInPalette) {
                        colorValueDescriptorWithReference.value = colorInPalette.color;
                    }
                    return colorValueDescriptorWithReference;
                }
            }
            return colorValueDescriptor;
        });
    }
    return descriptor;
};

const getStyleDefinitionById = (styleDefinitions = [], id, { scheme, colorPalette } = {}) => {
    let styleDefinition = styleDefinitions
        .find(cursor => cursor.id === id);

    const baseStyleDefinition = getBaseStyleDefinition(
        styleDefinitions,
        styleDefinition,
        { scheme, colorPalette }
    );

    if (styleDefinition) {
        styleDefinition = defaultsDeep(
            pickKindPropertiesInStyleDefinition(styleDefinition),
            baseStyleDefinition
        );

        styleDefinition = Object.entries(styleDefinition || {})
            .reduce(
                (updatedDefinition, [property, descriptor]) => ({
                    ...updatedDefinition,
                    [property]: updateColorReference({
                        styleDefinitions,
                        kind: styleDefinition.kind,
                        property,
                        descriptor,
                        scheme,
                        colorPalette
                    })
                }),
                {}
            );

        return styleDefinition;
    }

    return undefined;
};

const getEffectiveStyleById = (styleDefinitions = [], id, { scheme, colorPalette } = {}) => {
    const styleDefinition = getStyleDefinitionById(styleDefinitions, id, { scheme, colorPalette });

    const linkProperties = styleDefinition && getKindReferenceProperties(styleDefinition.kind);

    return styleDefinition && Object.entries(styleDefinition)
        .reduce((linkStyleDefinition, [property, value]) => ({
            ...linkStyleDefinition,
            [property]: (
                linkProperties.includes(property) ?
                    getEffectiveStyleById(styleDefinitions, value, { scheme, colorPalette }) :
                    value
            )
        }), {});
};

const getStyleDefinitionsFromEffectiveStyle = (styleDefinition = {}) => {
    const nestedStyleDefinitionsKeys = getKindReferenceProperties(styleDefinition.kind);

    const flattenStyleDefinitions = Object.entries(styleDefinition)
        .reduce(
            ({ flattenStyleDefinition, nestedStyleDefinitions }, [key, value]) => {
                const isNestedStyleDefinition = nestedStyleDefinitionsKeys.includes(key);
                return ({
                    flattenStyleDefinition: {
                        ...flattenStyleDefinition,
                        [key]: (isNestedStyleDefinition ? value.id : value)
                    },
                    nestedStyleDefinitions: (
                        isNestedStyleDefinition ?
                            [
                                ...nestedStyleDefinitions,
                                ...getStyleDefinitionsFromEffectiveStyle(value)
                            ] :
                            nestedStyleDefinitions
                    )
                });
            },
            { flattenStyleDefinition: {}, nestedStyleDefinitions: [] }
        );

    return uniqBy(
        [
            flattenStyleDefinitions.flattenStyleDefinition,
            ...flattenStyleDefinitions.nestedStyleDefinitions
        ],
        'id'
    );
};

const getStyleDefinitionWithoutInheritedProperties = (
    styleDefinitions,
    styleDefinitionWithInheritedProperties,
    { scheme, colorPalette } = {}
) => {
    const { basedOn } = styleDefinitionWithInheritedProperties;
    const baseStyleDefinition = getStyleDefinitionById(
        styleDefinitions,
        basedOn,
        { scheme, colorPalette }
    );
    return (
        Object.entries(styleDefinitionWithInheritedProperties)
            .filter(([key, value]) => (
                ['kind', 'name'].includes(key) || baseStyleDefinition[key] !== value))
            .reduce((styleDefinitionWithOwnProperties, [key, value]) => (
                { ...styleDefinitionWithOwnProperties, [key]: value }), {})
    );
};

const applyStyleProperty = (
    value,
    conversionDescriptor = {},
    canvasItemStyle = {},
    styleDefinition = {}
) => {
    if (conversionDescriptor.toItemUpdate) {
        return conversionDescriptor.toItemUpdate(
            value,
            canvasItemStyle,
            styleDefinition
        );
    }
    if (conversionDescriptor.itemUpdatePropertyName) {
        return set(
            canvasItemStyle,
            conversionDescriptor.itemUpdatePropertyName,
            value
        );
    }
    return canvasItemStyle;
};

const applyKindStyle = (canvasItem, styleDefinition = {}) => Object
    .entries(getKindPropertyDescriptors(styleDefinition.kind))
    .reduce(
        (
            canvasItemStyle,
            [styleDefinitionProperty, conversionDescriptor]
        ) => applyStyleProperty(
            styleDefinition[styleDefinitionProperty],
            conversionDescriptor,
            cloneDeep(canvasItemStyle),
            styleDefinition
        ),
        cloneDeep(canvasItem)
    );

const getReferencedStylesFromPropertyWithParent = (
    currentCanvasItemStyle = {},
    referencedProperty,
    styleDefinition,
    type
) => {
    const propertyDescriptor = getKindPropertyDescriptor(
        styleDefinition.kind,
        referencedProperty
    );
    if (propertyDescriptor.referenceUpdateParent) {
        return set(
            currentCanvasItemStyle,
            propertyDescriptor.referenceUpdateParent,
            applyStyle(
                {},
                styleDefinition[referencedProperty],
                type
            )
        );
    }
    return undefined;
};

const getReferencedStylesFromProperties = (
    referencedProperties = [],
    styleDefinition,
    type
) => referencedProperties
    .filter(referencedProperty => styleDefinition[referencedProperty])
    .reduce(
        (currentCanvasItemStyle, referencedProperty) => (
            getReferencedStylesFromPropertyWithParent(
                currentCanvasItemStyle,
                referencedProperty,
                styleDefinition,
                type
            ) ||
                applyStyle(
                    currentCanvasItemStyle,
                    styleDefinition[referencedProperty],
                    type
                )
        ),
        {}
    );

const getPropertiesToOmitForTargetType = type => {
    switch (type.toLowerCase()) {
        case 'ellipse':
            return ['rx', 'ry'];
        default:
            return [];
    }
};

const applyStyle = (canvasItem = {}, styleDefinition = {}, type = '') => {
    if (Object.keys(styleDefinition).length) {
        const referencedProperties = getKindReferenceProperties(styleDefinition.kind);
        const referenceStyle = getReferencedStylesFromProperties(
            referencedProperties,
            styleDefinition,
            type
        );
        const propertiesToOmit = getPropertiesToOmitForTargetType(type);
        return omit({
            ...applyKindStyle(referenceStyle, styleDefinition),
            ...canvasItem
        }, propertiesToOmit);
    }
    return undefined;
};

const getBulletStyleUpdateFromItem = (listItemStyle, bulletType) => ({
    listUpdate: {
        onlyBulletUpdate: true,
        allLevels: applyStyle({}, update(
            listItemStyle,
            'defaultBulletType',
            defaultBulletType => bulletType || defaultBulletType
        ))
    }
});

const forceBulletTypeOnListStyle = (listStyle, bulletType) => [1, 2, 3, 4, 5].reduce(
    (updatedListStyle, levelNo) => update(
        updatedListStyle,
        `level${levelNo}ItemPreset.defaultBulletType`,
        defaultBulletType => bulletType || defaultBulletType
    ),
    listStyle
);

const getBulletStyleUpdateFromList = (listStyle, bulletType) => {
    const typedListStyle = forceBulletTypeOnListStyle(listStyle, bulletType);
    return {
        listUpdate: {
            onlyBulletUpdate: true,
            ...applyStyle({}, typedListStyle)
        }
    };
};

const getBulletStyleUpdateForLevel = listStyle => applyStyle({}, listStyle);

module.exports = {
    applyKindStyle,
    applyStyle,
    applyStyleProperty,
    getBaseStyleDefinition,
    getKindReferenceProperties,
    getKindSpecificPropertyDescriptors,
    getKindPropertyDescriptors,
    getKindProperties,
    getEffectiveStyleById,
    getStyleDefinitionById,
    getStyleDefinitionsFromEffectiveStyle,
    getStyleDefinitionWithoutInheritedProperties,
    pickKindPropertiesInStyleDefinition,
    validate,
    validateAll,
    updateColorReference,
    shouldUpdateColorReference,
    filterStyleDefinitionsByKind,
    getSchemeColor,
    getBulletStyleUpdateFromItem,
    getBulletStyleUpdateFromList,
    forceBulletTypeOnListStyle,
    getBulletStyleUpdateForLevel
};
