import gql from 'graphql-tag';

import {
    getPageFields,
    getDeckFields
} from '#Utilities/graphql/fields';
import { makeClient } from '#Utilities/graphql/client';

export const setActiveSection = section => makeClient()
    .then(client => client.mutate({
        mutation: gql`
    mutation($section: String) {
        setActiveSection(section: $section)
    }
    `,
        variables: {
            section
        }
    }))
    .then(({ data }) => data.setActiveSection);

export const removePage = (deckId, pageId) => makeClient()
    .then(client => client.mutate({
        mutation: gql`
        mutation($deckId: String, $pageId: String) {
        removePage(deck: $deckId, page: $pageId) {
            ${getDeckFields()}
        }
    }
    `,
        variables: {
            deckId,
            pageId
        }
    }))
    .then(({ data }) => data.removePage);

export const reorderPages = (deckId, newOrder) => makeClient()
    .then(client => client.mutate({
        mutation: gql`
            mutation($deckId: String, $newOrder: [String]) {
        reorderPages(deckId: $deckId, newOrder: $newOrder) {
            ${getDeckFields()}
        }
    }
        `,
        variables: {
            deckId,
            newOrder
        }
    }))
    .then(({ data }) => data.reorderPages);

export const addPage = (deckId, page, pageIndex) => makeClient()
    .then(client => client.mutate({
        mutation: gql`
        mutation($deckId: String, $pageIndex: Int, $page: JSON) {
        addPage(deck: $deckId, pageIndex: $pageIndex, page: $page) {
            ${getDeckFields()}
        }
    }
    `,
        variables: {
            deckId,
            pageIndex,
            page
        }
    }))
    .then(({ data }) => data.addPage);

export const addPreviouslyExistingPage = (deckId, pageId, pageIndex) => makeClient()
    .then(client => client.mutate({
        mutation: gql`
        mutation($deckId: String, $pageId: String, $pageIndex: Int) {
        addPreviouslyExistingPage(deckId: $deckId, pageId: $pageId, pageIndex: $pageIndex) {
            ${getDeckFields()}
        }
    }
    `,
        variables: {
            deckId,
            pageId,
            pageIndex
        }
    }))
    .then(({ data }) => data.addPreviouslyExistingPage);

export const duplicatePage = (deckId, pageId, pageIndex) => makeClient()
    .then(client => client.mutate({
        mutation: gql`
        mutation($deckId: String, $pageId: String, $pageIndex: Int) {
        duplicatePage(deckId: $deckId, pageId: $pageId, pageIndex: $pageIndex) {
            ${getDeckFields()}
        }
    }
    `,
        variables: {
            deckId,
            pageId,
            pageIndex
        }
    }))
    .then(({ data }) => data.duplicatePage);

export const updatePage = (deckId, pageId, shapes, background, layout, originalLayout) => makeClient()
    .then(client => client.mutate({
        mutation: gql`
        mutation($deckId: String, $pageId: String, $shapes: [JSON], $background: JSON, $layout: JSON, $originalLayout: JSON) {
        updatePage(
            deck: $deckId,
            page: $pageId,
            shapes: $shapes,
            background: $background,
            layout: $layout,
            originalLayout: $originalLayout
        ) {
            ${getDeckFields()}
        }
    }
    `,
        variables: {
            deckId,
            pageId,
            shapes,
            background,
            layout,
            originalLayout
        }
    }))
    .then(({ data }) => data.updatePage);

export const updateTopic = (pageId, topic) => makeClient()
    .then(client => client.mutate({
        mutation: gql`
        mutation($pageId: String, $topic: JSON) {
        updateTopic(page: $pageId, topic: $topic) {
            ${getPageFields()}
        }
    }
    `,
        variables: {
            pageId,
            topic
        }
    }))
    .then(({ data }) => data.updateTopic);

export const updatePageVisibility = (deckId, pageId, visibility) => makeClient()
    .then(client => client.mutate({
        mutation: gql`
        mutation($deckId: String, $pageId: String, $visibility: Boolean) {
        updatePageVisibility(
            deckId: $deckId,
            pageId: $pageId,
            visibility: $visibility
        ) {
            ${getDeckFields()}
        }
    }
    `,
        variables: {
            deckId,
            pageId,
            visibility
        }
    }))
    .then(({ data }) => data.updatePageVisibility);

export const createTopicForPage = (pageId, topic) => makeClient()
    .then(client => client.mutate({
        mutation: gql`
        mutation($pageId: String, $topic: InputTopic) {
        createTopicForPage(
            page: $pageId,
            topic: $topic
        ) {
            ${getPageFields()}
        }
    }
    `,
        variables: {
            pageId,
            topic
        }
    }))
    .then(({ data }) => data.createTopicForPage);

export const updateDeckFromPlan = (deckId, modifications) => makeClient()
    .then(client => client.mutate({
        mutation: gql`
        mutation($deckId: String, $modifications: InputFromPlan) {
        updateDeckFromPlan(deckId: $deckId, modifications: $modifications) {
            ${getDeckFields()}
        }
    }
    `,
        variables: {
            deckId,
            modifications
        }
    }))
    .then(({ data }) => data.updateDeckFromPlan);

export const changeThemeOnNewDeck = ({ deckId, themeId }) => makeClient()
    .then(client => client.mutate({
        mutation: gql`
            mutation ($deckId: String, $themeId: String) {
        changeThemeOnNewDeck(deckId: $deckId, themeId: $themeId) {
            ${getDeckFields()}
        }
    }
        `,
        variables: {
            deckId,
            themeId
        }
    }))
    .then(({ data }) => data.changeThemeOnNewDeck);

export const createDeck = () => makeClient()
    .then(client => client.mutate({
        mutation: gql`
        mutation {
            createDeck {
                ${getDeckFields()}
            }
        }
    `
    }))
    .then(({ data }) => data.createDeck);

export const duplicateDeck = deckVersionId => makeClient()
    .then(client => client.mutate({
        mutation: gql`
        mutation($deckVersionId: String) {
        duplicateDeck(deckVersionId: $deckVersionId) {
            ${getDeckFields()}
        }
    }
    `,
        variables: {
            deckVersionId
        }
    }))
    .then(({ data }) => data.duplicateDeck);

export const deleteDeck = deckId => makeClient()
    .then(client => client.mutate({
        mutation: gql`
        mutation($deckId: String) {
        deleteDeck(deckId: $deckId)
    }
    `,
        variables: {
            deckId
        }
    }));

export const updateDeckTitle = (deckId, title) => makeClient()
    .then(client => client.mutate({
        mutation: gql`
        mutation($deckId: String, $title: String) {
        updateDeckTitle(deckId: $deckId, title: $title) {
            ${getDeckFields()}
        }
    }
    `,
        variables: {
            deckId,
            title
        }
    }))
    .then(({ data }) => data.updateDeckTitle);

export const updateThemeColorPalette = (deckId, colorPalette) => makeClient()
    .then(client => client.mutate({
        mutation: gql`
        mutation($deckId: String, $colorPalette: [InputColorPalette]) {
        updateThemeColorPalette(deckId: $deckId, colorPalette: $colorPalette) {
            ${getDeckFields()}
        }
    }
    `,
        variables: {
            deckId,
            colorPalette
        }
    }))
    .then(({ data }) => data.updateThemeColorPalette);

export const updateThemeLogoSource = (deckId, logoSource) => makeClient()
    .then(client => client.mutate({
        mutation: gql`
                mutation($deckId: String, $logoSource: String) {
            updateThemeLogoSource(
                deckId: $deckId,
                logoSource: $logoSource
            ) {
                theme
            }
        }
            `,
        variables: {
            deckId,
            logoSource
        }
    }))
    .then(({ data: { updateThemeLogoSource: { theme } } }) => theme);

export const addCommentToView = ({
    viewId,
    pageId,
    message
}) => makeClient()
    .then(client => client.mutate({
        mutation: gql`
        mutation($viewId: String, $pageId: String, $message: String) {
        comment(
            viewId: $viewId,
            pageId: $pageId,
            message: $message,
        )
    }
    `,
        variables: {
            viewId,
            pageId,
            message
        }
    }));
export const deleteCommentFromView = ({
    viewId,
    commentId
}) => makeClient()
    .then(client => client.mutate({
        mutation: gql`
        mutation($viewId: String, $commentId: String) {
        deleteComment(
            viewId: $viewId,
            commentId: $commentId
        )
    }
    `,
        variables: {
            viewId,
            commentId
        }
    }));
export const editCommentAndAddToView = ({
    viewId,
    commentId,
    message
}) => makeClient()
    .then(client => client.mutate({

        mutation: gql`
        mutation($viewId: String, $commentId: String, $message: String) {
        updateComment(
            viewId: $viewId,
            commentId: $commentId,
            message: $message
        )
    }
    `,
        variables: {
            viewId,
            commentId,
            message
        }
    }));

export const addFeedbackToView = ({
    viewId,
    pageId,
    feedback
}) => makeClient()
    .then(client => client.mutate({
        mutation: gql`
        mutation($viewId: String, $pageId: String, $feedback: JSON) {
        feedback(
            viewId: $viewId,
            pageId: $pageId,
            feedback: $feedback
        )
    }
    `,
        variables: {
            viewId,
            pageId,
            feedback
        }
    }));

export const addDurationToView = ({
    viewId,
    pageId,
    duration
}) => makeClient()
    .then(client => client.mutate({
        mutation: gql`
        mutation($viewId: String, $pageId: String, $duration: Int) {
        duration(
            viewId: $viewId,
            pageId: $pageId,
            duration: $duration
        )
    }
    `,
        variables: {
            viewId,
            pageId,
            duration
        }
    }));

export const createShare = input => makeClient()
    .then(client => client.mutate({
        mutation: gql`
        mutation($input: GraphQLShareInput) {
        createShare(input: $input) {
            id
        }
    }
    `,
        variables: {
            input
        }
    }))
    .then(({ data }) => data);

export const updateShare = (shareId, update, emailOptions) => makeClient()
    .then(client => client.mutate({
        mutation: gql`
        mutation($shareId: String!, $update: ShareUpdate!, $emailOptions: EmailOptions!) {
        updateShare(shareId: $shareId, update: $update, emailOptions: $emailOptions) {
            id
        }
    }
    `,
        variables: {
            shareId,
            update,
            emailOptions
        }
    }))
    .then(({ data }) => data);

export const deleteShare = shareId => makeClient()
    .then(client => client.mutate({
        mutation: gql`
        mutation($shareId: String!) {
        deleteShare(shareId: $shareId)
    }
    `,
        variables: {
            shareId
        }
    }))
    .then(({ data }) => data);

export const updateAllPageWithLayout = (deckId, layoutUpdate) => makeClient()
    .then(client => client.mutate({
        mutation: gql`
        mutation($deckId: String, $layoutUpdate: JSON) {
        updateAllPageWithLayout(deckId: $deckId, layoutUpdate: $layoutUpdate) {
            deck {
                ${getDeckFields()}
            },
            layouts,
            unaffectedShapesProperties,
            unaffectedShapes
        }
    }
    `,
        variables: {
            deckId,
            layoutUpdate
        }
    }))
    .then(({ data }) => data.updateAllPageWithLayout);

export const createNewVersionOfLayout = (deckId, layoutUpdate) => makeClient()
    .then(client => client.mutate({
        mutation: gql`
        mutation($deckId: String, $layoutUpdate: JSON) {
        createNewVersionOfLayout(deckId: $deckId, layoutUpdate: $layoutUpdate) {
            newLayoutName,
            layouts
        }
    }
    `,
        variables: {
            deckId,
            layoutUpdate
        }
    }))
    .then(({
        data: {
            createNewVersionOfLayout: createNewVersionOfLayoutOutput
        }
    }) => createNewVersionOfLayoutOutput);

export const sendInvite = (emails, message) => makeClient()
    .then(client => client.mutate({
        mutation: gql`
        mutation($emails: [String], $message: String){
        sendInvite(emails: $emails, message: $message){
            invites {
                _id,
                    email,
                    status,
                    createdAt,
                    resendCount
            },
                failedEmailErrors
        }
    }
    `,
        variables: {
            emails,
            message
        },
        fetchPolicy: 'no-cache'
    }))
    .then(({ data }) => data.sendInvite);

export const resendInvite = inviteId => makeClient()
    .then(client => client.mutate({
        mutation: gql`
        mutation($inviteId: String){
        resendInvite(inviteId: $inviteId){
            resendCount
        }
    }
    `,
        variables: {
            inviteId
        },
        fetchPolicy: 'no-cache'
    }))
    .then(({ data }) => data.resendInvite);

export const cancelInvite = inviteId => makeClient()
    .then(client => client.mutate({
        mutation: gql`
        mutation($inviteId: String){
        cancelInvite(inviteId: $inviteId)
    }
    `,
        variables: {
            inviteId
        },
        fetchPolicy: 'no-cache'
    }))
    .then(({ data }) => data.cancelInvite);

export const updateSource = update => makeClient()
    .then(client => client.mutate({
        mutation: gql`
        mutation($update: GraphQLSourceInput) {
        updateSource(update: $update)
    }
    `,
        variables: {
            update
        }
    }))
    .then(({ data }) => data.updateSource);

export const unsubscribe = () => makeClient()
    .then(client => client.mutate({
        mutation: gql`
        mutation {
            unsubscribe
        }
    `
    }))
    .then(({ data }) => data);

export const subscribe = (plan, source) => makeClient()
    .then(client => client.mutate({
        mutation: gql`
        mutation($plan: String, $source: String) {
        subscribe(plan: $plan, source: $source)
    }
    `,
        variables: {
            plan,
            source
        }
    }))
    .then(({ data }) => data);

export const subscribeForFree = plan => makeClient()
    .then(client => client.mutate({
        mutation: gql`
        mutation($plan: String) {
        subscribeForFree(plan: $plan)
    }
    `,
        variables: {
            plan
        }
    }))
    .then(({ data }) => data);

export const updateSubscription = plan => makeClient()
    .then(client => client.mutate({
        mutation: gql`
            mutation($plan: String) {
                updateSubscription(plan: $plan)
            }
        `,
        variables: {
            plan
        }
    }))
    .then(({ data }) => data.updateSubscription);

export const updateSubscriptionWithSource = (plan, source) => makeClient()
    .then(client => client.mutate({
        mutation: gql`
        mutation($plan: String, $source: String) {
            updateSubscriptionWithSource(plan: $plan, source: $source)
        }
    `,
        variables: {
            plan,
            source
        }
    }))
    .then(({ data }) => data);

export const getFileDimension = id => makeClient()
    .then(client => client.query({
        query: gql`
        query($id: String){
        file(id:$id) {
            width,
                height
        }
    }`,
        variables: {
            id
        }
    }))
    .then(({ data: { file } }) => file);

export const addStyleDefinitionsToDeckTheme = (deckId, styleDefinitions) => makeClient()
    .then(client => client.mutate({
        mutation: gql`
            mutation ($deckId: String, $styleDefinitions: [StyleDefinitionInput]) {
        addStyleDefinitionsToDeckTheme(deckId: $deckId, styleDefinitions: $styleDefinitions) {
            ${getDeckFields()}
        }
    }
        `,
        variables: {
            deckId,
            styleDefinitions
        }
    }))
    .then(({ data }) => data.addStyleDefinitionsToDeckTheme);

export const applyStyleSelectionToDeck = ({
    colorPalette,
    colorScheme,
    deckId,
    logoSource,
    themeId
}) => makeClient()
    .then(client => client.mutate({
        mutation: gql`
        mutation (
            $colorPalette: [InputColorPalette],
            $deckId: String,
            $logoSource: String,
            $themeId: String,
            $colorScheme: String
        ) {
        applyStyleSelectionToDeck(
            colorPalette: $colorPalette,
            deckId: $deckId,
            logoSource: $logoSource,
            themeId: $themeId,
            colorScheme: $colorScheme
        ) {
            ${getDeckFields()}
        }
    }
        `,
        variables: {
            colorPalette,
            colorScheme,
            deckId,
            logoSource,
            themeId
        }
    }))
    .then(({ data }) => data.applyStyleSelectionToDeck);

export const changePassword = (oldPassword, newPassword) => makeClient()
    .then(client => client.mutate({
        mutation: gql`
        mutation (
            $oldPassword: String,
            $newPassword: String
        ) {
        changePassword(
            oldPassword: $oldPassword,
            newPassword: $newPassword
        )
    }
        `,
        variables: {
            oldPassword,
            newPassword
        }
    })).then(({ data }) => ({
        error: '',
        passwordChanged: data.changePassword.passwordChanged
    }))
    .catch(response => {
        const errors = response.graphQLErrors.map(error => error.message);
        return {
            passwordChanged: false,
            error: errors[0]
        };
    });

export const changeName = name => makeClient()
    .then(client => client.mutate({
        mutation: gql`
        mutation (
            $name: String
        ) {
        updateName(
            name: $name
        )
    }
        `,
        variables: {
            name
        }
    })).then(({ data }) => ({
        error: '',
        name: data.updateName.name
    }))
    .catch(response => {
        const errors = response.graphQLErrors.map(error => error.message);
        return {
            name,
            error: errors[0]
        };
    });

export const changeOccupation = occupation => makeClient()
    .then(client => client.mutate({
        mutation: gql`
        mutation (
            $occupation: String
        ) {
        updateOccupation(
            occupation: $occupation
        )
    }
        `,
        variables: {
            occupation
        }
    })).then(({ data }) => ({
        error: '',
        occupation: data.updateOccupation.occupation
    }))
    .catch(response => {
        const errors = response.graphQLErrors.map(error => error.message);
        return {
            occupation,
            error: errors[0]
        };
    });

export const updateEmail = newEmail => makeClient()
    .then(client => client.mutate({
        mutation: gql`
        mutation (
            $email: String
        ) {
        updateEmail(
            email: $email
        )
    }
        `,
        variables: {
            email: newEmail
        }
    })).then(({ data }) => ({
        error: '',
        email: data.updateEmail.email
    }))
    .catch(response => {
        const errors = response.graphQLErrors.map(error => error.message);
        return {
            newEmail,
            error: errors[0]
        };
    });

export const login = (email, password) => makeClient()
    .then(client => client.mutate({
        mutation: gql`
        mutation (
            $email: String,
            $password: String
        ) {
            login(
                email: $email,
                password: $password
            )
        }
        `,
        variables: {
            email,
            password
        }
    }))
    .then(({ data }) => data.login.tokens)
    .catch(response => {
        const errors = response.graphQLErrors.map(error => error.message);
        return {
            error: errors[0]
        };
    });

export const logout = () => makeClient()
    .then(client => client.mutate({
        mutation: gql`
        mutation
        {
            logout
        }
        `
    }))
    .then(({ data }) => data)
    .catch(response => {
        const errors = response.graphQLErrors.map(error => error.message);
        return {
            error: errors[0]
        };
    });

export const changeWelcomeScreenOption = () => makeClient()
    .then(client => client.mutate({
        mutation: gql`
            mutation {
                changeWelcomeScreenOption
            }
        `
    }))
    .then(({ data }) => ({
        updatedOption: data.changeWelcomeScreenOption.result
    }))
    .catch(response => {
        const errors = response.graphQLErrors.map(error => error.message);
        return {
            error: errors[0]
        };
    });

export const resendConfirmation = (email, inviteId = '') => makeClient()
    .then(client => client.mutate({
        mutation: gql`
            mutation(
                $email: String,
                $inviteId: String
            ) {
                resendConfirmation(
                    email: $email,
                    inviteId: $inviteId
                )
            }
            `,
        variables: {
            email,
            inviteId
        }
    }))
    .then(({ data }) => data)
    .catch(response => {
        const errors = response.graphQLErrors.map(error => error.message);
        return {
            error: errors[0]
        };
    });
export const signUp = (email, password, name, occupation, inviteId = '') => makeClient()
    .then(client => client.mutate({
        mutation: gql`
        mutation(
            $email: String,
            $password: String,
            $name: String,
            $occupation: String,
            $inviteId: String
        ) {
            signUp(
                email: $email,
                password: $password,
                name: $name,
                occupation: $occupation,
                inviteId: $inviteId
            )
        }
        `,
        variables: {
            email,
            password,
            name,
            occupation,
            inviteId
        }
    }))
    .then(({ data }) => data)
    .catch(response => {
        const errors = response.graphQLErrors.map(error => error.message);
        return {
            error: errors[0]
        };
    });

export const renewTokens = refreshToken => makeClient(false)
    .then(client => client.mutate({
        mutation: gql`
        mutation(
            $refreshToken: String
        ) {
            renewTokens(
                refreshToken: $refreshToken
            )
        }
        `,
        variables: {
            refreshToken
        }
    }))
    .then(({ data }) => data.renewTokens.tokens)
    .catch(response => {
        const errors = response.graphQLErrors.map(error => error.message);
        return {
            error: errors[0]
        };
    });

export const resetPassword = email => makeClient()
    .then(client => client.mutate({
        mutation: gql`
        mutation(
            $email: String
        ) {
            resetPassword(
                email: $email
            )
        }
        `,
        variables: {
            email
        }
    }))
    .then(({ data }) => data)
    .catch(response => {
        const errors = response.graphQLErrors.map(error => error.message);
        return {
            error: errors[0]
        };
    });
export const confirmSignUp = (email, verificationCode) => makeClient()
    .then(client => client.mutate({
        mutation: gql`
            mutation(
                $email: String,
                $verificationCode: String
            ) {
                confirmSignUp(
                    email: $email,
                    verificationCode: $verificationCode
                )
            }
            `,
        variables: {
            email,
            verificationCode
        }
    }))

    .then(({ data }) => data)
    .catch(response => {
        const errors = response.graphQLErrors.map(error => error.message);
        return {
            error: errors[0]
        };
    });

export const confirmPassword = (email, verificationCode, newPassword) => makeClient()
    .then(client => client.mutate({
        mutation: gql`
        mutation(
            $email: String,
            $verificationCode: String,
            $newPassword: String
        ) {
            confirmPassword(
                email: $email,
                verificationCode: $verificationCode,
                newPassword: $newPassword
            )
        }
        `,
        variables: {
            email,
            verificationCode,
            newPassword
        }
    }))
    .then(({ data }) => data)
    .catch(response => {
        const errors = response.graphQLErrors.map(error => error.message);
        return {
            error: errors[0]
        };
    });
