import React from 'react';
import './NotFound.scss';

const NotFound = () => (
    <div className="NotFound">
        <h1 className="NotFound__title">404 Not Found</h1>
        <p>The page you have requested is not available on our servers.</p>
    </div>
);

export default NotFound;
