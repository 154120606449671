/* eslint-disable */  
const collectObjects = ({ objects,
    selectionX1Y1,
    selectionX2Y2,
    allowIntersect,
    isClick,
    inGroup,
    e,
    zoom }) => {
    let group = [];
    let currentObject;
    for (let i = objects.length; i--;) {
        currentObject = objects[i];

        if (!currentObject || !currentObject.selectable || !currentObject.visible) {
            continue;
        }

        if (currentObject.type === 'group') {
            const coords = currentObject.getCoords();
           
            const xPos = (coords[2].x + coords[0].x) / 2;
            const yPos = (coords[2].y + coords[0].y) / 2;

            let x1 = selectionX1Y1.x - xPos;
            let y1 = selectionX1Y1.y - yPos;
            let x2 = selectionX2Y2.x - xPos;
            let y2 = selectionX2Y2.y - yPos;

            if (!inGroup) {
                x1 = x1 / zoom;
                y1 = y1 / zoom;
                x2 = x2 / zoom;
                y2 = y2 / zoom;
            }

            const groupSelectionX1Y1 = new fabric.Point(x1, y1);
            const groupSelectionX2Y2 = new fabric.Point(x2, y2);

            const anyGroup = collectObjects({ 
                objects: currentObject._objects,
                selectionX1Y1: groupSelectionX1Y1,
                selectionX2Y2: groupSelectionX2Y2,
                allowIntersect,
                isClick,
                inGroup: true,
                e,
                zoom });
            if (anyGroup.length > 0) {
                group.push(currentObject);
            }
        } else if ((allowIntersect && currentObject.intersectsWithRect(selectionX1Y1, selectionX2Y2)) ||
                currentObject.isContainedWithinRect(selectionX1Y1, selectionX2Y2) ||
                (allowIntersect && currentObject.containsPoint(selectionX1Y1)) ||
                (allowIntersect && currentObject.containsPoint(selectionX2Y2))
        ) {
            group.push(currentObject);
            // only add one object if it's a click
            if (isClick) {
                break;
            }
        }
    }
    if (group.length > 1) {
        group = group.filter(object => !object.onSelect({ e }));
    }
    return group;
}

const isSelectable = (target) => target.id !== 'tempSelectionGroup';

module.exports = fabric => {

    /* eslint-disable */  
    fabric.Canvas.prototype._collectObjects = (function(originalFn) {
        return function(e){
            const x1 = this._groupSelector.ex;
            const y1 = this._groupSelector.ey;
            const x2 = x1 + this._groupSelector.left;
            const y2 = y1 + this._groupSelector.top;
            const selectionX1Y1 = new fabric.Point(Math.min(x1, x2), Math.min(y1, y2));
            const selectionX2Y2 = new fabric.Point(Math.max(x1, x2), Math.max(y1, y2));
            const allowIntersect = !this.selectionFullyContained;
            const isClick = x1 === x2 && y1 === y2;
            return collectObjects({ 
                objects: this._objects,
                selectionX1Y1,
                selectionX2Y2,
                allowIntersect,
                isClick,
                inGroup: false,
                e,
                zoom: this.getZoom() 
            });
        }
    })(fabric.Canvas.prototype._collectObjects);

    fabric.Canvas.prototype._setCursorFromEvent = (function(originalFn) {
        return function(e, target) {
            if (!target) {
                this.setCursor(this.defaultCursor);
                return false;
            }

            const useNewImplementation = this.useNewImplementation;
            const pointer = target.group && target.type === 'activeSelection' ?
                this._normalizePointer(target.group, this.getPointer(e, true)) : this.getPointer(e, true)

            let hoverCursor = this.defaultCursor;

            if (!useNewImplementation) {
                hoverCursor = !isSelectable(target) ? hoverCursor : target.hoverCursor || this.hoverCursor;
            } else {
                if (this.targets.length) {
                    hoverCursor = this.targets[0].hoverCursor;
                } else {
                    hoverCursor = !isSelectable(target) ? hoverCursor : target.hoverCursor || this.hoverCursor;
                }
            }

            let corner = target._findTargetCorner(pointer);
    
            if (!corner) {
                if (!useNewImplementation && target.subTargetCheck){
                    // hoverCursor should come from top-most subTarget,
                    // so we walk the array backwards
                    this.targets.filter(target => isSelectable(target)).concat().reverse().map(function(_target){
                        hoverCursor = _target.hoverCursor || hoverCursor;
                    });
                }
                this.setCursor(hoverCursor);
            } else {
                this.setCursor(this.getCornerCursor(corner, target, e));
            }
        };
    }) (fabric.Canvas.prototype._setCursorFromEvent);

    /*
    * Custom code for handling currentTransform for nested objects
    */
    fabric.Canvas.prototype._setupCurrentTransform = (function(originalSetupCurrentTransform) {
        return function(e, target, alreadySelected) {
            originalSetupCurrentTransform.apply(this, arguments);

            const pointer = this.getPointer(e);
            const absolutePosition = target.getAbsolutePosition();

            /* 
                Since position is relative to group we need to change the offset so it uses the absolutePosition and
                make sure we have the group offset so we can apply it in the dragHandler.
            */
            this._currentTransform.offsetX = pointer.x - absolutePosition.x;
            this._currentTransform.offsetY = pointer.y - absolutePosition.y;

            if (target.group && target.type === 'activeSelection') {
                corner = target._findTargetCorner(this._normalizePointer(target.group, this.getPointer(e, true)));
                action = this._getActionFromCorner(alreadySelected, corner, e, target);
                origin = this._getOriginFromCorner(target, corner);

                this._currentTransform = {
                    ...this._currentTransform,
                    corner,
                    action,
                    originX: origin.x,
                    originY: origin.y
                }

                this._currentTransform.original.originX = origin.x;
                this._currentTransform.original.originY = origin.y;
            }
        };
    }) (fabric.Canvas.prototype._setupCurrentTransform);

    /*
    * Custom code for handling groupOffset when translating an object inside a group
    */
    fabric.Canvas.prototype._translateObject = (function(originalTransformObject) {
        return function(x, y) {
            var transform = this._currentTransform,
                target = transform.target,
                groupOffset = target.group ? target.group.getAbsolutePosition() : { x: 0, y: 0 }
                newLeft = x - transform.offsetX - groupOffset.x,
                newTop = y - transform.offsetY - groupOffset.y,
                moveX = !target.get('lockMovementX') && target.left !== newLeft,
                moveY = !target.get('lockMovementY') && target.top !== newTop;
  
            moveX && target.set('left', newLeft);
            moveY && target.set('top', newTop);
            return moveX || moveY;
        };
    }) (fabric.Canvas.prototype._translateObject);

    /*
    * Part of the missing code for handling nested groups in addWithUpdate in Fabric 4.4.0
    * See PR https://github.com/fabricjs/fabric.js/pull/6774/
    */
    fabric.Canvas.prototype._realizeGroupTransformOnObject = (function(originalFn) {
        return function(instance) {
            if (instance.group && instance.group.type === 'activeSelection' && this._activeObject === instance.group) {
                var layoutProps = ['angle', 'flipX', 'flipY', 'left', 'scaleX', 'scaleY', 'skewX', 'skewY', 'top'];
                //Copy all the positionally relevant properties across now
                var originalValues = {};
                layoutProps.forEach(function (prop) {
                    originalValues[prop] = instance[prop];
                });
                fabric.util.addTransformToObject(instance, this._activeObject.calcOwnMatrix());
                return originalValues;
            }
            else {
                return null;
            }
        };
    }) (fabric.Canvas.prototype._realizeGroupTransformOnObject);


    /*
    * Forces update on parent group so bounds are update properly on transform
    */
    fabric.Canvas.prototype._performTransformAction = (function(originalPerformTransformAction) {
        return function(e, transform, pointer) {
            originalPerformTransformAction.apply(this, arguments);

            if (transform.target.group) {
                transform.target.group.addWithUpdate();
            }
        };
    }) (fabric.Canvas.prototype._performTransformAction);

    /*
    * Forces the use of absolute center point to calculate angle so it works inside groups
    */
    fabric.Canvas.prototype._rotateObject = (function(originalRotateObject) {
        return function(x, y) {
            var t = this._currentTransform,
                target = t.target,
                relativeCenterPoint = target.getCenterPoint(),
                absoluteCenterPoint = target.getAbsoluteCenterPoint(),
                constraintPosition = target.translateToOriginPoint(absoluteCenterPoint, t.originX, t.originY);

            if (target.lockRotation) {
                return false;
            }

            var lastAngle = Math.atan2(t.ey - constraintPosition.y, t.ex - constraintPosition.x),
                curAngle = Math.atan2(y - constraintPosition.y, x - constraintPosition.x),
                angle = fabric.util.radiansToDegrees(curAngle - lastAngle + t.theta),
                hasRotated = true;

            if (target.snapAngle > 0) {
                var snapAngle = target.snapAngle,
                    snapThreshold = target.snapThreshold || snapAngle,
                    rightAngleLocked = Math.ceil(angle / snapAngle) * snapAngle,
                    leftAngleLocked = Math.floor(angle / snapAngle) * snapAngle;

                if (Math.abs(angle - leftAngleLocked) < snapThreshold) {
                    angle = leftAngleLocked;
                }
                else if (Math.abs(angle - rightAngleLocked) < snapThreshold) {
                    angle = rightAngleLocked;
                }
            }

            // normalize angle to positive value
            if (angle < 0) {
                angle = 360 + angle;
            }
            angle %= 360;

            if (target.angle === angle) {
                hasRotated = false;
            }
            else {
                // rotation only happen here
                target.angle = angle;

                if (target.group) {
                    constraintPosition = target.translateToOriginPoint(relativeCenterPoint, t.originX, t.originY);
                }

                // Make sure the constraints apply
                target.setPositionByOrigin(constraintPosition, t.originX, t.originY);
            }

            return hasRotated;
        };
    }) (fabric.Canvas.prototype._rotateObject);
};
