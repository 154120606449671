import { Stack, Map } from 'immutable';
import { cloneDeep } from 'lodash';
import * as canvasStateActions from '#Constants/ActionTypes/canvas';
import * as clipboardActions from '#Constants/ActionTypes/clipboard';
import * as deckActions from '#Constants/ActionTypes/deck';
import * as buildActions from '#Constants/ActionTypes/build';
import * as planActions from '#Constants/ActionTypes/plan';
import * as historyActions from '#Constants/ActionTypes/history';

const initialState = {
    history: {
        undoStack: Stack(),
        redoStack: Stack()
    }
};

const MAX_STACK_SIZE = 20;

const CANVAS_ACTIONS_TO_HANDLE = Object.keys(canvasStateActions).filter(action => ![
    canvasStateActions.CANVAS_IS_RENDERED,
    canvasStateActions.START_CANVAS_STATE_SAVE,
    canvasStateActions.SET_CANVAS_UPDATING,
    canvasStateActions.SET_CANVAS_SIZE
].includes(action));

const CLIPBOARD_ACTIONS_TO_HANDLE = Object.keys(clipboardActions).filter(action => [
    clipboardActions.PASTE_CLIPBOARD_CONTENT
].includes(action));

const DECK_ACTIONS_TO_HANDLE = Object.keys({ ...deckActions, ...buildActions }).filter(action => [
    deckActions.REMOVE_PAGE_IN_CURRENT_DECK,
    buildActions.CHANGE_ACTIVE_PAGE
].includes(action));

const RESET_ACTIONS_TO_HANDLE = Object.keys({ ...deckActions, ...planActions, ...historyActions }).filter(action => [
    planActions.REORDER_STARTED,
    deckActions.ADD_PAGE,
    deckActions.DUPLICATE_PAGE,
    historyActions.REMOVE_PAGE,
    deckActions.FETCH_DECK_SUCCESS
].includes(action));

const isCanvasAction = action => CANVAS_ACTIONS_TO_HANDLE.includes(action.type);

const isClipboardAction = action => CLIPBOARD_ACTIONS_TO_HANDLE.includes(action.type);

const isDeckAction = action => DECK_ACTIONS_TO_HANDLE.includes(action.type);

const isResetAction = action => RESET_ACTIONS_TO_HANDLE.includes(action.type);

const isContextualSelectionChange = (currentState, updatedState) => {
    const currentContextualSelection = currentState.get('contextualSelection') || Map();
    const updatedContextualSelection = updatedState.get('contextualSelection') || Map();

    if (!currentContextualSelection.equals(updatedContextualSelection)) {
        return true;
    }

    return false;
};

const shouldSaveCanvasState = canvasState => {
    const lastCanvasAction = canvasState.getIn(['history', -1, 'type']);

    if (!lastCanvasAction || lastCanvasAction !== canvasStateActions.UPDATE_SELECTION) {
        return true;
    }

    return false;
};

const cleanHistoryIfNeeded = history => {
    const {
        undoStack,
        redoStack
    } = history;

    // Flips the stacks, removes first element (which is the last in the non reversed stack) and reverse again
    return {
        ...history,
        undoStack: undoStack.size > MAX_STACK_SIZE ? undoStack.reverse().shift().reverse() : undoStack,
        redoStack: redoStack.size > MAX_STACK_SIZE ? redoStack.reverse().shift().reverse() : redoStack
    };
};

const handleChange = (changeType, state, action) => {
    switch (changeType) {
        case historyActions.CANVAS_STATE:
            return {
                historyActionType: 'CANVAS_STATE',
                updatedState: state.build.canvasState
            };
        case historyActions.REMOVE_PAGE:
            return {
                historyActionType: 'REMOVE_PAGE',
                pageId: action.pageId,
                pageIndex: action.pageIndex,
                activePageIndex: state.deck.pages.findIndex(page => state.build.activePage.id === page.id)
            };
        case historyActions.CHANGE_PAGE:
            return {
                historyActionType: 'CHANGE_PAGE',
                newPageId: action.id,
                newPageIndex: action.pageIndex,
                currentPageIndex: action.currentPageIndex
            };
        default:
            throw new Error(`No change handler found for type ${changeType}`);
    }
};

const handleUndo = (undoType, state, action) => {
    switch (undoType) {
        case historyActions.CANVAS_STATE:
            return {
                historyActionType: 'CANVAS_STATE',
                updatedState: state.build.canvasState
            };
        case historyActions.REMOVE_PAGE:
            return {
                historyActionType: 'REMOVE_PAGE',
                pageId: action.pageId,
                pageIndex: action.pageIndex,
                activePageIndex: action.activePageIndex
            };
        case historyActions.CHANGE_PAGE:
            return {
                historyActionType: 'CHANGE_PAGE',
                newPageId: action.id,
                newPageIndex: action.newPageIndex,
                currentPageIndex: action.currentPageIndex
            };
        default:
            throw new Error(`No undo handler found for type ${undoType}`);
    }
};

const handleRedo = (redoType, state, action) => {
    switch (redoType) {
        case historyActions.CANVAS_STATE:
            return {
                historyActionType: 'CANVAS_STATE',
                updatedState: state.build.canvasState
            };
        case historyActions.REMOVE_PAGE:
            return {
                historyActionType: 'REMOVE_PAGE',
                pageId: action.pageId,
                pageIndex: action.pageIndex,
                activePageIndex: action.activePageIndex
            };
        case historyActions.CHANGE_PAGE:
            return {
                historyActionType: 'CHANGE_PAGE',
                newPageId: action.id,
                newPageIndex: action.newPageIndex,
                currentPageIndex: action.currentPageIndex
            };
        default:
            throw new Error(`No redo handler found for type ${redoType}`);
    }
};

const appHistory = appReducer => (state = initialState, action) => {
    const history = cleanHistoryIfNeeded(state.history);

    if (action.historyActionType) {
        return {
            ...appReducer(state, action),
            history
        };
    }

    if (action.type.match(/^UNDO_.*/)) {
        return {
            ...state,
            history: {
                ...history,
                undoStack: history.undoStack.shift(),
                redoStack: history.redoStack.unshift(handleUndo(action.type.replace(/^UNDO_/, ''), state, action))
            }
        };
    }

    if (action.type.match(/^REDO_.*/)) {
        return {
            ...state,
            history: {
                ...history,
                redoStack: history.redoStack.shift(),
                undoStack: history.undoStack.unshift(handleRedo(action.type.replace(/^REDO_/, ''), state, action))
            }
        };
    }

    const newState = appReducer(state, action);

    if (isCanvasAction(action) || isClipboardAction(action)) {
        const newCanvasState = newState.build.canvasState;
        const previousCanvasState = Map(state.build.canvasState);

        if (!shouldSaveCanvasState(newCanvasState) ||
            isContextualSelectionChange(previousCanvasState, newCanvasState)) {
            return {
                ...newState,
                history
            };
        }

        const previousState = cloneDeep(state);
        previousState.build.canvasState = previousCanvasState.delete('contextualSelection');
        return {
            ...newState,
            history: {
                ...history,
                undoStack: history.undoStack.unshift(handleChange('CANVAS_STATE', previousState, action)),
                redoStack: Stack()
            }
        };
    }

    if (isDeckAction(action)) {
        switch (action.type) {
            case deckActions.REMOVE_PAGE_IN_CURRENT_DECK:
                if (!RESET_ACTIONS_TO_HANDLE.includes(action.reason)) {
                    return {
                        ...newState,
                        history: {
                            ...history,
                            undoStack: history.undoStack.unshift(handleChange('REMOVE_PAGE', state, action)),
                            redoStack: Stack()
                        }
                    };
                }
                break;
            case buildActions.CHANGE_ACTIVE_PAGE:
                if (action.currentPageIndex !== -1 && !RESET_ACTIONS_TO_HANDLE.includes(action.reason)) {
                    return {
                        ...newState,
                        history: {
                            ...history,
                            undoStack: history.undoStack.unshift(handleChange('CHANGE_PAGE', state, action)),
                            redoStack: Stack()
                        }
                    };
                }
                break;
            default:
                break;
        }
    }

    if (isResetAction(action)) {
        return {
            ...newState,
            ...initialState
        };
    }

    return {
        ...newState,
        history
    };
};

export default appHistory;
