import { combineReducers } from 'redux';
import * as types from '#Constants/ActionTypes';

const isLoggingOut = (state = false, action) => {
    switch (action.type) {
        case types.LOGGING_OUT:
            return true;
        case types.LOGGED_OUT:
            return false;
        default:
            return state;
    }
};

const subscriptionWarning = (state = {
    isVisible: false,
    title: ''
}, action) => {
    switch (action.type) {
        case types.SHOW_SUBSCRIPTION_WARNING:
            return {
                isVisible: true,
                title: action.title,
                message: action.message
            };
        case types.HIDE_SUBSCRIPTION_WARNING:
            return {
                isVisible: false,
                title: '',
                message: ''
            };
        default:
            return state;
    }
};

export default combineReducers({
    isLoggingOut,
    subscriptionWarning
});
