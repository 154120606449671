import get from 'lodash/get';
import uniqBy from 'lodash/uniqBy';
import DecksignCanvas from 'decksign-canvas';

import * as types from '#Constants/ActionTypes';
import { getColorPalette, getPaletteColorSchemeFromDeck } from '#Utilities/decksign/deck';

const {
    api: {
        ItemStyle
    }
} = DecksignCanvas;

const getDefaultStyleReferenceFromDeck = deck => ({
    ...get(deck, 'applicationTheme.defaultStyles', {}),
    ...get(deck, 'originalTheme.defaultStyles', {}),
    ...get(deck, 'theme.defaultStyles', {})
});

const getStyleDefinitionsFromDeck = deck => {
    const flatStyleDefinitions = [
        ...get(deck, 'theme.styleDefinitions', []),
        ...get(deck, 'originalTheme.styleDefinitions', []),
        ...get(deck, 'applicationTheme.styleDefinitions', [])
    ];

    return uniqBy(flatStyleDefinitions, 'id');
};

const getEffectiveStylesForDeck = deck => {
    const defaultStyles = getDefaultStyleReferenceFromDeck(deck);
    const styleDefinitions = getStyleDefinitionsFromDeck(deck);
    const scheme = getPaletteColorSchemeFromDeck(deck);
    const colorPalette = getColorPalette(deck);
    return Object.entries(defaultStyles)
        .reduce(
            (effectiveStyles, [effectiveStyle, styleDefinitionId]) => ({
                ...effectiveStyles,
                [effectiveStyle]: ItemStyle.getEffectiveStyleById(
                    styleDefinitions,
                    styleDefinitionId,
                    { scheme, colorPalette }
                )
            }),
            {}
        );
};

export default (state = {}, action) => {
    switch (action.type) {
        case types.CHANGE_DECK_PROPERTIES:
        case types.CREATE_DECK_SUCCESS:
        case types.FETCH_DECK_SUCCESS:
        case types.SELECT_COLOR_PALETTE: {
            const {
                deck
            } = action;
            return getEffectiveStylesForDeck(deck);
        }
        case types.SET_USER: {
            const {
                currentDeck: deck
            } = action;
            return getEffectiveStylesForDeck(deck);
        }
        default:
            return state;
    }
};
