const set = require('lodash/set');
const get = require('lodash/get');
const isString = require('lodash/isString');

const getFirstRunStyle = require('./getFirstRunStyle');

module.exports = {
    toItemUpdate(value, canvasItemStyle = {}) {
        const cleanedUpdate = set(canvasItemStyle, 'textTransform', '');
        switch (value) {
            case 'subscript':
            case 'superscript':
            case 'overline':
            case 'underline':
            case 'linethrough':
                return set(
                    cleanedUpdate,
                    value,
                    true
                );
            case 'smallCaps':
            case 'allCaps':
            case 'capitalize':
                return set(
                    cleanedUpdate,
                    'textTransform',
                    value
                );
            default:
                return canvasItemStyle;
        }
    },
    toStyle(canvasItem = {}) {
        const firstRunStyle = getFirstRunStyle(canvasItem);
        let effect = ['subscript', 'superscript', 'overline', 'underline', 'linethrough'].find(property => (
            get(firstRunStyle, property) === true
        ));

        const textTransform = get(firstRunStyle, 'textTransform');

        if (!effect && isString(textTransform)) {
            effect = textTransform;
        }

        return effect;
    }
};
