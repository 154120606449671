/**
 * We use the DOM cartesian chart for all our calculation here, which is a standard cartesian
 * chart with its y-axis inverted. The angles and rotations are calculated from the positive
 * x-axis in a clockwise manner, meaning that a horizontal line has a rotation of 0 degrees
 * (or 180 degrees depends on its point's order).
 */

export const sanitizePoint = ({ x, y }) => ({
    x: sanitizeNumber(x),
    y: sanitizeNumber(y)
});

export const sanitizeCommand = command => command
    .map((val, idx) => (idx === 0 ? val : sanitizeNumber(val)));

export const sanitizeNumber = num => {
    const invalidValues = [undefined, null];

    if (typeof num !== 'number' || invalidValues.includes(num)) {
        throw new Error('Invalid number.');
    }

    if (num >= -0.00005 && num <= 0.00005) {
        return 0;
    }

    return num;
};

export const distanceBetweenPoints = (p1, p2) => Math.hypot(p2.x - p1.x, p2.y - p1.y);

export const angleBetweenPoints = (p1, p2, inDegrees = true) => {
    const angle = adjustAngleAxis(
        Math.atan2(p2.x - p1.x, p2.y - p1.y)
    );

    return inDegrees ?
        toDegrees(angle) :
        angle;
};

const adjustAngleAxis = angle => {
    const invertedYAxis = (2 * Math.PI) - angle;
    const relativeToXAxis = invertedYAxis + (Math.PI / 2);
    return relativeToXAxis % (2 * Math.PI);
};

export const rotatePoint = (point, angle, center = { x: 0, y: 0 }) => {
    const cos = Math.cos(toRadians(angle));
    const sin = Math.sin(toRadians(angle));
    const newX = ((cos * (point.x - center.x)) - (sin * (point.y - center.y))) + center.x;
    const newY = (sin * (point.x - center.x)) + (cos * (point.y - center.y)) + center.y;
    return sanitizePoint({
        x: Math.fround(newX),
        y: Math.fround(newY)
    });
};

export const toRadians = angle => angle * (Math.PI / 180);

export const toDegrees = angle => angle * (180 / Math.PI);

export const arcLength = (radius, angle, inDegrees = true) => (
    inDegrees ?
        toRadians(Math.abs(angle)) * radius :
        Math.abs(angle) * radius
);
