const Bounds = require('./Bounds');

class DiamondBounds extends Bounds {
    get dimensions() {
        return {
            width: this.shapeWidth / 2,
            height: this.shapeHeight / 2
        };
    }
}

module.exports = DiamondBounds;
