import CommandTypes from '../../../Canvas/commands/types';
import tableUpdateProperties from './updateProperties';
import removeLine from './removeLine';
import addLine from './addLine';
import { merge, split } from './mergeAndSplit';
import add from './add';
import copy from './copy';
import clear from './clear';
import selectCells from './selectCells';

const tableCommands = {
    [CommandTypes.UPDATE_PROPERTIES_ON_SELECTED_SHAPES]: tableUpdateProperties,
    [CommandTypes.SELECT_CELLS]: selectCells,
    [CommandTypes.ADD_TABLE_LINE]: addLine,
    [CommandTypes.REMOVE_TABLE_LINE]: removeLine,
    [CommandTypes.SPLIT_SELECTED_CELLS]: split,
    [CommandTypes.CREATE_SHAPES]: add,
    [CommandTypes.SET_CLIPBOARD]: copy,
    [CommandTypes.COPY_SELECTED_SHAPES]: copy,
    [CommandTypes.MERGE_SELECTED_CELLS]: merge,
    [CommandTypes.CLEAR_SELECTED_CELLS]: clear
};

export default tableCommands;
