import { fromJS, Map } from 'immutable';

export default fromJS({
    id: {
        itemUpdatePropertyName: 'styleId'
    },
    fill: {
        toItemUpdate: (value, shapeStyle) => shapeStyle
            .merge(Map({ fill: value })),
        toStyle: shape => shape.get('fill'),
        isFill: true
    },
    opacity: {
        itemUpdatePropertyName: 'opacity',
        toStyle: shape => shape.get('opacity')
    },
    roundedCorners: {
        toItemUpdate: (value, shapeStyle) => (
            value ?
                shapeStyle.merge(Map({
                    rx: value,
                    ry: value
                })) :
                shapeStyle
        ),
        toStyle: shape => (shape.get('rx') + shape.get('ry')) / 2
    }
});
