import { getCalloutTailVertexes } from '../../../../../utilities/ShapeGenerators';
import getTailAngle from './tailAngle';
import getTailLength from './tailLength';
import getTailWidth from './tailWidth';

const getTailVertexes = shape => getCalloutTailVertexes(
    shape.get('width'),
    shape.get('height'),
    getTailLength(shape),
    getTailWidth(shape),
    getTailAngle(shape)
);

export default getTailVertexes;
