import { createSelector } from 'reselect';
import { get } from 'lodash';

const USED_FEEDBACKS = ['representation', 'relevance', 'agreement'];
const aggregateFeedbackValues = feedbacks => Object.entries(feedbacks).reduce((totalFeedbacks, currentFeedback) => {
    if (USED_FEEDBACKS.includes(currentFeedback[0])) {
        return {
            positive: totalFeedbacks.positive + currentFeedback[1].positive,
            negative: totalFeedbacks.negative + currentFeedback[1].negative
        };
    }
    return { positive: totalFeedbacks.positive, negative: totalFeedbacks.negative };
}, { positive: 0, negative: 0 });

const parseDuration = duration => {
    if (duration < 60) {
        return `${duration} seconds`;
    }
    return `${Math.floor(duration / 60)} minutes`;
};

const isFeedbackSelection = selection => selection.match(/feedbacks.*/);

const isCommentSelection = selection => selection === 'comments';

const shareTableType = ({
    share: {
        currentChartSelection
    }
}) => {
    if (isFeedbackSelection(currentChartSelection)) {
        return 'feedbacks';
    } if (isCommentSelection(currentChartSelection)) {
        return 'comments';
    }
    return 'overview';
};

const shareTableData = ({
    share: {
        deckSummary: {
            shares
        },
        isAudienceLevel,
        currentShare,
        currentComment,
        currentChartSelection
    }
}) => {
    if (isAudienceLevel && Array.isArray(currentShare.audience)) {
        if (isFeedbackSelection(currentChartSelection)) {
            return currentShare.audience.map(person => ({
                id: person.id || `${person.audience + person.publicToken}`,
                dataType: 'share',
                name: person.audience === 'public' ? 'Public User' : person.audience,
                date: person.date,
                relevance: person.feedbacks.relevance,
                representation: person.feedbacks.representation,
                agreement: person.feedbacks.agreement,
                duration: parseDuration(person.duration.average),
                number: person.sequence,
                comments: person.comments.count
            }));
        } if (isCommentSelection(currentChartSelection)) {
            return currentShare.comments.list
                .reverse()
                .map((comment, index) => ({
                    id: comment.id,
                    dataType: 'comment',
                    number: index + 1,
                    createdAt: comment.date,
                    commentor: comment.commentor,
                    page: currentShare.pages.findIndex(page => page.id === comment.page) + 1,
                    message: comment.message
                }))
                .filter(comment => comment.commentor === currentComment.commentor);
        }
        return currentShare.audience.map(person => ({
            id: person.id || `${person.audience + person.publicToken}`,
            dataType: 'share',
            name: person.audience === 'public' ? 'Public User' : person.audience,
            date: person.date,
            duration: parseDuration(person.duration.average),
            pagesViewed: person.pagesViewed.length,
            opened: person.opened,
            read: person.read,
            feedbacks: aggregateFeedbackValues(person.feedbacks),
            comments: person.comments.count,
            number: person.sequence
        }));
    }
    if (isFeedbackSelection(currentChartSelection)) {
        return shares.map(share => ({
            id: share.id,
            dataType: 'share',
            comments: share.comments.count,
            duration: parseDuration(share.duration.average),
            relevance: share.feedbacks.relevance,
            representation: share.feedbacks.representation,
            agreement: share.feedbacks.agreement,
            name: share.description || share.id,
            number: share.sequence
        }));
    } if (isCommentSelection(currentChartSelection)) {
        if (currentShare && currentShare.comments) {
            return currentShare.comments.list.reverse().map((comment, index) => ({
                id: comment.id,
                dataType: 'comment',
                number: index + 1,
                createdAt: comment.date,
                commentor: comment.commentor,
                page: currentShare.pages.findIndex(page => page.id === comment.page) + 1,
                message: comment.message
            }));
        }
        return [];
    }
    return shares.map(share => ({
        id: share.id,
        dataType: 'share',
        comments: share.comments.count,
        createdAt: share.createdAt,
        duration: parseDuration(share.duration.average),
        feedbacks: aggregateFeedbackValues(share.feedbacks),
        name: share.description || share.id,
        recipients: share.recipients,
        audience: share.audience,
        isPublic: share.isPublic,
        number: share.sequence,
        opened: share.opened,
        read: share.read,
        pageViewed: share.pagesViewed.average,
        viewerComments: share.commentsCount,
        viewerDuration: share.duration
    }));
};

const getSharesFromLastState = state => state.share.deckSummary.shares || [];

const getShareUser = state => get(state, 'share.currentShare.user', '');

const getUser = state => get(state, 'user.profile.id', '');

export const isShareOwner = createSelector(
    getShareUser,
    getUser,
    (shareUser, user) => shareUser === user
);

export const getShareTableData = createSelector(
    [shareTableType, shareTableData],
    (tableType, data) => ({ tableType, data })
);

const isShareLoading = state => state.share.loading;
const isDeleteShareLoading = state => state.share.deleteShare.isLoading;

export const isLoading = createSelector(
    isShareLoading,
    isDeleteShareLoading,
    (...loadingIndicators) => loadingIndicators.some(indicator => !!indicator)
);

export const isNewShareModalVisible = state => get(state, 'share.displayNewShareModal', false);

export const getLastRecipients = createSelector(
    getSharesFromLastState,
    shares => {
        const lastShareWithRecipients = shares
            .find(({ recipients = [] }) => recipients.length > 0);
        if (!lastShareWithRecipients) {
            return [];
        }
        return lastShareWithRecipients.recipients;
    }
);
