const { fabric } = require('fabric');
const {
    defaultCursorZoneFormat,
    RESIZE_CURSOR_ZONE_SIZE
} = require('./config/defaultCursorZoneAttributes.config');
const { DEFAULT_TABLE_SIZE_SETTINGS } = require('../../../Table/config/defaultTableAttributes.config');
const { pixelRound } = require('../../../utilities/pixelRound.js');

const ResizeCursorZone = fabric.util.createClass(fabric.Rect, {
    type: 'cursorZone',
    excludeFromExport: true,

    initialize(border, table) {
        this.callSuper('initialize', {
            border, ...defaultCursorZoneFormat, originX: 'center', originY: 'center'
        });
        this.originX = 'center';
        this.originY = 'center';
        this.side = border.side;
        this.row = border.row;
        this.column = border.column;
        this.top = border.top + table.getAbsoluteTop();
        this.left = border.left + table.getAbsoluteLeft();
        this.table = table;
        this.border = border;
        if (border.isHorizontal()) {
            this.width = border.width;
            this.height = RESIZE_CURSOR_ZONE_SIZE;
            this.top -= RESIZE_CURSOR_ZONE_SIZE / 2;
            this.hoverCursor = 'row-resize';
        } else {
            this.width = RESIZE_CURSOR_ZONE_SIZE;
            this.height = border.height;
            this.left -= RESIZE_CURSOR_ZONE_SIZE / 2;
            this.hoverCursor = 'col-resize';
        }
        this.onMouseMove = this.onMouseMove.bind(this);
        this.onMouseUp = this.onMouseUp.bind(this);
        this.resizeLines = this.resizeLines.bind(this);
        this.onMouseDown = this.onMouseDown.bind(this);
        this.updatePositionAndSize = this.updatePositionAndSize.bind(this);
        this.on('mousedown', this.onMouseDown);
    },

    computeOffset(mouseCoords) {
        const offset = this.border.isHorizontal() ?
            mouseCoords.top - this.startTop :
            mouseCoords.left - this.startLeft;
        return offset > 0 ?
            Math.min(offset, this.travelInterval.max) :
            Math.max(offset, this.travelInterval.min);
    },

    computeTravelIntervalMin() {
        const columnsToConsider = this.table
            .getRowCursorZones(this.row)
            .map(cursorZone => cursorZone.column);
        const cellsToConsider = this.table
            .getCellsOnRow(this.row - 1, columnsToConsider);
        return cellsToConsider
            .reduce(
                (minTravel, cell) => Math.min(minTravel, cell.getSpannedHeight() - cell.getTextHeight()),
                this.table.rowHeights[this.row - 1] - DEFAULT_TABLE_SIZE_SETTINGS.minCellHeight
            );
    },

    updateBorder(border) {
        this.border = border;
    },

    updatePositionAndSize() {
        this.top = this.border.top + this.table.getAbsoluteTop();
        this.left = this.border.left + this.table.getAbsoluteLeft();

        if (this.border.isHorizontal()) {
            this.width = this.border.width;
            this.height = RESIZE_CURSOR_ZONE_SIZE;
            this.top -= RESIZE_CURSOR_ZONE_SIZE / 2;
            this.hoverCursor = 'row-resize';
        } else {
            this.width = RESIZE_CURSOR_ZONE_SIZE;
            this.height = this.border.height;
            this.left -= RESIZE_CURSOR_ZONE_SIZE / 2;
            this.hoverCursor = 'col-resize';
        }
        this.setCoords();
    },

    onMouseDown(e) {
        this.canvas.selection = false;
        this.startLeft = pixelRound(this.canvas.getPointer(e.e).x);
        this.startTop = pixelRound(this.canvas.getPointer(e.e).y);
        if (this.border.isHorizontal()) {
            this.travelInterval = {
                min: -this.computeTravelIntervalMin(),
                max: Infinity
            };
        } else {
            this.travelInterval = {
                min: -this.table.columnWidths[this.column - 1] +
                    DEFAULT_TABLE_SIZE_SETTINGS.minCellWidth,
                max: this.table.columnWidths[this.column] -
                    DEFAULT_TABLE_SIZE_SETTINGS.minCellWidth
            };
        }
        this.resetTableFocus(e);
        document.addEventListener('mousemove', this.onMouseMove);
        document.addEventListener('mouseup', this.onMouseUp);
    },

    onMouseMove(e) {
        if (!this.positionMarker) {
            this.createPositionMarker(e);
        }
        const mouseCoords = {
            left: pixelRound(this.canvas.getPointer(e).x),
            top: pixelRound(this.canvas.getPointer(e).y)
        };
        const offset = this.computeOffset(mouseCoords);
        if (this.border.isHorizontal()) {
            this.positionMarker.setTop(this.startTop + offset);
        } else {
            this.positionMarker.setLeft(this.startLeft + offset);
        }
    },

    createPositionMarker() {
        this.positionMarker = new fabric.PositionMarker(this.border);
        this.canvas.add(this.positionMarker);
    },

    onMouseUp(e) {
        this.canvas.selection = true;
        if (this.positionMarker) {
            const resizePosition = {
                top: this.positionMarker.top,
                left: this.positionMarker.left
            };
            const resizeOffset = this.computeOffset(resizePosition);
            if (this.border.isHorizontal()) {
                const newDefinedRowHeights = this.table.getRenderRowHeights();
                this.resizeLines(
                    [
                        ...newDefinedRowHeights.slice(0, this.row - 1),
                        newDefinedRowHeights[this.row - 1] + resizeOffset,
                        ...newDefinedRowHeights.slice(this.row)
                    ],
                    this.table.columnWidths,
                    this.table.rowHeights
                );
            } else {
                this.resizeLines(
                    this.table.definedRowHeights,
                    [
                        ...this.table.columnWidths.slice(0, this.column - 1),
                        this.table.columnWidths[this.column - 1] + resizeOffset,
                        this.table.columnWidths[this.column] - resizeOffset,
                        ...this.table.columnWidths.slice(this.column + 1)
                    ],
                    this.table.rowHeights
                );
            }
            this.canvas.remove(this.positionMarker);
            delete this.positionMarker;
        }
        document.removeEventListener('mousemove', this.onMouseMove);
        document.removeEventListener('mouseup', this.onMouseUp);
        this.resetTableFocus(e);
    },

    resizeLines(definedRowHeights, columnWidths, rowHeights) {
        this.canvas.fire('table:cells:resized', {
            target: this.border.getTable(), rowHeights, columnWidths, definedRowHeights
        });
    },

    resetTableFocus(e) {
        if (
            !this.canvas.getActiveObject() ||
            this.canvas.getActiveObject().id !== this.table.id
        ) {
            this.canvas.setActiveObject(this.table);
            this.canvas.fire('selection:created', e);
        }
    }
});

module.exports = ResizeCursorZone;
