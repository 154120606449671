exports.extraPropsToExtract = [
    'name',
    'lastScale',
    'hyperlink',
    'coords',
    'bullets',
    'anchorToLink',
    'id',
    'attachedAnchors',
    'inLayout',
    'placeholderType',
    'placeholderSequence',
    'padding',
    'isLocked',
    'isHidden',
    'isImported',
    'topVertex',
    'isRight',
    'baseLength',
    'sides',
    'tipWidth',
    'isFull',
    'headWidth',
    'tailHeight',
    'isDouble',
    'flipX',
    'flipY',
    'tailWidth',
    'tailLength',
    'tailAngle',
    'connectionStrategy'
];

exports.defaultSelectionGroupRectangle = {
    id: 'tempSelectionGroup',
    stroke: 'rgba(158, 158, 158, 0.85)',
    strokeDashArray: [10, 5],
    strokeWidth: 1,
    fill: 'rgba(0, 0, 0, 0.005)',
    hasControls: false,
    selectable: false
};
