import { fromJS, List, Map } from 'immutable';
import { isNil } from 'lodash';
import UUID from 'uuid/v4';
import Textbody from '../Text/TextBody';
import {
    forceShapeFillFromRendered,
    initShapeStyle
} from '../Style/shape';
import { updateBorders, UPDATES_SEQUENCE, createBorder } from './border';
import { DEFAULT_CELL_RUNSTYLE } from '../../../Table/Cell/config/defaultCellStyles.config';
import { MIN_FONT_SIZE } from '../../../fabric-adapter/constants/text';
import { enforceTextBodyDefaultsOnPlaceholder } from '../helpers';
import { removeText, setText } from '../Text/TextBodyProperties/TextWithStyles';
import {
    addBorder,
    getBorder,
    getBottomBorderSegments,
    getCellIndex,
    getHeightForCellAt,
    getRightBorderSegments,
    getLeftBorderSegments,
    getTopBorderSegments,
    getWidthForCellAt
} from './tableHelper';
import { combineDefinedAndRenderRowHeights } from './tableGridLogic';
import { DEFAULT_TEXT_BODY_STYLE } from '../Text/Config/defaultStyles';
import { forceOpacity } from '../Style/colorValueDescriptor';
import getPropertiesForDestructuring from '../../utilities/getPropertiesForDestructuring';
import { convertPropertiesToShapeProperties } from '../../utilities/convertPropertiesToShapeProperties';

const updateCell = (cell, update) => {
    let updatedCell = cell;

    const {
        shapeProps,
        textProps = Map(),
        isEmphasisStyle
    } = getPropertiesForDestructuring(
        update,
        [
            'shapeProps',
            'textProps',
            'isEmphasisStyle'
        ]
    );

    const {
        shapeFill,
        fill,
        opacity,
        shapeOpacity,
        margins = Map(),
        removeCustomStyles = false,
        style,
        assignedShapeStyle
    } = getPropertiesForDestructuring(
        shapeProps,
        [
            'shapeFill',
            'fill',
            'opacity',
            'shapeOpacity',
            'margins',
            'removeCustomStyles',
            'style',
            'assignedShapeStyle'
        ]
    );

    let shapeStyleToAssign = isEmphasisStyle ?
        cell.get('assignedShapeStyle').merge(assignedShapeStyle) :
        assignedShapeStyle;

    if (style) {
        updatedCell = updatedCell.set('style', style);
    }

    if (fill || shapeFill) {
        updatedCell = updatedCell.set('fill', fill || shapeFill);
    }

    if (!isNil(shapeOpacity) && !Number.isNaN(shapeOpacity)) {
        updatedCell = forceShapeFillFromRendered(updatedCell);
        updatedCell = updatedCell.set('opacity', shapeOpacity);
    }

    if (!isNil(opacity) && !Number.isNaN(opacity)) {
        updatedCell = forceShapeFillFromRendered(updatedCell);
        updatedCell = updatedCell.set('opacity', opacity);
    }
    if (textProps.keySeq().size > 0 || margins) {
        updatedCell = updatedCell.set('contents', updatedCell
            .get('contents')
            .map(content => content
                .set('textBody', Textbody.applyUpdate(content, content.get('textBody'), textProps
                    .merge(Map({ margins }))))
                .set('textBodyPlaceholder', Textbody.applyUpdate(content, content.get('textBodyPlaceholder'), textProps
                    .merge(Map({ margins }))))));
    }

    if (shapeStyleToAssign) {
        if (!isNil(shapeStyleToAssign.get('opacity')) && shapeStyleToAssign.getIn(['fill', 'color'])) {
            shapeStyleToAssign = shapeStyleToAssign.updateIn(
                ['fill', 'color'],
                color => forceOpacity(color, shapeStyleToAssign.get('opacity'))
            );
        }

        updatedCell = updatedCell.setIn(['assignedStyles', 'shape'], shapeStyleToAssign);
        if (removeCustomStyles) {
            updatedCell = initShapeStyle(updatedCell, Map());
        }
    }

    return updatedCell;
};

const updateCells = (table, cellIds, update) => {
    const {
        shapeProps = Map(),
        textProps = Map(),
        strokeProps = Map()
    } = getPropertiesForDestructuring(
        update,
        [
            'shapeProps',
            'textProps',
            'strokeProps'
        ]
    );

    const {
        removeCustomStyles,
        borderUpdates = Map()
    } = getPropertiesForDestructuring(
        shapeProps,
        [
            'removeCustomStyles',
            'borderUpdates'
        ]
    );

    let updatedTable = table;

    if (
        shapeProps.get('fill') ||
        shapeProps.get('shapeFill') ||
        shapeProps.get('opacity') ||
        shapeProps.get('shapeOpacity') ||
        shapeProps.get('assignedShapeStyle') ||
        textProps.keySeq().size > 0
    ) {
        updatedTable = cellIds.reduce((currentTable, id) => {
            const cellIndex = currentTable.get('cells').findIndex(cell => cell.get('id') === id);
            const cell = currentTable.getIn(['cells', cellIndex]);
            return currentTable.setIn(['cells', cellIndex], updateCell(cell, update));
        }, updatedTable);
    }

    if (strokeProps.get('fill') || strokeProps.get('width') || strokeProps.get('dash')) {
        updatedTable = updateBorders(table, strokeProps
            .merge(
                Map({
                    styleMismatches: borderUpdates.get('styleMismatches', List()),
                    removeCustomStyles,
                    type: shapeProps.get('type'),
                    ids: cellIds
                })
            ));
    }

    UPDATES_SEQUENCE
        .forEach(updateType => {
            if (borderUpdates.get(updateType)) {
                const borderUpdate = borderUpdates.get(updateType);
                updatedTable = updateBorders(updatedTable, convertPropertiesToShapeProperties(borderUpdate)
                    .get('strokeProps')
                    .merge(
                        Map({
                            styleMismatches: borderUpdates.get('styleMismatches', List()),
                            type: updateType,
                            removeCustomStyles,
                            ids: cellIds
                        })
                    ));
            }
        });

    return updatedTable;
};

const createCell = (name, row, column, rowSpan, columnSpan, attributes = {}) => {
    let cell = fromJS({
        id: attributes?.id ? attributes?.id : UUID().toString(),
        name,
        row,
        column,
        rowSpan,
        columnSpan,
        isHidden: false,
        isImported: false,
        isLocked: false
    });

    if (attributes.style) {
        cell = cell.set('style', attributes.style);
    }
    const newAttributes = {
        ...attributes
    };

    const textBody = Textbody.TextBody({
        runStyles: fromJS([{
            ...DEFAULT_CELL_RUNSTYLE,
            isDefault: true
        }]),
        margins: fromJS(DEFAULT_TEXT_BODY_STYLE.get('margins'))
    });

    const textBodyPlaceholder = Textbody.TextBody();

    cell = cell.set('contents', List([
        fromJS({
            name,
            id: UUID().toString(),
            x: 0,
            y: 0,
            textBody,
            textBodyPlaceholder,
            type: 'Textbox'
        })
    ]));

    cell = initShapeStyle(cell, attributes);

    if (newAttributes.assignedShapeStyle) {
        cell = cell.setIn(['assignedShapeStyles', 'shape'], fromJS(newAttributes.assignedShapeStyle));
    }

    cell = Object.entries(newAttributes).reduce((currentCell, [key, value]) => {
        if (['width', 'height'].includes(key)) {
            return currentCell;
        }
        return currentCell.set(key, value);
    }, cell);

    return cell;
};

const copyCellContentStyles = (newCell, cell, newLine = true) => {
    let updatedCell = newCell;
    updatedCell = updatedCell.set('contents', cell
        .get('contents')
        .map(content => {
            let newContent = content;
            const textBody = newContent.getIn(['textBody']);
            if (newLine) {
                newContent = newContent.setIn(
                    ['textBody'],
                    textBody.updateIn(
                        ['runStyles', 0],
                        runStyle => runStyle.update(
                            'font',
                            (font = Map()) => font
                                .set(
                                    'size',
                                    MIN_FONT_SIZE
                                )
                        )
                    )
                );
            }
            return newContent;
        }));
    if (newLine) updatedCell = clearText(updatedCell);
    return updatedCell;
};

const copyCellShapeStyle = (newCell, cell) => {
    let updatedCell = newCell;
    updatedCell = copyShapeStyleProperties(updatedCell, cell);
    updatedCell = updatedCell.set('style', cell.get('style'));

    if (cell.getIn(['assignedStyles', 'shape'])) {
        updatedCell = updatedCell.setIn(['assignedStyles', 'shape'], cell.getIn(['assignedStyles', 'shape']));
    }

    return updatedCell;
};

const copyShapeStyleProperties = (newCell, cell) => ['fill']
    .reduce(
        (updatedCell, property) => updatedCell
            .set(property, cell.get(property)),
        newCell
    );

const clearText = cell => cell
    .set(
        'contents',
        cell
            .get('contents')
            .map(content => {
                let updatedContent = content;
                updatedContent = updatedContent.set('textBody', setText(updatedContent.get('textBody'), ''));
                updatedContent = enforceTextBodyDefaultsOnPlaceholder(updatedContent);
                return updatedContent;
            })
    );

const copyCellContentWithoutText = cell => {
    const content = cell.getIn(['contents', 0]);
    return content.set('textBody', removeText(content.get('textBody')));
};

const getCellWidth = (table, cell) => Math.ceil(getWidthForCellAt(table, cell.get('column'), cell.get('columnSpan')));

const getCellHeight = (table, cell) => Math.ceil(getHeightForCellAt(table, cell.get('row'), cell.get('rowSpan')));

const getColumnIndexAtRelativeX = (table, cell, x) => {
    const cellColumnWidths = table.get('columnWidths')
        .slice(cell.get('column'), cell.get('column') + cell.get('columnSpan'));
    let currentWidth = 0;
    let columnIndex = 0;
    for (let len = cellColumnWidths.size; columnIndex < len; columnIndex++) {
        currentWidth += cellColumnWidths.get(columnIndex);
        if (currentWidth > x) {
            break;
        }
    }
    return cell.get('column') + columnIndex;
};

const getColumnRelativeXFromCellRelativeX = (table, cell, x) => {
    const endColumn = getColumnIndexAtRelativeX(table, cell, x);
    const cellColumnWidths = table.get('columnWidths').slice(cell.get('column'), endColumn).reduce(
        (sum, width) => sum + width,
        0
    );
    return x - cellColumnWidths;
};

const expandSpanAfterColumnSplit = (table, cell, columnIndex) => {
    let updatedTable = table;
    const cellIndex = updatedTable
        .get('cells')
        .findIndex(currentCell => currentCell.get('id') === cell.get('id'));
    const newColumnIndex = columnIndex + 1;

    fromJS([
        ...cell.get('row') === 0 ? getTopBorderSegments(cell.get('id'), updatedTable) : List(),
        ...getBottomBorderSegments(cell.get('id'), updatedTable)
    ])
        .map(borderId => getBorder(updatedTable, borderId))
        .filter(border => border.get('column') > columnIndex)
        .forEach(filteredBorder => {
            const borderIndex = updatedTable
                .get('borders')
                .findIndex(border => border.get('id') === filteredBorder.get('id'));

            updatedTable = updatedTable.setIn(
                [
                    'borders',
                    borderIndex,
                    'column'
                ],
                updatedTable.getIn(['borders', borderIndex, 'column']) + 1
            );
        });

    getRightBorderSegments(cell.get('id'), updatedTable)
        .forEach(borderId => {
            const borderIndex = updatedTable
                .get('borders')
                .findIndex(border => border.get('id') === borderId);

            updatedTable = updatedTable.setIn(
                [
                    'borders',
                    borderIndex,
                    'column'
                ],
                updatedTable.getIn(['borders', borderIndex, 'column']) + 1
            );
        });

    updatedTable = updatedTable
        .setIn(
            ['cells', cellIndex, 'columnSpan'],
            updatedTable.getIn(['cells', cellIndex, 'columnSpan']) + 1
        );

    const topBorder = getTopBorderSegments(cell.get('id'), updatedTable)
        .map(borderId => getBorder(updatedTable, borderId)).get(0);

    const bottomBorder = getBottomBorderSegments(cell.get('id'), updatedTable)
        .map(borderId => getBorder(updatedTable, borderId)).get(0);

    if (topBorder.get('row') === 0) {
        const newTopBorder = createBorder(
            `Border Horizontal ${topBorder.get('row')}x${newColumnIndex}`,
            topBorder.get('stroke'),
            topBorder.get('side'),
            topBorder.get('row'),
            newColumnIndex
        );

        updatedTable = addBorder(updatedTable, newTopBorder);
    }

    const newBottomBorder = createBorder(
        `Border Horizontal ${bottomBorder.get('row')}x${newColumnIndex}`,
        bottomBorder.get('stroke'),
        bottomBorder.get('side'),
        bottomBorder.get('row'),
        newColumnIndex
    );

    updatedTable = addBorder(updatedTable, newBottomBorder);

    return updatedTable;
};

const splitAtColumnIndex = (table, cell, columnIndex) => {
    let updatedTable = table;
    const newCellColumnSpan = cell.get('columnSpan') - (columnIndex - cell.get('column'));
    const cellIndex = getCellIndex(updatedTable, cell.get('id'));
    updatedTable = updatedTable.setIn(['cells', cellIndex, 'columnSpan'], columnIndex - cell.get('column'));
    const newCell = createCell(
        `${cell.get('name')}_split_column`,
        cell.get('row'),
        columnIndex,
        cell.get('rowSpan'),
        newCellColumnSpan
    );
    updatedTable = updatedTable.update('cells', cells => cells.push(newCell));
    updatedTable = getRightBorderSegments(newCell.get('id'), updatedTable)
        .reduce((currentTable, borderId) => {
            const originalBorder = getBorder(currentTable, borderId);
            return addBorder(currentTable, createBorder(
                `Border Vertical ${columnIndex}x${originalBorder.get('name')}`,
                originalBorder.get('stroke'),
                originalBorder.get('side'),
                originalBorder.get('row'),
                columnIndex
            ));
        }, updatedTable);

    return updatedTable;
};

const getRowIndexAtRelativeY = (table, cell, y) => {
    const cellRowHeights = combineDefinedAndRenderRowHeights(table)
        .slice(cell.get('row'), cell.get('row') + cell.get('rowSpan'));
    let currentHeight = 0;
    let rowIndex = 0;
    for (let len = cellRowHeights.size; rowIndex < len; rowIndex++) {
        currentHeight += cellRowHeights.get(rowIndex);
        if (Math.round(currentHeight) >= Math.round(y)) {
            break;
        }
    }
    return cell.get('row') + rowIndex;
};

const getRowRelativeYFromCellRelativeY = (table, cell, y) => {
    const endRow = getRowIndexAtRelativeY(table, cell, y);
    const cellRowHeights = combineDefinedAndRenderRowHeights(table)
        .slice(cell.get('row'), endRow)
        .reduce(
            (sum, height) => sum + height,
            0
        );
    return y - cellRowHeights;
};

const expandSpanAfterRowSplit = (table, cell, rowIndex) => {
    let updatedTable = table;
    const cellIndex = updatedTable
        .get('cells')
        .findIndex(currentCell => currentCell.get('id') === cell.get('id'));
    const newRowIndex = rowIndex + 1;

    fromJS([
        ...cell.get('column') === 0 ? getLeftBorderSegments(cell.get('id'), updatedTable) : [],
        ...getRightBorderSegments(cell.get('id'), updatedTable)
    ])
        .map(borderId => getBorder(updatedTable, borderId))
        .filter(border => border.get('row') > rowIndex)
        .forEach(filteredBorder => {
            const borderIndex = updatedTable
                .get('borders')
                .findIndex(border => border.get('id') === filteredBorder.get('id'));

            updatedTable = updatedTable.setIn(
                [
                    'borders',
                    borderIndex,
                    'row'
                ],
                updatedTable.getIn(['borders', borderIndex, 'row']) + 1
            );
        });

    getBottomBorderSegments(cell.get('id'), updatedTable)
        .forEach(borderId => {
            const borderIndex = updatedTable
                .get('borders')
                .findIndex(border => border.get('id') === borderId);

            updatedTable = updatedTable.setIn(
                [
                    'borders',
                    borderIndex,
                    'row'
                ],
                updatedTable.getIn(['borders', borderIndex, 'row']) + 1
            );
        });

    updatedTable = updatedTable
        .setIn(
            ['cells', cellIndex, 'rowSpan'],
            updatedTable.getIn(['cells', cellIndex, 'rowSpan']) + 1
        );

    const leftBorder = getLeftBorderSegments(cell.get('id'), updatedTable)
        .map(borderId => getBorder(updatedTable, borderId)).get(0);

    const rightBorder = getRightBorderSegments(cell.get('id'), updatedTable)
        .map(borderId => getBorder(updatedTable, borderId)).get(0);

    if (leftBorder?.get('column') === 0) {
        const newLeftBorder = createBorder(
            `Border Vertical ${newRowIndex}x${leftBorder.get('column')}`,
            leftBorder.get('stroke'),
            leftBorder.get('side'),
            newRowIndex,
            leftBorder.get('column')
        );

        updatedTable = addBorder(updatedTable, newLeftBorder);
    }

    const newRightBorder = createBorder(
        `Border Vertical ${newRowIndex}x${rightBorder.get('column')}`,
        rightBorder.get('stroke'),
        rightBorder.get('side'),
        newRowIndex,
        rightBorder.get('column')
    );

    updatedTable = addBorder(updatedTable, newRightBorder);

    return updatedTable;
};

const splitAtRowIndex = (table, cell, rowIndex) => {
    let updatedTable = table;
    const newCellRowSpan = cell.get('rowSpan') - (rowIndex - cell.get('row'));
    const cellIndex = getCellIndex(updatedTable, cell.get('id'));
    updatedTable = updatedTable.setIn(['cells', cellIndex, 'rowSpan'], rowIndex - cell.get('row'));
    const newCell = createCell(
        `${cell.get('name')}_split_row`,
        rowIndex,
        cell.get('column'),
        newCellRowSpan,
        cell.get('columnSpan')
    );
    updatedTable = updatedTable.update('cells', cells => cells.push(newCell));
    updatedTable = getBottomBorderSegments(newCell.get('id'), updatedTable)
        .reduce((currentTable, borderId) => {
            const originalBorder = getBorder(currentTable, borderId);
            return addBorder(currentTable, createBorder(
                `Border Horizontal ${rowIndex}x${originalBorder.get('name')}`,
                originalBorder.get('stroke'),
                originalBorder.get('side'),
                rowIndex,
                originalBorder.get('column')
            ));
        }, updatedTable);

    return updatedTable;
};

export {
    updateCells,
    updateCell,
    createCell,
    copyCellContentStyles,
    copyCellShapeStyle,
    clearText,
    copyCellContentWithoutText,
    getCellWidth,
    getCellHeight,
    getColumnIndexAtRelativeX,
    getColumnRelativeXFromCellRelativeX,
    expandSpanAfterColumnSplit,
    expandSpanAfterRowSplit,
    splitAtColumnIndex,
    splitAtRowIndex,
    getRowIndexAtRelativeY,
    getRowRelativeYFromCellRelativeY
};
