const { fabric } = require('fabric');
const { get } = require('lodash');

const CanvasState = require('../../Canvas/CanvasState');
const { defaultSelectionGroupRectangle } = require('../config/DecksignFabricCanvas');

module.exports = {
    implementation: 'old',

    onMouseDown(e) {
        if (e.target && (e.target.selectable)) {
            this.on('mouse:move', this.onMouseMove)
                .on('mouse:up', this.onMouseUp);
        }
    },

    onMouseMove() {
        this.off('mouse:move', this.onMouseMove)
            .off('mouse:up', this.onMouseUp);
    },

    mouseDownBefore(e) {
        if (this.selection &&
            e.target.type === 'group' &&
            this.getSubtargetShapes().length === 0 &&
            !e.target.__corner) {
            this._groupSelector = {
                ex: this._pointer.x,
                ey: this._pointer.y,
                top: 0,
                left: 0
            };
        }

        if (this.groupedShapeIsBeingEdited && get(e, 'target.type') === 'group') {
            this.inSubGroupingProcess = true;
        }
        if (['mr', 'tr', 'mt', 'tl', 'ml', 'bl', 'mb', 'br'].includes(e.target?.__corner) && this.getActiveObject()?.editingText === true) {
            this.getActiveObject().exitTextEditing();
        }
    },

    onMouseUp(e) {
        this.handleTextEditingEnterEvent(e);
        if (e.target.type === 'group' && this.getSubtargetShapes().length > 0) {
            this.handleMouseUpOnGroup(e.target);
            this.requestRenderAll();
        }
        this.inSubGroupingProcess = false;
        this.off('mouse:move', this.onMouseMove)
            .off('mouse:up', this.onMouseUp);
    },

    selectActiveShapeInGroupChildren(
        parentGroupsPath = [],
        selectedChildShape,
        modifyGroupPath = false,
        {
            fireUpdate = true
        } = {}
    ) {
        const detachActiveShapeGroupPath = () => {
            if (parentGroupsPath.length > 0) {
                const firstParentGroup = this.getObjects().find(group => group.id === parentGroupsPath[0]);
                const lastParentGroup = parentGroupsPath
                    .slice(1)
                    .reduce((parentGroup, nextGroupId) => {
                        const childGroup = parentGroup.getObjects()
                            .find(group => group.id === nextGroupId);
                        parentGroup.removeWithUpdate(childGroup);
                        this.add(childGroup);
                        return childGroup;
                    }, firstParentGroup);
                const selectedShape = lastParentGroup.getObjects().find(shape => shape.id === selectedChildShape.id);
                selectedShape.originalParentPosition = {
                    left: lastParentGroup.left,
                    top: lastParentGroup.top
                };
                const parentPosition = {
                    left: lastParentGroup.left,
                    top: lastParentGroup.top
                };
                lastParentGroup.removeWithUpdate(selectedShape);
                this.add(selectedShape);
                if (selectedShape.renderModificationHandlers instanceof Function) {
                    selectedShape.renderModificationHandlers();
                }
                selectedShape.dirty = true;
                return parentPosition;
            }
            return { left: 0, top: 0 };
        };
        const parentPosition = detachActiveShapeGroupPath();
        this.groupedShapeBeingEditedInfo = {
            ...this.groupedShapeBeingEditedInfo,
            shapeId: selectedChildShape.id,
            parentPosition
        };
        if (modifyGroupPath && this.groupedShapeIsBeingEdited) {
            if (parentGroupsPath.length === 0) {
                this.reattachActiveShapeGroupPath();
                this.groupedShapeIsBeingEdited = false;
                this.groupedShapeBeingEditedInfo.groupsPath = parentGroupsPath;
            } else {
                const modifiedParentGroupsPath = this.groupedShapeBeingEditedInfo.groupsPath;
                modifiedParentGroupsPath.push(parentGroupsPath[0]);
                this.groupedShapeBeingEditedInfo.groupsPath = modifiedParentGroupsPath;
            }
        } else {
            this.groupedShapeIsBeingEdited = parentGroupsPath.length !== 0;
            this.groupedShapeBeingEditedInfo.groupsPath = parentGroupsPath;
        }
        if (fireUpdate) {
            this.canvasState = CanvasState.update(
                this.canvasState,
                {
                    type: 'UPDATE_SELECTION',
                    selection: [this.groupedShapeBeingEditedInfo.shapeId],
                    selectedShapesParentGroupsPath: [
                        this.groupedShapeBeingEditedInfo.groupsPath
                    ]
                }
            );
            this.fireCanvasStateUpdate();
        }
        this.setActiveObject(selectedChildShape);
        this.off('selection:updated', this.makeSelection);
        const containingGroup = this.retrieveGroupOfShapeBeingEdited();
        this.showContainingGroup(selectedChildShape, containingGroup);
        this.on('selection:updated', this.makeSelection);
        return selectedChildShape;
    },

    setLastParentGroupAsActiveShape() {
        [this.groupedShapeBeingEditedInfo.shapeId] = this
            .groupedShapeBeingEditedInfo.groupsPath.slice(-1);

        this.groupedShapeBeingEditedInfo.groupsPath = this
            .groupedShapeBeingEditedInfo.groupsPath.slice(0, -1);

        this.groupedShapeIsBeingEdited = this.groupedShapeBeingEditedInfo
            .groupsPath.length !== 0;
    },

    handleMouseUpOnGroup(target) {
        if (this.targets[0] && this.targets[0].selectable &&
            !!this.getActiveObjects().find(o => o.id === target.id)) {
            this.off('mouse:up', this.onMouseUp);
            this.selectActiveShapeInGroupChildren(
                [target.id],
                this.targets[this.targets.length - 1],
                true
            );
        }
        return target;
    },

    retrieveGroupOfShapeBeingEdited() {
        return this.getObjects().find(activeGroup => activeGroup.id === this.groupedShapeBeingEditedInfo
            .groupsPath[this.groupedShapeBeingEditedInfo.groupsPath.length - 1]);
    },

    showContainingGroup(selectedShape, containingGroup) {
        const computeRectanglePosition = (shape, group) => {
            const groupForBox = new fabric.Group([shape, group]);
            const position = groupForBox.getBoundingRect(true, true);
            groupForBox.destroy();
            return {
                top: position.top,
                left: position.left,
                width: position.width,
                height: position.height,
                ...defaultSelectionGroupRectangle
            };
        };
        if (containingGroup) {
            this.removeTempActiveSelection();
            const groupRectangle = new fabric.Rect(
                computeRectanglePosition(selectedShape, containingGroup)
            );
            this.insertAt(groupRectangle, this.getObjects().indexOf(selectedShape) - 1);
        }
    },

    removeTempActiveSelection() {
        if (this.groupedShapeIsBeingEdited &&
            this.groupedShapeBeingEditedInfo.groupsPath
        ) {
            const ancientActiveSelection = this.getObjects().find(shape => shape.id === 'tempSelectionGroup');
            if (ancientActiveSelection) {
                this.renderOnAddRemove = false;
                this.remove(ancientActiveSelection);
            }
        }
    },

    selectSuperiorGroupLevel() {
        this.removeTempActiveSelection();
        this.reattachActiveShapeGroupPath();
        this.setLastParentGroupAsActiveShape();

        if (this.groupedShapeIsBeingEdited) {
            this.reselectActiveShape();
        } else {
            const activeShape = this.getObjects()
                .find(shape => shape.id === this.groupedShapeBeingEditedInfo.shapeId);
            if (activeShape) {
                this.setActiveObject(activeShape);
                this.renderAll();
                this.bindListenerForSelectionUpdate([activeShape.id]);
            }
        }
    },

    isSelectionInGroup(canvasState) {
        if (canvasState.get('selectedShapesParentGroupsPath').size === 1 &&
            canvasState.get('selectedShapesParentGroupsPath').toJS()[0] &&
            canvasState.get('selectedShapesParentGroupsPath').toJS()[0].length > 0 &&
            canvasState.get('selection').size === 1) {
            return true;
        }
        return false;
    },

    shouldReattachPreviousShape(canvasState) {
        const previousParentGroupsPath = get(this, 'groupedShapeBeingEditedInfo.groupsPath') &&
            get(this, 'groupedShapeBeingEditedInfo.groupsPath')[0];
        const currentParentGroupsPath = canvasState.get('selectedShapesParentGroupsPath').toJS() &&
            canvasState.get('selectedShapesParentGroupsPath').toJS()[0] &&
            canvasState.get('selectedShapesParentGroupsPath').toJS()[0][0];
        return previousParentGroupsPath && (previousParentGroupsPath === currentParentGroupsPath ||
            previousParentGroupsPath === canvasState.get('selection').toJS()[0]);
    },

    reselectActiveShape(modifyGroupPath = false) {
        const parentGroupsPath = [];
        const lastParentGroup = this.groupedShapeBeingEditedInfo.groupsPath
            .reduce((group, nextGroupInPathId) => {
                const lastParentGroupInPath = group.getObjects().find(
                    shape => shape.id === nextGroupInPathId
                );
                parentGroupsPath.push(lastParentGroupInPath.id);
                lastParentGroupInPath.lastGroupIndex = group.getObjects().indexOf(lastParentGroupInPath);
                return lastParentGroupInPath;
            }, this);
        const selectedChildShape = lastParentGroup
            .getObjects()
            .find(shape => shape.id === this.groupedShapeBeingEditedInfo.shapeId);
        selectedChildShape.lastGroupIndex = lastParentGroup.getObjects().indexOf(selectedChildShape);
        return this.selectActiveShapeInGroupChildren(
            parentGroupsPath,
            selectedChildShape,
            modifyGroupPath,
            { fireUpdate: false }
        );
    },

    reattachActiveShapeGroupPath() {
        const selectedShape = this.getObjects().find(shape => shape.id === get(this, 'groupedShapeBeingEditedInfo.shapeId'));
        return this.reattachShapeInGroup(selectedShape);
    },

    reattachShapeInGroup(shape) {
        if (!shape) {
            return null;
        }
        const regroupedTarget = this.groupedShapeBeingEditedInfo.groupsPath
            .reduceRight((currentShape, nextGroupId) => {
                const nextGroup = this.getObjects().find(group => group.id === nextGroupId);
                this.remove(currentShape);
                nextGroup.insertAtWithUpdate(
                    currentShape,
                    currentShape.lastGroupIndex + 1
                );
                return nextGroup;
            }, shape);
        return regroupedTarget;
    },

    groupObject(object) {
        if (!object.selectable) {
            object._objects.forEach(ob => {
                ob.hoverCursor = 'default';
                if (ob.shapeType === 'Group') this.groupObject(ob);
            });
        } else if (object.shapeType === 'Group') object._objects.forEach(ob => { ob.hoverCursor = 'move'; if (ob.shapeType === 'Group') this.groupObject(ob); });
    }
};
