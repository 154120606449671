import {
    updateSelection,
    removeShape
} from '../../Helpers/helpers';

const removeShapes = canvasState => {
    let updatedCanvasState = canvasState;
    updatedCanvasState = canvasState
        .get('selection')
        .reduce((currentCanvasState, id) => removeShape(currentCanvasState, id), canvasState);

    return updateSelection(updatedCanvasState);
};

export default removeShapes;
