import { Map } from 'immutable';
import equalsOrNull from './equalsOrNull';

/*
* The mergeDeepWith method from immutable does not work the same
* as the one from lodash. The merger method is not called when
* the value is undefined in the map and not in the source which
* prevents it from being null.
*/
const mergeDeepWith = (map, source, exceptions) => map
    .keySeq()
    .reduce((currentMap, key) => {
        if (Map.isMap(map.get(key))) {
            return currentMap.set(
                key,
                mergeDeepWith(
                    map.get(key),
                    source.get(key),
                    exceptions
                )
            );
        }
        return currentMap.set(key, equalsOrNull(exceptions)(
            map.get(key),
            source.get(key)
        ));
    }, map);

export default mergeDeepWith;
