export * from './textSelection';
export const CHANGE_ACTIVE_PAGE = 'CHANGE_ACTIVE_PAGE';
export const CHANGE_INSERT_MENU_VISIBILITY = 'CHANGE_INSERT_MENU_VISIBILITY';
export const CHANGE_LAST_CLICK_WAS_ON_PAGE = 'CHANGE_LAST_CLICK_WAS_ON_PAGE';
export const CHANGE_PAGES_PREVIEW_VISIBILITY = 'CHANGE_PAGES_PREVIEW_VISIBILITY';
export const CHANGE_NAVIGATION_MENU_VIEW = 'CHANGE_NAVIGATION_MENU_VIEW';
export const CHANGE_TOOLBAR_MENU_VIEW = 'CHANGE_TOOLBAR_MENU_VIEW';
export const CHANGE_TOOLS_MENU_VIEW = 'CHANGE_TOOLS_MENU_VIEW';
export const CLOSE_LAYOUT_CONFIRMATION_MODAL = 'CLOSE_LAYOUT_CONFIRMATION_MODAL';
export const LOAD_TOPICS_LIST = 'LOAD_TOPICS_LIST';
export const LOAD_PAGE = 'LOAD_PAGE';
export const RESET_PAGE = 'RESET_PAGE';
export const SET_ADD_TOPIC_FORM_VISIBILITY = 'SET_ADD_TOPIC_FORM_VISIBILITY';
export const SET_CANVAS_ITEM_STYLE = 'SET_CANVAS_ITEM_STYLE';
export const SET_MOUSE_NAVIGATION_BUFFER_ZONE = 'SET_MOUSE_NAVIGATION_BUFFER_ZONE';
export const SET_WORKSPACE_DIMENSIONS = 'SET_WORKSPACE_DIMENSIONS';
export const SET_WORKSPACE_HEIGHT = 'SET_WORKSPACE_HEIGHT';
export const SET_WORKSPACE_LOADING = 'SET_WORKSPACE_LOADING';
export const SET_WORKSPACE_WIDTH = 'SET_WORKSPACE_WIDTH';
export const SHOW_LAYOUT_CONFIRMATION_MODAL = 'SHOW_LAYOUT_CONFIRMATION_MODAL';
export const START_LAYOUT_CHANGE = 'START_LAYOUT_CHANGE';
export const UPDATE_ADD_TOPIC_LABEL = 'UPDATE_ADD_TOPIC_LABEL';
export const UPDATE_ADD_TOPIC_DESCRIPTION = 'UPDATE_ADD_TOPIC_DESCRIPTION';
export const CONVERT_TO_LIST_TYPE = 'CONVERT_TO_LIST_TYPE';
export const UPDATE_TOPIC_SEARCH = 'UPDATE_TOPIC_SEARCH';
export const UPDATE_TOPICS_SUGGESTIONS = 'UPDATE_TOPICS_SUGGESTIONS';
export const UPDATE_RENDERED_FONT_SIZE = 'UPDATE_RENDERED_FONT_SIZE';
