const Tip = require('./Tip');
const { PathBuilder } = require('../../PathBuilder');

class OvalTip extends Tip {
    constructor(lineWidth, widthModifier, lengthModifier) {
        super(lineWidth, widthModifier, lengthModifier);
        this.recenterPath = true;
    }

    get path() {
        return new PathBuilder()
            .moveTo({ x: 0, y: 0 })
            .arcTo({
                rx: 0.5,
                ry: 0.5,
                xAxisRotation: 0,
                largeArcFlag: 0,
                sweepFlag: 1,
                x: 1,
                y: 0
            })
            .arcTo({
                rx: 0.5,
                ry: 0.5,
                xAxisRotation: 0,
                largeArcFlag: 0,
                sweepFlag: 1,
                x: 0,
                y: 0
            })
            .close()
            .scale(this.pathLength, this.pathWidth)
            .translate({ x: -this.pathLength / 2, y: 0 });
    }
}

module.exports = OvalTip;
