const UUID = require('uuid/v4');
const get = require('lodash/get');
const isNil = require('lodash/isNil');

const Table = require('../../Table/Table');

const isRequired = require('../../../../utilities/isRequired');
const {
    getKindPropertyDescriptors,
    getKindSpecificPropertyDescriptors
} = require('./ItemStyle');

const {
    PART_NAMES
} = require('../../CanvasState/Helpers/Table/part');

const getStylePropertyFromCanvasItem = (
    conversion = isRequired(),
    canvasItem = {}
) => {
    if (conversion.toStyle) {
        return conversion.toStyle(canvasItem);
    }
    if (conversion.itemUpdatePropertyName) {
        return get(canvasItem, conversion.itemUpdatePropertyName);
    }
    return undefined;
};

const getCurrentIndexFromObjectStyleBase = (id = '', baseId = '') => {
    const idParts = id.match(new RegExp(`^${baseId}(?:-(\\d*))?$`)) || [];
    const currentIndex = Number(idParts[1] || 0);
    return currentIndex;
};

const buildStyleDefinitionFromCanvasItem = (
    canvasItem = {},
    kind = isRequired(),
    withSharedProperties = false
) => {
    const descriptors = (withSharedProperties ?
        getKindPropertyDescriptors(kind) :
        getKindSpecificPropertyDescriptors(kind)
    );

    return Object.entries(descriptors)
        .reduce(
            (styleDefinition, [property, conversion]) => {
                const value = getStylePropertyFromCanvasItem(conversion, canvasItem);
                if (!isNil(value) && !Number.isNaN(value)) {
                    return {
                        ...styleDefinition,
                        [property]: value
                    };
                }
                return styleDefinition;
            },
            {}
        );
};

const getBaseInfoFromCanvasItem = ({
    id = UUID().toString,
    name = id,
    style = ''
} = {}, kind) => {
    const baseStyleId = `${id}-${kind}`;
    return {
        id,
        name,
        baseStyleId,
        nextIndex: getCurrentIndexFromObjectStyleBase(
            style,
            baseStyleId
        ) + 1
    };
};

const buildObjectStyleFromCanvasItem = (canvasItem = {}) => {
    const objectStyleBase = buildStyleDefinitionFromCanvasItem(canvasItem, 'ObjectStyle', true);

    const {
        id,
        name,
        nextIndex,
        baseStyleId
    } = getBaseInfoFromCanvasItem(canvasItem, 'objectStyle');

    return {
        name: `${name} object style`,
        kind: 'ObjectStyle',
        ...objectStyleBase,
        id: `${baseStyleId}-${nextIndex}`,
        shapePreset: {
            id: `${id}-shapePreset-${nextIndex}`,
            name: `${name} shape preset`,
            kind: 'ShapePreset',
            ...buildStyleDefinitionFromCanvasItem(canvasItem, 'ShapePreset')
        },
        strokePreset: {
            id: `${id}-strokePreset-${nextIndex}`,
            name: `${name} stroke preset`,
            kind: 'StrokePreset',
            ...buildStyleDefinitionFromCanvasItem(canvasItem, 'StrokePreset')
        },
        paragraphPreset: {
            id: `${id}-paragraphPreset-${nextIndex}`,
            name: `${name} paragraph preset`,
            kind: 'ParagraphPreset',
            ...buildStyleDefinitionFromCanvasItem(canvasItem, 'ParagraphPreset')
        },
        typefacePreset: {
            id: `${id}-typefacePreset-${nextIndex}`,
            name: `${name} typeface preset`,
            kind: 'TypefacePreset',
            ...buildStyleDefinitionFromCanvasItem(canvasItem, 'TypefacePreset')
        }
    };
};

const buildTableStyle = (table, partName, { id, name, nextIndex }) => {
    const cells = Table.orderCellsByTopLeft(table.getPartCells(partName));

    const firstCellWithText = cells
        .find(cell => cell.contents.find(content => content.text)) ||
        cells[0];

    const firstContent = get(firstCellWithText || {}, 'contents', []).find(content => content.text);

    const serializedContent = firstContent ? firstContent.toJSON() : {};

    let bottomBorder = table.getBorderById(cells[0].bottomBorderSegments[0]);
    if (bottomBorder) {
        bottomBorder = bottomBorder.toJSON();
    }

    let leftBorder = table.getBorderById(cells[0].leftBorderSegments[0]);
    if (leftBorder) {
        leftBorder = leftBorder.toJSON();
    }

    let rightBorder = table.getBorderById(cells[0].rightBorderSegments[0]);
    if (rightBorder) {
        rightBorder = rightBorder.toJSON();
    }

    let topBorder = table.getBorderById(cells[0].topBorderSegments[0]);
    if (topBorder) {
        topBorder = topBorder.toJSON();
    }

    let innerHorizontal = table.getInnerHorizontalBorders(cells)[0];
    if (innerHorizontal) {
        innerHorizontal = innerHorizontal.toJSON();
    }

    let innerVertical = table.getInnerVerticalBorders(cells)[0];
    if (innerVertical) {
        innerVertical = innerVertical.toJSON();
    }

    return {
        id: `${id}-${partName}-tableStyle-${nextIndex}`,
        name: `${name} ${partName} table style`,
        kind: 'TableStyle',
        shapePreset: {
            id: `${id}-${partName}-tableStyle-cell-shapePreset-${nextIndex}`,
            name: `${name} ${partName} cell shape preset`,
            kind: 'ShapePreset',
            ...buildStyleDefinitionFromCanvasItem(firstCellWithText, 'ShapePreset')
        },
        typefacePreset: {
            id: `${id}-${partName}-tableStyle-cell-typefacePreset-${nextIndex}`,
            name: `${name} ${partName} cell typeface preset`,
            kind: 'TypefacePreset',
            ...buildStyleDefinitionFromCanvasItem(serializedContent, 'TypefacePreset')
        },
        paragraphPreset: {
            kind: 'ParagraphPreset',
            id: `${id}-${partName}-tableStyle-cell-paragraphPreset-${nextIndex}`,
            name: `${name} ${partName} cell paragraph preset`,
            ...buildStyleDefinitionFromCanvasItem(serializedContent, 'ParagraphPreset')
        },
        bottomBorderPreset: {
            kind: 'StrokePreset',
            id: `${id}-${partName}-tableStyle-cell-bottom-border-strokePreset-${nextIndex}`,
            name: `${name} ${partName} cell bottom border stroke preset`,
            ...buildStyleDefinitionFromCanvasItem(
                bottomBorder,
                'StrokePreset'
            )
        },
        leftBorderPreset: {
            kind: 'StrokePreset',
            id: `${id}-${partName}-tableStyle-cell-left-border-strokePreset-${nextIndex}`,
            name: `${name} ${partName} cell left border stroke preset`,
            ...buildStyleDefinitionFromCanvasItem(
                leftBorder,
                'StrokePreset'
            )
        },
        rightBorderPreset: {
            kind: 'StrokePreset',
            id: `${id}-${partName}-tableStyle-cell-right-border-strokePreset-${nextIndex}`,
            name: `${name} ${partName} cell right border stroke preset`,
            ...buildStyleDefinitionFromCanvasItem(
                rightBorder,
                'StrokePreset'
            )
        },
        topBorderPreset: {
            kind: 'StrokePreset',
            id: `${id}-${partName}-tableStyle-cell-top-border-strokePreset-${nextIndex}`,
            name: `${name} ${partName} cell top border stroke preset`,
            ...buildStyleDefinitionFromCanvasItem(
                topBorder,
                'StrokePreset'
            )
        },
        internalHorizontalBorderPreset: {
            kind: 'StrokePreset',
            id: `${id}-${partName}-tableStyle-cell-inner-horizontal-border-strokePreset-${nextIndex}`,
            name: `${name} ${partName} cell inner horizontal border stroke preset`,
            ...buildStyleDefinitionFromCanvasItem(
                innerHorizontal,
                'StrokePreset'
            )
        },
        internalVerticalBorderPreset: {
            kind: 'StrokePreset',
            id: `${id}-${partName}-tableStyle-cell-inner-vertical-border-strokePreset-${nextIndex}`,
            name: `${name} ${partName} cell inner vertical border stroke preset`,
            ...buildStyleDefinitionFromCanvasItem(
                innerVertical,
                'StrokePreset'
            )
        }
    };
};

const addTableStyle = ({
    tableBuilding,
    table,
    partName,
    identifiers
}) => ({
    ...tableBuilding,
    [`${partName}Style`]: buildTableStyle(
        table,
        partName,
        identifiers
    )
});

const buildTableBuilding = table => {
    const {
        id,
        name,
        nextIndex,
        baseStyleId
    } = getBaseInfoFromCanvasItem(table, 'tableBuilding');
    const {
        CONTENT_ROW,
        FIRST_ROW,
        HEADER_ROW,
        LAST_ROW,
        TOTAL_ROW
    } = PART_NAMES;

    let tableBuilding = {
        name: `${name} table building`,
        kind: 'TableBuilding',
        ...buildStyleDefinitionFromCanvasItem(table, 'TableBuilding'),
        id: `${baseStyleId}-${nextIndex}`
    };

    const identifiers = {
        id,
        name,
        nextIndex
    };

    const partsToBuild = [
        CONTENT_ROW,
        table.hasHeaderRow ? HEADER_ROW : FIRST_ROW,
        table.hasTotalRow ? TOTAL_ROW : LAST_ROW
    ];

    partsToBuild.forEach(partName => {
        tableBuilding = addTableStyle({
            tableBuilding,
            table,
            partName,
            identifiers
        });
    });

    return tableBuilding;
};

module.exports = {
    buildObjectStyleFromCanvasItem,
    buildTableBuilding
};
