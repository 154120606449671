const GradientStop = require('./GradientStop.js');

class Gradient {
    constructor(gradientType, angle, offsetX, offsetY, rotateWithTheShape, stops) {
        this.gradientType = gradientType;
        this.angle = angle;
        this.offsetX = offsetX;
        this.offsetY = offsetY;
        this.rotateWithTheShape = rotateWithTheShape;
        this.stops = stops;
    }

    toJSON() {
        return {
            gradientType: this.gradientType,
            angle: this.angle,
            offsetX: this.offsetX,
            offsetY: this.offsetY,
            rotateWithTheShape: this.rotateWithTheShape,
            stops: this.stops.map(stop => stop.toJSON())
        };
    }

    static fromJSON(gradient, jsonObject) {
        const stops = jsonObject.stops.maps(stop => GradientStop.fromJSON(stop));
        return new this(
            jsonObject.gradientType,
            jsonObject.angle,
            jsonObject.offsetX,
            jsonObject.offsetY,
            jsonObject.rotateWithTheShape,
            stops
        );
    }
}

module.exports = Gradient;
