const { throttle } = require('lodash');
const MultiItemTextbox = require('../MultiItemTextbox');
const { reduceFontSizeInParagraphs, updateBulletFontSizeInParagraphs } = require('../../utilities/text');
const TextBoundsCalculator = require('../../utilities/TextBoundsCalculator');
const { getEffectiveStyleById } = require('../../../Shape/ItemStyle/ItemStyle');

module.exports = {
    loadText() {
        const positions = this.getTextDrawZoneLimits();

        this.placeholderTextbox = this.getPlaceholderTextItems(positions);
        if (
            this.paragraphs.length <= 1 &&
            this.placeholderParagraphs[0] &&
            this.paragraphs[0]?.text?.length === 0
        ) {
            this.paragraphs[0] = {
                ...this.placeholderParagraphs[0],
                text: this.paragraphs[0].text,
                styles: this.paragraphs[0].styles
            };
        }
        const textboxOptions = {
            paragraphs: this.paragraphs,
            textShapeStyle: this.style,
            ...positions,
            isHidden: this.isHidden,
            visible: this.visible,
            objectCaching: this.objectCaching,
            flipX: this.flipX
        };

        this.bodyTextbox = new MultiItemTextbox(textboxOptions);

        this.add(this.bodyTextbox);
        this.add(this.placeholderTextbox);

        this.updateTextCoordsForShapeParent();

        return this.getTextObject();
    },

    updateTextCoordsForShapeParent() {
        this.setTextItemsVisibility();
        const textItems = this.getTextObject();
        if (this.autoFitText === true) {
            let retries = 0;

            let maxRetry = 5;

            while (this.isTextItemsBiggerThanShape(this.bodyTextbox) && retries < maxRetry &&
                this.getTextItemsWidth(this.bodyTextbox) > 0.1 && this.getTextItemsHeight(this.bodyTextbox) > 0.1) {
                this.bodyTextbox.paragraphs = reduceFontSizeInParagraphs(this.bodyTextbox.paragraphs);
                this.bodyTextbox.paragraphs = updateBulletFontSizeInParagraphs(this.bodyTextbox.paragraphs);
                this.bodyTextbox._clearCache();
                this.bodyTextbox.initDimensions();
                this.bodyTextbox.setCoords();
                retries++;
                if (this.isTextItemsBiggerThanShape(this.bodyTextbox) && retries === maxRetry) {
                    maxRetry++;
                }
            }
        }

        textItems.angle = this.getTextItemsAngle();
        if (textItems) {
            this.enforceShapeAutoFit(textItems);
        }
        this.placeTextItemsInTextDrawZone(textItems);
    },

    setTextItemsVisibility() {
        const isPlaceholderVisible = this.bodyTextbox.text.length === 0 && !this.editingText && this.displayPlaceholder;
        this.bodyTextbox.opacity = isPlaceholderVisible ? 0 : 1;
        this.placeholderTextbox.opacity = isPlaceholderVisible ? 1 : 0;
        this.isTextEmpty = isPlaceholderVisible;
    },

    refreshText() {
        const limits = this.getTextDrawZoneLimits();

        Object.keys(limits).forEach(key => {
            this.bodyTextbox[key] = limits[key];
            this.placeholderTextbox[key] = limits[key];
        });

        this.updateTextCoordsForShapeParent();
    },

    enterTextEditing(shouldOpenEditor = false) {
        this.editingText = true;
        this.hoverCursor = this.editingText ? 'text' : 'move';
        this.updateTextCoordsForShapeParent();
        if (shouldOpenEditor) {
            this.getTextObject().enterEditing();
        }
        this.onChanged = this.onChanged.bind(this);
        this.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.throttleObjectModified = throttle(this.onObjectModified, 500, { leading: false }).bind(this);
        this.longThrottleObjectModified = throttle(this.onObjectModified, 1000, { leading: false }).bind(this);
        this.throttleFireSelectionChanged =
            throttle(this.fireSelectionChanged, 300, { leading: false }).bind(this);
        this.on('deselected', this.exitTextEditing);
        this.objectPreset = getEffectiveStyleById(
            this.canvas.styleDefinitions,
            this.style,
            {
                scheme: this.canvas.colorScheme,
                colorPalette: this.canvas.canvasState.get('colorPalette').toJS()
            }
        );
        this.hasListPreset = this.objectPreset?.listPreset !== undefined;
        if (!this.hasListPreset) {
            this.defaultBulletStyles = getEffectiveStyleById(
                this.canvas.styleDefinitions,
                this.canvas.defaultBulletStyles,
                {
                    scheme: this.canvas.colorScheme,
                    colorPalette: this.canvas.canvasState.get('colorPalette').toJS()
                }
            );
        }
        this.getTextObject().on('changed', this.onChanged);
        this.getTextObject().on('selection:changed', this.onSelectionChanged);
    },

    getCursorType() {
        return !this.selectable && !this.editingText ? 'default' : 'move';
    },

    exitTextEditing() {
        this.editingText = false;
        this.hoverCursor = this.getCursorType();
        this.off('deselected', this.exitTextEditing);
        this.getTextObject().off('changed', this.onChanged);
        this.getTextObject().off('selection:changed', this.onSelectionChanged);
        this.bodyTextbox.exitEditing();
        if (this.canvas) {
            this.canvas.endTextEdition();
        }
        this.onSelectionChanged(true);
    },

    onSelectionChanged(exiting = false) {
        if (this.group?.type === 'cell') {
            if (!exiting) {
                this.onUpdateEditingCellHeight(exiting);
            }
        } else if (!exiting) {
            this.throttleFireSelectionChanged();
            this.throttleObjectModified(this.canvas.getActiveObject());
        }
        this.updateTextCoordsForShapeParent();
        this.getTextObject().renderCursorOrSelection();
    },

    onChanged() {
        if (this.group?.type === 'cell') {
            this.onUpdateEditingCellHeight();
        }
        this.updateTextCoordsForShapeParent();
        this.getTextObject().renderCursorOrSelection();
        this.throttleFireSelectionChanged();
        this.longThrottleObjectModified(this.canvas.getActiveObject());
    },

    getTextBounds() {
        const shape = this.getShapeObject();
        return new TextBoundsCalculator.Default(
            this.width,
            this.height,
            {
                flipX: shape.flipX
            }
        );
    },

    getTextBoundingRect() {
        const { width, height } = this.getTextBounds();
        return {
            width,
            height,
            verticalAlign: this.verticalAlign,
            textDirection: this.textDirection
        };
    },

    shouldTextBeSelectedOnEditorOpening() {
        if (this.group && this.group.group && this.group.group.type === 'table') {
            return this.group.group.isLastNavigationFromKeyboard;
        }
        return false;
    },

    initBehavior() {
        this.initCursorSelectionHandlers();
        this.initDoubleClickSimulation();
    },

    initCursorSelectionHandlers() {
        this.initMousedownHandler();
        this.initMouseupHandler();
        this.initClicks();
    },

    initDoubleClickSimulation() {
        this.on('mousedown', this.onMouseDown.bind(this));
    },

    onMouseDown(options) {
        this.bodyTextbox.onMouseDown(options);
    },

    initMousedownHandler() {
        this.on('mousedown', this._mouseDownHandler.bind(this));
        this.on('mousedown:before', this._mouseDownHandlerBefore.bind(this));
    },

    _mouseDownHandler(options) {
        this.bodyTextbox._mouseDownHandler(options);
    },

    _mouseDownHandlerBefore(options) {
        this.bodyTextbox._mouseDownHandlerBefore(options);
    },

    initMouseupHandler() {
        this.on('mouseup', this.mouseUpHandler.bind(this));
    },

    mouseUpHandler(options) {
        this.bodyTextbox.mouseUpHandler(options);
    },

    initClicks() {
        this.on('mousedblclick', this.doubleClickHandler.bind(this));
        this.on('tripleclick', this.tripleClickHandler.bind(this));
    },

    doubleClickHandler(options) {
        this.bodyTextbox.doubleClickHandler(options);
    },

    tripleClickHandler(options) {
        this.bodyTextbox.tripleClickHandler(options);
    },

    setSelectionToTextEnd(select = false) {
        const textItems = this.getTextObject();
        textItems.setSelectionStart(select ? 0 : textItems.text.length);
        textItems.setSelectionEnd(textItems.text.length);
        textItems.clearContextTop();
        textItems.initDelayedCursor(true);
    },

    setSelectionByEvent(e) {
        const textItems = this.getTextObject();
        textItems.setCursorByClick(e);
        textItems.clearContextTop();
        textItems.initDelayedCursor(true);
    },

    renderTextSelection(textSelection) {
        if (textSelection.editing === true) {
            const textItems = this.getTextObject();
            textItems.setSelectionStart(textSelection.start);
            textItems.setSelectionEnd(textSelection.end);
            this.enterTextEditing(true);
            if (textSelection.start === textSelection.end) {
                textItems.clearContextTop();
                textItems.initDelayedCursor(true);
            } else {
                textItems.renderCursorOrSelection();
            }
        }
    },

    onObjectModified(activeObject = null) {
        if (this.canvas && activeObject) {
            this.canvas.fire('object:modified', { target: this, activeObject });
        }
    },

    fireSelectionChanged() {
        if (this.canvas) {
            if (this.canvas.editingText) {
                this.canvas.fire('text:selection:changed', { target: this.getTextObject() });
            }
        }
    },

    onUpdateEditingCellHeight(exiting = false) {
        const margins = this.getTextBodyMargin('bottom') + this.getTextBodyMargin('top');
        this.group.group.updateEditingCellHeight({
            height: this.getTextDrawZoneHeight() + margins
        }, exiting);
    }
};
