import * as ColorValueDescriptor from '../../Style/colorValueDescriptor';

export default {
    updateColorPresets: (runStyle, { presets, scheme }) => {
        if (runStyle && runStyle.get('color')) {
            return runStyle.set(
                'color',
                ColorValueDescriptor.updateColorPresets(runStyle.get('color'), { presets, scheme })
            );
        }
        return runStyle;
    }
};
