import { List, Map } from 'immutable';
import { isNil } from 'lodash';
import { paragraphStyleProperties } from '../Helpers/Style/paragraph';
import { textBodyStyleProperties } from '../Helpers/Style/textBody';
import pick from './pick';

const styleToRunStyle = fabricStyle => {
    let style = Map();
    if (fabricStyle.get('fill')) {
        style = style.set('color', fabricStyle.get('fill'));
    }
    if (fabricStyle.get('fontFamily') ||
        fabricStyle.get('fontSize') ||
        fabricStyle.get('fontStyle') ||
        fabricStyle.get('fontWeight')
    ) {
        style = style.set('font', Map());
        if (fabricStyle.get('fontFamily')) {
            style = style.setIn(
                ['font', 'family'],
                fabricStyle.get('fontFamily')
            );
        }
        if (fabricStyle.get('fontSize')) {
            style = style.setIn(
                ['font', 'size'],
                fabricStyle.get('fontSize')
            );
        }
        if (fabricStyle.get('fontStyle')) {
            style = style.setIn(
                ['font', 'style'],
                fabricStyle.get('fontStyle')
            );
        }
        if (fabricStyle.get('fontWeight')) {
            style = style.setIn(
                ['font', 'weight'],
                fabricStyle.get('fontWeight')
            );
        }
    }
    if (fabricStyle.keySeq().includes('textTransform')) {
        style = style.set('textTransform', fabricStyle.get('textTransform'));
    }
    if (fabricStyle.get('opacity')) {
        style = style.set('opacity', fabricStyle.get('opacity'));
    }
    if (fabricStyle.get('assignedParagraphStyle')) {
        let assignedParagraphStyle = styleToRunStyle(fabricStyle.get('assignedParagraphStyle'));
        assignedParagraphStyle = assignedParagraphStyle
            .merge(assignedParagraphStyle.get('paragraph'));
        style = style.set('assignedStyle', pick(assignedParagraphStyle, textBodyStyleProperties));
        style = style.set('assignedParagraphStyle', pick(assignedParagraphStyle, paragraphStyleProperties));
    }
    if (fabricStyle.get('assignedRunStyle')) {
        style = style.set('assignedRunStyle', styleToRunStyle(fabricStyle.get('assignedRunStyle')));
    }
    if (fabricStyle.get('charSpacing')) {
        style = style.set('charSpacing', fabricStyle.get('charSpacing'));
    }
    if (!isNil(fabricStyle.get('overline'))) {
        style = style.set('overline', fabricStyle.get('overline'));
    }
    if (!isNil(fabricStyle.get('linethrough'))) {
        style = style.set('linethrough', fabricStyle.get('linethrough'));
    }
    if (!isNil(fabricStyle.get('underline'))) {
        style = style.set('underline', fabricStyle.get('underline'));
    }
    if (!isNil(fabricStyle.get('subscript'))) {
        style = style.set('subscript', fabricStyle.get('subscript'));
    }
    if (!isNil(fabricStyle.get('superscript'))) {
        style = style.set('superscript', fabricStyle.get('superscript'));
    }
    if (fabricStyle.get('textSelection')) {
        style = style.set('textSelection', fabricStyle.get('textSelection'));
    }
    if (fabricStyle.get('bullets')) {
        style = setParagraphUpdateProperty(
            style,
            'bullet',
            fabricStyle.get('bullets')
        );
    }
    if (fabricStyle.get('bullet')) {
        style = style.set('bullet', fabricStyle.get('bullet'));
    }
    if (fabricStyle.get('indent')) {
        style = setParagraphUpdateProperty(style, 'indent', fabricStyle.get('indent'));
    }
    if (fabricStyle.get('lineHeight')) {
        style = setParagraphUpdateProperty(style, 'lineSpacing', fabricStyle.get('lineHeight'));
    }
    if (!isNil(fabricStyle.get('level'))) {
        style = setParagraphUpdateProperty(style, 'level', fabricStyle.get('level'));
    }
    if (!isNil(fabricStyle.get('levelStyle'))) {
        style = style.set('levelStyle', fabricStyle.get('levelStyle'));
    }

    if (fabricStyle.get('margins')) {
        style = style.set('margins', fabricStyle.get('margins'));
    }

    const padding = styleToPadding(fabricStyle);

    if (padding.keySeq().size) {
        style = setParagraphUpdateProperty(style, 'padding', padding);
    }

    if (fabricStyle.get('align') || fabricStyle.get('textAlign')) {
        style = setParagraphUpdateProperty(
            style,
            'align',
            fabricStyle.get('align') || fabricStyle.get('textAlign')
        );

        style = style.set('align', fabricStyle.get('align') || fabricStyle.get('textAlign'));
    }

    if (fabricStyle.get('verticalAlign')) {
        style = setParagraphUpdateProperty(style, 'verticalAlign', fabricStyle.get('verticalAlign'));
        style = style.set('verticalAlign', fabricStyle.get('verticalAlign'));
    }

    if (fabricStyle.get('textDirection')) {
        style = setParagraphUpdateProperty(style, 'textDirection', fabricStyle.get('textDirection'));
        style = style.set('textDirection', fabricStyle.get('textDirection'));
    }

    if (fabricStyle.get('autoFitShape')) {
        style = style.set('autoFitShape', fabricStyle.get('autoFitShape'));
    }

    if (fabricStyle.get('autoFitText')) {
        style = style.set('autoFitText', fabricStyle.get('autoFitText'));
    }

    if (fabricStyle.get('listUpdate')) {
        style = style.set(
            'listUpdate',
            List(['allLevels', 'level1', 'level2', 'level3', 'level4', 'level5'])
                .reduce((listUpdate, key) => {
                    if (fabricStyle.hasIn(['listUpdate', key])) {
                        return listUpdate.set(
                            key,
                            styleToRunStyle(fabricStyle.getIn(['listUpdate', key]))
                        );
                    }
                    return listUpdate;
                }, Map())
        );
        style = style.setIn(
            ['listUpdate', 'onlyBulletUpdate'],
            fabricStyle.getIn(['listUpdate', 'onlyBulletUpdate']) || false
        );
        style = style.setIn(
            ['listUpdate', 'type'],
            fabricStyle.getIn(['listUpdate', 'type'])
        );
        style = style.setIn(
            ['listUpdate', 'itemType'],
            fabricStyle.getIn(['listUpdate', 'itemType'])
        );
        if (fabricStyle.get('assignedRunStyle')) {
            style = style.set(
                'font',
                style.getIn(['listUpdate', 'level1']) ?
                    style.getIn(['listUpdate', 'level1', 'font']) :
                    style.getIn(['listUpdate', 'allLevels', 'font'])
            );
        }
    }
    if (fabricStyle.get('levelStyle')) {
        style = style.set('levelStyle', styleToRunStyle(fabricStyle.get('levelStyle')));
    }

    return style;
};

const setParagraphUpdateProperty = (currentUpdate, property, value) => currentUpdate
    .setIn(
        [
            'paragraph',
            property
        ],
        value
    );

const styleToPadding = fabricStyle => List(['bottom', 'left', 'right', 'top'])
    .filter(side => !isNil(fabricStyle.get(`spacing-${side}`)))
    .reduce(
        (currentPadding, side) => currentPadding
            .set(
                side,
                fabricStyle.get(`spacing-${side}`)
            ),
        Map()
    );

export {
    // eslint-disable-next-line import/prefer-default-export
    styleToRunStyle
};
