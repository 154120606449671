const set = require('lodash/set');
const get = require('lodash/get');
const cloneDeep = require('lodash/cloneDeep');

const getBulletStyleFromTypefacePreset = ({
    fontSize, fontWeight = '', fontFamily = '', fill
}) => ({
    font: `${fontFamily} ${fontWeight}`.trim(),
    color: fill.color,
    size: fontSize
});

module.exports = {
    toItemUpdate(value, update, definition) {
        const newUpdate = cloneDeep(update);
        const bullet = {};
        switch (value) {
            case 'bullet':
                bullet.type = 'unordered';
                bullet.text = get(definition, 'bulletText');
                bullet.style = getBulletStyleFromTypefacePreset(definition.bulletTypefacePreset);
                newUpdate.indent = get(definition, 'bulletIndent');
                break;
            case 'numeric':
                bullet.type = 'ordered/numeric';
                bullet.style = {
                    ...getBulletStyleFromTypefacePreset(definition.numericTypefacePreset),
                    textAfter: definition.numericTextAfter,
                    textBefore: definition.numericTextBefore
                };
                newUpdate.indent = get(definition, 'numericIndent');
                break;
            case 'roman':
                bullet.type = 'ordered/roman';
                bullet.style = {
                    ...getBulletStyleFromTypefacePreset(definition.numericTypefacePreset),
                    textAfter: definition.numericTextAfter,
                    textBefore: definition.numericTextBefore
                };
                newUpdate.indent = get(definition, 'numericIndent');
                break;
            case 'none':
                bullet.type = 'none';
                newUpdate.indent = 0;
                newUpdate['spacing-left'] = 0;
                newUpdate.level = 0;
                break;
            default:
                throw new Error(`${value} default bullet type is not supported`);
        }
        return set(newUpdate, 'bullet', bullet);
    }
};
