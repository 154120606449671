const pick = require('lodash/pick');
const get = require('lodash/get');
const uniqBy = require('lodash/uniqBy');
const forEach = require('lodash/forEach');
const map = require('lodash/map');
const isEqual = require('lodash/isEqual');
const { DEFAULT_TABLE_SETTINGS, DEFAULT_TABLE_SIZE_SETTINGS } = require('./config/defaultTableAttributes.config.js');
const { Cell } = require('./Cell');
const Border = require('./Border.js');
const CellMixins = require('./mixins/Cell');
const BorderMixins = require('./mixins/Border');
const PartMixin = require('./mixins/Part/Part');
const FabricTableMixin = require('../fabric-adapter/mixins/tableFabricObject');
const { pixelRound } = require('../utilities/pixelRound.js');
const AbstractShape = require('../Shape/AbstractShape');
const { convertPropertiesToShapeProperties } = require('../utilities/convertPropertiesToShapeProperties');
const Shape = require('../Shape/Shape');
const attributesToExtract = require('./config/attributesToExtract.config');
const tableGridLogic = require('../utilities/table/tableGridLogic');

const shapePropsWhitelist = ['fill', 'opacity'];

const validTableShapeProps = shapeProps => shapePropsWhitelist.filter(props => Object.keys(shapeProps).includes(props));

const TableComponentsShape = CellMixins(BorderMixins(AbstractShape));

const StructuredTableComponents = PartMixin(TableComponentsShape);

class Table extends FabricTableMixin(StructuredTableComponents) {
    constructor(
        name = '',
        nbRows,
        nbColumns,
        posX,
        posY,
        attributes = {},
        generateDefaults = true
    ) {
        super(name, posX, posY, {});
        this.settings = {
            ...DEFAULT_TABLE_SETTINGS,
            ...attributes
        };
        if (generateDefaults) {
            this.rowHeights = tableGridLogic.generateDefaultRowHeights(nbRows, this);
            this.columnWidths = tableGridLogic.generateDefaultColumnWidths(nbColumns, this);
            this.generateDefaultCells(nbRows, nbColumns);
            this.generateDefaultBorders();
        }
        this.definedRowHeights = attributes.definedRowHeights || new Array(nbRows).fill(0);
        this.type = 'table';
        Object.assign(this, attributes);
    }

    get cellGrid() {
        return tableGridLogic.generateCellGrid(this);
    }

    get horizontalBorders() {
        return tableGridLogic.generateHorizontalBorderGrid(this);
    }

    get verticalBorders() {
        return tableGridLogic.generateVerticalBorderGrid(this);
    }

    toJSON() {
        return {
            ...super.toJSON(),
            cells: Object.values(this.cells).map(cell => cell.toJSON()),
            borders: Object.values(this.borderSegments).map(border => border.toJSON()),
            rows: this.rowCount,
            columns: this.columnCount,
            rowHeights: this.rowHeights,
            definedRowHeights: this.definedRowHeights,
            columnWidths: this.columnWidths,
            hasBandedColumns: this.hasBandedColumns,
            hasBandedRows: this.hasBandedRows,
            hasHeaderColumn: this.hasHeaderColumn,
            hasHeaderRow: this.hasHeaderRow,
            hasTotalColumn: this.hasTotalColumn,
            hasTotalRow: this.hasTotalRow,
            mainAxis: this.mainAxis,
            type: this.constructor.name
        };
    }

    static fromJSON(jsonObject) {
        const table = new this(
            jsonObject.name,
            jsonObject.rows,
            jsonObject.columns,
            jsonObject.x,
            jsonObject.y,
            pick(jsonObject, attributesToExtract),
            false
        );
        const cells = {};
        const borders = {};
        forEach(jsonObject.cells, serializedCell => {
            const cell = Cell.fromJSON(serializedCell);
            cells[cell.id] = cell;
            cells[cell.id].table = table;
        });
        forEach(jsonObject.borders, serializedBorder => {
            const border = Border.fromJSON(serializedBorder);
            borders[border.id] = border;
            borders[border.id].table = table;
        });
        table.cells = cells;
        table.borderSegments = borders;
        table.rowHeights = jsonObject.rowHeights;
        table.columnWidths = jsonObject.columnWidths;
        return table;
    }

    generateDefaultBorders() {
        this.borderSegments = {};
        for (
            let rowIndex = 0, rowCount = this.horizontalBorders.length;
            rowIndex < rowCount;
            rowIndex++
        ) {
            for (
                let columnIndex = 0, columnCount = this.horizontalBorders[rowIndex].length;
                columnIndex < columnCount;
                columnIndex++
            ) {
                this.addBorderSegment(new Border(
                    `Border Horizontal ${rowIndex}-${columnIndex}`,
                    undefined,
                    this,
                    Border.SIDE.HORIZONTAL,
                    rowIndex,
                    columnIndex
                ));
            }
        }
        for (
            let columnIndex = 0, columnCount = this.verticalBorders.length;
            columnIndex < columnCount;
            columnIndex++
        ) {
            for (
                let rowIndex = 0, rowCount = this.verticalBorders[columnIndex].length;
                rowIndex < rowCount;
                rowIndex++
            ) {
                this.addBorderSegment(new Border(
                    `Border Vertical ${columnIndex}-${rowIndex}`,
                    undefined,
                    this,
                    Border.SIDE.VERTICAL,
                    rowIndex,
                    columnIndex
                ));
            }
        }
    }

    getColumnStartX(column) {
        return tableGridLogic.getColumnStartX(column, this);
    }

    getColumnEndX(column) {
        return tableGridLogic.getColumnEndX(column, this);
    }

    getRowStartY(row) {
        return tableGridLogic.getRowStartY(row, this);
    }

    getRowEndY(row) {
        return tableGridLogic.getRowEndY(row, this);
    }

    getWidthForCellAt(column, columnSpan) {
        return this.getColumnEndX(column + (columnSpan - 1)) - this.getColumnStartX(column);
    }

    getHeightForCellAt(row, rowSpan) {
        return this.getRowEndY(row + (rowSpan - 1)) - this.getRowStartY(row);
    }

    adjustBorderRowIndexAfterPosition(position, adjustment = 1) {
        this.horizontalBorders.slice(position).forEach(row => {
            row.forEach(horizontalSegment => {
                this.borderSegments[horizontalSegment].row += adjustment;
            });
        });
        this.verticalBorders.forEach(column => {
            column.slice(position).forEach(verticalSegment => {
                this.borderSegments[verticalSegment].row += adjustment;
            });
        });
    }

    adjustBorderColumnIndexAfterPosition(position, adjustment = 1) {
        this.verticalBorders.slice(position).forEach(column => {
            column.forEach(verticalSegment => {
                this.borderSegments[verticalSegment].column += adjustment;
            });
        });
        this.horizontalBorders.forEach(row => {
            row.slice(position).forEach(horizontalSegment => {
                this.borderSegments[horizontalSegment].column += adjustment;
            });
        });
    }

    insertRow(currentIndex, direction) {
        const insertIndex = direction === 'top' ?
            currentIndex : currentIndex + 1;
        const height = DEFAULT_TABLE_SIZE_SETTINGS.minCellHeight;
        const currentRow = this.getCellsInRow(currentIndex);

        [
            ...Object.values(this.cells),
            ...Object.values(this.borderSegments)
        ]
            .forEach(tableComponent => {
                if (tableComponent.row >= insertIndex) {
                    tableComponent.row += 1;
                }
            });

        this.rowHeights.splice(insertIndex, 0, height);
        this.definedRowHeights.splice(insertIndex, 0, 0);

        currentRow.forEach(
            cell => {
                const verticalBorderColumnIndexToCopy = direction === 'top' ?
                    insertIndex + 1 :
                    insertIndex - 1;

                if (
                    cell.row < insertIndex &&
                    cell.lastSpannedRow >= insertIndex
                ) {
                    cell.rowSpan += 1;

                    const leftBorder = this.findBorder(
                        'vertical',
                        verticalBorderColumnIndexToCopy,
                        cell.column
                    );

                    if (leftBorder) {
                        const newLeftBorder = leftBorder.copy();
                        newLeftBorder.row = insertIndex;
                        this.addBorderSegment(newLeftBorder);
                    }

                    const rightBorder = this.findBorder(
                        'vertical',
                        verticalBorderColumnIndexToCopy,
                        cell.column + 1
                    );

                    if (rightBorder) {
                        const newRightBorder = rightBorder.copy();
                        newRightBorder.row = insertIndex;
                        this.addBorderSegment(newRightBorder);
                    }

                    return;
                }

                const newCell = new Cell(
                    `${cell.name}_inserted_${direction}`,
                    insertIndex,
                    cell.column,
                    1,
                    cell.columnSpan,
                    this
                );
                newCell.copyCellContentStyles(cell);
                newCell.copyCellShapeStyle(cell);
                this.cells[newCell.id] = newCell;

                this.insertBordersAfterRowInsert(
                    cell,
                    insertIndex,
                    verticalBorderColumnIndexToCopy
                );
            }
        );
        this.y += height / 2;
    }

    insertBordersAfterRowInsert(cell, insertIndex, verticalBorderRowIndexToCopy) {
        const leftBorder = this.findBorder(
            'vertical',
            cell.column,
            verticalBorderRowIndexToCopy
        );

        if (leftBorder) {
            const newLeftBorder = leftBorder.copy();
            newLeftBorder.row = insertIndex;
            this.addBorderSegment(newLeftBorder);
        }

        cell.topBorderSegments.forEach(horizontalBorderId => {
            const horizontalBorder = this.getBorderById(horizontalBorderId);

            if (horizontalBorder) {
                const newHorizontalBorder = horizontalBorder.copy();
                newHorizontalBorder.row = insertIndex;
                this.addBorderSegment(newHorizontalBorder);
            }
        });

        if (cell.column + (cell.columnSpan) === this.columnCount) {
            const rightBorder = this.findBorder(
                'vertical',
                cell.column + (cell.columnSpan),
                verticalBorderRowIndexToCopy
            );
            if (rightBorder) {
                const newRightBorder = rightBorder.copy();
                newRightBorder.row = insertIndex;
                this.addBorderSegment(newRightBorder);
            }
        }
    }

    removeRow(position) {
        const cellsToRemove = new Set();
        const currentRow = this.getCellIdsInRow(position);
        const height = this.rowHeights[position];
        currentRow.forEach(cellId => {
            if (this.cells[cellId].rowSpan > 1) {
                this.cells[cellId].rowSpan -= 1;
                return;
            }
            cellsToRemove.add(cellId);
        });
        cellsToRemove.forEach(cellIdToRemove => {
            delete this.cells[cellIdToRemove];
        });
        this.removeGridRowAt(position);
        this.rowHeights.splice(position, 1);
        this.definedRowHeights.splice(position, 1);
        this.mergeIdenticalColumns();
        this.y -= height / 2;
    }

    mergeIdenticalColumns() {
        for (let i = this.columnCount - 1; i >= 0; i--) {
            const column = Object.values(this.cells)
                .filter(cell => cell.spannedColumns.includes(i))
                .map(cell => cell.id);
            const nextColumn = Object.values(this.cells)
                .filter(cell => cell.spannedColumns.includes(i - 1))
                .map(cell => cell.id);
            if (isEqual(column, nextColumn)) {
                Object.values(this.cells).forEach(cell => {
                    if (cell.column === i - 1) {
                        cell.columnSpan--;
                    } else if (cell.column > i - 1) {
                        cell.column--;
                    }
                });
                Object.values(this.borderSegments).forEach(border => {
                    if (border.column > i - 1) {
                        border.column--;
                    }
                });
                this.columnWidths[i - 1] += this.columnWidths[i];
                this.columnWidths.splice(i, 1);
            }
        }
    }

    insertColumn(currentIndex, direction) {
        const insertIndex = direction === 'left' ?
            currentIndex : currentIndex + 1;
        const width = this.columnWidths[currentIndex];
        const currentColumn = this.getCellsInColumn(currentIndex);

        [
            ...Object.values(this.cells),
            ...Object.values(this.borderSegments)
        ]
            .forEach(tableComponent => {
                if (tableComponent.column >= insertIndex) {
                    tableComponent.column += 1;
                }
            });

        this.columnWidths.splice(insertIndex, 0, width);

        currentColumn.forEach(
            cell => {
                const horizontalBorderColumnIndexToCopy = direction === 'left' ?
                    insertIndex + 1 :
                    insertIndex - 1;

                if (
                    cell.column < insertIndex &&
                    cell.lastSpannedColumn >= insertIndex
                ) {
                    cell.columnSpan += 1;
                    const topBorder = this.findBorder(
                        'horizontal',
                        horizontalBorderColumnIndexToCopy,
                        cell.row
                    );

                    if (topBorder) {
                        const newTopBorder = topBorder.copy();
                        newTopBorder.column = insertIndex;
                        this.addBorderSegment(newTopBorder);
                    }

                    const bottomBorder = this.findBorder(
                        'horizontal',
                        horizontalBorderColumnIndexToCopy,
                        cell.row + 1
                    );

                    if (bottomBorder) {
                        const newBottomBorder = bottomBorder.copy();
                        newBottomBorder.column = insertIndex;
                        this.addBorderSegment(newBottomBorder);
                    }
                    return;
                }

                const newCell = new Cell(
                    `${cell.name}_inserted_${direction}`,
                    cell.row,
                    insertIndex,
                    cell.rowSpan,
                    1,
                    this
                );
                newCell.copyCellContentStyles(cell);
                newCell.copyCellShapeStyle(cell);
                this.cells[newCell.id] = newCell;

                this.insertBordersAfterColumnInsert(
                    cell,
                    insertIndex,
                    horizontalBorderColumnIndexToCopy
                );
            }
        );
        this.x += width / 2;
    }

    insertBordersAfterColumnInsert(cell, insertIndex, horizontalBorderColumnIndexToCopy) {
        const topBorder = this.findBorder(
            'horizontal',
            horizontalBorderColumnIndexToCopy,
            cell.row
        );

        if (topBorder) {
            const newTopBorder = topBorder.copy();
            newTopBorder.column = insertIndex;
            this.addBorderSegment(newTopBorder);
        }

        cell.leftBorderSegments.forEach(verticalBorderId => {
            const verticalBorder = this.getBorderById(
                verticalBorderId
            );

            if (verticalBorder) {
                const newVerticalBorder = verticalBorder.copy();
                newVerticalBorder.column = insertIndex;
                this.addBorderSegment(newVerticalBorder);
            }
        });

        if (cell.row + (cell.rowSpan) === this.rowCount) {
            const bottomBorder = this.findBorder(
                'horizontal',
                horizontalBorderColumnIndexToCopy,
                cell.row + (cell.rowSpan)
            );
            if (bottomBorder) {
                const newBottomBorder = bottomBorder.copy();
                newBottomBorder.column = insertIndex;
                this.addBorderSegment(newBottomBorder);
            }
        }
    }

    removeColumn(position) {
        const cellsToRemove = new Set();
        const currentColumn = this.getCellIdsInColumn(position);
        const width = this.columnWidths[position];
        currentColumn.forEach(cellId => {
            if (this.cells[cellId].columnSpan > 1) {
                this.verticalBorders[position].forEach(
                    borderSegment => {
                        if (this.borderSegments[borderSegment] &&
                            this.cells[cellId].leftBorderSegments.includes(borderSegment)) {
                            this.borderSegments[borderSegment].column += 1;
                        }
                    }
                );
                this.cells[cellId].columnSpan -= 1;
                return;
            }
            cellsToRemove.add(cellId);
        });
        cellsToRemove.forEach(cellIdToRemove => {
            delete this.cells[cellIdToRemove];
        });
        this.removeGridColumnAt(position);
        this.columnWidths.splice(position, 1);
        this.mergeIdenticalRows();
        this.x -= width / 2;
    }

    mergeIdenticalRows() {
        for (let i = this.rowCount - 1; i >= 0; i--) {
            const row = Object.values(this.cells)
                .filter(cell => cell.spannedRows.includes(i))
                .map(cell => cell.id);
            const nextRow = Object.values(this.cells)
                .filter(cell => cell.spannedRows.includes(i - 1))
                .map(cell => cell.id);
            if (isEqual(row, nextRow)) {
                Object.values(this.cells).forEach(cell => {
                    if (cell.row === i - 1) {
                        cell.rowSpan--;
                    } else if (cell.row > i - 1) {
                        cell.row--;
                    }
                });
                Object.values(this.borderSegments).forEach(border => {
                    if (border.row > i - 1) {
                        border.row--;
                    }
                });
                this.rowHeights[i - 1] += this.rowHeights[i];
                this.rowHeights.splice(i, 1);
                this.definedRowHeights[i - 1] += this.definedRowHeights[i];
                this.definedRowHeights.splice(i, 1);
            }
        }
    }

    removeBordersForRow(rowToRemove) {
        forEach(
            this.horizontalBorders[rowToRemove],
            borderSegment => (delete this.borderSegments[borderSegment])
        );
        this.horizontalBorders.splice(rowToRemove, 1);
        forEach(this.verticalBorders, rowSegments => {
            delete this.borderSegments[rowSegments[rowToRemove]];
            rowSegments.splice(rowToRemove, 1);
        });
        this.adjustBorderRowIndexAfterPosition(rowToRemove, -1);
    }

    removeBordersForColumn(columnToRemove) {
        forEach(
            this.verticalBorders[columnToRemove],
            borderSegment => (delete this.borderSegments[borderSegment])
        );
        this.verticalBorders.splice(columnToRemove, 1);
        forEach(this.horizontalBorders, columnSegments => {
            delete this.borderSegments[columnSegments[columnToRemove]];
            columnSegments.splice(columnToRemove, 1);
        });
        this.adjustBorderColumnIndexAfterPosition(columnToRemove, -1);
    }

    getTopmostLeftmostCell(cellIds) {
        return cellIds.map(cellId => this.cells[cellId])
            .sort((a, b) => a.row - b.row || a.column - b.column)[0];
    }

    removeGridRowAt(rowIndex) {
        this.updateCellsRowFromIndex(rowIndex);
        this.cellGrid.splice(rowIndex, 1);
        this.removeBordersForRow(rowIndex);
    }

    removeGridColumnAt(columnIndex) {
        this.updateCellsColumnFromIndex(columnIndex);
        this.removeBordersForColumn(columnIndex);
    }

    updateCellsRowFromIndex(rowIndex) {
        Object.values(this.cells)
            .forEach(cell => {
                if (cell && cell.row > rowIndex) {
                    cell.row--;
                }
            });
    }

    updateCellsColumnFromIndex(columnIndex) {
        Object.values(this.cells)
            .forEach(cell => {
                if (cell && cell.column > columnIndex) {
                    cell.column--;
                }
            });
    }

    reduceRowSpan(cellIds) {
        forEach([...new Set(cellIds)], cellId => {
            this.cells[cellId].rowSpan--;
        });
    }

    reduceColumnSpan(cellIds) {
        forEach([...new Set(cellIds)], cellId => {
            this.cells[cellId].columnSpan--;
        });
    }

    getCellGridColumn(columnIndex) {
        return tableGridLogic.getCellGridColumn(columnIndex, this);
    }

    getCellIdsInColumn(columnIndex) {
        return [...new Set(this.getCellGridColumn(columnIndex))];
    }

    getCellsInColumn(columnIndex) {
        return this.getCellIdsInColumn(columnIndex)
            .map(cellId => this.getCellById(cellId));
    }

    getCellGridRow(rowIndex) {
        return tableGridLogic.getCellGridRow(rowIndex, this);
    }

    getCellIdsInRow(rowIndex) {
        return [...new Set(this.getCellGridRow(rowIndex))];
    }

    getCellsInRow(rowIndex) {
        return this.getCellIdsInRow(rowIndex)
            .map(cellId => this.getCellById(cellId));
    }

    applySimpleTablePropertiesUpdate(update) {
        const propertySimpleUpdate = [
            'style',
            'hasBandedColumns',
            'hasBandedRows',
            'hasHeaderColumn',
            'hasHeaderRow',
            'hasTotalColumn',
            'hasTotalRow',
            'mainAxis',
            'x',
            'y',
            'offsetLeft',
            'offsetTop',
            'name'
        ];
        Object.entries(update)
            .forEach(([updateProperty, value]) => {
                if (propertySimpleUpdate.includes(updateProperty)) {
                    this[updateProperty] = value;
                }
            });
    }

    applyUpdate({
        shapeProps = {},
        textProps = {},
        strokeProps = {},
        isEmphasisStyle = false
    } = {}) {
        this.applySimpleTablePropertiesUpdate(shapeProps);
        if (shapeProps.width) {
            const scaleFactor = shapeProps.width / this.width;
            this.columnWidths = this.columnWidths.map(columnWidth => columnWidth * scaleFactor);
        }
        if (shapeProps.height) {
            const scaleFactor = shapeProps.height / this.height;
            this.definedRowHeights = this
                .combineDefinedAndRenderRowHeights()
                .map(rowHeight => rowHeight * scaleFactor);
        }
        if (shapeProps.rowHeights) {
            const oldHeight = this.height;
            this.rowHeights = shapeProps.rowHeights;
            this.y += (this.height - oldHeight) / 2;
        }
        if (shapeProps.definedRowHeights) {
            const oldHeight = this.height;
            this.definedRowHeights = shapeProps.definedRowHeights;
            this.y += (this.height - oldHeight) / 2;
        }
        if (shapeProps.columnWidths) {
            const oldWidth = this.width;
            this.columnWidths = shapeProps.columnWidths;
            this.x += (this.width - oldWidth) / 2;
        }
        if (shapeProps.cells) {
            this.applyCellUpdate(
                {
                    ...shapeProps.cells,
                    assignedShapeStyle: shapeProps.assignedShapeStyle
                },
                textProps,
                strokeProps,
                shapeProps.borderUpdates,
                shapeProps.removeCustomStyles,
                isEmphasisStyle
            );
        } else {
            this.applyUpdateOnAllCells(shapeProps, textProps);
        }
        if (shapeProps.partUpdates) {
            const tableStyleMismatches = get(shapeProps, 'tableStyleMismatches', {});
            const partUpdates = {
                ...shapeProps.partUpdates,
                tableStyleMismatches
            };
            this.applyPartUpdates(partUpdates, shapeProps.removeCustomStyles);
        }
    }

    applyUpdateOnAllCells(shapeProps, textProps) {
        const validShapeProps = validTableShapeProps(shapeProps);
        if (validShapeProps.length > 0) {
            Object.values(this.cells).forEach(cell => {
                if (shapeProps.opacity) {
                    this.opacity = shapeProps.opacity;
                    cell.applyUpdate({ opacity: shapeProps.opacity }, textProps);
                } else {
                    this.fill = shapeProps.fill;
                    cell.applyUpdate({ shapeFill: shapeProps.fill }, textProps);
                }
            });
        }
        if (textProps && !Object.keys(shapeProps).length > 0) {
            forEach(this.cells, cell => {
                cell.applyUpdate({}, textProps);
            });
        }
    }

    applyCellUpdate(
        update,
        textUpdate = {},
        strokeUpdate = {},
        borderUpdates = {},
        removeCustomStyles,
        isEmphasisStyle
    ) {
        if (
            update.shapeFill ||
            update.opacity ||
            update.shapeOpacity ||
            update.assignedShapeStyle ||
            Object.keys(textUpdate).length > 0
        ) {
            update.ids.forEach(id => {
                const cell = this.getCellById(id);
                cell.applyUpdate({
                    ...update,
                    removeCustomStyles
                }, textUpdate, isEmphasisStyle);
            });
        }
        if (strokeUpdate.fill || strokeUpdate.width || strokeUpdate.dash) {
            this.applyBorderUpdate({
                ...strokeUpdate,
                styleMismatches: get(borderUpdates, 'styleMismatches', []),
                removeCustomStyles,
                type: update.type,
                ids: update.ids
            });
        }

        Border.UPDATES_SEQUENCE
            .forEach(updateType => {
                if (borderUpdates[updateType]) {
                    const borderUpdate = borderUpdates[updateType];
                    this.applyBorderUpdate({
                        ...convertPropertiesToShapeProperties(borderUpdate).strokeProps,
                        styleMismatches: get(borderUpdates, 'styleMismatches', []),
                        type: updateType,
                        removeCustomStyles,
                        ids: update.ids
                    });
                }
            });
    }

    applyBorderUpdate(update = {}) {
        const cells = update.ids.map(id => this.getCellById(id));
        const borderSegmentIds = new Set();
        switch (update.type) {
            case Border.UPDATE_TYPES.CLEAR:
                cells.forEach(cell => cell.borders.forEach(borderId => borderSegmentIds.add(borderId)));
                this.clearBorders(borderSegmentIds);
                break;
            case Border.UPDATE_TYPES.BOTTOM:
                this.getBottomEdgeOfCellList(update.ids)
                    .forEach(cell => cell.bottomBorderSegments.forEach(borderId => borderSegmentIds.add(borderId)));
                break;
            case Border.UPDATE_TYPES.LEFT:
                this.getLeftEdgeOfCellList(update.ids)
                    .forEach(cell => cell.leftBorderSegments.forEach(borderId => borderSegmentIds.add(borderId)));
                break;
            case Border.UPDATE_TYPES.RIGHT:
                this.getRightEdgeOfCellList(update.ids)
                    .forEach(cell => cell.rightBorderSegments.forEach(borderId => borderSegmentIds.add(borderId)));
                break;
            case Border.UPDATE_TYPES.TOP:
                this.getTopEdgeOfCellList(update.ids)
                    .forEach(cell => cell.topBorderSegments.forEach(borderId => borderSegmentIds.add(borderId)));
                break;
            case Border.UPDATE_TYPES.INNER_ALL:
                this.getInnerBorders(cells)
                    .forEach(border => borderSegmentIds.add(border.id));
                break;
            case Border.UPDATE_TYPES.INNER_HORIZONTAL:
                this.getInnerHorizontalBorders(cells)
                    .forEach(border => borderSegmentIds.add(border.id));
                break;
            case Border.UPDATE_TYPES.INNER_VERTICAL:
                this.getInnerVerticalBorders(cells)
                    .forEach(border => borderSegmentIds.add(border.id));
                break;
            case Border.UPDATE_TYPES.OUTER:
                this.getOuterBorders(cells)
                    .forEach(border => borderSegmentIds.add(border.id));
                break;
            case Border.UPDATE_TYPES.ALL:
            default:
                cells.forEach(cell => cell.borders.forEach(borderId => borderSegmentIds.add(borderId)));
        }

        if (update.type !== Border.UPDATE_TYPES.CLEAR) {
            this.applyBorderUpdateToAll(
                new Set([...borderSegmentIds]
                    .filter(id => (
                        !get(update, 'styleMismatches', []).includes(id)
                    ))),
                update
            );
        }
    }

    getCellsOnAxis(coord, axis) {
        return (axis === 'horizontal' ?
            this.getCellGridRow(tableGridLogic.getRowForY(coord, this)) :
            this.getCellGridColumn(tableGridLogic.getColumnForX(coord, this))
        ).map(cellId => this.getCellById(cellId));
    }

    getFirstColumn() {
        return this.getCellGridColumn(0).map(cellId => this.cells[cellId]);
    }

    getFirstRow() {
        return this.getCellGridRow(0).map(cellId => this.cells[cellId]);
    }

    getLastColumn() {
        return this.getCellGridColumn(this.columnCount - 1).map(cellId => this.cells[cellId]);
    }

    getLastRow() {
        return this.getCellGridRow(this.rowCount - 1).map(cellId => this.cells[cellId]);
    }

    getVerticalOuterCells() {
        return uniqBy([
            ...this.getFirstRow(),
            ...this.getLastRow()
        ], 'id');
    }

    getVerticalInnerCells() {
        const cellIds = new Set();
        forEach(
            this.cellGrid.slice(1, this.rowCount - 1),
            cellRow => forEach(cellRow, cellId => cellIds.add(cellId))
        );
        return [...cellIds].map(cellId => this.cells[cellId]);
    }

    getHorizontalOuterCells() {
        return uniqBy([
            ...this.getFirstColumn(),
            ...this.getLastColumn()
        ], 'id');
    }

    getHorizontalInnerCells() {
        const cellIds = new Set();
        forEach(
            this.cellGrid,
            cellRow => forEach(cellRow.slice(1, this.columnCount - 1), cellId => cellIds.add(cellId))
        );
        return [...cellIds].map(cellId => this.cells[cellId]);
    }

    setCellContentById(id, update) {
        forEach(this.cells, cell => cell.setContentById(id, update));
    }

    getCellContentById(id) {
        return map(this.cells, cell => cell.getContentById(id)).filter(content => content)[0];
    }

    getCellById(id) {
        return this.cells[id];
    }

    getCellsById(ids) {
        return ids.map(cellId => this.cells[cellId]);
    }

    getCellByName(name) {
        return this.cells.find(cell => cell.name === name);
    }

    getBorderById(borderId) {
        return this.borderSegments[borderId];
    }

    getCellBorders(cellId) {
        const cell = this.cells[cellId];
        if (cell) {
            return [
                ...cell.leftBorderSegments,
                ...cell.rightBorderSegments,
                ...cell.topBorderSegments,
                ...cell.bottomBorderSegments
            ];
        }
        return [];
    }

    getCellListBorders(cellsList) {
        const borders = [];
        cellsList.forEach(cell => {
            borders.push(
                ...this.getCellBorders(cell)
            );
        });
        return borders;
    }

    addRowsForCells(cellIds, direction) {
        const cellsOnSelectionEdge = [];

        tableGridLogic.getGridColumns(this).forEach(column => {
            const selectedCells = column
                .filter(cellId => cellIds.includes(cellId))
                .map(cellId => this.cells[cellId]);
            if (selectedCells.length !== 0) {
                const cellOnSelectionEdge = direction === 'top' ?
                    selectedCells.sort((a, b) => a.row - b.row)[0] :
                    selectedCells.sort((a, b) => b.lastSpannedRow - a.lastSpannedRow)[0];
                cellsOnSelectionEdge.push(cellOnSelectionEdge);
            }
        });

        const currentIndex = direction === 'top' ?
            Math.max(...cellsOnSelectionEdge.map(cell => cell.row)) :
            Math.min(...cellsOnSelectionEdge.map(cell => cell.lastSpannedRow));
        this.insertRow(currentIndex, direction);
    }

    addColumnsForCells(cellIds, direction) {
        const cellsOnSelectionEdge = [];

        forEach(this.cellGrid, row => {
            const selectedCells = row
                .filter(cellId => cellIds.includes(cellId))
                .map(cellId => this.cells[cellId]);
            if (selectedCells.length !== 0) {
                const cellOnSelectionEdge = direction === 'left' ?
                    selectedCells.sort((a, b) => a.column - b.column)[0] :
                    selectedCells.sort((a, b) => b.lastSpannedColumn - a.lastSpannedColumn)[0];
                cellsOnSelectionEdge.push(cellOnSelectionEdge);
            }
        });

        const currentIndex = direction === 'left' ?
            Math.max(...cellsOnSelectionEdge.map(cell => cell.column)) :
            Math.min(...cellsOnSelectionEdge.map(cell => cell.lastSpannedColumn));
        this.insertColumn(currentIndex, direction);
    }

    getCellsRowIndexes(cellIds) {
        const rows = [];
        const cells = this.getCellsById(cellIds);
        cells.forEach(cell => {
            for (let i = cell.row; i < cell.row + cell.rowSpan; i++) {
                rows.push(i);
            }
        });
        return [...new Set(rows)];
    }

    getCellsColumnIndexes(cellIds) {
        const columns = [];
        const cells = this.getCellsById(cellIds);
        cells.forEach(cell => {
            for (let i = cell.column; i < cell.column + cell.columnSpan; i++) {
                columns.push(i);
            }
        });
        return [...new Set(columns)];
    }

    isCellCoveringWholeAxis(cellIds, axis) {
        return axis === 'horizontal' ?
            this.getCellsRowIndexes(cellIds).length === this.rowCount :
            this.getCellsColumnIndexes(cellIds).length === this.columnCount;
    }

    removeRowsForCellIds(cellIds) {
        this.getCellsRowIndexes(cellIds)
            .reverse()
            .forEach(row => this.removeRow(row));
    }

    removeColumnsForCellIds(cellIds) {
        this.getCellsColumnIndexes(cellIds)
            .reverse()
            .forEach(column => this.removeColumn(column));
    }

    getFlattenChildren() {
        let borders = [];
        let tableCells = [];
        if (this.borderSegments) {
            borders = Object.values(this.borderSegments);
        }
        if (this.cells) {
            tableCells = Object.values(this.cells);
        }
        return [
            this,
            ...borders,
            ...tableCells.reduce((cells, cell) => [
                ...cells,
                ...cell.getFlattenChildren()
            ], [])
        ];
    }

    addBorderSegment(borderSegment) {
        this.borderSegments[borderSegment.id] = borderSegment;
    }

    findBorder(axis, columnIndex, rowIndex) {
        if (axis === 'horizontal') {
            return this.getBorderById(this.horizontalBorders[rowIndex][columnIndex]);
        }
        return this.getBorderById(this.verticalBorders[columnIndex][rowIndex]);
    }

    combineDefinedAndRenderRowHeights() {
        return tableGridLogic.combineDefinedAndRenderRowHeights(this);
    }

    updateColorPresets({ presets, scheme } = {}) {
        forEach(this.cells, cell => {
            cell.updateColorPresets({ presets, scheme });
        });

        forEach(this.borderSegments, border => {
            border.updateColorPresets({ presets, scheme });
        });
    }

    forceAParagraphInEmptyCells() {
        Object.values(this.cells)
            .forEach(cell => {
                cell.forceParagraphIfEmpty();
            });
    }

    get hasBandedColumns() {
        return this.settings.hasBandedColumns;
    }

    set hasBandedColumns(hasBandedColumns) {
        this.settings.hasBandedColumns = hasBandedColumns;
    }

    get hasBandedRows() {
        return this.settings.hasBandedRows;
    }

    set hasBandedRows(hasBandedRows) {
        this.settings.hasBandedRows = hasBandedRows;
    }

    get hasHeaderColumn() {
        return this.settings.hasHeaderColumn || false;
    }

    set hasHeaderColumn(hasHeaderColumn) {
        this.settings.hasHeaderColumn = hasHeaderColumn;
    }

    get hasHeaderRow() {
        return this.settings.hasHeaderRow || false;
    }

    set hasHeaderRow(hasHeaderRow) {
        this.settings.hasHeaderRow = hasHeaderRow;
    }

    get hasHeaderSpannerColumn() {
        return this.getCellsInColumn(0)
            .some(cell => cell.isHeaderSpannerColumn);
    }

    get hasHeaderSpannerRow() {
        return this.getCellsInRow(0)
            .some(cell => cell.isHeaderSpannerRow);
    }

    get hasTotalColumn() {
        return this.settings.hasTotalColumn || false;
    }

    set hasTotalColumn(hasTotalColumn) {
        this.settings.hasTotalColumn = hasTotalColumn;
    }

    get hasTotalRow() {
        return this.settings.hasTotalRow || false;
    }

    set hasTotalRow(hasTotalRow) {
        this.settings.hasTotalRow = hasTotalRow;
    }

    get mainAxis() {
        return this.settings.mainAxis || 'vertical';
    }

    set mainAxis(mainAxis) {
        this.settings.mainAxis = mainAxis;
    }

    get shouldIncludeHeaderRowInSelection() {
        return this.settings.includeHeaderRowInSelection;
    }

    get shouldIncludeHeaderColumnInSelection() {
        return this.settings.includeHeaderColumnInSelection;
    }

    get shouldIncludeTotalRowInSelection() {
        return this.settings.includeTotalRowInSelection;
    }

    get shouldIncludeTotalColumnInSelection() {
        return this.settings.includeTotalColumnInSelection;
    }

    get shouldIncludeHeaderRowInCount() {
        return this.settings.includeHeaderRowInCount;
    }

    set shouldIncludeHeaderRowInCount(shouldIncludeHeaderRowInCount) {
        this.settings.includeHeaderRowInCount = shouldIncludeHeaderRowInCount;
    }

    get shouldIncludeHeaderColumnInCount() {
        return this.settings.includeHeaderColumnInCount;
    }

    set shouldIncludeHeaderColumnInCount(shouldIncludeHeaderColumnInCount) {
        this.settings.includeHeaderColumnInCount = shouldIncludeHeaderColumnInCount;
    }

    get shouldIncludeTotalRowInCount() {
        return this.settings.includeTotalRowInCount;
    }

    set shouldIncludeTotalRowInCount(shouldIncludeTotalRowInCount) {
        this.settings.includeTotalRowInCount = shouldIncludeTotalRowInCount;
    }

    get shouldIncludeTotalColumnInCount() {
        return this.settings.includeTotalColumnInCount;
    }

    set shouldIncludeTotalColumnInCount(shouldIncludeTotalColumnInCount) {
        this.settings.includeTotalColumnInCount = shouldIncludeTotalColumnInCount;
    }

    get rowCount() {
        return this.rowHeights.length;
    }

    get columnCount() {
        return this.columnWidths.length;
    }

    /**
     * These setters set properties that will never be used. They
     * are required for the implementation of the AbstractShape
     * extension
     */
    set width(width) {
        this._width = width;
    }

    get width() {
        return this.columnWidths.slice(0, this.columnCount + 1)
            .reduce((sum, width) => sum + width, 0);
    }

    get height() {
        return tableGridLogic.combineDefinedAndRenderRowHeights(this)
            .slice(0, this.rowCount + 1)
            .reduce((sum, height) => sum + height, 0);
    }

    /**
     * These setters set properties that will never be used. They
     * are required for the implementation of the AbstractShape
     * extension
     */
    set height(height) {
        this._height = height;
    }

    get headerRowCells() {
        return this.cells.filter(cell => cell.isHeaderRowCell);
    }

    get headerColumnCells() {
        return this.cells.filter(cell => cell.isHeaderColumnCell);
    }

    get fonts() {
        return new Set(this.cells.reduce((tableFonts, cell) => [
            ...tableFonts,
            ...cell.fonts
        ], []));
    }

    set position({ x = this.x, y = this.y }) {
        this.x = pixelRound(x);
        this.y = pixelRound(y);
    }
}

Shape.addToConstructorList(Table, 'table');

module.exports = Table;
