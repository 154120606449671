import { Map } from 'immutable';

const defaultDoActionOnFillColor = (fill, typeKey, action, ...args) => {
    switch ((fill || Map()).get(typeKey)) {
        case 'color': {
            return fill.update(
                'color',
                color => action(color, ...args)
            );
        }
        case 'gradient':
            return fill.updateIn(
                [
                    'gradient',
                    'stops'
                ],
                stops => stops.map(stop => stop.update(
                    'color',
                    color => action(color, ...args)
                ))
            );
        case 'pattern':
            return fill.updateIn(
                [
                    'pattern',
                    'colors'
                ],
                colors => colors
                    .update('background', background => action(background, ...args))
                    .update('foreground', foreground => action(foreground, ...args))
            );
        default:
            return fill;
    }
};

export default defaultDoActionOnFillColor;
