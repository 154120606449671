const { fabric } = require('fabric');
const pick = require('lodash/pick');
const isNil = require('lodash/isNil');
const get = require('lodash/get');
const cloneDeep = require('lodash/cloneDeep');

const svgpath = require('svgpath');
const applyFill = require('../utilities/applyFill');
const { dashArrayGenerator } = require('../utilities/dash');
const fabricShapeFactory = require('../utilities/fabricShapeFactory');
const fillProperties = require('../utilities/fillProperties');
const { getRgbaObject, isValid } = require('../../utilities/color');
const Placeholder = require('./Mixins/Placeholder');
const ImagePlaceholder = require('./Mixins/ImagePlaceholder');
const TablePlaceholder = require('./Mixins/TablePlaceholder');
const TextDrawZone = require('./Mixins/TextDrawZone');
const AxisLocking = require('./Mixins/AxisLocking');
const Duplicate = require('./Mixins/Duplicate');
const { svgStringToArray, stringifyPath } = require('../../utilities/svgUtility');
const { generatePathFromPoints } = require('../../utilities/PathGenerator/PathGenerator');
const textShapeITextBehavior = require('./Mixins/textShapeITextBehavior');

const Textshape = fabric.util.createClass(
    fabric.Group,
    Placeholder,
    ImagePlaceholder,
    TablePlaceholder,
    TextDrawZone,
    AxisLocking,
    Duplicate,
    textShapeITextBehavior,
    {
        type: 'textshape',

        originalPosition: {
            x: undefined,
            y: undefined
        },

        textShapePropertiesToPersist: [
            'name',
            'type',
            'id',
            'left',
            'top',
            'angle',
            'textBodyMargins',
            'isBackground',
            'verticalAlign',
            'textDirection',
            'superscript',
            'subscript',
            'displayPlaceholder',
            'isTextEmpty',
            'paragraphs',
            'placeholderParagraphs',
            'placeholderType',
            'placeholderSequence',
            'placeholderSourceId',
            'padding',
            'dynamicProperties',
            'style',
            'autoFitText',
            'autoFitShape',
            'lockPath'
        ],

        lockPath: false,

        initialize(json, opts = {}) {
            const options = {
                ...opts,
                rotation: 0,
                originX: 'center',
                originY: 'center',
                name: json.name,
                id: json.id,
                inLayout: json.inLayout,
                angle: json.angle,
                cacheProperties: this.textShapePropertiesToPersist,
                stateProperties: this.textShapePropertiesToPersist,
                placeholderType: json.placeholderType,
                placeholderSequence: json.placeholderSequence,
                placeholderSourceId: json.placeholderSourceId,
                isLocked: json.isLocked,
                isHidden: json.isHidden,
                isImpoted: json.isImported,
                visible: json.visible,
                selectable: json.selectable,
                displayPlaceholder: isNil(json.displayPlaceholder) ? true : json.displayPlaceholder,
                isTextEmpty: json.isTextEmpty,
                dynamicProperties: json.dynamicProperties,
                handlers: [],
                paragraphs: json.paragraphs || [],
                placeholderParagraphs: json.placeholderParagraphs || [],
                autoFitText: json.autoFitText || false,
                autoFitShape: json.autoFitShape || false,
                lockPath: json.lockPath || false
            };

            const {
                anchors,
                breakWords,
                charSpacing,
                displayPlaceholder,
                isTextEmpty,
                linethrough,
                overline,
                paragraphs,
                placeholderParagraphs,
                textBodyMargins,
                textDirection,
                underline,
                verticalAlign,
                fill,
                style,
                ...shapeJSON
            } = {

                ...json,
                type: json.shapeType || 'Rectangle',
                angle: 0
            };

            if (json.stroke) {
                if (json.stroke.dash) {
                    shapeJSON.strokeDashArray = dashArrayGenerator(
                        json.stroke.dash.type,
                        json.stroke.width,
                        json.stroke.dash.dashStops,
                        json.stroke.cap
                    );
                }

                if (!isNil(json.stroke.width)) {
                    shapeJSON.strokeWidth = json.stroke.width;
                }

                if (json.stroke.cap) {
                    shapeJSON.strokeLineCap = json.stroke.cap;
                }

                if (json.stroke.join) {
                    shapeJSON.strokeLineJoin = json.stroke.join.type;
                }
            }

            shapeJSON.strokeUniform = true;

            this.fill = fill;
            this.stroke = shapeJSON.stroke;
            this.lockUniScaling = json.lockUniScaling;
            this.hoverCursor = this.editingText ? 'text' : 'default';
            this.style = style;
            if (typeof shapeJSON.evented === 'boolean') {
                this.evented = shapeJSON.evented;
            }
            if (typeof shapeJSON.selectable === 'boolean') {
                this.selectable = shapeJSON.selectable;
            }
            if (opts.tableName) {
                this.tableName = opts.tableName;
            }

            this.callSuper('initialize', [], options);

            this.initializePlaceholder();

            fabricShapeFactory.createShape(shapeJSON, opts)
                .then(shape => {
                    const jsonScale = this.getScaleWithRotation(json.scaleX, json.scaleY);
                    const shapeScale = this.getScaleWithRotation(shape.scaleX, shape.scaleY);

                    shape.set('anchors', anchors);
                    shape.width = json.width * jsonScale.x || shape.width * shapeScale.x || 250;
                    shape.height = json.height * jsonScale.y || shape.height * shapeScale.y || 250;
                    shape.scaleX = 1;
                    shape.scaleY = 1;
                    this.imgUrl = json.imgUrlm;
                    this.isBackground = json.isBackground || false;
                    this.placeholderHtml = json.placeholderHtml || '';
                    this.verticalAlign = json.verticalAlign;
                    this.textDirection = json.textDirection;
                    this.textBodyMargins = json.textBodyMargins || {
                        bottom: 0,
                        left: 0,
                        right: 0,
                        top: 0
                    };
                    this.sendToBack = json.sendToBack;

                    return Promise.all([
                        applyFill({
                            textShape: this,
                            shape,
                            fill: this.fill,
                            targetProperty: 'fill'
                        }),
                        ...(
                            this.stroke && this.stroke.fill ? [
                                applyFill({
                                    textShape: this,
                                    shape,
                                    fill: this.stroke.fill,
                                    targetProperty: 'stroke'
                                })
                            ] : [])
                    ]).then(() => {
                        shape.angle = 0;
                        this.addWithUpdate(shape);
                        this.angle = json.angle;

                        if (json.path && (json.scaleX !== 1 || json.scaleY !== 1)) {
                            this.scalePath(jsonScale.x, jsonScale.y);
                            this.scalePathOffset(jsonScale.x, jsonScale.y);
                        }

                        this.initializePlaceholderStroke();
                    });
                })
                .then(() => this.prepareShapeForRendering())
                .then(() => {
                    this.loadText();

                    this.initBehavior();

                    this.on('scaling', () => {
                        /*
                         * Inverse scaling while interacting so text does not
                         * scale during interaction
                         */
                        const textObject = this.getTextObject();
                        if (textObject) {
                            textObject.set({
                                scaleX: 1 / this.scaleX,
                                scaleY: 1 / this.scaleY
                            });
                        }
                    });

                    this.on('mousedown', event => {
                        this.setInitialPositions(event);
                        if (event.e.metaKey &&
                            this.canvas.getActiveObjects()[0].shapeType !== 'Group' &&
                            !this.canvas.canvasState.get('contextualSelection')) {
                            this.duplicateShape();
                        }
                    });

                    this.on('moving', event => {
                        this.handleAxisLocking(event);
                    });

                    this.on('modified', () => {
                        this.resetAxisLocking();
                    });

                    this.on('removed', () => {
                        this.off('text:load');
                        this.off('load:error');
                        this.off('canvas:state:update');
                    });

                    this.on('mouseup', event => {
                        if (event.e.metaKey) {
                            this.changeOriginalShapeName();
                        }
                    });
                })
                .then(() => {
                    if (this.handleTextLoad) {
                        this.handleTextLoad();
                    }
                    this.isLoaded = true;
                    this.fire('text:load', { target: this });
                })
                .catch(err => {
                    console.error('load error', this.name, err);
                    this.fire('load:err', err);
                });
        },

        getActiveObjectLeft() {
            return this.left;
        },

        getActiveObjectTop() {
            return this.top;
        },

        setActiveObjectLeft(left) {
            this.left = left;
        },

        setActiveObjectTop(top) {
            this.top = top;
        },

        getCanvasIndex(canvas) {
            if (!canvas) {
                return -1;
            }
            if (this.group) {
                return canvas.getShapeIndex(this.group.name);
            }
            return canvas.getShapeIndex(this.name);
        },

        getCanvasIndexFromId(canvas) {
            if (!canvas) {
                return -1;
            }
            if (this.group) {
                return canvas.getShapeIndexFromId(this.group.id);
            }
            return canvas.getShapeIndexFromId(this.id);
        },

        updateShapeFill() {
            if (this.hasImageFill()) {
                const shapeObject = this.getShapeObject();
                const fillProps = fillProperties(this.fill, shapeObject, this.imgEl);

                if (this.fill.preserveAspectRatio && this.fill.preserveAspectRatio.meetOrSlice) {
                    this.imgEl.scaleX = fillProps.imageScaleX;
                    this.imgEl.scaleY = fillProps.imageScaleY;
                } else if (!this.fill.stretch) {
                    this.imgEl.scaleX = fillProps.imageScaleX;
                    this.imgEl.scaleY = fillProps.imageScaleY;
                } else {
                    this.imgEl.scaleX = (shapeObject.width / this.imgEl.width) *
                        fillProps.imageScaleX;
                    this.imgEl.scaleY = (shapeObject.height / this.imgEl.height) *
                        fillProps.imageScaleY;
                }

                shapeObject.fill.offsetX = fillProps.offsetX;
                shapeObject.fill.offsetY = fillProps.offsetY;
            }
        },

        updateTextScale() {
            const textObject = this.getTextObject();
            const margins = this.textBodyMargins;
            if (textObject) {
                textObject.set({
                    width: this.width - margins.left - margins.right,
                    height: this.height - margins.top - margins.bottom,
                    scaleX: 1,
                    scaleY: 1,
                    top: -(this.height / 2),
                    left: -(this.width / 2),
                    flipX: this.flipX
                });
                this.refreshText();
                this.set('dirty', true);
            }

            return Promise.resolve();
        },

        isShapeBorderVisible() {
            const shape = this.getShapeObject();
            const isStrokeWidthVisible = shape?.strokeWidth > 0;
            const isValidColor = isValid(shape?.stroke);
            if (!isValidColor) {
                return false;
            }
            const { a } = getRgbaObject(shape?.stroke);
            const isStrokeAlphaVisible = a > 0;
            return isStrokeWidthVisible && isStrokeAlphaVisible;
        },

        updateShapeScale(scaleX, scaleY, scalePath = false, fromGroup = false) {
            const shapeObject = this.getShapeObject();
            const strokeWidth = get(this, 'stroke.width', 0);

            let actualScale = {
                x: scaleX,
                y: scaleY
            };

            if (fromGroup) {
                actualScale = this.getScaleWithRotation(scaleX, scaleY);
            }

            const newShapeObjectWidth = ((shapeObject.width +
                strokeWidth) * actualScale.x) - strokeWidth;
            const newShapeObjectHeight = ((shapeObject.height +
                strokeWidth) * actualScale.y) - strokeWidth;
            if (scalePath) {
                const widthRatio = newShapeObjectWidth / shapeObject.width;
                const heightRatio = newShapeObjectHeight / shapeObject.height;

                this.scalePath(widthRatio, heightRatio);
            }
            shapeObject.set({
                width: newShapeObjectWidth,
                height: newShapeObjectHeight,
                scaleX: 1,
                scaleY: 1
            });
            this.width *= actualScale.x;
            this.height *= actualScale.y;
            this.scaleX = 1;
            this.scaleY = 1;
        },

        /*
            This replicates the PowerPoint behaviour where scaling is inverted depending on the angle.
        */
        getScaleWithRotation(scaleX, scaleY) {
            let { angle } = this;

            if (angle < 0) {
                angle += 360;
            }

            if ((angle >= 45 && angle < 135) || (angle >= 225 && angle < 315)) {
                return {
                    x: scaleY,
                    y: scaleX
                };
            }

            return {
                x: scaleX,
                y: scaleY
            };
        },

        updateShapeScaleToDimension({
            height = this.getScaledHeight(),
            width = this.getScaledWidth()
        } = {}) {
            const newScale = {
                x: width / this.getScaledWidth(),
                y: height / this.getScaledHeight()
            };
            this.updateShapeScale(newScale.x, newScale.y);
            this.set('dirty', true);
        },

        updateScale(scaleX, scaleY, fromGroup = false) {
            this.updateModificationHandlers();
            this.updateShapeScale(scaleX, scaleY, fromGroup);
            this.updateShapeFill();
            return this.updateTextScale();
        },

        toObject: function toObject(propertiesToInclude = []) {
            const shape = this.getShapeObject();

            if (shape.isDefaultPlaceholderStrokeUsed === true) {
                shape.set(this.originalStrokeDefinition);
            }

            const shapePropertiesToSerialize = [
                ...propertiesToInclude,
                'shapeType',
                'strokeDashName',
                'strokeCompound',
                'scaleX',
                'scaleY'
            ];

            let shapeObject = shape.toObject(shapePropertiesToSerialize);

            shapeObject = {
                ...shapeObject,
                ...cloneDeep(pick(this, this.textShapePropertiesToPersist))
            };

            shapeObject.scaleX *= this.scaleX;
            shapeObject.scaleY *= this.scaleY;
            shapeObject.lockUniScaling = this.lockUniScaling;
            shapeObject.left = this.left;
            shapeObject.top = this.top;
            shapeObject.verticalAlign = this.verticalAlign;
            shapeObject.textDirection = this.textDirection;
            shapeObject.fill = this.fill;
            shapeObject.style = this.style;
            shapeObject.stroke = this.stroke;
            shapeObject.opacity = undefined;
            shapeObject.paragraphs = this.bodyTextbox.toObject().paragraphs;
            shapeObject.placeholderParagraphs = this.placeholderTextbox.toObject().paragraphs;
            if (this.tableName) {
                shapeObject.tableName = this.tableName;
            }
            return shapeObject;
        },

        toJSON() {
            return fabric.util.object.extend(this.callSuper('toJSON'));
        },

        getTypedObject() {
            return {
                shape: this.getObjects().find(obj => !obj.isType('multiItemTextbox')),
                text: this.getObjects().find(obj => obj.isType('multiItemTextbox') && obj.opacity === 1),
                handles: this.getObjects().filter(obj => obj.isHandle)
            };
        },

        getTextObject() {
            return this.getTypedObject().text;
        },

        getShapeObject() {
            return this.getTypedObject().shape;
        },

        isType(type) {
            return this.getShapeObject() !== undefined && this.getShapeObject().shapeType === type;
        },

        generateModificationHandlers() {
            this.handlers = [];
        },

        renderModificationHandlers() {
            this.handlers.forEach(handler => {
                handler.updatePosition();
            });
            const index = this.getCanvasIndexFromId(this.canvas);
            this.canvas.addShapesAtIndex(this.handlers, index + 1);
        },

        removeModificationHandlers() {
            this.canvas.remove(...this.handlers);
        },

        updateModificationHandlers() {
            this.handlers.forEach(handler => handler.updatePosition());
        },

        prepareShapeForRendering() {
            this.setObjectCaching(false);
            this.updateScale(1, 1);
            this.generateModificationHandlers();
        },

        setObjectCaching(bool) {
            const shape = this.getShapeObject();
            shape.objectCaching = bool;
            this.objectCaching = bool;
        },

        renderDecorations() {
            return this;
        },

        onDeselect() {
            this.removeModificationHandlers();
        },

        unscalePath() {
            const shapeObject = this.getShapeObject();
            const activeScaleX = this.scaleX * shapeObject.scaleX;
            const activeScaleY = this.scaleY * shapeObject.scaleY;
            this.scalePath(1 / activeScaleX, 1 / activeScaleY);
        },

        scalePath(scaleX, scaleY) {
            const shapeObject = this.getShapeObject();
            const path = svgpath(stringifyPath(shapeObject.path))
                .scale(scaleX, scaleY);
            shapeObject.path = svgStringToArray(path);
            this.set('dirty', true);
        },

        getMinimumDimensions() {
            return {
                width: 0,
                height: 0
            };
        },

        getPathScale() {
            const { width: minWidth, height: minHeight } = this.getMinimumDimensions();
            return {
                x: this.width * this.scaleX < minWidth ?
                    (this.width * this.scaleX) / minWidth : 1,
                y: this.height * this.scaleY < minHeight ?
                    (this.height * this.scaleY) / minHeight : 1
            };
        },

        adjustPathScale(width, height) {
            const { width: minWidth, height: minHeight } = this.getMinimumDimensions();
            const scaleToApply = {
                x: width <= minWidth ? width / minWidth : 1,
                y: height <= minHeight ? height / minHeight : 1
            };
            this.scalePath(scaleToApply.x, scaleToApply.y);
            this.resetPathOffset();
        },

        scalePathOffset(scaleX, scaleY) {
            const shapeObject = this.getShapeObject();
            const strokeWidth = get(this, 'stroke.width', 0);
            shapeObject.set({
                pathOffset: {
                    x: (((shapeObject.width + strokeWidth) * scaleX) - strokeWidth) / 2,
                    y: (((shapeObject.height + strokeWidth) * scaleY) - strokeWidth) / 2
                }
            });
        },

        setNewPathFromPoints(points) {
            const shapeObject = this.getShapeObject();
            const activeScaleX = this.scaleX * shapeObject.scaleX;
            const activeScaleY = this.scaleY * shapeObject.scaleY;
            const path = svgpath(generatePathFromPoints(points))
                .scale(1 / activeScaleX, 1 / activeScaleY);
            shapeObject.points = points;
            shapeObject.path = svgStringToArray(path);
            this.set('dirty', true);
        },

        getScaledDimensions() {
            const { width: minWidth, height: minHeight } = this.getMinimumDimensions();
            return {
                width: this.width * this.scaleX <= minWidth ?
                    minWidth : this.width * this.scaleX,
                height: this.height * this.scaleY <= minHeight ?
                    minHeight : this.height * this.scaleY
            };
        },

        resetPathOffset() {
            const shapeObject = this.getShapeObject();
            shapeObject.pathOffset = { x: 0, y: 0 };
        },

        getHorizontalBorderSize() {
            if (this.isPlaceholderStrokeDisplayed()) {
                return 0;
            }
            const shape = this.getShapeObject();
            const { strokeWidth } = shape;
            return strokeWidth;
        },

        getVerticalBorderSize() {
            if (this.isPlaceholderStrokeDisplayed()) {
                return 0;
            }
            const shape = this.getShapeObject();
            const { strokeWidth } = shape;
            return strokeWidth;
        },

        _getLeftTopCoords() {
            if (this.group === undefined) {
                return this.callSuper('_getLeftTopCoords');
            }
            let currentParent = this.group;
            let leftTopCoords = currentParent.translateToCenterPoint(
                this.callSuper('_getLeftTopCoords'),
                'left',
                'top'
            );
            while (currentParent !== undefined) {
                const parentLeftTopCoords = currentParent.group ?
                    currentParent.group.translateToCenterPoint(
                        currentParent._getLeftTopCoords(),
                        'left',
                        'top'
                    ) :
                    currentParent._getLeftTopCoords();
                leftTopCoords = leftTopCoords.add(parentLeftTopCoords);
                currentParent = currentParent.group;
            }
            return leftTopCoords;
        }
    }
);

Textshape.fromObject = (object, callback) => {
    const textshape = new Textshape(object);
    textshape.on('text:load', () => {
        callback(textshape);
    });
    textshape.on('load:err', err => {
        callback(textshape, err);
    });
    return textshape;
};

module.exports = Textshape;
