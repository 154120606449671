import { getShapePathWithLayer, pasteShapes } from '../../Helpers/helpers';
import { getCells } from '../../Helpers/Table/table';
import { copyCellContentStyles, copyCellShapeStyle } from '../../Helpers/Table/cell';
import Textbody from '../../Helpers/Text/TextBody';
import getPropertiesForDestructuring from '../../utilities/getPropertiesForDestructuring';

const paste = (canvasState, command) => {
    const {
        ids,
        page
    } = getPropertiesForDestructuring(
        command,
        [
            'ids',
            'page'
        ]
    );

    const clipboard = canvasState.get('clipboard');
    let updatedCanvasState = canvasState;
    const contextualSelection = canvasState.get('contextualSelection');
    if (clipboard.get(0).get('contents') && contextualSelection?.get('cells')) {
        let table = canvasState.getIn(getShapePathWithLayer(canvasState, canvasState.get('selection').get(0)));
        let targetCell = getCells(table, contextualSelection.get('cells')).get(0);
        const targetCellIndex = table.get('cells').findIndex(cell => cell.get('id') === targetCell.get('id'));
        targetCell = copyCellContentStyles(targetCell, clipboard.get(0), false);
        targetCell = copyCellShapeStyle(targetCell, clipboard.get(0));
        table = table.setIn(['cells', targetCellIndex], targetCell);
        updatedCanvasState = updatedCanvasState.setIn(
            getShapePathWithLayer(canvasState, canvasState.get('selection').get(0)),
            table
        );
    } else if (ids &&
        getShapePathWithLayer(canvasState, ids[0]) &&
        clipboard.get(0).get('copiedText', null)) {
        let target = canvasState.getIn(getShapePathWithLayer(canvasState, ids[0]));
        let updatedTextBody = target.get('textBody');
        updatedTextBody = Textbody.setText(updatedTextBody, clipboard.get(0).get('copiedText'));
        target = target.set('textBody', updatedTextBody);
        updatedCanvasState = updatedCanvasState.setIn(getShapePathWithLayer(canvasState, ids[0]), target);
    } else {
        const shouldChangeXY = page ===
        clipboard.getIn([0, 'originalPage']);
        updatedCanvasState = pasteShapes(updatedCanvasState, clipboard, shouldChangeXY);

        const numberOfNewShapes = clipboard.size;
        const currentContext = canvasState.get('editMode').toLowerCase() === 'layout' ? 'layoutShapes' : 'shapes';
        updatedCanvasState = updatedCanvasState
            .set(
                'clipboard',
                updatedCanvasState
                    .getIn([currentContext])
                    .take(numberOfNewShapes)
                    .map(shape => shape.set('originalPage', page))
            );

        updatedCanvasState = updatedCanvasState
            .set(
                'selection',
                updatedCanvasState
                    .getIn([currentContext])
                    .take(numberOfNewShapes)
                    .map(shape => shape.get('id'))
            );
    }
    return updatedCanvasState;
};

export default paste;
