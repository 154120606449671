import {
    isNil, has, update, get
} from 'lodash';
import TEXTBODY_PROPERTIES from '../Config/textBodyRecordProperties';
import {
    getDefaultParagraphStyleIndex,
    getParagraphsWithStyleAndIndex,
    isParagraphStyleListType,
    getFirstRunInParagraph
} from './ParagraphStyles';
import {
    getDefaultRunStyle,
    getRunStyle
} from '../Runs/RunStyles';
import getParagraphStartingInRun from './getParagraphStartingInRun';

const updateParagraphDimensionsForFontSizeRatio = (
    textBodyData,
    paragraphStyleIndex,
    fontSizeRatio
) => textBodyData.updateIn(
    [TEXTBODY_PROPERTIES.PARAGRAPH_STYLES, paragraphStyleIndex],
    paragraphStyle => {
        ['bullet.style.size', 'indent', 'padding.left']
            .filter(dimensionKey => has(paragraphStyle, dimensionKey))
            .forEach(dimensionKey => update(
                paragraphStyle,
                dimensionKey,
                dimension => Number.parseFloat((dimension * fontSizeRatio).toPrecision(2))
            ));
        return paragraphStyle;
    }
);

const applyDefaultParagraphDimensionsUpdateFromNewFontSize = (textBodyData, newFontSize) => {
    const previousFontSize = getDefaultRunFontSize(textBodyData, newFontSize);
    const defaultParagraphStyleIndex = getDefaultParagraphStyleIndex(textBodyData);
    if ([newFontSize, previousFontSize, defaultParagraphStyleIndex].some(isNil)) {
        return textBodyData;
    }
    const paragraphStyleIndexes = getParagraphStyleFromParagraphWithDefaultFontSize(textBodyData);
    return paragraphStyleIndexes.reduce(
        (current, index) => updateParagraphDimensionsForFontSizeRatio(
            current,
            index,
            newFontSize / previousFontSize
        ),
        textBodyData
    );
};

const getParagraphStyleFromParagraphWithDefaultFontSize = textBodyData => {
    const defaultFontSize = getDefaultRunFontSize(textBodyData);
    const paragraphsWithDefaultFontSize = getParagraphsWithStyleAndIndex(
        textBodyData,
        undefined,
        undefined,
        true
    )
        .filter(paragraph => get(paragraph, 'textRuns.0.style.font.size') === defaultFontSize);
    return [...new Set([
        getDefaultParagraphStyleIndex(textBodyData),
        ...paragraphsWithDefaultFontSize.map(paragraph => get(paragraph, 'style')).filter(Boolean)
    ])];
};

const getDefaultRunFontSize = (textBodyData, defaultValue) => get(
    getDefaultRunStyle(textBodyData),
    'font.size',
    defaultValue
);

const getParagraphDimensionsUpdate = (
    textBodyData,
    runIndex,
    runStyleIndex,
    paragraphDimensionsUpdate
) => {
    const paragraphsStartingInRun = getParagraphStartingInRun(textBodyData, runIndex);

    const newFontSize = getRunStyle(textBodyData, runStyleIndex, true).getIn(['font', 'size']);

    if (!newFontSize) {
        return paragraphDimensionsUpdate;
    }

    return paragraphsStartingInRun
        .filter(paragraphStartingInRun => isParagraphStyleListType(
            textBodyData,
            paragraphStartingInRun.get('style')
        ))
        .reduce(
            (currentUpdate, paragraphStartingInRun) => {
                const previousFontSize = get(
                    getFirstRunInParagraph(
                        textBodyData,
                        paragraphStartingInRun.index
                    ),
                    'style.font.size'
                );

                const paragraphStyleIndex = paragraphStartingInRun.get('style');

                if ([previousFontSize, newFontSize, paragraphStyleIndex].some(isNil)) {
                    return paragraphDimensionsUpdate;
                }

                return {
                    ...currentUpdate,
                    [paragraphStyleIndex]: newFontSize / previousFontSize
                };
            },
            paragraphDimensionsUpdate
        );
};

export {
    applyDefaultParagraphDimensionsUpdateFromNewFontSize,
    updateParagraphDimensionsForFontSizeRatio,
    getParagraphDimensionsUpdate
};
