const doActionOnFillColor = require('../utilities/shape/doActionOnFillColor');
const ColorValueDescriptor = require('./ColorValueDescriptor');

module.exports = {
    emptySerialized: { gradient: { stops: [] } },

    updateValueOfColorDescriptors(fill, shape) {
        return this.doActionOnFillColor(fill, ColorValueDescriptor.updateValueOfDescriptor, shape);
    },

    normalizeAlpha(fill) {
        return this.doActionOnFillColor(fill, ColorValueDescriptor.normalizeAlpha);
    },

    forceOpacity(fill, opacity) {
        return this.doActionOnFillColor(fill, ColorValueDescriptor.forceOpacity, opacity);
    },

    updateColorPresets(fill, { presets, scheme } = {}) {
        return this.doActionOnFillColor(
            fill,
            ColorValueDescriptor.updateColorPresets.bind(ColorValueDescriptor),
            { presets, scheme }
        );
    },

    doActionOnFillColor(fill, action, ...args) {
        return doActionOnFillColor(fill, 'type', action, ...args);
    },

    createSolidFill(colorValueDescriptor) {
        return {
            type: 'color',
            color: colorValueDescriptor
        };
    },

    getFirstColorValueDescriptor(fill) {
        let firstColorValueDescriptor = null;
        this.doActionOnFillColor(fill, colorValueDescriptor => {
            if (!firstColorValueDescriptor) {
                firstColorValueDescriptor = colorValueDescriptor;
            }
        });

        return firstColorValueDescriptor;
    },

    extractRGBAFromFirstDecsriptor(fill, shape) {
        const descriptor = this.getFirstColorValueDescriptor(fill);
        if (descriptor) {
            return ColorValueDescriptor.extractRBGAFromDescriptor(descriptor, shape);
        }
        return undefined;
    }
};
