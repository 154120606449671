const isNil = require('lodash/isNil');
const isEqual = require('lodash/isEqual');

const StrokeFill = require('./StrokeFill');
const StrokeFillClass = require('./StrokeFill/StrokeFill');
require('./StrokeFill/StrokeFillLoader.js');
const Dash = require('./Dash.js');
const StrokeEnd = require('./StrokeEnd.js');
const Join = require('./Join.js');
const { dashArrayGenerator } = require('../../fabric-adapter/utilities/dash');
const Fill = require('../Fill');

class Stroke {
    static fromJSON(jsonObject = {}) {
        if (jsonObject) {
            const type = (jsonObject.fill && jsonObject.fill.type);

            return new Stroke(
                jsonObject.align,
                jsonObject.cap,
                jsonObject.compoundType,
                (jsonObject.dash && !isEqual(jsonObject.dash, Dash.emptySerialized) ?
                    Dash.fromJSON(jsonObject.dash) :
                    undefined),
                ((type && !isEqual(jsonObject.fill, Fill.emptySerialized)) ?
                    StrokeFill.strokeFillConstructor[type].fromJSON(jsonObject.fill || {}) :
                    undefined),
                jsonObject.head && StrokeEnd.fromJSON(jsonObject.head, 'head'),
                jsonObject.join && Join.fromJSON(jsonObject.join),
                jsonObject.tail && StrokeEnd.fromJSON(jsonObject.tail, 'tail'),
                jsonObject.width,
                jsonObject.opacity,
                jsonObject.styleId
            );
        }
        return new Stroke();
    }

    static lineCapFromFabric(value) {
        switch (value) {
            case 'round':
            case 'square':
                return value;
            case 'butt':
                return 'flat';
            default:
                return '';
        }
    }

    static lineCapToFabric(value) {
        switch (value) {
            case 'round':
            case 'square':
                return value;
            case 'flat':
                return 'butt';
            default:
                return '';
        }
    }

    static isVisible(fill) {
        return fill.type !== 'none';
    }

    static fromFabric(fabricObject) {
        return new this(
            fabricObject.strokeAlign,
            Stroke.lineCapFromFabric(fabricObject.strokeLineCap),
            fabricObject.strokeCompoundType,
            Dash.fromJSON({ type: fabricObject.strokeDashName }),
            StrokeFill.strokeFillConstructor[fabricObject.strokeFill.type]
                .fromJSON(fabricObject.strokeFill || {}),
            StrokeEnd.fromJSON(fabricObject.strokeHead, 'head'),
            new Join(fabricObject.strokeLineJoin, fabricObject.strokeMiterLimit),
            StrokeEnd.fromJSON(fabricObject.strokeTail, 'tail'),
            fabricObject.strokeWidth,
            fabricObject.styleId
        );
    }

    static get properties() {
        return [
            ...super.properties,
            'align',
            'cap',
            'compoundType',
            'dash',
            'fill',
            'head',
            'join',
            'tail',
            'width'
        ];
    }

    static get atomicProperties() {
        return [
            'dash',
            'fill',
            'head',
            'join',
            'tail'
        ];
    }

    static get Dash() {
        return Dash;
    }

    constructor(
        align,
        cap,
        compoundType,
        dash,
        fill,
        head,
        join,
        tail,
        width,
        opacity,
        styleId
    ) {
        this.align = align;
        this.cap = cap;
        this.compoundType = compoundType;
        this.dash = dash && Dash.fromJSON(dash);
        this.fill = fill;
        this.head = head && StrokeEnd.fromJSON(head);
        this.join = join;
        this.tail = tail && StrokeEnd.fromJSON(tail);
        this.width = width;
        if (!isNil(opacity)) {
            this.opacity = opacity;
        }
        this.styleId = styleId;
    }

    toJSON() {
        const serializedStroke = {
            align: this.align,
            cap: this.cap,
            compoundType: this.compoundType,
            width: this.width
        };
        if (this.dash && this.dash.toJSON) {
            serializedStroke.dash = this.dash.toJSON();
        }
        if (this.fill) {
            if (this.fill instanceof StrokeFillClass) {
                serializedStroke.fill = this.fill.toJSON();
            } else if (typeof this.fill === 'object') {
                serializedStroke.fill = this.fill;
            } else {
                throw new Error('Trying to serialize a stroke fill that doesn\'t inherit from StrokeFill');
            }
        }
        if (this.head && this.head.toJSON) {
            serializedStroke.head = this.head.toJSON();
        }
        if (this.join && this.join.toJSON) {
            serializedStroke.join = this.join.toJSON();
        }
        if (this.tail && this.tail.toJSON) {
            serializedStroke.tail = this.tail.toJSON();
        }
        serializedStroke.opacity = this.opacity;
        serializedStroke.styleId = this.styleId;
        return serializedStroke;
    }

    toFabric() {
        /* *
         * TODO: stroke dash
         * */
        return {
            strokeAlign: this.align,
            // Commented because lineCap property causes rendering errors
            strokeLineCap: Stroke.lineCapToFabric(this.cap),
            strokeCompoundType: this.compoundType,
            strokeDashArray: dashArrayGenerator(
                this.dash.type,
                this.width,
                this.dash.dashStops,
                this.cap
            ),
            strokeDashName: this.dash.type,
            strokeFill: this.fill instanceof StrokeFillClass ? this.fill.toJSON() : this.fill,
            strokeHead: this.head,
            strokeLineJoin: this.join.type,
            strokeMiterLimit: this.join.miterLimit,
            strokeTail: this.tail,
            strokeWidth: Stroke.isVisible(this.fill) ? this.width : 0,
            styleId: this.styleId
        };
    }

    copy() {
        return new Stroke(
            this.align,
            this.cap,
            this.compoundType,
            this.dash,
            this.fill,
            this.head,
            this.join,
            this.tail,
            this.width,
            this.styleId
        );
    }

    get fill() {
        if (!this.canvasItem) {
            throw new Error('Can\'t get fill without canvasItem');
        }
        return Fill.forceOpacity(
            Fill.updateValueOfColorDescriptors(this._fill, this.canvasItem),
            this.opacity
        );
    }

    set fill(fill) {
        if (!isNil(fill)) {
            this._fill = fill;
            let opacity = 1;
            const rgba = Fill.extractRGBAFromFirstDecsriptor(fill, this.canvasItem);
            if (rgba && !isNil(rgba.alpha)) {
                opacity = rgba.alpha;
            }
            this.opacity = opacity;
        }
    }

    get head() {
        return this._head;
    }

    set head(head) {
        if (head && head.constructor.name !== 'StrokeEnd') {
            throw new Error('Stroke head should be of type StrokeEnd.');
        }
        this._head = head;
    }

    get tail() {
        return this._tail;
    }

    set tail(tail) {
        if (tail && tail.constructor.name !== 'StrokeEnd') {
            throw new Error('Stroke tail should be of type StrokeEnd.');
        }
        this._tail = tail;
    }

    get dash() {
        return this._dash;
    }

    set dash(dash) {
        if (dash && dash.constructor.name !== 'Dash') {
            throw new Error('Stroke dash should be of type Dash.');
        }
        this._dash = dash;
    }

    get opacity() {
        return this._opacity;
    }

    set opacity(opacity) {
        if (!isNil(opacity)) {
            this._opacity = opacity;
        }
    }
}

module.exports = Stroke;
