const isFinite = require('lodash/isFinite');
const CommandMixin = require('./Command');

const commandChar = 'l';
const commandRegexp = new RegExp(`^(${
    commandChar
})${
    CommandMixin.regexp.decimalCapture.repeat(2)
}$`, 'i');

class LineCommand extends CommandMixin {
    constructor({ x, y, ...rest }) {
        if (!isFinite(x) || !isFinite(y)) {
            throw new TypeError('Line command expects x and y to be numbers');
        }
        super({
            x, y, commandChar: 'l', ...rest
        });
    }

    static fromString(string) {
        const commandData = string.match(commandRegexp);
        if (!commandData) {
            throw new TypeError('invalid line command');
        }
        const [command, x, y] = commandData.slice(1);
        const relative = command === 'l';
        return new this({ relative, x: Number(x), y: Number(y) });
    }

    static fromArray(array) {
        return new this({
            relative: array[0] === 'l',
            x: parseFloat(array[1]),
            y: parseFloat(array[2])
        });
    }

    toString() {
        return this.toArray().join(' ');
    }

    toArray() {
        return [this.commandChar, this.x, this.y];
    }
}

module.exports = LineCommand;
