/* eslint-disable class-methods-use-this */
import { get } from 'lodash';

export default class PlaceholderValidator {
    validate({ shapes = [] }) {
        const hasSomeInvalidPlaceholders = shapes
            .some(this.isInvalidPlaceholder);

        if (hasSomeInvalidPlaceholders) {
            throw new Error('Invalid Placeholder in shapes');
        }
    }

    isInvalidPlaceholder(shape = {}) {
        const isPlaceholder = !!get(
            shape,
            'modifiers.placeholderType',
            ''
        );

        const inLayout = get(
            shape,
            'modifiers.inLayout',
            false
        );

        return isPlaceholder && !inLayout;
    }
}
