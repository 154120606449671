import {
    getShapePathWithLayer
} from '../../Helpers/helpers';

const toggleLock = (canvasState, command) => command.get('ids')
    .reduce((currentCanvasState, id) => {
        const shapePath = getShapePathWithLayer(canvasState, id);
        return currentCanvasState.setIn(
            [...shapePath, 'isLocked'],
            !currentCanvasState.getIn([...shapePath, 'isLocked'])
        );
    }, canvasState);

export default toggleLock;
