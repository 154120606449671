const StrokeFill = require('./StrokeFill');
const StrokeFillConstructor = require('.');

class NoStrokeFill extends StrokeFill {
    static fromJSON() {
        return new this();
    }

    constructor() {
        super('none');
    }

    toJSON() {
        return super.toJSON();
    }
}

StrokeFillConstructor.addToConstructorList(NoStrokeFill, 'none');
