class PatternColors {
    constructor(background, foreground) {
        this.background = background;
        this.foreground = foreground;
    }

    toJSON() {
        return {
            background: this.background,
            foreground: this.foreground
        };
    }

    static fromJSON(jsonObject) {
        return new this(jsonObject.background, jsonObject.foreground);
    }
}

module.exports = PatternColors;
