const isNil = require('lodash/isNil');
const throttle = require('lodash/throttle');
const { fabric } = require('fabric');
const { changeDpiDataUrl } = require('changedpi');
const get = require('lodash/get');
const has = require('lodash/has');
const {
    getCanvasObjectsByIdsFromArray,
    offsetAndApplyAssetUrlOnFabricObjects,
    inverseItemsOrder,
    replaceCanvasObjectInArray
} = require('../utilities/canvas');
const {
    checkIfTextShape,
    getItemByid,
    getItemsByIdsWithParentGroupsIds,
    getDistanceToAnchor
} = require('./utilities/DecksignFabricCanvasUtilities');
const { extraPropsToExtract } = require('./config/DecksignFabricCanvas');
// eslint-disable-next-line no-unused-vars
const CanvasState = require('../Canvas/CanvasState');
const CanvasStateSelectors = require('../Canvas/CanvasStateSelectors');
const { removeSelectionOverlay } = require('./utilities/Selection/SelectionOverlay');
const { snapToAngle } = require('../utilities/shape');
const CommandTypes = require('../Canvas/commands/types');
const multiParagraphsTextItems = require('./config/multiParagraphsTextItems');
const MultiItemTextbox = require('./DecksignFabricShapeType/MultiItemTextbox');
const { SelectionHighlight, shouldShowHighlight } = require('./DecksignFabricShapeType/SelectionHighlight/SelectionHighlight');
const groupDefinition = require('./definitions/GroupDefinition');
const newGroupDefinition = require('./definitions/NewGroupDefinition');
const TreeNode = require('./DecksignFabricShapeType/Groups/TreeNode');
const { getShapePathWithLayer } = require('../CanvasState/Helpers/helpers');
const { default: fromShapeToFabricJSON } = require('../Adapters/FabricAdapters/ShapeToFabric/fromShapeToFabricJSON');
const { default: fromFabricToCanvasState } = require('../Adapters/FabricAdapters/FabricToCanvasState/fromFabricToCanvasState');
const { getTextBody, getTextBodyPlaceholder } = require('../Adapters/FabricAdapters/FabricToCanvasState/PropertyAdapters/table/content/textBody');

const AnchorSnapRadius = 20;
const shapesWithoutTextEdit = ['table', 'customLine', 'group', 'activeSelection'];

const DecksignFabricCanvasDefinition = {
    modifierKeysPressed: {
        ctrl: false,
        alt: false,
        shift: false,
        meta: false
    },

    offset: {
        left: 0,
        top: 0
    },

    canvasState: CanvasState.initialize([], [], { width: 1280, height: 720 }),

    inSubGroupingProcess: false,

    renderOnAddRemove: false,

    isCanvasStateUpdateLocked: false,

    textSelection: {
        editing: false,
        start: 0,
        end: 0
    },

    styleDefinitions: [],
    defaultBulletStyles: '',
    colorScheme: '',
    selectionHighlight: null,
    cachedFabricObjectsJSON: [],
    currentPageId: null,
    lastEditMode: null,

    initialize(...args) {
        this.callSuper('initialize', ...args);
        this.initEventHandlers();
    },

    initEventHandlers() {
        this.onObjectModified = this.onObjectModified.bind(this);
        this.onTableCellsResized = this.onTableCellsResized.bind(this);
        this.onTableCellsSelection = this.onTableCellsSelection.bind(this);
        this.onMouseDoubleClick = this.onMouseDoubleClick.bind(this);
        this.addEventHandlers = this.addEventHandlers.bind(this);
        this.cleanEventHandlers = this.cleanEventHandlers.bind(this);
        this.onMouseDown = this.onMouseDown.bind(this);
        this.onMouseMove = this.onMouseMove.bind(this);
        this.onMouseUp = this.onMouseUp.bind(this);
        this.mouseDownBefore = this.mouseDownBefore.bind(this);
        this.handleTextSelectionChanged = this.handleTextSelectionChanged.bind(this);
        this.handleTextEditingExited = this.handleTextEditingExited.bind(this);
        this.throttleTextSelectionChanged = throttle(
            this.handleTextSelectionChanged,
            1500,
            { leading: false }
        ).bind(this);

        this.on('after:render', this.addEventHandlers);
        this.on('before:render', this.cleanEventHandlers);
    },

    addEventHandlers() {
        this.cleanEventHandlers();

        this.on('mouse:down', this.onMouseDown)
            .on('mouse:dblclick', this.onMouseDoubleClick)
            .on('object:moving', this.updateHandlers)
            .on('object:scaling', this.updateHandlers)
            .on('object:rotating', this.handleRotationSnap)
            .on('selection:created', this.makeSelection)
            .on('selection:updated', this.makeSelection)
            .on('object:findNearestAnchorToPosition', this.handleFindNearestAnchorToPosition)
            .on('table:cells:resized', this.onTableCellsResized)
            .on('object:modified', this.onObjectModified)
            .on('table:cells:selected', this.onTableCellsSelection)
            .on('text:selection:changed', this.handleTextSelectionChanged)
            .on('text:editing:exited', this.handleTextEditingExited)
            .on('selection:cleared', this.handleSelectionClear)
            .on('mouse:down:before', this.mouseDownBefore)
            .on('mouse:over', this.mouseOver)
            .on('mouse:out', this.mouseOut);
    },

    cleanEventHandlers() {
        this.off('mouse:dblclick', this.onMouseDoubleClick)
            .off('mouse:down', this.onMouseDown)
            .off('object:moving', this.updateHandlers)
            .off('object:scaling', this.updateHandlers)
            .off('object:rotating', this.handleRotationSnap)
            .off('selection:created', this.makeSelection)
            .off('selection:updated', this.makeSelection)
            .off('object:findNearestAnchorToPosition', this.handleFindNearestAnchorToPosition)
            .off('table:cells:resized', this.onTableCellsResized)
            .off('object:modified', this.onObjectModified)
            .off('table:cells:selected', this.onTableCellsSelection)
            .off('selection:cleared', this.handleSelectionClear)
            .off('text:selection:changed', this.handleTextSelectionChanged)
            .off('text:editing:exited', this.handleTextEditingExited)
            .off('mouse:down:before', this.mouseDownBefore)
            .off('mouse:over', this.mouseOver)
            .off('mouse:out', this.mouseOut);
    },

    handleTextSelectionChanged({ target }) {
        this.textSelection = {
            editing: true,
            start: target.selectionStart,
            end: target.selectionEnd
        };
        this.fireDecksignTextSelectionChanged();
    },

    handleTextEditingExited() {
        this.textSelection = {
            editing: false,
            start: 0,
            end: 0
        };
        this.fireDecksignTextSelectionChanged();
    },

    fireDecksignTextSelectionChanged() {
        this.fire('decksign:text:selection:changed', this.textSelection);
    },

    onMouseDoubleClick(e) {
        if (!e.e.shiftKey) {
            this.handleTextEditingEnterEvent(e);
        }
    },

    mouseMove(e) {
        if (this.groupTree && e.target && this.groupTree.shape.id === e.target.id) {
            const selection = this.getSelectionForHighlight();

            if (shouldShowHighlight(this.getActiveObjects(), selection)) {
                if (this.selectionHighlight && this.selectionHighlight.isAlreadyHighlighted(selection)) {
                    return;
                }

                this.remove(this.selectionHighlight);
                this.selectionHighlight = new SelectionHighlight(selection);
                this.insertAt(this.selectionHighlight, this.getObjects().indexOf(e.target));
                this.renderAll();
            }
        }
    },

    mouseOver(e) {
        if (this.useNewImplementation && e.target && e.target.isType('group')) {
            this.on('mouse:move', this.mouseMove);
        }

        if (shouldShowHighlight(this.getActiveObjects(), e.target)) {
            this.selectionHighlight = new SelectionHighlight(e.target);
            this.insertAt(this.selectionHighlight, this.getObjects().indexOf(e.target));
            this.renderAll();
        }
    },

    mouseOut(e) {
        if (this.selectionHighlight !== null) {
            if (e.target && e.target.type === 'cursorZone' && e.nextTarget.id === 'selectionHighlight') {
                return;
            }
            this.off('mouse:move', this.mouseMove);
            this.remove(this.selectionHighlight);
            this.selectionHighlight = null;
            this.renderAll();
        }
    },

    _shouldRender(target) {
        if (this.inSubGroupingProcess) {
            return false;
        }
        return this.callSuper('_shouldRender', target);
    },

    duplicateItem(idsToDuplicate) {
        this.canvasState = CanvasState.update(this.canvasState, {
            type: CommandTypes.DUPLICATE_SHAPES,
            shapes: idsToDuplicate.map(
                id => this.canvasState.getIn(getShapePathWithLayer(this.canvasState, id))
            )
        });
    },

    handleTextEditingEnterEvent(e) {
        if (e.target && this.canEditText(e.target) && checkIfTextShape(e.target)) {
            this.editingText = true;
            e.target.enterTextEditing(e);
        }
    },

    canEditText(target) {
        return target.selectable &&
            !shapesWithoutTextEdit.includes(target.type);
    },

    endTextEdition() {
        this.editingText = false;
    },

    onTableCellsSelection({
        table, cells, e, navigationFromKeyboard = false
    }) {
        if (!navigationFromKeyboard) {
            if (cells.length === 1 &&
                CanvasState.isContextualSelectionActive(this.canvasState, 'cursorZone') &&
                this.canvasState.get('contextualSelection').get('cells').includes(cells[0])
            ) {
                this.handleTextEditingEnterEvent(e);
            }
            if (cells.length > 0) {
                this.canvasState = CanvasState.update(this.canvasState, {
                    type: CommandTypes.SELECT_CELLS,
                    mode: 'cursorZone',
                    ids: [table],
                    cells
                });
                this.fireCanvasStateUpdate();
            } else if (!cells.length) {
                this.exitContextualSelection();
            }
        }
    },

    getMousePagePosition(point) {
        return {
            x: ((point.x - this.viewportTransform[4]) /
                this.getZoom()) - this.offset.left,
            y: ((point.y - this.viewportTransform[5]) /
                this.getZoom()) - this.offset.top
        };
    },

    getPageRelativeBoundingRect(object) {
        const {
            height, left, top, width
        } = object.getBoundingRect(false, true);

        return {
            height,
            left: left - this.offset.left,
            top: top - this.offset.top,
            width
        };
    },

    getTextShapeTransformMatrix(textShape) {
        textShape.set({
            originX: 'right',
            originY: 'bottom'
        });

        const { left, top } = textShape.getTextBounds();
        const textShapeMatrix = textShape.calcTransformMatrix();

        textShapeMatrix[4] += (textShape.width / 2) + left;
        textShapeMatrix[5] += (textShape.height / 2) + top;

        if (textShape.getTextObject()) {
            textShapeMatrix[0] /= (textShape.flipX ? -textShape.scaleX : textShape.scaleX) || 1;
            textShapeMatrix[3] /= (textShape.flipY ? -textShape.scaleY : textShape.scaleY) || 1;
        }

        textShape.set({
            originX: 'center',
            originY: 'center'
        });

        this.set({ originX: 'left', originY: 'top' });

        const transformMatrix = [
            ...fabric.util.multiplyTransformMatrices(this.viewportTransform, textShapeMatrix)
        ];

        this.set({ originX: 'center', originY: 'center' });

        return transformMatrix;
    },

    inverseScaleOfTransformMatrix(transformMatrix) {
        this.set({ originX: 'left', originY: 'top' });

        const inverseTransformScalingMatrix = fabric.util
            .multiplyTransformMatrices(
                this.viewportTransform,
                fabric.util.invertTransform(transformMatrix)
            );

        inverseTransformScalingMatrix[4] = 0;
        inverseTransformScalingMatrix[5] = 0;

        this.set({ originX: 'center', originY: 'center' });

        return inverseTransformScalingMatrix;
    },

    getChildrenAnchors(ignoredTargetid) {
        return this.getObjects()
            .filter(object => object.getShapeObject &&
                !ignoredTargetid.includes(object.getShapeObject().id))
            .map(object => object.getShapeObject())
            .map(shape => Object.keys(get(shape, 'anchors', {}))
                .map(currentAnchor => ({
                    x: shape.anchors[currentAnchor].x,
                    y: shape.anchors[currentAnchor].y,
                    expectedAngles: shape.anchors[currentAnchor].expectedAngles,
                    id: shape.id,
                    name: currentAnchor
                })))
            .reduce((anchorList, currentAnchor) => [...anchorList, ...currentAnchor], []);
    },

    updateHandlers(event) {
        if (checkIfTextShape(event.target)) {
            event.target.updateModificationHandlers();
        }
        this.removeTempActiveSelection();
    },

    handleRotationSnap(event, forceDeltaAngle) {
        const { angle } = event.target;
        const deltaAngle = forceDeltaAngle || (angle - (event.target.lastAngle || angle));
        event.target.rotate(snapToAngle(angle + deltaAngle, this.getZoom()) % 360);
        this.updateHandlers(event);
    },

    cacheAnchorsForCurrentEvent(event) {
        const targetShape = event.target.getShapeObject();
        this.cachedAnchors = this.getChildrenAnchors(
            event.idToIgnore || [targetShape.id]
        );
        const removeEvents = (() => {
            this.cachedAnchors = null;
            window.removeEventListener('mouseup', removeEvents);
            // eslint-disable-next-line no-extra-bind
        }).bind(this);
        window.addEventListener('mouseup', removeEvents);
    },

    handleFindNearestAnchorToPosition(event) {
        if (!this.cachedAnchors) {
            this.cacheAnchorsForCurrentEvent(event);
        }
        const modifiedSnapRadius = (AnchorSnapRadius * AnchorSnapRadius) / this.getZoom();
        const nearestAnchors = getDistanceToAnchor(
            this.cachedAnchors,
            {
                x: event.position.x - this.offset.left,
                y: event.position.y - this.offset.top
            }
        );

        if (nearestAnchors.distPowerTwo < modifiedSnapRadius) {
            event.target.updateWithAnchorZoneDetected(
                nearestAnchors.anchor,
                getItemByid(this.getObjects(), nearestAnchors.anchor.id)
            );
        } else {
            event.target.updateWithoutAnchorZoneDetected({
                x: event.position.x - this.offset.left,
                y: event.position.y - this.offset.top
            });
        }
    },

    makeSelection(e) {
        if (
            !has(e, 'selected.0.id') ||
            this.canvasState.getIn(['contextualSelection', 'table'], '') !==
            get(e, 'selected.0.id')
        ) {
            this.exitContextualSelection();
        }
        if (!(e.e instanceof MouseEvent)) {
            return;
        }
        if (this.groupedShapeIsBeingEdited &&
            e.target.id !== this.groupedShapeBeingEditedInfo.shapeId &&
            !e.target.isType('cursorZone')
        ) {
            this.removeTempActiveSelection();
            this.reattachActiveShapeGroupPath();
            this.groupedShapeBeingEditedInfo = [];
            this.groupedShapeIsBeingEdited = false;
        }
        if (e.target.type === 'activeSelection') {
            this.handleTextEditingExited();
            const activeObjects = this.getActiveObjects();
            const firstSelectedObj = activeObjects.find(obj => obj.editingText);
            if (firstSelectedObj) {
                firstSelectedObj.exitTextEditing();
            }
            this.bindListenerForSelectionUpdate(e.target.getObjects().map(o => o.id));
        } else if (e.target.type === 'cursorZone' && e.target.table) {
            this.bindListenerForSelectionUpdate([e.target.table.id]);
        } else if (
            !(this.groupedShapeIsBeingEdited &&
                e.target.id !== this.groupedShapeBeingEditedInfo.shapeId)
        ) {
            e.selected.forEach(shape => {
                if (shape.renderModificationHandlers instanceof Function) {
                    shape.renderModificationHandlers();
                }
            });
            this.requestRenderAll();
            this.bindListenerForSelectionUpdate([e.target.id]);
        } else {
            this.fireCanvasStateUpdate();
        }
    },

    bindListenerForSelectionUpdate(objects, activeShapeParentGroupPath = []) {
        if (!this.useNewImplementation) {
            this.on('mouse:up', () => {
                this.canvasState = CanvasState.update(
                    this.canvasState,
                    {
                        type: 'UPDATE_SELECTION',
                        selection: objects.reverse(),
                        selectedShapesParentGroupsPath: activeShapeParentGroupPath.reverse()
                    }
                );
                if (objects.length !== 1 || ((objects[0] || {}).type || '').toLowerCase() !== 'table') {
                    this.fireCanvasStateUpdate();
                }
                this.off('mouse:up');
            });
        }
    },

    setActiveObject(target, e) {
        const isClick = (!this._groupSelector || (this._groupSelector.left === 0 && this._groupSelector.top === 0));
        if (target && target.isType('cursorZone')) {
            return undefined;
        }
        let targetIndexInPath = get(this, 'groupedShapeBeingEditedInfo.groupsPath', [])
            .findIndex(groupId => target.id === groupId);
        if (targetIndexInPath >= 0) {
            const numberOfSublevel = get(this, 'groupedShapeBeingEditedInfo.groupsPath', []).length;
            this.off('selection:updated', this.makeSelection);
            while (targetIndexInPath < numberOfSublevel) {
                this.selectSuperiorGroupLevel();
                targetIndexInPath++;
            }
            this.on('selection:updated', this.makeSelection);
            return undefined;
        }
        const isTargetGroup = target.type === 'group' &&
            e &&
            this._searchPossibleTargets([target], this.getPointer(e, true)) &&
            this.getSubtargetShapes().length === 0 &&
            isClick;
        if (isTargetGroup) {
            return undefined;
        }
        this.remove(this.selectionHighlight);
        return this.callSuper('setActiveObject', target, e);
    },

    _isSelectionKeyPressed(e) {
        if (!isNil(this.groupedShapeBeingEditedInfo)) {
            return false;
        }
        return this.callSuper('_isSelectionKeyPressed', e);
    },

    _shouldClearSelection(e, target) {
        if (target &&
            target.type === 'group' &&
            this.getSubtargetShapes().length === 0 &&
            !target.__corner) {
            return true;
        }
        if (target && target.type === 'modificationHandler') {
            return false;
        }
        return this.callSuper('_shouldClearSelection', e, target);
    },

    countBackgroundShapes() {
        return this.getObjects().filter(shape => shape.isBackground).length;
    },

    handleSelectionClear(event) {
        if (this.isAddingShapes || this.isLoadingFromState) {
            return;
        }
        if (
            this.groupedShapeIsBeingEdited &&
            !event.target
        ) {
            const target = this.findTarget(event);
            if (target && target.isType('cursorZone')) {
                return;
            }
            this.removeTempActiveSelection();

            const point = this.getPointer(event);
            let revertToParent = false;
            while (get(this, 'groupedShapeBeingEditedInfo.groupsPath', []).length > 0) {
                this.selectSuperiorGroupLevel();
                if (this.getActiveObject().containsPoint(point)) {
                    revertToParent = true;
                    break;
                }
            }
            if (revertToParent === false) {
                this.discardActiveObject();
            }
        } else if (event.deselected) {
            if (!event.target) {
                this.removeTempActiveSelection();
            }
            this.canvasState = CanvasState.update(
                this.canvasState,
                {
                    type: 'UPDATE_SELECTION',
                    selection: [],
                    selectedShapesParentGroupsPath: []
                }
            );
            this.fireCanvasStateUpdate();
        }
    },

    selectSuperiorGroupLevel() {
        this.removeTempActiveSelection();
        this.reattachActiveShapeGroupPath();
        this.setLastParentGroupAsActiveShape();

        if (this.groupedShapeIsBeingEdited) {
            this.reselectActiveShape();
        } else {
            const activeShape = this.getObjects()
                .find(shape => shape.id === this.groupedShapeBeingEditedInfo.shapeId);
            if (activeShape) {
                this.setActiveObject(activeShape);
                this.renderAll();
                this.bindListenerForSelectionUpdate([activeShape.id]);
            }
        }
    },

    wasCanvasItemModified(ids = [], canvasItem) {
        if (ids.includes(canvasItem.id)) return true;

        if (canvasItem.type === 'Group') {
            const groupTree = TreeNode.generateTree(canvasItem);
            return ids.some(id => TreeNode.findNode(groupTree, id));
        }

        return false;
    },

    loadCanvasFromJSON(fabricObjectsJSON, cb) {
        try {
            this.cachedFabricObjectsJSON = fabricObjectsJSON;
            this.currentPageId = CanvasStateSelectors.getPageId(this.canvasState);
            this.loadFromJSON({ objects: fabricObjectsJSON }, cb);
        } catch (e) {
            cb(e);
        }
    },

    renderContextualSelection() {
        if (this.canvasState.get('contextualSelection')) {
            const contextualSelection = this.canvasState.get('contextualSelection').toJS();
            if (contextualSelection.mode === 'cursorZone') {
                this.removeSelectionOverlay();
                const table = this
                    .getObjects()
                    .find(obj => obj.id === contextualSelection.table);
                const cells = contextualSelection.cells
                    .map(cellId => table.cells[cellId]);
                cells.forEach(cell => table.addCellToSelection(cell, false));
            }
        }
    },

    renderCursorZonesOnTables() {
        this._objects.forEach(shape => {
            if (shape.isType('table')) {
                shape.onAdded();
            }
        });
    },

    toPNG(options = {}) {
        return options.dpi ? changeDpiDataUrl(this.toDataURL(options), options.dpi) : this.toDataURL(options);
    },

    toPNGAsync(options = {}) {
        return new Promise(resolve => {
            this.clone(clone => {
                clone.getObjects()
                    .forEach(obj => {
                        obj.displayPlaceholder = false;
                        if (obj.setTextItemsVisibility instanceof Function) {
                            obj.setTextItemsVisibility();
                        }
                    });
                const png = clone.toDataURL(options);
                clone.dispose();
                return resolve(png);
            });
        });
    },

    setModifierKeyState(key, state) {
        this.modifierKeysPressed[key] = state;
    },

    reorderBackgrounds() {
        this.sendToBack(
            this.getObjects()
                .find(shape => shape.isBackground && shape.sendToBack)
        );
    },

    clearToCanvasState() {
        this.loadFromCanvasState(this.canvasState, {
            ...this.lastOptionsCanvasState,
            isEditingText: this.isEditingText()
        });
    },

    isEditingText() {
        const activeObject = this.getActiveObject();
        if (activeObject) {
            if (activeObject.isType('table')) {
                return activeObject.getEditingCell() !== undefined;
            }
            return activeObject.editing || false;
        }
        return false;
    },

    createSelectionObject(canvasState) {
        const selection = getItemsByIdsWithParentGroupsIds(
            this.getObjects(),
            canvasState.get('selection') && canvasState.get('selection').toJS(),
            canvasState.get('selectedShapesParentGroupsPath') && canvasState.get('selectedShapesParentGroupsPath').toJS()
        );
        let selectionObject = selection[0];
        if (selection.length > 1) {
            selectionObject = new fabric.ActiveSelection([], { canvas: this });
            this.setActiveObject(selectionObject);
            selection.forEach(object => selectionObject.addWithUpdate(object));
        }
        return selectionObject;
    },

    handleSelection(canvasState) {
        if (!this.useNewImplementation && this.shouldReattachPreviousShape(canvasState)) {
            this.reattachActiveShapeGroupPath();
            this.removeTempActiveSelection();
        }
        const selectionObject = this.createSelectionObject(canvasState);
        if (selectionObject) {
            this.off('selection:created', this.makeSelection)
                .off('selection:updated', this.makeSelection);
            if (this.isSelectionInGroup(canvasState)) {
                this.groupedShapeBeingEditedInfo = {
                    groupsPath: canvasState.get('selectedShapesParentGroupsPath').toJS()[0],
                    shapeId: selectionObject.id
                };
                this.reselectActiveShape();
            } else {
                this.groupedShapeIsBeingEdited = false;
                this.groupedShapeBeingEditedInfo = null;
                if (selectionObject.renderModificationHandlers instanceof Function) {
                    selectionObject.renderModificationHandlers();
                }
                this.setActiveObject(selectionObject);
            }
            this.on('selection:created', this.makeSelection)
                .on('selection:updated', this.makeSelection);
            this.renderContextualSelection();
            if (this.textSelection.editing === true) {
                this.getActiveObject().renderTextSelection(this.textSelection);
                this.removeSelectionOverlay();
            }
        } else if (!selectionObject) {
            this.groupedShapeIsBeingEdited = false;
            this.groupedShapeBeingEditedInfo = null;
            this.discardActiveObject();
        }
    },

    validatePreviousEditMode() {
        if (!this.lastEditMode) {
            this.lastEditMode = this.getEditMode();
        } else if (this.lastEditMode && this.lastEditMode !== this.getEditMode()) {
            this.cachedFabricObjectsJSON = [];
        }
    },

    convertCanvasStateShapesToFabricJSON(shapes, canvasState) {
        return shapes.map(shape => fromShapeToFabricJSON(shape, canvasState)).toJS();
    },

    getFabricObjectsJSON(canvasState) {
        const shapesToRender = CanvasStateSelectors.getShapesToRender(canvasState);

        const fabricJsonFromCanvasStateShapes = this.convertCanvasStateShapesToFabricJSON(
            shapesToRender,
            canvasState
        );

        this.validatePreviousEditMode();

        return Promise.resolve(fabricJsonFromCanvasStateShapes);
    },

    setDecksignCanvasAttributes(canvasState, options) {
        this.canvasState = canvasState;
        const {
            colorScheme,
            defaultBulletStyles,
            defaultBulletList,
            defaultNumberedList,
            styleDefinitions
        } = options;

        this.colorScheme = colorScheme;
        this.defaultBulletStyles = defaultBulletStyles;
        this.defaultBulletListPreset = defaultBulletList;
        this.defaultNumberedListPreset = defaultNumberedList;
        this.styleDefinitions = styleDefinitions;
        this.cleanEventHandlers();
    },

    loadFromCanvasState(canvasState, options = {}, resetCanvasCache = false) {
        this.lastOptionsCanvasState = options;
        if (!CanvasState.isCanvasState(canvasState)) {
            return Promise.reject(new Error('canvasState needs to be a CanvasState'));
        }

        const isOnlyUpdatingSelection = CanvasState.isOnlyUpdatingSelection({
            previous: this.canvasState,
            next: canvasState
        });

        if (!canvasState.get('groupTree')) {
            this.groupTree = undefined;
        }

        this.setDecksignCanvasAttributes(canvasState, options);

        const activeObject = this.getActiveObject();

        if (activeObject?.isType('table')) {
            const editingCell = activeObject.getEditingCell();
            if (editingCell) {
                editingCell.exitEditing();
            }
        }

        this.isLoadingFromState = true;

        return (isOnlyUpdatingSelection ?
            Promise.resolve() :
            new Promise((resolve, reject) => {
                this.getFabricObjectsJSON(
                    canvasState,
                    {
                        ...options,
                        resetCanvasCache
                    }
                ).then(fabricObjectsJSON => this.loadCanvasFromJSON(
                    inverseItemsOrder(offsetAndApplyAssetUrlOnFabricObjects(fabricObjectsJSON, options)),
                    err => {
                        if (err) {
                            return reject(err);
                        }
                        this.renderCursorZonesOnTables();
                        return resolve();
                    }
                ));
            })
        )
            .then(() => {
                if (options.textSelection) {
                    this.textSelection = options.textSelection;
                }

                this.handleSelection(
                    canvasState
                );

                this.isLoadingFromState = false;
            })
            .then(() => {
                this.handleLayers();
                this.addEventHandlers();
            });
    },

    onObjectModified({ target, activeObject }) {
        this.handleObjectModified(target, activeObject);
    },

    handleObjectModified(target, activeObject = null) {
        if (target.type === 'multiItemTextbox') {
            return;
        }

        const currentActiveObject = activeObject || this.getActiveObject();

        let shouldClearToCanvasState = false;
        const targetIsScaled = targetObject => (targetObject.scaleX && (targetObject.scaleX !== 1)) ||
            (targetObject.scaleY && (targetObject.scaleY !== 1));
        const targetWasScaled = targetIsScaled(target);
        new Promise(resolve => {
            if (target && targetWasScaled) {
                if (target.isType('table') || target.isType('Path')) {
                    shouldClearToCanvasState = true;
                }
                resolve(target.updateScale(target.scaleX, target.scaleY));
            } else {
                resolve();
            }
        })
            .then(() => {
                if (target.isType('table') && targetWasScaled) {
                    target.lockMovement();
                }
                target.dirty = true;
            })
            .then(() => {
                this.requestRenderAll();
                target.calcCoords();
                let fabricObjects = [];
                if (target.isType('activeSelection')) {
                    if (target.group) {
                        fabricObjects = [
                            this.groupTree.shape.toObject(extraPropsToExtract),
                            ...target.getObjects()
                                .filter(obj => !this.isGroupChild(obj))
                                .map(obj => {
                                    const absolutePosition = obj.getAbsolutePosition();
                                    return {
                                        ...obj.toObject(extraPropsToExtract),
                                        ...absolutePosition
                                    };
                                })
                        ];
                    } else {
                        fabricObjects = target
                            .getAbsolutePositionFabricObjects(extraPropsToExtract);
                    }
                } else if (target.group && currentActiveObject?.type !== 'table') {
                    fabricObjects = [
                        this.groupTree.shape.toObject(extraPropsToExtract),
                        target.toObject(extraPropsToExtract)
                    ];
                } else if (currentActiveObject?.type === 'table') {
                    fabricObjects = [currentActiveObject.toObject(extraPropsToExtract)];
                } else {
                    fabricObjects = [target.toObject(extraPropsToExtract)];
                }

                this.updateCanvasStateShape(target, fabricObjects, shouldClearToCanvasState);
            });
    },

    updateCanvasStateShape(target, fabricObjects, shouldClearToCanvasState) {
        const shapesCountBeforeUpdate = CanvasState.countAllShapes(this.canvasState);
        this.canvasState = CanvasState.update(this.canvasState, {
            type: CommandTypes.UPDATE_SHAPES,
            shapes: fabricObjects
                .map(fabricObject => fromFabricToCanvasState(fabricObject, this.canvasState))
        });
        const shapesCountAfterUpdate = CanvasState.countAllShapes(this.canvasState);
        this.cachedFabricObjectsJSON = this.cachedFabricObjectsJSON.map(obj => {
            const updatedCanvasItem = fabricObjects
                .find(updatedObject => updatedObject.id === obj.id);
            if (updatedCanvasItem) {
                return inverseItemsOrder([updatedCanvasItem]).pop();
            }
            return obj;
        });
        if (
            shapesCountBeforeUpdate !== shapesCountAfterUpdate ||
            target.duplicating ||
            shouldClearToCanvasState
        ) {
            this.clearToCanvasState();
        }
        this.fireCanvasStateUpdate();
        if (this.useNewImplementation) {
            this.requestRenderAll();
        } else if (this.groupedShapeIsBeingEdited && !this.editingText) {
            this.reselectActiveShape();
        }
    },

    getEditMode() {
        return this.canvasState.get('editMode');
    },

    handleVisibility() {
        this.getObjects()
            .filter(object => {
                switch (this.getEditMode()) {
                    case 'Page':
                        return true;
                    case 'Layout':
                        return object.isDisplayedInLayoutEditMode();
                    case 'Deck':
                        return true;
                    default:
                        return false;
                }
            });
    },

    handleSelectability() {
        this.getObjects()
            .filter(object => {
                switch (this.getEditMode()) {
                    case 'Page':
                        if (object.isBackground) {
                            object.hoverCursor = 'default';
                        }
                        return object.inLayout && !object.isPlaceholder();
                    case 'Layout':
                        return !object.isDisplayedInLayoutEditMode();
                    case 'Deck':
                        return true;
                    default:
                        return true;
                }
            })
            .forEach(ob => {
                ob.selectable = false;
                ob.hoverCursor = (ob.inLayout && this.getEditMode() === 'Page') || ob.isBackground ? 'default' : 'auto';
            });
        this.getObjects()
            .filter(ob => !ob.inLayout).forEach(object => {
                if (!object.selectable) {
                    if (object.shapeType === 'Group') { this.groupObject(object); }
                } else if (object.shapeType === 'Group') this.groupObject(object);
                else object.hoverCursor = 'move';
            });
    },

    handleLayers() {
        this.handleVisibility();
        this.handleSelectability();
    },

    onTableCellsResized({
        target, columnWidths, definedRowHeights
    }) {
        let { canvasState } = this;
        if (!this.isEditingText()) {
            this.exitContextualSelection();
            this.off('mouse:up');
            canvasState = CanvasState.update(this.canvasState, {
                type: 'UPDATE_SELECTION',
                selection: [target.id].reverse(),
                activeShapeParentGroupPath: [],
                selectedShapesParentGroupsPath: canvasState.get('selectedShapesParentGroupsPath')
            });
        }

        this.canvasState = CanvasState.update(canvasState, {
            type: CommandTypes.UPDATE_SHAPES_PROPERTIES,
            ids: [target.id],
            properties: {
                rowHeights: definedRowHeights,
                columnWidths,
                definedRowHeights
            }
        });

        this.clearToCanvasState();

        this.fireCanvasStateUpdate();
    },

    getObjectById(id) {
        return this.getObjects().find(object => object.id === id);
    },

    getRootObjectIds() {
        return this.getObjects().map(object => object.id);
    },

    undoOffset(obj) {
        let customOffset = {};
        if (obj.type === 'line') {
            customOffset = [1, 2].reduce((offset, point) => ({
                ...offset,
                [`x${point}`]: obj[`x${point}`] - this.offset.left,
                [`y${point}`]: obj[`y${point}`] - this.offset.top
            }), {});
        }
        return {
            ...obj,
            left: (obj.left || 0) - this.offset.left,
            top: (obj.top || 0) - this.offset.top,
            ...customOffset
        };
    },

    getSelectedObjectsByIds(selectedObjectsIds, canvasItems) {
        return getCanvasObjectsByIdsFromArray(selectedObjectsIds, canvasItems);
    },

    isShapeNotInAGroup(shapeId, canvasItems) {
        return canvasItems.find(({ id }) => shapeId === id) !== undefined;
    },

    insertEditedTextShapeInShapesArray(shapes, editedTextShape) {
        return replaceCanvasObjectInArray(editedTextShape, shapes);
    },

    exitContextualSelection() {
        if (!this.canvasState.get('contextualSelection')) {
            return;
        }
        if (!this.keepContextualSelection) {
            this.removeSelectionOverlay();
            this.canvasState = CanvasState.update(this.canvasState, {
                type: CommandTypes.EXIT_CONTEXTUAL_SELECTION
            });
            this.keepContextualSelection = false;
        }
    },

    removeSelectionOverlay() {
        this.getObjectsByType('table')
            .forEach(table => {
                table.getSelectedCells()
                    .forEach(cell => removeSelectionOverlay(cell));
            });
    },

    getObjectsByType(type) {
        return this.getObjects()
            .filter(obj => obj.type === type);
    },

    getShapeIndex(shapeName) {
        return this.getObjects()
            .findIndex(({ name }) => shapeName === name);
    },

    getShapeIndexFromId(shapeId) {
        return this.getObjects()
            .findIndex(({ id }) => shapeId === id);
    },

    addShapesAtIndex(shapes, index) {
        this.isAddingShapes = true;
        /**
         * It is faster to clear canvas order objects properly
         * and then re-add all objects in the right order than to
         * to do repetitive multiple insert at index.
         * This is especially the case when:
         * number of cursorZones >> number of objects
         * Objects are only removed from canvas they, don't need
         * the be reinitialized.
         */
        const { renderOnAddRemove } = this;
        this.renderOnAddRemove = false;
        const activeObjects = this.getActiveObjects();
        const objects = [
            ...this._objects.slice(0, index),
            ...shapes,
            ...this._objects.slice(index)
        ];
        this.clear();
        this.add(...objects);
        if (activeObjects.length > 1) {
            const selectionObject = new fabric.ActiveSelection(activeObjects, { canvas: this });
            this.setActiveObject(selectionObject);
        } else if (activeObjects.length === 1) {
            const [selectionObject] = activeObjects;
            this.setActiveObject(selectionObject);
        }
        this.renderOnAddRemove = renderOnAddRemove;
        this.renderAll();
        this.addEventHandlers();
        this.isAddingShapes = false;
    },

    addAtShapeIndex(shapeToAdd, index) {
        this.add(shapeToAdd);
        shapeToAdd.moveTo(index);
    },

    getEmptyCanvasContentLimits(absolute) {
        let { left, top } = this.getCenter();
        if (!absolute) {
            left *= this.viewportTransform[0];
            left += this.viewportTransform[4];

            top *= this.viewportTransform[3];
            top += this.viewportTransform[5];
        }
        return {
            bottom: top,
            left,
            right: left,
            top
        };
    },

    getContentLimits(absolute) {
        if (this.isEmpty()) {
            return this.getEmptyCanvasContentLimits(absolute);
        }
        return this.getObjects()
            .reduce(
                (currentLimit, object) => {
                    const {
                        height,
                        left,
                        top,
                        width
                    } = object.getBoundingRect(absolute);
                    return {
                        bottom: Math.max(currentLimit.bottom, top + height),
                        left: Math.min(currentLimit.left, left),
                        right: Math.max(currentLimit.right, left + width),
                        top: Math.min(currentLimit.top, top)
                    };
                },
                {
                    bottom: -Infinity,
                    left: Infinity,
                    right: -Infinity,
                    top: Infinity
                }
            );
    },

    getContentBoundingRect(absolute) {
        const {
            bottom,
            left,
            right,
            top
        } = this.getContentLimits(absolute);

        return {
            height: bottom - top,
            left,
            top,
            width: right - left
        };
    },

    updateCellContentTextBody({ content, cell, table }) {
        const textBody = getTextBody(table, cell, content, this.canvasState)
            .set('autoFitText', content.autoFitText)
            .set('autoFitShape', content.autoFitShape)
            .set('verticalAlign', content.verticalAlign);
        const textBodyPlaceholder = getTextBodyPlaceholder(table, cell, content, this.canvasState)
            .set('autoFitText', content.autoFitText)
            .set('autoFitShape', content.autoFitShape);

        this.canvasState = CanvasState.update(this.canvasState, {
            type: CommandTypes.UPDATE_CELL_CONTENT_TEXTBODY,
            cellId: cell.id,
            contentId: content.id,
            tableId: table.id,
            textBody,
            textBodyPlaceholder
        });
        this.fireCanvasStateUpdate();
    },

    getOriginalShapeJSON(shapeId) {
        const shapes = CanvasStateSelectors.getFlattenShapes(this.canvasState.get('shapes'))
            .push(...CanvasStateSelectors.getFlattenShapes(this.canvasState.get('layoutShapes')));

        const shape = shapes.find(cursor => cursor.get('id') === shapeId);
        return shape && shape.toJS();
    },

    getSelectedObjects() {
        return CanvasStateSelectors.getSelectedCanvasItems(this.canvasState).toJS();
    },

    addTextItems() {
        if (this.multiParagraphsTextItems !== undefined) {
            this.remove(this.multiParagraphsTextItems);
        }
        this.multiParagraphsTextItems = MultiItemTextbox.fromObject(multiParagraphsTextItems);
        this.multiParagraphsTextItems.left = 0;
        this.multiParagraphsTextItems.top = 0;
        this.add(this.multiParagraphsTextItems);
        this.requestRenderAll();
    },

    _transformObject(e) {
        if (this.getActiveObject()?.editingText === true) {
            return;
        }
        this.callSuper('_transformObject', e);
    },

    fireCanvasStateUpdate() {
        if (!this.isCanvasStateUpdateLocked) {
            setTimeout(() => {
                this.fire('canvas:state:update', this.canvasState);
            }, 0);
        }
    },

    lockCanvasStateUpdate() {
        this.isCanvasStateUpdateLocked = true;
    },

    unlockCanvasStateUpdate() {
        this.isCanvasStateUpdateLocked = false;
        this.fireCanvasStateUpdate();
    },

    getSubtargetShapes() {
        return this.targets.filter(x => x.type !== 'group');
    }
};

module.exports = (useNewImplementation = false) => fabric.util
    .createClass(fabric.Canvas, {
        useNewImplementation,
        ...DecksignFabricCanvasDefinition,
        ...(useNewImplementation ? newGroupDefinition : groupDefinition)
    });
