import React from 'react';
import PropTypes from 'prop-types';
import './LoadableLoading.scss';

const LoadingComponent = ({
    error,
    timedOut,
    pastDelay
}) => {
    if (error) {
        console.error(error);
        return (
            <div className="LoadableLoading">
                <span className="LoadableLoading__text">
                    Error!
                    {' '}
                    {error.message}
                </span>
            </div>
        );
    }

    if (timedOut) {
        return (
            <div className="animatedBg">
                <span className="LoadableLoading__text">
                    Taking a long time...
                </span>
            </div>
        );
    }

    if (pastDelay) {
        return (
            <div className="LoadableLoading">
                <span className="LoadableLoading__text">
                    Loading...
                </span>
            </div>
        );
    }

    return null;
};

LoadingComponent.defaultProps = {
    error: null,
    timedOut: false,
    pastDelay: false
};

LoadingComponent.propTypes = {
    error: PropTypes.object,
    timedOut: PropTypes.bool,
    pastDelay: PropTypes.bool
};

export default LoadingComponent;
