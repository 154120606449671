import { List } from 'immutable';
import { DEFAULT_RUNSTYLE } from '../Text/Config/defaultStyles';

export default item => {
    const runStyles = item.getIn(['textBody', 'runStyles'], List());
    const firstRun = item.getIn(['textBody', 'runs'], List())
        .find(run => run.get('startIndex') === 0);
    return (firstRun && runStyles.get(firstRun.get('style'))) ?
        runStyles.get(firstRun.get('style')) :
        DEFAULT_RUNSTYLE;
};
