const immutable = require('immutable');
const isNumber = require('lodash/isNumber');
const isNil = require('lodash/isNil');
const { DEFAULT_TEXT_BODY_STYLE } = require('../../Shape/AbstractShape/config/defaultStyles');

const getUniqueSetValueOrDefault = (set, defaultValue) => (
    set.size === 1 && isNumber(set.first()) ? set.first() || defaultValue : defaultValue
);

exports.computeMarginsFromTextBodies = textBodies => {
    const [bottomSet, leftSet, rightSet, topSet] = textBodies
        .reduce(([bottom, left, right, top], textBody) => new immutable.List([
            bottom.add(
                isNil(textBody.getIn(['margins', 'bottom'])) ?
                    textBody.getIn(['assignedStyles', 'textBody', 'margins', 'bottom']) :
                    textBody.getIn(['margins', 'bottom'])
            ),
            left.add(
                isNil(textBody.getIn(['margins', 'left'])) ?
                    textBody.getIn(['assignedStyles', 'textBody', 'margins', 'left']) :
                    textBody.getIn(['margins', 'left'])
            ),
            right.add(
                isNil(textBody.getIn(['margins', 'right'])) ?
                    textBody.getIn(['assignedStyles', 'textBody', 'margins', 'right']) :
                    textBody.getIn(['margins', 'right'])
            ),
            top.add(
                isNil(textBody.getIn(['margins', 'top'])) ?
                    textBody.getIn(['assignedStyles', 'textBody', 'margins', 'top']) :
                    textBody.getIn(['margins', 'top'])
            )
        ]), new immutable.List([
            new immutable.Set(),
            new immutable.Set(),
            new immutable.Set(),
            new immutable.Set()
        ]));

    return {
        bottom: getUniqueSetValueOrDefault(
            bottomSet,
            DEFAULT_TEXT_BODY_STYLE.margins.bottom
        ),
        left: getUniqueSetValueOrDefault(
            leftSet,
            DEFAULT_TEXT_BODY_STYLE.margins.left
        ),
        right: getUniqueSetValueOrDefault(
            rightSet,
            DEFAULT_TEXT_BODY_STYLE.margins.right
        ),
        top: getUniqueSetValueOrDefault(
            topSet,
            DEFAULT_TEXT_BODY_STYLE.margins.top
        )
    };
};
