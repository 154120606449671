const isString = require('lodash/isString');
const { SMALL_CAPS_RATIO, TAB_TO_SPACES } = require('../constants/text');

const shouldCapitalizeChar = style => ['smallCaps', 'allCaps'].includes(style.textTransform);

const getCapitalizedCharacter = (char, style) => (
    shouldCapitalizeChar(style) ?
        char.toUpperCase() :
        char
);

const enforceFontFamilyInCharStyle = style => (
    isString(style?.fontFamily) ?
        style :
        {
            ...style,
            fontFamily: ''
        }
);

const enforceSmallCapsFontSize = (char, style) => {
    if (style?.textTransform === 'smallCaps' && style?.fontSize && /[a-z]/.test(char || '')) {
        return {
            ...style,
            fontSize: style.fontSize * SMALL_CAPS_RATIO
        };
    }

    return style;
};

module.exports = fabric => {
    fabric.Text.prototype._styleProperties = [...fabric.Text.prototype._styleProperties, 'textTransform'];

    fabric.Text.prototype._measureChar = (function measureCharOuterFunction(measureChar) {
        return function measureCharInnerFunction(_char, charStyle, previousChar, prevCharStyle) {
            if (this.fontFamily === null) {
                this.fontFamily = '';
            }
            const char = getCapitalizedCharacter(_char, charStyle);

            if (char === '\t') {
                const size = measureChar.call(
                    this,
                    char,
                    enforceFontFamilyInCharStyle(enforceSmallCapsFontSize(_char, charStyle)),
                    previousChar,
                    enforceFontFamilyInCharStyle(enforceSmallCapsFontSize(_char, prevCharStyle))
                );

                size.width *= TAB_TO_SPACES;
                size.kernedWidth *= TAB_TO_SPACES;

                return size;
            }

            return measureChar.call(
                this,
                char,
                enforceFontFamilyInCharStyle(enforceSmallCapsFontSize(_char, charStyle)),
                previousChar,
                enforceFontFamilyInCharStyle(enforceSmallCapsFontSize(_char, prevCharStyle))
            );
        };
    }(fabric.Text.prototype._measureChar));

    fabric.Text.prototype._hasStyleChanged = (function hasStyleChangedOuterFunction(hasStyleChanged) {
        return function hasStyleChangedInnerFunction(prevStyle, thisStyle) {
            const hasTextTransformChanged = prevStyle.textTransform !== thisStyle.textTransform;
            return hasStyleChanged.call(this, prevStyle, thisStyle) ||
                hasTextTransformChanged;
        };
    }(fabric.Text.prototype._hasStyleChanged));

    /*
        This was taken from fabric but added condition to prevent trying to access undefined style for character
    */
    fabric.Text.prototype.styleHas = function styleHas(property, lineIndex) {
        if (!this.styles || !property || property === '') {
            return false;
        }
        if (typeof lineIndex !== 'undefined' && !this.styles[lineIndex]) {
            return false;
        }
        const obj = typeof lineIndex === 'undefined' ? this.styles : { 0: this.styles[lineIndex] };
        // eslint-disable-next-line
        for (var p1 in obj) {
            // eslint-disable-next-line
            for (var p2 in obj[p1]) {
                if (typeof obj[p1][p2] !== 'undefined' && typeof obj[p1][p2][property] !== 'undefined') {
                    return true;
                }
            }
        }
        return false;
    };
};
