import { List } from 'immutable';

const intersection = require('lodash/intersection');
const { DEFAULT_TABLE_SIZE_SETTINGS } = require('../../../Table/config/defaultTableAttributes.config.js');

const assignSingleCellToGrid = (cell, grid) => {
    const startRow = cell.get('row');
    const endRow = startRow + cell.get('rowSpan');
    const startColumn = cell.get('column');
    const endColumn = startColumn + cell.get('columnSpan');
    let updatedGrid = grid;
    for (let i = startRow; i < endRow; i++) {
        updatedGrid = updatedGrid.set(i, updatedGrid.get(i) || List());
        for (let j = startColumn; j < endColumn; j++) {
            updatedGrid = updatedGrid.setIn([i, j], cell.get('id'));
        }
    }
    return updatedGrid;
};

const assignCellsToGrid = (originalGrid, table) => (table.get('cells') || List())
    .reduce(
        (grid, cell) => assignSingleCellToGrid(cell, grid),
        originalGrid
    );

const getTableWithGrid = table => table
    .set('cellGrid', generateCellGrid(table))
    .set('horizontalBorders', generateHorizontalBorderGrid(table))
    .set('verticalBorders', generateVerticalBorderGrid(table));

const removeGridFromTable = table => table
    .remove('cellGrid')
    .remove('horizontalBorders')
    .remove('verticalBorders');

const generateCellGrid = table => {
    let cellGrid = List();
    for (let row = 0; row < table.get('rowHeights').size; row++) {
        cellGrid = cellGrid.set(row, List().setSize(table.get('columnWidths').size));
    }
    return assignCellsToGrid(cellGrid, table);
};

const generateDefaultRowHeights = (table, nbRows = 1) => {
    const maxTableHeight = table.getIn(['settings', 'maxTableHeight']) ||
        DEFAULT_TABLE_SIZE_SETTINGS.maxTableHeight;
    if ((maxTableHeight / nbRows) < DEFAULT_TABLE_SIZE_SETTINGS.minCellHeight) {
        return List(new Array(nbRows).fill(DEFAULT_TABLE_SIZE_SETTINGS.minCellHeight));
    }
    return List(new Array(nbRows).fill(DEFAULT_TABLE_SIZE_SETTINGS.minCellHeight));
};

const generateDefaultColumnWidths = (table, nbColumns = 1) => {
    const maxTableWidth = table.getIn(['settings', 'maxTableWidth']) || DEFAULT_TABLE_SIZE_SETTINGS.maxTableWidth;
    let cellWidth = table.getIn(['settings', 'width']) ?
        table.getIn(['settings', 'width']) / nbColumns : DEFAULT_TABLE_SIZE_SETTINGS.defaultCellWidth;
    cellWidth = Math.min(cellWidth, DEFAULT_TABLE_SIZE_SETTINGS.defaultCellMaxWidth);

    if (maxTableWidth / nbColumns < DEFAULT_TABLE_SIZE_SETTINGS.minCellWidth) {
        return List(new Array(nbColumns).fill(DEFAULT_TABLE_SIZE_SETTINGS.minCellWidth));
    }
    if (nbColumns * cellWidth > maxTableWidth) {
        return List(new Array(nbColumns).fill(Math.round(maxTableWidth / nbColumns)));
    }
    return List(new Array(nbColumns).fill(cellWidth));
};

const assignVerticalBordersToGrid = (originalGrid, table) => table
    .get('borders')
    .filter(border => border.get('side') !== 'horizontal')
    .reduce(
        (grid, border) => {
            if (grid.size > border.get('column') && grid.get(border.get('column')).size > border.get('row')) {
                return grid.setIn([border.get('column'), border.get('row')], border.get('id'));
            }
            return grid;
        },
        originalGrid
    );

const assignHorizontalBordersToGrid = (originalGrid, table) => table
    .get('borders')
    .filter(border => border.get('side') === 'horizontal')
    .reduce(
        (grid, border) => {
            if (grid.size > border.get('row') && grid.get(border.get('row')).size > border.get('column')) {
                return grid.setIn([border.get('row'), border.get('column')], border.get('id'));
            }
            return grid;
        },
        originalGrid
    );

const generateVerticalBorderGrid = table => {
    const grid = List().setSize(table.get('columnWidths').size + 1)
        .map(() => List().setSize(table.get('rowHeights').size));
    return assignVerticalBordersToGrid(grid, table);
};

const generateHorizontalBorderGrid = table => {
    const grid = List().setSize(table.get('rowHeights').size + 1)
        .map(() => List().setSize(table.get('columnWidths').size));
    return assignHorizontalBordersToGrid(grid, table);
};

const getTableWidth = table => table
    .get('columnWidths')
    .slice(0, table.get('columnWidths').size + 1)
    .reduce((sum, width) => sum + width, 0);

const getTableHeight = table => combineDefinedAndRenderRowHeights(table)
    .slice(0, table.get('rowHeights').size + 1)
    .reduce((sum, height) => sum + height, 0);

const getCellFromId = (table, cellId) => table
    .get('cells')
    .find(cell => cell.get('id') === cellId);

const getBorderFromId = (table, borderId) => table
    .get('borders')
    .find(border => border.get('id') === borderId);

const getVerticalBorders = table => {
    if (table.get('verticalBorders', List([])).size !== 0) {
        return table.get('verticalBorders');
    }
    return generateVerticalBorderGrid(table);
};

const getHorizontalBorders = table => {
    if (table.get('horizontalBorders', List([])).size !== 0) {
        return table.get('horizontalBorders');
    }
    return generateHorizontalBorderGrid(table);
};

const getColumnStartX = (column, table) => table.get('columnWidths')
    .slice(0, column)
    .reduce((sum, width) => sum + width, 0) -
    (getTableWidth(table) / 2);

const getColumnEndX = (column, table) => table.get('columnWidths')
    .slice(0, column + 1)
    .reduce((sum, width) => sum + width, 0) -
    (getTableWidth(table) / 2);

const combineDefinedAndRenderRowHeights = table => table.get('rowHeights').map(
    (renderRowHeight, i) => Math.max(renderRowHeight, table.getIn(['definedRowHeights', i]))
);

const getRowStartY = (row, table) => combineDefinedAndRenderRowHeights(table)
    .slice(0, row)
    .reduce((sum, height) => sum + height, 0) - (getTableHeight(table) / 2);

const getRowEndY = (row, table) => combineDefinedAndRenderRowHeights(table)
    .slice(0, row + 1)
    .reduce((sum, height) => sum + height, 0) - (getTableHeight(table) / 2);

const getRowForY = (y, table) => {
    let row = 0;
    while (row < table.get('rowHeights').size - 1) {
        if (getRowStartY(row, table) < y &&
            y <= getRowEndY(row, table)) {
            return row;
        }
        row++;
    }
    return row;
};

const getColumnForX = (x, table) => {
    let column = 0;
    while (column < table.get('columnWidths').size - 1) {
        if (getColumnStartX(column, table) < x &&
            x <= getColumnEndX(column, table)) {
            return column;
        }
        column++;
    }
    return column;
};

const getCellsBetweenColumns = (firstIndex, lastIndex, table) => {
    let cells = List();
    for (let i = firstIndex; i <= lastIndex; i++) {
        cells = cells.push(getCellGridColumn(i, table));
    }
    return cells.reduce((final, cell) => final.concat(cell));
};

const getCellsBetweenRows = (firstIndex, lastIndex, table) => {
    let cells = List();
    for (let i = firstIndex; i <= lastIndex; i++) {
        cells = cells.push(getCellGridRow(i, table));
    }
    return cells.reduce((final, cell) => final.concat(cell));
};

const getIntersectCells = (arr1, arr2) => intersection(arr1, arr2);

const getGridColumns = table => table
    .get('columnWidths')
    .reduce((columns, width, index) => columns.push(getCellGridColumn(index, table)), List());

const getGridRows = table => table
    .get('rowHeights')
    .reduce((rows, height, index) => rows.push(getCellGridRow(index, table)), List());

const getCellGridColumn = (columnIndex, table) => {
    const cellGrid = table.get('cellGrid') || generateCellGrid(table);
    return cellGrid.reduce((selectedColumn, row) => selectedColumn.push(row.get(columnIndex)), List());
};

const getCellGridRow = (rowIndex, table) => (table.get('cellGrid') || generateCellGrid(table)).get(rowIndex);

const getLeftBorderSegmentsForCell = (cellId, table) => {
    if (!table) return List();
    const cell = getCellFromId(table, cellId);
    return getVerticalBorders(table)
        .get(cell.get('column'))
        .slice(cell.get('row'), cell.get('row') + cell.get('rowSpan'))
        .filter(Boolean);
};

const getTopBorderSegmentsForCell = (cellId, table) => {
    if (!table) return List();
    const cell = getCellFromId(table, cellId);
    return getHorizontalBorders(table)
        .get(cell.get('row'))
        .slice(cell.get('column'), cell.get('column') + cell.get('columnSpan'))
        .filter(Boolean);
};

const getRightBorderSegmentsForCell = (cellId, table) => {
    if (!table) return List();
    const cell = getCellFromId(table, cellId);
    return getVerticalBorders(table)
        .get(cell.get('column') + cell.get('columnSpan'))
        .slice(cell.get('row'), cell.get('row') + cell.get('rowSpan'))
        .filter(Boolean);
};

const getBottomBorderSegmentsForCell = (cellId, table) => {
    if (!table) return List();
    const cell = getCellFromId(table, cellId);
    return getHorizontalBorders(table)
        .get(cell.get('row') + cell.get('rowSpan'))
        .slice(cell.get('column'), cell.get('column') + cell.get('columnSpan'))
        .filter(Boolean);
};

const isBorderOnTableEdge = (borderId, table) => {
    if (!table) return false;
    const border = getBorderFromId(table, borderId);
    if (border.get('side') === 'horizontal') {
        return border.get('row') === 0 || border.get('row') === table.get('rowHeights').size;
    } if (border.get('side') === 'vertical') {
        return border.get('column') === 0 || border.get('column') === table.get('columnWidths').size;
    }
    return false;
};

const isBorderInsideCell = (borderId, table) => {
    if (!table) return false;
    const border = getBorderFromId(table, borderId);
    const cellGrid = table.get('cellGrid') || generateCellGrid(table);
    if (border.get('side') === 'horizontal') {
        return !isBorderOnTableEdge(borderId, table) &&
            cellGrid.getIn([border.get('row') - 1, border.get('column')]) ===
            cellGrid.getIn([border.get('row'), border.get('column')]);
    }
    return !isBorderOnTableEdge(borderId, table) &&
        cellGrid.getIn([border.get('row'), border.get('column') - 1]) ===
        cellGrid.getIn([border.get('row'), border.get('column')]);
};

export {
    generateCellGrid,
    assignCellsToGrid,
    assignSingleCellToGrid,
    combineDefinedAndRenderRowHeights,
    generateDefaultRowHeights,
    generateDefaultColumnWidths,
    generateHorizontalBorderGrid,
    generateVerticalBorderGrid,
    getTableWidth,
    getColumnStartX,
    getColumnEndX,
    getColumnForX,
    getRowStartY,
    getRowEndY,
    getRowForY,
    getGridColumns,
    getGridRows,
    getCellGridColumn,
    getCellGridRow,
    getCellsBetweenColumns,
    getCellsBetweenRows,
    getIntersectCells,
    getLeftBorderSegmentsForCell,
    getRightBorderSegmentsForCell,
    getTopBorderSegmentsForCell,
    getBottomBorderSegmentsForCell,
    isBorderOnTableEdge,
    isBorderInsideCell,
    getTableWithGrid,
    removeGridFromTable,
    getVerticalBorders,
    getHorizontalBorders
};
