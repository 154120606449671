const isFinite = require('lodash/isFinite');
const CommandMixin = require('./Command');

const commandChar = 'a';
const commandRegexp = new RegExp(`^(${
    commandChar
})${
    CommandMixin.regexp.positiveDecimalCapture.repeat(2)
}${
    CommandMixin.regexp.decimalCapture
}${
    CommandMixin.regexp.flagCapture.repeat(2)
}${
    CommandMixin.regexp.decimalCapture.repeat(2)
}`, 'i');

class EllipticArcCommand extends CommandMixin {
    constructor({
        rx,
        ry,
        xAxisRotation,
        largeArcFlag,
        sweepFlag,
        x,
        y,
        ...rest
    }) {
        if (!isFinite(x) || !isFinite(y)) {
            throw new TypeError('Arc command expects x and y to be numbers');
        }
        if (!isFinite(rx) || !isFinite(ry)) {
            throw new TypeError('Arc command expects rx and ry to be numbers');
        }
        if (rx < 0 || ry < 0) {
            throw new TypeError('Arc command expects rx and ry to be positive numbers');
        }
        if (!isFinite(xAxisRotation)) {
            throw new TypeError('x-axis-rotation should be a number');
        }
        if ((xAxisRotation > 360) || (xAxisRotation < -360)) {
            throw new TypeError('x-axis-rotation should have a value between -360 and 360');
        }
        if (![0, 1].includes(largeArcFlag)) {
            throw new TypeError('large-arc-flag should either be 0 or 1');
        }
        if (![0, 1].includes(sweepFlag)) {
            throw new TypeError('sweep-flag should either be 0 or 1');
        }
        super({
            rx,
            ry,
            xAxisRotation,
            largeArcFlag,
            sweepFlag,
            x,
            y,
            commandChar,
            ...rest
        });
    }

    static fromString(string) {
        const commandData = string.match(commandRegexp);
        if (!commandData) {
            throw new TypeError('invalid arc command');
        }
        const [
            command, rx, ry, xAxisRotation, largeArcFlag, sweepFlag, x, y
        ] = commandData.slice(1);
        const relative = command === commandChar;
        return new this({
            relative,
            rx: Number(rx),
            ry: Number(ry),
            xAxisRotation: Number(xAxisRotation),
            largeArcFlag: Number(largeArcFlag),
            sweepFlag: Number(sweepFlag),
            x: Number(x),
            y: Number(y)
        });
    }

    static fromArray(array) {
        return this.fromString(array.join(' '));
    }

    toString() {
        return this.toArray().join(' ');
    }

    toArray() {
        return [
            this.commandChar,
            this.rx,
            this.ry,
            this.xAxisRotation,
            this.largeArcFlag,
            this.sweepFlag,
            this.x,
            this.y
        ];
    }
}

module.exports = EllipticArcCommand;
