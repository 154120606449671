const isPlainObject = require('lodash/isPlainObject');

module.exports = (object, customizer = () => {}) => {
    if (!isPlainObject(object)) {
        throw new Error('Can\'t get pairs of none plain object');
    }
    const pairs = [];
    const queue = [{ key: '', object }];
    while (queue.length > 0) {
        const {
            key: parentKey,
            object: target
        } = queue.shift();
        Object.entries(target)
            .forEach(([key, value]) => {
                const currentKey = `${parentKey}${key}`;
                const customValue = customizer(currentKey, value);
                if (customValue !== undefined) {
                    pairs.push([currentKey, customValue]);
                } else if (isPlainObject(value)) {
                    queue.push({
                        key: `${currentKey}.`,
                        object: value
                    });
                } else {
                    pairs.push([currentKey, value]);
                }
            });
    }

    return pairs;
};
