const isFinite = require('lodash/isFinite');
const CommandMixin = require('./Command');

const commandChar = 'm';
const commandRegexp = new RegExp(`^(${
    commandChar
})${
    CommandMixin.regexp.decimalCapture.repeat(2)
}$`, 'i');

class MoveCommand extends CommandMixin {
    constructor({ x, y, ...rest }) {
        if (!isFinite(x) || !isFinite(y)) {
            throw new TypeError('Move command expects x and y to be numbers');
        }
        super({
            x, y, commandChar, ...rest
        });
    }

    static fromString(string) {
        const commandData = string.match(commandRegexp);
        if (!commandData) {
            throw new TypeError('invalid move command');
        }
        const [command, x, y] = commandData.slice(1);
        const relative = command === commandChar;
        return new this({ relative, x: Number(x), y: Number(y) });
    }

    static fromArray(array) {
        return this.fromString(array.join(' '));
    }

    toString() {
        return this.toArray().join(' ');
    }

    toArray() {
        return [this.commandChar, this.x, this.y];
    }
}

module.exports = MoveCommand;
