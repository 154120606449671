const defaultsDeep = require('lodash/defaultsDeep');
const isNil = require('lodash/isNil');
const isEqual = require('lodash/isEqual');
const omit = require('lodash/omit');
const has = require('lodash/has');
const get = require('lodash/get');
const merge = require('lodash/merge');
// const { fromFabricToShape } = require('../../fabric-adapter/utilities/fromFabricToShape');

const { DEFAULT_CELL_STYLE, DEFAULT_CELL_RUNSTYLE } = require('./config/defaultCellStyles.config.js');
const { DEFAULT_RUNSTYLE, DEFAULT_PARAGRAPHSTYLE } = require('../../Shape/AbstractShape/config/defaultStyles');
const Textbox = require('../../Shape/Textbox');
const CanvasItem = require('../../Shape/CanvasItem');
const CellFabricMixin = require('../../fabric-adapter/mixins/cellFabricObject');
const { pixelRound } = require('../../utilities/pixelRound.js');
const intersectObjects = require('../../utilities/intersectObjects');
const tableGridLogic = require('../../utilities/table/tableGridLogic');
const Border = require('../Border');
const WithShapeStyle = require('../../Shape/AbstractShape/mixins/WithShapeStyle');
const ShapeStyle = require('../../Shape/Styles/ShapeStyle');
const { MIN_FONT_SIZE } = require('../../fabric-adapter/constants/text');

class Cell extends WithShapeStyle(CellFabricMixin(CanvasItem)) {
    static fromJSON(jsonObject) {
        const {
            assignedStyles,
            ...json
        } = jsonObject;
        if (has(assignedStyles, 'shape')) {
            json.assignedShapeStyle = ShapeStyle.fromJSON(get(assignedStyles, 'shape'));
        }
        if (isEqual(json.fill, { gradient: { stops: [] } })) {
            json.fill = undefined;
        }
        const cell = new this(
            json.name,
            json.row,
            json.column,
            json.rowSpan,
            json.columnSpan,
            undefined,
            {
                ...json,
                id: json.id || jsonObject._id,
                fill: json.fill,
                borderColor: json.borderColor,
                style: json.style
            }
        );
        cell.contents = (jsonObject.contents || [])
            .map(obj => Textbox.fromJSON(obj));
        return cell;
    }

    static get defaults() {
        return DEFAULT_CELL_STYLE;
    }

    constructor(name, row, column, rowSpan, columnSpan, table, attributes = {}) {
        super(name, attributes.id, attributes.inLayout);
        if (attributes.style) {
            this.style = attributes.style;
        }
        const newAttributes = {
            ...attributes
        };
        delete newAttributes._id;
        this.table = table;
        this.row = row;
        this.column = column;
        this.rowSpan = rowSpan;
        this.columnSpan = columnSpan;
        this.contents = [new Textbox(this.name, 0, 0, {
            text: ''
        })];
        this.contents[0].textBody.setDefaultRunStyle(DEFAULT_CELL_RUNSTYLE);
        this.contents[0].textBodyPlaceholder.setText('');
        this.initShapeStyle(attributes);
        this.type = 'cell';
        if (newAttributes.assignedShapeStyle) {
            this.assignedShapeStyle = newAttributes.assignedShapeStyle;
        }
        Object.assign(this, omit(newAttributes, ['width', 'height']));
    }

    toJSON() {
        const json = {
            ...super.toJSON(),
            contents: this.contents.map(obj => obj.toJSON()),
            fill: this.fill,
            name: this.name,
            row: this.row,
            column: this.column,
            columnSpan: this.columnSpan,
            rowSpan: this.rowSpan,
            style: this.style
        };

        if (
            this.assignedShapeStyle !== undefined
        ) {
            json.assignedStyles = {
                shape: this.assignedShapeStyle.toJSON()
            };
        }

        return json;
    }

    getContentById(id) {
        return this.contents.find(content => content.id === id);
    }

    getContentsWithText() {
        return this.contents.filter(content => content.textBody.hasText());
    }

    setContentById(id, newContent) {
        this.contents = this.contents.map(content => {
            if (content.id === id) {
                content.textBody = newContent.textBody;
            }
            return content;
        });
    }

    applyUpdate(
        {
            assignedShapeStyle,
            shapeFill,
            fill,
            opacity,
            style,
            shapeOpacity,
            margins,
            removeCustomStyles = false
        },
        textUpdate = {},
        isEmphasisStyle = false
    ) {
        const shapeStyleToAssign = isEmphasisStyle ?
            merge(this.assignedShapeStyle, assignedShapeStyle) :
            assignedShapeStyle;

        if (style) {
            this.style = style;
        }
        if (fill || shapeFill) {
            this.fill = fill || shapeFill;
        }
        if (!isNil(shapeOpacity) && !Number.isNaN(shapeOpacity)) {
            this.forceShapeFillFromRendered();
            this.opacity = shapeOpacity;
        }
        if (!isNil(opacity) && !Number.isNaN(opacity)) {
            this.forceShapeFillFromRendered();
            this.opacity = opacity;
        }
        if (Object.keys(textUpdate).length > 0 || margins) {
            this.contents.forEach(content => content.applyUpdate({
                shapeProps: { margins, removeCustomStyles },
                textProps: textUpdate,
                isEmphasisStyle
            }));
        }

        if (shapeStyleToAssign) {
            this.assignedShapeStyle = ShapeStyle.fromJSON(shapeStyleToAssign);
            if (removeCustomStyles) {
                this.initShapeStyle({});
            }
        }
    }

    copyCellContentStyles(cell, newLine = true) {
        this.contents = cell.contents
            .map(content => {
                const newContent = content.constructor.fromJSON(omit(content.toJSON(), ['id']));
                const {
                    textBodyData
                } = newContent.textBody;
                const currentRunStyle = textBodyData.getIn([
                    'runStyles',
                    0
                ]);
                if (newLine) {
                    newContent.textBody.textBodyData = textBodyData.setIn([
                        'runStyles',
                        0
                    ], {
                        ...currentRunStyle,
                        font: {
                            ...currentRunStyle.font,
                            size: MIN_FONT_SIZE
                        }

                    });
                }
                return newContent;
            });
        if (newLine) this.clearText();
    }

    copyCellShapeStyle(cell) {
        const shapeStyle = ShapeStyle.fromJSON(cell.shapeStyle.toJSON());
        shapeStyle.canvasItem = cell.shapeStyle.canvasItem;
        this.shapeStyle = shapeStyle;
        this.style = cell.style;

        if (cell.assignedShapeStyle) {
            const assignedShapeStyle = ShapeStyle.fromJSON(cell.assignedShapeStyle.toJSON());
            assignedShapeStyle.canvasItem = cell.assignedShapeStyle.canvasItem;
            this.assignedShapeStyle = assignedShapeStyle;
        }
    }

    clearText() {
        this.contents.forEach(content => {
            content.text = '';
            content.enforceTextBodyDefaultsOnPlaceholder();
        });
    }

    convertToListType(listType, update) {
        if (this.content.textBody.convertParagraphsToListType) {
            this.content.textBody.convertParagraphsToListType(listType, update);
        }
        if (this.content.textBodyPlaceholder.convertParagraphsToListType) {
            this.content.textBodyPlaceholder.convertParagraphsToListType(listType, update);
        }
    }

    getFlattenChildren() {
        return [
            this,
            this.contents.reduce((contents, content) => [
                ...contents,
                ...(content.getFlattenChildren instanceof Function ?
                    content.getFlattenChildren() :
                    [content])
            ])
        ];
    }

    getFullCellRunStyle() {
        const intersectedContentRunStyle = intersectObjects(...this.getContentsWithText()
            .map(content => content.textBody.getFullTextRunStyle()));
        return defaultsDeep(intersectedContentRunStyle, DEFAULT_CELL_RUNSTYLE, DEFAULT_RUNSTYLE);
    }

    getFullCellParagraphStyle() {
        const intersectedContentParagraphStyle = intersectObjects(...this.getContentsWithText()
            .map(content => content.textBody.getFullTextParagraphStyle()));
        return defaultsDeep(intersectedContentParagraphStyle, DEFAULT_PARAGRAPHSTYLE);
    }

    updateColorPresets({ presets, scheme }) {
        this.contents.forEach(content => {
            content.updateColorPresets({ presets, scheme });
        });

        this.updateShapeColorPresets({ presets, scheme });
    }

    copyCellContentWithoutText() {
        const content = this.content.copy();
        content.textBody.removeText();
        return content;
    }

    expandSpanAfterColumnSplit(columnIndex) {
        const newColumnIndex = columnIndex + 1;

        [
            ...(this.row === 0 ? this.topBorderSegments : []),
            ...this.bottomBorderSegments
        ]
            .map(borderId => this.table.getBorderById(borderId))
            .filter(border => border.column > columnIndex)
            .forEach(border => {
                border.column += 1;
            });

        this.rightBorderSegments
            .forEach(borderId => {
                const border = this.table.getBorderById(borderId);
                border.column += 1;
            });

        this.columnSpan += 1;

        const topBorder = this.topBorderSegments
            .map(borderId => this.table.getBorderById(borderId))[0];

        const bottomBorder = this.bottomBorderSegments
            .map(borderId => this.table.getBorderById(borderId))[0];

        if (topBorder.row === 0) {
            const newTopBorder = new Border(
                `Border Horizontal ${newColumnIndex}x${topBorder.row}`,
                topBorder.stroke,
                this.table,
                topBorder.side,
                topBorder.row,
                newColumnIndex
            );

            this.table.addBorderSegment(newTopBorder);
        }

        const newBottomBorder = new Border(
            `Border Horizontal ${newColumnIndex}x${bottomBorder.row}`,
            bottomBorder.stroke,
            this.table,
            bottomBorder.side,
            bottomBorder.row,
            newColumnIndex
        );

        this.table.addBorderSegment(newBottomBorder);
    }

    expandSpanAfterRowSplit(rowIndex) {
        const newRowIndex = rowIndex + 1;

        [
            ...(this.column === 0 ? this.leftBorderSegments : []),
            ...this.rightBorderSegments
        ]
            .map(borderId => this.table.getBorderById(borderId))
            .filter(border => border.row > rowIndex)
            .forEach(border => {
                border.row += 1;
            });

        this.bottomBorderSegments
            .forEach(borderId => {
                const border = this.table.getBorderById(borderId);
                border.row += 1;
            });

        this.rowSpan += 1;

        const leftBorder = this.leftBorderSegments
            .map(borderId => this.table.getBorderById(borderId))[0];

        const rightBorder = this.rightBorderSegments
            .map(borderId => this.table.getBorderById(borderId))[0];

        if (leftBorder.column === 0) {
            const newLeftBorder = new Border(
                `Border Vertical ${leftBorder.column}x${newRowIndex}`,
                leftBorder.stroke,
                this.table,
                leftBorder.side,
                newRowIndex,
                leftBorder.column
            );

            this.table.addBorderSegment(newLeftBorder);
        }

        const newRightBorder = new Border(
            `Border Vertical ${rightBorder.column}x${newRowIndex}`,
            rightBorder.stroke,
            this.table,
            rightBorder.side,
            newRowIndex,
            rightBorder.column
        );

        this.table.addBorderSegment(newRightBorder);
    }

    getColumnIndexAtRelativeX(x) {
        const cellColumnWidths = this.table.columnWidths
            .slice(this.column, this.column + this.columnSpan);
        let currentWidth = 0;
        let columnIndex = 0;
        for (let len = cellColumnWidths.length; columnIndex < len; columnIndex++) {
            currentWidth += cellColumnWidths[columnIndex];
            if (currentWidth > x) {
                break;
            }
        }
        return this.column + columnIndex;
    }

    getColumnRelativeXFromCellRelativeX(x) {
        const endColumn = this.getColumnIndexAtRelativeX(x);
        const cellColumnWidths = this.table.columnWidths.slice(this.column, endColumn).reduce(
            (sum, width) => sum + width,
            0
        );
        return x - cellColumnWidths;
    }

    splitAtColumnIndex(columnIndex) {
        const newCellColumnSpan = this.columnSpan - (columnIndex - this.column);
        this.columnSpan = columnIndex - this.column;
        const newCell = new Cell(
            `${this.name}_split_column`,
            this.row,
            columnIndex,
            this.rowSpan,
            newCellColumnSpan,
            this.table
        );
        this.table.cells[newCell.id] = newCell;
        newCell.rightBorderSegments
            .forEach(borderId => {
                const originalBorder = this.table.getBorderById(borderId);
                this.table.addBorderSegment(new Border(
                    `Border Vertical ${columnIndex}x${originalBorder}`,
                    originalBorder.stroke,
                    this.table,
                    originalBorder.side,
                    originalBorder.row,
                    columnIndex
                ));
            });
    }

    splitAtRowIndex(rowIndex) {
        const newCellRowSpan = this.rowSpan - (rowIndex - this.row);
        this.rowSpan = rowIndex - this.row;
        const newCell = new Cell(
            `${this.name}_split_row`,
            rowIndex,
            this.column,
            newCellRowSpan,
            this.columnSpan,
            this.table
        );
        this.table.cells[newCell.id] = newCell;
        newCell.bottomBorderSegments
            .forEach(borderId => {
                const originalBorder = this.table.getBorderById(borderId);
                this.table.addBorderSegment(new Border(
                    `Border Horizontal ${originalBorder.column}x${rowIndex}`,
                    originalBorder.stroke,
                    this.table,
                    originalBorder.side,
                    rowIndex,
                    originalBorder.column
                ));
            });
    }

    getRowIndexAtRelativeY(y) {
        const cellRowHeights = this.table
            .combineDefinedAndRenderRowHeights()
            .slice(this.row, this.row + this.rowSpan);
        let currentHeight = 0;
        let rowIndex = 0;
        for (let len = cellRowHeights.length; rowIndex < len; rowIndex++) {
            currentHeight += cellRowHeights[rowIndex];
            if (Math.round(currentHeight) >= Math.round(y)) {
                break;
            }
        }
        return this.row + rowIndex;
    }

    getRowRelativeYFromCellRelativeY(y) {
        const endRow = this.getRowIndexAtRelativeY(y);
        const cellRowHeights = this.table
            .combineDefinedAndRenderRowHeights().slice(this.row, endRow)
            .reduce(
                (sum, height) => sum + height,
                0
            );
        return y - cellRowHeights;
    }

    isPartOfBottomEdgeInList(cellIds) {
        return this.lastSpannedRow === (this.table.rowCount - 1) ||
            !cellIds.includes(this.table.cellGrid[this.lastSpannedRow + 1][this.column]);
    }

    isPartOfLeftEdgeInList(cellIds) {
        return this.column === 0 ||
            !cellIds.includes(this.table.cellGrid[this.row][this.column - 1]);
    }

    isPartOfRightEdgeInList(cellIds) {
        return this.lastSpannedColumn === (this.table.columnCount - 1) ||
            !cellIds.includes(this.table.cellGrid[this.row][this.lastSpannedColumn + 1]);
    }

    isPartOfTopEdgeInList(cellIds) {
        return this.row === 0 || !cellIds.includes(this.table.cellGrid[this.row - 1][this.column]);
    }

    copyStyles(original) {
        super.copyStyles(original);
    }

    forceParagraphIfEmpty() {
        if (!this.text) {
            const contentTextBody = this.content.textBody;
            if (contentTextBody.getParagraphsCount() === 0) {
                contentTextBody.addParagraph('');
            }
        }
    }

    get spannedColumns() {
        const spannedColumns = [];
        for (let i = this.column; i < this.column + this.columnSpan; i++) {
            spannedColumns.push(i);
        }
        return spannedColumns;
    }

    get lastSpannedColumn() {
        return (this.column + this.columnSpan) - 1;
    }

    get spannedRows() {
        const spannedRows = [];
        for (let i = this.row; i < this.row + this.rowSpan; i++) {
            spannedRows.push(i);
        }
        return spannedRows;
    }

    get lastSpannedRow() {
        return (this.row + this.rowSpan) - 1;
    }

    get content() {
        return this.contents[0];
    }

    set content(content) {
        this.contents[0] = content;
    }

    get top() {
        return pixelRound(this.y - (this.height / 2));
    }

    get bottom() {
        return pixelRound(this.y + (this.height / 2));
    }

    get left() {
        return pixelRound(this.x - (this.width / 2));
    }

    get right() {
        return pixelRound(this.x + (this.width / 2));
    }

    get fonts() {
        return new Set(this.contents.filter(content => content.fonts)
            .reduce((cellFonts, content) => ([
                ...cellFonts,
                ...content.fonts
            ]), []));
    }

    get text() {
        return this.content.textBody.getText();
    }

    set text(text) {
        this.content.text = text;
    }

    set format(formatProperties) {
        Object.keys(formatProperties).forEach(property => {
            this[property] = formatProperties[property];
        });
    }

    get isSpecialCell() {
        return this.isHeaderColumnCell || this.isTotalColumnCell || this.isHeaderRowCell;
    }

    get fill() {
        return this.shapeStyle.fill;
    }

    set fill(fill) {
        this.shapeStyle.fill = fill;
    }

    get opacity() {
        return this.shapeStyle.opacity;
    }

    set opacity(opacity) {
        this.shapeStyle.opacity = opacity;
    }

    get borders() {
        return [
            ...this.leftBorderSegments,
            ...this.rightBorderSegments,
            ...this.topBorderSegments,
            ...this.bottomBorderSegments
        ];
    }

    get leftBorderSegments() {
        return tableGridLogic.getLeftBorderSegmentsForCell(this.id, this.table);
    }

    get rightBorderSegments() {
        return tableGridLogic.getRightBorderSegmentsForCell(this.id, this.table);
    }

    get topBorderSegments() {
        return tableGridLogic.getTopBorderSegmentsForCell(this.id, this.table);
    }

    get bottomBorderSegments() {
        return tableGridLogic.getBottomBorderSegmentsForCell(this.id, this.table);
    }

    get defaults() {
        return this.constructor.defaults;
    }

    get isHeaderSpannerRow() {
        return this.text.length > 0 &&
            this.style.startsWith('TB-HRS');
    }

    get isHeaderSpannerColumn() {
        return this.text.length > 0 &&
            this.style.startsWith('TB-HCS');
    }
}

module.exports = Cell;
