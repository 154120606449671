const {
    fromJS, Map, List, Set
} = require('immutable');

const isRequired = require('../../../utilities/isRequired');
const Selection = require('../Selection/Selection');
const { same } = require('../Selection/utils');
const CanvasStateSelectors = require('./CanvasStateSelectors');
const pixelRoundRemoveArrayDuplicates = require('../utilities/pixelRoundRemoveArrayDuplicates');
const { ACCEPTABLE_ERROR } = require('../utilities/pixelRound');
const CommandExecutor = require('./commands/executor');
const StyleDefinitions = require('./StyleDefinitionsStateMixin');
const CommandTypes = require('./commands/types');
const CommandHistory = require('./commands/history');
const ItemStyleBuilder = require('../Shape/ItemStyle/ItemStyleBuilder');
const { updateValueOfDescriptorWithPalette } = require('../Shape/ColorValueDescriptor');
const Table = require('../Table/Table');
const { DEFAULT_RUNSTYLE } = require('../Shape/AbstractShape/config/defaultStyles');
const GroupHeuristic = require('../utilities/group/GroupHeuristic');

const Formatters = require('./Formatters');
const TreeNode = require('../fabric-adapter/DecksignFabricShapeType/Groups/TreeNode');

const requiredFields = [
    {
        name: 'pageBackground',
        validator: field => List.isList(field)
    },
    {
        name: 'layoutBackground',
        validator: field => List.isList(field)
    },
    {
        name: 'shapes',
        validator: field => List.isList(field)
    },
    {
        name: 'size',
        validator: field => Map.isMap(field) && field.has('height') && field.has('width')
    },
    {
        name: 'version',
        validator: field => Number.isInteger(field) && field >= 0
    },
    {
        name: 'openedTextShapeId',
        validator: field => typeof field === 'string'
    },
    {
        name: 'selection',
        validator: field => List.isList(field)
    },
    {
        name: 'history',
        validator: field => List.isList(field)
    },
    {
        name: 'layoutShapes',
        validator: field => List.isList(field)
    },
    {
        name: 'layoutName',
        validator: field => typeof field === 'string'
    },
    {
        name: 'colorPalette',
        validator: field => List.isList(field)
    },
    {
        name: 'headerAndFooterShapesVisibility',
        validator: field => Map.isMap(field)
    },
    {
        name: 'dynamicValues',
        validator: field => Map.isMap(field)
    },
    {
        name: 'styleDefinitions',
        validator: field => List.isList(field)
    },
    {
        name: 'pageId',
        validator: field => typeof field === 'string'
    }
];

const CanvasState = {
    NOT_PERSISTABLE_COMMANDS: CommandTypes.NOT_PERSISTABLE_COMMANDS,

    initialize(
        shapes = isRequired(),
        pageBackground = isRequired(),
        size = isRequired(),
        dynamicValues = {},
        layoutShapes = [],
        layoutBackground = [],
        layoutName = '',
        colorPalette = [],
        headerAndFooterShapesVisibility = {},
        {
            presets,
            scheme
        } = {},
        pageId = ''
    ) {
        const canvasState = fromJS({
            shapes,
            pageBackground,
            size,
            layoutShapes,
            layoutBackground,
            layoutName,
            colorPalette,
            headerAndFooterShapesVisibility,
            pageId,
            version: 0,
            selection: [],
            clipboard: [],
            history: [],
            dynamicValues,
            contextualSelection: null,
            openedTextShapeId: '',
            editMode: 'Page',
            styleDefinitions: []
        });
        return this.update(canvasState, {
            type: CommandTypes.UPDATE_COLOR_PRESETS,
            presets,
            scheme
        });
    },

    isCanvasState(maybeCanvasState) {
        if (!Map.isMap(maybeCanvasState)) {
            return false;
        }

        const doesValidateFields = requiredFields.every(field => {
            const { name: fieldName, validator } = field;
            if (!maybeCanvasState.has(fieldName)) {
                console
                    .error(`CanvasState need to have a ${fieldName} property`);
                return false;
            } if (!validator(maybeCanvasState.get(fieldName))) {
                console
                    .error(`${fieldName} is not of the correct type`);
                return false;
            }
            return true;
        });

        return doesValidateFields;
    },

    getSelectedItems(canvasState) {
        if (!this.isCanvasState(canvasState)) {
            throw new Error('should be called on valid canvasState');
        }
        return canvasState.get('selection').toJS();
    },

    update(canvasState, command) {
        if (!this.isCanvasState(canvasState)) {
            throw new Error('Can only update canvasState');
        }

        return this.executeCommand(canvasState, command);
    },

    getSelectedCanvasItemsIds(canvasState) {
        if (!this.isCanvasState(canvasState)) {
            throw new Error('To get selected canvas items ids, a canvas state should be passed.');
        }
        return canvasState.get('selection').toJS();
    },

    getSelectedCanvasItemsTypes(canvasState) {
        const paths = CanvasStateSelectors.getCanvasStateSelectedShapePaths(canvasState);
        return paths.map(path => canvasState.getIn([...path, 'type'], '')).toSet();
    },

    getSelectedCanvasItems(canvasState) {
        const paths = CanvasStateSelectors.getCanvasStateSelectedShapePaths(canvasState);
        return paths
            .filter(path => path.length > 0)
            .map(path => canvasState.getIn(path, ''));
    },

    getCanvasStateClipboard(canvasState) {
        return canvasState.get('clipboard').toJS();
    },

    getPersistedState(canvasState, originalPersisted = {}) {
        const {
            width,
            height
        } = canvasState.get('size').toJS();
        return {
            width,
            height,
            shapes: canvasState.get('shapes').toJS().filter(shape => !(shape.isBackground)),
            background: canvasState.get('pageBackground').toJS(),
            layout: {
                ...(originalPersisted.originalLayout || {}),
                ...(originalPersisted.layout || {}),
                shapes: canvasState.get('layoutShapes').toJS().filter(shape => !(shape.isBackground)),
                background: canvasState.get('layoutBackground').toJS(),
                name: canvasState.get('layoutName')
            }
        };
    },

    getSelectionComputedProperties(canvasState, textSelection) {
        if (!this.isCanvasState(canvasState)) {
            throw new Error('To compute selected canvas items a canvas state should be passed.');
        }

        const contextualSelectionComputedProperties = CanvasState
            .getContextualSelectionComputedProperties(canvasState, textSelection);

        if (contextualSelectionComputedProperties.size > 0) {
            const computedProperties = contextualSelectionComputedProperties.toJS();
            return this.updateColorValueDescriptorInComputedProperites(
                canvasState,
                computedProperties
            );
        }

        const selectedCanvasStateItems = CanvasStateSelectors
            .getSelectedCanvasItems(canvasState);

        const selectedCanvasStateItemsProperties = Selection
            .computeSelectionProperties(selectedCanvasStateItems, textSelection);

        if (canvasState.get('selectedShapesParentGroupsPath') &&
            canvasState.get('selectedShapesParentGroupsPath').size !== 0
        ) {
            const offsetedSelectedCanvasItemsProperties = selectedCanvasStateItemsProperties.toJS();
            const parentGroupsTotalOffset = canvasState.get('selectedShapesParentGroupsPath')
                .first().reduce((position, groupId) => {
                    const group = CanvasStateSelectors.getShapeById(canvasState, groupId);
                    if (group) {
                        return {
                            x: position.x + group.get('x') || 0,
                            y: position.y + group.get('y') || 0
                        };
                    }
                    return position;
                }, {
                    x: 0, y: 0
                });
            offsetedSelectedCanvasItemsProperties.x += parentGroupsTotalOffset.x;
            offsetedSelectedCanvasItemsProperties.y += parentGroupsTotalOffset.y;
            return offsetedSelectedCanvasItemsProperties;
        } else if (canvasState.get('groupTree')) {
            const offsetedSelectedCanvasItemsProperties = selectedCanvasStateItemsProperties.toJS();
            const groupTree = canvasState.get('groupTree');
            const firstSelectedId = canvasState.get('selection').first();

            const parentGroupsTotalOffset = TreeNode.getAllParents(groupTree, firstSelectedId)
                .map(node => node.shape)
                .reduce((position, group) => ({
                    x: position.x + group.left || 0,
                    y: position.y + group.top || 0
                }), {
                    x: 0,
                    y: 0
                });
            offsetedSelectedCanvasItemsProperties.x += parentGroupsTotalOffset.x;
            offsetedSelectedCanvasItemsProperties.y += parentGroupsTotalOffset.y;
            return offsetedSelectedCanvasItemsProperties;
        }

        const computedProperties = selectedCanvasStateItemsProperties.toJS();
        return this.updateColorValueDescriptorInComputedProperites(canvasState, computedProperties);
    },

    updateColorValueDescriptorInComputedProperites(canvasState, computedProperties) {
        return {
            ...computedProperties,
            color: updateValueOfDescriptorWithPalette(
                computedProperties.color,
                canvasState.get('colorPalette').toJS()
            )
        };
    },

    getFontsList(canvasState) {
        if (!this.isCanvasState(canvasState)) {
            throw new Error('To compute fonts in canvas, canvas state should be passed.');
        }

        return CanvasStateSelectors.getFlattenFonts(canvasState.get('shapes').concat(
            canvasState.get('layoutShapes'),
            canvasState.get('pageBackground'),
            canvasState.get('layoutBackground')
        )).add(DEFAULT_RUNSTYLE.font.family);
    },

    isContextualSelectionActive(canvasState, type) {
        if (!type) {
            return !!canvasState.get('contextualSelection');
        }
        return (canvasState.get('contextualSelection') || new Map({})).get('mode') === type;
    },

    getContextualSelectionComputedProperties(canvasState, textSelection) {
        const contextualSelection = canvasState.get('contextualSelection');
        if (!contextualSelection) {
            return new Map({});
        }
        switch (contextualSelection.get('mode')) {
            case 'cellContent':
                return CanvasState.getCellsComputedProperties(
                    canvasState,
                    contextualSelection.get('table'),
                    new List([contextualSelection.get('cell')]),
                    textSelection
                );
            case 'cursorZone':
                return CanvasState.getCellsComputedProperties(
                    canvasState,
                    contextualSelection.get('table'),
                    contextualSelection.get('cells'),
                    textSelection
                );
            default:
                return new Map({});
        }
    },

    getBorderInTable(table, side, column, row) {
        const border = table.get('borders').find(cursor => (
            cursor.get('side') === side &&
            cursor.get('row') === row &&
            cursor.get('column') === column
        ));
        return border && border.set('type', 'border');
    },

    getBordersForCell(cell, table) {
        const borders = [];
        for (let i = 0, len = cell.get('columnSpan'); i < len; i++) {
            borders.push(
                this.getBorderInTable(table, 'horizontal', cell.get('column') + i, cell.get('row'))
            );
            borders.push(
                this.getBorderInTable(table, 'horizontal', cell.get('column') + i, cell.get('row') + cell.get('rowSpan'))
            );
        }

        for (let i = 0, len = cell.get('rowSpan'); i < len; i++) {
            borders.push(
                this.getBorderInTable(table, 'vertical', cell.get('column'), cell.get('row') + i)
            );
            borders.push(
                this.getBorderInTable(table, 'vertical', cell.get('column') + cell.get('columnSpan'), cell.get('row') + i)
            );
        }
        return new Set(borders.filter(Boolean));
    },

    getCellsComputedProperties(canvasState, tableId, cellsId, textSelection) {
        const table = CanvasStateSelectors.getShapeById(canvasState, tableId);

        // Filter selected cells and make sure the type is set on the cell
        const cells = table.get('cells')
            .filter(cell => cellsId.includes(cell.get('id') || cell.get('_id')))
            .map(cell => cell.setIn(
                [
                    'contents',
                    0,
                    'type'
                ],
                'Textbox'
            ));

        const tableComponents = cells
            .reduce((list, cell) => list.push(...CanvasStateSelectors.getFlattenCell(cell)), new List([]));

        const borders = cells.reduce(
            (currentBorders, cell) => currentBorders.concat(this.getBordersForCell(cell, table)),
            new Set()
        );

        const selectedTableComponents = tableComponents.push(...borders);

        const selectedTableComponentsProperties = Selection.computeSelectionProperties(
            selectedTableComponents,
            textSelection
        )
            .merge({
                height: table.get('height'),
                rotation: table.get('rotation'),
                width: table.get('width'),
                x: table.get('x'),
                y: table.get('y'),
                style: same(cells, 'style', '')
            });

        return selectedTableComponentsProperties;
    },

    shouldCurrentVersionBePersisted(canvasState, lastPersistedVersion) {
        if (canvasState) {
            const currentVersion = canvasState.get('version');
            if (currentVersion !== lastPersistedVersion) {
                if (currentVersion > lastPersistedVersion) {
                    const isOnlyUpdatingSelection = canvasState.get('history')
                        .slice(lastPersistedVersion)
                        .every(command => this.NOT_PERSISTABLE_COMMANDS.includes(command.get('type')));
                    return !isOnlyUpdatingSelection;
                }
                return true;
            }
        }
        return false;
    },

    getSelectedCellsFromContextualSelection(canvasState) {
        const contextualSelection = canvasState.get('contextualSelection');
        if (
            !contextualSelection ||
            contextualSelection.get('mode') !== 'cursorZone'
        ) {
            return new List([]);
        }
        const table = CanvasStateSelectors.getShapeById(canvasState, contextualSelection.get('table'));
        return table.get('cells')
            .filter(shape => (
                contextualSelection.get('cells').includes(shape.get('id')) ||
                contextualSelection.get('cells').includes(shape.get('_id'))
            ));
    },

    getSelectedCellIds(canvasState) {
        const cells = this.getSelectedCellsFromContextualSelection(canvasState);
        return cells.map(cell => cell.get('id')).toJS();
    },

    getSelectedCellsAxes(selectedCells) {
        const cellHorizontalPositions = [];
        const cellVerticalPositions = [];
        selectedCells.forEach(cell => {
            if (!cellHorizontalPositions.includes(cell.get('x'))) {
                cellHorizontalPositions.push(cell.get('x'));
            }
            if (!cellVerticalPositions.includes(cell.get('y'))) {
                cellVerticalPositions.push(cell.get('y'));
            }
        });
        return [cellHorizontalPositions, cellVerticalPositions];
    },

    getSelectedRowsWidth(axes, selectedCells) {
        return axes.map(axis => {
            const widthOfCellsOnAxis = selectedCells
                .filter(cell => (
                    cell.get('y') - (cell.get('height') / 2) <= axis + ACCEPTABLE_ERROR &&
                    cell.get('y') + (cell.get('height') / 2) > axis + ACCEPTABLE_ERROR
                ))
                .map(cell => cell.get('width'));
            return widthOfCellsOnAxis.reduce((a, b) => a + b, 0);
        });
    },

    getSelectedColumnsHeight(axes, selectedCells) {
        return axes.map(axis => {
            const heightOfCellsOnAxis = selectedCells
                .filter(cell => (
                    cell.get('x') - (cell.get('width') / 2) <= axis + ACCEPTABLE_ERROR &&
                    cell.get('x') + (cell.get('width') / 2) > axis + ACCEPTABLE_ERROR
                ))
                .map(cell => cell.get('height'));
            return heightOfCellsOnAxis.reduce((a, b) => a + b, 0);
        });
    },

    shouldAllowCellMerge(canvasState) {
        const selectedCells = this.getSelectedCellsFromContextualSelection(canvasState);
        if (selectedCells.size <= 1) {
            return false;
        }
        const [cellHorizontalPositions, cellVerticalPositions] = this.getSelectedCellsAxes(selectedCells);
        const selectedRowsWidth = this.getSelectedRowsWidth(cellVerticalPositions, selectedCells);
        const selectedColumnsHeight = this.getSelectedColumnsHeight(cellHorizontalPositions, selectedCells);
        return (
            pixelRoundRemoveArrayDuplicates(selectedRowsWidth).length === 1 &&
            pixelRoundRemoveArrayDuplicates(selectedColumnsHeight).length === 1
        );
    },

    getLayoutShapes(canvasState) {
        if (CanvasState.isCanvasState(canvasState)) {
            if (canvasState.get('editMode') === 'Layout') {
                return canvasState.get('layoutShapes').toJS();
            }
            const layoutBasicShapes = (canvasState.get('layoutShapes').toJS() || [])
                .filter(shape => !shape.placeholderType);
            return layoutBasicShapes;
        }
        return [];
    },

    getListableShapesByLayers(canvasState) {
        return {
            pageShapes: [
                ...CanvasState.getListablePageShapes(canvasState),
                ...CanvasState.getListablePageBackground(canvasState)
            ],
            layoutShapes: [
                ...CanvasState.getListableLayoutShapes(canvasState),
                ...CanvasState.getListableLayoutBackground(canvasState)
            ]
        };
    },

    getListablePageShapes(canvasState) {
        if (CanvasState.isCanvasState(canvasState)) {
            if (canvasState.get('editMode') !== 'Layout') {
                const layoutShapes = CanvasStateSelectors
                    .filterHeaderAndFooterPlaceholders(
                        canvasState.get('layoutShapes'),
                        canvasState.get('headerAndFooterShapesVisibility').toJS()
                    );
                return CanvasStateSelectors
                    .intersectPageShapesAndLayoutShapesInPageEdit(
                        canvasState.get('shapes'),
                        layoutShapes
                    )
                    .toJS();
            }
            return CanvasStateSelectors
                .getShapeListWithoutPlaceholders(canvasState.get('shapes'))
                .toJS();
        }
        return [];
    },

    getListablePageBackground(canvasState) {
        if (CanvasState.isCanvasState(canvasState)) {
            if (canvasState.get('editMode') !== 'Layout') {
                return CanvasStateSelectors
                    .intersectPageShapesAndLayoutShapesInPageEdit(
                        canvasState.get('pageBackground'),
                        canvasState.get('layoutBackground')
                    )
                    .toJS();
            }
            return CanvasStateSelectors
                .getShapeListWithoutPlaceholders(canvasState.get('pageBackground'))
                .toJS();
        }
        return [];
    },

    getListableLayoutShapes(canvasState) {
        if (CanvasState.isCanvasState(canvasState)) {
            if (canvasState.get('editMode') === 'Layout') {
                return canvasState
                    .get('layoutShapes')
                    .toJS();
            }
            return CanvasStateSelectors
                .getShapeListWithoutPlaceholders(canvasState.get('layoutShapes'))
                .toJS();
        }
        return [];
    },

    getListableLayoutBackground(canvasState) {
        if (CanvasState.isCanvasState(canvasState)) {
            if (canvasState.get('editMode') === 'Layout') {
                return canvasState
                    .get('layoutBackground')
                    .toJS();
            }
            return CanvasStateSelectors
                .getShapeListWithoutPlaceholders(canvasState.get('layoutBackground'))
                .toJS();
        }
        return [];
    },

    getAllShapes(canvasState) {
        return List().concat(
            canvasState.get('shapes'),
            canvasState.get('pageBackground'),
            canvasState.get('layoutShapes')
                .map(s => s.merge({ inLayout: true })),
            canvasState.get('layoutBackground')
        );
    },

    countAllShapes(canvasState) {
        return CanvasState.getAllShapes(canvasState)
            .size;
    },

    getSerializedCanvasItemById(id, canvasState) {
        if (CanvasState.isCanvasState(canvasState)) {
            const canvasItem = CanvasState.getPageSerializedCanvasItemById(id, canvasState) ||
                CanvasState.getLayoutSerializedCanvasItemById(id, canvasState);
            return canvasItem;
        }
        return undefined;
    },

    getStyleDefinitionOfCanvasItem(canvasState, id) {
        const canvasItem = this.getSerializedCanvasItemById(id, canvasState);
        if (canvasItem) {
            switch (canvasItem.type.toLowerCase()) {
                case 'table': {
                    const serializedTable = CanvasStateSelectors
                        .getShapeById(canvasState, id)
                        .toJS();
                    const table = Table.fromJSON(serializedTable);
                    return ItemStyleBuilder.buildTableBuilding(table);
                }
                default:
                    return ItemStyleBuilder.buildObjectStyleFromCanvasItem(canvasItem);
            }
        } else {
            throw new Error(`Couldn't find canvas item with id ${id}`);
        }
    },

    getPageSerializedCanvasItemById(id, canvasState) {
        const canvasItems = CanvasStateSelectors.getFlattenShapes(canvasState.get('shapes') || []);
        if (canvasItems) {
            const canvasItem = canvasItems.find(cursorItem => cursorItem.get('id') === id);
            if (canvasItem) {
                return canvasItem.toJS();
            }
        }
        return undefined;
    },

    getLayoutSerializedCanvasItemById(id, canvasState) {
        const canvasItems = CanvasStateSelectors.getFlattenShapes(canvasState.get('layoutShapes'));
        if (canvasItems) {
            const canvasItem = canvasItems.find(cursorItem => cursorItem.get('id') === id);
            if (canvasItem) {
                return canvasItem.toJS();
            }
        }
        return undefined;
    },

    isOnlyUpdatingSelection({
        previous,
        next
    }) {
        if (!CanvasState.isCanvasState(previous) && !CanvasState.isCanvasState(next)) {
            return false;
        }
        if (previous.get('version') > next.get('version')) {
            return false;
        }
        return CommandHistory.isOnlySelectionUpdate({ previous, next });
    },

    getTableStyleMismatches(canvasState, tableId, tableUpdate) {
        const beforeUpdateStyleMap = CanvasStateSelectors
            .getTableStylesMap(canvasState, tableId);

        const updatedCanvasState = this.executeCommand(canvasState, {
            type: 'UPDATE_PROPERTIES_ON_SELECTED_SHAPES',
            properties: tableUpdate
        });

        const afterUpdateStyleMap = CanvasStateSelectors
            .getTableStylesMap(updatedCanvasState, tableId);

        return {
            cells: afterUpdateStyleMap.get('cells').filter((styleId, cellId) => {
                const beforeUpdateStyle = beforeUpdateStyleMap.getIn(['cells', cellId]);
                return beforeUpdateStyle !== styleId;
            }).keySeq().toJS(),
            borders: afterUpdateStyleMap.get('borders').filter((styleId, borderId) => {
                const beforeUpdateStyle = beforeUpdateStyleMap.getIn(['borders', borderId]);
                return beforeUpdateStyle !== styleId;
            }).keySeq().toJS()
        };
    },

    getTextBodyPaths(canvasState) {
        return CanvasStateSelectors.getTextBodyPathsFromShapeList(canvasState, ['layoutBackground'])
            .concat(
                CanvasStateSelectors.getTextBodyPathsFromShapeList(canvasState, ['layoutShapes'])
            )
            .concat(
                CanvasStateSelectors.getTextBodyPathsFromShapeList(canvasState, ['pageBackground'])
            )
            .concat(
                CanvasStateSelectors.getTextBodyPathsFromShapeList(canvasState, ['shapes'])
            )
            .toJS();
    },

    removeTextBodyCollectionIds(canvasState) {
        return this.getTextBodyPaths(canvasState)
            .reduce((currentState, path) => currentState.updateIn(
                path,
                this.removeCollectionIdsInTextBody.bind(this)
            ), canvasState);
    },

    removeCollectionIdsInTextBody(textBody) {
        return ['paragraphs', 'paragraphStyles', 'runs', 'runStyles'].reduce(
            (currentTextBody, collectionKey) => currentTextBody.update(
                collectionKey,
                collection => {
                    const updatedCollection = collection.map(object => object.delete('_id'));
                    return updatedCollection;
                }
            ),
            textBody
        );
    },

    canSendSelectionToFront(canvasState) {
        if (canvasState.get('shapes').size === 0) {
            return false;
        }
        const firstShapeId = canvasState.get('shapes').first().get('id');
        return !this.getSelectedCanvasItemsIds(canvasState).includes(firstShapeId);
    },

    canSendSelectionToBack(canvasState) {
        if (canvasState.get('shapes').size === 0) {
            return false;
        }
        const firstShapeId = canvasState.get('shapes').last().get('id');
        return !this.getSelectedCanvasItemsIds(canvasState).includes(firstShapeId);
    },

    canGroupShapes(canvasState) {
        const groupTree = canvasState.get('groupTree');
        const ids = canvasState.get('selection') ? canvasState.get('selection').toJS() : [];

        if (groupTree) {
            return GroupHeuristic.canGroupShapes(groupTree, ids);
        }

        return ids.length >= 2;
    },

    canUngroupShapes(canvasState) {
        const selectedShapes = this.getSelectedCanvasItems(canvasState);
        return GroupHeuristic.canUngroupShapes(selectedShapes);
    },

    ...StyleDefinitions,
    ...Formatters,
    ...CommandExecutor
};

module.exports = CanvasState;
