import { List, Map } from 'immutable';
import Textbody from './Helpers/Text/TextBody';

const genericShapeProperties = {
    type: undefined,
    name: undefined,
    assignedStyles: Map({ shape: Map({}), stroke: Map({}) }),
    dynamicProperties: List([]),
    fill: undefined,
    flipX: false,
    flipY: false,
    height: 120,
    id: undefined,
    inLayout: false,
    isBackground: false,
    isGroupHidden: false,
    isGroupLocked: false,
    isHidden: false,
    isImported: false,
    isLocked: false,
    lockAspectRatio: false,
    lockPath: true,
    opacity: 1,
    placeholderSequence: undefined,
    placeholderSourceId: undefined,
    placeholderType: undefined,
    rotation: 0,
    scaleX: 1,
    scaleY: 1,
    selectable: true,
    skewX: 0,
    skewY: 0,
    stroke: Map({}),
    style: 'DF-S1',
    styleId: undefined,
    textBody: Textbody.TextBody(),
    textBodyPlaceholder: Textbody.TextBody(),
    visible: true,
    width: 120,
    x: 0,
    y: 0
};

export default genericShapeProperties;
