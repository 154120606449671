import {
    Record,
    Map,
    List,
    fromJS
} from 'immutable';
import UUID from 'uuid/v4';
import genericShapeProperties from '../../genericShapeProperties';
import { addShape, getNewShapeName, updateSelection } from '../../Helpers/helpers';
import { DEFAULT_TABLE_SETTINGS } from '../../Helpers/Table/Config/defaultTableAttributes.config';
import * as tableGridLogic from '../../Helpers/Table/tableGridLogic';
import Textbody from '../../Helpers/Text/TextBody';
import { updateTable } from '../../Helpers/Table/table';
import getPropertiesForDestructuring from '../../utilities/getPropertiesForDestructuring';
import { applyStyle } from '../../Helpers/Style/style';
import { convertPropertiesToShapeProperties } from '../../utilities/convertPropertiesToShapeProperties';

const TableRecord = Record({
    ...genericShapeProperties,
    type: 'Table',
    lockPath: false,
    columns: 3,
    rows: 3,
    borders: List([]),
    cells: List([]),
    columnWidths: List([]),
    rowHeights: List([]),
    definedRowHeights: List([]),
    hasBandedColumns: false,
    hasBandedRows: false,
    hasHeaderColumn: false,
    hasHeaderRow: false,
    hasTotalColumn: false,
    hasTotalRow: false,
    mainAxis: 'vertical',
    cellGrid: undefined,
    horizontalBorders: List([]),
    verticalBorders: List([])
}, 'Table');

const CellRecord = Record({
    name: '',
    type: 'cell',
    assignedStyles: Map({}),
    column: 0,
    columnSpan: 1,
    contents: List([
        Map({
            textBody: Textbody.TextBody({
                paragraphStyles: List([Map({ isDefault: true })]),
                paragraphs: List([Map({ startIndex: 0, endIndex: -1, style: 1 })]),
                runStyles: List([Map({ isDefault: true })]),
                assignedStyles: Map({}),
                verticalAlign: undefined
            }),
            textBodyPlaceholder: Textbody.TextBody({
                paragraphStyles: List([Map({ isDefault: true })]),
                paragraphs: List([Map({ startIndex: 0, endIndex: -1, style: 1 })]),
                runStyles: List([Map({ isDefault: true })]),
                assignedStyles: Map({}),
                verticalAlign: undefined
            }),
            type: 'Textbox'
        })
    ]),
    fill: undefined,
    id: '',
    isHidden: false,
    isImported: false,
    isLocked: false,
    placeholderSequence: undefined,
    placeholderSourceId: undefined,
    placeholderType: undefined,
    row: 0,
    rowSpan: 1,
    style: '',
    opacity: 1,
    styleId: undefined
});

const BorderRecord = Record({
    assignedStyles: Map({}),
    column: 0,
    type: 'border',
    id: '',
    isHidden: false,
    isImported: false,
    isLocked: false,
    name: '',
    placeholderSequence: undefined,
    placeholderSourceId: undefined,
    placeholderType: undefined,
    row: 0,
    side: 'horizontal',
    stroke: Map({}),
    style: '',
    styleId: undefined,
    opacity: 1,
    table: 'tableId'
});

const generateDefaultCells = (tableName, rowCount, columnCount) => {
    let cells = List([]);
    for (let i = 0; i < rowCount; i++) {
        for (let j = 0; j < columnCount; j++) {
            const cell = CellRecord({
                name: `${tableName}_cell_${(i * columnCount) + j}`,
                id: UUID().toString(),
                row: i,
                column: j,
                rowSpan: 1,
                columnSpan: 1
            });
            cells = cells.push(cell);
        }
    }
    return cells;
};

const generateDefaultBorders = (tableId, horizontalBorders, verticalBorders) => {
    let borders = List([]);
    for (
        let rowIndex = 0, rowCount = horizontalBorders.size;
        rowIndex < rowCount;
        rowIndex++
    ) {
        for (
            let columnIndex = 0, columnCount = horizontalBorders.get(rowIndex).size;
            columnIndex < columnCount;
            columnIndex++
        ) {
            const border = BorderRecord({
                name: `Border Horizontal ${rowIndex}-${columnIndex}`,
                id: UUID().toString(),
                side: 'horizontal',
                row: rowIndex,
                column: columnIndex,
                table: tableId
            });
            borders = borders.push(border);
        }
    }
    for (
        let columnIndex = 0, columnCount = verticalBorders.size;
        columnIndex < columnCount;
        columnIndex++
    ) {
        for (
            let rowIndex = 0, rowCount = verticalBorders.get(columnIndex).size;
            rowIndex < rowCount;
            rowIndex++
        ) {
            const border = BorderRecord({
                name: `Border Vertical ${columnIndex}-${rowIndex}`,
                id: UUID().toString(),
                side: 'vertical',
                row: rowIndex,
                column: columnIndex,
                table: tableId
            });
            borders = borders.push(border);
        }
    }
    return borders;
};

const add = (canvasState, command) => {
    const {
        shapes,
        defaultCanvasItemStyles,
        editMode
    } = getPropertiesForDestructuring(
        command,
        [
            'shapes',
            'defaultCanvasItemStyles',
            'editMode'
        ]
    );

    const newIds = [];

    let updatedCanvasState = shapes.reduce((currentCanvasState, shape) => {
        const style = defaultCanvasItemStyles.get(shape.get('styleToLoad') || 'shape');
        const styleDefinition = applyStyle(Map(), style, 'Table');
        const styleToApply = convertPropertiesToShapeProperties(styleDefinition);

        const textBody = Textbody.TextBody({
            paragraphStyles: List([Map({ isDefault: true })]),
            paragraphs: List([Map({ startIndex: 0, endIndex: -1, style: 1 })]),
            runStyles: List([Map({ isDefault: true })]),
            assignedStyles: Map({
                paragraph: styleToApply.getIn(['textProps', 'assignedParagraphStyle']),
                run: styleToApply.getIn(['textProps', 'assignedRunStyle']),
                textBody: styleToApply.getIn(['textProps', 'assignedStyle'])
            })
        });

        const textBodyPlaceholder = Textbody.TextBody({
            paragraphStyles: List([Map({ isDefault: true })]),
            paragraphs: List([Map({ startIndex: 0, endIndex: -1, style: 1 })]),
            runStyles: List([Map({ isDefault: true })]),
            assignedStyles: Map({
                paragraph: styleToApply.getIn(['textProps', 'assignedParagraphStyle']),
                run: styleToApply.getIn(['textProps', 'assignedRunStyle']),
                textBody: styleToApply.getIn(['textProps', 'assignedStyle'])
            })
        });

        const settings = Map(DEFAULT_TABLE_SETTINGS)
            .merge(shape.get('settings'));

        const tableName = getNewShapeName(currentCanvasState, 'Table');
        const tableId = UUID().toString();

        const rowHeights = tableGridLogic.generateDefaultRowHeights(settings, shape.get('rows'));
        const columnWidths = tableGridLogic.generateDefaultColumnWidths(settings, shape.get('columns'));
        const cells = generateDefaultCells(tableName, shape.get('rows'), shape.get('columns'));

        const horizontalBorders = tableGridLogic.generateHorizontalBorderGrid(
            Map({
                rowHeights, columnWidths, borders: List([])
            })
        );
        const verticalBorders = tableGridLogic.generateVerticalBorderGrid(
            Map({
                rowHeights, columnWidths, borders: List([])
            })
        );
        const definedRowHeights = shape.get('definedRowHeights') || fromJS(new Array(shape.get('rows')).fill(0));
        const height = rowHeights.reduce((tot, rowHeight) => tot + rowHeight);
        const width = columnWidths.reduce((tot, columnWidth) => tot + columnWidth);

        const borders = generateDefaultBorders(tableId, horizontalBorders, verticalBorders);

        const newShape = TableRecord({
            name: tableName,
            id: tableId,
            inLayout: editMode === 'layout',
            x: currentCanvasState.get('size').get('width') / 2,
            y: currentCanvasState.get('size').get('height') / 2,
            assignedStyles: Map({
                shape: styleToApply.getIn(['shapeProps', 'assignedShapeStyle']),
                stroke: styleToApply.getIn(['strokeProps', 'assignedStrokeStyle'])
            }),
            textBody,
            textBodyPlaceholder,
            cells,
            borders,
            width,
            height,
            definedRowHeights,
            rowHeights,
            columnWidths,
            rows: shape.get('rows'),
            columns: shape.get('columns'),
            hasBandedColumns: settings.get('hasBandedColumns'),
            hasBandedRows: settings.get('hasBandedRows'),
            hasHeaderColumn: settings.get('hasHeaderColumn'),
            hasHeaderRow: settings.get('hasHeaderRow'),
            hasTotalColumn: settings.get('hasTotalColumn'),
            hasTotalRow: settings.get('hasTotalRow'),
            mainAxis: settings.get('mainAxis')
        });
        newIds.push(newShape.get('id'));
        return addShape(currentCanvasState, newShape);
    }, canvasState);

    updatedCanvasState = newIds.reduce((currentCanvasState, id, index) => {
        const shape = shapes.get(index);
        const style = defaultCanvasItemStyles.get(shape.get('styleToLoad') || 'shape');
        const styleDefinition = applyStyle(Map(), style, 'Table');
        const styleToApply = convertPropertiesToShapeProperties(styleDefinition);
        return updateTable(currentCanvasState, id, styleToApply);
    }, updatedCanvasState);

    updatedCanvasState = updateSelection(updatedCanvasState, fromJS({ selection: newIds }));

    return updatedCanvasState;
};

export default add;
