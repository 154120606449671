import CommandTypes from '../../../Canvas/commands/types';
import defaultAlign from './align';
import defaultDistribute from './distribute';
import defaultRemove from './remove';
import defaultCopy from './copy';
import group from './group';
import ungroup from './ungroup';
import updateSelection from './updateSelection';
import { toggleEditMode, lockBackgrounds } from './canvasStateProperties';
import toggleVisibility from './toggleVisibility';
import toggleLock from './toggleLock';
import updateProperties from './updateProperties';
import flip from './flip';
import updateSelectionAndGroup from './updateSelectionAndGroup';
import paste from './paste';
import duplicate from './duplicate';
import convertSelectedItemsTextLevel from './convertSelectedItemsTextLevel';
import cutShapes from './cut';
import updateColorPreset from './updateColorPreset';
import layoutReset from './layoutReset';
import applyLayoutOnPage from './applyLayoutOnPage';
import updateShapes from './updateShapes';
import updateDynamicValues from './updateDynamicValues';
import exitContextualSelection from './exitContextualSelection';
import changeLayoutName from './changeLayoutName';
import changeShapeIndex from './changeShapeIndex';
import removeAll from './removeAll';
import updateColorPalette from './updateColorPalette';
import updatePageBackground from './updatePageBackground';
import updatePageSize from './updatePageSize';
import updateCellContentTextbody from './updateCellContentTextbody';
import updateObjectPropertiesFromDelta from './updateObjectPropertiesFromDelta';

const defaultCommands = {
    [CommandTypes.ALIGN_SELECTED_SHAPES]: defaultAlign,
    [CommandTypes.DELETE_SELECTED_SHAPES]: defaultRemove,
    [CommandTypes.DELETE_ALL_SHAPES]: removeAll,
    [CommandTypes.CUT_SELECTED_SHAPES]: cutShapes,
    [CommandTypes.DISTRIBUTE_SELECTED_SHAPES]: defaultDistribute,
    [CommandTypes.UPDATE_SELECTION]: updateSelection,
    [CommandTypes.EXIT_CONTEXTUAL_SELECTION]: exitContextualSelection,
    [CommandTypes.TOGGLE_EDIT_MODE]: toggleEditMode,
    [CommandTypes.TOGGLE_SHAPE_LOCK]: toggleLock,
    [CommandTypes.TOGGLE_SHAPE_VISIBILITY]: toggleVisibility,
    [CommandTypes.PASTE_CLIPBOARD_CONTENT]: paste,
    [CommandTypes.DUPLICATE_SHAPES]: duplicate,
    [CommandTypes.CONVERT_SELECTED_SHAPES_TEXT_ITEMS_LEVEL]: convertSelectedItemsTextLevel,
    [CommandTypes.SET_CLIPBOARD]: defaultCopy,
    [CommandTypes.COPY_SELECTED_SHAPES]: defaultCopy,
    [CommandTypes.SET_CLIPBOARD]: defaultCopy,
    [CommandTypes.UPDATE_PROPERTIES_ON_SELECTED_SHAPES]: updateProperties,
    [CommandTypes.UPDATE_PROPERTIES_FROM_DELTA_ON_SELECTED_SHAPES]: updateObjectPropertiesFromDelta,
    [CommandTypes.UPDATE_SHAPES_PROPERTIES]: updateProperties,
    [CommandTypes.FLIP_SELECTED_SHAPES]: flip,
    [CommandTypes.UPDATE_SELECTION_AND_GROUP]: updateSelectionAndGroup,
    [CommandTypes.GROUP_SELECTED_SHAPES]: group,
    [CommandTypes.UNGROUP_SELECTED_SHAPES]: ungroup,
    [CommandTypes.LOCK_BACKGROUND_SHAPES]: lockBackgrounds,
    [CommandTypes.UPDATE_COLOR_PRESETS]: updateColorPreset,
    [CommandTypes.LAYOUT_RESET]: layoutReset,
    [CommandTypes.APPLY_LAYOUT_ON_PAGE]: applyLayoutOnPage,
    [CommandTypes.UPDATE_SHAPES]: updateShapes,
    [CommandTypes.UPDATE_DYNAMIC_VALUES]: updateDynamicValues,
    [CommandTypes.CHANGE_LAYOUT_NAME]: changeLayoutName,
    [CommandTypes.CHANGE_SHAPE_INDEX]: changeShapeIndex,
    [CommandTypes.UPDATE_COLOR_PALETTE]: updateColorPalette,
    [CommandTypes.UPDATE_PAGE_SIZE]: updatePageSize,
    [CommandTypes.UPDATE_PAGE_BACKGROUND]: updatePageBackground,
    [CommandTypes.UPDATE_CELL_CONTENT_TEXTBODY]: updateCellContentTextbody
};

export default defaultCommands;
