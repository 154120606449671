import UUID from 'uuid/v4';
import { List, Map, fromJS } from 'immutable';
import {
    isEqual
} from 'lodash';
import Textbody from './Text/TextBody';
import { assignedShapeStyleToCanvasState, initShapeStyle } from './Style/shape';
import { assignedStrokeStyleToCanvasState } from './Style/stroke';
import { getShapeById } from '../../Canvas/CanvasStateSelectors';
import getPropertiesForDestructuring from '../utilities/getPropertiesForDestructuring';
import omit from '../utilities/omit';
import pick from '../utilities/pick';

const getShapePathWithLayer = (canvasState, id) => {
    const shapeLayer = canvasState.get('editMode') === 'Layout' ? 'layoutShapes' : 'shapes';
    return [
        shapeLayer,
        ...getShapePath(canvasState.get(shapeLayer), id)
    ];
};

const getShapePathWithLayerFromName = (canvasState, name) => {
    const shapeLayer = canvasState.get('editMode') === 'Layout' ? 'layoutShapes' : 'shapes';
    return [
        shapeLayer,
        ...getShapePathFromName(canvasState.get(shapeLayer), name)
    ];
};

const getInsertionPathWithLayer = (canvasState, ids) => {
    const shapeLayer = canvasState.get('editMode') === 'Layout' ? 'layoutShapes' : 'shapes';
    return [
        shapeLayer,
        ...getInsertionPath(canvasState.get(shapeLayer), ids)
    ];
};

const getShapePath = (shapes, id) => shapes
    .reduce((currentPath, shape, index) => {
        if (shape.get('id') === id) {
            return [...currentPath, index];
        }

        if (shape.get('type') === 'Group') {
            const nested = getShapePath(shape.get('shapes'), id);

            if (nested.length) {
                return [...currentPath, index, 'shapes', ...nested];
            }
        }
        return currentPath;
    }, []);

const getShapePathFromName = (shapes, name) => shapes
    .reduce((currentPath, shape, index) => {
        if (currentPath.length > 0) {
            return currentPath;
        }

        if (shape.get('type') === 'Group') {
            const nested = getShapePathFromName(shape.get('shapes'), name);

            if (nested.length) {
                return [...currentPath, index, 'shapes', ...nested];
            }
        } else if (shape.get('name') === name) {
            return [...currentPath, index];
        }
        return currentPath;
    }, []);

const getInsertionPath = (shapes, ids) => shapes
    .reduce((currentPath, shape, index) => {
        if (shape.get('type') === 'Group') {
            let insertionPath = getInsertionPath(shape.get('shapes'), ids);
            if (insertionPath.length) {
                insertionPath = [
                    ...currentPath,
                    index,
                    'shapes',
                    ...insertionPath
                ];
                if (insertionPath.length > currentPath.length) {
                    return insertionPath;
                }
            }
        } else if (ids.includes(shape.get('id'))) {
            if (!currentPath.length) {
                return [index];
            }
        }

        return currentPath;
    }, []);

const getShapeRootPath = (shapes, selectedItem, pathToRoot = List(), currPath = List()) => {
    let updatedPathToRoot = pathToRoot;
    let updatedCurrentPath = currPath;

    const currentItem = shapes.find(shape => shape.get('id') === selectedItem.get(0));
    if (currentItem) {
        updatedCurrentPath = currPath.push(
            currentItem.get('id')
        );
        return updatedPathToRoot.push(updatedCurrentPath);
    }

    shapes.forEach(shape => {
        updatedPathToRoot = getShapeRootPath(
            shape.get('shapes') || List(),
            selectedItem,
            updatedPathToRoot,
            currPath.concat(List([shape.get('id')]))
        );
    });
    return updatedPathToRoot;
};

const getShapeRoot = (shapes, selection) => {
    let listToRoot = getShapeRootPath(shapes, selection);
    listToRoot = listToRoot.flatten();
    const root = listToRoot.get(0);
    return root;
};

const getAbsolutePositionWithLayer = (canvasState, id) => {
    const shapeLayer = canvasState.get('editMode') === 'Layout' ? 'layoutShapes' : 'shapes';
    return getAbsolutePosition(canvasState.get(shapeLayer), id);
};

const getAbsolutePosition = (shapes, id) => shapes
    .reduce((absolutePosition, shape) => {
        if (shape.get('type') === 'Group') {
            const position = getAbsolutePosition(shape.get('shapes'), id);
            if (position.x !== undefined && position.y !== undefined) {
                return {
                    x: shape.get('x') + position.x,
                    y: shape.get('y') + position.y
                };
            }
        }

        if (shape.get('id') === id) {
            return {
                x: shape.get('x'),
                y: shape.get('y')
            };
        }

        return absolutePosition;
    }, {});

const cloneShape = (canvasState, id) => fromJS(
    canvasState
        .getIn(getShapePathWithLayer(canvasState, id))
        .toJS()
);

const updateShapesFromDelta = (canvasState, update, ids = null) => {
    if (ids) {
        return ids
            .reduce((currentCanvasState, id) => updateShapeFromDelta(currentCanvasState, id, update), canvasState);
    }
    return canvasState
        .get('selection').reduce((currentCanvasState, id) => updateShapeFromDelta(currentCanvasState, id, update), canvasState);
};

const updateShapeFromDelta = (canvasState, id, update) => {
    const shapePath = getShapePathWithLayer(canvasState, id);
    const removeCustomStyles = update.getIn(['shapeProps', 'removeCustomStyles']);

    let updatedCanvasState = canvasState;

    const {
        shapeProps = Map(),
        textProps = Map(),
        strokeProps = Map()
    } = getPropertiesForDestructuring(
        update,
        [
            'shapeProps',
            'textProps',
            'strokeProps'
        ]
    );

    const keys = shapeProps.keySeq().toJS();

    const properties = keys.reduce((acc, key) => {
        acc[key] = shapeProps.get(key) + canvasState.getIn(shapePath).get(key);

        return acc;
    }, {});

    if (shapeProps.size > 0) {
        updatedCanvasState = updateShapeProperties(
            updatedCanvasState,
            shapePath,
            Map(properties),
            update.get('isEmphasisStyle')
        );
    }

    if (textProps.size > 0) {
        updatedCanvasState = updateTextProperties(
            updatedCanvasState,
            shapePath,
            textProps,
            removeCustomStyles,
            update.get('isEmphasisStyle')
        );
    }

    if (strokeProps.size > 0) {
        updatedCanvasState = updateStrokeProperties(
            updatedCanvasState,
            shapePath,
            strokeProps,
            removeCustomStyles,
            update.get('isEmphasisStyle')
        );
    }

    return updatedCanvasState;
};

const updateShape = (canvasState, id, update) => {
    const shapePath = getShapePathWithLayer(canvasState, id);
    const removeCustomStyles = update.getIn(['shapeProps', 'removeCustomStyles']);

    let updatedCanvasState = canvasState;

    const {
        shapeProps = Map(),
        textProps = Map(),
        strokeProps = Map()
    } = getPropertiesForDestructuring(
        update,
        [
            'shapeProps',
            'textProps',
            'strokeProps'
        ]
    );

    if (shapeProps.size > 0) {
        updatedCanvasState = updateShapeProperties(
            updatedCanvasState,
            shapePath,
            shapeProps,
            update.get('isEmphasisStyle')
        );
    }

    if (textProps.size > 0) {
        updatedCanvasState = updateTextProperties(
            updatedCanvasState,
            shapePath,
            textProps,
            removeCustomStyles,
            update.get('isEmphasisStyle')
        );
    }

    if (strokeProps.size > 0) {
        updatedCanvasState = updateStrokeProperties(
            updatedCanvasState,
            shapePath,
            strokeProps,
            removeCustomStyles,
            update.get('isEmphasisStyle')
        );
    }

    return updatedCanvasState;
};

const updateShapes = (canvasState, update, ids = null) => {
    if (ids) {
        return ids.reduce((currentCanvasState, id) => updateShape(currentCanvasState, id, update), canvasState);
    }
    return canvasState
        .get('selection').reduce((currentCanvasState, id) => updateShape(currentCanvasState, id, update), canvasState);
};

const updateShapeProperties = (
    canvasState,
    shapePath,
    update,
    isEmphasisStyle = false
) => {
    const {
        assignedShapeStyle,
        removeCustomStyles = false
    } = getPropertiesForDestructuring(
        update,
        [
            'assignedShapeStyle',
            'removeCustomStyles'
        ]
    );

    const shapeUpdate = omit(update, ['assignedShapeStyle', 'removeCustomStyles'])
        .remove('tableStyleMismatches')
        .remove('styleId');

    let updatedCanvasState = canvasState;

    const shapeStyleToAssign = isEmphasisStyle ?
        canvasState.getIn([...shapePath, 'assignedStyles', 'shape']).merge(assignedShapeStyle) :
        assignedShapeStyle;

    updatedCanvasState = updatedCanvasState
        .setIn(
            shapePath,
            updatedCanvasState.getIn(shapePath).merge(shapeUpdate)
        );

    if (shapeStyleToAssign) {
        updatedCanvasState = updatedCanvasState.setIn(
            [
                ...shapePath,
                'assignedStyles',
                'shape'
            ],
            assignedShapeStyleToCanvasState(
                canvasState.getIn(shapePath),
                assignedShapeStyle,
                canvasState
            )
        );
        if (removeCustomStyles) {
            updatedCanvasState = updatedCanvasState.setIn(
                shapePath,
                initShapeStyle(updatedCanvasState.getIn(shapePath), {})
            );
        }
    }

    return updatedCanvasState;
};

const shouldUpdatePlaceholder = (canvasState, shapePath, update) => {
    const textSelection = update?.textSelection;
    const textBodyPlaceholder = canvasState.getIn([...shapePath, 'textBodyPlaceholder']);

    return !textSelection?.editing ||
        (textSelection?.editing &&
            textSelection?.start === 0 &&
            textSelection?.end === 0 &&
            !textBodyPlaceholder.get('text'));
};

const updateTextProperties = (canvasState, shapePath, update, removeCustomStyles, isEmphasisStyle) => {
    const updatedTextBody = Textbody.applyUpdate(
        canvasState.getIn([...shapePath]),
        canvasState.getIn([...shapePath, 'textBody']),
        update,
        removeCustomStyles,
        isEmphasisStyle
    );
    let updatedCanvasState = canvasState.setIn(
        [...shapePath, 'textBody'],
        updatedTextBody
    );
    if (shouldUpdatePlaceholder(canvasState, shapePath, update)) {
        const updatedtextBodyPlaceholder = Textbody.applyUpdate(
            canvasState.getIn([...shapePath]),
            canvasState.getIn([...shapePath, 'textBodyPlaceholder']),
            update,
            removeCustomStyles,
            isEmphasisStyle
        );
        updatedCanvasState = updatedCanvasState.setIn(
            [...shapePath, 'textBodyPlaceholder'],
            updatedtextBodyPlaceholder
        );
    }
    return updatedCanvasState;
};

const updateStrokeProperties = (
    canvasState,
    shapePath,
    update,
    removeCustomStyles = false,
    isEmphasisStyle = false
) => {
    const strokePath = [...shapePath, 'stroke'];

    const {
        assignedStrokeStyle
    } = getPropertiesForDestructuring(
        update,
        [
            'assignedStrokeStyle'
        ]
    );

    const strokeUpdate = omit(update, ['assignedStrokeStyle'])
        .remove('removeCustomStyles')
        .remove('tableStyleMismatches')
        .remove('styleId');

    let updatedCanvasState = canvasState;

    const strokeStyleToAssign = isEmphasisStyle ?
        canvasState.getIn([...shapePath, 'assignedStyles', 'stroke']).merge(assignedStrokeStyle) :
        assignedStrokeStyle;

    updatedCanvasState = updatedCanvasState
        .setIn(
            strokePath,
            updatedCanvasState.getIn(strokePath).merge(strokeUpdate)
        );

    if (strokeStyleToAssign) {
        updatedCanvasState = updatedCanvasState.setIn(
            [
                ...shapePath,
                'assignedStyles',
                'stroke'
            ],
            assignedStrokeStyleToCanvasState(
                strokeStyleToAssign,
                canvasState
            )
        );
        if (removeCustomStyles) {
            updatedCanvasState = updatedCanvasState.setIn(
                strokePath,
                Map()
            );
        }
    }

    return updatedCanvasState;
};

const getShapesLayer = canvasState => (
    canvasState.get('editMode') === 'Layout' ? 'layoutShapes' : 'shapes'
);

const addShape = (canvasState, shape) => {
    const layer = getShapesLayer(canvasState);
    const updatedCanvasState = canvasState.setIn([layer], canvasState.getIn([layer]).insert(0, shape));
    return updatedCanvasState;
};

const removeShape = (canvasState, id) => {
    let updatedCanvasState = canvasState;
    const removedShapePath = getShapePathWithLayer(canvasState, id);
    const placeholderToCopy = getPlaceholderToCopy(canvasState, id);
    const shapeToRemove = updatedCanvasState.getIn(removedShapePath);
    const addPlaceholder = isEqual(
        pick(shapeToRemove, ['x', 'y', 'width', 'height', 'textBodyPlaceholder.text']),
        pick(placeholderToCopy, ['x', 'y', 'width', 'height', 'textBodyPlaceholder.text'])
    ) && shapeToRemove.getIn(['textBody', 'text']) !== '';
    updatedCanvasState = updatedCanvasState.removeIn(removedShapePath);

    updatedCanvasState = updatedCanvasState.set(
        'shapes',
        removeEmptyGroupsAndBreakSingleShapeGroups(updatedCanvasState.get('shapes'))
    );

    if (placeholderToCopy && placeholderToCopy.getIn(['textBodyPlaceholder', 'text']) !== '' && addPlaceholder) {
        updatedCanvasState = copyPlaceholderToPage(updatedCanvasState, placeholderToCopy);
    }

    return updatedCanvasState;
};

const copyPlaceholderToPage = (canvasState, layoutPlaceholder) => {
    let updatedCanvasState = canvasState;

    const pagePlaceholder = layoutPlaceholder
        .set('id', UUID().toString())
        .set('placeholderSourceId', layoutPlaceholder.get('id'))
        .set('inLayout', false)
        .set('textBody', Textbody.setDefaultRunStyle(
            layoutPlaceholder.get('textBody'),
            Textbody.getDefaultRunStyle(layoutPlaceholder.get('textBodyPlaceholder'))
        ))
        .set('textBody', Textbody.setDefaultParagraphStyle(
            layoutPlaceholder.get('textBody'),
            Textbody.getDefaultParagraphStyle(layoutPlaceholder.get('textBodyPlaceholder'))
        ));
    updatedCanvasState = addShape(updatedCanvasState, pagePlaceholder);

    return updatedCanvasState;
};

const shouldCopyPlaceholderToPage = (canvasState, updatedShape) => {
    const isEditingPage = canvasState.get('editMode') === 'Page';
    const presentInLayout = updatedShape.get('inLayout') &&
        getLayoutPlaceholders(canvasState).find(placeholder => updatedShape.get('id') === placeholder.get('id'));
    const shape = getShapeById(canvasState, updatedShape.get('id'));
    const textBodyUpdated = shape?.getIn(['textBody', 'text']) !== updatedShape?.getIn(['textBody', 'text']);
    const isPicturePlaceholder = updatedShape.get('placeholderType') === 'PICTURE';
    const isTablePlaceholder = updatedShape.get('placeholderType') === 'TABLE';
    return (
        isEditingPage &&
        presentInLayout &&
        updatedShape.get('isPlaceholder') &&
        (textBodyUpdated || isPicturePlaceholder || isTablePlaceholder)
    );
};

const getPlaceholderToCopy = (canvasState, id) => {
    const shape = canvasState.getIn(getShapePathWithLayer(canvasState, id));
    if (!!shape.get('placeholderType') && canvasState.get('editMode') === 'Page') {
        const layoutPlaceholder = getPlaceholderHomologue(canvasState, shape);
        const isFilledTablePlaceholder = layoutPlaceholder.get('placeholderType') === 'TABLE' && shape.get('type') === 'Table';
        const isFilledImagePlaceholder = layoutPlaceholder.get('placeholderType') === 'PICTURE' && shape.getIn(['shapeStyle', 'fill']);
        const isFilledTextPlaceholder = layoutPlaceholder.getIn('textBody', 'text') !== shape.get('text');
        if (isFilledTextPlaceholder || isFilledImagePlaceholder || isFilledTablePlaceholder) {
            return layoutPlaceholder;
        }
    }
    return null;
};

const getPlaceholderHomologue = (canvasState, shape) => canvasState
    .get(shape.get('inLayout') ? 'shapes' : 'layoutShapes')
    .find(placeholderShape => placeholderShape.get('placeholderSequence') === shape.get('placeholderSequence') &&
        placeholderShape.get('placeholderType') === shape.get('placeholderType'));

const removeEmptyGroupsAndBreakSingleShapeGroups = shapes => {
    let updatedShapes = shapes
        .reduce((currentShapes, shape, index) => {
            if (shape.get('type') === 'Group') {
                const nestedShapes = shape.get('shapes');

                if (nestedShapes.size === 1) {
                    // TODO: Add group offset here so it's positioned properly
                    return currentShapes.set(index, nestedShapes.get(0));
                } else if (nestedShapes.size) {
                    return currentShapes.setIn([index, 'shapes'], removeEmptyGroupsAndBreakSingleShapeGroups(nestedShapes));
                }

                return currentShapes.remove(index);
            }

            return currentShapes;
        }, shapes);

    /// Need to check current level after because sometimes groups can have only one shape after changes
    updatedShapes = updatedShapes
        .reduce((currentShapes, shape, index) => {
            if (shape.get('type') === 'Group') {
                const nestedShapes = shape.get('shapes');

                if (nestedShapes.size === 1) {
                    return currentShapes.set(index, nestedShapes.get(0));
                }
            }

            return currentShapes;
        }, updatedShapes);

    return updatedShapes;
};

const updateSelection = (canvasState, command = fromJS({ selection: [] })) => canvasState
    .set('selection', command.get('selection') || List())
    .set('groupTree', command.get('groupTree'));

const getShapeTypeCount = (shapes, type) => shapes
    .reduce((count, shape) => {
        let updatedCount = count;

        if (shape.get('type') === type) {
            updatedCount++;
        }

        if (shape.get('type') === 'Group') {
            updatedCount += getShapeTypeCount(shape.get('shapes'), type);
        }

        return updatedCount;
    }, 0);

const getNewShapeName = (canvasState, type) => `${type} ${getShapeTypeCount(canvasState.get('shapes'), type) + 1}`;

const getUniqueShapeName = (canvasState, shapeId, shapeName) => {
    let count = 1;
    let newShapeName = shapeName;
    let shapePath = getShapePathWithLayerFromName(canvasState, newShapeName);
    let shape = canvasState.getIn(shapePath);
    while (shape && shape.get('id') !== shapeId) {
        newShapeName = `${shapeName} ${count}`;
        count++;
        shapePath = getShapePathWithLayerFromName(canvasState, newShapeName);
        shape = shapePath.length > 1 ? canvasState.getIn(shapePath) : undefined;
    }
    return newShapeName;
};

const changeNamesAndIds = (canvasState, shapes, changeName, changeId) => shapes
    .reduce((currentShapes, shape, index) => {
        let updatedShapes = currentShapes;

        if (shape.get('type') === 'Group') {
            updatedShapes = updatedShapes.setIn([index, 'shapes'], changeNamesAndIds(canvasState, shape.get('shapes'), changeName, changeId));
        }

        if (changeId) {
            updatedShapes = updatedShapes.setIn([index, 'id'], UUID().toString());
        }

        if (changeName) {
            updatedShapes = updatedShapes.setIn(
                [index, 'name'],
                getUniqueShapeName(canvasState, updatedShapes.getIn([index, 'id']), updatedShapes.getIn([index, 'name']))
            );
        }

        return updatedShapes;
    }, shapes);

const updateFullShapes = (canvasState, updatedShapes) => {
    const shapePaths = updatedShapes.map(shape => getShapePathWithLayer(canvasState, shape.get('id')));
    let updatedCanvasState = canvasState;
    shapePaths.forEach((keyPath, index) => {
        updatedCanvasState = updatedCanvasState.setIn(keyPath, updatedShapes.get(index));
    });
    return updatedCanvasState;
};

const setGenericPageInfoFromLayout = (canvasState, layout) => canvasState
    .set('layoutName', layout.get('name'))
    .set('size', new Map({
        width: layout.get('width') || canvasState.getIn(['size', 'width']),
        height: layout.get('height') || canvasState.getIn(['size', 'height'])
    }));

const removeLinkedLayoutPlaceholders = shapes => {
    let actualIndex;
    return shapes
        .reduce((currentShapes, shape, index) => {
            if (shape.get('type') === 'Group') {
                return currentShapes.setIn([actualIndex || index, 'shapes'], removeLinkedLayoutPlaceholders(shape.get('shapes')));
            } else if (shape.get('placeholderSourceId')) {
                actualIndex = index - 1;
                return currentShapes.remove(index);
            }
            return currentShapes;
        }, shapes);
};

const removeLayoutShapes = canvasState => canvasState
    .set('layoutShapes', List())
    .set('layoutBackground', List());

const getShapesFromLayout = (canvasState, layout) => {
    const layoutShapes = setPlaceholderSequence(canvasState, layout.get('shapes'));
    const layoutBackground = setPlaceholderSequence(canvasState, layout.get('background'));

    return fromJS({
        layoutShapes,
        layoutBackground
    });
};

const setPlaceholderSequence = (canvasState, shapes) => shapes
    .reduce((linkedShapes, shape) => {
        let linkedShape = shape
            .set('inLayout', true);

        if ((shape.get('placeholderType') || '').length > 0) {
            const linkablePlaceholder = getLinkableLayoutPlaceholder(canvasState, shape, linkedShapes);
            if (linkablePlaceholder) {
                linkedShape = linkedShape
                    .set('placeholderSequence', linkablePlaceholder.get('placeholderSequence'));
            }
        }

        return linkedShapes
            .concat(List([linkedShape]));
    }, List());

const getLayoutPlaceholders = canvasState => canvasState
    .get('layoutShapes')
    .filter(shape => !!shape.get('placeholderType'));

const getPagePlaceholders = canvasState => canvasState
    .get('shapes')
    .filter(shape => !!shape.get('placeholderType'));

// eslint-disable-next-line max-len
const getLinkableLayoutPlaceholder = (canvasState, placeholderToLink, linkedShapes = List()) => getLayoutPlaceholders(canvasState)
    .find(oldLayoutPlaceholder => (
        oldLayoutPlaceholder.get('placeholderType') === placeholderToLink.get('placeholderType') &&
        !linkedShapes.find(linkedShape => (
            linkedShape.get('placeholderType') === oldLayoutPlaceholder.get('placeholderType') &&
            linkedShape.get('placeholderSequence') === oldLayoutPlaceholder.get('placeholderSequence')
        ))
    ));

const splitGroupUpdate = update => {
    const shapePropertiesToApplyDirectlyOnGroup = [
        'flipX',
        'flipY',
        'height',
        'lockAspectRatio',
        'name',
        'rotation',
        'skewX',
        'skewY',
        'width',
        'x',
        'y'
    ];

    const shapeProps = update.get('shapeProps');
    const updateExceptShapeProps = omit(update, ['shapeProps']);

    return {
        groupUpdate: Map({
            shapeProps: pick(shapeProps, shapePropertiesToApplyDirectlyOnGroup)
        }),
        childrenUpdate: updateExceptShapeProps
            .merge(
                Map({
                    shapeProps: omit(shapeProps, shapePropertiesToApplyDirectlyOnGroup)
                })
            )
    };
};

const getGroupChildrenIds = (canvasState, groupId) => {
    const groupPath = getShapePathWithLayer(canvasState, groupId);
    return canvasState
        .getIn([...groupPath, 'shapes'])
        .reduce((ids, shape) => {
            const current = [
                ...ids,
                ...(shape.get('type') === 'Group' ? getGroupChildrenIds(canvasState, shape.get('id')) : [shape.get('id')])
            ];
            return current;
        }, []);
};

const updateGroupChildren = (canvasState, groupId, update) => {
    const ids = getGroupChildrenIds(canvasState, groupId);
    return updateShapes(canvasState, update, ids);
};

const updateGroup = (canvasState, id, update) => {
    const { groupUpdate, childrenUpdate } = splitGroupUpdate(update);
    const canvasStateWithGroupUpdate = updateShape(canvasState, id, groupUpdate);
    return updateGroupChildren(canvasStateWithGroupUpdate, id, childrenUpdate);
};

const updateGroups = (canvasState, update, ids = null) => {
    if (ids) {
        return ids.reduce((currentCanvasState, id) => updateGroup(currentCanvasState, id, update), canvasState);
    }
    return canvasState
        .get('selection').reduce((currentCanvasState, id) => updateGroup(currentCanvasState, id, update), canvasState);
};

const changeAllChildrenIds = shape => shape
    .set(
        'shapes',
        shape.get('shapes')
            .map(child => {
                let updatedChild = child;
                updatedChild = child.set('id', UUID().toString());
                if (child.get('shapes')) {
                    updatedChild = changeAllChildrenIds(child);
                }
                return updatedChild;
            })
    );

const pasteShapes = (canvasState, shapes, shouldChangeXY = false) => {
    const context = canvasState.get('editMode');
    const updatedCanvasState = shapes.reduce((currentCanvasState, shape) => {
        let newShape = shape.set('id', UUID().toString())
            .set('name', getNewShapeName(currentCanvasState, shape.get('type')))
            .set('inLayout', context.toLowerCase() === 'layout')
            .set('x', shape.get('x') + (shouldChangeXY ? 5 : 0))
            .set('y', shape.get('y') + (shouldChangeXY ? 5 : 0))
            .delete('isCut')
            .delete('originalPage');
        if (newShape.get('type') === 'Group') {
            newShape = changeAllChildrenIds(newShape);
        }
        return addShape(currentCanvasState, newShape);
    }, canvasState);
    return updatedCanvasState;
};

const enforceTextBodyDefaultsOnPlaceholder = textbox => {
    let updatedTextbox = textbox;
    updatedTextbox = updatedTextbox.set(
        'textBodyPlaceholder',
        Textbody.applyTextBodyStructure(updatedTextbox.get('textBodyPlaceholder'), textbox.get('textBody'))
    );
    updatedTextbox = updatedTextbox.set(
        'textBody',
        Textbody.applyTextBodyStructure(updatedTextbox.get('textBody'), textbox.get('textBody'))
    );
    return updatedTextbox;
};

export {
    addShape,
    updateShape,
    updateShapeFromDelta,
    updateShapesFromDelta,
    updateFullShapes,
    updateShapes,
    removeShape,
    updateSelection,
    getShapePath,
    getShapePathWithLayer,
    getShapePathFromName,
    getShapeRoot,
    getInsertionPath,
    getInsertionPathWithLayer,
    getAbsolutePosition,
    getAbsolutePositionWithLayer,
    cloneShape,
    getUniqueShapeName,
    getNewShapeName,
    changeNamesAndIds,
    removeEmptyGroupsAndBreakSingleShapeGroups,
    setGenericPageInfoFromLayout,
    removeLinkedLayoutPlaceholders,
    removeLayoutShapes,
    shouldCopyPlaceholderToPage,
    getLayoutPlaceholders,
    getPagePlaceholders,
    getShapesFromLayout,
    getShapesLayer,
    updateGroups,
    getGroupChildrenIds,
    pasteShapes,
    enforceTextBodyDefaultsOnPlaceholder,
    copyPlaceholderToPage
};
