const { fabric } = require('fabric');
const Textshape = require('./TextShape');
const { flipDimension } = require('../utilities/FlipDimensions');
const TextBoundsCalculator = require('../utilities/TextBoundsCalculator');
const { SHAPE_RADIUS } = require('./ModificationHandlers/config/defaultModificationHandlerAttributes.config');
const {
    isTransparent,
    isNoFill
} = require('../../utilities/stringUtility');

const Rectangle = fabric.util.createClass(Textshape, {
    type: 'rectangle',

    initialize(json, opts) {
        this.callSuper('initialize', json, opts);
        this.initialRx = json.rx || 0;
        this.initialRy = json.ry || 0;
        this.shapeType = 'Rectangle';
        this.getHandlerPosition = this.getHandlerPosition.bind(this);
        this.getHandlerLimits = this.getHandlerLimits.bind(this);
        this.updateModifierAttribute = this.updateModifierAttribute.bind(this);
        this.handleScale = this.handleScale.bind(this);
        this.on('scaling', this.handleScale);
        this.on('text:load', () => {
            const shape = this.getShapeObject();
            if (shape.scaleX !== 1 || shape.scaleY !== 1) {
                shape.rx /= shape.scaleX;
                shape.ry /= shape.scaleY;
                shape.set('dirty', true);
            }
        });
    },

    generateModificationHandlers() {
        if (this.shouldHaveModificationHandlers()) {
            this.handlers = [
                new fabric.GenericModificationHandler(
                    this,
                    this.getHandlerPosition,
                    this.getHandlerLimits,
                    this.updateModifierAttribute,
                    { lockVertical: true }
                )
            ];
        }
    },

    shouldHaveModificationHandlers() {
        return !this.lockPath && (
            (
                !isTransparent(this.fill?.color?.value) &&
                !isNoFill(this.fill)
            ) ||
            this.getShapeObject()?.strokeWidth
        );
    },

    getHandlerPosition() {
        const relativePosition = this.getRelativeHandlerPosition();
        const rectangle = this.getShapeObject();
        const absolutePosition = this.getAbsolutePosition();
        return {
            x: absolutePosition.x + flipDimension(rectangle.flipX, relativePosition.x),
            y: absolutePosition.y + flipDimension(rectangle.flipY, relativePosition.y + SHAPE_RADIUS)
        };
    },

    getRelativeHandlerPosition() {
        const rectangle = this.getShapeObject();
        const cornerRadius = rectangle.rx >= (rectangle.width / 2) ? (rectangle.width / 2) :
            (rectangle.rx || 0);
        const activeScaleX = this.scaleX * rectangle.scaleX;
        const activeScaleY = this.scaleY * rectangle.scaleY;

        return {
            x: (cornerRadius - (rectangle.width / 2)) * activeScaleX,
            y: -(rectangle.height / 2) * activeScaleY
        };
    },

    getHandlerLimits() {
        const rectangle = this.getShapeObject();
        const halfWidth = this.width / 2;
        const halfStrokeWidth = (rectangle.strokeWidth / 2) * rectangle.scaleX;
        const absolutePosition = this.getAbsolutePosition();

        return {
            min: rectangle.flipX ?
                absolutePosition.x + halfStrokeWidth :
                absolutePosition.x - halfWidth,
            max: rectangle.flipX ?
                absolutePosition.x + halfWidth :
                absolutePosition.x - halfStrokeWidth
        };
    },

    updateModifierAttribute({ x }) {
        this.initialRx = this.getNewCornerRadius(x);
        this.initialRy = this.initialRx;
        this.updateRoundedCornersForScale();
    },

    getNewCornerRadius(x) {
        const rectangle = this.getShapeObject();
        const absolutePosition = this.getAbsolutePosition();
        return (rectangle.getScaledWidth() / 2) +
            flipDimension(rectangle.flipX, x - absolutePosition.x);
    },

    handleScale() {
        this.updateRoundedCornersForScale();
    },

    updateRoundedCornersForScale() {
        const shape = this.getShapeObject();
        shape.rx = this.initialRx / (this.scaleX * shape.scaleX);
        shape.ry = this.initialRy / (this.scaleY * shape.scaleY);
        shape.set('dirty', true);
    },

    updateShapeScale(scaleX, scaleY) {
        const shape = this.getShapeObject();

        if (shape.rx) {
            shape.set({
                rx: scaleX * shape.rx
            });
        }

        if (shape.ry) {
            shape.set({
                ry: scaleY * shape.ry
            });
        }

        this.callSuper('updateShapeScale', scaleX, scaleY);
    },

    getTextBounds() {
        const shape = this.getShapeObject();
        return new TextBoundsCalculator.Rectangle(
            this.width,
            this.height,
            {
                rx: this.initialRx,
                flipX: shape.flipX
            }
        );
    },

    toObject(propertiesToInclude = []) {
        const serializedRectangle = this.callSuper('toObject', propertiesToInclude);
        return {
            ...serializedRectangle,
            rx: this.initialRx,
            ry: this.initialRy
        };
    }
});

Rectangle.fromObject = (object, callback) => {
    const rectangle = new Rectangle(object);
    rectangle.on('text:load', () => {
        callback(rectangle);
    })
        .on('load:error', err => {
            console.error(err);
            callback(null);
        });
};

module.exports = Rectangle;
