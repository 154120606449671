import { getContentPath } from './utils';

const getAssignedStyles = (table, cell, content, canvasState) => canvasState.getIn(
    [
        ...getContentPath(table, cell, content, canvasState),
        'assignedStyles'
    ]
);

export default getAssignedStyles;
