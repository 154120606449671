import { Map, fromJS, List } from 'immutable';
import {
    isNil, omit, isEqual, get
} from 'lodash';
import TEXTBODY_PROPERTIES from '../Config/textBodyRecordProperties';
import { getParagraphsCount, getParagraphs } from './Paragraphs';
import removeUnusedStyles from '../Utils/removeUnusedStyles';
import { getTextWithStyle } from '../Runs/RunStyles';
import { DEFAULT_PARAGRAPHSTYLE } from '../Config/defaultStyles';
import cleanupStyles from '../Utils/cleanupStyles';

const setDefaultParagraphStyle = (textBodyData, newDefault) => textBodyData
    .update(
        TEXTBODY_PROPERTIES.PARAGRAPH_STYLES,
        (paragraphStyles = {}) => {
            let mutableParagraphStyles = paragraphStyles;
            const defaultIndex = mutableParagraphStyles
                .findIndex(style => style.get('isDefault') === true);
            const defaultStyle = defaultIndex === -1 ?
                Map({ isDefault: true }) :
                mutableParagraphStyles.get(defaultIndex);
            const updatedDefault = defaultStyle.mergeDeep(newDefault);
            mutableParagraphStyles = mutableParagraphStyles.map((style, index) => {
                if (index === defaultIndex) {
                    return updatedDefault;
                }
                const updatedStyle = style.filter((value, key) => {
                    if (isEqual(style.get(key), updatedDefault.get(key))) {
                        return false;
                    }
                    return true;
                });
                return updatedStyle;
            });
            if (defaultIndex === -1) {
                mutableParagraphStyles = mutableParagraphStyles.unshift(updatedDefault);
            }
            return mutableParagraphStyles;
        }
    );

const getDefaultParagraphStyleIndex = textBodyData => {
    const index = textBodyData
        .get(TEXTBODY_PROPERTIES.PARAGRAPH_STYLES)
        .findIndex(style => style.get('isDefault'));
    if (index === -1) {
        return textBodyData.get(TEXTBODY_PROPERTIES.PARAGRAPH_STYLES).size;
    }
    return index;
};

const getDefaultParagraphStyle = textBodyData => (
    textBodyData ? textBodyData.getIn([
        TEXTBODY_PROPERTIES.PARAGRAPH_STYLES,
        getDefaultParagraphStyleIndex(textBodyData)
    ], Map()) : Map()
);

const getParagraphStyleAt = (textBodyData, index) => textBodyData
    .getIn([TEXTBODY_PROPERTIES.PARAGRAPH_STYLES, index]);

const getParagraphStyleIndex = (textBodyData, query) => textBodyData
    .get(TEXTBODY_PROPERTIES.PARAGRAPH_STYLES)
    .findIndex(paragraphStyle => isEqual(omit(query, ['isDefault']), omit(paragraphStyle, ['isDefault'])));

const setParagraphStyle = (textBodyData, paragraphIndex, style) => textBodyData.updateIn(
    [TEXTBODY_PROPERTIES.PARAGRAPHS, paragraphIndex],
    paragraph => paragraph.set('style', style)
);

const getParagraphStyle = (textBodyData, index, withDefault = false) => {
    const style = getParagraphStyleAt(textBodyData, index);
    return withDefault ?
        Map().mergeDeep(getDefaultParagraphStyle(textBodyData)).mergeDeep(style) :
        style;
};

const getParagraphsWithParagraphStyle = (
    textBodyData,
    startIndex = 0,
    endIndex = getParagraphsCount(textBodyData) - 1,
    withDefault
) => {
    const paragraphs = getParagraphs(textBodyData, startIndex, endIndex)
        .map(paragraph => {
            let style = Map({});
            if (!isNil(paragraph.get('style'))) {
                style = getParagraphStyle(textBodyData, paragraph.get('style'), withDefault);
            } else if (withDefault) {
                style = getDefaultParagraphStyle(textBodyData);
            }
            return paragraph.set('style', style);
        });
    return paragraphs;
};

const addParagraphStyle = (textBodyData, paragraphStyle) => textBodyData.update(
    TEXTBODY_PROPERTIES.PARAGRAPH_STYLES,
    paragraphStyles => paragraphStyles.push(Map.isMap(paragraphStyle) ? paragraphStyle : fromJS(paragraphStyle))
);

const getParagraphStylesCount = textBodyData => textBodyData
    .get(TEXTBODY_PROPERTIES.PARAGRAPH_STYLES).size;

const removeUnusedParagraphStyles = textBodyData => removeUnusedStyles(
    textBodyData,
    TEXTBODY_PROPERTIES.PARAGRAPH_STYLES,
    TEXTBODY_PROPERTIES.PARAGRAPHS
);

const setParagraphStyleProperties = (
    textBodyData,
    properties,
    startIndex = 0,
    endIndex = getParagraphsCount(textBodyData) - 1
) => {
    const updatedTextBody = getParagraphsWithParagraphStyle(textBodyData, startIndex, endIndex)
        .reduce((reducedTextBody, paragraph, index) => {
            let textBody = reducedTextBody;
            const newStyle = (paragraph.get('style') || Map()).mergeDeep(properties.set('isDefault', false));
            const defaultParagraphStyle = getDefaultParagraphStyle(textBody);
            const newParagraphStyle = defaultParagraphStyle.mergeDeep(newStyle);

            let newParagraphStyleIndex = getParagraphStyleIndex(
                textBody,
                newParagraphStyle
            );

            if (newParagraphStyleIndex === -1) {
                newParagraphStyleIndex = getParagraphStylesCount(textBody);
                textBody = addParagraphStyle(textBody, newParagraphStyle);
            }
            return setParagraphStyle(
                textBody,
                startIndex + index,
                newParagraphStyleIndex
            );
        }, textBodyData);
    return removeUnusedParagraphStyles(updatedTextBody);
};

const getParagraphsWithStyleAndIndex = (
    textBodyData,
    startIndex,
    endIndex,
    withDefault
) => getParagraphsWithStyle(textBodyData, startIndex, endIndex, withDefault)
    .map((paragraph, index) => paragraph.set('index', index));

const getParagraphsWithStyle = (
    textBodyData,
    startIndex = 0,
    endIndex = getParagraphsCount(textBodyData) - 1,
    withDefault
) => getParagraphs(textBodyData, startIndex, endIndex)
    .map(paragraph => paragraph.set('textRuns', getTextWithStyle(
        textBodyData,
        paragraph.get('startIndex'),
        paragraph.get('endIndex'),
        withDefault
    )));

const getParagraphWithStyle = (textBodyData, index, withDefault) => getParagraphsWithStyle(
    textBodyData,
    index,
    index,
    withDefault
)
    .first();

const getItemsForTextSelection = (textBody, textSelection) => {
    if (textSelection) {
        return getParagraphsWithStyleAndIndex(textBody)
            .filter(paragraph => textSelection.get('start') <= paragraph.get('endIndex') + 1 &&
                paragraph.get('startIndex') <= textSelection.get('end'));
    }
    return getParagraphsWithStyleAndIndex(textBody);
};

const getFirstRunInParagraph = (textBodyData, paragraphIndex, withDefault) => {
    const paragraphWithStyle = getParagraphWithStyle(
        textBodyData,
        paragraphIndex,
        withDefault
    );
    return paragraphWithStyle.get('textRuns').get(0);
};

const getParagraphStyles = (textBodyData, withDefault) => List(
    textBodyData
        .get(TEXTBODY_PROPERTIES.PARAGRAPHS)
        .reduce(
            (acc, paragraph) => (isNil(paragraph.get('style')) && acc) || acc.add(paragraph.get('style')),
            new Set()
        )
)
    .map(i => getParagraphStyle(textBodyData, i, withDefault));

const isParagraphStyleListType = (textBodyData, paragraphStyleIndex) => {
    const paragraphStyle = getParagraphStyle(textBodyData, paragraphStyleIndex, true);
    return get(paragraphStyle, 'bullet.type', 'none') !== 'none';
};

const cleanUpParagraphStyles = textBody => {
    let updatedTextBody = textBody;
    const fullDefaultStyle = updatedTextBody
        .getIn([TEXTBODY_PROPERTIES.ASSIGNED_STYLES, 'paragraph'], Map())
        .mergeDeep(DEFAULT_PARAGRAPHSTYLE)
        .mergeDeep(getDefaultParagraphStyle(updatedTextBody));
    const updatedParagraphStyles = List([
        getDefaultParagraphStyle(updatedTextBody),
        ...cleanupStyles(
            fullDefaultStyle,
            updatedTextBody.get(TEXTBODY_PROPERTIES.PARAGRAPH_STYLES)
        )
    ]);
    updatedTextBody = updatedTextBody.set(TEXTBODY_PROPERTIES.PARAGRAPH_STYLES, updatedParagraphStyles);
    return updatedTextBody;
};

const getRenderParagraphStyle = textBody => {
    const assignedParagraphStyle = textBody.get(TEXTBODY_PROPERTIES.ASSIGNED_STYLES).get('paragraph');
    const defaultApplicationParagraphStyle = DEFAULT_PARAGRAPHSTYLE;
    const defaultStyle = getDefaultParagraphStyle(textBody);
    return assignedParagraphStyle.mergeDeep(defaultApplicationParagraphStyle).mergeDeep(defaultStyle).toJS();
};

export {
    getParagraphStyle,
    getDefaultParagraphStyle,
    getDefaultParagraphStyleIndex,
    setDefaultParagraphStyle,
    setParagraphStyleProperties,
    removeUnusedParagraphStyles,
    getParagraphStylesCount,
    getParagraphsWithParagraphStyle,
    getParagraphsWithStyle,
    getParagraphWithStyle,
    getParagraphsWithStyleAndIndex,
    getItemsForTextSelection,
    getFirstRunInParagraph,
    getParagraphStyles,
    isParagraphStyleListType,
    getParagraphStyleAt,
    cleanUpParagraphStyles,
    getRenderParagraphStyle
};
