import { getChevronPoints } from '../../../../../utilities/ShapeGenerators';
import isFull from './isFull';
import getTipWidth from './tipWidth';

const getPoints = shape => getChevronPoints(
    shape.get('width'),
    shape.get('height'),
    getTipWidth(shape),
    isFull(shape)
);

export default getPoints;
