import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getSentryConfig } from '#Selectors/configs';

const SENTRY_END_POINT = 'https://570f55b274ed4553bc503161ac2f7076@sentry.io/1262897';

if (document.location.hostname !== 'localhost') {
    Raven
        .config(SENTRY_END_POINT)
        .install();
}

export default WrappedComponent => {
    class With extends React.Component {
        static propTypes = {
            sentry: PropTypes.shape({
                env: PropTypes.string,
                shouldLogErrors: PropTypes.bool
            })
        }

        static defaultProps = {
            sentry: undefined
        }

        componentDidUpdate(prevProps) {
            const {
                sentry = {}
            } = this.props;

            const {
                sentry: prevSentry = {}
            } = prevProps;

            if (sentry && sentry.shouldLogErrors && prevSentry.env !== sentry.env) {
                Raven.setEnvironment(sentry.env);
            }
        }

        render() {
            return <WrappedComponent {...this.props} />;
        }
    }

    const mapStateToProps = state => ({
        sentry: getSentryConfig(state)
    });

    return connect(mapStateToProps)(With);
};
