import { getParagraph } from '../../../../CanvasState/Helpers/Text/Paragraphs/Paragraphs';
import { setDefaultParagraphStyle, setParagraphStyleProperties } from '../../../../CanvasState/Helpers/Text/Paragraphs/ParagraphStyles';
import { setDefaultRunStyle } from '../../../../CanvasState/Helpers/Text/Runs/RunStyles';
import Textbody from '../../../../CanvasState/Helpers/Text/TextBody';
import {
    addParagraphWithStyle,
    getFullTextParagraphStyle,
    getFullTextRunStyle,
    setParagraphTextWithStyle
} from '../../../../CanvasState/Helpers/Text/TextBodyProperties/TextWithStyles';
import adaptFabricTextStyleToParagraphStyle from './paragraphStyleAdapter';
import adaptFabricCharacterStyleToRunStyle from './runStyleAdapter';

const adaptFabricParagraphToParagraph = ({
    fabricParagraph: {
        text,
        styles: fabricMultiLineCharacterStyles,
        ...fabricTextStyle
    },
    textBody,
    colorPalette,
    paragraphIndex
}) => {
    let updatedTextBody = textBody;
    const paragraphRunStyle = adaptFabricCharacterStyleToRunStyle(fabricTextStyle, colorPalette);
    if (paragraphIndex === 0) {
        updatedTextBody = setParagraphTextWithStyle(
            updatedTextBody,
            0,
            text,
            paragraphRunStyle
        );
    } else {
        updatedTextBody = addParagraphWithStyle(
            updatedTextBody,
            text,
            paragraphRunStyle
        );
    }

    const [fabricCharacterStyles] = mergeFabricMultiLineStyles({
        styles: fabricMultiLineCharacterStyles,
        text
    });

    updatedTextBody = adaptFabricCharacterStylesToRunStyles({
        textBody: updatedTextBody,
        paragraphIndex,
        fabricCharacterStyles,
        colorPalette,
        text
    });

    updatedTextBody = adaptFabricTextStyleToCanvasStateStyle({
        textBody: updatedTextBody,
        fabricTextStyle,
        paragraphIndex,
        colorPalette
    });

    return updatedTextBody;
};

const adaptFabricParagraphsToTextBody = (fabricParagraphs, oldTextBody, colorPalette) => {
    let textBody = oldTextBody;
    textBody = Textbody.removeParagraphs(textBody);
    const [lastFabricParagraph] = fabricParagraphs.slice(-1);
    const lastBullet = adaptFabricTextStyleToParagraphStyle(lastFabricParagraph).get('bullet');
    if (lastBullet) {
        textBody = textBody.set('bullets', lastBullet);
    }
    fabricParagraphs.forEach((fabricParagraph, paragraphIndex) => {
        textBody = adaptFabricParagraphToParagraph({
            textBody,
            fabricParagraph,
            colorPalette,
            paragraphIndex
        });
    });
    textBody = setDefaultParagraphStyle(textBody, getFullTextParagraphStyle(textBody));
    textBody = setDefaultRunStyle(textBody, getFullTextRunStyle(textBody));
    return textBody;
};

const mergeFabricMultiLineStyles = ({
    styles: [mergedStyles, currentStyles, ...nextStyles],
    text,
    currentOffset = 0
}) => {
    const currentParagraphOffset = text
        .substr(currentOffset)
        .indexOf('\n') + (1 + currentOffset);

    if (currentParagraphOffset > 0 && currentStyles) {
        const currentMergedStyles = Object.entries(currentStyles).reduce(
            (accumulatedMergedStyles, [serializedCharacterIndex, style]) => {
                const characterIndex = Number.parseInt(serializedCharacterIndex, 10);
                return {
                    ...accumulatedMergedStyles,
                    [characterIndex + currentParagraphOffset]: style
                };
            },
            mergedStyles
        );

        return mergeFabricMultiLineStyles({
            styles: [
                currentMergedStyles,
                ...nextStyles
            ],
            text,
            currentOffset: currentParagraphOffset
        });
    }
    return [mergedStyles];
};

const adaptFabricCharacterStylesToRunStyles = ({
    textBody,
    paragraphIndex,
    fabricCharacterStyles = {},
    colorPalette
}) => {
    let updatedTextBody = textBody;
    const paragraph = getParagraph(updatedTextBody, paragraphIndex);

    Object.entries(fabricCharacterStyles).forEach(([index, fabricCharacterStyle]) => {
        const runStyle = adaptFabricCharacterStyleToRunStyle(
            fabricCharacterStyle,
            colorPalette
        );
        const textBodyIndex = Number.parseInt(index, 10) + paragraph.get('startIndex');
        updatedTextBody = Textbody.setStyleProperties(
            updatedTextBody,
            runStyle,
            textBodyIndex,
            textBodyIndex
        );
    });

    return updatedTextBody;
};

const adaptFabricTextStyleToCanvasStateStyle = ({
    textBody,
    fabricTextStyle,
    paragraphIndex,
    colorPalette
}) => {
    let updatedTextBody = textBody;
    const paragraphStyle = adaptFabricTextStyleToParagraphStyle(fabricTextStyle, colorPalette);

    updatedTextBody = setParagraphStyleProperties(
        updatedTextBody,
        paragraphStyle,
        paragraphIndex
    );

    return updatedTextBody;
};

export default adaptFabricParagraphsToTextBody;
