const Rectangle = require('./Rectangle');
const { removeZeroSpaceChars } = require('../utilities/text');
const Shape = require('./Shape');

class Textbox extends Rectangle {
    constructor(name, posX, posY, attributes = {}) {
        super(name, posX, posY, attributes);
    }

    static isSerializedFabricObject() {
        return false;
    }

    get text() {
        return this.textBody.getText();
    }

    set text(text) {
        this.textBody.setText(removeZeroSpaceChars(text) || '');
    }

    get isTextEmpty() {
        return !this.textBody.getText();
    }
    // eslint-disable-next-line
    set isTextEmpty(isTextEmpty) {
    }
}

Shape.addToConstructorList(Textbox, ['textBox', 'textbox', 'Textbox']);

module.exports = Textbox;
