const { defaultStrokeAttributes } = require('../../Table/config/defaultStrokeAttributes.config.js');

class StrokeEnd {
    constructor(
        width = 'medium',
        type = 'none',
        length = 'medium'
    ) {
        this.width = width;
        this.type = type;
        this.length = length;
    }

    toJSON() {
        return {
            width: this.width,
            type: this.type,
            length: this.length
        };
    }

    static fromJSON(jsonObject = {}, type = 'head') {
        return new this(
            jsonObject.width || defaultStrokeAttributes[type].width,
            jsonObject.type || defaultStrokeAttributes[type].type,
            jsonObject.length || defaultStrokeAttributes[type].length
        );
    }
}

module.exports = StrokeEnd;
