import { fromJS } from 'immutable';

const defaultStrokeAttributes = {
    width: 1,
    tail: {
        width: 'medium',
        type: 'none',
        length: 'medium'
    },
    join: {
        type: 'round'
    },
    head: {
        width: 'medium',
        type: 'none',
        length: 'medium'
    },
    fill: {
        color: {
            type: 'custom',
            value: 'rgba(0, 0, 0, 1)'
        },
        type: 'color'
    },
    dash: {
        type: 'solid'
    },
    compoundType: 'single',
    cap: 'flat',
    align: 'center'
};

const immutableDefaultStrokeAttributes = fromJS(defaultStrokeAttributes);

export {
    defaultStrokeAttributes,
    immutableDefaultStrokeAttributes
};
