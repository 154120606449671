const complementAgainstSources = require('../../../utilities/complementAgainstSources');
const Stroke = require('../../Stroke/Stroke');
const defaultsDeepWithExceptions = require('../../../utilities/defaultsDeepWithExceptions');
const Fill = require('../../Fill');

module.exports = Base => class extends Base {
    getRenderStroke() {
        return defaultsDeepWithExceptions(
            this.stroke ? this.stroke.toJSON() : {},
            (this.assignedStrokeStyle ?
                this.assignedStrokeStyle.toJSON() :
                {}),
            this.defaultStroke.toJSON(),
            Stroke.atomicProperties
        );
    }

    setComplementStrokeAsItemStrokeStyle() {
        this.stroke = Stroke.fromJSON(complementAgainstSources(
            (this.stroke ? this.stroke.toJSON() : {}),
            this.defaultStroke.toJSON(),
            (this.assignedStrokeStyle ?
                this.assignedStrokeStyle.toJSON() :
                {}),
            Stroke.atomicProperties
        ));
    }

    copyAssignedStrokeStyle(original) {
        if (original.assignedStrokeStyle) {
            this.assignedStrokeStyle = Stroke
                .fromJSON(original.assignedStrokeStyle.toJSON());
        }
    }

    updateStrokeColorPresets({ presets, scheme }) {
        if (this.stroke && this.stroke.fill) {
            const oldOpacity = this.stroke.opacity;
            this.stroke.fill = Fill.updateColorPresets(this.stroke.fill, { presets, scheme });
            this.stroke.opacity = oldOpacity;
        }
        if (this.defaultStroke && this.defaultStroke.fill) {
            const oldOpacity = this.defaultStroke.opacity;
            this.defaultStroke.fill = Fill
                .updateColorPresets(this.defaultStroke.fill, { presets, scheme });
            this.defaultStroke.opacity = oldOpacity;
        }
        if (this.assignedStrokeStyle && this.assignedStrokeStyle.fill) {
            const oldOpacity = this.assignedStrokeStyle.opacity;
            this.assignedStrokeStyle.fill = Fill
                .updateColorPresets(this.assignedStrokeStyle.fill, { presets, scheme });
            this.assignedStrokeStyle.opacity = oldOpacity;
        }
    }

    forceStrokeFillFromRendered() {
        if (this.stroke.fill === undefined) {
            this.stroke.fill = this.getRenderStroke().fill;
        }
    }

    set assignedStrokeStyle(stroke) {
        if (stroke === undefined) {
            this._strokeAssignedStyle = undefined;
            return;
        }
        if (!(stroke instanceof Stroke)) {
            throw new Error('Shape assigned style should be an instance of Shape style');
        }
        stroke.canvasItem = this;
        this._strokeAssignedStyle = stroke;
    }

    get assignedStrokeStyle() {
        return this._strokeAssignedStyle;
    }

    get stroke() {
        return this._stroke;
    }

    set stroke(stroke) {
        if (stroke === undefined) {
            this._stroke = undefined;
            return;
        }
        if (!(stroke instanceof Stroke)) {
            throw new Error('Can\'t set stroke with invalid type');
        }

        stroke.canvasItem = this;
        this._stroke = stroke;
    }

    get defaultStroke() {
        const defaultStroke = Stroke.fromJSON(this.defaults.stroke);
        defaultStroke.canvasItem = this;
        return defaultStroke;
    }
};
