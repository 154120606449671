const isNil = require('lodash/isNil');

const {
    adaptColorValueAdapterToFabricColor,
    adaptFabricColorToColorValueDescriptor
} = require('../ColorValueDescriptorAdapter');

exports.adaptBulletStyleToFabricBulletStyle = (
    {
        itemCountStartsAt,
        level = 1,
        style = {},
        text,
        type = 'none'
    } = {},
    colorPalette
) => {
    const fabricBulletStyle = {};
    if (type !== 'none') {
        fabricBulletStyle.type = 'listItem';
        fabricBulletStyle.level = level;
        fabricBulletStyle.bulletType = type;
        if (text) {
            fabricBulletStyle.bulletText = text;
        }
        if (itemCountStartsAt) {
            fabricBulletStyle.itemCountStartsAt = itemCountStartsAt;
        }
        fabricBulletStyle.bulletStyle = {};
        if (style.font) {
            fabricBulletStyle.bulletStyle.fontFamily = style.font;
        }

        if (style.fontWeight) {
            fabricBulletStyle.bulletStyle.fontWeight = style.fontWeight;
        }
        if (style.size) {
            fabricBulletStyle.bulletStyle.fontSize = style.size;
        }
        if (style.color) {
            fabricBulletStyle.bulletStyle.fill = adaptColorValueAdapterToFabricColor(
                style.color,
                colorPalette
            );
        }
    } else {
        fabricBulletStyle.type = 'paragraph';
    }
    return fabricBulletStyle;
};

const adaptPaddingStyleToFabricPaddingStyle = ({
    bottom,
    left,
    right,
    top
} = {}) => {
    const fabricPaddingStyle = {};
    if (bottom) {
        fabricPaddingStyle.paddingBottom = bottom;
    }
    if (left) {
        fabricPaddingStyle.paddingLeft = left;
    }
    if (right) {
        fabricPaddingStyle.paddingRight = right;
    }
    if (top) {
        fabricPaddingStyle.paddingTop = top;
    }
    return fabricPaddingStyle;
};

exports.adaptParagraphStyleToFabricTextStyle = (
    {
        align,
        lineSpacing,
        level = 1,
        bullet,
        padding,
        indent
    } = {},
    colorPalette
) => {
    const fabricTextStyle = {
        ...exports.adaptBulletStyleToFabricBulletStyle({ ...bullet, level }, colorPalette),
        ...adaptPaddingStyleToFabricPaddingStyle(padding)
    };
    if (align) {
        fabricTextStyle.textAlign = align === 'justify' ? 'justify-left' : align;
    }
    if (lineSpacing) {
        fabricTextStyle.lineHeight = lineSpacing;
    }
    if (level) {
        fabricTextStyle.level = level;
    }
    if (!isNil(indent) && !Number.isNaN(indent)) {
        fabricTextStyle.indent = indent;
    }
    return fabricTextStyle;
};

exports.adaptFabricBulletStyleToBulletStyle = (
    {
        bulletType, bulletText, bulletStyle = {}, itemCountStartsAt
    },
    colorPalette
) => {
    const paragraphBulletStyle = {};
    if (bulletType) {
        paragraphBulletStyle.type = bulletType;
    }
    if (bulletText) {
        paragraphBulletStyle.text = bulletText;
    }
    if (itemCountStartsAt) {
        paragraphBulletStyle.itemCountStartsAt = itemCountStartsAt;
    }
    paragraphBulletStyle.style = {};
    if (bulletStyle.fontFamily) {
        paragraphBulletStyle.style.font = bulletStyle.fontFamily;
    }
    if (bulletStyle.fontSize) {
        paragraphBulletStyle.style.size = bulletStyle.fontSize;
    }
    if (bulletStyle.fill) {
        paragraphBulletStyle.style.color = adaptFabricColorToColorValueDescriptor(
            bulletStyle.fill,
            colorPalette
        );
    }
    return paragraphBulletStyle;
};

const adaptFabricPaddingStyleToPaddingStyle = ({
    paddingBottom,
    paddingLeft,
    paddingRight,
    paddingTop
}) => {
    const paddingStyle = {};
    if (paddingBottom) {
        paddingStyle.bottom = paddingBottom;
    }
    if (paddingLeft) {
        paddingStyle.left = paddingLeft;
    }
    if (paddingRight) {
        paddingStyle.right = paddingRight;
    }
    if (paddingTop) {
        paddingStyle.top = paddingTop;
    }
    return paddingStyle;
};

exports.adaptFabricTextStyleToParagraphStyle = (
    {
        textAlign,
        lineHeight,
        bulletType,
        bulletText,
        bulletStyle,
        itemCountStartsAt,
        level,
        paddingBottom,
        paddingLeft,
        paddingRight,
        paddingTop,
        indent
    } = {},
    colorPalette
) => {
    const paragraphStyle = {};
    if (textAlign) {
        paragraphStyle.align = textAlign === 'justify-left' ? 'justify' : textAlign;
    }
    if (lineHeight) {
        paragraphStyle.lineSpacing = lineHeight;
    }
    if (level) {
        paragraphStyle.level = level;
    }
    if (bulletType || bulletText) {
        paragraphStyle.bullet = exports.adaptFabricBulletStyleToBulletStyle(
            {
                bulletType, bulletText, bulletStyle, itemCountStartsAt
            },
            colorPalette
        );
    }
    if (paddingBottom || paddingLeft || paddingRight || paddingTop) {
        paragraphStyle.padding = adaptFabricPaddingStyleToPaddingStyle({
            paddingBottom,
            paddingLeft,
            paddingRight,
            paddingTop
        });
    }
    if (!isNil(indent) && !Number.isNaN(indent)) {
        paragraphStyle.indent = indent;
    }
    return paragraphStyle;
};
