import { createSelector } from 'reselect';
import unionBy from 'lodash/unionBy';
import differenceBy from 'lodash/differenceBy';

const deckSelector = state => state.deck;
const userSelectedTopicsSelector = state => state.plan.userSelectedTopics;
const userUnselectedTopicsSelector = state => state.plan.userUnselectedTopics;
const storyboardTopicsSelector = state => state.plan.topics;
const browseStoryboardTopicsSelector = state => state.plan.browseTopics || [];
const getIsReordering = state => state.plan.isReordering;

const topicsSelector = createSelector(
    [deckSelector, userSelectedTopicsSelector, userUnselectedTopicsSelector, storyboardTopicsSelector],
    (deck, userSelectedTopics, userUnselectedTopics, topics) => ({
        deck,
        userSelectedTopics,
        userUnselectedTopics,
        topics,
        recommendedTopics: topics.filter(topic => !topic.optional).map(topic => topic.topic)
    })
);

const browseTopicsSelector = createSelector(
    [deckSelector, userSelectedTopicsSelector, userUnselectedTopicsSelector, browseStoryboardTopicsSelector],
    (deck, userSelectedTopics, userUnselectedTopics, browseTopics) => ({
        deck,
        userSelectedTopics,
        userUnselectedTopics,
        topics: browseTopics,
        recommendedTopics: browseTopics.filter(topic => !topic.optional).map(topic => topic.topic)
    })
);

const categorizeSelectedTopics = createSelector(topicsSelector, state => {
    if (state.deck) {
        const topicsInDeck = state.deck.pages.map(page => page.topic);
        const totalSelectedTopics = unionBy(topicsInDeck, state.userSelectedTopics, 'id');

        if (state.topics.length > 0 && totalSelectedTopics.length > 0) {
            return totalSelectedTopics.reduce(
                (categorizedTopics, topic) => {
                    const foundInCurrentStoryboard = state.topics.find(key => topic.id === key.topic.id);
                    if (foundInCurrentStoryboard) {
                        if (foundInCurrentStoryboard.optional) {
                            categorizedTopics.optionalTopics.push(topic);
                            return categorizedTopics;
                        }
                        categorizedTopics.recommendedTopics.push(topic);
                        return categorizedTopics;
                    }
                    if (topic.id !== 'BLANK_TOPIC') {
                        categorizedTopics.otherTopics.push(topic);
                        return categorizedTopics;
                    }
                    return categorizedTopics;
                },
                { recommendedTopics: [], optionalTopics: [], otherTopics: [] }
            );
        }
    }
    return {
        recommendedTopics: [],
        optionalTopics: [],
        otherTopics: []
    };
});

const userSelectedTopicsWithStoryboardDefaults = createSelector(topicsSelector, state => {
    if (state.deck) {
        const topicsInStoryBoard = state.topics;
        const recommendedTopics = state.recommendedTopics;
        let totalSelectedTopics = unionBy(recommendedTopics, state.userSelectedTopics, 'id');
        totalSelectedTopics = differenceBy(totalSelectedTopics, state.userUnselectedTopics, 'id');
        return { totalSelectedTopics, topicsInStoryBoard };
    }
    return { totalSelectedTopics: [], topicsInStoryBoard: [] };
});

const userSelectedTopicsOnly = createSelector(browseTopicsSelector, state => {
    if (state.deck) {
        const topicsInStoryBoard = state.topics;
        const totalSelectedTopics = state.userSelectedTopics;
        return { totalSelectedTopics, topicsInStoryBoard };
    }
    return { totalSelectedTopics: [], topicsInStoryBoard: [] };
});

const filterUserSelectedTopicsWithStoryBoard = ({ totalSelectedTopics, topicsInStoryBoard }) => {
    if (totalSelectedTopics.length > 0) {
        return totalSelectedTopics.reduce(
            (categorizedTopics, topic) => {
                const foundInCurrentStoryboard = topicsInStoryBoard.find(key => topic.id === key.topic.id);
                if (foundInCurrentStoryboard) {
                    if (foundInCurrentStoryboard.optional) {
                        categorizedTopics.optionalTopics.push(topic);
                        return categorizedTopics;
                    }
                    categorizedTopics.recommendedTopics.push(topic);
                    return categorizedTopics;
                }
                if (topic.id !== 'BLANK_TOPIC') {
                    categorizedTopics.otherTopics.push(topic);
                    return categorizedTopics;
                }
                return categorizedTopics;
            },
            { recommendedTopics: [], optionalTopics: [], otherTopics: [] }
        );
    }
    return {
        recommendedTopics: [],
        optionalTopics: [],
        otherTopics: []
    };
};

const categorizeUserSelectedTopicsWithSelectedStoryboard = createSelector(
    userSelectedTopicsWithStoryboardDefaults,
    filterUserSelectedTopicsWithStoryBoard
);
const categorizeUserSelectedTopicsWithBrowseStoryboard = createSelector(
    userSelectedTopicsOnly,
    filterUserSelectedTopicsWithStoryBoard
);

const categorizeStoryboardTopics = ({ topics }) => {
    if (topics.length > 0) {
        return topics.reduce((allTopics, topic) => {
            if (!topic.optional) {
                allTopics.recommendedTopics.push(topic.topic);
                return allTopics;
            }
            allTopics.optionalTopics.push(topic.topic);
            return allTopics;
        }, { recommendedTopics: [], optionalTopics: [] });
    }
    return {
        recommendedTopics: [],
        optionalTopics: []
    };
};

const categorizeAllBrowseTopics = createSelector(browseTopicsSelector, categorizeStoryboardTopics);

const categorizeAllTopics = createSelector(topicsSelector, categorizeStoryboardTopics);

export const getAllTopicsForObjective = () => {};

export const getCategorizedSelectedTopics = createSelector(
    [categorizeSelectedTopics],
    categorizedSelectedTopics => categorizedSelectedTopics
);

export const getCategorizedAllTopics = createSelector(
    [categorizeAllTopics],
    categorizedAllTopics => categorizedAllTopics
);

export const getCategorizedAllBrowseTopics = createSelector(
    [categorizeAllBrowseTopics],
    categorizedAllBrowseTopics => categorizedAllBrowseTopics
);

export const getUserSelectedTopicsWithStoryboardDefaults = createSelector(
    [userSelectedTopicsWithStoryboardDefaults],
    ({ totalSelectedTopics }) => totalSelectedTopics
);

export const getCategorizedUserSelectedTopicsWithStoryboardDefaults = createSelector(
    [categorizeUserSelectedTopicsWithSelectedStoryboard],
    categorizedUserSelectedTopicsWithStoryboardDefaults => categorizedUserSelectedTopicsWithStoryboardDefaults
);

export const getCategorizedUserSelectedTopicsWithBrowseStoryboard = createSelector(
    [categorizeUserSelectedTopicsWithBrowseStoryboard],
    categorizedUserSelectedTopicsWithBrowseStoryboard => categorizedUserSelectedTopicsWithBrowseStoryboard
);

export const isUpdatingDeckFromPlan = createSelector(
    getIsReordering,
    isReordering => !!isReordering
);

export const isLoading = createSelector(
    isUpdatingDeckFromPlan,
    updating => !!updating
);
