const UUID = require('uuid/v4');

class CanvasItem {
    static isValid(canvasItem) {
        return canvasItem instanceof this;
    }

    constructor(
        name,
        id = UUID().toString(),
        inLayout = false,
        isLocked = false,
        isHidden = false,
        isImported = false,
        placeholderType,
        placeholderSequence,
        style = '',
        placeholderSourceId
    ) {
        this._id = id === '' ? UUID().toString() : id;
        this._name = name;
        this._inLayout = inLayout;
        this._isLocked = isLocked;
        this._isHidden = isHidden;
        this._isImported = isImported;
        this.placeholderType = placeholderType;
        this.placeholderSequence = placeholderSequence;
        this.style = style;
        this.placeholderSourceId = placeholderSourceId;
    }

    toJSON() {
        return {
            id: this.id,
            name: this.name,
            isLocked: this.isLocked,
            isHidden: this.isHidden,
            isImported: this.isImported,
            placeholderType: this.placeholderType,
            placeholderSequence: this.placeholderSequence,
            style: this.style,
            placeholderSourceId: this.placeholderSourceId
        };
    }

    toFabric() {
        return {
            id: this.id,
            name: this.name,
            isLocked: this.isLocked,
            isHidden: this.isHidden,
            inLayout: this.inLayout,
            isImported: this.isImported,
            placeholderType: this.placeholderType,
            placeholderSequence: this.placeholderSequence,
            placeholderSourceId: this.placeholderSourceId,
            style: this.style
        };
    }

    isPlaceholderHomologueTo({ placeholderSequence, placeholderType }) {
        return (
            this.placeholderType === placeholderType &&
            this.placeholderSequence === placeholderSequence
        );
    }

    copyStyles(original) {
        if (this.copyAssignedShapeStyle) {
            this.copyAssignedShapeStyle(original);
        }
        if (this.copyAssignedStrokeStyle) {
            this.copyAssignedStrokeStyle(original);
        }
        this.setComplementStyleAsItemStyle();
    }

    setComplementStyleAsItemStyle() {
        if (this.setComplementShapeStyleAsItemShapeStyle) {
            this.setComplementShapeStyleAsItemShapeStyle();
        }
        if (this.setComplementStrokeAsItemStrokeStyle) {
            this.setComplementStrokeAsItemStrokeStyle();
        }
    }

    get DOMParser() {
        return this._DOMParser;
    }

    set DOMParser(parser) {
        this._DOMParser = parser;
    }

    get id() {
        return this._id;
    }

    set id(id) {
        this._id = id;
    }

    get name() {
        return this._name;
    }

    set name(name) {
        this._name = name;
    }

    get inLayout() {
        return this._inLayout;
    }

    set inLayout(inLayout) {
        this._inLayout = inLayout;
    }

    get isLocked() {
        return this._isLocked;
    }

    set isLocked(isLocked) {
        this._isLocked = isLocked;
    }

    get isHidden() {
        return this._isHidden;
    }

    set isHidden(isHidden) {
        this._isHidden = isHidden;
    }

    get isImported() {
        return this._isImported;
    }

    set isImported(isImported) {
        this._isImported = isImported;
    }

    get placeholderType() {
        return this._placeholderType;
    }

    set placeholderType(placeholderType) {
        this._placeholderType = placeholderType;
    }

    get isPlaceholder() {
        return !!this.placeholderType;
    }

    get placeholderSequence() {
        return this._placeholderSequence;
    }

    set placeholderSequence(placeholderSequence) {
        this._placeholderSequence = placeholderSequence;
    }

    get placeholderSourceId() {
        return this._placeholderSourceId;
    }

    set placeholderSourceId(placeholderSourceId) {
        this._placeholderSourceId = placeholderSourceId;
    }

    get style() {
        return this._style;
    }

    set style(style = '') {
        this._style = style;
    }
}

module.exports = CanvasItem;
