module.exports = (fill, typeKey, action, ...args) => {
    switch ((fill || {})[typeKey]) {
        case 'color':
            return {
                ...fill,
                color: action(fill.color, ...args)
            };
        case 'gradient':
            return {
                ...fill,
                gradient: {
                    ...fill.gradient,
                    stops: fill.gradient.stops.map(stop => ({
                        ...stop,
                        color: action(stop.color, ...args)
                    }))
                }
            };
        case 'pattern':
            return {
                ...fill,
                pattern: {
                    ...fill.pattern,
                    colors: {
                        background: action(fill.pattern.colors.background, ...args),

                        foreground: action(fill.pattern.colors.foreground, ...args)
                    }
                }
            };
        default:
            return fill;
    }
};
