import { Map } from 'immutable';
import { isNil } from 'lodash';
import { DEFAULT_BULLETS } from '../Config/defaultStyles';
import { addParagraph, getParagraph, getParagraphsCount } from '../Paragraphs/Paragraphs';
import { getParagraphStyle, setParagraphStyleProperties } from '../Paragraphs/ParagraphStyles';
import { getRunsAt } from '../Runs/Runs';
import { getDefaultRunStyle, getRunStyle } from '../Runs/RunStyles';
import { bulletStrategies } from '../Utils/bulletStrategies';

const getItemBulletText = (textBody, index) => {
    const bullet = getBulletOfItem(textBody, index);
    if (bullet.get('type') === 'unordered' && !bullet.get('text')) {
        bullet.text = '•';
    }
    return bulletStrategies(index, bullet.toJS(), textBody);
};

const getBulletOfItem = (textBody, index) => {
    let bullet = textBody.get('bullets') || DEFAULT_BULLETS;
    const styleIndex = getParagraph(textBody, index)?.get('style') || 0;
    if (!Number.isNaN(Number.parseInt(styleIndex, 10))) {
        const runStyle = getParagraphStyle(textBody, styleIndex, true) || Map();
        bullet = runStyle.get('bullet') || bullet;
    }
    return parseSerializedBullet(bullet.get('type'))
        .merge(bullet);
};

const parseSerializedBullet = (propertyValue = Map({ type: 'none' })) => {
    let options = '';

    if (typeof propertyValue === 'string') {
        options = propertyValue;
    } else if (options.type === 'ordered') {
        options = `${propertyValue.get('type')}/${propertyValue.get('orderStrategy')}`;
    } else {
        options = `${propertyValue.get('type')}/${propertyValue.get('text')}`;
    }

    const bulletType = options.split('/')[0];
    let bullets = Map({
        type: bulletType
    });
    if (bulletType === 'ordered') {
        const orderStrategy = options.split('/')[1];
        bullets = bullets.set('orderStrategy', orderStrategy);
    } else {
        const text = options.split('/')[1];
        if (text) {
            bullets = bullets.set('type', 'unordered');
            bullets = bullets.set('text', text);
        }
    }
    return bullets;
};

const addItem = (textBody, text, level = 1) => {
    let updatedTextBody = textBody;
    updatedTextBody = addParagraph(updatedTextBody, text);
    const nextIndex = getParagraphsCount(updatedTextBody) - 1;
    updatedTextBody = setLevelToItem(updatedTextBody, nextIndex, level);
    const bullets = updatedTextBody.get('bullets') ? updatedTextBody.get('bullets') : DEFAULT_BULLETS;
    updatedTextBody = setBulletTextToItem(
        updatedTextBody,
        nextIndex,
        bulletStrategies(nextIndex, bullets.toJS(), updatedTextBody)
    );
    return updatedTextBody;
};

const setBulletTextToItem = (textBody, index, text) => setParagraphStyleProperties(
    textBody,
    Map({
        bullet: Map({
            type: textBody.getIn(['bullets', 'type']) === 'ordered' ?
                `${textBody.getIn(['bullets', 'type'])}/${textBody.getIn(['bullets', 'orderStrategy'])}` :
                textBody.getIn(['bullets', 'type']),
            text
        })
    }),
    index,
    index
);

const setLevelToItem = (textBody, itemIndex, level, overwriteIndentAndPadding = false) => {
    const updatedTextBody = setParagraphStyleProperties(
        textBody,
        Map({
            level
        }),
        itemIndex,
        itemIndex
    );

    const startIndex = getParagraph(updatedTextBody, itemIndex).get('startIndex');

    return setLeveledTextPosition(
        updatedTextBody,
        {
            index: itemIndex,
            level,
            startIndex,
            onlyUpdatePadding: true,
            overwriteIndentAndPadding
        }
    );
};

const setLeveledTextPosition = (
    textBody,
    {
        index,
        level,
        startIndex,
        onlyUpdatePadding = false,
        overwriteIndentAndPadding = false
    }
) => {
    let updatedTextBody = textBody;

    const run = getRunsAt(updatedTextBody, startIndex).get(0) || Map({});

    let runStyle = getDefaultRunStyle(updatedTextBody) || Map({});

    if (!isNil(run.style)) {
        runStyle = getRunStyle(updatedTextBody, Number(run.get('style') || 0), true);
    }

    const fontSize = runStyle.getIn(['font', 'size']);

    let setPadding = true;
    let setIndent = true;
    const paragraph = getParagraph(updatedTextBody, index);
    if (paragraph.get('style')) {
        const originalParagraphStyle = getParagraphStyle(updatedTextBody, paragraph.get('style'), true);
        if (originalParagraphStyle.getIn(['padding', 'left'])) {
            setPadding = false;
        }
        if (originalParagraphStyle.get('indent') || onlyUpdatePadding) {
            setIndent = false;
        }
    }
    if (overwriteIndentAndPadding) {
        setPadding = true;
        setIndent = true;
    }

    let update = Map();

    if (setPadding) {
        update = update.setIn(['padding', 'left'], Math.max(fontSize + (fontSize * 2 * (level - 1)), 0));
    }

    if (setIndent) {
        update = update.set('indent', -fontSize);
    }

    if (setIndent || setPadding) {
        updatedTextBody = setParagraphStyleProperties(
            updatedTextBody,
            update,
            index,
            index
        );
    }

    return updatedTextBody;
};

export {
    getItemBulletText,
    parseSerializedBullet,
    getBulletOfItem,
    addItem
};
