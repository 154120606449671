import { LOCATION_CHANGE } from 'connected-react-router';
import { pick } from 'lodash';
import * as types from '#Constants/ActionTypes';

const resetableReducer = (...resetOn) => reducer => (state, action) => {
    if (resetOn.includes(action.type)) {
        return reducer(undefined, action);
    }
    return reducer(state, action);
};

const locationRegex = /^\/([^/]*)(\/.*)?$/;

const keepStateFromPlan = state => pick(state, [
    'userSelectedTopics',
    'topics',
    'filteredObjectives',
    'objectives',
    'objectivesCategories',
    'selectedObjective',
    'initialObjective',
    'storyboards',
    'selectedStoryboard',
    'initialStoryboard',
    'browseTopics',
    'isReordering',
    'selectedPage',
    'userUnselectedTopics',
    'shouldSave',
    'confirmTopicDeletion',
    'currentItemDescription',
    'styleSelection',
    'activePage',
    'page'
]);

const keepStateFromSection = (section, state) => {
    switch (section) {
        case 'plan':
            return keepStateFromPlan(state);
        case 'build':
            return keepStateFromPlan(state);
        default:
            return undefined;
    }
};

const resetOnLocationChange = (reducer, section = 'decksign') => (state, action) => {
    if ((action.type === types.CREATE_DECK ||
        action.type === types.CREATE_DECK_SUCCESS
    ) &&
        section === 'plan') {
        return reducer(undefined, action);
    }
    if (action.type === LOCATION_CHANGE &&
        action.payload.prevPathName !== `/${action.payload.location.pathname.match(locationRegex)[1]}` &&
        section !== action.payload.location.pathname.match(locationRegex)[1]) {
        return reducer(keepStateFromSection(section, state), action);
    }

    return reducer(state, action);
};

export { resetableReducer, resetOnLocationChange };
