const set = require('lodash/set');
const merge = require('lodash/merge');
const isNil = require('lodash/isNil');
const get = require('lodash/get');
const isEqual = require('lodash/isEqual');

const StrokeFill = require('../Stroke/StrokeFill');
const StrokeEnd = require('../Stroke/StrokeEnd.js');
const Dash = require('../Stroke/Dash.js');
require('../Stroke/StrokeFill/StrokeFillLoader.js');
const Stroke = require('../Stroke/Stroke');
const FabricObjectMixin = require('../../fabric-adapter/mixins/genericFabricObject');
const AlignMixin = require('./mixins/align');
const StyleProviderMixin = require('./mixins/styleProvider').Shape;
const AnchorsMixin = require('./mixins/anchor');
const Paste = require('./mixins/paste');
const Shape = require('../Shape');
const TextBody = require('./TextBody');
const Point = require('../Point');
const filterObject = require('../../utilities/object/filter');
const Fill = require('../Fill');
const CanvasItem = require('../CanvasItem');
const { numberOrDefault, booleanOrDefault, stringOrDefault } = require('../../utilities/typeOrDefault');
const WithShapeStyle = require('./mixins/WithShapeStyle');
const WithStrokeStyle = require('./mixins/WithStrokeStyle');
const ShapeStyle = require('../Styles/ShapeStyle');
const {
    setJSONAssignedShapeStype,
    setJSONAssignedStrokeStype,
    setShapeMiscValues
} = require('./JSONAdapter');

class AbstractShape extends WithStrokeStyle(WithShapeStyle(StyleProviderMixin(AnchorsMixin(
    AlignMixin(FabricObjectMixin(Paste(CanvasItem)))
)))) {
    constructor(
        name,
        posX,
        posY,
        attributeProperties = {}
    ) {
        super(
            name,
            attributeProperties.id,
            attributeProperties.inLayout,
            attributeProperties.isLocked,
            attributeProperties.isHidden,
            attributeProperties.isImported,
            attributeProperties.placeholderType,
            attributeProperties.placeholderSequence,
            attributeProperties.style,
            attributeProperties.placeholderSourceId
        );
        this._x = posX;
        this._y = posY;
        this._width = Math.round(
            (numberOrDefault(attributeProperties.width, this.defaults.width) + Number.EPSILON) * 100
        ) / 100;
        this._height = Math.round(
            (numberOrDefault(attributeProperties.height, this.defaults.height) + Number.EPSILON) * 100
        ) / 100;
        this._rotation = numberOrDefault(attributeProperties.rotation, this.defaults.rotation);
        this._skewX = numberOrDefault(attributeProperties.skewX, this.defaults.skewX);
        this._skewY = numberOrDefault(attributeProperties.skewY, this.defaults.skewY);
        this._flipX = booleanOrDefault(attributeProperties.flipX, this.defaults.flipX);
        this._flipY = booleanOrDefault(attributeProperties.flipY, this.defaults.flipY);
        this.initShapeStyle(attributeProperties);
        this._borderColor = attributeProperties.borderColor || this.defaults.borderColor;
        this._borderSize = attributeProperties.borderSize || this.defaults.borderSize;
        this._borderCompound = attributeProperties.borderCompound || this.defaults.borderCompound;
        this._isBackground = booleanOrDefault(
            attributeProperties.isBackground,
            this.defaults.isBackground
        );
        this._scaleX = numberOrDefault(attributeProperties.scaleX, this.defaults.scaleX);
        this._scaleY = numberOrDefault(attributeProperties.scaleY, this.defaults.scaleY);
        this._hyperlink = attributeProperties.hyperlink || this.defaults.hyperlink;
        this._selectable = booleanOrDefault(
            attributeProperties.selectable,
            this.defaults.selectable
        );
        this._evented = booleanOrDefault(attributeProperties.evented, this.defaults.evented);
        this._lockAspectRatio = booleanOrDefault(
            attributeProperties.lockAspectRatio,
            false
        );
        this._lockPath = booleanOrDefault(attributeProperties.lockPath, this.defaults.lockPath);
        this._isGroupHidden = booleanOrDefault(
            attributeProperties.isGroupHidden,
            this.defaults.isGroupHidden
        );
        this._isGroupLocked = booleanOrDefault(
            attributeProperties.isGroupLocked,
            this.defaults.isGroupLocked
        );
        this._visible = booleanOrDefault(attributeProperties.visible, this.defaults.selectable);

        if (attributeProperties.textBody) {
            this._textBody = new TextBody(this);
            this._textBody.fromJSON(attributeProperties.textBody);
        } else {
            this._textBody = new TextBody(this, {
                text: stringOrDefault(
                    attributeProperties.text,
                    this.defaults.text
                )
            });
        }

        if (attributeProperties.textBodyPlaceholder) {
            this._textBodyPlaceholder = new TextBody(this);
            this._textBodyPlaceholder.fromJSON(attributeProperties.textBodyPlaceholder);
        } else {
            this._textBodyPlaceholder = new TextBody(this, {
                text: stringOrDefault(
                    attributeProperties.placeholderText,
                    this.defaults.placeholderText
                )
            });
        }
        this._dynamicProperties = attributeProperties.dynamicProperties || [];

        this.stroke = Stroke.fromJSON(attributeProperties.stroke);

        if (attributeProperties.assignedShapeStyle) {
            this.assignedShapeStyle = attributeProperties.assignedShapeStyle;
        }
        if (attributeProperties.assignedStrokeStyle) {
            this.assignedStrokeStyle = attributeProperties.assignedStrokeStyle;
        }
    }

    setAttributeProperties(attributeProperties) {
        Object.keys(attributeProperties).forEach(attribute => {
            this[attribute] = attributeProperties[attribute];
        });
    }

    getScaledWidth() {
        return this.width * this.scaleX;
    }

    getScaledHeight() {
        return this.height * this.scaleY;
    }

    toJSON() {
        const json = {
            ...super.toJSON(),
            ...filterObject({
                type: this.constructor.name,
                width: this.width,
                height: this.height,
                x: this.x,
                y: this.y,
                rotation: this.rotation,
                skewX: this.skewX,
                skewY: this.skewY,
                flipX: this.flipX,
                flipY: this.flipY,
                scaleX: this.scaleX,
                scaleY: this.scaleY,
                lockAspectRatio: this.lockAspectRatio,
                lockPath: this.lockPath,
                textBody: this.textBody.toJSON(),
                textBodyPlaceholder: this.textBodyPlaceholder.toJSON(),
                fill: this.fill,
                opacity: this.opacity,
                stroke: this.stroke instanceof Stroke ? this.stroke.toJSON() : this.stroke,
                selectable: this.selectable,
                evented: this.evented,
                isBackground: this.isBackground,
                displayPlaceholder: this.displayPlaceholder,
                isGroupLocked: this.isGroupLocked,
                isGroupHidden: this.isGroupHidden,
                visible: this.visible,
                inLayout: this.inLayout,
                dynamicProperties: this.dynamicProperties,
                sendToBack: this.sendToBack
            }),
            ...(this.hyperlink ? { hyperlink: this.hyperlink } : {})
        };

        if (
            this.assignedShapeStyle !== undefined ||
            this.assignedStrokeStyle !== undefined
        ) {
            json.assignedStyles = {
                shape: this.assignedShapeStyle && this.assignedShapeStyle.toJSON(),
                stroke: this.assignedStrokeStyle && this.assignedStrokeStyle.toJSON()
            };
        }

        return json;
    }

    static fromJSON(jsonObject) {
        const json = jsonObject;
        if (json.fill && isEqual(json.fill, Fill.emptySerialized)) {
            json.fill = undefined;
        }
        setJSONAssignedShapeStype(json);
        setJSONAssignedStrokeStype(json);
        const shape = new this(
            json.name,
            json.x,
            json.y,
            json
        );
        setShapeMiscValues(json, shape);
        return shape;
    }

    shouldUpdatePlaceholder(update) {
        const textSelection = update?.textProps?.textSelection;
        const {
            textBodyData
        } = this.textBodyPlaceholder;

        return !textSelection?.editing ||
            (textSelection?.editing &&
                textSelection?.start === 0 &&
                textSelection?.end === 0 &&
                !textBodyData?.text);
    }

    copy() {
        const obj = new this.constructor();
        Object.assign(obj, this);
        obj.textBody = this.textBody.copy();
        obj.textBodyPlaceholder = this.textBodyPlaceholder.copy();
        return obj;
    }

    applyUpdate(update) {
        const removeCustomStyles = get(update, 'shapeProps.removeCustomStyles');
        if (Object.keys(update.textProps || {}).length) {
            this.textBody.applyUpdate(
                update.textProps,
                removeCustomStyles,
                update.isEmphasisStyle
            );

            if (this.shouldUpdatePlaceholder(update)) {
                this.textBodyPlaceholder.applyUpdate(
                    update.textProps,
                    removeCustomStyles,
                    update.isEmphasisStyle
                );
            }
        }
        if (Object.keys(update.shapeProps || {}).length) {
            this.applyShapeUpdate(update.shapeProps, update.isEmphasisStyle);
        }
        this.applyStrokeUpdate(update.strokeProps, removeCustomStyles, update.isEmphasisStyle);
    }

    applyShapeUpdate({
        fill,
        opacity,
        shapeOpacity,
        assignedShapeStyle,
        removeCustomStyles = false,
        ...update
    } = {}, isEmphasisStyle = false) {
        const shapeStyleToAssign = isEmphasisStyle ?
            merge(this.assignedShapeStyle, assignedShapeStyle) :
            assignedShapeStyle;

        if (fill && (!this.fill || this.fill.type !== 'imageSource')) {
            this.fill = Fill.updateValueOfColorDescriptors(fill, this);
            this.shapeStyle._fill = Fill.updateValueOfColorDescriptors(fill, this);
        }
        if (!isNil(opacity) && !Number.isNaN(opacity)) {
            this.forceShapeFillFromRendered();
            this.opacity = opacity;
            this.stroke.opacity = opacity;
        }
        if (!isNil(shapeOpacity) && !Number.isNaN(shapeOpacity)) {
            this.forceShapeFillFromRendered();
            this.opacity = shapeOpacity;
        }

        if (shapeStyleToAssign) {
            this.assignedShapeStyle = ShapeStyle.fromJSON(shapeStyleToAssign);
            if (removeCustomStyles) {
                this.initShapeStyle({});
            }
        }

        Object.entries(update)
            .forEach(([key, value]) => {
                this[key] = value;
            });
    }

    applyStrokeUpdate(
        {
            fill,
            opacity,
            head,
            tail,
            dash,
            assignedStrokeStyle,
            ...update
        } = {},
        removeCustomStyles = false,
        isEmphasisStyle = false
    ) {
        const strokeStyleToAssign = isEmphasisStyle ?
            merge(this.assignedStrokeStyle, assignedStrokeStyle) :
            assignedStrokeStyle;

        if (fill) {
            this.stroke.fill = fill;
        }
        if (!isNil(opacity) && !Number.isNaN(opacity)) {
            this.forceStrokeFillFromRendered();
            this.stroke.opacity = opacity;
        }
        if (head) {
            this.stroke.head = new StrokeEnd(head.width, head.type, head.length);
        }
        if (tail) {
            this.stroke.tail = new StrokeEnd(tail.width, tail.type, tail.length);
        }
        if (dash) {
            this.stroke.dash = new Dash(dash.type, dash.dashStops);
        }
        this.stroke = merge(this.stroke, update);
        if (strokeStyleToAssign) {
            this.assignedStrokeStyle = Stroke.fromJSON(strokeStyleToAssign);
            if (removeCustomStyles) {
                this.stroke = Stroke.fromJSON({});
            }
        }
    }

    flip(flipX = false, flipY = false) {
        this.flipX = (flipX !== this.flipX);
        this.flipY = (flipY !== this.flipY);
    }

    isReversedLayoutPlaceholder() {
        return (
            this.inLayout &&
            this.isPlaceholder &&
            (
                this._textBody &&
                this._textBody.hasText &&
                this._textBody.hasText()
            ) &&
            (
                this._textBodyPlaceholder &&
                this._textBodyPlaceholder.isEmpty &&
                this._textBodyPlaceholder.isEmpty()
            )
        );
    }

    updateColorPresets({ presets, scheme } = {}) {
        this.textBody.updateColorPresets({ presets, scheme });
        this.textBodyPlaceholder.updateColorPresets({ presets, scheme });

        this.updateShapeColorPresets({ presets, scheme });
        this.updateStrokeColorPresets({ presets, scheme });
    }

    enforceTextBodyDefaultsOnPlaceholder() {
        this.textBodyPlaceholder.applyTextBodyStructure(this._textBody);
        this.textBody.applyTextBodyStructure(this._textBody);
    }

    updatePropertiesFromDynamicValues() {
        if (this.dynamicProperties.length) {
            this.dynamicProperties.forEach(({
                dynamicValue,
                property
            }) => {
                if (this.dynamicValues[dynamicValue]) {
                    set(this, property, this.dynamicValues[dynamicValue]);
                }
            });
        }
    }

    copyStyles(original) {
        super.copyStyles(original);
        this.textBody.copyStyles(original.textBody);
        if (original.textBodyPlaceholder && this.textBodyPlaceholder) {
            this.textBodyPlaceholder.copyStyles(original.textBodyPlaceholder);
        }
    }

    set offsetLeft(update) {
        this.x += update;
    }

    set offsetTop(update) {
        this.y += update;
    }

    get x() {
        return this._x;
    }

    set x(x) {
        if (x === undefined) {
            this._x = 0;
        } else {
            this._x = x;
        }
    }

    get y() {
        return this._y;
    }

    set y(y) {
        if (y === undefined) {
            this._y = 0;
        } else {
            this._y = y;
        }
    }

    get width() {
        return this._width;
    }

    set width(width) {
        if (this.lockAspectRatio && this.width) {
            const ratio = width / (this.width * this.scaleX);
            this._height *= ratio;
        }
        this._width = width / this.scaleX;
    }

    get height() {
        return this._height;
    }

    set height(height) {
        if (this.lockAspectRatio && this.height) {
            const ratio = height / (this.height * this.scaleY);
            this._width *= ratio;
        }
        this._height = height / this.scaleY;
    }

    get rotation() {
        return this._rotation;
    }

    set rotation(rotation) {
        this._rotation = rotation;
    }

    get skewX() {
        return this._skewX;
    }

    set skewX(skewX) {
        this._skewX = skewX;
    }

    get skewY() {
        return this._skewY;
    }

    set skewY(skewY) {
        this._skewY = skewY;
    }

    get flipX() {
        return this._flipX;
    }

    set flipX(flipX) {
        this._flipX = flipX;
    }

    get flipY() {
        return this._flipY;
    }

    set flipY(flipY) {
        this._flipY = flipY;
    }

    get fill() {
        return this.shapeStyle.fill;
    }

    set fill(fill) {
        this.shapeStyle.fill = fill;
    }

    set opacity(opacity) {
        this.shapeStyle.opacity = opacity;
    }

    get opacity() {
        return this.shapeStyle.opacity;
    }

    get isBackground() {
        return this._isBackground;
    }

    set isBackground(isBackground) {
        this._isBackground = isBackground;
    }

    get scaleX() {
        return this._scaleX || 1;
    }

    set scaleX(scaleX) {
        this._scaleX = scaleX;
    }

    get scaleY() {
        return this._scaleY || 1;
    }

    set scaleY(scaleY) {
        this._scaleY = scaleY;
    }

    get hyperlink() {
        return this._hyperlink;
    }

    set hyperlink(hyperlink) {
        this._hyperlink = hyperlink;
    }

    get evented() {
        return this._evented;
    }

    set evented(evented) {
        this._evented = evented;
    }

    set textBody(textBody) {
        this._textBody = textBody;
    }

    get textBody() {
        if (this.isReversedLayoutPlaceholder()) {
            return this._textBodyPlaceholder;
        }
        return this._textBody;
    }

    set textBodyPlaceholder(textBodyPlaceholder) {
        this._textBodyPlaceholder = textBodyPlaceholder;
    }

    get textBodyPlaceholder() {
        if (this.isReversedLayoutPlaceholder()) {
            return this._textBody;
        }
        return this._textBodyPlaceholder;
    }

    get type() {
        return this.constructor.name;
    }
    // eslint-disable-next-line
    set type(type) {
    }

    set text(text) {
        this._textBody.setText(text);
    }

    get text() {
        return this.textBody.getText();
    }

    set align(align) {
        this.textBody.align = align;
        this.textBodyPlaceholder.align = align;
    }

    set lineSpacing(lineSpacing) {
        this.textBody.lineSpacing = lineSpacing;
        this.textBodyPlaceholder.lineSpacing = lineSpacing;
    }

    get bullets() {
        return this.textBody.bullets;
    }

    set bullets(bullets) {
        this.textBody.bullets = bullets;
    }

    get boundingBox() {
        const corners = this.boundingBoxCorners;
        const relativeBoundingBox = corners.reduce((currentBox, corner) => ({
            bottom: Math.max(currentBox.bottom, corner.y),
            left: Math.min(currentBox.left, corner.x),
            right: Math.max(currentBox.right, corner.x),
            top: Math.min(currentBox.top, corner.y)
        }), {
            bottom: -Infinity, left: Infinity, right: -Infinity, top: Infinity
        });
        return {
            bottom: this.y + relativeBoundingBox.bottom,
            left: this.x + relativeBoundingBox.left,
            right: this.x + relativeBoundingBox.right,
            top: this.y + relativeBoundingBox.top
        };
    }

    get boundingBoxCorners() {
        let corners = [
            new Point((this.scaledWidth / 2), this.scaledHeight / 2), // Bottom Right
            new Point(-this.scaledWidth / 2, this.scaledHeight / 2), // Bottom Left
            new Point(-this.scaledWidth / 2, -this.scaledHeight / 2), // Top Left
            new Point(this.scaledWidth / 2, -this.scaledHeight / 2) // Top Right
        ];
        corners = corners.map(corner => this.applyBorderSize(corner));
        corners.forEach(corner => corner.rotateInDegrees(this.rotation));
        return corners;
    }

    applyBorderSize(corner) {
        if (this.stroke && this.stroke.width) {
            const borderHalf = this.stroke.width / 2;
            return new Point(
                corner.x > 0 ? corner.x + borderHalf : corner.x - borderHalf,
                corner.y > 0 ? corner.y + borderHalf : corner.y - borderHalf
            );
        }
        return corner;
    }

    get fonts() {
        return this.textBody.fonts;
    }

    get lockAspectRatio() {
        return this._lockAspectRatio;
    }

    set lockAspectRatio(lockAspectRatio) {
        this._lockAspectRatio = lockAspectRatio;
    }

    get lockPath() {
        return this._lockPath;
    }

    set lockPath(lockPath) {
        this._lockPath = lockPath;
    }

    get scaledWidth() {
        return this.scaleX * this.width;
    }

    get scaledHeight() {
        return this.scaleY * this.height;
    }

    get borderDash() {
        return this._borderDash;
    }

    set borderDash(borderDash) {
        this._borderDash = borderDash;
    }

    get isTextEmpty() {
        return !this.textBody.getText();
    }
    // eslint-disable-next-line
    set isTextEmpty(isTextEmpty) {
    }

    get isGroupLocked() {
        return this._isGroupLocked;
    }

    set isGroupLocked(isGroupLocked) {
        this._isGroupLocked = isGroupLocked;
    }

    get isGroupHidden() {
        return this._isGroupHidden;
    }

    set isGroupHidden(isGroupHidden) {
        this._isGroupHidden = isGroupHidden;
    }

    set selectable(selectable) {
        this._selectable = selectable;
    }

    get selectable() {
        return !(this.isLocked || this.isGroupLocked || this.isBackground || this.isHidden);
    }

    set visible(visible) {
        this.isHidden = !visible;
    }

    get visible() {
        return !(this.isHidden || this.isGroupHidden);
    }

    get inLayout() {
        return this._inLayout;
    }

    set inLayout(inLayout) {
        this._inLayout = inLayout;
    }

    getStrokeAsValue() {
        return StrokeFill.updateValueOfColorDescriptors(this.borderColor, this);
    }

    get dynamicValues() {
        return this._dynamicValues || {};
    }

    set dynamicValues(dynamicValues = {}) {
        this._dynamicValues = dynamicValues;
        this.updatePropertiesFromDynamicValues();
    }

    get dynamicProperties() {
        return this._dynamicProperties || [];
    }

    set dynamicProperties(dynamicProperties = []) {
        this._dynamicProperties = dynamicProperties;
        this.updatePropertiesFromDynamicValues();
    }

    set autoFitText(autoFitText = false) {
        this.textBody.autoFitText = autoFitText;
        this.textBodyPlaceholder.autoFitText = autoFitText;
    }

    set autoFitShape(autoFitShape = false) {
        this.textBody.autoFitShape = autoFitShape;
        this.textBodyPlaceholder.autoFitShape = autoFitShape;
    }

    get defaults() {
        return this.loadDefaultShapeStyleConfiguration(
            this.constructor.name
        );
    }
}

Shape.addToConstructorList(AbstractShape);

module.exports = AbstractShape;
