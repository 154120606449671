import 'react-dates/initialize';
import { StripeProvider, Elements } from 'react-stripe-elements';
import React from 'react';
import ReactDOM from 'react-dom';
import 'semantic-ui-less/semantic.less';
import { Provider } from 'react-redux';
import { Route } from 'react-router-dom';
import { addLocaleData, IntlProvider } from 'react-intl';
import en from 'react-intl/locale-data/en';
import fr from 'react-intl/locale-data/fr';
import * as enLang from '#Resources/locales/en';
import * as frLang from '#Resources/locales/fr';
import GlobalErrorBoundary from '#Components/Common/ErrorBoundary/GlobalErrorBoundary';
import Switch from '#Components/Common/Router/Switch';
import configureStore, { history } from './configureStore';
import App from './components/App/App';
import ConnectedRouter from './components/Common/ConnectedRouter/ConnectedRouter';

import './Index.scss';

const store = configureStore();

addLocaleData([...en, ...fr]);
const LANG = 'en';
const setMessagesLanguage = chosenLang => {
    if (chosenLang === 'en') {
        return enLang.en;
    }
    if (chosenLang === 'fr') {
        return frLang.fr;
    }
    return enLang.en;
};

const Index = () => (
    <StripeProvider apiKey="pk_test_icYJa1bVzIh7Be98MLFy5T0w">
        <Elements>
            <Provider store={store}>
                <GlobalErrorBoundary>
                    <IntlProvider locale={LANG} messages={setMessagesLanguage(LANG)}>
                        <ConnectedRouter history={history}>
                            <Switch>
                                {/* <Route exact path="/implicit/callback" component={LoginCallback} /> */}
                                <Route path="/" component={App} />
                            </Switch>
                        </ConnectedRouter>
                    </IntlProvider>
                </GlobalErrorBoundary>
            </Provider>
        </Elements>
    </StripeProvider>
);

ReactDOM.render(
    <Index />,
    document.getElementById('AppRoot')
);
