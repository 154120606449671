import getPath from '../PropertyAdapters/regularPolygon/path';
import getPoints from '../PropertyAdapters/regularPolygon/points';
import getSides from '../PropertyAdapters/regularPolygon/sides';
import defaultShapeToFabricJSON from './default';

const fromShapeToFabricJSON = (shape, canvasState) => ({
    ...defaultShapeToFabricJSON(shape, canvasState),
    sides: getSides(shape),
    path: getPath(shape),
    points: getPoints(shape)
});

export default fromShapeToFabricJSON;
