import { getRenderParagraphStyle } from '../../../../CanvasState/Helpers/Style/paragraph';
import { getRenderRunStyle } from '../../../../CanvasState/Helpers/Style/run';
import { getParagraphs as getParagraphsFromTextBody } from '../../../../CanvasState/Helpers/Text/Paragraphs/Paragraphs';
import { getDefaultParagraphStyle, getParagraphStyle } from '../../../../CanvasState/Helpers/Text/Paragraphs/ParagraphStyles';
import { getRunsAt } from '../../../../CanvasState/Helpers/Text/Runs/Runs';
import { getDefaultRunStyle, getRunStyle } from '../../../../CanvasState/Helpers/Text/Runs/RunStyles';
import { getItemBulletText } from '../../../../CanvasState/Helpers/Text/TextBodyProperties/bullets';
import { getText } from '../../../../CanvasState/Helpers/Text/TextBodyProperties/TextBodyProperties';
import getTextBodyMargins from '../PropertyAdapters/default/textBody/textBodyMargins';
import getVerticalAlign from '../PropertyAdapters/default/textBody/verticalAlign';
import { adaptParagraphStyleToFabricTextStyle } from './paragraphStyleAdapter';
import { adaptRunStyleToFabricCharacterStyle } from './runStyleAdapter';

const adaptTextBodyToFabricParagraphs = (shape, textBody, colorPalette) => (textBody ?
    getParagraphsFromTextBody(textBody)
        .map((paragraph, index) => adaptParagraphToFabricParagraph({
            shape,
            textBody,
            paragraph,
            colorPalette,
            paragraphIndex: index
        })).toJS() : []);

const adaptParagraphToFabricParagraph = ({
    shape,
    textBody,
    paragraph,
    paragraphIndex,
    colorPalette
}) => {
    const {
        endIndex,
        startIndex,
        style
    } = paragraph.toJS();

    const updatedTextBody = setupTextBody(shape, textBody);

    const runs = getRunsAt(updatedTextBody, startIndex, endIndex + 1);
    const runsInParagraph = limitRunsToRange({ runs, startIndex, endIndex: endIndex + 1 });
    const styles = getFabricCharacterStylesFromRuns({
        startIndex,
        textBody: updatedTextBody,
        colorPalette,
        runs: runsInParagraph
    });
    const bulletText = getItemBulletText(updatedTextBody, paragraphIndex);
    const text = getText(updatedTextBody, startIndex, endIndex);

    const fabricParagraph = {
        text,
        styles: cutStylesIntoLines(styles, text),
        ...adaptRunStyleToFabricCharacterStyle(
            getDefaultRunStyle(updatedTextBody),
            colorPalette
        ),
        ...adaptParagraphStyleToFabricTextStyle(
            (
                style !== undefined ?
                    getParagraphStyle(updatedTextBody, style, true) :
                    getDefaultParagraphStyle(updatedTextBody)
            ),
            colorPalette
        ),
        bulletText
    };
    return fabricParagraph;
};

/*
    This replicates the behaviour we have inside the fromJSON of the TextBody. Some values are set
    for defaults and if we don't do it the old and new conversion will never match.
*/
const setupTextBody = (shape, textBody) => {
    let updatedTextBody = textBody;

    // Setup TextBody properties
    updatedTextBody = updatedTextBody
        .set('margins', getTextBodyMargins(shape))
        .set('verticalAlign', getVerticalAlign(shape));

    const defaultRunStyleIndex = updatedTextBody.get('runStyles')
        .findIndex(style => style.get('isDefault') === true);
    if (defaultRunStyleIndex > -1) {
        updatedTextBody = updatedTextBody.set('runStyle', updatedTextBody.getIn(['runStyles', defaultRunStyleIndex]));
        updatedTextBody = updatedTextBody.setIn(
            ['runStyles', defaultRunStyleIndex],
            getRenderRunStyle(updatedTextBody, shape)
                .set('isDefault', true)
        );
    } else {
        updatedTextBody = updatedTextBody.update(
            'runStyles',
            runStyles => runStyles.push(
                getRenderRunStyle(updatedTextBody, shape)
                    .set('isDefault', true)
            )
        );
    }

    const defaultParagraphStyleIndex = updatedTextBody.get('paragraphStyles')
        .findIndex(style => style.get('isDefault') === true);
    if (defaultParagraphStyleIndex > -1) {
        updatedTextBody = updatedTextBody.set('paragraphStyle', updatedTextBody.getIn(['paragraphStyles', defaultParagraphStyleIndex]));
        updatedTextBody = updatedTextBody.setIn(
            ['paragraphStyles', defaultParagraphStyleIndex],
            getRenderParagraphStyle(
                updatedTextBody,
                shape
            )
                .set('isDefault', true)
        );
    } else {
        updatedTextBody = updatedTextBody.update(
            'paragraphStyles',
            paragraphStyles => paragraphStyles.push(
                getRenderParagraphStyle(
                    updatedTextBody,
                    shape
                )
                    .set('isDefault', true)
            )
        );
    }

    return updatedTextBody;
};

const getFabricCharacterStylesFromRuns = ({
    textBody,
    startIndex,
    runs,
    colorPalette
}) => runs.reduce((styles, run) => ({
    ...styles,
    ...getFabricCharacterStylesFromRun({
        textBody,
        colorPalette,
        run,
        offset: startIndex
    })
}), {});

const getFabricCharacterStylesFromRun = ({
    offset,
    textBody,
    colorPalette,
    run
}) => {
    const {
        startIndex,
        endIndex,
        style
    } = run.toJS();
    const runStyle = getRunStyle(textBody, style, true);
    const fabricCharacterStyle = adaptRunStyleToFabricCharacterStyle(runStyle, colorPalette);
    const fabricCharacterStyles = {};
    for (let i = startIndex; i <= endIndex; i++) {
        const currentFontStyle = {
            ...fabricCharacterStyle
        };
        fabricCharacterStyles[i - offset] = currentFontStyle;
    }
    return fabricCharacterStyles;
};

const limitRunsToRange = ({ runs, startIndex, endIndex }) => runs.map(run => run
    .set('startIndex', Math.max(startIndex, run.get('startIndex')))
    .set('endIndex', Math.min(endIndex, run.get('endIndex'))));

const cutStylesIntoLines = (styles, text) => {
    const nextLineStart = text.indexOf('\n') + 1;
    if (nextLineStart > 0 && nextLineStart < text.length) {
        const [lineStyles, nextStyles] = Object.entries(styles)
            .reduce(([currentLineStyles, nextLineStyles], [serializedCharacterIndex, style]) => {
                const characterIndex = parseInt(serializedCharacterIndex, 10);
                if (characterIndex < nextLineStart) {
                    currentLineStyles[characterIndex] = style;
                } else {
                    nextLineStyles[characterIndex - nextLineStart] = style;
                }
                return [currentLineStyles, nextLineStyles];
            }, [{}, {}]);
        const nextText = text.substr(nextLineStart);
        return [lineStyles, ...cutStylesIntoLines(nextStyles, nextText)];
    }
    return [styles];
};

export {
    // eslint-disable-next-line import/prefer-default-export
    adaptTextBodyToFabricParagraphs
};
