import { createSelector } from 'reselect';
import { getDeckStore, deckIsLoading } from '#Selectors/deck';
import { checkBuildUpdating } from '#Selectors/build';
import { isLoading as isManageLoading } from '#Selectors/manage';
import { isLoading as isShareLoading } from '#Selectors/share/share';
import { isLoading as isPlanLoading } from '#Selectors/plan';

export const hasDeck = createSelector(
    getDeckStore,
    deckIsLoading,
    (deck, isDeckLoading) => !!deck && !isDeckLoading
);

const appIsLoading = createSelector(({
    app: {
        loading
    }
}) => loading, loading => !!loading);

const userIsLoading = createSelector(({
    user: {
        fetching
    }
}) => fetching, fetching => fetching);

const userIsLoggingOut = createSelector(({
    auth: {
        isLoggingOut
    }
}) => isLoggingOut, isLoggingOut => isLoggingOut);

export const isLoading = createSelector(
    deckIsLoading,
    appIsLoading,
    userIsLoading,
    checkBuildUpdating,
    userIsLoggingOut,
    isManageLoading,
    isShareLoading,
    isPlanLoading,
    (...loadingSelectors) => loadingSelectors.some(selector => !!selector)
);
