const stringifyPathFromArray = path => {
    if (path instanceof Array) {
        return path.map(part => stringifyPathFromArray(part)).join('');
    } if (`${path}`.match(/^([a-zA-Z])$/)) {
        return path;
    } if (Number.isNaN(path)) {
        return '';
    }
    return `${path} `;
};

const stringifyPath = path => {
    if (path instanceof Array) {
        return stringifyPathFromArray(path);
    }
    return path;
};

const svgStringToArray = svgPath => {
    const arr = [];
    svgPath.iterate(s => {
        arr.push(s);
    });
    return arr;
};

module.exports = {
    stringifyPath,
    svgStringToArray,
    stringifyPathFromArray
};
