import * as types from '#Constants/ActionTypes';

const initialState = {
    shares: [],
    lastShare: null,
    uniqueViewers: [],
    read: 0,
    opened: 0,
    comments: {
        count: 0
    },
    feedbacks: {
        overall: {
            positive: 0,
            negative: 0
        },
        totalCount: 0
    },
    duration: {
        average: 0,
        min: 0,
        max: 0
    },
    viewsCount: 0,
    uniqueViews: 0
};

const deckSummary = (state = initialState, action) => {
    switch (action.type) {
        case types.RESET_SHARE:
            return initialState;
        case types.FETCH_SHARES:
            return { ...initialState, ...action.deck };
        case types.SET_LAST_SHARE:
            return { ...state, lastShare: action.lastShare };
        default:
            return state;
    }
};

export { deckSummary };
