/* eslint-disable */
// Since it's used only to prevent require loop so the shapes populate the list themselves
// ignore the linting so it doesn't complain that they are never used here
const SolidStrokeFill = require('./SolidStrokeFill.js');
const GradientStrokeFill = require('./GradientStrokeFill.js');
const PatternStrokeFill = require('./PatternStrokeFill.js');
const NoStrokeFill = require('./NoStrokeFill');
/* eslint-enable */

module.exports = {
    color: SolidStrokeFill,
    none: NoStrokeFill
};
