import { combineReducers } from 'redux';

import * as types from '#Constants/ActionTypes';

const addTopicFormVisibility = (state = false, action) => {
    switch (action.type) {
        case types.UPDATE_PAGE_IN_CURRENT_DECK:
            return false;
        case types.SET_ADD_TOPIC_FORM_VISIBILITY:
            return action.visibility;
        default:
            return state;
    }
};

const addTopicForm = combineReducers({
    label: (state = null, action) => {
        switch (action.type) {
            case types.UPDATE_ADD_TOPIC_LABEL:
                return action.value;
            case types.SET_ADD_TOPIC_FORM_VISIBILITY:
                return action.labelValue;
            default:
                return state;
        }
    },
    description: (state = '', action) => {
        switch (action.type) {
            case types.UPDATE_ADD_TOPIC_DESCRIPTION:
                return action.value;
            default:
                return state;
        }
    }
});

const toolbar = combineReducers({
    addTopicFormVisibility,
    addTopicForm
});

export default toolbar;
