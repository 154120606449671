import { fromJS } from 'immutable';
import defaultPropertyAdapters from '../PropertyAdapters/default';
import getBorders from '../PropertyAdapters/table/borders';
import getCells from '../PropertyAdapters/table/cells';

const fromFabricToCanvasState = (fabricShape, canvasState) => fromJS({
    id: defaultPropertyAdapters.id(fabricShape),
    name: defaultPropertyAdapters.name(fabricShape),
    type: 'table',
    path: '',
    hyperlink: '',
    placeholderSourceId: '',
    placeholderType: '',
    columnWidths: fabricShape.columnWidths,
    rowHeights: fabricShape.rowHeights,
    definedRowHeights: fabricShape.definedRowHeights,
    columnCount: fabricShape.columnCount,
    rowCount: fabricShape.rowCount,
    hasBandedColumns: fabricShape.hasBandedColumns,
    hasBandedRows: fabricShape.hasBandedRows,
    hasHeaderColumn: fabricShape.hasHeaderColumn,
    hasHeaderRow: fabricShape.hasHeaderRow,
    hasTotalColumn: fabricShape.hasTotalColumn,
    hasTotalRow: fabricShape.hasTotalRow,
    mainAxis: fabricShape.mainAxis,
    style: defaultPropertyAdapters.style(fabricShape, canvasState),
    assignedStyles: defaultPropertyAdapters.assignedStyles(fabricShape, canvasState),
    dynamicProperties: defaultPropertyAdapters.dynamicProperties(fabricShape),
    flipX: defaultPropertyAdapters.flipX(fabricShape),
    flipY: defaultPropertyAdapters.flipY(fabricShape),
    scaleX: defaultPropertyAdapters.scaleX(fabricShape),
    scaleY: defaultPropertyAdapters.scaleY(fabricShape),
    width: defaultPropertyAdapters.width(fabricShape),
    height: defaultPropertyAdapters.height(fabricShape),
    x: defaultPropertyAdapters.left(fabricShape),
    y: defaultPropertyAdapters.top(fabricShape),
    skewX: defaultPropertyAdapters.skewX(fabricShape),
    skewY: defaultPropertyAdapters.skewY(fabricShape),
    inLayout: defaultPropertyAdapters.inLayout(fabricShape),
    isBackground: defaultPropertyAdapters.isBackground(fabricShape),
    isHidden: defaultPropertyAdapters.isHidden(fabricShape),
    isImported: defaultPropertyAdapters.isImported(fabricShape),
    isLocked: defaultPropertyAdapters.isLocked(fabricShape),
    lockAspectRatio: defaultPropertyAdapters.lockUniScaling(fabricShape),
    rotation: defaultPropertyAdapters.angle(fabricShape) || 0,
    borders: getBorders(fabricShape, canvasState),
    cells: getCells(fabricShape, canvasState)
});

export default fromFabricToCanvasState;
