const angleNameToDegree = {
    top: 0,
    right: 90,
    bottom: 180,
    left: 270
};

const rotateExpectedAngles = (expectedAngles, rotation) => expectedAngles.map(direction => {
    let angle = (typeof direction === 'string' ? angleNameToDegree[direction] : direction) + rotation;
    while (angle < 0) angle += 360;
    angle %= 360;
    if (angle < 45) return 'top';
    if (angle < 135) return 'right';
    if (angle < 225) return 'bottom';
    if (angle < 315) return 'left';
    return 'top';
});

module.exports = Base => class extends Base {
    makeAnchor({ x, y, expectedAngles }) {
        const angleFromCenter = Math.atan2(y - this.y, x - this.x);
        const rotationInRadian = (this.rotation / 180) * Math.PI;
        const distanceFromCenter = Math.sqrt(((x - this.x) * (x - this.x)) + ((y - this.y) * (y - this.y)));

        return {
            x: Math.round(
                (distanceFromCenter * Math.cos(angleFromCenter + rotationInRadian)) + this.x
            ),
            y: Math.round(
                (distanceFromCenter * Math.sin(angleFromCenter + rotationInRadian)) + this.y
            ),
            expectedAngles: rotateExpectedAngles(expectedAngles, this.rotation)
        };
    }

    get relativeAnchors() {
        const left = -this.width / 2;
        const right = this.width / 2;
        const top = -this.height / 2;
        const bottom = this.height / 2;
        return {
            auto: { x: 0, y: 0 },
            'top-left': { x: left, y: top, expectedAngles: ['top', 'left'] },
            'top-center': { x: 0, y: top, expectedAngles: ['top'] },
            'top-right': { x: right, y: top, expectedAngles: ['top', 'right'] },
            'center-left': { x: left, y: 0, expectedAngles: ['left'] },
            'center-right': { x: right, y: 0, expectedAngles: ['right'] },
            'bottom-left': { x: left, y: bottom, expectedAngles: ['bottom', 'left'] },
            'bottom-center': { x: 0, y: bottom, expectedAngles: ['bottom'] },
            'bottom-right': { x: right, y: bottom, expectedAngles: ['bottom', 'right'] }
        };
    }

    get anchors() {
        const anchors = this.relativeAnchors;
        return Object.keys(anchors).reduce((compilation, anchorName) => {
            if (anchorName === 'auto') {
                compilation[anchorName] = anchors[anchorName];
            } else {
                compilation[anchorName] = this.makeAnchor(anchors[anchorName]);
            }
            compilation[anchorName].x += this.x;
            compilation[anchorName].y += this.y;
            return compilation;
        }, {});
    }
};
