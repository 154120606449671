import CanvasStateSelectors from '../../../Canvas/CanvasStateSelectors';
import { getShapesLayer } from '../../Helpers/helpers';
import getPropertiesForDestructuring from '../../utilities/getPropertiesForDestructuring';

const updateCellContentTextbody = (canvasState, command) => {
    const {
        tableId,
        cellId,
        contentId,
        textBody,
        textBodyPlaceholder
    } = getPropertiesForDestructuring(
        command,
        [
            'tableId',
            'cellId',
            'contentId',
            'textBody',
            'textBodyPlaceholder'
        ]
    );

    const tablePath = CanvasStateSelectors
        .getShapePathById(canvasState, tableId);

    const cellIndex = canvasState.getIn([...tablePath, 'cells'])
        .findIndex(cell => cell.get('id') === cellId);

    const contentIndex = canvasState.getIn([...tablePath, 'cells', cellIndex, 'contents'])
        .findIndex(content => content.get('id') === contentId);

    return canvasState.updateIn(
        [
            getShapesLayer(canvasState),
            ...tablePath.slice(1),
            'cells',
            cellIndex,
            'contents',
            contentIndex
        ],
        content => (content
            .set(
                'textBody',
                textBody
            )
            .set(
                'textBodyPlaceholder',
                textBodyPlaceholder
            ))
    );
};

export default updateCellContentTextbody;
