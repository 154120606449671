const DEFAULT_TABLE_SETTINGS = {
    hasBandedRows: false,
    hasBandedColumns: false,
    hasHeaderRow: false,
    hasHeaderColumn: false,
    hasTotalRow: false,
    hasTotalColumn: false,
    mainAxis: 'vertical',
    includeHeaderRowInCount: false,
    includeHeaderColumnInCount: false,
    includeTotalRowInCount: false,
    includeTotalColumnInCount: false,
    includeHeaderRowInSelection: true,
    includeHeaderColumnInSelection: true,
    includeTotalRowInSelection: true,
    includeTotalColumnInSelection: true,
    includePartlySelected: true
};

const DEFAULT_TABLE_SIZE_SETTINGS = {
    defaultRows: 3,
    defaultColumns: 3,
    minCellHeight: 10,
    minCellWidth: 10,
    defaultCellWidth: 120,
    defaultCellMaxWidth: 400,
    maxTableWidth: 1200,
    maxTableHeight: 650
};

export {
    DEFAULT_TABLE_SETTINGS,
    DEFAULT_TABLE_SIZE_SETTINGS
};
