module.exports = Base => class extends Base {
    alignToPositions(position, positions = {}) {
        switch (position) {
            case 'center':
            case 'horizontalCenter':
                this.alignHorizontalCenter(positions.horizontalCenter);
                break;
            case 'verticalCenter':
                this.alignVerticalCenter(positions.verticalCenter);
                break;
            case 'left':
            case 'right':
            case 'top':
            case 'bottom':
                if (positions[position] === undefined) {
                    throw new Error(`Positions in ${position} value is not setted.`);
                }
                // Do not move the shape if it's furthest in its position
                if (this.boundingBox[position] === positions[position]) return;

                this[`align${position[0].toUpperCase()}${position.substr(1)}`](positions[position]);
                break;
            default:
                throw new Error(`${position} align is not supported.`);
        }
    }

    alignToTarget(position, target) {
        if (this === target) return;
        this.alignToPositions(position, Object.assign(
            target.boundingBox,
            {
                horizontalCenter: target.x,
                verticalCenter: target.y
            }
        ));
    }

    alignLeft(anchor) {
        this.x = anchor + (this.scaledWidth / 2);
    }

    alignHorizontalCenter(anchor) {
        this.x = anchor;
    }

    alignRight(anchor) {
        this.x = anchor - (this.scaledWidth / 2);
    }

    alignTop(anchor) {
        this.y = anchor + (this.scaledHeight / 2);
    }

    alignVerticalCenter(anchor) {
        this.y = anchor;
    }

    alignBottom(anchor) {
        this.y = anchor - (this.scaledHeight / 2);
    }
};
