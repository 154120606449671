import {
    Record,
    Map,
    List,
    fromJS
} from 'immutable';
import UUID from 'uuid/v4';
import genericShapeProperties from '../../genericShapeProperties';
import { addShape, getNewShapeName, updateSelection } from '../../Helpers/helpers';
import { generateLinePath } from '../../../utilities/PathGenerator/PathGenerator';
import Textbody from '../../Helpers/Text/TextBody';
import getPropertiesForDestructuring from '../../utilities/getPropertiesForDestructuring';
import { applyStyle } from '../../Helpers/Style/style';
import { convertPropertiesToShapeProperties } from '../../utilities/convertPropertiesToShapeProperties';

const minDimension = 0.5;

const LineRecord = Record({
    ...genericShapeProperties,
    type: 'Line',
    path: '',
    coords: List([]),
    connectionStrategy: 'straight',
    lockPath: false
}, 'Line');

const getDimensions = coords => {
    const maxCoords = coords.reduce(
        (result, nextCoords) => result
            .update('x', x => Math.max(x, nextCoords.get('x')))
            .update('y', y => Math.max(y, nextCoords.get('y'))),
        Map({ x: -Infinity, y: -Infinity })
    );

    const minCoords = coords.reduce(
        (result, nextCoords) => result
            .update('x', x => Math.min(x, nextCoords.get('x')))
            .update('y', y => Math.min(y, nextCoords.get('y'))),
        Map({ x: Infinity, y: Infinity })
    );

    return Map({
        width: maxCoords.get('x') - minCoords.get('x'),
        height: maxCoords.get('y') - minCoords.get('y')
    });
};

const add = (canvasState, command) => {
    const {
        shapes,
        defaultCanvasItemStyles,
        editMode
    } = getPropertiesForDestructuring(
        command,
        [
            'shapes',
            'defaultCanvasItemStyles',
            'editMode'
        ]
    );

    const newIds = [];

    let updatedCanvasState = shapes.reduce((currentCanvasState, shape) => {
        const style = defaultCanvasItemStyles.get(shape.get('styleToLoad') || 'line');
        const styleDefinition = applyStyle(Map(), style, 'Line');
        const styleToApply = convertPropertiesToShapeProperties(styleDefinition);

        const textBody = Textbody.TextBody({
            paragraphStyles: List([Map({ isDefault: true })]),
            paragraphs: List([Map({ startIndex: 0, endIndex: -1, style: 1 })]),
            runStyles: List([Map({ isDefault: true })]),
            assignedStyles: Map({
                paragraph: styleToApply.getIn(['textProps', 'assignedParagraphStyle']),
                run: styleToApply.getIn(['textProps', 'assignedRunStyle']),
                textBody: styleToApply.getIn(['textProps', 'assignedStyle'])
            })
        });

        const textBodyPlaceholder = Textbody.TextBody({
            paragraphStyles: List([Map({ isDefault: true })]),
            paragraphs: List([Map({ startIndex: 0, endIndex: -1, style: 1 })]),
            runStyles: List([Map({ isDefault: true })]),
            assignedStyles: Map({
                paragraph: styleToApply.getIn(['textProps', 'assignedParagraphStyle']),
                run: styleToApply.getIn(['textProps', 'assignedRunStyle']),
                textBody: styleToApply.getIn(['textProps', 'assignedStyle'])
            })
        });

        const modifiers = shape.get('modifiers');

        const dimensions = getDimensions(modifiers.get('coords'));

        const height = Math.max(dimensions.get('height'), minDimension);

        const width = Math.max(dimensions.get('width'), minDimension);

        const path = generateLinePath({
            coords: modifiers.get('coords').toJS(),
            lineWidth: Math.max(styleToApply.getIn(['strokeProps', 'assignedStrokeStyle', 'width']), 0.5),
            lineDash: styleToApply.getIn(['strokeProps', 'assignedStrokeStyle', 'dash', 'type']),
            lineDashStops: styleToApply.getIn(['strokeProps', 'assignedStrokeStyle', 'dash', 'dashStops'], List()).toJS(),
            lineCap: styleToApply.getIn(['strokeProps', 'assignedStrokeStyle', 'cap']),
            lineHead: styleToApply.getIn(['strokeProps', 'assignedStrokeStyle', 'head']).toJS(),
            lineTail: styleToApply.getIn(['strokeProps', 'assignedStrokeStyle', 'tail']).toJS()

        }).buildString();

        const newShape = LineRecord(
            modifiers
                .merge(
                    fromJS({
                        coords: modifiers.get('coords'),
                        name: getNewShapeName(currentCanvasState, 'Line'),
                        id: UUID().toString(),
                        inLayout: editMode === 'layout',
                        x: currentCanvasState.get('size').get('width') / 2,
                        y: currentCanvasState.get('size').get('height') / 2,
                        assignedStyles: Map({
                            shape: styleToApply.getIn(['shapeProps', 'assignedShapeStyle']),
                            stroke: styleToApply.getIn(['strokeProps', 'assignedStrokeStyle'])
                        }),
                        style: style.get('id'),
                        textBody,
                        textBodyPlaceholder,
                        path,
                        height,
                        width
                    })
                )
        );
        newIds.push(newShape.get('id'));
        return addShape(currentCanvasState, newShape);
    }, canvasState);
    updatedCanvasState = updateSelection(updatedCanvasState, fromJS({ selection: newIds }));
    return updatedCanvasState;
};

export default add;
