const isNil = require('lodash/isNil');

const Style = require('./Style');
const Fill = require('../Fill');

module.exports = class extends Style {
    static fromJSON(properties) {
        return new this(properties);
    }

    static get properties() {
        return [
            ...super.properties,
            'fill',
            'opacity',
            'rx',
            'ry'
        ];
    }

    static get atomicProperties() {
        return [
            'fill'
        ];
    }

    constructor({
        fill,
        opacity,
        rx,
        ry,
        ...otherProperties
    }) {
        super(otherProperties);
        this.fill = fill;
        this.opacity = opacity;
        this.rx = rx;
        this.ry = ry;
    }

    toJSON() {
        return {
            ...super.toJSON(),
            fill: this.fill,
            opacity: this.opacity,
            rx: this.rx,
            ry: this.ry
        };
    }

    get fill() {
        if (!this.canvasItem) {
            throw new Error('Can\'t get fill without canvasItem');
        }
        return Fill.forceOpacity(
            Fill.updateValueOfColorDescriptors(this._fill, this.canvasItem),
            this.opacity
        );
    }

    set fill(fill) {
        if (!isNil(fill)) {
            this._fill = fill;
            let opacity = 1;
            const rgba = Fill.extractRGBAFromFirstDecsriptor(fill, this.canvasItem);
            if (rgba && !isNil(rgba.alpha)) {
                opacity = rgba.alpha;
            }
            this.opacity = opacity;
        }
    }

    get opacity() {
        return this._opacity;
    }

    set opacity(opacity) {
        if (!isNil(opacity)) {
            this._opacity = opacity;
        }
    }

    get rx() {
        return this._rx;
    }

    set rx(rx) {
        if (!isNil(rx)) {
            this._rx = rx;
        }
    }

    get ry() {
        return this._ry;
    }

    set ry(ry) {
        if (!isNil(ry)) {
            this._ry = ry;
        }
    }
};
