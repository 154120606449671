const {
    hasFontMetrics
} = require('../../utilities/FontMetricsGetter');

const { SMALL_CAPS_RATIO, PPTX_FIRST_LINE_RATIO } = require('../../constants/text');

module.exports = {
    _render(ctx) {
        this.callSuper('_render', ctx);
        this._renderBullets(ctx);
    },

    _renderTextCommon(ctx, method) {
        if (!this._shouldRenderText()) {
            return;
        }

        ctx.save();
        let lineHeights = 0;
        const left = this._getLeftOffset();
        const top = this._getTopOffset();
        const offsets = this._applyPatternGradientTransform(ctx, method === 'fillText' ? this.fill : this.stroke);
        for (let i = 0, len = this._textLines.length; i < len; i++) {
            const leftOffset = this._getLineLeftOffset(i);
            const topOffset = i !== 0 ? this._getLineTopOffset(i) : 0;
            const lineHeight = this.getParagraphForLine(i).lineHeight / 1.2;

            const leading = this.calcLeading(i);
            let heightOfLine = leading;

            if (lineHeight !== 1) {
                if (!i) { // First Line
                    heightOfLine *= PPTX_FIRST_LINE_RATIO;
                } else { // Multiple Font Sizes
                    heightOfLine += this.calcMultipleLinesDiff(i, heightOfLine);
                }
            } else if (!i) {
                heightOfLine -= heightOfLine * this._fontSizeFraction;
            } else {
                heightOfLine += this.calcMultipleLinesDiff(i, heightOfLine);
            }

            lineHeights += topOffset;
            this._renderTextLine(
                method,
                ctx,
                this._textLines[i],
                left + leftOffset - offsets.offsetX,
                top + lineHeights + heightOfLine - offsets.offsetY,
                i
            );

            const bottomOffset = this._getLineBottomOffset(i);
            lineHeights += heightOfLine + bottomOffset;
        }
        ctx.restore();
    },

    _renderChar(method, ctx, lineIndex, charIndex, _char, left, approximatedCharacterTop) {
        let top = hasFontMetrics(this.getValueOfPropertyAt(lineIndex, charIndex, 'fontFamily')) ?
            this.correctCharacterTopToActualBaseline({
                approximatedCharacterTop,
                charIndex,
                lineIndex
            }) :
            approximatedCharacterTop;

        const decl = this._getStyleDeclaration(lineIndex, charIndex);
        const fullDecl = this.getCompleteStyleDeclaration(lineIndex, charIndex);
        const shouldFill = method === 'fillText' && fullDecl.fill;
        const shouldStroke = method === 'strokeText' && fullDecl.stroke && fullDecl.strokeWidth;

        if (!shouldStroke && !shouldFill) {
            return;
        }

        if (decl) ctx.save();

        let currentLeft = left;
        let currentChar = _char;

        switch (fullDecl.textTransform) {
            case 'smallCaps':
                [..._char].forEach(char => {
                    let currentStyle = fullDecl;
                    let currentCharIndex = charIndex;
                    currentChar = char;

                    if (char !== char.toUpperCase()) {
                        currentStyle = {
                            ...fullDecl,
                            fontSize: fullDecl.fontSize * SMALL_CAPS_RATIO
                        };
                        currentChar = char.toUpperCase();
                    }

                    this._applyCharStyles(method, ctx, lineIndex, currentCharIndex, currentStyle);

                    if (decl && decl.textBackgroundColor) {
                        this._removeShadow(ctx);
                    }
                    if (decl && decl.deltaY) {
                        top += decl.deltaY;
                    }

                    if (shouldFill) ctx.fillText(currentChar, currentLeft, top);
                    if (shouldStroke) ctx.strokeText(currentChar, currentLeft, top);

                    currentLeft += this._measureChar(currentChar, currentStyle).width;
                    currentCharIndex++;
                });
                break;
            case 'allCaps':
                currentChar = _char.toUpperCase();
                /* falls through */
            default:
                this._applyCharStyles(method, ctx, lineIndex, charIndex, fullDecl);

                if (decl && decl.textBackgroundColor) {
                    this._removeShadow(ctx);
                }
                if (decl && decl.deltaY) {
                    top += decl.deltaY;
                }

                if (shouldFill) ctx.fillText(this.convertTabsToSpaces(currentChar), left, top);
                if (shouldStroke) ctx.strokeText(this.convertTabsToSpaces(currentChar), left, top);
        }

        if (decl) ctx.restore();
    },

    _renderChars(method, ctx, line, initialLeft, initialTop, lineIndex) {
        let left = initialLeft;
        const top = initialTop;
        const {
            textAlign = '',
            charSpacing = 0
        } = this.getParagraphForLine(lineIndex);
        const isJustify = textAlign.indexOf('justify') !== -1;
        let actualStyle;
        let nextStyle;
        let charsToRender = '';
        let charBox;
        let boxWidth = 0;
        let timeToRender;
        const shortCut = !isJustify && charSpacing === 0 && this.isEmptyStyles(lineIndex);

        ctx.save();
        if (shortCut) {
            // render all the line in one pass without checking
            this._renderChar(method, ctx, lineIndex, 0, this.textLines[lineIndex], left, top);
            ctx.restore();
            return;
        }
        for (let i = 0, len = line.length - 1; i <= len; i++) {
            timeToRender = i === len || charSpacing;
            charsToRender += line[i];
            charBox = this.__charBounds[lineIndex][i];
            if (boxWidth === 0) {
                left += charBox.kernedWidth - charBox.width;
                boxWidth += charBox.width;
            } else {
                boxWidth += charBox.kernedWidth;
            }
            if (isJustify && !timeToRender) {
                if (this._reSpaceAndTab.test(line[i])) {
                    timeToRender = true;
                }
            }
            if (!timeToRender) {
                // if we have charSpacing, we render char by char
                actualStyle = actualStyle || this.getCompleteStyleDeclaration(lineIndex, i);
                nextStyle = this.getCompleteStyleDeclaration(lineIndex, i + 1);
                timeToRender = this._hasStyleChanged(actualStyle, nextStyle);
            }
            if (timeToRender) {
                this._renderChar(method, ctx, lineIndex, i, charsToRender, left, top);
                charsToRender = '';
                actualStyle = nextStyle;
                left += boxWidth;
                boxWidth = 0;
            }
        }
        ctx.restore();
    },

    _renderTextLinesBackground(ctx) {
        if (!this.textBackgroundColor && !this.styleHas('textBackgroundColor')) {
            return;
        }
        let lineTopOffset = 0;
        let heightOfLine;
        let lineLeftOffset;
        const originalFill = ctx.fillStyle;
        let line;
        let lastColor;
        const leftOffset = this._getLeftOffset();
        const topOffset = this._getTopOffset();
        let boxStart = 0;
        let boxWidth = 0;
        let charBox;
        let currentColor;

        for (let i = 0, len = this._textLines.length; i < len; i++) {
            heightOfLine = this.getHeightOfLine(i);
            const fontSize = this.getMaxFontSizeForLine(i);
            lineTopOffset += this._getLineTopOffset(i);
            if (!this.textBackgroundColor && !this.styleHas('textBackgroundColor', i)) {
                lineTopOffset += heightOfLine;
            } else {
                line = this._textLines[i];
                lineLeftOffset = this._getLineLeftOffset(i);
                boxWidth = 0;
                boxStart = 0;
                lastColor = this.getValueOfPropertyAt(i, 0, 'textBackgroundColor');
                for (let j = 0, jlen = line.length; j < jlen; j++) {
                    charBox = this.__charBounds[i][j];
                    currentColor = this.getValueOfPropertyAt(i, j, 'textBackgroundColor');
                    if (currentColor !== lastColor) {
                        ctx.fillStyle = lastColor;
                        if (lastColor) {
                            ctx.fillRect(
                                leftOffset + lineLeftOffset + boxStart,
                                topOffset + lineTopOffset + heightOfLine - this.getLineBaselineOffset(i),
                                boxWidth,
                                this._fontSizeMult * fontSize
                            );
                        }
                        boxStart = charBox.left;
                        boxWidth = charBox.width;
                        lastColor = currentColor;
                    } else {
                        boxWidth += charBox.kernedWidth;
                    }
                }
                if (currentColor) {
                    ctx.fillStyle = currentColor;
                    ctx.fillRect(
                        leftOffset + lineLeftOffset + boxStart,
                        topOffset + lineTopOffset + heightOfLine - this.getLineBaselineOffset(i),
                        boxWidth,
                        this._fontSizeMult * fontSize
                    );
                }
                lineTopOffset += heightOfLine;
            }
            lineTopOffset += this._getLineBottomOffset(i);
        }
        ctx.fillStyle = originalFill;
        this._removeShadow(ctx);
    },

    _renderTextDecoration(ctx, type) {
        if (!this[type] && !this.styleHas(type)) {
            return;
        }
        let heightOfLine; let size; let _size;
        let lineLeftOffset; let dy; let _dy;
        let line; let lastDecoration;
        const leftOffset = this._getLeftOffset();
        let topOffset = this._getTopOffset(); let top;
        let boxStart; let boxWidth; let charBox; let currentDecoration;
        let currentFill; let lastFill;
        const charSpacing = this._getWidthOfCharSpacing();

        for (let i = 0, len = this._textLines.length; i < len; i++) {
            heightOfLine = this.getHeightOfLine(i);
            topOffset += this._getLineTopOffset(i);
            if (!this[type] && !this.styleHas(type, i)) {
                topOffset += heightOfLine;
            } else {
                line = this._textLines[i];
                lineLeftOffset = this._getLineLeftOffset(i);
                boxStart = 0;
                boxWidth = 0;
                lastDecoration = this.getValueOfPropertyAt(i, 0, type);
                lastFill = this.getValueOfPropertyAt(i, 0, 'fill');
                top = topOffset;
                size = this.getHeightOfChar(i, 0);
                dy = this.getValueOfPropertyAt(i, 0, 'deltaY');
                for (let j = 0, jlen = line.length; j < jlen; j++) {
                    charBox = this.__charBounds[i][j];
                    currentDecoration = this.getValueOfPropertyAt(i, j, type);
                    currentFill = this.getValueOfPropertyAt(i, j, 'fill');
                    _size = this.getHeightOfChar(i, j);
                    _dy = this.getValueOfPropertyAt(i, j, 'deltaY');
                    if ((
                        currentDecoration !== lastDecoration ||
                        currentFill !== lastFill ||
                        _size !== size ||
                        _dy !== dy
                    ) &&
                        boxWidth > 0) {
                        ctx.fillStyle = lastFill;
                        if (lastDecoration && lastFill) {
                            ctx.fillRect(
                                leftOffset + lineLeftOffset + boxStart,
                                top + this.offsets[type] * size + dy + heightOfLine,
                                boxWidth,
                                this.fontSize / 15
                            );
                        }
                        boxStart = charBox.left;
                        boxWidth = charBox.width;
                        lastDecoration = currentDecoration;
                        lastFill = currentFill;
                        size = _size;
                        dy = _dy;
                    } else {
                        boxWidth += charBox.kernedWidth;
                    }
                }
                ctx.fillStyle = currentFill;
                if (currentDecoration && currentFill) {
                    ctx.fillRect(
                        leftOffset + lineLeftOffset + boxStart,
                        top + this.offsets[type] * size + dy + heightOfLine,
                        boxWidth - charSpacing,
                        this.fontSize / 15
                    );
                }
                topOffset += heightOfLine;
            }
            topOffset += this._getLineBottomOffset(i);
        }
        this._removeShadow(ctx);
    },

    _renderBullets(ctx) {
        this.paragraphs.forEach((paragraph, index) => {
            if (paragraph.type === 'listItem') {
                this._renderBullet(ctx, paragraph, index);
            }
        });
    },

    _renderBullet(ctx, paragraph, index) {
        const {
            bullet,
            firstLineIndex,
            indent,
            text
        } = paragraph;
        const leftOffset = this._getLeftOffset();

        if (!this._textLines[firstLineIndex]) {
            return;
        }

        const bulletWidth = bullet ? bullet.getLineWidth(0) : 0;
        const bulletOffset = this._getLineLeftOffset(firstLineIndex) + (indent === 0 ? -bulletWidth : 0);

        bullet.group = this.group;
        bullet.top = this._getBulletTopOffset(firstLineIndex, bullet);
        bullet.left = Math.max(this._getLeftOffset(), bulletOffset + indent + leftOffset);

        const {
            start: {
                paragraphIndex
            }
        } = this.getSelectionIndexes();

        const isEditingInParagraph = (this.isEditing && paragraphIndex === index);
        const hasText = text?.length;

        bullet.overrideOpacity(isEditingInParagraph, hasText);

        bullet.render(ctx);
    },

    _getBulletTopOffset(index, bullet) {
        const bulletHeightOfLine = bullet.fontSize * (this._fontSizeMult - 0.25);
        return this.getTopOffsetBeforeLine(index) + this.getHeightOfLine(index) - bulletHeightOfLine;
    },

    _shouldRenderText() {
        if (this?.group?.isImagePlaceholder() && this?.group?.hasImageFill()) {
            return false;
        }

        return true;
    }
};
