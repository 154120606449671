const isPlainObject = require('lodash/isPlainObject');

function filterObject(input) {
    const output = {};
    Object.keys(input)
        .filter(key => input[key] !== undefined)
        .forEach(key => {
            output[key] = isPlainObject(input[key]) ? filterObject(input[key]) : input[key];
        });
    return output;
}

module.exports = filterObject;
