import { Map } from 'immutable';

const selectCells = (canvasState, command) => {
    const updatedCanvasState = canvasState.set(
        'contextualSelection',
        Map({
            mode: 'cursorZone',
            table: command.getIn(['ids', 0]),
            cells: command.get('cells')
        })
    );
    return updatedCanvasState;
};

export default selectCells;
