const createFontLoader = ({
    name, status, fontFaceName, fontFamily, fontFaceURL, file
}) => {
    const fontLoader = {
        name,
        status,
        fontFaceName,
        fontFamily,
        fontFaceURL,
        file
    };
    return fontLoader;
};

const updateFontLoader = (fontLoader, update) => createFontLoader({
    ...fontLoader,
    ...update
});

module.exports = {
    createFontLoader,
    updateFontLoader
};
