import { fromJS } from 'immutable';
// eslint-disable-next-line import/no-cycle
import generalFromFabricToCanvasState from '../fromFabricToCanvasState';
import defaultPropertyAdapters from '../PropertyAdapters/default';

const fromFabricToCanvasState = (fabricShape, canvasState) => fromJS({
    id: defaultPropertyAdapters.id(fabricShape),
    name: defaultPropertyAdapters.name(fabricShape),
    type: 'Group',
    width: defaultPropertyAdapters.width(fabricShape),
    height: defaultPropertyAdapters.height(fabricShape),
    x: defaultPropertyAdapters.left(fabricShape),
    y: defaultPropertyAdapters.top(fabricShape),
    flipX: defaultPropertyAdapters.flipX(fabricShape),
    flipY: defaultPropertyAdapters.flipY(fabricShape),
    scaleX: defaultPropertyAdapters.scaleX(fabricShape),
    scaleY: defaultPropertyAdapters.scaleY(fabricShape),
    skewX: defaultPropertyAdapters.skewX(fabricShape),
    skewY: defaultPropertyAdapters.skewY(fabricShape),
    inLayout: defaultPropertyAdapters.inLayout(fabricShape),
    isHidden: defaultPropertyAdapters.isHidden(fabricShape),
    isLocked: defaultPropertyAdapters.isLocked(fabricShape),
    shapes: fabricShape.objects.map(obj => generalFromFabricToCanvasState(obj, canvasState))
});

export default fromFabricToCanvasState;
