import { Map } from 'immutable';

const DEFAULT_RUNSTYLE = Map({
    characterSpacing: 0,
    color: Map({
        type: 'custom',
        value: 'rgba(0, 0, 0, 1)'
    }),
    font: Map({
        case: null,
        family: 'Lato',
        size: 16,
        style: 'normal',
        weight: 'normal'
    }),
    hyperlink: '',
    linethrough: false,
    opacity: 1,
    overline: false,
    subscript: false,
    superscript: false,
    textTransform: '',
    underline: false
});

const DEFAULT_PARAGRAPHSTYLE = Map({
    align: 'center',
    indent: 0,
    lineSpacing: 1.2,
    padding: Map({
        bottom: 0,
        left: 0,
        right: 0,
        top: 0
    })
});

const DEFAULT_TEXT_BODY_STYLE = Map({
    autoFitText: false,
    autoFitShape: false,
    margins: Map({
        bottom: 0,
        left: 0,
        right: 0,
        top: 0
    }),
    verticalAlign: 'middle',
    textDirection: 'horizontal'
});

const DEFAULT_BULLETS = Map({
    type: 'none',
    text: '•'
});

export {
    DEFAULT_RUNSTYLE,
    DEFAULT_PARAGRAPHSTYLE,
    DEFAULT_TEXT_BODY_STYLE,
    DEFAULT_BULLETS
};
