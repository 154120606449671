const { fabric } = require('fabric');
const get = require('lodash/get');
const Textshape = require('./TextShape');

const CustomPath = fabric.util.createClass(Textshape, {
    type: 'customPath',

    initialize(json, opts) {
        this.callSuper('initialize', json, opts);
        this.shapeType = 'CustomPath';
    },

    updateShapeScale(scaleX, scaleY, fromGroup) {
        const shapeObject = this.getShapeObject();
        const strokeWidth = get(this, 'stroke.width', 0);

        let actualScale = {
            x: scaleX,
            y: scaleY
        };

        if (fromGroup) {
            actualScale = this.getScaleWithRotation(scaleX, scaleY);
        }

        shapeObject.set({
            pathOffset: {
                x: (((shapeObject.width + strokeWidth) * actualScale.x) - strokeWidth) / 2,
                y: (((shapeObject.height + strokeWidth) * actualScale.y) - strokeWidth) / 2
            }
        });
        this.callSuper('updateShapeScale', scaleX, scaleY, true, fromGroup);
    }
});

CustomPath.fromObject = (object, callback) => {
    const path = new CustomPath(object);
    path.on('text:load', () => {
        callback(path);
    })
        .on('load:error', err => {
            console.error(err);
            callback(null);
        });
};

module.exports = CustomPath;
