import {
    Record,
    Map,
    List,
    fromJS
} from 'immutable';
import UUID from 'uuid/v4';
import genericShapeProperties from '../../genericShapeProperties';
import { addShape, getNewShapeName, updateSelection } from '../../Helpers/helpers';
import { applyStyle } from '../../Helpers/Style/style';
import Textbody from '../../Helpers/Text/TextBody';
import { convertPropertiesToShapeProperties } from '../../utilities/convertPropertiesToShapeProperties';
import getPropertiesForDestructuring from '../../utilities/getPropertiesForDestructuring';

const gapAroundImageInPx = 200;

const generatePath = (width, height) => `M0 0 L${width} 0 L${width} ${height} L0 ${height} Z`;

const handleInitialSize = attributes => {
    const pageSize = {
        pageWidth: attributes.pageWidth,
        pageHeight: attributes.pageHeight
    };

    const imageSize = {
        width: attributes.width,
        height: attributes.height
    };

    if (pageSize.pageWidth && pageSize.pageHeight &&
        imageSize.width && imageSize.height && !attributes.isBackground) {
        if (attributes.width > pageSize.pageWidth || attributes.height > pageSize.pageHeight) {
            return scaleAtCreation(imageSize, pageSize);
        }
    }
    return {
        height: attributes.height,
        width: attributes.width
    };
};

const scaleAtCreation = (imageSize, pageSize) => {
    const scaleX = Math.min((pageSize.pageWidth - gapAroundImageInPx) / imageSize.width, 1);
    const scaleY = Math.min((pageSize.pageHeight - gapAroundImageInPx) / imageSize.height, 1);
    const scale = Math.min(scaleX, scaleY);
    const height = imageSize.height * scale;
    const width = imageSize.width * scale;
    return {
        height,
        width
    };
};

const ImageRecord = Record({
    ...genericShapeProperties,
    type: 'Image',
    path: '',
    lockPath: false,
    lockAspectRatio: true,
    clipTo: undefined,
    source: '',
    style: ''
}, 'Image');

const add = (canvasState, command) => {
    const {
        shapes,
        defaultCanvasItemStyles,
        editMode
    } = getPropertiesForDestructuring(
        command,
        [
            'shapes',
            'defaultCanvasItemStyles',
            'editMode'
        ]
    );

    const newIds = [];

    let updatedCanvasState = shapes.reduce((currentCanvasState, shape) => {
        const style = defaultCanvasItemStyles.get(shape.get('styleToLoad') || 'shape');
        const styleDefinition = applyStyle(Map(), style, 'Image');
        const styleToApply = convertPropertiesToShapeProperties(styleDefinition);

        const textBody = Textbody.TextBody({
            paragraphStyles: List([Map({ isDefault: true })]),
            paragraphs: List([Map({ startIndex: 0, endIndex: -1, style: 1 })]),
            runStyles: List([Map({ isDefault: true })]),
            assignedStyles: Map({
                paragraph: styleToApply.getIn(['textProps', 'assignedParagraphStyle']),
                run: styleToApply.getIn(['textProps', 'assignedRunStyle']),
                textBody: styleToApply.getIn(['textProps', 'assignedStyle'])
            })
        });

        const textBodyPlaceholder = Textbody.TextBody({
            paragraphStyles: List([Map({ isDefault: true })]),
            paragraphs: List([Map({ startIndex: 0, endIndex: -1, style: 1 })]),
            runStyles: List([Map({ isDefault: true })]),
            assignedStyles: Map({
                paragraph: styleToApply.getIn(['textProps', 'assignedParagraphStyle']),
                run: styleToApply.getIn(['textProps', 'assignedRunStyle']),
                textBody: styleToApply.getIn(['textProps', 'assignedStyle'])
            })
        });

        const source = (shape.get('source') || '').split('/').pop();

        const {
            height,
            width
        } = handleInitialSize(
            {
                height: shape.get('height'),
                width: shape.get('width'),
                pageWidth: shape.get('pageWidth'),
                pageHeight: shape.get('pageHeight')
            }
        );

        const path = generatePath(width, height);

        const newShape = ImageRecord({
            name: getNewShapeName(currentCanvasState, 'Image'),
            id: UUID().toString(),
            inLayout: editMode === 'layout',
            x: currentCanvasState.get('size').get('width') / 2,
            y: currentCanvasState.get('size').get('height') / 2,
            fill: shape.get('fill'),
            textBody,
            textBodyPlaceholder,
            path,
            height,
            width,
            source
        });
        newIds.push(newShape.get('id'));
        return addShape(currentCanvasState, newShape);
    }, canvasState);
    updatedCanvasState = updateSelection(updatedCanvasState, fromJS({ selection: newIds }));
    return updatedCanvasState;
};

export default add;
