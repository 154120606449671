const set = require('lodash/set');
const get = require('lodash/get');

module.exports = tip => ({
    toItemUpdate: (value, canvasItemStyle) => set(
        set(
            canvasItemStyle,
            `${tip}TipLength`,
            value
        ),
        `${tip}TipWidth`,
        value
    ),
    toStyle: canvasItem => {
        const { length, width } = get(canvasItem, `stroke.${tip}`, {});
        if (length === width) {
            return length;
        }
        return '';
    }
});
