import * as types from '#Constants/ActionTypes';

const currentShare = (state = {}, action) => {
    switch (action.type) {
        case types.RESET_CURRENT_SHARE:
            return {};
        case types.SET_CURRENT_SHARE:
        case types.FETCH_SHARE_INFORMATION:
            return action.share;
        case types.FETCH_SHARES:
            return action.currentShare;
        default:
            return state;
    }
};

const isAudienceLevel = (state = false, action) => {
    switch (action.type) {
        case 'SET_SHARE_LEVEL':
            return action.isAudienceLevel;
        default:
            return state;
    }
};

export { currentShare, isAudienceLevel };
