import { saveAs } from 'file-saver/FileSaver';
import * as types from '#Constants/ActionTypes';
import api from '#Utilities/api';
import client from '#Utilities/graphql';

const getDeckAsBlob = (deckId, pages, type) => api
    .post({
        path: `decks/${deckId}/export`,
        body: {
            data: {
                selectedPagesNumber: pages || [],
                type
            }
        },
        parse: 'blob'
    });

const exportAsImages = (pages, deckId = null) => (dispatch, getState) => {
    const state = getState();
    const deckIdToExport = deckId || state.deck.id;
    dispatch({ type: types.EXPORT_STARTED });
    return getDeckAsBlob(deckIdToExport, pages, 'png')
        .then(blob => {
            saveAs(blob, `${state.deck.title}.zip`);
            dispatch({ type: types.EXPORT_FINISHED });
        });
};

const exportAsPDF = (pages, deckId = null) => (dispatch, getState) => {
    const state = getState();
    const deckIdToExport = deckId || state.deck.id;
    dispatch({ type: types.EXPORT_STARTED });
    return getDeckAsBlob(deckIdToExport, pages, 'pdf')
        .then(blob => {
            saveAs(blob, `${state.deck.title}.pdf`);
            dispatch({ type: types.EXPORT_FINISHED });
        });
};

const getFileTypeForStore = fileType => {
    let newFileType = 'private';

    if (fileType.includes('public') && !fileType.includes('private')) {
        newFileType = 'public';
    } else if (!fileType.includes('public') && fileType.includes('private')) {
        newFileType = 'private';
    }

    return newFileType;
};

const resetFilesList = fileType => ({
    type: types.RESET_FILES_LIST,
    fileType: getFileTypeForStore(fileType)
});

const setFiles = accessCategorizedFiles => ({
    type: types.SET_FILES,
    private: accessCategorizedFiles.private,
    public: accessCategorizedFiles.public
});

const setSearchBody = body => ({
    type: types.SET_SEARCH_BODY,
    body
});

const setCurrentFile = (fileId, files) => {
    let currentFile = {};

    if (fileId && files) {
        currentFile = (files.find(file => file.deck.id === fileId) || {});
    }

    return {
        type: types.SET_CURRENT_FILE,
        currentFile: { ...currentFile.deck, pageToDisplayNumber: currentFile.pageToDisplayNumber }
    };
};

const fetchFiles = body => dispatch => {
    dispatch({ type: types.FETCH_FILES });
    return client
        .search({
            searchParams: body,
            url: ''
        })
        .then(accessCategorizedFiles => {
            dispatch(setFiles(accessCategorizedFiles));
        });
};

const populateElasticSearchBody = ({
    size,
    order,
    type,
    searchId,
    resourceOwnership,
    from,
    to,
    field,
    orderField,
    value
}, currentSearchBody) => ({
    id: searchId || currentSearchBody.id,
    params: {
        type: type || currentSearchBody.params.type,
        orderField: orderField || currentSearchBody.params.orderField,
        order: order || currentSearchBody.params.order,
        from: from !== undefined ? from : currentSearchBody.params.from,
        to: to !== undefined ? to : currentSearchBody.params.to,
        resourceOwnership: resourceOwnership || currentSearchBody.params.resourceOwnership,
        size: size || currentSearchBody.params.size,
        field: field || currentSearchBody.params.field,
        value: value !== undefined ? value : currentSearchBody.params.value
    }
});

const loadFiles = elasticSearchParams => (dispatch, getState) => {
    const currentSearchBody = getState().manage.search.body;

    let body = currentSearchBody;

    if (elasticSearchParams) {
        body = populateElasticSearchBody(elasticSearchParams, currentSearchBody);
    }

    dispatch(setSearchBody(body));
    return dispatch(fetchFiles(body));
};

const resetSearchBody = () => ({
    type: types.RESET_SEARCH_BODY
});

const shouldUpdateCurrentFile = (fileId, properties) => (dispatch, getState) => {
    const currentFile = getState().manage.currentFile;

    if ((currentFile || {}).id !== fileId) {
        return;
    }

    dispatch({
        type: types.UPDATE_CURRENT_FILE,
        properties
    });
};

const updateFileProperties = (fileId, properties) => dispatch => {
    dispatch({
        type: types.UPDATE_FILE_PROPERTIES,
        fileId,
        properties
    });

    dispatch(shouldUpdateCurrentFile(fileId, properties));
};

const removeFileFromList = fileId => ({
    type: types.REMOVE_FILE_FROM_LIST,
    fileId
});

const toggleImportModal = () => ({ type: types.TOGGLE_IMPORT_MODAL });

const toggleImportPanel = () => ({ type: types.TOGGLE_IMPORT_PANEL });

const setManageView = view => ({ type: types.SET_MANAGE_VIEW, view });

export {
    setFiles,
    fetchFiles,
    setCurrentFile,
    updateFileProperties,
    setSearchBody,
    resetSearchBody,
    loadFiles,
    resetFilesList,
    shouldUpdateCurrentFile,
    removeFileFromList,
    exportAsImages,
    exportAsPDF,
    getDeckAsBlob,
    populateElasticSearchBody,
    toggleImportModal,
    toggleImportPanel,
    setManageView
};
