import { fromJS } from 'immutable';

export default fromJS({
    achromaticSchemeColor: {},
    monochromaticSchemeColor: {},
    complementarySchemeColor: {},
    analogousSchemeColor: {},
    triadicSchemeColor: {},
    tetradicSchemeColor: {}
});
