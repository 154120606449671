import { Map, Set } from 'immutable';
import { addParagraph, getParagraphsCount } from '../Text/Paragraphs/Paragraphs';
import { setParagraphStyleProperties } from '../Text/Paragraphs/ParagraphStyles';
import { hasDefaultRunStyle, setDefaultRunStyle } from '../Text/Runs/RunStyles';
import { addTextWithStyle, getStyledParagraphsWithStyledRuns } from '../Text/TextBodyProperties/TextWithStyles';
import { copyCellContentWithoutText } from './cell';
import { getCellsBorders, getTopmostLeftmostCell } from './tableHelper';

const checkCanMergeCells = (table, cellIds) => checkRows(table, cellIds) && checkColumns(table, cellIds);

const checkRows = (table, cellIds) => {
    let cellColumnStarts = Map();
    let cellColumnEnds = Map();
    let cell;

    cellIds.forEach(cellId => {
        cell = table.get('cells').find(currentCell => currentCell.get('id') === cellId);
        for (let index = 0; index < cell.get('rowSpan'); index++) {
            const currentMin = cellColumnStarts.get(cell.get('row') + index) !== undefined ?
                cellColumnStarts.get(cell.get('row') + index) : table.get('columnWidths').size;
            cellColumnStarts = cellColumnStarts.set(cell.get('row') + index, Math.min(
                currentMin,
                cell.get('column')
            ));
            cellColumnEnds = cellColumnEnds.set(cell.get('row') + index, Math.max(
                cellColumnEnds.get(cell.get('row')) || 0,
                cell.get('column') + cell.get('columnSpan')
            ));
        }
    });

    return new Set(cellColumnStarts.values()).size === 1 &&
        new Set(cellColumnEnds.values()).size === 1;
};

const checkColumns = (table, cellIds) => {
    let cellRowStarts = Map();
    let cellRowEnds = Map();
    let cell;

    cellIds.forEach(cellId => {
        cell = table.get('cells').find(currentCell => currentCell.get('id') === cellId);
        for (let index = 0; index < cell.get('columnSpan'); index++) {
            const currentMin = cellRowStarts.get(cell.get('column') + index) !== undefined ?
                cellRowStarts.get(cell.get('column') + index) : table.get('rowHeights').size;
            cellRowStarts = cellRowStarts.set(cell.get('column') + index, Math.min(
                currentMin,
                cell.get('row')
            ));
            cellRowEnds = cellRowEnds.set(
                cell.get('column') + index,
                Math.max(
                    cellRowEnds.get(cell.get('column')) || 0,
                    cell.get('row') + cell.get('rowSpan')
                )
            );
        }
    });

    return new Set(cellRowStarts.values()).size === 1 &&
        new Set(cellRowEnds.values()).size === 1;
};

const removeCommonBorders = (table, cellIds) => table.set(
    'borders',
    table.get('borders')
        .filter(border => !removeUniqueValues(getCellsBorders(table, cellIds)).includes(border.get('id')))
);

const removeUniqueValues = list => {
    const map = new Map();
    list.forEach(a => map.set(a, (map.get(a) || 0) + 1));
    return [...new Set(list.filter(a => map.get(a) > 1))];
};

const doCellVectorsMatch = (cellVector1, cellVector2) => {
    if (cellVector1.size !== cellVector2.size) return false;
    for (let i = 0; i < cellVector1.size; i++) {
        if (cellVector1.get(i) !== cellVector2.get(i)) return false;
    }
    return true;
};

const mergeTextBodies = (table, mergedCell, cellIds) => {
    let content = copyCellContentWithoutText(getTopmostLeftmostCell(table, cellIds));
    let textCounter = 0;
    let paragraphCounter = 0;

    cellIds.forEach(cellId => {
        const currentCell = table
            .get('cells')
            .find(cell => cell.get('id') === cellId);
        const paragraphsWithStyle = getStyledParagraphsWithStyledRuns(currentCell
            .getIn(['contents', 0, 'textBody']), true);

        paragraphsWithStyle.forEach((paragraph, index) => {
            if (paragraph.get('textRuns').size !== 0) {
                content = content.set('textBody', addParagraph(content.get('textBody')));
                paragraph.get('textRuns').forEach(run => {
                    content = content.set('textBody', addTextWithStyle(
                        content.get('textBody'),
                        run.get('text'),
                        run.get('style'),
                        run.get('startIndex') + textCounter
                    ));
                    content = content.set('textBody', setParagraphStyleProperties(
                        content.get('textBody'),
                        paragraph.get('style'),
                        index + paragraphCounter,
                        index + paragraphCounter
                    ));
                });
            }
        });

        textCounter += currentCell.getIn(['contents', 0, 'textBody', 'text']).length + 1;
        paragraphCounter += getParagraphsCount(currentCell.getIn(['contents', 0, 'textBody']));
    });

    if (!hasDefaultRunStyle(content.get('textBody'))) {
        content = content.set(
            'textBody',
            setDefaultRunStyle(content.get('textBody'), Map({ isDefault: true }))
        );
    }

    return mergedCell.setIn(['contents', 0], content);
};

export {
    checkCanMergeCells,
    removeCommonBorders,
    doCellVectorsMatch,
    mergeTextBodies
};
