import { DEFAULT_SIZE } from '../../../../../Shape/config/Shapes';
import isRight from './isRight';

const getTopVertex = shape => (shape.get('topVertex') ?
    shape.get('topVertex').toJS() :
    getDefaultTopVertex(shape));

const getDefaultTopVertex = shape => ({
    x: isRight(shape) ? -(shape.get('width') || DEFAULT_SIZE.width) / 2 : 0,
    y: -(shape.get('height') || DEFAULT_SIZE.height) / 2
});

export default getTopVertex;
