const defaultsDeep = require('lodash/defaultsDeep');
const pick = require('lodash/pick');
const omit = require('lodash/omit');
const get = require('lodash/get');

const { getListStyleType, getUlLiText, getClassName } = require('../../../utilities/bulletStrategies');
const ColorValueDescriptor = require('../../ColorValueDescriptor');

const convertParagraphToHTML = Symbol('convertParagraphToHTML');
const contextualizeRunsToParagraph = Symbol('contextualizeRunsToParagraph');
const convertRunToHTML = Symbol('convertRunToHTML');
const convertRunsToHTML = Symbol('convertRunsToHTML');
const convertListItemToHTML = Symbol('convertUnorderedListItemToHTML');
const stringifyStyles = Symbol('stringifyStyles');
const convertParagraphProperty = Symbol('convertParagraphProperty');
const convertRunProperty = Symbol('convertRunProperty');
const convertParagraphPadding = Symbol('convertParagraphPadding');
const convertRunTextDecoration = Symbol('convertParagraphTextDecoration');
const convertRunFont = Symbol('convertParagraphFont');
const addUnitToValue = Symbol('addUnitToValue');
const addPixelUnitToValue = Symbol('addPixelUnitToValue');
const applyDefaultsOnStyles = Symbol('applyDefaultsOnStyles');
const getHTMLForStyles = Symbol('getHTMLForStyles');
const getStyleForFontsToLoad = Symbol('getStyleForFontsToLoad');
const sanitizeForHTML = Symbol('sanitizeForHTML');

module.exports = Base => class extends Base {
    convertToHTML() {
        this.fontsToLoad = {};

        const items = this.getStyledParagraphsWithStyledRuns(true);
        this[applyDefaultsOnStyles](items);

        const convertedContent = this.convertItemsToHTML(items);

        const fontfaces = this[getStyleForFontsToLoad]();
        return `${fontfaces}${convertedContent}`;
    }

    convertItemsToHTML(items) {
        this.lastLevel = 0;
        let text = items.reduce((html, item, index) => `${html}${this.convertItemToHTML(item, index)}`, '');
        while ((this.listTagHeap || []).length > 0) {
            text += `</${this.listTagHeap.pop()}>`;
        }
        return text;
    }

    convertItemToHTML(item, index) {
        let text = '';
        if (
            (item.style.bullet || {}).type !== (this.lastBullet || {}).type ||
            this.lastLevel !== item.style.level
        ) {
            text = this.levelList(text, item);
            text = this.changeListType(text, item);
        }

        this.lastLevel = item.style.level || 0;
        this.lastBullet = item.style.bullet;

        if (this.constructor.isList(item.style.bullet)) {
            text += this[convertListItemToHTML](item, index);
        } else {
            text += this[convertParagraphToHTML](item);
        }
        return text;
    }

    levelList(input, item) {
        let text = input;
        if (this.lastLevel < item.style.level) {
            text = this.incrementItem(text, item);
        } else if (this.lastLevel > item.style.level) {
            text = this.decrementItem(text, item);
        }
        return text;
    }

    decrementItem(input, item) {
        let text = input;
        const decrement = this.lastLevel - item.style.level;
        for (let i = 0, len = decrement; i < len; i++) {
            text += `</${this.listTagHeap.pop()}>`;
        }
        return text;
    }

    incrementItem(input, item) {
        let text = input;
        const increment = item.style.level - this.lastLevel;
        if (this.constructor.isList(item.style.bullet)) {
            text = this.incrementListItem(text, item, increment);
        }
        return text;
    }

    incrementListItem(input, item, increment) {
        let text = input;
        const listTag = this.getItemListTag(item);
        const htmlOpenListTag = this.getHTMLOpenListTag(item);
        for (let i = 0, len = increment; i < len; i++) {
            text = this.incrementOneLevelToListItem(text, htmlOpenListTag, listTag);
        }
        return text;
    }

    incrementOneLevelToListItem(input, htmlOpenListTag, listTag) {
        let text = input;
        text += htmlOpenListTag;
        this.listTagHeap = [...(this.listTagHeap || []), listTag];
        return text;
    }

    getHTMLOpenListTag(item) {
        return `<${this.getItemListTag(item)} class="${getClassName(item.style.bullet)}" style="list-style-type:${getListStyleType(item.style.bullet)}${getUlLiText(item.style.bullet)}">`;
    }

    getItemListTag(item) {
        return this.constructor.isUnorderedList(item.style.bullet) ? 'ul' : 'ol';
    }

    changeListType(input, item) {
        let text = input;
        if (
            (this.lastBullet || {}).type !== (item.style.bullet || {}).type &&
            this.lastLevel >= (item.style.level || 0)
        ) {
            if ((
                this.constructor.isList(this.lastBullet) ||
                !this.constructor.isList(item.style.bullet)
            )) {
                if ((this.listTagHeap || []).length) {
                    text += `</${this.listTagHeap.pop()}>`;
                }
            }
            if (this.constructor.isList(item.style.bullet)) {
                this.listTagHeap = [...(this.listTagHeap || []), this.getItemListTag(item)];
                text += this.getHTMLOpenListTag(item);
            }
        }
        return text;
    }

    static isList(bullet) {
        return /^(un)?ordered/.test((bullet || { type: 'none' }).type);
    }

    static isUnorderedList(bullet) {
        return /^un/.test(bullet.type);
    }

    static [sanitizeForHTML](text) {
        return text
            .replace(/&/g, '&amp;')
            .replace(/</g, '&lt;')
            .replace(/>/g, '&gt;')
            .replace(/\n/g, '<br/>');
    }

    [getStyleForFontsToLoad]() {
        const fontfaces = Object.keys(this.fontsToLoad)
            .reduce((accumulator, fontName) => {
                const isBold = fontName.endsWith(' Bold');
                const familyName = isBold ? fontName.replace(' Bold', '') : fontName;
                return `${accumulator}${`@font-face {font-family: ${familyName};src: url('${this.fontsToLoad[fontName]}')${isBold ? ';font-weight: bold' : ''}}`}`;
            }, '');
        return fontfaces.length ?
            `<style>${fontfaces}</style>` :
            '';
    }

    [applyDefaultsOnStyles](items) {
        const paragraphGlobals = {};
        if (this.align) {
            paragraphGlobals.align = this.align;
        }
        items.forEach(item => {
            const paragraphLineHeight = (item.textRuns || []).reduce((fontSizeMax, run = {}) => {
                if (run.style && run.style.font && run.style.font.size) {
                    return Math.max(fontSizeMax, run.style.font.size);
                }
                return fontSizeMax;
            }, -Infinity);
            item.style = defaultsDeep(
                item.style || {},
                {
                    font: {
                        ...(item.style || {}).font || {},
                        size: (
                            paragraphLineHeight > 0 ?
                                paragraphLineHeight :
                                this.getDefaultRunStyle().font.size
                        )
                    }
                },
                paragraphGlobals,
                this.getDefaultParagraphStyle()
            );
            item.textRuns.forEach(run => {
                run.style = defaultsDeep(
                    run.style || {},
                    this.getDefaultRunStyle()
                );
            });
        });
    }

    [convertParagraphToHTML](paragraph) {
        const runsText = this[convertRunsToHTML](paragraph);
        const stringifiedStyles = this.stringifyParagraphStyles(paragraph.style);
        return `<p${stringifiedStyles}>${runsText}</p>`;
    }

    [convertRunsToHTML](parent) {
        const runs = this.constructor[contextualizeRunsToParagraph](parent);
        let { text } = parent;
        if (runs.length) {
            text = runs.map((run, i) => {
                this.offsetRunsDynamically(run, i, runs);
                return this[convertRunToHTML](text, run);
            }).join('');
        }
        return text;
    }

    static [contextualizeRunsToParagraph](paragraph) {
        return paragraph.textRuns.map(run => Object.assign(run, {
            startIndex: Math.max(run.startIndex, paragraph.startIndex) - paragraph.startIndex,
            endIndex: Math.min(run.endIndex, paragraph.endIndex) - paragraph.startIndex
        }));
    }

    [convertRunToHTML](text, run) {
        const htmlTagsForStyles = this.constructor[getHTMLForStyles](run.style);
        const stringifiedStyles = this.stringifyRunStyles(omit(run.style, ['opacity']));
        const dynamicStyles = this.constructor
            .insertDynamicTypeInStyleString(stringifiedStyles, run.dynamicType);
        const openingStyleTags = htmlTagsForStyles.reduce((accumulator, tag) => `${accumulator}<${tag}>`, '');
        const closingStyleTags = htmlTagsForStyles.reverse().reduce((accumulator, tag) => `${accumulator}</${tag}>`, '');
        const dynamicType = run.dynamicType || 'none';
        const textSegment = this.constructor[sanitizeForHTML](
            (dynamicType !== 'none') ?
                this.getDynamicValue(run.dynamicType) :
                text.substr(run.startIndex, (run.endIndex - run.startIndex) + 1)
        );
        return `<span${dynamicStyles}>${openingStyleTags}${textSegment}${closingStyleTags}</span>`;
    }

    [convertListItemToHTML](item, index) {
        const { textRuns: [{ style: runStyle } = {}] } = item;
        const listBulletStyle = pick(runStyle, ['color', 'font.size', 'font.family']);
        if (item.text === '') {
            listBulletStyle.opacity = 0;
        }
        let paragraphStyleCss = this
            .getConvertedStylesValue(item.style, convertParagraphProperty);
        paragraphStyleCss = paragraphStyleCss.replace(/text-indent:\s?[-.\d]*px;?/g, '');
        const listBulletStyleCss = this.stringifyRunStyles(listBulletStyle).replace(/(\sstyle=)?"/g, '');
        const runsText = this[convertRunsToHTML](item);
        const liStyle = ` style="${listBulletStyleCss}${paragraphStyleCss}${getUlLiText(item.style.bullet, index)}"`;
        return `<li${liStyle}><span style="width:100%">${runsText}</span></li>`;
    }

    stringifyParagraphStyles(style) {
        return this[stringifyStyles](style, convertParagraphProperty);
    }

    stringifyRunStyles(style) {
        return this[stringifyStyles](style, convertRunProperty);
    }

    [stringifyStyles](style, converter) {
        const styleValue = this.getConvertedStylesValue(style, converter);
        if (styleValue) {
            return ` style="${styleValue}"`;
        }
        return '';
    }

    getConvertedStylesValue(style, converter) {
        if (style) {
            let convertedStyles = {};
            const propertiesToConvertNames = Object.keys(style);
            propertiesToConvertNames.forEach(propertyToConvertName => {
                convertedStyles = this[converter](style, propertyToConvertName, convertedStyles);
            });
            const convertedPropertiesNames = Object.keys(convertedStyles);
            if (convertedPropertiesNames.length > 0) {
                const convertedStylesValue = convertedPropertiesNames
                    .reduce(
                        (accumulator, name) => `${accumulator}${name}:${convertedStyles[name]};`,
                        ''
                    );
                return `${convertedStylesValue}`;
            }
        }
        return '';
    }

    [convertParagraphProperty](style, propertyName, input) {
        const output = { ...input };
        switch (propertyName) {
            case 'align':
                output['text-align'] = style.align;
                break;
            case 'indent':
                if (!style.level) {
                    output['text-indent'] = this[addPixelUnitToValue](style.indent);
                }
                break;
            case 'lineSpacing':
                output['line-height'] = style.lineSpacing;
                break;
            case 'padding':
                return {
                    ...output,
                    ...this[convertParagraphPadding](style)
                };
            case 'font':
                output['font-size'] = this[addPixelUnitToValue](style.font.size);
                break;
            case 'bullet': {
                if (get(style, 'bullet.style')) {
                    const bulletStyle = style.bullet.style;
                    if (bulletStyle.size) {
                        output['--bullet-font-size'] = this[addPixelUnitToValue](bulletStyle.size);
                    }
                    if (bulletStyle.font) {
                        output['--bullet-font-family'] = bulletStyle.font;
                    }
                    if (bulletStyle.color) {
                        output['--bullet-color'] = ColorValueDescriptor
                            .getValueFromDescriptor(bulletStyle.color, this.shape);
                        const bulletColorPreset = get(bulletStyle, 'color.preset');
                        if (bulletColorPreset) {
                            output['--bullet-color-preset'] = bulletColorPreset;
                        }
                    }
                }
                const indent = get(style, 'indent');
                if (indent) {
                    output['--bullet-indent'] = this[addPixelUnitToValue](indent);
                }
                const leftPadding = get(style, 'padding.left');
                if (leftPadding) {
                    output['--bullet-left-padding'] = this[addPixelUnitToValue](leftPadding);
                }
                const itemCountStartsAt = get(style, 'bullet.itemCountStartsAt');
                if (itemCountStartsAt) {
                    output['--item-count-starts-at'] = itemCountStartsAt;
                }
                break;
            }
            default:
                break;
        }
        return output;
    }

    [convertRunProperty](style, propertyName, input) {
        let output = { ...input };
        switch (propertyName) {
            case 'color': {
                output.color = ColorValueDescriptor.getValueFromDescriptor(style.color, this.shape);
                const preset = get(style, 'color.preset');
                if (preset) {
                    output['--color-preset'] = preset;
                }
                break;
            }
            case 'underline':
            case 'overline':
                if (style[propertyName]) {
                    output['text-decoration'] = this.constructor[convertRunTextDecoration](output['text-decoration'], propertyName);
                }
                break;
            case 'linethrough':
                if (style[propertyName]) {
                    output['text-decoration'] = this.constructor[convertRunTextDecoration](output['text-decoration'], 'line-through');
                }
                break;
            case 'font':
                output = this[convertRunFont](style.font, output);
                break;
            case 'characterSpacing':
                output['letter-spacing'] = this[addPixelUnitToValue](style.characterSpacing);
                break;
            case 'opacity':
                output.opacity = style.opacity;
                break;
            case 'textTransform':
                if (style.textTransform === 'allCaps') {
                    output['text-transform'] = 'uppercase';
                }
                if (style.textTransform === 'smallCaps') {
                    output['font-variant'] = 'small-caps';
                }
                break;
            default:
                break;
        }
        return output;
    }

    [convertRunFont](font, output) {
        if (font.family !== undefined) {
            output['font-family'] = font.family;
        }
        if (font.size) {
            output['font-size'] = this[addPixelUnitToValue](font.size);
        }
        if (font.style) {
            output['font-style'] = font.style;
        }
        if (font.weight) {
            output['font-weight'] = font.weight;
        }
        return output;
    }

    [convertParagraphPadding]({ padding, level }) {
        const paddings = {
            'padding-bottom': this[addPixelUnitToValue](padding.bottom),
            'padding-right': this[addPixelUnitToValue](padding.right),
            'padding-top': this[addPixelUnitToValue](padding.top)
        };
        if (level <= 0) {
            paddings['padding-left'] = this[addPixelUnitToValue](padding.left);
        }
        return paddings;
    }

    [addPixelUnitToValue](value = 0) {
        return this.constructor[addUnitToValue](value, 'px');
    }

    static [addUnitToValue](value, unit) {
        return `${value}${unit}`;
    }

    static [convertRunTextDecoration](textDecoration = '', name) {
        return `${textDecoration} ${name}`;
    }

    static [getHTMLForStyles](style) {
        const tags = [];
        if (style.font.weight === 'bold') {
            tags.push('strong');
        }
        if (style.font.style === 'italic') {
            tags.push('em');
        }
        if (style.underline) {
            tags.push('ins');
        }
        if (style.linethrough) {
            tags.push('del');
        }
        if (style.superscript) {
            tags.push('sup');
        }
        if (style.subscript) {
            tags.push('sub');
        }
        return tags;
    }
};
