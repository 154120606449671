const { fabric } = require('fabric');
const { SelectionHighlightConfig } = require('./SelectionHighlight.config');

const SelectionHighlight = fabric.util.createClass(fabric.Rect, {
    target: null,
    excludeFromExport: true,

    initialize(target) {
        this.target = target;
        let attributes = {
            left: this.getLeft(),
            top: this.getTop(),
            width: this.getWidth(),
            height: this.getHeight(),
            angle: this.getAngle(),
            ...SelectionHighlightConfig
        };
        if (this.target.type === 'cursorZone') {
            const tablePosition = this.handleTableCursorZones();
            attributes = {
                ...attributes,
                ...tablePosition
            };
        }
        this.callSuper('initialize', attributes);
    },

    getLeft() {
        return this.target.getAbsolutePosition ? this.target.getAbsolutePosition().x : this.target.left;
    },

    getTop() {
        return this.target.getAbsolutePosition ? this.target.getAbsolutePosition().y : this.target.top;
    },

    getWidth() {
        return this.target.width + SelectionHighlightConfig.strokeWidth;
    },

    getHeight() {
        return this.target.height + SelectionHighlightConfig.strokeWidth;
    },

    getAngle() {
        return this.target.getAbsoluteAngle ? this.target.getAbsoluteAngle() : (this.target.angle || 0);
    },

    handleTableCursorZones() {
        return {
            left: this.target.table.left,
            top: this.target.table.top,
            width: this.target.table.width + SelectionHighlightConfig.strokeWidth,
            height: this.target.table.height + SelectionHighlightConfig.strokeWidth
        };
    },

    isAlreadyHighlighted(target) {
        return target.id === this.target.id;
    }
});

const shouldShowHighlight = (activeObjects, target) => {
    const isValidTarget = target && target.id;
    const isNotActiveObjects = target && !activeObjects.find(obj => obj.id === target.id);
    const isCursorZone = (target?.type === 'cursorZone' && !activeObjects.find(obj => obj.id === target?.table?.id));
    const canHighlight = (target?.selectable || isCursorZone);
    return isValidTarget && isNotActiveObjects && canHighlight;
};

module.exports = {
    SelectionHighlight,
    shouldShowHighlight
};
