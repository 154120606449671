import getConnectionStrategy from '../PropertyAdapters/line/connectionStrategy';
import getCoords from '../PropertyAdapters/line/coords';
import getPath from '../PropertyAdapters/line/path';
import getPathOffset from '../PropertyAdapters/line/pathOffset';
import getStroke from '../PropertyAdapters/line/stroke';
import defaultShapeToFabricJSON from './default';

const fromShapeToFabricJSON = (shape, canvasState) => ({
    ...defaultShapeToFabricJSON(shape, canvasState),
    type: 'CustomLine',
    shapeType: 'Line',
    coords: getCoords(shape),
    connectionStrategy: getConnectionStrategy(shape),
    fill: getStroke(shape, canvasState.get('colorPalette'))
        .get('fill')
        .toJS(),
    path: getPath(shape),
    pathOffset: getPathOffset(shape)
});

export default fromShapeToFabricJSON;
