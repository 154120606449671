const { fabric } = require('fabric');
const defaults = require('lodash/defaults');
const omit = require('lodash/omit');

const ITextBehaviorOverwrite = require('./Mixins/iTextBehaviorOverwrite');
const Bullet = require('./TextItems/Bullet');
const TextboxOverwrite = require('./Mixins/textboxOverwrite');
const textboxRenderOverwrite = require('./Mixins/textboxRenderOverwrite');
const textboxLineDimensions = require('./Mixins/textboxLineDimensions');

const MultiParagraphTextbox = fabric.util.createClass(
    fabric.Textbox,
    ITextBehaviorOverwrite,
    TextboxOverwrite,
    textboxRenderOverwrite,
    textboxLineDimensions,
    {
        type: 'multiItemTextbox',
        originY: 'top',
        lineHeight: 1,
        isShiftKeyPressed: false,
        _wordJoiners: /[ \r]/,
        initialize(options) {
            let firstUnwrappedLineIndex = 0;
            const text = options.paragraphs.map(paragraph => {
                paragraph.firstUnwrappedLineIndex = firstUnwrappedLineIndex;
                firstUnwrappedLineIndex += paragraph.text.split(this._reNewline).length;
                return paragraph.text;
            }).join('\n');
            this.callSuper(
                'initialize',
                text,
                {
                    ...options,
                    styles: options.paragraphs.flatMap(({ styles }) => styles)
                }
            );

            if (this.hasOrderedList()) {
                this.regenerateBulletText();
            }
        },

        toObject(propertiesToInclude = []) {
            const serialized = this.callSuper('toObject', [
                'textDirection',
                'width',
                ...propertiesToInclude,
                'flipX'
            ]);

            serialized.paragraphs = this.paragraphs
                .map(paragraph => {
                    if (paragraph.type === 'listItem') {
                        const {
                            bullet,
                            ...listItem
                        } = omit(paragraph, ['bullet', 'bulletStyle.bullet']);
                        if (bullet) {
                            listItem.bulletText = bullet.text;
                            listItem.bulletStyle = omit(bullet.getStyle(), ['bullet']);
                        }
                        return listItem;
                    }
                    return paragraph;
                });

            return serialized;
        },

        _wrapText(lines, desiredWidth) {
            let currentTextItemIndex = 0;
            if (this.paragraphs.length === 0) {
                return [];
            }
            this.paragraphs[0].firstLineIndex = 0;
            this.isWrapping = true;
            const wrappedGraphemeLines = lines.reduce((currentSplitLines, line, lineIndex) => {
                if (
                    this.paragraphs.length - 1 > currentTextItemIndex &&
                    this.paragraphs[currentTextItemIndex + 1].firstUnwrappedLineIndex === lineIndex
                ) {
                    currentTextItemIndex += 1;
                    this.paragraphs[currentTextItemIndex].firstLineIndex = currentSplitLines.length;
                }
                return this._wrapParagraphLine(
                    currentTextItemIndex,
                    currentSplitLines,
                    line,
                    lineIndex,
                    desiredWidth
                );
            }, []);

            this.isWrapping = false;
            return wrappedGraphemeLines;
        },

        _wrapParagraphLine(currentTextItemIndex, currentSplitLines, line, lineIndex, desiredWidth) {
            const {
                paddingLeft = 0,
                paddingRight = 0,
                firstLineIndex,
                type,
                indent = 0
            } = this.paragraphs[currentTextItemIndex];
            let bulletWidth = 0;
            let lineText = line;
            const currentCharIndex = 0;
            if (type === 'listItem') {
                this.paragraphs[currentTextItemIndex].bullet = this.addBulletTextbox(currentTextItemIndex);
                bulletWidth = this.paragraphs[currentTextItemIndex].bullet.getLineWidth(0);
            }
            if (lineText.length === 0) {
                currentSplitLines.push([]);
            } else if (currentSplitLines.length === firstLineIndex) {
                const [firstLine] = this._wrapLine(
                    lineText,
                    lineIndex,
                    desiredWidth - (paddingLeft + paddingRight + Math.max(0, indent + bulletWidth)),
                    currentCharIndex
                );
                currentSplitLines.push(firstLine);
                lineText = lineText
                    .replace(firstLine.join(''), '')
                    .trimStart();
            }
            if (lineText.length > 0) {
                this._wrapLine(
                    lineText,
                    lineIndex,
                    desiredWidth - (paddingLeft + paddingRight),
                    currentCharIndex
                )
                    .forEach(newLine => currentSplitLines.push(newLine));
            }
            return currentSplitLines;
        },

        addBulletTextbox(currentListItemIndex) {
            const listItem = this.paragraphs[currentListItemIndex];
            const firstCharacterStyle = this.getCompleteStyleDeclaration(listItem.firstLineIndex, 0);

            const bullet = new Bullet(
                listItem.bulletText,
                defaults(
                    listItem.bulletStyle,
                    firstCharacterStyle,
                    omit(listItem, [
                        'bulletText',
                        'bulletStyle',
                        'text',
                        'level',
                        'styles',
                        'paddingBottom',
                        'paddingLeft',
                        'paddingRight',
                        'paddingTop',
                        'indent',
                        'itemCountStartsAt'
                    ])
                )
            );

            return bullet;
        },

        getParagraphIndexForLine(lineIndex) {
            let nextIndex = this.paragraphs
                .findIndex(paragraph => paragraph.firstLineIndex > lineIndex);
            if (nextIndex === -1) {
                nextIndex = this.paragraphs.length;
            }
            return nextIndex - 1;
        },

        getParagraphForLine(lineIndex) {
            return this.paragraphs[
                this.getParagraphIndexForLine(lineIndex)
            ];
        },

        getParagraphIndexForUnwrappedTextLine(lineIndex) {
            let nextIndex = this.paragraphs
                .findIndex(paragraph => paragraph.firstUnwrappedLineIndex > lineIndex);
            if (nextIndex === -1) {
                nextIndex = this.paragraphs.length;
            }
            return nextIndex - 1;
        },

        getParagraphForUnwrappedTextLine(lineIndex) {
            return this.paragraphs[
                this.getParagraphIndexForUnwrappedTextLine(lineIndex)
            ];
        },

        _isLineLastOfParagraph(lineIndex) {
            if (this.textLines.length - 1 === lineIndex) {
                return true;
            }
            const paragraphIndex = this.getParagraphIndexForLine(lineIndex);
            if (paragraphIndex + 1 === this.paragraphs.length) {
                return false;
            }
            const {
                firstLineIndex
            } = this.paragraphs[paragraphIndex + 1];
            return firstLineIndex - 1 === lineIndex;
        },

        _isLineInLastParagraph(lineIndex) {
            const paragraphIndex = this.getParagraphIndexForLine(lineIndex);

            if (paragraphIndex === this.paragraphs.length - 1) {
                return true;
            }

            return false;
        },

        isInTableCell() {
            return this.group?.group?.type === 'cell';
        },

        isCursorAtTextStart() {
            return (
                this.selectionStart === this.selectionEnd &&
                this.selectionStart === 0
            );
        },

        isCursorAtTextEnd() {
            return (
                this.selectionStart === this.selectionEnd &&
                this.selectionStart === this.text.length
            );
        },

        isCursorAtTopLeftTableCellText() {
            return (
                this.isInTableCell() &&
                this.isCursorAtTextStart()
            );
        },

        isCursorAtBottomRightTableCellText() {
            return (
                this.isInTableCell() &&
                this.isCursorAtTextEnd()
            );
        }
    }
);

MultiParagraphTextbox.fromObject = options => new MultiParagraphTextbox(options);

module.exports = MultiParagraphTextbox;
