import * as types from '#Constants/ActionTypes';
import effectiveCanvasItemStyles from './effectiveCanvasItemStyles/effectiveCanvasItemStyles';

const deck = (state = null, action) => {
    switch (action.type) {
        case types.DELETE_DECK:
            return null;
        case types.CHANGE_DECK_OBJECTIVE:
            return { ...state, objective: action.objective };
        case types.CHANGE_DECK_STORYBOARD:
            return { ...state, storyboard: action.storyboard };
        case types.CHANGE_PAGES_ORDER:
        case types.ADD_PAGES_FROM_TOPIC:
            return { ...state, pages: action.pages };
        case types.SET_DECK:
            return {
                id: action.deckId,
                version: action.deckVersion,
                title: action.deckTitle,
                pages: action.pages || [],
                activePageNumber: action.activePageNumber,
                isPublic: action.isPublic,
                isSharedDeck: action.isSharedDeck
            };
        case types.SET_PAGES_TO_WAIT_FOR_UPDATE: {
            const pages = state.pages.map(page => ({
                ...page,
                waitForUpdate: true
            }));
            return {
                ...state,
                pages
            };
        }
        case types.SET_THUMBNAIL: {
            const pages = state.pages.map(page => (
                page.id === action.id ? { ...page, thumbnail: action.thumbnail } : page
            ));

            return { ...state, pages };
        }
        case types.CHANGE_DECK_PROPERTIES:
        case types.LOAD_DECK:
        case types.UPDATE_PAGE_IN_CURRENT_DECK:
        case types.REMOVE_PAGE_IN_CURRENT_DECK:
        case types.CREATE_DECK_SUCCESS:
        case types.FETCH_DECK_SUCCESS:
        case types.UPDATE_DECK_LAYOUTS:
            return {
                ...action.deck,
                isFetching: false
            };
        case types.CREATE_DECK_FAIL:
        case types.REMOVE_PAGE_ENDED:
            return {
                ...state,
                isFetching: false
            };
        case types.FETCH_DECK:
        case types.CREATE_DECK:
        case types.UPDATE_DECK:
        case types.ADD_PAGE:
        case types.DUPLICATE_PAGE:
        case types.REMOVE_PAGE_STARTED:
            return {
                ...state,
                isFetching: true
            };
        case types.FETCH_DECK_FAIL:
            return null;
        case types.FINISH_COLOR_PALETTE_SAVE:
            return {
                ...state,
                theme: action.theme
            };
        case types.REORDER_STARTED:
            return {
                ...state,
                pages: action.pageIds.map(id => state.pages.find(page => page.id === id))
            };
        case types.UPDATE_PAGE_ID_IN_CURRENT_DECK:
            return {
                ...state,
                pages: state.pages.map(page => {
                    if (page.id !== action.previousId) {
                        return page;
                    }
                    return {
                        ...page,
                        ...action.newPage
                    };
                })
            };
        case types.SET_USER:
            return {
                ...action.currentDeck,
                isFetching: false
            };
        default:
            return state;
    }
};

export {
    deck,
    effectiveCanvasItemStyles
};
