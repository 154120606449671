/*
* This is a wrapper around the ajv validators. This is so
* we can create our custom validators and be able to use
* validate() without having worry about the underlying ajv
* implementation which does not throw errors by default.
*/
export default class AjvValidator {
    constructor(validator) {
        this.validator = validator;
    }

    validate(command) {
        if (!this.validator(command)) {
            const error = new Error(this.validator?.errors[0]?.message);
            error.errors = this.validator?.errors;
            throw error;
        }
    }
}
