const CanvasItem = require('../CanvasItem');

module.exports = class {
    static fromJSON(properties) {
        return new this(properties);
    }

    static get properties() {
        return [
            'styleId'
        ];
    }

    constructor({ styleId } = {}) {
        this.styleId = styleId;
    }

    toJSON() {
        return {
            styleId: this.styleId
        };
    }

    get styleId() {
        return this._styleId;
    }

    set styleId(styleId) {
        this._styleId = styleId;
    }

    get canvasItem() {
        return this._canvasItem;
    }

    set canvasItem(canvasItem) {
        if (!(canvasItem instanceof CanvasItem)) {
            throw new Error('Can only set canvasItem with an instance of CanvasItem');
        }
        this._canvasItem = canvasItem;
    }
};
