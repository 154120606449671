import {
    Map,
    fromJS,
    Set,
    List
} from 'immutable';
import { isNil } from 'lodash';
import UUID from 'uuid/v4';
import {
    getTableWithGrid
} from './tableGridLogic';
import {
    getBorders,
    getBottomEdgeOfCellList,
    getTopEdgeOfCellList,
    getLeftEdgeOfCellList,
    getRightEdgeOfCellList,
    getBottomBorderSegments,
    getTopBorderSegments,
    getLeftBorderSegments,
    getRightBorderSegments,
    getInnerBorders,
    getInnerHorizontalBorders,
    getInnerVerticalBorders,
    getOuterBorders
} from './tableHelper';
import { DEFAULT_STROKE_COLOR } from '../../../Shape/Stroke/StrokeFill/config/defaultStrokeFillAttributes.config';
import defaultsDeepWithExceptions from '../../utilities/defaultsDeepWithExceptions';
import { immutableDefaultStrokeAttributes as defaultStrokeAttributes } from '../../../Table/config/defaultStrokeAttributes.config';
import { getComputedStroke, copy as copyStroke } from '../Stroke';
import getPropertiesForDestructuring from '../../utilities/getPropertiesForDestructuring';

const UPDATE_TYPES = {
    ALL: 'all',
    BOTTOM: 'bottom',
    CLEAR: 'clear',
    INNER_ALL: 'inner-all',
    INNER_HORIZONTAL: 'inner-horizontal',
    INNER_VERTICAL: 'inner-vertical',
    LEFT: 'left',
    OUTER: 'outer',
    RIGHT: 'right',
    TOP: 'top'
};

const UPDATES_SEQUENCE = [
    UPDATE_TYPES.ALL,
    UPDATE_TYPES.INNER_ALL,
    UPDATE_TYPES.INNER_HORIZONTAL,
    UPDATE_TYPES.INNER_VERTICAL,
    UPDATE_TYPES.OUTER,
    UPDATE_TYPES.LEFT,
    UPDATE_TYPES.RIGHT,
    UPDATE_TYPES.BOTTOM,
    UPDATE_TYPES.TOP
];

const updateBorders = (table, update) => {
    const cellIds = update.get('ids');
    const tableWithGrid = getTableWithGrid(table);
    let borderSegmentIds = Set();
    switch (update.get('type')) {
        case UPDATE_TYPES.CLEAR:
            cellIds.forEach(id => getBorders(id, tableWithGrid)
                .forEach(borderId => {
                    borderSegmentIds = borderSegmentIds.add(borderId);
                }));
            return clearBorders(table, borderSegmentIds);
        case UPDATE_TYPES.BOTTOM:
            getBottomEdgeOfCellList(tableWithGrid, cellIds)
                .forEach(id => getBottomBorderSegments(id, tableWithGrid)
                    .forEach(borderId => {
                        borderSegmentIds = borderSegmentIds.add(borderId);
                    }));
            break;
        case UPDATE_TYPES.LEFT:
            getLeftEdgeOfCellList(tableWithGrid, cellIds)
                .forEach(id => getLeftBorderSegments(id, tableWithGrid)
                    .forEach(borderId => {
                        borderSegmentIds = borderSegmentIds.add(borderId);
                    }));
            break;
        case UPDATE_TYPES.RIGHT:
            getRightEdgeOfCellList(tableWithGrid, cellIds)
                .forEach(id => getRightBorderSegments(id, tableWithGrid)
                    .forEach(borderId => {
                        borderSegmentIds = borderSegmentIds.add(borderId);
                    }));
            break;
        case UPDATE_TYPES.TOP:
            getTopEdgeOfCellList(tableWithGrid, cellIds)
                .forEach(id => getTopBorderSegments(id, tableWithGrid)
                    .forEach(borderId => {
                        borderSegmentIds = borderSegmentIds.add(borderId);
                    }));
            break;
        case UPDATE_TYPES.INNER_ALL:
            getInnerBorders(tableWithGrid, cellIds)
                .forEach(borderId => {
                    borderSegmentIds = borderSegmentIds.add(borderId);
                });
            break;
        case UPDATE_TYPES.INNER_HORIZONTAL:
            getInnerHorizontalBorders(tableWithGrid, cellIds)
                .forEach(borderId => {
                    borderSegmentIds = borderSegmentIds.add(borderId);
                });
            break;
        case UPDATE_TYPES.INNER_VERTICAL:
            getInnerVerticalBorders(tableWithGrid, cellIds)
                .forEach(borderId => {
                    borderSegmentIds = borderSegmentIds.add(borderId);
                });
            break;
        case UPDATE_TYPES.OUTER:
            getOuterBorders(tableWithGrid, cellIds)
                .forEach(borderId => {
                    borderSegmentIds = borderSegmentIds.add(borderId);
                });
            break;
        case UPDATE_TYPES.ALL:
        default:
            cellIds.forEach(id => getBorders(id, tableWithGrid)
                .forEach(borderId => {
                    borderSegmentIds = borderSegmentIds.add(borderId);
                }));
    }

    return applyBorderUpdateToAll(
        table,
        Set(borderSegmentIds
            .filter(id => (
                !update.get('styleMismatches', List()).includes(id)
            ))),
        update
    );
};

const clearBorders = (table, borderIds) => {
    const update = fromJS({
        fill: {
            type: 'color',
            color: DEFAULT_STROKE_COLOR
        },
        width: 0
    });
    return applyBorderUpdateToAll(table, borderIds, update);
};

const applyBorderUpdateToAll = (table, borderIds = [], update) => [...borderIds]
    .reduce((currentTable, id) => {
        const borderIndex = currentTable.get('borders').findIndex(border => border.get('id') === id);
        const border = currentTable.getIn(['borders', borderIndex]);
        return currentTable.setIn(['borders', borderIndex], updateBorder(border, update));
    }, table);

const updateBorder = (border, update) => {
    const {
        fill,
        opacity,
        width,
        dash,
        assignedStrokeStyle,
        removeCustomStyles
    } = getPropertiesForDestructuring(
        update,
        [
            'fill',
            'opacity',
            'width',
            'dash',
            'assignedStrokeStyle',
            'removeCustomStyles'
        ]
    );

    let updatedBorder = border;

    if (fill && fill.get('type')) {
        if (updatedBorder.get('stroke') === undefined) {
            updatedBorder = updatedBorder.set('stroke', Map());
        }
        updatedBorder = updatedBorder.setIn(['stroke', 'fill'], fill);
    }
    if (!isNil(opacity) && !Number.isNaN(opacity)) {
        if (updatedBorder.getIn(['stroke', 'fill']) === undefined) {
            updatedBorder = updatedBorder.setIn(['stroke', 'fill'], getRenderStroke(border).get('fill'));
        }
        updatedBorder = updatedBorder.setIn(['stroke', 'opacity'], opacity);
    }
    if (width !== undefined) {
        updatedBorder = updatedBorder.setIn(['stroke', 'width'], width);
    }
    if (dash !== undefined) {
        updatedBorder = updatedBorder.setIn(['stroke', 'dash'], dash);
    }

    if (assignedStrokeStyle) {
        updatedBorder = updatedBorder.setIn(['assignedStyles', 'stroke'], getComputedStroke(assignedStrokeStyle));
        if (removeCustomStyles) {
            updatedBorder = updatedBorder.set('stroke', Map());
        }
    }

    return updatedBorder;
};

const getRenderStroke = border => defaultsDeepWithExceptions(
    border?.get('stroke') || Map(),
    border?.get('assignedStrokeStyle') || Map(),
    defaultStrokeAttributes,
    [
        'dash',
        'fill',
        'head',
        'join',
        'tail'
    ]
);

const createBorder = (name, stroke, side, row, column, attributes = {}) => fromJS({
    id: attributes.id ? attributes.id : UUID().toString(),
    name,
    stroke,
    side,
    row,
    column,
    inLayout: attributes.inLayout ? attributes.inLayout : false,
    isLocked: false,
    isHidden: false,
    isImported: false,
    assignedStyles: {
        stroke: attributes.assignedStrokeStyle
    }
});

const copy = border => {
    const borderCopy = fromJS({
        id: UUID().toString(),
        type: border.get('type'),
        name: border.get('name'),
        stroke: copyStroke(border.get('stroke') || Map()),
        side: border.get('side'),
        row: border.get('row'),
        column: border.get('column'),
        inLayout: border.get('inLayout'),
        assignedStyles: {
            stroke: border.getIn(['assignedStyles', 'stroke'])
        }
    });

    return borderCopy;
};

export {
    UPDATES_SEQUENCE,
    UPDATE_TYPES,
    updateBorder,
    updateBorders,
    createBorder,
    copy
};
