import { combineReducers } from 'redux';

import * as types from '#Constants/ActionTypes';

import importImageForm from './importImageForm';

const toolsMenu = (state = 'Tools', action) => {
    switch (action.type) {
        case types.CHANGE_TOOLS_MENU_VIEW:
            return action.section;
        default:
            return state;
    }
};

const insertMenu = (state = false, action) => {
    switch (action.type) {
        case types.CHANGE_INSERT_MENU_VISIBILITY:
            return action.visibility;
        case types.CREATE_SHAPES:
            return false;
        default:
            return state;
    }
};

const pagesPreview = (state = false, action) => {
    switch (action.type) {
        case types.CHANGE_PAGES_PREVIEW_VISIBILITY:
            return action.visibility;
        default:
            return state;
    }
};

const splitMenu = (state = {
    error: undefined
}, action) => {
    switch (action.type) {
        case types.SPLIT_SELECTED_CELLS_ERROR:
            return {
                ...state,
                error: action.error.message
            };
        case types.SPLIT_SELECTED_CELLS:
            return {
                ...state,
                error: undefined
            };
        default:
            return state;
    }
};

const navigation = (state = { view: 'pages', visible: true }, action) => {
    switch (action.type) {
        case types.CHANGE_NAVIGATION_MENU_VIEW:
            if (state.view === action.index && state.visible === true) {
                return { ...state, visible: false };
            }
            return { ...state, view: action.index, visible: true };
        default:
            return state;
    }
};

const toolbar = (state = { view: 'tools', visible: true }, action) => {
    switch (action.type) {
        case types.CHANGE_TOOLBAR_MENU_VIEW:
            if (state.view === action.index && state.visible === true) {
                return { ...state, visible: false };
            }
            return { ...state, view: action.index, visible: true };
        default:
            return state;
    }
};

const pagesNavigation = (state = {
    mouseNavigationBufferZone: 0,
    isNavigatingPages: false
}, action) => {
    switch (action.type) {
        case types.SET_MOUSE_NAVIGATION_BUFFER_ZONE:
            return {
                ...state,
                mouseNavigationBufferZone: action.mouseNavigationBufferZone
            };
        case types.CHANGE_ACTIVE_PAGE:
            return {
                ...state,
                isNavigatingPages: action.id !== null
            };
        case types.CANVAS_IS_RENDERED:
            return {
                ...state,
                isNavigatingPages: false
            };
        default:
            return state;
    }
};

const pagesFormat = (state = { width: 0, height: 0 }, action) => {
    switch (action.type) {
        case types.CHANGE_DECK_PROPERTIES:
        case types.FETCH_DECK_SUCCESS:
            return (action.deck && action.deck.pages.length > 0) ?
                {
                    width: action.deck.pages[0].width,
                    height: action.deck.pages[0].height
                } :
                state;
        default:
            return state;
    }
};

const lastClickWasOnPage = (state = false, action) => {
    switch (action.type) {
        case types.CHANGE_LAST_CLICK_WAS_ON_PAGE:
            return action.lastClickWasOnPage;
        default:
            return state;
    }
};

const renderedFontSize = (state = null, action) => {
    switch (action.type) {
        case types.UPDATE_RENDERED_FONT_SIZE:
            return action.renderedFontSize;
        default:
            return state;
    }
};

const shapesNavigation = (state = {
    delayedActionCreator: undefined,
    isLayoutChangeInProgress: false,
    currentMode: 'Page',
    showModal: false,
    versionBeforeEdit: 0
}, action) => {
    switch (action.type) {
        case types.TOGGLE_EDIT_MODE:
            return {
                ...state,
                currentMode: action.editMode,
                versionBeforeEdit: action.version
            };
        case types.SHOW_LAYOUT_CONFIRMATION_MODAL:
            return {
                ...state,
                delayedActionCreator: action.delayedActionCreator,
                showModal: true
            };
        case types.START_LAYOUT_CHANGE:
            return {
                ...state,
                isLayoutChangeInProgress: true
            };
        case types.CLOSE_LAYOUT_CONFIRMATION_MODAL:
            return {
                ...state,
                isLayoutChangeInProgress: false,
                delayedActionCreator: undefined,
                showModal: false
            };
        default:
            return state;
    }
};

const ui = combineReducers({
    toolsMenu,
    insertMenu,
    pagesPreview,
    navigation,
    toolbar,
    importImageForm,
    pagesFormat,
    splitMenu,
    pagesNavigation,
    lastClickWasOnPage,
    renderedFontSize,
    shapesNavigation
});

export default ui;
