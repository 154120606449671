import * as types from '#Constants/ActionTypes';

export const share = (state = { permissions: {} }, action) => {
    switch (action.type) {
        case types.INIT_VIEW:
            return action.share;
        default:
            return state;
    }
};
