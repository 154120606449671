const forEach = require('lodash/forEach');
const intersection = require('lodash/intersection');
const { DEFAULT_TABLE_SIZE_SETTINGS } = require('../../CanvasState/Helpers/Table/Config/defaultTableAttributes.config');

const assignSingleCellToGrid = (cell, grid) => {
    const startRow = cell.row;
    const endRow = startRow + cell.rowSpan;
    const startColumn = cell.column;
    const endColumn = startColumn + cell.columnSpan;
    for (let i = startRow; i < endRow; i++) {
        grid[i] = grid[i] || [];
        for (let j = startColumn; j < endColumn; j++) {
            grid[i][j] = cell.id;
        }
    }
    return grid;
};

const assignCellsToGrid = (originalGrid, table) => Object.values(table.cells || {})
    .reduce(
        (grid, cell) => assignSingleCellToGrid(cell, grid),
        originalGrid
    );

const generateCellGrid = table => {
    const cellGrid = [];
    for (let row = 0; row < table.rowCount; row++) {
        cellGrid[row] = new Array(table.columnCount);
    }
    return assignCellsToGrid(cellGrid, table);
};

const generateDefaultRowHeights = (nbRows = 1, table) => {
    const maxTableHeight = table.settings.maxTableHeight ||
        DEFAULT_TABLE_SIZE_SETTINGS.maxTableHeight;
    if ((maxTableHeight / nbRows) < DEFAULT_TABLE_SIZE_SETTINGS.minCellHeight) {
        return new Array(nbRows).fill(DEFAULT_TABLE_SIZE_SETTINGS.minCellHeight);
    }
    return new Array(nbRows).fill(DEFAULT_TABLE_SIZE_SETTINGS.minCellHeight);
};

const generateDefaultColumnWidths = (nbColumns = 1, table) => {
    const maxTableWidth = table.settings.maxTableWidth || DEFAULT_TABLE_SIZE_SETTINGS.maxTableWidth;
    let cellWidth = table.settings.width ?
        table.settings.width / nbColumns : DEFAULT_TABLE_SIZE_SETTINGS.defaultCellWidth;
    cellWidth = Math.min(cellWidth, DEFAULT_TABLE_SIZE_SETTINGS.defaultCellMaxWidth);

    if (maxTableWidth / nbColumns < DEFAULT_TABLE_SIZE_SETTINGS.minCellWidth) {
        return new Array(nbColumns).fill(DEFAULT_TABLE_SIZE_SETTINGS.minCellWidth);
    }
    if (nbColumns * cellWidth > maxTableWidth) {
        return new Array(nbColumns).fill(Math.round(maxTableWidth / nbColumns));
    }
    return new Array(nbColumns).fill(cellWidth);
};

const assignVerticalBordersToGrid = (originalGrid, table) => Object.values(table.borderSegments)
    .filter(border => (border.isHorizontal ? !border.isHorizontal() : border.side !== 'horizontal'))
    .reduce(
        (grid, border) => {
            if (grid.length > border.column && grid[border.column].length > border.row) {
                grid[border.column][border.row] = border.id;
            }
            return grid;
        },
        originalGrid
    );

const assignHorizontalBordersToGrid = (originalGrid, table) => Object.values(table.borderSegments)
    .filter(border => (border.isHorizontal ? border.isHorizontal() : border.side === 'horizontal'))
    .reduce(
        (grid, border) => {
            if (grid.length > border.row && grid[border.row].length > border.column) {
                grid[border.row][border.column] = border.id;
            }
            return grid;
        },
        originalGrid
    );

const generateVerticalBorderGrid = table => {
    const grid = [...Array(table.columnCount + 1)].map(() => Array(table.rowCount));
    return assignVerticalBordersToGrid(grid, table);
};

const generateHorizontalBorderGrid = table => {
    const grid = [...Array(table.rowCount + 1)].map(() => Array(table.columnCount));
    return assignHorizontalBordersToGrid(grid, table);
};

const getColumnStartX = (column, table) => table.columnWidths
    .slice(0, column)
    .reduce((sum, width) => sum + width, 0) -
    (table.width / 2);

const getColumnEndX = (column, table) => table.columnWidths
    .slice(0, column + 1)
    .reduce((sum, width) => sum + width, 0) -
    (table.width / 2);

const combineDefinedAndRenderRowHeights = table => table.rowHeights.map(
    (renderRowHeight, i) => Math.max(renderRowHeight, table.definedRowHeights[i])
);

const getRowStartY = (row, table) => combineDefinedAndRenderRowHeights(table)
    .slice(0, row)
    .reduce((sum, height) => sum + height, 0) - (table.height / 2);

const getRowEndY = (row, table) => combineDefinedAndRenderRowHeights(table)
    .slice(0, row + 1)
    .reduce((sum, height) => sum + height, 0) - (table.height / 2);

const getRowForY = (y, table) => {
    let row = 0;
    while (row < table.rowHeights.length - 1) {
        if (getRowStartY(row, table) < y &&
            y <= getRowEndY(row, table)) {
            return row;
        }
        row++;
    }
    return row;
};

const getColumnForX = (x, table) => {
    let column = 0;
    while (column < table.columnWidths.length - 1) {
        if (getColumnStartX(column, table) < x &&
            x <= getColumnEndX(column, table)) {
            return column;
        }
        column++;
    }
    return column;
};

const getCellsBetweenColumns = (firstIndex, lastIndex, table) => {
    const cells = [];
    for (let i = firstIndex; i <= lastIndex; i++) {
        cells.push(getCellGridColumn(i, table));
    }
    return cells.reduce((final, cell) => final.concat(cell));
};

const getCellsBetweenRows = (firstIndex, lastIndex, table) => {
    const cells = [];
    for (let i = firstIndex; i <= lastIndex; i++) {
        cells.push(getCellGridRow(i, table));
    }
    return cells.reduce((final, cell) => final.concat(cell));
};

const getIntersectCells = (arr1, arr2) => intersection(arr1, arr2);

const getGridColumns = table => {
    const columns = [];
    for (let i = 0; i < table.columnCount; i++) {
        columns.push(getCellGridColumn(i, table));
    }
    return columns;
};

const getCellGridColumn = (columnIndex, table) => {
    const selectedColumn = [];
    forEach(table.cellGrid, row => selectedColumn.push(row[columnIndex]));
    return selectedColumn;
};

const getCellGridRow = (rowIndex, table) => table.cellGrid[rowIndex];

const getLeftBorderSegmentsForCell = (cellId, table) => {
    if (!table) return [];
    const cell = table.cells[cellId];
    return table.verticalBorders[cell.column]
        .slice(cell.row, cell.row + cell.rowSpan)
        .filter(Boolean);
};

const getTopBorderSegmentsForCell = (cellId, table) => {
    if (!table) return [];
    const cell = table.cells[cellId];
    return table.horizontalBorders[cell.row]
        .slice(cell.column, cell.column + cell.columnSpan)
        .filter(Boolean);
};

const getRightBorderSegmentsForCell = (cellId, table) => {
    if (!table) return [];
    const cell = table.cells[cellId];
    return table.verticalBorders[cell.column + cell.columnSpan]
        .slice(cell.row, cell.row + cell.rowSpan)
        .filter(Boolean);
};

const getBottomBorderSegmentsForCell = (cellId, table) => {
    if (!table) return [];
    const cell = table.cells[cellId];
    return table.horizontalBorders[cell.row + cell.rowSpan]
        .slice(cell.column, cell.column + cell.columnSpan)
        .filter(Boolean);
};

const isBorderOnTableEdge = (borderId, table) => {
    if (!table) return false;
    const border = table.borderSegments[borderId];
    if (border.isHorizontal()) {
        return border.row === 0 ||
            border.row === (table.rowCount || table.rows);
        // this is to support both fabric and decksign, need to fix
    } if (border.isVertical) {
        return border.column === 0 ||
            border.column === (table.columnCount || table.columns);
    }
    return false;
};

const isBorderInsideCell = (borderId, table) => {
    if (!table) return false;
    const border = table.borderSegments[borderId];
    if (border.isHorizontal()) {
        return !isBorderOnTableEdge(borderId, table) &&
            table.cellGrid[border.row - 1][border.column] ===
            table.cellGrid[border.row][border.column];
    }
    return !isBorderOnTableEdge(borderId, table) &&
        table.cellGrid[border.row][border.column - 1] ===
        table.cellGrid[border.row][border.column];
};

module.exports = {
    generateCellGrid,
    assignCellsToGrid,
    assignSingleCellToGrid,
    combineDefinedAndRenderRowHeights,
    generateDefaultRowHeights,
    generateDefaultColumnWidths,
    generateHorizontalBorderGrid,
    generateVerticalBorderGrid,
    getColumnStartX,
    getColumnEndX,
    getColumnForX,
    getRowStartY,
    getRowEndY,
    getRowForY,
    getGridColumns,
    getCellGridColumn,
    getCellGridRow,
    getCellsBetweenColumns,
    getCellsBetweenRows,
    getIntersectCells,
    getLeftBorderSegmentsForCell,
    getRightBorderSegmentsForCell,
    getTopBorderSegmentsForCell,
    getBottomBorderSegmentsForCell,
    isBorderOnTableEdge,
    isBorderInsideCell
};
