const { PathBuilder } = require('../../PathBuilder');

const BASE_SIZE_MULTIPLICATOR = 3;

const TIP_SIZE_MULTIPLICATORS = {
    small: 0.5 * BASE_SIZE_MULTIPLICATOR,
    medium: 1 * BASE_SIZE_MULTIPLICATOR,
    large: 2 * BASE_SIZE_MULTIPLICATOR
};

const getPathDimensions = (lineWidth, widthModifier, lengthModifier) => ({
    width: (lineWidth + 1) * TIP_SIZE_MULTIPLICATORS[widthModifier],
    length: (lineWidth + 1) * TIP_SIZE_MULTIPLICATORS[lengthModifier]
});

class Tip {
    constructor(lineWidth, widthModifier, lengthModifier) {
        this.lineWidth = lineWidth;
        this.widthModifier = widthModifier;
        this.lengthModifier = lengthModifier;
        this.recenterPath = false;
    }

    toObject({ x, y } = { x: 0, y: 0 }, angle = 0, isTail = false) {
        const path = isTail ?
            this.path.rotate(180 + angle) :
            this.path.rotate(angle);
        return {
            path: path.translate({ x, y }),
            pathLength: this.pathLength,
            length: this.length,
            recenterPath: this.recenterPath
        };
    }

    get pathDimensions() {
        return getPathDimensions(
            this.lineWidth,
            this.widthModifier,
            this.lengthModifier
        );
    }

    get pathWidth() {
        return this.pathDimensions.width;
    }

    get pathLength() {
        return this.pathDimensions.length;
    }

    // eslint-disable-next-line class-methods-use-this
    get path() {
        return new PathBuilder();
    }

    // eslint-disable-next-line class-methods-use-this
    get length() {
        return 0;
    }
}

module.exports = Tip;
