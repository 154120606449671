import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash';

const GlobalEventHandlerContext = React.createContext({ registerHandler: () => {} });
const decksignStopFlag = Symbol('decksignStopFlag');

export class GlobalEventHandlerProvider extends Component {
    static propTypes = {
        children: PropTypes.object.isRequired
    }

    constructor(props) {
        super(props);
        this.registerHandler = this.registerHandler.bind(this);
        this.unregisterHandler = this.unregisterHandler.bind(this);
        this.documentHandlers = [];
        this.windowHandlers = [];
        this.initEvents = this.initEvents.bind(this);
        // Add possible document event in this list
        this.documentEventList = ['keyup', 'keydown', 'wheel', 'mousemove', 'visibilitychange'];
        this.initEvents(document, this.documentEventList);
        // Add possible window event in this list
        this.windowEventList = ['resize', 'click', 'beforeunload', 'blur'];
        this.initEvents(window, this.windowEventList);
    }

    initEvents(listener, eventList) {
        eventList.forEach(eventType => {
            listener.addEventListener(eventType, e => {
                e[decksignStopFlag] = false;
                e.stopPropagation = () => {
                    if (!e[decksignStopFlag]) {
                        e[decksignStopFlag] = true;
                        e.stopPropagation();
                    }
                };
                this.documentHandlers.forEach(handler => {
                    if (handler.type === eventType) {
                        if (e[decksignStopFlag]) {
                            return;
                        }
                        handler.fct(e);
                    }
                });
                this.windowHandlers.forEach(handler => {
                    if (handler.type === eventType) {
                        if (e[decksignStopFlag]) {
                            return;
                        }
                        handler.fct(e);
                    }
                });
            });
        });
    }

    registerHandler(type, handler) {
        const handlerToRegister = {
            id: uniqueId(),
            fct: handler,
            type
        };
        if (![...this.documentEventList, ...this.windowEventList].includes(type)) throw new Error(`${type} has not been set to be handle by document or window.`);
        if (this.documentEventList.includes(type)) {
            this.documentHandlers = [handlerToRegister, ...this.documentHandlers];
        }
        if (this.windowEventList.includes(type)) {
            this.windowHandlers = [handlerToRegister, ...this.windowHandlers];
        }
        return () => this.unregisterHandler(handlerToRegister.id);
    }

    unregisterHandler(handlerId) {
        this.documentHandlers = this.documentHandlers.filter(handler => handler.id !== handlerId);
        this.windowHandlers = this.windowHandlers.filter(handler => handler.id !== handlerId);
    }

    render() {
        const { children } = this.props;
        return (
            <GlobalEventHandlerContext.Provider
                value={{ registerHandler: this.registerHandler }}
            >
                { children }
            </GlobalEventHandlerContext.Provider>
        );
    }
}

export const GlobalEventHandlerConsumer = GlobalEventHandlerContext.Consumer;
