import defaultCommands from './Default';
import groupCommands from './Group';
import rectangleCommands from './Rectangle';
import textboxCommands from './Textbox';
import triangleCommands from './Triangle';
import ellipseCommands from './Ellipse';
import trapezoidCommands from './Trapezoid';
import regularPolygonCommands from './RegularPolygon';
import tableCommands from './Table/index';
import lineCommands from './Line';
import chevronCommands from './Chevron';
import arrowCommands from './Arrow';
import calloutCommands from './Callout';
import imageCommands from './Image';

const commands = {
    default: defaultCommands,
    group: groupCommands,
    rectangle: rectangleCommands,
    textbox: textboxCommands,
    triangle: triangleCommands,
    regularpolygon: regularPolygonCommands,
    ellipse: ellipseCommands,
    trapezoid: trapezoidCommands,
    table: tableCommands,
    line: lineCommands,
    chevron: chevronCommands,
    arrow: arrowCommands,
    callout: calloutCommands,
    image: imageCommands
};

// eslint-disable-next-line import/prefer-default-export
export { commands };
