const { fabric } = require('fabric');

const Textshape = require('./DecksignFabricShapeType/TextShape');
const CustomImage = require('./DecksignFabricShapeType/CustomImage');
const CustomLine = require('./DecksignFabricShapeType/CustomLine');
const Table = require('./DecksignFabricShapeType/Table');
const Border = require('./DecksignFabricShapeType/Border');
const { Cell, CellBackground } = require('./DecksignFabricShapeType/Cell');
const PositionMarker = require('./DecksignFabricShapeType/Markers/PositionMarker');
const ResizeCursorZone = require('./DecksignFabricShapeType/CursorZones/ResizeCursorZone');
const SelectionCursorZone = require('./DecksignFabricShapeType/CursorZones/SelectionCursorZone');
const MoveCursorZone = require('./DecksignFabricShapeType/CursorZones/MoveCursorZone');
const DecksignFabricCanvas = require('./DecksignFabricCanvas');
const RegularPolygonModificationHandler = require('./DecksignFabricShapeType/ModificationHandlers/RegularPolygonModificationHandler');
const GenericModificationHandler = require('./DecksignFabricShapeType/ModificationHandlers/GenericModificationHandler');
const RegularPolygon = require('./DecksignFabricShapeType/RegularPolygon');
const Triangle = require('./DecksignFabricShapeType/Triangle');
const Rectangle = require('./DecksignFabricShapeType/Rectangle');
const Trapezoid = require('./DecksignFabricShapeType/Trapezoid');
const Chevron = require('./DecksignFabricShapeType/Chevron');
const Arrow = require('./DecksignFabricShapeType/Arrow');
const Callout = require('./DecksignFabricShapeType/Callout');
const CustomPath = require('./DecksignFabricShapeType/CustomPath');
const Chart = require('./DecksignFabricShapeType/Chart');
const Ellipse = require('./DecksignFabricShapeType/Ellipse');
const MultiItemTextbox = require('./DecksignFabricShapeType/MultiItemTextbox');
require('./overwrites/UtilOverwrite')(fabric);
require('./overwrites/GroupOverwrite')(fabric);
require('./overwrites/ActiveSelectionOverwrite')(fabric);
require('./overwrites/ObjectOverwrite')(fabric);
require('./overwrites/TextOverwrite')(fabric);
require('./overwrites/CanvasOverwrite')(fabric);

const useNewImplementation = true;

Object.assign(fabric, {
    Border,
    Cell,
    CellBackground,
    CustomImage,
    CustomLine,
    DecksignFabricCanvas: DecksignFabricCanvas(useNewImplementation),
    Table,
    Textshape,
    PositionMarker,
    ResizeCursorZone,
    SelectionCursorZone,
    MoveCursorZone,
    RegularPolygonModificationHandler,
    GenericModificationHandler,
    ModificationHandler: GenericModificationHandler,
    MultiItemTextbox,
    RegularPolygon,
    Triangle,
    Rectangle,
    Trapezoid,
    Chevron,
    Arrow,
    Callout,
    CustomPath,
    Chart,
    Ellipse
});

module.exports = fabric;
