const { fabric } = require('fabric');
const { get } = require('lodash');

const Bullet = require('../TextItems/Bullet');
const intersectObjects = require('../../../utilities/intersectObjects');
const { TAB_TO_SPACES } = require('../../constants/text');
const { getBulletTextForFabricParagraph } = require('../../../utilities/bulletStrategies');
const { applyStyle } = require('../../../Shape/ItemStyle/ItemStyle');
const { getEffectiveStyleById } = require('../../../Shape/ItemStyle/ItemStyle');
const { styleToRunStyle } = require('../../../utilities/shape');
const { adaptParagraphStyleToFabricTextStyle } = require('../../Adapters/TextBody/ParagraphStyleAdapter');
const { adaptRunStyleToFabricCharacterStyle } = require('../../Adapters/TextBody/RunStyleAdapter');
const { adaptFabricColorToColorValueDescriptor } = require('../../Adapters/ColorValueDescriptorAdapter');

module.exports = {
    _getCursorBoundariesOffsets(position) {
        if (this.cursorOffsetCache && 'top' in this.cursorOffsetCache) {
            return this.cursorOffsetCache;
        }
        let leftOffset = 0;
        const { charIndex, lineIndex } = this.get2DCursorLocation(position);
        let topOffset = lineIndex !== 0 ? this._getLineTopOffset(lineIndex) : 0;
        for (let i = 0; i < lineIndex; i++) {
            const heightOfLine = this.getHeightOfLine(i);
            const lineTopOffset = i !== 0 ? this._getLineTopOffset(i) : 0;
            const bottomOffset = this._getLineBottomOffset(i);
            topOffset += heightOfLine + bottomOffset + lineTopOffset;
        }
        const lineLeftOffset = this._getLineLeftOffset(lineIndex);
        const bound = this.__charBounds[lineIndex][charIndex];
        if (bound) {
            (leftOffset = bound.left);
        }
        if (this.charSpacing !== 0 && charIndex === this._textLines[lineIndex].length) {
            leftOffset -= this._getWidthOfCharSpacing();
        }
        this.cursorOffsetCache = {
            top: topOffset,
            left: lineLeftOffset + (leftOffset > 0 ? leftOffset : 0)
        };
        return this.cursorOffsetCache;
    },

    renderCursorOrSelection() {
        if (this.group._transformDone === true) {
            return;
        }
        this.callSuper('renderCursorOrSelection');
    },

    renderCursor(boundaries, ctx) {
        const {
            charIndex: cursorCharIndex,
            lineIndex
        } = this.get2DCursorLocation();

        let charIndex;
        let applyCursorStyle;
        const cursorStyle = get(this._getStyleDeclaration(lineIndex, cursorCharIndex), 'cursorStyle');

        if (!cursorStyle) {
            charIndex = cursorCharIndex ? cursorCharIndex - 1 : 0;
            applyCursorStyle = false;
        } else {
            charIndex = cursorCharIndex;
            applyCursorStyle = true;
        }

        const charHeight = this.getValueOfPropertyAt(lineIndex, charIndex, 'fontSize', applyCursorStyle);
        const multiplier = this.scaleX * this.canvas.getZoom();
        const cursorWidth = this.cursorWidth / multiplier;
        let {
            topOffset
        } = boundaries;
        const dy = this.getValueOfPropertyAt(lineIndex, charIndex, 'deltaY', applyCursorStyle);

        topOffset += this.getHeightOfLine(lineIndex) - ((this._fontSizeMult - 0.25) * charHeight);

        if (this.inCompositionMode) {
            this.renderSelection(boundaries, ctx);
        }

        ctx.fillStyle = this.getValueOfPropertyAt(lineIndex, charIndex, 'fill', applyCursorStyle);
        ctx.globalAlpha = this.__isMousedown ? 1 : this._currentCursorOpacity;

        ctx.fillRect(
            boundaries.left + boundaries.leftOffset - cursorWidth / 2,
            topOffset + boundaries.top + dy,
            cursorWidth,
            charHeight
        );
    },

    renderSelection(boundaries, ctx) {
        let {
            topOffset: topOffsetBoundary
        } = boundaries;
        const selectionStart = this.inCompositionMode ?
            this.hiddenTextarea.selectionStart :
            this.selectionStart;
        const selectionEnd = this.inCompositionMode ?
            this.hiddenTextarea.selectionEnd :
            this.selectionEnd;
        const start = this.get2DCursorLocation(selectionStart);
        const end = this.get2DCursorLocation(selectionEnd);
        const startLine = start.lineIndex;
        const endLine = end.lineIndex;
        const startChar = start.charIndex < 0 ? 0 : start.charIndex;
        const endChar = end.charIndex < 0 ? 0 : end.charIndex;

        for (let i = startLine; i <= endLine; i++) {
            const lineOffset = this._getLineLeftOffset(i) ?? 0;
            const heightOfLine = this.getHeightOfLine(i);
            const fontSize = this.getMaxFontSizeForLine(i);
            const {
                textAlign = ''
            } = this.getParagraphForLine(i);
            const isJustify = textAlign.indexOf('justify') !== -1;
            let boxStart = 0;
            let boxEnd = 0;
            if (i !== startLine) {
                topOffsetBoundary += this._getLineTopOffset(i);
            }

            if (i === startLine) {
                boxStart = this.__charBounds[startLine][startChar].left;
            }
            if (i >= startLine && i < endLine) {
                boxEnd = isJustify && !this.isEndOfWrapping(i) ?
                    this.width :
                    this.getLineWidth(i) ?? 5;
            } else if (i === endLine) {
                if (endChar === 0) {
                    boxEnd = this.__charBounds[endLine][endChar].left;
                } else {
                    const charSpacing = this._getWidthOfCharSpacing();
                    boxEnd = this.__charBounds[endLine][endChar - 1].left +
                        this.__charBounds[endLine][endChar - 1].width - charSpacing;
                }
            }
            if (this.inCompositionMode) {
                ctx.fillStyle = this.compositionColor || 'black';
                ctx.fillRect(
                    boundaries.left + lineOffset + boxStart,
                    boundaries.top + topOffsetBoundary + heightOfLine - this.getLineBaselineOffset(i),
                    boxEnd - boxStart,
                    1
                );
            } else {
                ctx.fillStyle = this.selectionColor;
                ctx.fillRect(
                    boundaries.left + lineOffset + boxStart,
                    boundaries.top + topOffsetBoundary + heightOfLine - this.getLineBaselineOffset(i),
                    boxEnd - boxStart,
                    this._fontSizeMult * fontSize
                );
            }

            topOffsetBoundary += this._getLineBottomOffset(i);
            topOffsetBoundary += heightOfLine;
        }
    },

    getSelectionIndexes() {
        return {
            start: this.getSelectionPointers(this.selectionStart),
            end: this.getSelectionPointers(this.selectionEnd)
        };
    },

    getSelectionPointers(charIndex) {
        let lineIndex = 0;
        for (let currentLength = 0; this.textLines.length > lineIndex; lineIndex++) {
            currentLength += this.textLines[lineIndex].length + 1;
            if (currentLength > charIndex) {
                break;
            }
        }
        let unwrappedLineIndex = 0;
        for (let currentLength = 0; this._unwrappedTextLines.length > unwrappedLineIndex; unwrappedLineIndex++) {
            currentLength += this._unwrappedTextLines[unwrappedLineIndex].length + 1;
            if (currentLength > charIndex) {
                break;
            }
        }
        let paragraphIndex;
        for (paragraphIndex = 0; paragraphIndex < this.paragraphs.length - 1; paragraphIndex++) {
            if (this.paragraphs[paragraphIndex + 1].firstUnwrappedLineIndex > unwrappedLineIndex) {
                break;
            }
        }
        return {
            charIndex,
            lineIndex,
            unwrappedLineIndex,
            paragraphIndex
        };
    },

    copy(e) {
        const { start, end } = this.getSelectionIndexes();
        const firstIndexUnwrapped =
            Math.max(start.unwrappedLineIndex, this.paragraphs[start.paragraphIndex].firstUnwrappedLineIndex);
        const lastIndexUnwrapped =
            Math.max(end.unwrappedLineIndex, this.paragraphs[end.paragraphIndex].firstUnwrappedLineIndex);

        const paragraphs = this.paragraphs.slice(start.paragraphIndex, end.paragraphIndex + 1);
        fabric.copiedParagraphs = paragraphs.map((paragraph, i) => {
            const paragraphUnwrappedLinesStart = i === 0 ? firstIndexUnwrapped : paragraph.firstUnwrappedLineIndex;
            const paragraphUnwrappedLinesEnd = paragraphs.length === i + 1 ?
                lastIndexUnwrapped : paragraphs[i + 1].firstUnwrappedLineIndex - 1;
            return {
                paragraphUnwrappedLinesStart: paragraphUnwrappedLinesStart - firstIndexUnwrapped,
                paragraphUnwrappedLinesEnd: paragraphUnwrappedLinesEnd - firstIndexUnwrapped,
                newLine: paragraphUnwrappedLinesEnd - paragraphUnwrappedLinesStart
            };
        });
        this.callSuper('copy', e);
    },

    onInput(e) {
        const { start, end } = this.getSelectionIndexes();
        const firstIndexUnwrapped =
            Math.max(start.unwrappedLineIndex, this.paragraphs[start.paragraphIndex].firstUnwrappedLineIndex);
        const lastIndexUnwrapped =
            Math.max(end.unwrappedLineIndex, this.paragraphs[end.paragraphIndex].firstUnwrappedLineIndex);

        const removedUnwrappedLines = Math.abs(
            lastIndexUnwrapped - firstIndexUnwrapped
        );
        if (removedUnwrappedLines > 0) {
            const firstParagraphIndex = Math.min(start.paragraphIndex, end.paragraphIndex);
            const lastParagraphIndex = Math.max(start.paragraphIndex, end.paragraphIndex);
            this.paragraphs = [
                ...this.paragraphs.slice(0, firstParagraphIndex + 1),
                ...(this.paragraphs
                    .slice(lastParagraphIndex + 1)
                    .map(paragraph => {
                        paragraph.firstUnwrappedLineIndex -= removedUnwrappedLines;
                        return paragraph;
                    }))
            ];
        }
        if (e.inputType === 'insertFromPaste' && this.fromPaste) {
            this.onInsertFromPaste();
        }
        if (e.inputType === 'insertLineBreak' || this.isUnrecognizedInsertLineBreakEvent(e)) {
            this.onInsertLineBreak();
        }
        if (e.inputType === 'deleteContentBackward') {
            this.onDeleteContentBackward();
        }
        let currentParent = this.group;
        while (currentParent !== undefined) {
            currentParent.set('dirty', true);
            currentParent = currentParent.group;
        }
        this.callSuper('onInput', e);

        this.regenerateBulletText(start.paragraphIndex);
    },

    isUnrecognizedInsertLineBreakEvent(e) {
        const textarea = e.target;
        return (
            e.inputType === 'insertText' &&
            e.data === null &&
            this._reNewline.test(textarea.value[textarea.selectionStart - 1])
        );
    },

    onInsertFromPaste() {
        const nextText = this._splitTextIntoLines(this.hiddenTextarea.value).graphemeText;
        const charCount = this._text.length;
        const nextCharCount = nextText.length;
        let charDiff = nextCharCount - charCount;
        const { selectionStart, selectionEnd } = this;
        const selection = selectionStart !== selectionEnd;
        let removedText = [];
        if (selection) {
            removedText = this._text.slice(selectionStart, selectionEnd);
            charDiff += selectionEnd - selectionStart;
        }
        const textareaSelection = this.fromStringToGraphemeSelection(
            this.hiddenTextarea.selectionStart,
            this.hiddenTextarea.selectionEnd,
            this.hiddenTextarea.value
        );
        const insertedText =
            nextText.slice(textareaSelection.selectionEnd - charDiff, textareaSelection.selectionEnd);
        const { start, end } = this.getSelectionIndexes();
        const differenceParagraph = end.paragraphIndex - start.paragraphIndex;
        const firstParagraphIndex = Math.min(start.paragraphIndex, end.paragraphIndex);
        const paragraphLineOffset = 1;
        const originParagraph = this.paragraphs[firstParagraphIndex];
        const newParagraphs = [];
        let paragraphsInserted = 0;
        const unwrappedLineInserted = insertedText.join('').split(/[\r\n]+/).length;
        const selectedLineRemoved = removedText.join('').split(/[\r\n]+/).length - 1;
        const isCopiedFromDecksign = fabric.copiedParagraphs && fabric.copiedText === insertedText.join('');
        if (isCopiedFromDecksign) {
            paragraphsInserted = fabric.copiedParagraphs.length;
            for (let index = 0; index < paragraphsInserted; index++) {
                const originParagraphsStyles = JSON.parse(JSON.stringify(originParagraph.styles));
                const newParagraph = {
                    ...originParagraph,
                    ...intersectObjects(...originParagraphsStyles
                        .map(lineStyle => Object.values(lineStyle || {})).flat())
                };

                const lineIndexOffset = fabric.copiedParagraphs[index].newLine ? 0 :
                    fabric.copiedParagraphs[index].paragraphUnwrappedLinesStart;

                newParagraphs.push({
                    ...newParagraph,
                    firstUnwrappedLineIndex: newParagraph.firstUnwrappedLineIndex + lineIndexOffset,
                    firstLineIndex: newParagraph.firstLineIndex + lineIndexOffset
                });
            }
        } else {
            for (let index = 0; index < unwrappedLineInserted; index++) {
                const originParagraphsStyles = JSON.parse(JSON.stringify(originParagraph.styles));
                newParagraphs.push({
                    ...originParagraph,
                    ...intersectObjects(...originParagraphsStyles
                        .map(lineStyle => Object.values(lineStyle || {})).flat())
                });
            }
        }

        if (originParagraph.bullet) {
            newParagraphs.bullet = new Bullet(originParagraph.bullet.text, originParagraph.bullet.toObject());
        }
        this.paragraphs = [
            ...this.paragraphs.slice(0, firstParagraphIndex),
            ...newParagraphs,
            ...(this.paragraphs
                .slice(firstParagraphIndex + differenceParagraph + paragraphLineOffset)
                .map(paragraph => {
                    paragraph.firstUnwrappedLineIndex += unwrappedLineInserted - 1 - selectedLineRemoved;
                    paragraph.firstLineIndex += unwrappedLineInserted - 1 - selectedLineRemoved;
                    return paragraph;
                }))
        ];

        this.regenerateBulletText(firstParagraphIndex + paragraphLineOffset);
    },

    onInsertLineBreak() {
        const { start, end } = this.getSelectionIndexes();
        const firstUnwrappedLine = Math.min(start.unwrappedLineIndex, end.unwrappedLineIndex);
        const firstLine = Math.min(start.lineIndex, end.lineIndex);
        const lastLine = Math.max(start.lineIndex, end.lineIndex);
        const differenceLine = lastLine - firstLine;
        const firstParagraphIndex = Math.min(start.paragraphIndex, end.paragraphIndex);
        const paragraphLineOffset = 1;
        const originParagraph = this.paragraphs[firstParagraphIndex + differenceLine];
        const originParagraphsStyles = JSON.parse(JSON.stringify(originParagraph.styles));
        const newParagraph = this.isShiftKeyPressed ? [] : [{
            ...originParagraph,
            text: '',
            ...intersectObjects(...originParagraphsStyles.map(lineStyle => Object.values(lineStyle || {})).flat()),
            firstUnwrappedLineIndex: firstUnwrappedLine + paragraphLineOffset,
            firstLineIndex: firstLine + paragraphLineOffset
        }];

        if (originParagraph.bullet && newParagraph.length) {
            newParagraph[0].bullet = new Bullet(originParagraph.bullet.text, originParagraph.bullet.toObject());
        }
        this.paragraphs = [
            ...this.paragraphs.slice(0, firstParagraphIndex + paragraphLineOffset),
            ...newParagraph,
            ...(this.paragraphs
                .slice(firstParagraphIndex + differenceLine + paragraphLineOffset)
                .map(paragraph => {
                    paragraph.firstUnwrappedLineIndex += newParagraph.length - differenceLine;
                    if (this.isShiftKeyPressed) {
                        paragraph.firstUnwrappedLineIndex += 1;
                    }
                    paragraph.firstLineIndex += newParagraph.length - differenceLine;
                    return paragraph;
                }))
        ];

        this.regenerateBulletText(firstParagraphIndex + paragraphLineOffset);
    },

    regenerateBulletText(firstParagraphIndex = 0) {
        this.paragraphs.forEach((paragraph, paragraphIndex) => {
            if (paragraphIndex >= firstParagraphIndex && paragraph.bullet) {
                paragraph.bullet.text = getBulletTextForFabricParagraph(
                    this.toJSON().paragraphs,
                    paragraphIndex
                );
                paragraph.bulletText = paragraph.bullet.text;
            }
        });
    },

    hasOrderedList() {
        return this.paragraphs.some(paragraph => paragraph.bulletType && paragraph.bulletType.includes('ordered'));
    },

    isCursorAtStartOfParagraph() {
        const { start, end } = this.getSelectionIndexes();
        const firstParagraphIndex = Math.min(start.paragraphIndex, end.paragraphIndex);
        return this.getCharacterRangeOfParagraph(firstParagraphIndex)[0] ===
            Math.min(start.charIndex, end.charIndex);
    },

    isCursor() {
        const { start, end } = this.getSelectionIndexes();
        return start.charIndex === end.charIndex;
    },

    isParagraphEmpty(index) {
        const paragraph = get(this.paragraphs, index, false);

        return !paragraph || !paragraph.text;
    },

    isListItem() {
        const { start } = this.getSelectionIndexes();
        const paragraph = this.paragraphs[start.paragraphIndex];
        return paragraph && paragraph.bulletType !== 'none' && paragraph.bullet !== undefined;
    },

    convertListItemToParagraph() {
        const { start } = this.getSelectionIndexes();
        const paragraph = this.paragraphs[start.paragraphIndex];

        paragraph.bulletText = '';
        paragraph.bulletType = 'none';
        paragraph.styles = [{}];

        // Sets the indent en padding left so the text starts where the bullet/number started
        paragraph.indent += paragraph.paddingLeft;
        paragraph.paddingLeft = 0;

        delete paragraph.bulletStyle;

        this.onObjectModified();

        if (this.canvas) {
            this.canvas.renderAll();
        }
    },

    onObjectModified() {
        if (this.group && this.group.onObjectModified) {
            this.group.onObjectModified();
        }
    },

    convertTabsToSpaces(text) {
        return text.replace(/\t/g, ' '.repeat(TAB_TO_SPACES));
    },

    onDeleteContentBackward() {
        const { start, end } = this.getSelectionIndexes();
        if (start.paragraphIndex !== end.paragraphIndex) {
            return;
        }

        if (this.isCursorAtStartOfParagraph() && !this.isListItem() && this.paragraphs.length > 1) {
            this.paragraphs = [
                ...this.paragraphs.slice(0, start.paragraphIndex),
                ...(this.paragraphs
                    .slice(start.paragraphIndex + 1)
                    .map(paragraph => {
                        paragraph.firstUnwrappedLineIndex -= 1;
                        paragraph.firstLineIndex -= 1;
                        return paragraph;
                    }))
            ];
        } else if (this.isCursor() && this.isCursorAtStartOfParagraph() && this.isListItem()) {
            /*
                This fixes the HiddenTextarea so it does not take into account the backspace and stay
                synced with what is actually in fabric. If we do not do that the selection will already
                be moved to the previous line and we do not want that.
            */
            this.hiddenTextarea.value = this._text.join('');
            this.hiddenTextarea.selectionStart = this.selectionStart;
            this.hiddenTextarea.selectionEnd = this.selectionEnd;
            this.convertListItemToParagraph();
        } else if (this.hasSpaceOnDeleteText(start, end)) {
            const startCharIndex = start.charIndex !== end.charIndex ? start.charIndex : start.charIndex - 1;
            const textNewLine = this.text.slice(startCharIndex, end.charIndex).split(/\r\n|\r|\n/).length - 1;
            this.paragraphs = [
                ...this.paragraphs.slice(0, start.paragraphIndex + 1),
                ...(this.paragraphs
                    .slice(start.paragraphIndex + 1)
                    .map(paragraph => {
                        paragraph.firstUnwrappedLineIndex -= textNewLine;
                        return paragraph;
                    }))
            ];
        }
    },

    hasSpaceOnDeleteText(start, end) {
        if (start.charIndex !== end.charIndex) {
            return this.text.slice(start.charIndex, end.charIndex).match(/[\r\n]+/);
        }
        return this.text.slice(start.charIndex - 1, end.charIndex).match(/[\r\n]+/);
    },

    onKeyDown(e) {
        const {
            start: {
                paragraphIndex
            }
        } = this.getSelectionIndexes();
        const {
            bullet
        } = this.paragraphs[paragraphIndex];
        if (this.isEditing && e.key === 'Shift') {
            this.isShiftKeyPressed = true;
        }
        if (this.isInTableCell() && e.keyCode === 9 && bullet === undefined) {
            return;
        }
        if (this.isCursorNavigationCausingCellNavigation(e)) {
            return;
        }
        if (e.keyCode === 9 && bullet !== undefined) {
            if (!this.isCursorAtStartOfParagraph()) {
                this.addTabToTextarea();
                e.preventDefault();
            } else {
                e.preventDefault();
                e.stopImmediatePropagation();
            }
            return;
        }
        this.callSuper('onKeyDown', e);
    },

    onKeyUp(e) {
        const {
            start: {
                paragraphIndex
            }
        } = this.getSelectionIndexes();
        const {
            bullet
        } = this.paragraphs[paragraphIndex];
        if (e.keyCode === 9 && bullet !== undefined && this.isCursorAtStartOfParagraph()) {
            this.onListLevelDepthChange(e);
            this.renderCursorOrSelection();
        }
        if (this.isEditing && e.key === 'Shift') {
            this.isShiftKeyPressed = false;
        }
        if (bullet && e.keyCode >= 33 && e.keyCode <= 40) {
            this.set('dirty', true);
            this.group.set('dirty', true);
            this.canvas.renderAll();
        }
        this.callSuper('onKeyUp', e);
    },

    addTabToTextarea() {
        this.hiddenTextarea.setRangeText(
            '\t',
            this.hiddenTextarea.selectionStart,
            this.hiddenTextarea.selectionEnd,
            'end'
        );
        this.updateFromTextArea();
        this.canvas.renderAll();
        this.fire('changed');
    },

    onListLevelDepthChange(e) {
        const augmentLevel = !e.shiftKey;
        const {
            start,
            end
        } = this.getSelectionIndexes();
        const listPresets = this.canvas.styleDefinitions.filter(
            styleDefinition => styleDefinition.kind === 'ListPreset'
        );
        if (this.group.hasListPreset) {
            const update = applyStyle({}, this.group.objectPreset.listPreset);
            for (let i = start.paragraphIndex; i <= end.paragraphIndex; i++) {
                this.listItemLevelDepthChange(augmentLevel, i, update);
            }
        } else if (listPresets.length) {
            this.handleDefaultListPreset(listPresets, augmentLevel);
        } else {
            for (let i = start.paragraphIndex; i <= end.paragraphIndex; i++) {
                this.updateSimpleBulletLevel(augmentLevel, i);
            }
        }
        this.regenerateBulletText(start.paragraphIndex);
        this._clearCache();
        this.initDimensions();
        this.set('dirty', true);
        this.group.set('dirty', true);

        this.onObjectModified();

        if (this.canvas) {
            this.canvas.renderAll();
        }
    },

    handleDefaultListPreset(listPresets, augmentLevel) {
        const {
            start,
            end
        } = this.getSelectionIndexes();
        const paragraphIndex = start.paragraphIndex || 0;
        const styleId =
            this.paragraphs[paragraphIndex].bulletType === 'unordered' ?
                this.canvas.defaultBulletListPreset :
                this.canvas.defaultNumberedListPreset;
        const listPreset = listPresets.find(def => def.id === styleId);
        const listStyle = getEffectiveStyleById(
            this.canvas.styleDefinitions,
            listPreset.id,
            {
                scheme: this.canvas.colorScheme,
                colorPalette: this.canvas.canvasState.get('colorPalette').toJS()
            }
        );
        const update = applyStyle({}, listStyle);
        for (let i = start.paragraphIndex; i <= end.paragraphIndex; i++) {
            this.listItemLevelDepthChange(augmentLevel, i, update);
        }
    },

    listItemLevelDepthChange(augmentLevel, paragraphIndex, update) {
        const paragraph = this.paragraphs[paragraphIndex];
        if ((!augmentLevel && this.paragraphs[paragraphIndex].level === 1)
        ) {
            return;
        }
        paragraph.level += (augmentLevel ? 1 : -1);
        paragraph.level = Math.min(Math.max(1, paragraph.level), 5);
        const [startCharIndex] = this.getCharacterRangeOfParagraph(paragraphIndex);
        const {
            firstLineIndex,
            styles
        } = this.paragraphs[paragraphIndex];

        const currentStyleFontSize = styles?.[0]?.[0]?.fontSize;

        const firstLineFontSize = (typeof currentStyleFontSize !== 'undefined') ? currentStyleFontSize : this.getValueOfPropertyAt(firstLineIndex, startCharIndex, 'fontSize');
        const firstLinecanvasFontSize = firstLineFontSize * 0.75;
        const levelUpdate = update[`level${paragraph.level}`];
        const currentStyleFill = styles?.[0]?.[0]?.fill || levelUpdate.fill.value;
        const indentRatioForLevel = levelUpdate.indent / levelUpdate.fontSize;
        const leftPaddingRatioForLevel = get(levelUpdate, 'spacing-left', 1) / levelUpdate.fontSize;

        levelUpdate.fontSize = Math.max(augmentLevel ? firstLinecanvasFontSize - 2 : firstLinecanvasFontSize + 2, 8);
        levelUpdate.fontSize /= 0.75;
        levelUpdate.bullet.style.size = levelUpdate.fontSize;
        levelUpdate.indent = levelUpdate.fontSize * indentRatioForLevel;
        paragraph.paddingLeft = levelUpdate.fontSize * leftPaddingRatioForLevel;

        const {
            margins,
            paragraph: paragraphStyle,
            bullet,
            ...runStyle
        } = styleToRunStyle(levelUpdate);
        paragraphStyle.bullet = bullet;
        paragraphStyle.indent = levelUpdate.indent;
        paragraphStyle.padding.left = paragraph.paddingLeft;

        runStyle.color = adaptFabricColorToColorValueDescriptor(currentStyleFill);

        const colorPalette = this.canvas.canvasState.get('colorPalette').toJS();
        const {
            level,
            ...fabricParagraphStyle
        } = adaptParagraphStyleToFabricTextStyle(paragraphStyle, colorPalette);
        const fabricRunStyle = adaptRunStyleToFabricCharacterStyle(runStyle, colorPalette);
        const [paragraphCharStart, paragraphCharEnd] = this.getCharacterRangeOfParagraph(paragraphIndex);
        Object.entries(fabricParagraphStyle).forEach(([key, value]) => {
            paragraph[key] = value;
        });
        paragraph.fontSize = levelUpdate.fontSize;
        paragraph.indent = levelUpdate.indent;
        Object.entries(fabricRunStyle).forEach(([key, value]) => {
            paragraph[key] = value;
        });
        this.setSelectionStyles(fabricRunStyle, paragraphCharStart, paragraphCharEnd);
        this._forceClearCache = true;
    },

    updateSimpleBulletLevel(augmentLevel, paragraphIndex) {
        const paragraph = this.paragraphs[paragraphIndex];
        const paragraphBeforeChange = this.paragraphs[paragraphIndex - 1];
        if (augmentLevel && (
            paragraphBeforeChange === undefined ||
            (paragraphBeforeChange?.level ?? 1) < (paragraph?.level ?? 1)
        )) {
            return;
        }
        const [startCharIndex] = this.getCharacterRangeOfParagraph(paragraphIndex);
        const {
            firstLineIndex
        } = this.paragraphs[paragraphIndex];
        const fontSize = this.getValueOfPropertyAt(firstLineIndex, startCharIndex, 'fontSize');
        paragraph.level += (augmentLevel ? 1 : -1);
        paragraph.level = Math.min(Math.max(1, paragraph.level), 5);
        paragraph.paddingLeft = ((paragraph.level * 2) - 1) * fontSize;
        this._forceClearCache = true;
    },

    updateFromTextArea() {
        this.callSuper('updateFromTextArea');
        this.updateParagraphsFromLines();
    },

    updateParagraphsFromLines() {
        this.paragraphs.forEach((paragraph, i) => {
            const paragraphUnwappedLinesStart = paragraph.firstUnwrappedLineIndex;
            const paragraphUnwrappedLinesEnd = this.paragraphs.length === i + 1 ?
                this._unwrappedTextLines.length :
                this.paragraphs[i + 1].firstUnwrappedLineIndex;
            paragraph.text = this._unwrappedTextLines
                .slice(
                    paragraphUnwappedLinesStart,
                    paragraphUnwrappedLinesEnd
                )
                .map(line => line.join(''))
                .join('\n');
            if (this.styles) {
                const styles = this.styles.slice ? this.styles : Object.keys(this.styles).map(key => this.styles[key]);
                paragraph.styles = styles.slice(
                    paragraphUnwappedLinesStart,
                    paragraphUnwrappedLinesEnd
                );
            }
        });
    },

    getCharacterRangeOfLine(i) {
        const startLineCharacterIndex = this.textLines
            .slice(0, i)
            .reduce((currentSum, currentLine) => currentSum + currentLine.length + 1, 0);
        return [
            startLineCharacterIndex,
            startLineCharacterIndex + this.textLines[i].length + 1
        ];
    },

    getCharacterRangeOfUnwrappedLine(i) {
        const startLineCharacterIndex = this._unwrappedTextLines
            .slice(0, i)
            .reduce((currentSum, currentLine) => currentSum + currentLine.length + 1, 0);
        return [
            startLineCharacterIndex,
            startLineCharacterIndex + this._unwrappedTextLines[i].length + 1
        ];
    },

    getCharacterRangeOfParagraph(i) {
        const startUnwrappedTextLineIndex = this.paragraphs[i].firstUnwrappedLineIndex;
        const endUnwrappedTextLineIndex = this.paragraphs.length === i + 1 ?
            this._unwrappedTextLines.length - 1 :
            this.paragraphs[i + 1].firstUnwrappedLineIndex - 1;

        const [startCharacterIndex] =
            this.getCharacterRangeOfUnwrappedLine(startUnwrappedTextLineIndex);
        const endCharacterIndex =
            this.getCharacterRangeOfUnwrappedLine(endUnwrappedTextLineIndex)[1];

        return [
            startCharacterIndex,
            endCharacterIndex
        ];
    },

    getCompleteStyleDeclaration(lineIndex, charIndex) {
        const characterStyle = this._getStyleDeclaration(lineIndex, charIndex) || {};
        const paragraph = this.getParagraphForUnwrappedTextLine(lineIndex);
        return this._styleProperties.reduce((accumulatedStyles, property) => ({
            ...accumulatedStyles,
            [property]: characterStyle[property] ?? paragraph[property] ?? this[property]
        }), {});
    },

    _mouseDownHandlerBefore(options) {
        if (!this.canvas || !this.editable || (options.e.button && options.e.button !== 1)) {
            return;
        }
        // we want to avoid that an object that was selected and then becomes unselectable,
        // may trigger editing mode in some way.
        this.selected = this.group === this.canvas._activeObject;
    },

    mouseUpHandler(options) {
        this.__isMousedown = false;
        if (!this.editable ||
            (options.transform && options.transform.actionPerformed) ||
            (options.e.button && options.e.button !== 1)) {
            return;
        }

        if (this.canvas) {
            const currentActive = this.canvas._activeObject;
            if (currentActive && currentActive !== this.group) {
                // avoid running this logic when there is an active object
                // this because is possible with shift click and fast clicks,
                // to rapidly deselect and reselect this object and trigger an enterEdit
                return;
            }
        }

        if (this.__lastSelected && !this.__corner) {
            this.selected = false;
            this.__lastSelected = false;
            this.enterEditing(options.e);
            if (this.selectionStart === this.selectionEnd) {
                this.initDelayedCursor(true);
            } else {
                this.renderCursorOrSelection();
            }
        } else {
            this.selected = true;
        }
    },

    _getNewSelectionStartFromOffset(mouseOffset, prevWidth, width, index) {
        // we need Math.abs because when width is after the last char, the offset is given as 1, while is 0
        const distanceBtwLastCharAndCursor = mouseOffset.x - prevWidth;
        const distanceBtwNextCharAndCursor = width - mouseOffset.x;
        const offset = distanceBtwNextCharAndCursor > distanceBtwLastCharAndCursor ||
            distanceBtwNextCharAndCursor < 0 ? 0 : 1;
        let newSelectionStart = index + offset;
        if (newSelectionStart > this._text.length) {
            newSelectionStart = this._text.length;
        }

        return newSelectionStart;
    },

    getSelectionStartFromPointer(e) {
        const mouseOffset = this.getMouseOffset(e);
        let prevWidth = 0;
        let width = 0;
        let height = 0;
        let charIndex = 0;
        let lineIndex = 0;
        let lineLeftOffset = 0;
        let line = 0;
        for (let i = 0, len = this._textLines.length; i < len; i++) {
            if (height <= mouseOffset.y) {
                const heightOfLine = this.getHeightOfLine(i);
                const bottomOffset = this._getLineBottomOffset(i);
                const lineTopOffset = this._getLineTopOffset(i);
                height += (heightOfLine + bottomOffset + lineTopOffset) * this.scaleY;
                lineIndex = i;
                if (i > 0) {
                    charIndex += this._textLines[i - 1].length + this.missingNewlineOffset(i - 1);
                }
            } else {
                break;
            }
        }
        lineLeftOffset = this._getLineLeftOffset(lineIndex);
        width = lineLeftOffset * this.scaleX;
        line = this._textLines[lineIndex];
        for (let j = 0; j < line.length; j++) {
            prevWidth = width;
            // i removed something about flipX here, check.
            width += this.__charBounds[lineIndex][j].kernedWidth * this.scaleX;
            if (width <= mouseOffset.x) {
                charIndex++;
            } else {
                break;
            }
        }
        return this._getNewSelectionStartFromOffset(mouseOffset, prevWidth, width, charIndex, line.length);
    },

    getMouseOffset(e) {
        const eventTextshapePointer = this.group.getLocalPointer(e);
        const eventTextShapePoint = new fabric.Point(
            eventTextshapePointer.x,
            eventTextshapePointer.y
        );
        const textboxLeftCornerInTextShape = this.group
            .translateToGivenOrigin({ x: this.left, y: this.top }, 'left', 'top', 'center', 'center');
        return eventTextShapePoint.subtract(textboxLeftCornerInTextShape);
    },

    isCursorNavigationCausingCellNavigation(e) {
        return (
            (
                this.isCursorAtTopLeftTableCellText() &&
                this.getKeyCodesForTopLeftCellNavigation().includes(e.keyCode)
            ) ||
            (
                this.isCursorAtBottomRightTableCellText() &&
                this.getKeyCodesForBottomRightCellNavigation().includes(e.keyCode)
            )
        );
    },

    getKeyCodesForTopLeftCellNavigation() {
        return Object.entries(this.keysMap)
            .filter(entry => ['moveCursorLeft', 'moveCursorUp'].includes(entry[1]))
            .map(([keyCode]) => Number.parseInt(keyCode, 10));
    },

    getKeyCodesForBottomRightCellNavigation() {
        return Object.entries(this.keysMap)
            .filter(entry => ['moveCursorDown', 'moveCursorRight'].includes(entry[1]))
            .map(([keyCode]) => Number.parseInt(keyCode, 10));
    },

    selectAll() {
        const selectAllReturned = this.callSuper('selectAll');
        this.renderCursorOrSelection();
        return selectAllReturned;
    },

    /* eslint-disable consistent-return */
    enterEditing(e) {
        if (this.isEditing || !this.editable) {
            return;
        }

        if (this.canvas) {
            this.canvas.calcOffset();
            this.exitEditingOnOthers(this.canvas);
        }

        this.isEditing = true;

        this.initHiddenTextarea(e);
        this.hiddenTextarea.focus();
        this.hiddenTextarea.value = this.text;
        this._updateTextarea();
        this._saveEditingProps();
        this._setEditingProps();
        this._textBeforeEdit = this.text;

        this._tick();
        this.fire('editing:entered');
        this._fireSelectionChanged();
        if (!this.canvas) {
            return this;
        }
        this.canvas.fire('text:editing:entered', { target: this });
        this.initMouseMoveHandler();
        this.canvas.requestRenderAll();
        return this;
    },

    getLineMaxCharHeight(lineIndex) {
        const line = this._textLines[lineIndex];
        let maxHeight = this.getHeightOfChar(lineIndex, 0);

        for (let i = 1, len = line.length; i < len; i++) {
            maxHeight = Math.max(this.getHeightOfChar(lineIndex, i), maxHeight);
        }

        return maxHeight;
    }
};
