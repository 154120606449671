const StrokeFill = require('./StrokeFill.js');
const StrokeFillConstructor = require('.');
const Gradient = require('./Gradient.js');

class GradientStrokeFill extends StrokeFill {
    constructor(gradient) {
        super('gradient');
        this.gradient = gradient;
    }

    toJSON() {
        return Object.assign(super.toJSON(), {
            gradient: this.gradient.toJSON()
        });
    }

    static fromJSON(gradientStrokeFill, jsonObject) {
        return new this(Gradient.fromJSON(jsonObject.gradient));
    }
}

StrokeFillConstructor.addToConstructorList(GradientStrokeFill, 'gradient');

module.exports = GradientStrokeFill;
