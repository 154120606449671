import * as types from '#Constants/ActionTypes';

const initialState = {
    editing: false,
    start: 0,
    end: 0
};

export default (
    state = initialState,
    action
) => {
    switch (action.type) {
        case types.TEXT_SELECTION_CHANGED:
            return action.textSelection;
        default:
            return state;
    }
};
