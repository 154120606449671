import { fromJS } from 'immutable';

import { UPDATE_TYPES } from '../Table/border';

export default fromJS({
    shapePreset: {
        isReference: true,
        referenceUpdateParent: 'assignedShapeStyle'
    },
    typefacePreset: {
        isReference: true,
        referenceUpdateParent: 'assignedRunStyle'
    },
    paragraphPreset: {
        isReference: true,
        referenceUpdateParent: 'assignedParagraphStyle'
    },
    strokePreset: {
        isReference: true,
        referenceUpdateParent: `borderUpdates.${UPDATE_TYPES.ALL}.assignedStrokeStyle`
    },
    topBorderPreset: {
        isReference: true,
        referenceUpdateParent: `borderUpdates.${UPDATE_TYPES.TOP}.assignedStrokeStyle`
    },
    bottomBorderPreset: {
        isReference: true,
        referenceUpdateParent: `borderUpdates.${UPDATE_TYPES.BOTTOM}.assignedStrokeStyle`
    },
    leftBorderPreset: {
        isReference: true,
        referenceUpdateParent: `borderUpdates.${UPDATE_TYPES.LEFT}.assignedStrokeStyle`
    },
    rightBorderPreset: {
        isReference: true,
        referenceUpdateParent: `borderUpdates.${UPDATE_TYPES.RIGHT}.assignedStrokeStyle`
    },
    internalHorizontalBorderPreset: {
        isReference: true,
        referenceUpdateParent: `borderUpdates.${UPDATE_TYPES.INNER_HORIZONTAL}.assignedStrokeStyle`
    },
    internalVerticalBorderPreset: {
        isReference: true,
        referenceUpdateParent: `borderUpdates.${UPDATE_TYPES.INNER_VERTICAL}.assignedStrokeStyle`
    }
});
