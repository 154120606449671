import React from 'react';
import PropTypes from 'prop-types';
import './Error.scss';

/**
 * LocalErrorBoundary stores caught errors in its local state
 * when you only want to disable a single component
 *
 * It will display a cute error icon in place of the errored component
 * only if the component managed to render at least once before crashing
 * if not, it will delegate the responsibility of handling the error
 * to the GlobalErrorBoundary
 */
class LocalErrorBoundary extends React.Component {
    static getDerivedStateFromError() {
        return { hasError: true };
    }

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            hasError: false
        };
    }

    componentDidCatch(error) {
        this.setState({
            error,
            hasError: true
        });
    }

    render() {
        return (
            this.state.hasError ? (
                <div className="Error">
                    <div className="Error__content">
                        <i className="material-icons">
                            error outline
                        </i>
                        {`Error: ${this.state.error?.message || 'An Error has occured'}`}
                    </div>
                </div>
            ) :
                this.props.children
        );
    }
}

LocalErrorBoundary.propTypes = {
    children: PropTypes.any.isRequired
};

export default LocalErrorBoundary;
