import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { hideError, displayError } from '#Actions/error/error';
import { ERROR_LEVEL } from '#Constants/error';
import Modal from '#Components/Common/Modal/Modal';
import './Error.scss';

/**
 * GlobalErrorBoundary synchronizes the errors it receives with the redux store
 */
class GlobalErrorBoundary extends React.Component {
    constructor(props) {
        super(props);

        this.hideError = this.hideError.bind(this);
    }

    componentDidCatch(error, errorInfo) {
        this.props.onDisplayError(error);
        Raven.captureException(error, { extra: errorInfo });
    }

    getErrorPage() {
        return (
            <div key="error-page">
                <h1>Fatal Error</h1>
                <p>An unexpected error has occured. Please contact us for further support.</p>
                <p>{this.props.currentError.message}</p>
            </div>
        );
    }

    getErrorModal() {
        return (
            <Modal
                key="error-modal"
                title="Error"
                isVisible={this.props.currentError.visible}
                closeModal={this.hideError}
            >
                <p>An unexpected error has occured. Please try again.</p>
                <p>{this.props.currentError.message}</p>
            </Modal>
        );
    }

    hideError() {
        this.props.onHideError();
    }

    render() {
        const children = React.Children.toArray(this.props.children);
        if (this.props.currentError.visible) {
            if (this.props.currentError.level <= ERROR_LEVEL.FATAL) {
                return this.getErrorPage();
            }
            children.push(this.getErrorModal());
        }
        return children;
    }
}

GlobalErrorBoundary.propTypes = {
    onDisplayError: PropTypes.func.isRequired,
    onHideError: PropTypes.func.isRequired,
    currentError: PropTypes.object.isRequired,
    children: PropTypes.any.isRequired
};

const mapStateToProps = state => ({
    currentError: state.error.currentError
});

const mapDispatchToProps = dispatch => ({
    onHideError: () => dispatch(hideError()),
    onDisplayError: err => dispatch(displayError(err))
});

export default connect(mapStateToProps, mapDispatchToProps)(GlobalErrorBoundary);
