const TreeNode = require('../../fabric-adapter/DecksignFabricShapeType/Groups/TreeNode');

const handleDrillDown = (tree, currentSelection, shapeId, isMultiSelection) => {
    const allAncestors = TreeNode.getAllParents(tree, shapeId);
    const currentSelectionIndex = allAncestors.findIndex(node => currentSelection.find(id => id === node.shape.id));
    if (currentSelectionIndex === -1) {
        if (isMultiSelection) {
            return currentSelection;
        }
        const currentSelectionSiblingsIds = TreeNode.getSiblings(tree, currentSelection[0]).map(node => node.shape.id);
        const myAncestors = TreeNode.getAllParents(tree, shapeId).map(node => node.shape.id);
        const newSelection = myAncestors.find(id => id === currentSelectionSiblingsIds.find(selId => selId === id));
        return [newSelection];
    }
    let isOneLevelDeep = false;
    if (allAncestors[currentSelectionIndex].shape.id === TreeNode.getParent(tree, shapeId).shape.id) {
        isOneLevelDeep = true;
    }
    if (isMultiSelection) {
        if (canMultiSelect(tree, currentSelection, shapeId)) {
            return [
                ...currentSelection,
                isOneLevelDeep ? shapeId :
                    allAncestors[currentSelectionIndex + 1].shape.id
            ];
        }
        return currentSelection;
    }
    return [
        isOneLevelDeep ? shapeId :
            allAncestors[currentSelectionIndex + 1].shape.id
    ];
};

const canMultiSelect = (tree, currentSelection, shapeId) => {
    let siblings = currentSelection.map(id => TreeNode.getSiblings(tree, id));
    siblings = [...new Set(siblings.flat().map(node => node.shape.id))];
    if (siblings.find(id => id === shapeId)) {
        return true;
    }
    return false;
};

const isInitialSelection = (tree, currentSelection = []) => {
    const hasCurrentSelectionInTree = currentSelection.find(id => TreeNode.findNode(tree, id) !== null);
    return currentSelection.length === 0 || !hasCurrentSelectionInTree;
};

const isOutside = (tree, shapeId) => !TreeNode.findNode(tree, shapeId);

const isCurrentSelection = (tree, currentSelection = [], shapeId) => TreeNode.findNode(tree, shapeId) &&
    currentSelection.includes(shapeId);

const isDrillingDown = (tree, currentSelection, shapeId) => {
    const allAncestors = TreeNode.getAllParents(tree, shapeId);
    const currentSelectionIndex = allAncestors.findIndex(node => currentSelection.find(id => id === node.shape.id));
    if (currentSelectionIndex === -1) {
        const downInOtherSubtree = allAncestors.length > TreeNode.getAllParents(tree, currentSelection[0]).length;
        return downInOtherSubtree;
    }
    return currentSelectionIndex !== -1;
};

const isSelectingSibling = (tree, currentSelection, shapeId) => {
    const siblings = TreeNode.getSiblings(tree, shapeId);
    if (siblings.findIndex(node => currentSelection.find(id => id === node.shape.id)) !== -1) {
        return true;
    }
    return false;
};

const getDrillUpAvailableIds = (tree, id) => {
    const allParents = TreeNode.getAllParents(tree, id);
    let allParentsSiblings = allParents.map(node => TreeNode.getSiblings(tree, node.shape.id));
    allParentsSiblings = new Set(allParentsSiblings.map(siblings => siblings.map(sib => sib.shape.id)));
    const availableIds = new Set(
        [...allParents.map(node => node.shape.id),
            ...[...allParentsSiblings].flat(Infinity)
        ]
    );
    return [...availableIds];
};

const handleDrillUp = (tree, currentSelection, shapeId, isMultiSelection) => {
    const ids = getDrillUpAvailableIds(tree, currentSelection[0]);
    if (ids.find(availableId => availableId === shapeId)) {
        if (isMultiSelection) {
            if (canMultiSelect(tree, currentSelection, shapeId)) {
                return [...currentSelection, shapeId];
            }
            return currentSelection;
        }
        return [shapeId];
    }

    const parentsOfnewSelection = TreeNode.getAllParents(tree, shapeId);
    const closestAvailableParent = parentsOfnewSelection
        .reverse()
        .find(node => ids.find(validId => validId === node.shape.id));

    if (closestAvailableParent) {
        if (isMultiSelection) {
            if (canMultiSelect(tree, currentSelection, shapeId)) {
                return [...currentSelection, closestAvailableParent.shape.id];
            }
            return currentSelection;
        }
        return [closestAvailableParent.shape.id];
    }
    return currentSelection;
};

const handleSiblingSelection = (tree, currentSelection, shapeId, isMultiSelection) => {
    if (isMultiSelection && canMultiSelect(tree, currentSelection, shapeId)) {
        const allChilds = TreeNode.getParent(tree, shapeId).getChildrenIds();
        const childsMissingInSelection = allChilds.filter(
            id => ![...currentSelection, shapeId].find(nodeId => nodeId === id)
        );
        if (childsMissingInSelection.length === 0) {
            return [TreeNode.getParent(tree, shapeId).shape.id];
        }
        return [...currentSelection, shapeId];
    }
    return [shapeId];
};

const getValidSelection = (tree, currentSelection = [], shapeId = '', isMultiSelection = false) => {
    if (isInitialSelection(tree, currentSelection)) {
        return [tree.shape.id];
    }
    if (isOutside(tree, shapeId)) {
        return [...currentSelection, shapeId];
    }
    if (isCurrentSelection(tree, currentSelection, shapeId)) {
        return currentSelection;
    }
    if (isSelectingSibling(tree, currentSelection, shapeId)) {
        return handleSiblingSelection(tree, currentSelection, shapeId, isMultiSelection);
    }
    if (isDrillingDown(tree, currentSelection, shapeId)) {
        return handleDrillDown(tree, currentSelection, shapeId, isMultiSelection);
    }
    return handleDrillUp(tree, currentSelection, shapeId, isMultiSelection);
};

module.exports = getValidSelection;
