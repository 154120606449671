import { getRenderShapeProperty } from '../../../../../CanvasState/Helpers/Style/shape';
import { getColumnStartX, getRowStartY } from '../../../../../CanvasState/Helpers/Table/tableGridLogic';
import { getHeightForCellAt, getWidthForCellAt } from '../../../../../CanvasState/Helpers/Table/tableHelper';
import textboxShapeToFabricJSON from '../../ShapeAdapters/textbox';

const getWidth = (cell, table) => Math.ceil(getWidthForCellAt(table, cell.get('column'), cell.get('columnSpan')));

const getHeight = (cell, table) => Math.ceil(getHeightForCellAt(table, cell.get('row'), cell.get('rowSpan')));

const getX = (cell, table) => getColumnStartX(cell.get('column'), table) + (getWidth(cell, table) / 2);

const getY = (cell, table) => getRowStartY(cell.get('row'), table) + (getHeight(cell, table) / 2);

const getCells = (table, canvasState) => table
    .get('cells')
    .map(cell => getCell(cell, table, canvasState))
    .toJS();

const getCell = (cell, table, canvasState) => ({
    id: cell.get('id'),
    name: cell.get('name'),
    width: getWidth(cell, table),
    height: getHeight(cell, table),
    left: getX(cell, table),
    top: getY(cell, table),
    originX: 'center',
    originY: 'center',
    row: cell.get('row'),
    column: cell.get('column'),
    rowSpan: cell.get('rowSpan'),
    columnSpan: cell.get('columnSpan'),
    fill: getRenderShapeProperty(cell, ['fill']).toJS(),
    inLayout: false,
    isHidden: false,
    isImported: false,
    isLocked: false,
    opacity: cell.get('opacity'),
    contents: cell.get('contents')
        .map(content => ({
            ...textboxShapeToFabricJSON(
                content
                    .set('type', 'Textbox'),
                canvasState
            )
        })).toJS(),
    ownCaching: true,
    style: cell.get('style') || ''
});

export default getCells;
