import * as types from '#Constants/ActionTypes';

export const displayError = err => {
    console.error(err);
    return {
        type: types.ERROR_ALL,
        error: err
    };
};

export const hideError = () => ({
    type: types.HIDE_ERROR
});
