import { List } from 'immutable';
import { cloneShape, getShapePathWithLayer } from '../../Helpers/helpers';

const copyShapes = (canvasState, command) => {
    const contextualSelection = canvasState.get('contextualSelection');
    let updatedCanvasState = canvasState;
    if (contextualSelection) {
        const table = canvasState.getIn(
            getShapePathWithLayer(canvasState, canvasState.get('selection').get(0))
        );
        const cells = table.getIn(['cells']).filter(cell => contextualSelection.get('cells').includes(cell.get('id')));
        updatedCanvasState = canvasState
            .set(
                'clipboard',
                cells.reduce(
                    (clipboard, cell) => clipboard.push(
                        cell
                            .set('isCut', command.isCut)
                            .set('originalPage', canvasState.getIn(['dynamicValues', 'pageNumber']))
                    ),
                    new List()
                )
            );
        return updatedCanvasState;
    }
    updatedCanvasState = canvasState
        .set(
            'clipboard',
            canvasState
                .get('selection')
                .reduce(
                    (clipboard, id) => clipboard.push(
                        cloneShape(canvasState, id)
                            .set('isCut', command.isCut)
                            .set('originalPage', canvasState.getIn(['dynamicValues', 'pageNumber']))
                    ),
                    new List()
                )
        );
    return updatedCanvasState;
};

export default copyShapes;
