export const ADD_PAGES_FROM_TOPIC = 'ADD_PAGES_FROM_TOPIC';
export const CREATE_DECK = 'CREATE_DECK';
export const CREATE_DECK_SUCCESS = 'CREATE_DECK_SUCCESS';
export const CREATE_DECK_FAIL = 'CREATE_DECK_FAIL';
export const CHANGE_DECK_OBJECTIVE = 'CHANGE_DECK_OBJECTIVE';
export const CHANGE_DECK_STORYBOARD = 'CHANGE_DECK_STORYBOARD';
export const CHANGE_DECK_PROPERTIES = 'CHANGE_DECK_PROPERTIES';
export const CHANGE_PAGES_ORDER = 'CHANGE_PAGES_ORDER';
export const DECK_DUPLICATED = 'DECK_DUPLICATED';
export const DELETE_DECK = 'DELETE_DECK';
export const DUPLICATE_DECK = 'DUPLICATE_DECK';
export const DUPLICATE_DECK_FAILED = 'DUPLICATE_DECK_FAILED';
export const LOAD_DECK = 'LOAD_DECK';
export const FETCH_DECK = 'FETCH_DECK';
export const FETCH_DECK_SUCCESS = 'FETCH_DECK_SUCCESS';
export const FETCH_DECK_FAIL = 'FETCH_DECK_FAIL';
export const REMOVE_PAGE_IN_CURRENT_DECK = 'REMOVE_PAGE_IN_CURRENT_DECK';
export const SET_PAGES_TO_WAIT_FOR_UPDATE = 'SET_PAGES_TO_WAIT_FOR_UPDATE';
export const SET_DECK = 'SET_DECK';
export const SET_THUMBNAIL = 'SET_THUMBNAIL';
export const UPDATE_DECK = 'UPDATE_DECK';
export const UPDATE_PAGE_ID_IN_CURRENT_DECK = 'UPDATE_PAGE_ID_IN_CURRENT_DECK';
export const UPDATE_PAGE_IN_CURRENT_DECK = 'UPDATE_PAGE_IN_CURRENT_DECK';
export const ADD_PAGE = 'ADD_PAGE';
export const DUPLICATE_PAGE = 'DUPLICATE_PAGE';
