import { createSelector } from 'reselect';

export const getConfig = createSelector(
    ({
        app: {
            config = {}
        } = {}
    }) => config,
    config => config
);

export const getSentryConfig = createSelector(
    getConfig,
    config => config.sentry
);

export const getAssetUrlBase = createSelector(
    getConfig,
    ({
        decksAssetsURLBase
    }) => decksAssetsURLBase
);

export const getDecksImagesURLBase = createSelector(
    getConfig,
    ({
        decksImagesURLBase = ''
    }) => decksImagesURLBase
);

export const getDecksThumbnailsURLBase = createSelector(
    getConfig,
    ({
        decksThumbnailsURLBase = ''
    }) => decksThumbnailsURLBase
);
