const Tip = require('./Tip');
const { PathBuilder } = require('../../PathBuilder');

class HalfDiamondTip extends Tip {
    get path() {
        return new PathBuilder()
            .moveTo({ x: 0, y: -0.5 })
            .lineTo({ x: 0.5, y: 0 })
            .lineTo({ x: 0, y: 0.5 })
            .close()
            .scale(this.pathLength, this.pathWidth);
    }
}

module.exports = HalfDiamondTip;
