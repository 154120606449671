// Watch out for the implementation of setDefaultStyle in each shape
const defaultStrokeStyle = {
    width: 0,
    tail: {
        width: 'medium',
        type: 'none',
        length: 'medium'
    },
    join: {
        type: 'miter'
    },
    head: {
        width: 'medium',
        type: 'none',
        length: 'medium'
    },
    fill: {
        color: {
            type: 'custom',
            value: 'rgba(77, 77, 77, 1)'
        },
        type: 'color'
    },
    dash: {
        type: 'solid'
    },
    compoundType: 'single',
    cap: 'flat',
    align: 'center'
};

const defaultShapeStyle = {
    rotation: 0,
    scaleX: 1,
    scaleY: 1,
    skewX: 0,
    skewY: 0,
    lockPath: false,
    flipX: false,
    flipY: false,
    selectable: true,
    visible: true,
    isGroupLocked: false,
    isGroupHidden: false,
    fill: {
        type: 'color',
        color: {
            type: 'custom',
            value: 'rgba(26, 120, 192, 1)'
        }
    },
    stroke: defaultStrokeStyle,
    presetType: null,
    path: null,
    opacity: 1,
    width: 150,
    height: 150,
    justcreated: true
};

module.exports = {
    defaultShapeStyle,
    defaultStrokeStyle
};
