const { sameRunStyleInTextBodies } = require('../utils');

exports.computeLinethroughFromTextBodies = (textBodies, textSelection) => sameRunStyleInTextBodies(
    textBodies,
    'linethrough',
    undefined,
    {
        textSelection
    }
);
