import { List } from 'immutable';
import { cloneShape } from '../../Helpers/helpers';
import removeShapes from './remove';

const cutShapes = (canvasState, command) => {
    let updatedCanvasState = canvasState
        .set(
            'clipboard',
            canvasState
                .get('selection')
                .reduce(
                    (clipboard, id) => clipboard.push(
                        cloneShape(canvasState, id)
                            .set('isCut', command.get('isCut'))
                            .set('originalPage', canvasState.getIn(['dynamicValues', 'pageNumber']))
                    ),
                    new List()
                )
        );
    updatedCanvasState = removeShapes(updatedCanvasState);
    return updatedCanvasState;
};

export default cutShapes;
