import validateKeyExceptions from './validateKeyExceptions';
import defaultsDeep from './defaultsDeep';

export default (map, ...sources) => {
    const deepExceptions = sources.pop();
    validateKeyExceptions(deepExceptions);
    return deepExceptions.reduce(
        (current, path) => {
            const pathArray = path.split('.');
            let exceptionValue = [map, ...sources]
                .find(container => container && container.getIn(pathArray) !== undefined);

            exceptionValue = exceptionValue ? exceptionValue.getIn(pathArray) : undefined;

            return exceptionValue !== undefined ?
                current.setIn(pathArray, exceptionValue) :
                current;
        },
        defaultsDeep(map, ...sources)
    );
};
