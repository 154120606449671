export const CHART_SELECT = 'CHART_SELECT';
export const CLOSE_UPDATE_SHARE_MODAL = 'CLOSE_UPDATE_SHARE_MODAL';
export const CREATE_NEW_SHARE_PENDING = 'CREATE_NEW_SHARE_PENDING';
export const CREATE_NEW_SHARE_DONE = 'CREATE_NEW_SHARE_DONE';
export const CREATE_NEW_SHARE_REJECTED = 'CREATE_NEW_SHARE_REJECTED';
export const DISPLAY_PUBLIC_LINK_MODAL = 'DISPLAY_PUBLIC_LINK_MODAL';
export const FETCH_SHARE_INFORMATION = 'FETCH_SHARE_INFORMATION';
export const FETCH_SHARE_INFORMATION_PENDING = 'FETCH_SHARE_INFORMATION_PENDING';
export const FETCH_SHARE_INFORMATION_REJECTED = 'FETCH_SHARE_INFORMATION_REJECTED';
export const FETCH_SHARES = 'FETCH_SHARES';
export const FETCH_SHARES_PENDING = 'FETCH_SHARES_PENDING';
export const FETCH_SHARES_REJECTED = 'FETCH_SHARES_REJECTED';
export const FETCH_SHARE_VIEWER_INFORMATION = 'FETCH_SHARE_VIEWER_INFORMATION';
export const HIDE_PUBLIC_LINK_MODAL = 'HIDE_PUBLIC_LINK_MODAL';
export const OPEN_UPDATE_SHARE_MODAL = 'OPEN_UPDATE_SHARE_MODAL';
export const RESET_CURRENT_SHARE = 'RESET_CURRENT_SHARE';
export const RESET_SHARE = 'RESET_SHARE';
export const SET_CURRENT_AUDIENCE = 'SET_CURRENT_AUDIENCE';
export const SET_CURRENT_COMMENT = 'SET_CURRENT_COMMENT';
export const SET_CURRENT_SHARE = 'SET_CURRENT_SHARE';
export const SET_LAST_SHARE = 'SET_LAST_SHARE';
export const SET_SHARE_LEVEL = 'SET_SHARE_LEVEL';
export const TOGGLE_NEW_SHARE_MODAL = 'TOGGLE_NEW_SHARE_MODAL';
export const TOGGLE_UPDATE_SHARE_MODAL = 'TOGGLE_UPDATE_SHARE_MODAL';
export const UPDATE_SHARE_MODAL_FETCH_PENDING = 'UPDATE_SHARE_MODAL_FETCH_PENDING';
export const UPDATE_SHARE_MODAL_FETCH_REJECTED = 'UPDATE_SHARE_MODAL_FETCH_REJECTED';
export const UPDATE_SHARE_MUTATION_PENDING = 'UPDATE_SHARE_MUTATION_PENDING';
export const UPDATE_SHARE_MUTATION_DONE = 'UPDATE_SHARE_MUTATION_DONE';
export const UPDATE_SHARE_MUTATION_REJECTED = 'UPDATE_SHARE_MUTATION_REJECTED';
export const OPEN_DELETE_SHARE_MODAL = 'OPEN_DELETE_SHARE_MODAL';
export const CLOSE_DELETE_SHARE_MODAL = 'CLOSE_DELETE_SHARE_MODAL';
export const DELETE_SHARE_PENDING = 'DELETE_SHARE_PENDING';
export const DELETE_SHARE_DONE = 'DELETE_SHARE_DONE';
export const DELETE_SHARE_REJECTED = 'DELETE_SHARE_REJECTED';
