import { Iterable } from 'immutable';
import { isEqual } from 'lodash';

export default (pairs, source) => pairs
    .filter(([key, value]) => {
        const path = key.split('.');
        return Iterable.isIterable(source?.getIn(path)) ?
            !source?.getIn(path).equals(value) :
            !isEqual(value, source?.getIn(key.split('.')));
    });
