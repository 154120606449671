import { fromJS, Map } from 'immutable';
import {
    DEFAULT_PARAGRAPHSTYLE,
    DEFAULT_RUNSTYLE,
    DEFAULT_TEXT_BODY_STYLE
} from './defaultStyles';

export default {
    Textbox: Map({
        DEFAULT_RUNSTYLE: fromJS({
            ...DEFAULT_RUNSTYLE.toJS(),
            color: {
                type: 'custom',
                value: 'rgba(77, 77, 77, 1)'
            }
        }),
        DEFAULT_PARAGRAPHSTYLE: fromJS({
            ...DEFAULT_PARAGRAPHSTYLE.toJS(),
            align: 'left'
        }),
        DEFAULT_TEXT_BODY_STYLE: fromJS({
            ...DEFAULT_TEXT_BODY_STYLE.toJS(),
            verticalAlign: 'top',
            textDirection: 'horizontal'
        })
    }),
    Path: fromJS({
        DEFAULT_RUNSTYLE: fromJS({
            ...DEFAULT_RUNSTYLE.toJS(),
            color: {
                type: 'custom',
                value: 'rgba(77, 77, 77, 1)'
            }
        }),
        DEFAULT_PARAGRAPHSTYLE: fromJS({
            ...DEFAULT_PARAGRAPHSTYLE.toJS(),
            align: 'left'
        }),
        DEFAULT_TEXT_BODY_STYLE: fromJS({
            ...DEFAULT_TEXT_BODY_STYLE.toJS(),
            verticalAlign: 'top',
            textDirection: 'horizontal'

        })
    }),
    DEFAULT: fromJS({
        DEFAULT_RUNSTYLE,
        DEFAULT_PARAGRAPHSTYLE,
        DEFAULT_TEXT_BODY_STYLE
    })
};
