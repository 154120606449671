export const ADD_TABLE_LINE = 'ADD_TABLE_LINE';
export const ALIGN_SELECTED_SHAPES = 'ALIGN_SELECTED_SHAPES';
export const APPLY_LAYOUT_ON_PAGE = 'APPLY_LAYOUT_ON_PAGE';
export const CANVAS_IS_RENDERED = 'CANVAS_IS_RENDERED';
export const CHANGE_SHAPE_INDEX = 'CHANGE_SHAPE_INDEX';
export const CONVERT_SELECTED_SHAPES_TEXT_ITEMS_LEVEL = 'CONVERT_SELECTED_SHAPES_TEXT_ITEMS_LEVEL';
export const CLEAR_SELECTED_CELLS = 'CLEAR_SELECTED_CELLS';
export const COPY_SELECTED_SHAPES = 'COPY_SELECTED_SHAPES';
export const CREATE_SHAPES = 'CREATE_SHAPES';
export const CUT_SELECTED_SHAPES = 'CUT_SELECTED_SHAPES';
export const DELETE_ALL_SHAPES = 'DELETE_ALL_SHAPES';
export const DELETE_SELECTED_SHAPES = 'DELETE_SELECTED_SHAPES';
export const DISTRIBUTE_SELECTED_SHAPES = 'DISTRIBUTE_SELECTED_SHAPES';
export const EDITED_TEXTSHAPE = 'EDITED_TEXTSHAPE';
export const EXIT_CONTEXTUAL_SELECTION = 'EXIT_CONTEXTUAL_SELECTION';
export const FLIP_SELECTED_OBJECTS = 'FLIP_SELECTED_OBJECTS';
export const GROUP_SELECTED_SHAPES = 'GROUP_SELECTED_SHAPES';
export const MERGE_SELECTED_CELLS = 'MERGE_SELECTED_CELLS';
export const OFFSET_SELECTED_SHAPES = 'OFFSET_SELECTED_SHAPES';
export const REDO_CANVAS_STATE = 'REDO_CANVAS_STATE';
export const REMOVE_TABLE_LINE = 'REMOVE_TABLE_LINE';
export const REVERT_CANVAS_STATE = 'REVERT_CANVAS_STATE';
export const START_CANVAS_STATE_SAVE = 'START_CANVAS_STATE_SAVE';
export const SET_CANVAS_SIZE = 'SET_CANVAS_SIZE';
export const SET_CANVAS_UPDATING = 'SET_CANVAS_UPDATING';
export const SELECT_OBJECTS = 'SELECT_OBJECTS';
export const SPLIT_SELECTED_CELLS = 'SPLIT_SELECTED_CELLS';
export const SPLIT_SELECTED_CELLS_ERROR = 'SPLIT_SELECTED_CELLS_ERROR';
export const TOGGLE_EDIT_MODE = 'TOGGLE_EDIT_MODE';
export const TOGGLE_SHAPE_LOCK = 'TOGGLE_SHAPE_LOCK';
export const TOGGLE_SHAPE_VISIBILITY = 'TOGGLE_SHAPE_VISIBILITY';
export const UNDO_CANVAS_STATE = 'UNDO_CANVAS_STATE';
export const UNGROUP_SELECTED_SHAPES = 'UNGROUP_SELECTED_SHAPES';
export const UPDATE_CANVAS_STATE = 'UPDATE_CANVAS_STATE';
export const UPDATE_SHAPES = 'UPDATE_SHAPES';
export const UPDATE_CANVAS_LOGO_SOURCE = 'UPDATE_CANVAS_LOGO_SOURCE';
export const UPDATE_SELECTION = 'UPDATE_SELECTION';
export const UPDATE_SELECTION_AND_GROUP = 'UPDATE_SELECTION_AND_GROUP';
export const UPDATE_PROPERTIES_ON_SELECTED_SHAPES = 'UPDATE_PROPERTIES_ON_SELECTED_SHAPES';
export const UPDATE_PROPERTIES_FROM_DELTA_ON_SELECTED_SHAPES = 'UPDATE_PROPERTIES_FROM_DELTA_ON_SELECTED_SHAPES';
