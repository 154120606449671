import toPairsDeep from './toPairsDeep';
import validateKeyExceptions from './validateKeyExceptions';

export default (object, exceptions) => {
    validateKeyExceptions(exceptions);

    const customizer = (key, value) => (exceptions.includes(key) ?
        value :
        undefined);

    return toPairsDeep(object, customizer);
};
