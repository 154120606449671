import { fromJS } from 'immutable';
import { getShapePathById } from '../../../../../../Canvas/CanvasStateSelectors';
import { updateValueOfDescriptorWithPalette } from '../../../../../../CanvasState/Helpers/Style/colorValueDescriptor';
import { setComplementShapeStyleAsItemShapeStyle } from '../../../../../../CanvasState/Helpers/Style/shape';
import { addOpacityToRGBA } from '../../../../../../CanvasState/utilities/stringUtility';

const getFill = (table, cell, canvasState) => {
    const oldShape = canvasState.getIn([
        ...getShapePathById(canvasState, table.id),
        'cells',
        table.cells
            .findIndex(currentCell => currentCell.id === cell.id)
    ]);

    let fill = fromJS(cell.fill);

    if (fill.get('color')) {
        fill = fill.update(
            'color',
            color => updateValueOfDescriptorWithPalette(
                color,
                canvasState.get('colorPalette')
            )
        );

        if (fill.get('opacity')) {
            fill = fill.updateIn(
                ['color', 'value'],
                colorValue => addOpacityToRGBA(
                    colorValue,
                    fill.get('opacity')
                )
            );
        }

        return setComplementShapeStyleAsItemShapeStyle(
            oldShape.set('fill', fill)
        ).get('fill');
    }

    return fill;
};

export default getFill;
