const fabric = require('./fabric-adapter/adapter');
const CanvasState = require('./Canvas/CanvasState');
const bulletStrategies = require('./utilities/bulletStrategies');
const Selection = require('./Selection/Selection');
const npm = require('../../package.json');
const { setMetrics, getMetricsMap } = require('./fabric-adapter/utilities/FontMetricsGetter');
const ItemStyle = require('./Shape/ItemStyle/ItemStyle');
const { propertiesFromRenderer } = require('./utilities/propertiesFromRenderer');

console.info(`Loaded ${npm.name}@${npm.version}`);

// This is so the text isn't cut when zoomed in at 400%
fabric.perfLimitSizeTotal = 8388608;
fabric.maxCacheSideLimit = 8192;
fabric.decksignCanvasVersion = `${npm.name}@${npm.version}`;

module.exports = {
    bulletStrategies,
    CanvasState,
    fabric,
    getFontMetricsMap: getMetricsMap,
    ItemStyle,
    Selection,
    setFontMetrics: setMetrics,
    propertiesFromRenderer
};
