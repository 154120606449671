import { authNavConfig } from '#Config/navigation.config';
import client from '#Utilities/graphql';
import { history } from '../configureStore';

const tokenIsExpired = accessToken => {
    const exp = accessToken?.payload?.exp;
    if (exp) {
        return exp * 1000 < Date.now();
    }
    return false;
};

const getAccessToken = () => {
    const tokensInfo = JSON.parse(localStorage.getItem('cognito-user-tokens'));
    if (tokenIsExpired(tokensInfo?.accessToken)) {
        return client.renewTokens(tokensInfo?.refreshToken?.jwtToken).then(({ accessToken, idToken, refreshToken }) => {
            localStorage.setItem('cognito-user-tokens', JSON.stringify({ accessToken, idToken, refreshToken }));
            return Promise.resolve(accessToken);
        });
    }
    return Promise.resolve(tokensInfo?.accessToken || null);
};

const clearTokens = () => localStorage.clear();

const getAuthorizationHeader = () => getAccessToken().then(token => {
    if (token) {
        const expiresAt = token.payload.exp * 1000;
        const currentDate = new Date().getTime();
        if (expiresAt < currentDate) {
            clearTokens();
            return history.replace({
                pathname: authNavConfig.login.path
            });
        }
        return `Bearer ${token.jwtToken}`;
    }
    return null;
});

const hasToken = () => {
    const tokensInfo = JSON.parse(localStorage.getItem('cognito-user-tokens'));
    return tokensInfo?.accessToken !== undefined;
};

export const getAuthorizationToken = () => getAccessToken().then(({ jwtToken }) => jwtToken);

export {
    getAccessToken,
    getAuthorizationHeader,
    hasToken,
    clearTokens
};
