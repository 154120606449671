const { fabric } = require('fabric');
const svgpath = require('svgpath');
const Textshape = require('./TextShape');
const { generatePathFromPoints } = require('../../utilities/PathGenerator/PathGenerator');
const TextBoundsCalculator = require('../utilities/TextBoundsCalculator');
const { getRegularPolygonPoints } = require('../../utilities/ShapeGenerators');

const RegularPolygon = fabric.util.createClass(Textshape, {
    type: 'regularPolygon',

    initialize(json, opts) {
        this.callSuper('initialize', json, opts);
        this.shapeType = 'RegularPolygon';
    },

    updateShapeScale(scaleX, scaleY) {
        this.callSuper('updateShapeScale', scaleX, scaleY, true);
    },

    generateModificationHandlers() {
        this.handlers = [
            new fabric.RegularPolygonModificationHandler(
                this
            )
        ];
    },

    updateModifierAttribute(sides) {
        const polygon = this.getShapeObject();
        const points = getRegularPolygonPoints(sides, polygon.width, polygon.height);
        const path = svgpath(generatePathFromPoints(points)).segments;
        polygon.sides = sides;
        polygon.points = points;
        polygon.path = path;
        this.set('dirty', true);
    },

    getTextBounds() {
        const shape = this.getShapeObject();
        switch (shape.sides) {
            case 3:
                return new TextBoundsCalculator.Triangle(
                    this.width,
                    this.height,
                    {
                        topVertex: {
                            x: 0, y: 0
                        },
                        flipX: shape.flipX
                    }
                );
            case 4:
                return new TextBoundsCalculator.Diamond(
                    this.width,
                    this.height,
                    {
                        flipX: shape.flipX
                    }
                );
            default:
                return new TextBoundsCalculator.Ellipse(
                    this.width,
                    this.height,
                    {
                        flipX: shape.flipX
                    }
                );
        }
    }
});

RegularPolygon.fromObject = (object, callback) => {
    const regularpolygon = new RegularPolygon(object);
    regularpolygon.on('text:load', () => {
        callback(regularpolygon);
    })
        .on('load:error', err => {
            console.error(err);
            callback(null);
        });
};

module.exports = RegularPolygon;
