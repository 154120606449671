import { createSelector } from 'reselect';

export const isFetchingFiles = ({
    manage: {
        files: {
            isFetching
        }
    }
}) => isFetching;

export const isExporting = ({
    manage: {
        exportModal: {
            isExporting: exporting
        }
    }
}) => exporting;

export const isLoading = createSelector(
    isFetchingFiles,
    isExporting,
    (fetchingFiles, exporting) => fetchingFiles || exporting
);
