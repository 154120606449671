module.exports = {
    type: 'object',
    required: [
        'onlyBulletUpdate'
    ],
    properties: {
        onlyBulletUpdate: {
            type: 'boolean'
        },
        allLevels: {
            type: 'object'
        },
        level1: {
            type: 'object'
        },
        level2: {
            type: 'object'
        },
        level3: {
            type: 'object'
        },
        level4: {
            type: 'object'
        },
        level5: {
            type: 'object'
        }
    },
    oneOf: [
        {
            required: [
                'allLevels'
            ]
        },
        {
            required: [
                'level1',
                'level2',
                'level3',
                'level4',
                'level5'
            ]
        }
    ]
};
