const DEFAULT_CELL_STYLE = {
    fill: {
        type: 'color',
        color: {
            type: 'custom',
            value: 'rgba(255, 255, 255, 1)'
        }
    },
    color: {
        type: 'custom',
        value: 'rgba(77, 77, 77, 1)'
    },
    opacity: 1
};

const DEFAULT_CELL_RUNSTYLE = {
    color: {
        type: 'custom',
        value: 'rgba(77, 77, 77, 1)'
    }
};

const DEFAULT_HEADERROW_STYLE = {
    fill: {
        type: 'color',
        color: {
            type: 'custom',
            value: 'rgba(202, 202, 202, 1)'
        }
    }
};

const DEFAULT_HEADERCOL_STYLE = {
    fill: {
        type: 'color',
        color: {
            type: 'custom',
            value: 'rgba(202, 202, 202, 1)'
        }
    }
};

const DEFAULT_TOTALROW_STYLE = {
    fill: {
        type: 'color',
        color: {
            type: 'custom',
            value: 'rgba(153, 153, 202, 1)'
        }
    }
};

const DEFAULT_TOTALCOL_STYLE = {
    fill: {
        type: 'color',
        color: {
            type: 'custom',
            value: 'rgba(153, 153, 202, 1)'
        }
    }
};

const DEFAULT_CELL_SIZE = {
    width: 200,
    height: 50
};

module.exports = {
    DEFAULT_CELL_STYLE,
    DEFAULT_CELL_RUNSTYLE,
    DEFAULT_HEADERROW_STYLE,
    DEFAULT_HEADERCOL_STYLE,
    DEFAULT_TOTALROW_STYLE,
    DEFAULT_TOTALCOL_STYLE,
    DEFAULT_CELL_SIZE
};
