const isNil = require('lodash/isNil');

const Style = require('./Style');

module.exports = class extends Style {
    static fromJSON(json) {
        return new this(json);
    }

    static get properties() {
        return [
            ...super.properties,
            'autoFitText',
            'autoFitShape',
            'margins',
            'textDirection',
            'verticalAlign'
        ];
    }

    constructor({
        autoFitText,
        autoFitShape,
        margins,
        textDirection,
        verticalAlign,
        ...baseStyle
    }) {
        super(baseStyle);
        this.autoFitText = autoFitText;
        this.autoFitShape = autoFitShape;
        this.margins = margins;
        this.textDirection = textDirection;
        this.verticalAlign = verticalAlign;
    }

    toJSON() {
        return {
            ...super.toJSON(),
            autoFitText: this.autoFitText,
            autoFitShape: this.autoFitShape,
            margins: this.margins,
            textDirection: this.textDirection,
            verticalAlign: this.verticalAlign
        };
    }

    get autoFitText() {
        return this._autoFitText;
    }

    set autoFitText(autoFitText) {
        if (!isNil(autoFitText)) {
            this._autoFitText = autoFitText;
        }
    }

    get autoFitShape() {
        return this._autoFitShape;
    }

    set autoFitShape(autoFitShape) {
        if (!isNil(autoFitShape)) {
            this._autoFitShape = autoFitShape;
        }
    }

    get margins() {
        return this._margins;
    }

    set margins(margins) {
        if (!isNil(margins)) {
            this._margins = margins;
        }
    }

    get textDirection() {
        return this._textDirection;
    }

    set textDirection(textDirection) {
        if (!isNil(textDirection)) {
            this._textDirection = textDirection;
        }
    }

    get verticalAlign() {
        return this._verticalAlign;
    }

    set verticalAlign(verticalAlign) {
        if (!isNil(verticalAlign)) {
            this._verticalAlign = verticalAlign;
        }
    }
};
