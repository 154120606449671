import decksignCanvas from 'decksign-canvas';

import {
    getColorPalette,
    getColorsPresets,
    getPaletteColorSchemeFromDeck
} from './deck';

const CanvasState = decksignCanvas.api.CanvasState;

export const updateCanvasStateAfterDeckUpdate = (state, deck) => {
    if (CanvasState.isCanvasState(state)) {
        const theme = deck.theme && Object.keys(deck.theme).length > 0 ?
            deck.theme :
            (deck.originalTheme || {});

        const colorPalette = getColorPalette(deck);

        const dynamicValues = {
            'deck.createdAt': deck.createdAt,
            'deck.updatedAt': deck.updatedAt,
            'deck.title': deck.title,
            pageCount: deck.pageCount,
            author: deck.properties ? deck.properties.author || null : null
        };

        if (theme.logo) {
            dynamicValues.logo = theme.logo;
        }

        let updatedState = CanvasState.update(state, {
            type: 'UPDATE_COLOR_PALETTE',
            colorPalette
        });

        updatedState = CanvasState.update(updatedState, {
            type: 'UPDATE_DYNAMIC_VALUES',
            dynamicValues
        });

        updatedState = CanvasState.update(updatedState, {
            type: 'UPDATE_COLOR_PRESETS',
            presets: getColorsPresets(deck),
            scheme: getPaletteColorSchemeFromDeck(deck)
        });

        return updatedState;
    }
    return state;
};
