const { defaultShapeStyle, defaultStrokeStyle } = require('../config/defaultShapeStyle');

const shape = {
    Textbox: {
        ...defaultShapeStyle,
        width: 240,
        height: 90,
        fill: {
            type: 'color',
            color: {
                type: 'custom',
                value: 'rgba(255, 255, 255, 0)'
            }
        },
        stroke: {
            fill: {
                type: 'color',
                color: {
                    type: 'custom',
                    value: 'rgba(77, 77, 77, 1)'
                }
            },
            width: 0
        },
        placeholderText: 'Enter text...',
        text: '',
        opacity: 0
    },
    Rectangle: {
        ...defaultShapeStyle,
        width: 240,
        height: 90,
        rx: 0.0,
        ry: 0.0
    },
    Group: {
        ...defaultShapeStyle,
        stroke: undefined
    },
    Line: {
        ...defaultShapeStyle,
        stroke: {
            ...defaultStrokeStyle,
            width: 1
        }
    },
    DEFAULT: { ...defaultShapeStyle }
};

module.exports = Base => class StyleProvider extends Base {
    // eslint-disable-next-line class-methods-use-this
    loadDefaultShapeStyleConfiguration(name) {
        const style = {
            ...(!!name && Object.prototype.hasOwnProperty.call(shape, name) ?
                shape[name] :
                shape.DEFAULT)
        };
        return style;
    }
};
