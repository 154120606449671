import get from 'lodash/get';
import decksignCanvas from 'decksign-canvas';

import {
    getColorPalette,
    getColorsPresets,
    getPaletteColorSchemeFromDeck
} from './deck';

const CanvasState = decksignCanvas.api.CanvasState;

const createCanvasState = (deck, page) => {
    const {
        shapes = [], background = {}, originalLayout = { shapes: [] }, layout, id
    } = page;

    const theme = deck.theme && Object.keys(deck.theme).length > 0 ?
        deck.theme :
        (deck.originalTheme || {});

    const colorsPresets = getColorsPresets(deck);
    const scheme = getPaletteColorSchemeFromDeck(deck);
    const colorPalette = getColorPalette(deck);

    const dynamicValues = {
        'deck.createdAt': deck.createdAt,
        'deck.updatedAt': deck.updatedAt,
        'deck.title': deck.title,
        pageCount: deck.pageCount,
        author: deck.properties ? deck.properties.author || null : null,
        'page.createdAt': page.createdAt,
        'page.updatedAt': page.updatedAt,
        'page.title': page.title,
        pageNumber: page.pageNumber
    };

    if (theme.logo) {
        dynamicValues.logo = theme.logo;
    }

    let layoutShapes = [];
    let layoutName = '';
    let layoutBackground = [];
    if (layout && layout.shapes) {
        layoutShapes = layout.shapes;
        layoutName = layout.name || layoutName;
        layoutBackground = layout.background;
    } else {
        layoutShapes = get(originalLayout, 'shapes', []);
        layoutName = get(originalLayout, 'name', layoutName);
        layoutBackground = get(originalLayout, 'background', []);
    }

    const headerAndFooterShapesVisibility = theme.headerAndFooterShapesVisibility;

    return CanvasState.initialize(
        shapes,
        background,
        { width: page.width, height: page.height },
        dynamicValues,
        layoutShapes,
        layoutBackground,
        layoutName,
        colorPalette,
        headerAndFooterShapesVisibility,
        {
            presets: colorsPresets,
            scheme
        },
        id
    );
};

if (process.env.REACT_APP_PERF_TEST_ENABLED && !window.createCanvasState) {
    window.createCanvasState = createCanvasState;
}

export default createCanvasState;
