const { fabric } = require('fabric');
const svgpath = require('svgpath');
const Textshape = require('./TextShape');
const { generatePathFromPoints } = require('../../utilities/PathGenerator/PathGenerator');
const { getTriangleBottomVertexes } = require('../../utilities/ShapeGenerators');
const { flipDimension } = require('../utilities/FlipDimensions');
const TextBoundsCalculator = require('../utilities/TextBoundsCalculator');
const { SHAPE_RADIUS } = require('./ModificationHandlers/config/defaultModificationHandlerAttributes.config');

const Triangle = fabric.util.createClass(Textshape, {
    type: 'triangle',

    initialize(json, opts) {
        this.callSuper('initialize', json, opts);
        this.shapeType = 'Triangle';
        this.getHandlerPosition = this.getHandlerPosition.bind(this);
        this.getHandlerLimits = this.getHandlerLimits.bind(this);
        this.updateModifierAttribute = this.updateModifierAttribute.bind(this);
    },

    updateShapeScale(scaleX, scaleY) {
        const shapeObject = this.getShapeObject();
        shapeObject.set({
            topVertex: shapeObject.topVertex ? {
                x: shapeObject.topVertex.x * scaleX,
                y: shapeObject.topVertex.y * scaleY
            } : shapeObject.topVertex
        });
        this.callSuper('updateShapeScale', scaleX, scaleY, true);
    },

    addDefaultTopVertex() {
        const triangle = this.getShapeObject();
        if (!triangle.topVertex) {
            triangle.topVertex = {
                x: this.width / 2,
                y: 0
            };
        }
    },

    generateModificationHandlers() {
        this.addDefaultTopVertex();
        this.handlers = [
            new fabric.GenericModificationHandler(
                this,
                this.getHandlerPosition,
                this.getHandlerLimits,
                this.updateModifierAttribute,
                { lockVertical: true }
            )
        ];
    },

    getHandlerPosition() {
        const relativePosition = this.getRelativeHandlerPosition();
        const triangle = this.getShapeObject();
        const absolutePosition = this.getAbsolutePosition();
        return {
            x: absolutePosition.x + flipDimension(triangle.flipX, relativePosition.x),
            y: absolutePosition.y + flipDimension(triangle.flipY, relativePosition.y + SHAPE_RADIUS)
        };
    },

    getRelativeHandlerPosition() {
        const triangle = this.getShapeObject();
        const { topVertex } = triangle;
        const activeScaleX = this.scaleX * triangle.scaleX;
        const activeScaleY = this.scaleY * triangle.scaleY;
        return {
            x: topVertex.x * activeScaleX,
            y: topVertex.y * activeScaleY
        };
    },

    getHandlerLimits() {
        const triangle = this.getShapeObject();
        const halfWidth = this.width / 2;
        const halfStrokeWidth = (triangle.strokeWidth / 2) * triangle.scaleX;
        const absolutePosition = this.getAbsolutePosition();
        return {
            min: (absolutePosition.x - halfWidth) + halfStrokeWidth,
            max: (absolutePosition.x + halfWidth) - halfStrokeWidth
        };
    },

    updateModifierAttribute({ x }) {
        const triangle = this.getShapeObject();
        const newTopVertex = this.getNewTopVertex(x);
        const points = this.getNewVertexes(newTopVertex);
        const path = svgpath(generatePathFromPoints(points)).segments;
        triangle.topVertex = newTopVertex;
        triangle.points = points;
        triangle.path = path;
        this.set('dirty', true);
    },

    getNewTopVertex(x) {
        const triangle = this.getShapeObject();
        const absolutePosition = this.getAbsolutePosition();
        return {
            x: flipDimension(triangle.flipX, x - absolutePosition.x) / triangle.scaleX,
            y: triangle.topVertex.y
        };
    },

    getNewVertexes(topVertex) {
        const triangle = this.getShapeObject();
        const bottomVertexes = getTriangleBottomVertexes(
            triangle.width,
            triangle.height
        );
        return [topVertex, ...bottomVertexes];
    },

    getTextBounds() {
        const shape = this.getShapeObject();
        return new TextBoundsCalculator.Triangle(
            this.width,
            this.height,
            {
                topVertex: shape.topVertex,
                flipX: shape.flipX,
                flipY: shape.flipY
            }
        );
    },

    toObject(propertiesToInclude = []) {
        const shape = this.getShapeObject();
        const serializedObject = this.callSuper('toObject', propertiesToInclude);
        serializedObject.topVertex = shape.topVertex;
        return serializedObject;
    }
});

Triangle.fromObject = (object, callback) => {
    const triangle = new Triangle(object);
    triangle.on('text:load', () => {
        callback(triangle);
    })
        .on('load:error', err => {
            console.error(err);
            callback(null);
        });
};

module.exports = Triangle;
