import { Map } from 'immutable';

export default (map, keys) => (
    Map.isMap(map) ?
        keys
            .reduce((currentMap, key) => {
                const keyPath = key.split('.');
                if (map.hasIn(keyPath)) {
                    return currentMap.setIn(keyPath, map.getIn(keyPath));
                }
                return currentMap;
            }, Map()) :
        map
);
