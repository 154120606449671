import * as types from '#Constants/ActionTypes';

export const layoutReset = (state = {
    isReseting: false,
    error: null
}, action) => {
    switch (action.type) {
        case types.START_ORIGINAL_LAYOUT_RESET:
            return {
                ...state,
                isReseting: true
            };
        case types.UPDATE_PAGE_ID_IN_CURRENT_DECK:
            return {
                ...state,
                isReseting: false
            };
        case types.SAVING_FAILED:
            return {
                ...state,
                isReseting: false,
                error: action.error
            };
        default:
            return state;
    }
};
export const layouts = (
    state = {
        loaded: false,
        loading: false,
        list: []
    },
    action
) => {
    switch (action.type) {
        case types.UPDATE_DECK_LAYOUTS:
        case types.LOAD_LAYOUTS:
            return {
                loaded: true,
                loading: false,
                list: action.list
            };
        case types.FETCHING_LAYOUTS:
            return {
                ...state,
                loading: true
            };
        case types.FETCH_LAYOUTS_FAILED:
            return {
                ...state,
                loading: false
            };
        case types.SET_DECK:
        case types.LOAD_DECK:
        case types.RESET_LAYOUTS_LIST:
        case types.CHANGE_LAYOUT_NAME:
        case types.CREATE_DECK:
            return {
                loaded: false,
                list: []
            };
        default:
            return state;
    }
};
