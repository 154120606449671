let metrics = require('../config/metrics.json');

const hasFontMetrics = fontFamily => Boolean(metrics[fontFamily]);

const getFontMetrics = fontFamily => metrics[fontFamily] || {};

const getFontMetricById = ({
    fontFamily,
    metricId
}) => getFontMetrics(fontFamily)[metricId];

const getFontUnitsPerEm = fontFamily => getFontMetricById({
    fontFamily,
    metricId: 'unitsPerEm'
});

const getEmOfFontMetric = ({
    fontFamily,
    metricId
}) => {
    const metricUnits = getFontMetricById({ fontFamily, metricId }) ?? 0;
    const unitsPerEm = getFontUnitsPerEm(fontFamily) ?? 0;
    return metricUnits && metricUnits / unitsPerEm;
};

const getLineContentHeightInEm = ({
    fontFamily
}) => (hasFontMetrics(fontFamily) ?
    (
        getEmOfFontMetric({ fontFamily, metricId: 'ascender' }) -
        getEmOfFontMetric({ fontFamily, metricId: 'descender' })
    ) :
    1
);

const getFontMetricPositionRelativeToFontSize = ({
    fontFamily,
    metricId,
    fontSize = 0
}) => {
    const metricEms = getEmOfFontMetric({ fontFamily, metricId });
    return metricEms && metricEms * fontSize;
};

const setMetrics = newMetrics => {
    metrics = newMetrics;
};

const getMetricsMap = () => metrics;

module.exports = {
    hasFontMetrics,
    getFontMetrics,
    getFontMetricById,
    getFontUnitsPerEm,
    getEmOfFontMetric,
    getFontMetricPositionRelativeToFontSize,
    getLineContentHeightInEm,
    getMetricsMap,
    setMetrics
};
