const PatternColors = require('./PatternColors.js');

class Pattern {
    constructor(colors, preset) {
        this.colors = colors;
        this.preset = preset;
    }

    toJSON() {
        return {
            colors: this.colors.toJSON(),
            preset: this.preset
        };
    }

    static fromJSON(pattern, jsonObject) {
        return new this(PatternColors.fromJSON(jsonObject.colors), jsonObject.preset);
    }
}

module.exports = Pattern;
