exports.LIST_LEVEL_LEFT_OFFSET = 40;
exports.FABRIC_FONT_DEFAULT_SIZE = 16;
exports.TAB_TO_SPACES = 8;
exports.MIN_FONT_SIZE = 13.33;
// This magic ratio is to replicate html line height for fabric, see this github issue.
// https://github.com/fabricjs/fabric.js/issues/2059#issuecomment-85965640
exports.FABRIC_FIRST_LINE_OFFSET_RATIO = 0.825;
exports.FABRIC_BASE_RATIO = 1.06;
exports.FABRIC_SPACING_FACTOR = 1000;
exports.FABRIC_DEFAULT_CHAR_SPACING = 0;
exports.FABRIC_FONT_SIZE_AUTO_FIT_STEP_MULTIPLIER = 0.2;
exports.FABRIC_DEFAULT_TEXT_FILL = 'rgb(0,0,0)';
exports.SMALL_CAPS_RATIO = 0.7;
exports.PPTX_FIRST_LINE_RATIO = 0.75;
exports.PPTX_BOTTOM_HEIGHT_RATIO = 0.25;
exports.PPTX_ONE_LINE_HEIGHT_MULTIPLIER = 1.211718897638;
