module.exports = fabric => {
    /* eslint-disable */    
    fabric.ActiveSelection.prototype.updateScale = function (scaleX, scaleY) {
        this.scaleX = 1.0;
        this.scaleY = 1.0;
        this.width *= scaleX;
        this.height *= scaleY; 
        return Promise.all(this._objects.map(object => {
            object.dirty = true;
            object.left *= scaleX;
            object.top *= scaleY;
            return object.updateScale(scaleX, scaleY);              
        })).then(() => {
            this.dirty = true;
        });
    };

    fabric.ActiveSelection.prototype.rotateAboutSelection = function (obj, degrees = 0) {
        const radians = fabric.util.degreesToRadians(degrees);

        const rotatedPoint = fabric.util.rotatePoint(
            new fabric.Point(obj.left, obj.top),
            new fabric.Point(this.left, this.top),
            radians
        );

        obj.left = rotatedPoint.x;
        obj.top = rotatedPoint.y;

        return obj
    };

    fabric.ActiveSelection.prototype.getAbsolutePositionFabricObjects = function (extraPropsToExtract) {
        return this._objects.map(object => {
            const fabricObject = object.toObject(extraPropsToExtract);
            fabricObject.angle += this.angle;
            fabricObject.left += (this.left + this.width/2);
            fabricObject.top += (this.top + this.height/2);

            return this.rotateAboutSelection(fabricObject, this.angle);
        });
    };

    fabric.ActiveSelection.prototype.renderDecorations = function(canvas) {
        this._objects.forEach(object => object.renderDecorations(canvas));
    };

    fabric.ActiveSelection.prototype.initialize = (function(fabricInitialize) {
        return function() {
            fabricInitialize.apply(this, arguments);
            this.on('mousedown', event => {
                this.setInitialPositions(event);
                if (event.e.metaKey) {
                    this.duplicateShape();
                }
            });
            this.on('mouseup', event => {
                if (event.e.metaKey) {
                    this.changeOriginalShapeName();
                }
            });
            this.on('moving', event => {
                this.handleAxisLocking(event);
            });
            this.on('modified', event => {
                this.resetAxisLocking();
            });
        }
    })(fabric.ActiveSelection.prototype.initialize);

    fabric.ActiveSelection.prototype.onDeselect = (function(onDeselect) {
        return function() {
            if (this.canvas.useNewImplementation && this.group) {
                var oldGroup = this.group;

                this.group.removeWithUpdate(this);

                // This needs to be a while because we remove items while iterating
                while(this._objects && this._objects.length) {
                    let obj = this._objects[0];
                    this.removeWithUpdate(obj);

                    if (this.canvas.isGroupChild(obj)) {
                        oldGroup.addWithUpdate(obj);
                    } else {
                        this.canvas.add(obj);
                    }
                }
            }

            onDeselect.apply(this, arguments);
        }
    })(fabric.ActiveSelection.prototype.onDeselect);
};
