import * as types from '#Constants/ActionTypes';

const currentAudience = (state = {}, action) => {
    switch (action.type) {
        case types.SET_CURRENT_AUDIENCE:
            return action.currentAudience;
        case types.FETCH_SHARE_INFORMATION: {
            if (action.share.audience.length > 0) {
                return action.share.audience[0];
            }
            return state;
        }
        case types.SET_CURRENT_SHARE:
            return {};
        default:
            return state;
    }
};

export { currentAudience };
