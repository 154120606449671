const get = require('lodash/get');
const set = require('lodash/set');

const typefaceEffectConversion = require('./typefaceEffectConversion');
const getFirstRunStyle = require('./getFirstRunStyle');
const Fill = require('../../Fill');

module.exports = {
    id: {
        itemUpdatePropertyName: 'styleId'
    },
    fontFamily: {
        toItemUpdate: (value, update, definition) => set(
            update,
            'fontFamily',
            value ? `${value} ${definition.fontWeight ? definition.fontWeight : ''}`.trim() : ''
        ),
        toStyle(canvasItem) {
            const runStyle = getFirstRunStyle(canvasItem);
            return get(runStyle, 'font.family', '');
        }
    },
    fontWeight: {
        toItemUpdate(value, update) {
            let fontWeightUpdate = update;
            if (/\bbold\b/i.test(value)) {
                fontWeightUpdate = set(
                    fontWeightUpdate,
                    'fontWeight',
                    'bold'
                );
            } else {
                fontWeightUpdate = set(
                    fontWeightUpdate,
                    'fontWeight',
                    'normal'
                );
            }
            if (/\bitalic\b/i.test(value)) {
                fontWeightUpdate = set(
                    fontWeightUpdate,
                    'fontStyle',
                    'italic'
                );
            } else {
                fontWeightUpdate = set(
                    fontWeightUpdate,
                    'fontStyle',
                    'normal'
                );
            }
            return fontWeightUpdate;
        },
        toStyle(canvasItem) {
            const runStyle = getFirstRunStyle(canvasItem);
            return get(runStyle, 'font.weight', 'normal');
        }
    },
    fontSize: {
        itemUpdatePropertyName: 'fontSize',
        toStyle(canvasItem) {
            const runStyle = getFirstRunStyle(canvasItem);
            return get(runStyle, 'font.size', 0);
        }
    },
    fill: {
        toItemUpdate(value, canvasItemStyle = {}) {
            return set(
                canvasItemStyle,
                'fill',
                Fill.getFirstColorValueDescriptor(value)
            );
        },
        toStyle(canvasItem) {
            const runStyle = getFirstRunStyle(canvasItem);
            return Fill.createSolidFill(get(runStyle, 'color', {}));
        },
        isFill: true
    },
    opacity: {
        itemUpdatePropertyName: 'textOpacity',
        toStyle(canvasItem) {
            const runStyle = getFirstRunStyle(canvasItem);
            return get(runStyle, 'opacity');
        }
    },
    effect: {
        ...typefaceEffectConversion
    },
    characterSpacing: {
        itemUpdatePropertyName: 'characterSpacing',
        toStyle(canvasItem) {
            const runStyle = getFirstRunStyle(canvasItem);
            return get(runStyle, 'characterSpacing');
        }
    }
};
