import {
    Record,
    Map,
    List,
    fromJS
} from 'immutable';
import UUID from 'uuid/v4';
import genericShapeProperties from '../../genericShapeProperties';
import { addShape, getNewShapeName, updateSelection } from '../../Helpers/helpers';
import Textbody from '../../Helpers/Text/TextBody';
import { shapeStyleProvider } from '../../utilities/ShapeStyleProvider';
import { forceOpacity } from '../../Helpers/Style/colorValueDescriptor';
import getPropertiesForDestructuring from '../../utilities/getPropertiesForDestructuring';
import { applyStyle } from '../../Helpers/Style/style';
import { convertPropertiesToShapeProperties } from '../../utilities/convertPropertiesToShapeProperties';

const TextboxRecord = Record({
    ...genericShapeProperties,
    type: 'Textbox'
}, 'Textbox');

const initializeTextbody = (shape, styleToApply) => (shape.getIn(['modifiers', 'textBody']) ?
    Textbody.TextBody(shape.getIn(['modifiers', 'textBody'])) :
    Textbody.TextBody({
        paragraphStyles: List([Map({ isDefault: true })]),
        paragraphs: List([Map({ startIndex: 0, endIndex: -1, style: 1 })]),
        runStyles: List([Map({ isDefault: true })]),
        assignedStyles: Map({
            paragraph: styleToApply.getIn(['textProps', 'assignedParagraphStyle']),
            run: styleToApply.getIn(['textProps', 'assignedRunStyle']),
            textBody: styleToApply.getIn(['textProps', 'assignedStyle'])
        }),
        verticalAlign: undefined
    })
);

const initializeTextbodyPlaceholder = (shape, styleToApply, defaultStyle) => {
    let textBodyPlaceholder = shape.getIn(['modifiers', 'textBodyPlaceholder']) ?
        Textbody.TextBody(shape.getIn(['modifiers', 'textBodyPlaceholder'])) :
        Textbody.TextBody({
            paragraphStyles: List([Map({ isDefault: true })]),
            paragraphs: List([Map({ startIndex: 0, endIndex: -1, style: 1 })]),
            runStyles: List([Map({ isDefault: true })]),
            assignedStyles: Map({
                paragraph: styleToApply.getIn(['textProps', 'assignedParagraphStyle']),
                run: styleToApply.getIn(['textProps', 'assignedRunStyle']),
                textBody: styleToApply.getIn(['textProps', 'assignedStyle'])
            }),
            verticalAlign: undefined
        });

    if (!shape.getIn(['modifiers', 'textBodyPlaceholder'])) {
        textBodyPlaceholder = Textbody.setText(textBodyPlaceholder, defaultStyle.placeholderText);
    }

    if (styleToApply.getIn(['textProps', 'listUpdate'])) {
        const updatedStyleToApply = styleToApply
            .setIn(
                ['textProps', 'listUpdate', 'type'],
                shape.get('styleToLoad') === 'bulletList' ? 'unordered' : 'ordered'
            );
        textBodyPlaceholder = Textbody.applyUpdate(Map({ type: 'Textbox' }), textBodyPlaceholder, updatedStyleToApply.get('textProps'));
    }
    return textBodyPlaceholder;
};

const add = (canvasState, command) => {
    const {
        shapes,
        defaultCanvasItemStyles,
        editMode
    } = getPropertiesForDestructuring(
        command,
        [
            'shapes',
            'defaultCanvasItemStyles',
            'editMode'
        ]
    );

    const newIds = [];

    let updatedCanvasState = shapes.reduce((currentCanvasState, shape) => {
        const defaultStyle = shapeStyleProvider.Textbox;
        const style = defaultCanvasItemStyles.get(shape.get('styleToLoad') || 'textbox');
        const styleDefinition = applyStyle(Map(), style, 'Textbox');
        const styleToApply = convertPropertiesToShapeProperties(styleDefinition);

        const textBody = initializeTextbody(shape, styleToApply);

        const textBodyPlaceholder = initializeTextbodyPlaceholder(shape, styleToApply, defaultStyle);

        // Need to do this because the opacity is not right in the rgba and it wil cause the toolbar
        // to not have the right color
        let updatedStyleToApply = styleToApply;
        updatedStyleToApply = updatedStyleToApply
            .updateIn(
                ['shapeProps', 'assignedShapeStyle', 'fill', 'color'],
                color => forceOpacity(
                    color,
                    updatedStyleToApply.getIn(['shapeProps', 'assignedShapeStyle', 'opacity'])
                )
                    .update('value', value => value.replace(/\s/g, ''))
            );

        const newShape = TextboxRecord(
            shape.get('modifiers')
                .merge(
                    fromJS({
                        name: getNewShapeName(currentCanvasState, 'Textbox'),
                        id: UUID().toString(),
                        inLayout: editMode === 'layout',
                        x: currentCanvasState.get('size').get('width') / 2,
                        y: currentCanvasState.get('size').get('height') / 2,
                        assignedStyles: Map({
                            shape: updatedStyleToApply.getIn(['shapeProps', 'assignedShapeStyle']),
                            stroke: updatedStyleToApply.getIn(['strokeProps', 'assignedStrokeStyle'])
                        }),
                        style: style.get('id'),
                        textBody,
                        textBodyPlaceholder
                    })
                )
        );
        newIds.push(newShape.get('id'));
        return addShape(currentCanvasState, newShape);
    }, canvasState);
    updatedCanvasState = updateSelection(updatedCanvasState, fromJS({ selection: newIds }));
    return updatedCanvasState;
};

export default add;
