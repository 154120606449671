const { Map } = require('immutable');

module.exports = borders => {
    const pathInBorder = (
        borders.first() &&
        borders.first().hasIn(['stroke', 'fill']) &&
        borders.first().getIn(['stroke', 'fill']) !== undefined
    ) ?
        ['stroke', 'fill'] :
        ['assignedStyles', 'stroke', 'fill'];
    const isAllSameBorderFill = borders
        .every(border => (
            border.hasIn(pathInBorder) &&
            borders.first().hasIn(pathInBorder) &&
            border.getIn(pathInBorder)
                .equals(borders.first().getIn(pathInBorder))
        ));
    return (borders.size > 0 && isAllSameBorderFill) ?
        borders.first().getIn(pathInBorder) :
        Map({
            color: {
                type: 'custom',
                value: 'rgba(77, 77, 77, 0)'
            },
            type: 'color'
        });
};
