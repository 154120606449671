import { Map } from 'immutable';

const updatePageSize = (canvasState, command) => canvasState.set(
    'size',
    Map({
        width: command.get('width'),
        height: command.get('height')
    })
);

export default updatePageSize;
