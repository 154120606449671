import * as types from '#Constants/ActionTypes';

const initialState = {
    activePage: 0,
    blackPage: false,
    whitePage: false,
    isLoading: false
};

export const ui = (state = initialState, action) => {
    switch (action.type) {
        case types.CHANGE_PAGES_PREVIEW_VISIBILITY: {
            return {
                ...state,
                activePage: 0
            };
        }
        case types.SET_VIEWER_ACTIVE_PAGE:
            return { ...state, activePage: action.activePageIndex };
        case types.SET_VIEWER_BLACK_PAGE:

            return { ...state, blackPage: action.active };
        case types.SET_VIEWER_WHITE_PAGE:
            return { ...state, whitePage: action.active };
        case types.VIEWER_LOADING:
            return { ...state, isLoading: true };
        case types.VIEWER_LOADED:
            return { ...state, isLoading: false };
        default:
            return state;
    }
};
