const Bounds = require('./Bounds');

class ArrowBounds extends Bounds {
    get dimensions() {
        const tailWidth = this._attributes.isDouble ?
            this.shapeWidth - (2 * this._attributes.headWidth) :
            this.shapeWidth - this._attributes.headWidth;
        const headWidth = this._attributes.isDouble ?
            2 * (this.shapeHeight - this._attributes.tailHeight) *
            (this._attributes.headWidth / this.shapeHeight) :
            (this.shapeHeight - this._attributes.tailHeight) *
            (this._attributes.headWidth / this.shapeHeight);
        return {
            width: Math.max(tailWidth + headWidth, Bounds.MIN_BOUNDS_SIZE),
            height: Math.max(this._attributes.tailHeight, Bounds.MIN_BOUNDS_SIZE)
        };
    }

    get position() {
        const boundsX = this._attributes.isDouble ?
            0 :
            -(this.shapeWidth - this.dimensions.width) / 2;
        return {
            x: boundsX,
            y: 0
        };
    }
}

module.exports = ArrowBounds;
