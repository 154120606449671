const has = require('lodash/has');
const get = require('lodash/get');
const isNil = require('lodash/isNil');
const ShapeStyle = require('../Styles/ShapeStyle');
const Fill = require('../Fill');
const Stroke = require('../Stroke/Stroke');

module.exports = {
    setJSONAssignedShapeStype(json) {
        if (has(json, 'assignedStyles.shape')) {
            json.assignedShapeStyle = ShapeStyle.fromJSON(get(json, 'assignedStyles.shape'));
        }
    },

    setJSONAssignedStrokeStype(json) {
        if (has(json, 'assignedStyles.stroke')) {
            json.assignedStrokeStyle = Stroke.fromJSON(get(json, 'assignedStyles.stroke'));
        }
    },

    setShapeMiscValues(json, shape) {
        if (json.dynamicProperties) {
            shape._dynamicProperties = json.dynamicProperties;
        }
        if (json.fill) {
            shape.fill = Fill.normalizeAlpha(json.fill);
        }
        if (json.bullets) {
            shape._textBody.bullets = json.bullets;
        }
        if (json.stroke) {
            shape.stroke = Stroke.fromJSON(json.stroke);
        }
        if (!isNil(json.sendToBack)) {
            shape.sendToBack = json.sendToBack;
        }
    }

};
