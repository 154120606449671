const headerAndFooterSettingsMapToPlaceholders = {
    DATETIME: 'dateTime',
    FOOTER: 'footer',
    HEADER: 'header',
    SLIDE_NUMBER: 'slideNumber'
};

const headerAndFooterPlaceholderTypes = Object
    .keys(headerAndFooterSettingsMapToPlaceholders);

module.exports = (placeholderType, headerAndFooterShapesVisibility) => {
    if (
        headerAndFooterPlaceholderTypes.includes(placeholderType) &&
        headerAndFooterShapesVisibility[
            headerAndFooterSettingsMapToPlaceholders[placeholderType]
        ] === false
    ) {
        return false;
    }
    return true;
};
