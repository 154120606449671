import CommandTypes from '../types';

const CommandCategories = {
    UPDATE_CANVAS_COMMAND: 'UPDATE_CANVAS_COMMAND',
    UPDATE_SELECTED_COMMAND: 'UPDATE_SELECTED_COMMAND',
    UPDATE_SHAPES_COMMAND: 'UPDATE_SHAPES_COMMAND',
    CREATE_OR_REPLACE_SHAPE_COMMAND: 'CREATE_OR_REPLACE_SHAPE_COMMAND'
};

const commandsByCategory = {
    [CommandCategories.UPDATE_CANVAS_COMMAND]: [
        CommandTypes.APPLY_LAYOUT_ON_PAGE,
        CommandTypes.CHANGE_LAYOUT_NAME,
        CommandTypes.LAYOUT_RESET,
        CommandTypes.UPDATE_PAGE_SIZE,
        CommandTypes.UPDATE_PAGE_BACKGROUND,
        CommandTypes.TOGGLE_EDIT_MODE,
        CommandTypes.UPDATE_DYNAMIC_VALUES,
        CommandTypes.EXIT_CONTEXTUAL_SELECTION,
        CommandTypes.UPDATE_SELECTION,
        CommandTypes.UPDATE_SELECTION_AND_GROUP,
        CommandTypes.DELETE_ALL_SHAPES,
        CommandTypes.LOCK_BACKGROUND_SHAPES,
        CommandTypes.UPDATE_COLOR_PRESETS,
        CommandTypes.UPDATE_COLOR_PALETTE,
        CommandTypes.CHANGE_SHAPE_INDEX,
        CommandTypes.PASTE_CLIPBOARD_CONTENT
    ],
    [CommandCategories.UPDATE_SELECTED_COMMAND]: [
        CommandTypes.ADD_TABLE_LINE,
        CommandTypes.REMOVE_TABLE_LINE,
        CommandTypes.MERGE_SELECTED_CELLS,
        CommandTypes.SPLIT_SELECTED_CELLS,
        CommandTypes.CLEAR_SELECTED_CELLS,
        CommandTypes.FLIP_SELECTED_SHAPES,
        CommandTypes.DISTRIBUTE_SELECTED_SHAPES,
        CommandTypes.ALIGN_SELECTED_SHAPES,
        CommandTypes.UPDATE_PROPERTIES_ON_SELECTED_SHAPES,
        CommandTypes.UPDATE_PROPERTIES_FROM_DELTA_ON_SELECTED_SHAPES,
        CommandTypes.CONVERT_SELECTED_SHAPES_TEXT_ITEMS_LEVEL,
        CommandTypes.DELETE_SELECTED_SHAPES,
        CommandTypes.GROUP_SELECTED_SHAPES,
        CommandTypes.UNGROUP_SELECTED_SHAPES,
        CommandTypes.COPY_SELECTED_SHAPES,
        CommandTypes.SET_CLIPBOARD,
        CommandTypes.CUT_SELECTED_SHAPES,
        CommandTypes.UPDATE_CELL_CONTENT_TEXTBODY
    ],
    [CommandCategories.UPDATE_SHAPES_COMMAND]: [
        CommandTypes.TOGGLE_SHAPE_LOCK,
        CommandTypes.TOGGLE_SHAPE_VISIBILITY,
        CommandTypes.UPDATE_SHAPES_PROPERTIES,
        CommandTypes.SELECT_CELLS
    ],
    [CommandCategories.CREATE_OR_REPLACE_SHAPE_COMMAND]: [
        CommandTypes.CREATE_SHAPES,
        CommandTypes.DUPLICATE_SHAPES,
        CommandTypes.UPDATE_SHAPES
    ]
};

const getCommandCategory = command => {
    if (!command?.type) {
        return undefined;
    }

    return Object.keys(CommandCategories)
        .find(category => commandsByCategory[category]
            .find(commandType => commandType === command.type));
};

export {
    commandsByCategory,
    CommandCategories,
    getCommandCategory
};
