import { Map } from 'immutable';
import { isNull } from 'lodash';

const omitNullDeep = object => (
    Map.isMap(object) ?
        object.entrySeq()
            .reduce((currentObject, [key, value]) => {
                if (!isNull(value)) {
                    return currentObject.set(
                        key,
                        Map.isMap(value) ?
                            omitNullDeep(value) :
                            value
                    );
                }
                return currentObject;
            }, Map()) :
        Map()
);

export default omitNullDeep;
