const get = require('lodash/get');

module.exports = item => {
    const defaultParagraphStyle = get(item, 'textBody.DEFAULT_PARAGRAPHSTYLE', {});
    const paragraphStyles = get(item, 'textBody.paragraphStyles', []);
    const firstParagraph = get(item, 'textBody.paragraphs', []).find(({ startIndex }) => startIndex === 0);
    return (firstParagraph && paragraphStyles[firstParagraph.style]) ?
        paragraphStyles[firstParagraph.style] :
        defaultParagraphStyle;
};
