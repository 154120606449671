import { getShapePathById } from '../../../../../Canvas/CanvasStateSelectors';
import { complementParagraphStyleAgainstDefault, setComplementParagraphStyleAsParagraphStyle } from '../../../../../CanvasState/Helpers/Style/paragraph';
import { complementRunStyleAgainstDefault, setComplementRunStyleAsRunStyle } from '../../../../../CanvasState/Helpers/Style/run';
import { setComplementTextBodyStyleAsTextBodyStyle } from '../../../../../CanvasState/Helpers/Style/textBody';
import { getParagraphs } from '../../../../../CanvasState/Helpers/Text/Paragraphs/Paragraphs';
import Textbody from '../../../../../CanvasState/Helpers/Text/TextBody';
import adaptFabricParagraphsToTextBody from '../../TextBodyAdapters/textBodyAdapter';

const removeUneededStyleProperties = textBody => textBody
    .remove('paragraphStyle')
    .remove('runStyle')
    .remove('style');

const fabricParagraphsToTextBody = (paragraphs, oldTextBody, colorPalette) => {
    let textBody = adaptFabricParagraphsToTextBody(
        paragraphs,
        oldTextBody,
        colorPalette
    );

    if (!textBody) {
        return undefined;
    }

    textBody = textBody.set(
        'assignedStyles',
        oldTextBody.get('assignedStyles')
    );

    textBody = setComplementTextBodyStyleAsTextBodyStyle(textBody);
    textBody = textBody.merge(
        textBody.get('style')
    );

    textBody = setComplementParagraphStyleAsParagraphStyle(textBody);
    textBody = textBody.set(
        'paragraphStyles',
        textBody
            .get('paragraphStyles')
            .map(paragraphStyle => complementParagraphStyleAgainstDefault(textBody, paragraphStyle))
    );

    textBody = setComplementRunStyleAsRunStyle(textBody);
    textBody = textBody.set(
        'runStyles',
        textBody
            .get('runStyles')
            .map(runStyle => complementRunStyleAgainstDefault(textBody, runStyle))
    );

    // This is to make sure start and end indexes are set properly
    textBody = textBody.set(
        'paragraphs',
        getParagraphs(textBody)
            .map(paragraph => paragraph.remove('text'))
    );

    return removeUneededStyleProperties(textBody);
};

const getTextBody = (fabricShape, canvasState) => {
    const shapePath = getShapePathById(canvasState, fabricShape.id);
    const oldShape = canvasState.getIn(shapePath);
    return fabricParagraphsToTextBody(
        fabricShape.paragraphs,
        Textbody.addParagraphIfEmpty(
            oldShape.get('textBody'),
            oldShape.get('textBodyPlaceholder')
        ),
        canvasState.get('colorPalette')
    );
};

const getTextBodyPlaceholder = (fabricShape, canvasState) => {
    const oldShape = canvasState.getIn(getShapePathById(canvasState, fabricShape.id));
    return fabricParagraphsToTextBody(fabricShape.placeholderParagraphs, oldShape.get('textBodyPlaceholder'), canvasState.get('colorPalette'));
};

export {
    fabricParagraphsToTextBody,
    getTextBody,
    getTextBodyPlaceholder
};
