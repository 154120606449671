import { createSelector } from 'reselect';

export const getTextSelection = createSelector(
    ({
        build: {
            textSelection
        }
    }) => textSelection,
    textSelection => textSelection
);

export const checkTextSelected = createSelector(
    getTextSelection,
    textSelection => textSelection.editing
);
