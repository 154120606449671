const angleNameToDegree = {
    top: 0,
    right: 90,
    bottom: 180,
    left: 270
};

const getAnchors = (shape, relativeAnchor) => {
    const anchors = relativeAnchor;
    return Object.keys(anchors).reduce((compilation, anchorName) => {
        if (anchorName === 'auto') {
            compilation[anchorName] = anchors[anchorName];
        } else {
            compilation[anchorName] = makeAnchor(shape, anchors[anchorName]);
        }
        compilation[anchorName].x += shape.get('x');
        compilation[anchorName].y += shape.get('y');
        return compilation;
    }, {});
};

const makeAnchor = (shape, { x, y, expectedAngles }) => {
    const angleFromCenter = Math.atan2(y - shape.get('y'), x - shape.get('x'));
    const rotationInRadian = (shape.get('rotation') / 180) * Math.PI;
    const distanceFromCenter = Math.sqrt(((x - shape.get('x')) * (x - shape.get('x'))) + ((y - shape.get('y')) * (y - shape.get('y'))));

    return {
        x: Math.round(
            (distanceFromCenter * Math.cos(angleFromCenter + rotationInRadian)) + shape.get('x')
        ),
        y: Math.round(
            (distanceFromCenter * Math.sin(angleFromCenter + rotationInRadian)) + shape.get('y')
        ),
        expectedAngles: rotateExpectedAngles(expectedAngles, shape.get('rotation'))
    };
};

const rotateExpectedAngles = (expectedAngles, rotation) => expectedAngles.map(direction => {
    let angle = (typeof direction === 'string' ? angleNameToDegree[direction] : direction) + rotation;
    while (angle < 0) angle += 360;
    angle %= 360;
    if (angle < 45) return 'top';
    if (angle < 135) return 'right';
    if (angle < 225) return 'bottom';
    if (angle < 315) return 'left';
    return 'top';
});

export default getAnchors;
