const validateKeyExceptions = require('./validateKeyExceptions');
const defaultsDeepWithExceptions = require('./defaultsDeepWithExceptions');
const toPairsDeepWithExceptions = require('./toPairsDeepWithExceptions');
const fromDeepPairs = require('./fromDeepPairs');
const complementPairsAgainstSource = require('./complementPairsAgainstSource');

module.exports = (object, ...sources) => {
    if (object) {
        const exceptions = sources.pop();
        validateKeyExceptions(exceptions);
        const source = defaultsDeepWithExceptions(...sources.filter(Boolean).reverse(), exceptions);
        return fromDeepPairs(
            complementPairsAgainstSource(
                toPairsDeepWithExceptions(object, exceptions),
                source
            )
        );
    }
    return object;
};
