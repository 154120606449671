const Tip = require('./Tip');
const { PathBuilder } = require('../../PathBuilder');

class StealthTip extends Tip {
    get path() {
        return new PathBuilder()
            .moveTo({ x: 0, y: 0 })
            .lineTo({ x: 1, y: -0.5 })
            .lineTo({ x: 0.75, y: 0 })
            .lineTo({ x: 1, y: 0.5 })
            .close()
            .scale(this.pathLength, this.pathWidth);
    }

    get length() {
        return this.pathLength * 0.75;
    }
}

module.exports = StealthTip;
