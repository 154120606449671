import { generateCalloutPath } from '../../../../../utilities/PathGenerator/PathGenerator';
import getTailVertexes from './tailVertexes';

const getPath = shape => generateCalloutPath(
    shape.get('width'),
    shape.get('height'),
    getTailVertexes(shape)
);

export default getPath;
