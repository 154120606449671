import { combineReducers } from 'redux';
import { deck } from './deck';
import { share } from './share';
import { view } from './view';
import { accessToken } from './accessToken';
import { creator } from './creator';
import { ui } from './ui';
import { settings } from './settings';
import { feedbacks } from './feedbacks';
import comments from './comments';
import { sessionTimer } from './sessionTimer';

export default combineReducers({
    deck,
    share,
    view,
    accessToken,
    creator,
    ui,
    settings,
    comments,
    feedbacks,
    sessionTimer
});
