import { updateSelection } from '../../Helpers/helpers';

const lockBackgrounds = canvasState => {
    let updatedCanvasState = canvasState.set(
        'pageBackground',
        canvasState.get('pageBackground')
            .map(background => background.set('isLocked', true))
    );
    updatedCanvasState = updatedCanvasState.set(
        'layoutBackground',
        canvasState.get('layoutBackground')
            .map(background => background.set('isLocked', true))
    );
    return updatedCanvasState;
};

const toggleEditMode = canvasState => {
    let updatedCanvasState = canvasState.get('editMode') === 'Page' ? canvasState.set('editMode', 'Layout') : canvasState.set('editMode', 'Page');
    updatedCanvasState = updateSelection(updatedCanvasState);
    updatedCanvasState = lockBackgrounds(updatedCanvasState);
    return updatedCanvasState;
};

export {
    lockBackgrounds,
    toggleEditMode,
    updateSelection
};
