import { DEFAULT_LINE_PROPS } from '../../../../../Shape/config/Shapes';
import { generateLinePath } from '../../../../../utilities/PathGenerator/PathGenerator';
import getStroke from './stroke';

const MIN_STROKE_WIDTH = 0.5;

const tipWithDefaults = (stroke, tipType = 'head') => ({
    type: stroke.getIn([tipType, 'type']) || DEFAULT_LINE_PROPS[tipType].type,
    width: stroke.getIn([tipType, 'width']) || DEFAULT_LINE_PROPS[tipType].width,
    length: stroke.getIn([tipType, 'length']) || DEFAULT_LINE_PROPS[tipType].length
});

const capWithDefaults = stroke => stroke.get('cap') || DEFAULT_LINE_PROPS.cap;

const getPath = shape => {
    const stroke = getStroke(shape);
    return generateLinePath({
        coords: shape.get('coords').toJS(),
        lineWidth: Math.max(stroke.get('width'), MIN_STROKE_WIDTH),
        lineDash: stroke.getIn(['dash', 'type']),
        lineDashStops: stroke.getIn(['dash', 'dashStops']),
        lineCap: capWithDefaults(stroke),
        lineHead: tipWithDefaults(stroke, 'head'),
        lineTail: tipWithDefaults(stroke, 'tail')
    }).buildString();
};

export default getPath;
