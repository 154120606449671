const forEach = require('lodash/forEach');
const { Cell } = require('../../Cell');
const { removeUniqueValues } = require('../../../utilities/arrayUtility');

module.exports = Base => class extends Base {
    mergeTextBodies(cellsIdsToMerge) {
        const content = this.getTopmostLeftmostCell(cellsIdsToMerge)
            .copyCellContentWithoutText();
        let textCounter = 0;
        let paragraphCounter = 0;
        cellsIdsToMerge.forEach(cellId => {
            const paragrahsWithStyle = this.cells[cellId].content.textBody.getStyledParagraphsWithStyledRuns();
            paragrahsWithStyle.forEach((paragraph, index) => {
                if (paragraph.textRuns.length !== 0) {
                    content.textBody.addParagraph();
                    paragraph.textRuns.forEach(run => {
                        content.textBody.addTextWithStyle(run.text, run.style, run.startIndex + textCounter);
                    });
                    content.textBody.setParagraphStyleProperties(
                        paragraph.style,
                        index + paragraphCounter,
                        index + paragraphCounter
                    );
                }
            });
            textCounter += this.cells[cellId].content.textBody.getText().length + 1;
            paragraphCounter += this.cells[cellId].content.textBody.getParagraphsCount();
        });
        return content;
    }

    mergeCells(cellIdList) {
        if (!this.checkCanMergeCells(cellIdList)) return;
        this.removeCommonBorders(cellIdList);
        const cellRowStart = cellIdList.reduce((start, cellId) => Math.min(
            start,
            this.cells[cellId].row
        ), this.rowCount + 1);
        const cellRowEnd = cellIdList.reduce((end, cellId) => Math.max(
            end,
            this.cells[cellId].row + this.cells[cellId].rowSpan
        ), 0);
        const cellColumnStart = cellIdList.reduce((start, cellId) => Math.min(
            start,
            this.cells[cellId].column
        ), this.columnWidths.length + 1);
        const cellColumnEnd = cellIdList.reduce((end, cellId) => Math.max(
            end,
            this.cells[cellId].column + this.cells[cellId].columnSpan
        ), 0);
        const cellNames = cellIdList.map(cellId => this.cells[cellId].name);
        const mergedCell = new Cell(
            `${cellNames.join('_')}_merged`,
            cellRowStart,
            cellColumnStart,
            cellRowEnd - cellRowStart,
            cellColumnEnd - cellColumnStart,
            this
        );
        this.cells[mergedCell.id] = mergedCell;
        mergedCell.contents = [this.mergeTextBodies(cellIdList)];
        cellIdList.forEach(cellIdToRemove => {
            delete this.cells[cellIdToRemove];
        });
        this.reduceRows();
        this.reduceColumns();
    }

    removeCommonBorders(cellIds) {
        const borders = this.getCellListBorders(cellIds);
        removeUniqueValues(borders)
            .forEach(borderId => {
                delete this.borderSegments[borderId];
            });
    }

    checkCanMergeCells(cellIdList) {
        return this.checkRows(cellIdList) && this.checkColumns(cellIdList);
    }

    checkColumns(cellIdList) {
        const cellRowStarts = {};
        const cellRowEnds = {};
        let cell;
        forEach(cellIdList, cellId => {
            cell = this.cells[cellId];
            for (let index = 0; index < cell.columnSpan; index++) {
                const currentMin = cellRowStarts[cell.column + index] !== undefined ?
                    cellRowStarts[cell.column + index] : this.rowCount;
                cellRowStarts[cell.column + index] = Math.min(
                    currentMin,
                    cell.row
                );
                cellRowEnds[cell.column + index] = Math.max(
                    cellRowEnds[cell.column] || 0,
                    cell.row + cell.rowSpan
                );
            }
        });
        return new Set(Object.values(cellRowStarts)).size === 1 &&
            new Set(Object.values(cellRowEnds)).size === 1;
    }

    checkRows(cellIdList) {
        const cellColumnStarts = {};
        const cellColumnEnds = {};
        let cell;
        forEach(cellIdList, cellId => {
            cell = this.cells[cellId];
            for (let index = 0; index < cell.rowSpan; index++) {
                const currentMin = cellColumnStarts[cell.row + index] !== undefined ?
                    cellColumnStarts[cell.row + index] : this.columnWidths.length;
                cellColumnStarts[cell.row + index] = Math.min(
                    currentMin,
                    cell.column
                );
                cellColumnEnds[cell.row + index] = Math.max(
                    cellColumnEnds[cell.row] || 0,
                    cell.column + cell.columnSpan
                );
            }
        });
        return new Set(Object.values(cellColumnStarts)).size === 1 &&
            new Set(Object.values(cellColumnEnds)).size === 1;
    }

    reduceRows() {
        let i = 0;
        while (i + 1 < this.cellGrid.length) {
            if (this.doCellVectorsMatch(this.cellGrid[i], this.cellGrid[i + 1])) {
                this.removeGridRowAt(i + 1, 1);
                this.reduceRowSpan(this.cellGrid[i]);
                this.rowHeights[i] += this.rowHeights[i + 1];
                this.definedRowHeights[i] += this.definedRowHeights[i + 1];
                this.rowHeights.splice(i + 1, 1);
                this.definedRowHeights.splice(i + 1, 1);
            } else {
                i += 1;
            }
        }
    }

    reduceColumns() {
        let j = 0;
        while (j + 1 < this.cellGrid[0].length) {
            const currentColumn = this.getCellGridColumn(j);
            const nextColumn = this.getCellGridColumn(j + 1);
            if (this.doCellVectorsMatch(currentColumn, nextColumn)) {
                this.removeGridColumnAt(j + 1);
                this.reduceColumnSpan(currentColumn);
                this.columnWidths[j] += this.columnWidths[j + 1];
                this.columnWidths.splice(j + 1, 1);
            } else {
                j++;
            }
        }
    }
    // eslint-disable-next-line
    doCellVectorsMatch(cellVector1, cellVector2) {
        if (cellVector1.length !== cellVector2.length) return false;
        for (let i = 0; i < cellVector1.length; i++) {
            if (cellVector1[i] !== cellVector2[i]) return false;
        }
        return true;
    }
};
