const { fabric } = require('fabric');
const {
    defaultModificationHandlerAttributes
} = require('./config/defaultModificationHandlerAttributes.config');
const { rotatePoint } = require('../../../utilities/geometry');

const GenericModificationHandler = fabric.util.createClass(fabric.Circle, {
    type: 'modificationHandler',
    excludeFromExport: true,

    initialize(target, positionHandler, limitsHandler, updateHandler, attributes = {}) {
        const fullAttributes = {
            ...defaultModificationHandlerAttributes,
            ...attributes
        };

        this.callSuper('initialize', fullAttributes);
        this.target = target;
        this.lockHorizontal = fullAttributes.lockHorizontal;
        this.lockVertical = fullAttributes.lockVertical;
        this.applyRotation = fullAttributes.applyRotation;
        this.refreshText = fullAttributes.refreshText;
        this.getPosition = positionHandler;
        this.getLimits = limitsHandler;
        this.updateShape = updateHandler;
        this.updatePosition();

        this.onMouseMove = this.onMouseMove.bind(this);
        this.onMouseUp = this.onMouseUp.bind(this);
        this.onMouseDown = this.onMouseDown.bind(this);
        this.on('mousedown', this.onMouseDown);
    },

    onMouseDown() {
        this.target.canvas.selection = false;
        document.addEventListener('mousemove', this.onMouseMove);
        document.addEventListener('mouseup', this.onMouseUp);

        if (this.target.group) {
            this.target.group.objectCaching = false;
        }
    },

    onMouseMove(e) {
        const boundedPosition = this.getBoundedPosition(e);
        this.updatePosition(boundedPosition);
        this.updateShape(this.getUnrotatedPosition(), this);
        this.target.updateModificationHandlers();
        this.target.canvas.renderAll();
    },

    onMouseUp() {
        this.target.canvas.selection = true;
        this.target.canvas.on('selection:cleared', this.target.canvas.handleSelectionClear);
        document.removeEventListener('mousemove', this.onMouseMove);
        document.removeEventListener('mouseup', this.onMouseUp);
        this.target.canvas._groupSelector = null;
        this.target.canvas.setActiveObject(this.target);
        this.target.canvas.handleObjectModified(this.target);
        if (this.refreshText) {
            this.target.refreshText();
        }

        if (this.target.group) {
            this.target.group.objectCaching = true;
        }
    },

    updatePosition(position) {
        const newPosition = position || this.getPosition();
        const rotatedPosition = this.applyRotation ?
            rotatePoint(
                newPosition,
                this.target.angle || 0,
                this.target.getAbsolutePosition()
            ) :
            newPosition;
        this.left = rotatedPosition.x;
        this.top = rotatedPosition.y;
        this.setCoords();
    },

    getPointerPosition(e) {
        return this.applyRotation ?
            rotatePoint(
                this.canvas.getPointer(e),
                -this.target.angle || 0,
                this.target.getAbsolutePosition()
            ) :
            this.canvas.getPointer(e);
    },

    getBoundedPosition(e) {
        const unrotatedPosition = this.getUnrotatedPosition();
        let { x } = unrotatedPosition;
        let { y } = unrotatedPosition;
        const pointerPosition = this.getPointerPosition(e);
        const limits = this.getLimits();
        if (!this.lockHorizontal) {
            if (pointerPosition.x > limits.max) {
                x = limits.max;
            } else if (pointerPosition.x < limits.min) {
                x = limits.min;
            } else {
                x = pointerPosition.x;
            }
        }
        if (!this.lockVertical) {
            if (pointerPosition.y > limits.max) {
                y = limits.max;
            } else if (pointerPosition.y < limits.min) {
                y = limits.min;
            } else {
                y = pointerPosition.y;
            }
        }
        return { x, y };
    },

    getUnrotatedPosition() {
        return this.applyRotation ?
            rotatePoint(
                { x: this.left, y: this.top },
                -this.target.angle || 0,
                this.target.getAbsolutePosition()
            ) :
            { x: this.left, y: this.top };
    }
});

GenericModificationHandler.fromObject = (object, callback) => {
    const modificationHandler = new GenericModificationHandler(object);
    callback(modificationHandler);
    return modificationHandler;
};

module.exports = GenericModificationHandler;
