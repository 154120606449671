const CommandMixin = require('./Command');

const commandChar = 'z';
const commandRegexp = new RegExp(`^(${commandChar})$$`, 'i');

class CloseCommand extends CommandMixin {
    constructor(args) {
        super({ commandChar: 'z', ...args });
    }

    static fromString(string) {
        const commandData = string.match(commandRegexp);
        if (!commandData) {
            throw new TypeError('invalid close command');
        }
        const relative = commandData[1] === 'z';
        return new this({ relative });
    }

    static fromArray(array) {
        return this.fromString(array.join(' '));
    }

    toString() {
        return this.toArray().join(' ');
    }

    toArray() {
        return [this.commandChar];
    }
}

module.exports = CloseCommand;
