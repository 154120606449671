const forEach = require('lodash/forEach');

const defaultFabricAttributesConfig = require('../../Table/config/defaultFabricAttributes.config.js');
const Border = require('../../Table/Border');
const Cell = require('../../Table/Cell/Cell');

module.exports = Base => class extends Base {
    generateFabricSerialized() {
        return {
            ...defaultFabricAttributesConfig,
            ...super.toFabric(),
            borders: Object.values(this.borderSegments).map(border => border.toFabric()),
            cells: Object.values(this.cells).map(cell => cell.toFabric()),
            rowHeights: this.rowHeights,
            columnWidths: this.columnWidths,
            definedRowHeights: this.definedRowHeights,
            height: this.height,
            left: this.x,
            top: this.y,
            width: this.width,
            rows: this.rowCount,
            columns: this.columnCount,
            hasBandedColumns: this.hasBandedColumns,
            hasBandedRows: this.hasBandedRows,
            hasHeaderColumn: this.hasHeaderColumn,
            hasHeaderRow: this.hasHeaderRow,
            hasTotalColumn: this.hasTotalColumn,
            hasTotalRow: this.hasTotalRow,
            mainAxis: this.mainAxis,
            style: this.style,
            type: 'table'
        };
    }

    toFabric({ fabric } = {}) {
        this.forceAParagraphInEmptyCells();

        const previousHeight = this.height;

        return new Promise(resolve => {
            fabric.Table.fromObject(this.generateFabricSerialized(), fabricTable => {
                this.rowHeights = fabricTable.getTextRenderRowHeights();
                this.y += (this.height - previousHeight) / 2;
                const table = {
                    ...fabricTable,
                    borders: Object.values(fabricTable.borderSegments).map(border => ({
                        ...border
                    })),
                    cells: Object.values(fabricTable.cells).map(cell => ({
                        ...cell,
                        contents: cell._objects[0].contents
                    })),
                    rowHeights: this.rowHeights,
                    columnWidths: this.columnWidths,
                    definedRowHeights: this.definedRowHeights,
                    height: this.height,
                    left: this.x,
                    top: this.y,
                    width: this.width,
                    rows: this.rowCount,
                    columns: this.columnCount,
                    hasBandedColumns: this.hasBandedColumns,
                    hasBandedRows: this.hasBandedRows,
                    hasHeaderColumn: this.hasHeaderColumn,
                    hasHeaderRow: this.hasHeaderRow,
                    hasTotalColumn: this.hasTotalColumn,
                    hasTotalRow: this.hasTotalRow,
                    mainAxis: this.mainAxis,
                    style: this.style,
                    type: 'table'
                };
                return resolve(table);
            });
        });
    }

    static fromFabric(serializedFabricTable, DOMParser, canvas, originalTableJSON) {
        const table = new this(
            serializedFabricTable.name,
            serializedFabricTable.rows,
            serializedFabricTable.columns,
            serializedFabricTable.left,
            serializedFabricTable.top,
            {
                id: serializedFabricTable.id,
                inlayout: serializedFabricTable.inLayout,
                isLocked: serializedFabricTable.isLocked,
                isImported: serializedFabricTable.isImported,
                isHidden: serializedFabricTable.isHidden,
                placeholderType: serializedFabricTable.placeholderType,
                placeholderSequence: serializedFabricTable.placeholderSequence,
                hasBandedColumns: serializedFabricTable.hasBandedColumns,
                hasBandedRows: serializedFabricTable.hasBandedRows,
                hasHeaderColumn: serializedFabricTable.hasHeaderColumn,
                hasHeaderRow: serializedFabricTable.hasHeaderRow,
                hasTotalColumn: serializedFabricTable.hasTotalColumn,
                hasTotalRow: serializedFabricTable.hasTotalRow,
                mainAxis: serializedFabricTable.mainAxis,
                definedRowHeights: serializedFabricTable.definedRowHeights
            },
            false
        );
        const cells = {};
        const borders = {};
        forEach(serializedFabricTable.cells, cell => {
            const originalCellJSON = (originalTableJSON.cells || [])
                .find(cellJSON => cellJSON.id === cell.id);
            cells[cell.id] = Cell.fromFabric(cell, DOMParser, canvas, originalCellJSON);
            cells[cell.id].table = table;
        });
        forEach(serializedFabricTable.borders, border => {
            const originalBorderJSON = (originalTableJSON.borders || [])
                .find(borderJSON => borderJSON.id === border.id);
            borders[border.id] = Border.fromFabric(border, originalBorderJSON);
            borders[border.id].table = table;
        });
        table.cells = cells;
        table.borderSegments = borders;
        table.rowHeights = serializedFabricTable.rowHeights;
        table.columnWidths = serializedFabricTable.columnWidths;
        table.id = serializedFabricTable.id;
        table.style = serializedFabricTable.style;
        return table;
    }

    fromShapeToFabric({ fabric } = {}) {
        return this.toFabric({ fabric });
    }
};
