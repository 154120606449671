const memoize = require('lodash/memoize');
const { dashArrayGenerator } = require('../../fabric-adapter/utilities/dash');
const { PathBuilder } = require('../PathBuilder');
const LineTipGenerator = require('./LineTipGenerator');
const geometry = require('../geometry');

const adjustLineCoordsForTips = (coords, head, tail) => {
    const adjustedCoords = getAdjustedCoordsForHead(coords, head);
    return getAdjustedCoordsForTail(adjustedCoords, tail);
};

const getAdjustedCoordsForHead = (coords, head) => {
    const adjustedCoords = [...coords];
    const angle = geometry.angleBetweenPoints(coords[1], coords[0]);
    adjustedCoords[0] = adjustCoordForAngle(
        adjustedCoords[0],
        angle,
        head.length
    );
    return adjustedCoords;
};

const getAdjustedCoordsForTail = (coords, tail) => {
    const adjustedCoords = [...coords];
    const angle = geometry.angleBetweenPoints(
        coords[coords.length - 2],
        coords[coords.length - 1]
    );
    adjustedCoords[coords.length - 1] = adjustCoordForAngle(
        adjustedCoords[coords.length - 1],
        angle,
        tail.length
    );
    return adjustedCoords;
};

const adjustCoordForAngle = (coord, angle, tipLength) => ({
    x: coord.x - (tipLength * Math.cos(geometry.toRadians(angle))),
    y: coord.y - (tipLength * Math.sin(geometry.toRadians(angle)))
});

const generateLineTipPath = (lineWidth, tipData, segment, isTail = false) => {
    const TipType = LineTipGenerator[tipData.type];
    if (!TipType) {
        throw new Error('Invalid tip type.');
    }
    if (tipData.type === 'none') {
        return new TipType().toObject();
    }
    const angle = geometry.angleBetweenPoints(segment[0], segment[1]);

    return new TipType(lineWidth, tipData.width, tipData.length)
        .toObject(
            isTail ? segment[1] : segment[0],
            angle,
            isTail
        );
};

const generateDashPath = memoize(({ lineWidth, lineCap, dashLength }) => {
    if (lineCap === 'round') {
        return new PathBuilder()
            .moveTo({ x: lineWidth / 2, y: lineWidth / 2 })
            .arcTo({
                rx: lineWidth / 2,
                ry: lineWidth / 2,
                xAxisRotation: 0,
                largeArcFlag: 0,
                sweepFlag: 1,
                x: lineWidth / 2,
                y: -lineWidth / 2
            })
            .lineTo({ x: dashLength - (lineWidth / 2), y: -lineWidth / 2 })
            .arcTo({
                rx: lineWidth / 2,
                ry: lineWidth / 2,
                xAxisRotation: 0,
                largeArcFlag: 0,
                sweepFlag: 1,
                x: dashLength - (lineWidth / 2),
                y: lineWidth / 2
            })
            .close();
    }

    return new PathBuilder()
        .moveTo({ x: 0, y: lineWidth / 2 })
        .lineTo({ x: 0, y: -lineWidth / 2 })
        .lineTo({ x: dashLength, y: -lineWidth / 2 })
        .lineTo({ x: dashLength, y: lineWidth / 2 })
        .close();
}, JSON.stringify); // lodash.memoize use args equality so we stringify them

const generateLinePath = ({
    coords,
    lineWidth,
    lineDash,
    lineCap,
    lineDashStops,
    lineHead = { type: 'none', width: 'medium', length: 'medium' },
    lineTail = { type: 'none', width: 'medium', length: 'medium' }
}) => {
    // Elsewhere in the app we use an empty array to represent a solid line
    // But by using Infinity here we can handle solid lines exactly as
    // dashed ones
    const dashPattern = lineDash === 'solid' ?
        [Infinity] :
        dashArrayGenerator(lineDash, lineWidth, lineDashStops);
    const scaledDashPattern = dashPattern.map((length, index) => ({
        length,
        isSpace: index % 2 !== 0
    }));
    const hasBothTip = lineHead && lineHead.type !== 'none' && lineTail && lineTail.type !== 'none';
    const headTip = generateLineTipPath(
        lineWidth,
        lineHead,
        [coords[0], coords[1]],
        false
    );
    const tailTip = generateLineTipPath(
        lineWidth,
        lineTail,
        [coords[coords.length - 2], coords[coords.length - 1]],
        true
    );
    const adjustedCoords = adjustLineCoordsForTips(coords, headTip, tailTip);
    let isLastDashSpace = false;
    let patternIndex = 0;
    let segmentLengthLeftToDraw = 0;

    const dashedPath = adjustedCoords.slice(1).reduce(
        (path, nextCoord, coordIndex) => {
            // as we reduce on a sliced by one array the previous element in the
            // original array is the reduce index
            const previousCoord = adjustedCoords[coordIndex];
            const angleWithLastPoint = geometry.angleBetweenPoints(
                previousCoord,
                nextCoord,
                true
            );
            const distanceWithLastPoint = geometry.distanceBetweenPoints(previousCoord, nextCoord);
            let dashedSegmentPath = new PathBuilder();
            let movedDistance = 0;
            let remainingDistance = distanceWithLastPoint;

            // Handles corner joints so they line up properly
            // and form nice full corners. Does not consider
            // straight lines since they have no corners.
            if (adjustedCoords.length !== 2 && coordIndex === 0) {
                remainingDistance += lineWidth / 2;
            } else if (adjustedCoords.length !== 2 && coordIndex === adjustedCoords.length - 2) {
                remainingDistance += lineWidth / 2;
                movedDistance -= lineWidth / 2;
            } else if (adjustedCoords.length !== 2) {
                remainingDistance += lineWidth;
                movedDistance -= lineWidth / 2;
            }

            // Here we greedily generate a dash path
            while ((lineCap !== 'round' && remainingDistance > 0) || remainingDistance > lineWidth) {
                // Loop over pattern array indefinitely
                const patternStep = scaledDashPattern[
                    patternIndex % scaledDashPattern.length
                ];
                const segmentLength = Math.max(
                    segmentLengthLeftToDraw || patternStep.length,
                    lineWidth
                );
                // Greedily take as much space as possible
                const currentLength = Math.min(remainingDistance, segmentLength);
                const positionOffset = {
                    x: (previousCoord.x + movedDistance),
                    y: previousCoord.y
                };
                if (!patternStep.isSpace) {
                    const dash = generateDashPath({
                        lineWidth,
                        lineCap,
                        dashLength: currentLength
                    }).translate(positionOffset);
                    dashedSegmentPath = dashedSegmentPath
                        .addPath(dash);
                }
                if (segmentLength > remainingDistance) {
                    segmentLengthLeftToDraw = segmentLength - remainingDistance;
                    remainingDistance = 0;
                } else {
                    segmentLengthLeftToDraw = 0;
                    patternIndex += 1;
                    remainingDistance -= currentLength;
                    movedDistance += currentLength;
                }
                isLastDashSpace = patternStep.isSpace;
            }
            return path.addPath(
                dashedSegmentPath.rotate(angleWithLastPoint, previousCoord.x, previousCoord.y)
            );
        },
        new PathBuilder()
    );
    let dashWithTip = dashedPath
        .addPath(headTip.path)
        .addPath(tailTip.path);
    if (!hasBothTip) {
        if (!Number.isNaN(headTip.pathLength)) {
            dashWithTip = dashWithTip.translate({ x: 0, y: -(headTip.pathLength / 2) });
        }
        if (!Number.isNaN(tailTip.pathLength)) {
            dashWithTip = dashWithTip.translate({ x: 0, y: (tailTip.pathLength / 2) });
        }
    }
    return adjustPathForCentering(dashWithTip, coords, isLastDashSpace, headTip, tailTip);
};

/**
 * These conditions prevent fabric from recentering the path because there
 * is empty space at the end of the line or when the tips need to be centered
 * on the line's end point.
 */
const adjustPathForCentering = (path, coords, isLastDashSpace, headTip, tailTip) => {
    let adjustedPath = path;
    if (isLastDashSpace) {
        adjustedPath = adjustedPath.addPath(new PathBuilder().moveTo(coords[coords.length - 1]));
    }
    if (headTip.recenterPath) {
        adjustedPath = adjustedPath.addPath(
            new PathBuilder().moveTo({
                x: coords[coords.length - 1].x + (headTip.pathLength / 2),
                y: coords[coords.length - 1].y
            })
        );
    }
    if (tailTip.recenterPath) {
        adjustedPath = adjustedPath.addPath(
            new PathBuilder().moveTo({
                x: coords[0].x - (tailTip.pathLength / 2),
                y: coords[0].y
            })
        );
    }
    return adjustedPath;
};

const generatePathFromPoints = points => {
    if (!points || !points) {
        return '';
    }

    return points.slice(1).reduce(
        (path, { x, y }) => path.lineTo({ x, y }),
        new PathBuilder().moveTo({ x: points[0].x, y: points[0].y })
    )
        .close()
        .buildString();
};

const generateCalloutPath = (width, height, tailVertexes) => {
    const halfWidth = width / 2;
    const halfHeight = height / 2;

    const bubblePath = generateEllipsePath(halfWidth, halfHeight);

    const tailPath = new PathBuilder()
        .moveTo({ x: tailVertexes.point.x, y: tailVertexes.point.y })
        .lineTo({ x: tailVertexes.left.x, y: tailVertexes.left.y })
        .lineTo({ x: tailVertexes.right.x, y: tailVertexes.right.y });

    return bubblePath.addPath(tailPath).buildString();
};

const generateEllipsePath = (rx, ry) => new PathBuilder()
    .moveTo({ x: rx, y: 0 })
    .arcTo({
        rx, ry, xAxisRotation: 0, largeArcFlag: 0, sweepFlag: 1, x: rx, y: 2 * ry
    })
    .arcTo({
        rx, ry, xAxisRotation: 0, largeArcFlag: 0, sweepFlag: 1, x: rx, y: 0
    });

module.exports = {
    generateLinePath,
    generatePathFromPoints,
    generateCalloutPath,
    generateEllipsePath
};
