import getAssignedStyles from './border/assignedStyles';
import getStroke from './border/stroke';

const getBorders = (table, canvasState) => table.borders
    .map(border => getBorder(table, border, canvasState));

const getBorder = (table, border, canvasState) => ({
    id: border.id,
    name: border.name,
    row: border.row,
    column: border.column,
    side: border.side,
    assignedStyles: getAssignedStyles(table, border, canvasState),
    stroke: getStroke(table, border, canvasState)
});

export default getBorders;
