import * as types from '#Constants/ActionTypes';

const currentFile = (state = {}, action) => {
    switch (action.type) {
        case types.SET_CURRENT_FILE:
            return action.currentFile || {};
        case types.FETCH_DECK_SUCCESS:
            return action.keepCurrentFile ?
                state : { ...action.deck, pageToDisplayNumber: state.pageToDisplayNumber } || {};
        case types.SET_USER:
            return action.currentDeck || {};
        case types.UPDATE_CURRENT_FILE:
            return { ...state, ...action.properties };
        default:
            return state;
    }
};

export { currentFile };
