import * as types from '#Constants/ActionTypes';

const view = (state = null, action) => {
    switch (action.type) {
        case types.INIT_VIEW:
            return { ...action.view };
        default:
            return state;
    }
};

export { view };
