import defaultPropertyAdapters from '../default';
import getAssignedStyles from './content/assignedStyles';
import getFill from './content/fill';
import getStroke from './content/stroke';
import { getTextBody, getTextBodyPlaceholder } from './content/textBody';

const getContents = (table, cell, canvasState) => cell.contents
    .map(content => getContent(table, cell, content, canvasState));

const getContent = (table, cell, content, canvasState) => ({
    id: defaultPropertyAdapters.id(content),
    name: defaultPropertyAdapters.name(content),
    type: 'Textbox',
    flipX: defaultPropertyAdapters.flipX(content),
    flipY: defaultPropertyAdapters.flipY(content),
    x: defaultPropertyAdapters.left(content),
    y: defaultPropertyAdapters.top(content),
    width: defaultPropertyAdapters.width(content),
    height: defaultPropertyAdapters.height(content),
    rotation: defaultPropertyAdapters.angle(content),
    inLayout: defaultPropertyAdapters.inLayout(content),
    scaleX: defaultPropertyAdapters.scaleX(content),
    scaleY: defaultPropertyAdapters.scaleY(content),
    isImported: defaultPropertyAdapters.isImported(content),
    isLocked: defaultPropertyAdapters.isLocked(content),
    isBackground: defaultPropertyAdapters.isBackground(content),
    skewX: defaultPropertyAdapters.skewX(content),
    skewY: defaultPropertyAdapters.skewY(content),
    opacity: defaultPropertyAdapters.opacity(content),
    dynamicProperties: defaultPropertyAdapters.dynamicProperties(content),
    lockAspectRatio: defaultPropertyAdapters.lockUniScaling(content),
    stroke: getStroke(table, cell, content, canvasState),
    fill: getFill(table, cell, content, canvasState),
    textBody: getTextBody(table, cell, content, canvasState),
    textBodyPlaceholder: getTextBodyPlaceholder(table, cell, content, canvasState),
    assignedStyles: getAssignedStyles(table, cell, content, canvasState),
    placeholderSequence: defaultPropertyAdapters.placeholderSequence(content),
    placeholderSourceId: defaultPropertyAdapters.placeholderSourceId(content),
    placeholderType: defaultPropertyAdapters.placeholderType(content)
});

export default getContents;
