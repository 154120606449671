import { fromJS, Map } from 'immutable';

const getBulletStyleFromTypefacePreset = bulletTypefacePreset => Map({
    font: `${bulletTypefacePreset.get('fontFamily')} ${bulletTypefacePreset.get('fontWeight')}`.trim(),
    color: bulletTypefacePreset.getIn('fill', 'color'),
    size: bulletTypefacePreset.get('fontSize')
});

export default fromJS({
    toItemUpdate(value, update, definition) {
        let newUpdate = update;
        let bullet = Map();
        switch (value) {
            case 'bullet':
                bullet = bullet.set('type', 'unordered');
                bullet = bullet.set('text', definition.get('bulletText'));
                bullet = bullet.set('style', getBulletStyleFromTypefacePreset(definition.get('bulletTypefacePreset')));
                newUpdate = newUpdate.set('indent', definition.get('bulletIndent'));
                break;
            case 'numeric':
                bullet = bullet.set('type', 'ordered/numeric');
                bullet = bullet.set(
                    'style',
                    getBulletStyleFromTypefacePreset(definition.get('numericTypefacePreset'))
                        .merge(Map({
                            textAfter: definition.get('numericTextAfter'),
                            textBefore: definition.get('numericTextBefore')
                        }))
                );
                newUpdate = newUpdate.set('indent', definition.get('numericIndent'));
                break;
            case 'roman':
                bullet = bullet.set('type', 'ordered/roman');
                bullet = bullet.set(
                    'style',
                    getBulletStyleFromTypefacePreset(definition.get('numericTypefacePreset'))
                        .merge(Map({
                            textAfter: definition.get('numericTextAfter'),
                            textBefore: definition.get('numericTextBefore')
                        }))
                );
                newUpdate = newUpdate.set('indent', definition.get('numericIndent'));
                break;
            case 'none':
                bullet = bullet.set('type', 'none');
                newUpdate = newUpdate.set('indent', 0);
                newUpdate = newUpdate.set('spacing-left', 0);
                newUpdate = newUpdate.set('level', 0);
                break;
            default:
                throw new Error(`${value} default bullet type is not supported`);
        }
        return newUpdate.set('bullet', bullet);
    }
});
