import { fromJS } from 'immutable';
import defaultFromFabricToCanvasState from './default';

const fromFabricToCanvasState = (fabricShape, canvasState) => fromJS({
    ...defaultFromFabricToCanvasState(fabricShape, canvasState),
    tipWidth: fabricShape.tipWidth,
    isFull: fabricShape.isFull
});

export default fromFabricToCanvasState;
