function numberOrDefault(candidate, defaultValue) {
    return typeof candidate === 'number' ?
        candidate :
        defaultValue;
}
function booleanOrDefault(candidate, defaultValue) {
    return typeof candidate === 'boolean' ?
        candidate :
        defaultValue;
}

function stringOrDefault(candidate, defaultValue) {
    return typeof candidate === 'string' ?
        candidate :
        defaultValue;
}

function definedOrDefault(candidate, defaultValue) {
    return candidate !== undefined ?
        candidate :
        defaultValue;
}

module.exports = {
    numberOrDefault,
    booleanOrDefault,
    stringOrDefault,
    definedOrDefault
};
