const Tip = require('./Tip');
const { PathBuilder } = require('../../PathBuilder');

class BarTip extends Tip {
    get path() {
        return new PathBuilder()
            .moveTo({ x: 0, y: -0.5 })
            .lineTo({ x: 0.25, y: -0.5 })
            .lineTo({ x: 0.25, y: 0.5 })
            .lineTo({ x: 0, y: 0.5 })
            .close()
            .scale(this.pathLength, this.pathWidth);
    }
}

module.exports = BarTip;
