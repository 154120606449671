import { getArrowPoints } from '../../../../../utilities/ShapeGenerators';
import getHeadWidth from './headWidth';
import isDouble from './isDouble';
import getTailHeight from './tailHeight';

const getPoints = shape => getArrowPoints(
    shape.get('width'),
    shape.get('height'),
    getTailHeight(shape),
    getHeadWidth(shape),
    isDouble(shape)
);

export default getPoints;
