import isRight from '../PropertyAdapters/triangle/isRight';
import getPath from '../PropertyAdapters/triangle/path';
import getPoints from '../PropertyAdapters/triangle/points';
import getRelativeAnchors from '../PropertyAdapters/triangle/relativeAnchors';
import getTopVertex from '../PropertyAdapters/triangle/topVertex';
import getAnchors from '../Utils/anchors';
import defaultShapeToFabricJSON from './default';

const fromShapeToFabricJSON = (shape, canvasState) => ({
    ...defaultShapeToFabricJSON(shape, canvasState),
    anchors: getAnchors(shape, getRelativeAnchors(shape)),
    isRight: isRight(shape),
    topVertex: getTopVertex(shape),
    points: getPoints(shape),
    path: getPath(shape)
});

export default fromShapeToFabricJSON;
