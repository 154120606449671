import { fabric } from 'fabric';
import { List, fromJS } from 'immutable';
import { isNil } from 'lodash';
import { FABRIC_FONT_DEFAULT_SIZE } from '../../../../fabric-adapter/constants/text';
import adaptFabricColorToColorValueDescriptor from './colorValueDescriptorAdapter';

const { subscript, superscript } = new fabric.Textbox('');

const unsetScript = ({
    size = 1
} = {}, fontSize) => fontSize / size;

const unsetScripts = (fontSize = FABRIC_FONT_DEFAULT_SIZE, ...scripts) => scripts
    .reduce((currentFontSize, script) => unsetScript(script, currentFontSize), fontSize);

const adaptFabricCharacterStyleToRunStyle = (fabricCharacterStyle = {}, colorPalette = List()) => {
    const runStyle = {};
    if (fabricCharacterStyle.fill) {
        runStyle.color = adaptFabricColorToColorValueDescriptor(
            fabricCharacterStyle.fill,
            colorPalette
        );
        runStyle.color.preset = fabricCharacterStyle.fillPreset || '';
    }
    if (!isNil(fabricCharacterStyle.hyperlink)) {
        runStyle.hyperlink = fabricCharacterStyle.hyperlink;
    }
    const scripts = [];
    if (fabricCharacterStyle.subscript) {
        scripts.push(subscript);
        runStyle.subscript = true;
    }
    if (fabricCharacterStyle.superscript) {
        scripts.push(superscript);
        runStyle.superscript = true;
    }
    if (
        fabricCharacterStyle.fontCase ||
        fabricCharacterStyle.fontFamily ||
        !isNil(fabricCharacterStyle.fontSize) ||
        fabricCharacterStyle.fontStyle ||
        fabricCharacterStyle.fontWeight ||
        scripts.length > 0
    ) {
        runStyle.font = adaptFabricFontStyleToRunFontStyle(fabricCharacterStyle, scripts);
    }
    if (!isNil(fabricCharacterStyle.overline)) {
        runStyle.overline = fabricCharacterStyle.overline;
    }
    if (!isNil(fabricCharacterStyle.underline)) {
        runStyle.underline = fabricCharacterStyle.underline;
    }
    if (!isNil(fabricCharacterStyle.linethrough)) {
        runStyle.linethrough = fabricCharacterStyle.linethrough;
    }
    if (!isNil(fabricCharacterStyle.opacity)) {
        runStyle.opacity = fabricCharacterStyle.opacity;
    }
    if (!isNil(fabricCharacterStyle.charSpacing)) {
        runStyle.characterSpacing = fabricCharacterStyle.charSpacing;
    }
    if (!isNil(fabricCharacterStyle.textTransform)) {
        runStyle.textTransform = fabricCharacterStyle.textTransform;
    }
    if (!isNil(fabricCharacterStyle.textSelection)) {
        runStyle.textSelection = fabricCharacterStyle.textSelection;
    }
    if (!isNil(fabricCharacterStyle.cursorStyle)) {
        runStyle.cursorStyle = adaptFabricCharacterStyleToRunStyle(fabricCharacterStyle.cursorStyle);
    }

    return fromJS(runStyle);
};

const adaptFabricFontStyleToRunFontStyle = (fabricCharacterStyle, scripts) => {
    const font = {};
    if (fabricCharacterStyle.fontCase) {
        font.case = fabricCharacterStyle.fontCase;
    }
    if (!isNil(fabricCharacterStyle.fontFamily)) {
        font.family = fabricCharacterStyle.fontFamily;
    }
    font.size = unsetScripts(fabricCharacterStyle.fontSize, ...scripts);
    if (fabricCharacterStyle.fontStyle) {
        font.style = fabricCharacterStyle.fontStyle;
    }
    if (fabricCharacterStyle.fontWeight) {
        font.weight = fabricCharacterStyle.fontWeight;
    }
    return font;
};

export default adaptFabricCharacterStyleToRunStyle;
