const defaultsDeep = require('lodash/defaultsDeep');
const get = require('lodash/get');
const set = require('lodash/set');
const cloneDeep = require('lodash/cloneDeep');
const validateKeyExceptions = require('./validateKeyExceptions');

module.exports = (object, ...sources) => {
    const deepExceptions = sources.pop();
    validateKeyExceptions(deepExceptions);
    return deepExceptions.reduce(
        (current, path) => {
            const exceptionValue = get(
                [object, ...sources].find(container => get(container, path) !== undefined),
                path
            );

            return exceptionValue !== undefined ?
                set(current, path, exceptionValue) :
                current;
        },
        defaultsDeep(cloneDeep(object), ...sources)
    );
};
