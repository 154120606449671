import { createSelector } from 'reselect';
import get from 'lodash/get';
import uniqBy from 'lodash/uniqBy';
import decksignCanvas from 'decksign-canvas';

import { generatePageThumbnailUrl, generatePageImageUrl } from '#Utilities/s3';

import { getDecksThumbnailsURLBase, getDecksImagesURLBase } from './configs';

const {
    api: {
        ItemStyle
    }
} = decksignCanvas;

export const getPageNumberFromStore = createSelector(({
    deck
}) => deck, deck => get(deck, 'activePageNumber'));

export const getDeckStore = createSelector(
    ({ deck }) => deck || {},
    deck => deck
);

export const getPages = createSelector(
    getDeckStore,
    deck => get(deck, 'pages', [])
);

export const getVisiblePages = createSelector(
    getPages,
    pages => pages.filter(page => page.hidden !== true)
);

export const deckIsLoading = createSelector(({
    deck
}) => get(deck, 'isFetching'), isFetching => !!isFetching);

export const deckIsPublic = createSelector(({
    deck
}) => get(deck, 'isPublic'), isPublic => !!isPublic);

export const deckIsShared = createSelector(({
    deck
}) => get(deck, 'isSharedDeck'), isShared => !!isShared);

export const getDeckHavePage = createSelector(
    getPages,
    pages => pages.length > 0
);

export const getDeckId = createSelector(
    ({
        deck
    }) => (
        (deck && deck.id) ?
            deck.id :
            null
    ),
    id => id
);

export const getDeckIdWithVersion = createSelector(
    ({
        deck
    }) => (
        (deck && deck.id && deck.version) ?
            `${deck.id}-${deck.version}` :
            null
    ),
    id => id
);

export const getDeckVersionId = createSelector(
    ({
        deck
    }) => (
        (deck && deck.version) ?
            deck.version :
            null
    ),
    id => id
);

export const getCurrentPageIndex = createSelector(
    ({
        deck: {
            pages
        },
        build: {
            activePage: {
                id: activePageId
            }
        }
    }) => pages.findIndex(page => page._id === activePageId),
    index => index
);

export const getIsDeckEmpty = createSelector(
    getDeckStore,
    ({
        pages = []
    } = {}) => pages.length === 0
);

export const getTheme = createSelector(
    getDeckStore,
    ({
        theme = {},
        originalTheme = {}
    } = {}) => ((theme && Object.keys(theme).length > 0) ?
        theme :
        (originalTheme || {})
    )
);

export const getStyleDefinitions = createSelector(
    getDeckStore,
    ({
        originalTheme = {},
        theme = {},
        applicationTheme = {}
    } = {}) => uniqBy([
        ...get(theme, 'styleDefinitions', []),
        ...get(originalTheme, 'styleDefinitions', []),
        ...get(applicationTheme, 'styleDefinitions', [])
    ], 'id')
);

export const getSelectableStyleDefinitions = createSelector(
    getDeckStore,
    ({
        originalTheme = {},
        theme = {}
    } = {}) => uniqBy([
        ...get(theme, 'styleDefinitions', []),
        ...get(originalTheme, 'styleDefinitions', [])
    ], 'id')
);

export const getThemeId = createSelector(
    getDeckStore,
    ({
        originalTheme = {},
        theme = {},
        applicationTheme = {}
    } = {}) => theme.themeId || originalTheme.themeId || applicationTheme.themeId
);

export const getDefaultBulletStyles = createSelector(
    getDeckStore,
    ({
        originalTheme = {},
        theme = {},
        applicationTheme = {}
    } = {}) => [applicationTheme, originalTheme, theme]
        .reduce((defaultBulletStyles, currentTheme) => get(
            currentTheme,
            'defaultBulletStyles',
            defaultBulletStyles
        ), '')
);

export const getObjectStyles = createSelector(
    getStyleDefinitions,
    styleDefinitions => styleDefinitions
        .filter(({ kind }) => kind === 'ObjectStyle')
);

export const getSelectableObjectStyles = createSelector(
    getSelectableStyleDefinitions,
    styleDefinitions => styleDefinitions
        .filter(({ kind }) => kind === 'ObjectStyle')
);

export const getTableBuildings = createSelector(
    getStyleDefinitions,
    styleDefinitions => styleDefinitions
        .filter(({ kind }) => kind === 'TableBuilding')
);

export const getSelectableTableBuildings = createSelector(
    getSelectableStyleDefinitions,
    styleDefinitions => styleDefinitions
        .filter(({ kind }) => kind === 'TableBuilding')
);

export const getTableStyles = createSelector(
    getStyleDefinitions,
    styleDefinitions => styleDefinitions
        .filter(({ kind }) => kind === 'TableStyle')
);

export const getSelectableTableStyles = createSelector(
    getSelectableStyleDefinitions,
    styleDefinitions => styleDefinitions
        .filter(({ kind }) => kind === 'TableStyle')
);

export const getColorsPresets = createSelector(
    getStyleDefinitions,
    styleDefinitions => styleDefinitions.filter(({ kind }) => kind === 'ColorsPreset')
);

export const getSelectableColorsPresets = createSelector(
    getSelectableStyleDefinitions,
    styleDefinitions => styleDefinitions
        .filter(({ kind }) => kind === 'ColorsPreset')
);

export const getLogo = createSelector(
    getTheme,
    ({ logo }) => logo || {}
);

export const getPagesWithImageSource = createSelector(
    getDecksThumbnailsURLBase,
    getDecksImagesURLBase,
    getPages,
    (decksThumbnailsURLBase, decksImagesUrlBase, pages) => pages.map(page => ({
        ...page,
        thumbnailSrc: page.thumbnail || generatePageThumbnailUrl(decksThumbnailsURLBase, page.id, false),
        imageSrc: page.imageSrc || generatePageImageUrl(decksImagesUrlBase, page.id, false)
    }))
);

export const getDeckHasAllNewPages = createSelector(
    getPages,
    pages => pages.every(({ createdAt, updatedAt }) => createdAt === updatedAt)
);

export const getDeckHasOnlyOriginalTheme = createSelector(
    getDeckStore,
    deck => (
        (Object.keys(deck.theme || {}).length === 0 &&
            deck.originalTheme !== undefined)
    )
);

export const getPaletteColorScheme = createSelector(
    getTheme,
    (theme = {}) => get(theme, 'colorScheme', 'monochromatic')
);

export const getColorsPresetsOptions = createSelector(
    getSelectableColorsPresets,
    getPaletteColorScheme,
    getDeckStore,
    (colorsPresets, paletteColorScheme, { theme = {}, originalTheme = {} }) => colorsPresets.map(colorsPreset => {
        const reference = colorsPreset[`${paletteColorScheme}SchemeColor`];
        const colorInPalette = [
            ...theme?.colorPalette || [],
            ...originalTheme.colorPalette || []
        ]
            .find(({ id }) => id === reference);

        return {
            type: 'coded',
            preset: colorsPreset.id,
            reference,
            value: colorInPalette && colorInPalette.color
        };
    })
);

export const getColorPalette = createSelector(
    getDeckStore,
    ({
        applicationTheme = {},
        originalTheme = {},
        theme = {}
    }) => uniqBy([
        ...get(theme, 'colorPalette', []),
        ...get(originalTheme, 'colorPalette', []),
        ...get(applicationTheme, 'colorPalette', [])
    ], 'id')
);

export const getDefaultTableStructures = createSelector(
    getDeckStore,
    ({
        applicationTheme = {},
        originalTheme = {},
        theme = {}
    }) => {
        const defaultTableStructures = {
            ...get(applicationTheme, 'defaultTableStructures', {}),
            ...get(theme, 'defaultTableStructures', {}),
            ...get(originalTheme, 'defaultTableStructures', {}),
            defaultAlternate: {
                hasBandedColumns: false,
                hasBandedRows: true,
                hasHeaderRow: true,
                hasHeaderColumn: false,
                hasTotalRow: false,
                hasTotalColumn: false,
                mainAxis: 'vertical'
            }
        };

        return Object.keys(defaultTableStructures).length ?
            defaultTableStructures :
            undefined;
    }
);

export const getDefaultListItemStyles = createSelector(
    getStyleDefinitions,
    getDefaultBulletStyles,
    getPaletteColorScheme,
    getColorPalette,
    (styleDefinitions, defaultBulletStyles, colorScheme, colorPalette) => (defaultBulletStyles ?
        ItemStyle.getEffectiveStyleById(
            styleDefinitions,
            defaultBulletStyles,
            { scheme: colorScheme, colorPalette }
        ) :
        {}
    )
);
