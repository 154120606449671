import { Map, List } from 'immutable';
import { styleToRunStyle } from './shape';

const emphasisStyleRegexp = /^(TB-CE|TB-HE)/;

const convertPropertyToNewShapeProperty = property => {
    const dictionary = {};
    dictionary.angle = 'rotation';
    dictionary.left = 'x';
    dictionary.lineHeight = 'lineSpacing';
    dictionary.shapeFill = 'fill';
    dictionary.stroke = 'borderColor';
    dictionary.strokeWidth = 'borderSize';
    dictionary.textAlign = 'align';
    dictionary.top = 'y';
    return dictionary[property] || property;
};

const convertPropertyToStrokePath = property => {
    const dictionary = {};
    dictionary.strokeColor = 'fill';
    dictionary.strokeWidth = 'width';
    dictionary.strokeDash = 'dash.type';
    dictionary.headTipType = 'head.type';
    dictionary.tailTipType = 'tail.type';
    dictionary.strokeJoinType = 'join.type';
    dictionary.headTipLength = 'head.length';
    dictionary.headTipWidth = 'head.width';
    dictionary.tailTipLength = 'tail.length';
    dictionary.tailTipWidth = 'tail.width';
    dictionary.strokeCapType = 'cap';
    dictionary.strokeOpacity = 'opacity';
    return dictionary[property] || property;
};

const convertPropertiesToShapeProperties = properties => {
    let convertedProperties = Map({
        shapeProps: Map(),
        textProps: Map(),
        strokeProps: Map()
    });
    properties.keySeq().forEach(property => {
        switch (property) {
            case 'fill':
            case 'fontFamily':
            case 'fontSize':
            case 'fontStyle':
            case 'fontWeight':
            case 'linethrough':
            case 'overline':
            case 'textAlign':
            case 'align':
            case 'level':
            case 'margins':
            case 'verticalAlign':
            case 'textDirection':
            case 'underline':
            case 'spacing-bottom':
            case 'spacing-left':
            case 'spacing-right':
            case 'spacing-top':
            case 'lineHeight':
            case 'textTransform':
            case 'characterSpacing':
            case 'superscript':
            case 'assignedParagraphStyle':
            case 'assignedRunStyle':
            case 'textSelection':
            case 'subscript':
            case 'bullet':
            case 'indent':
            case 'levelStyle':
            case 'listUpdate':
                convertedProperties = convertedProperties
                    .update(
                        'textProps',
                        textProps => textProps.merge(styleToRunStyle(properties))
                    );
                break;
            case 'html':
                convertedProperties = convertedProperties
                    .setIn(
                        [
                            'textProps',
                            property
                        ],
                        properties.get(property)
                    );
                break;
            case 'left':
            case 'top':
            case 'angle': {
                const convertedProperty = convertPropertyToNewShapeProperty(property);
                convertedProperties = convertedProperties
                    .setIn(
                        [
                            'shapeProps',
                            convertedProperty
                        ],
                        properties.get(property)
                    );
                break;
            }
            case 'strokeDash':
            case 'strokeWidth':
            case 'strokeColor':
            case 'strokeJoinType':
            case 'strokeCapType':
            case 'headTipType':
            case 'tailTipType':
            case 'headTipLength':
            case 'tailTipLength':
            case 'headTipWidth':
            case 'strokeOpacity':
            case 'tailTipWidth': {
                const convertedProperty = convertPropertyToStrokePath(property);
                convertedProperties = convertedProperties
                    .setIn(
                        [
                            'strokeProps',
                            ...convertedProperty.split('.')
                        ],
                        properties.get(property)
                    );
                break;
            }
            case 'assignedStrokeStyle': {
                properties
                    .get('assignedStrokeStyle')
                    .entrySeq()
                    .forEach(([key, value]) => {
                        convertedProperties = convertedProperties
                            .setIn(
                                [
                                    'strokeProps',
                                    'assignedStrokeStyle',
                                    ...convertPropertyToStrokePath(key).split('.')
                                ],
                                value
                            );
                    });
                break;
            }
            case 'shapeFill': {
                const convertedProperty = convertPropertyToNewShapeProperty(property);
                convertedProperties = convertedProperties
                    .setIn(
                        [
                            'shapeProps',
                            convertedProperty
                        ],
                        properties.get(property)
                    );
                if (properties.getIn([property, 'color'])) {
                    convertedProperties = convertedProperties
                        .setIn(
                            [
                                'shapeProps',
                                convertedProperty,
                                'type'
                            ],
                            'color'
                        );
                }
                break;
            }
            case 'bullets': {
                convertedProperties = convertedProperties
                    .set('textProps', styleToRunStyle(properties));
                break;
            }
            case 'style': {
                if (properties.getIn(['cells', 'ids'], List()).size === 0 || !properties.getIn(['cells', 'contextualSelection'])) {
                    convertedProperties = convertedProperties
                        .setIn(
                            [
                                'shapeProps',
                                'style'
                            ],
                            properties.get('style')
                        );
                }
                convertedProperties = convertedProperties.set(
                    'isEmphasisStyle',
                    emphasisStyleRegexp.test(properties.get('style'))
                );
                break;
            }
            case 'autoFitShape':
            case 'autoFitText': {
                convertedProperties = convertedProperties
                    .setIn(
                        [
                            'shapeProps',
                            property
                        ],
                        properties.get(property)
                    );
                convertedProperties = convertedProperties
                    .setIn(
                        [
                            'textProps',
                            property
                        ],
                        properties.get(property)
                    );
                break;
            }
            default: {
                convertedProperties = convertedProperties
                    .setIn(
                        [
                            'shapeProps',
                            property
                        ],
                        properties.get(property)
                    );
                break;
            }
        }
    });
    return convertedProperties;
};

const convertStrokeUpdateToStroke = strokeUpdate => strokeUpdate
    .entrySeq()
    .reduce((current, [key, value]) => current
        .set(
            convertPropertyToStrokePath(key),
            value
        ), Map());

export {
    convertPropertiesToShapeProperties,
    convertPropertyToNewShapeProperty,
    convertStrokeUpdateToStroke
};
