const flipDimension = (isFlipped, dimension) => (
    isFlipped ?
        -dimension : dimension
);

const flipAngle = (isFlippedHorizontally, isFlippedVertically, currentAngle) => {
    const flippedAngle = isFlippedHorizontally ?
        Math.PI - currentAngle : currentAngle;

    return isFlippedVertically ?
        -flippedAngle : flippedAngle;
};

module.exports = {
    flipDimension,
    flipAngle
};
