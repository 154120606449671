class Dash {
    static get emptySerialized() {
        return { dashStops: [] };
    }

    constructor(type = 'solid', dashStops) {
        this.type = type;
        this.dashStops = dashStops;
    }

    toJSON() {
        return {
            type: this.type,
            dashStops: this.dashStops
        };
    }

    static fromJSON(jsonObject = {}) {
        const { type, dashStops } = jsonObject;
        return new this(type, dashStops);
    }
}

module.exports = Dash;
