const { fabric } = require('fabric');
const { OVERLAY_COLOR } = require('./config/SelectionOverlayDefaultAttributes.config');

const blendColors = (rgba1, rgba2) => {
    const color1 = new fabric.Color(rgba1);
    const color2 = new fabric.Color(rgba2);
    return color1.getAlpha() === 0 ?
        new fabric.Color('#fff').overlayWith(color2).toRgba() :
        color1.overlayWith(color2).toRgba();
};

const applySelectionOverlay = cell => {
    cell.isSelected = true;
    const newColor = blendColors(cell.originalFill, OVERLAY_COLOR);
    cell.getBackground().set('fill', newColor);
};

const removeSelectionOverlay = cell => {
    delete cell.isSelected;
    cell.getBackground().set('fill', cell.originalFill);
};

module.exports = { applySelectionOverlay, removeSelectionOverlay };
