import { addRowAtIndex, addColumnAtIndex } from '../../Helpers/Table/table';

const addLine = (canvasState, command) => {
    const direction = command.get('direction');

    if (['top', 'bottom'].includes(direction)) {
        return addRowAtIndex(canvasState, direction);
    }

    return addColumnAtIndex(canvasState, direction);
};

export default addLine;
