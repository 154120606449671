const { List, fromJS } = require('immutable');

const insertItemsAfterIndex = (itemArray, insertIndex, itemIndices) => {
    const sortedMoveIndices = itemIndices.sort();
    const orderedArrays = itemArray.reduce((output, element, index) => {
        let updatedOutput = output;
        if (output.get('moveCursor') < sortedMoveIndices.size &&
            index === sortedMoveIndices.get(output.get('moveCursor'))) {
            updatedOutput = updatedOutput
                .update(
                    'insertedItems',
                    insertedItems => insertedItems.push(element)
                )
                .update(
                    'moveCursor',
                    moveCursor => moveCursor + 1
                );
        } else if (index < insertIndex) {
            updatedOutput = updatedOutput
                .update(
                    'preItems',
                    preItems => preItems.push(element)
                );
        } else {
            updatedOutput = updatedOutput
                .update(
                    'postItems',
                    postItems => postItems.push(element)
                );
        }
        return updatedOutput;
    }, fromJS({
        preItems: [],
        postItems: [],
        insertedItems: [],
        moveCursor: 0
    }));
    return orderedArrays.get('preItems')
        .concat(orderedArrays.get('insertedItems'))
        .concat(orderedArrays.get('postItems'));
};

const zIndexOrderer = ({ array, key, values }) => {
    const indexesToReorder = (
        !(values instanceof List) ? List([values]) : values
    ).map(value => array.findIndex(object => object.get(key) === value))
        .filter(index => index >= 0)
        .sort((a, b) => a - b);
    return {
        back: () => {
            const targetIndex = array.size;
            return insertItemsAfterIndex(array, targetIndex, indexesToReorder);
        },
        backward: () => {
            const targetIndex = indexesToReorder.get(0) + 2;
            return insertItemsAfterIndex(array, targetIndex, indexesToReorder);
        },
        forward: () => {
            const targetIndex = indexesToReorder.get(0) - 1;
            return insertItemsAfterIndex(array, targetIndex, indexesToReorder);
        },
        front: () => {
            const targetIndex = 0;
            return insertItemsAfterIndex(array, targetIndex, indexesToReorder);
        },
        afterValue: targetValue => {
            const targetIndex = array.findIndex(object => object.get(key) === targetValue) + 1;
            return insertItemsAfterIndex(array, targetIndex, indexesToReorder);
        },
        beforeValue: targetValue => {
            const targetIndex = array.findIndex(object => object.get(key) === targetValue);
            return insertItemsAfterIndex(array, targetIndex, indexesToReorder);
        }
    };
};

export {
    // eslint-disable-next-line import/prefer-default-export
    zIndexOrderer
};
