const { fabric } = require('fabric');
const Textshape = require('./TextShape');
const { getArrowPoints } = require('../../utilities/ShapeGenerators');
const { flipDimension } = require('../utilities/FlipDimensions');
const TextBoundsCalculator = require('../utilities/TextBoundsCalculator');
const { SHAPE_RADIUS } = require('./ModificationHandlers/config/defaultModificationHandlerAttributes.config');

const Arrow = fabric.util.createClass(Textshape, {
    type: 'arrow',

    initialize(json, opts) {
        this.callSuper('initialize', json, opts);
        this.shapeType = 'Arrow';
        this.getHeadHandlerPosition = this.getHeadHandlerPosition.bind(this);
        this.getTailHandlerPosition = this.getTailHandlerPosition.bind(this);
        this.getHeadHandlerLimits = this.getHeadHandlerLimits.bind(this);
        this.getTailHandlerLimits = this.getTailHandlerLimits.bind(this);
        this.updateHeadModifierAttribute = this.updateHeadModifierAttribute.bind(this);
        this.updateTailModifierAttribute = this.updateTailModifierAttribute.bind(this);
        this.onResize = this.onResize.bind(this);
        this.on('scaling', this.onResize);
    },

    updateShapeScale(scaleX, scaleY) {
        const shapeObject = this.getShapeObject();
        shapeObject.set({
            headWidth: shapeObject.headWidth * scaleX,
            tailHeight: shapeObject.tailHeight * scaleY
        });
        this.callSuper('updateShapeScale', scaleX, scaleY, true);
    },

    prepareShapeForRendering() {
        this.callSuper('prepareShapeForRendering');
        this.unscalePath();
        if (
            this.width < this.getMinimumDimensions().width ||
            this.height < this.getMinimumDimensions().height
        ) {
            this.generateScaledPath();
        }
    },

    onResize() {
        const arrow = this.getShapeObject();
        const activeScaleX = arrow.scaleX * this.scaleX;
        const activeScaleY = arrow.scaleY * this.scaleY;
        const scaledPoints = getArrowPoints(
            this.getScaledDimensions().width - (arrow.strokeWidth * activeScaleX),
            this.getScaledDimensions().height - (arrow.strokeWidth * activeScaleY),
            arrow.tailHeight,
            arrow.headWidth,
            arrow.isDouble
        );
        this.setNewPathFromPoints(scaledPoints);
        this.adjustPathScale(
            this.width * this.scaleX,
            this.height * this.scaleY
        );
        this.updateModificationHandlers();
    },

    generateModificationHandlers() {
        this.handlers = [
            new fabric.GenericModificationHandler(
                this,
                this.getHeadHandlerPosition,
                this.getHeadHandlerLimits,
                this.updateHeadModifierAttribute,
                { lockVertical: true }
            ),
            new fabric.GenericModificationHandler(
                this,
                this.getTailHandlerPosition,
                this.getTailHandlerLimits,
                this.updateTailModifierAttribute,
                { lockHorizontal: true }
            )
        ];
    },

    getHeadHandlerPosition() {
        const relativePosition = this.getRelativeHeadHandlerPosition();
        const arrow = this.getShapeObject();
        const absolutePosition = this.getAbsolutePosition();
        return {
            x: absolutePosition.x + flipDimension(arrow.flipX, relativePosition.x),
            y: absolutePosition.y + flipDimension(arrow.flipY, relativePosition.y + SHAPE_RADIUS)
        };
    },

    getRelativeHeadHandlerPosition() {
        const arrow = this.getShapeObject();
        const activeScaleX = arrow.scaleX * this.scaleX;
        const activeScaleY = arrow.scaleY * this.scaleY;
        const scaledWidth = (this.width * this.scaleX) - (arrow.strokeWidth * activeScaleX);
        const scaledHeight = (this.height * this.scaleY) - (arrow.strokeWidth * activeScaleY);
        const scaledHeadWidth = arrow.headWidth * this.getPathScale().x;
        return {
            x: (scaledWidth / 2) - scaledHeadWidth,
            y: -scaledHeight / 2
        };
    },

    getTailHandlerPosition() {
        const relativePosition = this.getRelativeTailHandlerPosition();
        const arrow = this.getShapeObject();
        const absolutePosition = this.getAbsolutePosition();
        return {
            x: this.getShapeObject().isDouble ?
                absolutePosition.x + flipDimension(arrow.flipX, relativePosition.x) :
                absolutePosition.x + flipDimension(arrow.flipX, relativePosition.x + SHAPE_RADIUS),
            y: absolutePosition.y + flipDimension(arrow.flipY, relativePosition.y + SHAPE_RADIUS)
        };
    },

    getRelativeTailHandlerPosition() {
        const arrow = this.getShapeObject();
        const activeScaleX = arrow.scaleX * this.scaleX;
        const scaledHeadWidth = arrow.headWidth * this.getPathScale().x;
        const scaledTailHeight = arrow.tailHeight * this.getPathScale().y;
        const scaledWidth = (this.width * this.scaleX) - (arrow.strokeWidth * activeScaleX);
        return {
            x: arrow.isDouble ?
                scaledHeadWidth - (scaledWidth / 2) :
                -scaledWidth / 2,
            y: -scaledTailHeight / 2
        };
    },

    getHeadHandlerLimits() {
        const arrow = this.getShapeObject();
        const halfScaledWidth = arrow.getScaledWidth() / 2;
        const halfStrokeWidth = (arrow.strokeWidth / 2) * arrow.scaleX;
        const absolutePosition = this.getAbsolutePosition();
        if (arrow.flipX) {
            return {
                min: (absolutePosition.x - halfScaledWidth) + halfStrokeWidth,
                max: arrow.isDouble ?
                    absolutePosition.x - halfStrokeWidth :
                    (absolutePosition.x + halfScaledWidth) - halfStrokeWidth
            };
        }
        return {
            min: arrow.isDouble ?
                absolutePosition.x + halfStrokeWidth :
                (absolutePosition.x - halfScaledWidth) + halfStrokeWidth,
            max: (absolutePosition.x + halfScaledWidth) - halfStrokeWidth
        };
    },

    getTailHandlerLimits() {
        const arrow = this.getShapeObject();
        const halfScaledHeight = arrow.getScaledHeight() / 2;
        const halfStrokeWidth = (arrow.strokeWidth / 2) * arrow.scaleY;
        const absolutePosition = this.getAbsolutePosition();
        if (arrow.flipY) {
            return {
                min: absolutePosition.y,
                max: (absolutePosition.y + halfScaledHeight) - halfStrokeWidth
            };
        }
        return {
            min: (absolutePosition.y - halfScaledHeight) + halfStrokeWidth,
            max: absolutePosition.y
        };
    },

    updateHeadModifierAttribute({ x }) {
        const arrow = this.getShapeObject();
        const newHeadWidth = this.getNewHeadWidth(x);
        arrow.headWidth = newHeadWidth;
        const points = this.getNewPoints(
            newHeadWidth,
            arrow.tailHeight * this.getPathScale().y
        );
        this.setNewPathFromPoints(points);
    },

    getNewHeadWidth(x) {
        const arrow = this.getShapeObject();
        const absolutePosition = this.getAbsolutePosition();
        const scaledStrokeWidth = arrow.strokeWidth * arrow.scaleX;
        return ((this.width - scaledStrokeWidth) / 2) + flipDimension(arrow.flipX, absolutePosition.x - x);
    },

    updateTailModifierAttribute({ y }) {
        const arrow = this.getShapeObject();
        const newTailHeight = this.getNewTailHeight(y);
        arrow.tailHeight = newTailHeight;
        const points = this.getNewPoints(
            arrow.headWidth * this.getPathScale().x,
            newTailHeight
        );
        this.setNewPathFromPoints(points);
    },

    getNewTailHeight(y) {
        const arrow = this.getShapeObject();
        const absolutePosition = this.getAbsolutePosition();
        return 2 * flipDimension(arrow.flipY, absolutePosition.y - y);
    },

    getNewPoints(newHeadWidth, newTailHeight) {
        const arrow = this.getShapeObject();
        return getArrowPoints(
            this.width - (arrow.strokeWidth * arrow.scaleX),
            this.height - (arrow.strokeWidth * arrow.scaleY),
            newTailHeight,
            newHeadWidth,
            arrow.isDouble
        );
    },

    getMinimumDimensions() {
        const arrow = this.getShapeObject();
        const activeScaleX = this.scaleX * arrow.scaleX;
        const activeScaleY = this.scaleY * arrow.scaleY;
        return {
            width: arrow.isDouble ?
                2 * (arrow.headWidth + (arrow.strokeWidth * activeScaleX)) :
                arrow.headWidth + (arrow.strokeWidth * activeScaleX),
            height: arrow.tailHeight + (arrow.strokeWidth * activeScaleY)
        };
    },

    generateScaledPath() {
        const arrow = this.getShapeObject();
        const points = getArrowPoints(
            this.getScaledDimensions().width,
            this.getScaledDimensions().height,
            arrow.tailHeight,
            arrow.headWidth,
            arrow.isDouble
        );
        this.setNewPathFromPoints(points);
        this.adjustPathScale(this.width, this.height);
    },

    getTextBounds() {
        const shape = this.getShapeObject();
        return new TextBoundsCalculator.Arrow(
            this.width,
            this.height,
            {
                tailHeight: shape.tailHeight,
                headWidth: shape.headWidth,
                isDouble: shape.isDouble,
                flipX: shape.flipX
            }
        );
    }
});

Arrow.fromObject = (object, callback) => {
    const arrow = new Arrow(object);
    arrow.on('text:load', () => {
        callback(arrow);
    })
        .on('load:error', err => {
            console.error(err);
            callback(null);
        });
};

module.exports = Arrow;
