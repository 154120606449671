import { fromJS } from 'immutable';
import defaultFromFabricToCanvasState from './default';

const fromFabricToCanvasState = (fabricShape, canvasState) => fromJS({
    ...defaultFromFabricToCanvasState(fabricShape, canvasState),
    tailWidth: fabricShape.tailWidth,
    tailLength: fabricShape.tailLength,
    tailAngle: fabricShape.tailAngle
});

export default fromFabricToCanvasState;
