import { getLocation } from 'connected-react-router';
import decksignCanvas from 'decksign-canvas';
import * as types from '#Constants/ActionTypes';
import {
    getCanvasState,
    getEditMode
} from '#Selectors/build/canvasState';

const {
    api: {
        CanvasState
    }
} = decksignCanvas;

export default (actionCreator, ...args) => (dispatch, getState) => {
    const state = getState();
    const canvasState = getCanvasState(state);
    if (canvasState) {
        const canvasStateShouldBePersisted = CanvasState
            .shouldCurrentVersionBePersisted(
                canvasState,
                state.build.ui.shapesNavigation.versionBeforeEdit
            );
        if (
            getEditMode(state) === 'Layout' &&
            canvasStateShouldBePersisted &&
            getLocation(state).pathname.includes('build')
        ) {
            return dispatch({
                type: types.SHOW_LAYOUT_CONFIRMATION_MODAL,
                delayedActionCreator: () => actionCreator(...args)
            });
        }
    }
    return dispatch(actionCreator(...args));
};
