import getAssignedStyles from './cell/assignedStyles';
import getFill from './cell/fill';
import getContents from './contents';

const getCells = (table, canvasState) => table.cells
    .map(cell => getCell(table, cell, canvasState));

const getCell = (table, cell, canvasState) => ({
    id: cell.id,
    name: cell.name,
    row: cell.row,
    rowSpan: cell.rowSpan,
    column: cell.column,
    columnSpan: cell.columnSpan,
    assignedStyles: getAssignedStyles(table, cell, canvasState),
    fill: getFill(table, cell, canvasState),
    contents: getContents(table, cell, canvasState)
});

export default getCells;
