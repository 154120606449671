const ColorsPreset = require('./ColorsPreset');
const DiagramStyle = require('./DiagramStyle');
const ListItemPreset = require('./ListItemPreset');
const ListPreset = require('./ListPreset');
const ObjectStyle = require('./ObjectStyle');
const ParagraphPreset = require('./ParagraphPreset');
const ShapePreset = require('./ShapePreset');
const SharedProps = require('./SharedProps');
const StrokePreset = require('./StrokePreset');
const TableBuilding = require('./TableBuilding');
const TableStyle = require('./TableStyle');
const TypefacePreset = require('./TypefacePreset');

module.exports = {
    ColorsPreset,
    DiagramStyle,
    ListPreset,
    ListItemPreset,
    ObjectStyle,
    ParagraphPreset,
    ShapePreset,
    SharedProps,
    StrokePreset,
    TableBuilding,
    TableStyle,
    TypefacePreset
};
