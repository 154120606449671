const { fabric } = require('fabric');

const fabricShapeFactory = {
    createShape(shapeJSON, opts) {
        return new Promise(resolve => {
            if (opts.shape) {
                resolve(opts.shape);
            } else {
                switch (shapeJSON.shapeType) {
                    case 'Chart':
                        resolve(new fabric.Rect(shapeJSON));
                        break;
                    case 'Circle':
                        resolve(new fabric.Circle(shapeJSON));
                        break;
                    case 'Polygon':
                        resolve(new fabric.Polygon(shapeJSON.points, shapeJSON));
                        break;
                    case 'RegularPolygon':
                    case 'Trapezoid':
                    case 'Triangle':
                    case 'Ellipse':
                    case 'Chevron':
                    case 'Arrow':
                    case 'Callout':
                    case 'Image':
                    case 'CustomImage':
                    case 'Path':
                    case 'Line':
                    case 'CustomLine':
                        resolve(new fabric.Path(shapeJSON.path, shapeJSON));
                        break;
                    default:
                        resolve(new fabric.Rect(shapeJSON));
                }
            }
        });
    }
};

module.exports = fabricShapeFactory;
