import React from 'react';
import { Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import Route from '#Components/Common/Router/Route';
import NotFound from '#Components/NotFound/NotFound';
/**
 * Custom switch that automatically adds a not found route
 */
const CustomSwitch = props => {
    const children = React.Children.toArray(props.children);
    const notFoundRoute = <Route key="not-found" path="*" component={NotFound} />;
    children.push(notFoundRoute);
    return (
        <Switch>
            {children}
        </Switch>
    );
};

CustomSwitch.propTypes = {
    children: PropTypes.any.isRequired
};

export default CustomSwitch;
