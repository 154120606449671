const UUID = require('uuid/v4');

module.exports = Base => class extends Base {
    paste(modifyName = true, modifyPosition = true) {
        const newShape = this.copy();
        newShape.id = UUID().toString();
        newShape.name = modifyName ? `${this.name}_copy` : this.name;

        if (modifyPosition) {
            newShape.x += 5;
            newShape.y += 5;
        }

        return newShape;
    }
};
