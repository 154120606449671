const ASSIGNED_STYLES = 'assignedStyles';
const PARAGRAPHS = 'paragraphs';
const PARAGRAPH_STYLES = 'paragraphStyles';
const RUNS = 'runs';
const RUN_STYLES = 'runStyles';
const TEXT = 'text';
const AUTO_FIT_TEXT = 'autoFitText';
const AUTO_FIT_SHAPE = 'autoFitShape';
const TEXT_DIRECTION = 'textDirection';
const VERTICAL_ALIGN = 'verticalAlign';
const MARGINS = 'margins';
const PARENT_TYPE = 'parentType';
const PLACEHOLDER_BORDER_STROKE = 'placeholderBorderStroke';
const IS_CELL_CONTENT = 'isCellContent';
const RUN_STYLE = 'runStyle';
const PARAGRAPH_STYLE = 'paragraphStyle';
const BULLETS = 'bullets';
const STYLE = 'style';

const TEXTBODY_PROPERTIES = {
    ASSIGNED_STYLES,
    PARAGRAPHS,
    PARAGRAPH_STYLES,
    RUNS,
    RUN_STYLES,
    TEXT,
    AUTO_FIT_SHAPE,
    AUTO_FIT_TEXT,
    TEXT_DIRECTION,
    VERTICAL_ALIGN,
    MARGINS,
    PARENT_TYPE,
    PLACEHOLDER_BORDER_STROKE,
    IS_CELL_CONTENT,
    RUN_STYLE,
    PARAGRAPH_STYLE,
    BULLETS,
    STYLE
};

export default TEXTBODY_PROPERTIES;
