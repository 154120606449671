const getRelativeAnchors = shape => {
    const left = -shape.get('width') / 2;
    const right = shape.get('width') / 2;
    const top = -shape.get('height') / 2;
    const bottom = shape.get('height') / 2;
    return {
        auto: { x: 0, y: 0 },
        'top-left': { x: left, y: top, expectedAngles: ['top', 'left'] },
        'top-center': { x: 0, y: top, expectedAngles: ['top'] },
        'top-right': { x: right, y: top, expectedAngles: ['top', 'right'] },
        'center-left': { x: left, y: 0, expectedAngles: ['left'] },
        'center-right': { x: right, y: 0, expectedAngles: ['right'] },
        'bottom-left': { x: left, y: bottom, expectedAngles: ['bottom', 'left'] },
        'bottom-center': { x: 0, y: bottom, expectedAngles: ['bottom'] },
        'bottom-right': { x: right, y: bottom, expectedAngles: ['bottom', 'right'] }
    };
};

export default getRelativeAnchors;
