import * as types from '#Constants/ActionTypes';

const currentComment = (state = {}, action) => {
    switch (action.type) {
        case types.SET_CURRENT_COMMENT:
            return action.comment;
        case types.SET_CURRENT_AUDIENCE: {
            return action.currentAudience.comments.list[0] || {};
        }
        case types.SET_CURRENT_SHARE:
        case types.FETCH_SHARES:
            return {};
        default:
            return state;
    }
};

export { currentComment };
