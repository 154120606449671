import * as types from '#Constants/ActionTypes';

const creator = (state = null, action) => {
    switch (action.type) {
        case types.INIT_VIEW:
            return action.isCreator;
        default:
            return state;
    }
};

export { creator };
