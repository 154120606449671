const isEqual = require('lodash/isEqual');
const omit = require('lodash/omit');
const filterObject = require('../../utilities/object/filter');

const {
    styleToRunStyle,
    getCombinedLineCharacterStyles
} = require('../../utilities/shape');
const defaultFabricAttributes = require('../config/defaultFabricAttributes');
const {
    adaptTextBodyToFabricParagraphs
} = require('../Adapters/TextBody/TextBodyAdapter');

module.exports = Base => class extends Base {
    fromShapeToFabric() {
        if (this.textBody.getParagraphsCount() === 0) {
            const defaultParagraphStyle = this.textBodyPlaceholder.getDefaultParagraphStyle();
            if ((defaultParagraphStyle?.bullet?.type ?? 'none') !== 'none' &&
                this.textBodyPlaceholder.getParagraphsCount()) {
                const [{ style: paragraphStyle }] = this.textBodyPlaceholder
                    .getParagraphsWithParagraphStyle(0, 0, true);
                this.textBody.addItem('');
                this.textBody.setParagraphStyleProperties(paragraphStyle, 0, 0);
                const [{ style: defaultRunStyle }] = this.textBodyPlaceholder.getTextWithStyle(0, 0, true);
                this.textBody.setDefaultRunStyle(defaultRunStyle);
            } else {
                this.textBody.addParagraph('');
            }
        }
        const stroke = this.getRenderStroke();
        return {
            ...defaultFabricAttributes,
            ...filterObject({
                id: this.id,
                angle: this.rotation,
                anchors: this.anchors,
                charSpacing: this.charSpacing,
                fill: this.getRenderShapeProperty('fill'),
                height: this.height,
                left: this.left,
                name: this.name,
                inLayout: this.inLayout,
                shapeType: this.shapeType,
                skewX: this.skewX,
                isBackground: this.isBackground,
                skewY: this.skewY,
                flipX: this.flipX,
                flipY: this.flipY,
                scaleX: this.scaleX,
                scaleY: this.scaleY,
                lockUniScaling: this.lockUniScaling,
                lockPath: this.lockPath,
                stroke,
                selected: this.selected,
                autoFitText: this.textBody.getRenderStyleProperty('autoFitText'),
                autoFitShape: this.textBody.getRenderStyleProperty('autoFitShape'),
                paragraphs: adaptTextBodyToFabricParagraphs(
                    this.textBody,
                    (this.canvas || {}).colorPalette
                ),
                placeholderParagraphs: adaptTextBodyToFabricParagraphs(
                    this.textBodyPlaceholder,
                    (this.canvas || {}).colorPalette
                ),
                textBodyMargins: this.textBodyMargins,
                top: this.top,
                width: this.width,
                selectable: this.selectable,
                evented: this.evented,
                verticalAlign: this.verticalAlign,
                textDirection: this.textDirection,
                placeholderType: this.placeholderType,
                placeholderSequence: this.placeholderSequence,
                placeholderSourceId: this.placeholderSourceId,
                isLocked: this.isLocked,
                isImported: this.isImported,
                visible: this.visible,
                isHidden: this.isHidden,
                displayPlaceholder: this.displayPlaceholder || false,
                hyperlink: this.hyperlink,
                startIndex: this.startIndex,
                endIndex: this.endIndex,
                isTextEmpty: this.isTextEmpty,
                hoverCursor: this.hoverCursor,
                dynamicProperties: this.dynamicProperties,
                style: this.style,
                sendToBack: this.sendToBack
            })
        };
    }

    static getRunStylesAndRunsFromFabricStyles(styles) {
        const runs = [];
        let runStyles = [];
        const flattenedStyles = styles.reduce((a, b) => a.concat(b));
        for (let j = 0; j < flattenedStyles.length; j += 1) {
            let alreadyExists = false;
            for (let k = 0; k < runStyles.length; k += 1) {
                if (isEqual(runStyles[k], flattenedStyles[j])) {
                    alreadyExists = true;
                    runs.push({
                        startIndex: j,
                        endIndex: j,
                        style: k
                    });
                    break;
                }
            }
            if (!alreadyExists && Object.keys(flattenedStyles[j]).length) {
                runStyles.push(flattenedStyles[j]);
                runs.push({
                    startIndex: j,
                    endIndex: j,
                    style: runStyles.length - 1
                });
            }
        }

        const mergedRuns = runs.length ? [runs[0]] : [];
        for (let l = 1; l < runs.length; l += 1) {
            const lastMergedRunIndex = mergedRuns.length - 1;
            if (runs[l].style === runs[l - 1].style) {
                mergedRuns[lastMergedRunIndex].endIndex = runs[l].endIndex;
            } else {
                mergedRuns.push(runs[l]);
            }
        }
        runStyles = runStyles.map(runStyle => styleToRunStyle(runStyle));
        return { runStyles, runs: mergedRuns };
    }

    static getShapeAttributesFromSerializedFabricObject(serializedFabricObject = {}) {
        return serializedFabricObject;
    }

    setShapePropertiesFromSerializedFabricObject(serializedFabricObject) {
        Object.entries(omit(serializedFabricObject, ['stroke', 'fill', 'opacity', 'rx', 'ry'])).forEach(([key, value]) => {
            if (Object.hasOwnProperty.call(this, `_${key}`)) {
                this[`_${key}`] = value;
            } else {
                this[key] = value;
            }
        });
    }

    applyCombinedLineCharacterStyles(combinedLineCharacterStyles, textFormat) {
        combinedLineCharacterStyles.forEach((style, index) => {
            const runStyleForChar = styleToRunStyle(textFormat);
            this.textBody.setStyleAt(runStyleForChar, index, index);
            if (style) {
                this.textBody.setStyleProperties(styleToRunStyle(style), index, index);
            }
        });
    }

    applyParagraphStyles(paragraphStyles) {
        (paragraphStyles || []).forEach((style, index) => {
            this.textBody.setParagraphStyleProperties(index, style);
        });
    }

    setDefaultStylesFromSerializedFabricTextFormat(serializedFabricTextFormat) {
        if (serializedFabricTextFormat.fontSize) {
            this.textBody.getDefaultRunStyle().font.size = serializedFabricTextFormat.fontSize;
        }
        if (serializedFabricTextFormat.fontFamily) {
            this.textBody.getDefaultRunStyle().font.family = serializedFabricTextFormat.fontFamily;
        }
        if (serializedFabricTextFormat.fontStyle) {
            this.textBody.getDefaultRunStyle().font.style = serializedFabricTextFormat.fontStyle;
        }
        if (serializedFabricTextFormat.fontWeight) {
            this.textBody.getDefaultRunStyle().font.weight = serializedFabricTextFormat.fontWeight;
        }
        if (serializedFabricTextFormat.fill) {
            this.textBody.getDefaultRunStyle().color = serializedFabricTextFormat.fill;
        }
        if (serializedFabricTextFormat.linethrough) {
            this.textBody.getDefaultRunStyle().linethrough = serializedFabricTextFormat.linethrough;
        }
        if (serializedFabricTextFormat.underline) {
            this.textBody.getDefaultRunStyle().underline = serializedFabricTextFormat.underline;
        }
    }

    shouldPrioritzieTextBodyPlaceholderProps() {
        return !this.textBody.hasText() &&
            this.textBodyPlaceholder.hasText();
    }

    getPrioritizedTextBody() {
        return this.shouldPrioritzieTextBodyPlaceholderProps() ?
            this.textBodyPlaceholder :
            this.textBody;
    }

    get verticalAlign() {
        return this
            .getPrioritizedTextBody()
            .getRenderStyleProperty('verticalAlign');
    }

    set verticalAlign(verticalAlign) {
        this.textBody.verticalAlign = verticalAlign;
        this.textBodyPlaceholder.verticalAlign = verticalAlign;
    }

    get textDirection() {
        return this
            .getPrioritizedTextBody()
            .getRenderStyleProperty('textDirection');
    }

    set textDirection(textDirection) {
        this.textBody.textDirection = textDirection;
        this.textBodyPlaceholder.textDirection = textDirection;
    }

    set startIndex(startIndex) {
        this.textBody.selection.startIndex = startIndex;
    }

    get startIndex() {
        if (this.selected) {
            let offsetStart = 0;
            const { text } = this;
            const { startIndex } = this.selection;
            for (let i = 0; i < startIndex + offsetStart + 1; i++) {
                if (text[i] === '\n') {
                    offsetStart += 1;
                }
            }
            return this.selection.startIndex + offsetStart;
        }
        return undefined;
    }

    get endIndex() {
        if (this.selected) {
            let offsetEnd = 0;
            const { text } = this;
            const { endIndex } = this.selection;
            for (let i = 0; i < endIndex + offsetEnd + 1; i++) {
                if (text[i] === '\n') {
                    offsetEnd += 1;
                }
            }
            return endIndex + offsetEnd;
        }
        return undefined;
    }

    set endIndex(endIndex) {
        this.textBody.selection.endIndex = endIndex;
    }

    get firstParagraphStyle() {
        return (this.textBody.getStyledParagraphsWithStyledRuns()[0] || {}).style || {};
    }

    get text() {
        return this.textBody.getText();
    }

    set text(text) {
        this.textBody.setText(text);
    }

    get charSpacing() {
        return this.textBody.DEFAULT_RUNSTYLE.characterSpacing;
    }
    // eslint-disable-next-line
    set charSpacing(charSpacing) {
    }

    get left() {
        return this.x;
    }

    set left(left) {
        this._left = left;
    }

    get top() {
        return this.y;
    }

    set top(top) {
        this._top = top;
    }

    get shapeType() {
        return this.constructor.name;
    }
    // eslint-disable-next-line
    set shapeType(shapeType) {
    }

    get textBodyMargins() {
        return this
            .getPrioritizedTextBody()
            .getRenderStyleProperty('margins');
    }

    set textBodyMargins(textBodyMargins) {
        this.textBody.margins = textBodyMargins;
        this.textBodyPlaceholder.margins = textBodyMargins;
    }

    set textFormat(textFormat) {
        this.textBody.setText(textFormat.text);
        if (textFormat.paragraphs) {
            this.textBody.setParagraphs(textFormat.paragraphs);
        }
        this.applyParagraphStyles(textFormat.paragraphStyles);
        this.applyCombinedLineCharacterStyles(
            getCombinedLineCharacterStyles(textFormat),
            textFormat
        );

        this.setDefaultStylesFromSerializedFabricTextFormat(
            textFormat
        );

        this.textBody.color = textFormat.fill;
        this.textBody.align = textFormat.textAlign || 'center';
    }

    set angle(rotation) {
        this.rotation = rotation;
    }

    set lockUniScaling(lockUniScaling) {
        this._lockAspectRatio = lockUniScaling;
    }

    get lockUniScaling() {
        return this._lockAspectRatio;
    }

    get hoverCursor() {
        return (this.selectable && !(this.isBackground)) ? 'move' : 'auto';
    }
};
