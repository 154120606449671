const { isValid, getRgba } = require('./color');

exports.filterValidColors = (palette = []) => palette
    .filter(({ color } = {}) => isValid(color));

exports.findColorById = (palette = [], search = '') => {
    const { color } = exports.filterValidColors(palette)
        .find(({ id }) => id === search) || {};
    return color;
};

exports.findIdByColor = (palette = [], search) => {
    if (isValid(search)) {
        const { id } = exports.filterValidColors(palette)
            .find(({ color }) => getRgba(color) === getRgba(search)) || {};
        return id;
    }
    throw new Error(`Search pattern ${search} is not valid`);
};
