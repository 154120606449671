import { InMemoryCache, defaultDataIdFromObject } from 'apollo-cache-inmemory';

const apolloCache = new InMemoryCache({
    dataIdFromObject: object => {
        switch (object.__typename.toLowerCase()) {
            case 'colorpalette':
                // Our colorpalette ressources does not have globally unique ids
                // so naive caching will not work here.
                return null;
            default:
                return defaultDataIdFromObject(object);
        }
    }
});

export default apolloCache;
