const getRelativeAnchors = shape => {
    const sideAngle = (Math.atan2(shape.get('height'), shape.get('width') / 2) / Math.PI) * 180;
    const sideCornerAngle = 90 + ((Math.atan2(shape.get('height') / 2, shape.get('width') / 2) / Math.PI) * 180);
    return {
        auto: { x: 0, y: 0 },
        top: { x: 0, y: -(shape.get('height') / 2), expectedAngles: ['top'] },
        left: {
            x: -(shape.get('width') / 2),
            y: shape.get('height') / 2,
            expectedAngles: [-sideCornerAngle - 1]
        },
        right: {
            x: shape.get('width') / 2,
            y: shape.get('height') / 2,
            expectedAngles: [sideCornerAngle]
        },
        'center-bottom': { x: 0, y: shape.get('height') / 2, expectedAngles: ['bottom'] },
        'center-left': { x: -(shape.get('width') / 4), y: 0, expectedAngles: [-sideAngle] },
        'center-right': { x: shape.get('width') / 4, y: 0, expectedAngles: [sideAngle] }
    };
};

export default getRelativeAnchors;
