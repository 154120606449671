const TextBody = require('../../../Shape/AbstractShape/TextBody');
const TextBodyData = require('../../../Shape/AbstractShape/TextBodyData');
const {
    adaptParagraphStyleToFabricTextStyle,
    adaptFabricTextStyleToParagraphStyle
} = require('./ParagraphStyleAdapter');
const {
    adaptRunStyleToFabricCharacterStyle,
    adaptFabricCharacterStyleToRunStyle
} = require('./RunStyleAdapter');

const limitRunsToRange = ({ runs, startIndex, endIndex }) => runs.map(run => ({
    ...run,
    startIndex: Math.max(startIndex, run.startIndex),
    endIndex: Math.min(endIndex, run.endIndex)
}));

const getFabricCharacterStylesFromRun = ({
    offset,
    textBody,
    colorPalette,
    run: {
        startIndex,
        endIndex,
        style
    }
}) => {
    const runStyle = textBody.getRunStyle(style, true);
    const fabricCharacterStyle = adaptRunStyleToFabricCharacterStyle(runStyle, colorPalette);
    const fabricCharacterStyles = {};
    for (let i = startIndex; i <= endIndex; i++) {
        const currentFontStyle = {
            ...fabricCharacterStyle
        };
        fabricCharacterStyles[i - offset] = currentFontStyle;
    }
    return fabricCharacterStyles;
};

const getFabricCharacterStylesFromRuns = ({
    textBody,
    startIndex,
    runs,
    colorPalette
}) => runs.reduce((styles, run) => ({
    ...styles,
    ...getFabricCharacterStylesFromRun({
        textBody,
        colorPalette,
        run,
        offset: startIndex
    })
}), {});

const cutStylesIntoLines = (styles, text) => {
    const nextLineStart = text.indexOf('\n') + 1;
    if (nextLineStart > 0 && nextLineStart < text.length) {
        const [lineStyles, nextStyles] = Object.entries(styles)
            .reduce(([currentLineStyles, nextLineStyles], [serializedCharacterIndex, style]) => {
                const characterIndex = parseInt(serializedCharacterIndex, 10);
                if (characterIndex < nextLineStart) {
                    currentLineStyles[characterIndex] = style;
                } else {
                    nextLineStyles[characterIndex - nextLineStart] = style;
                }
                return [currentLineStyles, nextLineStyles];
            }, [{}, {}]);
        const nextText = text.substr(nextLineStart);
        return [lineStyles, ...cutStylesIntoLines(nextStyles, nextText)];
    }
    return [styles];
};

const adaptParagraphToFabricParagraph = ({
    paragraph: {
        endIndex,
        startIndex,
        style
    },
    textBody,
    paragraphIndex,
    colorPalette
}) => {
    const runs = textBody
        .getRunsAt(startIndex, endIndex + 1);
    const runsInParagraph = limitRunsToRange({ runs, startIndex, endIndex: endIndex + 1 });
    const styles = getFabricCharacterStylesFromRuns({
        startIndex,
        textBody,
        colorPalette,
        runs: runsInParagraph
    });
    const bulletText = textBody.getItemBulletText(paragraphIndex);
    const text = textBody.getText(startIndex, endIndex);

    const fabricParagraph = {
        text,
        styles: cutStylesIntoLines(styles, text),
        ...adaptRunStyleToFabricCharacterStyle(
            textBody.getDefaultRunStyle(),
            colorPalette
        ),
        ...adaptParagraphStyleToFabricTextStyle(
            (
                style !== undefined ?
                    textBody.getParagraphStyle(style, true) :
                    textBody.getDefaultParagraphStyle()
            ),
            colorPalette
        ),
        bulletText
    };
    return fabricParagraph;
};

exports.adaptTextBodyToFabricParagraphs = (textBody = {}, colorPalette) => {
    const paragraphs = textBody.getParagraphs ?
        textBody.getParagraphs()
            .map((paragraph, index) => adaptParagraphToFabricParagraph({
                textBody,
                paragraph,
                colorPalette,
                paragraphIndex: index
            })) :
        [];
    return paragraphs;
};

const adaptFabricTextStyleToCanvasItemStyle = ({
    textBody,
    fabricTextStyle,
    paragraphIndex,
    colorPalette
}) => {
    const paragraphStyle = adaptFabricTextStyleToParagraphStyle(fabricTextStyle, colorPalette);

    textBody.textBodyData = TextBodyData.setParagraphStyleProperties(
        textBody.textBodyData,
        paragraphStyle,
        paragraphIndex
    );
};

const adaptFabricCharacterStylesToRunStyles = ({
    textBody,
    paragraphIndex,
    fabricCharacterStyles = {},
    colorPalette
}) => {
    const {
        startIndex
    } = textBody.getParagraph(paragraphIndex);

    Object.entries(fabricCharacterStyles).forEach(([index, fabricCharacterStyle]) => {
        const runStyle = adaptFabricCharacterStyleToRunStyle(
            fabricCharacterStyle,
            colorPalette
        );
        const textBodyIndex = Number.parseInt(index, 10) + startIndex;
        textBody.textBodyData = TextBodyData.setStyleProperties(
            textBody.textBodyData,
            runStyle,
            textBodyIndex,
            textBodyIndex
        );
    });
};

const mergeFabricMultiLineStyles = ({
    styles: [mergedStyles, currentStyles, ...nextStyles],
    text,
    currentOffset = 0
}) => {
    const currentParagraphOffset = text
        .substr(currentOffset)
        .indexOf('\n') + (1 + currentOffset);

    if (currentParagraphOffset > 0 && currentStyles) {
        const currentMergedStyles = Object.entries(currentStyles).reduce(
            (accumulatedMergedStyles, [serializedCharacterIndex, style]) => {
                const characterIndex = Number.parseInt(serializedCharacterIndex, 10);
                return {
                    ...accumulatedMergedStyles,
                    [characterIndex + currentParagraphOffset]: style
                };
            },
            mergedStyles
        );

        return mergeFabricMultiLineStyles({
            styles: [
                currentMergedStyles,
                ...nextStyles
            ],
            text,
            currentOffset: currentParagraphOffset
        });
    }
    return [mergedStyles];
};

const adaptFabricParagraphToParagraph = ({
    fabricParagraph: {
        text,
        styles: fabricMultiLineCharacterStyles,
        ...fabricTextStyle
    },
    textBody,
    colorPalette,
    paragraphIndex
}) => {
    const paragraphRunStyle = adaptFabricCharacterStyleToRunStyle(fabricTextStyle, colorPalette);
    if (paragraphIndex === 0) {
        textBody.setParagraphTextWithStyle(
            0,
            text,
            paragraphRunStyle
        );
    } else {
        textBody.addParagraphWithStyle(
            text,
            paragraphRunStyle
        );
    }

    const [fabricCharacterStyles] = mergeFabricMultiLineStyles({
        styles: fabricMultiLineCharacterStyles,
        text
    });

    adaptFabricCharacterStylesToRunStyles({
        textBody,
        paragraphIndex,
        fabricCharacterStyles,
        colorPalette,
        text
    });

    adaptFabricTextStyleToCanvasItemStyle({
        textBody,
        fabricTextStyle,
        paragraphIndex,
        colorPalette
    });
};

exports.adaptFabricParagraphsToTextBody = (fabricParagraphs = [], colorPalette, shape) => {
    const textBody = new TextBody(shape);
    textBody.removeParagraphs();
    const [lastFabricParagraph] = fabricParagraphs.slice(-1);
    const { bullet: lastBullet } = adaptFabricTextStyleToParagraphStyle(lastFabricParagraph);
    if (lastBullet) {
        textBody.bullets = lastBullet;
    }
    fabricParagraphs.forEach((fabricParagraph, paragraphIndex) => adaptFabricParagraphToParagraph({
        textBody,
        fabricParagraph,
        colorPalette,
        paragraphIndex
    }));
    textBody.setDefaultParagraphStyle(textBody.getFullTextParagraphStyle());
    textBody.setDefaultRunStyle(textBody.getFullTextRunStyle());
    return textBody;
};
