/* eslint-disable max-classes-per-file */
const {
    omitBy,
    cloneDeep,
    has,
    pick,
    omit,
    get,
    merge,
    isNil
} = require('lodash');
const { List } = require('immutable');
const { removeZeroSpaceChars } = require('../../utilities/text');
const formatFontFamily = require('../../utilities/formatFontFamily');
const DynamicFields = require('./mixins/dynamicFields');
const StyleProvider = require('./mixins/styleProvider').TextBody;
const HTMLParser = require('./mixins/HTMLParser');
const HTMLConverter = require('./mixins/HTMLConverter');
const BulletsMixin = require('./mixins/bullets');

const TextBodyData = require('./TextBodyData');
const TextBodyStyle = require('../Styles/TextBodyStyle');
const ParagraphStyle = require('../Styles/ParagraphStyle');
const RunStyle = require('../Styles/RunStyle');
const WithTextBodyStyle = require('./mixins/WithTextBodyStyle');
const WithParagraphStyle = require('./mixins/WithParagraphStyle');
const WithRunStyle = require('./mixins/WithRunStyle');

class Base { }

class StyleBase extends WithRunStyle(WithParagraphStyle(WithTextBodyStyle(StyleProvider(Base)))) { }

class TextBody extends HTMLConverter(HTMLParser(BulletsMixin(DynamicFields(StyleBase)))) {
    constructor(shape, options = {}) {
        super(shape, options);
        this.shape = shape;
        this.textBodyData = new TextBodyData.TextBodyData({
            paragraphStyles: List([{
                isDefault: true
            }]),
            runStyles: List([{
                isDefault: true
            }])
        });

        this._verticalAlign = options.verticalAlign;
        this.textDirection = options.textDirection;
        if (options.text) {
            this.setText(options.text);
        }
        this.setParagraphStyleDefaultOnData(shape);
        this.setRunStyleDefaultOnData(shape);
    }

    toJSON() {
        const dataJSON = TextBodyData.toJSON(this.textBodyData);
        this.setComplementParagraphStyleAsParagraphStyle();
        dataJSON.paragraphStyles = dataJSON.paragraphStyles.map(
            paragraphStyle => this.complementParagraphStyleAgainstDefault(paragraphStyle)
        );
        this.setComplementRunStyleAsRunStyle();
        dataJSON.runStyles = dataJSON.runStyles.map(
            runStyle => this.complementRunStyleAgainstDefault(runStyle)
        );
        return {
            ...dataJSON,
            ...this.style.toJSON(),
            assignedStyles: {
                textBody: this.assignedStyle.toJSON(),
                paragraph: this.assignedParagraphStyle.toJSON(),
                run: this.assignedRunStyle.toJSON()
            },
            verticalAlign: this.verticalAlign
        };
    }

    fromJSON(json = {}) {
        const updatedTextBody = {
            paragraphStyles: [],
            runStyles: [],
            ...cloneDeep(json)
        };
        this.assignedStyle = TextBodyStyle.fromJSON(get(json, 'assignedStyles.textBody', {}));
        this.assignedRunStyle = RunStyle.fromJSON(get(json, 'assignedStyles.run', {}));
        updatedTextBody.runStyles = updatedTextBody.runStyles.filter(Boolean);
        updatedTextBody.paragraphStyles = updatedTextBody.paragraphStyles.filter(Boolean);
        const defaultRunStyleIndex = updatedTextBody.runStyles
            .findIndex(style => style.isDefault === true);
        if (defaultRunStyleIndex > -1) {
            this.runStyle = RunStyle.fromJSON(updatedTextBody.runStyles[defaultRunStyleIndex]);
            updatedTextBody.runStyles[defaultRunStyleIndex] = {
                ...this.getRenderRunStyle(),
                isDefault: true
            };
        } else {
            updatedTextBody.runStyles.push({
                ...this.getRenderRunStyle(),
                isDefault: true
            });
        }
        this.assignedParagraphStyle = ParagraphStyle.fromJSON(get(json, 'assignedStyles.paragraph', {}));
        const defaultParagraphStyleIndex = updatedTextBody.paragraphStyles
            .findIndex(style => style.isDefault === true);
        if (defaultParagraphStyleIndex > -1) {
            this.paragraphStyle = ParagraphStyle
                .fromJSON(updatedTextBody.paragraphStyles[defaultParagraphStyleIndex]);
            updatedTextBody.paragraphStyles[defaultParagraphStyleIndex] = {
                ...this.getRenderParagraphStyle(),
                isDefault: true
            };
        } else {
            updatedTextBody.paragraphStyles.push({
                ...this.getRenderParagraphStyle(),
                isDefault: true
            });
        }
        this.textBodyData = TextBodyData.fromJSON(updatedTextBody);
        this.setComplementParagraphStyleAsParagraphStyle();
        this.setComplementRunStyleAsRunStyle();

        this.style = TextBodyStyle.fromJSON(pick(json, TextBodyStyle.properties));
        if (has(json, 'verticalAlign')) {
            this._verticalAlign = json.verticalAlign;
        }
        this.setParagraphStyleDefaultOnData();
        this.setRunStyleDefaultOnData();
    }

    copy() {
        const obj = new this.constructor();
        Object.assign(obj, this);
        return obj;
    }

    applyTextBodyStructure(sourceTextBody) {
        this.style = sourceTextBody.style;
        this.assignedStyle = sourceTextBody.assignedStyle;

        this.setDefaultParagraphStyle(sourceTextBody.getDefaultParagraphStyle());
        this.assignedParagraphStyle = sourceTextBody.assignedParagraphStyle;
        this.setComplementParagraphStyleAsParagraphStyle();

        this.setDefaultRunStyle(sourceTextBody.getDefaultRunStyle());
        this.assignedRunStyle = sourceTextBody.assignedRunStyle;
        this.setComplementRunStyleAsRunStyle();
    }

    isEmpty() {
        return this.getTextLength() === 0;
    }

    hasText() {
        return !this.isEmpty();
    }

    getText(startIndex = 0, endIndex) {
        return TextBodyData.getText(this.textBodyData, startIndex, endIndex);
    }

    getTextLength() {
        return TextBodyData.getTextLength(this.textBodyData);
    }

    setText(text, startIndex, endIndex) {
        this.textBodyData = TextBodyData
            .setText(this.textBodyData, removeZeroSpaceChars(text), startIndex, endIndex);
    }

    addText(text, index) {
        this.textBodyData = TextBodyData.addText(this.textBodyData, text, index);
    }

    removeText(startIndex, endIndex) {
        this.textBodyData = TextBodyData.removeText(
            this.textBodyData,
            startIndex,
            endIndex
        );
    }

    updateDynamicFields() {
        const runs = TextBodyData.getRunsAt(this.textBodyData);
        runs.forEach(run => {
            if (TextBodyData.getRunDynamicType(run) !== 'none') {
                this.setTextWithStyle(
                    this.getDynamicValue([TextBodyData.getRunDynamicType(run)]),
                    this.getRunStyle(run.style),
                    run.startIndex,
                    run.endIndex
                );
            }
        });
    }

    getTextWithStyle(startIndex, endIndex, withDefault) {
        return TextBodyData.getTextWithStyle(
            this.textBodyData,
            startIndex,
            endIndex,
            withDefault
        );
    }

    setTextWithStyle(text, style, startIndex, endIndex) {
        this.textBodyData = TextBodyData.setTextWithStyle(
            this.textBodyData,
            text,
            style,
            startIndex,
            endIndex
        );
    }

    addTextWithStyle(text, style, index) {
        this.textBodyData = TextBodyData.addTextWithStyle(
            this.textBodyData,
            text,
            style,
            index
        );
    }

    getParagraphs(startIndex, endIndex) {
        return TextBodyData
            .getParagraphs(this.textBodyData, startIndex, endIndex)
            .toJS();
    }

    getParagraphsWithIndex(startIndex, endIndex) {
        return TextBodyData
            .getParagraphsWithIndex(this.textBodyData, startIndex, endIndex)
            .toJS();
    }

    getParagraph(index) {
        return TextBodyData.getParagraph(this.textBodyData, index);
    }

    copyTextFrom(textBodyToCopy) {
        const originalStyles = this.getStyledParagraphsWithStyledRuns();
        this.textBodyData = TextBodyData.reset(this.textBodyData);

        const paragraphsToCopy = textBodyToCopy.getStyledParagraphsWithStyledRuns();

        for (let i = 0, len = paragraphsToCopy.length; i < len; i++) {
            const { text, style: paragraphStyleForLevel } = paragraphsToCopy[i];
            const paragraphIndex = Math.min(
                originalStyles.findIndex(({ style = {} }) => (style.level === paragraphStyleForLevel.level)),
                originalStyles.length - 1
            );
            const originalParagraph = originalStyles[paragraphIndex];
            this.addParagraphWithStyle(text, ((originalParagraph || { textRuns: [] })
                .textRuns[0] || { style: this.getDefaultRunStyle() }).style);
            this.setParagraphStylePropertiesAt(i, (originalParagraph || {}).style);
        }
    }

    getParagraphsStyle(withDefault) {
        return TextBodyData.getParagraphStyles(this.textBodyData, withDefault).toJS();
    }

    getParagraphsCount() {
        return TextBodyData.getParagraphsCount(this.textBodyData);
    }

    getParagraphStyle(styleIndex, withDefault = false) {
        return TextBodyData.getParagraphStyle(
            this.textBodyData,
            styleIndex,
            withDefault
        );
    }

    getStyledParagraphsWithStyledRuns(withDefault) {
        return TextBodyData
            .getStyledParagraphsWithStyledRuns(this.textBodyData, withDefault)
            .toJS();
    }

    setParagraphText(index, text) {
        this.textBodyData = TextBodyData.setParagraphText(
            this.textBodyData,
            index,
            text
        );
    }

    setParagraphs(paragraphs) {
        this.textBodyData = TextBodyData.setParagraphs(
            this.textBodyData,
            paragraphs
        );
    }

    setParagraphTextWithStyle(index, text, style) {
        this.textBodyData = TextBodyData.setParagraphTextWithStyle(
            this.textBodyData,
            index,
            text,
            style
        );
    }

    getParagraphStylesCount() {
        return TextBodyData.getParagraphStylesCount(this.textBodyData);
    }

    addParagraph(text) {
        this.textBodyData = TextBodyData.addParagraph(this.textBodyData, text);
        return TextBodyData.getParagraphsCount(this.textBodyData) - 1;
    }

    getParagraphWithStyle(index, withDefault) {
        return TextBodyData.getParagraphWithStyle(this.textBodyData, index, withDefault);
    }

    getFirstRunInParagraph(paragraphIndex, withDefault) {
        return TextBodyData.getFirstRunInParagraph(this.textBodyData, paragraphIndex, withDefault);
    }

    getParagraphsWithStyle(startIndex, endIndex) {
        return TextBodyData.getParagraphsWithStyle(
            this.textBodyData,
            startIndex,
            endIndex
        ).toJS();
    }

    getParagraphsWithParagraphStyle(startIndex, endIndex, withDefault) {
        return TextBodyData.getParagraphsWithParagraphStyle(
            this.textBodyData,
            startIndex,
            endIndex,
            withDefault
        ).toJS();
    }

    addParagraphWithStyle(text, style) {
        this.textBodyData = TextBodyData.addParagraphWithStyle(
            this.textBodyData,
            text,
            style
        );
    }

    removeParagraphs(startIndex, endIndex) {
        this.textBodyData = TextBodyData.removeParagraphs(
            this.textBodyData,
            startIndex,
            endIndex
        );
    }

    getStylesCount() {
        return TextBodyData.getStylesCount(this.textBodyData);
    }

    setStyle(style, startIndex, endIndex) {
        this.textBodyData = TextBodyData.setStyle(
            this.textBodyData,
            style,
            startIndex,
            endIndex
        );
    }

    setStyleAt(style, startIndex, endIndex) {
        this.textBodyData = TextBodyData.setStyle(
            this.textBodyData,
            style,
            startIndex,
            endIndex
        );
    }

    setStyleProperties(
        {
            margins,
            verticalAlign,
            textDirection,
            ...styleProperties
        } = {},
        startIndex,
        endIndex,
        dynamicType
    ) {
        if (styleProperties?.paragraph?.level) {
            const paragraphs = !isNil(startIndex) ?
                this.getItemsForTextSelection({ start: startIndex, end: endIndex }) :
                this.getParagraphs();
            paragraphs.forEach(paragraph => {
                this.setParagraphStylePropertiesAt(paragraph.index, {
                    level: styleProperties?.paragraph?.level
                });
                this.applyLeveledUpdate({
                    update: styleProperties.levelStyle,
                    level: styleProperties?.paragraph?.level,
                    onlyBulletUpdate: false
                }, startIndex, endIndex);
            });
        }

        if (styleProperties?.font?.size && startIndex !== endIndex) {
            const paragraphs = !isNil(startIndex) ?
                this.getItemsForTextSelection({ start: startIndex, end: endIndex }) :
                this.getParagraphs();

            paragraphs.forEach(paragraph => {
                const style = this.getParagraphStyle(paragraph.style);
                if (style?.bullet) {
                    this.setParagraphStylePropertiesAt(paragraph.index, {
                        bullet: {
                            style: {
                                size: styleProperties.font.size
                            }
                        }
                    });
                }
            });
        }

        if (
            [undefined, this.getText().length - 1].includes(endIndex) &&
            [undefined, 0].includes(startIndex)
        ) {
            Object.entries(styleProperties.paragraph || {})
                .forEach(([key, value]) => {
                    if (['padding', 'bullet'].includes(key)) {
                        this[key] = {
                            ...this[key],
                            ...value
                        };
                    } else {
                        this[key] = value;
                    }
                });

            this.setParagraphStyleDefaultOnData();

            Object.entries(pick(styleProperties, RunStyle.properties))
                .forEach(([key, value]) => {
                    if (key === 'font') {
                        this.font = {
                            ...this.font,
                            ...value
                        };
                    } else {
                        this[key] = value;
                    }
                });

            this.setRunStyleDefaultOnData();
        }

        this.textBodyData = TextBodyData.setStyleProperties(
            this.textBodyData,
            styleProperties,
            startIndex,
            endIndex,
            dynamicType
        );

        if (verticalAlign) {
            this._verticalAlign = verticalAlign;
            this.assignedStyle._verticalAlign = verticalAlign;
        }

        if (textDirection) {
            this.textDirection = textDirection;
        }

        if (margins) {
            this.updateMargins(margins || {});
        }
        if (this.isEmpty()) {
            this.setDefaultParagraphAndRunStyles(styleProperties);
        }
    }

    setDefaultParagraphAndRunStyles(styleProperties) {
        const runStyle = omitBy(styleProperties, (value, key) => key === 'paragraph');
        const paragraphStyle = styleProperties.paragraph;
        this.DEFAULT_PARAGRAPHSTYLE = paragraphStyle;
        this.DEFAULT_RUNSTYLE = runStyle;
    }

    clearStyle(startIndex, endIndex) {
        this.textBodyData = TextBodyData.clearStyle(
            this.textBodyData,
            startIndex,
            endIndex
        );
    }

    clearStyleProperties(styleProperties, startIndex = 0, endIndex) {
        const newStyleElements = styleProperties.reduce(
            (newElements, property) => ({
                ...newElements,
                [property]: null
            }),
            {}
        );
        this.setStyleProperties(newStyleElements, startIndex, endIndex);
    }

    getTextForStyles(styles) {
        return TextBodyData.getTextForStyles(
            this.textBodyData,
            styles
        ).toJS();
    }

    getTextForStyleProperties(styleProperties) {
        return TextBodyData.getTextForStyleProperties(
            this.textBodyData,
            styleProperties
        ).toJS();
    }

    hasStyleProperties(
        styleProperties,
        startIndex,
        endIndex
    ) {
        return TextBodyData.hasStyleProperties(
            this.textBodyData,
            styleProperties,
            startIndex,
            endIndex
        );
    }

    getBulletStyleFontSize(runStyleToAssign) {
        return runStyleToAssign?.font?.size || this.defaults.DEFAULT_RUNSTYLE.font.size;
    }

    applyAssignedStyleOnBullet(runStyleToAssign) {
        this.textBodyData = TextBodyData
            .applyDefaultParagraphDimensionsUpdateFromNewFontSize(
                this.textBodyData,
                this.getBulletStyleFontSize(runStyleToAssign)
            );
        this.setComplementParagraphStyleAsParagraphStyle();
        const newBulletStyle = {
            color: runStyleToAssign?.color || this.paragraphStyle.bullet.style.color,
            font: runStyleToAssign?.font?.family || this.paragraphStyle.bullet.style.font,
            size: runStyleToAssign?.font?.size || this.paragraphStyle.bullet.style.size
        };
        this.paragraphStyle = ParagraphStyle.fromJSON({
            bullet: {
                ...this.paragraphStyle.bullet,
                color: newBulletStyle.color,
                font: newBulletStyle.font
            },
            indent: this.paragraphStyle.indent,
            padding: { ...this.paragraphStyle.padding },
            level: this.paragraphStyle.level || 1
        });
    }

    applyUpdate(
        {
            assignedStyle,
            assignedParagraphStyle,
            assignedRunStyle,
            ...update
        } = {},
        removeCustomStyles = false,
        isEmphasisStyle = false
    ) {
        const styleToAssign = isEmphasisStyle ?
            merge(this.assignedStyle, assignedStyle) :
            assignedStyle;
        const paragraphStyleToAssign = isEmphasisStyle ?
            merge(this.assignedParagraphStyle, assignedParagraphStyle) :
            assignedParagraphStyle;
        const runStyleToAssign = isEmphasisStyle ?
            formatFontFamily(
                merge(this.assignedRunStyle, assignedRunStyle),
                assignedRunStyle
            ) :
            assignedRunStyle;

        if (paragraphStyleToAssign) {
            this.assignedParagraphStyle = ParagraphStyle.fromJSON(paragraphStyleToAssign);
            if (removeCustomStyles) {
                if (this.hasBullet()) {
                    this.applyAssignedStyleOnBullet(assignedRunStyle);
                } else {
                    this.paragraphStyle = ParagraphStyle.fromJSON({});
                }

                this.textBodyData = TextBodyData.setStyleProperties(
                    this.textBodyData,
                    {
                        paragraph: this.getRenderParagraphStyle()
                    }
                );
            }
        }
        if (runStyleToAssign) {
            this.assignedRunStyle = RunStyle.fromJSON(runStyleToAssign);
            if (removeCustomStyles) {
                this.runStyle = RunStyle.fromJSON({});
                this.textBodyData = TextBodyData.setStyleProperties(
                    this.textBodyData,
                    this.hasBullet() ? omit(this.getRenderRunStyle(), ['font.size']) : this.getRenderRunStyle()
                );
            }
            this.setRunStyleDefaultOnData();
        }

        if (styleToAssign) {
            this.assignedStyle = TextBodyStyle.fromJSON(styleToAssign);
            if (removeCustomStyles) {
                this.style = TextBodyStyle.fromJSON({});
            }
        }

        if (update.listUpdate) {
            this.applyListUpdate(update);
        } else if (
            update.textSelection?.editing === true &&
            update.textSelection?.start === update.textSelection?.end
        ) {
            this.setStyleProperties(
                update,
                update.textSelection.start,
                update.textSelection.end
            );
        } else if (
            update.textSelection?.editing === true &&
            this.isTextSelectionApplicable(update.textSelection)
        ) {
            this.setStyleProperties(
                update,
                update.textSelection.start,
                update.textSelection.end - 1
            );
        } else if (update.textSelection?.editing !== true) {
            if (has(update, 'font.size') && Object.keys(update).length === 1) {
                this.textBodyData = TextBodyData
                    .applyDefaultParagraphDimensionsUpdateFromNewFontSize(
                        this.textBodyData,
                        get(update, 'font.size')
                    );
                this.setComplementParagraphStyleAsParagraphStyle();
            }
            this.setStyleProperties(update);
        }
    }

    hasBullet() {
        return this.paragraphStyle?.bullet &&
            this.paragraphStyle?.bullet?.font?.length !== 0 &&
            this.paragraphStyle?.bullet?.type !== 'none';
    }

    isTextSelectionApplicable(textSelection) {
        return (
            textSelection?.editing === true &&
            textSelection?.start <= this.getTextLength() - 1 &&
            textSelection?.end <= this.getTextLength()
        );
    }

    getFullTextParagraphStyle() {
        return TextBodyData.getFullTextParagraphStyle(this.textBodyData);
    }

    getFullTextRunStyle() {
        return TextBodyData.getFullTextRunStyle(this.textBodyData);
    }

    getDefaultParagraphStyle() {
        return this.DEFAULT_PARAGRAPHSTYLE;
    }

    setDefaultParagraphStyle(style) {
        this.DEFAULT_PARAGRAPHSTYLE = style;
    }

    getDefaultRunStyle() {
        return this.DEFAULT_RUNSTYLE;
    }

    setDefaultRunStyle(style) {
        this.DEFAULT_RUNSTYLE = style;
    }

    get DEFAULT_PARAGRAPHSTYLE() {
        return TextBodyData.getDefaultParagraphStyle(this.textBodyData);
    }

    set DEFAULT_PARAGRAPHSTYLE(style) {
        this.textBodyData = TextBodyData.setDefaultParagraphStyle(
            this.textBodyData,
            style
        );
    }

    get DEFAULT_RUNSTYLE() {
        return TextBodyData.getDefaultRunStyle(this.textBodyData);
    }

    set DEFAULT_RUNSTYLE(style) {
        this.textBodyData = TextBodyData.setDefaultRunStyle(
            this.textBodyData,
            style
        );
    }

    setParagraphStyleProperties(properties, startIndex, endIndex) {
        this.textBodyData = TextBodyData.setParagraphStyleProperties(
            this.textBodyData,
            properties,
            startIndex,
            endIndex
        );
    }

    setParagraphStylePropertiesAt(index, properties) {
        this.setParagraphStyleProperties(properties, index, index);
    }

    removeParagraph(index) {
        this.removeParagraphs(index, index);
    }

    updateMargins(marginsUpdate) {
        this.margins = {
            ...this.margins,
            ...marginsUpdate
        };
    }

    getRunsAt(startIndex, endIndex) {
        return TextBodyData
            .getRunsAt(this.textBodyData, startIndex, endIndex)
            .toJS();
    }

    getRunStyle(index, withDefault = false) {
        return TextBodyData
            .getRunStyle(this.textBodyData, index, withDefault);
    }

    updateColorPresets(colorPresets) {
        this.textBodyData = TextBodyData.updateColorPresets(this.textBodyData, colorPresets);
    }

    copyStyles(original) {
        this.copyAssignedTextBodyStyle(original);
        this.copyAssignedParagraphStyle(original);
        this.copyAssignedRunStyle(original);
        this.setComplementTextBodyStyleAsTextBodyStyle();
        this.setComplementParagraphStyleAsParagraphStyle();
        this.setComplementRunStyleAsRunStyle();
    }

    get fonts() {
        return TextBodyData.getFonts(this.textBodyData);
    }

    get shape() {
        return this._shape;
    }

    set shape(shape) {
        this._shape = shape;
    }

    get defaults() {
        return this.loadDefaultTextBodyStyleConfiguration(
            this.shape ? this.shape.constructor.name : null
        );
    }

    get html() {
        return this.convertToHTML();
    }

    set html(html) {
        this.parseHTMLToTextBody(`<div>${html}</div>`.replace(/\n/g, ''));
        this.setIndentAndPaddingFromLevel({ onlyUpdatePadding: true });
    }

    get autoFitText() {
        return super.autoFitText();
    }

    set autoFitText(autoFitText) {
        super.autoFitText = autoFitText;
        const renderAutoFitText = this.getRenderStyleProperty('autoFitText');
        if (renderAutoFitText === true) {
            this.textBodyData = this.textBodyData.set('autoFitShape', false);
        }
        this.textBodyData = this.textBodyData.set('autoFitText', renderAutoFitText);
    }

    get autoFitShape() {
        return super.auotFitShape;
    }

    set autoFitShape(autoFitShape) {
        super.autoFitShape = autoFitShape;
        const renderAutoFitShape = this.getRenderStyleProperty('autoFitShape');
        if (renderAutoFitShape === true) {
            this.textBodyData = this.textBodyData.set('autoFitText', false);
        }
        this.textBodyData = this.textBodyData.set('autoFitShape', renderAutoFitShape);
    }

    get verticalAlign() {
        return super.verticalAlign;
    }

    set verticalAlign(verticalAlign) {
        super.verticalAlign = verticalAlign;
    }
}

module.exports = TextBody;
