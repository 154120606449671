module.exports = {
    id: {
        itemUpdatePropertyName: 'styleId'
    },
    fill: {
        toItemUpdate: (value, canvasItemStyle) => ({
            ...canvasItemStyle,
            fill: value
        }),
        toStyle: canvasItem => canvasItem.fill,
        isFill: true
    },
    opacity: {
        itemUpdatePropertyName: 'opacity',
        toStyle: canvasItem => canvasItem.opacity
    },
    roundedCorners: {
        toItemUpdate: (value, canvasItemStyle) => (
            value ?
                ({
                    ...canvasItemStyle,
                    rx: value,
                    ry: value
                }) :
                canvasItemStyle
        ),
        toStyle: canvasItem => (canvasItem.rx + canvasItem.ry) / 2
    }
};
