const {
    fromJS,
    List,
    Map,
    Set
} = require('immutable');

const TreeNode = require('../fabric-adapter/DecksignFabricShapeType/Groups/TreeNode');

const isShapeId = (shape, id) => (shape.get('id') || shape.get('_id')) === id;

const getSubGroup = (group, groupId) => group.find(s => isShapeId(s, groupId)).get('shapes');

const extractShapesFromGroup = (group, ids) => group.filter(
    shape => ids.find(shapeId => isShapeId(shape, shapeId))
);

const filterHeaderAndFooterPlaceholders = require('../utilities/Canvas/filterHeaderAndFooterPlaceholders');

const getCanvasItemsFromParentGroups = (
    canvasState,
    selectedShapesParentGroupsPath,
    selection
) => selectedShapesParentGroupsPath.flatMap(
    selectedShapeParentGroupsPath => {
        const lastParentGroupShapes = selectedShapeParentGroupsPath.reduce(
            (currentGroup, nextGroupId) => getSubGroup(currentGroup, nextGroupId),
            canvasState
        );
        return extractShapesFromGroup(lastParentGroupShapes, selection);
    }
);

const getCanvasItemsFromGroupTree = (
    shapes,
    groupTree,
    selection
) => {
    const groupPaths = selection.map(id => TreeNode
        .getAllParents(groupTree, id)
        .map(parent => parent.getShapeId()));
    return getCanvasItemsFromParentGroups(shapes, groupPaths, selection);
};

const getSelectedItemsFromLayer = (shapes, selection, canvasState) => {
    const selectedShapesParentGroupsPath = canvasState.get('selectedShapesParentGroupsPath');
    const groupTree = canvasState.get('groupTree');
    if (selectedShapesParentGroupsPath && selectedShapesParentGroupsPath.size > 0) {
        return getCanvasItemsFromParentGroups(shapes, selectedShapesParentGroupsPath, selection);
    } else if (groupTree) {
        return getCanvasItemsFromGroupTree(shapes, groupTree, selection);
    }
    return extractShapesFromGroup(shapes, selection);
};

module.exports = {
    getShapePathById(canvasState, shapeId) {
        let targetPath;
        const pathsToCheck = [['shapes']];
        while (pathsToCheck.length > 0 && targetPath === undefined) {
            const [nextPathToCheck] = pathsToCheck.splice(0, 1);
            for (let i = 0; i < canvasState.getIn(nextPathToCheck).size; i++) {
                const shape = canvasState.getIn([...nextPathToCheck, i]);
                if (shape.get('id') === shapeId) {
                    targetPath = [...nextPathToCheck, i];
                } else if (shape.has('shapes') && shape.get('shapes').size !== 0) {
                    pathsToCheck.push([...nextPathToCheck, i, 'shapes']);
                } else if (shape.has('cells')) {
                    pathsToCheck.push([...nextPathToCheck, i, 'cells']);
                }
            }
        }
        return targetPath;
    },

    getPageId(canvasState) {
        return canvasState.get('pageId');
    },

    getCanvasStateSelectedShapePaths(canvasState) {
        return canvasState.get('selection').map((selectedShapeId, selectionIndex) => {
            const groupPath = this.getGroupPath(canvasState, selectionIndex, 'shapes') ||
                this.getGroupPath(canvasState, selectionIndex, 'layoutShapes');
            if (groupPath) {
                const selectedShapeIndex = canvasState.getIn(groupPath).findIndex(shape => shape.get('id') === selectedShapeId);
                return [
                    ...groupPath,
                    selectedShapeIndex
                ];
            }
            let shapePath = [];
            ['shapes', 'layoutShapes'].forEach(shapeGroup => {
                const shapeIndex = canvasState.get(shapeGroup).findIndex(shape => shape.get('id') === selectedShapeId);
                if (shapeIndex >= 0) {
                    shapePath = [shapeGroup, shapeIndex];
                }
            });
            return shapePath;
        });
    },

    getGroupPath(canvasState, selectionIndex, shapeGroup = 'shapes') {
        const groupPath = canvasState.getIn(['selectedShapesParentGroupsPath', selectionIndex], [])
            .reduce(
                (path, shapeId) => {
                    const shapeIndex = canvasState.getIn(path).findIndex(shape => shape.get('id') === shapeId);
                    return shapeIndex !== -1 ?
                        [...path, shapeIndex, 'shapes'] :
                        path;
                },
                [shapeGroup]
            );

        return groupPath.length > 1 ?
            groupPath :
            undefined;
    },

    getSelectedCanvasItems(canvasState) {
        const shapesInLayer = canvasState.get('editMode') === 'Page' ?
            this.getSelectableShapesInPageEdit(canvasState) :
            canvasState.get('layoutShapes').concat(canvasState.get('layoutBackground'));
        return getSelectedItemsFromLayer(shapesInLayer, canvasState.get('selection'), canvasState);
    },

    getCanvasStateSelectedShapeTypes(canvasState) {
        return this.getCanvasStateSelectedShapePaths(canvasState)
            .map(path => canvasState.getIn([...path, 'type'], ''))
            .toSet();
    },

    getSelectableShapesInPageEdit(canvasState) {
        const foreground = this.intersectPageShapesAndLayoutShapesInPageEdit(
            canvasState.get('shapes'),
            canvasState.get('layoutShapes')
        );
        const background = this.intersectPageShapesAndLayoutShapesInPageEdit(
            canvasState.get('pageBackground'),
            canvasState.get('layoutBackground')
        );
        return foreground.concat(background);
    },

    getFlattenRunStyles(shapes) {
        return this.getFlattenTextBodies(shapes)
            .flatMap(textBody => textBody.get('runStyles'));
    },

    getFlattenParagraphStyles(shapes) {
        return this.getFlattenTextBodies(shapes)
            .flatMap(textBody => textBody.get('paragraphStyles'));
    },

    getFlattenFonts(shapes) {
        const textBodies = this.getFlattenTextBodies(shapes);
        const bodiesFonts = textBodies.flatMap(textBody => [
            textBody.getIn('assignedStyles.paragraph.bullet.style.font'.split('.')),
            textBody.getIn('assignedStyles.run.font.family'.split('.'))
        ]);

        const runStyles = this.getFlattenRunStyles(shapes);

        const runFonts = runStyles
            .map(runStyle => {
                if (runStyle) {
                    return runStyle.getIn(['font', 'family']);
                }
                return null;
            });

        const paragraphStyles = this.getFlattenParagraphStyles(shapes);

        const paragraphFonts = paragraphStyles
            .map(paragraphStyle => paragraphStyle.getIn(['bullet', 'style', 'font']));

        return Set.of(...runFonts.concat(paragraphFonts, bodiesFonts)).filter(Boolean);
    },

    getFlattenTextBodies(shapes) {
        const textBodies = this.getFlattenTextBody(shapes);

        const placeholders = this.getFlattenTextBodyPlaceholder(shapes);

        return textBodies.concat(placeholders);
    },

    getFlattenTextBody(shapes) {
        return this.getFlattenShapes(shapes)
            .filter(shape => Boolean(shape) && shape.get('textBody'))
            .map(shape => shape.get('textBody'));
    },

    getFlattenTextBodyPlaceholder(shapes) {
        return this.getFlattenShapes(shapes)
            .filter(shape => shape.get('textBodyPlaceholder'))
            .map(shape => shape.get('textBodyPlaceholder'));
    },

    getFlattenShapes(shapes = new List()) {
        return shapes.reduce((flattenCanvasShapes, canvasItem) => {
            if (this.isCanvasItemType(canvasItem, 'table')) {
                return flattenCanvasShapes.push(...this.getFlattenTable(canvasItem));
            }
            if (this.isCanvasItemType(canvasItem, 'group')) {
                return flattenCanvasShapes.push(...this.getFlattenShapes(canvasItem.get('shapes')));
            }
            return flattenCanvasShapes.push(canvasItem);
        }, new List());
    },

    getCanvasItemType(canvasItem) {
        return (canvasItem.get('type') || '').toLowerCase();
    },

    isCanvasItemType(canvasItem, type) {
        return this.getCanvasItemType(canvasItem) === type.toLowerCase();
    },

    getFlattenTable(table) {
        return [
            ...table.get('borders').map(border => border.merge({
                type: 'border'
            })),
            ...table.get('cells').reduce((flattenCells, cell) => [
                ...flattenCells,
                ...this.getFlattenCell(cell)
            ], []),
            table
        ];
    },

    getFlattenCell(cell) {
        return [
            ...cell.get('contents').map(content => content.set('isCellContent', true)),
            cell.merge({
                type: 'cell'
            })
        ];
    },

    getBoundingBoxOfItems(items = new List()) {
        const edges = items
            .reduce((currentEdges, item) => ({
                bottom: Math.max(item.get('y') + (item.get('height') / 2), currentEdges.bottom),
                left: Math.min(item.get('x') - (item.get('width') / 2), currentEdges.left),
                right: Math.max(item.get('x') + (item.get('width') / 2), currentEdges.right),
                top: Math.min(item.get('y') - (item.get('height') / 2), currentEdges.top)
            }), {
                bottom: -Infinity, left: Infinity, right: -Infinity, top: Infinity
            });

        return new Map({
            x: (edges.left + edges.right) / 2,
            y: (edges.top + edges.bottom) / 2,
            width: edges.right - edges.left,
            height: edges.bottom - edges.top
        });
    },

    getShapeById(canvasState, id) {
        const getShapeByIdInMap = shapes => shapes.reduce((foundShape, nextShape) => {
            if (foundShape) return foundShape;
            if (nextShape.get('id') === id) {
                return nextShape;
            }
            if (nextShape.get('type') === 'Group') {
                return getShapeByIdInMap(nextShape.get('shapes'));
            }
            return null;
        }, null);
        const pageShapes = canvasState.get('shapes').concat(canvasState.get('pageBackground'));
        const layoutShapes = canvasState.get('layoutShapes').concat(canvasState.get('layoutBackground'));
        return getShapeByIdInMap(pageShapes) || getShapeByIdInMap(layoutShapes);
    },

    intersectPageShapesAndLayoutShapesInPageEdit(pageShapes) {
        return pageShapes;
    },

    getListableLayoutPlaceholdersInPageEdit(pageShapes) {
        return this.getFlattenShapes(pageShapes);
    },

    filterHeaderAndFooterPlaceholders(shapes, headerAndFooterShapesVisibility = {}) {
        return shapes
            .filter(shape => filterHeaderAndFooterPlaceholders(
                shape.get('placeholderType'),
                headerAndFooterShapesVisibility
            ));
    },

    getPlaceholdersInShapeList(shapes) {
        return shapes.filter(shape => this.isShapePlaceholder(shape));
    },

    getShapeListWithoutPlaceholders(shapes) {
        return shapes.filter(shape => !this.isShapePlaceholder(shape));
    },

    isShapePlaceholder(shape) {
        return this.getPlaceholderType(shape).length > 0;
    },

    getPlaceholderType(shape) {
        return shape.get('placeholderType') || '';
    },

    getTableStylesMap(canvasState, tableId) {
        const table = this.getShapeById(canvasState, tableId);
        const cellStyleMap = table.get('cells')
            .reduce((currentMap, cell) => ({
                ...currentMap,
                [cell.get('id')]: cell.get('style', '')
            }), {});
        const borderStyleMap = table.get('borders')
            .reduce((currentMap, border) => ({
                ...currentMap,
                [border.get('id')]: border.getIn([
                    'assignedStyles',
                    'stroke',
                    'styleId'
                ], '')
            }), {});
        return fromJS({
            cells: cellStyleMap,
            borders: borderStyleMap
        });
    },

    getTextBodyPathsFromShapeList(canvasState, shapeListPath) {
        return canvasState.getIn(shapeListPath)
            .flatMap((shape, shapeIndex) => {
                const shapePath = [...shapeListPath, shapeIndex];
                switch (shape.get('type', '').toLowerCase()) {
                    case 'group':
                        if (shape.has('shapes')) {
                            return this.getTextBodyPathsFromShapeList(canvasState, [...shapePath, 'shapes']);
                        }
                        return [];
                    case 'table':
                        return this.getTextBodyPathsFromTable(canvasState, shapePath);
                    default:
                        return this.getTextBodyPathsFromShape(canvasState, shapePath);
                }
            });
    },

    getTextBodyPathsFromTable(canvasState, tablePath) {
        return canvasState
            .getIn([...tablePath, 'cells'])
            .flatMap((cell, cellIndex) => cell.get('contents')
                .flatMap((content, contentIndex) => this.getTextBodyPathsFromShape(
                    canvasState,
                    [...tablePath, 'cells', cellIndex, 'contents', contentIndex]
                )));
    },

    getTextBodyPathsFromShape(canvasState, shapePath) {
        return [
            canvasState.hasIn([...shapePath, 'textBody']) && [...shapePath, 'textBody'],
            canvasState.hasIn([...shapePath, 'textBodyPlaceholder']) && [...shapePath, 'textBodyPlaceholder']
        ].filter(Boolean);
    },

    getShapesToRender(canvasState) {
        if (canvasState.get('editMode') === 'Page') {
            return this.getPageShapesToRender(canvasState);
        }
        return this.getLayoutShapesToRender(canvasState);
    },

    getPageShapesToRender(canvasState) {
        const pageShapes = canvasState.get('shapes');
        const nonPlaceholderLayoutShapes = canvasState.get('layoutShapes')
            .filter(shape => (shape.get('placeholderType') || '').length === 0);
        const background = canvasState.get('pageBackground');
        const layoutBackgroundCanvasItemsToRender = canvasState.get('layoutBackground');
        const shapesToRender = List(
            pageShapes
        ).concat(nonPlaceholderLayoutShapes, background, layoutBackgroundCanvasItemsToRender);
        return shapesToRender.filter(shape => filterHeaderAndFooterPlaceholders(
            shape.get('placeholderType'),
            canvasState.get('headerAndFooterShapesVisibility')
        ));
    },

    getLayoutShapesToRender(canvasState) {
        const layoutShapes = canvasState.get('layoutShapes');
        const layoutBackground = canvasState.get('layoutBackground');
        return List(layoutShapes).concat(layoutBackground);
    }
};
