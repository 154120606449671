const defaultCell = {
    top: Infinity,
    left: Infinity,
    bottom: -Infinity,
    right: -Infinity,
    getRelativeLeft: () => Infinity,
    getRelativeTop: () => Infinity,
    getRelativeBottom: () => -Infinity,
    getRelativeRight: () => -Infinity
};

class SelectionBox {
    constructor(selectionOrigin, table) {
        this._hasSelectionOrigin = selectionOrigin !== undefined;
        this._selectionOrigin = selectionOrigin || defaultCell;
        this._topLeftCellInSelection = selectionOrigin || defaultCell;
        this._bottomRightCellInSelection = selectionOrigin || defaultCell;
        this.table = table;
        /* HACK since our cells don't perfectly line up */
        this._pixelBuffer = 0;
        this.growLimit = 5;
        if (this._hasSelectionOrigin) {
            this.selectedCells = [selectionOrigin];
        } else {
            this.selectedCells = [];
        }
    }

    intersectsWithCell(cell) {
        return (
            this.intersectsHorizontallyWithCell(cell) &&
            this.intersectsVerticallyWithCell(cell)
        );
    }

    intersectsHorizontallyWithCell(cell) {
        return this.left < cell.getRelativeRight() &&
                this.right > cell.getRelativeLeft();
    }

    intersectsVerticallyWithCell(cell) {
        return this.top < cell.getRelativeBottom() &&
                this.bottom > cell.getRelativeTop();
    }

    hasSelectionOrigin() {
        return this._hasSelectionOrigin;
    }

    set selectionOrigin(cell) {
        this._selectionOrigin = cell;
        this._topLeftCellInSelection = cell;
        this._bottomRightCellInSelection = cell;
        this._hasSelectionOrigin = true;
        this.selectedCells = [cell];
    }

    get selectionOrigin() {
        return this._selectionOrigin;
    }

    get topLeftCellInSelection() {
        return this._topLeftCellInSelection;
    }

    get bottomRightCellInSelection() {
        return this._bottomRightCellInSelection;
    }

    get left() {
        return this._topLeftCellInSelection.getRelativeLeft() +
            this._pixelBuffer;
    }

    get right() {
        return this._bottomRightCellInSelection.getRelativeRight() -
            this._pixelBuffer;
    }

    get top() {
        return this._topLeftCellInSelection.getRelativeTop() +
            this._pixelBuffer;
    }

    get bottom() {
        return this._bottomRightCellInSelection.getRelativeBottom() -
            this._pixelBuffer;
    }

    updateSelection(lastClickedCell) {
        const topLeftRow = Math.min(this._selectionOrigin.row, lastClickedCell.row);
        const topLeftColumn = Math.min(this._selectionOrigin.column, lastClickedCell.column);
        const bottomRightRow = Math.max(
            this._selectionOrigin.row + (this._selectionOrigin.rowSpan - 1),
            lastClickedCell.row + (lastClickedCell.rowSpan - 1)
        );
        const bottomRightColumn = Math.max(
            this._selectionOrigin.column + (this._selectionOrigin.columnSpan - 1),
            lastClickedCell.column + (lastClickedCell.columnSpan - 1)
        );
        const cellsInRows = this.table.getCellsBetweenRows(topLeftRow, bottomRightRow);
        const cellsInCols = this.table.getCellsBetweenColumns(topLeftColumn, bottomRightColumn);
        this.selectedCells = this.table
            .getCellsIntersection(cellsInRows, cellsInCols)
            .map(cellId => this.table.cells[cellId]);
        // this.squareSelection(); See method comment for more details
    }

    findSelectionBoundingPoints() {
        const pointTopLeft = { x: Infinity, y: Infinity };
        const pointBottomRight = { x: -Infinity, y: -Infinity };
        Object.values(this.table.cells).forEach(cell => {
            pointTopLeft.x = Math.min(pointTopLeft.x, cell.getRelativeLeft()) + this._pi;
            pointTopLeft.y = Math.min(pointTopLeft.y, cell.getRelativeBottom());
            pointBottomRight.x = Math.max(pointBottomRight.x, cell.getRelativeRight());
            pointBottomRight.y = Math.max(pointBottomRight.y, cell.getRelativeBottom());
        });
        return [pointTopLeft, pointBottomRight];
    }

    squareSelection() {
        /**
         * This method makes the selection work as Keynote, buy making sure a shift selection
         * is always square.This does not yet work as expected, but implementation is on hold
         * until we decide what the expected behaviour is.
         */
        let previousSelectionLength = 0;
        let it = 0;
        let pointTopLeft;
        let pointBottomRight;
        this.selectedCells = this.findSelection();
        while (previousSelectionLength < this.selectedCells.length && it < this.growLimit) {
            previousSelectionLength = this.selectedCells.length;
            [pointTopLeft, pointBottomRight] = this.findSelectionBoundingPoints();
            this._topLeftCellInSelection = this.findSelectionBoundingCell(pointTopLeft) ||
                this._topLeftCellInSelection;
            this._bottomRightCellInSelection = this.findSelectionBoundingCell(pointBottomRight) ||
                this._bottomRightCellInSelection;
            this.selectedCells = this.findSelection();
            it += 1;
        }
    }

    findSelection() {
        return Object.values(this.table.cells).filter(cell => this.intersectsWithCell(cell));
    }

    findSelectionBoundingCell(point) {
        return this.table.cells[this.table.getCellIdAtPosition(point)];
    }
}

module.exports = SelectionBox;
