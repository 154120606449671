import * as types from '#Constants/ActionTypes';

const list = (state = [], action) => {
    switch (action.type) {
        case types.LOAD_TOPICS_LIST:
            return action.topics;
        default:
            return state;
    }
};

const searchValue = (state = '', action) => {
    switch (action.type) {
        case types.UPDATE_PAGE_IN_CURRENT_DECK:
            return '';
        case types.UPDATE_TOPIC_SEARCH:
            return action.value || '';
        default:
            return state;
    }
};

const suggestions = (state = [], action) => {
    switch (action.type) {
        case types.UPDATE_TOPICS_SUGGESTIONS:
            return action.topics;
        default:
            return state;
    }
};

export { list, searchValue, suggestions };
