import { Map } from 'immutable';
import typefaceEffectConversion from './typefaceEffectConversion';
import getFirstRunStyle from './getFirstRunStyle';
import * as Fill from '../Fill';

export default {
    id: {
        itemUpdatePropertyName: 'styleId'
    },
    fontFamily: {
        toItemUpdate: (value, update, definition) => update
            .set(
                'fontFamily',
                value ? `${value} ${definition.get('fontWeight') ? definition.get('fontWeight') : ''}`.trim() : ''
            ),
        toStyle(shape) {
            const runStyle = getFirstRunStyle(shape);
            return runStyle.getIn(['font', 'family'], '');
        }
    },
    fontWeight: {
        toItemUpdate(value, update) {
            let fontWeightUpdate = update;
            if (/\bbold\b/i.test(value)) {
                fontWeightUpdate = fontWeightUpdate
                    .set(
                        'fontWeight',
                        'bold'
                    );
            } else {
                fontWeightUpdate = fontWeightUpdate
                    .set(
                        'fontWeight',
                        'normal'
                    );
            }
            if (/\bitalic\b/i.test(value)) {
                fontWeightUpdate = fontWeightUpdate
                    .set(
                        'fontStyle',
                        'italic'
                    );
            } else {
                fontWeightUpdate = fontWeightUpdate
                    .set(
                        'fontStyle',
                        'normal'
                    );
            }
            return fontWeightUpdate;
        },
        toStyle(shape) {
            const runStyle = getFirstRunStyle(shape);
            return runStyle.getIn(['font', 'weight'], 'normal');
        }
    },
    fontSize: {
        itemUpdatePropertyName: 'fontSize',
        toStyle(shape) {
            const runStyle = getFirstRunStyle(shape);
            return runStyle.getIn(['font', 'size'], 0);
        }
    },
    fill: {
        toItemUpdate(value, shapeStyle = Map()) {
            return shapeStyle
                .set(
                    'fill',
                    Fill.getFirstColorValueDescriptor(value)
                );
        },
        toStyle(shape) {
            const runStyle = getFirstRunStyle(shape);
            return Fill.createSolidFill(runStyle.get('color', Map()));
        },
        isFill: true
    },
    opacity: {
        itemUpdatePropertyName: 'textOpacity',
        toStyle(shape) {
            const runStyle = getFirstRunStyle(shape);
            return runStyle.get('opacity');
        }
    },
    effect: typefaceEffectConversion,
    characterSpacing: {
        itemUpdatePropertyName: 'characterSpacing',
        toStyle(shape) {
            const runStyle = getFirstRunStyle(shape);
            return runStyle.get('characterSpacing');
        }
    }
};
