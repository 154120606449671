import React from 'react';
import { Route } from 'react-router-dom';
import LocalErrorBoundary from '#Components/Common/ErrorBoundary/LocalErrorBoundary';

const ErrorHandlingRoute = props => (
    <LocalErrorBoundary>
        <Route {...props} />
    </LocalErrorBoundary>
);

export default ErrorHandlingRoute;
