import { fromJS, List } from 'immutable';
import TreeNode from '../../../fabric-adapter/DecksignFabricShapeType/Groups/TreeNode';
import {
    getShapePathWithLayer,
    updateSelection
} from '../../Helpers/helpers';

const ungroup = canvasState => {
    const selection = canvasState.get('selection');
    let ungroupedShapes = new List([]);

    let updatedCanvasState = canvasState;

    selection.forEach(id => {
        const groupPath = getShapePathWithLayer(canvasState, id);
        const insertionIndex = groupPath[groupPath.length - 1];

        const rootGroup = groupPath.length > 2 ?
            canvasState.getIn(groupPath.slice(0, 2)) : undefined;

        const shapes = canvasState
            .getIn([...groupPath, 'shapes'])
            .map(shape => {
                const groupShape = canvasState.getIn(groupPath);
                return shape
                    .set('x', shape.get('x') + groupShape.get('x'))
                    .set('y', shape.get('y') + groupShape.get('y'));
            });

        updatedCanvasState = updatedCanvasState
            .setIn(
                groupPath.slice(0, -1), // Target List before last index
                updatedCanvasState.getIn(
                    groupPath.slice(0, -1)
                ).splice(insertionIndex, 0, ...shapes) // Add group at this index
            );

        ungroupedShapes = ungroupedShapes.push(...shapes.map(shape => shape.get('id')));

        updatedCanvasState = updatedCanvasState.removeIn(getShapePathWithLayer(updatedCanvasState, id));

        updatedCanvasState = updatedCanvasState.set('groupTree', TreeNode.generateTree(rootGroup));
    });

    updatedCanvasState = updateSelection(updatedCanvasState, fromJS({ selection: ungroupedShapes }));

    return updatedCanvasState;
};

export default ungroup;
