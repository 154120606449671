import { getShapePathById } from '../../../../../../Canvas/CanvasStateSelectors';

const getContentPath = (table, cell, content, canvasState) => {
    const cellIndex = table.cells
        .findIndex(currentCell => currentCell.id === cell.id);

    const contentIndex = table.cells[cellIndex].contents
        .findIndex(currentContent => currentContent.id === content.id);

    return [
        ...getShapePathById(canvasState, table.id),
        'cells',
        cellIndex,
        'contents',
        contentIndex
    ];
};

export {
    // eslint-disable-next-line import/prefer-default-export
    getContentPath
};
