const {
    DEFAULT_PARAGRAPHSTYLE,
    DEFAULT_RUNSTYLE,
    DEFAULT_TEXT_BODY_STYLE
} = require('../config/defaultStyles');

const textbody = {
    Textbox: {
        DEFAULT_RUNSTYLE: {
            ...DEFAULT_RUNSTYLE,
            color: {
                type: 'custom',
                value: 'rgba(77, 77, 77, 1)'
            }
        },
        DEFAULT_PARAGRAPHSTYLE: {
            ...DEFAULT_PARAGRAPHSTYLE,
            align: 'left'

        },
        DEFAULT_TEXT_BODY_STYLE: {
            ...DEFAULT_TEXT_BODY_STYLE,
            verticalAlign: 'top',
            textDirection: 'horizontal'

        }
    },
    Path: {
        DEFAULT_RUNSTYLE: {
            ...DEFAULT_RUNSTYLE,
            color: {
                type: 'custom',
                value: 'rgba(77, 77, 77, 1)'
            }
        },
        DEFAULT_PARAGRAPHSTYLE: {
            ...DEFAULT_PARAGRAPHSTYLE,
            align: 'left'
        },
        DEFAULT_TEXT_BODY_STYLE: {
            ...DEFAULT_TEXT_BODY_STYLE,
            verticalAlign: 'top',
            textDirection: 'horizontal'

        }
    },
    DEFAULT: {
        DEFAULT_RUNSTYLE: { ...DEFAULT_RUNSTYLE },
        DEFAULT_PARAGRAPHSTYLE: { ...DEFAULT_PARAGRAPHSTYLE },
        DEFAULT_TEXT_BODY_STYLE: { ...DEFAULT_TEXT_BODY_STYLE }
    }
};

module.exports = {
    getStyleProviderForClass(Base) {
        return class StyleProvider extends Base {
            // eslint-disable-next-line class-methods-use-this
            loadDefaultTextBodyStyleConfiguration(name) {
                const style = {
                    ...(!!name && Object.prototype.hasOwnProperty.call(textbody, name) ?
                        textbody[name] :
                        textbody.DEFAULT)
                };
                return style;
            }
        };
    },
    loadTextBodyDefaults(type = '') {
        const defaultType = Object.keys(textbody)
            .find(key => new RegExp(type.toLowerCase(), 'i').test(key)) || 'DEFAULT';
        return textbody[defaultType];
    }
};
