import getSource from '../PropertyAdapters/image/source';
import getPath from '../PropertyAdapters/image/path';
import defaultShapeToFabricJSON from './default';
import getFill from '../PropertyAdapters/image/fill';

const gapAroundImageInPx = 200;

const scaleAtCreation = (imageSize, pageSize, shape) => {
    const scaleX = Math.min((pageSize.pageWidth - gapAroundImageInPx) / imageSize.width, 1);
    const scaleY = Math.min((pageSize.pageHeight - gapAroundImageInPx) / imageSize.height, 1);
    const scale = Math.min(scaleX, scaleY);

    return shape
        .update('width', width => width * scale)
        .update('height', height => height * scale);
};

const fromShapeToFabricJSON = (shape, canvasState) => {
    const pageSize = {
        pageWidth: shape.get('pageWidth'),
        pageHeight: shape.get('pageHeight')
    };

    const imageSize = {
        width: shape.get('width'),
        height: shape.get('height')
    };

    let updatedShape = shape;

    if (pageSize.pageWidth && pageSize.pageHeight &&
        imageSize.width && imageSize.height && !shape.get('isBackground')) {
        if (shape.get('width') > pageSize.pageWidth || shape.get('height') > pageSize.pageHeight) {
            updatedShape = scaleAtCreation(imageSize, pageSize, updatedShape);
        }
    }

    return {
        ...defaultShapeToFabricJSON(updatedShape, canvasState),
        type: 'customImage',
        shapeType: 'CustomImage',
        fill: getFill(shape),
        source: getSource(updatedShape),
        path: getPath(updatedShape),
        clipTo: shape.get('clipTo'),
        lockAspectRatio: true
    };
};

export default fromShapeToFabricJSON;
