import { updateTable } from '../../Helpers/Table/table';
import { convertPropertiesToShapeProperties } from '../../utilities/convertPropertiesToShapeProperties';
import getPropertiesForDestructuring from '../../utilities/getPropertiesForDestructuring';

const updateProperties = (canvasState, command, ids = null) => {
    const { properties } = getPropertiesForDestructuring(command, ['properties']);
    const updates = convertPropertiesToShapeProperties(properties);

    if (ids) {
        return ids.reduce((currentCanvasState, id) => updateTable(currentCanvasState, id, updates), canvasState);
    }
    return canvasState
        .get('selection').reduce((currentCanvasState, id) => updateTable(currentCanvasState, id, updates), canvasState);
};

export default updateProperties;
