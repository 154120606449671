export const INIT_VIEW = 'INIT_VIEW';
export const PAUSE_SESSION_TIMER = 'PAUSE_SESSION_TIMER';
export const POST_DECK_COMMENT = 'POST_DECK_COMMENT';
export const POST_PAGE_COMMENT = 'POST_PAGE_COMMENT';
export const DELETE_PAGE_COMMENT = 'DELETE_PAGE_COMMENT';
export const DELETE_DECK_COMMENT = 'DELETE_PAGE_COMMENT';
export const EDIT_PAGE_COMMENT = 'EDIT_PAGE_COMMENT';
export const EDIT_DECK_COMMENT = 'EDIT_DECK_COMMENT';
export const POST_TIMER_END = 'POST_TIMER_END';
export const POST_TIMER_START = 'POST_TIMER_START';
export const PUT_PAGE_FEEDBACKS = 'PUT_PAGE_FEEDBACKS';
export const SET_SESSION_TIMER_TRACKED_PAGE = 'SET_SESSION_TIMER_TRACKED_PAGE';
export const SET_VIEWER_ACTIVE_PAGE = 'SET_VIEWER_ACTIVE_PAGE';
export const SET_VIEWER_BLACK_PAGE = 'SET_VIEWER_BLACK_PAGE';
export const SET_VIEWER_WHITE_PAGE = 'SET_VIEWER_WHITE_PAGE';

export const SET_VIEWER_SETTINGS = 'SET_VIEWER_SETTINGS';
export const SPLIT_SESSION_TIMER = 'SPLIT_SESSION_TIMER';
export const START_SESSION_TIMER = 'START_SESSION_TIMER';
export const VIEWER_LOADED = 'VIEWER_LOADED';
export const VIEWER_LOADING = 'VIEWER_LOADING';
