const { fabric } = require('fabric');

const { getGradientObject } = require('./gradientGenerator');
const fillProperties = require('./fillProperties');

module.exports = ({
    textShape, shape, fill, targetProperty, angle
}) => new Promise(resolve => {
    if (typeof fill === 'string') {
        shape[targetProperty] = fill;
        resolve();
    } else if (!fill || !fill.type) {
        shape[targetProperty] = 'rgba(0, 0, 0, 0)';// Backup so it doesn't crash if it's missing for tests
        resolve();
    } else if (fill.type === 'color') {
        shape[targetProperty] = fill.color.value;
        resolve();
    } else if (fill.type === 'gradient') {
        shape.setGradient('fill', getGradientObject({
            gradient: fill.gradient,
            width: shape.width,
            height: shape.height,
            rotation: angle || 0
        }));
        resolve();
    } else if (fill.type === 'imageSource' && fill.tile) {
        // The fetch is useful for making sure that the origin is set in request headers
        fetch(fill.imageSource, {
            redirect: 'manual',
            headers: {
                Accept: 'image/webp,image/apng,image/*,*/*;q=0.8'
            }
        })
            .then(() => {
                fabric.util.loadImage(fill.imageSource, img => {
                    shape[targetProperty] = new fabric.Pattern({
                        source: img,
                        repeat: 'repeat',
                        crossOrigin: 'Anonymous'
                    });
                    resolve();
                }, null, 'Anonymous');
            });
    } else if (fill.type === 'imageSource') {
        // The fetch is useful for making sure that the origin is set in request headers
        fetch(fill.imageSource, {
            redirect: 'manual',
            headers: {
                Accept: 'image/webp,image/apng,image/*,*/*;q=0.8'
            }
        })
            .then(() => {
                // see: http://fabricjs.com/dynamic-patterns
                fabric.Image.fromURL(fill.imageSource, img => {
                    if (img && shape) {
                    // When the image is empty or broken
                        if (img.width === 0 && img.height === 0) {
                            shape[targetProperty] = 'rgba(0, 0, 0, 0)';
                            resolve();
                            return;
                        }
                        const staticCanvas = new fabric.StaticCanvas(
                            undefined,
                            { enableRetinaScaling: false }
                        );
                        const fillProps = fillProperties(fill, shape, img);
                        img.scaleX = fillProps.imageScaleX;
                        img.scaleY = fillProps.imageScaleY;
                        staticCanvas.add(img);
                        staticCanvas.renderAll();
                        const pattern = new fabric.Pattern({
                            source: () => {
                                staticCanvas.setDimensions({
                                    width: img.getScaledWidth(),
                                    height: img.getScaledHeight()
                                });
                                staticCanvas.renderAll();
                                return staticCanvas.getElement();
                            },
                            repeat: 'no-repeat',
                            rossOrigin: 'Anonymous'
                        });
                        pattern.offsetX = fillProps.offsetX;
                        pattern.offsetY = fillProps.offsetY;
                        shape[targetProperty] = pattern;
                        textShape.imgEl = img;
                        resolve();
                    } else {
                        resolve();
                    }
                }, { crossOrigin: 'Anonymous' });
            });
    } else if (fill.type === 'none') {
        shape[targetProperty] = 'rgba(0, 0, 0, 0)';
        resolve();
    } else {
        shape[targetProperty] = 'rgba(0, 0, 0, 0)';
        resolve();
    }
});
