const zeroSpaceChars = [
    '\u200e'
];

const zeroSpaceRegex = new RegExp(`${zeroSpaceChars.join('|')}`, 'g');

const removeZeroSpaceChars = text => text.replace(zeroSpaceRegex, '');

module.exports = {
    removeZeroSpaceChars
};
