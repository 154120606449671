export const en = {

    'App.Navigation.Plan': 'Plan',
    'App.Navigation.Share': 'Share',
    'App.Navigation.Build': 'Build',
    'App.Navigation.Manage': 'Manage',

    'Build.Navigation.Menu.Button.Pages': 'Pages',
    'Build.Navigation.Menu.Button.Topics': 'Topics',
    'Build.Navigation.Menu.Button.Objects': 'Objects',

    'Build.Toolbar.Menu.Button.Tools': 'Tools',
    'Build.Toolbar.Menu.Button.Page': 'Page',
    'Build.Toolbar.Menu.Button.Deck': 'Deck',

    'Build.Toolbar.Tools.Tool.Inputbox.x': 'x',
    'Build.Toolbar.Tools.Tool.Inputbox.y': 'y',
    'Build.Toolbar.Tools.Tool.Inputbox.width': 'width',
    'Build.Toolbar.Tools.Tool.Inputbox.height': 'height',
    'Build.Toolbar.Tools.Tool.Inputbox.rotate': 'rotate',
    'Build.Toolbar.Tools.Tool.Inputbox.border': 'border',
    'Build.Toolbar.Tools.Tool.Inputbox.opacity': 'opacity',
    'Build.Toolbar.Tools.Tool.Inputbox.font size': 'font size',
    'Build.Toolbar.Tools.Tool.Inputbox.line height': 'line height',
    'Build.Toolbar.Tools.Tool.Inputbox.left': 'left',
    'Build.Toolbar.Tools.Tool.Inputbox.right': 'right',
    'Build.Toolbar.Tools.Tool.Inputbox.top': 'top',
    'Build.Toolbar.Tools.Tool.Inputbox.bottom': 'bottom',
    'Build.Toolbar.Tools.Tool.Inputbox.Skew Y': 'Skew Y',
    'Build.Toolbar.Tools.Tool.Inputbox.Skew X': 'Skew X',

    'Build.Toolbars.Tools.Tool.Positions': 'Positions',
    'Build.Toolbars.Tools.Tool.Alignment&Positionning': 'Alignment & Positioning',
    'Build.Toolbars.Tools.Tool.Groups': 'Groups',
    'Build.Toolbars.Tools.Tool.Actions': 'Actions',
    'Build.Toolbars.Tools.Tool.Colors&Styles': 'Colors & Styles',
    'Build.Toolbars.Tools.Tool.Typography': 'Typography',
    'Build.Toolbars.Tools.Tool.Spacings': 'Spacings',
    'Build.Toolbars.Tools.Tool.Transform': 'Transform',

    'Build.Workspace.Header.Insert.InsertButton.Insert': 'Insert',
    'Build.Workspace.Header.DisplayOptions.DisplayOptions': 'Display Options',

    'Common.ListViews.Item.itemType.decks': 'Deck'

};
