import { createSelector } from 'reselect';
import get from 'lodash/get';

import { getCanCutOrCopyCanvasItem } from './canvasState';
import { checkLayoutReseting } from './layout';
import { checkTextSelected } from './textSelection';

export const getCanCutOrCopy = createSelector([
    getCanCutOrCopyCanvasItem, checkTextSelected
], (canCutOrCopyCanvasItem, isTextSelected) => canCutOrCopyCanvasItem || isTextSelected);

export const checkFontsFetching = state => (state.build.fonts.fetching || []).length > 0;

export const checkCanvasUpdating = state => state.build.isCanvasUpdating;

export const checkFirstRender = state => state.build.isFirstRender;

export const checkIsDuplicatingDeck = state => state.build.isDuplicatingDeck;

export const checkIsNavigatingPages = state => state.build.ui.pagesNavigation.isNavigatingPages;

export const checkLayoutsLoading = state => state.build.layouts.loading;

export const checkLayoutChangeInProgress = state => state.build.ui.shapesNavigation.isLayoutChangeInProgress;

export const checkBuildUpdating = createSelector(
    checkFontsFetching,
    checkCanvasUpdating,
    checkFirstRender,
    checkLayoutReseting,
    checkIsDuplicatingDeck,
    checkIsNavigatingPages,
    checkLayoutsLoading,
    checkLayoutChangeInProgress,
    (isFontsFetching,
        isCanvasUpdating,
        isEditorOpening,
        isFirstRender,
        isLayoutResesting,
        isDuplicatingDeck,
        isNavigatingPages,
        areLayoutLoading,
        isLayoutChangeInProgress) => Boolean((
        isFontsFetching ||
            isCanvasUpdating ||
            isEditorOpening ||
            isLayoutResesting ||
            isDuplicatingDeck ||
            isNavigatingPages ||
            areLayoutLoading ||
            isLayoutChangeInProgress
    ) &&
    !isFirstRender)
);

export const getZoom = createSelector(
    state => get(state, 'build.zoom'),
    zoom => zoom
);
