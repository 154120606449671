exports.UPDATE_PROPERTIES_ON_SELECTED_SHAPES = 'UPDATE_PROPERTIES_ON_SELECTED_SHAPES';
exports.UPDATE_PROPERTIES_FROM_DELTA_ON_SELECTED_SHAPES = 'UPDATE_PROPERTIES_FROM_DELTA_ON_SELECTED_SHAPES';
exports.UPDATE_SELECTION = 'UPDATE_SELECTION';
exports.UPDATE_SELECTION_AND_GROUP = 'UPDATE_SELECTION_AND_GROUP';
exports.EXIT_CONTEXTUAL_SELECTION = 'EXIT_CONTEXTUAL_SELECTION';
exports.SELECT_CELLS = 'SELECT_CELLS';
exports.SPLIT_SELECTED_CELLS = 'SPLIT_SELECTED_CELLS';
exports.CREATE_SHAPES = 'CREATE_SHAPES';
exports.DUPLICATE_SHAPES = 'DUPLICATE_SHAPES';
exports.UPDATE_SHAPES = 'UPDATE_SHAPES';
exports.UPDATE_SHAPES_PROPERTIES = 'UPDATE_SHAPES_PROPERTIES';
exports.UPDATE_SHAPES = 'UPDATE_SHAPES';
exports.DELETE_SELECTED_SHAPES = 'DELETE_SELECTED_SHAPES';
exports.DELETE_ALL_SHAPES = 'DELETE_ALL_SHAPES';
exports.CHANGE_SHAPE_INDEX = 'CHANGE_SHAPE_INDEX';
exports.GROUP_SELECTED_SHAPES = 'GROUP_SELECTED_SHAPES';
exports.UNGROUP_SELECTED_SHAPES = 'UNGROUP_SELECTED_SHAPES';
exports.FLIP_SELECTED_SHAPES = 'FLIP_SELECTED_SHAPES';
exports.SET_CLIPBOARD = 'SET_CLIPBOARD';
exports.COPY_SELECTED_SHAPES = 'COPY_SELECTED_SHAPES';
exports.PASTE_CLIPBOARD_CONTENT = 'PASTE_CLIPBOARD_CONTENT';
exports.DISTRIBUTE_SELECTED_SHAPES = 'DISTRIBUTE_SELECTED_SHAPES';
exports.ALIGN_SELECTED_SHAPES = 'ALIGN_SELECTED_SHAPES';
exports.MERGE_SELECTED_CELLS = 'MERGE_SELECTED_CELLS';
exports.ADD_TABLE_LINE = 'ADD_TABLE_LINE';
exports.REMOVE_TABLE_LINE = 'REMOVE_TABLE_LINE';
exports.LAYOUT_RESET = 'LAYOUT_RESET';
exports.APPLY_LAYOUT_ON_PAGE = 'APPLY_LAYOUT_ON_PAGE';
exports.TOGGLE_SHAPE_LOCK = 'TOGGLE_SHAPE_LOCK';
exports.LOCK_BACKGROUND_SHAPES = 'LOCK_BACKGROUND_SHAPES';
exports.TOGGLE_SHAPE_VISIBILITY = 'TOGGLE_SHAPE_VISIBILITY';
exports.CUT_SELECTED_SHAPES = 'CUT_SELECTED_SHAPES';
exports.UPDATE_COLOR_PALETTE = 'UPDATE_COLOR_PALETTE';
exports.UPDATE_PAGE_SIZE = 'UPDATE_PAGE_SIZE';
exports.UPDATE_PAGE_BACKGROUND = 'UPDATE_PAGE_BACKGROUND';
exports.TOGGLE_EDIT_MODE = 'TOGGLE_EDIT_MODE';
exports.CHANGE_LAYOUT_NAME = 'CHANGE_LAYOUT_NAME';
exports.UPDATE_DYNAMIC_VALUES = 'UPDATE_DYNAMIC_VALUES';
exports.CLEAR_SELECTED_CELLS = 'CLEAR_SELECTED_CELLS';
exports.UPDATE_COLOR_PRESETS = 'UPDATE_COLOR_PRESETS';
exports.UPDATE_CELL_CONTENT_TEXTBODY = 'UPDATE_CELL_CONTENT_TEXTBODY';
exports.CONVERT_SELECTED_SHAPES_TEXT_ITEMS_LEVEL = 'CONVERT_SELECTED_SHAPES_TEXT_ITEMS_LEVEL';

exports.SELECTION_COMMANDS = [
    exports.UPDATE_SELECTION,
    exports.EXIT_CONTEXTUAL_SELECTION,
    exports.SELECT_CELLS,
    exports.UPDATE_SELECTION_AND_GROUP
];

exports.NOT_PERSISTABLE_COMMANDS = [
    exports.UPDATE_SELECTION,
    exports.EXIT_CONTEXTUAL_SELECTION,
    exports.SELECT_CELLS,
    exports.UPDATE_SELECTION_AND_GROUP
];
