const get = require('lodash/get');

module.exports = {
    DEFAULT_TEXT_DIRECTION: 'horizontal',
    DEFAULT_VERTICAL_ALIGN: 'middle',

    getTextDirection() {
        return this.textDirection || this.DEFAULT_TEXT_DIRECTION;
    },

    isTextDirectionVertical() {
        return this.getTextDirection().startsWith('vertical');
    },

    getTextDrawZoneLimits() {
        const textDirection = this.getTextDirection();

        const textDrawZoneLimits = {
            textDirection
        };

        switch (textDirection) {
            case 'vertical270':
            case 'vertical':
                textDrawZoneLimits.width = this.getTextDrawZoneHeight();
                break;
            case 'horizontal':
            default:
                textDrawZoneLimits.width = this.getTextDrawZoneWidth();
                break;
        }

        return textDrawZoneLimits;
    },

    getTextBodyMargin(side) {
        if (['bottom', 'left', 'right', 'top'].includes(side)) {
            return get(this, `textBodyMargins.${side}`, 0);
        }
        throw new Error(`The ${side} is an invalid textbody margin.`);
    },

    isTextItemsBiggerThanShape(textItems) {
        return this.hasHorizontalOverflow(textItems) || this.hasVerticalOverflow(textItems);
    },

    getTextItemsWidth(textItems = this.getTextObject()) {
        const maxWidth = textItems.paragraphs.length > 0 ?
            textItems.calcTextWidth() :
            0;

        const textItemsWidth = this.isTextDirectionVertical() ?
            textItems.getScaledHeight() :
            maxWidth;

        return textItemsWidth + (this.getTextBodyMargin('left') + this.getTextBodyMargin('right'));
    },

    hasHorizontalOverflow(textItems = this.getTextObject()) {
        const textBounds = this.getTextBounds();
        const horizontalMarginsSize = this.getTextBodyMargin('left') + this.getTextBodyMargin('right');
        const horizontalBordersSize = this.getHorizontalBorderSize() * 2;
        return this.getTextItemsWidth(textItems) >
            Math.max(textBounds.width - (
                horizontalBordersSize
            ), horizontalMarginsSize);
    },

    getTextItemsHeight(textItems = this.getTextObject()) {
        const maxHeight = textItems.paragraphs.length ?
            textItems.calcTextWidth() :
            0;

        const textItemsHeight = this.isTextDirectionVertical() ?
            maxHeight :
            textItems.height;

        return textItemsHeight + this.getTextBodyMargin('bottom') + this.getTextBodyMargin('top');
    },

    hasVerticalOverflow(textItems = this.getTextObject()) {
        const textBounds = this.getTextBounds();
        const verticalMarginsSize = this.getTextBodyMargin('bottom') + this.getTextBodyMargin('top');
        const verticalBordersSize = this.getVerticalBorderSize() * 2;
        return this.getTextItemsHeight(textItems) >
            Math.max(textBounds.height - (
                verticalBordersSize
            ), verticalMarginsSize);
    },

    getTextMarginsForEdit() {
        return {
            bottom: this.getTextBodyMargin('bottom') + this.getVerticalBorderSize(),
            left: this.getTextBodyMargin('left') + this.getHorizontalBorderSize(),
            right: this.getTextBodyMargin('right') + this.getHorizontalBorderSize(),
            top: this.getTextBodyMargin('top') + this.getVerticalBorderSize()
        };
    },

    getTextItemsAngle() {
        const textDirection = this.getTextDirection();
        const shape = this.getShapeObject();

        const angle = shape.flipY ? 180 : 0;

        if (textDirection === 'vertical') {
            return angle + 90;
        }
        if (textDirection === 'vertical270') {
            return angle + 270;
        }
        return angle;
    },

    getTextDrawZoneWidth() {
        const { width } = this.getTextBounds();
        const margins = this.getTextBodyMargin('left') + this.getTextBodyMargin('right');
        const borders = this.getHorizontalBorderSize() * 2;
        return width - (margins + borders);
    },

    getTextDrawZoneHeight() {
        const { height } = this.getTextBounds();
        const margins = this.getTextBodyMargin('bottom') + this.getTextBodyMargin('top');
        const borders = this.getHorizontalBorderSize() * 2;
        return height - (margins + borders);
    },

    getTextDrawZoneLeftOffset() {
        const { left } = this.getTextBounds();
        return left + this.getTextBodyMargin('left') + this.getHorizontalBorderSize();
    },

    getTextDrawZoneTopOffset() {
        const { top } = this.getTextBounds();
        return top + this.getTextBodyMargin('top') + this.getVerticalBorderSize();
    },

    getTextDrawZoneBottomOffset() {
        const { bottom } = this.getTextBounds();
        return bottom - (this.getTextBodyMargin('bottom') + this.getVerticalBorderSize());
    },

    getVerticalAlign() {
        return this.verticalAlign || this.DEFAULT_VERTICAL_ALIGN;
    },

    placeTextItemsInTextDrawZone(textItems) {
        switch (this.getTextDirection()) {
            case 'vertical270':
                this.placeTextItemsForVertical270Direction(textItems);
                break;
            case 'vertical':
                this.placeTextItemsForVerticalDirection(textItems);
                break;
            case 'horizontal':
            default:
                this.placeTextItemsForHorizontalDirection(textItems);
                break;
        }
    },

    placeTextItemsForVerticalDirection(textItems) {
        const { left, right, x } = this.getTextBounds();
        const shape = this.getShapeObject();
        textItems.top = this.getTextDrawZoneTopOffset(textItems);
        textItems.top += shape.flipY ? textItems.width : 0;
        switch (this.getVerticalAlign()) {
            case 'top':
                textItems.left = right -
                    (this.getTextBodyMargin('left'));
                textItems.left -= this.getHorizontalBorderSize();
                break;
            case 'bottom':
                textItems.left = left +
                    this.getTextBodyMargin('right') + textItems.height;
                textItems.left += this.getHorizontalBorderSize();
                break;
            case 'middle':
            default:
                textItems.left = (x + (shape.flipY ? -textItems.height : textItems.height) / 2) - (
                    this.getTextBodyMargin('left') -
                    this.getTextBodyMargin('right')
                );
                break;
        }
    },

    placeTextItemsForVertical270Direction(textItems) {
        const { left, right, x } = this.getTextBounds();
        const shape = this.getShapeObject();
        textItems.top = this.getTextDrawZoneBottomOffset(textItems);
        textItems.top -= shape.flipY ? textItems.width : 0;
        switch (this.getVerticalAlign()) {
            case 'top':
                textItems.left = left +
                    this.getTextBodyMargin('left');
                textItems.left += this.getHorizontalBorderSize();
                break;
            case 'bottom':
                textItems.left = right -
                    (this.getTextBodyMargin('right') + textItems.height);
                textItems.left -= this.getHorizontalBorderSize();
                break;
            case 'middle':
            default:
                textItems.left = x - ((shape.flipY ? -textItems.height : textItems.height) / 2) - (
                    this.getTextBodyMargin('right') -
                    this.getTextBodyMargin('left')
                );
                break;
        }
    },

    placeTextItemsForHorizontalDirection(textItems) {
        const { top, bottom, y } = this.getTextBounds();
        const shape = this.getShapeObject();
        textItems.left = this.getTextDrawZoneLeftOffset();
        textItems.left += shape.flipY ? textItems.width : 0;
        switch (this.getVerticalAlign()) {
            case 'top':
                textItems.top = top +
                    this.getTextBodyMargin('top');
                textItems.top += this.getVerticalBorderSize();
                break;
            case 'bottom':
                textItems.top = bottom -
                    (this.getTextBodyMargin('bottom') + textItems.height);
                textItems.top -= this.getVerticalBorderSize();
                break;
            case 'middle':
            default:
                textItems.top = (y + (shape.flipY ? textItems.height : -textItems.height) / 2) + (
                    this.getTextBodyMargin('top') -
                    this.getTextBodyMargin('bottom')
                );
                break;
        }
    },

    enforceShapeAutoFit(textItems) {
        if (this.autoFitShape) {
            let widthOffset = 0;
            let heightOffset = 0;
            if (this.isTextDirectionVertical()) {
                if (this.hasVerticalOverflow(textItems)) {
                    heightOffset = (this.getTextItemsHeight(textItems) +
                        (this.getVerticalBorderSize() * 2)) - this.height;
                }
                widthOffset = (this.getTextItemsWidth(textItems) +
                    (this.getHorizontalBorderSize() * 2)) - this.width;
            } else {
                if (this.hasHorizontalOverflow(textItems)) {
                    widthOffset = (this.getTextItemsWidth(textItems) +
                        (this.getVerticalBorderSize() * 2)) - this.width;
                }
                if (this.getTextItemsHeight(textItems) > 0) {
                    heightOffset = (this.getTextItemsHeight(textItems) +
                        (this.getHorizontalBorderSize() * 2)) - this.height;
                }
            }
            this.updateShapeScaleToDimension({
                height: this.height + heightOffset,
                width: this.width + widthOffset
            });
            this.updatePositionOnShapeAutoFit(heightOffset);
            this.updateModificationHandlers();
            this.setCoords();
            this.set('dirty', true);
        }
    },

    updatePositionOnShapeAutoFit(heightOffset) {
        const verticalAlign = this.getVerticalAlign();
        if (verticalAlign === 'top') {
            this.top += heightOffset / 2;
        } else if (verticalAlign === 'bottom') {
            this.top -= heightOffset / 2;
        }
    },

    updateTextSize(inputSize) {
        const newSize = {
            height: (
                inputSize.height < this.getShapeObject().height ?
                    inputSize.height :
                    this.getShapeObject().height
            ),
            width: (
                inputSize.width < this.getShapeObject().width ?
                    inputSize.width :
                    this.getShapeObject().width
            )
        };
        const text = this.getTextObject();
        if (text) {
            text.set({
                left: -(newSize.height / 2),
                height: inputSize.height,
                top: -(newSize.width / 2),
                width: inputSize.width
            });
        }
        this.set({
            width: this.getShapeObject().width,
            height: this.getShapeObject().height
        });
        this.canvas.renderAll();
    }
};
