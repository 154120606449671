const AxisLocking = require('../DecksignFabricShapeType/Mixins/AxisLocking');
const Duplicate = require('../DecksignFabricShapeType/Mixins/Duplicate');

module.exports = fabric => {
    /* eslint-disable */
    fabric.util.object.extend(fabric.Group.prototype, AxisLocking);
    fabric.util.object.extend(fabric.Group.prototype, Duplicate);

    fabric.Group.prototype.initialize = (function (fabricInitialize) {
        return function () {
            fabricInitialize.apply(this, arguments);
            this.on('mousedown', event => {
                this.setInitialPositions(event);
                if (event.e.metaKey && this.type === 'group') {
                    this.duplicateShape();
                }
            });
            this.on('moving', event => {
                this.handleAxisLocking(event);
            });
            this.on('modified', event => {
                this.resetAxisLocking();
            });
            this.on('mouseup', event => {
                if (event.e.metaKey && this.type === 'group') {
                    this.changeOriginalShapeName();
                }
            });
        }
    })(fabric.Group.prototype.initialize);

    fabric.Group.prototype.updateScale = function (scaleX, scaleY) {
        this.scaleX = 1.0;
        this.scaleY = 1.0;
        this.width *= scaleX;
        this.height *= scaleY;
        return Promise.all(this._objects.map(object => {
            object.dirty = true;
            object.left *= scaleX;
            object.top *= scaleY;
            object.updateScale(scaleX, scaleY, true);
        })).then(() => {
            this.addWithUpdate();
            this.dirty = true;
        });
    };

    fabric.Group.prototype.getActiveObjectLeft = function () {
        return this.left;
    };

    fabric.Group.prototype.getActiveObjectTop = function () {
        return this.top;
    };

    fabric.Group.prototype.setActiveObjectLeft = function (left) {
        this.left = left;
    };

    fabric.Group.prototype.setActiveObjectTop = function (top) {
        this.top = top;
    };

    fabric.Group.prototype.renderDecorations = function (canvas) {
        this._objects.forEach(object => object.renderDecorations(canvas));
    };

    fabric.Group.prototype.rotateAboutOrigin = function (obj, degrees = 0)  {
        const radians = fabric.util.degreesToRadians(degrees);

        const rotatedPoint = fabric.util.rotatePoint(
            new fabric.Point(obj.left, obj.top),
            new fabric.Point(0, 0),
            radians
        );

        obj.left = rotatedPoint.x;
        obj.top = rotatedPoint.y;

        return obj
    }

    // Must avoid making anonymous function an arrow function
    // the context should not be modified
    fabric.Group.prototype.onSelect = (function (oldOnSelect) {
        return function () {
            oldOnSelect.apply(this, arguments);
            if (this.containsChildWithType('table')) {
                this.setControlVisible('mtr', false); // hides handler
            }
        }
    })(fabric.Group.prototype.onSelect);

    fabric.Group.prototype.containsChildWithType = function (type) {
        return this.getObjects().find(obj => obj.type === type);
    };
    
    /*
        Replicates the behaviour of insertAt (line 355 of fabric.js)
        but with an update to the bounds and coordinates, in continuity to
        addWithUpdate (line 18854 of fabric.js)
    */
    fabric.Group.prototype.insertAtWithUpdate = function(object, index, nonSplicing) {
        this._restoreObjectsState();
        fabric.util.resetObjectTransform(this);
        if (nonSplicing) {
            this._objects[index] = object;
        } else {
            this._objects.splice(index, 0, object);
        }
        object.group = this;
        object._set('canvas', this.canvas);
        this._calcBounds();
        this._updateObjectsCoords();
        this.setCoords();
        this.dirty = true;
        return this;
    }
    
    /*
    * Part of the missing code for handling nested groups in addWithUpdate in Fabric 4.4.0
    * See PR https://github.com/fabricjs/fabric.js/pull/6774/
    */
    fabric.Group.prototype.addWithUpdate = (function (originalFn) {
        return function (object) {
            var nested = !!this.group;
            this._restoreObjectsState();
            fabric.util.resetObjectTransform(this);
            if (object) {
                if (nested) {
                    // if this group is inside another group, we need to pre transform the object
                    fabric.util.removeTransformFromObject(object, this.group.calcTransformMatrix());
                }
                this._objects.push(object);
                object.group = this;
                object._set('canvas', this.canvas);
            }
            this._calcBounds();
            this._updateObjectsCoords();
            this.dirty = true;
            if (nested) {
                this.group.addWithUpdate();
            }
            else {
                this.setCoords();
            }
            return this;
        }
    })(fabric.Group.prototype.addWithUpdate);

    /*
    * Custom code for handling removeWithUpdate for nested shapes
    */
    fabric.Group.prototype.removeWithUpdate = (function (originalFn) {
        return function(object) {
            var nested = !!this.group;
            this._restoreObjectsState();
            fabric.util.resetObjectTransform(this);

            if (object) {
                if (nested) {
                    // if this group is inside another group, we need to pre transform the object
                    fabric.util.removeTransformFromObject(object, fabric.util.invertTransform(this.group.calcTransformMatrix()));
                }
            }

            this.remove(object);
            this._calcBounds();
            this._updateObjectsCoords();
            this.setCoords();
            this.dirty = true;
            return this;
        }
    })(fabric.Group.prototype.removeWithUpdate)

    /*
    * Part of the missing code for handling nested groups in addWithUpdate in Fabric 4.4.0
    * See PR https://github.com/fabricjs/fabric.js/pull/6774/
    */
    fabric.Group.prototype._restoreObjectsState = (function (originalFn) {
        return function () {
            var groupMatrix = this.calcOwnMatrix();
            this._objects.forEach(function(object) {
                // instead of using _this = this;
                fabric.util.addTransformToObject(object, groupMatrix);
                delete object.group;
                object.setCoords();
            });
            return this;
        }
    })(fabric.Group.prototype._restoreObjectsState);

    /*
    * Part of the missing code for handling nested groups in addWithUpdate in Fabric 4.4.0
    * See PR https://github.com/fabricjs/fabric.js/pull/6774/
    */
    fabric.Group.prototype.realizeTransform = (function (originalFn) {
        return function (object, parentMatrix) {
            fabric.util.addTransformToObject(object, parentMatrix);
            return object;
        }
    })(fabric.Group.prototype.realizeTransform);

    /*
    * Part of the missing code for handling nested groups in addWithUpdate in Fabric 4.4.0
    * See PR https://github.com/fabricjs/fabric.js/pull/6774/
    */
    fabric.Group.prototype._calcBounds = (function (originalFn) {
        return function (onlyWidthHeight) {
            var aX = [],
                aY = [],
                o, prop, coords,
                props = ['tr', 'br', 'bl', 'tl'],
                i = 0, iLen = this._objects.length,
                j, jLen = props.length;

            for (; i < iLen; ++i) {
                o = this._objects[i];
                coords = o.calcACoords();
                for (j = 0; j < jLen; j++) {
                    prop = props[j];
                    aX.push(coords[prop].x);
                    aY.push(coords[prop].y);
                }
                o.aCoords = coords;
            }

            this._getBounds(aX, aY, onlyWidthHeight);
        }
    })(fabric.Group.prototype._calcBounds);

    /*
    * Override so ActiveSelection is broken down instead of being kept as a group
    */
    fabric.Group.prototype.toObject = function toObject(propertiesToInclude) {
        var _includeDefaultValues = this.includeDefaultValues;
        var objsToObject = [];

        this._objects.forEach(obj => {
            if (obj.type === 'activeSelection') {
                objsToObject.push(...obj._objects.reduce((aObjects, aObj) => {
                    if (!this.canvas.isGroupChild(aObj)) return aObjects;

                    var originalDefaults = aObj.includeDefaultValues;
                    aObj.includeDefaultValues = _includeDefaultValues;
                    var _obj = aObj.toObject(propertiesToInclude);
                    aObj.includeDefaultValues = originalDefaults;
                    _obj = this.rotateAboutOrigin(_obj, obj.angle)
                    _obj.angle += obj.angle;
                    _obj.left += obj.left;
                    _obj.top += obj.top;
                    return [ ...aObjects, _obj];
                }, []))
            } else {
                var originalDefaults = obj.includeDefaultValues;
                obj.includeDefaultValues = _includeDefaultValues;
                var _obj = obj.toObject(propertiesToInclude);
                obj.includeDefaultValues = originalDefaults;
                objsToObject.push(_obj);
            }
        });

        var obj = fabric.Object.prototype.toObject.call(this, propertiesToInclude);
        obj.objects = objsToObject;
        return obj;
    }
};
