import {
    fetchFontsWithFetchedMetrics,
    updateFontLoader
} from 'decksign-font-server-interface';
import { api as decksignCanvasApi } from 'decksign-canvas';

import api from '#Utilities/api';
import { getFontsList } from '#Selectors/build/canvasState';

import client from '#Utilities/graphql';

import * as types from '#Constants/ActionTypes';

const {
    setFontMetrics,
    fabric
} = decksignCanvasApi;

const {
    urlBase: API_URL_BASE
} = api;

const getFont = font => (dispatch, getState) => {
    const loadedFonts = getState().build.fonts.deck;
    if (!loadedFonts.includes(font)) {
        dispatch({
            type: types.GET_PAGE_FONTS,
            fonts: [font]
        });
    }
};

const splitFontRequetsInStatusGroup = fontRequests => fontRequests
    .reduce(({ fetchedFonts, unresolvedFonts }, { name, status }) => {
        if (status === 'fetched') {
            return {
                fetchedFonts: [...fetchedFonts, name],
                unresolvedFonts
            };
        }
        return {
            fetchedFonts,
            unresolvedFonts: [...unresolvedFonts, name]
        };
    }, {
        fetchedFonts: [],
        unresolvedFonts: []
    });

const getProcessedFontListFromState = ({
    build: {
        fonts: {
            fetching = [],
            fetched = [],
            unresolved = []
        } = {}
    } = {}
}) => [
    ...(fetching || []),
    ...(fetched || []),
    ...(unresolved || [])
];

const loadFontPromises = fontLoaders => Promise.all(fontLoaders.map(fontLoader => {
    if (fontLoader.status === 'ready') {
        const fontFace = new FontFace(fontLoader.fontFaceName, fontLoader.fontFaceURL);
        document.fonts.add(fontFace);
        return fontFace.load()
            .then(() => {
                fabric.util.clearFabricFontCache(fontFace.family.toLowerCase());
                return updateFontLoader(fontLoader, {
                    status: 'fetched'
                });
            })
            .catch(() => updateFontLoader(fontLoader, {
                status: 'unresolved'
            }));
    }
    return Promise.resolve(fontLoader);
}));

const fetchAndLoadFontsInDocument = (dispatch, fontNames, fontsRegistry, baseFontFileUrl) => {
    dispatch({
        type: types.FETCHING_FONTS,
        fonts: fontNames
    });

    return fetchFontsWithFetchedMetrics({
        fontNames,
        fontsRegistry,
        baseFontFileUrl,
        fetchMetrics: client.getFontMetricsForFiles
    })
        .then(({ loaders, metrics }) => {
            setFontMetrics(metrics);
            return loadFontPromises(loaders);
        })
        .then(splitFontRequetsInStatusGroup)
        .then(({ unresolvedFonts, fetchedFonts }) => {
            if (Object.keys(fetchedFonts).length > 0) {
                dispatch({
                    type: types.ADD_FETCHED_FONTS,
                    fonts: fetchedFonts
                });
            }
            if (unresolvedFonts.length > 0) {
                dispatch({
                    type: types.FETCHED_FONT_ERRORS,
                    fonts: unresolvedFonts
                });
            }
        });
};

const loadFont = fontName => (dispatch, getState) => {
    const state = getState();

    const baseFontFileUrl = `${API_URL_BASE}/${state.app.config.fontURLBase}`;
    const fontsRegistry = state.build.fonts.registry;
    const processedFonts = getProcessedFontListFromState(state);

    if (
        !processedFonts.includes(fontName)
    ) {
        fetchAndLoadFontsInDocument(dispatch, [fontName], fontsRegistry, baseFontFileUrl);
    }
};

const loadFonts = (dispatch, getState) => {
    const state = getState();
    const pageFonts = getFontsList(state);
    const fontsRegistry = state.build.fonts.registry;
    const baseFontFileUrl = `${API_URL_BASE}/${state.app.config.fontURLBase}`;
    if (fontsRegistry) {
        const processedFonts = getProcessedFontListFromState(state);
        if (!pageFonts) {
            return;
        }
        const fontsToLoad = [...pageFonts]
            .filter(fontName => !processedFonts.includes(fontName));

        if (fontsToLoad.length > 0) {
            fetchAndLoadFontsInDocument(dispatch, fontsToLoad, fontsRegistry, baseFontFileUrl);
        }
    }
};

const getFontsRegistry = () => (dispatch, getState) => {
    const {
        app: {
            config: {
                fontURLBase
            }
        }
    } = getState();

    fetch(`${API_URL_BASE}/${fontURLBase}`, {
        headers: new Headers({
            Accept: 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
        })
    }).then(res => {
        if (!res) {
            return;
        }

        try {
            res.json().then(fonts => dispatch({
                type: types.GET_FONTS_REGISTRY,
                fonts
            }));
        } catch (e) {
            console.info(e);
        }
    });
};

export {
    getFontsRegistry,
    getFont,
    loadFonts,
    loadFont
};
