import DecksignCanvas from 'decksign-canvas';
import get from 'lodash/get';

import * as types from '#Constants/ActionTypes';
import { getCanvasItemUpdateForStyleId } from '#Selectors/build/canvasState';
import client from '#Utilities/graphql';
import { loadFonts } from './fonts';

const {
    api: {
        ItemStyle
    }
} = DecksignCanvas;

export const addStyleDefinitionToDeckTheme = style => (dispatch, getState) => {
    const {
        deck: {
            id
        }
    } = getState();

    dispatch({
        type: types.UPDATE_DECK
    });
    const styleDefinitions = ItemStyle.getStyleDefinitionsFromEffectiveStyle(style);
    return client.addStyleDefinitionsToDeckTheme(id, styleDefinitions)
        .then(deck => {
            dispatch({
                type: types.CHANGE_DECK_PROPERTIES,
                deck
            });

            dispatch({
                type: types.UPDATE_PROPERTIES_ON_SELECTED_SHAPES,
                properties: {
                    style: style.id
                }
            });
        });
};

export const createCanvasItemStyleSelectionAction = (
    styleId,
    removeCustomStyles = false,
    tableStyleMismatches
) => (dispatch, getState) => {
    const state = getState();

    let canvasItemProperties = {
        style: styleId
    };

    if (styleId) {
        canvasItemProperties = getCanvasItemUpdateForStyleId(state)(styleId);
    }

    if (
        canvasItemProperties.listUpdate !== undefined &&
        get(canvasItemProperties, 'listUpdate.allLevels.bullet.type') !== 'none'
    ) {
        dispatch({
            type: types.CONVERT_SELECTED_SHAPES_TEXT_ITEMS_LEVEL,
            oldLevel: 0,
            newLevel: 1
        });
    }
    dispatch({
        type: types.UPDATE_PROPERTIES_ON_SELECTED_SHAPES,
        properties: {
            ...canvasItemProperties,
            tableStyleMismatches,
            removeCustomStyles
        }
    });

    return dispatch(loadFonts);
};
