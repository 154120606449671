import isFull from '../PropertyAdapters/chevron/isFull';
import getPath from '../PropertyAdapters/chevron/path';
import getPoints from '../PropertyAdapters/chevron/points';
import getTipWidth from '../PropertyAdapters/chevron/tipWidth';
import defaultShapeToFabricJSON from './default';

const fromShapeToFabricJSON = (shape, canvasState) => ({
    ...defaultShapeToFabricJSON(shape, canvasState),
    tipWidth: getTipWidth(shape),
    isFull: isFull(shape),
    path: getPath(shape),
    points: getPoints(shape)
});

export default fromShapeToFabricJSON;
