module.exports = {
    id: '#style-definition',
    title: 'StyleDefinition',
    type: 'object',
    properties: {
        id: {
            type: 'string'
        },
        name: {
            type: 'string'
        },
        basedOn: {
            type: 'string'
        },
        kind: {
            type: 'string',
            enum: [
                'ObjectStyle',
                'TableStyle',
                'DiagramStyle',
                'ColorsPreset',
                'ShapePreset',
                'TypefacePreset',
                'ParagraphPreset',
                'StrokePreset',
                'BorderPreset',
                'ListPreset'
            ]
        },
        shapePreset: {
            type: 'string'
        },
        typefacePreset: {
            type: 'string'
        },
        paragraphPreset: {
            type: 'string'
        },
        strokePreset: {
            type: 'string'
        },
        topBorder: {
            type: 'string'
        },
        bottomBorder: {
            type: 'string'
        },
        leftBorder: {
            type: 'string'
        },
        rightBorder: {
            type: 'string'
        },
        internalHorizontalBorder: {
            type: 'string'
        },
        internalVerticalBorder: {
            type: 'string'
        },
        achromaticSchemeColor: {
            type: 'string'
        },
        monochromaticSchemeColor: {
            type: 'string'
        },
        complementarySchemeColor: {
            type: 'string'
        },
        analogousSchemeColor: {
            type: 'string'
        },
        triadicSchemeColor: {
            type: 'string'
        },
        tetradicSchemeColor: {
            type: 'string'
        },
        roundedCorners: {
            type: 'number'
        },
        fill: {
            title: 'fill',
            type: 'object',
            properties: {
                type: {
                    type: 'string'
                },
                color: {
                    title: 'color',
                    type: 'object',
                    properties: {
                        type: {
                            type: 'string',
                            enum: [
                                'coded',
                                'custom'
                            ]
                        },
                        value: {
                            type: 'string'
                        },
                        reference: {
                            type: 'string'
                        }
                    }
                },
                gradient: {
                    title: 'gradient',
                    type: 'object',
                    properties: {
                        gradientType: {
                            type: 'string'
                        },
                        angle: {
                            type: 'number'
                        },
                        offsetX: {
                            type: 'number'
                        },
                        offsetY: {
                            type: 'number'
                        },
                        rotateWithTheShape: {
                            type: 'boolean'
                        },
                        stops: {
                            type: 'array',
                            items: {
                                title: 'itemOf_stops',
                                type: 'object',
                                properties: {
                                    position: {
                                        type: 'number'
                                    },
                                    color: {
                                        title: 'color',
                                        type: 'object',
                                        properties: {
                                            type: {
                                                type: 'string',
                                                enum: [
                                                    'coded',
                                                    'custom'
                                                ]
                                            },
                                            value: {
                                                type: 'string'
                                            },
                                            reference: {
                                                type: 'string'
                                            }
                                        }
                                    },
                                    _id: {
                                        type: 'string',
                                        pattern: '^[0-9a-fA-F]{24}$'
                                    }
                                }
                            }
                        }
                    }
                },
                imageSource: {
                    type: 'string'
                },
                stretch: {
                    type: 'boolean'
                },
                tile: {
                    type: 'boolean'
                },
                crop: {
                    top: {
                        type: 'number'
                    },
                    right: {
                        type: 'number'
                    },
                    bottom: {
                        type: 'number'
                    },
                    left: {
                        type: 'number'
                    }
                },
                preserveAspectRatio: {
                    title: 'preserveAspectRatio',
                    type: 'object',
                    properties: {
                        align: {
                            title: 'align',
                            type: 'object',
                            properties: {
                                x: {
                                    type: 'string',
                                    enum: [
                                        'min',
                                        'mid',
                                        'max'
                                    ]
                                },
                                y: {
                                    type: 'string',
                                    enum: [
                                        'min',
                                        'mid',
                                        'max'
                                    ]
                                }
                            }
                        },
                        meetOrSlice: {
                            type: 'string',
                            enum: [
                                'meet',
                                'slice',
                                ''
                            ]
                        }
                    }
                }
            }
        },
        opacity: {
            type: 'number'
        },
        fontFamily: {
            type: 'string'
        },
        fontWeight: {},
        fontSize: {
            type: 'number'
        },
        effect: {
            type: 'string'
        },
        characterSpacing: {
            type: 'number'
        },
        lineSpacing: {
            type: 'number'
        },
        horizontalJustification: {
            type: 'string',
            enum: [
                'center',
                'justify',
                'left',
                'right'
            ]
        },
        verticalJustification: {
            type: 'string',
            enum: [
                'bottom',
                'middle',
                'top'
            ]
        },
        topMargin: {
            type: 'number'
        },
        bottomMargin: {
            type: 'number'
        },
        leftMargin: {
            type: 'number'
        },
        rightMargin: {
            type: 'number'
        },
        spaceBefore: {
            type: 'number'
        },
        spaceAfter: {
            type: 'number'
        },
        leftIndent: {
            type: 'number'
        },
        rightIndent: {
            type: 'number'
        },
        direction: {
            type: 'number'
        },
        strokeType: {
            type: 'string'
        },
        strokeWeight: {
            type: 'number'
        },
        joinType: {
            type: 'string',
            enum: [
                'miter',
                'bevel',
                'round',
                ''
            ],
            default: ''
        },
        capType: {
            type: 'string',
            enum: [
                'flat',
                'round',
                'square',
                ''
            ],
            default: ''
        },
        startTipType: {
            type: 'string',
            enum: [
                'triangle',
                'bar',
                'arrow',
                'diamond',
                'halfDiamond',
                'none',
                'stealth',
                'oval',
                ''
            ],
            default: ''
        },
        endTipType: {
            type: 'string',
            enum: [
                'triangle',
                'bar',
                'arrow',
                'diamond',
                'halfDiamond',
                'none',
                'stealth',
                'oval',
                ''
            ],
            default: ''
        },
        startTipSize: {
            type: 'string',
            enum: [
                'large',
                'medium',
                'small',
                ''
            ],
            default: ''
        },
        tipAlign: {
            type: 'string'
        },
        bulletStyle: {
            type: 'string'
        },
        textPercent: {
            type: 'number'
        },
        _id: {
            type: 'string',
            pattern: '^[0-9a-fA-F]{24}$'
        },
        __v: {
            type: 'number'
        },
        __t: {
            type: 'string'
        }
    },
    required: [
        'id',
        'kind'
    ]
};
