import { List, Set, Map } from 'immutable';
import { isEqual } from 'lodash';

import {
    combineDefinedAndRenderRowHeights,
    getCellGridColumn,
    getGridColumns,
    getGridRows,
    getHorizontalBorders,
    getTableWithGrid,
    getVerticalBorders,
    removeGridFromTable
} from './tableGridLogic';
import {
    getBorder,
    getCellIdsInColumn,
    getCellIdsInRow,
    getCellsInRow,
    getTopBorderSegments,
    getLastSpannedRow,
    getLastSpannedColumn,
    getCellsInColumn,
    getLeftBorderSegments,
    addBorder,
    getCellIndex
} from './tableHelper';
import {
    copyCellContentStyles,
    copyCellShapeStyle,
    createCell,
    updateCells
} from './cell';

import { copy as copyBorder } from './border';
import { applyPartUpdates } from './part';
import CanvasStateSelectors, { getShapePathById } from '../../../Canvas/CanvasStateSelectors';
import { DEFAULT_TABLE_SIZE_SETTINGS } from '../../../Table/config/defaultTableAttributes.config';
import {
    checkCanMergeCells,
    removeCommonBorders,
    doCellVectorsMatch,
    mergeTextBodies
} from './merge';
import { splitCell } from './split';
import getPropertiesForDestructuring from '../../utilities/getPropertiesForDestructuring';

const isTableSelected = canvasState => CanvasStateSelectors
    .getCanvasStateSelectedShapeTypes(canvasState)
    .every(type => type.toLowerCase() === 'table') &&
    canvasState.get('selection').size;

const getCellIds = canvasState => {
    const contextualSelection = canvasState.get('contextualSelection');
    const selectionShapes = CanvasStateSelectors.getSelectedCanvasItems(canvasState);
    if (contextualSelection && contextualSelection.get('cells')) {
        return List(contextualSelection.get('cells'));
    } else if (isTableSelected(canvasState)) {
        return selectionShapes.getIn([0, 'cells']).map(cell => cell.get('id'));
    }

    return new List([]);
};

const getCells = (table, cellIds) => table
    .get('cells')
    .filter(cell => cellIds.includes(cell.get('id')));

const isCellCoveringWholeAxis = (canvasState, axis) => {
    const table = CanvasStateSelectors.getSelectedCanvasItems(canvasState).get(0);
    const cellIds = getCellIds(canvasState);

    return axis === 'horizontal' ?
        getCellsRowIndexes(table, cellIds).size === table.get('rows') :
        getCellsColumnIndexes(table, cellIds).size === table.get('columns');
};

const getCellsRowIndexes = (table, cellIds) => {
    const rows = [];
    const cells = getCells(table, cellIds);
    cells.forEach(cell => {
        for (let i = cell.get('row'); i < cell.get('row') + cell.get('rowSpan'); i++) {
            rows.push(i);
        }
    });
    return new List([...new Set(rows)]);
};

const getCellsColumnIndexes = (table, cellIds) => {
    const columns = [];
    const cells = getCells(table, cellIds);
    cells.forEach(cell => {
        for (let i = cell.get('column'); i < cell.get('column') + cell.get('columnSpan'); i++) {
            columns.push(i);
        }
    });
    return new List([...new Set(columns)]);
};

const removeRowsForCellIds = canvasState => {
    const cellIds = getCellIds(canvasState);
    let table = CanvasStateSelectors.getSelectedCanvasItems(canvasState).get(0);

    table = getCellsRowIndexes(table, cellIds)
        .reverse()
        .reduce((currentTable, rowIndex) => removeRow(currentTable, rowIndex), table);

    return canvasState.setIn(
        CanvasStateSelectors.getShapePathById(canvasState, table.get('id')),
        table
    );
};

const removeColumnsForCellIds = canvasState => {
    const cellIds = getCellIds(canvasState);
    let table = CanvasStateSelectors.getSelectedCanvasItems(canvasState).get(0);

    table = getCellsColumnIndexes(table, cellIds)
        .reverse()
        .reduce((currentTable, columnIndex) => removeColumn(currentTable, columnIndex), table);

    return canvasState.setIn(
        CanvasStateSelectors.getShapePathById(canvasState, table.get('id')),
        table
    );
};

const removeRow = (table, rowIndex) => {
    let cellsToRemove = new Set();
    const currentRow = getCellIdsInRow(table, rowIndex);
    const height = table.getIn(['rowHeights', rowIndex]);
    let updatedTable = table;

    currentRow.forEach(cellId => {
        const cellIndex = updatedTable.get('cells')?.findIndex(cell => cell.get('id') === cellId);
        if (updatedTable.getIn(['cells', cellIndex, 'rowSpan']) > 1) {
            updatedTable = updatedTable.setIn(['cells', cellIndex, 'rowSpan'], updatedTable.getIn(['cells', cellIndex, 'rowSpan']) - 1);
            return;
        }
        cellsToRemove = cellsToRemove.add(cellId);
    });
    updatedTable = updatedTable.set('cells', updatedTable.get('cells').filter(cell => !cellsToRemove.has(cell.get('id'))));
    updatedTable = removeGridRowAt(updatedTable, rowIndex);
    updatedTable = updatedTable.set('rowHeights', updatedTable.get('rowHeights').splice(rowIndex, 1));
    updatedTable = updatedTable.set('definedRowHeights', updatedTable.get('definedRowHeights').splice(rowIndex, 1));
    updatedTable = mergeIdenticalColumns(updatedTable);
    updatedTable = updatedTable.set('y', updatedTable.get('y') - (height / 2));
    updatedTable = updatedTable.set('rows', updatedTable.get('rowHeights').size);
    return removeGridFromTable(updatedTable);
};

const removeColumn = (table, columnIndex) => {
    let cellsToRemove = new Set();
    const currentColumn = getCellIdsInColumn(table, columnIndex);
    const width = table.getIn(['columnWidths', columnIndex]);
    let updatedTable = table;

    currentColumn.forEach(cellId => {
        const cellIndex = updatedTable.get('cells')?.findIndex(cell => cell.get('id') === cellId);
        if (updatedTable.getIn(['cells', cellIndex, 'columnSpan']) > 1) {
            updatedTable
                .get('borders')
                .forEach((border, borderIndex) => {
                    const cell = table.get('cells').find(tableCell => tableCell.get('id') === cellId);
                    if (border.get('side') === 'vertical' &&
                        border.get('column') === cell.get('column') &&
                        border.get('row') >= cell.get('row') &&
                        border.get('row') < cell.get('row') + cell.get('rowSpan')) {
                        updatedTable = updatedTable
                            .setIn(
                                ['borders', borderIndex, 'column'],
                                updatedTable.getIn(['borders', borderIndex, 'column']) + 1
                            );
                    }
                });

            updatedTable = updatedTable.setIn(['cells', cellIndex, 'columnSpan'], updatedTable.getIn(['cells', cellIndex, 'columnSpan']) - 1);
            return;
        }
        cellsToRemove = cellsToRemove.add(cellId);
    });

    updatedTable = updatedTable.set('cells', updatedTable.get('cells').filter(cell => !cellsToRemove.has(cell.get('id'))));
    updatedTable = removeGridColumnAt(updatedTable, columnIndex);
    updatedTable = updatedTable.set('columnWidths', updatedTable.get('columnWidths').splice(columnIndex, 1));
    updatedTable = mergeIdenticalRows(updatedTable);
    updatedTable = updatedTable.set('x', updatedTable.get('x') - (width / 2));
    updatedTable = updatedTable.set('columns', updatedTable.get('columnWidths').size);
    return removeGridFromTable(updatedTable);
};

const removeGridRowAt = (table, rowIndex) => {
    let updatedTable = updateCellsRowFromIndex(table, rowIndex);
    updatedTable = removeBordersForRow(updatedTable, rowIndex);
    return updatedTable;
};

const removeGridColumnAt = (table, columnIndex) => {
    let updatedTable = updateCellsColumnFromIndex(table, columnIndex);
    updatedTable = removeBordersForColumn(updatedTable, columnIndex);
    return updatedTable;
};

const updateCellsRowFromIndex = (table, rowIndex) => table.set('cells', table
    .get('cells')
    .map(cell => {
        if (cell && cell.get('row') > rowIndex) {
            return cell.set('row', cell.get('row') - 1);
        }
        return cell;
    }));

const removeBordersForRow = (table, rowIndex) => {
    const updatedTable = table.set('borders', table
        .get('borders')
        .filter(border => border.get('row') !== rowIndex));

    return adjustBorderRowIndexAfterPosition(updatedTable, rowIndex, -1);
};

const updateCellsColumnFromIndex = (table, columnIndex) => table.set('cells', table
    .get('cells')
    .map(cell => {
        if (cell && cell.get('column') > columnIndex) {
            return cell.set('column', cell.get('column') - 1);
        }
        return cell;
    }));

const removeBordersForColumn = (table, columnIndex) => {
    const updatedTable = table.set('borders', table
        .get('borders')
        .filter(border => border.get('column') !== columnIndex));

    return adjustBorderColumnIndexAfterPosition(updatedTable, columnIndex, -1);
};

const adjustBorderColumnIndexAfterPosition = (table, columnIndex, adjustment = 1) => table
    .set(
        'borders',
        table
            .get('borders')
            .map(border => {
                const column = border.get('column');
                if (border.get('column') > columnIndex) {
                    return border.set('column', column + adjustment);
                }
                return border;
            })
    );

const adjustBorderRowIndexAfterPosition = (table, rowIndex, adjustment = 1) => table
    .set(
        'borders',
        table
            .get('borders')
            .map(border => {
                const row = border.get('row');
                if (border.get('row') > rowIndex) {
                    return border.set('row', row + adjustment);
                }
                return border;
            })
    );

const mergeIdenticalColumns = table => {
    let updatedTable = table;
    for (let i = table.get('columnWidths').size - 1; i >= 0; i--) {
        const column = table.get('cells')
            .filter(cell => getSpannedColumns(cell).includes(i))
            .map(cell => cell.get('id'));
        const nextColumn = table.get('cells')
            .filter(cell => getSpannedColumns(cell).includes(i - 1))
            .map(cell => cell.get('id'));
        if (isEqual(column, nextColumn)) {
            updatedTable = updatedTable.set('cells', updatedTable
                .get('cells')
                .reduce((currentCells, cell, index) => {
                    if (cell.get('column') === i - 1) {
                        return currentCells.setIn([index, 'columnSpan'], cell.get('columnSpan') - 1);
                    } else if (cell.get('column') > i - 1) {
                        return currentCells.setIn([index, 'column'], cell.get('column') - 1);
                    }
                    return currentCells;
                }, updatedTable.get('cells')));

            updatedTable = updatedTable.set('borders', updatedTable
                .get('borders')
                .reduce((currentBorders, border, index) => {
                    if (border.get('column') > i - 1) {
                        return currentBorders.setIn([index, 'column'], border.get('column') - 1);
                    }
                    return currentBorders;
                }, updatedTable.get('borders')));

            updatedTable = updatedTable.setIn(
                ['rowHeights', i - 1],
                updatedTable.getIn(['columnWidths', i - 1]) + updatedTable.getIn(['columnWidths', i])
            );

            updatedTable = updatedTable.set('columnWidths', updatedTable.get('columnWidths').splice(i, 1));
        }
    }

    return updatedTable;
};

const mergeIdenticalRows = table => {
    let updatedTable = table;
    for (let i = table.get('rowHeights').size - 1; i >= 0; i--) {
        const row = table.get('cells')
            .filter(cell => getSpannedRows(cell).includes(i))
            .map(cell => cell.get('id'));
        const nextRow = table.get('cells')
            .filter(cell => getSpannedRows(cell).includes(i - 1))
            .map(cell => cell.get('id'));
        if (isEqual(row, nextRow)) {
            updatedTable = updatedTable.set('cells', updatedTable
                .get('cells')
                .reduce((currentCells, cell, index) => {
                    if (cell.get('row') === i - 1) {
                        return currentCells.setIn([index, 'rowSpan'], cell.get('rowSpan') - 1);
                    } else if (cell.get('row') > i - 1) {
                        return currentCells.setIn([index, 'row'], cell.get('row') - 1);
                    }
                    return currentCells;
                }, updatedTable.get('cells')));

            updatedTable = updatedTable.set('borders', updatedTable
                .get('borders')
                .reduce((currentBorders, border, index) => {
                    if (border.get('row') > i - 1) {
                        return currentBorders.setIn([index, 'row'], border.get('row') - 1);
                    }
                    return currentBorders;
                }, updatedTable.get('borders')));

            updatedTable = updatedTable.setIn(
                ['rowHeights', i - 1],
                updatedTable.getIn(['rowHeights', i - 1]) + updatedTable.getIn(['rowHeights', i])
            );

            updatedTable = updatedTable.set('rowHeights', updatedTable.get('rowHeights').splice(i, 1));

            updatedTable = updatedTable.setIn(
                ['definedRowHeights', i - 1],
                updatedTable.getIn(['definedRowHeights', i - 1]) + updatedTable.getIn(['definedRowHeights', i])
            );

            updatedTable = updatedTable.set('definedRowHeights', updatedTable.get('definedRowHeights').splice(i, 1));
        }
    }

    return updatedTable;
};

const getSpannedRows = cell => {
    let spannedRows = new List([]);
    for (let i = cell.get('row'); i < cell.get('row') + cell.get('rowSpan'); i++) {
        spannedRows = spannedRows.push(i);
    }
    return spannedRows;
};

const getSpannedColumns = cell => {
    let spannedColumns = new List([]);
    for (let i = cell.get('column'); i < cell.get('column') + cell.get('columnSpan'); i++) {
        spannedColumns = spannedColumns.push(i);
    }
    return spannedColumns;
};

const updateTable = (canvasState, id, update) => {
    const shapePath = CanvasStateSelectors.getShapePathById(canvasState, id);
    const cellIds = getCellIds(canvasState);

    let updatedCanvasState = canvasState;

    const shapeProps = update.get('shapeProps');

    updatedCanvasState = applySimpleTablePropertiesUpdate(updatedCanvasState, id, shapeProps);
    let table = updatedCanvasState.getIn(shapePath);
    if (shapeProps.get('width')) {
        const scaleFactor = shapeProps.get('width') / getWidth(table);
        updatedCanvasState = updatedCanvasState.setIn(
            [...shapePath, 'columnWidths'],
            updatedCanvasState.getIn([...shapePath, 'columnWidths'])
                .map(columnWidth => columnWidth * scaleFactor)
        );
        updatedCanvasState = updatedCanvasState.setIn(
            [...shapePath, 'width'],
            getWidth(updatedCanvasState.getIn(shapePath))
        );
        table = updatedCanvasState.getIn(shapePath);
    }
    if (shapeProps.get('height')) {
        const scaleFactor = shapeProps.get('height') / getHeight(table);
        updatedCanvasState = updatedCanvasState.setIn(
            [...shapePath, 'definedRowHeights'],
            combineDefinedAndRenderRowHeights(table)
                .map(rowHeight => rowHeight * scaleFactor)
        );
        updatedCanvasState = updatedCanvasState.setIn(
            [...shapePath, 'height'],
            getHeight(updatedCanvasState.getIn(shapePath))
        );
        table = updatedCanvasState.getIn(shapePath);
    }
    if (shapeProps.get('rowHeights')) {
        const oldHeight = getHeight(table);
        updatedCanvasState = updatedCanvasState
            .setIn([...shapePath, 'rowHeights'], shapeProps.get('rowHeights'));
        updatedCanvasState = updatedCanvasState
            .setIn([...shapePath, 'y'], (getHeight(updatedCanvasState.getIn(shapePath)) - oldHeight) / 2);
        updatedCanvasState = updatedCanvasState.setIn(
            [...shapePath, 'height'],
            getHeight(updatedCanvasState.getIn(shapePath))
        );
        table = updatedCanvasState.getIn(shapePath);
    }
    if (shapeProps.get('definedRowHeights')) {
        const oldHeight = getHeight(table);
        updatedCanvasState = updatedCanvasState
            .setIn([...shapePath, 'definedRowHeights'], shapeProps.get('definedRowHeights'));
        updatedCanvasState = updatedCanvasState
            .setIn([...shapePath, 'y'], (getHeight(updatedCanvasState.getIn(shapePath)) - oldHeight) / 2);
        updatedCanvasState = updatedCanvasState.setIn(
            [...shapePath, 'height'],
            getHeight(updatedCanvasState.getIn(shapePath))
        );
        table = updatedCanvasState.getIn(shapePath);
    }
    if (shapeProps.get('columnWidths')) {
        const oldWidth = getWidth(table);
        updatedCanvasState = updatedCanvasState
            .setIn([...shapePath, 'columnWidths'], shapeProps.get('columnWidths'));
        updatedCanvasState = updatedCanvasState
            .setIn([...shapePath, 'x'], (getWidth(updatedCanvasState.getIn(shapePath)) - oldWidth) / 2);
        updatedCanvasState = updatedCanvasState.setIn(
            [...shapePath, 'width'],
            getWidth(updatedCanvasState.getIn(shapePath))
        );
        table = updatedCanvasState.getIn(shapePath);
    }

    if (cellIds && cellIds.size !== 0) {
        updatedCanvasState = updatedCanvasState.setIn(shapePath, updateCells(table, cellIds, update));
    } else {
        updatedCanvasState = updatedCanvasState.setIn(shapePath, applyUpdateOnAllCells(table, update));
    }
    if (shapeProps.get('partUpdates')) {
        updatedCanvasState = updatedCanvasState.setIn(shapePath, applyPartUpdates(table, update));
    }

    return updatedCanvasState;
};

const applyUpdateOnAllCells = (table, update) => {
    const {
        shapeProps
    } = getPropertiesForDestructuring(
        update,
        [
            'shapeProps'
        ]
    );

    const cellIds = table
        .get('cells')
        .map(cell => cell.get('id'))
        .toJS();

    return updateCells(table, cellIds, update
        .merge(
            Map({
                shapeProps
            })
        ));
};

const applySimpleTablePropertiesUpdate = (canvasState, id, update) => {
    const shapePath = CanvasStateSelectors.getShapePathById(canvasState, id);
    const propertySimpleUpdate = [
        'style',
        'hasBandedColumns',
        'hasBandedRows',
        'hasHeaderColumn',
        'hasHeaderRow',
        'hasTotalColumn',
        'hasTotalRow',
        'mainAxis',
        'x',
        'y',
        'offsetLeft',
        'offsetTop',
        'name'
    ];
    return update
        .entrySeq()
        .reduce((currentCanvasState, [updateProperty, value]) => {
            if (propertySimpleUpdate.includes(updateProperty)) {
                return currentCanvasState.setIn([...shapePath, updateProperty], value);
            }
            return currentCanvasState;
        }, canvasState);
};

const getHeight = table => combineDefinedAndRenderRowHeights(table)
    .slice(0, table.get('rowHeights').size + 1)
    .reduce((sum, height) => sum + height, 0);

const getWidth = table => table
    .get('columnWidths')
    .slice(0, table.get('columnWidths').size + 1)
    .reduce((sum, width) => sum + width, 0);

const addRowAtIndex = (canvasState, direction) => {
    let cellsOnSelectionEdge = List();
    const table = getTableWithGrid(CanvasStateSelectors
        .getSelectedCanvasItems(canvasState)
        .get(0));
    const tablePath = getShapePathById(canvasState, table.get('id'));
    const cellIds = getCellIds(canvasState);

    getGridColumns(table).forEach(column => {
        const selectedCells = getCells(
            table,
            column
                .filter(cellId => cellIds.includes(cellId))
        );
        if (selectedCells.size !== 0) {
            const cellOnSelectionEdge = direction === 'top' ?
                selectedCells.sort((a, b) => a.get('row') - b.get('row')).get(0) :
                selectedCells.sort((a, b) => getLastSpannedRow(b) - getLastSpannedRow(a)).get(0);
            cellsOnSelectionEdge = cellsOnSelectionEdge.push(cellOnSelectionEdge);
        }
    });

    const currentIndex = direction === 'top' ?
        Math.max(...cellsOnSelectionEdge.map(cell => cell.get('row'))) :
        Math.min(...cellsOnSelectionEdge.map(cell => getLastSpannedRow(cell)));

    return canvasState.setIn(tablePath, insertRow(table, currentIndex, direction));
};

const insertRow = (table, currentIndex, direction) => {
    const insertIndex = direction === 'top' ?
        currentIndex : currentIndex + 1;
    const height = DEFAULT_TABLE_SIZE_SETTINGS.minCellHeight;

    let updatedTable = table;

    updatedTable = updatedTable.set('cells', updatedTable
        .get('cells')
        .map(cell => (cell.get('row') >= insertIndex ?
            cell.set('row', cell.get('row') + 1) : cell)));

    updatedTable = updatedTable.set('borders', updatedTable
        .get('borders')
        .map(border => (border.get('row') >= insertIndex ?
            border.set('row', border.get('row') + 1) : border)));

    updatedTable = updatedTable.set(
        'rowHeights',
        updatedTable
            .get('rowHeights')
            .splice(insertIndex, 0, height)
    );

    updatedTable = updatedTable.set(
        'definedRowHeights',
        updatedTable
            .get('definedRowHeights')
            .splice(insertIndex, 0, height)
    );

    const currentRow = getCellsInRow(table, currentIndex);

    currentRow.forEach(
        cell => {
            const verticalBorderColumnIndexToCopy = direction === 'top' ?
                insertIndex + 1 :
                insertIndex - 1;

            if (
                cell.get('row') < insertIndex &&
                getLastSpannedRow(cell) >= insertIndex
            ) {
                const cellIndex = getCellIndex(updatedTable, cell.get('id'));
                updatedTable = updatedTable
                    .setIn(
                        ['cells', cellIndex, 'rowSpan'],
                        updatedTable.getIn(['cells', cellIndex, 'rowSpan']) + 1
                    );

                const leftBorder = findBorder(
                    updatedTable,
                    'vertical',
                    verticalBorderColumnIndexToCopy,
                    cell.get('column')
                );

                if (leftBorder) {
                    let newLeftBorder = copyBorder(leftBorder);
                    newLeftBorder = newLeftBorder.set('row', insertIndex);
                    updatedTable = addBorder(updatedTable, newLeftBorder);
                }

                const rightBorder = findBorder(
                    updatedTable,
                    'vertical',
                    verticalBorderColumnIndexToCopy,
                    cell.get('column') + 1
                );

                if (rightBorder) {
                    let newRightBorder = copyBorder(rightBorder);
                    newRightBorder = newRightBorder.set('row', insertIndex);
                    updatedTable = addBorder(updatedTable, newRightBorder);
                }

                return;
            }

            let newCell = createCell(
                `${cell.get('name')}_inserted_${direction}`,
                insertIndex,
                cell.get('column'),
                1,
                cell.get('columnSpan')
            );
            newCell = copyCellContentStyles(newCell, cell);
            newCell = copyCellShapeStyle(newCell, cell);
            updatedTable = updatedTable.set('cells', updatedTable.get('cells').push(newCell));

            updatedTable = insertBordersAfterRowInsert(
                updatedTable,
                cell,
                insertIndex,
                verticalBorderColumnIndexToCopy
            );
        }
    );
    updatedTable = updatedTable.set('y', updatedTable.get('y') + height / 2)
        .set('rows', updatedTable.get('rows') + 1);

    return removeGridFromTable(updatedTable);
};

const findBorder = (table, axis, column, row) => (
    axis === 'horizontal' ?
        getBorder(table, getHorizontalBorders(table).getIn([row, column])) :
        getBorder(table, getVerticalBorders(table).getIn([column, row]))
);

const insertBordersAfterRowInsert = (table, cell, insertIndex, verticalBorderRowIndexToCopy) => {
    let updatedTable = table;

    const leftBorder = findBorder(
        table,
        'vertical',
        cell.get('column'),
        verticalBorderRowIndexToCopy
    );

    if (leftBorder) {
        let newLeftBorder = copyBorder(leftBorder);
        newLeftBorder = newLeftBorder.set('row', insertIndex);
        updatedTable = addBorder(updatedTable, newLeftBorder);
    }

    getTopBorderSegments(cell.get('id'), updatedTable).forEach(horizontalBorderId => {
        const horizontalBorder = getBorder(updatedTable, horizontalBorderId);

        if (horizontalBorder) {
            let newHorizontalBorder = copyBorder(horizontalBorder);
            newHorizontalBorder = newHorizontalBorder.set('row', insertIndex);
            updatedTable = addBorder(updatedTable, newHorizontalBorder);
        }
    });

    if (cell.get('column') + cell.get('columnSpan') === updatedTable.get('columnWidths').size) {
        const rightBorder = findBorder(
            updatedTable,
            'vertical',
            cell.get('column') + cell.get('columnSpan'),
            verticalBorderRowIndexToCopy
        );
        if (rightBorder) {
            let newRightBorder = copyBorder(rightBorder);
            newRightBorder = newRightBorder.set('row', insertIndex);
            updatedTable = addBorder(updatedTable, newRightBorder);
        }
    }

    return updatedTable;
};

const addColumnAtIndex = (canvasState, direction) => {
    let cellsOnSelectionEdge = List();
    const table = CanvasStateSelectors
        .getSelectedCanvasItems(canvasState)
        .get(0);

    const tablePath = getShapePathById(canvasState, table.get('id'));
    const cellIds = getCellIds(canvasState);

    getGridRows(table).forEach(row => {
        const selectedCells = getCells(
            table,
            row
                .filter(cellId => cellIds.includes(cellId))
        );
        if (selectedCells.size !== 0) {
            const cellOnSelectionEdge = direction === 'left' ?
                selectedCells.sort((a, b) => a.get('column') - b.get('column')).get(0) :
                selectedCells.sort((a, b) => getLastSpannedColumn(b) - getLastSpannedColumn(a)).get(0);
            cellsOnSelectionEdge = cellsOnSelectionEdge.push(cellOnSelectionEdge);
        }
    });

    const currentIndex = direction === 'left' ?
        Math.max(...cellsOnSelectionEdge.map(cell => cell.get('column'))) :
        Math.min(...cellsOnSelectionEdge.map(cell => getLastSpannedColumn(cell)));

    return canvasState.setIn(tablePath, insertColumn(table, currentIndex, direction));
};

const insertColumn = (table, currentIndex, direction) => {
    const insertIndex = direction === 'left' ?
        currentIndex : currentIndex + 1;
    const width = table.getIn(['columnWidths', currentIndex]);

    let updatedTable = table;

    updatedTable = updatedTable.set('cells', updatedTable
        .get('cells')
        .map(cell => (cell.get('column') >= insertIndex ?
            cell.set('column', cell.get('column') + 1) : cell)));

    updatedTable = updatedTable.set('borders', updatedTable
        .get('borders')
        .map(border => (border.get('column') >= insertIndex ?
            border.set('column', border.get('column') + 1) : border)));

    updatedTable = updatedTable.set(
        'columnWidths',
        updatedTable
            .get('columnWidths')
            .splice(insertIndex, 0, width)
    );

    const currentColumn = getCellsInColumn(table, currentIndex);

    currentColumn.forEach(
        cell => {
            const horizontalBorderColumnIndexToCopy = direction === 'left' ?
                insertIndex + 1 :
                insertIndex - 1;

            if (
                cell.get('column') < insertIndex &&
                getLastSpannedColumn(cell) >= insertIndex
            ) {
                const cellIndex = getCellIndex(updatedTable, cell.get('id'));
                updatedTable = updatedTable
                    .setIn(
                        ['cells', cellIndex, 'columnSpan'],
                        updatedTable.getIn(['cells', cellIndex, 'columnSpan']) + 1
                    );

                const topBorder = findBorder(
                    updatedTable,
                    'horizontal',
                    horizontalBorderColumnIndexToCopy,
                    cell.get('row')
                );

                if (topBorder) {
                    let newTopBorder = copyBorder(topBorder);
                    newTopBorder = newTopBorder.set('column', insertIndex);
                    updatedTable = addBorder(updatedTable, newTopBorder);
                }

                const bottomBorder = findBorder(
                    updatedTable,
                    'horizontal',
                    horizontalBorderColumnIndexToCopy,
                    cell.get('row') + 1
                );

                if (bottomBorder) {
                    let newBottomBorder = copyBorder(bottomBorder);
                    newBottomBorder = newBottomBorder.set('column', insertIndex);
                    updatedTable = addBorder(updatedTable, newBottomBorder);
                }

                return;
            }

            let newCell = createCell(
                `${cell.get('name')}_inserted_${direction}`,
                cell.get('row'),
                insertIndex,
                cell.get('rowSpan'),
                1
            );
            newCell = copyCellContentStyles(newCell, cell);
            newCell = copyCellShapeStyle(newCell, cell);
            updatedTable = updatedTable.set('cells', updatedTable.get('cells').push(newCell));

            updatedTable = insertBordersAfterColumnInsert(
                updatedTable,
                cell,
                insertIndex,
                horizontalBorderColumnIndexToCopy
            );
        }
    );
    updatedTable = updatedTable.set('x', updatedTable.get('x') + width / 2)
        .set('columns', updatedTable.get('columns') + 1);
    return removeGridFromTable(updatedTable);
};

const insertBordersAfterColumnInsert = (table, cell, insertIndex, horizontalBorderColumnIndexToCopy) => {
    let updatedTable = table;

    const topBorder = findBorder(
        updatedTable,
        'horizontal',
        horizontalBorderColumnIndexToCopy,
        cell.get('row')
    );

    if (topBorder) {
        let newTopBorder = copyBorder(topBorder);
        newTopBorder = newTopBorder.set('column', insertIndex);
        updatedTable = addBorder(updatedTable, newTopBorder);
    }

    getLeftBorderSegments(cell.get('id'), updatedTable).forEach(verticalBorderId => {
        const verticalBorder = getBorder(updatedTable, verticalBorderId);

        if (verticalBorder) {
            let newVerticalBorder = copyBorder(verticalBorder);
            newVerticalBorder = newVerticalBorder.set('column', insertIndex);
            updatedTable = addBorder(updatedTable, newVerticalBorder);
        }
    });

    if (cell.get('row') + cell.get('rowSpan') === table.get('rowHeights').size) {
        const bottomBorder = findBorder(
            updatedTable,
            'horizontal',
            horizontalBorderColumnIndexToCopy,
            cell.get('row') + cell.get('rowSpan')
        );

        if (bottomBorder) {
            let newBottomBorder = copyBorder(bottomBorder);
            newBottomBorder = newBottomBorder.set('column', insertIndex);
            updatedTable = addBorder(updatedTable, newBottomBorder);
        }
    }

    return updatedTable;
};

const mergeCells = canvasState => {
    const table = CanvasStateSelectors.getSelectedCanvasItems(canvasState).get(0);
    const cellIds = getCellIds(canvasState);

    let updatedTable = table;

    if (!checkCanMergeCells(updatedTable, cellIds)) return canvasState;
    updatedTable = removeCommonBorders(updatedTable, cellIds);
    const cellRowStart = cellIds.reduce((start, cellId) => Math.min(start, table.get('cells').find(cell => cell.get('id') === cellId).get('row')), table.get('rowHeights').size + 1);
    const cellRowEnd = cellIds.reduce((end, cellId) => Math.max(
        end,
        table.get('cells').find(cell => cell.get('id') === cellId).get('row') +
                table.get('cells').find(cell => cell.get('id') === cellId).get('rowSpan')
    ), 0);
    const cellColumnStart = cellIds.reduce((start, cellId) => Math.min(start, table.get('cells').find(cell => cell.get('id') === cellId).get('column')), table.get('columnWidths').size + 1);
    const cellColumnEnd = cellIds.reduce((end, cellId) => Math.max(
        end,
        table.get('cells').find(cell => cell.get('id') === cellId).get('column') +
                table.get('cells').find(cell => cell.get('id') === cellId).get('columnSpan')
    ), 0);
    const cellNames = cellIds.map(cellId => table.get('cells').find(cell => cell.get('id') === cellId).get('name'));

    let mergedCell = createCell(
        `${cellNames.join('_')}_merged`,
        cellRowStart,
        cellColumnStart,
        cellRowEnd - cellRowStart,
        cellColumnEnd - cellColumnStart
    );

    mergedCell = mergeTextBodies(updatedTable, mergedCell, cellIds);

    updatedTable = updatedTable.set('cells', updatedTable.get('cells').push(mergedCell));

    updatedTable = updatedTable.set(
        'cells',
        updatedTable.get('cells').filter(cell => !cellIds.includes(cell.get('id')))
    );

    updatedTable = reduceRows(updatedTable);
    updatedTable = reduceColumns(updatedTable);

    return canvasState.setIn([
        'shapes',
        canvasState
            .get('shapes')
            .findIndex(shape => shape.get('id') === updatedTable.get('id'))
    ], updatedTable);
};

const reduceRows = table => {
    const tableWithGrid = getTableWithGrid(table);
    let updatedTable = table;
    let i = 0;
    while (i + 1 < tableWithGrid.get('cellGrid').size) {
        if (doCellVectorsMatch(tableWithGrid.getIn(['cellGrid', i]), tableWithGrid.getIn(['cellGrid', i + 1]))) {
            updatedTable = removeGridRowAt(updatedTable, i + 1, 1);
            updatedTable = reduceRowSpan(updatedTable, tableWithGrid.getIn(['cellGrid', i]));
            updatedTable = updatedTable.setIn(
                ['rowHeights', i],
                updatedTable.getIn(['rowHeights', i]) + updatedTable.getIn(['rowHeights', i + 1])
            );
            updatedTable = updatedTable.setIn(
                ['definedRowHeights', i],
                updatedTable.getIn(['definedRowHeights', i]) + updatedTable.getIn(['definedRowHeights', i + 1])
            );
            updatedTable = updatedTable.set('rowHeights', updatedTable.get('rowHeights').splice(i + 1, 1));
            updatedTable = updatedTable.set('definedRowHeights', updatedTable.get('definedRowHeights').splice(i + 1, 1));
        } else {
            i += 1;
        }
    }

    return updatedTable;
};

const reduceRowSpan = (table, cellIds) => Set(cellIds).reduce((updatedTable, cellId) => {
    const cellIndex = updatedTable.get('cells').findIndex(cell => cell.get('id') === cellId);
    return updatedTable.setIn(
        ['cells', cellIndex, 'rowSpan'],
        updatedTable.getIn(['cells', cellIndex, 'rowSpan']) - 1
    );
}, table);

const reduceColumns = table => {
    const tableWithGrid = getTableWithGrid(table);
    let updatedTable = table;
    let j = 0;
    while (j + 1 < tableWithGrid.getIn(['cellGrid', 0]).size) {
        const currentColumn = getCellGridColumn(j, updatedTable);
        const nextColumn = getCellGridColumn(j + 1, updatedTable);
        if (doCellVectorsMatch(currentColumn, nextColumn)) {
            updatedTable = removeGridColumnAt(updatedTable, j + 1);
            updatedTable = reduceColumnSpan(updatedTable, currentColumn);
            updatedTable = updatedTable.setIn(
                ['columnWidths', j],
                updatedTable.getIn(['columnWidths', j]) + updatedTable.getIn(['columnWidths', j + 1])
            );
            updatedTable = updatedTable.set('columnWidths', updatedTable.get('columnWidths').splice(j + 1, 1));
        } else {
            j++;
        }
    }

    return updatedTable;
};

const reduceColumnSpan = (table, cellIds) => Set(cellIds).reduce((updatedTable, cellId) => {
    const cellIndex = updatedTable.get('cells').findIndex(cell => cell.get('id') === cellId);
    return updatedTable.setIn(
        ['cells', cellIndex, 'columnSpan'],
        updatedTable.getIn(['cells', cellIndex, 'columnSpan']) - 1
    );
}, table);

const splitCells = (canvasState, rows, columns) => {
    const table = CanvasStateSelectors.getSelectedCanvasItems(canvasState).get(0);
    const cellIds = getCellIds(canvasState);

    return canvasState.setIn(
        getShapePathById(canvasState, table.get('id')),
        Set(cellIds)
            .reduce((currentTable, cellId) => splitCell(currentTable, cellId, rows, columns), table)
    );
};

export {
    isTableSelected,
    getCellIds,
    getCells,
    isCellCoveringWholeAxis,
    getCellsRowIndexes,
    getCellsColumnIndexes,
    removeRowsForCellIds,
    removeColumnsForCellIds,
    removeRow,
    removeColumn,
    removeGridColumnAt,
    updateCellsColumnFromIndex,
    removeBordersForColumn,
    adjustBorderRowIndexAfterPosition,
    adjustBorderColumnIndexAfterPosition,
    mergeIdenticalRows,
    mergeIdenticalColumns,
    getSpannedRows,
    getSpannedColumns,
    updateTable,
    mergeCells,
    splitCells,
    addRowAtIndex,
    addColumnAtIndex
};
