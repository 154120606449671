const { Record, List } = require('immutable');
const last = require('lodash/last');
const svgPath = require('svgpath');
const MoveCommand = require('./commands/move');
const LineCommand = require('./commands/line');
const CloseCommand = require('./commands/close');
const EllipticArcCommand = require('./commands/ellipticArc');
const geometry = require('../geometry');

class PathBuilder extends Record({ commands: [] }) {
    _pushCommand(command) {
        return this.update('commands', commands => commands.concat(command));
    }

    _pushMoveCommand({ x, y, relative }) {
        return this._pushCommand(new MoveCommand({ x, y, relative }));
    }

    _pushLineCommand({ x, y, relative }) {
        this.checkFirstMove();
        return this._pushCommand(new LineCommand({ x, y, relative }));
    }

    _pushCloseCommand({ relative }) {
        this.checkFirstMove();
        return this._pushCommand(new CloseCommand({ relative }));
    }

    _pushArcCommand({
        rx,
        ry,
        xAxisRotation,
        largeArcFlag,
        sweepFlag,
        x,
        y,
        relative
    }) {
        this.checkFirstMove();
        return this._pushCommand(new EllipticArcCommand({
            rx,
            ry,
            xAxisRotation,
            largeArcFlag,
            sweepFlag,
            x,
            y,
            relative
        }));
    }

    _checkBuildReady() {
        if (this.commands.length === 0) {
            throw new Error('Unable to build due to empty command list');
        }
    }

    buildString() {
        this._checkBuildReady();
        return this.commands.map(command => command.toString()).join(' ');
    }

    buildArray() {
        this._checkBuildReady();
        return this.commands.map(command => command.toArray());
    }

    buildSVG() {
        this._checkBuildReady();
        return `<path d="${this.buildString()}" />`;
    }

    moveTo({ x, y }) {
        return this._pushMoveCommand({ x, y, relative: false });
    }

    relativeMoveTo({ x, y }) {
        return this._pushMoveCommand({ x, y, relative: true });
    }

    checkFirstMove() {
        if (this.commands.length === 0) {
            throw Error('Path builder not ready to build, first command should be a moveTo');
        }
    }

    lineTo({ x, y }) {
        return this._pushLineCommand({ x, y, relative: false });
    }

    relativeLineTo({ x, y }) {
        return this._pushLineCommand({ x, y, relative: true });
    }

    close() {
        return this._pushCloseCommand({ relative: false });
    }

    relativeClose() {
        return this._pushCloseCommand({ relative: true });
    }

    arcTo({
        rx, ry, xAxisRotation, largeArcFlag, sweepFlag, x, y
    }) {
        return this._pushArcCommand({
            rx,
            ry,
            xAxisRotation,
            largeArcFlag,
            sweepFlag,
            x,
            y,
            relative: false
        });
    }

    relativeArcTo({
        rx, ry, xAxisRotation, largeArcFlag, sweepFlag, x, y
    }) {
        return this._pushArcCommand({
            rx,
            ry,
            xAxisRotation,
            largeArcFlag,
            sweepFlag,
            x,
            y,
            relative: true
        });
    }

    addPath(otherPath) {
        if (otherPath.constructor.name !== this.constructor.name) {
            throw TypeError('addPath expect a PathBuilder instance');
        }
        return this.update('commands', commands => commands
            .concat(otherPath.commands));
    }

    mergeWithPath(otherPath) {
        if (otherPath.constructor.name !== this.constructor.name) {
            throw TypeError('mergeWithPath expect a PathBuilder instance');
        }
        let commandsWithoutClose;
        if (this.commands.length === 0) {
            commandsWithoutClose = List();
        } else if (last(this.commands).commandChar.toLowerCase() === 'z') {
            commandsWithoutClose = this.commands.slice(0, -1);
        }
        return this.set('commands', commandsWithoutClose
            .concat(otherPath.commands.slice(1)));
    }

    static fromString(string) {
        const cleanedString = string.replace(/\s+|,/g, ' ').trim();
        const commands = cleanedString.match(/[mlaz][^mlaz]*/gi);
        return new PathBuilder().withMutations(mutablePath => commands
            .reduce((path, strCommand) => {
                const stringCommand = strCommand.trim();
                let command;
                switch (stringCommand[0].toLowerCase()) {
                    case 'a':
                        command = EllipticArcCommand.fromString(stringCommand);
                        break;
                    case 'm':
                        command = MoveCommand.fromString(stringCommand);
                        break;
                    case 'l':
                        command = LineCommand.fromString(stringCommand);
                        break;
                    case 'z':
                        command = CloseCommand.fromString(stringCommand);
                        break;
                    default:
                        throw new Error(`unsuported command ${stringCommand}`);
                }
                return path._pushCommand(command);
            }, mutablePath));
    }

    static fromArray(array) {
        return new PathBuilder().withMutations(mutablePath => array
            .reduce((path, arrayCommand) => {
                let command;
                switch (arrayCommand[0].toLowerCase()) {
                    case 'a':
                        command = EllipticArcCommand.fromArray(arrayCommand);
                        break;
                    case 'm':
                        command = MoveCommand.fromArray(arrayCommand);
                        break;
                    case 'l':
                        command = LineCommand.fromArray(arrayCommand);
                        break;
                    case 'z':
                        command = CloseCommand.fromArray(arrayCommand);
                        break;
                    default:
                        throw new Error(`unsuported command ${arrayCommand}`);
                }
                return path._pushCommand(command);
            }, mutablePath));
    }

    translate({ x, y }) {
        if (this.commands.length === 0) {
            return this;
        }
        if (x === 0 && y === 0) {
            return this;
        }
        const pathAsString = this.buildString();
        const translatedPath = svgPath(pathAsString).translate(x, y);
        const commandArray = [];
        translatedPath.iterate(segment => commandArray.push(geometry.sanitizeCommand(segment)));
        return PathBuilder.fromArray(commandArray);
    }

    rotate(angle, rx = 0, ry = 0) {
        if (this.commands.length === 0) {
            return this;
        }
        if (angle === 360) {
            return this;
        }
        const pathAsString = this.buildString();
        const rotatedPath = svgPath(pathAsString)
            .rotate(angle, rx, ry)
            .round(2);
        const commandArray = [];
        rotatedPath.iterate(segment => commandArray.push(segment));
        return PathBuilder.fromArray(commandArray);
    }

    scale(scaleX, scaleY) {
        if (this.commands.length === 0) {
            return this;
        }
        if (scaleX === 1 && scaleY === 1) {
            return this;
        }
        const pathAsString = this.buildString();
        const scaledPath = svgPath(pathAsString).scale(scaleX, scaleY);
        const commandArray = [];
        scaledPath.iterate(segment => commandArray.push(segment));
        return PathBuilder.fromArray(commandArray);
    }
}

module.exports = {
    PathBuilder,
    MoveCommand,
    LineCommand,
    CloseCommand,
    EllipticArcCommand
};
