import { List } from 'immutable';
import { isNil } from 'lodash';
import CanvasStateSelectors, { getShapeById, getShapePathById } from '../../../Canvas/CanvasStateSelectors';
import TreeNode from '../../../fabric-adapter/DecksignFabricShapeType/Groups/TreeNode';
import { destroyGroupsWithOnlyOneChild, resetBoundingBoxAndPosition } from '../../Helpers/Group';
import { getShapeRoot, getShapesLayer } from '../../Helpers/helpers';
import getPropertiesForDestructuring from '../../utilities/getPropertiesForDestructuring';
import { zIndexOrderer } from '../../utilities/reorderer';

const changeShapeIndex = (canvasState, command) => {
    const {
        changeType,
        isTriggeredByUI,
        sourcePath,
        targetPath
    } = getPropertiesForDestructuring(
        command,
        [
            'changeType',
            'isTriggeredByUI',
            'sourcePath',
            'targetPath'
        ]
    );

    let updatedCanvasState = canvasState;

    if (!updatedCanvasState.get('groupTree') && sourcePath.get(0)) {
        const rootId = getShapeRoot(updatedCanvasState.get(getShapesLayer(updatedCanvasState)), sourcePath);
        const group = getShapeById(updatedCanvasState, rootId);
        updatedCanvasState = updatedCanvasState.set('groupTree', TreeNode.generateTree(group));
    }
    if (
        isTriggeredByUI &&
        (
            (sourcePath && sourcePath.size > 1) ||
            (targetPath && targetPath.size > 1)
        )
    ) {
        updatedCanvasState = updateNestedShapeContext(
            canvasState,
            {
                changeType,
                sourcePath,
                targetPath
            }
        );
    } else if (updatedCanvasState.get('groupTree') && updatedCanvasState.get('groupTree').getChildrenIds().length > 0) {
        updatedCanvasState = reorderShapeInContext(updatedCanvasState, {
            changeType,
            source: sourcePath,
            target: targetPath,
            context: getShapeById(
                updatedCanvasState,
                TreeNode.getParent(updatedCanvasState.get('groupTree'), sourcePath.get(0)).shape.id
            )
        });
    } else {
        updatedCanvasState = reorderShapes(
            updatedCanvasState,
            changeType,
            sourcePath,
            targetPath && targetPath.get(0)
        );
    }

    return updatedCanvasState;
};

const reorderShapes = (canvasState, action, ids = List(), targetId) => {
    const targetIdIndex = ids.indexOf(targetId);
    const idsToReorder = validateIfAllShapeIdsCanBeUpdated(canvasState, ids.concat(List([targetId])))
        .filter(idToReorder => ids.includes(idToReorder));
    const targetIdValidated = targetIdIndex > -1 ? idsToReorder[targetIdIndex] : targetId;
    const orderedShapes = zIndexOrderer({
        array: canvasState.get(getShapesLayer(canvasState)),
        key: 'id',
        values: idsToReorder
    })[action](targetIdValidated);
    return canvasState.set(getShapesLayer(canvasState), orderedShapes);
};

const validateIfAllShapeIdsCanBeUpdated = (canvasState, ids) => {
    const shapes = ids
        .map(id => CanvasStateSelectors.getShapeById(canvasState, id))
        .filter(shape => !isNil(shape));
    return shapes
        .map(shape => shape.get('id'));
};

const updateNestedShapeContext = (canvasState, { changeType, sourcePath, targetPath }) => {
    let updatedCanvasState = canvasState;

    const {
        group: sourceGroup,
        offset: sourceOffset,
        groups: sourceGroups,
        itemId: sourceId
    } = getGroupAndOffsetFromPath(updatedCanvasState, sourcePath);

    const {
        group: targetGroup,
        offset: targetOffset,
        groups: targetGroups,
        itemId: targetId
    } = getGroupAndOffsetFromPath(updatedCanvasState, targetPath);

    if (sourceGroup.equals(targetGroup) && targetId !== sourceGroup.get('id')) {
        updatedCanvasState = reorderChildren(
            updatedCanvasState,
            targetGroup,
            changeType,
            sourceId,
            targetId
        );
    } else {
        const groupsToResize = [
            ...sourceGroups,
            ...targetGroups
        ];

        updatedCanvasState = moveShapeBetweenContext(
            updatedCanvasState,
            {
                sourceId,
                sourceOffset,
                sourceContext: sourceGroup,
                targetOffset,
                targetContext: targetPath.length === 1 ? {} : targetGroup
            }
        );

        updatedCanvasState = reorderShapeInContext(
            updatedCanvasState,
            {
                changeType,
                source: sourceId,
                target: targetId,
                context: targetPath.length === 1 ? {} : getShapeById(updatedCanvasState, targetGroup.get('id'))
            }
        );

        updatedCanvasState = resetBoundingBoxesAndPositions(updatedCanvasState, groupsToResize);
        updatedCanvasState = updatedCanvasState.update(
            'shapes',
            shapes => shapes.map(shape => destroyGroupsWithOnlyOneChild(shape))
        );
    }

    return updatedCanvasState;
};

const getGroupAndOffsetFromPath = (canvasState, path, inputGroups = []) => {
    const itemId = path.get(path.size - 1);
    const reducedPath = path.slice(1, -1)
        .reduce(({ group, offset, groups }, childId) => ({
            group: group.get('shapes').find(shape => shape.get('id') === childId),
            groups: groups.push(group),
            offset: {
                x: offset.x + group.x,
                y: offset.y + group.y
            }
        }), {
            group: CanvasStateSelectors.getShapeById(canvasState, path.get(0)),
            groups: List(inputGroups),
            offset: {
                x: 0,
                y: 0
            }
        });

    return {
        itemId,
        group: reducedPath.group,
        groups: reducedPath.groups,
        offset: {
            x: reducedPath.offset.x + reducedPath.group.x,
            y: reducedPath.offset.y + reducedPath.group.y
        }
    };
};

const moveShapeBetweenContext = (
    canvasState,
    {
        sourceId,
        sourceOffset,
        sourceContext,
        targetOffset,
        targetContext
    }
) => {
    const shapeToMove = extractShapeFromContext(canvasState, {
        id: sourceId,
        offset: sourceOffset,
        context: sourceContext
    });

    let updatedCanvasState = canvasState
        .removeIn(CanvasStateSelectors.getShapePathById(canvasState, sourceId));

    updatedCanvasState = insertShapeInContext(
        updatedCanvasState,
        {
            shape: shapeToMove,
            offset: targetOffset,
            context: targetContext
        }
    );

    return updatedCanvasState;
};

const extractShapeFromContext = (canvasState, { id, offset, context }) => {
    if (context.get('id') === id) {
        return context;
    }
    if (context && context.get('type') === 'Group') {
        return CanvasStateSelectors.getShapeById(canvasState, id)
            .update('x', x => x + offset.x)
            .update('y', y => y + offset.y);
    }
    return CanvasStateSelectors.getShapeById(canvasState, id);
};

const insertShapeInContext = (canvasState, { shape, offset, context }) => {
    if (context && context.get('type') === 'Group') {
        return canvasState.updateIn([
            ...CanvasStateSelectors.getShapePathById(canvasState, context.get('id')),
            'shapes'
        ], shapes => shapes
            .splice(0, 0, shape
                .update('x', x => x - offset.x)
                .update('y', y => y - offset.y)));
    }
    return canvasState
        .updateIn(
            getShapesLayer(canvasState),
            shapes => shapes.splice(0, 0, shape)
        );
};

const reorderShapeInContext = (canvasState, {
    changeType,
    source,
    target,
    context
}) => {
    if (context && context.get('type') === 'Group') {
        return reorderChildren(canvasState, context, changeType, source, target);
    }

    return reorderShapes(
        canvasState,
        changeType,
        source,
        target
    );
};

const reorderChildren = (canvasState, context, action, ids, targetId) => {
    const orderedShapes = zIndexOrderer({
        array: context.get('shapes'),
        key: 'id',
        values: ids
    })[action](targetId);

    return canvasState.setIn(
        [
            ...getShapePathById(canvasState, context.get('id')),
            'shapes'
        ],
        orderedShapes
    );
};

const resetBoundingBoxesAndPositions = (canvasState, groups) => groups
    .reduce((currentCanvasState, group) => currentCanvasState
        .updateIn(
            getShapePathById(canvasState, group.get('id')),
            shape => resetBoundingBoxAndPosition(shape)
        ), canvasState);

export default changeShapeIndex;
