const isString = require('lodash/isString');
const isArray = require('lodash/isArray');

module.exports = exceptions => {
    if (
        !isArray(exceptions) ||
        exceptions.some(exception => !isString(exception))
    ) {
        throw new Error('deepExceptions must be an array of string');
    }
};
