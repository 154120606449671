const { DEFAULT_TOTALCOL_STYLE } = require('./config/defaultCellStyles.config.js');
const Cell = require('./Cell.js');

class TotalColumnCell extends Cell {
    constructor(name, x, y, width, height, attributes = {}) {
        super(name, x, y, width, height);
        this.isTotalColumnCell = true;
        Object.assign(this, DEFAULT_TOTALCOL_STYLE, attributes);
    }
}

module.exports = TotalColumnCell;
