import { fromJS } from 'immutable';
import { getComputedFill } from '../Fill';

const getComputedStroke = stroke => {
    let updatedStroke = stroke;
    updatedStroke = updatedStroke.set('fill', getComputedFill(updatedStroke, updatedStroke.get('fill')));

    return updatedStroke;
};

const copy = stroke => fromJS({
    align: stroke.get('align'),
    cap: stroke.get('cap'),
    compoundType: stroke.get('compoundType'),
    dash: stroke.get('dash'),
    fill: stroke.get('fill'),
    head: stroke.get('head'),
    join: stroke.get('join'),
    tail: stroke.get('tail'),
    width: stroke.get('width'),
    styleId: stroke.get('styleId')
});

const dashEmptySerialized = fromJS({ dashStops: [] });

export {
    // eslint-disable-next-line import/prefer-default-export
    getComputedStroke,
    copy,
    dashEmptySerialized
};
