import Point from '../../../Shape/Point';
import { getScaledHeight, getScaledWidth } from '../Shape';

const resetBoundingBoxAndPosition = group => {
    let updatedGroup = group
        .update('shapes', shapes => shapes.map(shape => {
            if (shape.get('type') === 'Group') {
                return resetBoundingBoxAndPosition(shape);
            }
            return shape;
        }));

    updatedGroup = unoffsetChildren(updatedGroup);
    updatedGroup = setSizeFromChildren(updatedGroup);
    updatedGroup = setPositionFromChildren(updatedGroup);
    updatedGroup = offsetChildren(updatedGroup);
    return updatedGroup;
};

const unoffsetChildren = group => group
    .update('shapes', shapes => shapes.map(shape => shape
        .update('x', x => x + group.get('x'))
        .update('y', y => y + group.get('y'))));

const setSizeFromChildren = group => {
    const {
        bottom, left, right, top
    } = getChildrenReducedBoundingBox(group);
    return group
        .set('width', right - left)
        .set('height', bottom - top);
};

const setPositionFromChildren = group => {
    const { bottom, right } = getChildrenReducedBoundingBox(group);
    return group
        .set('x', right - (group.get('width') / 2))
        .set('y', bottom - (group.get('height') / 2));
};

const offsetChildren = group => group
    .update('shapes', shapes => shapes.map(shape => shape
        .update('x', x => x - group.get('x'))
        .update('y', y => y - group.get('y'))));

const getChildrenReducedBoundingBox = group => {
    if (group.get('shapes')) {
        return group.get('shapes')
            .map(child => getBoundingBox(child))
            .reduce((currentBoundaryBox, objectBoundaryBox) => ({
                bottom: Math.max(currentBoundaryBox.bottom, objectBoundaryBox.bottom),
                left: Math.min(currentBoundaryBox.left, objectBoundaryBox.left),
                right: Math.max(currentBoundaryBox.right, objectBoundaryBox.right),
                top: Math.min(currentBoundaryBox.top, objectBoundaryBox.top)
            }), {
                bottom: -Infinity,
                left: Infinity,
                right: -Infinity,
                top: Infinity
            });
    }
    return {
        bottom: 0,
        left: 0,
        right: 0,
        top: 0
    };
};

const getBoundingBox = shape => {
    const corners = getBoundingBoxCorners(shape);
    const relativeBoundingBox = corners.reduce((currentBox, corner) => ({
        bottom: Math.max(currentBox.bottom, corner.y),
        left: Math.min(currentBox.left, corner.x),
        right: Math.max(currentBox.right, corner.x),
        top: Math.min(currentBox.top, corner.y)
    }), {
        bottom: -Infinity, left: Infinity, right: -Infinity, top: Infinity
    });
    return {
        bottom: shape.get('y') + relativeBoundingBox.bottom,
        left: shape.get('x') + relativeBoundingBox.left,
        right: shape.get('x') + relativeBoundingBox.right,
        top: shape.get('y') + relativeBoundingBox.top
    };
};

const getBoundingBoxCorners = shape => {
    let corners = [
        new Point((getScaledWidth(shape) / 2), getScaledHeight(shape) / 2), // Bottom Right
        new Point(-getScaledWidth(shape) / 2, getScaledHeight(shape) / 2), // Bottom Left
        new Point(-getScaledWidth(shape) / 2, -getScaledHeight(shape) / 2), // Top Left
        new Point(getScaledWidth(shape) / 2, -getScaledHeight(shape) / 2) // Top Right
    ];
    corners = corners.map(corner => applyBorderSize(shape, corner));
    corners.forEach(corner => corner.rotateInDegrees(shape.get('rotation')));
    return corners;
};

const applyBorderSize = (shape, corner) => {
    if (shape.getIn(['stroke', 'width'])) {
        const borderHalf = shape.getIn(['stroke', 'width']) / 2;
        return new Point(
            corner.x > 0 ? corner.x + borderHalf : corner.x - borderHalf,
            corner.y > 0 ? corner.y + borderHalf : corner.y - borderHalf
        );
    }
    return corner;
};

const destroyGroupsWithOnlyOneChild = group => group
    .update('shapes', shapes => (shapes ? shapes
        .map(shape => {
            let updatedShape = shape;
            if (shape.get('type') === 'Group') {
                updatedShape = destroyGroupsWithOnlyOneChild(updatedShape);
                if (shape.get('shapes').size === 1) {
                    updatedShape = unoffsetChildren(shape);
                    return updatedShape.getIn(['shapes', 0]);
                }
            }
            return updatedShape;
        }) : shapes));

export {
    resetBoundingBoxAndPosition,
    setSizeFromChildren,
    getChildrenReducedBoundingBox,
    destroyGroupsWithOnlyOneChild
};
