export const DELETE_DECK_SUCCESS = 'DELETE_DECK_SUCCESS';
export const EXPORT_AS_IMAGES = 'EXPORT_AS_IMAGES';
export const EXPORT_AS_PDF = 'EXPORT_AS_PDF';
export const EXPORT_FINISHED = 'EXPORT_FINISHED';
export const EXPORT_STARTED = 'EXPORT_STARTED';
export const FETCH_FILES = 'FETCH_FILES';
export const GET_DECKS = 'GET_DECKS';
export const LOAD_FILES = 'LOAD_FILES';
export const POPULATE_ELASTIC_SEARCH_BODY = '';
export const REMOVE_FILE_FROM_LIST = 'REMOVE_FILE_FROM_LIST';
export const RESET_DECKS_LIST = 'RESET_DECKS_LIST';
export const RESET_FILES_LIST = 'RESET_FILES_LIST';
export const SET_CURRENT_FILE = 'SET_CURRENT_FILE';
export const SET_DECKS = 'SET_DECKS';
export const SET_FILES = 'SET_FILES';
export const SET_MANAGE_SHOULD_FETCH_TRUE = 'SET_MANAGE_SHOULD_FETCH_TRUE';
export const TOGGLE_IMPORT_MODAL = 'TOGGLE_IMPORT_MODAL';
export const TOGGLE_IMPORT_PANEL = 'TOGGLE_IMPORT_PANEL';
export const UPDATE_CURRENT_FILE = 'UPDATE_CURRENT_FILE';
export const UPDATE_FILE_PROPERTIES = 'UPDATE_FILE_PROPERTIES';
export const SET_MANAGE_VIEW = 'SET_MANAGE_VIEW';
