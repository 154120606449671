const countNumberOfBrTagsInText = (textNodes = {}) => {
    let brCount = 0;
    if (textNodes && textNodes.forEach) {
        textNodes.forEach(child => {
            if (child.nodeName === 'br') {
                brCount += 1;
            } else {
                brCount += countNumberOfBrTagsInText(child.childNodes || {});
            }
        });
    } else if (textNodes['0']) {
        brCount = Object.values(textNodes)
            .reduce((accumulatedCount, textNode) => {
                if (textNode.nodeName === 'br') {
                    return accumulatedCount + 1;
                }
                return accumulatedCount + countNumberOfBrTagsInText(textNode.childNodes || {});
            }, brCount);
    }
    return brCount;
};

const calculateRunStyleEndIndex = (node, textOffset) => {
    const textContentLength = countNumberOfBrTagsInText(node.childNodes) + node.textContent.length;
    return textOffset + (textContentLength - 1);
};

module.exports = {
    calculateRunStyleEndIndex
};
