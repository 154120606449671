import { fromJS, Map } from 'immutable';
import { extractRGBAFromFirstDecsriptor } from '../Fill';
import strokeTipSizeToCanvasItemConversion from './strokeTipSizeToCanvasItemConversion';

export default fromJS({
    id: {
        itemUpdatePropertyName: 'styleId'
    },
    fill: {
        toItemUpdate: (value, shapeStyle) => shapeStyle
            .merge(Map({ strokeColor: value })),
        toStyle: shape => shape.getIn(['stroke', 'fill'], Map()),
        isFill: true
    },
    strokeType: {
        toItemUpdate: (value, shapeStyle) => shapeStyle
            .merge(Map({ strokeDash: value || 'solid' })),
        toStyle: shape => shape.getIn(['stroke', 'dash', 'type'], 'solid')
    },
    strokeWeight: {
        toItemUpdate: (value, shapeStyle) => shapeStyle
            .merge(Map({ strokeWidth: value || 0 })),
        toStyle: shape => shape.getIn(['stroke', 'width'], 0)
    },
    opacity: {
        itemUpdatePropertyName: 'strokeOpacity',
        toItemUpdate: (value, update, definition) => {
            let opacity = value;
            if (opacity === undefined) {
                const rgba = definition.get('fill');
                if (rgba) {
                    const components = extractRGBAFromFirstDecsriptor(definition.get('fill'));
                    if (components && components.alpha !== undefined) {
                        opacity = components.alpha;
                    }
                }
            }
            return update.set(
                'strokeOpacity',
                opacity
            );
        }
    },
    joinType: {
        toItemUpdate: (value, shapeStyle) => shapeStyle
            .merge(Map({ strokeJoinType: value || '' })),
        toStyle: shape => shape.getIn(['stroke', 'join', 'type'], '')
    },
    capType: {
        toItemUpdate: (value, shapeStyle) => shapeStyle
            .merge(Map({ strokeCapType: value || '' })),
        toStyle: shape => shape.getIn(['stroke', 'cap'], '')
    },
    startTipType: {
        toItemUpdate: (value, shapeStyle) => shapeStyle
            .merge(Map({ headTipType: value || '' })),
        toStyle: shape => shape.getIn(['stroke', 'head', 'type'], '')
    },
    endTipType: {
        toItemUpdate: (value, shapeStyle) => shapeStyle
            .merge(Map({ tailTipType: value || '' })),
        toStyle: shape => shape.getIn(['stroke', 'tail', 'type'], '')
    },
    startTipSize: {
        ...strokeTipSizeToCanvasItemConversion('head')
    },
    endTipSize: {
        ...strokeTipSizeToCanvasItemConversion('tail')
    },
    tipAlign: {
    }
});
