const pick = require('lodash/pick');
const get = require('lodash/get');

const complementAgainstSources = require('../../../utilities/complementAgainstSources');
const defaultsDeepWithExceptions = require('../../../utilities/defaultsDeepWithExceptions');
const ShapeStyle = require('../../Styles/ShapeStyle');
const Fill = require('../../Fill');

module.exports = Base => class extends Base {
    initShapeStyle(properties) {
        this.shapeStyle = new ShapeStyle(pick(properties, ShapeStyle.properties));
        this.shapeStyle.canvasItem = this;
    }

    getRenderShapeProperty(propertyPath) {
        return get(defaultsDeepWithExceptions(
            this.shapeStyle.toJSON(),
            (this.assignedShapeStyle ?
                this.assignedShapeStyle.toJSON() :
                {}),
            this.defaultShapeStyle.toJSON(),
            ShapeStyle.atomicProperties
        ), propertyPath);
    }

    setComplementShapeStyleAsItemShapeStyle() {
        this.initShapeStyle(complementAgainstSources(
            this.shapeStyle.toJSON(),
            this.defaultShapeStyle.toJSON(),
            (this.assignedShapeStyle ?
                this.assignedShapeStyle.toJSON() :
                {}),
            ShapeStyle.atomicProperties
        ));
    }

    copyAssignedShapeStyle(original) {
        if (original.assignedShapeStyle) {
            this.assignedShapeStyle = ShapeStyle
                .fromJSON(original.assignedShapeStyle.toJSON());
        }
    }

    updateShapeColorPresets({ presets, scheme }) {
        if (this.fill) {
            const oldOpacity = this.opacity;
            this.fill = Fill.updateColorPresets(this.fill, { presets, scheme });
            this.opacity = oldOpacity;
        }
        if (this.assignedShapeStyle && this.assignedShapeStyle.fill) {
            const oldOpacity = this.assignedShapeStyle.opacity;
            this.assignedShapeStyle.fill = Fill
                .updateColorPresets(this.assignedShapeStyle.fill, { presets, scheme });
            this.assignedShapeStyle.opacity = oldOpacity;
        }
        if (this.defaultShapeStyle && this.defaultShapeStyle.fill) {
            const oldOpacity = this.defaultShapeStyle.opacity;
            this.defaultShapeStyle.fill = Fill
                .updateColorPresets(this.defaultShapeStyle.fill, { presets, scheme });
            this.defaultShapeStyle.opacity = oldOpacity;
        }
    }

    forceShapeFillFromRendered() {
        if (this.fill === undefined) {
            this.fill = this.getRenderShapeProperty('fill');
        }
    }

    get defaultShapeStyle() {
        const style = new ShapeStyle(pick(
            this.defaults,
            ShapeStyle.properties
        ));

        style.canvasItem = this;

        return style;
    }

    set assignedShapeStyle(style) {
        if (!(style instanceof ShapeStyle)) {
            throw new Error('Shape assigned style should be an instance of Shape style');
        }
        style.canvasItem = this;
        this._shapeAssignedStyle = style;
    }

    get assignedShapeStyle() {
        return this._shapeAssignedStyle;
    }
};
