export const FETCHING_USER = 'FETCHING_USER';
export const FETCHED_USER = 'FETCHED_USER';
export const HIDE_SUBSCRIPTION_WARNING = 'HIDE_SUBSCRIPTION_WARNING';
export const LOGGING_IN = 'LOGGING_IN';
export const LOGGED_IN = 'LOGGED_IN';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGOUT_ERROR = 'LOGOUT_ERROR';
export const LOGGING_OUT = 'LOGGING_OUT';
export const LOGGED_OUT = 'LOGGED_OUT';
export const SET_USER = 'SET_USER';
export const SHOW_SUBSCRIPTION_WARNING = 'SHOW_SUBSCRIPTION_WARNING';
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_ERROR = 'UPDATE_PASSWORD_ERROR';
export const UPDATE_NAME = 'UPDATE_NAME';
export const UPDATE_NAME_DONE = 'UPDATE_NAME_DONE';
export const UPDATE_OCCUPATION = 'UPDATE_OCCUPATION';
export const UPDATE_OCCUPATION_DONE = 'UPDATE_OCCUPATION_DONE';
export const UPDATE_EMAIL = 'UPDATE_EMAIL';
export const UPDATE_EMAIL_DONE = 'UPDATE_EMAIL_DONE';
export const WELCOME_OPTION_CHANGED = 'WELCOME_OPTION_CHANGED';
export const CONFIRMATION_ERROR = 'CONFIRMATION_ERROR';
export const CONFIRMATION_SUCCESS = 'CONFIRMATION_SUCCESS';
export const RESEND_CONFIRMATION_SUCCESS = 'RESEND_CONFIRMATION_SUCCESS';
export const RESEND_CONFIRMATION_ERROR = 'RESEND_CONFIRMATION_ERROR';
