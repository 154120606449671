import { List, Map } from 'immutable';
import {
    getParagraph,
    addParagraph,
    getParagraphsCount,
    updateParagraphs
} from '../Paragraphs/Paragraphs';
import {
    getParagraphStyle,
    getParagraphStyles,
    getDefaultParagraphStyle,
    removeUnusedParagraphStyles,
    setDefaultParagraphStyle,
    getParagraphsWithStyle
} from '../Paragraphs/ParagraphStyles';
import {
    updateRuns
} from '../Runs/Runs';
import {
    setStyle,
    getRunStyles,
    getDefaultRunStyle,
    removeUnusedRunStyles,
    setDefaultRunStyle,
    getDefaultRunStyleFromFirstRun,
    getRunStyle,
    getRunStyleIndex
} from '../Runs/RunStyles';
import {
    getTextLength,
    getText,
    getEndIndex,
    isEmpty
} from './TextBodyProperties';
import TEXTBODY_PROPERTIES from '../Config/textBodyRecordProperties';
import defaultsDeepWithExceptions from '../../../utilities/defaultsDeepWithExceptions';
import intersectObjects from '../../../utilities/intersectObjects';

const styleDeepExceptions = ['color', 'bullet.style.color'];

const defaultsDeep = (...styles) => defaultsDeepWithExceptions(...styles, styleDeepExceptions);

const intersectStyles = (...styles) => intersectObjects(...styles, styleDeepExceptions);

const addTextWithStyle = (textBodyData, text, style, index) => {
    let addIndex = index;
    if (index === undefined) {
        addIndex = getTextLength(textBodyData);
    }
    const updatedTextBody = addText(textBodyData, text, addIndex);
    return setStyle(
        updatedTextBody,
        style,
        addIndex,
        addIndex + (text.length - 1)
    );
};

const setParagraphText = (textBodyData, index, text = '') => {
    const paragraph = getParagraph(textBodyData, index);
    return setText(
        textBodyData,
        text,
        paragraph.get('startIndex'),
        paragraph.get('endIndex')
    );
};

const setParagraphTextWithStyle = (textBodyData, index, text, style) => {
    let paragraph = getParagraph(textBodyData, index);
    if (!paragraph) throw new Error('Cannot set unexistant paragraph');

    const newTextBodyData = setParagraphText(textBodyData, index, text);
    paragraph = getParagraph(newTextBodyData, index);
    return setStyle(
        newTextBodyData,
        style,
        paragraph.get('startIndex'),
        paragraph.get('endIndex')
    );
};

const getStyledParagraphsWithStyledRuns = (textBodyData, withDefault = false) => getParagraphsWithStyle(
    textBodyData,
    0,
    getParagraphsCount(textBodyData) - 1,
    withDefault
)
    .map(paragraph => paragraph.set('style', getParagraphStyle(
        textBodyData,
        paragraph.get('style'),
        withDefault
    )));

const addParagraphWithStyle = (textBodyData, text = '', style) => {
    const updatedTextBody = addParagraph(textBodyData, text);
    const newParagraphIndex = getParagraphsCount(updatedTextBody) - 1;
    const paragraph = getParagraph(updatedTextBody, newParagraphIndex);

    return setStyle(
        updatedTextBody,
        style,
        paragraph.get('startIndex'),
        paragraph.get('endIndex')
    );
};

const updateStyleRanges = (
    textBodyData,
    startIndex,
    endIndex,
    originalLength
) => {
    // Update paragraphs
    let updatedTextBody = updateParagraphs(
        textBodyData,
        startIndex,
        originalLength,
        getTextLength(textBodyData)
    );

    // Update runs
    updatedTextBody = updateRuns(
        updatedTextBody,
        startIndex,
        endIndex,
        originalLength,
        getTextLength(updatedTextBody)
    );

    return removeUnusedParagraphStyles(
        removeUnusedRunStyles(updatedTextBody)
    );
};

const addText = (textBodyData, text, index = getTextLength(textBodyData)) => {
    const originalTextLength = getTextLength(textBodyData);
    const updatedTextBody = textBodyData.update(
        TEXTBODY_PROPERTIES.TEXT,
        t => t.substr(0, index) + text + t.substr(index)
    );
    return updateStyleRanges(
        updatedTextBody,
        index,
        index,
        originalTextLength
    );
};

const setText = (
    textBodyData,
    text,
    startIndex = 0,
    endIndex = getEndIndex(textBodyData, text, startIndex)
) => {
    let updatedTextBodyData = textBodyData;
    const originalText = getText(updatedTextBodyData);
    if (startIndex < 0 || endIndex > originalText.length) {
        throw new RangeError('Invalid range for setText');
    }
    if (startIndex === 0 && endIndex === -1) {
        if (text.length === 0) {
            updatedTextBodyData = setDefaultStyleFromFullText(updatedTextBodyData);
        }
        return updatedTextBodyData
            .set(TEXTBODY_PROPERTIES.TEXT, text)
            .set(TEXTBODY_PROPERTIES.PARAGRAPHS, List([Map({ startIndex: 0 })]))
            .set(TEXTBODY_PROPERTIES.RUNS, List());
    }

    return updateStyleRanges(
        updatedTextBodyData.update(
            TEXTBODY_PROPERTIES.TEXT,
            t => t.substr(0, startIndex) + text + t.substr(endIndex + 1)
        ),
        startIndex,
        endIndex,
        originalText.length
    );
};

const setTextWithStyle = (
    textBodyData,
    text,
    style,
    startIndex = 0,
    endIndex = getEndIndex(textBodyData, text, startIndex)
) => setStyle(
    setText(textBodyData, text, startIndex, endIndex),
    style,
    startIndex,
    startIndex + (text.length - 1)
);

const removeText = (textBodyData, startIndex, endIndex) => setText(textBodyData, '', startIndex, endIndex);

const setDefaultStyleFromFullText = textBodyData => {
    if (!isEmpty(textBodyData)) {
        let updatedTextBody = setDefaultRunStyle(
            textBodyData,
            getDefaultRunStyleFromFirstRun(textBodyData)
        );
        updatedTextBody = setDefaultParagraphStyle(
            updatedTextBody,
            getStyledParagraphsWithStyledRuns(textBodyData, true).get(0).get('style')
        );
        return updatedTextBody;
    }
    return textBodyData;
};

const getFullTextParagraphStyle = textBodyData => {
    const paragraphStyles = getParagraphStyles(textBodyData, true);
    const intersectedParagraphStyle = intersectStyles(...paragraphStyles);
    return defaultsDeep(
        intersectedParagraphStyle
            .set(
                'isDefault',
                true
            ),
        getDefaultParagraphStyle(textBodyData)
    );
};

const getFullTextRunStyle = textBodyData => {
    const runStyles = getRunStyles(textBodyData, true);
    const intersectedRunStyle = intersectStyles(...runStyles);
    return defaultsDeep(
        intersectedRunStyle
            .set(
                'isDefault',
                true
            ),
        getDefaultRunStyle(textBodyData)
    );
};

const getTextForStyles = (textBodyData, styles) => {
    const styleIndexes = styles
        .map(
            style => getRunStyleIndex(textBodyData, style)
        )
        .filter(index => index > -1);
    return textBodyData.get(TEXTBODY_PROPERTIES.RUNS)
        .filter(run => styleIndexes.includes(run.get('style')))
        .map(run => run.set('text', getText(textBodyData, run.get('startIndex'), run.get('endIndex')))
            .set('style', getRunStyle(textBodyData, run.get('style'))));
};

export {
    addParagraphWithStyle,
    addText,
    addTextWithStyle,
    getStyledParagraphsWithStyledRuns,
    removeText,
    setParagraphText,
    setParagraphTextWithStyle,
    setText,
    setTextWithStyle,
    getFullTextParagraphStyle,
    getFullTextRunStyle,
    getTextForStyles
};
