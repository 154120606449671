import { List } from 'immutable';
import CanvasStateSelectors from '../../../Canvas/CanvasStateSelectors';

const applyLayoutOnPage = (canvasState, command) => canvasState
    .update('shapes', shapes => applyLayoutPlaceholderToPageShapes(
        canvasState,
        shapes,
        command.get('unaffectedShapes') || List(),
        command.get('unaffectedShapesProperties') || List()
    ));

const applyLayoutPlaceholderToPageShapes = (
    canvasState,
    shapes,
    unaffectedShapes,
    unaffectedShapesProperties
) => shapes.map(shape => {
    if (shape.has('shapes')) {
        return shape.update(
            'shapes',
            groupShapes => applyLayoutPlaceholderToPageShapes(
                canvasState,
                groupShapes,
                unaffectedShapes,
                unaffectedShapesProperties
            )
        );
    } if (isShapePlaceholderWithLayoutSource(shape)) {
        const layoutPlaceholder = getLayoutSourcePlaceholder(
            canvasState,
            shape.get('placeholderSourceId')
        );
        if (layoutPlaceholder && !unaffectedShapes.some(id => id === shape.get('id'))) {
            return applyLayoutPlaceholderOnShape(shape, layoutPlaceholder, unaffectedShapesProperties.get(shape.get('id')) || List());
        }
    }
    return shape;
});

const applyLayoutPlaceholderOnShape = (
    shape,
    layoutPlaceholder,
    unaffectedShapesProperties
) => shape.withMutations(mutableShape => {
    if (unaffectedShapesProperties.indexOf('style') === -1) {
        mutableShape.set('style', layoutPlaceholder.get('style'));
    }
    if (unaffectedShapesProperties.indexOf('assignedStyles') === -1) {
        mutableShape.set('assignedStyles', layoutPlaceholder.get('assignedStyles'));
    }
    if (unaffectedShapesProperties.indexOf('stroke') === -1) {
        mutableShape.set('stroke', layoutPlaceholder.get('stroke'));
    }
    if (unaffectedShapesProperties.indexOf('fill') === -1) {
        mutableShape.set('fill', layoutPlaceholder.get('fill'));
    }
    if (unaffectedShapesProperties.indexOf('x') === -1 && unaffectedShapesProperties.indexOf('y') === -1) {
        mutableShape.set('x', layoutPlaceholder.get('x'));
        mutableShape.set('y', layoutPlaceholder.get('y'));
    }
    if (unaffectedShapesProperties.indexOf('witdh') === -1 && unaffectedShapesProperties.indexOf('height') === -1) {
        mutableShape.set('witdh', layoutPlaceholder.get('width'));
        mutableShape.set('height', layoutPlaceholder.get('height'));
    }
    if (unaffectedShapesProperties.indexOf('textBodyPlaceholder') === -1) {
        mutableShape.set('textBodyPlaceholder', layoutPlaceholder.get('textBodyPlaceholder'));
    }
    if (layoutPlaceholder.has('rx')) {
        mutableShape.set('rx', layoutPlaceholder.get('rx'));
    }
    if (layoutPlaceholder.has('ry')) {
        mutableShape.set('ry', layoutPlaceholder.get('ry'));
    }
    if (unaffectedShapesProperties.indexOf('textBody') === -1) {
        mutableShape.setIn(
            ['textBody', 'assignedStyles'],
            layoutPlaceholder.getIn(['textBodyPlaceholder', 'assignedStyles'])
        );

        mutableShape.setIn(
            ['textBody', 'autoFitShape'],
            layoutPlaceholder.getIn(['textBodyPlaceholder', 'autoFitShape'])
        );

        mutableShape.setIn(
            ['textBody', 'autoFitText'],
            layoutPlaceholder.getIn(['textBodyPlaceholder', 'autoFitText'])
        );

        mutableShape.setIn(
            ['textBody', 'margins'],
            layoutPlaceholder.getIn(['textBodyPlaceholder', 'margins'])
        );

        mutableShape.setIn(
            ['textBody', 'textDirection'],
            layoutPlaceholder.getIn(['textBodyPlaceholder', 'textDirection'])
        );

        mutableShape.setIn(
            ['textBody', 'verticalAlign'],
            layoutPlaceholder.getIn(['textBodyPlaceholder', 'verticalAlign'])
        );

        const defaultRunStyle = layoutPlaceholder
            .getIn(['textBodyPlaceholder', 'runStyles'])
            .find(run => run.get('isDefault'), undefined, { isDefault: true });

        const defaultParagraphStyle = layoutPlaceholder
            .getIn(['textBodyPlaceholder', 'paragraphStyles'])
            .find(run => run.get('isDefault'), undefined, { isDefault: true });

        mutableShape
            .setIn(
                ['textBody', 'runStyles'],
                List.of(defaultRunStyle)
            );

        mutableShape
            .setIn(
                ['textBody', 'paragraphStyles'],
                List.of(defaultParagraphStyle)
            );
    }
    return mutableShape;
});

const getLayoutSourcePlaceholder = (canvasState, sourceId) => CanvasStateSelectors
    .getFlattenShapes(canvasState.get('layoutShapes'))
    .find(layoutShape => layoutShape.get('id') === sourceId);

const isShapePlaceholderWithLayoutSource = shape => shape.has('placeholderSourceId');

export default applyLayoutOnPage;
