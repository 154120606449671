const { fromFabricToShape } = require('../utilities/fromFabricToShape');

module.exports = Base => class extends Base {
    static fromFabric(fabricObject, DOMParser, canvas, cellJSON = {}) {
        const originalCell = this.fromJSON(cellJSON);

        const cell = new this(
            fabricObject.name,
            fabricObject.row,
            fabricObject.column,
            fabricObject.rowSpan,
            fabricObject.columnSpan,
            null,
            {
                id: fabricObject.id,
                fill: fabricObject.fill,
                style: fabricObject.style
            }
        );

        cell.copyStyles(originalCell);
        cell.ownCaching = true;
        cell.contents = (fabricObject.contents || []).map(content => fromFabricToShape(
            canvas,
            content,
            DOMParser,
            (cellJSON.contents || [])
                .find(contentJSON => contentJSON.id === content.id)
        ));
        return cell;
    }

    toFabric() {
        return {
            ...super.toFabric(),
            width: this.width,
            height: this.height,
            left: this.x,
            top: this.y,
            originX: 'center',
            originY: 'center',
            column: this.column,
            row: this.row,
            columnSpan: this.columnSpan,
            rowSpan: this.rowSpan,
            fill: this.getRenderShapeProperty('fill'),
            contents: this.contents.map(content => content.fromShapeToFabric()),
            ownCaching: true
        };
    }

    get x() {
        return this.table.getColumnStartX(this.column) + (this.width / 2);
    }

    get y() {
        return this.table.getRowStartY(this.row) + (this.height / 2);
    }

    get width() {
        return Math.ceil(this.table.getWidthForCellAt(this.column, this.columnSpan));
    }

    get height() {
        return Math.ceil(this.table.getHeightForCellAt(this.row, this.rowSpan));
    }
};
