import { combineReducers } from 'redux';

import * as types from '#Constants/ActionTypes';

const defaultSearchBody = {
    id: 'default_search',
    params: {
        type: ['decks'],
        orderField: 'updatedAt',
        order: 'desc',
        from: 0,
        to: 0,
        size: 40,
        resourceOwnership: ['public', 'private'],
        field: ['text', 'title', 'word'],
        value: null
    }
};

const body = (state = defaultSearchBody, action) => {
    switch (action.type) {
        case types.SET_SEARCH_BODY:
            return action.body;
        case types.RESET_SEARCH_BODY:
            return defaultSearchBody;
        default:
            return state;
    }
};

export default combineReducers({
    body
});
