import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import Portal from '../Portal/Portal';
import ModalContent from './ModalContent';
import './Modal.scss';

const ModalTransition = ({ ...props }) => (
    <CSSTransition
        {...props}
        timeout={300}
        classNames="ModalTransition"
        unmountOnExit
    />
);

const Modal = ({
    title,
    closeModal,
    isVisible,
    children,
    cssCustomClass,
    action,
    actionIcon,
    actionLabel,
    hasForm,
    backdropCantClose
}) => (
    <Portal>
        <ModalTransition in={isVisible}>
            <ModalContent
                title={title}
                closeModal={closeModal}
                cssCustomClass={cssCustomClass}
                action={action}
                actionIcon={actionIcon}
                actionLabel={actionLabel}
                hasForm={hasForm}
                backdropCantClose={backdropCantClose}
            >
                {children}
            </ModalContent>
        </ModalTransition>
    </Portal>
);

Modal.defaultProps = {
    closeModal: null,
    title: null,
    cssCustomClass: null,
    action: null,
    actionIcon: null,
    actionLabel: null,
    hasForm: false,
    backdropCantClose: false
};

Modal.propTypes = {
    children: PropTypes.any.isRequired,
    closeModal: PropTypes.func,
    isVisible: PropTypes.bool.isRequired,
    title: PropTypes.string,
    cssCustomClass: PropTypes.string,
    action: PropTypes.func,
    actionIcon: PropTypes.string,
    actionLabel: PropTypes.string,
    hasForm: PropTypes.bool,
    backdropCantClose: PropTypes.bool
};

export default Modal;
