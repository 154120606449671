const {
    sameParagraphStyleInTextBodies
} = require('../utils');

const serializeBulletType = (bullet = { type: 'none' }) => {
    if (bullet.type === 'unordered') {
        return `${bullet.type}/•`;
    }
    if (bullet.type.startsWith('ordered')) {
        return bullet.type;
    }
    return bullet.type;
};

exports.computeBulletsFromTextBodies = (textBodies, textSelection) => {
    const type = sameParagraphStyleInTextBodies(textBodies, 'bullet.type', undefined, { textSelection });

    return type ?
        serializeBulletType({ type }) :
        'mixed';
};

exports.computeBulletColorFromTextBodies = (textBodies, textSelection) => sameParagraphStyleInTextBodies(
    textBodies,
    'bullet.style.color.value',
    undefined,
    { textSelection }
);

exports.computeBulletColorPresetFromTextBodies = (textBodies, textSelection) => sameParagraphStyleInTextBodies(
    textBodies,
    'bullet.style.color.preset',
    '',
    { textSelection }
);

exports.computeBulletFamilyFromTextBodies = (textBodies, textSelection) => sameParagraphStyleInTextBodies(
    textBodies,
    'bullet.style.font',
    undefined,
    {
        textSelection
    }
);

exports.computeBulletFontWeightFromTextBodies = (textBodies, textSelection) => sameParagraphStyleInTextBodies(
    textBodies,
    'bullet.style.fontWeight',
    'normal',
    {
        textSelection
    }
);

exports.computeBulletFontStyleFromTextBodies = (textBodies, textSelection) => sameParagraphStyleInTextBodies(
    textBodies,
    'bullet.style.fontStyle',
    'normal',
    {
        textSelection
    }
);

exports.computeBulletLevelFromTextBodies = (textBodies, textSelection) => sameParagraphStyleInTextBodies(
    textBodies,
    'level',
    undefined,
    {
        textSelection
    }
);

exports.computeBulletSizeFromTextBodies = (textBodies, textSelection) => sameParagraphStyleInTextBodies(
    textBodies,
    'bullet.style.size',
    undefined,
    {
        textSelection
    }
);

exports.computeBulletTextFromTextBodies = (textBodies, textSelection) => sameParagraphStyleInTextBodies(
    textBodies,
    'bullet.text',
    undefined,
    {
        textSelection
    }
);

exports.computeBulletLeftPaddingFromTextBodies = (textBodies, textSelection) => sameParagraphStyleInTextBodies(
    textBodies,
    'padding.left',
    undefined,
    {
        textSelection
    }
);
