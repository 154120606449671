import Textbody from '../../../../../../CanvasState/Helpers/Text/TextBody';
import { adaptTextBodyToFabricParagraphs } from '../../../TextBodyAdapters/textBodyAdapter';

const getParagraphs = (shape, colorPalette) => adaptTextBodyToFabricParagraphs(
    shape,
    Textbody.addParagraphIfEmpty(
        shape.get('textBody'),
        shape.get('textBodyPlaceholder')
    ),
    colorPalette
);

const getPlaceholderParagraphs = (shape, colorPalette) => adaptTextBodyToFabricParagraphs(
    shape,
    shape.get('textBodyPlaceholder'),
    colorPalette
);

export {
    getParagraphs,
    getPlaceholderParagraphs
};
