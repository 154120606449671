const { fabric } = require('fabric');
const get = require('lodash/get');
const TextBoundsCalculator = require('../utilities/TextBoundsCalculator');
const Textshape = require('./TextShape');

const Ellipse = fabric.util.createClass(Textshape, {
    type: 'ellipse',

    initialize(json, opts = {}) {
        this.callSuper('initialize', json, opts);
        this.shapeType = 'Ellipse';
    },

    updateShapeScale(scaleX, scaleY, fromGroup) {
        const shapeObject = this.getShapeObject();
        const strokeWidth = get(this, 'stroke.width', 0);

        let actualScale = {
            x: scaleX,
            y: scaleY
        };

        if (fromGroup) {
            actualScale = this.getScaleWithRotation(scaleX, scaleY);
        }

        shapeObject.set({
            rx: shapeObject.rx * actualScale.x,
            ry: shapeObject.ry * actualScale.y,
            pathOffset: {
                x: (((shapeObject.width + strokeWidth) * actualScale.x) - strokeWidth) / 2,
                y: (((shapeObject.height + strokeWidth) * actualScale.y) - strokeWidth) / 2
            }
        });
        this.callSuper('updateShapeScale', scaleX, scaleY, true, fromGroup);
    },

    getTextBounds() {
        const shape = this.getShapeObject();
        return new TextBoundsCalculator.Ellipse(
            this.width,
            this.height,
            {
                flipX: shape.flipX
            }
        );
    }
});

Ellipse.fromObject = (object, callback) => {
    const ellipse = new Ellipse(object);
    ellipse.on('text:load', () => {
        callback(ellipse);
    })
        .on('load:error', err => {
            console.error(err);
            callback(null);
        });
};

module.exports = Ellipse;
