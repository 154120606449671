module.exports = fabric => {
    /* eslint-disable */
    fabric.Object.prototype.renderDecorations = function renderDecorations() {
        return this;
    };

    fabric.Object.prototype.isPlaceholder = function isPlaceholder() {
        return (this.placeholderType || '').length > 0;
    };

    fabric.Object.prototype.isDisplayedInLayoutEditMode = function isDisplayedInLayoutEditMode() {
        return this.inLayout || this.isPlaceholder();
    };

    /*
    * Need to do this manually because inside groups they are not added properly
    */
    fabric.Object.prototype.setMiddleCoords = function setMiddleCoords() {
        const angle = fabric.util.degreesToRadians(this.angle);
        const cos = fabric.util.cos(angle);
        const sin = fabric.util.sin(angle);

        const {
            tl,
            tr,
            br,
            bl
        } = this.oCoords;

        const mt  = new fabric.Point((tr.x + tl.x) / 2, (tr.y + tl.y) / 2);

        const rotatingPointOffset = this.rotatingPointOffset / this.canvas.getZoom();

        this.oCoords = {
            ...this.oCoords,
            mt,
            ml: new fabric.Point((tl.x + bl.x) / 2, (tl.y + bl.y) / 2),
            mr: new fabric.Point((br.x + tr.x) / 2, (br.y + tr.y) / 2),
            mb: new fabric.Point((br.x + bl.x) / 2, (br.y + bl.y) / 2),
            mtr: new fabric.Point(mt.x + sin * rotatingPointOffset, mt.y - cos * rotatingPointOffset)
        }
    }

    fabric.Object.prototype.setCornerCoordsWithZoom = function setCornerCoordsWithZoom() {
        var coords = this.oCoords,
            newTheta = fabric.util.degreesToRadians(45 - this.angle),
            cornerHypotenuse = (this.cornerSize / this.canvas.getZoom()) * 0.707106,
            cosHalfOffset = cornerHypotenuse * fabric.util.cos(newTheta),
            sinHalfOffset = cornerHypotenuse * fabric.util.sin(newTheta),
            x, y;

        for (var point in coords) {
            x = coords[point].x;
            y = coords[point].y;
            coords[point].corner = {
                tl: {
                    x: x - sinHalfOffset,
                    y: y - cosHalfOffset
                },
                tr: {
                    x: x + cosHalfOffset,
                    y: y - sinHalfOffset
                },
                bl: {
                    x: x - cosHalfOffset,
                    y: y + sinHalfOffset
                },
                br: {
                    x: x + sinHalfOffset,
                    y: y + cosHalfOffset
                }
            };
        }
    }

    /*
    * Calculates absolute center instead of relative to group
    */
    fabric.Object.prototype.getAbsoluteCenterPoint = function getAbsoluteCenterPoint() {
        const absolute = this.getAbsolutePosition();
        var leftTop = new fabric.Point(absolute.x, absolute.y);
        return this.translateToCenterPoint(leftTop, this.originX, this.originY);
    }

    fabric.Object.prototype.cornerStyle = 'decksign';
    fabric.Object.prototype.cornerSize = 7;
    fabric.Object.prototype.decksignHandleFill = 'white';

    /**
     * Taken from fabric.js source line 16 026
     * Overriden to add the decksign corner style
     */
    fabric.Object.prototype._drawControl = function _drawControl(control, ctx, methodName, left, top, styleOverride) {
        const styleOverrideOrDefault = styleOverride || {};
        if (!this.isControlVisible(control)) {
            return;
        }
        const size = this.cornerSize;
        const stroke = !this.transparentCorners && this.cornerStrokeColor;
        const fillSave = ctx.fillStyle;
        switch (styleOverrideOrDefault.cornerStyle || this.cornerStyle) {
            case 'circle':
                ctx.beginPath();
                ctx.arc(left + (size / 2), top + (size / 2), size / 2, 0, 2 * Math.PI, false);
                ctx[methodName]();
                if (stroke) {
                    ctx.stroke();
                }
                break;
            case 'decksign':
                ctx.fillStyle = this.decksignHandleFill;
                ctx.fillRect(left, top, size, size);
                ctx.strokeRect(left, top, size, size);
                ctx.fillStyle = fillSave;
                break;
            default:
                if (!this.transparentCorners) ctx.clearRect(left, top, size, size);
                ctx[`${methodName}Rect`](left, top, size, size);
                if (stroke) {
                    ctx.strokeRect(left, top, size, size);
                }
        }
    };

    /*
    * Adds missing method added in later versions of fabric needed to calculate bounds properly
    */
    fabric.Object.prototype.calcACoords = function () {
        var rotateMatrix = this._calcRotateMatrix(),
            translateMatrix = this._calcTranslateMatrix(),
            finalMatrix = fabric.util.multiplyTransformMatrices(translateMatrix, rotateMatrix),
            dim = this._getTransformedDimensions(),
            w = dim.x / 2, h = dim.y / 2;
        return {
            // corners
            tl: fabric.util.transformPoint({ x: -w, y: -h }, finalMatrix),
            tr: fabric.util.transformPoint({ x: w, y: -h }, finalMatrix),
            bl: fabric.util.transformPoint({ x: -w, y: h }, finalMatrix),
            br: fabric.util.transformPoint({ x: w, y: h }, finalMatrix)
        };
    };

    /*
    * Custom method to calculate absolute position
    */
    fabric.Object.prototype.getAbsolutePosition = function getAbsolutePosition() {
        if (this.group) {
            const parentPosition = this.group.getAbsolutePosition();

            const radians = fabric.util.degreesToRadians(this.group.angle);

            const rotatedPoint = fabric.util.rotatePoint(
                new fabric.Point(this.left, this.top),
                new fabric.Point(0, 0),
                radians
            );

            return {
                x: parentPosition.x + rotatedPoint.x,
                y: parentPosition.y + rotatedPoint.y
            };
        }

        return {
            x: this.left,
            y: this.top
        };
    };

    /*
    * Custom method to calculate absolute angle
    */
    fabric.Object.prototype.getAbsoluteAngle = function getAbsoluteAngle() {
        if (this.group) {
            const parentAngle = this.group.getAbsoluteAngle();

            return parentAngle + this.angle;
        }

        return this.angle;
    };



    /*
    * Overrides default fabric behaviour preventing controls of objects inside groups to be hovered
    */
    fabric.Object.prototype._findTargetCorner = function _findTargetCorner(pointer) {
        if (!this.hasControls || (!this.canvas || this.canvas._activeObject !== this)) {
            return false;
        }

        var ex = pointer.x,
            ey = pointer.y,
            xPoints,
            lines;
        this.__corner = 0;

        // If inside group need to do setCoords to set corner properly in oCoords
        if (this.group) {
            this.oCoords = this.calcCoords(true);
            this.setMiddleCoords();
            this.setCornerCoordsWithZoom();

            if (this.type !== 'activeSelection') {
                const normalizedPointer = this.canvas._normalizePointer(this.group, pointer);

                ex = normalizedPointer.x;
                ey = normalizedPointer.y;
            }
        }

        for (var i in this.oCoords) {

            if (!this.isControlVisible(i)) {
                continue;
            }

            if (i === 'mtr' && !this.hasRotatingPoint) {
                continue;
            }

            if (this.get('lockUniScaling') &&
                (i === 'mt' || i === 'mr' || i === 'mb' || i === 'ml')) {
                continue;
            }

            lines = this._getImageLines(this.oCoords[i].corner);

            xPoints = this._findCrossPoints({ x: ex, y: ey }, lines);
            if (xPoints !== 0 && xPoints % 2 === 1) {
                this.__corner = i;
                return i;
            }
        }
        return false;
    }

    /*
    * Overrides default fabric logic to use absolute center point instead so it works for shapes inside groups
    */
    fabric.Object.prototype.toLocalPoint = function toLocalPoint(point, originX, originY) {
        var center = this.getAbsoluteCenterPoint(),
          p, p2;

        if (typeof originX !== 'undefined' && typeof originY !== 'undefined' ) {
            p = this.translateToGivenOrigin(center, 'center', 'center', originX, originY);
        }
        else {
            p = new fabric.Point(this.left, this.top);
        }

        p2 = new fabric.Point(point.x, point.y);
        if (this.angle) {
            p2 = fabric.util.rotatePoint(p2, center, -fabric.util.degreesToRadians(this.angle));
        }
        return p2.subtractEquals(p);
    }

    fabric.Object.prototype._finalizeDimensions = function (width, height) {
        return this.strokeUniform && !['CustomLine', 'Line'].includes(this.shapeType) ?
            { x: width + this.strokeWidth, y: height + this.strokeWidth } : { x: width, y: height };
    }
};
