import { Map } from 'immutable';
import isString from 'lodash/isString';
import getFirstRunStyle from './getFirstRunStyle';

export default {
    toItemUpdate(value, shapeStyle = Map()) {
        const cleanedUpdate = shapeStyle.set('textTransform', '');
        switch (value) {
            case 'subscript':
            case 'superscript':
            case 'overline':
            case 'underline':
            case 'linethrough':
                return cleanedUpdate
                    .set(
                        value,
                        true
                    );
            case 'smallCaps':
            case 'allCaps':
            case 'capitalize':
                return cleanedUpdate
                    .set(
                        'textTransform',
                        value
                    );
            default:
                return cleanedUpdate;
        }
    },
    toStyle(shape = Map()) {
        const firstRunStyle = getFirstRunStyle(shape);
        let effect = ['subscript', 'superscript', 'overline', 'underline', 'linethrough'].find(property => (
            firstRunStyle.get(property) === true
        ));

        const textTransform = firstRunStyle.get('textTransform');

        if (!effect && isString(textTransform)) {
            effect = textTransform;
        }

        return effect;
    }
};
