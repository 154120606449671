const { fromJS } = require('immutable');

const defaultsDeepWithExceptions = require('../../utilities/defaultsDeepWithExceptions');
const Stroke = require('../../Shape/Stroke/Stroke');
const { defaultStrokeStyle } = require('../../Shape/AbstractShape/config/defaultShapeStyle');

exports.computeSelectionStroke = canvasStateItems => fromJS(defaultsDeepWithExceptions(
    canvasStateItems.first().get('stroke') ? canvasStateItems.first().get('stroke').toJS() : {},
    canvasStateItems.first().getIn(['assignedStyles', 'stroke']) ? canvasStateItems.first().getIn(['assignedStyles', 'stroke']).toJS() : {},
    defaultStrokeStyle,
    Stroke.atomicProperties
));
