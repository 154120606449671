import { mergeCells, splitCells } from '../../Helpers/Table/table';

const merge = canvasState => mergeCells(canvasState);

const split = (canvasState, command) => splitCells(canvasState, command.get('rows'), command.get('columns'));

export {
    merge,
    split
};
