const tinycolor = require('tinycolor2');

exports.getRgba = input => {
    if (exports.isValid(input)) {
        const rgbaObject = exports.getRgbaObject(input);

        return exports.stringifyRgbaObject(rgbaObject);
    }
    throw new Error(`Invalid ${input} color can't convert to rgba`);
};

exports.getRgbaObject = input => {
    if (exports.isValid(input)) {
        return tinycolor(input).toRgb();
    }
    throw new Error(`Invalid ${input} color can't convert to rgba object`);
};

exports.stringifyRgbaObject = ({
    r,
    g,
    b,
    a
}) => `rgba(${r},${g},${b},${a})`;

exports.isValid = input => tinycolor(input).isValid();
