import {
    Record,
    Map,
    List,
    fromJS
} from 'immutable';
import UUID from 'uuid/v4';
import genericShapeProperties from '../../genericShapeProperties';
import { addShape, getNewShapeName, updateSelection } from '../../Helpers/helpers';
import { generateCalloutPath } from '../../../utilities/PathGenerator/PathGenerator';
import { getCalloutTailVertexes } from '../../../utilities/ShapeGenerators';
import Textbody from '../../Helpers/Text/TextBody';
import getPropertiesForDestructuring from '../../utilities/getPropertiesForDestructuring';
import { applyStyle } from '../../Helpers/Style/style';
import { convertPropertiesToShapeProperties } from '../../utilities/convertPropertiesToShapeProperties';

const CalloutRecord = Record({
    ...genericShapeProperties,
    type: 'Callout',
    tailWidth: 0,
    tailLength: 0,
    tailAngle: 0,
    tailVertexes: List([]),
    path: '',
    lockPath: false
}, 'Callout');

const add = (canvasState, command) => {
    const {
        shapes,
        defaultCanvasItemStyles,
        editMode
    } = getPropertiesForDestructuring(
        command,
        [
            'shapes',
            'defaultCanvasItemStyles',
            'editMode'
        ]
    );

    const newIds = [];

    let updatedCanvasState = shapes.reduce((currentCanvasState, shape) => {
        const style = defaultCanvasItemStyles.get(shape.get('styleToLoad') || 'shape');
        const styleDefinition = applyStyle(Map(), style, 'Callout');
        const styleToApply = convertPropertiesToShapeProperties(styleDefinition);

        const textBody = Textbody.TextBody({
            paragraphStyles: List([Map({ isDefault: true })]),
            paragraphs: List([Map({ startIndex: 0, endIndex: -1, style: 1 })]),
            runStyles: List([Map({ isDefault: true })]),
            assignedStyles: Map({
                paragraph: styleToApply.getIn(['textProps', 'assignedParagraphStyle']),
                run: styleToApply.getIn(['textProps', 'assignedRunStyle']),
                textBody: styleToApply.getIn(['textProps', 'assignedStyle'])
            })
        });

        const textBodyPlaceholder = Textbody.TextBody({
            paragraphStyles: List([Map({ isDefault: true })]),
            paragraphs: List([Map({ startIndex: 0, endIndex: -1, style: 1 })]),
            runStyles: List([Map({ isDefault: true })]),
            assignedStyles: Map({
                paragraph: styleToApply.getIn(['textProps', 'assignedParagraphStyle']),
                run: styleToApply.getIn(['textProps', 'assignedRunStyle']),
                textBody: styleToApply.getIn(['textProps', 'assignedStyle'])
            })
        });

        const modifiers = shape.get('modifiers');

        const tailVertexes = getCalloutTailVertexes(
            modifiers.get('width'),
            modifiers.get('height'),
            modifiers.get('tailLength'),
            modifiers.get('tailWidth'),
            modifiers.get('tailAngle')
        );

        const path = generateCalloutPath(
            modifiers.get('width'),
            modifiers.get('height'),
            tailVertexes
        );

        const newShape = CalloutRecord(
            modifiers
                .merge(
                    fromJS({
                        name: getNewShapeName(currentCanvasState, 'Callout'),
                        id: UUID().toString(),
                        inLayout: editMode === 'layout',
                        x: currentCanvasState.get('size').get('width') / 2,
                        y: currentCanvasState.get('size').get('height') / 2,
                        assignedStyles: Map({
                            shape: styleToApply.getIn(['shapeProps', 'assignedShapeStyle']),
                            stroke: styleToApply.getIn(['strokeProps', 'assignedStrokeStyle'])
                        }),
                        style: style.get('id'),
                        textBody,
                        textBodyPlaceholder,
                        tailVertexes,
                        path
                    })
                )
        );
        newIds.push(newShape.get('id'));
        return addShape(currentCanvasState, newShape);
    }, canvasState);
    updatedCanvasState = updateSelection(updatedCanvasState, fromJS({ selection: newIds }));
    return updatedCanvasState;
};

export default add;
