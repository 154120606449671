const jsonschema = require('jsonschema');
const isNil = require('lodash/isNil');
const get = require('lodash/get');
const set = require('lodash/set');
const omit = require('lodash/omit');
const defaultsDeep = require('lodash/defaultsDeep');
const cloneDeepWith = require('lodash/cloneDeepWith');

const TextBodyData = require('../TextBodyData');
const listUpdateSchema = require('../config/listUpdate.jsonschema');
const { bulletStrategies, bulletStrategiesValidator } = require('../../../utilities/bulletStrategies');
const defaultBullets = require('../config/defaultBullets');

const getBulletSizeRatioFromItemUpdate = update => {
    const textSize = get(update, 'font.size');
    const bulletSize = get(update, 'bullet.style.size');
    if (!isNil(textSize) && !isNil(bulletSize) && textSize > 0) {
        return bulletSize / textSize;
    }
    return 1;
};

const getIndentSizeRatioFromItemUpdate = update => {
    const textSize = get(update, 'font.size');
    const indentSize = get(update, 'paragraph.indent', textSize);
    const bulletType = get(update, 'bullet.type', 'none');
    if (bulletType === 'none') {
        return 0;
    }
    if (!isNil(textSize) && !isNil(indentSize) && textSize > 0) {
        return indentSize / textSize;
    }
    return 1;
};

const getLeftPaddingRatioFromItemUpdate = update => {
    const textSize = get(update, 'font.size');
    const leftPaddingSize = get(update, 'paragraph.padding.left', textSize);
    if (!isNil(textSize) && !isNil(leftPaddingSize) && textSize > 0) {
        return leftPaddingSize / textSize;
    }
    return 1;
};

module.exports = Base => class extends Base {
    static parseSerializedBullet(propertyValue = { type: 'none' }) {
        let options = '';

        if (typeof propertyValue === 'string') {
            options = propertyValue;
        } else if (options.type === 'ordered') {
            options = `${propertyValue.type}/${propertyValue.orderStrategy}`;
        } else {
            options = `${propertyValue.type}/${propertyValue.text}`;
        }

        const bulletType = options.split('/')[0];
        const bullets = {
            type: bulletType
        };
        if (bulletType === 'ordered') {
            const orderStrategy = options.split('/')[1];
            bullets.orderStrategy = orderStrategy;
        } else {
            const text = options.split('/')[1];
            if (text) {
                bullets.type = 'unordered';
                bullets.text = text;
            }
        }
        return bullets;
    }

    constructor(...args) {
        super(...args);
        if (!this._bullets) {
            this._bullets = defaultBullets;
        }
    }

    set bullets(propertyValue) {
        const bullets = this.constructor.parseSerializedBullet(propertyValue);
        this.setParagraphStyleProperties({ bullet: bullets });
        this._bullets = bullets;
    }

    get bullets() {
        return this._bullets;
    }

    setAllBulletsType(options) {
        this._bullets = {
            type: options.type
        };
        if (options.text) this.bullets.text = options.text;
        if (options.orderStrategy) this.bullets.orderStrategy = options.orderStrategy;
        bulletStrategiesValidator(this.bullets, this);
        this.getParagraphs().forEach((paragraph, index) => {
            this.setBulletTextToItem(index, bulletStrategies(index, this.bullets, this));
            this.setLevelToItem(index, 1);
        });
    }

    getItemBulletText(index) {
        const bullet = this.getBulletOfItem(index);
        if (bullet.type === 'unordered' && !bullet.text) {
            bullet.text = '•';
        }
        return bulletStrategies(index, bullet, this);
    }

    addItem(text, level = 1) {
        this.addParagraph(text);
        const nextIndex = this.getParagraphsCount() - 1;
        this.setLevelToItem(nextIndex, level);
        this.setBulletTextToItem(nextIndex, bulletStrategies(nextIndex, this.bullets, this));
    }

    getBulletOfItem(index) {
        let bullet = this.bullets || defaultBullets;
        let { style: styleIndex } = this.getParagraph(index);
        styleIndex = styleIndex || 0;
        if (!Number.isNaN(Number.parseInt(styleIndex, 10))) {
            const runStyle = this.getParagraphStyle(styleIndex, true) || {};
            bullet = runStyle.bullet || bullet;
        }
        return {
            ...this.constructor.parseSerializedBullet(bullet.type),
            ...bullet
        };
    }

    setItem(index = 0, text = '', level = 1) {
        this.setParagraphText(index, text);
        this.setLevelToItem(index, level);
        this.setBulletTextToItem(index, bulletStrategies(index, this.bullets, this));
    }

    setBulletTextToItem(item, text) {
        this.setParagraphStylePropertiesAt(item, {
            bullet: {
                type: this.bullets.type === 'ordered' ? `${this.bullets.type}/${this.bullets.orderStrategy}` : this.bullets.type,
                text
            }
        });
    }

    setBulletToItem(item, bullet = { type: 'none' }) {
        this.textBodyData = TextBodyData.setParagraphStyleProperties(
            this.textBodyData,
            {
                bullet: {
                    type: bullet.type,
                    text: bulletStrategies(item, bullet, this),
                    style: bullet.style
                }
            },
            item,
            item
        );
    }

    setLevelToItem(itemIndex, level, overwriteIndentAndPadding = false) {
        this.setParagraphStylePropertiesAt(itemIndex, {
            level
        });
        const {
            startIndex
        } = this.getParagraph(itemIndex);

        this.setLeveledTextPosition({
            index: itemIndex,
            level,
            startIndex,
            onlyUpdatePadding: true,
            overwriteIndentAndPadding
        });
    }

    setIndentAndPaddingFromLevel({ onlyUpdatePadding = false } = {}) {
        const paragraphs = this.getParagraphsWithParagraphStyle(undefined, undefined, true);
        paragraphs.forEach((paragraph, paragraphIndex) => {
            if (!isNil(paragraph.style)) {
                if (!isNil(paragraph.style.level) && paragraph.style.level > 0 && get(paragraph.style, 'bullet.type', 'none') !== 'none') {
                    this.setLeveledTextPosition({
                        index: paragraphIndex,
                        level: paragraph.style.level,
                        startIndex: paragraph.startIndex,
                        onlyUpdatePadding
                    });
                }
            }
        });
    }

    setLeveledTextPosition({
        index,
        level,
        startIndex,
        onlyUpdatePadding = false,
        overwriteIndentAndPadding = false
    }) {
        const [run = {}] = this.getRunsAt(startIndex);

        let runStyle = this.getDefaultRunStyle();

        if (!isNil(run.style)) {
            runStyle = this.getRunStyle(Number(run.style || 0), true);
        }

        const {
            font: {
                size: fontSize
            } = {}
        } = runStyle || {};

        let setPadding = true;
        let setIndent = true;
        const paragraph = this.getParagraph(index);
        if (paragraph.style) {
            const originalParagraphStyle = this.getParagraphStyle(paragraph.style, true);
            if (get(originalParagraphStyle, 'padding.left')) {
                setPadding = false;
            }
            if (get(originalParagraphStyle, 'indent') || onlyUpdatePadding) {
                setIndent = false;
            }
        }
        if (overwriteIndentAndPadding) {
            setPadding = true;
            setIndent = true;
        }

        let update = {};

        if (setPadding) {
            update = set(update, 'padding.left', Math.max(fontSize + (fontSize * 2 * (level - 1)), 0));
        }

        if (setIndent) {
            update = set(update, 'indent', -fontSize);
        }

        if (setIndent || setPadding) {
            this.textBodyData = TextBodyData.setParagraphStyleProperties(
                this.textBodyData,
                update,
                index,
                index
            );
        }
    }

    applyListUpdate(update = {}) {
        jsonschema.validate(update.listUpdate, listUpdateSchema, { throwError: true });
        const {
            onlyBulletUpdate,
            allLevels,
            level1,
            level2,
            level3,
            level4,
            level5
        } = update.listUpdate;

        const {
            textSelection
        } = update;

        // Properly sets up list related properties so applyLeveledUpdate works properly
        this.convertToListType(update);

        if (allLevels) {
            [1, 2, 3, 4, 5].forEach(level => {
                this.applyLeveledUpdate({
                    update: allLevels,
                    textSelection,
                    level,
                    onlyBulletUpdate
                });
            });
            if (!update.textSelection) {
                this.applyListUpdateToDefault({
                    onlyBulletUpdate,
                    update: allLevels
                });
            }
        } else {
            [level1, level2, level3, level4, level5].forEach((leveledUpdate, index) => {
                this.applyLeveledUpdate({
                    update: leveledUpdate,
                    textSelection,
                    level: index + 1,
                    onlyBulletUpdate
                });
            });
        }
    }

    applyListUpdateToDefault({
        onlyBulletUpdate,
        update
    } = {}) {
        this.applyBulletStyleUpdateToDefault({
            onlyBulletUpdate,
            update
        });
    }

    applyBulletStyleUpdateToDefault({
        onlyBulletUpdate,
        update
    } = {}) {
        const bulletSizeRatio = onlyBulletUpdate ?
            getBulletSizeRatioFromItemUpdate(update) :
            1;
        const indentSizeRatio = onlyBulletUpdate ?
            getIndentSizeRatioFromItemUpdate(update) :
            1;
        const leftPaddingRatio = getLeftPaddingRatioFromItemUpdate(update);
        const defaultFontSize = get(this.getDefaultRunStyle(), 'font.size');
        const defaultBulletStyle = {
            ...get(update, 'bullet.style'),
            size: defaultFontSize * bulletSizeRatio
        };
        this.setDefaultParagraphStyle(defaultsDeep(
            {
                bullet: {
                    style: defaultBulletStyle,
                    text: bulletStrategies(0, update.bullet, this),
                    type: update.bullet.type
                },
                indent: defaultFontSize * indentSizeRatio,
                padding: {
                    left: defaultFontSize * leftPaddingRatio
                }
            },
            this.getDefaultParagraphStyle()
        ));
    }

    applyLeveledUpdate({
        update,
        textSelection,
        level,
        onlyBulletUpdate
    }) {
        let bulletSizeRatio;
        let indentSizeRatio;
        let leftPaddingRatio;
        if (onlyBulletUpdate) {
            bulletSizeRatio = getBulletSizeRatioFromItemUpdate(update);
            indentSizeRatio = getIndentSizeRatioFromItemUpdate(update);
            leftPaddingRatio = getLeftPaddingRatioFromItemUpdate(update);
        }
        const levelItems = this.getItemsForTextSelectionAndLevel(textSelection, level);

        levelItems.forEach(paragraph => {
            let newBulletSize = get(update, 'bullet.style.size');
            let newIndent = get(update, 'paragraph.indent');
            let newLeftPadding = get(update, 'paragraph.padding.left');
            if (onlyBulletUpdate) {
                const firstRunInParagraph = this
                    .getFirstRunInParagraph(paragraph.index, true) || {};
                const currentFontSize = get(defaultsDeep(firstRunInParagraph.style || {}, this.getDefaultRunStyle()), 'font.size');
                newBulletSize = currentFontSize * bulletSizeRatio;
                newIndent = currentFontSize * indentSizeRatio;
                newLeftPadding = currentFontSize * leftPaddingRatio;
            }
            const bulletUpdate = cloneDeepWith(
                update.bullet,
                (value, key) => (key === 'size' ? newBulletSize : undefined)
            );
            this.setBulletToItem(paragraph.index, bulletUpdate);
            if (!onlyBulletUpdate) {
                set(update, 'paragraph.padding.left', newLeftPadding);
                set(update, 'paragraph.indent', newIndent);
                this.textBodyData = TextBodyData.setStyleProperties(
                    this.textBodyData,
                    omit(update, ['bullet']),
                    paragraph.startIndex,
                    paragraph.endIndex
                );
            } else {
                this.textBodyData = TextBodyData.setParagraphStyleProperties(
                    this.textBodyData,
                    {
                        level,
                        bullet: bulletUpdate,
                        indent: newIndent,
                        padding: {
                            left: newLeftPadding
                        }
                    },
                    paragraph.index,
                    paragraph.index
                );
            }
        });
    }

    getItemsForTextSelection(textSelection) {
        if (textSelection) {
            return this.getParagraphsWithIndex()
                .filter(paragraph => textSelection.start <= paragraph.endIndex + 1 &&
                    paragraph.startIndex <= textSelection.end);
        }

        return this.getParagraphsWithIndex();
    }

    getItemsForTextSelectionAndLevel(textSelection, level) {
        return this.getItemsForTextSelection(textSelection)
            .filter(paragraph => {
                const paragraphStyle = this.getParagraphStyle(paragraph.style, true) ||
                    this.getDefaultParagraphStyle();
                return paragraphStyle.level === level;
            });
    }

    convertParagraphsToListType(newBulletType, update = {}) {
        if (update.textSelection) {
            const paragraphs = this.getItemsForTextSelection(update.textSelection);
            paragraphs.forEach(paragraph => this.convertParagraphToListType(paragraph.index, newBulletType, update));
        } else {
            for (let i = 0, len = this.getParagraphsCount(); i < len; i++) {
                this.convertParagraphToListType(i, newBulletType, update);
            }
        }
    }

    convertToListType(update) {
        const {
            cells
        } = update;

        if (cells && cells.ids) {
            cells.ids.forEach(cellId => {
                this.cells[cellId].convertToListType(
                    update.listUpdate.type,
                    update
                );
            });
            return;
        }

        if (this.convertParagraphsToListType) {
            this.convertParagraphsToListType(update.listUpdate.type, update);
        }
        if (this.convertParagraphsToListType) {
            this.convertParagraphsToListType(update.listUpdate.type, update);
        }
    }

    convertParagraphToListType(index, newBulletType, bulletUpdate) {
        const paragraph = this.getParagraph(index);
        const paragraphStyle = this.getParagraphStyle(paragraph.style, true);
        const oldBulletStyle = get(paragraphStyle, 'bullet.type', 'none');
        const oldStyleWasList = oldBulletStyle.includes('ordered');
        const oldItemLevel = get(paragraphStyle, 'level', 1);
        switch (newBulletType) {
            case 'none': {
                let update = {
                    level: 1,
                    bullet: {
                        type: 'none'
                    },
                    indent: 0,
                    padding: {
                        ...paragraphStyle.padding,
                        left: 0
                    }
                };
                if (oldStyleWasList) {
                    update = set(update, 'padding.left', 0);
                }
                this.setParagraphStylePropertiesAt(index, update);
                break;
            }
            case 'ordered':
            case 'unordered': {
                const update = {
                    level: oldStyleWasList ? oldItemLevel : 1,
                    bullet: {
                        type: newBulletType === 'ordered' ? `ordered/${get(bulletUpdate, 'listUpdate.itemType', 'numeric')}` : 'unordered'
                    },
                    indent: get(bulletUpdate, `listUpdate.level${oldStyleWasList ? oldItemLevel : 1}.indent`, 0),
                    padding: {
                        left: get(bulletUpdate, `listUpdate.level${oldStyleWasList ? oldItemLevel : 1}.spacing-left`, 0)
                    }
                };
                this.setParagraphStylePropertiesAt(index, update);
                break;
            }
            default:
                break;
        }
    }
};
