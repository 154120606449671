export const CHANGE_ACCENT_COLOR_INPUT = 'CHANGE_ACCENT_COLOR_INPUT';
export const CHANGE_ACTIVE_COLOR_PALETTE_VIEW = 'CHANGE_ACTIVE_COLOR_PALETTE_VIEW';
export const CHANGE_ACTIVE_COLOR_SCHEME = 'CHANGE_ACTIVE_COLOR_SCHEME';
export const CHANGE_ACTIVE_LOGO_SELECTION_VIEW = 'CHANGE_ACTIVE_LOGO_SELECTION_VIEW';
export const CHANGE_ACTIVE_PAGE_THUMBNAIL = 'CHANGE_ACTIVE_PAGE_THUMBNAIL';
export const CHANGE_PRIMARY_COLOR_INPUT = 'CHANGE_PRIMARY_COLOR_INPUT';
export const CHANGE_SECONDARY_COLOR_INPUT = 'CHANGE_SECONDARY_COLOR_INPUT';
export const CHANGE_LOGO_SOURCE = 'CHANGE_LOGO_SOURCE';
export const FETCH_PREVIOUSLY_USED_LOGO_SOURCES = 'FETCH_PREVIOUSLY_USED_LOGO_SOURCES';
export const FETCH_THEMES = 'FETCH_THEMES';
export const INIT_COLOR_INPUTS = 'INIT_COLOR_INPUTS';
export const INIT_SELECTED_COLOR_PALETTE = 'INIT_SELECTED_COLOR_PALETTE';
export const INIT_SELECTED_THEME = 'INIT_SELECTED_THEME';
export const RESET_COLOR_INPUTS = 'RESET_COLOR_INPUTS';
export const RESET_SECONDARY_COLOR_INPUT = 'RESET_SECONDARY_COLOR_INPUT';
export const RESET_ACCENT_COLOR_INPUT = 'RESET_ACCENT_COLOR_INPUT';
export const SELECT_COLOR_PALETTE = 'SELECT_COLOR_PALETTE';
export const SELECT_THEME = 'SELECT_THEME';
export const SET_PREVIOUSLY_USED_LOGO_SOURCES = 'SET_PREVIOUSLY_USED_LOGO_SOURCES';
export const SET_THEMES = 'SET_THEMES';
export const SAVING_COLOR_PALETTE = 'SAVING_COLOR_PALETTE';
