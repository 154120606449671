import { List, Map, Iterable } from 'immutable';
import { isNil } from 'lodash';

import {
    addOpacityToRGBA,
    extractRGBAComponent,
    rgbaDivisionOfAlphaBy255IfNeeded
} from '../../utilities/stringUtility';

import { findColorById } from '../../utilities/ColorPalette';

const updateValueOfDescriptorWithPalette = (descriptor = Map(), colorPalette = List()) => {
    if (descriptor.get('type') === 'coded') {
        const { alpha } = extractRGBAComponent(descriptor.get('value'));
        const colorOnPalette = findColorById(
            colorPalette,
            descriptor.get('reference')
        );
        if (colorOnPalette) {
            const {
                red,
                green,
                blue,
                aplha: rgbaAlpha = 1
            } = extractRGBAComponent(colorOnPalette);

            return descriptor.set(
                'value',
                `rgba(${red}, ${green}, ${blue}, ${!isNil(alpha) ? alpha : rgbaAlpha})`
            );
        }
    }
    return descriptor;
};

const getValueFromDescriptor = (descriptor = Map(), colorPalette = List()) => {
    if (descriptor.get('type') === 'coded' && colorPalette) {
        return updateValueOfDescriptorWithPalette(
            descriptor,
            colorPalette
        ).get('value');
    }
    return descriptor.get('value');
};

const getDescriptorFromValue = (value, colorPalette = []) => {
    if (colorPalette.length) {
        const { red, green, blue } = extractRGBAComponent(value);
        const colorReference = colorPalette.find(colorToCompare => {
            const componentsToCompare = extractRGBAComponent(colorToCompare.color);
            return (
                red === componentsToCompare.red &&
                green === componentsToCompare.green &&
                blue === componentsToCompare.blue
            );
        });
        if (colorReference) {
            return {
                type: 'coded',
                reference: colorReference.id,
                value: colorReference.color
            };
        }
    }
    return {
        type: 'custom',
        value
    };
};

const updateValueOfDescriptor = (descriptor = Map(), colorPalette = List()) => updateValueOfDescriptorWithPalette(
    descriptor,
    colorPalette
);

const normalizeAlpha = (descriptor = {}) => ({
    ...descriptor,
    value: rgbaDivisionOfAlphaBy255IfNeeded(descriptor.value || 0)
});

const forceOpacity = (descriptor, opacity) => descriptor.update(
    'value',
    value => addOpacityToRGBA(value, opacity)
);

const extractRBGAFromDescriptor = (descriptor = Map(), colorPalette = List()) => {
    const value = getValueFromDescriptor(descriptor, colorPalette);
    return extractRGBAComponent(value);
};

const isColorValueDescriptorValid = descriptor => {
    let isValidCoded;
    let isValidCustom;
    if (Iterable.isIterable(descriptor)) {
        isValidCoded = descriptor.get('type') === 'coded' && !!descriptor.get('reference');
        isValidCustom = descriptor.get('type') === 'custom' && !!descriptor.get('value');
    } else {
        const {
            type,
            reference,
            value
        } = descriptor || {};

        isValidCoded = type === 'coded' && !!reference;
        isValidCustom = type === 'custom' && !!value;
    }
    return isValidCoded || isValidCustom;
};

const updateColorPresets = (descriptor, { presets = [], scheme = 'monochromatic' } = {}) => {
    if (isColorValueDescriptorValid(descriptor) && descriptor.get('preset')) {
        const preset = presets.find(({ id }) => id === descriptor.get('preset'));
        if (preset) {
            const reference = preset[`${scheme}SchemeColor`];
            if (reference) {
                return descriptor.set(
                    'reference',
                    reference
                );
            }
        }
    }
    return descriptor;
};

export {
    updateValueOfDescriptorWithPalette,
    getValueFromDescriptor,
    getDescriptorFromValue,
    updateValueOfDescriptor,
    normalizeAlpha,
    forceOpacity,
    extractRBGAFromDescriptor,
    isColorValueDescriptorValid,
    updateColorPresets
};
