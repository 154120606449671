import React from 'react';
import PropTypes from 'prop-types';

const HasForm = ({ children, hasForm, cssCustomClass }) => {
    const cssClass = cssCustomClass ? `Modal Modal--${cssCustomClass}` : 'Modal';

    return (
        hasForm ? (
            <form className={`${cssClass} Modal--hasForm`}>
                {children}
            </form>
        ) : (
            <div className={cssClass}>
                {children}
            </div>
        )
    );
};

class ModalContent extends React.Component {
    constructor() {
        super();
        this.backdropCloseModal = this.backdropCloseModal.bind(this);
    }

    backdropCloseModal() {
        if (this.props.backdropCantClose || !this.props.closeModal) {
            return;
        }

        this.props.closeModal();
    }

    render() {
        const {
            cssCustomClass,
            children,
            title,
            closeModal,
            action,
            actionIcon,
            actionLabel,
            hasForm
        } = this.props;

        return (
            <HasForm hasForm={hasForm} cssCustomClass={cssCustomClass}>
                <div
                    className="Modal__backdrop"
                    onClick={this.backdropCloseModal}
                    role="presentation"
                />
                <div
                    className="Modal__wrapper"
                    ref={modalWrapper => { this.modalWrapper = modalWrapper; }}
                >
                    <div className="Modal__header">
                        {title && (
                            <h3 className="Modal__header__title">
                                {title}
                            </h3>
                        )}
                        {closeModal && (
                            <button
                                className="Modal__header__btn Modal__header__btn--close"
                                onClick={closeModal}
                                type="button"
                            >
                                <i className="material-icons">close</i>
                            </button>
                        )}
                    </div>
                    <div className="Modal__content">
                        <div className="Modal__content__inner">
                            {children}
                        </div>
                    </div>
                    {action && (
                        <div className="Modal__actions">
                            <button
                                className="Modal__actions__btn Modal__actions__btn--cancel Modal__actions__btn--small"
                                onClick={closeModal}
                                type="button"
                            >
                                Cancel
                            </button>
                            {(actionIcon || actionLabel) && (
                                <button
                                    className="Modal__actions__btn Modal__actions__btn--main Modal__actions__btn--small"
                                    onClick={action}
                                    type={hasForm ? 'submit' : 'button'}
                                >
                                    {actionIcon && (
                                        <i className="Modal__actions__btn__icon material-icons">
                                            {actionIcon}
                                        </i>
                                    )}
                                    {actionLabel && actionLabel}
                                </button>
                            )}
                        </div>
                    )}
                </div>
            </HasForm>
        );
    }
}

HasForm.defaultProps = {
    children: null,
    hasForm: false,
    cssCustomClass: null
};

HasForm.propTypes = {
    children: PropTypes.any,
    cssCustomClass: PropTypes.string,
    hasForm: PropTypes.bool
};

ModalContent.defaultProps = {
    closeModal: null,
    title: null,
    children: null,
    cssCustomClass: null,
    action: null,
    actionIcon: null,
    actionLabel: null,
    hasForm: false,
    backdropCantClose: false
};

ModalContent.propTypes = {
    closeModal: PropTypes.func,
    title: PropTypes.string,
    children: PropTypes.any,
    cssCustomClass: PropTypes.string,
    action: PropTypes.func,
    actionIcon: PropTypes.string,
    actionLabel: PropTypes.string,
    hasForm: PropTypes.bool,
    backdropCantClose: PropTypes.bool
};

export default ModalContent;
