export const SET_PROFILE_MODAL_VISIBILITY = 'SET_PROFILE_MODAL_VISIBILITY';
export const PROFILE_BILLING_FETCH_PLANS_PENDING = 'PROFILE_BILLING_FETCH_PLANS_PENDING';
export const PROFILE_BILLING_FETCH_PLANS_DONE = 'PROFILE_BILLING_FETCH_PLANS_DONE';
export const PROFILE_BILLING_FETCH_PLANS_REJECTED = 'PROFILE_BILLING_FETCH_PLANS_REJECTED';
export const PROFILE_BILLING_FETCH_BILLING_INFO_PENDING = 'PROFILE_BILLING_FETCH_BILLING_INFO_PENDING';
export const PROFILE_BILLING_FETCH_BILLING_INFO_DONE = 'PROFILE_BILLING_FETCH_BILLING_INFO_DONE';
export const PROFILE_BILLING_FETCH_BILLING_INFO_REJECTED = 'PROFILE_BILLING_FETCH_BILLING_INFO_REJECTED';
export const PROFILE_BILLING_SUBSCRIBE_PENDING = 'PROFILE_BILLING_SUBSCRIBE_PENDING';
export const PROFILE_BILLING_SUBSCRIBE_DONE = 'PROFILE_BILLING_SUBSCRIBE_DONE';
export const PROFILE_BILLING_SUBSCRIBE_REJECTED = 'PROFILE_BILLING_SUBSCRIBE_REJECTED';
export const PROFILE_BILLING_UPDATE_SUBSCRIPTION_PENDING = 'PROFILE_BILLING_UPDATE_SUBSCRIPTION_PENDING';
export const PROFILE_BILLING_UPDATE_SUBSCRIPTION_DONE = 'PROFILE_BILLING_UPDATE_SUBSCRIPTION_DONE';
export const PROFILE_BILLING_UPDATE_SUBSCRIPTION_REJECTED = 'PROFILE_BILLING_UPDATE_SUBSCRIPTION_REJECETED';
export const PROFILE_BILLING_UNSUBSCRIBE_PENDING = 'PROFILE_BILLING_UNSUBSCRIBE_PENDING';
export const PROFILE_BILLING_UNSUBSCRIBE_DONE = 'PROFILE_BILLING_UNSUBSCRIBE_DONE';
export const PROFILE_BILLING_UNSUBSCRIBE_REJECTED = 'PROFILE_BILLING_UNSUBSCRIBE_REJECETED';
export const FETCH_INVOICES = 'FETCH_INVOICES';
export const FETCH_INVOICES_SUCCESS = 'FETCH_INVOICES_SUCCESS';
export const FETCH_INVOICES_ERROR = 'FETCH_INVOICES_ERROR';
export const FETCH_INVITES = 'FETCH_INVITES';
export const FETCH_INVITES_SUCCESS = 'FETCH_INVITES_SUCCESS';
export const CANCEL_INVITE = 'CANCEL_INVITE';
export const INVITE_SENT = 'INVITE_SENT';
export const RESEND_INVITE_START = 'RESEND_INVITE_START';
export const RESEND_INVITE_END = 'RESEND_INVITE_END';
export const INVITE_ERROR = 'INVITE_ERROR';
export const INVITE_RESET_RESEND = 'INVITE_RESET_RESEND';
export const CLEAR_INVITE_ERROR = 'CLEAR_INVITE_ERROR';
