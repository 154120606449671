const { fabric } = require('fabric');
const get = require('lodash/get');
const { generateLinePath } = require('../../utilities/PathGenerator/PathGenerator');
const geometry = require('../../utilities/geometry');
const Textshape = require('./TextShape');

const CustomLine = fabric.util.createClass(Textshape, {
    type: 'customLine',

    initialize({ stroke = {}, ...json }, opts) {
        this.callSuper('initialize', json, opts);
        this.shapeType = 'CustomLine';
        this.coords = json.coords;
        this.connectionStrategy = json.connectionStrategy;
        this.strokeSettings = stroke;
        this.hasBorders = false;
        this.hasControls = false;
        this.getHandlerPositions = this.getHandlerPositions.bind(this);
        this.updateCoords = this.updateCoords.bind(this);
        this.padding = Math.max(this.strokeSettings.width / 2, 5);
    },

    generateModificationHandlers() {
        this.handlers = [
            new fabric.GenericModificationHandler(
                this,
                () => this.getHandlerPositions().head,
                this.getHandlerLimits,
                (pos, self) => this.handleUpdate(pos, 'tail', self),
                {
                    applyRotation: false
                }
            ),
            new fabric.GenericModificationHandler(
                this,
                () => this.getHandlerPositions().tail,
                this.getHandlerLimits,
                (pos, self) => this.handleUpdate(pos, 'head', self),
                {
                    applyRotation: false
                }
            )];
    },

    getHandlerPositions() {
        const headCoords = this.coords[0];
        const tailCoords = this.coords[this.coords.length - 1];

        const absolutePosition = this.getAbsolutePosition();

        const topLeft = {
            x: absolutePosition.x - (this.width / 2),
            y: absolutePosition.y - (this.height / 2)
        };
        const flipOffset = this.getFlipOffset();
        const head = geometry.rotatePoint(
            {
                x: headCoords.x + topLeft.x + flipOffset.x,
                y: headCoords.y + topLeft.y + flipOffset.y
            },
            this.angle,
            absolutePosition
        );
        const tail = geometry.rotatePoint(
            {
                x: (tailCoords.x + topLeft.x) - flipOffset.x,
                y: (tailCoords.y + topLeft.y) - flipOffset.y
            },
            this.angle,
            absolutePosition
        );
        return { head, tail };
    },

    getHandlerLimits() {
        return {
            min: Number.MIN_VALUE,
            max: Number.MAX_VALUE
        };
    },

    handleUpdate(movedHandler, staticTip, handler) {
        if (this.staticHandler === undefined) {
            this.staticHandler = this.getHandlerPositions()[staticTip];
            handler.on('mouseup', () => {
                if (this.group) this.group.objectCaching = true;
                delete this.staticHandler;
            });
        }
        const unrotatedMovedHandler = geometry.rotatePoint(
            movedHandler,
            -this.angle,
            this.staticHandler
        );

        const newDimensions = this.getNewDimensions(unrotatedMovedHandler, this.staticHandler);
        const newAbsolutePosition = this.getNewAbsolutePosition(unrotatedMovedHandler, this.staticHandler);
        const newPosition = this.getNewPosition(unrotatedMovedHandler, this.staticHandler);

        const rotatedNewPosition = geometry.rotatePoint(
            newPosition,
            this.angle,
            this.staticHandler
        );

        this.updateCoords(staticTip, unrotatedMovedHandler, newDimensions, newAbsolutePosition);
        this.updateAttributes(newDimensions, rotatedNewPosition);
        this.updateShapeAttributes();

        if (this.group) {
            this.group.objectCaching = false;
            this.group.addWithUpdate();
        }
    },

    updateCoords(staticTip, movedHandler, newDimensions, newPosition) {
        const absoluteCoords = staticTip === 'head' ?
            this.generateCoords(this.staticHandler, movedHandler) :
            this.generateCoords(movedHandler, this.staticHandler);
        this.coords = absoluteCoords.map(coord => ({
            x: coord.x - (newPosition.x - (newDimensions.width / 2)),
            y: coord.y - (newPosition.y - (newDimensions.height / 2))
        }));
    },

    updateAttributes({ width, height }, { x, y }) {
        this.width = width;
        this.height = height;
        this.left = x;
        this.top = y;
        this.flipX = false;
        this.flipY = false;
    },

    updateShapeAttributes() {
        const shape = this.getShapeObject();

        shape.coords = this.coords;
        shape.path = this.getPath();
        shape.pathOffset.x += (this.width - shape.width) / 2;
        shape.pathOffset.y += (this.height - shape.height) / 2;
        shape.width = this.width;
        shape.height = this.height;

        this.setCoords();
        if (this.canvas) {
            this.canvas.requestRenderAll();
        }
    },

    generateCoords(head, tail) {
        const midPoint = Math.abs((head.y + tail.y) / 2);
        switch (this.connectionStrategy) {
            case 'elbow':
                return [
                    head,
                    { x: head.x, y: midPoint },
                    { x: tail.x, y: midPoint },
                    tail
                ];
            case 'straight':
            default:
                return [head, tail];
        }
    },

    updateShapeScale(scaleX, scaleY) {
        if (scaleX === 1 && scaleY === 1) { return; }
        this.coords = this.coords.map(coord => ({
            x: coord.x * scaleX,
            y: coord.y * scaleY
        }));

        const dimensions = this.getNewDimensions(this.coords[0], this.coords[this.coords.length - 1]);

        this.width = dimensions.width;
        this.height = dimensions.height;

        this.updateShapeAttributes();
    },

    getPath() {
        return generateLinePath({
            coords: this.coords,
            lineWidth: Math.max(get(this, 'strokeSettings.width'), 0.5),
            lineDash: get(this, 'strokeSettings.dash.type'),
            lineCap: get(this, 'strokeSettings.cap'),
            lineHead: get(this, 'strokeSettings.head'),
            lineTail: get(this, 'strokeSettings.tail')
        }).buildArray();
    },

    getFlipOffset() {
        const shape = this.getShapeObject();
        const xOffset = shape.flipX ? this.width : 0;
        const yOffset = shape.flipY ? this.height : 0;
        const head = this.coords[0];
        const tail = this.coords[this.coords.length - 1];

        return {
            x: head.x <= tail.x ? xOffset : -xOffset,
            y: head.y <= tail.y ? yOffset : -yOffset
        };
    },

    getNewDimensions(p1, p2) {
        return {
            width: Math.abs(p1.x - p2.x),
            height: Math.abs(p1.y - p2.y)
        };
    },

    getNewAbsolutePosition(p1, p2) {
        return {
            x: (p1.x + p2.x) / 2,
            y: (p1.y + p2.y) / 2
        };
    },

    getNewPosition(p1, p2) {
        const absolutePosition = this.getAbsolutePosition();
        const newAbsolutePosition = this.getNewAbsolutePosition(p1, p2);

        return {
            x: this.left + (newAbsolutePosition.x - absolutePosition.x),
            y: this.top + (newAbsolutePosition.y - absolutePosition.y)
        };
    },

    toObject(propertiesToInclude = []) {
        const serializedLine = this.callSuper('toObject', [...propertiesToInclude]);
        serializedLine.stroke = this.strokeSettings;
        serializedLine.coords = this.coords;
        serializedLine.connectionStrategy = this.connectionStrategy;
        serializedLine.padding = Math.max(this.strokeSettings.width / 2, 5);
        return serializedLine;
    }
});

CustomLine.fromObject = (object, callback) => {
    const obj = new CustomLine(object);
    if (callback instanceof Function) {
        obj.on('text:load', ({ target: line }) => {
            callback(line);
        })
            .on('load:error', err => {
                console.error(err);
                callback(null);
            });
    }
    return obj;
};

module.exports = CustomLine;
