const get = require('lodash/get');

module.exports = item => {
    const defaultRunStyle = get(item, 'textBody.DEFAULT_RUNSTYLE', {});
    const runStyles = get(item, 'textBody.runStyles', []);
    const firstRun = get(item, 'textBody.runs', []).find(({ startIndex }) => startIndex === 0);
    return (firstRun && runStyles[firstRun.style]) ?
        runStyles[firstRun.style] :
        defaultRunStyle;
};
