import { LOCATION_CHANGE } from 'connected-react-router';

let prevPathName = '';
export const addPrevPathNameToLocationChange = () => next => action => {
    if (action.type === LOCATION_CHANGE) {
        const enhancedAction = {
            ...action,
            payload: {
                ...action.payload,
                prevPathName
            }
        };
        prevPathName = action.payload.location.pathname;
        return next(enhancedAction);
    }
    return next(action);
};
