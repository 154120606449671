const getDashTemplateFromName = name => ({
    dash: [4, 3],
    dash_dot: [4, 3, 1, 3],
    dot: [1, 3],
    lg_dash: [8, 3],
    lg_dash_dot: [8, 3, 1, 3],
    lg_dash_dot_dot: [8, 3, 1, 3, 1, 3],
    sys_dash: [3, 1],
    sys_dash_dot: [3, 1, 1, 1],
    sys_dash_dot_dot: [3, 1, 1, 1, 1, 1],
    sys_dot: [1, 1],
    solid: []
}[name]);

const dashArrayGenerator = (name, strokeWidth, customArray = [], cap) => {
    if (!name || !strokeWidth) return [Infinity];// Return solid line instead of crashing

    if (name === 'custom') {
        return customArray.reduce(
            (dashArray, { length, space }) => [
                ...dashArray,
                length * strokeWidth,
                space * strokeWidth
            ],
            []
        );
    }

    return getDashTemplateFromName(name).map((width, idx) => {
        /* When lineCap is round or square we need to remove strokeWidth because the tip extrudes
        from half the strokeWidth of the actual line segment on both sides. We use modulo to skip
        spaces */
        if (['round', 'square'].includes(cap)) {
            return idx % 2 ? (width * strokeWidth) + strokeWidth :
                ((width * strokeWidth) - strokeWidth);
        }

        return width * strokeWidth;
    });
};

module.exports = {
    getDashTemplateFromName,
    dashArrayGenerator,
    getDefaultPlaceholderStroke() {
        return {
            strokeWidth: 1,
            strokeDashArray: dashArrayGenerator('dot', 1),
            stroke: 'rgba(162, 162, 162, 1)',
            isDefaultPlaceholderStrokeUsed: true
        };
    }
};
