const { rotatePoint } = require('./geometry');

const getRegularPolygonPoints = (sideCount, width, height) => {
    const size = Math.min(width, height) / 2;
    const scaleX = width / Math.min(width, height);
    const scaleY = height / Math.min(width, height);
    const sweep = (Math.PI * 2) / sideCount;
    const oddOffset = sideCount % 2 ? (Math.PI / 2) : 0;
    return [...Array(sideCount)].map((_, index) => {
        // Rounding because sub pixel accuracy is not needed and make tests easiers
        const point = {
            x: Math.cos((index * sweep) - oddOffset),
            y: Math.sin((index * sweep) - oddOffset)
        };
        return point;
    }).map(point => ({
        x: parseFloat((point.x * size * scaleX).toFixed(2)),
        y: parseFloat((point.y * size * scaleY).toFixed(2))
    }));
};

const getTriangleBottomVertexes = (width, height) => {
    const rightVertex = {
        x: width / 2,
        y: height / 2
    };
    const leftVertex = {
        x: -width / 2,
        y: height / 2
    };
    return [rightVertex, leftVertex];
};

const getChevronPoints = (width, height, tipWidth, isFull) => {
    const halfWidth = width / 2;
    const halfHeight = height / 2;

    const topWingStart = {
        x: -halfWidth,
        y: -halfHeight
    };
    const topWingEnd = {
        x: halfWidth - tipWidth,
        y: -halfHeight
    };
    const bottomWingStart = {
        x: -halfWidth,
        y: halfHeight
    };
    const bottomWingEnd = {
        x: halfWidth - tipWidth,
        y: halfHeight
    };
    const rightTip = {
        x: halfWidth,
        y: 0
    };
    const leftTip = {
        x: isFull ?
            -halfWidth :
            -halfWidth + tipWidth,
        y: 0
    };
    return [
        topWingStart,
        topWingEnd,
        rightTip,
        bottomWingEnd,
        bottomWingStart,
        leftTip
    ];
};

const getTrapezoidPoints = (width, height, baseLength) => {
    const halfBaseLength = baseLength / 2;
    const halfWidth = width / 2;
    const halfHeight = height / 2;

    const topLeftVertex = {
        x: -halfBaseLength,
        y: -halfHeight
    };
    const topRightVertex = {
        x: halfBaseLength,
        y: -halfHeight
    };
    const bottomRightVertex = {
        x: halfWidth,
        y: halfHeight
    };
    const bottomLeftVertex = {
        x: -halfWidth,
        y: halfHeight
    };
    return [
        topLeftVertex,
        topRightVertex,
        bottomRightVertex,
        bottomLeftVertex
    ];
};

const getArrowLeftHead = (halfWidth, halfHeight, headWidth) => {
    const leftHeadTip = {
        x: -halfWidth,
        y: 0
    };
    const leftHeadBottomPoint = {
        x: headWidth - halfWidth,
        y: halfHeight
    };
    const leftHeadTopPoint = {
        x: headWidth - halfWidth,
        y: -halfHeight
    };
    return [
        leftHeadTip,
        leftHeadBottomPoint,
        leftHeadTopPoint
    ];
};

const getArrowRightHead = (halfWidth, halfHeight, headWidth) => {
    const rightHeadTip = {
        x: halfWidth,
        y: 0
    };
    const rightHeadBottomPoint = {
        x: halfWidth - headWidth,
        y: halfHeight
    };
    const rightHeadTopPoint = {
        x: halfWidth - headWidth,
        y: -halfHeight
    };
    return [
        rightHeadTip,
        rightHeadBottomPoint,
        rightHeadTopPoint
    ];
};

const getArrowTailPoints = (halfWidth, headWidth, tailHeight, isDouble) => {
    const halfTailHeight = tailHeight / 2;

    const tailRightTopCorner = {
        x: halfWidth - headWidth,
        y: -halfTailHeight
    };
    const tailRightBottomCorner = {
        x: halfWidth - headWidth,
        y: halfTailHeight
    };
    const tailLeftTopCorner = {
        x: isDouble ?
            headWidth - halfWidth :
            -halfWidth,
        y: -halfTailHeight
    };
    const tailLeftBottomCorner = {
        x: isDouble ?
            headWidth - halfWidth :
            -halfWidth,
        y: halfTailHeight
    };
    return [
        tailRightTopCorner,
        tailRightBottomCorner,
        tailLeftTopCorner,
        tailLeftBottomCorner
    ];
};

const getArrowPoints = (width, height, tailHeight, headWidth, isDouble) => {
    const halfWidth = width / 2;
    const halfHeight = height / 2;

    const [
        rightHeadTip,
        rightHeadBottomPoint,
        rightHeadTopPoint
    ] = getArrowRightHead(halfWidth, halfHeight, headWidth);

    const [
        leftHeadTip,
        leftHeadBottomPoint,
        leftHeadTopPoint
    ] = getArrowLeftHead(halfWidth, halfHeight, headWidth);

    const [
        tailRightTopCorner,
        tailRightBottomCorner,
        tailLeftTopCorner,
        tailLeftBottomCorner
    ] = getArrowTailPoints(halfWidth, headWidth, tailHeight, isDouble);

    return isDouble ?
        [
            rightHeadTip,
            rightHeadBottomPoint,
            tailRightBottomCorner,
            tailLeftBottomCorner,
            leftHeadBottomPoint,
            leftHeadTip,
            leftHeadTopPoint,
            tailLeftTopCorner,
            tailRightTopCorner,
            rightHeadTopPoint
        ] :
        [
            rightHeadTip,
            rightHeadBottomPoint,
            tailRightBottomCorner,
            tailLeftBottomCorner,
            tailLeftTopCorner,
            tailRightTopCorner,
            rightHeadTopPoint
        ];
};

const getCalloutTailVertexes = (width, height, tailLength, tailWidth, tailAngle) => {
    const halfWidth = width / 2;
    const halfHeight = height / 2;

    const point = rotatePoint(
        { x: halfWidth + tailLength, y: halfHeight },
        tailAngle,
        { x: halfWidth, y: halfHeight }
    );

    const leftBase = rotatePoint(
        { x: halfWidth + (tailWidth / 2), y: halfHeight },
        tailAngle + 90,
        { x: halfWidth, y: halfHeight }
    );

    const rightBase = rotatePoint(
        { x: halfWidth + (tailWidth / 2), y: halfHeight },
        tailAngle - 90,
        { x: halfWidth, y: halfHeight }
    );
    return {
        point,
        left: leftBase,
        right: rightBase
    };
};

module.exports = {
    getRegularPolygonPoints,
    getTriangleBottomVertexes,
    getChevronPoints,
    getTrapezoidPoints,
    getArrowPoints,
    getCalloutTailVertexes
};
