import { DEFAULT_TABLE_SETTINGS } from '../../../../Table/config/defaultTableAttributes.config';
import defaultPropertyAdapters from '../PropertyAdapters/default';
import getBorders from '../PropertyAdapters/table/borders';
import getCells from '../PropertyAdapters/table/cells';
import getColumnCount from '../PropertyAdapters/table/columnCount';
import getRowCount from '../PropertyAdapters/table/rowCount';

const defaultTableAttributes = {
    originX: 'center',
    originY: 'center',
    type: 'table',
    zoomX: 1,
    zoomY: 1
};

const fromShapeToFabricJSON = (shape, canvasState) => ({
    ...defaultTableAttributes,
    id: defaultPropertyAdapters.id(shape),
    name: defaultPropertyAdapters.name(shape),
    hyperlink: defaultPropertyAdapters.hyperlink(shape),
    left: defaultPropertyAdapters.x(shape),
    top: defaultPropertyAdapters.y(shape),
    width: defaultPropertyAdapters.width(shape),
    height: defaultPropertyAdapters.height(shape),
    inLayout: defaultPropertyAdapters.inLayout(shape),
    isHidden: defaultPropertyAdapters.isHidden(shape),
    isImported: defaultPropertyAdapters.isImported(shape),
    isLocked: defaultPropertyAdapters.isLocked(shape),
    type: defaultPropertyAdapters.type(shape),
    placeholderType: defaultPropertyAdapters.placeholderType(shape) || '',
    angle: defaultPropertyAdapters.rotation(shape),
    dynamicProperties: defaultPropertyAdapters.dynamicProperties(shape),
    flipX: defaultPropertyAdapters.flipX(shape),
    flipY: defaultPropertyAdapters.flipY(shape),
    scaleX: defaultPropertyAdapters.scaleX(shape),
    scaleY: defaultPropertyAdapters.scaleY(shape),
    skewX: defaultPropertyAdapters.skewX(shape),
    skewY: defaultPropertyAdapters.skewY(shape),
    lockUniScaling: defaultPropertyAdapters.lockAspectRatio(shape),
    style: defaultPropertyAdapters.style(shape) || '',
    mainAxis: shape.get('mainAxis'),
    hasBandedColumns: shape.get('hasBandedColumns') || DEFAULT_TABLE_SETTINGS.hasBandedColumns,
    hasBandedRows: shape.get('hasBandedRows') || DEFAULT_TABLE_SETTINGS.hasBandedRows,
    hasHeaderColumn: shape.get('hasHeaderColumn') || DEFAULT_TABLE_SETTINGS.hasHeaderColumn,
    hasHeaderRow: shape.get('hasHeaderRow') || DEFAULT_TABLE_SETTINGS.hasHeaderRow,
    hasTotalColumn: shape.get('hasTotalColumn') || DEFAULT_TABLE_SETTINGS.hasTotalColumn,
    hasTotalRow: shape.get('hasTotalRow') || DEFAULT_TABLE_SETTINGS.hasTotalRow,
    rows: getRowCount(shape),
    columns: getColumnCount(shape),
    columnWidths: shape.get('columnWidths') ? shape.get('columnWidths').toJS() : [],
    rowCount: getRowCount(shape),
    columnCount: getColumnCount(shape),
    rowHeights: shape.get('rowHeights') ? shape.get('rowHeights').toJS() : [],
    definedRowHeights: shape.get('definedRowHeights') ? shape.get('definedRowHeights').toJS() : [],
    borders: getBorders(shape, canvasState),
    cells: getCells(shape, canvasState)
});

export default fromShapeToFabricJSON;
