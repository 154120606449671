const Bounds = require('./Bounds');

class TrapezoidBounds extends Bounds {
    get dimensions() {
        return {
            width: this._attributes.baseLength,
            height: this.shapeHeight
        };
    }
}

module.exports = TrapezoidBounds;
