import { List, fromJS, Map } from 'immutable';
import sortByStartIndexes from '../Utils/sortByStartIndexes';
import TEXTBODY_PROPERTIES from '../Config/textBodyRecordProperties';
import { getTextLength, getText } from '../TextBodyProperties/TextBodyProperties';

const sortParagraphs = textBodyData => {
    const sortedParagraphs = sortByStartIndexes(textBodyData.get(TEXTBODY_PROPERTIES.PARAGRAPHS));
    if (sortedParagraphs.size === 0) {
        return List();
    }

    const lastParagraph = sortedParagraphs.last();
    const lastParagraphEndIndex = getTextLength(textBodyData) - 1;
    const updatedLast = lastParagraph.set('endIndex', lastParagraphEndIndex)
        .set('text', getText(
            textBodyData,
            lastParagraph.get('startIndex'),
            lastParagraphEndIndex
        ));
    return sortedParagraphs
        .slice(0, -1)
        .map((paragraph, index) => {
            // as indexes are inclusives and we're computing our endIndex based
            // on the next paragraphs start we need to offset it by 2
            const PARAGRAPHS_OFFSET = 2;
            const endIndex = Math.max(
                sortedParagraphs.get(index + 1).get('startIndex') - PARAGRAPHS_OFFSET,
                -1
            );
            return paragraph.set('endIndex', endIndex)
                .set('text', getText(textBodyData, paragraph.get('startIndex'), endIndex));
        }).push(updatedLast);
};

const getParagraphs = (textBodyData, start = 0, endIndex) => sortParagraphs(
    textBodyData
).slice(
    start,
    endIndex === undefined ? undefined : endIndex + 1
);

const getParagraph = (textBodyData, index) => getParagraphs(textBodyData, index, index).first();

const getParagraphsCount = textBodyData => textBodyData.get(TEXTBODY_PROPERTIES.PARAGRAPHS).size;

const getParagraphsWithIndex = (textBodyData, startIndex = 0, endIndex) => getParagraphs(
    textBodyData,
    startIndex,
    endIndex
).map((paragraph, index) => paragraph.set('index', index + startIndex));

const getParagraphIndexAtChar = (textBodyData, charIndex = 0) => {
    const numOfEmptyParagraphs = getParagraphs(textBodyData).reduce((total, paragraph) => {
        if (paragraph.get('startIndex') <= charIndex) {
            if (paragraph.get('endIndex') === -1) {
                return total + 1;
            }
        }
        return total;
    }, 0);
    const index = getParagraphs(textBodyData)
        .findIndex(paragraph => (
            (paragraph.get('endIndex') + numOfEmptyParagraphs + 1) >= charIndex &&
            (
                (paragraph.get('startIndex') + numOfEmptyParagraphs) <= charIndex ||
                paragraph.get('startIndex') - 1 === paragraph.get('endIndex')
            )
        ));

    if (index === -1) {
        throw new RangeError('Text selection should be in text range.');
    }
    return index;
};

const setParagraphs = (textBodyData, paragraphs) => textBodyData
    .set(TEXTBODY_PROPERTIES.PARAGRAPHS, List(fromJS(paragraphs)));

const addParagraph = (textBodyData, text = '') => {
    let originalText = getText(textBodyData);
    if (originalText.length > 0) {
        originalText += '\n';
    }

    return textBodyData
        .set(TEXTBODY_PROPERTIES.TEXT, `${originalText}${text}`)
        .update(TEXTBODY_PROPERTIES.PARAGRAPHS, paragraphs => paragraphs.push(Map({
            startIndex: originalText.length
        })));
};

const updateParagraphs = (
    textBodyData,
    startIndex,
    originalTextLength,
    newTextLength
) => {
    const offset = newTextLength - originalTextLength;
    const text = getText(textBodyData);
    return textBodyData.update(
        TEXTBODY_PROPERTIES.PARAGRAPHS,
        originalParagraphs => originalParagraphs
            .map(paragraph => (paragraph
                .set(
                    'startIndex',
                    paragraph.get('startIndex') > startIndex ?
                        paragraph.get('startIndex') + offset :
                        paragraph.get('startIndex')
                )
            ))
            .filter((paragraph, index, offsettedParagraph) => (
                paragraph.get('startIndex') <= newTextLength
            ) && (
                paragraph.get('startIndex') === 0 ||
                    text[paragraph.get('startIndex') - 1] === '\n'
            ) && (
                offsettedParagraph
                    .findIndex(
                        p => p.get('startIndex') === paragraph.get('startIndex')
                    ) === index
            ))
    );
};

export {
    getParagraphs,
    getParagraph,
    setParagraphs,
    addParagraph,
    getParagraphsCount,
    sortParagraphs,
    getParagraphsWithIndex,
    getParagraphIndexAtChar,
    updateParagraphs
};
