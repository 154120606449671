const Bounds = require('./Bounds');

class RectangleBounds extends Bounds {
    get dimensions() {
        const angle = Math.atan2(this.shapeHeight, this.shapeWidth);
        const fullDiagonal = Math.sqrt((this.shapeWidth ** 2) + (this.shapeHeight ** 2));
        const roundedDifference = 2 *
                (Math.sqrt(2 * (this._attributes.rx ** 2)) - this._attributes.rx);
        const roundedDiagonal = fullDiagonal - roundedDifference;
        return {
            width: Math.cos(angle) * roundedDiagonal,
            height: Math.sin(angle) * roundedDiagonal
        };
    }
}

module.exports = RectangleBounds;
