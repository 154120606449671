import { List } from 'immutable';
import { getRgba, isValid } from '../../../../CanvasState/utilities/color';
import { findIdByColor } from '../../../../CanvasState/utilities/ColorPalette';

const adaptFabricColorToColorValueDescriptor = (
    colorValue,
    colorPalette = List()
) => {
    if (isValid(colorValue)) {
        const reference = findIdByColor(colorPalette, colorValue);
        if (reference !== undefined) {
            return {
                reference,
                type: 'coded',
                value: getRgba(colorValue)
            };
        }
        return {
            type: 'custom',
            value: getRgba(colorValue)
        };
    }
    throw new Error(`The color value ${colorValue} is not a valid color string`);
};

export default adaptFabricColorToColorValueDescriptor;
