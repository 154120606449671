const { fabric } = require('fabric');
const Textshape = require('./TextShape');
const { getChevronPoints } = require('../../utilities/ShapeGenerators');
const { flipDimension } = require('../utilities/FlipDimensions');
const TextBoundsCalculator = require('../utilities/TextBoundsCalculator');
const { SHAPE_RADIUS } = require('./ModificationHandlers/config/defaultModificationHandlerAttributes.config');

const Chevron = fabric.util.createClass(Textshape, {
    type: 'chevron',

    initialize(json, opts) {
        this.callSuper('initialize', json, opts);
        this.shapeType = 'Chevron';
        this.getHandlerPosition = this.getHandlerPosition.bind(this);
        this.getHandlerLimits = this.getHandlerLimits.bind(this);
        this.updateModifierAttribute = this.updateModifierAttribute.bind(this);
        this.onResize = this.onResize.bind(this);
        this.on('scaling', this.onResize);
    },

    updateShapeScale(scaleX, scaleY) {
        const shapeObject = this.getShapeObject();
        shapeObject.set({
            tipWidth: shapeObject.tipWidth * scaleX
        });
        this.callSuper('updateShapeScale', scaleX, scaleY, true);
    },

    prepareShapeForRendering() {
        this.callSuper('prepareShapeForRendering');
        this.unscalePath();
        if (
            this.width < this.getMinimumDimensions().width ||
            this.height < this.getMinimumDimensions().height
        ) {
            this.generateScaledPath();
        }
    },

    onResize() {
        const chevron = this.getShapeObject();
        const activeScaleX = chevron.scaleX * this.scaleX;
        const activeScaleY = chevron.scaleY * this.scaleY;
        const scaledPoints = getChevronPoints(
            this.getScaledDimensions().width - (chevron.strokeWidth * activeScaleX),
            this.getScaledDimensions().height - (chevron.strokeWidth * activeScaleY),
            chevron.tipWidth,
            chevron.isFull
        );
        this.setNewPathFromPoints(scaledPoints);
        this.adjustPathScale(
            this.width * this.scaleX,
            this.height * this.scaleY
        );
        this.updateModificationHandlers();
    },

    generateModificationHandlers() {
        this.handlers = [
            new fabric.GenericModificationHandler(
                this,
                this.getHandlerPosition,
                this.getHandlerLimits,
                this.updateModifierAttribute,
                { lockVertical: true }
            )
        ];
    },

    getHandlerPosition() {
        const relativePosition = this.getRelativeHandlerPosition();
        const chevron = this.getShapeObject();
        const absolutePosition = this.getAbsolutePosition();
        return {
            x: absolutePosition.x + flipDimension(chevron.flipX, relativePosition.x),
            y: absolutePosition.y + flipDimension(chevron.flipY, relativePosition.y + SHAPE_RADIUS)
        };
    },

    getRelativeHandlerPosition() {
        const chevron = this.getShapeObject();
        const activeScaleX = chevron.scaleX * this.scaleX;
        const activeScaleY = chevron.scaleY * this.scaleY;
        const scaledWidth = (this.width * this.scaleX) - (chevron.strokeWidth * activeScaleX);
        const scaledHeight = (this.height * this.scaleY) - (chevron.strokeWidth * activeScaleY);
        const scaledTipWidth = chevron.tipWidth * this.getPathScale().x;
        return {
            x: (scaledWidth / 2) - scaledTipWidth,
            y: -scaledHeight / 2
        };
    },

    getHandlerLimits() {
        const chevron = this.getShapeObject();
        const halfScaledWidth = chevron.getScaledWidth() / 2;
        const halfStrokeWidth = (chevron.strokeWidth / 2) * chevron.scaleX;
        const absolutePosition = this.getAbsolutePosition();
        return {
            min: (absolutePosition.x - halfScaledWidth) + halfStrokeWidth,
            max: (absolutePosition.x + halfScaledWidth) - halfStrokeWidth
        };
    },

    updateModifierAttribute({ x }) {
        const chevron = this.getShapeObject();
        const newTipLength = this.getNewTipLength(x);
        chevron.tipWidth = newTipLength;
        const points = this.getNewPoints(newTipLength);
        this.setNewPathFromPoints(points);
    },

    getNewTipLength(x) {
        const chevron = this.getShapeObject();
        const scaledStrokeWidth = chevron.strokeWidth * chevron.scaleX;
        const shape = this.getShapeObject();
        const absolutePosition = this.getAbsolutePosition();
        return ((this.width - scaledStrokeWidth) / 2) + flipDimension(shape.flipX, absolutePosition.x - x);
    },

    getNewPoints(newTipLength) {
        const chevron = this.getShapeObject();
        return getChevronPoints(
            this.width - (chevron.strokeWidth * chevron.scaleX),
            this.height - (chevron.strokeWidth * chevron.scaleY),
            newTipLength,
            chevron.isFull
        );
    },

    getMinimumDimensions() {
        const chevron = this.getShapeObject();
        const activeScaleX = this.scaleX * chevron.scaleX;
        const activeScaleY = this.scaleY * chevron.scaleY;
        return {
            width: chevron.tipWidth + (chevron.strokeWidth * activeScaleX),
            height: chevron.strokeWidth * activeScaleY
        };
    },

    generateScaledPath() {
        const chevron = this.getShapeObject();
        const points = getChevronPoints(
            this.getScaledDimensions().width,
            this.getScaledDimensions().height,
            chevron.tipWidth,
            chevron.isFull
        );
        this.setNewPathFromPoints(points);
        this.adjustPathScale(this.width, this.height);
    },

    getTextBounds() {
        const shape = this.getShapeObject();
        return new TextBoundsCalculator.Chevron(
            this.width,
            this.height,
            {
                tipWidth: shape.tipWidth,
                isFull: shape.isFull,
                flipX: shape.flipX
            }
        );
    }
});

Chevron.fromObject = (object, callback) => {
    const chevron = new Chevron(object);
    chevron.on('text:load', () => {
        callback(chevron);
    })
        .on('load:error', err => {
            console.error(err);
            callback(null);
        });
};

module.exports = Chevron;
