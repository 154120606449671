const { fabric } = require('fabric');
const {
    get, forEach
} = require('lodash');
const SelectionBox = require('../utilities/Selection/SelectionBox');
const { applySelectionOverlay, removeSelectionOverlay } = require('../utilities/Selection/SelectionOverlay');
const { getRgbaObject, isValid } = require('../../utilities/color');
const Placeholder = require('./Mixins/Placeholder');
const ImagePlaceholder = require('./Mixins/ImagePlaceholder');
const TablePlaceholder = require('./Mixins/ImagePlaceholder');
const TableRendering = require('./Mixins/TableRendering');
const tableGridLogic = require('../../utilities/table/tableGridLogic');
const ResizeCursorZone = require('./CursorZones/ResizeCursorZone');

// eslint-disable-next-line max-len
const Table = fabric.util.createClass(fabric.Group, TableRendering, Placeholder, ImagePlaceholder, TablePlaceholder, {
    type: 'table',
    activeSelectionBox: undefined,
    isLastNavigationFromKeyboard: false,
    currentSelectionOfCells: [],
    selectedCells: new Set(),
    isSelectionOverlayLocked: false,

    initialize(json, opts = {
        rotation: 0,
        originX: 'center',
        originY: 'center'
    }) {
        const options = {
            ...opts,
            name: json.name,
            id: json.id,
            inLayout: json.inLayout,
            lockRotation: true,
            subTargetCheck: true,
            lockMovementX: true,
            lockMovementY: true,
            selectable: !json.isLocked,
            isLocked: json.isLocked,
            isHidden: json.isHidden,
            visible: !json.isHidden,
            isImported: json.isImported,
            style: json.style,
            placeholderType: json.placeholderType,
            placeholderSequence: json.placeholderSequence,
            hasBandedColumns: json.hasBandedColumns,
            hasBandedRows: json.hasBandedRows,
            hasHeaderColumn: json.hasHeaderColumn,
            hasHeaderRow: json.hasHeaderRow,
            hasTotalColumn: json.hasTotalColumn,
            hasTotalRow: json.hasTotalRow,
            mainAxis: json.mainAxis,
            definedRowHeights: json.definedRowHeights || new Array(json.rows).fill(0)
        };

        this.callSuper('initialize', [], options);

        this.setControlVisible('mtr', false);

        this.enterCellEditing = this.enterCellEditing.bind(this);
        this.columns = json.columns;
        this.rows = json.rows;
        this.rowHeights = json.rowHeights;
        this.columnWidths = json.columnWidths;
        const cellsPromises = (json.cells || []).map(cell => new Promise(resolve => {
            fabric.Cell.fromObject({
                ...cell,
                tableName: this.name,
                table: this
            }, resolve);
        }).then(cellIns => this.add(cellIns)));
        this.rowCount = this.rows;
        this.columnCount = this.columns;
        this.hoverCursor = 'default';

        this.onContentTextEditingExited = this.onContentTextEditingExited.bind(this);
        this.initializeEvents();

        Promise.all(cellsPromises)
            .then(() => {
                this.cells = {};
                this.borderSegments = {};
                this.cells = this.getCells();
                this.rowHeights = this.getTextRenderRowHeights();
                this.height = this.rowHeights.reduce((totalHeight, rowHeight) => totalHeight + rowHeight, 0);
                forEach((json.borders || []), borderJson => {
                    const border = new fabric.Border({ ...borderJson, table: this });
                    this.borderSegments[border.id] = border;
                });
                this.cellGrid = tableGridLogic.generateCellGrid(this);
                this.horizontalBorders = tableGridLogic.generateHorizontalBorderGrid(this);
                this.verticalBorders = tableGridLogic.generateVerticalBorderGrid(this);
                this.renderBorders();
                this.resizeFromRowAndColumn();
                this.addWithUpdate();
                this.left = json.left;
                this.top = json.top;
                this.fire('table:load', { target: this });
            })
            .catch(err => {
                this.fire('table:load', { err });
            });
    },

    getCells() {
        return this.getObjects()
            .filter(child => child.type === 'cell')
            .reduce(
                (cells, cell) => ({
                    ...cells,
                    [cell.id]: cell
                }),
                {}
            );
    },

    initializeEvents() {
        this.onModified = this.onModified.bind(this);
        this.onMouseUp = this.onMouseUp.bind(this);
        this.selectCells = this.selectCells.bind(this);
        this.clearSelection = this.clearSelection.bind(this);
        this.onMouseDown = this.onMouseDown.bind(this);
        this.onAdded = this.onAdded.bind(this);
        this.onRemoved = this.onRemoved.bind(this);
        this.on('modified', this.onModified);
        this.on('deselected', e => {
            const target = e.e instanceof MouseEvent ?
                this.canvas.findTarget(e.e) :
                undefined;
            if (target !== undefined) {
                if (!target.isType('cursorZone') || !target.table || target.table.id !== this.id) {
                    this.clearSelection(true);
                }
            }
        });
        this.on('mousedown', this.onMouseDown);
        this.on('added', this.onAdded);
        this.on('removed', this.onRemoved);
    },

    onAdded() {
        if (
            this.canvas &&
            !this.group &&
            !this.addingCusorZones &&
            !this.canvas.isAddingShapes
        ) {
            this.renderDecorations(this.canvas);
        }
    },

    onRemoved() {
        if (this.canvas) {
            this.removeCursorZones(this.canvas);
        }
    },

    onMouseUp(e) {
        const mouseEvent = e.e ? e.e : e;
        const clickedCell = this.getCellAtEventPosition(e);
        const cellIsTargetAndNoCMDClick = this.targetMouseDownCellId &&
            this.targetMouseDownCellId === clickedCell.id &&
            !mouseEvent.ctrlKey && !mouseEvent.metaKey;
        if (cellIsTargetAndNoCMDClick && this.getEditingCellContent() === undefined) {
            this.canvas.removeSelectionOverlay(clickedCell);
            this.enterCellEditingWithCell(e, clickedCell);
        } else if (!this.isEditingCell() || (this.getEditingCell() && clickedCell.id !== this.getEditingCell().id)) {
            const target = this.canvas.findTarget(e);
            if (this.isEditingCell() && target) {
                const cell = this.getEditingCell();
                cell.exitEditing();
            }
            document.removeEventListener('mouseup', this.onMouseUp);
            document.removeEventListener('mousemove', this.selectCells);
            const selectedCells = this.getSelectedCells();
            this.fireSelectedCells(selectedCells);
        }
        this.targetMouseDownCellId = '';
    },

    onMouseDown(e) {
        const mouseEvent = e.e ? e.e : e;
        if (this.isLocked || e.target.shapeType === 'Group') {
            return;
        }
        this.canvas.removeSelectionOverlay();
        // This is for KeyNotelike selection
        const currentCellSelection = this.getSelectedCells();
        const clickedCell = this.getCellAtEventPosition(e);
        if (currentCellSelection.some(cell => cell.id === clickedCell.id)) {
            this.targetMouseDownCellId = clickedCell.id;
        }
        // we click on the current selection
        if (currentCellSelection && currentCellSelection.length === 1) {
            if (clickedCell && (clickedCell.id === currentCellSelection[0].id)) {
                document.addEventListener('mousemove', this.selectCells);
                document.addEventListener('mouseup', this.onMouseUp);
                return;
            }
        }
        if (this.__corner === 0) {
            if (!mouseEvent.shiftKey && !mouseEvent.ctrlKey && !mouseEvent.metaKey) {
                this.clearSelection(false);
            }
            const selectedObjects = this.canvas.getSelectedObjects();
            if (
                mouseEvent.shiftKey &&
                (
                    (selectedObjects.length === 1 && selectedObjects[0].id !== this.id) ||
                    selectedObjects.length > 1
                )
            ) {
                return;
            }
            this.selectCells(
                mouseEvent,
                !!mouseEvent.shiftKey,
                (!!mouseEvent.ctrlKey || !!mouseEvent.metaKey)
            );
            document.addEventListener('mousemove', this.selectCells);
            document.addEventListener('mouseup', this.onMouseUp);
        }
    },

    fireSelectedCells(selectedCells) {
        if (this.canvas) {
            this.canvas.fire('table:cells:selected', {
                table: this.id,
                cells: selectedCells.map(cell => cell.id),
                e: { target: this, subTargets: selectedCells }
            });
        }
    },

    toObject(propertiesToExtract) {
        const serializedTable = this.callSuper('toObject', [
            'id',
            'name',
            'columns',
            'rows',
            'scaleX',
            'scaleY',
            'style',
            'rowHeights',
            'columnWidths',
            'hasBandedColumns',
            'hasBandedRows',
            'hasHeaderColumn',
            'hasHeaderRow',
            'hasTotalColumn',
            'hasTotalRow',
            'mainAxis',
            'definedRowHeights'
        ].concat(propertiesToExtract));
        serializedTable.cells = serializedTable.objects.filter(object => object.type === 'cell');
        serializedTable.borders = Object.keys(this.borderSegments)
            .map(borderSegment => this.borderSegments[borderSegment].toObject('table'));
        delete serializedTable.objects;
        return serializedTable;
    },

    toJSON() {
        return fabric.util.object.extend(this.callSuper('toJSON'));
    },

    getShapeObject: function getShapeObject() {
        return this;
    },

    isShapeBorderVisible() {
        const shape = this.getShapeObject();
        const isStrokeWidthVisible = shape.strokeWidth > 0;
        const isValidColor = isValid(shape.stroke);
        if (!isValidColor) {
            return false;
        }
        const { a } = getRgbaObject(shape.stroke);
        const isStrokeAlphaVisible = a > 0;
        return isStrokeWidthVisible && isStrokeAlphaVisible;
    },

    getHorizontalBorderPositionExtremes() {
        const filteredPositions = this.borders
            .filter(border => border.isVertical())
            .map(border => border.left);
        return {
            min: Math.min(...filteredPositions),
            max: Math.max(...filteredPositions)
        };
    },

    getVerticalBorderPositionExtremes() {
        const filteredPositions = this.borders
            .filter(border => border.isHorizontal())
            .map(border => border.top);
        return {
            min: Math.min(...filteredPositions),
            max: Math.max(...filteredPositions)
        };
    },

    getCellForPoint(point) {
        this
            .getObjects()
            .reverse()
            .find(object => {
                if (object.get('type' === 'cell')) {
                    return false;
                }
                return object.containsPoint(point);
            });
    },

    enterCellEditing(e) {
        const subTargetedCell = (e.subTargets || []).find(subTarget => subTarget.type === 'cell');
        const localPoint = this.getLocalPointer(e.e);
        const centeredLocalPoint = {
            x: localPoint.x - (this.width / 2),
            y: localPoint.y - (this.height / 2)
        };
        const cell = subTargetedCell || this.getCellForPoint(centeredLocalPoint);
        cell.enterCellEditing(centeredLocalPoint, e);
        const editingContent = this.getEditingCellContent();
        editingContent.getTextObject().on('editing:exited', this.onContentTextEditingExited);
    },

    onContentTextEditingExited() {
        const editingContent = this.getEditingCellContent();
        if (editingContent) {
            editingContent.getTextObject().off('editing:exited', this.onContentTextEditingExited);
        }
        this.renderSelectionOverlay(this.getSelectedCells());
    },

    enterCellEditingWithCell(e, cell) {
        if (e === undefined) {
            cell.enterCellEditing();
        } else {
            const localPoint = this.getLocalPointer(e);
            const centeredLocalPoint = {
                x: localPoint.x - (this.width / 2),
                y: localPoint.y - (this.height / 2)
            };
            cell.enterCellEditing(centeredLocalPoint, e);
        }
        const editingContent = this.getEditingCellContent();
        editingContent.getTextObject().on('editing:exited', this.onContentTextEditingExited);
    },

    generateCursorZones() {
        const zones = [];
        const borders = this.getObjects().filter(obj => obj.type === 'border');
        forEach(borders, border => {
            if (!tableGridLogic.isBorderOnTableEdge(border.id)) {
                zones.push(new fabric.ResizeCursorZone(border, this));
            }
        });
        zones.push(
            new fabric.SelectionCursorZone('top', this),
            new fabric.SelectionCursorZone('bottom', this),
            new fabric.SelectionCursorZone('left', this),
            new fabric.SelectionCursorZone('right', this),
            new fabric.MoveCursorZone('top', this),
            new fabric.MoveCursorZone('bottom', this),
            new fabric.MoveCursorZone('left', this),
            new fabric.MoveCursorZone('right', this)
        );
        return zones;
    },

    updateCursorZonesPositionsAndSizes() {
        const borders = this.getObjects().filter(obj => obj.type === 'border');

        this._cursorZones.forEach(cursorZone => {
            if (cursorZone instanceof ResizeCursorZone) {
                const currentBorder = borders.find(border => border.id === cursorZone.border.id);
                cursorZone.updateBorder(currentBorder);
            }

            cursorZone.updatePositionAndSize();
        });
    },

    getOppositeBordersFromAdjacentCells(selectedCells, border) {
        const adjacentCells = border.getAdjacentCells();
        const oppositeSide = {
            bottom: 'top',
            top: 'bottom',
            left: 'right',
            right: 'left'
        };
        const oppositeBorders = [];
        adjacentCells.forEach(cell => {
            if (!selectedCells.includes(cell)) {
                const cellBorders = cell.borders.map(borderId => this.getBorderById(borderId));
                const sideToFind = oppositeSide[border.side];
                oppositeBorders.push(
                    cellBorders.find(cellBorder => cellBorder.side === sideToFind)
                );
            }
        });
        return oppositeBorders;
    },

    getAdjacentBorders(border) {
        const filteredBorders = this.borders
            .filter(tableBorder => (
                tableBorder.id !== border.id &&
                tableBorder.isOnSameAxisAs(border) &&
                border.isAdjacentTo(tableBorder) &&
                border.isAtSamePositionAs(tableBorder)
            ));
        return filteredBorders;
    },

    getEditedCell(posX, posY) {
        const cell = this.getObjects().filter(obj => (
            (
                posX > (this.left + obj.left) - (obj.width / 2) &&
                posX < (this.left + obj.left) + (obj.width / 2) &&
                posY > (this.top + obj.top) - (obj.height / 2) &&
                posY < (this.top + obj.top) + (obj.height / 2)
            )
        ));
        return cell;
    },

    getCellById(id) {
        return this.cells[id];
    },

    getCellsOnAxis(isHorizontal, position) {
        if (isHorizontal) {
            return this.cellGrid[this.getRowForY(position.y)].map(cellId => this.cells[cellId]);
        }
        return this.getCellGridColumn(this.getColumnForX(position.x))
            .map(cellId => this.cells[cellId]);
    },

    getBorderById(id) {
        return this.borderSegments[id];
    },

    getTextObject() {
        return this.getObjects()[1].getObjects()[1];
    },

    getEditingCellContent() {
        const cell = this.getEditingCell();
        return (cell && cell.getEditingContent());
    },

    getEditingCell() {
        return this.getObjects().find(object => object.editingText === true);
    },

    getLeftEdge() {
        return this.left - (this.width / 2);
    },

    getRightEdge() {
        return this.left + (this.width / 2);
    },

    getTopEdge() {
        return this.top - (this.height / 2);
    },

    getBottomEdge() {
        return this.top + (this.height / 2);
    },

    getLeftBorderMaxWidth() {
        return Math.max(...this.verticalBorders[0].map(borderId => this.getStrokeWidthFromBorderId(borderId)));
    },

    getTopBorderMaxWidth() {
        return Math.max(...this.horizontalBorders[0].map(borderId => this.getStrokeWidthFromBorderId(borderId)));
    },

    getStrokeWidthFromBorderId(segmentId) {
        if (this.borderSegments?.[segmentId]?.strokeFill?.type === 'none') {
            return 0;
        }

        return this.borderSegments?.[segmentId]?.strokeWidth || 0;
    },

    onModified() {
        if (this.canvas) {
            this.canvas.exitContextualSelection();
            this.renderDecorations(this.canvas);
        }
    },

    renderDecorations(canvas) {
        this.renderCursorZones(canvas);
    },

    getCanvasIndex(canvas) {
        if (!canvas) {
            return -1;
        }
        if (this.group && this.group.type !== 'activeSelection') {
            return canvas.getShapeIndex(this.group.name);
        }
        return canvas.getShapeIndex(this.name);
    },

    renderCursorZones(canvas) {
        this.removeCursorZones(canvas);
        if (canvas.getShapeIndex instanceof Function) {
            if (this.selectable && !this.isLocked && !this.isHidden) {
                this._cursorZones = this.generateCursorZones();
                this.addingCusorZones = true;
                canvas.addShapesAtIndex(this._cursorZones, this.getCanvasIndex(canvas) + 1);
                this.addingCusorZones = false;
            }
        }
    },

    removeCursorZones(canvas) {
        if (this._cursorZones && canvas) {
            this._cursorZones.forEach(cursorZone => canvas.remove(cursorZone));
        }
    },

    resizeFromRowAndColumn() {
        Object.values(this.cells).forEach(cell => cell.adjustCoordsToTable());
        Object.values(this.borderSegments).forEach(border => border.adjustCoordsToTable());
    },

    updateScale(scaleX, scaleY) {
        this.scaleX = 1;
        this.scaleY = 1;

        if (this.canScaleTable(scaleY)) {
            this.lockMovement(false, false);
            this.width *= scaleX;
            this.height *= scaleY;
            if (scaleY !== 1) {
                this.definedRowHeights = tableGridLogic.combineDefinedAndRenderRowHeights(this)
                    .map(rowHeight => rowHeight * scaleY);
            }
            this.rowHeights = this.rowHeights.map(rowHeight => rowHeight * scaleY);
            this.columnWidths = this.columnWidths.map(columnWidth => columnWidth * scaleX);
            this.resizeFromRowAndColumn();
            this.renderBorders();
            this.setInitialPositionsToCurrentPosition();
        }
    },

    canScaleTable(scaleY) {
        return scaleY === 1 ? true :
            Object.values(this.cells).every(cell => {
                const getCellsOnRow = this.getCellsOnRow(cell.row, Array.from(Array(this.columns).keys()));
                const textMaxCellHeight = Math.max(...getCellsOnRow.map(_cell => _cell.getTextHeight()));
                if (textMaxCellHeight < this.rowHeights[cell.row] * scaleY) return true;
                return false;
            });
    },

    getAbsoluteTop() {
        if (this.group) {
            const groupCenter = this.group.getCenterPoint();
            return this.top + groupCenter.y;
        }
        return this.top;
    },

    getAbsoluteLeft() {
        if (this.group) {
            const groupCenter = this.group.getCenterPoint();
            return this.left + groupCenter.x;
        }
        return this.left;
    },

    getEventRelativeLocation(e) {
        let relativeLocalPoint = {
            x: 0,
            y: 0
        };
        const mouseEvent = e.e ? e.e : e;
        if (this.canvas) {
            const localPoint = {
                x: Math.max(Math.min(this.getLocalPointer(mouseEvent).x, this.width - 1), 1),
                y: Math.max(Math.min(this.getLocalPointer(mouseEvent).y, this.height - 1), 1)
            };
            relativeLocalPoint = {
                x: localPoint.x - (this.width / 2),
                y: localPoint.y - (this.height / 2)
            };
        }
        return relativeLocalPoint;
    },
    getColumnStartX(column) {
        return tableGridLogic.getColumnStartX(column, this);
    },
    getColumnEndX(column) {
        return tableGridLogic.getColumnEndX(column, this);
    },

    getRowStartY(row) {
        return tableGridLogic.getRowStartY(row, this);
    },

    getRowEndY(row) {
        return tableGridLogic.getRowEndY(row, this);
    },

    getColumnForX(x) {
        return tableGridLogic.getColumnForX(x, this);
    },

    getRowForY(y) {
        return tableGridLogic.getRowForY(y, this);
    },

    getCellsBetweenRows(firstIndex, lastIndex) {
        return tableGridLogic.getCellsBetweenRows(firstIndex, lastIndex, this);
    },

    getCellsBetweenColumns(firstIndex, lastIndex) {
        return tableGridLogic.getCellsBetweenColumns(firstIndex, lastIndex, this);
    },

    getCellsIntersection(arr1, arr2) {
        return tableGridLogic.getIntersectCells(arr1, arr2);
    },

    getCellIdAtPosition(relativeLocalPoint) {
        const row = this.getRowForY(relativeLocalPoint.y);
        const column = this.getColumnForX(relativeLocalPoint.x);
        return this.cellGrid[row][column];
    },

    getCellAtEventPosition(e) {
        return this.cells[this.getCellIdAtPosition(this.getEventRelativeLocation(e))];
    },

    selectCells(e, appendToActive = true, toggle = false) {
        /**
         * The use of Math.min and Math.max is necessary because we do not want
         * our point to be outside of table boundaries. If the point is outside,
         * the position used will be the corresponding edge of the table.
         */
        const mouseEvent = e.e ? e.e : e;
        if (mouseEvent instanceof MouseEvent) {
            this.isLastNavigationFromKeyboard = false;
            if (mouseEvent.which === 0 && mouseEvent.type === 'mousemove') {
                return;
            }
        }
        const currentCell = this.getCellAtEventPosition(e);
        this.addCellToSelection(currentCell, appendToActive, toggle);
        const selectedCells = this.getSelectedCells();
        this.fireSelectedCells(selectedCells);
    },

    addCellToSelection(cell, appendToActive = true, toggle = false) {
        if (appendToActive) {
            this.addCellToActiveSelection(cell);
        } else if (toggle) {
            this.toggleSelection(cell);
        } else {
            this.addNewSelectionWithCell(cell);
        }
        const selectedCells = this.getSelectedCells();
        this.renderSelectionOverlay(selectedCells);
    },

    addCellToActiveSelection(cell) {
        if (!this.activeSelectionBox) {
            this.activeSelectionBox = new SelectionBox(cell, this);
        } else {
            this.activeSelectionBox.updateSelection(cell);
        }
    },

    toggleSelection(cell) {
        if (this.activeSelectionBox && this.activeSelectionBox.hasSelectionOrigin()) {
            this.addMultipleCellsToSelectedCells(this.activeSelectionBox.selectedCells);
        }
        this.toggleCellInSelection(cell);
        this.activeSelectionBox = undefined;
    },

    addNewSelectionWithCell(cell) {
        if (this.activeSelectionBox && this.activeSelectionBox.hasSelectionOrigin()) {
            this.addMultipleCellsToSelectedCells(this.activeSelectionBox.selectedCells);
        }
        this.activeSelectionBox = new SelectionBox(cell, this);
    },

    renderSelectionOverlay(cells) {
        if (!this.isSelectionOverlayLocked) {
            const ids = cells.map(cell => cell.id);
            forEach(this.cells, cell => removeSelectionOverlay(cell));
            ids.map(cellId => applySelectionOverlay(this.cells[cellId]));
            if (this.canvas) {
                this.canvas.renderAll();
            } else {
                this.dirty = true;
            }
        }
    },

    getCellGridColumn(column) {
        return tableGridLogic.getCellGridColumn(column, this);
    },

    getCellGridRow(row) {
        return tableGridLogic.getCellGridRow(row, this);
    },

    getIntersectionCrossFromCell(cell) {
        const cellIds = {};
        for (let { row } = cell; row < cell.row + cell.rowSpan; row++) {
            tableGridLogic.getCellGridRow().forEach(cellId => cellIds.add(cellId));
        }
        for (let { column } = cell; column < cell.column + cell.columnSpan; column++) {
            tableGridLogic.getCellGridColumn().forEach(cellId => cellIds.add(cellId));
        }
        return cellIds.map(cellId => this.cells[cellId]);
    },

    findSelection() {
        return this.cells.filter(cell => this.getIntersectionCrossFromCell(cell));
    },

    getSelectedCells() {
        const fullSelectedSet = new Set();
        if (this.activeSelectionBox && this.activeSelectionBox.hasSelectionOrigin()) {
            this.activeSelectionBox.selectedCells.forEach(cell => fullSelectedSet.add(cell));
        }
        this.selectedCells.forEach(cell => fullSelectedSet.add(cell));
        return Array.from(fullSelectedSet);
    },

    getSelectedCellsCount() {
        if (this.activeSelectionBox && this.activeSelectionBox.selectedCells) {
            return this.selectedCells.size +
                this.activeSelectionBox.selectedCells.length;
        }
        return this.selectedCells.size;
    },

    isEditingCell() {
        return !Object.values(this.cells).every(cell => cell.editingText !== true);
    },

    changeEditTargetCell({ html, margins, direction = 'next' }) {
        this.canvas.lockCanvasStateUpdate();
        this.lockSelectionOverlay();
        const previousEditedCell = this.getEditingCell();
        let endPreviousEditionPromise = Promise.resolve();
        if (previousEditedCell) {
            const previousEditedContent = previousEditedCell.getEditingContent();
            previousEditedCell.exitEditing();
            if (previousEditedContent) {
                this.canvas.keepContextualVariable = true;
                endPreviousEditionPromise = previousEditedContent.updateText({ html, margins });
            }
        }
        endPreviousEditionPromise.then(() => {
            forEach(this.cells, cell => {
                cell.editing = false;
            });

            const nextCell = this.getAdjacentCell(previousEditedCell, direction);
            if (nextCell) {
                this.selectCell(nextCell);
                this.renderSelectionOverlay(this.getSelectedCells());
            }
        });
        this.unlockSelectionOverlay();
        this.canvas.unlockSelectionOverlay();
    },

    changeSelectionTargetCell({ direction = 'next', shiftPressed = false }) {
        this.canvas.lockCanvasStateUpdate();
        this.lockSelectionOverlay();
        this.isLastNavigationFromKeyboard = true;
        if (shiftPressed) {
            const nextCell = this.getCellAdjacentToSelectionBox(direction);
            if (nextCell) {
                this.addCellToActiveSelection(nextCell);
            }
        }
        const nextCell = this.getCellAdjacentToSelection(direction);
        if (nextCell) {
            this.selectCell(nextCell);
        }
        const selectedCells = this.getSelectedCells();
        this.renderSelectionOverlay(selectedCells);
        this.fireSelectedCells(selectedCells);
        this.unlockSelectionOverlay();
        this.canvas.unlockCanvasStateUpdate();
    },

    selectCell(cell) {
        this.clearSelection(false);
        this.addNewSelectionWithCell(cell);
        this.fireSelectedCells([cell]);
    },

    editSelectedCell() {
        const cells = this.getSelectedCells();
        if (cells && cells.length === 1) {
            const cell = cells[0];
            removeSelectionOverlay(cell);
            this.enterCellEditingWithCell(undefined, cell);
        }
    },

    getCellAdjacentToSelectionBox(direction) {
        if (this.activeSelectionBox && this.activeSelectionBox.hasSelectionOrigin()) {
            if (['next', 'right', 'bottom'].includes(direction)) {
                return this.getAdjacentCell(
                    this.activeSelectionBox.bottomRightCellInSelection,
                    direction
                );
            }
            return this.getAdjacentCell(
                this.activeSelectionBox.topLeftCellInSelection,
                direction
            );
        } if (this.selectionBoxes && this.selectionBoxes.length >= 1) {
            if (['next', 'right', 'bottom'].includes(direction)) {
                return this.getAdjacentCell(
                    this.selectionBoxes[this.selectionBoxes.length - 1].bottomRightCellInSelection,
                    direction
                );
            }
            return this.getAdjacentCell(
                this.selectionBoxes[this.selectionBoxes.length - 1].topLeftCellInSelection,
                direction
            );
        }
        return this.getCellAdjacentToSelection(direction);
    },

    getCellAdjacentToSelection(direction) {
        if (this.activeSelectionBox && this.activeSelectionBox.hasSelectionOrigin()) {
            return this.getAdjacentCell(this.activeSelectionBox.selectionOrigin, direction);
        } if (this.getSelectedCells() && this.getSelectedCellsCount() > 0) {
            return this.getSelectedCells()[0];
        }
        return undefined;
    },

    getAdjacentCell(cell, direction) {
        if (cell) {
            const previousCenter = cell.getCenterPoint();
            switch (direction) {
                case 'right':
                case 'next':
                    return this.getAdjacentCellForSide(cell, 'right') ||
                        this.getAdjacentCellForSide(
                            this.getLeftCellOfHorizontalAxis(previousCenter),
                            'bottom'
                        );
                case 'left':
                case 'previous':
                    return this.getAdjacentCellForSide(cell, 'left') ||
                        this.getAdjacentCellForSide(
                            this.getRightCellOfHorizontalAxis(previousCenter),
                            'top'
                        );
                case 'bottom':
                case 'top':
                    return this.getAdjacentCellForSide(cell, direction);
                default:
                    throw new Error(`Can't find adjacent cell for direction ${direction}`);
            }
        } else {
            return undefined;
        }
    },

    getLeftCellOfHorizontalAxis(pointer) {
        const [cell] = this.getCellsOnAxis(true, pointer)
            .sort((a, b) => a.left - b.left);
        return cell;
    },

    getRightCellOfHorizontalAxis(pointer) {
        const [cell] = this.getCellsOnAxis(true, pointer)
            .sort((a, b) => b.left - a.left);
        return cell;
    },

    getAdjacentCellForSide(cell, side) {
        let otherCellsOnAxis = [];
        const previousCenter = cell.getCenterPoint();
        if (['left', 'right'].includes(side)) {
            otherCellsOnAxis = this.getCellsOnAxis(true, previousCenter);
        } else if (['bottom', 'top'].includes(side)) {
            otherCellsOnAxis = this.getCellsOnAxis(false, previousCenter);
        } else {
            throw new Error(`Can't find adjacent cell for side ${side}`);
        }
        const [adjacentCell] = otherCellsOnAxis
            .filter(otherCell => (
                otherCell.id !== cell.id &&
                cell.checkIfOtherCellIsOnSide(otherCell, side)
            ))
            .sort((previousCell, currentCell) => {
                switch (side) {
                    case 'bottom':
                        return previousCell.top - currentCell.top;
                    case 'left':
                        return currentCell.left - previousCell.left;
                    case 'right':
                        return previousCell.left - currentCell.left;
                    case 'top':
                    default:
                        return currentCell.top - previousCell.top;
                }
            });

        return adjacentCell;
    },

    getCellBorders(cellId) {
        const cell = this.cellGrid[cellId];
        return [
            ...cell.getLeftBorderSegments(),
            ...cell.getRightBorderSegments(),
            ...cell.getTopBorderSegments(),
            ...cell.getBottomBorderSegments()
        ];
    },

    getRowCursorZones(row) {
        return this._cursorZones
            .filter(cursorZone => (
                cursorZone.border &&
                cursorZone.border.isHorizontal() &&
                cursorZone.row === row
            ));
    },

    getCellsOnRow(row, columnsToConsider) {
        return this.cellGrid[row]
            .map(cellId => this.cells[cellId])
            .filter(cell => columnsToConsider.includes(cell.column));
    },

    clearSelection(rerender = true) {
        this.getSelectedCells()
            .forEach(cell => cell.exitEditing());
        this.selectionBoxes = [];
        this.activeSelectionBox = undefined;
        this.selectedCells.clear();
        if (rerender) {
            this.renderSelectionOverlay([]);
        }
        this.fireSelectedCells([]);
    },

    addCellToSelectedCells(cell) {
        this.selectedCells.add(cell);
    },

    addMultipleCellsToSelectedCells(cells) {
        cells.forEach(cell => this.addCellToSelectedCells(cell));
    },

    removeCellFromSelectedCells(cell) {
        this.selectedCells.delete(cell);
    },

    removeMultipleCellsFromSelectedCells(cells) {
        cells.forEach(cell => this.removeCellFromSelectedCells(cell));
    },

    toggleCellInSelection(cell) {
        if (!this.selectedCells.delete(cell)) {
            this.selectedCells.add(cell);
        }
        return this.selectedCells.has(cell);
    },

    onCellTextEditingExit({
        mouseDownEvent, html, margins, isTextEmpty
    }) {
        this.getEditingCellContent()
            .updateText({
                html,
                margins,
                isTextEmpty
            })
            .then(() => {
                if (get(mouseDownEvent, 'type') === 'mousedown') {
                    if (this.containsPoint(this.canvas.getPointer(mouseDownEvent))) {
                        this.onMouseDown(mouseDownEvent);
                    } else {
                        this.canvas.__onMouseDown({
                            e: mouseDownEvent,
                            target: this.canvas.findTarget(mouseDownEvent)
                        });
                    }
                }
                this.renderSelectionOverlay(this.getSelectedCells());
            });
    },

    getRenderRowHeights() {
        return tableGridLogic.combineDefinedAndRenderRowHeights(this);
    },

    getTextRenderRowHeights() {
        return Object.values(this.getCells())
            .sort((currentCell, nextCell) => currentCell.getLastRowIndex() - nextCell.getLastRowIndex())
            .reduce(
                (renderRowHeights, cell) => {
                    let lastSpanHeight = cell.getTextHeight();
                    for (let i = 0, len = cell.rowSpan - 1; i < len; i++) {
                        lastSpanHeight -= renderRowHeights[cell.row + i];
                    }
                    renderRowHeights[cell.getLastRowIndex()] = Math.max(
                        lastSpanHeight,
                        renderRowHeights[cell.getLastRowIndex()]
                    );
                    if (cell.editingText) {
                        const cellHeights = Object.values(this.getCells())
                            .filter(_cell => _cell.row === cell.row && _cell.rowSpan === 1)
                            .map(_cell => _cell.getTextHeight());
                        const textMaxCellHeight = Math.max(...cellHeights);
                        if (this.definedRowHeights.reduce(
                            (totalDefinedRowHeight, definedRowHeight) => totalDefinedRowHeight + definedRowHeight
                        ) !== 0) {
                            renderRowHeights[cell.getLastRowIndex()] =
                                this.definedRowHeights[cell.row] > textMaxCellHeight ?
                                    this.definedRowHeights[cell.row] : textMaxCellHeight;
                        } else renderRowHeights[cell.getLastRowIndex()] = textMaxCellHeight;
                    }
                    return renderRowHeights;
                },
                this.rowHeights
            );
    },

    updateEditingCellHeight({ height }, exiting = false) {
        const targetCell = this.getEditingCell();
        if (!targetCell) {
            return;
        }
        let otherCells = [];
        let currentHeight = 0;
        for (let i = 0, len = targetCell.rowSpan; i < len; i++) {
            currentHeight += this.getTextRenderRowHeights()[targetCell.row + i];
            otherCells = [
                ...otherCells,
                ...this.getCellsOnRow(
                    targetCell.row + i,
                    Array.from(Array(this.columns).keys())
                        .filter(columnIndex => columnIndex !== targetCell.column)
                )
                    .filter(cell => cell.getLastRowIndex() === targetCell.row + i)
            ];
        }
        if (Math.abs(currentHeight - height).toFixed(0) > 1 || exiting === true) {
            this.resizeTableOnCellSizeChange(height, currentHeight);
        }
    },

    resizeTableOnCellSizeChange(previousHeight, currentHeight) {
        const heightOffset = Math.round(currentHeight) - Math.round(previousHeight);
        this.top += heightOffset / 2;
        this.height += heightOffset;
        this.resizeFromRowAndColumn();
        this.renderBorders();
        this.setInitialPositionsToCurrentPosition();
        this.updateCursorZonesPositionsAndSizes();
    },

    renderTextSelection(textSelection) {
        const selectedCells = this.getSelectedCells();
        if (selectedCells.length === 1) {
            selectedCells[0].editingText = true;
            const [content] = selectedCells[0].getContents();
            if (content) {
                content.renderTextSelection(textSelection);
            }
        }
    },

    lockSelectionOverlay() {
        this.isSelectionOverlayLocked = true;
    },

    unlockSelectionOverlay() {
        this.isSelectionOverlayLocked = false;

        this.renderSelectionOverlay(this.getSelectedCells());
    }
});

Table.fromObject = (object, callback) => {
    const obj = new Table(object);
    obj.on('table:load', ({ target, err }) => {
        if (err) {
            console.error(err);
            callback(undefined, err);
        } else {
            callback(target);
        }
    });
};

module.exports = Table;
