import { Map } from 'immutable';
import { isNil } from 'lodash';
import TEXTBODY_PROPERTIES from '../Config/textBodyRecordProperties';
import { getTextLength, getText } from '../TextBodyProperties/TextBodyProperties';
import sortByStartIndexes from '../Utils/sortByStartIndexes';

const getRunsAt = (
    textBodyData,
    startIndex = 0,
    endIndex = getTextLength(textBodyData)
) => textBodyData
    .get(TEXTBODY_PROPERTIES.RUNS)
    .map((run, index) => (run.set('index', index)))
    .filter(run => run.get('endIndex') >= startIndex && run.get('startIndex') <= endIndex);

const getRun = (textBodyData, index) => textBodyData.getIn([TEXTBODY_PROPERTIES.RUNS, index]);

const getRunIndexAtPosition = (textBodyData, position) => textBodyData
    .get(TEXTBODY_PROPERTIES.RUNS)
    .findIndex(run => run.get('endIndex') >= position && run.get('startIndex') <= position);

const getRunDynamicType = run => run.get('dynamicType', 'none');

const addRuns = (textBodyData, ...runs) => runs.reduce(
    (updatedTextBody, run) => updatedTextBody
        .update(TEXTBODY_PROPERTIES.RUNS, updatedRuns => updatedRuns.push(Map.isMap(run) ? run : Map(run))),
    textBodyData
);

const getRunIndex = (textBodyData, run) => textBodyData
    .get(TEXTBODY_PROPERTIES.RUNS)
    .findIndex(currentRun => currentRun.equals(run));

const removeRuns = (textBodyData, ...runIndexes) => textBodyData
    .update(
        TEXTBODY_PROPERTIES.RUNS,
        runs => runs.filter((_, index) => !runIndexes.includes(index))
    );

const sortRuns = textBodyData => textBodyData
    .update(TEXTBODY_PROPERTIES.RUNS, runs => sortByStartIndexes(runs));

const splitRunAt = (textBodyData, runIndex, textIndex) => {
    const runAtIndex = getRun(textBodyData, runIndex);

    const firstRun = runAtIndex.set('endIndex', textIndex - 1);

    const lastRun = runAtIndex.set('startIndex', textIndex);

    return addRuns(
        removeRuns(textBodyData, runIndex),
        firstRun,
        lastRun
    );
};

const shouldMergeLoneNewLineCharWithRun = (
    previousRun,
    previousRunText
) => previousRun.get('startIndex') === previousRun.get('endIndex') &&
    previousRunText === '\n';

const mergeRunsWithNewLineChar = textBodyData => {
    const sortedRuns = sortByStartIndexes(textBodyData.get(TEXTBODY_PROPERTIES.RUNS));
    const mergedRuns = sortedRuns.slice(1).reduce((acc, currentRun) => {
        const previousRun = acc.last();
        if (shouldMergeLoneNewLineCharWithRun(previousRun, getText(textBodyData)[previousRun.get('startIndex')])) {
            return acc.set(-1, currentRun.set('startIndex', previousRun.get('endIndex')));
        }
        return acc.push(currentRun);
    }, sortedRuns.slice(0, 1));
    return textBodyData.set(TEXTBODY_PROPERTIES.RUNS, mergedRuns);
};

const splitRunsAt = (textBodyData, startIndex, endIndex) => {
    let updatedTextBody = sortRuns(textBodyData);
    let startRunIndex = getRunIndexAtPosition(updatedTextBody, startIndex);
    const endRunIndex = getRunIndexAtPosition(updatedTextBody, endIndex);
    const sortedRuns = updatedTextBody.get(TEXTBODY_PROPERTIES.RUNS);
    if (startRunIndex === -1 && endRunIndex === -1) {
        const runsCompletelyInsideRange = sortedRuns
            .filter(run => run.get('startIndex') >= startIndex &&
                run.get('endIndex') <= endIndex);

        if (!runsCompletelyInsideRange.size) {
            return addRuns(updatedTextBody, {
                startIndex,
                endIndex
            });
        }
        return addRuns(
            textBodyData,
            {
                startIndex,
                endIndex: runsCompletelyInsideRange.first().get('startIndex') - 1
            },
            {
                startIndex: runsCompletelyInsideRange.last().get('endIndex') + 1,
                endIndex
            }
        );
    }
    if (endRunIndex !== -1) {
        const endRun = getRun(updatedTextBody, endRunIndex);
        const firstRunInRange = sortedRuns.find(run => run.get('startIndex') > startIndex);
        if (startRunIndex === -1 && !isNil(firstRunInRange)) {
            updatedTextBody = addRuns(updatedTextBody, {
                startIndex,
                endIndex: firstRunInRange.get('startIndex') - 1
            });
        }
        if (endRun.get('endIndex') > endIndex) {
            updatedTextBody = splitRunAt(
                updatedTextBody,
                endRunIndex,
                endIndex + 1
            );
        }
    }

    updatedTextBody = sortRuns(updatedTextBody);

    startRunIndex = getRunIndexAtPosition(updatedTextBody, startIndex);
    const startRun = getRun(updatedTextBody, startRunIndex);

    if (endRunIndex === -1) {
        const lastRunOfRange = updatedTextBody.get(TEXTBODY_PROPERTIES.RUNS)
            .findLast(run => run?.get('endIndex') < endIndex);

        if (lastRunOfRange) {
            updatedTextBody = addRuns(updatedTextBody, {
                startIndex: lastRunOfRange.get('endIndex') + 1,
                endIndex
            });
        }
    }
    if (startRun && startRun.get('startIndex') < startIndex) {
        updatedTextBody = splitRunAt(
            updatedTextBody,
            startRunIndex,
            startIndex
        );
    }
    return updatedTextBody;
};

const updateRuns = (
    textBodyData,
    startIndex,
    endIndex,
    originalTextLength,
    newTextLength
) => {
    const offset = newTextLength - originalTextLength;
    return textBodyData.update(TEXTBODY_PROPERTIES.RUNS, runs => runs
        .filter(run => run.get('startIndex') <= startIndex || run.get('endIndex') >= endIndex)
        .map(run => {
            if (run.get('endIndex') >= startIndex && run.get('startIndex') <= endIndex) {
                return run.set('endIndex', run.get('endIndex') + offset);
            } if (run.get('startIndex') > endIndex) {
                return run.set('startIndex', run.get('startIndex') + offset)
                    .set('endIndex', run.get('endIndex') + offset);
            }
            return run;
        })
        .filter(run => run.get('startIndex') <= run.get('endIndex')));
};

export {
    getRunsAt,
    getRun,
    getRunIndexAtPosition,
    getRunDynamicType,
    addRuns,
    getRunIndex,
    removeRuns,
    sortRuns,
    splitRunAt,
    mergeRunsWithNewLineChar,
    splitRunsAt,
    updateRuns
};
