import * as types from '#Constants/ActionTypes';

const topics = (state = [], action) => {
    switch (action.type) {
        case types.SET_TOPICS_LIST:
            return action.topics;
        case types.REMOVE_TOPICS_LIST:
        case types.REMOVE_INITIAL_STATE:
            return [];
        default:
            return state;
    }
};

const browseTopics = (state = [], action) => {
    switch (action.type) {
        case types.SET_BROWSE_TOPICS_LIST:
            return action.topics;
        default:
            return state;
    }
};

const userSelectedTopics = (state = [], action) => {
    switch (action.type) {
        case types.SET_USER_SELECTED_TOPICS:
            return action.topics;
        case types.ADD_USER_SELECTED_TOPICS:
        case types.ADD_SELECTED_TOPICS: {
            const selectedTopics = state.concat(action.topics);
            const orderedSelectedTopics = state;
            const newOptionalTopic = [];
            const selectedRequiredTopic = action.topicsOrderRequired
                .filter(topic => selectedTopics.find(selectedTopic => selectedTopic.id === topic));

            action.topics.forEach(topic => {
                const requiredTopicIndex = selectedRequiredTopic.indexOf(topic.id);
                if (requiredTopicIndex >= 0) {
                    orderedSelectedTopics.splice(requiredTopicIndex, 0, topic);
                } else {
                    newOptionalTopic.push(topic);
                }
            });

            return [
                ...orderedSelectedTopics,
                ...newOptionalTopic
            ];
        }
        case types.REMOVE_SELECTED_TOPICS: {
            return state.filter(topic => !action.topics.some(actionTopic => actionTopic.id === topic.id));
        }
        case types.REMOVE_ALL_SELECTED_TOPICS:
        case types.REMOVE_INITIAL_STATE:
            return [];
        default:
            return state;
    }
};

const userUnselectedTopics = (state = [], action) => {
    switch (action.type) {
        case types.SET_USER_UNSELECTED_TOPICS:
            return action.topics;
        case types.ADD_SELECTED_TOPICS: {
            return state.filter(topic => !action.topics.some(actionTopic => actionTopic.id === topic.id));
        }
        case types.ADD_USER_UNSELECTED_TOPICS:
        case types.REMOVE_SELECTED_TOPICS: {
            return state.concat(action.topics);
        }
        case types.REMOVE_INITIAL_STATE:
            return [];
        default:
            return state;
    }
};

const isReordering = (state = false, action) => {
    switch (action.type) {
        case types.REORDER_STARTED:
            return true;
        case types.REORDER_ENDED:
            return false;
        case types.REMOVE_PAGE_STARTED:
            return true;
        case types.REMOVE_PAGE_ENDED:
            return false;
        default:
            return state;
    }
};

const selectedPage = (state = null, action) => {
    switch (action.type) {
        case types.SET_SELECTED_PAGE:
            return action.page;
        case types.UPDATE_PAGE_IN_CURRENT_DECK:
            if (state && state.id === action.previousId) {
                return action.newPage;
            }
            return state;
        case types.SET_PAGES_TO_WAIT_FOR_UPDATE:
            return null;
        default:
            return state;
    }
};

export {
    topics, browseTopics, selectedPage, userSelectedTopics, userUnselectedTopics, isReordering
};
