const StrokeFill = require('./StrokeFill.js');
const StrokeFillConstructor = require('.');
const { DEFAULT_STROKE_COLOR } = require('./config/defaultStrokeFillAttributes.config.js');

class SolidStrokeFill extends StrokeFill {
    constructor(color = DEFAULT_STROKE_COLOR) {
        super('color');
        this.color = color;
    }

    toJSON() {
        return Object.assign(super.toJSON(), {
            color: this.color
        });
    }

    static fromJSON(jsonObject) {
        return new this(jsonObject.color || DEFAULT_STROKE_COLOR);
    }
}

StrokeFillConstructor.addToConstructorList(SolidStrokeFill, 'color');

module.exports = SolidStrokeFill;
