const SelectionHighlightConfig = {
    id: 'selectionHighlight',
    stroke: 'rgba(7, 11, 22, 1)',
    strokeWidth: 2,
    originX: 'center',
    originY: 'center',
    fill: 'rgba(0, 0, 0, 0)',
    hasControls: false,
    selectable: false
};

module.exports = {
    SelectionHighlightConfig
};
