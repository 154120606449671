import { fromJS } from 'immutable';
import { PART_NAMES } from '../Table/part';

const {
    BANDED_COLUMN_EVEN,
    BANDED_COLUMN_ODD,
    BANDED_ROW_EVEN,
    BANDED_ROW_ODD,
    CONTENT_COLUMN,
    CONTENT_ROW,
    FIRST_COLUMN,
    FIRST_ROW,
    HEADER_COLUMN,
    HEADER_ROW,
    HEADER_SPANNER_COLUMN,
    HEADER_SPANNER_ROW,
    LAST_COLUMN,
    LAST_ROW,
    SUBTOTAL_COLUMN,
    SUBTOTAL_ROW,
    TOTAL_COLUMN,
    TOTAL_ROW
} = PART_NAMES;

export default fromJS({
    headerColumnStyle: {
        isReference: true,
        referenceUpdateParent: `partUpdates.${HEADER_COLUMN}`
    },
    headerRowStyle: {
        isReference: true,
        referenceUpdateParent: `partUpdates.${HEADER_ROW}`
    },
    headerSpannerColumnStyle: {
        isReference: true,
        referenceUpdateParent: `partUpdates.${HEADER_SPANNER_COLUMN}`
    },
    headerSpannerRowStyle: {
        isReference: true,
        referenceUpdateParent: `partUpdates.${HEADER_SPANNER_ROW}`
    },
    contentColumnStyle: {
        isReference: true,
        referenceUpdateParent: `partUpdates.${CONTENT_COLUMN}`
    },
    contentRowStyle: {
        isReference: true,
        referenceUpdateParent: `partUpdates.${CONTENT_ROW}`
    },
    alternateColumnOddStyle: {
        isReference: true,
        referenceUpdateParent: `partUpdates.${BANDED_COLUMN_ODD}`
    },
    alternateColumnEvenStyle: {
        isReference: true,
        referenceUpdateParent: `partUpdates.${BANDED_COLUMN_EVEN}`
    },
    alternateRowOddStyle: {
        isReference: true,
        referenceUpdateParent: `partUpdates.${BANDED_ROW_ODD}`
    },
    alternateRowEvenStyle: {
        isReference: true,
        referenceUpdateParent: `partUpdates.${BANDED_ROW_EVEN}`
    },
    totalColumnStyle: {
        isReference: true,
        referenceUpdateParent: `partUpdates.${TOTAL_COLUMN}`
    },
    totalRowStyle: {
        isReference: true,
        referenceUpdateParent: `partUpdates.${TOTAL_ROW}`
    },
    subtotalColumnStyle: {
        isReference: true,
        referenceUpdateParent: `partUpdates.${SUBTOTAL_COLUMN}`
    },
    subtotalRowStyle: {
        isReference: true,
        referenceUpdateParent: `partUpdates.${SUBTOTAL_ROW}`
    },
    firstColumnStyle: {
        isReference: true,
        referenceUpdateParent: `partUpdates.${FIRST_COLUMN}`
    },
    firstRowStyle: {
        isReference: true,
        referenceUpdateParent: `partUpdates.${FIRST_ROW}`
    },
    lastColumnStyle: {
        isReference: true,
        referenceUpdateParent: `partUpdates.${LAST_COLUMN}`
    },
    lastRowStyle: {
        isReference: true,
        referenceUpdateParent: `partUpdates.${LAST_ROW}`
    },
    tableStyleBorder: {
        isReference: true,
        referenceUpdateParent: 'assignedStrokeStyle'
    },
    headerEmphasis1Style: {
        isReference: true,
        referenceUpdateParent: 'partUpdates.headerEmphasis1'
    },
    headerEmphasis2Style: {
        isReference: true,
        referenceUpdateParent: 'partUpdates.headerEmphasis2'
    },
    contentEmphasis1Style: {
        isReference: true,
        referenceUpdateParent: 'partUpdates.contentEmphasis1'
    },
    contentEmphasis2Style: {
        isReference: true,
        referenceUpdateParent: 'partUpdates.contentEmphasis2'
    }
});
