import { fromJS, Map } from 'immutable';
import UUID from 'uuid/v4';
import TreeNode from '../../../fabric-adapter/DecksignFabricShapeType/Groups/TreeNode';
import {
    getShapePathWithLayer,
    getInsertionPathWithLayer,
    getAbsolutePositionWithLayer,
    getNewShapeName,
    updateSelection,
    changeNamesAndIds
} from '../../Helpers/helpers';

const GROUP_DEFAULTS = {
    type: 'Group',
    flipX: false,
    flipY: false,
    skewX: 0,
    skewY: 0,
    scaleX: 1,
    scaleY: 1,
    isHidden: false,
    isLocked: false
};

const getGroupCenter = shapes => {
    const groupBounds = getGroupBounds(shapes);

    return {
        x: groupBounds.x1 + ((groupBounds.x2 - groupBounds.x1) / 2),
        y: groupBounds.y1 + ((groupBounds.y2 - groupBounds.y1) / 2)
    };
};

const getGroupBounds = shapes => {
    const shapeBounds = shapes.map(shape => ({
        x1: shape.get('x') - (shape.get('width') / 2),
        y1: shape.get('y') - (shape.get('height') / 2),
        x2: shape.get('x') + (shape.get('width') / 2),
        y2: shape.get('y') + (shape.get('height') / 2)
    })).toJS();

    return {
        x1: Math.min(...shapeBounds.map(bounds => bounds.x1)),
        y1: Math.min(...shapeBounds.map(bounds => bounds.y1)),
        x2: Math.max(...shapeBounds.map(bounds => bounds.x2)),
        y2: Math.max(...shapeBounds.map(bounds => bounds.y2))
    };
};

const getGroupSize = shapes => {
    const groupBounds = getGroupBounds(shapes);
    return {
        width: groupBounds.x2 - groupBounds.x1,
        height: groupBounds.y2 - groupBounds.y1
    };
};

const updateShapePositions = (shapes, center) => shapes
    .map(shape => shape
        .set('x', shape.get('x') - center.x)
        .set('y', shape.get('y') - center.y));

const createGroup = (canvasState, shapes) => {
    const groupId = UUID().toString();
    const center = getGroupCenter(shapes);
    const size = getGroupSize(shapes);
    const updatedShapes = updateShapePositions(shapes, center);

    return new Map({
        ...GROUP_DEFAULTS,
        id: groupId,
        name: getNewShapeName(canvasState, 'Group'),
        inLayout: canvasState.get('editMode') === 'Layout',
        ...center,
        ...size,
        shapes: updatedShapes
    });
};

const group = canvasState => {
    const selection = canvasState.get('selection');
    const insertionPath = getInsertionPathWithLayer(canvasState, selection); // Path up to the last index
    const insertionIndex = insertionPath[insertionPath.length - 1];
    const rootGroup = insertionPath.length > 2 ?
        canvasState.getIn(insertionPath.slice(0, 2)) : undefined; // Topmost Group
    const parentGroup = insertionPath.length > 2 ?
        canvasState.getIn(insertionPath.slice(0, -2)) : undefined; // Bottommost Group

    const shapes = selection.map(id => {
        const shapePath = getShapePathWithLayer(canvasState, id);
        const absolutePosition = getAbsolutePositionWithLayer(canvasState, id);

        return canvasState
            .getIn(shapePath)
            .set('x', absolutePosition.x)
            .set('y', absolutePosition.y);
    });

    let groupShape = createGroup(canvasState, shapes);

    if (parentGroup) {
        const absoluteParentPosition = getAbsolutePositionWithLayer(canvasState, parentGroup.get('id'));
        groupShape = groupShape
            .set('x', groupShape.get('x') - absoluteParentPosition.x)
            .set('y', groupShape.get('y') - absoluteParentPosition.y);
    }

    let updatedCanvasState = canvasState;

    updatedCanvasState = selection
        .reduce(
            (currentCanvasState, id) => currentCanvasState
                .removeIn(getShapePathWithLayer(currentCanvasState, id)),
            updatedCanvasState
        );

    groupShape = groupShape.set('shapes', changeNamesAndIds(canvasState, groupShape.get('shapes'), true, false));

    updatedCanvasState = updatedCanvasState
        .setIn(
            insertionPath.slice(0, -1), // Target List before last index
            updatedCanvasState.getIn(
                insertionPath.slice(0, -1)
            ).splice(insertionIndex, 0, groupShape) // Add group at this index
        );

    updatedCanvasState = updateSelection(updatedCanvasState, fromJS({ selection: [groupShape.get('id')] }));

    if (rootGroup) {
        updatedCanvasState = updatedCanvasState.set('groupTree', TreeNode.generateTree(rootGroup));
    }

    return updatedCanvasState;
};

export default group;
