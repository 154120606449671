const toPairsDeep = require('./toPairsDeep');
const validateKeyExceptions = require('./validateKeyExceptions');

module.exports = (object, exceptions) => {
    validateKeyExceptions(exceptions);

    const customizer = (key, value) => (exceptions.includes(key) ?
        value :
        undefined);

    return toPairsDeep(object, customizer);
};
