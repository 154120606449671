import { combineReducers } from 'redux';
import * as types from '#Constants/ActionTypes';
import { resetOnLocationChange } from '#Utilities/reducers';
import { files } from './files';
import { currentFile } from './currentFile';
import search from './search';

const importModalOpened = (state = false, action) => {
    switch (action.type) {
        case types.TOGGLE_IMPORT_MODAL:
            return !state;
        default:
            return state;
    }
};

const importPanelOpened = (state = false, action) => {
    switch (action.type) {
        case types.TOGGLE_IMPORT_PANEL:
            return !state;
        default:
            return state;
    }
};

const view = (state = 'details', action) => {
    switch (action.type) {
        case types.SET_MANAGE_VIEW:
            return action.view;
        default:
            return state;
    }
};

const exportModal = (state = { isExporting: false }, action) => {
    switch (action.type) {
        case types.EXPORT_STARTED:
            return {
                ...state,
                isExporting: true
            };
        case types.EXPORT_FINISHED:
            return {
                ...state,
                isExporting: false
            };
        default:
            return state;
    }
};

const combinedReducer = combineReducers({
    currentFile,
    exportModal,
    files,
    importModalOpened,
    importPanelOpened,
    search,
    view
});

export default resetOnLocationChange(combinedReducer, 'manage');
