import { camelCase } from 'lodash';
import rectangleFabricToCanvasState from './ShapeAdapters/rectangle';
import ellipseFabricToCanvasState from './ShapeAdapters/ellipse';
import triangleFabricToCanvasState from './ShapeAdapters/triangle';
import trapezoidFabricToCanvasState from './ShapeAdapters/trapezoid';
import chevronFabricToCanvasState from './ShapeAdapters/chevron';
import calloutFabricToCanvasState from './ShapeAdapters/callout';
import imageFabricToCanvasState from './ShapeAdapters/image';
import pathFabricToCanvasState from './ShapeAdapters/path';
import arrowFabricToCanvasState from './ShapeAdapters/arrow';
import regularPolygonFabricToCanvasState from './ShapeAdapters/regularPolygon';
import lineFabricToCanvasState from './ShapeAdapters/line';
import tableFabricToCanvasState from './ShapeAdapters/table';
// eslint-disable-next-line import/no-cycle
import groupFabricToCanvasState from './ShapeAdapters/group';

const adaptersByTypes = {
    rectangle: rectangleFabricToCanvasState,
    ellipse: ellipseFabricToCanvasState,
    triangle: triangleFabricToCanvasState,
    trapezoid: trapezoidFabricToCanvasState,
    chevron: chevronFabricToCanvasState,
    callout: calloutFabricToCanvasState,
    textbox: rectangleFabricToCanvasState,
    customImage: imageFabricToCanvasState,
    customPath: pathFabricToCanvasState,
    arrow: arrowFabricToCanvasState,
    regularPolygon: regularPolygonFabricToCanvasState,
    customLine: lineFabricToCanvasState,
    table: tableFabricToCanvasState,
    group: groupFabricToCanvasState
};

const fromFabricToCanvasState = (fabricShape, canvasState) => adaptersByTypes[
    camelCase(fabricShape.type)
](fabricShape, canvasState);

export default fromFabricToCanvasState;
