import getHeadWidth from '../PropertyAdapters/arrow/headWidth';
import getTailHeight from '../PropertyAdapters/arrow/tailHeight';
import isDouble from '../PropertyAdapters/arrow/isDouble';
import getPath from '../PropertyAdapters/arrow/path';
import getPoints from '../PropertyAdapters/arrow/points';
import defaultShapeToFabricJSON from './default';

const fromShapeToFabricJSON = (shape, canvasState) => ({
    ...defaultShapeToFabricJSON(shape, canvasState),
    headWidth: getHeadWidth(shape),
    tailHeight: getTailHeight(shape),
    isDouble: isDouble(shape),
    path: getPath(shape),
    points: getPoints(shape)
});

export default fromShapeToFabricJSON;
