import { getCommandCategory } from './commandCategories';
import commandCategoryValidation from './commandCategoryValidation';
import commandTypeValidation from './commandTypeValidation';

const validateCommandWithCategory = (command, category) => commandCategoryValidation[category]
    .forEach(validator => {
        validator.validate(command);
    });

const validateCommandWithType = command => commandTypeValidation[command.type]
    ?.forEach(validator => {
        validator.validate(command);
    });

const validateCommand = command => {
    const commandCategory = getCommandCategory(command);

    if (!commandCategory) {
        throw new Error('No category found for command');
    }

    // Validate Command at the category level
    validateCommandWithCategory(command, commandCategory);

    // Validate Command at the command level
    validateCommandWithType(command);
};

export default validateCommand;
