const isNil = require('lodash/isNil');

const AbstractShape = require('./AbstractShape');
const Shape = require('./Shape');
const RectangleFabricObjectMixin = require('../fabric-adapter/mixins/rectangleFabricObject');

class Rectangle extends RectangleFabricObjectMixin(AbstractShape) {
    constructor(
        name,
        posX,
        posY,
        attributes = {}
    ) {
        super(name, posX, posY, attributes);
        this.rx = attributes.rx;
        this.ry = attributes.ry;
    }

    static fromJSON(jsonObject) {
        const rect = super.fromJSON(jsonObject);
        return rect;
    }

    toJSON() {
        const serializedRectangle = super.toJSON();
        if (this.rx) {
            serializedRectangle.rx = this.rx;
        }
        if (this.ry) {
            serializedRectangle.ry = this.ry;
        }
        return serializedRectangle;
    }

    applyShapeUpdate({
        assignedShapeStyle,
        ...shapeUpdate
    } = {}) {
        if (assignedShapeStyle) {
            const tmpAssignedStyle = {
                ...assignedShapeStyle
            };

            if (tmpAssignedStyle.rx) {
                tmpAssignedStyle.rx /= this.width;
            }
            if (tmpAssignedStyle.ry) {
                tmpAssignedStyle.ry /= this.height;
            }
            super.applyShapeUpdate({
                ...shapeUpdate,
                assignedShapeStyle: tmpAssignedStyle
            });
        }

        super.applyShapeUpdate(shapeUpdate);
    }

    get rx() {
        if (isNil(this.shapeStyle.rx)) {
            return undefined;
        }
        return this.shapeStyle.rx * this.width;
    }

    set rx(rx) {
        if (!isNil(rx)) {
            this.shapeStyle.rx = rx / this.width;
        }
    }

    get ry() {
        if (isNil(this.shapeStyle.ry)) {
            return undefined;
        }
        return this.shapeStyle.ry * this.height;
    }

    set ry(ry) {
        if (!isNil(ry)) {
            this.shapeStyle.ry = ry / this.height;
        }
    }
}

Shape.addToConstructorList(Rectangle, 'rect');

module.exports = Rectangle;
