// Main menu links
import { features } from './features';

const mainNavConfig = [
    {
        id: 'plan',
        path: 'plan',
        label: 'Plan',
        hideSubNavWithoutDeck: false,
        createButtonWithoutContent: false,
        subNav: [
            {
                id: 'objectives',
                path: '',
                label: 'Objectives'
            },
            {
                id: 'storyboard',
                path: 'storyboard',
                label: 'Storyboard'
            },
            {
                id: 'style',
                path: 'style',
                label: 'Style',
                accessible: features.plan.style.display
            }
        ]
    },
    {
        id: 'build',
        path: 'build',
        label: 'Build',
        hideSubNavWithoutDeck: true,
        createButtonWithoutContent: true
    },
    {
        id: 'share',
        path: 'share',
        label: 'Share',
        subNav: [],
        hideSubNavWithoutDeck: true,
        createButtonWithoutContent: true
    },

    {
        id: 'manage',
        path: 'manage',
        label: 'Manage',
        subNav: [
            {
                id: 'overview',
                path: '',
                label: 'Overview'
            },
            {
                id: 'myFiles',
                path: 'my-files',
                label: 'My files'
            },
            {
                id: 'sharedWithMe',
                path: 'shared-with-me',
                label: 'Shared with me'
            }
        ],
        hideSubNavWithoutDeck: false,
        createButtonWithoutContent: false
    }

];

// Logo dropdown menu links
const dropdownNavConfig = [
    {
        id: 'profile',
        path: 'profile',
        icon: 'user circle',
        label: 'Manage your profile'
    },
    {
        id: 'sign-out',
        icon: 'sign-out',
        label: 'Sign Out',
        flipped: 'horizontally'
    },
    {
        id: 'preferences',
        path: 'preferences',
        icon: 'settings',
        label: 'Manage your preferences'
    },
    {
        id: 'decksign',
        path: 'https://www.decksign.com',
        external: true,
        icon: '',
        label: 'Go to decksign.com'
    }
];

// Other links not displayed
const otherNavConfig = [
    {
        id: 'viewer',
        path: 'viewer',
        label: 'Viewer'
    }
];

const authNavConfig = {
    login: {
        path: '/auth/login',
        label: 'Login'
    },
    signup: {
        path: '/auth/signup',
        conditionalPath: '/auth/signup/:inviteId?',
        label: 'SignUp'
    },
    success: {
        path: '/auth/success',
        label: 'Sign up Success'
    },
    forgot: {
        path: '/auth/forgot',
        label: 'Forgot password'
    },
    recover: {
        path: '/auth/recover',
        label: 'Password Recovery'
    },
    activate: {
        path: '/auth/activate/:token',
        label: 'Activate Account'
    },
    verification: {
        path: '/auth/verification',
        label: 'Account verification'
    },
    welcome: {
        path: '/auth/welcome',
        label: 'Welcome'
    }
};

const mainNavConfigByType = mainNavConfig.reduce((accumulator, item) => ({
    ...accumulator,
    [item.id]: item
}), {});

const dropdownNavConfigByType = dropdownNavConfig.reduce((accumulator, item) => ({
    ...accumulator,
    [item.id]: item
}), {});

const otherNavConfigByType = otherNavConfig.reduce((accumulator, item) => ({
    ...accumulator,
    [item.id]: item
}), {});

export {
    dropdownNavConfig,
    dropdownNavConfigByType,
    mainNavConfigByType,
    otherNavConfig,
    otherNavConfigByType,
    authNavConfig
};
