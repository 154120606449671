const { fabric } = require('fabric');
const UUID = require('uuid/v4');
const cloneDeep = require('lodash/cloneDeep');
const {
    defaultCursorZoneFormat,
    MOVE_CURSOR_ZONE_SIZE
} = require('./config/defaultCursorZoneAttributes.config');
const AxisLocking = require('../Mixins/AxisLocking');

const MoveCursorZone = fabric.util.createClass(fabric.Rect, AxisLocking, {
    type: 'cursorZone',

    initialize(side, table) {
        this.callSuper('initialize', { ...defaultCursorZoneFormat });
        if (['top', 'bottom'].includes(side)) {
            this.width = table.width;
            this.height = MOVE_CURSOR_ZONE_SIZE;
            this.left = table.left;
            if (side === 'top') {
                this.top = table.getAbsoluteTop() - (table.height / 2);
            } else {
                this.top = table.getAbsoluteTop() + (table.height / 2);
            }
        } else {
            this.width = MOVE_CURSOR_ZONE_SIZE;
            this.height = table.height;
            this.top = table.top;
            if (side === 'left') {
                this.left = table.getAbsoluteLeft() - (table.width / 2);
            } else {
                this.left = table.getAbsoluteLeft() + (table.width / 2);
            }
        }
        this.originY = 'center';
        this.originX = 'center';
        this.table = table;

        this.onMouseUp = this.onMouseUp.bind(this);
        this.onMouseMove = this.onMouseMove.bind(this);
        this.updatePositionAndSize = this.updatePositionAndSize.bind(this);
        this.on('mousedown', e => {
            this.setInitialPositions(e);
            e.target = this;
            this.canvas.setActiveObject(this.table);
            this.canvas.fire('selection:created', e);
            const canvasPoint = this.canvas.getPointer(e.e);
            this.moveOrigin = {
                x: canvasPoint.x,
                y: canvasPoint.y
            };
            this.tableOrigin = {
                left: this.table.left,
                top: this.table.top
            };
            this.canvas.selection = false;
            if (e.e.metaKey) {
                const duplicateTable = cloneDeep(this.table);
                duplicateTable.selectable = true;
                const newId = UUID().toString();
                duplicateTable.id = newId;
                this.canvas.add(duplicateTable);
                this.canvas.requestRenderAll();
                this.canvas.duplicateItem([this.table.id]);
            }
            document.addEventListener('mouseup', this.onMouseUp);
            document.addEventListener('mousemove', this.onMouseMove);
        });
        this.tableMoved = false;
    },

    // those methods returns the TABLE left and top.
    getActiveObjectLeft() {
        return this.table.getAbsoluteLeft();
    },

    getActiveObjectTop() {
        return this.table.getAbsoluteTop();
    },

    // those methods set the TABLE left and top.
    setActiveObjectLeft(left) {
        this.table.left = left;
    },

    setActiveObjectTop(top) {
        this.table.top = top;
    },

    updatePositionAndSize() {
        if (['top', 'bottom'].includes(this.side)) {
            this.width = this.table.width;
            this.height = MOVE_CURSOR_ZONE_SIZE;
            this.left = this.table.left;
            if (this.side === 'top') {
                this.top = this.table.getAbsoluteTop() - (this.table.height / 2);
            } else {
                this.top = this.table.getAbsoluteTop() + (this.table.height / 2);
            }
        } else {
            this.width = MOVE_CURSOR_ZONE_SIZE;
            this.height = this.table.height;
            this.top = this.table.top;
            if (this.side === 'left') {
                this.left = this.table.getAbsoluteLeft() - (this.table.width / 2);
            } else {
                this.left = this.table.getAbsoluteLeft() + (this.table.width / 2);
            }
        }
        this.setCoords();
    },

    onMouseUp(event) {
        this.resetAxisLocking();
        document.removeEventListener('mouseup', this.onMouseUp);
        document.removeEventListener('mousemove', this.onMouseMove);
        this.tableMoved = false;
        this.canvas.selection = true;
        if (event.metaKey) {
            this.table.name = `${this.table.name}_copy`;
            this.table.duplicating = true;
        }
        if (!this.table.group) {
            this.table.onModified();
        }
        this.table.canvas.fire('object:modified', { target: this.table });
    },

    onMouseMove(e) {
        this.handleAxisLocking(e);
        this.tableMoved = true;
        const canvasPoint = this.canvas.getPointer(e);
        if (!this.lockMovementX) {
            this.table.left = this.tableOrigin.left + (canvasPoint.x - this.moveOrigin.x);
        }
        if (!this.lockMovementY) {
            this.table.top = this.tableOrigin.top + (canvasPoint.y - this.moveOrigin.y);
        }
        this.table.setCoords();
        this.table.canvas.requestRenderAll();
    }
});

module.exports = MoveCursorZone;
