import { fromJS, List, Map } from 'immutable';
import { isNil } from 'lodash';

import complementAgainstSources from '../../utilities/complementAgainstSources';
import defaultsDeepWithExceptions from '../../utilities/defaultsDeepWithExceptions';

import { immutableShapeStyleProvider as shapeStyleProvider } from '../../utilities/ShapeStyleProvider';
import { extractRGBAFromFirstDecsriptor } from '../Fill';
import { forceOpacity, updateValueOfDescriptor } from './colorValueDescriptor';

const atomicProperties = [
    'dash',
    'fill',
    'head',
    'join',
    'tail'
];

const getStroke = (stroke, colorPalette = List()) => {
    let updatedStroke = stroke;

    if (!updatedStroke) {
        return Map();
    }

    if (updatedStroke?.size && updatedStroke.getIn(['fill', 'color'])) {
        updatedStroke = updatedStroke.setIn(['fill', 'color'], updateValueOfDescriptor(
            updatedStroke.get('opacity') ?
                forceOpacity(
                    updatedStroke.getIn(['fill', 'color']),
                    updatedStroke.get('opacity')
                ) : updatedStroke.getIn(['fill', 'color']),
            colorPalette
        ));
        return updatedStroke;
    }

    return updatedStroke;
};

const getRenderStroke = (shape, colorPalette) => defaultsDeepWithExceptions(
    getStroke(shape?.get('stroke'), colorPalette),
    getStroke(shape?.getIn(['assignedStyles', 'stroke']), colorPalette),
    getStrokeStyleDefaults(shape, colorPalette),
    atomicProperties
);

const getRenderStrokeWithDefaults = (shape, defaultStroke = Map(), colorPalette) => defaultsDeepWithExceptions(
    getStroke(shape?.get('stroke'), colorPalette),
    getStroke(shape?.getIn(['assignedStyles', 'stroke']), colorPalette),
    setStrokeOpacityFromFill(defaultStroke, colorPalette),
    atomicProperties
);

const setComplementStrokeAsItemStrokeStyle = shape => complementAgainstSources(
    shape.get('stroke') || Map(),
    getStrokeStyleDefaults(shape),
    shape.getIn(['assignedStyles', 'stroke']) || Map(),
    atomicProperties
);

const setComplementStrokeAsItemStrokeStyleWithDefaults = (shape, defaultStroke = Map()) => complementAgainstSources(
    shape.get('stroke') || Map(),
    defaultStroke,
    shape.getIn(['assignedStyles', 'stroke']) || Map(),
    atomicProperties
);

const getStrokeStyleDefaults = (shape, colorPalette) => {
    const stroke = loadDefaultShapeStyleConfiguration(shape.get('type')).get('stroke');

    if (!stroke) {
        return stroke;
    }

    const rgba = extractRGBAFromFirstDecsriptor(stroke.get('fill'), colorPalette);

    if (rgba && !isNil(rgba.alpha)) {
        return stroke.set('opacity', rgba.alpha);
    }

    return stroke;
};

const setStrokeOpacityFromFill = (stroke = Map(), colorPalette = List()) => {
    const rgba = extractRGBAFromFirstDecsriptor(stroke.get('fill'), colorPalette);

    if (rgba && !isNil(rgba.alpha)) {
        return stroke.set('opacity', rgba.alpha);
    }

    return stroke;
};

const loadDefaultShapeStyleConfiguration = type => (shapeStyleProvider.get(type) ?
    shapeStyleProvider.get(type) :
    shapeStyleProvider.get('DEFAULT'));

const assignedStrokeStyleToCanvasState = (assignedStrokeStyle, canvasState) => {
    if (assignedStrokeStyle) {
        return fromJS({
            ...assignedStrokeStyle,
            fill: {
                ...assignedStrokeStyle.fill,
                color: updateValueOfDescriptor(
                    forceOpacity(assignedStrokeStyle.getIn(['fill', 'color']), assignedStrokeStyle.get('opacity')),
                    canvasState.get('colorPalette')
                )
            }
        });
    }

    return fromJS(assignedStrokeStyle);
};

export {
    getRenderStroke,
    getRenderStrokeWithDefaults,
    setStrokeOpacityFromFill,
    setComplementStrokeAsItemStrokeStyle,
    setComplementStrokeAsItemStrokeStyleWithDefaults,
    assignedStrokeStyleToCanvasState
};
