import { combineReducers } from 'redux';

import * as types from '#Constants/ActionTypes';
import { resetableReducer, resetOnLocationChange } from '#Utilities/reducers';

import toolbar from './toolbar';
import {
    list as topics,
    searchValue as topicSearchValue,
    suggestions as topicsSuggestions
} from './topics';
import ui from './ui/index';

import { layouts, layoutReset } from './layouts';

import {
    page,
    canvasState,
    activePage,
    editedTextShape,
    isCanvasUpdating,
    isDuplicatingDeck,
    isFirstPageRender,
    persistedCanvasState,
    textSelection,
    resetCanvasCache
} from './page';
import fonts from './fonts';

const zoom = (state = {
    type: 'fit',
    value: -1
}, action) => {
    switch (action.type) {
        case types.ZOOM_IN:
        case types.ZOOM_OUT:
        case types.ZOOM_AT:
            return {
                type: 'ratio',
                value: Number(action.zoom.toFixed(2))
            };
        case types.LOAD_PAGE:
        case types.ADJUST_ZOOM_TO_SCREEN:
            return {
                type: 'fit',
                // the value is unimportant we just want it to change to trigger
                // re-renders
                value: state.type === 'fit' ? state.value - 1 : -1
            };
        default:
            return state;
    }
};

const clipboard = (state = { itemType: 'Object', items: [] }, action) => {
    switch (action.type) {
        case types.SET_CLIPBOARD:
            return {
                itemType: action.itemType,
                items: action.items,
                isCut: action.isCut
            };
        default:
            return state;
    }
};

const computedProperties = (state = {}, action) => {
    switch (action.type) {
        case 'SET_COMPUTED_PROPERTIES': {
            return {
                ...action.computedProperties
            };
        }
        default:
            return state;
    }
};

const combinedReducers = combineReducers({
    activePage,
    page,
    canvasState,
    resetCanvasCache,
    computedProperties,
    clipboard,
    isCanvasUpdating,
    isFirstPageRender,
    isDuplicatingDeck,
    editedTextShape,
    toolbar,
    topics,
    topicSearchValue,
    topicsSuggestions,
    zoom,
    ui,
    fonts,
    persistedCanvasState,
    layouts,
    layoutReset,
    textSelection
});

export default resetOnLocationChange(resetableReducer(types.CREATE_DECK, types.SET_DECK)(combinedReducers), 'build');
