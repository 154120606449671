import { isNil } from 'lodash';
import { DEFAULT_TRAPEZOID_PROPS } from '../../../../../Shape/config/Shapes';

const getBaseLength = shape => {
    let baseLengthRatio = shape.get('baseLength') / shape.get('width');
    baseLengthRatio = !isNil(baseLengthRatio) ? baseLengthRatio :
        DEFAULT_TRAPEZOID_PROPS.baseLengthRatio;
    return baseLengthRatio * shape.get('width');
};

export default getBaseLength;
