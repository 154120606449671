import { connect } from 'react-redux';
import { ConnectedRouter, onLocationChanged } from 'connected-react-router';
import blockActionCreatorByLayoutModal from '#Actions/build/blockActionCreatorByLayoutModal';

const createConnectedRouter = () => {
    const mapDispatchToProps = dispatch => ({
        onLocationChanged: (location, action, isFirstRendering) => dispatch(
            blockActionCreatorByLayoutModal(
                onLocationChanged,
                location,
                action,
                isFirstRendering
            )
        )
    });
    return connect(null, mapDispatchToProps)(ConnectedRouter.WrappedComponent);
};

export default createConnectedRouter();
